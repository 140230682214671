import styled from '@emotion/styled';
import React, { CSSProperties } from 'react';
import {
  ColorProps,
  SpaceProps,
  TypographyProps,
  color,
  space,
  typography,
} from 'styled-system';

export type HeadingFontTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type Props = React.HTMLAttributes<HTMLDivElement> &
  SpaceProps &
  ColorProps &
  TypographyProps & {
    type: HeadingFontTypes;
    children?: string | React.ReactNode;
    id?: string;
    display?: CSSProperties['display'];
    onClick?: () => void;
    cursor?: CSSProperties['cursor'];
  };

const getStyleFontType = (type: HeadingFontTypes) => {
  switch (type) {
    case 'h1':
      return `
            font-weight: bold;
            font-size: 64px;
            line-height: 80px;
            letter-spacing: -0.022px;
          `;
    case 'h2':
      return `
            font-weight: bold;
            font-size: 48px;
            line-height: 64px;
            letter-spacing: -0.00022em;
          `;

    case 'h3':
      return `
            font-weight: bold;
            font-size: 36px;
            line-height: 48px;
            letter-spacing: -0.021px;
          `;
    case 'h4':
      return `
            font-weight: 600;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: -0.019px;
          `;
    case 'h5':
      return `
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.017px;
          `;
    case 'h6':
      return `
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.017px;
            margin-bottom: unset;
          `;
    default:
      return null;
  }
};

export const H = styled.p<Props>`
  margin-bottom: 0px;
  font-family: 'Inter';
  font-style: normal;
  cursor: ${(props) => props.cursor && props.cursor};
  ${(props) => getStyleFontType(props.type)}
  ${typography}
  ${space}
  ${color}
`;
