import React, { FC, useEffect, useState } from 'react';
import { RadioButton, RadioGroup } from '../../../../components';
import { Dropdown, Menu } from 'antd';
import EmployeesTable from './employees/EmployeesTable';
import PayrollTable from './payroll/PayrollTable';
import UserStatsSummary from './employees/UserStatsSummary';
import styled from '@emotion/styled';
import PayrollStats from './payroll/PayrollStats';
import {
  EmployeeI,
  OrganizationsDataByOwnerI,
  PayrollI,
} from '../../../../types/superAdmin.types';
import JsonView from './JsonView';
import { DropdownBlue } from '../../../../assets';
import UserFeatures from './payroll/UserFeatures';
interface Props {
  organizationDetails: OrganizationsDataByOwnerI | null;
}
const OrganizationSummary = styled.div`
  padding-left: 32px;
`;
const OrganizationSummaryTad = styled.div`
  padding: 29px 0px 21px;
  display: flex;
  flex-direction: row;
`;
const OrganizationSummaryTable = styled.div`
  @media (max-width: 1366px) {
    margin-bottom: 32px;
  }
`;
const FilterDropdown = styled(Dropdown)`
  margin-left: auto;
  margin-top: 15px;
  color: #0052ea;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  :hover {
    color: #0052ea !important;
  }
  .ant-dropdown .ant-dropdown-placement-bottomLeft {
    top: 255px !important;
  }
`;
const OrganizationSummaryDropdown = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
`;
enum TabTypes {
  employees = 'employees',
  payroll = 'payroll',
  Settings = 'Settings',
  Features = 'Features',
}
enum UserFilterTypes {
  allUsers = ' allUsers',
  activeUsers = 'activeUsers',
  archivedUsers = 'archivedUsers',
}
enum PayrunsFilterTypes {
  activePayruns = 'activePayruns',
  draftedPayruns = 'draftedPayruns',
  archivedPayruns = 'archivedPayruns',
  completedPayruns = 'completedPayruns',
}
const userMenuItems = [
  {
    key: UserFilterTypes.allUsers,
    name: 'All Users',
  },
  {
    key: UserFilterTypes.activeUsers,
    name: 'Active Users',
  },
  {
    key: UserFilterTypes.archivedUsers,
    name: 'Archived Users',
  },
];
const payrunMenuItems = [
  {
    key: PayrunsFilterTypes.activePayruns,
    name: 'Active Payruns',
  },
  {
    key: PayrunsFilterTypes.completedPayruns,
    name: 'Completed Payruns ',
  },
  {
    key: PayrunsFilterTypes.draftedPayruns,
    name: 'Drafted Payruns',
  },
  {
    key: PayrunsFilterTypes.archivedPayruns,
    name: 'Archived Payruns ',
  },
];
const OrganizationDetails: FC<Props> = ({ organizationDetails }) => {
  const [filter, setFilter] = useState<TabTypes>(TabTypes.employees);
  const [userFilter, setUserFilter] = useState<UserFilterTypes>(
    UserFilterTypes.allUsers,
  );
  const [payrunFilter, setPayrunFilter] = useState<PayrunsFilterTypes>(
    PayrunsFilterTypes.activePayruns,
  );
  const [dataSource, setDataSource] = useState<PayrollI[]>([]);
  const [userDataSource, setUserDataSource] = useState<EmployeeI[]>([]);
  const userMenu = (
    <Menu>
      {userMenuItems.map((item) => (
        <Menu.Item onClick={() => setUserFilter(item.key)} key={item.key}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  const payrunMenu = (
    <Menu>
      {payrunMenuItems.map((item) => (
        <Menu.Item onClick={() => setPayrunFilter(item.key)} key={item.key}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  //...........................PayrunsFilter.......................
  useEffect(() => {
    const list: PayrollI[] = [];
    organizationDetails?.payrolls.forEach((payroll) => {
      const payrollTable: PayrollI = {
        ...payroll,
      };
      if (
        payrunFilter === PayrunsFilterTypes.activePayruns &&
        !payrollTable.isArchived
      ) {
        list.push(payrollTable);
      } else if (
        payrunFilter === PayrunsFilterTypes.archivedPayruns &&
        payrollTable.isArchived
      ) {
        list.push(payrollTable);
      } else if (
        payrunFilter === PayrunsFilterTypes.draftedPayruns &&
        payrollTable.status === 'DRAFTED'
      ) {
        list.push(payrollTable);
      } else if (
        payrunFilter === PayrunsFilterTypes.completedPayruns &&
        payrollTable.status === 'COMPLETED'
      ) {
        list.push(payrollTable);
      }
    });

    setDataSource(list);
  }, [organizationDetails, payrunFilter]);
  //........................userFilter..........................
  useEffect(() => {
    const list: EmployeeI[] = [];

    organizationDetails?.employees.forEach((employee) => {
      const employeeTable: EmployeeI = {
        ...employee,
      };
      if (userFilter === UserFilterTypes.allUsers) {
        list.push(employeeTable);
      } else if (
        userFilter === UserFilterTypes.activeUsers &&
        !employeeTable.isArchived
      ) {
        list.push(employeeTable);
      } else if (
        userFilter === UserFilterTypes.archivedUsers &&
        employeeTable.isArchived
      ) {
        list.push(employeeTable);
      }
    });

    setUserDataSource(list);
  }, [organizationDetails, userFilter]);
  const JsonConfig = {
    id: organizationDetails?.organizationId,
    bankDetails: organizationDetails?.bankDetails,
    dataExtractorTypes: organizationDetails?.dataExtractorTypes,
    isLeaveInPayroll: organizationDetails?.isLeaveInPayroll,
    isUsingSystemLeaves: organizationDetails?.isUsingSystemLeaves,
    paySlipConfig: organizationDetails?.paySlipConfig,
    createdAt: organizationDetails?.createdAt,
    updatedAt: organizationDetails?.updatedAt,
  };

  return (
    <>
      <OrganizationSummary>
        {filter === TabTypes.employees ? (
          <UserStatsSummary organizationDetails={organizationDetails} />
        ) : (
          <PayrollStats
            payrollDetails={
              organizationDetails ? organizationDetails.payrolls : []
            }
          />
        )}
        <OrganizationSummaryTad>
          <RadioGroup defaultValue="employees">
            <RadioButton
              value="employees"
              onClick={() => {
                setFilter(TabTypes.employees);
              }}>
              Employees
            </RadioButton>
            <RadioButton
              value="payroll"
              onClick={() => {
                setFilter(TabTypes.payroll);
              }}>
              Payroll
            </RadioButton>
            <RadioButton
              value="settings"
              onClick={() => {
                setFilter(TabTypes.Settings);
              }}>
              Settings
            </RadioButton>
            <RadioButton
              value="features"
              onClick={() => setFilter(TabTypes.Features)}>
              Features
            </RadioButton>
          </RadioGroup>
          <OrganizationSummaryDropdown>
            {filter === TabTypes.employees ? (
              <FilterDropdown overlay={userMenu} trigger={['click']}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}>
                  {userMenuItems.find((item) => item.key === userFilter)?.name}{' '}
                  <img src={DropdownBlue} />
                </a>
              </FilterDropdown>
            ) : filter === TabTypes.payroll ? (
              <FilterDropdown overlay={payrunMenu} trigger={['click']}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}>
                  {
                    payrunMenuItems.find((item) => item.key === payrunFilter)
                      ?.name
                  }{' '}
                  <img src={DropdownBlue} />
                </a>
              </FilterDropdown>
            ) : null}
          </OrganizationSummaryDropdown>
        </OrganizationSummaryTad>
        <OrganizationSummaryTable>
          {filter === TabTypes.employees ? (
            <EmployeesTable employees={userDataSource} />
          ) : filter === TabTypes.payroll ? (
            <PayrollTable payroll={dataSource} />
          ) : filter === TabTypes.Features ? (
            <UserFeatures
              organizationId={organizationDetails?.organizationId}
            />
          ) : (
            // @ts-ignore
            <JsonView JsonConfig={JsonConfig} />
          )}
        </OrganizationSummaryTable>
      </OrganizationSummary>
    </>
  );
};
export default OrganizationDetails;
