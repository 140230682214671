import React, { ReactNode, FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { Collapse, List, message, Spin } from 'antd';
import { sortBy, groupBy } from 'lodash';
import moment from 'moment';
import { useGetUpcomingEvents } from '../../../api/organizationHooks';
import { B, Div } from '../../../components';
import { UpcomingTypesEnum } from '../../../types/organization.types';
import theme from '../../../theme';
import { UpcomingEventsI } from '../../../types/notification.types';
import { EmptyContainer } from './EmptyContainer';
import noNotificationYetSvg from '../../../assets/Icons/note-icons/no-notification-yet.svg';
import birthdaySvg from '../../../assets/Icons/note-icons/birthday.svg';
import { AnniversarySVG } from '../../../assets';

const { Panel } = Collapse;

const RemindersListComp = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.5px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  .ant-card-body {
    padding: 0;
  }
  .demo-loadmore-list {
    cursor: pointer;
  }
`;

const CollapseSection = styled(Collapse)`
  border: none;
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 6px;
  }
  .ant-collapse-extra {
    margin-top: -20px;
  }
  .ant-collapse-header {
    padding: 4px 16px !important;
  }
  .ant-collapse-arrow svg {
    margin-top: -8px;
  }
`;

const NotificationsListDiv = styled.div`
  overflow: auto;
  height: 713px;
`;

const NoNotificationSection = styled.div`
  text-align: center;
  padding: 30px 0px;
`;

interface DateRowProps {
  date: string;
  children: ReactNode;
}

interface UpcomingEventsPropsI {
  data: UpcomingEventsI[];
}

const UpcomingEvents: FC = () => {
  const {
    isLoading: loadingUpcomingEvents,
    error: upcomingEventsErrors,
    data: UpcomingEvents = [],
    mutate: getUpcomingEvents,
  } = useGetUpcomingEvents();

  useEffect(() => {
    getUpcomingEvents();
  }, []);

  const today = new Date().toISOString(); // Get today's date in ISO format

  // Check if today's date is already in the list
  const todayExists = UpcomingEvents.some(
    (item) =>
      moment(item.effectiveDate).format('YYYY-MM-DD') ===
      moment().format('YYYY-MM-DD'),
  );

  // If today's date is not in the list, add an object for today
  if (!todayExists) {
    UpcomingEvents.push({
      type: UpcomingTypesEnum.No_Any_Notifications,
      payload: {},
      effectiveDate: today,
    });
  }

  const sortedData = sortBy(UpcomingEvents, 'effectiveDate');

  const orderedReminders = groupBy(sortedData, function (b) {
    return moment(b.effectiveDate).format('YYYY-MM-DD');
  });

  const DateRow: FC<DateRowProps> = ({ date, children }) => {
    return (
      <CollapseSection
        expandIconPosition="right"
        defaultActiveKey={[
          moment().format('YYYY-MM-DD'),
          moment().add('d', 1).format('YYYY-MM-DD'),
        ]}>
        <Panel
          header={
            <B type="b-small" color={theme.gray600}>
              {moment(date).format('MMM Do dddd')}
            </B>
          }
          key={date}
          extra={
            date == moment().format('YYYY-MM-DD') ? (
              <B type="b-small" color={theme.gray600}>
                Today
              </B>
            ) : date == moment().add('d', 1).format('YYYY-MM-DD') ? (
              <B type="b-small" color={theme.gray600}>
                Tomorrow
              </B>
            ) : null
          }
          showArrow={
            date == moment().format('YYYY-MM-DD') ||
            date == moment().add('d', 1).format('YYYY-MM-DD')
              ? false
              : true
          }>
          {children}
        </Panel>
      </CollapseSection>
    );
  };

  const NotesDataRow: FC<UpcomingEventsPropsI> = ({ data }) => {
    return (
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item: UpcomingEventsI) => {
          if (
            item.type === UpcomingTypesEnum.BirthDay ||
            item.type === UpcomingTypesEnum.Anniversary
          ) {
            return (
              <>
                <Div display="flex" p="12px 10px">
                  <img
                    src={
                      item.type === UpcomingTypesEnum.BirthDay
                        ? birthdaySvg
                        : AnniversarySVG
                    }
                    width={22}
                    height={22}
                  />
                  <B type="b-small" pl="10px">
                    {item.type === UpcomingTypesEnum.BirthDay
                      ? item.payload.birthdayText
                      : item.payload.event}
                  </B>
                </Div>
              </>
            );
          } else {
            return (
              <NoNotificationSection>
                <img src={noNotificationYetSvg} />
                <B type="b-small" pt="6px">
                  No Notifications Yet
                </B>
              </NoNotificationSection>
            );
          }
        }}
      />
    );
  };
  return (
    <>
      {upcomingEventsErrors && message.error(upcomingEventsErrors.message)}
      <RemindersListComp>
        <Div
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="16px">
          <Div>
            <B type="b-large-semibold">Notifications</B>
          </Div>
        </Div>
        {sortedData.length === 0 ? (
          <EmptyContainer
            description="You have no todo list activities!"
            subDescription="Currently, There’re no hr activities to list here until you’r employees start to use this."
          />
        ) : (
          <NotificationsListDiv>
            <Spin spinning={loadingUpcomingEvents}>
              <Div borderTop={`1px solid ${theme.gray300}`}>
                {Object.entries(orderedReminders).map(([key, value]) => {
                  const rows = (
                    <div>
                      <NotesDataRow data={value} />
                    </div>
                  );

                  return <DateRow date={key}>{rows}</DateRow>;
                })}
              </Div>
            </Spin>
          </NotificationsListDiv>
        )}
      </RemindersListComp>
    </>
  );
};
export default UpcomingEvents;
