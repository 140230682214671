import React, { FC, useRef, useEffect } from 'react';
import Select, { RefSelectProps, SelectProps } from 'antd/lib/select';
import { SelectValue } from 'antd/lib/tree-select';
import theme from '../../theme';
import styled from '@emotion/styled';

interface Props extends SelectProps<SelectValue> {
  forceClose?: boolean;
  bordered?: boolean;
  flexDirection?: string;
  overruleWidthPresent?: number | undefined;
}

const SelectInput = styled(Select)<Props>`
  @media screen and (max-width: 768px) {
    width: ${(props) => props.flexDirection === 'row' && '100%'};
    max-width: ${(props) => props.flexDirection === 'row' && '344px'};
  }
  width: ${(props) =>
    props.overruleWidthPresent ? `${props.overruleWidthPresent}px` : '100%'};
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  .ant-select-selector {
    box-sizing: border-box !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    display: flex !important;
    align-items: center !important;
    height: ${(props) => (props.size === 'large' ? '40px' : '32px')} !important;
    padding: ${(props) =>
      props.size === 'large' ? '0px 12px' : '0px 16px'} !important;

    input {
      height: 100% !important;
    }
  }
  .span {
    font-size: 14px;
    line-height: 20px;
    color: ${theme.gray600} !important;
  }
  .ant-select-selector:hover {
    border: ${(props) =>
      props.bordered === false
        ? 'none'
        : `1px solid ${theme.blue500}`} !important;
    box-sizing: border-box !important;
    filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
    border-radius: 4px !important;
  }
  .ant-select-selector:focus {
    border: 1px solid ${theme.blue500} !important;
    box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1) !important;
  }
  .ant-select-arrow {
    color: black;
  }
  .ant-select-selection-item-content {
    color: ${theme.blue600};
  }
  .ant-select-selection-item-remove {
    color: ${theme.blue600};
  }
`;
export const SelectV2: FC<Props> = (props) => {
  const {
    size = 'small',
    bordered = true,
    flexDirection = 'column',
    overruleWidthPresent = undefined,
    forceClose = false,
    ...rest
  } = props;

  const selectRef = useRef<RefSelectProps | null>(null);

  useEffect(() => {
    if (forceClose) {
      selectRef.current?.blur();
    }
  }, [forceClose]);

  return (
    <SelectInput
      overruleWidthPresent={overruleWidthPresent}
      flexDirection={flexDirection}
      bordered={bordered}
      ref={(node) => (selectRef.current = node)}
      size={size}
      {...rest}
    />
  );
};
