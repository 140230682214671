import XLSX from 'xlsx';

import { cleanupInitialCsvData } from './openPayrollValidator';

interface LocationDataI {
  data: Array<string[]>;
  headers: string[];
}

function fetchCsv(url: any) {
  return fetch(url).then(function (response) {
    if (response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      return reader.read().then(function (result) {
        return decoder.decode(result.value);
      });
    }
  });
}

export async function getCSVDataFromUrl(url: string): Promise<LocationDataI> {
  const response = await fetchCsv(url);

  const csvFile = XLSX.read(response, { type: 'binary' });
  const sheetName = csvFile.SheetNames[0];
  const workSheet = csvFile.Sheets[sheetName];
  const json = XLSX.utils.sheet_to_json(workSheet, {
    header: 1,
    raw: false,
  });
  const headers = json[0] as string[];
  json.shift();
  const data = json.slice(0, 8);
  return {
    headers,
    data: cleanupInitialCsvData(data as Array<string[]>),
  };
}
