import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logOutUser } from '../store/actions';
import { DispatchType, RootState } from '../store/reducer';
import { useNavigate } from 'react-router';

export const InternalErrorComponent: FC = () => {
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();
  const authAllPayloadError = useSelector(
    (state: RootState) => state.auth.authAllPayloadError,
  );

  if (!authAllPayloadError) {
    return null;
  } else {
    dispatch(logOutUser());
    navigate('/login');
  }
  return <></>;
};
