import styled from '@emotion/styled';
import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  BackgroundProps,
  background,
  border,
  BorderProps,
  flexbox,
  FlexboxProps,
  TypographyProps,
  typography,
  display,
  maxWidth,
  width,
  position,
  FlexProps,
  BackgroundColorProps,
  DisplayProps,
  MaxWidthProps,
  WidthProps,
  PositionProps,
} from 'styled-system';

export const Div = styled.div<
  SpaceProps &
    FlexProps &
    LayoutProps &
    BackgroundProps &
    BorderProps &
    FlexboxProps &
    TypographyProps &
    DisplayProps &
    BackgroundColorProps &
    MaxWidthProps &
    WidthProps &
    PositionProps &
    React.HTMLAttributes<HTMLDivElement>
>`
  ${display}
  ${width}
  ${maxWidth}
  ${space}
  ${background}
  ${border}
  ${position}
  ${flexbox}
  ${layout}
  ${typography}
`;
