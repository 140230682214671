export interface SubscriptionI {
  id: number;
  month: string | null;
  outstandingAmount: number | null;
  status: SubscriptionEnum;
  invoiceLink: string | null;
  outstanding: string | null;
  userId: number;
  year: number;
}
export enum SubscriptionEnum {
  DUE = 'DUE',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  WRITTEN_OFF = 'WRITTEN_OFF',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}
export enum SubscriptionFilterTypes {
  DUE = 'DUE',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  WRITTEN_OFF = 'WRITTEN_OFF',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  ALL = 'ALL',
}
