import React, { FC, useEffect, useState } from 'react';

import { Col, Dropdown, Menu, Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { useParams } from 'react-router';

import styled from '@emotion/styled';

import { B, LinkButton } from '../../../../components';
import { DeleteModal } from '../../../../components/PopupModals';
import TableComp from '../../../../components/TableComp';
import { useIndividualPeopleState } from '../../../../providers/IndividualPeopleState';
import theme from '../../../../theme';
import PeopleSelect from '../../shared/PeopleSelect';
import { FileUploadModal } from './FileUploadModal';
import {
  useDeleteUserDocument,
  useGetUserDocuments,
  useUserFileUpload,
} from '../../../../api/documentHooks';
import { DocumentListI } from '../../../../types/document.types';
import { MoreSVG, PlusBlueSVG } from '../../../../assets';

const LastColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: flex-end;
`;

const DropdownMenu = styled(Menu)`
  width: 200px;
  padding: 0;
  style: bold;
  border: 0.5px solid ${theme.gray300};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .ant-dropdown-menu-item {
    padding: 12px 0px 12px 16px;
    border-bottom: 1px solid ${theme.gray300};
  }
`;

const MainContainer = styled.div`
  padding: 32px;
  height: 75vh;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const HeadingSection = styled(Row)`
  @media (max-width: 1024px) and (min-width: 500px) {
    display: flex;
    flex-direction: row;
  }
  @media (max-width: 450px) {
    display: flex;
    flex-direction: inherit;
  }
`;

const TitleSelectSection = styled.div`
  @media (max-width: 1024px) and (min-width: 500px) {
    display: flex;
    flex-direction: column-reverse;
  }
  @media (max-width: 1024px) {
    padding-bottom: 24px;
  }
`;

const UploadMobileSection = styled(Col)`
  padding-left: 25px;
  display: flex;
  justify-content: flex-end;
  @media (min-width: 1025px) {
    display: none;
  }
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const UploadDeskSection = styled(Col)`
  padding-left: 25px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const MoreIcon = styled.img`
  cursor: pointer;
  margin: auto;
`;

type ParamsI = {
  employeeKey: string;
};

export const DocumentTable: FC = () => {
  const params = useParams<ParamsI>();
  const [visible, setVisible] = useState(false);
  const { fetchPeopleById } = useIndividualPeopleState();

  const {
    isLoading: loadingDocumentList,
    mutate: getUserDocuments,
    data: documentList = [],
  } = useGetUserDocuments();

  useEffect(() => {
    getUserDocuments(params.employeeKey);
  }, [params.employeeKey]);

  const {
    isLoading: loadingAddUserDocuments,
    error: errorUploadDocument,
    mutate: uploadDocument,
    data: uploadDocumentForData,
  } = useUserFileUpload();

  const {
    isLoading: loadingDeleteUserDocument,
    error: errorDeleteUserDocument,
    mutate: deleteUserDocument,
    data: deleteUserDocumentForData,
  } = useDeleteUserDocument();

  const deleteDocument = (documentId: number) => {
    deleteUserDocument(documentId);
    fetchPeopleById(params.employeeKey);
  };

  useEffect(() => {
    if (
      (uploadDocumentForData && !errorUploadDocument) ||
      (deleteUserDocumentForData && !errorDeleteUserDocument)
    ) {
      fetchPeopleById(params.employeeKey);
      getUserDocuments(params.employeeKey);
    }
  }, [uploadDocumentForData, deleteUserDocumentForData]);

  const columns: ColumnProps<DocumentListI>[] = [
    {
      title: 'Document',
      dataIndex: 'title',
      render: (value) => (
        <p
          style={{
            margin: 0,
            fontSize: 12,
            cursor: 'pointer',
          }}>
          {value ? value : '-'}
        </p>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (value) => {
        return (
          <p
            style={{
              margin: 0,
              fontSize: 12,
              cursor: 'pointer',
            }}>
            {moment(value).isValid()
              ? moment(value).format('DD MMM YYYY')
              : '-'}
          </p>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (value) => (
        <p
          style={{
            margin: 0,
            fontSize: 12,
            cursor: 'pointer',
          }}>
          {value ? value.toLowerCase() : '-'}
        </p>
      ),
    },
    {
      title: '',
      dataIndex: 'menu',
      key: 'menu',
      align: 'right',
      render: (_value, data) => (
        <LastColumn>
          <Dropdown
            overlay={
              <DropdownMenu>
                <Menu.Item>
                  <a href={data.url} download target="_blank" rel="noreferrer">
                    Download
                  </a>
                </Menu.Item>

                <Menu.Item>
                  <DeleteModal
                    title="Delete"
                    buttonLabel="Delete"
                    content="Are you sure you want to delete ?"
                    onClickCancel={() => deleteDocument(data.id)}
                    openModal={<B type="b-default">Delete</B>}></DeleteModal>
                </Menu.Item>
              </DropdownMenu>
            }>
            <MoreIcon
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
              src={MoreSVG}
            />
          </Dropdown>
        </LastColumn>
      ),
    },
  ];

  return (
    <MainContainer>
      <HeadingSection>
        <Col xs={0} sm={0} md={0} lg={16} xl={16} xxl={19}>
          <B type="b-large-semibold">Document</B>
        </Col>
        <Col xs={12} sm={14} md={12} lg={0} xl={0} xxl={0}>
          <TitleSelectSection>
            <PeopleSelect defaultIndex="6" />
          </TitleSelectSection>
        </Col>

        <UploadMobileSection xs={12} sm={10} md={12} lg={0} xl={0} xxl={0}>
          <Row justify="end">
            <LinkButton
              id="upload-new-doc"
              onClick={() => setVisible(true)}
              icon={<img src={PlusBlueSVG} />}>
              Upload
            </LinkButton>
          </Row>
        </UploadMobileSection>
        <UploadDeskSection xs={12} sm={10} md={12} lg={24} xl={8} xxl={5}>
          <Row justify="end">
            <LinkButton
              id="upload-new-doc"
              onClick={() => setVisible(true)}
              icon={<img src={PlusBlueSVG} />}>
              Upload New Document
            </LinkButton>
          </Row>
        </UploadDeskSection>
      </HeadingSection>
      <Row>
        <Col xs={24} sm={24} md={24} lg={15} xl={15} xxl={15}>
          <B type="b-small" color={theme.gray600} Pb="16px">
            You can store all the documents related to the employee here. Your
            documents should be in either PDF or Word formats.
          </B>
        </Col>
      </Row>

      <TableComp
        loading={
          loadingAddUserDocuments ||
          loadingDeleteUserDocument ||
          loadingDocumentList
        }
        size="middle"
        withBorders={true}
        columns={columns}
        dataSource={documentList}
        pagination={false}
      />

      {visible ? (
        <FileUploadModal
          visible={visible}
          handleCancel={() => {
            setVisible(false);
          }}
          onSubmit={async (values, file) => {
            const data = {
              title: values ? values : '',
              type: 'FILE',
              userId: params.employeeKey,
            };
            uploadDocument({ file: file, ...data });
            setVisible(false);
          }}
        />
      ) : null}
    </MainContainer>
  );
};
