import { Dispatch, combineReducers } from 'redux';

import auth, { LOGOUT_FROM_APP } from './modules/auth';
import dashboard from './modules/dashboard';
import leave from './modules/leave';
import organization from './modules/organization';
import payroll from './modules/payroll';
import people from './modules/people';
import logTrail from './modules/logTrail';
import app from './modules/app';
import dataImport from './modules/dataImport';
import integration from './modules/integration';
import dataExport from './modules/dataExport';
import contract from './modules/contract';
import notification from './modules/notification';
import report from './modules/report';
import superAdmin from './modules/superAdmin';

const rootReducer = combineReducers({
  app,
  auth,
  organization,
  people,
  payroll,
  leave,
  logTrail,
  dataImport,
  dataExport,
  integration,
  contract,
  notification,
  report,
  superAdmin,
  dashboard,
});

export default (state: any, action: any) =>
  rootReducer(action.type === LOGOUT_FROM_APP ? undefined : state, action);

export type RootState = ReturnType<typeof rootReducer>;
export type DispatchType = Dispatch<any>;
