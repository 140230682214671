export const identityColumnsSummary = [
  { key: 'empId', width: 15 },
  { key: 'fullName', width: 25 },
];

export const identityColumns = [
  { key: 'empId', width: 15 },
  { key: 'fullName', width: 25 },
  { key: 'nameWithInitials', width: 20 },
  { key: 'preferredName', width: 15 },
];

export const calculationColumns = [
  { key: 'deduction', width: 15 },
  { key: 'netSalary', width: 15 },
  { key: 'costToCompany', width: 15 },
];

export const calculationColumnsWithGrossCols = [
  { key: 'grossEarningsForAPIT', width: 25 },
  { key: 'grossEarningsForEPFAndETF', width: 30 },
  { key: 'deduction', width: 15 },
  { key: 'netSalary', width: 15 },
  { key: 'costToCompany', width: 15 },
];

export const bankColumns = [
  { key: 'paymentMode', width: 20 },
  { key: 'bankAccountName', width: 20 },
  { key: 'bankAccountNumber', width: 20 },
  { key: 'bankBankCode', width: 20 },
  { key: 'branchCode', width: 20 },
];

export const generalInfoColumns = [
  { key: 'epfNumber', width: 15 },
  { key: 'etfNumber', width: 15 },
  { key: 'taxNumber', width: 15 },
  { key: 'loginEmail', width: 30 },
  { key: 'gender', width: 15 },
  { key: 'personalMobile', width: 15 },
  { key: 'phoneNumber', width: 15 },
  { key: 'employmentType', width: 15 },
  { key: 'branchName', width: 15 },
  { key: 'joinedDate', width: 15 },
  { key: 'designation', width: 15 },
  { key: 'startDate', width: 15 },
  { key: 'occupationGrade', width: 15 },
  { key: 'teams', width: 15 },
  { key: 'address', width: 50 },
  { key: 'DOB', width: 15 },
  { key: 'nicOrPassport', width: 15 },
];

export const empColumns = [
  { key: 'paySlipMail', width: 30 },
  { key: 'loginEmail', width: 30 },
  { key: 'nicOrPassport', width: 15 },
  { key: 'occupationGrade', width: 15 },
  { key: 'designation', width: 15 },
  { key: 'address', width: 50 },
  { key: 'DOB', width: 15 },
  { key: 'personalMobile', width: 15 },
  { key: 'employmentType', width: 15 },
  { key: 'branchName', width: 15 },
  { key: 'teams', width: 15 },
  { key: 'bankAccountName', width: 20 },
  { key: 'bankAccountNumber', width: 20 },
  { key: 'bankBankCode', width: 20 },
  { key: 'branchCode', width: 20 },
  { key: 'gender', width: 15 },
  { key: 'phoneNumber', width: 15 },
  { key: 'epfNumber', width: 15 },
  { key: 'etfNumber', width: 15 },
  { key: 'taxNumber', width: 15 },
  { key: 'joinedDate', width: 15 },
  { key: 'startDate', width: 15 },
  { key: 'endDate', width: 15 },
];
