import React, { FC } from 'react';
import { Col, DatePicker, Row } from 'antd';
import { css } from '@emotion/react';
import { B, InputComp } from '../../../components';
import dayJs from 'dayjs';
import theme from '../../../theme';

const { RangePicker } = DatePicker;
interface Props {
  payRunName: string;
  onChange: (text: string) => void;
}
const SalaryInfoTitleAndDate: FC<Props> = ({ payRunName }) => {
  return (
    <>
      <Row gutter={[32, 32]}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <InputComp
            label="Payrun Name"
            value={payRunName}
            onChange={(e) => e.target.value}
          />
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <B color={theme.blue700} pb="4px" type="b-italic">
            Date Period
          </B>
          <br />
          <RangePicker
            size="large"
            defaultValue={[dayJs(), dayJs().add(1, 'month')]}
            css={css`
              border: 1px solid #bdbdbd;
              box-sizing: border-box;
              padding-left: 16px;
              border-radius: 4px;
              font-family: Inter;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;

              &:focus {
                border: 1px solid #0052ea;
                box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1);
              }

              &:hover {
                border: 1px solid #0052ea;
                box-sizing: border-box;
                filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
                border-radius: 4px;
              }
            `}
          />
        </Col>
      </Row>
    </>
  );
};

export default SalaryInfoTitleAndDate;
