import React, { FC, useEffect, useState } from 'react';
import { Col, Row, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useNavigate, useLocation } from 'react-router';
import { css } from '@emotion/react';
import SalarySummary from './SalarySummary';
import SalaryInfoTitleAndDate from './SalaryInfoTitleAndDate';
import SalaryInfoFooter from './SalaryInforFooter';
import { numberThousandSeparator } from '../../../util/utils';
import { B, Div, H } from '../../../components';
import { PaySheet } from '../modules/PaySheet';
import { EmployeePaySheetSummary } from '../modules/EmployeeSalary';
import styled from '@emotion/styled';

const MainCard = styled.div`
  border-radius: 4px;
  background-color: white;
  padding: 10px;
`;

const SalaryInfoTitle = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  background-clip: border-box;
  padding: 32px 0px 0px;
  margin-top: 16px;
  border: 0.5px solid #e0e0e0;

  box-sizing: border-box;
  border-radius: 0;
  border-bottom: none;
`;
const SalaryInformation: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [payRunName, setPayRunName] = useState('2021 January');
  const [paySheet, setPaySheet] = useState<PaySheet>(new PaySheet([]));

  const [dataSource, setDataSource] = useState<EmployeePaySheetSummary[]>([]);
  const [summary, setSummary] = useState<EmployeePaySheetSummary>();

  useEffect(() => {
    const mapDataColumns = location.state.mapDataColumns || [];
    const newPaySheet = new PaySheet(mapDataColumns);
    setDataSource(newPaySheet.getPaySheetBreakdownByEmployees());
    setSummary(newPaySheet.getOverallPaySheetSummary());
    setPaySheet(newPaySheet);
  }, []);

  const columns: ColumnProps<EmployeePaySheetSummary>[] = [
    {
      title: 'Employees',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Gross',
      key: 'grossAddition',
      dataIndex: 'grossAddition',
      align: 'right',
      render: (value) => numberThousandSeparator(value),
    },
    {
      title: 'Deduction',
      key: 'grossDeduction',
      dataIndex: 'grossDeduction',
      align: 'right',
      render: (value) => numberThousandSeparator(value),
    },
    {
      title: 'Net Pay',
      key: 'netPay',
      dataIndex: 'netPay',
      align: 'right',
      render: (value) => numberThousandSeparator(value),
    },
    {
      title: 'Action',
      key: 'key',
      dataIndex: 'key',
      render: (value) => (
        <a
          onClick={() => {
            navigate(`/open-personal-salary/edit`, {
              state: {
                indexId: value,
                mapDataColumns: paySheet.getMapDataColumns(),
              },
            });
          }}>
          <p
            style={{
              margin: 0,
              fontSize: 14,
              color: '#0052ea',
              cursor: 'pointer',
            }}>
            View
          </p>
        </a>
      ),
    },
  ];

  return (
    <MainCard>
      <Row
        justify="space-between"
        css={css`
          padding: 16px 0 0 0;
        `}>
        <Col>
          <H type="h5" noMargin>
            CSV Data Mapping Process
          </H>
          <B type="b-large" Pb="24px">
            Please verify payroll (Double check your payroll looks good to run){' '}
          </B>
        </Col>
        <Col></Col>
      </Row>

      <SalarySummary summary={summary} />
      <SalaryInfoTitle>
        <Div padding="0px 32px">
          <SalaryInfoTitleAndDate
            payRunName={payRunName}
            onChange={(text) => setPayRunName(text)}
          />
        </Div>
        <Table
          scroll={{
            x: 800,
          }}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          css={css`
            border-top: 0.5px solid #e0e0e0;
            border-radius: 4px;
            .ant-table-thead > tr > th {
              background: #fafafa;
              border-radius: 4px 4px 0px 0px;
              font-family: Inter;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: #5f6267;
              padding: 10px 26px;
            }
            .ant-table-tbody > tr > td {
              font-family: Inter;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              border-bottom: 1px solid #e0e0e0;
              padding: 15px 26px;
            }
            .ant-checkbox-checked .ant-checkbox-inner {
              background-color: #0052ea;
              border-color: #0052ea;
            }
            .ant-checkbox-indeterminate .ant-checkbox-inner::after {
              background-color: #ffff;
            }
          `}
        />
      </SalaryInfoTitle>
      <SalaryInfoFooter
        onClickPostPayRun={() => {
          navigate('/open-bank-confirmation', {
            state: {
              mapDataColumns: paySheet.getMapDataColumns(),
              payRunName: payRunName,
            },
          });
        }}
      />
    </MainCard>
  );
};

export default SalaryInformation;
