import { Button, Checkbox, Dropdown, Menu } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { B } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { getPayrollSummary, PreviousPayrollI } from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import moment from 'moment';
import { addDotsForLongText } from '../../../util/utils';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import { PayrunType } from '../../../types/payroll.types';
import styled from '@emotion/styled';
import theme from '../../../theme';

const StyledMenu = styled(Menu)`
  background: ${theme.white};
  border: 1px solid ${theme.gray300};
  border-radius: 4px;
  width: 330px;
`;

const OuterDiv = styled.div`
  overflow-y: scroll;
  max-height: 350px;
`;

const CheckDiv = styled.div`
  border-bottom: 1px solid ${theme.gray300};
  padding: 11px 14px;
  display: flex;
  flex-direction: row;
`;

const SmallDateDiv = styled.div`
  margin-left: auto;
`;

const SelectAllDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 11px 14px;
`;

const AllPayrunsButton = styled(Button)`
  margin-left: 16px !important;
  @media only screen and (max-width: 461px) {
    margin-left: 0px !important;
    margin-top: 5px !important;
  }
  width: 250px;
  height: 32px;
  padding: 4px 12px 6px 16px !important;
  text-align: start !important;
  :hover,
  :focus {
    color: ${theme.black} !important;
    border-color: ${theme.gray300} !important;
  }
`;

const SelectedPayrunsCount = styled.span`
  margin-left: 5px;
  background: ${theme.gray200};
  border-radius: 14px;
  padding: 0px 8px;
`;

const StyledDownLine = styled(DownOutlined)`
  margin-left: 90px !important;
  .anticon .anticon-down .css-d8vyo0-emotion--ReportSelector--ReportSelector {
    margin-left: 37px !important;
  }
`;
interface Props {
  onChange: (value: number[]) => void;
}
const PayrollSelector: FC<Props> = ({ onChange }) => {
  const dispatch: DispatchType = useDispatch();
  const [selectedPayrollIds, setSelectedPayrollIds] = useState<number[]>([]);
  const [payrolls, setPayrolls] = useState<PreviousPayrollI[]>([]);

  const previousPayroll = useSelector(
    (state: RootState) => state.payroll.payrollSummery,
  );
  const onCheckPayroll = () => {
    setSelectedPayrollIds(payrolls.map((item) => Number(item.id)));
  };
  useEffect(() => {
    onChange(selectedPayrollIds);
  }, [selectedPayrollIds]);

  useEffect(() => {
    dispatch(getPayrollSummary());
  }, []);

  useEffect(() => {
    if (previousPayroll && previousPayroll.previousPayroll) {
      const list = previousPayroll.previousPayroll.filter(
        (item) =>
          item.status === 'COMPLETED' &&
          !item.isArchived &&
          item.payrunType !== PayrunType.MID_CYCLE_PAYRUN,
      );
      setSelectedPayrollIds(list.map((item) => Number(item.id)));
      setPayrolls(list);
    }
  }, [previousPayroll]);

  const menu = (
    <Checkbox.Group
      value={selectedPayrollIds}
      onChange={(e) => setSelectedPayrollIds(e as number[])}>
      <StyledMenu>
        <OuterDiv onClick={(e) => e.stopPropagation()}>
          {payrolls.map((item, i) => {
            return (
              <CheckDiv key={i + 1}>
                <CheckBoxComp key={item.id} value={item.id} mt="3px" />
                <B type="b-default" pl="11px">
                  {item.name ? addDotsForLongText(item.name, 20) : '-'}
                </B>
                <SmallDateDiv>
                  <B type="b-small" color={theme.gray600}>
                    {moment(item.payrollStartDate).format('MMM D') +
                      '-' +
                      moment(item.payrollEndDate).format('MMM D')}
                  </B>
                </SmallDateDiv>
              </CheckDiv>
            );
          })}
        </OuterDiv>

        <SelectAllDiv key={1} onClick={(e) => e.stopPropagation()}>
          <B
            type="b-default"
            color={theme.contract.receiver.default}
            onClick={() => onCheckPayroll()}>
            Select All
          </B>{' '}
          <SmallDateDiv>
            <B
              type="b-default"
              color={theme.gray600}
              onClick={() => setSelectedPayrollIds([])}>
              Clear
            </B>
          </SmallDateDiv>
        </SelectAllDiv>
      </StyledMenu>
    </Checkbox.Group>
  );
  return (
    <>
      <Dropdown overlay={menu}>
        <AllPayrunsButton>
          All Payruns
          {selectedPayrollIds.length != 0 ? (
            <SelectedPayrunsCount>
              <B type="b-small">{selectedPayrollIds.length}</B>
            </SelectedPayrunsCount>
          ) : null}
          <StyledDownLine />
        </AllPayrunsButton>
      </Dropdown>
    </>
  );
};

export default PayrollSelector;
