import React, { FC } from 'react';

import { Form, Row } from 'antd';

import styled from '@emotion/styled';

import {
  Div,
  GhostButton,
  InputComp,
  PrimaryButton,
} from '../../../../../components';
import theme from '../../../../../theme';
import { EditPayslipFieldI } from '../../../../../types/payroll.types';

const AddEditLayout = styled.div`
  padding: 16px 24px;
  background: ${theme.gray50};
  .ant-form-item {
    margin-bottom: 12px;
  }
`;

const FormBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

interface AddEditPayslipFieldProps {
  setVisible: (value: boolean) => void;
  keyName?: string;
  inputValue?: string;
  onClickAdd?: (data: [string, string]) => void;
  onClickEdit?: (data: Omit<EditPayslipFieldI, 'empPayrollId'>) => void;
}

const AddEditPayslipField: FC<AddEditPayslipFieldProps> = ({
  setVisible,
  keyName,
  inputValue,
  onClickAdd,
  onClickEdit,
}) => {
  return (
    <Form
      initialValues={{
        label: keyName || '',
        value: inputValue,
      }}
      onFinish={(data) => {
        const { label, value } = data;
        if (onClickAdd) onClickAdd([label, value]);
        if (onClickEdit && keyName && inputValue)
          onClickEdit({
            existingKey: keyName,
            existingValue: inputValue,
            newKey: label,
            newValue: value,
          });
        setVisible(false);
      }}>
      <AddEditLayout>
        <FormBody>
          <Div width="200px">
            <Form.Item
              name="label"
              rules={[
                {
                  required: false,
                  message: 'Required',
                },
              ]}>
              <InputComp placeholder="Label" size="middle" />
            </Form.Item>
          </Div>
          <Div width="100px">
            <Form.Item
              name="value"
              rules={[
                {
                  required: false,
                  message: 'Required',
                },
              ]}>
              <InputComp placeholder="Value" size="middle" />
            </Form.Item>
          </Div>
        </FormBody>
        <Row justify="end">
          <GhostButton size="small" onClick={() => setVisible(false)}>
            Cancel
          </GhostButton>
          <Div ml="8px">
            <PrimaryButton htmlType="submit" size="small">
              Save
            </PrimaryButton>
          </Div>
        </Row>
      </AddEditLayout>
    </Form>
  );
};

export default AddEditPayslipField;
