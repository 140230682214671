import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { B, FooterComponent, SecondaryButton } from '../../../../components';
import GeneratedBankFilesTable from './GeneratedBankFilesTable';

const ListViewLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 40px 0px 32px;
  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`;

const Title = styled.div`
  padding-bottom: 16px;
`;

const GeneratedBankFiles: FC = () => {
  const navigate = useNavigate();
  return (
    <ListViewLayout>
      <Title>
        <B type="b-large-semibold">Generated Bank Files</B>
      </Title>
      <GeneratedBankFilesTable />
      <FooterComponent
        leftChildren={
          <SecondaryButton onClick={() => navigate('/expenses')}>
            Back
          </SecondaryButton>
        }
        fullScreen={false}
      />
    </ListViewLayout>
  );
};

export default GeneratedBankFiles;
