import styled from '@emotion/styled';

import { space, SpaceProps, border, BorderProps } from 'styled-system';

type ImageProps = React.HTMLAttributes<HTMLDivElement> &
  SpaceProps &
  BorderProps;

export const Image = styled.img<ImageProps>`
  ${space}
  ${border}
`;
