import React, { FC, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import CalculationCard from './CalculationCard';
import {
  numberThousandSeparator,
  removeCommasInNumber,
} from '../../../util/utils';

const RowSummary = styled(Row)`
  padding: 12px 32px 12px 64px;
  @media only screen and (max-width: 768px) {
    padding: 8px 10px;
    .ant-col {
      margin-bottom: 16px;
    }
  }
`;
const ColSummary = styled(Col)`
  text-align: right;
`;
interface Props extends PropsWithChildren {
  summary: {
    costToCompany: string;
    netSalary: string;
    employerEpf: string;
    employeeEpf: string;
    etf: string;
  };
  selected: string | number;
  allEmpLength?: number;
  currentExchangeRate?: number | null;
  peggedCurrency?: string | null;
}

export const USDSalarySummaryCard: FC<Props> = ({
  summary,
  children,
  selected,
  allEmpLength,
  currentExchangeRate,
  peggedCurrency,
}) => {
  return (
    <RowSummary>
      <CalculationCard
        prefix=""
        title="Employee Count"
        amount={selected}
        length={allEmpLength}
      />
      <CalculationCard
        prefix={peggedCurrency ? peggedCurrency : 'USD'}
        title="Total EPF"
        amount={
          currentExchangeRate
            ? numberThousandSeparator(
                Number(removeCommasInNumber(summary.employerEpf)) /
                  Number(currentExchangeRate),
              )
            : '-'
        }
      />
      <CalculationCard
        prefix={peggedCurrency ? peggedCurrency : 'USD'}
        title="Total ETF "
        amount={
          currentExchangeRate
            ? numberThousandSeparator(
                Number(removeCommasInNumber(summary.etf)) /
                  Number(currentExchangeRate),
              )
            : '-'
        }
      />
      <CalculationCard
        prefix={peggedCurrency ? peggedCurrency : 'USD'}
        title="Total Net Salary"
        amount={
          currentExchangeRate
            ? numberThousandSeparator(
                Number(removeCommasInNumber(summary.netSalary)) /
                  Number(currentExchangeRate),
              )
            : '-'
        }
      />
      <CalculationCard
        prefix={peggedCurrency ? peggedCurrency : 'USD'}
        title="Total Cost to Company"
        amount={
          currentExchangeRate
            ? numberThousandSeparator(
                Number(removeCommasInNumber(summary.costToCompany)) /
                  Number(currentExchangeRate),
              )
            : '-'
        }
      />
      {currentExchangeRate && (
        <CalculationCard
          prefix={peggedCurrency ? peggedCurrency : 'USD'}
          title="Exchange Rate"
          amount={(1 / Number(currentExchangeRate)).toFixed(4)}
        />
      )}
      <ColSummary xs={24} sm={24} md={12} lg={4} xl={4}>
        {children}
      </ColSummary>
    </RowSummary>
  );
};
