import { css } from '@emotion/react';
import { Divider } from 'antd';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { B, DefaultBody, EmptyPage, PrimaryButton } from '../../../components';
import { addAccountant } from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import { Permission } from '../../../types';
import { isAllowed } from '../../../util/permissionUtil';
import AccountingFirmMemberTable from './AccountingFirmsMemberTable';
import InviteAnAccountantModal from './InviteAnAccountantModal';
import styled from '@emotion/styled';
import theme from '../../../theme';
const Card = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0px 10px;
  }
`;
const Accountant = styled.div`
  @media screen and (max-width: 768px) {
    padding-bottom: 10px;
  }
`;
const ButtonRow = styled.div`
  margin-left: auto;
`;
const AccountingFirms: FC = ({}) => {
  const [visible, setVisible] = useState(false);
  const dispatch: DispatchType = useDispatch();
  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  if (!isAllowed(Permission.MANAGE_ACCOUNTANTS)) {
    return (
      <EmptyPage
        title="You don't have permission"
        content="You don't have permission to manage accountants in this organization."
        buttonLabel="Home Page"></EmptyPage>
    );
  }
  return (
    <DefaultBody>
      <Card>
        <Accountant>
          <B type="b-large-semibold">
            Invite Your Payroll Officer or Accountant
          </B>
          <B type="b-small" color={theme.gray600} mt="4px">
            Provide your accountant access to your {organizationData?.name}{' '}
            account so they can manage and process payroll.
          </B>
        </Accountant>
        {isAllowed(Permission.MANAGE_ACCOUNTANTS) && (
          <ButtonRow>
            <PrimaryButton onClick={() => setVisible(true)}>
              Invite Accountant
            </PrimaryButton>
          </ButtonRow>
        )}
      </Card>
      <Divider
        css={css`
          margin: 16px 0px 24px;
          border: 1px solid #e0e0e0;
        `}
      />
      <AccountingFirmMemberTable />
      {visible ? (
        <InviteAnAccountantModal
          visible={visible}
          handleCancel={() => {
            setVisible(false);
          }}
          onSubmit={(values) => {
            setVisible(false);
            dispatch(addAccountant(values));
          }}
        />
      ) : null}{' '}
    </DefaultBody>
  );
};
export default AccountingFirms;
