import React, { FC, useEffect, useState } from 'react';
import { Col, Form, Row, Tooltip } from 'antd';
import { SelectValue } from 'antd/lib/select';
import styled from '@emotion/styled';
import {
  AlertMessage,
  B,
  Div,
  InputComp,
  InputNumberComp,
  LabeledInputV2,
  ModalComponent,
  SelectComp,
} from '../../../../components';
import { CheckBoxComp } from '../../../../components/v2/CheckBoxComp';
import theme from '../../../../theme';
import {
  EmploymentType,
  PayDetailI,
  PayDetailSubType,
  PayrollItemI,
  PayrollItemTypeEnum,
} from '../../../../types/payroll.types';
import {
  IndividualPeopleI,
  UserPayDetailI,
} from '../../../../types/people.types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducer';
import { find, isEqual } from 'lodash';
import { Validation } from '../../../../common/validation';
import { InfoSVG } from '../../../../assets';
import { getLastJob } from '../../../../util/utils';

const PayFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const InfoIcon = styled.img`
  margin-left: 5px;
  width: 13px;
  margin-top: 5px;
`;

interface Props {
  visible: boolean;
  handleCancel: () => void;
  onSubmit: (formValues: PayDetailI) => void;
  payDetail: UserPayDetailI | null;
  readOnly?: PayrollItemTypeEnum;
  textTip?: string;
  payrollItems?: PayrollItemI[];
  applyMasterData?: boolean;
  userData?: IndividualPeopleI;
  isForeignCurrencyPayroll?: boolean;
  isEmployeeAddDetailPage?: boolean;
  isMidCyclePayrun?: boolean;
  employmentType?: string;
}

interface FormValues {
  type: string | null;
  payTitle: string | null;
  amount: number;
  isTaxable: boolean;
  isEpf: boolean;
  addToMaster: boolean;
  noOfDays: number | undefined;
}

export const RemunerationModal: FC<Props> = ({
  visible,
  handleCancel,
  onSubmit,
  payDetail,
  readOnly,
  textTip,
  payrollItems,
  applyMasterData,
  userData,
  isForeignCurrencyPayroll,
  isEmployeeAddDetailPage = false,
  isMidCyclePayrun,
  employmentType,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [payType, setPayType] = useState<PayrollItemTypeEnum | null>(null);
  const [subPayType, setSubPayType] = useState<string>(
    PayDetailSubType.DEFAULT,
  );
  const [noOfDays, setnoOfDays] = useState<number | undefined>(0);
  const [noOfDaysValidation, setNoOfDaysValidation] = useState<
    [] | '' | RegExpMatchArray | null
  >();
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>();
  const [form] = Form.useForm();

  const validateMasterPayitem = userData?.userPayItems?.find(
    (payItem) =>
      payItem.payTitle?.trim().toLowerCase() ===
      payDetail?.payTitle?.trim().toLowerCase(),
  );

  const [initialFormValues, setInitialFormValues] = useState<FormValues>();

  const [isAddToMaster, setIsAddToMaster] = useState<boolean>(
    !!validateMasterPayitem,
  );
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const deductionOptions = [
    {
      label: 'Default',
      value: PayDetailSubType.DEFAULT,
    },
    {
      label: 'No Pay Deduction',
      value: PayDetailSubType.NOPAY,
    },
  ];

  // let noOfHoursDayPattern: RegExp | undefined;
  // if (
  //   organizationData?.dailyWorkingHours &&
  //   organizationData?.monthlyWorkingDays
  // ) {
  //   noOfHoursDayPattern = OtNoPayValidation(
  //     organizationData?.dailyWorkingHours,
  //     organizationData?.monthlyWorkingDays,
  //     organizationData?.workTimeUnitType,
  //   );
  // }

  const otList: {
    value: string | number;
    label: string;
  }[] = [
    {
      label: 'Default',
      value: PayDetailSubType.DEFAULT,
    },
  ];
  if (organizationData?.otTypes && !isMidCyclePayrun) {
    organizationData?.otTypes.forEach((i) => {
      i.isEnable && otList.push({ value: i.id, label: i.otName });
    });
  }

  const onFinish = (FormValues: PayDetailI) => {
    const values = {
      ...FormValues,
    };
    const val = form.getFieldValue('subType');
    if (
      val !== PayDetailSubType.DEFAULT &&
      payType === PayrollItemTypeEnum.VARIABLE_ALLOWANCE
    ) {
      values.payTitle =
        find(organizationData?.otTypes, (o) => o.id == val)?.otName || null;
    } else {
      values.payTitle = FormValues.payTitle;
    }

    onSubmit(values);
  };

  const [payTitle, setPayTitle] = useState<string | null>(null);
  const [amount, setAmount] = useState<number>(0);
  const [isApit, setIsApit] = useState<boolean>(false);
  const [isEpfEtf, setIsEpfEtf] = useState<boolean>(false);
  const [empType, setEmpType] = useState<string>();

  useEffect(() => {
    if (employmentType) {
      setEmpType(employmentType);
    } else if (getLastJob(userData?.userHistory)?.employmentType) {
      setEmpType(getLastJob(userData?.userHistory)?.employmentType);
    }
    if (payDetail === null) {
      if (readOnly === PayrollItemTypeEnum.NON_CASH_BENEFITS) {
        form.setFieldsValue({ isEpf: undefined });
      }
      form.setFieldsValue({
        type: readOnly,
      });
      if (readOnly === PayrollItemTypeEnum.BASIC) {
        form.setFieldsValue({
          payTitle: 'Basic Salary',
        });
      }
    }
    if (payDetail?.subType === null) {
      form.setFieldsValue({
        subType: PayDetailSubType.DEFAULT,
      });
    }
    if (!payDetail?.subType) {
      form.setFieldsValue({
        subType: PayDetailSubType.DEFAULT,
      });
    }
    if (payDetail?.amount) {
      form.setFieldsValue({
        amount: organizationData?.isFourDecimalPoint
          ? Math.round(payDetail.amount * 10000) / 10000
          : Math.round(payDetail.amount * 100) / 100,
      });
    }
    if (!!validateMasterPayitem) {
      form.setFieldsValue({
        addToMaster: true,
      });
    }
    if (
      payDetail?.type === PayrollItemTypeEnum.VARIABLE_ALLOWANCE &&
      !!organizationData?.otSettings?.ot?.status
    ) {
      const val = form.getFieldValue('subType');
      const findOT = organizationData?.otTypes?.find(
        ({ otName }) => otName === val,
      );
      if (findOT) {
        form.setFieldsValue({
          subType: findOT?.id,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (payDetail) {
      setPayTitle(payDetail.payTitle);
      setAmount(payDetail.amount);
      setIsApit(payDetail.isTaxable);
      setIsEpfEtf(payDetail.isEpf);
      setPayType(payDetail.type);
      setnoOfDays(payDetail.noOfdays);
      if (payDetail.subType) {
        setSubPayType(String(payDetail.subType));
      }
    } else if (readOnly) setPayType(readOnly);
  }, [payDetail]);

  const onChange = (value: SelectValue) => {
    if (value === PayrollItemTypeEnum.BASIC) {
      form.setFieldsValue({
        payTitle: 'Basic Salary',
      });
    } else {
      form.setFieldsValue({
        payTitle: '',
      });
    }
  };

  useEffect(() => {
    setInitialFormValues({
      type: payType,
      payTitle: payTitle,
      amount: amount,
      isTaxable: isApit,
      isEpf: isEpfEtf,
      addToMaster: isAddToMaster,
      noOfDays: noOfDays,
    });
  }, [payType, payTitle, amount, isApit, isEpfEtf, isAddToMaster, noOfDays]);

  useEffect(() => {
    let currentFormValues;
    if (payDetail) {
      currentFormValues = {
        type: payDetail.type,
        payTitle: payDetail.payTitle,
        amount: payDetail.amount,
        isTaxable: payDetail.isTaxable,
        isEpf: payDetail.isEpf,
        addToMaster: !validateMasterPayitem ? false : true,
        noOfDays: payDetail.noOfdays,
      };
    }

    // Check if the current form values are the same as the initial values
    const isFormChanged = !isEqual(currentFormValues, initialFormValues);

    // // If the form is changed, enable the submit button; otherwise, disable it
    setDisableSubmitButton(!isFormChanged);
  }, [form, initialFormValues]);

  const monthPayrunOptions = [
    {
      label: 'Basic',
      value: PayrollItemTypeEnum.BASIC,
    },
    {
      label: 'Fixed Allowance',
      value: PayrollItemTypeEnum.FIXED_ALLOWANCE,
    },
    {
      label: 'Variable Allowance',
      value: PayrollItemTypeEnum.VARIABLE_ALLOWANCE,
    },
    {
      label: 'Non-Cash Benefits',
      value: PayrollItemTypeEnum.NON_CASH_BENEFITS,
    },
    {
      label: 'Deduction',
      value: PayrollItemTypeEnum.DEDUCTION,
    },
    {
      label: 'Lump Sum',
      value: PayrollItemTypeEnum.LUMP_SUM,
    },
  ];

  const midPayrunOptions = [
    {
      label: 'Variable Allowance',
      value: PayrollItemTypeEnum.VARIABLE_ALLOWANCE,
    },
    {
      label: 'Lump Sum',
      value: PayrollItemTypeEnum.LUMP_SUM,
    },
  ]

  return (
    <>
      <ModalComponent
        submitText={payDetail ? 'Save' : 'Add Pay Item'}
        form={form}
        onCancel={handleCancel}
        visible={visible}
        disabled={disableSubmitButton}
        centered
        title={
          <div
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}>
            <B type="b-large-semibold">
              {payDetail ? 'Update' : 'Add'} Pay Item
            </B>
          </div>
        }>
        <div>
          {noOfDaysValidation === null && noOfDays && showErrorMessage && (
            <Div Pb="24px">
              <AlertMessage
                title={`No of hours per day for this organisation is ${organizationData?.dailyWorkingHours}, Please adjust your No Pay and Overtime days and hours accordingly`}
                type="error"
                iconCssStyle="margin-top: -15px"
                closeCssStyle="margin-top: -15px"
                onClose={() => setShowErrorMessage(false)}
              />
            </Div>
          )}
          <B pb="16px" type="b-default">
            {textTip}
          </B>
          <Form
            // onBlur={() => {
            //   const noOfdaysVal = form.getFieldValue('noOfdays');
            //   if (noOfdaysVal && noOfHoursDayPattern) {
            //     setNoOfDaysValidation(
            //       noOfdaysVal
            //         ? String(noOfdaysVal).match(noOfHoursDayPattern)
            //         : [],
            //     );
            //     setShowErrorMessage(true);
            //   }
            // }}
            form={form}
            name="account_details"
            validateTrigger="onBlur"
            initialValues={{
              isTaxable: true,
              isEpf: true,
              addToMaster: true,
              noOfdays: payDetail?.noOfdays,
              ...payDetail,
            }}
            onFinish={onFinish}>
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Please select your Type!',
                },
              ]}>
              <SelectComp
                size="middle"
                label="Type"
                placeholder="Select Type"
                onChange={(v) => {
                  setPayType(v as PayrollItemTypeEnum);
                  onChange(v);
                }}
                allowClear
                options={
                  isMidCyclePayrun ? midPayrunOptions : monthPayrunOptions
                }
              />
            </Form.Item>

            {(payType === PayrollItemTypeEnum.DEDUCTION ||
              payType === PayrollItemTypeEnum.VARIABLE_ALLOWANCE) && (
              <>
                {!!organizationData?.leaveSettings?.noPay?.status &&
                !isEmployeeAddDetailPage &&
                payType === PayrollItemTypeEnum.DEDUCTION &&
                !organizationData?.isLeaveInPayroll ? (
                  <Form.Item name="subType">
                    <SelectComp
                      size="middle"
                      label="Sub - Type"
                      placeholder="Select Type"
                      allowClear
                      onChange={(v) => setSubPayType(String(v))}
                      options={
                        empType?.toLowerCase() !== EmploymentType.CONTRACT
                          ? deductionOptions
                          : deductionOptions.filter(
                              (option) =>
                                option.value !== PayDetailSubType.NOPAY,
                            )
                      }
                    />
                  </Form.Item>
                ) : !!organizationData?.otSettings?.ot?.status &&
                  !isEmployeeAddDetailPage &&
                  empType.toLowerCase() !== EmploymentType.CONTRACT &&
                  payType === PayrollItemTypeEnum.VARIABLE_ALLOWANCE ? (
                  <Form.Item name="subType">
                    <SelectComp
                      size="middle"
                      label="Sub - Type"
                      placeholder="Select Type"
                      allowClear
                      onChange={(v) => setSubPayType(String(v))}
                      options={otList}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item name="subType">
                    <SelectComp
                      size="middle"
                      label="Sub - Type"
                      placeholder="Select Type"
                      allowClear
                      onChange={(v) => setSubPayType(String(v))}
                      options={[
                        {
                          label: 'Default',
                          value: PayDetailSubType.DEFAULT,
                        },
                      ]}
                    />
                  </Form.Item>
                )}
              </>
            )}

            {!(
              payType === PayrollItemTypeEnum.VARIABLE_ALLOWANCE &&
              subPayType != PayDetailSubType.DEFAULT
            ) && (
              <Form.Item
                name="payTitle"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your pay item title!',
                  },
                ]}>
                <InputComp
                  size="small"
                  label="Pay Details(Title)"
                  placeholder="Pay item title"
                  onChange={(v) => setPayTitle(v.target.value)}
                />
              </Form.Item>
            )}
            {(organizationData?.leaveSettings?.noPay?.status ||
              organizationData?.otSettings?.ot?.status) && (
              <>
                {((payType === PayrollItemTypeEnum.DEDUCTION &&
                  subPayType === PayDetailSubType.NOPAY) ||
                  (payType === PayrollItemTypeEnum.VARIABLE_ALLOWANCE &&
                    subPayType != PayDetailSubType.DEFAULT)) && (
                  <>
                    <Div display="flex">
                      <LabeledInputV2
                        label={
                          payType === PayrollItemTypeEnum.DEDUCTION &&
                          subPayType === PayDetailSubType.NOPAY
                            ? 'Number of No Pay Days and Hours'
                            : 'Number of Overtime Days and Hours'
                        }
                        flexDirection="column"
                      />

                      <Tooltip
                        title="Please enter days and hours seperated by a decimal point. E.g. - 2.5 means 2 days 5 hours" //TODO have to check if the option is second tool
                        placement="right">
                        <InfoIcon src={InfoSVG} />
                      </Tooltip>
                    </Div>
                    <Form.Item
                      name="noOfdays"
                      // rules={[
                      //   {
                      //     pattern: noOfHoursDayPattern,
                      //     message: 'Invalid Days and Hours',
                      //   },
                      // ]}
                    >
                      <InputNumberComp
                        onChange={(v) => setnoOfDays(Number(v))}
                        size="middle"
                        placeholder={
                          payType === PayrollItemTypeEnum.DEDUCTION &&
                          subPayType === PayDetailSubType.NOPAY
                            ? 'Number of No Pay Days and Hours'
                            : 'Number of Overtime Days and Hours'
                        }
                      />
                    </Form.Item>
                  </>
                )}
              </>
            )}
            <Form.Item
              name="amount"
              rules={[
                {
                  required:
                    (payType === PayrollItemTypeEnum.DEDUCTION &&
                      subPayType === PayDetailSubType.NOPAY) ||
                    (payType === PayrollItemTypeEnum.VARIABLE_ALLOWANCE &&
                      subPayType != PayDetailSubType.DEFAULT)
                      ? false
                      : true,
                },
                organizationData?.isFourDecimalPoint
                  ? {
                      pattern: Validation.FourDecimalPointAmountPattern,
                      message: 'Please enter four decimal places!',
                    }
                  : {
                      pattern: Validation.AmountPattern,
                      message: 'Please enter  two decimal places!',
                    },
              ]}>
              <InputNumberComp
                size="small"
                label="Pay Amount"
                isCurrency
                placeholder="Amount"
                disabled={
                  ((payType === PayrollItemTypeEnum.DEDUCTION &&
                    subPayType === PayDetailSubType.NOPAY) ||
                    (payType === PayrollItemTypeEnum.VARIABLE_ALLOWANCE &&
                      subPayType != PayDetailSubType.DEFAULT)) &&
                  true
                }
                onChange={(v) => setAmount(Number(v))}
              />
            </Form.Item>

            {empType?.toLowerCase() !== 'contract' && (
              <Row>
                <Col span={7}>
                  <PayFormItem name="isTaxable" valuePropName="checked">
                    <CheckBoxComp
                      defaultChecked
                      onChange={(v) => setIsApit(v.target.checked)}>
                      APIT
                    </CheckBoxComp>
                  </PayFormItem>
                </Col>

                <Col span={7}>
                  <PayFormItem name="isEpf" valuePropName="checked">
                    <CheckBoxComp
                      defaultChecked
                      onChange={(v) => setIsEpfEtf(v.target.checked)}>
                      EPF/ETF
                    </CheckBoxComp>
                  </PayFormItem>
                </Col>
              </Row>
            )}

            {applyMasterData &&
              payType !== PayrollItemTypeEnum.LUMP_SUM &&
              !isForeignCurrencyPayroll && (
                <Div
                  background={theme.gray50}
                  mt="26px"
                  p="12px 15px 8px"
                  borderRadius={8}>
                  <PayFormItem name="addToMaster" valuePropName="checked">
                    <CheckBoxComp
                      defaultChecked
                      onChange={(v) => setIsAddToMaster(v.target.checked)}>
                      Apply this to master data
                    </CheckBoxComp>
                  </PayFormItem>
                  <B type="b-small" color={theme.gray600} pl="26px" pt="4px">
                    Select this option if you want to update your master data
                    as-well
                  </B>
                </Div>
              )}
          </Form>
        </div>
      </ModalComponent>
    </>
  );
};
