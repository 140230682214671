import { message } from "antd";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { LogTrailItemI } from "../store/actions";
import { GratuityDocumentI, GratuityI, GratuityNoteI, UpdateGratuityI } from "../types/gratuity.types";
import { getApiError } from "../util/getApiError";

export const useGetGratuityList = () => {
    return useQuery<GratuityI[], AxiosError>('gratuity', async () => {
        const response: AxiosResponse<GratuityI[]> = await axios.get(
            '/gratuity',
        );
        return response.data;
    },
        {
            onError: (error) => {
                message.error(getApiError(error));
            }
        });
};

export const useGetGratuityByID = () => {
    return useMutation<GratuityI, AxiosError, number>(async (id) => {
        const response: AxiosResponse<GratuityI> = await axios.get(`/gratuity/${id}`);
        return response.data;
    },
        {
            onError: (error) => {
                message.error(getApiError(error));
            }
        });
};

export const useUpdateGratuity = () => {
    return useMutation<string, AxiosError, UpdateGratuityI>(
        async ({ id, data }) => {
            const response: AxiosResponse<string> = await axios.put(
                `/gratuity/${id}`,
                data,
            );
            return response.data;
        },
        {
            onError: (error) => {
                message.error(getApiError(error));
            },
            onSuccess: () => {
                message.success('Updated Successfully');
            },
        },
    );
};

export const useAddGratuityNote = () =>
    useMutation<AxiosResponse, AxiosError, GratuityNoteI>(
        async (data: GratuityNoteI) => {
            const response: AxiosResponse = await axios.post(
                '/gratuity/addNote',
                data,
            );
            return response.data;
        },
        {
            onError: (error) => {
                message.error(getApiError(error));
            },
            onSuccess: () => {
                message.success('Note added successfully');
            },
        },
    );

export const useEditGratuityNote = () => {
    return useMutation<string, AxiosError, GratuityNoteI>(
        async ({ id, note }) => {
            const response: AxiosResponse<string> = await axios.put(
                `/gratuity/editNote/${id}`,
                { note },
            );
            return response.data;
        },
        {
            onError: (error) => {
                message.error(getApiError(error));
            },
            onSuccess: () => {
                message.success('Note updated successfully');
            },
        },
    );
};

export const useRemoveGratuityNote = () => {
    return useMutation<string, AxiosError, number>(
        async (id) => {
            const response: AxiosResponse<string> = await axios.delete(
                `/gratuity/removeNote/${id}`,
            );
            return response.data;
        },
        {
            onError: (error) => {
                message.error(getApiError(error));
            },
            onSuccess: () => {
                message.success('Note removed successfully');
            },
        },
    );
};

export const useAddGratuityDocument = () =>
    useMutation<AxiosResponse, AxiosError, GratuityDocumentI>(
        async (data: GratuityDocumentI) => {
            const formData = new FormData();
            formData.append('file', data.document);
            formData.append('name', data.name);
            formData.append('gratuityId', String(data.gratuityId));

            const response: AxiosResponse = await axios.post(
                '/gratuity/addDocument',
                formData,
            );
            return response.data;
        },
        {
            onError: (error) => {
                message.error(getApiError(error));
            },
            onSuccess: () => {
                message.success('Document added successfully');
            },
        },
    );

export const useRemoveGratuityDocument = () => {
    return useMutation<string, AxiosError, number>(
        async (id) => {
            const response: AxiosResponse<string> = await axios.delete(
                `/gratuity/removeDocument/${id}`,
            );
            return response.data;
        },
        {
            onError: (error) => {
                message.error(getApiError(error));
            },
            onSuccess: () => {
                message.success('Document removed successfully');
            },
        },
    );
};

export const useGetGratuityLogs = () =>
    useMutation<LogTrailItemI[], AxiosError>(
        'audit-logs',
        async () => {
            const response: AxiosResponse<LogTrailItemI[]> = await axios.get(
                '/gratuity/audit-logs',
            );
            return response.data;
        },
        {
            onError: (error) => {
                message.error(getApiError(error));
            },
        },
    );