import styled from '@emotion/styled';
import { Collapse } from 'antd';
import React, { FC, useState, useEffect } from 'react';
import { B, Div, LinkButton } from '../../../components';
import theme from '../../../theme';
import { AddLoanNoteI, LoanDocumentI } from '../../../types/loan.types';
import { isValidMoment } from '../../../util/isValidMoment';
import NoteItem from '../../loan/shared/NoteItem';
import { GratuityDocumentUploadModal } from './GratuityDocumentUploadModal';
import GratuityNoteForm from './GratuityNoteForm';
import { Cancel, DownloadBlack, PlusBlueSVG } from '../../../assets';

const { Panel } = Collapse;

const CollapseSection = styled(Collapse)`
  border: none;
  background-color: ${theme.white};
  .ant-collapse-header {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: ${theme.black};
    background: ${theme.gray50} !important;
    border-radius: 4px !important;
    border: 0.5px solid ${theme.gray300};
    padding: 16px 32px !important;
  }
  .ant-collapse-content {
    border-top: none;
    .ant-collapse-content-box {
      padding: 0px !important;
    }
    border: 1px solid ${theme.gray300};
    border-radius: 0px !important;
    margin-top: 4px;
  }
  .ant-collapse-item {
    padding: 0px !important;
    border-radius: 4px !important;
    margin-bottom: 24px;
    border-bottom: none;
  }
  .ant-collapse-item-active {
    padding: 0px !important;
  }
  .ant-collapse-header .ant-collapse-arrow {
    top: 17px !important;
    left: 34px !important;
    font-weight: 900 !important;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    display: inline-block !important;
    margin-right: 12px !important;
    font-size: 12px !important;
  }

  .ant-collapse-arrow svg {
    margin-top: 6px;
  }

  .ant-collapse-extra {
    margin-top: 2px;
  }

  .ant-collapse-item-active > .ant-collapse-header {
    border-radius: 4px 4px 0px 0px !important;
  }
`;

const NoteList = styled.div`
  padding: 24px 32px;
  div:first-child {
    padding-top: 0px;
  }
  div:last-child {
    padding-bottom: 0px;
    border-bottom: none;
  }
`;

const DocumentList = styled.div`
  padding: 24px 32px;
  div:first-child {
    padding-top: 0px;
  }
  div:last-child {
    padding-bottom: 0px;
    border-bottom: none;
  }
`;

const DocumentRemoveIcon = styled.img`
  cursor: pointer;
`;

interface Props {
  note?: AddLoanNoteI[];
  onEditNote: (id: string | number | undefined, data: AddLoanNoteI) => void;
  onAddNewNote: (data: AddLoanNoteI) => void;
  onRemoveNote: (id: string | number) => void;
  documents?: LoanDocumentI[];
  onSubmitDocument: (document: LoanDocumentI) => void;
  onRemoveDocument: (id: string | number) => void;
  isUserView?: boolean;
}

const GratuityNotesAndDocuments: FC<Props> = ({
  note,
  onEditNote,
  onAddNewNote,
  onRemoveNote,
  documents,
  onSubmitDocument,
  onRemoveDocument,
  isUserView,
}) => {
  const [showAddNewNote, setShowAddNewNote] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const onSubmitNote = (value: AddLoanNoteI) => {
    if (editIndex > -1) {
      onEditNote(value.id, value);
    } else {
      onAddNewNote(value);
    }
    setShowAddNewNote(false);
    setEditIndex(-1);
  };

  const onCancel = () => {
    setShowAddNewNote(false);
    setEditIndex(-1);
  };

  useEffect(() => {
    onCancel();
  }, [note]);

  return (
    <div>
      <CollapseSection>
        <Panel
          header={
            <B type="b-large" pl="32px">
              Notes
            </B>
          }
          extra={
            !isUserView && (
              <Div mt="-25px">
                <img
                  src={PlusBlueSVG}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAddNewNote(true);
                  }}
                />
              </Div>
            )
          }
          key="1">
          <NoteList>
            {note && note.length > 0
              ? note.map((item, index) => {
                  if (index === editIndex) {
                    return (
                      <Div>
                        <GratuityNoteForm
                          editItem={item}
                          onSubmit={(v) => onSubmitNote(v)}
                          onCancel={() => onCancel()}
                        />
                      </Div>
                    );
                  }
                  return (
                    <NoteItem
                      isUserView={isUserView}
                      item={item}
                      onClickEdit={() => setEditIndex(index)}
                      onRemove={() => {
                        if (item.id) {
                          onRemoveNote(item.id);
                        }
                      }}
                    />
                  );
                })
              : !showAddNewNote && (
                  <Div
                    height={124}
                    background={theme.gray50}
                    display="flex"
                    borderRadius={4}>
                    <Div m="auto">
                      <B type="b-default" color={theme.gray600}>
                        You don't have any notes yet.
                      </B>
                      {!isUserView && (
                        <Div display="flex" justifyContent="center">
                          <LinkButton
                            icon={<img src={PlusBlueSVG} />}
                            onClick={() => setShowAddNewNote(true)}>
                            Add Notes
                          </LinkButton>
                        </Div>
                      )}
                    </Div>
                  </Div>
                )}
            {showAddNewNote && (
              <GratuityNoteForm
                onSubmit={(v) => onSubmitNote(v)}
                onCancel={() => setShowAddNewNote(false)}
              />
            )}
          </NoteList>
        </Panel>
        <Panel
          header={
            <B type="b-large" pl="32px">
              Documents
            </B>
          }
          extra={
            !isUserView && (
              <Div mt="-25px">
                <img
                  src={PlusBlueSVG}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDocumentModal(true);
                  }}
                />
              </Div>
            )
          }
          key="2">
          <DocumentList>
            {documents && documents.length > 0 ? (
              documents.map((item) => {
                if (item) {
                  return (
                    <Div
                      display="flex"
                      justifyContent="space-between"
                      borderBottom={`1px solid ${theme.gray300}`}
                      p="16px 0px">
                      <div>
                        <B type="b-default">{item.name}</B>
                      </div>
                      <Div display="flex">
                        <B type="b-default" color={theme.gray600} pr="22px">
                          {isValidMoment(
                            item.updatedAt,
                            undefined,
                            'DD MMM YYYY',
                          ).toString()}
                        </B>
                        <Div pr="24px">
                          <a href={item.fileUrl} download>
                            <img src={DownloadBlack} />
                          </a>
                        </Div>
                        {!isUserView && (
                          <DocumentRemoveIcon
                            src={Cancel}
                            onClick={() => {
                              if (item.id) {
                                onRemoveDocument(item.id);
                              }
                            }}
                          />
                        )}
                      </Div>
                    </Div>
                  );
                }
              })
            ) : (
              <Div
                height={124}
                background={theme.gray50}
                display="flex"
                borderRadius={4}>
                <Div m="auto">
                  <B type="b-default" color={theme.gray600}>
                    You don't have any documents yet.
                  </B>
                  {!isUserView && (
                    <Div display="flex" justifyContent="center">
                      <LinkButton
                        icon={<img src={PlusBlueSVG} />}
                        onClick={() => setShowDocumentModal(true)}>
                        Add Documents
                      </LinkButton>
                    </Div>
                  )}
                </Div>
              </Div>
            )}
          </DocumentList>
        </Panel>
        {showDocumentModal && (
          <GratuityDocumentUploadModal
            visible={showDocumentModal}
            handleCancel={() => setShowDocumentModal(false)}
            onSubmit={(document) => {
              onSubmitDocument(document);
              setShowDocumentModal(false);
            }}
          />
        )}
      </CollapseSection>
    </div>
  );
};

export default GratuityNotesAndDocuments;
