import React, { FC, useState } from 'react';
import { Popover } from 'antd';
import theme from '../theme';
import styled from '@emotion/styled';
import { Div } from './Div';
import { Link } from 'react-router-dom';
import { B } from './B';
import { Cancel } from '../assets';

interface PopOverProps {
  content: string;
  messagesTwoDigits: string;
  subMenuPath: string;
}

const CloseIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const StyledNumberOfMessages = styled.span`
  display: flex;
  text-align: center;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  background-color: ${theme.white};
  border-radius: 90%;
  font-weight: normal;
  font-size: 10px;
  color: ${theme.blue900};
`;

export const PopOverComponent: FC<PopOverProps> = ({
  content,
  messagesTwoDigits,
  subMenuPath,
}) => {
  const [open, setOpen] = useState(true);

  const buttons = (
    <Div pt="4px" pb="8px" pr="0px" display="flex">
      <Link to={subMenuPath} onClick={() => setOpen(false)}>
        <B
          type="b-default"
          pl="4px"
          pr="16px"
          color={theme.contract.receiver.default}>
          View
        </B>
      </Link>

      <a onClick={() => setOpen(false)}>
        <B type="b-default" color={theme.gray600}>
          Cancel
        </B>
      </a>
    </Div>
  );

  const titleContent = (
    <>
      <CloseIcon
        src={Cancel}
        height={16}
        width={16}
        onClick={() => setOpen(false)}
      />
      <B type="b-default" pt="15px" pl="4px">
        {content}
      </B>
    </>
  );

  return (
    <Popover
      open={open}
      placement="rightTop"
      title={titleContent}
      content={buttons}
      trigger="hover"
      onOpenChange={() => setOpen(true)}
      color={theme.lightBlue50}
      overlayInnerStyle={{
        padding: '0px',
        width: '277px',
        minHeight: '96px',
      }}>
      <Div
        alignItems="center"
        justifyContent="center"
        display="inline-flex"
        width="20px"
        height="20px"
        ml="16px"
        color={theme.blue900}>
        <StyledNumberOfMessages>{messagesTwoDigits}</StyledNumberOfMessages>
      </Div>
    </Popover>
  );
};
