import React, { FC } from 'react';
import { useParams } from 'react-router';
import { B, Div } from '../../../../components';
import theme from '../../../../theme';
import { EmployeePaySlipSummary } from './utils/payslipPageTypes';
import { getEmployeeSelectorOption } from './utils/getEmployeeSelectorOption';
interface Props {
  employees: EmployeePaySlipSummary[];
}

type RouteParams = {
  employeeKey: string;
};

export const Attendance: FC<Props> = ({ employees }) => {
  const params = useParams<RouteParams>();
  const options = getEmployeeSelectorOption(employees);
  const emp =
    options.find((option) => option.id == Number(params.employeeKey)) || null;

  if (!emp) {
    return null;
  }
  return (
    <>
      {emp?.attendance && (
        <Div
          border={`1px solid ${theme.gray300}`}
          background={theme.warn}
          p="12px 17px"
          mb={24}
          borderRadius="4px">
          <B type="b-large-semibold">Attendance: {emp?.attendance}</B>
        </Div>
      )}
    </>
  );
};
