import { Form } from 'antd';
import React, { FC } from 'react';
import { B, InputComp } from '../../../components';
import { ModalComponent } from '../../../components/ModalComponent';
import { Permission } from '../../../types';
import { AddAccountantI } from '../../../types/people.types';
import { isAllowed } from '../../../util/permissionUtil';
import theme from '../../../theme';
interface Props {
  visible: boolean;
  handleCancel: () => void;
  onSubmit: (formValues: AddAccountantI) => void;
}
const InviteAnAccountantModal: FC<Props> = ({
  visible,
  handleCancel,
  onSubmit,
}) => {
  const [form] = Form.useForm();
  function onFinish(FormValues: AddAccountantI) {
    const values = {
      ...FormValues,
    };
    onSubmit(values);
  }
  if (!isAllowed(Permission.MANAGE_ACCOUNTANTS)) {
    return null;
  }
  return (
    <>
      <ModalComponent
        form={form}
        onCancel={handleCancel}
        submitText="Invite"
        width={540}
        visible={visible}
        title={<B type="b-large-semibold">Invite an Accountant</B>}>
        <Form
          form={form}
          name="accountant_details"
          validateTrigger="onBlur"
          onFinish={onFinish}
          initialValues={{
            isAccountAdmin: false,
          }}>
          <div>
            <B type="b-default" color={theme.gray600} mb="24px">
              Once they accept your invitation, they can manage and process
              payroll, EPF, ETF, payslips and more.
            </B>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'This email is not a valid accountant in our system',
                },
                {
                  type: 'email',
                  message: 'Enter a valid email',
                },
              ]}>
              <InputComp placeholder="devid@petersfirm.com" label="Email" />
            </Form.Item>
          </div>
        </Form>
      </ModalComponent>
    </>
  );
};
export default InviteAnAccountantModal;
