import React, { FC, useEffect } from 'react';
import { Popover } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import styled from '@emotion/styled';
import { B, Div, H } from '../../../../components';
import TableComp from '../../../../components/TableComp';
import { useWindowSize } from '../../../../util/screenSize';
import { addDotsForLongText, isHaveThreeDots } from '../../../../util/utils';
import { getDesignation, getPositionLevel } from '../../../people/PeopleUtil';
import { useGetAllPeople } from '../../../../api/peopleHooks';
import { PeopleI } from '../../../../types/people.types';

const FirstColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;
const MainContainer = styled.div`
  flex-direction: column;
  background-color: #ffffff;
  background-clip: border-box;
  padding: 32px;
  @media (max-width: 760px) {
    padding: 32px 10px;
  }
`;
const CustomizationTable: FC = () => {
  const { isDesktop, isDesktopLarge } = useWindowSize();
  const { data: allPeoples, mutate: fetchPeople } = useGetAllPeople();

  useEffect(() => {
    fetchPeople();
  }, []);

  const columns: ColumnProps<PeopleI>[] = [
    {
      title: 'Employee name',
      dataIndex: 'fullName',
      width: isDesktop ? 400 : 0,
      render: (value: string) => (
        <>
          <FirstColumn>
            {isHaveThreeDots(value, 30) ? (
              <Popover content={value} trigger="hover" placement="topLeft">
                <B type="b-small">
                  {value
                    ? addDotsForLongText(value, isDesktopLarge ? 50 : 30)
                    : '-'}
                </B>
              </Popover>
            ) : (
              <B type="b-small">{value ? value : '-'}</B>
            )}
          </FirstColumn>
        </>
      ),
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      width: isDesktop ? 200 : 0,
      render: (_value: string, data) => (
        <B type="b-small">
          {data.userHistory ? getDesignation(data.userHistory) : '-'}
        </B>
      ),
    },
    {
      title: 'Position Levels',
      dataIndex: 'positionLevels',
      width: isDesktop ? 200 : 0,
      render: (_value: string, data) => (
        <B type="b-small">
          {data.userHistory ? getPositionLevel(data.userHistory) : '-'}
        </B>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: isDesktop ? 200 : 0,
      render: (_value: string, data) => (
        <B type="b-small">{data.role?.role ? data.role?.role : '-'}</B>
      ),
    },
    {
      title: ' Teams ',
      dataIndex: ' teams',
      width: isDesktop ? 200 : 0,
      render: (_value: string, data) => (
        <B type="b-small">{data.teams ? data.teams : '-'}</B>
      ),
    },
  ];

  return (
    <MainContainer>
      <Div marginBottom="32px">
        <H type="h6">Employee Details</H>
      </Div>
      <TableComp
        size="middle"
        scroll={{
          x: 800,
        }}
        columns={columns}
        dataSource={allPeoples}
        pagination={false}
        withBorders={true}
      />
    </MainContainer>
  );
};
export default CustomizationTable;
