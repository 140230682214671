import React, { FC, useEffect, useState } from 'react';
import { Image, Layout as AntLayout, Menu, Popover } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import styled from '@emotion/styled';
import { CustomIcon, Div } from '../../../components';
import { RootState } from '../../../store/reducer';
import theme from '../../../theme';
import { Permission } from '../../../types';
import { useGetMenuItems } from '../../../util/useGetMenuItems';
import BrandLogo from '../shared/BrandLogo';
import { contractEnabledOrg } from '../../../configs';
import { validateOrganizationIDs } from '../../../util/validateOrganizationIDs.util';
import { enableLoans } from '../../../util/permissionUtil';
import { SubscriptionEnum } from '../../../types/subscription.types';
import { PopOverComponent } from '../../../components/PopOverComponent';
import { useGetMessages } from '../../../api/organizationHooks';
import { Cancel, NavIconLeft, NavIconRight } from '../../../assets';

const { Sider } = AntLayout;
const { SubMenu } = Menu;

const SIDER_WIDTH = 260;
const SIDER_COLLAPSED_WIDTH = 88;

interface Props {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

const SubMenuItem = styled(Menu.Item)<{
  path: string;
  location: any;
  collapsed: boolean;
}>`
  background-color: ${({ path, location }) =>
    path.includes(location.pathname + location.search)
      ? 'rgba(255, 255, 255, 0.1) !important'
      : 'none'};
  margin-left: ${({ collapsed }) => (collapsed ? '0px' : '24px !important')};
  padding-left: 10px;
  position: absolute;

  font-size: 14px;
  height: 44px !important;
  border-radius: 8px;
  width: 176px !important;
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 44px !important;
  }
`;
const CustomSubMenu = styled(SubMenu)<{ collapsed: boolean }>`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;
  ul {
    background: transparent !important;
  }

  .ant-menu-submenu-title {
    padding-left: ${({ collapsed }) =>
      collapsed ? '16px' : '16px'} !important;
    padding-top: 2px !important;
    margin: 0px;
    height: 44px !important;
  }
  .anticon {
    margin-right: 14px;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    background-color: ${theme.black};
  }
`;
const CustomSider = styled(Sider)<{ collapsed: boolean }>`
  overflow: auto !important;
  overflow-x: hidden !important;
  height: 100vh !important;
  left: 0 !important;
  // top: 0 !important;
  position: fixed !important;
  z-index: 1000 !important;
  background-color: ${theme.blue900} !important;
  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background: ${theme.blue900};
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    width: ${({ collapsed }) => (collapsed ? '84px' : '241px')};
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-item {
    padding: 2px 0px 0px 10px !important;
    height: 44px;
  }
`;
const CustomMenuItem = styled(Menu.Item)<{ path: string; location: any }>`
  background-color: ${({ path, location }) =>
    path.includes(location.pathname + location.search)
      ? 'rgba(255, 255, 255, 0.1) !important'
      : 'none'};

  border-radius: 8px;
  font-size: 16px;
  margin: 0px !important;

  .ant-image-img {
    margin-bottom: 5px;
  }
`;
const CollapseDiv = styled.div<{ collapsed: boolean }>`
  position: fixed;
  padding: ${({ collapsed }) =>
    collapsed ? ' 40px 0px 0px 75px' : ' 40px 0px 0px 246px'};

  &:hover {
    cursor: pointer;
  }
`;

const MenuItemImage = styled(Image)<{ collapsed: boolean }>`
  margin: ${({ collapsed }) => (collapsed ? '' : '2px 0px 0px 6px')};
  .ant-image-img {
    margin-bottom: 5px;
  }
`;

const StyledNumberOfMessages = styled.span`
  display: flex;
  text-align: center;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  background-color: ${theme.white};
  border-radius: 90%;
  font-weight: normal;
  font-size: 10px;
  color: ${theme.blue900};
`;

const StyledCustomIcon = styled(CustomIcon)`
  margin-left: 8px;
`;

interface StyledSPanProps {
  menuItem: {
    path: string;
    displayName: string;
  };
  location: {
    pathname: string;
    search: string;
  };
  theme?: {
    white: string;
  };
}

const StyledSpan = styled.span<StyledSPanProps>`
  padding-left: 32px;
  color: ${({ menuItem, location, theme }) =>
    menuItem.path.includes(location.pathname + location.search)
      ? theme.white
      : ''};
`;

interface StyledSubmenuDisplayNameProps {
  collapsed: boolean;
  location: {
    pathname: string;
    search: string;
  };
  subMenu: {
    path: string;
  };
  theme?: {
    white: string;
  };
}
const StyledSubmenuDisplayName = styled.span<StyledSubmenuDisplayNameProps>`
  padding-left: ${({ collapsed }) => (collapsed ? '0px' : '16px')};
  color: ${({ location, subMenu }) =>
    location.pathname + location.search === subMenu.path ? theme.white : ''};
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const DesktopSideMenu: FC<Props> = ({ collapsed, setCollapsed }) => {
  const menuItems = useGetMenuItems();
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState<string>('');
  const [isSuspend, setISSuspend] = useState<boolean>(false);
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false);
  const [open, setOpen] = useState(true);

  const hide = () => {
    setOpen(false);
  };

  const { data: messages = [] } = useGetMessages();
  const systemMessages = messages;

  const messagesCount = systemMessages.length;

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );
  const me = useSelector((state: RootState) => state.people.me);

  useEffect(() => {
    if (me) {
      setISSuspend(me.subscriptionStatus === SubscriptionEnum.SUSPENDED);
    }
  }, [me]);

  useEffect(() => {
    setActiveMenu(getCurrentActiveMenu());
  }, [menuItems, location]);

  function getCurrentActiveMenu() {
    const currentPath = menuItems.find(
      (item) => item.path === location.pathname,
    );
    return currentPath?.displayName || '';
  }

  function getIsSettingOpen(
    subMenus: {
      displayName: string;
      path: string;
      type?: 'DATA_IMPORT' | undefined;
      permissions?: Permission[] | undefined;
    }[],
  ) {
    const isExist = subMenus.find(
      (subMenu) => location.pathname + location.search === subMenu.path,
    );
    return isExist ? true : false;
  }

  const messagesTwoDigits = String(messagesCount).padStart(2, '0');
  const titleContent = (
    <>
      <CloseIcon
        src={Cancel}
        height={16}
        width={16}
        onClick={(e) => {
          e.preventDefault();
          hide();
        }}
      />
      <p style={{ marginLeft: '4px', marginTop: '15px', marginBottom: '0px' }}>
        {`You have ${messagesTwoDigits} new messages`}
      </p>
    </>
  );

  return (
    <div style={{ width: collapsed ? SIDER_COLLAPSED_WIDTH : SIDER_WIDTH }}>
      <CustomSider
        width={SIDER_WIDTH}
        collapsedWidth={SIDER_COLLAPSED_WIDTH}
        trigger={null}
        collapsible
        collapsed={collapsed}>
        <BrandLogo title={organizationData?.name || ''} collapsed={collapsed} />

        <CollapseDiv
          collapsed={collapsed}
          onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <img src={NavIconRight} /> : <img src={NavIconLeft} />}
        </CollapseDiv>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[activeMenu]}
          style={{ marginTop: 120, marginRight: 0, padding: '0px 16px' }}
          onMouseEnter={(e) => e.stopPropagation()}
          onClick={(event) => {
            setActiveMenu(event.key as any);
          }}>
          {menuItems.map((menuItem) =>
            !menuItem.subMenus?.length ? (
              menuItem.displayName === 'Contracts' ? (
                validateOrganizationIDs(contractEnabledOrg) ? (
                  <CustomMenuItem
                    disabled={true}
                    path={menuItem.path}
                    location={location}
                    key={menuItem.displayName}
                    title={menuItem.displayName}>
                    <Link to={menuItem.path}>
                      <MenuItemImage
                        width={18}
                        height={18}
                        preview={false}
                        collapsed={collapsed}
                        src={
                          menuItem.path.includes(
                            location.pathname + location.search,
                          )
                            ? require(`../../../assets/Icons/menu-icons/${menuItem.icon}`)
                            : require(`../../../assets/Icons/menu-icons/${menuItem.iconUnselected}`)
                        }
                      />

                      <StyledSpan
                        menuItem={menuItem}
                        location={location}
                        theme={theme}>
                        {collapsed || menuItem.displayName}
                      </StyledSpan>
                    </Link>
                  </CustomMenuItem>
                ) : null
              ) : menuItem.displayName === 'Loan' ? (
                enableLoans() ? (
                  <CustomMenuItem
                    disabled={isSuspend}
                    path={menuItem.path}
                    location={location}
                    key={menuItem.displayName}
                    title={menuItem.displayName}>
                    <Link to={menuItem.path}>
                      <MenuItemImage
                        width={18}
                        height={18}
                        preview={false}
                        collapsed={collapsed}
                        src={
                          menuItem.path.includes(
                            location.pathname + location.search,
                          )
                            ? require(`../../../assets/Icons/menu-icons/${menuItem.icon}`)
                            : require(`../../../assets/Icons/menu-icons/${menuItem.iconUnselected}`)
                        }
                      />
                      <StyledSpan
                        menuItem={menuItem}
                        location={location}
                        theme={theme}>
                        {collapsed || menuItem.displayName}
                      </StyledSpan>
                    </Link>
                  </CustomMenuItem>
                ) : null
              ) : (
                <CustomMenuItem
                  disabled={isSuspend}
                  path={menuItem.path}
                  location={location}
                  key={menuItem.displayName}
                  title={menuItem.displayName}>
                  <Link to={menuItem.path}>
                    <MenuItemImage
                      width={18}
                      height={18}
                      preview={false}
                      collapsed={collapsed}
                      src={
                        menuItem.path.includes(
                          location.pathname + location.search,
                        )
                          ? require(`../../../assets/Icons/menu-icons/${menuItem.icon}`)
                          : require(`../../../assets/Icons/menu-icons/${menuItem.iconUnselected}`)
                      }
                    />
                    <StyledSpan
                      menuItem={menuItem}
                      location={location}
                      theme={theme}>
                      {collapsed || menuItem.displayName}
                    </StyledSpan>
                  </Link>
                </CustomMenuItem>
              )
            ) : (
              <CustomSubMenu
                disabled={isSuspend}
                collapsed={collapsed}
                key={menuItem.displayName}
                title={
                  menuItem.displayName === 'Settings' &&
                  messagesCount > 0 &&
                  !collapsed &&
                  !subMenuOpen ? (
                    <span>
                      {menuItem.displayName}
                      <PopOverComponent
                        content={`You have ${messagesTwoDigits} new messages`}
                        messagesTwoDigits={messagesTwoDigits}
                        subMenuPath={
                          menuItem.subMenus[menuItem.subMenus.length - 1].path
                        }
                      />
                    </span>
                  ) : (
                    menuItem.displayName
                  )
                }
                onTitleClick={() => {
                  if (!collapsed && messagesCount === 0) {
                    setSubMenuOpen(!subMenuOpen);
                  }
                }}
                icon={
                  menuItem.displayName === 'Settings' &&
                  messagesCount > 0 &&
                  collapsed ? (
                    <span
                      style={{
                        margin: '0px',
                        padding: '0px',
                        display: 'inline-flex',
                      }}>
                      <StyledCustomIcon
                        onMouseEnter={() => {
                          hide();
                        }}
                        icon={
                          getIsSettingOpen(menuItem.subMenus)
                            ? `menu-icons/${menuItem.icon}`
                            : `menu-icons/${menuItem.iconUnselected}`
                        }
                      />
                      <Popover
                        align={{ offset: [-24] }}
                        overlayStyle={{ left: '-24px !important' }}
                        trigger="hover"
                        visible={open}
                        placement="rightTop"
                        title={titleContent}
                        color={theme.lightBlue50}
                        overlayInnerStyle={{
                          padding: '0px',
                          width: '277px',
                          minHeight: '116px',
                        }}
                        content={
                          <Div pt="4px" pb="8px" pr="0px">
                            <Link
                              to={
                                menuItem.subMenus[menuItem.subMenus.length - 1]
                                  .path
                              }
                              onClick={hide}>
                              <a
                                style={{
                                  marginLeft: '4px',
                                  marginRight: '16px',
                                  color: theme.contract.receiver.default,
                                }}>
                                View
                              </a>
                            </Link>
                            <a
                              style={{ color: theme.gray600 }}
                              onClick={(e) => {
                                e.preventDefault();
                                hide();
                              }}>
                              Cancel
                            </a>
                          </Div>
                        }>
                        <span
                          style={{
                            height: '1px',
                            width: '1px',
                            marginLeft: '20px',
                            left: '20px',
                          }}>
                          {' '}
                        </span>
                      </Popover>
                    </span>
                  ) : (
                    <StyledCustomIcon
                      icon={
                        getIsSettingOpen(menuItem.subMenus)
                          ? `menu-icons/${menuItem.icon}`
                          : `menu-icons/${menuItem.iconUnselected}`
                      }
                    />
                  )
                }>
                {menuItem.subMenus.map((subMenu) => {
                  if (
                    subMenu.type === 'DATA_IMPORT' &&
                    !organizationData?.dataExtractorTypes
                  ) {
                    return null;
                  }
                  return (
                    <SubMenuItem
                      disabled={isSuspend}
                      collapsed={collapsed}
                      path={subMenu.path}
                      key={subMenu.displayName}
                      location={location}
                      onMouseLeave={() => setOpen(false)}>
                      <Link to={subMenu.path}>
                        {subMenu.displayName === 'System Updates' ? (
                          <StyledSubmenuDisplayName
                            collapsed={collapsed}
                            location={location}
                            subMenu={subMenu}>
                            {subMenu.displayName}
                            {messagesCount > 0 && (
                              <Div
                                alignItems="center"
                                justifyContent="center"
                                display="inline-flex"
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  marginLeft: '12px',
                                  marginRight: '2px',
                                }}>
                                <StyledNumberOfMessages>
                                  {messagesTwoDigits}
                                </StyledNumberOfMessages>
                              </Div>
                            )}
                          </StyledSubmenuDisplayName>
                        ) : (
                          <StyledSubmenuDisplayName
                            collapsed={collapsed}
                            location={location}
                            subMenu={subMenu}>
                            {subMenu.displayName}
                          </StyledSubmenuDisplayName>
                        )}
                      </Link>
                    </SubMenuItem>
                  );
                })}
              </CustomSubMenu>
            ),
          )}
        </Menu>
      </CustomSider>
    </div>
  );
};

export default DesktopSideMenu;
