import { Checkbox } from 'antd';
import styled from '@emotion/styled';
import { space, SpaceProps } from 'styled-system';
import { CheckboxProps } from 'antd/lib/checkbox';

type Props = CheckboxProps & SpaceProps;

export const CheckBoxComp = styled(Checkbox)<Props>`
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #0052ea;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ebf1fd;
    border-color: #0052ea;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid #0052ea;
    border-top: 0;
    border-left: 0;
  }
  .ant-checkbox-checked::after {
    border-radius: 4px;
  }
  span.ant-checkbox.ant-checkbox-checked:hover {
    border: none;
    border-radius: 4px;
  }
  ${space}
`;
