export const INDUSTRIES = [
  {
    label: 'Agriculture',
    value: 'Agriculture',
  },
  {
    label: 'Forestry',
    value: 'Forestry',
  },
  {
    label: 'Fishing and Hunting	 Mining',
    value: 'Fishing and Hunting	 Mining',
  },
  {
    label: 'Utilities',
    value: 'Utilities',
  },
  {
    label: 'Construction',
    value: 'Construction',
  },
  {
    label: 'Computer and Electronics',
    value: 'Computer and Electronics',
  },
  {
    label: 'Wholesale ',
    value: 'Wholesale ',
  },
  {
    label: 'Retail',
    value: 'Retail',
  },
  {
    label: 'Transportation and Warehousing',
    value: 'Transportation and Warehousing',
  },
  {
    label: 'Software',
    value: 'Software',
  },
  {
    label: 'Telecommunications',
    value: 'Telecommunications',
  },
  {
    label: 'Broadcasting',
    value: 'Broadcasting',
  },
  {
    label: 'Other Information Industry',
    value: 'Other Information Industry',
  },
  {
    label: 'Finance and Insurance',
    value: 'Finance and Insurance',
  },
  {
    label: 'Real Estate',
    value: 'Real Estate',
  },
  {
    label: 'Rental and Leasing',
    value: 'Rental and Leasing',
  },
  {
    label: 'Education',
    value: 'Education',
  },
  {
    label: 'Health Care ',
    value: 'Health Care',
  },
  {
    label: 'Entertainment',
    value: 'Entertainment',
  },
  {
    label: 'Hotel and Food Services',
    value: 'Hotel and Food Services',
  },
  {
    label: 'Government or Public Administration',
    value: 'Government or Public Administration',
  },
  {
    label: 'Legal Services',
    value: 'Legal Services',
  },
  {
    label: 'Scientific or Technical Services',
    value: 'Scientific or Technical Services',
  },
  {
    label: 'Homemaker',
    value: 'Homemaker',
  },
  {
    label: 'Religious',
    value: 'Religious',
  },
  {
    label: 'Other Industry',
    value: 'Other Industry',
  },
];
