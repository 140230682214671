import { message } from 'antd';
import axios from 'axios';

import { LeaveBalance, LeaveBalancesI, OrganizationLeaveI } from '../../types';
import { Permission } from '../../types';
import { isAllowed, isManager } from '../../util/permissionUtil';
import showApiError from '../../util/showApiError';

const ORGANIZATION_LEAVES = 'ORGANIZATION_LEAVE';
const ORGANIZATION_LEAVES_LOADING = 'ORGANIZATION_LEAVE_LOADING';
const MY_LEAVES = 'MY_LEAVES';
const MY_LEAVES_LOADING = 'MY_LEAVES_LOADING';
const LEAVE_REQUEST_LOADING = 'LEAVE_REQUEST_LOADING';
const RESPONSE_STATUS = 'RESPONSE_STATUS';
const LEAVES_BALANCE_ME = 'LEAVES_BALANCE_ME';
const LEAVES_BALANCE_ME_LOADING = 'LEAVES_BALANCE_ME_LOADING';
const LEAVES_BALANCE_USER = 'LEAVES_BALANCE_USER';
const LEAVES_BALANCE_USER_LOADING = 'LEAVES_BALANCE_USER_LOADING';
const LEAVES_BALANCES = 'LEAVES_BALANCES';
const LEAVES_BALANCES_LOADING = 'LEAVES_BALANCES_LOADING';

interface InitialStateI {
  organizationLeaves: OrganizationLeaveI[];
  organizationLeavesLoading: boolean;
  myLeaves: OrganizationLeaveI[];
  myLeavesLoading: boolean;
  meLeaveRequest: string | null;
  adminLeaveRequest: string | null;
  LeaveRequestLoading: boolean;
  response_status: any;
  leavesBalanceMe: LeaveBalance[];
  leavesBalanceMeLoading: boolean;
  leavesBalanceUser: LeaveBalance[] | null;
  leavesBalanceUserLoading: boolean;
  leaveBalancesLoading: boolean;
  leaveBalances: LeaveBalancesI[];
}

const initialState: InitialStateI = {
  organizationLeaves: [],
  organizationLeavesLoading: false,
  myLeaves: [],
  myLeavesLoading: false,
  meLeaveRequest: null,
  adminLeaveRequest: null,
  LeaveRequestLoading: false,
  response_status: 0,
  leavesBalanceMe: [],
  leavesBalanceMeLoading: false,
  leavesBalanceUser: null,
  leavesBalanceUserLoading: false,
  leaveBalancesLoading: false,
  leaveBalances: [],
};

export default function reducer(
  state: InitialStateI = initialState,
  action: any,
): InitialStateI {
  switch (action.type) {
    case ORGANIZATION_LEAVES: {
      return {
        ...state,
        organizationLeaves: action.payload,
      };
    }
    case ORGANIZATION_LEAVES_LOADING: {
      return {
        ...state,
        organizationLeavesLoading: action.payload,
      };
    }
    case MY_LEAVES: {
      return {
        ...state,
        myLeaves: action.payload,
      };
    }
    case MY_LEAVES_LOADING: {
      return {
        ...state,
        myLeavesLoading: action.payload,
      };
    }
    case RESPONSE_STATUS: {
      return {
        ...state,
        response_status: action.payload,
      };
    }
    case LEAVE_REQUEST_LOADING: {
      return {
        ...state,
        LeaveRequestLoading: action.payload,
      };
    }
    case LEAVES_BALANCE_ME: {
      return {
        ...state,
        leavesBalanceMe: action.payload,
        leavesBalanceMeLoading: false,
      };
    }
    case LEAVES_BALANCE_ME_LOADING: {
      return {
        ...state,
        leavesBalanceMeLoading: action.payload,
      };
    }
    case LEAVES_BALANCE_USER: {
      return {
        ...state,
        leavesBalanceUser: action.payload,
        leavesBalanceUserLoading: false,
      };
    }
    case LEAVES_BALANCE_USER_LOADING: {
      return {
        ...state,
        leavesBalanceUserLoading: action.payload,
      };
    }
    case LEAVES_BALANCES: {
      return {
        ...state,
        leaveBalances: action.payload,
        leaveBalancesLoading: false,
      };
    }
    case LEAVES_BALANCES_LOADING: {
      return {
        ...state,
        leaveBalancesLoading: action.payload,
      };
    }
    default:
      return state;
  }
}

export function clearResponseState() {
  return async (dispatch: any) => {
    dispatch({ type: RESPONSE_STATUS, payload: 0 });
  };
}

export function getAllOrganizationLeaves(filter?: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: ORGANIZATION_LEAVES_LOADING, payload: true });
      const res = await axios.get('/Leaves/organization', {
        params: {
          filter: filter ? filter : null,
        },
      });
      dispatch({ type: ORGANIZATION_LEAVES, payload: res.data });
      dispatch({ type: ORGANIZATION_LEAVES_LOADING, payload: false });
    } catch (error: any) {
      dispatch({ type: ORGANIZATION_LEAVES_LOADING, payload: false });
      showApiError(error);
    }
  };
}

export function getMyLeaves() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: MY_LEAVES_LOADING, payload: true });
      const res = await axios.get('/Leaves/myLeaves');
      dispatch({ type: MY_LEAVES, payload: res.data });
      dispatch({ type: MY_LEAVES_LOADING, payload: false });
    } catch (error: any) {
      dispatch({ type: MY_LEAVES_LOADING, payload: false });
      showApiError(error);
    }
  };
}

export function deleteLeave(leaveId: string) {
  return async (dispatch: any) => {
    try {
      await axios.put('/Leaves/cancel/me', { leaveId });
      message.success('Successfully deleted the Leave..');
      if (isAllowed(Permission.MANAGE_ASSIGNED_USERS)) {
        dispatch(getAllOrganizationLeaves());
      }
      dispatch(getMyLeaves());
      dispatch(leavesBalanceMe());
    } catch (error: any) {
      showApiError(error);
    }
  };
}

export function deleteLeaveUser(userId: string, leaveId: string) {
  return async (dispatch: any) => {
    try {
      await axios.put('/Leaves/cancel/user', { userId, leaveId });
      message.success('Successfully canceled the Leave..');
      if (isAllowed(Permission.MANAGE_ASSIGNED_USERS) || isManager()) {
        dispatch(getAllOrganizationLeaves());
      }
      dispatch(getMyLeaves());
      dispatch(leavesBalanceMe());
    } catch (error: any) {
      showApiError(error);
    }
  };
}

export function leavesBalanceMe() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LEAVES_BALANCE_ME_LOADING, payload: true });
      const res = await axios.get('/Leaves/overview/me');
      dispatch({ type: LEAVES_BALANCE_ME, payload: res.data });
      dispatch({ type: LEAVES_BALANCE_ME_LOADING, payload: false });
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        message.error(error.response.data.message);
      } else {
        showApiError(error);
        dispatch({ type: LEAVES_BALANCE_ME_LOADING, payload: false });
      }
    }
  };
}
export function getLeaveBalanceByUser(userId: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LEAVES_BALANCE_USER_LOADING, payload: true });
      const res = await axios.get('/Leaves/overview/user/', {
        params: {
          userId: userId,
        },
      });
      dispatch({ type: LEAVES_BALANCE_USER, payload: res.data });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: LEAVES_BALANCE_USER_LOADING, payload: false });
    }
  };
}
