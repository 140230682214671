import { isTodayOrBefore } from '../../util/utils';

export enum LeaveStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export function isPending(status: string): boolean {
  return status === LeaveStatus.PENDING;
}

export const isThreeDotMenu = (status: string, date: string): boolean => {
  return isPending(status) && !isTodayOrBefore(date);
};
