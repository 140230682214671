import moment from 'moment';

const dateSort = (dateA: string, dateB: string) =>
  moment(dateA).diff(moment(dateB));

/**
 *
 * @param {number|string} a
 * @param {number|string} b
 */
const defaultSort = <T>(a: T, b: T) => {
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
};

export const tableSorter = {
  defaultSort,
  dateSort,
};
