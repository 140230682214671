import React, { FC } from 'react';
import { Form, Input, Modal, Row } from 'antd';
import { useParams } from 'react-router';
import { B, PrimaryButton } from '../../../components';
import theme from '../../../theme';
import { AssignableUsersI, SendContractI } from '../../../types/contract.types';
import styled from '@emotion/styled';

interface Props {
  handleCancel: () => void;
  visible: boolean;
  assignableUsers: AssignableUsersI[];
  onSubmit: (values: SendContractI) => void;
  subject: string;
  receiver: AssignableUsersI;
  documentName: string;
}
type ParamsI = {
  contractId: string;
};

const ModalComp = styled(Modal)`
  .ant-modal-footer {
    border-top: 0;
  }
  .ant-modal-header {
    padding: 18px 16px;
    border-radius: 8px 8px 0px 0px;
  }
  .ant-modal-body {
    padding: 0px;
  }
`;

const To = styled(Row)`
  display: flex;
  padding: 16px;
  align-items: center;
  border-bottom: 1px solid ${theme.gray300};
`;
const Subject = styled(Row)`
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.gray300};
`;
const SubjectFormItem = styled(Form.Item)`
  width: 80%;
  margin-bottom: 0px;
`;
const SubjectInput = styled(Input)`
  border: none;
  &:focus {
    border: none;
    box-shadow: none;
  }

  &:hover {
    border: none;
    box-sizing: none;
    filter: none;
    border-radius: none;
  }
`;
const TextArea = styled(Input.TextArea)`
  border: none;
  min-height: 160px !important;
  padding: 16px !important;
  &:focus {
    border: none;
    box-shadow: none;
  }

  &:hover {
    border: none;
    box-sizing: none;
    filter: none;
    border-radius: none;
  }
  .ant-form-item .ant-mentions,
  .ant-form-item textarea.ant-input {
    min-height: 160px !important;
    height: 160px !important;
  }
`;

const Footer = styled(Row)`
  padding: 16px 24px 16px 0px;
`;
const TextAreaFormItem = styled(Form.Item)`
  width: 100%;
  margin-bottom: 0px;
`;

const Message = styled(Row)`
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.gray300};
  text-align: justify;
`;

const SendContractModal: FC<Props> = ({
  handleCancel,
  visible,
  assignableUsers,
  onSubmit,
  subject,
  receiver,
  documentName,
}) => {
  const params = useParams<ParamsI>();

  const onFinish = (formValues: SendContractI) => {
    const str = document.getElementById('message')?.innerHTML;
    if (str) {
      const strWithBr = str.replace(/(?:\n)/gm, '<br>');
      const values = {
        contractId: params.contractId,
        subject: formValues.subject,
        message: strWithBr,
      };
      onSubmit(values);
    }
  };

  return (
    <ModalComp
      footer={null}
      width={720}
      open={visible}
      onCancel={handleCancel}
      title={<B type="b-default-semibold">Send Contract</B>}>
      <Form onFinish={onFinish} initialValues={{ subject }}>
        <div>
          <To>
            <B pr="8px" type="b-default-semibold">
              To:
            </B>
            <B type="b-default">
              {assignableUsers
                .map((user) => {
                  return `${user.fullName} (${user.email})`;
                })
                .join(', ')}
            </B>
          </To>
          <Subject>
            <B type="b-default-semibold">Subject:{''}</B>
            <SubjectFormItem
              name="subject"
              rules={[
                {
                  required: true,
                  message: 'Please enter email subject!',
                },
              ]}>
              <SubjectInput />
            </SubjectFormItem>
          </Subject>
          <Message>
            <B p="16px" type="b-default">
              Dear {receiver.fullName}
            </B>
            <TextAreaFormItem name="message">
              <TextArea
                defaultValue={`We are pleased to share your ${documentName}  with you via email for your reference and acceptance. Please follow the link below to sign the contract securely.We will proceed to place the contract under your employee profile/records within the Humanised system once it has been digitally signed and accepted by you.`}
              />
            </TextAreaFormItem>
          </Message>
        </div>
        <Footer justify="end">
          <PrimaryButton htmlType="submit">Send</PrimaryButton>
        </Footer>
      </Form>
    </ModalComp>
  );
};
export default SendContractModal;
