import React, { FC, useState } from 'react';
import { Form } from 'antd';
import moment, { Moment } from 'moment';
import { B, DatePickerComp, InputComp } from '../../../components';
import { ModalComponent } from '../../../components/ModalComponent';
import dayjs from 'dayjs';
import {
  HolidayI,
  OrganizationHolidayI,
} from '../../../types/organization.types';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (formValues: HolidayI) => void;
  holidayDetails: OrganizationHolidayI | null;
}

export const EditHolidayModal: FC<Props> = ({
  visible,
  onCancel,
  holidayDetails,
  onSubmit,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [isName, setIsName] = useState('');
  const [isDate, setIsDate] = useState<Moment | null>();
  const [form] = Form.useForm();

  function onFinish(FormValues: HolidayI) {
    const values = {
      ...FormValues,
      isHoliday: holidayDetails?.isHoliday,
      id: holidayDetails?.id.toString(),
    };
    onSubmit(values);
  }

  return (
    <>
      <ModalComponent
        form={form}
        visible={visible}
        submitText="Update Holiday"
        disabled={isName || isDate ? false : true}
        width="532px"
        title={
          <div
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}>
            <B type="b-large-semibold" color="#202125">
              Edit Holiday
            </B>
          </div>
        }
        onCancel={() => onCancel()}>
        <Form
          form={form}
          initialValues={{
            ...holidayDetails,
            description: holidayDetails?.description,
            date: holidayDetails?.date,
          }}
          onFinish={onFinish}
          layout="vertical"
          name="form_in_modal">
          <div>
            <Form.Item
              name="description"
              rules={[
                { required: true, message: 'Please input holiday name' },
              ]}>
              <InputComp
                size="small"
                onChange={(e) => {
                  setIsName(e.target.value);
                }}
                label="Holiday Name"
              />
            </Form.Item>
            <Form.Item
              name="date"
              rules={[
                { required: true, message: 'Please input holiday date' },
              ]}>
              <DatePickerComp
                size="middle"
                onChange={(date) => {
                  setIsDate(moment(date.toDate()));
                }}
                label="Date"
                disabledDate={(current) => current.isBefore(dayjs(), 'day')}
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </div>
        </Form>
      </ModalComponent>
    </>
  );
};
