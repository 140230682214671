import React, { FC, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Col, Row, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  PrimaryButton,
  SecondaryButton,
  FullScreen,
  fullScreenCss,
  ConfirmationModal,
  B,
  LinkButton,
} from '../../../components';
import * as _ from 'lodash';
import { FooterComponent } from '../../../components/FooterComponent';
import { RadioButton, RadioGroup } from '../../../components/RadioComp';
import {
  submitForProcess,
  clearDataImportRedirect,
  SubmitDataImportPayload,
  DataImportUserPayLoad,
  PayrunI,
  CsvDataI,
  DataExtractorCSVEnum,
  DataExtractorTypesEnum,
} from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import theme from '../../../theme';
import { BannerAlert } from '../../../components/alertMessage/BannerAlert';
import { useUploadForDataValidate } from '../../../api/DataImportHooks';
import { useGetDefaultDataExtractor } from '../../../util/dataImportUtil';
import { formatDataImportJSON } from './dataImport.util';
import PeopleImportTable from './ImportTable';
import PayRunModal from '../../payroll/pages/payroll-home/shared/PayRunModal';
import styled from '@emotion/styled';

enum TableFilterEnum {
  new = 'new',
  all = 'all',
  updated = 'updated',
  error = 'error',
}
interface Props {
  csvType: DataExtractorCSVEnum;
  extractorType: DataExtractorTypesEnum;
  onComplete: () => void;
}
const DataImport = styled.div<{ fullScreen: boolean }>`
  width: 100%;
  background: ${theme.white};
  padding: ${(props) => (props.fullScreen ? '32px' : '')};
  ${(props) => (props.fullScreen ? fullScreenCss : '')}
`;

const DataImportPage: FC<Props> = ({ csvType, extractorType, onComplete }) => {
  const dispatch: DispatchType = useDispatch();
  const navigate = useNavigate();
  const [filterType, setFilterType] = useState<TableFilterEnum>(
    TableFilterEnum.all,
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [errorRow, setErrorRow] = useState<DataImportUserPayLoad[]>([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dataPayload, setDataPayload] = useState<DataImportUserPayLoad[]>([]);
  const [payItemColumns, setPayItemColumns] = useState<string[]>([]);
  const [originalPayload, setOriginalPayload] = useState<
    Partial<DataImportUserPayLoad>[]
  >([]);

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const dataImportPayloadLoading = useSelector(
    (state: RootState) => state.dataImport.loading,
  );

  const dataImportPayload = useSelector(
    (state: RootState) => state.dataImport.dataImportPayload,
  );

  const initialPayItemColumns = useSelector(
    (state: RootState) => state.dataImport.payItemColumns,
  );
  const initialOriginalPayload = useSelector(
    (state: RootState) => state.dataImport.originalPayload,
  );
  const extractorInfo = useGetDefaultDataExtractor();

  const {
    isLoading: loadingUploadForDataValidate,
    mutate: uploadForDataValidate,
    data: uploadDataValidate,
  } = useUploadForDataValidate();

  useEffect(() => {
    setDataPayload(dataImportPayload);
    setPayItemColumns(initialPayItemColumns);
    setOriginalPayload(initialOriginalPayload);
  }, []);

  useEffect(() => {
    if (uploadDataValidate) {
      const formattedPayload = formatDataImportJSON(
        _.cloneDeep(uploadDataValidate),
      );
      setDataPayload(formattedPayload.dataList);
    }
  }, [uploadDataValidate]);

  useEffect(() => {
    if (dataPayload) {
      setSelectedRowKeys(
        dataPayload
          .filter((item) => {
            let selected = true;
            if (item.defaultSelected !== undefined) {
              // if it has a field called defaultSelected, then decide select/unselect based on that
              selected = item.defaultSelected;
            }
            if (item.hasEmployeeErrors) {
              // if it has hasEmployeeErrors, it should be unselected
              selected = false;
            }
            return selected;
          })
          .map((item) => item.rowKey),
      );
      setErrorRow(dataPayload.filter((item) => item.hasEmployeeErrors));
    }
  }, [dataPayload]);

  const loading = useSelector((state: RootState) => state.dataImport.loading);

  const redirectTo = useSelector(
    (state: RootState) => state.dataImport.redirectTo,
  );

  useEffect(() => {
    if (redirectTo) {
      dispatch(clearDataImportRedirect());
      navigate(redirectTo);
    }
  }, [redirectTo]);

  const getFilteredData = () => {
    if (filterType === TableFilterEnum.updated) {
      const data = dataPayload.filter((item) => {
        return (
          (!_.isEmpty(item.updatedUserValues) && item.values.id) ||
          (item.payrollUpdates.length > 0 && item.values.id)
        );
      });
      return data;
    } else if (filterType === TableFilterEnum.new) {
      return dataPayload.filter((item) => !item.values.id);
    } else if (filterType === TableFilterEnum.error) {
      return errorRow;
    }
    return dataPayload;
  };
  const payload = getFilteredData();

  const getErrors = (data: DataImportUserPayLoad[]) => {
    let payItemErrorColumns: string[] = [];
    const employeeErrorObj = {} as { [key: string]: any };

    for (const item of data) {
      if (item.payItemErrors.length) {
        item.payItemErrors.forEach((error) => {
          error.payTitle && payItemErrorColumns.push(error.payTitle);
        });
      }
      if (!_.isEmpty(item.employeeErrors)) {
        Object.entries(item.employeeErrors).forEach(([key, value]) => {
          if (!employeeErrorObj.hasOwnProperty(key)) {
            employeeErrorObj[key] = value;
          }
        });
      }
    }
    payItemErrorColumns = [...new Set(payItemErrorColumns)];
    return { payItemErrorColumns, employeeErrorObj };
  };

  const { payItemErrorColumns, employeeErrorObj } = getErrors(payload);

  const handleKeyDown = (evt: KeyboardEvent) => {
    if (evt.code === 'Escape') {
      setFullScreen(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  if (!dataPayload.length) {
    return null;
  }

  const getErrorMessage = (data: DataImportUserPayLoad[]) => {
    const unavailableSystem = data.filter(
      (item) => item.employeeErrors.fullName,
    ).length;
    const payrollAllowed = data.filter(
      (item) => item.employeeErrors.employeeNumber,
    ).length;
    const mandatoryDetails = data.filter((item) => item.employeeErrors).length;
    if (unavailableSystem > 0 && payrollAllowed > 0) {
      return `Your CSV contains ${unavailableSystem} employees either unavailable in the system or switched off for payroll, Please add them first! `;
    } else if (payrollAllowed > 0) {
      return `This ${payrollAllowed}  employee is not allowed in payroll`;
    } else if (unavailableSystem > 0) {
      return `Your CSV contains ${unavailableSystem} employees unavailable in the system,  please add them first!.`;
    } else {
      return `There are ${mandatoryDetails} employees with missing details, Please make sure all mandatory details are completed`;
    }
  };

  return (
    <Spin spinning={loadingUploadForDataValidate || loading}>
      {errorRow && errorRow.length ? (
        <BannerAlert
          message={getErrorMessage(errorRow)}
          AlertTypes="error"
          closable
          showIcon={true}
          action={
            csvType === DataExtractorCSVEnum.SALARY_CSV && (
              <LinkButton
                size="small"
                onClick={() => {
                  navigate('/contact');
                }}>
                Manage People
              </LinkButton>
            )
          }
        />
      ) : (
        <BannerAlert
          message="Please double-check if employee data is correct before importing!"
          AlertTypes="info"
          closable
          showIcon={true}
        />
      )}

      <DataImport fullScreen={fullScreen}>
        <></>
        <Row
          css={css`
            padding: 30px;
            @media (max-width: 1024px) {
              padding: 30px 10px;
            }
          `}>
          <Col xs={24} sm={19} md={20} lg={20} xl={21} xxl={22}>
            <RadioGroup value={filterType}>
              <RadioButton
                value={TableFilterEnum.all}
                onClick={() => setFilterType(TableFilterEnum.all)}>
                All Employees ({dataPayload.length})
              </RadioButton>
              {csvType !== DataExtractorCSVEnum.SALARY_CSV ? (
                <RadioButton
                  value={TableFilterEnum.new}
                  onClick={() => setFilterType(TableFilterEnum.new)}>
                  New (
                  {originalPayload &&
                    originalPayload.filter((item) => !item?.values?.id).length}
                  )
                </RadioButton>
              ) : null}

              <RadioButton
                value={TableFilterEnum.updated}
                onClick={() => setFilterType(TableFilterEnum.updated)}>
                Updated (
                {originalPayload &&
                  originalPayload.filter(
                    (item) =>
                      (!_.isEmpty(item?.updatedUserValues) &&
                        !!item?.values?.id) ||
                      (item?.payrollUpdates &&
                        item.payrollUpdates.length > 0 &&
                        item?.values?.id),
                  ).length}
                )
              </RadioButton>

              {errorRow.length === 0 ? null : (
                <RadioButton
                  value={TableFilterEnum.error}
                  onClick={() => setFilterType(TableFilterEnum.error)}>
                  Errors ({errorRow.length})
                </RadioButton>
              )}
            </RadioGroup>
          </Col>
          <Col
            xs={24}
            sm={5}
            md={4}
            lg={3}
            xl={3}
            xxl={2}>
            <FullScreen
              fullScreen={fullScreen}
              openFullScreen={() => {
                setFullScreen(true);
              }}
              exitFullscreen={() => {
                setFullScreen(false);
              }}
            />
          </Col>
        </Row>

        <Row
          css={css`
            padding-bottom: 100px;
          `}>
          <PeopleImportTable
            csvType={csvType}
            onErrorFilter={filterType === TableFilterEnum.error}
            employeeErrorObj={employeeErrorObj}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            payItemColumns={payItemColumns || []}
            dataPayload={payload || []}
            isDraftPayroll={true}
            payrollKey=""
            fullScreen={fullScreen}
            extraTableHeight={fullScreen ? 0 : 50}
            setFullScreen={setFullScreen}
            isSelectedEmployeeOnly={true}
            payItemErrorColumns={payItemErrorColumns}
            onChange={(v) => {
              const payloadIds = v.map((value) => value.values.employeeNumber);
              const restOfPayload = dataPayload.filter(
                (item) => !payloadIds.includes(item.values.employeeNumber),
              );
              const payload = [...restOfPayload, ...v].map(
                (item) => item.values,
              );
              uploadForDataValidate({
                type: extractorInfo.extractorType,
                csvType,
                payload,
              });
            }}
          />
        </Row>

        <FooterComponent
          fullScreen={fullScreen}
          rightChildren={
            csvType === DataExtractorCSVEnum.SALARY_CSV ? (
              <PrimaryButton
                disabled={!selectedRowKeys.length}
                loading={loading}
                onClick={() => setVisible(true)}>
                {csvType === DataExtractorCSVEnum.SALARY_CSV
                  ? 'Create Draft'
                  : 'Proceed To Import'}
              </PrimaryButton>
            ) : (
              <ConfirmationModal
                title={<B type="b-large-semibold">Confirmation Alert</B>}
                content={
                  <B type="b-large">
                    You cannot undo after you pressing the confirm button. Are
                    you sure that you need to confirm data import?
                  </B>
                }
                okText="Confirm"
                cancelText="No"
                onConfirm={async () => {
                  const payloadList: SubmitDataImportPayload[] = [];
                  dataPayload.forEach((item) => {
                    if (selectedRowKeys.includes(item.rowKey)) {
                      payloadList.push(item.values);
                    }
                  });
                  const data: CsvDataI = {
                    extractedUsers: payloadList,
                    payroll: undefined,
                  };
                  await dispatch(
                    submitForProcess(data, extractorType, csvType),
                  );

                  onComplete();
                }}
                trigger={
                  <PrimaryButton
                    disabled={!selectedRowKeys.length}
                    loading={loading}>
                    Proceed To Import
                  </PrimaryButton>
                }
              />
            )
          }
          leftChildren={
            <SecondaryButton
              onClick={() => {
                dispatch(clearDataImportRedirect());
              }}>
              Upload Again
            </SecondaryButton>
          }
        />
      </DataImport>
      {visible && (
        <PayRunModal
          buttonDisabled={dataImportPayloadLoading}
          visible={visible}
          onClose={() => setVisible(false)}
          onSubmit={async (values) => {
            const otNames: string[] = [];
            organizationData?.otTypes?.map((otType) =>
              otNames.push(otType.otName),
            );

            const payloadList: SubmitDataImportPayload[] = [];
            dataPayload.forEach((item) => {
              if (selectedRowKeys.includes(item.rowKey)) {
                payloadList.push(item.values);
              }
            });

            payloadList.forEach((payloadItem) => {
              payloadItem.payItems.forEach((payItem) => {
                if (otNames.includes(payItem.payTitle)) {
                  payItem.subType = payItem.payTitle;
                }
              });
            });

            const payrollValues: PayrunI = {
              ...values,
              name: values.name,
              payrollStartDate: values.payrollStartDate,
              payrollEndDate: values.payrollEndDate,
              defaultRemark: values.defaultRemark,
              payrunType: values.payrunType,
              month: values.month,
              year: values.year,
            };

            const data: CsvDataI = {
              extractedUsers: payloadList,
              payroll: payrollValues,
            };
            await dispatch(submitForProcess(data, extractorType, csvType));
            onComplete();
            setVisible(false);
          }}
        />
      )}
    </Spin>
  );
};

export default DataImportPage;
