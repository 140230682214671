import { Col, Row, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { B, LinkButton } from '../../../../components';
import {
  getPayrollReport,
  PayrollReportI,
  ReportDetailedOtherRecordsI,
  ReportDetailedPayrollCalculationsI,
  ReportDetailedRecordsI,
} from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import { ReportLayout } from '../../shared/report-layout/ReportLayout';
import ReportSelector from '../../shared/ReportSelector';
import {
  SummaryContainer,
  SummaryItem,
} from '../../../payroll/pages/publish-payrun/SummaryContainer';
import { payrollReportFiltersI } from '../../../../types/report.types';
import {
  getDataFiltered,
  getDetailedDataFiltered,
  getSelectedEmployee,
} from '../../utils/payrollReport';
import { generateDetailedExcel } from '../../utils/generateDetailedExport';
import styled from '@emotion/styled';
import { BannerAlert } from '../../../../components/alertMessage/BannerAlert';
import { DownloadBlue } from '../../../../assets';
import ReportMenuSelector, { DropdownSection, StyledRow } from '../../shared/ReportMenuSelector';



const ExportButton = styled.div`
  margin-left: auto;
`;

const StyledExportButton = styled(ExportButton)`
  margin-left: auto;
`;

const ContainerDiv = styled.div`
  padding: 24px;
  .ant-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  p:first-letter {
    text-transform: capitalize;
  }
`;

const StyledRowFixedWidth = styled(Row)`
  max-width: 800px;
`;

const DetailedReportPage: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const allPeoples = useSelector((state: RootState) => state.people.allPeoples);
  const [filterSummaryReportData, setFilterSummaryReportData] =
    useState<payrollReportFiltersI>();
  // used for set filtered chart data
  const [detailedData, _setDetailedData] =
    useState<ReportDetailedPayrollCalculationsI>({
      earnings: {},
      deductions: {},
      total: {},
      other: {},
    });
  const [filterDataSet, setFilterDataSet] = useState<PayrollReportI[]>([]);
  // result of api call loading
  const payrollReportLoading = useSelector(
    (state: RootState) => state.report.payrollReportLoading,
  );
  // result of api call data
  const payrollReport = useSelector(
    (state: RootState) => state.report.payrollReport,
  );

  // fires when new report api is called
  useEffect(() => {
    getDetailedData();
  }, [payrollReport, filterSummaryReportData]);

  useEffect(() => {
    dispatch(getPayrollReport());
  }, []);

  const getDetailedData = () => {
    const selectedFilters: payrollReportFiltersI = {};
    if (filterSummaryReportData?.users?.length) {
      selectedFilters.users = filterSummaryReportData?.users;
    }
    if (filterSummaryReportData?.payrollIds?.length) {
      selectedFilters.payrollIds = filterSummaryReportData?.payrollIds;
    }
    const chartData: ReportDetailedPayrollCalculationsI =
      getDetailedDataFiltered(payrollReport, selectedFilters);
    const dataSet: PayrollReportI[] = getDataFiltered(
      payrollReport,
      selectedFilters,
    );
    setFilterDataSet(dataSet);
    _setDetailedData(chartData);
  };
  const getTypeNames = (key: string): string => {
    const types: ReportDetailedOtherRecordsI = {
      basic: 'Basics',
      costToCompany: 'Total Cost to company',
      netSalary: 'Net Salary Amount',
      employeeEPF: 'EPF Employee contribution 8%',
      employerEPF: 'EPF Employer contribution 12%',
      employerETF: 'ETF contribution 3%',
      totalTax: 'APIT',
    };
    if (types[key]) return types[key];
    return key;
  };

  const renderRows = (obj: ReportDetailedRecordsI) => {
    const rows = [];
    const sortedList = Object.entries(obj).sort((a, b) =>
      a[0].localeCompare(b[0]),
    );
    for (const [key, value] of sortedList) {
      if (value)
        rows.push(
          <SummaryItem
            key={key}
            typeName={getTypeNames(key)}
            payAmount={Number(value)}
          />,
        );
    }
    return rows;
  };
  const selectedEmployee = getSelectedEmployee(
    allPeoples,
    filterSummaryReportData?.users ? filterSummaryReportData?.users : [],
  );

  return (
    <>
      <ReportLayout>
        {filterSummaryReportData?.payrollIds?.length === 0 && (
          <BannerAlert
            message="Please select atleast one payrun for your report"
            closable
            showIcon={true}
            AlertTypes="warning"
          />
        )}
        <div>
          <StyledRow>
            <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
              <B type="b-large-semibold">Payroll Detailed Report</B>
            </Col>

            <Col xs={12} sm={12} md={12} lg={0} xl={0} xxl={0}>
              <DropdownSection>
                  <ReportMenuSelector defaultIndex="2" />
              </DropdownSection>
            </Col>

            {!payrollReportLoading && (
              <StyledExportButton>
                <LinkButton
                  p={0}
                  icon={<img src={DownloadBlue} />}
                  disabled={payrollReportLoading}
                  onClick={() =>
                    generateDetailedExcel(
                      detailedData,
                      filterSummaryReportData?.payrollIds?.length,
                      selectedEmployee,
                      filterDataSet,
                    )
                  }>
                  Export
                </LinkButton>
              </StyledExportButton>
            )}
          </StyledRow>
          <ReportSelector
            useDateRange={false}
            onChange={(values) => setFilterSummaryReportData(values)}
          />
        </div>
        {!!filterSummaryReportData?.payrollIds?.length && (
          <ContainerDiv>
            <Spin spinning={payrollReportLoading}>
              <StyledRowFixedWidth>
                <Col xs={24} sm={24} md={24} lg={24} xl={20} xxl={20}>
                  {Boolean(Object.keys(detailedData).length) && (
                    <>
                      {Boolean(Object.keys(detailedData?.earnings).length) && (
                        <SummaryContainer headingTitle="Earnings">
                          {renderRows(detailedData?.earnings)}
                        </SummaryContainer>
                      )}
                      {Boolean(
                        Object.keys(detailedData?.deductions).length,
                      ) && (
                        <SummaryContainer headingTitle="Deductions">
                          {renderRows(detailedData?.deductions)}
                        </SummaryContainer>
                      )}
                      {Boolean(Object.keys(detailedData?.total).length) && (
                        <SummaryContainer headingTitle="Total">
                          {renderRows(detailedData?.total)}
                        </SummaryContainer>
                      )}
                      {Boolean(Object.keys(detailedData?.other).length) && (
                        <SummaryContainer headingTitle="Other">
                          {renderRows(detailedData?.other)}
                        </SummaryContainer>
                      )}
                    </>
                  )}
                </Col>
              </StyledRowFixedWidth>
            </Spin>
          </ContainerDiv>
        )}
      </ReportLayout>
    </>
  );
};

export default DetailedReportPage;
