/**
 * Salary Caluclator Doc - https://block-hq.atlassian.net/wiki/spaces/HUM/pages/1739292675/Salary+Calculation
 */
export interface PayItemType {
  id?: number;
  type: 'EARN' | 'DEDUCTION';
  amount: number;
  isTaxable: boolean;
  isEpf: boolean;
}

export interface PaySlipType {
  totalTax: number;
  employeerETF: number;
  employeerEPF: number;
  employeeEPF: number;
  netPay: number;
  netSalary: number;
  grossSalary: number;
  grossDeduction: number;
  payItems: PayItemType[];
}

export function calculatePayeeTax(taxableAmount: number) {
  if (taxableAmount > 750000) {
    return (taxableAmount * 18) / 100 - 90000;
  } else if (taxableAmount > 500000) {
    return (taxableAmount * 12) / 100 - 45000;
  } else if (taxableAmount > 250000) {
    return (taxableAmount * 6) / 100 - 15000;
  }
  return 0;
}

export function calculateEmployeerETF(amount: number) {
  return (amount * 3) / 100;
}

export function calculateEmployeerEPF(amount: number) {
  return (amount * 12) / 100;
}

export function calculateEmployeeEPF(amount: number) {
  return (amount * 8) / 100;
}

export function calaulateSalarySlip(payItems: PayItemType[]): PaySlipType {
  let taxableAmount = 0;
  let epfEtfAmount = 0;
  let grossSalary = 0;
  let grossDeduction = 0;
  for (let i = 0; i < payItems.length; i++) {
    const payItem = payItems[i];
    if (payItem.type !== 'DEDUCTION') {
      if (payItem.isTaxable) {
        taxableAmount += payItem.amount;
      }

      if (payItem.isEpf) {
        epfEtfAmount += payItem.amount;
      }
      grossSalary += payItem.amount;
    } else {
      grossDeduction += payItem.amount;
    }
  }

  const totalTax = calculatePayeeTax(taxableAmount);
  const employeerETF = calculateEmployeerETF(epfEtfAmount);
  const employeerEPF = calculateEmployeerEPF(epfEtfAmount);
  const employeeEPF = calculateEmployeeEPF(epfEtfAmount);

  const netPay =
    grossSalary + employeerEPF + employeerETF - grossDeduction - totalTax;
  const netSalary = grossSalary - grossDeduction - employeeEPF;

  return {
    totalTax,
    employeerETF,
    employeerEPF,
    employeeEPF,
    netPay,
    netSalary,
    grossSalary,
    grossDeduction,
    payItems,
  };
}
