import React, { FC, useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';
import { B, Div, SwitchComponent } from '../../../components';
import theme from '../../../theme';
import {
  ProrataSettingsI,
  ProrataTypeSelection,
} from '../../../types/organization.types';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import { Divider } from '../../../components/Divider';

const MainDiv = styled.div`
  padding: 32px;
  @media (max-width: 768px) {
    padding: 24px 10px;
    width: 100%;
  }
`;

const ProrataSettingsDiv = styled.div`
  padding: 0px 32px 32px;
`;

const FormItem = styled(Form.Item)<SpaceProps & LayoutProps>`
  margin-bottom: 0px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  ${space}
  ${layout}
`;

interface Props {
  form: FormInstance<any>;
  prorataSettings: ProrataSettingsI;
  prorataTypeSelection: ProrataTypeSelection;
  isBasicSalaryChecked: boolean;
  setIsBasicSalaryChecked: (checked: boolean) => void;
  isFixedAllowanceChecked: boolean;
  setIsFixedAllowanceChecked: (checked: boolean) => void;
  isVariableAllowanceChecked: boolean;
  setIsVariableAllowanceChecked: (checked: boolean) => void;
}

const ProRated: FC<Props> = ({
  form,
  prorataSettings,
  prorataTypeSelection,
  isBasicSalaryChecked,
  setIsBasicSalaryChecked,
  isFixedAllowanceChecked,
  setIsFixedAllowanceChecked,
  isVariableAllowanceChecked,
  setIsVariableAllowanceChecked,
}) => {
  const [isProratedEnabled, setIsProratedEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (prorataSettings && prorataTypeSelection) {
      setIsProratedEnabled(prorataSettings?.prorata?.status);
      form.setFieldsValue({
        prorated: prorataSettings.prorata.status,
        variableAllowanceProrata: prorataTypeSelection.VARIABLE_ALLOWANCE,
        fixedAllowanceProrata: prorataTypeSelection.FIXED_ALLOWANCE,
        basicSalaryProrata: prorataTypeSelection.BASIC,
      });
    }
  }, [prorataSettings]);

  useEffect(() => {
    if (prorataSettings && prorataTypeSelection) {
      setIsProratedEnabled(prorataSettings?.prorata?.status);
      form.setFieldsValue({
        prorated: prorataSettings.prorata.status,
        variableAllowanceProrata: prorataTypeSelection.VARIABLE_ALLOWANCE,
        fixedAllowanceProrata: prorataTypeSelection.FIXED_ALLOWANCE,
        basicSalaryProrata: prorataTypeSelection.BASIC,
      });
    }
  }, []);

  useEffect(() => {
    if (prorataTypeSelection) {
      setIsBasicSalaryChecked(prorataTypeSelection.BASIC);
      setIsFixedAllowanceChecked(prorataTypeSelection.FIXED_ALLOWANCE);
      setIsVariableAllowanceChecked(prorataTypeSelection.VARIABLE_ALLOWANCE);
    }
  }, [prorataTypeSelection]);

  return (
    <>
      <MainDiv>
        <B type="b-large-semibold" pb="16px">
          Prorata
        </B>
        <Row>
          <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
            <Div display="flex">
              <FormItem name="prorated" valuePropName="checked">
                <SwitchComponent
                  onChange={(checked: boolean) => setIsProratedEnabled(checked)}
                />
              </FormItem>
              <B type="b-default" pl="10px" pt="5px" color={theme.gray600}>
                Prorata
              </B>
            </Div>
          </Col>
          <Col xs={24} sm={24} md={19} lg={19} xl={19} xxl={19}>
            {isProratedEnabled && (
              <B type="b-small" color={theme.gray600} pt="5px">
                By switching on no pay, the no pay amount will be calculated by
                multiplying the no pay rate; obtained by dividing the Basic
                salary by the no of working days, from the the no.of no pay days
                logged by system Owners, Admins, Guest admins or Managers for
                each employee. Please contact us if you follow a different no
                pay policy.
              </B>
            )}
          </Col>
        </Row>
      </MainDiv>
      {isProratedEnabled && (
        <FormItem>
          <ProrataSettingsDiv>
            <B type="b-default" pb="12px">
              Please select which items you want to include in no-pay
              calculations & pro-rata calculations
            </B>
            <FormItem
              name="prorata"
              marginBottom="0px"
              rules={[
                {
                  required:
                    !isBasicSalaryChecked &&
                    !isFixedAllowanceChecked &&
                    !isVariableAllowanceChecked,
                  message: 'Please choose at least one item',
                },
              ]}>
              <Div display="flex" height={25}>
                <Div display="flex">
                  <FormItem valuePropName="checked" marginBottom="16px">
                    <CheckBoxComp
                      checked={isBasicSalaryChecked}
                      onChange={() =>
                        setIsBasicSalaryChecked(!isBasicSalaryChecked)
                      }
                    />
                  </FormItem>
                  <B type="b-default" pl="18px" pt="6px" pr="51px">
                    Basic Salary
                  </B>
                </Div>
                <Div display="flex">
                  <FormItem valuePropName="checked" mb="16px">
                    <CheckBoxComp
                      checked={isFixedAllowanceChecked}
                      onChange={() =>
                        setIsFixedAllowanceChecked(!isFixedAllowanceChecked)
                      }
                    />
                  </FormItem>

                  <B type="b-default" pl="18px" pt="6px" pr="51px">
                    Fixed Allowances
                  </B>
                </Div>
                <Div display="flex">
                  <FormItem valuePropName="checked" marginBottom="0px">
                    <CheckBoxComp
                      checked={isVariableAllowanceChecked}
                      onChange={() =>
                        setIsVariableAllowanceChecked(
                          !isVariableAllowanceChecked,
                        )
                      }
                    />
                  </FormItem>
                  <B type="b-default" pl="18px" pt="6px">
                    Variable Allowances
                  </B>
                </Div>
              </Div>
            </FormItem>
          </ProrataSettingsDiv>
        </FormItem>
      )}

      <Divider />
    </>
  );
};

export default ProRated;
