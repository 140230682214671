import React, { FC, useEffect, useState } from 'react';
import { Col, Form, message, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import {
  border,
  BorderProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';
import styled from '@emotion/styled';
import { B, Div, LinkButton, SwitchComponent } from '../../../components';
import theme from '../../../theme';
import {
  AutomatedAllowanceSettingI,
  AutomatedAllowanceTypesI,
  otPayItemType,
} from '../../../types/organization.types';

import { v4 as uuidv4 } from 'uuid';
import AttendanceAllowanceTypeList from './AttendanceAllowanceTypeList';
import AddAttendanceAllowance from './AddAttendanceAllowance';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Divider } from '../../../components/Divider';
import { PlusBlueSVG } from '../../../assets';

const MainDiv = styled.div`
  width: 800px;
  @media (min-width: 1440px) {
    width: 1200px;
  }
  padding: 32px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  @media (max-width: 768px) {
    padding: 24px 10px;
    width: 100%;
  }
`;
const FormItem = styled(Form.Item)<SpaceProps & LayoutProps>`
  margin-bottom: 0px;
  ${space}
  ${layout}
`;

const AutoList = styled.div<SpaceProps & BorderProps>`
  width: 680px;
  border-radius: 4px;
  div:last-child {
    border-bottom: none;
  }
  ${space}
  ${border}
`;
interface Props {
  form: FormInstance<any>;
  attendanceSetting?: AutomatedAllowanceSettingI | null;
  otPayItemType?: otPayItemType | null;
  autoList: AutomatedAllowanceTypesI[];
  autoAllowanceList?: AutomatedAllowanceTypesI[];
  setAutoList: (v: AutomatedAllowanceTypesI[]) => void;
}

const AttendanceAllowance: FC<Props> = ({
  form,
  attendanceSetting,
  autoList,
  setAutoList,
  autoAllowanceList,
}) => {
  const [isAutomatedAllowance, setIsAutomatedAllowance] =
    useState<boolean>(false);
  const [addAutomatedAllowance, setAddAutomatedAllowance] =
    useState<boolean>(false);
  const [allowanceName, setAllowanceName] = useState<string>();
  const [amount, setAmount] = useState<number>(0);
  const [automatedAllowanceIsEnable, setAutomatedAllowanceIsEnable] =
    useState<boolean>(false);
  const [isEpf, setIsEpf] = useState<boolean>(false);
  const [isApit, setIsApit] = useState<boolean>(false);

  const [
    isCheckAutomatedAllowanceValidation,
    setCheckAutomatedAllowanceValidation,
  ] = useState<boolean>(false);

  useEffect(() => {
    setCheckAutomatedAllowanceValidation(
      Object.values(autoList).some((item) => item.isEnable),
    );
  }, [autoList]);

  useEffect(() => {
    if (autoAllowanceList) {
      setAutoList([...autoAllowanceList]);
    }
  }, [autoAllowanceList]);

  useEffect(() => {
    if (attendanceSetting) {
      setIsAutomatedAllowance(attendanceSetting.automatedAllowance.status);

      form.setFieldsValue({
        automatedAllowance: !!attendanceSetting.automatedAllowance.status,
      });
    }
  }, [attendanceSetting]);

  return (
    <>
      <MainDiv>
        <B type="b-large-semibold" pb="16px">
          Variable Allowances on Attendance
        </B>
        <Row>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <Div display="flex">
              <FormItem name="automatedAllowance" valuePropName="checked">
                <SwitchComponent
                  onChange={(checked: boolean) =>
                    setIsAutomatedAllowance(checked)
                  }
                />
              </FormItem>
              <B type="b-default" color={theme.gray600} pl="10px" pt="5px">
                Enable allowances
              </B>
            </Div>
          </Col>
        </Row>
      </MainDiv>
      {isAutomatedAllowance && (
        <>
          <Div p="0px 32px 32px">
            <B type="b-default" pb="16px">
              Please configure the allowances variable on attendance
            </B>
            <AutoList
              border={
                autoList?.length || addAutomatedAllowance
                  ? `1px solid ${theme.gray300}`
                  : `none`
              }
              mb={autoList?.length || addAutomatedAllowance ? '10px' : '0px'}>
              {!!(autoList && autoList.length) && (
                <AttendanceAllowanceTypeList
                  autoAllowanceList={autoList}
                  onRemove={(value: { id: string | number; name: string }) => {
                    form.resetFields([value.name]);
                    const setId = autoList.findIndex(
                      (list) => list.id === value.id,
                    );
                    autoList.splice(setId, 1);
                    setAutoList([...autoList]);
                  }}
                  onChangeAmount={(value: {
                    e: React.ChangeEvent<HTMLInputElement>;
                    id: number | string;
                  }) => {
                    const setId = autoList.findIndex(
                      (list) => list.id === value.id,
                    );

                    autoList[setId].amount = value.e.target.value
                      ? parseInt(value.e.target.value)
                      : 0;
                  }}
                  onChangeName={(e) => setAllowanceName(e.target.value)}
                  onEdit={(id) => {
                    if (allowanceName) {
                      const setId = autoList.findIndex(
                        (autoAllowance) => autoAllowance.id === id,
                      );
                      autoList[setId].allowanceName = allowanceName;
                    }
                  }}
                  onChangeIsApit={(value: {
                    e: CheckboxChangeEvent;
                    id: number | string;
                  }) => {
                    const setId = autoList.findIndex(
                      (list) => list.id === value.id,
                    );

                    autoList[setId].isApit = value.e.target.checked;
                  }}
                  onChangeIsEpf={(value: {
                    e: CheckboxChangeEvent;
                    id: string | number;
                  }) => {
                    const setId = autoList.findIndex(
                      (list) => list.id === value.id,
                    );

                    autoList[setId].isEpf = value.e.target.checked;
                  }}
                  onChangeCheckBox={(value: {
                    e: CheckboxChangeEvent;
                    id: number | string;
                  }) => {
                    const setId = autoList.findIndex(
                      (list) => list.id === value.id,
                    );

                    autoList[setId].isEnable = value.e.target.checked;

                    setAutoList([...autoList]);
                  }}
                />
              )}

              {addAutomatedAllowance && (
                <AddAttendanceAllowance
                  onChangeEpf={(e) => setIsEpf(e.target.checked)}
                  onChangeApit={(e) => setIsApit(e.target.checked)}
                  onChange={(e) => setAllowanceName(e.target.value)}
                  onChangeAmount={(e) => setAmount(parseInt(e.target.value))}
                  onBlur={() => {
                    setAddAutomatedAllowance(false);
                    if (allowanceName) {
                      const value: AutomatedAllowanceTypesI = {
                        id: uuidv4(),
                        allowanceName: allowanceName,
                        amount: undefined,
                        isEnable: automatedAllowanceIsEnable,
                        isEpf: isEpf,
                        isApit: isApit,
                      };
                      if (
                        autoList
                          ?.map((i) => i?.allowanceName.toLocaleLowerCase())
                          .includes(allowanceName.toLocaleLowerCase())
                      ) {
                        message.error('This Allowance name already exists!');
                      } else {
                        autoList.push(value);

                        setAutoList([...autoList]);
                        setAutomatedAllowanceIsEnable(false);
                      }
                    }
                  }}
                  onRemove={() => setAddAutomatedAllowance(false)}
                  onChangeCheckBox={(e) =>
                    setAutomatedAllowanceIsEnable(e.target.checked)
                  }
                />
              )}
            </AutoList>
            <FormItem
              name="autoList"
              rules={[
                {
                  required: !isCheckAutomatedAllowanceValidation && true,
                  message: 'Please enable at least one  allowance',
                },
              ]}>
              <LinkButton
                onClick={() => setAddAutomatedAllowance(true)}
                padding={0}
                icon={<img src={PlusBlueSVG} />}
                disabled={autoList.length > 2}>
                Add new allowance
              </LinkButton>
            </FormItem>
          </Div>
        </>
      )}
      <Divider />
    </>
  );
};

export default AttendanceAllowance;
