import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Col, Divider, Row, Tooltip } from 'antd';
import moment from 'moment';
import React, { FC, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { B, DeleteModal } from '../../../components';
import { deleteLeaveUser } from '../../../store/actions';
import { addDotsForLongText, capitalizeUnderscore } from '../../../util/utils';
import { LeaveStatus } from '../../home/homeUtil';
import clockSvg from '../../../assets/Icons/note-icons/clock.svg';
import { reviewAllowed } from '../../../util/reviewAllowed';
import { convertHoursToHoursAndMin } from '../utils';
import { OrganizationLeaveI, ReviewI } from '../../../types';
import { DispatchType, RootState } from '../../../store/reducer';
import theme from '../../../theme';
import { ExpenseStatusTag } from '../../expenses/shared';
import { CalenderSVG } from '../../../assets';

const LeaveItem = styled.div`
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 12px;
`;

const CustomButton = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 16px;
`;
interface Props {
  leavesDetails: OrganizationLeaveI;
  onDataChange: (v: ReviewI) => void;
}

const AdminLeave: FC<Props> = ({ leavesDetails, onDataChange }) => {
  const me = useSelector((state: RootState) => state.people.me);
  const dispatch: DispatchType = useDispatch();

  return (
    <LeaveItem>
      <Row justify="space-between" align="middle">
        <B type="b-default-semibold" color={theme.black}>
          {leavesDetails.user.fullName
            ? addDotsForLongText(leavesDetails.user.fullName, 40)
            : '-'}
        </B>
        <div>
          {leavesDetails.reviewBy ? (
            <Tooltip
              placement="topLeft"
              title={
                <Fragment>
                  {leavesDetails.reviewBy && (
                    <B color="white" type="b-small">
                      Reviewed By : {leavesDetails.reviewBy.fullName}
                    </B>
                  )}
                  {leavesDetails.reviewBy &&
                    leavesDetails.status === LeaveStatus.REJECTED && (
                      <B color="white" type="b-small">
                        Reject Note : {leavesDetails.rejectionNote || '-'}
                      </B>
                    )}
                </Fragment>
              }>
              <div>
                <ExpenseStatusTag status={leavesDetails.status} />
              </div>
            </Tooltip>
          ) : (
            <ExpenseStatusTag status={leavesDetails.status} />
          )}
        </div>
      </Row>
      <Row>
        <Col span={24}>
          <B type="b-small" display="inline" color={theme.gray600}>
            {leavesDetails.reason
              ? addDotsForLongText(leavesDetails.reason, 40)
              : '-'}
          </B>
        </Col>
        <Col span={24}>
          <img src={CalenderSVG} />
          <B type="b-small" display="inline" color={theme.gray600} px="8px">
            {moment(leavesDetails.date).format('YYYY.MM.DD')}
          </B>
          <B type="b-small" display="inline" color={theme.gray600}>
            &bull;{' '}
            {leavesDetails.leaveType
              ? capitalizeUnderscore(leavesDetails.leaveType)
              : '-'}
          </B>
        </Col>
        <Col span={24}>
          <img src={clockSvg} />
          <B type="b-small" display="inline" color={theme.gray600} pl="8px">
            {convertHoursToHoursAndMin(leavesDetails.hours)}
          </B>
        </Col>
      </Row>
      <Divider
        css={css`
          margin: 8px 0px 8px 0px;
        `}
      />
      <Row justify="start" align="middle">
        {leavesDetails.status !== LeaveStatus.ACCEPTED &&
          reviewAllowed({
            reviewerId: me?.id,
            userId: Number(leavesDetails.userId),
          }) && (
            <B
              type="b-small"
              color={theme.blue500}
              cursor="pointer"
              padding="0px"
              onClick={() => {
                const values = {
                  status: LeaveStatus.ACCEPTED,
                  leaveId: String(leavesDetails.id),
                };
                onDataChange(values);
              }}>
              Approve
            </B>
          )}

        {leavesDetails.status == LeaveStatus.PENDING && (
          <CustomButton>
            <B
              pt={leavesDetails.status == LeaveStatus.PENDING ? '5px' : '0px'}
              type="b-small"
              color={theme.blue500}
              cursor="pointer"
              padding="0px"
              onClick={() => {
                const values = {
                  status: LeaveStatus.REJECTED,
                  leaveId: String(leavesDetails.id),
                };

                onDataChange(values);
              }}>
              Reject
            </B>
          </CustomButton>
        )}

        <DeleteModal
          title="Confirm Cancelation"
          buttonLabel="Cancel"
          content="Are you sure you want to cancel this leave?"
          onClickCancel={() =>
            dispatch(
              deleteLeaveUser(
                String(leavesDetails.userId),
                String(leavesDetails.id),
              ),
            )
          }
          openModal={
            <CustomButton>
              <B type="b-small" color={theme.blue500} cursor="pointer" pt="5px">
                Cancel
              </B>
            </CustomButton>
          }></DeleteModal>
      </Row>
    </LeaveItem>
  );
};

export default AdminLeave;
