import React, { FC, useState } from 'react';

import { Col, Row } from 'antd';
import RadioButton from 'antd/lib/radio/radioButton';

import styled from '@emotion/styled';

import { Can, RadioGroup } from '../../../../components';
import { PeopleLayout } from '../../shared';
import PeopleSelect from '../../shared/PeopleSelect';
import Payslips from './Payslips';
import SalaryInfo from './SalaryInfo';
import { Permission } from '../../../../types';

const MainContainer = styled.div`
  padding: 32px 24px 0px;
  @media (max-width: 768px) {
    padding: 0px 0px 24px;
  }
`;

const PayDetails: FC = () => {
  const [isSalaryDetailsTab, setIsSalaryDetailsTab] = useState(true);

  return (
    <>
      <PeopleLayout>
        <MainContainer>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={0} xxl={0}>
              <PeopleSelect defaultIndex="3" />
            </Col>
          </Row>
          <RadioGroup defaultValue="salaryDetails">
            <RadioButton
              value="salaryDetails"
              onClick={() => setIsSalaryDetailsTab(true)}>
              Salary Details
            </RadioButton>
            <Can
              permission={
                Permission.VIEW_MY_PAYSLIP || Permission.VIEW_PAYSLIPS
              }>
              <RadioButton
                value="payslips"
                onClick={() => setIsSalaryDetailsTab(false)}>
                Payslips
              </RadioButton>
            </Can>
          </RadioGroup>
        </MainContainer>
        {isSalaryDetailsTab ? <SalaryInfo /> : <Payslips />}
      </PeopleLayout>
    </>
  );
};

export default PayDetails;
