import axios from 'axios';
import showApiError from './showApiError';

export const downloadHandler = (id: string, contractName: string) => {
  axios({
    method: 'get',
    url: `contracts/download/${id}`,
    responseType: 'blob',
  })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${contractName}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      showApiError(error);
    });
};

export const downloadHandlerTemplate = (id: string, templateName: string) => {
  axios({
    method: 'get',
    url: `contracts/template/download/${id}`,
    responseType: 'blob',
  })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${templateName}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      showApiError(error);
    });
};

export const downloadNonUserHandler = (token: string) => {
  axios({
    method: 'get',
    url: `contracts/nonSysDownload/${token}`,
    responseType: 'blob',
  })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `contract.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      showApiError(error);
    });
};
