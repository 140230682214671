import React, { FC } from 'react';
import { TreeSelect } from 'antd';
import { SelectValue, TreeSelectProps } from 'antd/lib/tree-select';
import theme from '../../theme';
import styled from '@emotion/styled';

interface Props extends TreeSelectProps<SelectValue> {
  bordered?: boolean;
  flexDirection?: string;
  overruleWidthPresent?: number | undefined;
}
const TreeSelectComp = styled(TreeSelect)<Props>`
  ${(props) =>
    props.overruleWidthPresent
      ? `width: ${props.overruleWidthPresent}%;`
      : `        @media screen and (max-width: 768px) {
        ${props.flexDirection === 'row' && 'width: 100%;'}
      }
      @media screen and (min-width: 768px) {
        ${props.flexDirection === 'row' && 'max-width: 344px;'}
      }`}
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  .ant-select-selector {
    border: ${(props) =>
      props.bordered === false
        ? 'none'
        : `1px solid ${theme.gray300}`}!important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    display: flex !important;
    align-items: center !important;
    height: 32px !important;
    padding: 0px 16px !important;

    input {
      height: 100% !important;
    }
  }
  .span {
    font-size: 14px;
    line-height: 20px;
    color: ${theme.gray600} !important;
  }
  .ant-select-selector:hover {
    border: ${(props) =>
      props.bordered === false
        ? 'none'
        : `1px solid ${theme.blue500}`} !important;
    box-sizing: border-box !important;
    filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
    border-radius: 4px !important;
  }
  .ant-select-selector:focus {
    border: 1px solid ${theme.blue500} !important;
    box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1) !important;
  }
  .ant-select-arrow {
    color: black;
  }
  .ant-select-selection-item-content {
    color: ${theme.blue600};
  }
  .ant-select-selection-item-remove {
    color: ${theme.blue600};
  }
`;
export const TreeSelectV2: FC<Props> = (props) => {
  const {
    size = 'large',
    bordered = true,
    flexDirection = 'column',
    overruleWidthPresent = undefined,
    ...rest
  } = props;
  return (
    <TreeSelectComp
      size={size}
      {...rest}
      bordered={bordered}
      flexDirection={flexDirection}
      overruleWidthPresent={overruleWidthPresent}
    />
  );
};
