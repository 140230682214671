import React, { FC } from 'react';
import { Col, Dropdown, Menu, MenuProps, Row } from 'antd';
import styled from '@emotion/styled';
import theme from '../../../../theme';
import PayrollViewHeader from './PayrollViewHeader';
import { B, Div, FullScreen } from '../../../../components';
import PayrollTitle from '../../shared/PayrollTitle';
import { PayrollViewTypeEnum } from '../../../../types/payroll.types';
import { MoreVerticalSVG } from '../../../../assets';
import { PayrollExport } from './payroll-draft/PayrollExport';
import {
  DataImportUserPayLoad,
  PayrollViewDataI,
} from '../../../../store/actions';

interface Props {
  onChangeView: (type: PayrollViewTypeEnum) => void;
  isDraftPayroll: boolean;
  viewType: PayrollViewTypeEnum;
  onClickFullScreen: (v: boolean) => void;
  isFullScreen: boolean;
  payrollDetails?: PayrollViewDataI;
  dataForTable: {
    dataList: DataImportUserPayLoad[];
    payItemColumns: string[];
  };
  leaveColumnNames?: string[];
  onClickMenu: (v: boolean) => void;
}

const Image = styled.img`
  margin-top:3px;
  
  @media only screen and (max-width: 320px) {
    width; 20px;
    height: 20px; padding-left: 0px;
   };
`;

const CustomRow = styled(Row)`
  padding: 8px 32px;
  border-bottom: 1px solid ${theme.gray300};
  @media only screen and (max-width: 768px) {
    padding: 8px 10px;
  }
`;

const CustomCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const PayrollViewSummary: FC<Props> = ({
  onChangeView,
  isDraftPayroll,
  viewType,
  payrollDetails,
  isFullScreen,
  onClickFullScreen,
  dataForTable,
  leaveColumnNames,
  onClickMenu,
}) => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <B onClick={() => onClickMenu(true)}>Edit Payroll Name and Date</B>
      ),
    },
  ];

  return (
    <CustomRow>
      <Col xs={24} sm={24} md={isDraftPayroll ? 24 : 8} lg={10} xl={8} xxl={8}>
        <PayrollViewHeader
          isDraftPayroll={isDraftPayroll}
          onChangeView={(type) => onChangeView(type)}
          viewType={viewType}
        />
      </Col>
      <CustomCol
        xs={24}
        sm={24}
        md={isDraftPayroll ? 24 : 16}
        lg={14}
        xl={16}
        xxl={16}>
        <Div
          display="flex"
          justifyContent="flex-end"
          height="34px"
          borderRadius="4px">
          <PayrollTitle payrollDetails={payrollDetails} />
          {viewType === PayrollViewTypeEnum.PAYROLL_VIEW && (
            <FullScreen
              size="small"
              isDisplayTitle={false}
              fullScreen={isFullScreen}
              openFullScreen={() => onClickFullScreen(true)}
              exitFullscreen={() => onClickFullScreen(false)}
            />
          )}
          {isDraftPayroll ? (
            <Dropdown menu={{ items }} trigger={['click']}>
              <a className="ant-dropdown-link">
                <Image src={MoreVerticalSVG} />
              </a>
            </Dropdown>
          ) : (
            <PayrollExport
              dataForTable={dataForTable}
              leaveColumnNames={leaveColumnNames}
              payrollViewData={payrollDetails}
              suffix=""
            />
          )}
        </Div>
      </CustomCol>
    </CustomRow>
  );
};

export default PayrollViewSummary;
