import styled from '@emotion/styled';
import { Steps } from 'antd';
import React, { FC } from 'react';
import theme from '../../../../theme';
const { Step } = Steps;

interface Props {
  current: number;
  setCurrent: (value: number) => void;
}

const CustomSteps = styled(Steps)`
  .ant-steps-item-content {
    width: 131px !important;
  }
  .ant-steps-item-icon {
    width: 32px;
    height: 32px;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: ${theme.blue500};
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    color: ${theme.blue500};
    background: ${theme.white};
    border: 2px solid ${theme.blue500};
    .ant-steps-icon {
      color: ${theme.blue500};
    }
    &:hover {
      background: ${theme.lightBlue50};
    }
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background: ${theme.gray950};
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background: ${theme.green50};
    border: 2px solid ${theme.green500};
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    .ant-steps-icon {
      color: ${theme.white};
      .anticon .anticon-check .ant-steps-finish-icon {
        margin-bottom: 2px;
      }
    }
    &:hover {
      background: ${theme.green500};
      border-color: ${theme.green500};
      .ant-steps-icon {
        color: ${theme.white};
        .anticon .anticon-check .ant-steps-finish-icon {
          margin-bottom: 2px;
        }
      }
      .anticon svg {
        color: ${theme.white};
      }
    }
  }
  .anticon svg {
    color: ${theme.green500};
    &:hover {
      color: ${theme.white};
    }
  }
  .css-d0vblc-emotion--StepsComponent--CustomSteps .anticon svg {
    color: ${theme.white};
    &:hover {
      color: ${theme.white};
    }
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: ${theme.white};
    border: 2px solid ${theme.gray950};
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: ${theme.black};
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background: ${theme.green500};
    &:hover {
      background: ${theme.green500};
    }
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background: ${theme.gray950};
  }
  .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-description {
    color: ${theme.black} !important;
  }
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-icon {
    color: ${theme.black};
    background: ${theme.white};
    border: 2px solid ${theme.gray800};
  }
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-icon
    .ant-steps-icon {
    color: ${theme.black};
  }
`;
export const steps = [
  {
    description: 'Personal Details',
    content: 'PersonalDetails',
    completed: false,
  },
  {
    description: 'Employment Details',
    content: 'Employment ]Details',
    completed: false,
  },
  {
    description: 'Salary Details',
    content: 'SalaryDetails',
    completed: false,
  },
  {
    description: 'Banking Details',
    content: 'BankingDetails',
    completed: false,
  },
  {
    description: 'Completed',
    content: 'Completed',
    completed: false,
  },
];

const StepsComponent: FC<Props> = ({ current, setCurrent }) => {
  const handleChange = (targetCurrent: number) => {
    const targetStep = steps[targetCurrent];

    if (targetStep.completed) {
      setCurrent(targetCurrent);
    }
  };

  return (
    <CustomSteps
      labelPlacement="vertical"
      current={current}
      onChange={(e) => handleChange(e)}>
      {steps.map((item) =>
        item.completed ? (
          <Step
            key={item.description}
            description={item.description}
            status="finish"
          />
        ) : (
          <Step
            key={item.description}
            description={item.description}
            disabled={true}
          />
        ),
      )}
    </CustomSteps>
  );
};

export default StepsComponent;
