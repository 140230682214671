import React, { FC } from 'react';
import theme from '../../theme';
import ViewContract from './contract-view/ViewContract';
import styled from '@emotion/styled';

const Card = styled.div`
  background: ${theme.gray100};
`;
const ContractViewHome: FC = () => {
  return (
    <>
      <Card>
        <ViewContract />
      </Card>
    </>
  );
};
export default ContractViewHome;
