import moment from 'moment';
import React, { FC } from 'react';
import { useParams } from 'react-router';
import { B, Div } from '../../../../components';
import theme from '../../../../theme';
import { isValidMoment } from '../../../../util/isValidMoment';
import { EmployeePaySlipSummary } from '../../../../types/payslipPageTypes';
import { getEmployeeSelectorOption } from './utils/getEmployeeSelectorOption';

interface Props {
  employees: EmployeePaySlipSummary[];
  payrollStartDate?: string | null;
  payrollEndDate?: string | null;
  updatedWorkedDays?: { workedDays: number; userId: number } | null;
}

type RouteParams = {
  employeeKey: string;
};

const SpecialNote: FC<Props> = ({
  employees,
  payrollStartDate,
  payrollEndDate,
  updatedWorkedDays,
}) => {
  const params = useParams<RouteParams>();
  const options = getEmployeeSelectorOption(employees);
  const emp =
    options.find((option) => option.id == Number(params.employeeKey)) || null;

  const usersWorkDays =
    updatedWorkedDays?.userId === Number(params.employeeKey);
  let workedDays = null;
  if (usersWorkDays) {
    workedDays = updatedWorkedDays?.workedDays;
  }

  if (!emp) {
    return null;
  }
  const joinDate = moment(emp.joinedDate);
  const resignDate = moment(emp.resignedDate);
  const payrunStart = moment(payrollStartDate);
  const payrunEnd = moment(payrollEndDate);

  const proRatedValidation = () => {
    if (
      joinDate?.isBetween(payrunStart, payrunEnd) &&
      resignDate?.isBetween(payrunStart, payrunEnd)
    ) {
      return `joined on ${isValidMoment(
        emp.joinedDate,
        '-',
        'YYYY/MM/DD',
      )}, resigned on ${isValidMoment(emp.resignedDate, '-', 'YYYY/MM/DD')}, `;
    } else if (joinDate?.isBetween(payrunStart, payrunEnd)) {
      return `joined on ${isValidMoment(emp.joinedDate, '-', 'YYYY/MM/DD')}, `;
    } else if (resignDate?.isBetween(payrunStart, payrunEnd)) {
      return `resigned on ${isValidMoment(
        emp.resignedDate,
        '-',
        'YYYY/MM/DD',
      )}, `;
    }
  };

  return (
    <>
      {(emp.isProRata || workedDays || emp.workedDays) && (
        <Div
          m="24px 0px"
          p="16px"
          background={theme.lightBlue50}
          borderRadius="4px"
          border={`1px solid ${theme.gray300}`}>
          <B type="b-large-semibold" pb="6px">
            Special Note
          </B>
          <B type="b-default">
            This employee has {proRatedValidation()}
            Please prorate any new allowances you add here according to the
            no.of worked days above
          </B>
        </Div>
      )}
    </>
  );
};

export default SpecialNote;
