import React, { FC, useEffect, useState } from 'react';
import { Col, Row, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import styled from '@emotion/styled';
import { Div } from '../../../../components';
import {
  EmpPayrollI,
  SalaryInformationI,
  getMinimumUserInfo,
} from '../../../../store/actions';
import { DispatchType } from '../../../../store/reducer';
import theme from '../../../../theme';
import { Permission } from '../../../../types';
import { SalaryInformationView } from '../../../../types/payroll.types';
import { isAllowed } from '../../../../util/permissionUtil';
import EmployeeSelector from './EmployeeSelector';
import {
  EmployeePaySlipSummary,
  payrollParam,
} from '../../../../types/payslipPageTypes';
import {
  ArrowBackwardBlue,
  ChevronLeftBlue,
  ChevronRightBlueSVG,
} from '../../../../assets';

const MainContainer = styled.div`
  padding: 8px 32px;
  border-bottom: 1px solid ${theme.gray300};
  @media only screen and (max-width: 768px) {
    padding: 12px 16px;
  }
`;

export const BackButtonSection = styled.div`
  display: flex;
  padding-top: 5px;
  cursor: pointer;
`;

export const BackButtonText = styled.div`
  padding: 1px 0 0 6px;
  font-weight: 500;
  line-height: 20px;
  color: ${theme.blue500};
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const NextPreviousEmployeeDiv = styled.div`
  padding-left: 24px;
  margin: auto;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const NextPreviousEmployeeImage = styled.img`
  cursor: pointer;
`;

interface Props {
  payrollDetails: SalaryInformationI | null;
}

export const PaySlipHeader: FC<Props> = ({ payrollDetails }) => {
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();
  const params = useParams<payrollParam>();
  const isAllowedUser = isAllowed(Permission.VIEW_USER_INFO);
  const [dataSource, setDataSource] = useState<EmployeePaySlipSummary[]>([]);

  const isDraftPayroll = payrollDetails?.status === 'COMPLETED';
  const [sheetIndex, setSheetIndex] = useState(0);

  useEffect(() => {
    if (isAllowedUser) {
      dispatch(getMinimumUserInfo());
    }
  }, []);

  const employeeSelectorMap = (data: EmpPayrollI): EmployeePaySlipSummary => {
    return {
      payRollId: data.id,
      netSalary: data.netSalary,
      name: data.user?.fullName
        ? data.user?.fullName
        : data.user?.preferredName || '',
      userId: Number(data.user?.id),
      employeeNumber: data.user.employeeNumber,
      designation: data.user.designation,
      joinedDate: data.user.joinedDate,
      resignedDate: data.user.resignedDate,
      isProRata: data.isProRata,
      proRataDays: data.proRataDays,
      isSecondary: data.user.isSecondary,
      isEmployeePaidInCash: data.user.isEmployeePaidInCash,
    };
  };

  useEffect(() => {
    if (!isDraftPayroll) {
      const employeeSelectorData = payrollDetails?.empPayroll.map((data) =>
        employeeSelectorMap(data),
      );
      setDataSource(employeeSelectorData || []);
    } else {
      const confirmEmployeeSelectorData: EmployeePaySlipSummary[] = [];
      payrollDetails?.empPayroll.forEach((item) => {
        if (item.isSelected) {
          confirmEmployeeSelectorData.push(employeeSelectorMap(item));
        }
      });
      setDataSource(confirmEmployeeSelectorData || []);
    }
  }, [payrollDetails]);

  useEffect(() => {
    setSheetIndex(
      dataSource.findIndex((x) => x.userId == Number(params.employeeKey)),
    );
  }, [dataSource, sheetIndex]);

  const urlChange = (index: number) => {
    setSheetIndex(index);
    const userSlip = dataSource[index];
    navigate(
      `/personal-salary-information/${params.payrollKey}/employee/${userSlip?.userId}/${params.salaryInformationViewType}`,
    );
  };

  return (
    <>
      <MainContainer>
        <Row justify="space-between">
          <Col>
            <BackButtonSection
              onClick={() => {
                params.salaryInformationViewType ===
                SalaryInformationView.ALL_EMPLOYEE
                  ? navigate(`/Payroll-view/${params.payrollKey}`)
                  : navigate(`/Payroll-view/${params.payrollKey}`, {
                      state: SalaryInformationView.CONFIRM_EMPLOYEE,
                    });
              }}>
              <img src={ArrowBackwardBlue} />
              <BackButtonText>Back to Payrun</BackButtonText>
            </BackButtonSection>
          </Col>
          <Col>
            <Div display="flex">
              <EmployeeSelector
                employees={dataSource}
                sheetIndex={sheetIndex}
                onSelect={(index) => {
                  urlChange(index);
                }}
              />
              {sheetIndex !== 0 && (
                <NextPreviousEmployeeDiv>
                  <Tooltip placement="topLeft" title="Previous Employee">
                    <NextPreviousEmployeeImage
                      onClick={() => urlChange(sheetIndex - 1)}
                      src={ChevronLeftBlue}
                    />
                  </Tooltip>
                </NextPreviousEmployeeDiv>
              )}
              {sheetIndex !== dataSource.length - 1 && (
                <NextPreviousEmployeeDiv>
                  <Tooltip placement="bottomRight" title="Next Employee">
                    <NextPreviousEmployeeImage
                      onClick={() => {
                        urlChange(sheetIndex + 1);
                      }}
                      src={ChevronRightBlueSVG}
                    />
                  </Tooltip>
                </NextPreviousEmployeeDiv>
              )}
            </Div>
          </Col>
        </Row>
      </MainContainer>
    </>
  );
};
