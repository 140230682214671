import React, { FC, useEffect, useState } from 'react';
import { message, Row, Spin } from 'antd';
import { Div, LinkButton } from '../../../components';
import LeaveBalanceTable from './LeaveBalanceTable';
import { LeaveBalancesI } from '../../../types';
import { generateLeaveBalanceExcel } from '../utils/generateLeaveBalanceExcel';
import { useGetAllLeaveBalances } from '../../../api/leaveHooks';
import EmployeesSelector from '../../../components/selector/EmployeesSelector';
import styled from '@emotion/styled';
import { getApiError } from '../../../util/getApiError';
import { DownloadBlue } from '../../../assets';

const CustomRow = styled(Row)`
  padding: 16px 24px;
  width: 100%;
`;

const LeaveBalancePage: FC = () => {
  const [isLeaveTab] = useState(true);
  const [results, setResults] = useState<LeaveBalancesI[]>([]);
  const [leaveColumns, setLeaveColumns] = useState<string[]>([]);
  const [employeesSelector, setEmployeesSelector] = useState<number[]>([]);

  const {
    isLoading: loadingLeaves,
    error: leavesErrors,
    data: leaveData = [],
    refetch: refetchAllLeaveBalances,
  } = useGetAllLeaveBalances();

  useEffect(() => {
    const employee = employeesSelector.map((item) => {
      return { id: item };
    });
    const data = leaveData.filter(({ id: i }) =>
      employee.some(({ id: y }) => y === i),
    );
    setResults(data);
  }, [leaveData, employeesSelector]);

  useEffect(() => {
    const leaveNames: {
      [key: string]: boolean;
    } = {};
    results?.forEach((item) => {
      item.leaveBalances?.forEach((name) => {
        if (!leaveNames[name.leaveName]) {
          leaveNames[name.leaveName] = true;
        }
      });
    });

    setLeaveColumns(Object.keys(leaveNames));
  }, [results]);

  return (
    <>
      {leavesErrors && message.error(getApiError(leavesErrors))}
      <div>
        <CustomRow>
          {isLeaveTab && (
            <Div ml="auto" display="flex" flexDirection="row">
              <EmployeesSelector
                isActive={true}
                onChange={(value) => {
                  setEmployeesSelector(value);
                }}
                employees={employeesSelector}
              />
              <LinkButton
                size="small"
                icon={<img src={DownloadBlue} />}
                onClick={() =>
                  generateLeaveBalanceExcel(results, leaveColumns)
                }>
                Export
              </LinkButton>
            </Div>
          )}
        </CustomRow>
        <Spin spinning={loadingLeaves}>
          <LeaveBalanceTable
            onDataChange={() => refetchAllLeaveBalances()}
            dataSource={results}
            leaveColumns={leaveColumns}
          />
        </Spin>
      </div>
    </>
  );
};

export default LeaveBalancePage;
