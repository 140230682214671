import React, { FC } from 'react';
import { Col, Form, Row } from 'antd';
import styled from '@emotion/styled';
import { B, InputComp } from '../../../../components';
import { Validation } from '../../../../common/validation';

const MainContainer = styled.div`
  padding: 0px 32px 32px;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;
interface Props {
  defaultDisable?: boolean;
}

export const EmergencyForm: FC<Props> = ({ defaultDisable }) => {
  return (
    <MainContainer>
      <B type="b-large-semibold" mb="24px">
        Emergency Details
      </B>

      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name="emergencyContactName" label="Name">
            <InputComp
              size="small"
              placeholder="Name"
              disabled={defaultDisable}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="emergencyContactRelationship"
            label="Relationship"
            rules={[
              {
                required: false,
                message: 'Please enter your relationship!',
              },
            ]}>
            <InputComp
              size="small"
              placeholder="Relationship"
              disabled={defaultDisable}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="emergencyContactMobile"
            label="Mobile Number"
            rules={[
              {
                pattern: Validation.MobileNumberPattern,
                message: 'The input is not valid mobile number!',
              },
            ]}>
            <InputComp
              size="small"
              placeholder="07xxxxxxxx"
              disabled={defaultDisable}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="emergencyContactDaytimeNumber"
            label="Daytime Contact Number"
            rules={[
              {
                required: false,
                message: 'Please enter your daytime contact number!',
              },
              {
                pattern: Validation.MobileNumberPattern,
                message: 'The input is not valid contact number!',
              },
            ]}>
            <InputComp
              size="small"
              placeholder="07xxxxxxxx"
              disabled={defaultDisable}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="emergencyContactAfterHoursNumber"
            label="Afterhours Contact Number"
            rules={[
              {
                required: false,
                message: 'Please Enter your Afterhours contact number!',
              },
              {
                pattern: Validation.MobileNumberPattern,
                message: 'The input is not valid contact number!',
              },
            ]}>
            <InputComp
              size="small"
              placeholder="07xxxxxxxx"
              disabled={defaultDisable}
            />
          </Form.Item>
        </Col>
      </Row>
    </MainContainer>
  );
};
