import {
  ContractStatusEnum,
  ContractStatusI,
  GetContractI,
  UserType,
} from '../../../types/contract.types';

type GetSenderRecipientStatusI = {
  senderStatus: ContractStatusI | null;
  recipientStatus: ContractStatusI | null;
};
export function getSenderRecipientStatus(
  contractStatus: ContractStatusI[],
): GetSenderRecipientStatusI {
  let senderStatus = null;
  let recipientStatus = null;
  contractStatus.forEach((item) => {
    if (item.userType == UserType.SENDER) {
      senderStatus = item;
    } else if (item.userType == UserType.ASSIGNEE) {
      recipientStatus = item;
    }
  });
  return { senderStatus, recipientStatus };
}

type GetContractStatusInfoI = {
  message: string;
  status: ContractStatusEnum;
  sender: null | ContractStatusI;
  recipient: null | ContractStatusI;
};
export function getContractStatusMessage(
  contract?: GetContractI | null | undefined,
): GetContractStatusInfoI {
  const result: GetContractStatusInfoI = {
    message: 'Not found message',
    status: ContractStatusEnum.PENDING,
    sender: null,
    recipient: null,
  };
  if (contract) {
    const { senderStatus, recipientStatus } = getSenderRecipientStatus(
      contract.contractStatus || [],
    );
    result.sender = senderStatus;
    result.recipient = recipientStatus;

    if (contract.status === ContractStatusEnum.ARCHIVED) {
      result.message = 'The contract has been archived.';
    } else if (contract.status === ContractStatusEnum.EXPIRED) {
      result.message = 'The contract has been expired.';
    } else if (contract.status === ContractStatusEnum.COMPLETED) {
      result.message = 'The contract has been completed';
    } else if (contract.status === ContractStatusEnum.SENT) {
      result.message = 'The contract is currently active';
    } else if (recipientStatus) {
      // First we send digital contract to sender. then recipient.
      // if recipient has been signed, that means it is completed. if
      // sender has been received, that means recipient has to sign.
      if (recipientStatus.isSigned) {
        result.message =
          'The both parties have signed and now it is an active contract.';
      } else if (recipientStatus.isSend) {
        // Assume that sender has already signed.
        result.message =
          'The document has signed by the sender but recipient has to sign.';
      }
    } else if (senderStatus) {
      if (!senderStatus.isSigned) {
        result.message =
          'The sender has to sign. Then receiver will receive the contract to sign.';
      }
    } else {
      result.message = 'The contract is still in the edit mode.';
    }
  }

  return result;
}
