import styled from '@emotion/styled';
import { Col, Row } from 'antd';

import React, { FC } from 'react';
import { B } from '../../../../../components';
import { OrganizationsDataByOwnerI } from '../../../../../types/superAdmin.types';
import { numberThousandSeparator } from '../../../../../util/utils';
const Column = styled(Col)`
  display: flex;
  flex-direction: row;
  padding-bottom: 3px;
`;
const UserStats = styled.div`
  background: #fafafa;
  border-radius: 4px;
`;

const UserColumn = styled(Col)`
  padding: 16px 24px 16px 24px;
`;
const Rows = styled(Row)`
  padding: 16px 24px 16px 24px;
`;
interface Props {
  organizationDetails: OrganizationsDataByOwnerI | null;
}
const UserStatsSummary: FC<Props> = ({ organizationDetails }) => {
  return (
    <>
      <UserStats>
        <Row>
          <UserColumn xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <B type="b-default-semibold"> User Stats</B>
          </UserColumn>
        </Row>
        <Rows>
          <Column xs={24} sm={6} md={6} lg={8} xl={6} xxl={6}>
            <B type="b-default">Payroll Enabled Users: </B>
            <B type="b-default-semibold">
              {organizationDetails?.payrollEnabledUsers
                ? numberThousandSeparator(
                    organizationDetails.payrollEnabledUsers,
                  )
                : '0'}
            </B>
          </Column>
          <Column xs={24} sm={4} md={4} lg={8} xl={4} xxl={4}>
            <B type="b-default">Active Users :</B>
            <B type="b-default-semibold">
              {organizationDetails?.activeUsers
                ? numberThousandSeparator(organizationDetails.activeUsers)
                : '0'}
            </B>
          </Column>
          <Column xs={24} sm={6} md={6} lg={8} xl={5} xxl={5}>
            <B type="b-default">Login Allowed Users :</B>
            <B type="b-default-semibold">
              {organizationDetails?.loginAllowedUsers
                ? numberThousandSeparator(organizationDetails.loginAllowedUsers)
                : '0'}
            </B>
          </Column>
          <Column xs={24} sm={4} md={4} lg={8} xl={5} xxl={5}>
            <B type="b-default">Archived Users :</B>
            <B type="b-default-semibold">
              {organizationDetails?.archivedUsers
                ? numberThousandSeparator(organizationDetails.archivedUsers)
                : '0'}
            </B>
          </Column>
          <Column xs={24} sm={4} md={4} lg={8} xl={4} xxl={4}>
            <B type="b-default">All Users(count):</B>
            <B type="b-default-semibold">
              {organizationDetails?.allUsers
                ? numberThousandSeparator(organizationDetails.allUsers)
                : '0'}
            </B>
          </Column>
        </Rows>
      </UserStats>
    </>
  );
};

export default UserStatsSummary;
