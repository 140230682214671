import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import {
  ChangeNotificationStatusI,
  Reminders,
} from '../types/notification.types';

export interface GetRemindersParams {
  status: string;
  page: number;
}
export const useGetReminderList = () => {
  return useMutation<Reminders[], AxiosError, GetRemindersParams>(
    'reminderList',
    async (params: GetRemindersParams) => {
      const response: AxiosResponse<
        Reminders[]
      > = await axios.get(`/notification/reminder-list`, { params });
      return response.data;
    },
  );
};

export const useChangeNotificationStatus = () => {
  return useMutation<string, AxiosError, ChangeNotificationStatusI>(
    async (data: ChangeNotificationStatusI) => {
      const response: AxiosResponse<string> = await axios.put(
        '/notification/changeStatus',
        data,
      );
      return response.data;
    },
  );
};
