import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import {
  LogTrailTable,
  FooterComponent,
  SecondaryButton,
} from '../../../../components';
import { useGetAttendanceLogs } from '../../../../api/attendanceHooks';

const AttendanceLogTrail: FC = () => {
  const navigate = useNavigate();

  const {
    isLoading: loadingAttendanceLogs,
    data: dataAttendanceLogs = [],
    mutate: getAttendanceLogs,
  } = useGetAttendanceLogs();

  useEffect(() => {
    getAttendanceLogs();
  }, []);

  return (
    <>
      <LogTrailTable
        logs={dataAttendanceLogs}
        loading={loadingAttendanceLogs}
      />
      <FooterComponent
        leftChildren={
          <SecondaryButton onClick={() => navigate(-1)}>Back</SecondaryButton>
        }
      />
    </>
  );
};
export default AttendanceLogTrail;
