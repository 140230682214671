import styled from '@emotion/styled';
import React, { FC } from 'react';
import Dropzone, { DropzoneProps } from 'react-dropzone';
import { CSVicon, UploadDocumentSVG } from '../../assets';
import theme from '../../theme';
import { B } from '../B';
import { Div } from '../Div';

interface Props extends DropzoneProps {
  isUploaded?: boolean;
}
const Card = styled.div`
  background: ${theme.gray25};
  border: 1px dashed ${theme.blue450};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 55px;
  display: flex;
  flex-direction: column;
  text-align: center;
`;
const DragAndDrop: FC<Props> = ({ ...props }) => {
  return (
    <Dropzone {...props}>
      {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => {
        const additionalClass = isDragAccept
          ? 'accept'
          : isDragReject
          ? 'reject'
          : '';

        return (
          <Card
            {...getRootProps({
              className: `dropzone ${additionalClass}`,
            })}>
            <input {...getInputProps()} />
            {props.isUploaded ? (
              <img
                src={CSVicon}
                style={{ maxWidth: '100%', maxHeight: '75px' }}
                alt="Preview"
              />
            ) : (
              <>
                <div className="ant-upload-drag-icon">
                  <img src={UploadDocumentSVG} alt="Upload Icon" />
                </div>
                <Div
                  textAlign="center"
                  display="flex"
                  flexDirection="row"
                  paddingX="84px">
                  <B type="b-large-semibold" color={theme.gray600} pt="8px">
                    Drag and drop or
                  </B>
                  &nbsp;
                  <B
                    type="b-large-semibold"
                    color={theme.blue400}
                    marginBottom="0px"
                    pt="8px">
                    Browse
                  </B>
                </Div>
                <B type="b-default" color={theme.gray600} pt="4px">
                  Upload your document
                </B>
              </>
            )}
          </Card>
        );
      }}
    </Dropzone>
  );
};

export default DragAndDrop;
