import { css } from '@emotion/react';
import { Col, Form, Row } from 'antd';
import React, { useEffect } from 'react';
import Dragger from 'antd/es/upload/Dragger';
import { FC } from 'react';
import { ImageUploadSVG } from '../../../assets';
import {
  B,
  DatePickerComp,
  DatePickerV2,
  Div,
  InputComp,
  ModalComponent,
  SelectComp,
} from '../../../components';
import theme from '../../../theme';
import styled from '@emotion/styled';
import { IApproveModalData } from '../../../types/attendance.types';
import { getTextOnly } from '../../../util/getTextOnly';
interface Props {
  onClose: () => void;
  onSubmit: () => void;
  visible: boolean;
  buttonDisabled?: boolean;
  loading: boolean;
  data: IApproveModalData;
}

const StyledDiv = styled(Div)`
  padding: 16px;
  background: ${theme.gray50};
  border-radius: 4px;
  display: grid;
  gap: 12px;
  margin: 16px 0;
`;
const ApproveModal: FC<Props> = ({
  onClose,
  onSubmit,
  visible,
  buttonDisabled,
  loading,
  data,
}) => {
  const [form] = Form.useForm();

  return (
    <ModalComponent
      form={form}
      visible={visible}
      loading={loading}
      onCancel={() => onClose()}
      onSubmit={() => onSubmit()}
      width={540}
      submitText="Confirm"
      title="Confirmation">
      <B type="b-default">Confirm timesheet for following details</B>
      <StyledDiv>
        <Row>
          <Col span={12}>
            <B type="b-default" color={theme.gray600}>
              Time Attendance
            </B>
          </Col>
          <Col span={12}>
            <B type="b-default" color={theme.gray600}>
              {getTextOnly(data?.month)}
            </B>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <B type="b-default" color={theme.gray600}>
              Upload Date
            </B>
          </Col>
          <Col span={12}>
            <B type="b-default" color={theme.gray600}>
              {data?.uploadedDate}
            </B>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <B type="b-default" color={theme.gray600}>
              Month/Year
            </B>
          </Col>
          <Col span={12}>
            <B type="b-default" color={theme.gray600}>
              {data?.monthYear}
            </B>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <B type="b-default" color={theme.gray600}>
              Number of Employees
            </B>
          </Col>
          <Col span={12}>
            <B type="b-default" color={theme.gray600}>
              {data?.noOfEmployees}
            </B>
          </Col>
        </Row>
      </StyledDiv>
      <B type="b-default">
        I understand that I cannot edit or delete this time attendance once I
        confirm
      </B>
    </ModalComponent>
  );
};

export default ApproveModal;
