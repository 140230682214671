const debug = 'debug';
const log = 'log';
const info = 'info';
const warn = 'warn';
const error = 'error';

const level = process.env.NODE_ENV === 'development' ? debug : error;

const logger = {
  debug(...params: any[]) {
    if ([debug].indexOf(level) > -1) {
      console.log('logger debug:', ...params);
    }
  },
  log(...params: any[]) {
    if ([debug, log].indexOf(level) > -1) {
      console.log('logger log:', ...params);
    }
  },
  info(...params: any[]) {
    if ([debug, log, info].indexOf(level) > -1) {
      console.log('logger debug:', ...params);
    }
  },
  warn(...params: any[]) {
    if ([debug, log, info, warn].indexOf(level) > -1) {
      console.log('logger debug:', ...params);
    }
  },
  error(...params: any[]) {
    if ([debug, log, info, warn, error].indexOf(level) > -1) {
      console.log('logger debug:', ...params);
    }
  },
};

export default logger;
