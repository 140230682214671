import React, { FC, useEffect, useState } from 'react';
import { Col, Dropdown, Menu, Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { B, DeleteModal, H, LinkButton } from '../../../../components';
import { EmptyPage } from '../../../../components/EmptyComponent';
import TableComp from '../../../../components/TableComp';
import theme from '../../../../theme';
import { useWindowSize } from '../../../../util/screenSize';
import UserPeopleSelect from '../../../people/shared/UserPeopleSelect';
import { FileUploadModal } from '../../../people/pages/document/FileUploadModal';
import {
  useDeleteMeDocument,
  useGetMeDocument,
  useUserFileUpload,
} from '../../../../api/documentHooks';
import { DocumentListI } from '../../../../types/document.types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducer';
import _ from 'lodash';
import { MoreSVG, PlusBlueSVG } from '../../../../assets';

const LastColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: flex-end;
`;

const DropdownMenu = styled(Menu)`
  width: 200px;
  padding: 0px;
  border: 0.5px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .ant-dropdown-menu-item > a {
    padding: 12px 0px 12px 16px;
  }
`;

const MainContainer = styled.div`
  padding: 32px;
  height: 75vh;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const HeadingSection = styled(Row)`
  margin-bottom: 32px;
  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

const UploadMobileSection = styled(Col)`
  padding-left: 25px;
  display: flex;
  justify-content: flex-end;
  @media (min-width: 1025px) {
    display: none;
  }
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const UploadDeskSection = styled(Col)`
  padding-left: 25px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const MoreIcon = styled.img`
  cursor: pointer;
  margin: auto;
`;
export const DocumentDetailTable: FC = () => {
  const { isMobile, isTablet, isDesktopLarge } = useWindowSize();
  const [visible, setVisible] = useState(false);
  const me = useSelector((state: RootState) => state.people.me);

  const {
    error: errorDocumentList,
    data: meDocumentList = [],
    refetch: getMeDocument,
  } = useGetMeDocument();

  const {
    isLoading: loadingAddMeDocuments,
    error: errorUploadDocument,
    mutate: uploadDocument,
    data: uploadDocumentForData,
  } = useUserFileUpload();

  const {
    isLoading: loadingDeleteMeDocument,
    error: errorDeleteMeDocument,
    mutate: deleteMeDocument,
    data: deleteMeDocumentForData,
  } = useDeleteMeDocument();

  const deleteDocument = (documentId: number) => {
    deleteMeDocument(String(documentId));
  };

  useEffect(() => {
    if (
      (uploadDocumentForData && !errorUploadDocument) ||
      (deleteMeDocumentForData && !errorDeleteMeDocument)
    ) {
      getMeDocument();
    }
  }, [uploadDocumentForData, deleteMeDocumentForData]);

  const columns: ColumnProps<DocumentListI>[] = [
    {
      title: 'Document',
      dataIndex: 'title',
      render: (value: string) => (
        <p
          style={{
            margin: 0,
            fontSize: 12,
            cursor: 'pointer',
          }}>
          {value || '-'}
        </p>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (_value: string, data) => {
        return (
          <p
            style={{
              margin: 0,
              fontSize: 12,
              cursor: 'pointer',
            }}>
            {data.createdAt
              ? moment(data.createdAt).format('YYYY MMMM Do')
              : '-'}
          </p>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (_value: string, data) => (
        <p
          style={{
            margin: 0,
            fontSize: 12,
            cursor: 'pointer',
          }}>
          {data.type ? data.type : '-'}
        </p>
      ),
    },
    {
      title: '',
      dataIndex: 'menu',
      key: 'menu',
      render: (_value, data) => (
        <LastColumn>
          <Dropdown
            overlay={
              <DropdownMenu>
                <Menu.Item
                  css={css`
                    border-bottom: 1px solid ${theme.gray300};
                  `}>
                  <a href={data.url} download target="_blank" rel="noreferrer">
                    Download
                  </a>
                </Menu.Item>
                {Number(data.uploadedBy) === data.userId ? (
                  <Menu.Item>
                    <DeleteModal
                      title="Delete"
                      buttonLabel="Delete"
                      content="Are you sure you want to delete ?"
                      onClickCancel={() => {
                        deleteDocument(data.id);
                      }}
                      openModal={
                        <B py="10px" pl="6px" type="b-default">
                          Delete
                        </B>
                      }></DeleteModal>
                  </Menu.Item>
                ) : null}
              </DropdownMenu>
            }>
            <a>
              <MoreIcon className="ant-dropdown-link" src={MoreSVG} />
            </a>
          </Dropdown>
        </LastColumn>
      ),
    },
  ];
  return (
    <>
      {errorDocumentList ? (
        <div>
          <EmptyPage
            content="Sorry, the page you visited does not exist."
            title="something went wrong !"
            buttonLabel=" Back Home"
          />
        </div>
      ) : (
        <MainContainer>
          <HeadingSection>
            <Col xs={0} sm={0} md={0} lg={0} xl={16} xxl={19}>
              <H type="h6">Document</H>
            </Col>
            <UserPeopleSelect defaultIndex="5" />

            <UploadMobileSection xs={12} sm={10} md={12} lg={12} xl={8} xxl={5}>
              <Row justify="end">
                <LinkButton
                  id="upload-new-doc"
                  onClick={() => setVisible(true)}
                  icon={<img src={PlusBlueSVG} />}>
                  Upload
                </LinkButton>
              </Row>
            </UploadMobileSection>
            <UploadDeskSection xs={12} sm={10} md={12} lg={12} xl={8} xxl={5}>
              <Row justify="end">
                <LinkButton
                  id="upload-new-doc"
                  onClick={() => setVisible(true)}
                  icon={<img src={PlusBlueSVG} />}>
                  Upload New Document
                </LinkButton>
              </Row>
            </UploadDeskSection>
          </HeadingSection>

          <TableComp
            size="middle"
            css={css`
              height: ${isDesktopLarge ? '90%' : '80%'};
              background: ${theme.gray50};
              .ant-table-tbody > tr > td {
                background: ${meDocumentList.length == 0
                  ? theme.gray50
                  : theme.white};
                border-bottom: ${meDocumentList.length == 0
                  ? 'none'
                  : '1px solid #e0e0e0;'};
              }
            `}
            columns={columns}
            dataSource={meDocumentList}
            pagination={false}
            loading={loadingAddMeDocuments || loadingDeleteMeDocument}
            withBorders={true}
          />
          <div
            style={{
              display: isMobile && !isTablet ? 'block' : 'flex',
              flexDirection: isMobile && !isTablet ? 'unset' : 'row-reverse',
              alignItems: 'flex-end',
              paddingTop: '100px',
            }}></div>
          {visible && (
            <FileUploadModal
              visible={visible}
              handleCancel={() => {
                setVisible(false);
              }}
              onSubmit={async (values, file) => {
                const data = {
                  title: values ? values : '',
                  type: 'FILE',
                  userId: `${_.get(me, 'id', '')}`,
                };
                uploadDocument({ file: file, ...data });
                setVisible(false);
              }}
            />
          )}
        </MainContainer>
      )}
    </>
  );
};
