import React, { FC } from 'react';
import { Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { B, InputComp, PrimaryButton, Tag } from '../../../../components';
import { useWindowSize } from '../../../../util/screenSize';
import styled from '@emotion/styled';
import { DispatchType, RootState } from '../../../../store';
import { CustomizationEnum } from '../../../../types/organization.types';
import { updateCustomization } from '../../../../store/actions';
import { validateField } from '../../util/utils';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  background-clip: border-box;
  padding: 32px;
  @media (max-width: 760px) {
    padding: 32px 10px;
    border-bottom: 1px solid #e0e0e0;
  }
`;
const FormItem = styled(Form.Item)`
  .ant-col-10 {
    max-width: 100%;
  }
`;
const Input = styled(InputComp)`
  .ant-select-selection-item {
    background: rgba(51, 117, 238, 0.1);
    border-radius: 4px;
    color: #0042bb;
  }
  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    background: #0042bb;
  }
`;

const Teams: FC = () => {
  const { isMobile } = useWindowSize();
  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const dispatch: DispatchType = useDispatch();
  const teams: string[] = organizationData?.accessGroups || [];
  const [form] = Form.useForm();

  async function handleSubmit(data: any) {
    const newText: string = data.team;
    const trimmedValue: string = newText.trim();
    if (trimmedValue) {
      const newList = [...new Set<string>([...teams, trimmedValue])];
      if (organizationData) {
        form.resetFields();
        dispatch(updateCustomization(newList, CustomizationEnum.GROUP));
      }
    }
  }

  async function removeByIndex(index: number) {
    if (organizationData) {
      const newList = [...teams];
      newList.splice(index, 1);
      dispatch(updateCustomization(newList, CustomizationEnum.GROUP));
    }
  }
  // const handleKeyPress = (evt: KeyboardEvent) => {
  //   if (evt.key === 'Enter') {
  //     if (organizationData) {
  //       const newList = [...teams];
  //       form.resetFields();
  //       dispatch(updateCustomization(newList, 'TEAMS'));
  //     }
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener('keydown', handleKeyPress);
  //   return () => {
  //     window.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, []);
  return (
    <MainContainer>
      <Form
        form={form}
        name="form"
        onFinish={handleSubmit}
        validateTrigger="onBlur">
        <B type="b-default-semibold" color="#00318C" pb="4px">
          Teams
        </B>
        <Row gutter={[32, 16]}>
          <Col xs={24} sm={24} md={12} lg={20} xl={20}>
            <FormItem
              rules={[
                {
                  required: true,
                  message: 'Enter team!',
                },
                {
                  validator: (_, value) => {
                    return validateField(value, {
                      minLength: 1,
                      maxLength: 50,
                    });
                  },
                },
              ]}
              name="team">
              <Input name="team" placeholder="Enter your access groups names" />
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={12} lg={4} xl={4}>
            <PrimaryButton id="add-team" block={isMobile} htmlType="submit">
              Add
            </PrimaryButton>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {teams.map((team, i) => (
              <Tag onClose={() => removeByIndex(i)}>{team}</Tag>
            ))}
          </Col>
        </Row>
      </Form>
    </MainContainer>
  );
};

export default Teams;
