import React from 'react';
import OnboardingFormNavBar from './OnboardingFormNavBar';
import { Div, H } from '../../components';

const NoAccessPage = () => {
  return (
    <>
      <OnboardingFormNavBar />
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop={100}>
        <H fontSize={48} fontWeight={600} marginBottom={18}>
          Oops!
        </H>
        <H fontSize={36} fontWeight={400}>
          You do not have access to this page!
        </H>
      </Div>
    </>
  );
};

export default NoAccessPage;
