import React, { FC, ReactElement } from 'react';
import { Typography } from 'antd';
import theme from '../../theme';
import styled from '@emotion/styled';
const { Text } = Typography;

interface Props {
  label?: string;
  children?: ReactElement | null;
  flexDirection?: string;
}

const LabeledInput = styled.div<{ flexDirection?: string }>`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection === 'column' ? 'column' : 'row'};
  align-items: ${(props) => props.flexDirection === 'row' && 'flex-start'};
  justify-content: ${(props) =>
    props.flexDirection !== 'column' && 'space-between'};
  @media screen and (max-width: 768px) {
    align-items: ${(props) =>
      props.flexDirection === 'row' ? 'flex-start' : 'center'};
    flex-direction: ${(props) =>
      props.flexDirection === 'column' ? 'column' : 'row'};
  }
`;

const Label = styled(Text)<{ flexDirection?: string }>`
  padding-top: 7.5px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.blue700};
  padding-bottom: ${(props) => props.flexDirection === 'column' && '4px'};

  @media screen and (max-width: 768px) {
    ${(props) => props.flexDirection === 'row' && 'margin-bottom: 8px;'}
  }
`;
export const LabeledInputV2: FC<Props> = (props) => {
  const { label, children = null, flexDirection = 'column' } = props;

  return (
    <LabeledInput flexDirection={flexDirection}>
      {label ? <Label flexDirection={flexDirection}>{label}</Label> : null}
      {children}
    </LabeledInput>
  );
};
