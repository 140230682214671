import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { B, Div } from '../../../../../components';
import theme from '../../../../../theme';
import { PayrollItemI } from '../../../../../types/payroll.types';
import { SummaryDataI } from '../../../../../types/people.types';
import { numberThousandSeparator } from '../../../../../util/utils';
import { SalaryItem } from './SalaryItem';

const CutsCol = styled(Col)`
  text-align: end;
`;
interface Props {
  employment?: SummaryDataI;
  payItem: PayrollItemI[];
}
const PayDetails: FC<Props> = ({ employment, payItem }) => {
  const earningsItem = payItem.filter((item) => item.type != 'DEDUCTION');
  const deductionItem = payItem.filter((item) => item.type === 'DEDUCTION');

  return (
    <Div
      p="24px 16px 16px "
      background="#FAFAFA"
      border={` 0.5px solid ${theme.gray300}`}
      borderRadius="4px">
      <B type="b-small-semibold" color={theme.black} pb="12px">
        Earnings
      </B>
      <SalaryItem salaryItems={earningsItem} />
      {deductionItem.length != 0 && (
        <>
          <B type="b-small-semibold" color={theme.black} pb="12px">
            Deduction
          </B>
          <SalaryItem salaryItems={deductionItem} />
        </>
      )}

      <Row gutter={[32, 12]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small-semibold" color={theme.black}>
            Net pay
          </B>
        </Col>
        <CutsCol xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small-semibold" color={theme.black}>
            {numberThousandSeparator(employment?.netSalary || 0)}
          </B>
        </CutsCol>
      </Row>
      <B type="b-small-semibold" color={theme.black} pb="12px">
        Employer Contribution
      </B>
      <Row gutter={[32, 12]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small" color={theme.gray600}>
            EPF 12%
          </B>
        </Col>
        <CutsCol xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small" color={theme.black}>
            {numberThousandSeparator(employment?.employerEPF || 0)}
          </B>
        </CutsCol>
      </Row>
      <Row gutter={[32, 12]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small" color={theme.gray600}>
            ETF 3%
          </B>
        </Col>
        <CutsCol xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small" color={theme.black}>
            {numberThousandSeparator(employment?.employerETF || 0)}
          </B>
        </CutsCol>
      </Row>

      <B type="b-small-semibold" color={theme.black} pb="12px">
        Employee Contribution
      </B>
      <Row gutter={[32, 12]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small" color={theme.gray600}>
            EPF 8%
          </B>
        </Col>
        <CutsCol xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small" color={theme.black}>
            {numberThousandSeparator(employment?.employeeEPF || 0)}
          </B>
        </CutsCol>
      </Row>
      <Row gutter={[32, 12]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small" color={theme.gray600}>
            APIT Table1
          </B>
        </Col>
        <CutsCol xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small" color={theme.black}>
            {numberThousandSeparator(employment?.apitTable1Amount || 0)}
          </B>
        </CutsCol>
      </Row>
      <Row gutter={[32, 12]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small-semibold" color={theme.black}>
            Cost to company
          </B>
        </Col>
        <CutsCol xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small-semibold" color={theme.black}>
            {numberThousandSeparator(employment?.costToCompany || 0)}
          </B>
        </CutsCol>
      </Row>
    </Div>
  );
};

export default PayDetails;
