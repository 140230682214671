import React, { FC, useEffect, useState } from 'react';

import { Dropdown, Menu, message } from 'antd';
import { useDispatch } from 'react-redux';

import styled from '@emotion/styled';

import {
  useDeletePayslipField,
  useEditPayslipField,
} from '../../../../../api/payrollHooks';
import { Div } from '../../../../../components';
import { getAllPayrollDetails } from '../../../../../store/actions';
import theme from '../../../../../theme';
import { getApiError } from '../../../../../util/getApiError';
import ThreeDotPopover from '../../../../contract/shared/ThreedotPopover';
import AddEditPayslipField from './AddEditPayslipField';
import { DispatchType } from '../../../../../store';
import { MoreSVG } from '../../../../../assets';

const PayslipFieldRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 34px 12px 24px;
  border-bottom: 0.5px solid ${theme.gray300};
`;

const MenuItem = styled(Menu.Item)`
  border-bottom: 1px solid ${theme.gray300};
`;

const ThreeDotImg = styled.img`
  cursor: pointer;
  margin-left: 10px;
`;

interface CustomPaySlipFieldProps {
  keyName: string;
  value: string;
  isCompletedPayRun: boolean;
  empPayrollId: number;
  payrollId: number;
}

const CustomPaySlipField: FC<CustomPaySlipFieldProps> = ({
  keyName,
  value,
  isCompletedPayRun,
  empPayrollId,
  payrollId,
}) => {
  const [visible, setVisible] = useState(false);
  const dispatch: DispatchType = useDispatch();
  const {
    error: errorEditPayslipField,
    data: dataEditPayslipField,
    mutate: editPayslipField,
  } = useEditPayslipField();

  const {
    error: errorDeletePayslipField,
    data: dataDeletePayslipField,
    mutate: deletePayslipField,
  } = useDeletePayslipField();

  // success handling
  useEffect(() => {
    if (dataEditPayslipField && !errorEditPayslipField) {
      message.success('Custom Payslip field edited Successfully!');
      payrollId && dispatch(getAllPayrollDetails(payrollId.toString()));
    }
    if (dataDeletePayslipField && !errorDeletePayslipField) {
      message.success('Custom Payslip field deleted Successfully!');
      payrollId && dispatch(getAllPayrollDetails(payrollId.toString()));
    }
  }, [dataEditPayslipField, dataDeletePayslipField]);

  // Error handling
  useEffect(() => {
    if (errorEditPayslipField) {
      message.error(getApiError(errorEditPayslipField));
    }
    if (errorDeletePayslipField) {
      message.error(getApiError(errorDeletePayslipField));
    }
  }, [errorEditPayslipField, errorDeletePayslipField]);

  return (
    <>
      <PayslipFieldRow>
        <ThreeDotPopover text={keyName} maxLength={15} />
        <Div display="flex" pb="12px">
          <ThreeDotPopover text={value} maxLength={15} />
          {!isCompletedPayRun ? (
            <Dropdown
              overlay={
                <Menu>
                  <MenuItem key="edit" onClick={() => setVisible(true)}>
                    Edit
                  </MenuItem>
                  <Menu.Item
                    key="delete"
                    onClick={() => {
                      deletePayslipField({
                        empPayrollId,
                        key: keyName,
                        value: value,
                      });
                    }}>
                    Delete
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}>
              <ThreeDotImg
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                src={MoreSVG}
              />
            </Dropdown>
          ) : null}
        </Div>
      </PayslipFieldRow>
      {visible && (
        <AddEditPayslipField
          keyName={keyName}
          inputValue={value}
          setVisible={(visible) => {
            setVisible(visible);
          }}
          onClickEdit={(data) => editPayslipField({ ...data, empPayrollId })}
        />
      )}
    </>
  );
};

export default CustomPaySlipField;
