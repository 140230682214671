import React, { FC, useEffect } from 'react';
import { Badge, Popover } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import { B, Div, LinkButton, NameTag } from '../../../../components';
import { useIndividualPeopleState } from '../../../../providers/IndividualPeopleState';
import theme from '../../../../theme';
import { Permission } from '../../../../types';
import { UserHistoryI } from '../../../../types/people.types';
import { isValidMoment } from '../../../../util/isValidMoment';
import { isAllowed } from '../../../../util/permissionUtil';
import { useWindowSize } from '../../../../util/screenSize';
import {
  addDotsForLongText,
  capitalizeUnderscore,
  isHaveThreeDots,
} from '../../../../util/utils';
import { durationByYearAndMonth } from '../../PeopleUtil';
import { EditSVG } from '../../../../assets';

const MainContainer = styled.div`
  margin-bottom: 24px;
  padding: 16px 24px 24px;
  width: 100%;
  border-radius: 4px;
  border: 0.5px solid ${theme.gray400};
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  id?: string;
  experience: UserHistoryI;
  onClickEdit: () => void;
}

type UseParamI = {
  employeeKey: string;
};

export const Experience: FC<Props> = ({ experience, id, onClickEdit }) => {
  const params = useParams<UseParamI>();
  const { isDesktopLarge, isDesktop } = useWindowSize();
  const jotTitleMaxLenght = isDesktopLarge ? 70 : isDesktop ? 40 : 30;
  const designation = experience?.designation || '-';

  const {
    state: { people },
    fetchPeopleById,
  } = useIndividualPeopleState();

  useEffect(() => {
    fetchPeopleById(params.employeeKey);
  }, [params.employeeKey]);

  return (
    <>
      <MainContainer>
        <HeaderSection>
          <Div display="flex" m="auto 0">
            <B type="b-large-semibold">
              {isHaveThreeDots(designation, jotTitleMaxLenght) ? (
                <Popover
                  content={designation}
                  trigger="hover"
                  placement="topLeft">
                  {addDotsForLongText(designation, jotTitleMaxLenght)}
                </Popover>
              ) : (
                designation
              )}
            </B>
            {experience.isCurrentJob && !people?.isArchived && (
              <Div m="auto" pl="15px">
                <NameTag textColor={theme.green300} color={theme.green50}>
                  Current
                </NameTag>
              </Div>
            )}
          </Div>
          {isAllowed(Permission.CREATE_EDIT_USERS) && (
            <LinkButton
              icon={<img width="18px" height="18px" src={EditSVG} />}
              id={id}
              onClick={() => onClickEdit()}>
              Edit
            </LinkButton>
          )}
        </HeaderSection>
        <div>
          <Div display="flex" pb="8px" pt="4px">
            <B type="b-default" pr="8px">
              {experience?.branchName}
            </B>
            <Badge size="default" color={theme.black} />
            <B type="b-default" color={theme.gray600} pl="8px">
              {experience.employmentType &&
                capitalizeUnderscore(experience.employmentType)}
            </B>
          </Div>
          <Div display="flex" pb="8px">
            <B type="b-default" color={theme.gray600}>
              {isValidMoment(
                experience?.startDate,
                undefined,
                'DD.MM.YYYY',
              ).toString()}
              &nbsp;-&nbsp;
              {experience.endDate
                ? isValidMoment(
                    experience?.endDate,
                    undefined,
                    'DD.MM.YYYY',
                  ).toString()
                : people?.isArchived
                ? isValidMoment(
                    people.archiveDate,
                    undefined,
                    'DD.MM.YYYY',
                  ).toString()
                : 'Present'}
            </B>
            <B type="b-default" pl="4px" color={theme.gray600}>
              (
              {durationByYearAndMonth(
                moment(experience?.startDate).format('DD.MM.YYYY'),
                experience?.endDate
                  ? moment(experience?.endDate).format('DD.MM.YYYY')
                  : moment().format('DD.MM.YYYY'),
              )}
              )
            </B>
          </Div>
          <Div display="flex">
            <B type="b-default" color={theme.gray600}>
              Manager :
            </B>
            <B type="b-default" color={theme.gray600} pl="4px">
              {experience?.manager &&
              isHaveThreeDots(experience?.manager.fullName, 25) ? (
                <Popover
                  content={<>{experience?.manager}</>}
                  trigger="hover"
                  placement="topLeft">
                  {addDotsForLongText(experience?.manager.fullName, 25)}
                </Popover>
              ) : experience?.manager ? (
                experience?.manager.fullName
              ) : (
                '-'
              )}
            </B>
          </Div>
        </div>
      </MainContainer>
    </>
  );
};

export default Experience;
