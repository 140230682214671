import React, { FC } from 'react';
import { Form } from 'antd';
import { B, InputComp } from '../../../components';
import theme from '../../../theme';
import styled from '@emotion/styled';
import { CreateOwnerI } from '../../../types/superAdmin.types';
import { ModalComponent } from '../../../components/ModalComponent';
import { Validation } from '../../../common/validation';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const FormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;
const LastFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;
const FormContainer = styled(Form)`
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-bottom: 0px;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: none;
  }
  .ant-form-item-label > label {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.blue700};
    letter-spacing: 0.2px;
  }
  .ant-input[disabled] {
    color: ${theme.black};
  }

  .input-delete-icon {
    color: ${theme.gray600};
  }
  .ant-form-item-label {
    padding: 4px !important;
  }
`;
interface Props {
  visible: boolean;
  ownerName: string | undefined;
  handleCancel: () => void;
  onSubmit: (formValues: CreateOwnerI) => void;
}
const CreateOrganizationModal: FC<Props> = ({
  handleCancel,
  onSubmit,
  visible,
  ownerName,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values: CreateOwnerI): void => {
    const value = { ...values, isNewOwner: false };
    onSubmit(value);
  };

  const isOwnerLoading = useSelector(
    (state: RootState) => state.superAdmin.putOwnerLoading,
  );

  return (
    <ModalComponent
      form={form}
      onCancel={handleCancel}
      disabled={isOwnerLoading}
      submitText="Create"
      centered
      width={540}
      visible={visible}
      title={<B type="b-large-semibold">Create Organization</B>}>
      <>
        <FormContainer
          form={form}
          layout="vertical"
          onFinish={(v) => onFinish(v as CreateOwnerI)}>
          <B type="b-default" mb="16px" color={theme.gray600}>
            Organization owner is {ownerName ?? '-'}
          </B>

          <FormItem
            label="Organization Name"
            name="organizationName"
            rules={[
              {
                required: true,
                message: 'Please enter your organization name!',
              },
            ]}>
            <InputComp placeholder="Enter your organization name" />
          </FormItem>
          <FormItem
            name="organizationEmail"
            label="Organization Email"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Please enter the email',
              },
              {
                type: 'email',
                message: 'Please enter valid email',
              },
            ]}>
            <InputComp type="email" placeholder="Enter email" />
          </FormItem>
          <FormItem
            name="organizationAddress"
            label="Address"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Please enter the address',
              },
            ]}>
            <InputComp placeholder="Enter address" />
          </FormItem>
          <FormItem
            name="industry"
            label="Industry"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Please enter the industry',
              },
            ]}>
            <InputComp placeholder="Enter industry" />
          </FormItem>
          <LastFormItem
            name="organizationTelephoneNumber"
            label="Telephone"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Please enter the telephone',
              },
              {
                pattern: Validation.MobileNumberPattern,
                message: 'The input is not valid telephone number!',
              },
            ]}>
            <InputComp placeholder="Enter telephone" />
          </LastFormItem>
        </FormContainer>
      </>
    </ModalComponent>
  );
};

export default CreateOrganizationModal;
