import React, { FC, useState } from 'react';
import { DatePicker } from 'antd';
import { ContractStatusEnum } from '../../../../types/contract.types';
import { useZoom } from '../../shared/ZoomContext';
import { DraggableContainer } from './containers/DraggableContainer';
import { PlaceholderBoxPropsI } from './PlaceholderBoxProps';
import dayjs from 'dayjs';
import { Div, Image } from '../../../../components';
import { BlackCalender } from '../../../../assets';

const DatePlaceHolderBox: FC<PlaceholderBoxPropsI> = ({
  onAction,
  isSelected,
  isReceiver,
  isEditable,
  value,
  onChange,
  contractStatus,
}) => {
  const { ratio } = useZoom();
  const [dateSelected, setDateSelected] = useState<string | null>(
    value || null,
  );
  if (contractStatus === ContractStatusEnum.COMPLETED) {
    return null;
  }
  return (
    <>
      <DraggableContainer
        isReceiver={isReceiver}
        selected={isSelected}
        showPoints={isEditable}
        onAction={onAction}>
        <div className={!isEditable ? 'input-container' : 'container'}>
          {!isEditable ? (
            <DatePicker
              placeholder="Date"
              defaultValue={value ? dayjs(value) : undefined}
              onChange={(_d, dateString: string) => {
                if (onChange) {
                  onChange(dateString as string);
                  setDateSelected(
                    dateString != '' ? (dateString as string) : null,
                  );
                }
              }}
              suffixIcon={dateSelected && null}
            />
          ) : (
            <Div className="date-input" display="flex" flexDirection="row">
              <label
                style={{
                  fontSize: 12 * ratio,
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <img
                  src={BlackCalender}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 7 * ratio,
                    width: 18 * ratio,
                    height: 18 * ratio,
                    pointerEvents: 'none',
                  }}
                />
                Date
              </label>
            </Div>
          )}
        </div>
      </DraggableContainer>
    </>
  );
};
export default DatePlaceHolderBox;
