import { css } from '@emotion/react';
import React, { FC, useEffect, useState } from 'react';
import { B, DeleteModal, Div, TableComp } from '../../../components';
import { ColumnProps } from 'antd/lib/table';
import { Dropdown, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAccountants,
  removeExternalAccountant,
} from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import { useWindowSize } from '../../../util/screenSize';
import moment from 'moment';
import { Permission, UserRoleIdEnum } from '../../../types';
import { isAllowed } from '../../../util/permissionUtil';

export interface AccountingFirmMemberI {
  id: string;
  accountantName: string;
  accEmail: string;
  permission?: string;
  dateOfJoined: string;
}
const DATE_FORMAT = 'DD.MM.YYYY';
const AccountingFirmMemberTable: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const { isMobile } = useWindowSize();
  const [dataSource, setDataSource] = useState<AccountingFirmMemberI[]>([]);
  useEffect(() => {
    dispatch(getAccountants());
  }, []);
  const allAccountants = useSelector(
    (state: RootState) => state.people.allAccountants,
  );
  useEffect(() => {
    const list = allAccountants.map((accountant) => {
      const accountantTable: AccountingFirmMemberI = {
        ...accountant,
        id: String(accountant.id) || '',
        accountantName: accountant.fullName || '-',
        accEmail: accountant.loginEmail || '-',
        permission:
          accountant.roleId === UserRoleIdEnum.GUEST_ADMIN
            ? 'Payroll only'
            : 'Admin access' || '_',
        dateOfJoined: moment(accountant.createdAt).format(DATE_FORMAT) || '-',
      };
      return accountantTable;
    });

    setDataSource(list);
  }, [allAccountants]);

  const allAccountantsLoading = useSelector(
    (state: RootState) => state.people.allAccountantsLoading,
  );

  function deleteAccountant(id: string) {
    dispatch(removeExternalAccountant(id));
  }

  const columns: ColumnProps<AccountingFirmMemberI>[] = [
    {
      title: 'Accountant Name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Acc Email',
      dataIndex: 'loginEmail',
      key: 'loginEmail',
    },
    {
      title: 'Permission',
      dataIndex: 'permission',
      key: 'permission',
    },
    {
      title: 'Date of joined',
      dataIndex: 'dateOfJoined',
      key: 'dateOfJoined',
    },
  ];
  const actionColumn = {
    title: '',
    dataIndex: 'id',
    key: 'id',
    width: 100,
    render: (value: string) => (
      <Dropdown
        overlay={
          <Menu
            css={css`
              padding: 0;
              box-sizing: border-box;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
              border-radius: 4px;
              .ant-dropdown-menu-item > a {
                padding: 12px 0px 12px 16px;
              }
              border: 0.5px solid #e0e0e0;
            `}>
            <Menu.Item>
              <DeleteModal
                title="Delete Accountant"
                buttonLabel="Yes, Delete!"
                content="Are you sure you want to delete accountant?"
                onClickCancel={() => {
                  deleteAccountant(value);
                }}
                openModal={
                  <B
                    cssStyle="padding: 10px 0px 10px 6px;"
                    type="b-default"
                    color="#000000">
                    Remove access
                  </B>
                }></DeleteModal>
            </Menu.Item>
          </Menu>
        }>
        <a>
          <MoreOutlined style={{ fontSize: '24px', color: '#000000' }} />
        </a>
      </Dropdown>
    ),
  };

  return (
    <Div
      css={css`
        padding: ${isMobile && '0px 10px'};
      `}>
      <TableComp
        loading={allAccountantsLoading}
        columns={
          isAllowed(Permission.MANAGE_ACCOUNTANTS)
            ? [...columns, actionColumn]
            : columns
        }
        dataSource={dataSource}
        pagination={false}
        withBorders={true}
        scroll={{
          y: 'calc(100vh - 340px)',
          x: 900,
        }}
        css={css`
          background: #fafafa;
          min-height: 300px;
          .ant-table-expanded-row-fixed {
            min-height: 300px;
          }
        `}
      />
    </Div>
  );
};
export default AccountingFirmMemberTable;
