import React, { FC } from 'react';
import moment from 'moment';
import { PayrollViewDataI } from '../../../store/actions';
import { B, Div } from '../../../components';
import { addDotsForLongText } from '../../../util/utils';
import { PayrollCalenderSVG } from '../../../assets';

export enum PayrollViewTypeEnum {
  logTrailView = 'logTrailView',
  PayrollView = 'PayrollView',
}
type Props = {
  payrollDetails?: PayrollViewDataI | null;
};

const PayrollTitle: FC<Props> = ({ payrollDetails }) => {
  return (
    <Div display="flex" height="34px">
      <B type="b-default" pr="8px" pl="16px" py="6px">
        {payrollDetails?.name && addDotsForLongText(payrollDetails?.name, 30)}
      </B>
      <Div height="34px" padding="6px 8px" display="flex">
        <img width={18} height={20} src={PayrollCalenderSVG} />
        <B type="b-default" pl="8px">
          {moment(payrollDetails?.payrollStartDate).format('MMM D') +
            ' - ' +
            moment(payrollDetails?.payrollEndDate).format('MMM D')}
        </B>
      </Div>
    </Div>
  );
};

export default PayrollTitle;
