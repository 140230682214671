import styled from '@emotion/styled';
import { Form, Row, Spin, Tooltip, message } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import {
  updateTimeAttendanceSettings,
  useGetOrganization,
} from '../../../api/organizationHooks';
import { InfoOutedSVG } from '../../../assets';
import {
  B as BaseB,
  Div,
  FooterComponent,
  PrimaryButton,
  SelectComp,
  SwitchComponent,
} from '../../../components';
import {
  minOvertimeOptions,
  roundOffHighLow,
  roundOffOptions,
} from '../../../configs';
import { useAttendanceState } from '../../../context/AttendanceContext';
import theme from '../../../theme';
import { AttendanceSettings } from '../../../types/attendance.types';
import { getApiError } from '../../../util/getApiError';

const TextHeader = styled.div`
  padding: 24px 40px 24px 32px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: block;
    padding: 30px 22px 24px 16px;
  }
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 28px;
  margin-right: -3px;
  margin-bottom: -42px;
`;
const ImageInfo = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 16px;
`;

const TextContainer = styled.div`
  width: 682px;
`;

const ToggleHeader = styled.div`
  padding: 24px 40px 24px 32px;
  display: flex;
  margin-top: -20px;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: block;
    padding: 30px 22px 24px 16px;
  }
`;

const SelectComponent = styled.div`
  margin-top: -5px;
  margin-left: 16px;
`;

const B = styled(BaseB)`
  margin-top: -5px;
  font-size: 14px;
  font-weight: 400;
`;

const LeftSection = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    padding-bottom: 30px;
  }
`;

const TimeAttendanceSettings: FC = () => {
  const [settings, setSettings] = useState<AttendanceSettings>({
    isTimeAttendance: false,
    roundOffToNearest: 0,
    roundOff: '',
    minOverTimeAmountPerDay: null,
    noPay: false,
  });

  const { toggleAttendance } = useAttendanceState();
  const { mutate: updateTimeAttendanceData, isLoading: isUpdatingSettings } =
    updateTimeAttendanceSettings();

  const {
    data: organizationData,
    mutate: getOrganizationData,
    isLoading: isLoadingSettings,
    error: getOrganizationError,
  } = useGetOrganization();

  const [isNoPayOrOtEnabled, setIsNoPayOrOTEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (getOrganizationError) {
      message.error(getApiError(getOrganizationError));
    }
    getOrganizationData();
  }, []);

  useEffect(() => {
    if (organizationData) {
      const isOT = organizationData?.otSettings?.ot?.status
        ? organizationData?.otSettings?.ot?.status
        : false;
      const isNoPay = organizationData?.leaveSettings?.noPay?.status
        ? organizationData?.leaveSettings?.noPay?.status
        : false;
      setIsNoPayOrOTEnabled(isOT && isNoPay);

      setSettings(organizationData?.timeAttendanceSettings);
    }
  }, [organizationData]);

  const otcontent = (
    <div>
      <B type="b-small" color={theme.white}>
        According to Shop and office act, overtime hours in a week should not
        exceed 12 hours
      </B>
    </div>
  );

  const handleUpdate = () => {
    if (settings) {
      updateTimeAttendanceData(settings, {
        onSuccess: (success) => {
          message.success(success);
          toggleAttendance(settings.isTimeAttendance);
        },
        onError: (error) => {
          message.error(getApiError(error));
        },
      });
    }
  };

  return (
    <Spin spinning={isLoadingSettings || isUpdatingSettings}>
      <Div>
        <TextHeader>
          <LeftSection>
            <B>Time Attendance</B>
          </LeftSection>
        </TextHeader>
        <ToggleHeader>
          <Row>
            <SwitchComponent
              disabled={!isNoPayOrOtEnabled}
              loading={false}
              checked={settings?.isTimeAttendance}
              onChange={(value) =>
                setSettings({ ...settings, isTimeAttendance: value })
              }
            />
            <B type="b-default" color={theme.gray600} pl="16px" pt="6px">
              Time Attendance
            </B>
            <B type="b-small" color={theme.gray600} pt="6px" pl="100px">
              To enable Time & Attendance calculations to your payrolls, please
              update the OT and No Pay settings in the company settings
            </B>
          </Row>
        </ToggleHeader>
      </Div>

      {settings?.isTimeAttendance && (
        <Div borderTop={`1px solid ${theme.gray300}`}>
          <TextHeader>
            <LeftSection>
              <B>Round off in calculations</B>
            </LeftSection>
          </TextHeader>
          <ToggleHeader>
            <Row>
              <B type="b-large" color={theme.black100} pl="16px" pt="6px">
                Round off to nearest
              </B>
              <SelectComponent>
                <SelectComp
                  size="middle"
                  placeholder="select"
                  value={settings?.roundOffToNearest}
                  options={roundOffOptions}
                  onChange={(value: number) =>
                    setSettings({ ...settings, roundOffToNearest: value })
                  }
                />
              </SelectComponent>
              <B type="b-large" color={theme.black100} pl="16px" pt="6px">
                Round off
              </B>
              <SelectComponent>
                <SelectComp
                  size="middle"
                  placeholder="select"
                  value={settings?.roundOff}
                  options={roundOffHighLow}
                  onChange={(value: string) =>
                    setSettings({ ...settings, roundOff: value })
                  }
                />
              </SelectComponent>
            </Row>
          </ToggleHeader>
        </Div>
      )}
      {settings?.isTimeAttendance && (
        <Div borderTop={`1px solid ${theme.gray300}`}>
          <TextHeader>
            <LeftSection>
              <B>Overtime Settings</B>
            </LeftSection>
          </TextHeader>
          <ToggleHeader>
            <Row>
              <B type="b-large" color={theme.black100} pl="16px" pt="6px">
                Min overtime amount per day
              </B>
              <SelectComponent>
                <SelectComp
                  size="middle"
                  placeholder="select"
                  value={settings?.minOverTimeAmountPerDay}
                  options={minOvertimeOptions}
                  onChange={(value: number) =>
                    setSettings({ ...settings, minOverTimeAmountPerDay: value })
                  }
                />
              </SelectComponent>

              <Tooltip placement="topLeft" title={otcontent}>
                <ImageInfo src={InfoOutedSVG} />
              </Tooltip>
            </Row>
          </ToggleHeader>
        </Div>
      )}
      {settings?.isTimeAttendance && (
        <Div borderTop={`1px solid ${theme.gray300}`}>
          <TextHeader>
            <LeftSection>
              <B>Penalty No Pay</B>
            </LeftSection>
          </TextHeader>
          <ToggleHeader>
            <Row>
              <SwitchComponent
                loading={false}
                checked={settings?.noPay}
                onChange={(value) => setSettings({ ...settings, noPay: value })}
              />
              <B type="b-default" color={theme.gray600} pl="16px" pt="6px">
                Penalty no pay
              </B>
            </Row>
          </ToggleHeader>
          <TextContainer>
            <Tooltip placement="topLeft">
              <Image src={InfoOutedSVG} />
            </Tooltip>
            <TextHeader>
              <B type="b-default" color={theme.gray600} Pb="16px" ml="30px">
                If an employee checks in within 10 minutes after start time, no
                penalty no pay.
              </B>
            </TextHeader>
            <TextHeader>
              <B type="b-default" color={theme.gray600} Pb="16px" ml="30px">
                If an employee checks in after 1 hour after start time, penalty
                no pay of half day will be applicable (half day calculated by
                dividing the no.of working hours per day by two)
              </B>
            </TextHeader>
            <TextHeader>
              <B type="b-default" color={theme.gray600} Pb="16px" ml="30px">
                if an employee checks in after 4 hours after start time, penalty
                no pay of a full day will be applicable (penalty no pay hours =
                no.of working hours per day)
              </B>
            </TextHeader>
          </TextContainer>
        </Div>
      )}
      <Div mt="80px">
        <FooterComponent
          rightChildren={
            <PrimaryButton ml={16} onClick={handleUpdate}>
              Save
            </PrimaryButton>
          }
        />
      </Div>
    </Spin>
  );
};

export default TimeAttendanceSettings;
