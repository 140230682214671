/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useEffect, useState } from 'react';
import {
  B,
  Div,
  FooterComponent,
  SecondaryButton,
  TableComp,
} from '../../components';
import theme from '../../theme';
import { useNavigate } from 'react-router';
import { ColumnProps } from 'antd/lib/table';
import {
  SubscriptionEnum,
  SubscriptionFilterTypes,
  SubscriptionI,
} from '../../types/subscription.types';
import { StatusTag } from '../leave/shared/StatusTag';
import { numberThousandSeparator } from '../../util/utils';
import { useGetInvoiceList } from '../../api/invoiceHook';
import { Row, Select } from 'antd';
import styled from '@emotion/styled';
import { DropdownBlue } from '../../assets';

const SelectComponent = styled(Select)`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${theme.blue50};
  width: 125px;
  .ant-select-selection-item {
    color: ${theme.blue500} !important;
    text-align: end !important;
  }
`;
const Link = styled.a`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
  color: ${theme.blue500} !important;
`;

const menuItems = [
  {
    value: SubscriptionFilterTypes.ALL,
    label: 'All Status',
  },
  {
    value: SubscriptionFilterTypes.DUE,
    label: 'Due',
  },
  {
    value: SubscriptionFilterTypes.OVERDUE,
    label: 'Over Due',
  },
  {
    value: SubscriptionFilterTypes.PAID,
    label: 'Paid',
  },
  {
    value: SubscriptionFilterTypes.WRITTEN_OFF,
    label: 'Written off',
  },
];

const Subscription: FC = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<string>(SubscriptionFilterTypes.ALL);
  const [filterYear, setFilterYear] = useState<string>('Year');
  const [dataSource, setDataSource] = useState<SubscriptionI[]>([]);
  const [menuYear, setMenuYear] = useState<{ value: string; label: string }[]>(
    [],
  );
  const { data = [], isLoading } = useGetInvoiceList();

  useEffect(() => {
    const yearFilter = data
      .filter((obj, index) => {
        return index === data.findIndex((o) => obj.year === o.year);
      })
      .map((item) => {
        return {
          label: String(item.year),
          value: String(item.year),
        };
      });
    setMenuYear(
      [
        {
          value: 'Year',
          label: 'Year',
        },
      ].concat(yearFilter),
    );
  }, [data]);

  useEffect(() => {
    const list: SubscriptionI[] = [];

    data.forEach((invoice) => {
      const invoiceTable: SubscriptionI = {
        ...invoice,
      };
      if (
        filter === SubscriptionFilterTypes.WRITTEN_OFF &&
        invoiceTable.status === SubscriptionEnum.WRITTEN_OFF &&
        filterYear === 'Year'
      ) {
        list.push(invoiceTable);
      } else if (
        filter === SubscriptionFilterTypes.DUE &&
        invoiceTable.status === SubscriptionEnum.DUE &&
        filterYear === 'Year'
      ) {
        list.push(invoiceTable);
      } else if (
        filterYear === 'Year' &&
        filter === SubscriptionFilterTypes.OVERDUE &&
        (invoiceTable.status === SubscriptionEnum.OVERDUE ||
          invoiceTable.status === SubscriptionEnum.SUSPENDED)
      ) {
        list.push(invoiceTable);
      } else if (
        filterYear === 'Year' &&
        filter === SubscriptionFilterTypes.PAID &&
        invoiceTable.status === SubscriptionEnum.PAID
      ) {
        list.push(invoiceTable);
      } else if (
        filter === SubscriptionFilterTypes.ALL &&
        filterYear === 'Year'
      ) {
        list.push(invoiceTable);
      } else if (
        filter === SubscriptionFilterTypes.WRITTEN_OFF &&
        invoiceTable.status === SubscriptionEnum.WRITTEN_OFF &&
        filterYear === String(invoiceTable.year)
      ) {
        list.push(invoiceTable);
      } else if (
        filter === SubscriptionFilterTypes.DUE &&
        invoiceTable.status === SubscriptionEnum.DUE &&
        filterYear === String(invoiceTable.year)
      ) {
        list.push(invoiceTable);
      } else if (
        filterYear === String(invoiceTable.year) &&
        filter === SubscriptionFilterTypes.OVERDUE &&
        (invoiceTable.status === SubscriptionEnum.OVERDUE ||
          invoiceTable.status === SubscriptionEnum.SUSPENDED)
      ) {
        list.push(invoiceTable);
      } else if (
        filterYear === String(invoiceTable.year) &&
        filter === SubscriptionFilterTypes.PAID &&
        invoiceTable.status === SubscriptionEnum.PAID
      ) {
        list.push(invoiceTable);
      } else if (
        filter === SubscriptionFilterTypes.ALL &&
        filterYear === String(invoiceTable.year)
      ) {
        list.push(invoiceTable);
      }
    });

    setDataSource(list);
  }, [data, filter, filterYear]);

  const columns: ColumnProps<SubscriptionI>[] = [
    {
      title: 'Month',
      dataIndex: 'month',
      render: (value, data) => (
        <B type="b-small-semibold">
          {data ? (value ? value + '-' + data.year : '-' + data.year) : '-'}
        </B>
      ),
    },
    {
      title: 'Outstanding Amount',
      dataIndex: 'outstanding',
      render: (value) => (
        <B type="b-small-semibold">
          {value ? numberThousandSeparator(value) : 0}
        </B>
      ),
    },
    {
      title: 'Invoice Link',
      dataIndex: 'invoiceLink',
      render: (value) => {
        return (
          <>
            {value ? (
              <Link href={value} target="_blank" rel="noreferrer">
                Download Invoice
              </Link>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 200,
      render: (value) => {
        return <StatusTag status={value} />;
      },
    },
  ];
  return (
    <Div m="32px">
      <B type="b-default" Pb="24px" color={theme.gray500}>
        You will receive an invoice for your subscription payment on the 10th of
        each month, and payment will be due within 30 days. If payment is not
        received within 30 days of the due date, your account will be considered
        overdue, and access for all users will be suspended.
      </B>

      <Row justify="end">
        <SelectComponent
          bordered={false}
          defaultValue={filter}
          suffixIcon={<img src={DropdownBlue} />}
          onChange={(e) => setFilterYear(String(e))}
          options={menuYear}
        />
        <SelectComponent
          bordered={false}
          defaultValue={filter}
          suffixIcon={<img src={DropdownBlue} />}
          onChange={(e) => setFilter(String(e))}
          options={menuItems}
        />
      </Row>

      <TableComp
        loading={isLoading}
        withBorders={true}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />

      <FooterComponent
        leftChildren={
          <SecondaryButton onClick={() => navigate('/')}>Back</SecondaryButton>
        }
      />
    </Div>
  );
};

export default Subscription;
