import React, { FC, useState } from 'react';
import { Layout, Menu } from 'antd';
import { css } from '@emotion/react';
import { OrganizationListI } from '../../../../types/superAdmin.types';
import styled from '@emotion/styled';
const { Content, Sider } = Layout;
interface Prop {
  organizationList: OrganizationListI[];
  onSelect: (organizationId: number) => void;
}

const ContentComp = styled(Content)`
  height: 100%;
  min-height: calc(100vh - 200px);
  background-color: #fafafa !important;
  .ant-layout-sider-children {
    background-color: #ffffff;
  }
`;
const MenuComp = styled(Menu)`
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6eefd !important;
  }
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: #0052ea;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-menu-item:hover {
    color: #0052ea;
  }
  .ant-menu-item::after {
    border-right: unset !important;
  }
  .ant-menu-item {
    border-bottom: 1px solid #e0e0e0;
    margin: 0;
  }
  .ant-menu-item:not(:last-child) {
    margin-bottom: 0px !important;
  }
`;
export const OrganizationsList: FC<Prop> = ({ organizationList, onSelect }) => {
  const [activeMenu, setActiveMenu] = useState(
    String(organizationList[0]?.organizationId),
  );
  return (
    <ContentComp>
      <Layout className="site-layout-background">
        <Sider className="site-layout-background" width="100%">
          <MenuComp
            mode="inline"
            onClick={(event) => {
              onSelect(Number(event.key));
              setActiveMenu(event.key.toString());
            }}
            selectedKeys={[activeMenu]}>
            {organizationList.map((item) => {
              return (
                <Menu.Item key={item.organizationId}>
                  {item.organizationName}
                </Menu.Item>
              );
            })}
          </MenuComp>
        </Sider>
      </Layout>
    </ContentComp>
  );
};
