import React, { FC } from 'react';
import { Input, Typography } from 'antd';
import { InputProps } from 'antd/lib/input';
import theme from '../theme';
import { B } from '.';
import styled from '@emotion/styled';

const { Text } = Typography;
type InputTypes = 'small' | 'large' | 'middle';
interface Props extends InputProps {
  label?: string;
  infoText?: string;
}
const InputPassword = styled(Input.Password)<{ size: InputTypes }>`
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  padding-left: 16px;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 20px;
  height: ${(Props) => (Props.size == 'small' ? '32px !important' : '40px')};

  .ant-input-lg {
    font-size: 14px;
  }

  &:focus {
    border: 1px solid #0052ea;
    box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1);
  }

  &:hover {
    border: 1px solid #0052ea;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
    border-radius: 4px;
  }
`;
const InputText = styled(Text)`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #00318c;
  padding-bottom: 4px;
`;

export const InputPasswordComp: FC<Props> = (props) => {
  const { label, infoText, size = 'large' } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {label ? <InputText>{label}</InputText> : null}

      <InputPassword {...props} size={size} />
      {infoText ? (
        <B type="b-small" color={theme.gray600} marginTop="10px">
          {infoText}
        </B>
      ) : null}
    </div>
  );
};
