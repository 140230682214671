import React, { FC } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { B, Div, H } from '../../../components';

const BankConfirmThankYou: FC = () => {
  return (
    <>
      <Div display="block" textAlign="center" padding="20px">
        <CheckCircleOutlined
          width={200}
          style={{ fontSize: '72px', color: '#0ab50a', paddingBottom: 20 }}
        />
        <H type="h2" noMargin={true}>
          Thank You
        </H>
        <B type="b-large"> Check your mail and download zip file.</B>
      </Div>
    </>
  );
};

export default BankConfirmThankYou;
