import { Form } from 'antd';
import React, { FC, useEffect } from 'react';
import {
  ModalComponent,
  B,
  RangePickerComp,
  InputAreaV2,
  LabeledInputV2,
} from '../../../../components';
import {
  EditSystemUpdateI,
  SystemUpdateFormI,
  SystemUpdateI,
} from '../../../../types/superAdmin.types';
import dayjs from 'dayjs';

interface Props {
  visible: boolean;
  onCancel: () => void;
  createSystemUpdate: (values: SystemUpdateI) => void;
  editSystemUpdate: (values: EditSystemUpdateI) => void;
  systemUpdate?: SystemUpdateI;
  isEdit: boolean;
}

const AddNewUpdate: FC<Props> = ({
  visible,
  onCancel,
  createSystemUpdate,
  editSystemUpdate,
  systemUpdate,
  isEdit,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (systemUpdate && isEdit) {
      form.setFieldsValue({
        title: systemUpdate.title,
      });
      form.setFieldsValue({
        description: systemUpdate.description,
      });
      form.setFieldsValue({
        duration: [dayjs(systemUpdate.from), dayjs(systemUpdate.to)],
      });
    }
  }, [systemUpdate]);

  const onFinish = (data: SystemUpdateFormI) => {
    const values: SystemUpdateI = {
      title: data.title,
      description: data.description,
      from: data.duration[0],
      to: data.duration[1],
      id: data.id,
    };

    if (systemUpdate && isEdit) {
      editSystemUpdate({ id: systemUpdate.id, data: values });
    } else {
      createSystemUpdate(values);
    }

    onCancel();
  };

  return (
    <ModalComponent
      form={form}
      centered
      width={540}
      visible={visible}
      onCancel={onCancel}
      submitText="Submit"
      title={<B type="b-large-semibold">Add New Update</B>}>
      <Form
        form={form}
        layout="vertical"
        wrapperCol={{ span: 50 }}
        onFinish={onFinish}>
        <LabeledInputV2 label="Title*">
          <Form.Item
            name="title"
            rules={[
              {
                required: true,
                message: 'Please Enter Title',
              },
            ]}>
            <InputAreaV2 placeholder="Enter Title" maxLength={254} />
          </Form.Item>
        </LabeledInputV2>
        <LabeledInputV2 label="Description*">
          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: 'Please Enter Description',
              },
            ]}>
            <InputAreaV2 placeholder="Enter Description" />
          </Form.Item>
        </LabeledInputV2>
        <LabeledInputV2 label="Duration*">
          <Form.Item
            name="duration"
            rules={[
              {
                required: true,
                message: 'Please Choose Duration',
              },
            ]}>
            <RangePickerComp
              showTime={{ format: 'HH:mm' }}
              format="YYYY/MM/DD HH:mm"
            />
          </Form.Item>
        </LabeledInputV2>
      </Form>
    </ModalComponent>
  );
};

export default AddNewUpdate;
