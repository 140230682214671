import React, { FC, useState } from 'react';
import { Button, Modal } from 'antd';
import { B } from './B';
import { GhostButton, SecondaryButton } from '.';
import styled from '@emotion/styled';
import theme from '../theme';
import { Div } from './Div';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px !important;
    padding: 0px;
  }
  .ant-modal-body {
    padding: 16px 24px 32px !important;
  }
  .ant-modal-header {
    padding: 16px 24px 0px !important;
    border-radius: 8px 8px 0px 0px !important;
    border-bottom: none !important;
  }

  .ant-modal-footer {
    padding: 12px 16px;
    background: ${theme.gray50};
    border-radius: 0px 0px 8px 8px;
    border-top: 1px solid #f0f0f0;
    margin: 0px;
  }

  @media screen and (max-width: 768px) {
    .ant-modal-body {
      padding: 24px;
    }
  }
`;
const DeleteButton = styled(Button)`
  background-color: ${theme.red600}!important;
  border-color: ${theme.red600} !important;
  font-size: 14px;
  border-radius: 4px;
  height: 32px;
`;

const EditButton = styled(Button)`
  background-color: ${theme.blue600} !important;
  border-color: ${theme.blue600} !important;
  font-size: 14px;
  border-radius: 4px;
  height: 32px;
`;
interface Props {
  content: string;
  title: string;
  buttonLabel: string;
  onClickCancel?: () => void;
  openModal: React.ReactNode;
}
export const DeleteModal: FC<Props> = ({
  content,
  title,
  buttonLabel,
  onClickCancel,
  openModal,
}) => {
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);

  function showModal() {
    setVisible(true);
  }

  function deletePay() {
    onClickCancel && onClickCancel();
    setVisible(false);
  }

  function handleCancel() {
    setVisible(false);
  }
  return (
    <>
      <Div padding="0px" onClick={showModal}>
        {openModal}
      </Div>
      <StyledModal
        open={visible}
        width="550px"
        title={
          <div
            style={{
              width: '75%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}>
            <B type="b-large-semibold" color="#000000">
              {title}
            </B>
          </div>
        }
        onCancel={handleCancel}
        footer={[
          <>
            <GhostButton
              size="small"
              onClick={() => {
                setVisible(false);
              }}>
              No
            </GhostButton>
            <DeleteButton
              key="submit"
              type="primary"
              id="delete-button"
              onClick={() => {
                deletePay();
              }}>
              {buttonLabel}
            </DeleteButton>
          </>,
        ]}>
        <Div>
          <B type="b-large">{content}</B>
        </Div>
      </StyledModal>
    </>
  );
};
interface PropsDeleteV2 {
  content: string;
  title: string;
  buttonLabel: string;
  submitClick: () => void;
  visible: boolean;
  setVisible: (view: boolean) => void;
}
export const DeleteModalV2: FC<PropsDeleteV2> = ({
  content,
  title,
  buttonLabel,
  submitClick,
  visible,
  setVisible,
}) => {
  const triggerView = (view: boolean) => {
    setVisible(view);
  };

  const onSubmitClick = () => {
    submitClick();
    triggerView(false);
  };

  return (
    <StyledModal
      open={visible}
      width="550px"
      title={
        <div
          style={{
            width: '75%',
            cursor: 'move',
          }}>
          <B type="b-large-semibold" color="#000000">
            {title}
          </B>
        </div>
      }
      onCancel={() => triggerView(false)}
      footer={[
        <>
          <SecondaryButton onClick={() => triggerView(false)}>
            No
          </SecondaryButton>
          <DeleteButton
            size="large"
            key="submit"
            type="primary"
            id="delete-button"
            onClick={() => onSubmitClick()}>
            {buttonLabel}
          </DeleteButton>
        </>,
      ]}>
      <div>
        <B type="b-default">{content}</B>
      </div>
    </StyledModal>
  );
};

export const EditModal: FC<Props> = ({
  content,
  title,
  buttonLabel,
  onClickCancel,
  openModal,
}) => {
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);

  function showModal() {
    setVisible(true);
  }

  function deletePay() {
    onClickCancel && onClickCancel();
    setVisible(false);
  }

  function handleCancel() {
    setVisible(false);
  }
  return (
    <>
      <Div padding="0px" onClick={showModal}>
        {openModal}
      </Div>
      <StyledModal
        open={visible}
        width="550px"
        title={
          <div
            style={{
              width: '75%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}>
            <B type="b-large-semibold" color="#000000">
              {title}
            </B>
          </div>
        }
        onCancel={handleCancel}
        footer={[
          <>
            <GhostButton
              size="small"
              onClick={() => {
                setVisible(false);
              }}>
              No
            </GhostButton>
            <EditButton
              key="submit"
              type="primary"
              onClick={() => {
                deletePay();
              }}>
              {buttonLabel}
            </EditButton>
          </>,
        ]}>
        <Div>
          <B type="b-large">{content}</B>
        </Div>
      </StyledModal>
    </>
  );
};
