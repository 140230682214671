import React, { FC, useState, useEffect } from 'react';

import { Input } from 'antd';
import moment from 'moment';
import styled from '@emotion/styled';
import { AddLoanNoteI } from '../../../types/loan.types';
import { PrimaryButton, SecondaryButton } from '../../../components';
import theme from '../../../theme';
import { uniqueId } from 'lodash';

const { TextArea } = Input;

const Container = styled.div`
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 200px;
  @media (max-width: 450px) {
    height: auto;
  }
`;

const TextSection = styled.div`
  background-color: ${theme.white};
  border-bottom: 1px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  height: 130px;
`;

const TextAreaField = styled(TextArea)`
  border: none;
  padding: 16px 24px !important;
  &:focus {
    border: none;
    box-shadow: none;
  }
  &:hover {
    border: none;
    box-sizing: none;
    filter: none;
    border-radius: none;
  }
`;

const BottomSection = styled.div`
  margin-bottom: 0px;
  padding: 14px 24px !important;
  background: ${theme.gray100};
  border-bottom: 1px solid ${theme.offWhite} !important;
  box-sizing: border-box;
  border-radius: 4px;
  @media (max-width: 450px) {
    margin-top: 85px;
  }
`;

interface Props {
  onCancel: () => void;
  editItem?: AddLoanNoteI;
  onSubmit: (note: AddLoanNoteI) => void;
  isLoanView?: boolean;
}

const LoanNoteForm: FC<Props> = ({
  onCancel,
  editItem,
  onSubmit,
  isLoanView,
}) => {
  const [noteText, setNoteText] = useState<string>('');
  const dateFormat = 'YYYY-MM-DD';
  const date = moment().format(dateFormat);

  useEffect(() => {
    if (editItem) {
      setNoteText(editItem.note);
    }
  }, [editItem]);

  return (
    <Container>
      <TextSection>
        <TextAreaField
          name="note"
          onChange={(e) => setNoteText(e.target.value)}
          value={noteText}
          placeholder="Write your note here"
          autoSize={{ minRows: 2, maxRows: 2 }}
        />
      </TextSection>

      <BottomSection>
        <PrimaryButton
          disabled={!noteText}
          onClick={() => {
            if (editItem) {
              onSubmit({
                note: noteText,
                id: editItem.id,
                updatedAt: editItem.updatedAt,
                loanId: editItem.loanId,
              });
            } else {
              onSubmit(
                isLoanView
                  ? {
                      note: noteText,
                    }
                  : {
                      id: uniqueId(),
                      note: noteText,
                      updatedAt: date,
                    },
              );
            }
          }}>
          {editItem ? 'Update' : 'Add Note'}
        </PrimaryButton>
        <SecondaryButton ml={16} onClick={() => onCancel()}>
          Cancel
        </SecondaryButton>
      </BottomSection>
    </Container>
  );
};

export default LoanNoteForm;
