const TWELVE_MONTHS = 12;
export const loanInterestAmountCal = (
  loanAmount: number,
  loanInterest?: number,
) => {
  const loanInterestAmount = loanInterest
    ? (Number(loanAmount) * (Number(loanInterest) / 100)) / TWELVE_MONTHS
    : 0;
  return loanInterestAmount;
};

export const installmentAmountCal = (
  loanAmount: number,
  loanPeriod: number,
  loanInterestAmount: number,
) => {
  const installmentAmount =
    Number(loanAmount) / Number(loanPeriod) + Number(loanInterestAmount);
  return installmentAmount;
};
