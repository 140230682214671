import { ExpenseMetaData, IntegrationMetaList } from '../../../types';
import { PeopleI } from '../../../types/people.types';

export const expenseMetaList = (
  getCategoriesList: IntegrationMetaList | undefined,
) => {
  const list = [];
  if (getCategoriesList?.metaData && getCategoriesList?.metaData.length) {
    for (const { value, label } of getCategoriesList.metaData) {
      list.push({
        value,
        label,
      });
    }
  }
  return list;
};

export const expenseCategoryList = (
  getCategoriesList: IntegrationMetaList | undefined,
) => {
  const list = [];
  if (getCategoriesList?.metaData && getCategoriesList?.metaData.length) {
    for (const { value, label } of getCategoriesList.metaData) {
      list.push({
        value,
        label,
      });
    }
  }
  return list;
};

export const setTreeViewData = (
  data: ExpenseMetaData[],
  selectedValues: string[],
): any => {
  if (data && data.length) {
    return data.map((i) => treeViewSelect(i, selectedValues));
  }
};

export const treeViewSelect = (
  data: ExpenseMetaData,
  selectedValues: string[],
): any => {
  const { value, label, subs = [] } = data;
  return subs && subs.length
    ? {
        value,
        title: label,
        children:
          data.subs &&
          data.subs.map((i: any) => treeViewSelect(i, selectedValues)),
        disabled: selectedValues.includes(value),
      }
    : {
        value,
        title: label,
        disabled: selectedValues.includes(value),
      };
};

export const getExpenseUsersList = (users: PeopleI[]) => {
  if (users && users.length) {
    return users
      .sort((a, b) => a.fullName?.localeCompare(b.fullName))
      .filter((active) => !active.isArchived)
      .map((item) => ({
        label: `${item.fullName} ${
          item.employeeNumber ? `(${item.employeeNumber})` : ''
        }`,
        value: item.id,
      }));
  } else {
    return [];
  }
};
