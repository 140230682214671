import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import theme from '../../../theme';
import { PeopleMenu } from './PeopleMenu';
import { useParams } from 'react-router';
import { useIndividualPeopleState } from '../../../providers/IndividualPeopleState';
import { DefaultBody } from '../../../components';
import { Permission } from '../../../types';
import { isAllowed, isManager } from '../../../util/permissionUtil';
import ErrorPage from '../../../components/errorPage/ErrorPage';
import { Header } from './Header';
import styled from '@emotion/styled';

const Card = styled.div`
  background: ${theme.white};
  border: 1px solid ${theme.gray300};
  border-radius: 4px;
  @media (max-width: 768px) {
    border: none;
    margin-bottom: 80px;
  }
`;

const MenuCol = styled(Col)`
  border-right: ${`1px solid ${theme.gray300}`};
  @media (max-width: 1024px) {
    display: none;
    border-right: none;
  }
`;
export const PeopleLayout: FC<PropsWithChildren> = ({ children }) => {
  const params = useParams();
  const isAllowedUser = isAllowed(Permission.VIEW_USER_INFO);
  const { state, fetchPeopleById } = useIndividualPeopleState();
  const [titleName, setTitleName] = useState('');

  const {
    isLoading: peopleLoading,
    error: errorGetPeople,
    people: people,
  } = state;

  useEffect(() => {
    fetchPeopleById(params.employeeKey);
  }, [params]);

  if (errorGetPeople) {
    return <ErrorPage />;
  } else {
    return (
      <>
        {/* <Helmet>
          <title>
            {isAllowedUser ? titleName + ' - ' + people?.fullName : titleName}
          </title>
        </Helmet> */}
        {(isAllowed(Permission.VIEW_USER_INFO) || isManager()) && (
          <Header people={people} />
        )}
        <DefaultBody>
          <Card>
            <Row>
              <MenuCol xs={0} sm={0} md={0} lg={6} xl={6} xxl={5}>
                <PeopleMenu onChange={(value) => setTitleName(value)} />
              </MenuCol>
              <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={19}>
                <Spin spinning={peopleLoading}>{children}</Spin>
              </Col>
            </Row>
          </Card>
        </DefaultBody>
      </>
    );
  }
};
