import { FC, Fragment} from 'react';

import { Image, Row, Select } from 'antd';
import { Link } from 'react-router-dom';
import { B } from '../../../components';
import { useWindowSize } from '../../../util/screenSize';
import {
  REPORT_MENU_ITEMS,
} from '../../../configs';
import styled from '@emotion/styled';
import { DropdownIcon } from '../../../assets';
import theme from '../../../theme';

const { Option } = Select;

const MenuDropdown = styled(Select)`
  min-width: 210px !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    padding-left: 0px !important;
  }
  .ant-select-selection-item {
    line-height: 39px !important;
    padding-right: 25px !important;
  }
`;

export const DropdownSection = styled(Fragment)`
  @media (max-width: 1024px) {
    padding-bottom: 24px;
  }
`;

export const StyledRow = styled(Row)`
  padding: 16px 24px;
  width: 100%;
`;

interface Props {
  defaultIndex: string;
}



const ReportMenuSelector: FC<Props> = ({ defaultIndex }) => {
  const { isMobile, isTablet } = useWindowSize();
  

  return (
    <>
      {isMobile || isTablet ? (
        <div>
          <MenuDropdown
            suffixIcon={
              <Image
                width="18px"
                height="18px"
                preview={false}
                src={DropdownIcon}
              />
            }
            defaultValue={defaultIndex}>
            {REPORT_MENU_ITEMS.map((menuItem, i) => {
              return (
                <Option key={i.toString()} value={(i + 1).toString()}>
                  <Link
                    to={{
                      pathname: `${menuItem.path}`,
                    }}>
                    <B type="b-large-semibold" color={theme.black}>{menuItem.displayName}</B>
                  </Link>
                </Option>
              );
            })}
          </MenuDropdown>
        </div>
      ) : null}
    </>
  );
};

export default ReportMenuSelector;
