import styled from '@emotion/styled';
import { Row, Col, Spin, message } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useGetAddEmployeeSummary } from '../../../../api/peopleHooks';
import { B, DefaultBody, PrimaryButton } from '../../../../components';
import { Div } from '../../../../components/Div';
import theme from '../../../../theme';
import { AddEmployeeI } from '../../../../types/people.types';
import { getApiError } from '../../../../util/getApiError';
import {
  getCurrentEmployment,
  getEmploymentDetail,
  getPersonalDetail,
} from '../../PeopleUtil';
import CurrentEmploymentDetails from './confirmation/CurrentEmploymentDetails';
import EmploymentDetailsConfirmation from './confirmation/EmploymentDetailsConfirmation';
import PersonalDetailsConfirmation from './confirmation/PersonalDetailsConfirmation';
import SalaryDetailsConfirmation from './confirmation/SalaryDetailsConfirmation';
import AddEmployeeFooter from './shared/AddEmployeeFooter';
import { steps } from '../../shared/addEmployeeManually/StepsComponent';

export const FormBody = styled.div`
  padding: 32px 32px 8px;
  border-bottom: 1px solid #e0e0e0 ;
  backgroundColor= ${theme.white};
  @media only screen and (max-width: 1200px) {
    padding: 32px 24px;
  }
  @media only screen and (max-width: 768px) {
    padding: 0px 16px;
  }
`;

interface Props {
  current: number;
  onClickNext: (num: number) => void;
  employmentDetail: AddEmployeeI | null;
}
const Confirmation: FC<Props> = ({
  current,
  onClickNext,
  employmentDetail,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const {
    isLoading: loadingSummary,
    error: errorSummary,
    mutate: getSummary,
    data: summaryData,
  } = useGetAddEmployeeSummary();

  useEffect(() => {
    if (employmentDetail != null) {
      getSummary(employmentDetail);
    }
  }, [employmentDetail]);

  const personalDetail = getPersonalDetail(summaryData);
  const employmentDetails = getEmploymentDetail(summaryData);
  const currentEmployment = getCurrentEmployment(summaryData);

  const disableSteps = () => {
    const currentSteps = [...steps];
    currentSteps.forEach((step) => {
      step.completed = false;
    });
  };

  const handleClick = () => {
    if (!isClicked) {
      disableSteps();
      setIsClicked(true);
      onClickNext(current + 1);
    }
  };

  return (
    <>
      {errorSummary && message.error(getApiError(errorSummary))}
      <DefaultBody
        p={0}
        backgroundColor={theme.white}
        border={`0.5px solid ${theme.gray300}`}
        borderRadius={4}>
        <Spin spinning={loadingSummary}>
          <FormBody>
            <Div
              background={theme.warn}
              border={`0.5px solid ${theme.gray300}`}
              p="16px 24px 16px 16px">
              <Row gutter={[16, 0]}>
                <Col xs={24} sm={24} md={20} lg={21} xl={21} xxl={21}>
                  <B type="b-large-semibold">Confirmation!</B>
                  <B type="b-default" color={theme.gray600}>
                    You can edit your employee information anytime from the
                    People page.
                  </B>
                </Col>
                <Col xs={24} sm={24} md={4} lg={3} xl={3} xxl={3}>
                  <PrimaryButton
                    size="small"
                    mt="4px"
                    onClick={handleClick}
                    disabled={isClicked}>
                    Confirm
                  </PrimaryButton>
                </Col>
              </Row>
            </Div>
            <PersonalDetailsConfirmation PersonalDetails={personalDetail} />
            <Div borderBottom={`0.5px solid ${theme.gray300}`} pb="10px">
              <EmploymentDetailsConfirmation
                employmentDetails={employmentDetails}
              />

              <CurrentEmploymentDetails currentEmployment={currentEmployment} />
            </Div>
            <SalaryDetailsConfirmation
              summaryData={summaryData}
              payItem={
                employmentDetail?.payItems ? employmentDetail?.payItems : []
              }
            />
          </FormBody>
          <AddEmployeeFooter
            label="Confirm"
            current={current}
            onClickNext={(v) => {
              disableSteps();
              onClickNext(v);
            }}
            onClickBack={(v) => {
              onClickNext(v);
            }}
            isConfirm={true}
          />
        </Spin>
      </DefaultBody>
    </>
  );
};

export default Confirmation;
