import axios from 'axios';
import { message } from 'antd';
import {
  OrganizationsDataByOwnerI,
  OrganizationsI,
  OrganizationsSummaryI,
} from '../../types/superAdmin.types';
import showApiError from '../../util/showApiError';
import { CreateOwnerI } from '../../types/superAdmin.types';
import { IndividualPeopleI } from '../../types/people.types';

const GET_ORGANIZATION_SUMMARY = 'GET_ORGANIZATION_SUMMARY';
const LOAD_ORGANIZATION_SUMMARY = 'LOAD_ORGANIZATION_SUMMARY';
const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
const LOAD_ORGANIZATIONS = 'LOAD_ORGANIZATIONS';
const GET_ORGANIZATION_DATA = 'GET_ORGANIZATION_DATA';
const LOAD_ORGANIZATION_DATA = 'LOAD_ORGANIZATION_DATA';
const PUT_OWNER_LOADING = 'PUT_OWNER_LOADING';
const ME = 'ME';

interface InitialStateI {
  organizationsSummary: OrganizationsSummaryI | null;
  loading: boolean;
  organizations: OrganizationsI[];
  organizationData: OrganizationsDataByOwnerI[];
  organizationDataLoading: boolean;
  putOwnerLoading: boolean;
  me: IndividualPeopleI | null;
}

const initialState: InitialStateI = {
  organizationsSummary: null,
  loading: false,
  organizations: [],
  organizationData: [],
  organizationDataLoading: false,
  putOwnerLoading: false,
  me: null,
};

export default function reducer(
  state = initialState,
  action: any,
): InitialStateI {
  switch (action.type) {
    case GET_ORGANIZATION_SUMMARY: {
      return {
        ...state,
        organizationsSummary: action.payload,
      };
    }
    case LOAD_ORGANIZATION_SUMMARY: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case GET_ORGANIZATIONS: {
      return {
        ...state,
        organizations: action.payload,
      };
    }
    case LOAD_ORGANIZATIONS: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case GET_ORGANIZATION_DATA: {
      return {
        ...state,
        organizationData: action.payload,
      };
    }
    case LOAD_ORGANIZATION_DATA: {
      return {
        ...state,
        organizationDataLoading: action.payload,
      };
    }
    case PUT_OWNER_LOADING: {
      return {
        ...state,
        putOwnerLoading: action.payload,
      };
    }
    case ME: {
      return {
        ...state,
        me: action.payload,
      };
    }
    default:
      return state;
  }
}

export function getOrganizationsSummary() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_ORGANIZATION_SUMMARY, payload: true });
      const res = await axios.get('/super-admin/organizationsSummary');
      dispatch({ type: GET_ORGANIZATION_SUMMARY, payload: res.data });
      dispatch({ type: LOAD_ORGANIZATION_SUMMARY, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: LOAD_ORGANIZATION_SUMMARY, payload: false });
    }
  };
}

export function getOrganizations() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_ORGANIZATIONS, payload: true });
      const res = await axios.get('/super-admin/organizations');
      dispatch({ type: GET_ORGANIZATIONS, payload: res.data });
      dispatch({ type: LOAD_ORGANIZATIONS, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: LOAD_ORGANIZATIONS, payload: false });
    }
  };
}

// This call convert to react query. No need this one now
export function getOrganizationsDataByOwner(accountId: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_ORGANIZATION_DATA, payload: true });
      const res = await axios.get(
        `/super-admin/getOrganizationsDataByOwner/${accountId}`,
      );
      dispatch({ type: GET_ORGANIZATION_DATA, payload: res.data });
      dispatch({ type: LOAD_ORGANIZATION_DATA, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: LOAD_ORGANIZATION_DATA, payload: false });
    }
  };
}

export function addNewOwner(data: CreateOwnerI, userAccountId?: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: PUT_OWNER_LOADING, payload: true });
      const res = await axios.post('/super-admin/addNewOwner', data);
      message.success('New Organization created successfully');
      dispatch({ type: PUT_OWNER_LOADING, payload: false });
      if (userAccountId) {
        dispatch(getOrganizationsDataByOwner(userAccountId));
      }
      return res.data.id;
    } catch (error: any) {
      const errorMessage = showApiError(error);
      throw errorMessage;
    } finally {
      dispatch({ type: PUT_OWNER_LOADING, payload: false });
    }
  };
}

export function getLoggedInSuperAdmin() {
  return async (dispatch: any) => {
    try {
      const res = await axios.get('super-admin/me');
      dispatch({ type: ME, payload: res.data });
    } catch (error: any) {
      showApiError(error);
    }
  };
}
