import * as ExcelJS from 'exceljs';

import { DataImportUserPayLoad } from '../../../../store/actions';
import { camelCaseToNormalText } from '../../../../util/camelCaseToNormal.util';
import { capitalizeUnderscore } from '../../../../util/utils';
import { ColumnItemType } from './generatePayrollExport';

export const generateEmployeesSheet = ({
  identityColumns,
  payItemColumns,
  empColumns,
  employees,
  userRows,
  dataForTable,

  epf,
  apit,
}: {
  identityColumns: ColumnItemType[];
  payItemColumns: ColumnItemType[];
  empColumns: ColumnItemType[];
  employees: ExcelJS.Worksheet;
  userRows: {
    [key: string]: string;
  }[];
  dataForTable:
    | {
        dataList: DataImportUserPayLoad[];
        payItemColumns: string[];
      }
    | undefined;
  epf: {
    [key: string]: string;
  };
  apit: {
    [key: string]: string;
  };
}) => {
  empColumns.splice(11, 0, { key: 'paymentMode', width: 20 });
  const empSheetColumns = [
    ...identityColumns,
    ...empColumns,
    ...payItemColumns,
  ];

  employees.columns = empSheetColumns;

  employees.addRow(epf);
  employees.addRow(apit);

  //Employees Sheet Main headings
  const empSheetTitles: { [key: string]: string } = {};

  empSheetTitles['empId'] = 'EMP ID*';
  empSheetTitles['fullName'] = 'Full Name*';
  empSheetTitles['nameWithInitials'] = 'Name with initials';
  empSheetTitles['preferredName'] = 'Preferred Name';
  empSheetTitles['joinedDate'] = 'Joined Date';
  empSheetTitles['resignedDate'] = 'Resignation Date';
  empSheetTitles['paySlipMail'] = 'Payslip Mail';
  empSheetTitles['loginEmail'] = 'Email';
  empSheetTitles['nicOrPassport'] = 'NIC*';
  empSheetTitles['occupationGrade'] = 'Occupation Grade*';
  empSheetTitles['designation'] = 'Designation';
  empSheetTitles['address'] = 'Address';
  empSheetTitles['DOB'] = 'DOB';
  empSheetTitles['personalMobile'] = 'Mobile';
  empSheetTitles['employmentType'] = 'Employment Type';
  empSheetTitles['branchName'] = 'Office Branch';
  empSheetTitles['teams'] = 'Teams';
  empSheetTitles['paymentMode'] = 'Payment Mode';
  empSheetTitles['bankAccountName'] = 'Bank Account Name*';
  empSheetTitles['bankAccountNumber'] = 'Bank Account Number*';
  empSheetTitles['bankBankCode'] = 'Bank Code*';
  empSheetTitles['branchCode'] = 'Branch Code*';
  empSheetTitles['gender'] = 'Gender';
  empSheetTitles['phoneNumber'] = 'Phone Number';
  empSheetTitles['epfNumber'] = 'EPF Number';
  empSheetTitles['etfNumber'] = 'ETF Number';
  empSheetTitles['taxNumber'] = 'Tax Number';
  empSheetTitles['startDate'] = 'Start Date';
  empSheetTitles['endDate'] = 'Resignation Date';
  empSheetTitles['joinedDate'] = 'Joined Date';
  const dynamicColumns: string[] = [];

  const startCharCode = 'B'.charCodeAt(0); // ASCII code of 'A'
  let currentIndex = 0;
  if (dataForTable) {
    for (const data of dataForTable.dataList) {
      for (const payitemColumn of payItemColumns) {
        data.values.payItems.forEach((item) => {
          let charCode = 0;
          let columnValue: string;
          if (
            !(payitemColumn?.key in empSheetTitles) &&
            item.payTitle === payitemColumn.key
          ) {
            if (startCharCode + currentIndex <= 90) {
              charCode = startCharCode + currentIndex;
              columnValue = 'A' + String.fromCharCode(charCode); // Append 'B' to the generated character
            } else {
              charCode = startCharCode + currentIndex - 26;
              columnValue = 'B' + String.fromCharCode(charCode);
            }
            dynamicColumns.push(columnValue);
            currentIndex++;
          }
          if (
            item.payTitle === payitemColumn.key &&
            !item.payTitle.toLowerCase().trim().includes('basic')
          ) {
            empSheetTitles[payitemColumn.key] = `${capitalizeUnderscore(
              item.type,
            )}:${camelCaseToNormalText(payitemColumn.key)}`;
          }
          if (
            item.payTitle === payitemColumn.key &&
            item.payTitle.toLowerCase().trim().includes('basic')
          ) {
            empSheetTitles[payitemColumn.key] = 'Basic Salary';
          }
        });
      }
    }
  }
  employees.addRow(empSheetTitles);
  employees.getRow(3).font = { bold: true };

  employees.addRows(userRows);
  dynamicColumns.forEach((columnName) => {
    const column = employees.getColumn(columnName);

    column.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (typeof cell.value === 'number') {
        const numericValue = parseFloat(cell.value.toFixed(2));
        if (!isNaN(numericValue)) {
          cell.value = numericValue;
        }
        if (rowNumber > 3) {
          cell.numFmt = '0.00';
        }
      }
    });
  });
};
