import React, { FC, useEffect, useState } from 'react';
import { Layout, Menu, Image } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
// import { Image } from './../../../components';
import {
  GUEST_ADMIN_MENU,
  HR_ADMIN_MENU,
  LOAN_DISABLE_ORG_MENU,
  PEOPLE_MENU_ITEMS,
  PeopleMenuItemType,
  PeopleStatusEnum,
} from '../../../configs';
import { useIndividualPeopleState } from '../../../providers/IndividualPeopleState';
import { RootState } from '../../../store/reducer';
import theme from '../../../theme';
import { Permission, UserRoleIdEnum } from '../../../types';
import {
  enableLoans,
  isAllowed,
  isManager,
} from '../../../util/permissionUtil';
import { Completed } from '../../../assets';
import { UserCompleteStatus } from '../../../types/people.types';

const { Content, Sider } = Layout;

const LOG_VIEW_PATH = '/contact/log-view/';
const SETTINGS_VIEW_PATH = '/contact/settings/';

type ParamsI = {
  employeeKey: string;
};

interface Props {
  onChange: (value: string) => void;
}

const Links = styled(Link)<{
  status: UserCompleteStatus;
  menuItem: PeopleMenuItemType;
}>`
  font-size: 14px;
  line-height: 20px;
  padding: ${(peops) =>
    peops.status && peops.status[peops.menuItem.statusKey]
      ? '12px 8px'
      : '12px 07px 12px 26px '};
`;
const SideMenu = styled(Menu)`
  overflow: hidden !important;
  border: 0;
  .ant-menu-item,
  .ant-menu-submenu.ant-menu-submenu-inline {
    border-radius: 0px;
    border-bottom: 1px solid ${theme.gray300};
    margin: 0 !important;
    .ant-menu-submenu-title {
      margin: 0;
      padding: 0 32px !important;
    }
    .ant-menu.ant-menu-sub.ant-menu-inline {
      .ant-menu-item {
        border-bottom: 0;
      }
    }
  }
  .ant-select-selector {
    width: 50%;
  }
  .ant-menu-item-selected {
    background: ${theme.lightBlue100} !important;
    a {
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: ${theme.blue500}!important;
      cursor: initial;
    }
  }
  .ant-menu-item::after {
    border-right: 0px !important;
  }
`;

const MenuItems = styled(Menu.Item)`
  height: 40px !important;
  padding-left: 16px !important;
`;

const MenuLink = styled(Link)`
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding: 12px 07px 12px 26px;
`;

const MainContainer = styled(Content)`
  height: 100%;
  min-height: calc(100vh - 200px);
  .ant-layout-sider-children {
    background-color: ${theme.white};
  }
`;

export const PeopleMenu: FC<Props> = ({ onChange }) => {
  const location = useLocation();
  const params = useParams<ParamsI>();
  const me = useSelector((state: RootState) => state.people.me);
  const [userMenuItems, setUserMenuItems] =
    useState<PeopleMenuItemType[]>(PEOPLE_MENU_ITEMS);
  const [activeMenu, setActiveMenu] = useState('');
  const {
    state: { people },
  } = useIndividualPeopleState();

  useEffect(() => {
    setActiveMenu(getCurrentActiveMenu());
  }, [userMenuItems, location]);

  useEffect(() => {
    if (!isAllowed(Permission.CREATE_EDIT_USERS)) {
      setUserMenuItems(
        PEOPLE_MENU_ITEMS.filter(
          (item) =>
            [
              PeopleStatusEnum.personnelDetails,
              PeopleStatusEnum.emergencyDetails,
              PeopleStatusEnum.employmentDetails,
              PeopleStatusEnum.loanDetails,
              PeopleStatusEnum.userDocument,
            ].includes(item.statusKey) ||
            (isManager() &&
              people?.id !== me?.id &&
              item.statusKey == PeopleStatusEnum.notificationDetails),
        ),
      );
    } else if (me?.roleId === UserRoleIdEnum.HR_ADMIN) {
      setUserMenuItems(
        PEOPLE_MENU_ITEMS.filter((item) =>
          HR_ADMIN_MENU.includes(item.statusKey),
        ),
      );
    } else if (people?.roleId === UserRoleIdEnum.GUEST_ADMIN) {
      setUserMenuItems(
        PEOPLE_MENU_ITEMS.filter((item) =>
          GUEST_ADMIN_MENU.includes(item.statusKey),
        ),
      );
    } else if (!enableLoans()) {
      setUserMenuItems(
        PEOPLE_MENU_ITEMS.filter((item) =>
          LOAN_DISABLE_ORG_MENU.includes(item.statusKey),
        ),
      );
    } else {
      setUserMenuItems(PEOPLE_MENU_ITEMS);
    }
  }, [people?.id, people?.roleId]);

  useEffect(() => {
    if (activeMenu) {
      onChange(activeMenu);
    }
  }, [activeMenu]);

  function getCurrentActiveMenu() {
    const currentPath = userMenuItems.find(
      (item) => `${item.path}/${params.employeeKey}` === location.pathname,
    );
    if (location.pathname.includes(LOG_VIEW_PATH)) {
      return 'View Log Trail';
    }
    if (location.pathname.includes(SETTINGS_VIEW_PATH)) {
      return 'Settings';
    }
    return currentPath?.displayName || '';
  }

  return (
    <MainContainer>
      <Layout className="site-layout-background">
        <Sider className="site-layout-background" width="100%">
          <SideMenu
            style={{
              overflowY: 'hidden',
            }}
            onClick={(event) => setActiveMenu(event.key as any)}
            selectedKeys={[activeMenu]}
            mode="inline">
            {userMenuItems.map((menuItem) => {
              const status = people?.profileStatus?.completionStatus;
              return (
                <MenuItems key={menuItem.displayName}>
                  {status && status[menuItem.statusKey] ? (
                    <Image
                      preview={false}
                      id={menuItem.statusKey}
                      width={18}
                      height={18}
                      src={Completed}
                    />
                  ) : null}
                  <Links
                    to={`${menuItem.path}/${params?.employeeKey}`}
                    status={status}
                    menuItem={menuItem}>
                    {menuItem.displayName}
                  </Links>
                </MenuItems>
              );
            })}
            {isAllowed(Permission.CREATE_EDIT_USERS) && (
              <MenuItems key="Settings">
                <MenuLink to={`/contact/settings/${params?.employeeKey}`}>
                  Settings
                </MenuLink>
              </MenuItems>
            )}
            {isAllowed(Permission.CREATE_EDIT_USERS) &&
              me?.roleId !== UserRoleIdEnum.HR_ADMIN && (
                <MenuItems key="View Log Trail">
                  <MenuLink to={`/contact/log-view/${params?.employeeKey}`}>
                    View Log Trail
                  </MenuLink>
                </MenuItems>
              )}
          </SideMenu>
        </Sider>
      </Layout>
    </MainContainer>
  );
};
