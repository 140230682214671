import styled from '@emotion/styled';
import { Form } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { SpaceProps, space } from 'styled-system';
import { B, InputComp } from '../../../../components';
import { ModalComponent } from '../../../../components/ModalComponent';
import DragAndDrop from '../../../../components/dropzone/DragAndDrop';
interface Props {
  visible: boolean;
  handleCancel: () => void;
  onSubmit: (title: string | null, file: File) => void;
}
const FormItem = styled(Form.Item)<SpaceProps>`
  ${space}
`;
export const FileUploadModal: FC<Props> = ({
  visible,
  handleCancel,
  onSubmit,
}) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [isName, setIsName] = useState('');
  const [isFile, setIsFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);

  async function onFinish(formValues: { title: string }) {
    if (selectedFile) {
      onSubmit(formValues.title, selectedFile);
    }
  }
  useEffect(() => {
    form.setFieldsValue({ title: isName || null });
  }, [isName]);

  const handleDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      const fileName = file.name.slice(0, file.name.lastIndexOf('.'));
      setSelectedFileName(fileName);
      setIsName(fileName);
      setIsFile(true);
    }
  };
  return (
    <>
      <ModalComponent
        form={form}
        submitText="Upload"
        disabled={!isFile}
        visible={visible}
        title={
          <div
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}>
            <B type="b-large-semibold">Upload your document or file</B>
          </div>
        }
        onCancel={handleCancel}>
        <Form form={form} onFinish={onFinish} validateTrigger="onBlur">
          <FormItem marginBottom={selectedFileName ? '0px' : '24px'}>
            <DragAndDrop
              minSize={1024}
              maxSize={3072000}
              accept=".pdf"
              onDrop={handleDrop}
            />
          </FormItem>
          <FormItem
            marginTop="24px"
            name="title"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Please enter file name!',
              },
            ]}>
            <InputComp
              size="small"
              onChange={(e) => {
                setIsName(e.target.value);
              }}
              placeholder="Name your document or file"
            />
          </FormItem>
        </Form>
      </ModalComponent>
    </>
  );
};
