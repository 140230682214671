import * as ExcelJS from 'exceljs';
import { sumBy } from 'lodash';

import { camelCaseToNormalText } from '../../../../../util/camelCaseToNormal.util';
import { centerTexts } from '../../../../../util/exceljs.util';
import { ColumnItemType } from '../generatePayrollExport';
import { TotalRowI } from '../../../../../types/payroll.types';

export const generatePayrollDataSheet = ({
  identityColumns,
  payItemColumns,
  calculationColumnsWithMoreCols,
  bankColumns,
  generalInfoColumns,
  worksheet,
  userRows,
  leaveColumnNames,
  companyName,
  payrollYear,
  payrollMonth,
}: {
  identityColumns: ColumnItemType[];
  payItemColumns: ColumnItemType[];
  calculationColumnsWithMoreCols: ColumnItemType[];
  bankColumns: ColumnItemType[];
  generalInfoColumns: ColumnItemType[];
  leaveColumnNames: string[] | undefined;
  worksheet: ExcelJS.Worksheet;
  userRows: {
    [key: string]: string;
  }[];
  companyName?: string;
  payrollYear?: number;
  payrollMonth?: string;
}) => {
  const leaveColumns = leaveColumnNames?.map((item) => {
    return {
      key: `${item}`,
      width: 15,
    };
  });

  const sheetColumns = leaveColumns
    ? [
        ...identityColumns,
        ...payItemColumns,
        ...calculationColumnsWithMoreCols,
        ...bankColumns,
        ...generalInfoColumns,
        ...leaveColumns,
      ]
    : [
        ...identityColumns,
        ...payItemColumns,
        ...calculationColumnsWithMoreCols,
        ...bankColumns,
        ...generalInfoColumns,
      ];

  //column groups starting cells
  const payrollInfoCell = identityColumns.length;
  const calculationCell = identityColumns.length + payItemColumns.length;
  const bankInfoCell =
    identityColumns.length +
    payItemColumns.length +
    calculationColumnsWithMoreCols.length;
  const generalInfoCell =
    identityColumns.length +
    payItemColumns.length +
    calculationColumnsWithMoreCols.length +
    bankColumns.length;

  //Main headings
  const values = [];

  values[0] = 'EMP ID';
  values[1] = 'Full Name';
  values[2] = 'Name with initials';
  values[3] = 'Preferred Name';
  values[payrollInfoCell] = 'Current Payroll Information';
  values[calculationCell] = 'Calculations';
  values[bankInfoCell] = 'Bank Information';
  values[generalInfoCell] = 'General Information';

  const payrollDateVal = `${payrollMonth} ${payrollYear}`;

  // Adding company name and the payroll dates
  worksheet.getCell('A1').value = companyName;
  worksheet.getCell('A2').value = payrollDateVal;

  worksheet.getRow(1).font = { bold: true, italic: true };
  worksheet.getRow(2).font = { bold: true, italic: true };

  worksheet.columns = sheetColumns;

  worksheet.addRow([]);
  worksheet.getRow(4).values = values;
  const firstRow = worksheet.getRow(4);

  //Current Payroll Information Merge
  worksheet.mergeCells(4, payrollInfoCell + 1, 4, calculationCell);

  //Calculations Merge
  worksheet.mergeCells(4, calculationCell + 1, 4, bankInfoCell);

  //Bank Information Merge
  worksheet.mergeCells(4, bankInfoCell + 1, 4, generalInfoCell);

  //General information Merge
  worksheet.mergeCells(
    4,
    generalInfoCell + 1,
    4,
    generalInfoCell + generalInfoColumns.length,
  );

  //Centering first row
  centerTexts(firstRow, [
    payrollInfoCell + 1,
    calculationCell + 1,
    bankInfoCell + 1,
    generalInfoCell + 1,
  ]);

  const subTitles: { [key: string]: string } = {};

  for (const column of [
    ...identityColumns,
    ...payItemColumns,
    ...calculationColumnsWithMoreCols,
    ...bankColumns,
    ...generalInfoColumns,
  ]) {
    subTitles[column.key] = camelCaseToNormalText(column.key);
  }

  const dynamicColumns: string[] = [];

  const startCharCode = 'F'.charCodeAt(0); // ASCII code of 'A'
  //F is 6
  //Z is 27
  let currentIndex = 0;
  [...payItemColumns, ...calculationColumnsWithMoreCols].forEach(() => {
    let charCode = 0;
    let columnValue: string;
    if (startCharCode + currentIndex <= 90) {
      charCode = startCharCode + currentIndex;
      columnValue = String.fromCharCode(charCode); // Append 'B' to the generated character
    } else {
      charCode = startCharCode + currentIndex - 26;
      columnValue = 'A' + String.fromCharCode(charCode);
    }
    dynamicColumns.push(columnValue);
    currentIndex++;
  });
  //Adding subtitle row
  worksheet.addRow(subTitles);
  worksheet.getRow(4).font = { bold: true };

  identityColumns.forEach((_cell, i) => {
    worksheet.mergeCells(4, i + 1, 5, i + 1);
  });

  worksheet.addRows(userRows);

  //Adding border to all cells
  worksheet.eachRow(function (row, _rowNumber) {
    sheetColumns.forEach((_item, i) => {
      if (row.number > 3) {
        const cell = row.getCell(i + 1);
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
        };
      }
    });
  });

  // Set total values
  const totalRow: TotalRowI = {
    empId: 'Total',
    fullName: `Total Employees - ${userRows?.length}`,
    grossEarningsForAPIT: sumBy(userRows, (o) => {
      return o.grossEarningsForAPIT ? parseFloat(o.grossEarningsForAPIT) : 0;
    }),
    grossEarningsForEPFAndETF: sumBy(userRows, (o) => {
      return o.grossEarningsForEPFAndETF
        ? parseFloat(o.grossEarningsForEPFAndETF)
        : 0;
    }),
    deduction: sumBy(userRows, (o) => {
      return o.deduction ? parseFloat(o.deduction) : 0;
    }),
    netSalary: sumBy(userRows, (o) => {
      return o.netSalary ? parseFloat(o.netSalary) : 0;
    }),
    costToCompany: sumBy(userRows, (o) => {
      return o.costToCompany ? parseFloat(o.costToCompany) : 0;
    }),
  };

  for (const p of payItemColumns) {
    totalRow[p.key] = sumBy(userRows, (o) => {
      return o[p.key] ? parseFloat(o[p.key]) : 0;
    });
  }

  worksheet.addRows([[], [], totalRow]);

  dynamicColumns.forEach((columnName) => {
    const column = worksheet.getColumn(columnName);

    column.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (typeof cell.value === 'number') {
        const numericValue = parseFloat(cell.value.toFixed(2));
        if (!isNaN(numericValue)) {
          cell.value = numericValue;
        }
        if (rowNumber > 2) {
          cell.numFmt = '0.00';
        }
      }
    });
  });
};
