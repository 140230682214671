import { PayrollViewUserPayLoad } from '../../../store/actions';
import { numberThousandSeparator } from '../../../util/utils';

export const calculatePayrollSummary = (
  selectedPayrolls: PayrollViewUserPayLoad[],
) => {
  if (!selectedPayrolls.length) {
    return {
      costToCompany: '-',
      netSalary: '-',
      employeeEpf: '-',
      employerEpf: '-',
      etf: '-',
      totalEpf: '-',
    };
  }
  let costToCompany = 0;
  let netSalary = 0;
  let etf = 0;
  let employeeEpf = 0;
  let employerEpf = 0;
  selectedPayrolls.forEach((selectedPayroll) => {
    costToCompany += Number(selectedPayroll.costToCompany);
    netSalary += Number(selectedPayroll.netSalary);

    selectedPayroll.values.payItems.map((payrollItem) => {
      if (payrollItem.type === 'ETF') {
        etf += parseFloat(payrollItem.amount.toString());
      }
      if (payrollItem.type == 'EMPLOYEE_EPF') {
        employeeEpf += parseFloat(payrollItem.amount.toString());
      }
      if (payrollItem.type == 'EMPLOYER_EPF') {
        employerEpf += parseFloat(payrollItem.amount.toString());
      }
    });
  });
  return {
    costToCompany: numberThousandSeparator(costToCompany),
    netSalary: numberThousandSeparator(netSalary),
    employeeEpf: numberThousandSeparator(employeeEpf),
    employerEpf: numberThousandSeparator(employerEpf),
    etf: numberThousandSeparator(etf),
    totalEpf: numberThousandSeparator(employeeEpf + employerEpf),
  };
};
