import { Col, Row, Tooltip } from 'antd';
import React, { FC } from 'react';
import { B } from '../../../../../components';
import theme from '../../../../../theme';
import { camelCaseToNormalText } from '../../../../../util/camelCaseToNormal.util';
import { addDotsForLongText } from '../../../../../util/utils';
interface EmploymentDetailsI {
  employeeNumber: string | null;
  joinedDate: string | null;
  occupationGrade: string | null;
  etfNumber: string | null;
  epfNumber: string | null;
  taxNumber: string | null;
}
interface Props {
  employmentDetails?: EmploymentDetailsI;
}
const EmploymentDetailsConfirmation: FC<Props> = ({ employmentDetails }) => {
  return (
    <>
      <B type="b-default-semibold" py="16px">
        Employment Details
      </B>
      <Row gutter={[32, 12]}>
        {employmentDetails ? (
          <>
            {Object.entries(employmentDetails).map(([key, v]) => (
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <B type="b-default" color={theme.gray600}>
                  {key === 'taxNumber' ? 'TIN' : camelCaseToNormalText(key)}
                </B>
                <B type="b-default" color={theme.black}>
                  <Tooltip title={v}>
                    {v ? addDotsForLongText(v, 25) : '-'}{' '}
                  </Tooltip>
                </B>
              </Col>
            ))}
          </>
        ) : null}
      </Row>
    </>
  );
};

export default EmploymentDetailsConfirmation;
