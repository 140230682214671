import { ExpenseMetaData } from '.';
import { LogTrailMapper } from '../store/actions';

export enum IntegrationStatusEnum {
  ACTIVATED = 'ACTIVATED',
  PENDING = 'PENDING',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  DEACTIVATED = 'DEACTIVATED',
}

export enum IntegrationTypeEnum {
  QUICK_BOOK_INTEGRATION = 'QUICK_BOOK_INTEGRATION',
  SLACK_INTEGRATION = 'SLACK_INTEGRATION',
  GOOGLE_SHEET_INTEGRATION = 'GOOGLE_SHEET_INTEGRATION',
  XERO_INTEGRATION = 'XERO_INTEGRATION',
}

export interface IntegrationItem {
  id: number;
  title: string;
  logo: string;
  description: string;
  shortDescription: string;
  integrationType: IntegrationTypeEnum;
  integrationSettings: IntegrationItemSetting | null;
  integrationDomainId: number;
  integrationDomain: IntegrationDomain | null;
}

export interface IntegrationItemSetting {
  id: number;
  status: IntegrationStatusEnum | null;
}

export interface IntegrationDomain {
  id: number;
  type: string;
  description: string;
}

export interface OAuthURI {
  authURI: string;
}

/**Integration Settings */
export interface IntegrationSetting {
  id: number;
  integrationId: number;
  organizationId: number;
  integrationType: IntegrationTypeEnum;
  settings: Settings;
  status: IntegrationStatusEnum;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface Settings {
  essential: IntegrationEssential;
  metaData: IntegrationMetaData;
}

export interface IntegrationSuppliers {
  label: string;
  value: string;
}
export interface IntegrationMetaData {
  expenses?: Array<ExpenseMetaData>;
  suppliers?: Array<IntegrationSuppliers>;
  companyName: string;
  companyMail: string;
}

export interface CurrencyRef {
  name: string;
  value: number;
}

export interface IntegrationEssential {
  epf?: number | null;
  etf?: number | null;
  salary?: number | null;
}
/******** End*/

export interface PutEssential {
  essential: IntegrationEssential;
}

export interface IntegrationLog {
  id: number;
  actorId: number;
  message: string;
  type: string;
  createdAt: string;
  integrationSettingId?: number;
  userId?: number;
  mapper: {
    [key: string]: LogTrailMapper;
  };
}
