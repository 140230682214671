import React, { FC, ReactNode } from 'react';
import { Tag } from '../../../components/StatusTag';
import theme from '../../../theme';
import { IExpenseStatus } from '../../../types/expense.types';
import { LeaveStatus } from '../../home/homeUtil';
import { AttendanceFilterTypes } from '../../../types/attendance.types';

interface Props {
  children?: ReactNode;
  status: string;
  noBullet?: boolean;
}

export const ExpenseStatusTag: FC<Props> = ({ status, noBullet }) => {
  {
    switch (status) {
      case IExpenseStatus.PENDING:
      case LeaveStatus.PENDING:
        return (
          <Tag
            textColor={theme.orange400}
            tagColor="white"
            borderColor={`1px solid ${theme.orange400}`}>
            Pending
          </Tag>
        );
      case AttendanceFilterTypes.APPROVED:
      case IExpenseStatus.APPROVED:
      case LeaveStatus.ACCEPTED:
        return (
          <Tag
            textColor={theme.green500}
            tagColor="white"
            noBullet={noBullet}
            borderColor={`1px solid ${theme.green500}`}>
            Approved
          </Tag>
        );
      case IExpenseStatus.REJECTED:
      case LeaveStatus.REJECTED:
        return (
          <Tag textColor={theme.red300} tagColor={theme.red300}>
            Rejected
          </Tag>
        );
      case IExpenseStatus.PAID:
        return (
          <Tag textColor={theme.green300} tagColor={theme.green300}>
            Paid
          </Tag>
        );
      case IExpenseStatus.ARCHIVED:
        return (
          <Tag textColor={theme.gray600} tagColor={theme.gray600}>
            Archived
          </Tag>
        );
      case AttendanceFilterTypes.DRAFT:
        return (
          <Tag
            textColor={theme.orange400}
            tagColor="white"
            noBullet={noBullet}
            borderColor={`1px solid ${theme.orange400}`}>
            Draft
          </Tag>
        );
      default:
        return null;
    }
  }
};
