import React, { FC, useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { B, Div } from '../../../components';
import { SwitchComponent } from '../../../components/SwitchComponent';
import styled from '@emotion/styled';
import theme from '../../../theme';
import { Divider } from '../../../components/Divider';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import { BudgetaryAllowanceSettingI } from '../../../types/organization.types';

const StyledFormItem = styled(Div)(`
  padding: 32px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
`);

const StyledCol = styled(Col)`
  margin-bottom: 24px;
`;

const StyledFormItemInternal = styled(Form.Item)`
  margin-bottom: 16px;
`;
interface Props {
  budgetaryAllowanceSetting?: BudgetaryAllowanceSettingI | null;
}

const BudgetaryAllowanceSetting: FC<Props> = ({
  budgetaryAllowanceSetting,
}) => {
  const [isBudgetaryAllowanceEnabled, setBudgetaryReliefAllowanceEnabled] =
    useState(false);

  useEffect(() => {
    if (budgetaryAllowanceSetting) {
      setBudgetaryReliefAllowanceEnabled(
        budgetaryAllowanceSetting.isAutoBudgetaryReliefAllowance,
      );
    }
  }, [budgetaryAllowanceSetting]);
  return (
    <>
      <StyledFormItem>
        <Row>
          <StyledCol>
            <B type="b-large-semibold">Budgetary Relief Allowance</B>
          </StyledCol>
        </Row>
        <Row>
          <Div display="flex">
            <StyledFormItemInternal
              name="isAutoBudgetaryReliefAllowance"
              valuePropName="checked">
              <SwitchComponent
                onChange={(checked: boolean) => {
                  setBudgetaryReliefAllowanceEnabled(checked);
                }}
              />
            </StyledFormItemInternal>
            <Div display="flex" flexDirection="column">
              <B type="b-large" pl="18px" pt="4px">
                Automatically calculate budgetary relief allowance for employees
              </B>
              <Div marginLeft="18px">
                <B type="b-small" pb="16px" color={theme.gray600}>
                  By switching on this toggle you will automatically add
                  Budgetary relief allowance 1 & 2 to the payrun when applicable
                </B>
              </Div>
            </Div>
          </Div>
        </Row>
        {isBudgetaryAllowanceEnabled && (
          <Div display="flex" height={25} pl="15px">
            <Div display="flex">
              <Form.Item
                name="isEpfEtfBudgetaryAllowance"
                valuePropName="checked">
                <CheckBoxComp />
              </Form.Item>
              <B
                type="b-default"
                style={{ marginLeft: '18px', marginTop: '6px' }}>
                EPF/ETF
              </B>
            </Div>
            <Div display="flex" ml={50}>
              <Form.Item
                name="isApitBudgetaryAllowance"
                valuePropName="checked">
                <CheckBoxComp />
              </Form.Item>
              <B
                type="b-default"
                style={{ marginLeft: '18px', marginTop: '6px' }}>
                APIT
              </B>
            </Div>
          </Div>
        )}
      </StyledFormItem>
      <Divider />
    </>
  );
};

export default BudgetaryAllowanceSetting;
