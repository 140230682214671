import React, {
  FC,
  useState,
} from 'react';

import {
  Col,
  Menu,
  Row,
  Tooltip,
} from 'antd';
import { useNavigate } from 'react-router';

import {
  B,
  Div,
  DropdownIcon,
  PrimaryButton,
} from '../../../components';
import theme from '../../../theme';
import {
  LoanI,
  LoanStatus,
  LoanTypeEnum,
  SettleLoanI,
} from '../../../types/loan.types';
import { useWindowSize } from '../../../util/screenSize';
import {
  addDotsForLongText,
  numberThousandSeparator,
} from '../../../util/utils';
import SettleLoanModal from './SettleLoanModal';

interface Props {
  loandata?: LoanI;
  onSubmit: (formValues: SettleLoanI) => void;
  isUserView?: boolean;
  netSalary?: number;
}

const LoanOverview: FC<Props> = ({
  loandata,
  onSubmit,
  isUserView,
  netSalary,
}) => {
  const navigate = useNavigate();
  const { isDesktopLarge } = useWindowSize();
  const [visible, setVisible] = useState(false);

  const threeDotMenu = () => (
    <Menu>
      <Menu.Item onClick={() => navigate(`/loan/loan-logs/${loandata?.id}`)}>
        View LogTrail
      </Menu.Item>
    </Menu>
  );

  return (
    <Row gutter={24}>
      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        <B type="b-small" color={theme.gray600}>
          Employee Name
        </B>
        <B type="b-large-semibold">
          {loandata?.user.fullName && (
            <Tooltip placement="topLeft" title={loandata?.user.fullName}>
              {isDesktopLarge && loandata?.user.fullName
                ? addDotsForLongText(loandata?.user.fullName, 20)
                : loandata?.user.fullName
                ? addDotsForLongText(loandata?.user.fullName, 15)
                : '-'}
            </Tooltip>
          )}
        </B>
      </Col>
      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        <B type="b-small" color={theme.gray600}>
          Loan Amount
        </B>
        <Div display="flex">
          <B type="b-small" color={theme.gray600} pr="4px" pt="3px">
            LKR
          </B>
          <B type="b-large-semibold">
            {loandata && numberThousandSeparator(loandata.loanAmount)}
          </B>
        </Div>
      </Col>
      {loandata?.loanType === LoanTypeEnum.DEFAULT && (
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <B type="b-small" color={theme.gray600}>
            Interest Amount
          </B>
          <Div display="flex">
            <B type="b-small" color={theme.gray600} pr="4px" pt="3px">
              LKR
            </B>
            <B type="b-large-semibold">
              {loandata && numberThousandSeparator(loandata.interestAmount)}
            </B>
          </Div>
        </Col>
      )}
      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        <B type="b-small" color={theme.gray600}>
          Paid Amount
        </B>
        <Div display="flex">
          <B type="b-small" color={theme.gray600} pr="4px" pt="3px">
            LKR
          </B>
          <B type="b-large-semibold">
            {loandata && numberThousandSeparator(loandata.paidAmount)}
          </B>
        </Div>
      </Col>
      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        <B type="b-small" color={theme.gray600}>
          Balance Amount
        </B>
        <Div display="flex">
          <B type="b-small" color={theme.gray600} pr="4px" pt="3px">
            LKR
          </B>
          <B type="b-large-semibold">
            {loandata && numberThousandSeparator(loandata.balanceAmount)}
          </B>
        </Div>
      </Col>
      {!isUserView && (
        <Div m="auto 0 auto auto" pr="10px">
          {loandata?.loanStatus === LoanStatus.ACTIVE && (
            <PrimaryButton size="small" onClick={() => setVisible(true)}>
              Settle Loan
            </PrimaryButton>
          )}
          <DropdownIcon
            marginLeft={16}
            overlay={threeDotMenu}
            placement="bottomRight"
            trigger={['click']}
          />
        </Div>
      )}
      {visible && (
        <SettleLoanModal
          visible={visible}
          loanBalance={loandata?.balanceAmount}
          netSalary={netSalary}
          onSubmit={(v) => {
            onSubmit(v);
            setVisible(false);
          }}
          onCancel={() => setVisible(false)}
        />
      )}
    </Row>
  );
};

export default LoanOverview;
