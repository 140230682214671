import styled from '@emotion/styled';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import FormItem from 'antd/lib/form/FormItem';
import React, { FC, useState } from 'react';
import { B, Div, InputComp, SelectComp } from '../../../components';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import theme from '../../../theme';
import { AutomatedAllowanceTypesI } from '../../../types/organization.types';
import { EditSVG, GrayCancel } from '../../../assets';

const MainDiv = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.gray300};
  padding: 11px 22px 16px 19px;
  height: 56px;
  justify-content: space-between;
`;

const EditIcon = styled.img`
  width: 13px;
  height: 13px;
  cursor: pointer;
`;

const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const CustomInputComp = styled(InputComp)`
  height: 24px;
  width: 160px;
  margin-left: 10px;
`;
interface RemoveAttendanceI {
  id: number | string;
  name: string;
}

interface CheckBoxI {
  e: CheckboxChangeEvent;
  id: number | string;
}

interface InputI {
  e: React.ChangeEvent<HTMLInputElement>;
  id: number | string;
}
interface Props {
  autoAllowanceList: AutomatedAllowanceTypesI[];
  onEdit: (id: number | string) => void;
  onRemove: (value: RemoveAttendanceI) => void;
  onChangeName: React.ChangeEventHandler<HTMLInputElement>;
  onChangeAmount: (value: InputI) => void;
  onChangeCheckBox: (value: CheckBoxI) => void;
  onChangeIsApit: (value: CheckBoxI) => void;
  onChangeIsEpf: (value: CheckBoxI) => void;
}

const AttendanceAllowanceTypeList: FC<Props> = ({
  autoAllowanceList,
  onEdit,
  onRemove,
  onChangeName,
  onChangeCheckBox,
  onChangeAmount,
  onChangeIsApit,
  onChangeIsEpf,
}) => {
  const [editIndex, setEditIndex] = useState<number>(-1);

  const edit = (index: number, allowanceName: string, id: number | string) => {
    if (index === editIndex) {
      return (
        <>
          <FormItem
            name={allowanceName + index}
            rules={[
              {
                required: true,
                message: 'Please enter allowance name',
              },
            ]}>
            <CustomInputComp
              onChange={(e) => onChangeName(e)}
              onBlur={() => {
                onEdit(id);
                setEditIndex(-1);
              }}
              defaultValue={allowanceName}
            />
          </FormItem>
        </>
      );
    } else {
      return (
        <B type="b-default" pl="18px" pt="6px">
          {allowanceName}
        </B>
      );
    }
  };

  return (
    <>
      {autoAllowanceList.map((item, index) => {
        return (
          <MainDiv>
            <Div display="flex">
              <FormItem name={'isEnable' + index}>
                <CheckBoxComp
                  defaultChecked={item.isEnable}
                  onChange={(e) => onChangeCheckBox({ e, id: item.id })}
                />
              </FormItem>
              {edit(index, item.allowanceName, item.id)}
            </Div>
            <Div display="flex">
              <Div pt="4px" pr="20px" pl="4px">
                <EditIcon onClick={() => setEditIndex(index)} src={EditSVG} />
              </Div>
              <FormItem name={'amount' + index}>
                <CustomInputComp
                  onChange={(e) => onChangeAmount({ e, id: item.id })}
                  defaultValue={item?.amount ? item.amount : undefined}
                />
              </FormItem>

              <Div display="flex" pl="15px">
                <FormItem name={'isEpf' + index}>
                  <CheckBoxComp
                    defaultChecked={item.isEpf}
                    onChange={(e) => onChangeIsEpf({ e, id: item.id })}
                  />
                </FormItem>
                <B type="b-default" pl="8px" pt="7px">
                  EPF/ETF
                </B>
              </Div>
              <Div display="flex" pl="15px">
                <FormItem name={'isApit' + index}>
                  <CheckBoxComp
                    defaultChecked={item.isApit}
                    onChange={(e) => onChangeIsApit({ e, id: item.id })}
                  />
                </FormItem>
                <B type="b-default" pl="8px" pt="7px">
                  APIT
                </B>
              </Div>
              <Div pl="38px" pt="4px">
                <CloseIcon
                  onClick={() => {
                    if (item.id) {
                      onRemove({ id: item.id, name: 'amount' + index });
                    }
                  }}
                  src={GrayCancel}
                />
              </Div>
            </Div>
          </MainDiv>
        );
      })}
    </>
  );
};

export default AttendanceAllowanceTypeList;
