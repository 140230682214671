import React, { FC } from 'react';
import { Empty, Result, Row } from 'antd';
import { useNavigate } from 'react-router';
import { PrimaryButton } from './buttons/PrimaryButton';
import { EmptyStateSVG } from '../assets';
import styled from '@emotion/styled';
import { getPathWithOrgId } from '../util/getPathWithOrgId';
import { ResultStatusType } from 'antd/lib/result';

interface EmptyTextProps {
  description: string;
}
const Card = styled(Row)`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: #757575;
`;
export const EmptyTextContainer: FC<EmptyTextProps> = ({ description }) => {
  return (
    <Empty
      image={EmptyStateSVG}
      imageStyle={{
        height: 42,
      }}
      description={
        <Card justify="space-around" align="middle">
          {description}
        </Card>
      }></Empty>
  );
};
interface EmptyPageProps {
  status?: ResultStatusType;
  content: string;
  title: string;
  buttonLabel?: string;
}

export const EmptyPage: FC<EmptyPageProps> = ({
  status = '404',
  content,
  title,
  buttonLabel,
}) => {
  const navigate = useNavigate();
  return (
    <div>
      <Result
        status={status}
        title={title}
        subTitle={content}
        extra={
          buttonLabel && (
            <PrimaryButton
              onClick={() => {
                navigate(getPathWithOrgId('/home'));
              }}>
              {buttonLabel}
            </PrimaryButton>
          )
        }
      />
    </div>
  );
};
