import React, { FC, useState } from 'react';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';

import {
  B,
  DefaultBody,
  InputPasswordComp,
  PrimaryButton,
} from '../../components';
import { changePassword } from '../../store/actions';
import { useWindowSize } from '../../util/screenSize';
import { checkPasswordStrengthWarningMsg } from '../../util/utils';
import theme from '../../theme';
import styled from '@emotion/styled';
import { DispatchType } from '../../store';

const SecurityForm = styled(Form)`
  margin-top: 32px;
  width: 311px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

interface ChangePasswordI {
  oldPassword: string;
  newPassword: string;
}

const Security: FC = () => {
  const { isMobile, isTablet } = useWindowSize();
  const dispatch: DispatchType = useDispatch();
  const [form] = Form.useForm();
  const [valueChanged, setValueChanged] = useState<string>('');

  async function onFinish(values: ChangePasswordI) {
    const arr = values;
    values = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };
    if (await dispatch(changePassword(values))) {
      for (const [key] of Object.entries(arr)) {
        form.setFieldsValue({ [key]: '' });
      }
    }
  }

  return (
    <DefaultBody>
      <B type="b-large-semibold" pt="16px">
        Change Password
      </B>
      <B type="b-large" color={theme.black} pt="8px">
        No special characters required but your password should be at least 8
        characters long.
      </B>
      <SecurityForm
        form={form}
        onFinish={(v) => onFinish(v as ChangePasswordI)}
        layout="vertical">
        <Form.Item
          name="oldPassword"
          label="Old Password"
          rules={[
            {
              required: true,
              message: 'Please enter Old Password',
            },
          ]}>
          <InputPasswordComp
            type="password"
            placeholder="Enter your old password"
            size="small"
            onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
              setValueChanged(ev.target.value)
            }
          />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[
            () => ({
              validator(_, value, callback) {
                try {
                  checkPasswordStrengthWarningMsg(value);
                  callback();
                } catch (error: any) {
                  if (error instanceof Error) {
                    callback(error.message);
                  }
                }
              },
            }),
          ]}
          hasFeedback={false}>
          <InputPasswordComp
            type="password"
            placeholder="Enter your new password"
            size="small"
          />
        </Form.Item>
        <Form.Item
          label="Confirm New Password"
          name="password"
          dependencies={['newPassword']}
          hasFeedback={false}
          rules={[
            {
              required: true,
              message: 'Please enter Confirm Password',
            },
            ({ getFieldValue }) => ({
              validator(_, value, callback) {
                if (!value || getFieldValue('newPassword') === value) {
                  return callback();
                }
                return callback(
                  'The Confirmation password that you entered do not match with the New password',
                );
              },
            }),
          ]}>
          <InputPasswordComp
            type="password"
            placeholder="Confirm your new password"
            size="small"
          />
        </Form.Item>
        <Form.Item>
          <PrimaryButton
            block={isMobile && !isTablet ? true : false}
            htmlType="submit"
            disabled={valueChanged ? false : true}>
            Update
          </PrimaryButton>
        </Form.Item>
      </SecurityForm>
    </DefaultBody>
  );
};
export default Security;
