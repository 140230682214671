import React, { FC } from 'react';
import { EmptyPage } from '../../components/EmptyComponent';

const EmptyPages: FC = () => {
  return (
    <div>
      <EmptyPage
        content="Sorry, the page you visited does not exist."
        title="something went wrong !"
        buttonLabel="Back Home"
      />
    </div>
  );
};
export default EmptyPages;
