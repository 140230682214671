import React, { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import { Col, Row, Spin } from 'antd';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { B, Div } from '../../../../components';
import {
  getEmployeeHeadCountReport,
  HeadCountReportI,
} from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import { ReportLayout } from '../../shared/report-layout/ReportLayout';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import styled from '@emotion/styled';
import theme from '../../../../theme';
import ReportMenuSelector, {
  DropdownSection,
  StyledRow,
} from '../../shared/ReportMenuSelector';

const ContainerDiv = styled.div`
  padding: 1px 24px;
  width: 100%;
  margin-top: 49px;
  margin-bottom: 10px;
  .ant-spin-container {
    position: relative;
    height: 60vh;
  }
  .ant-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
`;
// chart options
const options: _DeepPartialObject<any> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (tooltipItems: any): string => {
          return tooltipItems[0].raw.createdDate;
        },
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      display: true,
      title: {
        display: true,
        text: 'Count',
        color: 'black',
        font: {
          family: 'Inter',
          size: 14,
          weight: 'bold',
          lineHeight: 1,
        },
        padding: { top: 0, left: 0, right: 0, bottom: 20 },
      },
    },
    x: {
      display: true,
      title: {
        display: true,
        text: 'Date',
        color: 'black',
        font: {
          family: 'Inter',
          size: 14,
          weight: 'bold',
          lineHeight: 1,
        },
        padding: { top: 20, left: 0, right: 0, bottom: 0 },
      },
    },
  },
  elements: {
    line: {
      tension: 0.2,
    },
  },
  interaction: {
    intersect: false,
    mode: 'index',
  },
};

const HeadCountReportPage: FC = () => {
  const dispatch: DispatchType = useDispatch();

  // result of api call loading
  const headCountReportLoading = useSelector(
    (state: RootState) => state.report.headCountReportLoading,
  );
  // result of api call data
  const headCountReport: HeadCountReportI = useSelector(
    (state: RootState) => state.report.headCountReport,
  );

  // fires report api to get data on mount
  useEffect(() => {
    dispatch(getEmployeeHeadCountReport());
  }, []);

  const getHeadCountChart = () => {
    const data = {
      datasets: [
        {
          label: 'Head Count',
          data: headCountReport.records.map((i) => ({
            createdDate: moment(i.date).format('DD MMM YYYY'),
            x: moment(i.date).format('MMM YYYY'),
            y: i.count,
          })),
          fill: false,
          borderColor: ' #0052EA',
        },
      ],
    };
    return data;
  };

  return (
    <>
      <ReportLayout>
        <StyledRow>
          <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
            <B type="b-large-semibold">
              Head Count
              <B type="b-small" color={theme.gray600}>
                Trend of headcount in organization
              </B>
            </B>
          </Col>

          <Col xs={12} sm={12} md={12} lg={0} xl={0} xxl={0}>
            <DropdownSection>
              <ReportMenuSelector defaultIndex="4" />
            </DropdownSection>
          </Col>

          <Div marginLeft="auto">
            <B type="b-large-semibold">{headCountReport?.latestCount}</B>
          </Div>
        </StyledRow>
        <ContainerDiv>
          <Spin spinning={headCountReportLoading}>
            <Line data={getHeadCountChart()} options={options} />
          </Spin>
        </ContainerDiv>
      </ReportLayout>
    </>
  );
};

export default HeadCountReportPage;
