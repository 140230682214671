import axios from 'axios';
import { BASE_URL } from '../configs';

export function setupAxiosToken(token: string | null): void {
  const cookie = document.cookie;
  if (cookie)
    axios.defaults.withCredentials = true;

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}

export const logoutRequest = axios.create({
  baseURL: BASE_URL
})