import React, { FC, useEffect, useState } from 'react';

import { Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useDispatch, useSelector } from 'react-redux';
import { B, CompactTableComp, Div } from '../../../components';
import { getMinimumUserInfo } from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import theme from '../../../theme';
import { LeaveBalancesI, LeaveNameEnum, Permission } from '../../../types';
import getNormalText from '../../../util/getNormalText';
import { isAllowed } from '../../../util/permissionUtil';
import { useWindowSize } from '../../../util/screenSize';
import { tableSorter } from '../../../util/tableSorter';
import {
  addDotsForLongText,
  isHaveThreeDots,
  safeCheckNaN,
} from '../../../util/utils';
import ManageLeaveBalanceModal from '../new-leave/ManageLeaveBalanceModal';
import { getHoursDaysFromHours } from '../utils';
import { sortEmpIds } from '../../payroll/utils/sortingUtils';

interface Props {
  dataSource: LeaveBalancesI[];
  leaveColumns: string[];
  onDataChange: () => void;
}
type TableColumnProp = ColumnProps<LeaveBalancesI>;
const LeaveBalanceTable: FC<Props> = ({
  dataSource = [],
  leaveColumns,
  onDataChange,
}) => {
  const dispatch: DispatchType = useDispatch();
  const [visible, setVisible] = useState(false);
  const { isMobile, isTablet } = useWindowSize();
  const allPeoples = useSelector((state: RootState) => state.people.allPeoples);
  const [userId, setUserId] = useState<number>();
  const me = useSelector((state: RootState) => state.people.me);
  const isAllowedLeaveVisibility =
    !!me?.isManager || isAllowed(Permission.MANAGE_ASSIGNED_USERS);
  const isAllowedLeaveEdit = isAllowed(Permission.MANAGE_ASSIGNED_USERS);

  useEffect(() => {
    if (isAllowedLeaveVisibility) {
      dispatch(getMinimumUserInfo());
    }
  }, []);

  const mainColumns = leaveColumns.slice(0, 2).reverse();
  const restColumns = leaveColumns.slice(2);
  const mainLeaveColumns = [...mainColumns, ...restColumns];

  const columns: TableColumnProp[] = [
    {
      title: 'Emp ID',
      width: 100,
      fixed: isMobile || isTablet ? undefined : 'left',
      sorter: (a, b) => sortEmpIds(a.employeeNumber, b.employeeNumber),
      sortDirections: ['descend', 'ascend'],
      render: (record: LeaveBalancesI) => {
        return <>{record.employeeNumber ? record.employeeNumber : '-'}</>;
      },
    },
    {
      title: 'Employee Name',
      width: 200,
      fixed: isMobile || isTablet ? undefined : 'left',
      ellipsis: true,
      sorter: (a, b) => tableSorter.defaultSort(a.fullName, b.fullName),
      sortDirections: ['descend', 'ascend'],
      render: (record: LeaveBalancesI) => {
        return (
          <>
            {isHaveThreeDots(String(record.fullName), 20) ? (
              <B type="b-small-semibold">
                <Tooltip placement="topLeft" title={record.fullName}>
                  {addDotsForLongText(record.fullName, 20)}
                </Tooltip>
              </B>
            ) : record.fullName ? (
              <B type="b-small-semibold">{record.fullName}</B>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Employee Type',
      width: 100,
      fixed: isMobile || isTablet ? undefined : 'left',
      render: (record: LeaveBalancesI) => {
        return <>{record.employeeType ? record.employeeType : '-'}</>;
      },
    },
    ...mainLeaveColumns.map((item) => {
      return {
        title: getNormalText(item),
        children: [
          {
            title: 'Entitlement',
            ellipsis: true,
            render: (record: LeaveBalancesI) => {
              return (
                <>
                  {
                    getHoursDaysFromHours(
                      record?.leaveBalances?.find((leaveBalance) => {
                        return (
                          leaveBalance.leaveName === (item as LeaveNameEnum)
                        );
                      })?.totalLeave || 0,
                      true,
                    ).text
                  }
                </>
              );
            },
            width: 120,
          },
          {
            title: 'Taken',
            render: (record: LeaveBalancesI) => {
              return (
                <>
                  {
                    getHoursDaysFromHours(
                      record?.leaveBalances?.find((leaveBalance) => {
                        return (
                          leaveBalance.leaveName === (item as LeaveNameEnum)
                        );
                      })?.taken || 0,
                      true,
                    ).text
                  }
                </>
              );
            },
            width: 120,
          },
          {
            title: 'Balance',
            render: (record: LeaveBalancesI) => {
              return (
                <>
                  {
                    getHoursDaysFromHours(
                      record?.leaveBalances?.find((leaveBalance) => {
                        return (
                          leaveBalance.leaveName === (item as LeaveNameEnum)
                        );
                      })?.balance || 0,
                      true,
                    ).text
                  }
                </>
              );
            },
            width: 120,
          },
        ],
      };
    }),

    ...(isAllowedLeaveEdit
      ? [
          {
            title: '',
            width: 80,
            fixed: 'right',
            render: (record: LeaveBalancesI) => {
              return (
                <B
                  type="b-default"
                  color={theme.blue500}
                  onClick={() => {
                    setUserId(record.id);
                    setVisible(true);
                  }}>
                  Edit
                </B>
              );
            },
          },
        ]
      : []),
  ] as TableColumnProp[];

  return (
    <Div
      background={theme.white}
      width="100%"
      p="4px 24px 0px"
      id="leaveBalanceTable">
      <CompactTableComp
        pagination={false}
        columns={[...columns]}
        dataSource={dataSource}
        scroll={{
          x: 1075,
          y: window.innerHeight - 270,
        }}
      />
      {visible && (
        <ManageLeaveBalanceModal
          allPeoples={allPeoples}
          onClose={() => {
            setVisible(false);
            onDataChange();
          }}
          id={userId}
        />
      )}
    </Div>
  );
};

export default LeaveBalanceTable;
