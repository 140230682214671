import React, { createContext, useContext, useState, useEffect } from 'react';
import { useGetOrganization } from '../api/organizationHooks';

interface State {
  isTimeAttendance: boolean;
  setIsTimeAttendance: (isTimeAttendance: boolean) => void;
  toggleAttendance: (value: boolean) => void;
}

const AttendanceStateContext = createContext<State | undefined>(undefined);

export const AttendanceProvider = ({ children }) => {
  const { data: organisationData, mutate: getOrganizationData } =
    useGetOrganization();

  useEffect(() => {
    if (organisationData) {
      setIsTimeAttendance(
        organisationData.timeAttendanceSettings?.isTimeAttendance,
      );
    }
  }, [organisationData]);

  useEffect(() => {
    getOrganizationData();
  }, []);

  const [isTimeAttendance, setIsTimeAttendance] = useState<boolean>(false);

  const toggleAttendance = (value: boolean) => {
    setIsTimeAttendance(value);
  };

  return (
    <AttendanceStateContext.Provider
      value={{ isTimeAttendance, toggleAttendance, setIsTimeAttendance }}>
      {children}
    </AttendanceStateContext.Provider>
  );
};
export const useAttendanceState = () => useContext(AttendanceStateContext);
