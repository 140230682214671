import { tableSorter } from '../../../../../util/tableSorter';
import { addDotsForLongText } from '../../../../../util/utils';
import { EmployeePaySlipSummary } from '../../../../../types/payslipPageTypes';

export const getEmployeeSelectorOption = (
  employees: EmployeePaySlipSummary[],
) =>
  employees
    .map((item, index) => {
      return {
        value: index,
        id: item.userId,
        label: addDotsForLongText(item.name || '', 27),
        employeeNumber: item.employeeNumber,
        designation: item.designation,
        joinedDate: item.joinedDate,
        resignedDate: item.resignedDate,
        isProRata: item.isProRata,
        proRataDays: item.proRataDays,
        isSecondary: item.isSecondary,
        isEmployeePaidInCash: item.isEmployeePaidInCash,
        attendance: item?.attendance ? item?.attendance : null,
        workedDays: item?.workedDays ? item?.workedDays : null,
        employmentType: item?.employmentType ? item?.employmentType : null,
      };
    })
    .sort((a, b) =>
      tableSorter.defaultSort(a?.employeeNumber, b?.employeeNumber),
    );
