import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { message } from 'antd';
import { BankI, BankInfoI } from '../types/bank.types';

export const useGetBankList = () => {
  return useQuery<BankI[], AxiosError>('bank-list', async () => {
    const response: AxiosResponse<BankI[]> = await axios.get(
      '/banks/getPublicInfo',
    );
    return response.data;
  });
};

export const useAddBankInformation = () => {
  return useMutation<string, AxiosError, BankInfoI>(
    async (data: BankInfoI) => {
      const response: AxiosResponse<string> = await axios.post(
        '/organization/bankDetails',
        data,
      );
      return response.data;
    },
    {
      onSuccess: () => {
        message.success('Organization bank details saved!');
      },
    },
  );
};
