import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useGetLogsByLoanId } from '../../../api/loanHooks';
import {
  FooterComponent,
  LogTrailTable,
  SecondaryButton,
} from '../../../components';

const IndividualLoanLogTrail: FC = () => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    isLoading: loadingLoanLogs,
    data: dataLoanLogs = [],
    mutate: getLoanLogs,
  } = useGetLogsByLoanId();

  useEffect(() => {
    if (params.id) {
      getLoanLogs(Number(params.id));
    }
  }, []);

  return (
    <>
      <LogTrailTable logs={dataLoanLogs} loading={loadingLoanLogs} />
      <FooterComponent
        leftChildren={
          <SecondaryButton
            onClick={() => navigate(`/loan/${Number(params.id)}`)}>
            Back
          </SecondaryButton>
        }
      />
    </>
  );
};
export default IndividualLoanLogTrail;
