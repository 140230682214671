import dayjs, { Dayjs } from 'dayjs';

export const minutesToNearestHour = (minutes: number): number => {
  return Math.round(minutes / 60);
};

export const minutesToHoursAndMinutes = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  //padStart(): pads the string with another string ('0') until it reaches the desired length.
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(
    remainingMinutes,
  ).padStart(2, '0')}`;

  return formattedTime;
};

export const minutesToNearestDay = (minutes: number): number => {
  return Math.round(minutes / 1440);
};

export const monthToString = (month: number): string => {
  if (month !== undefined) {
    return dayjs().month(month).format('MMMM ');
  }
};

export const monthToStringTrimmed = (month: number): string => {
  if (month !== undefined) {
    return dayjs().month(month).format('MMMM');
  }
};

export const stringToMonthNumTrimmed = (month: string): number => {
  if (month) {
    return Number(dayjs().month(Number(month)).format('M'));
  }
};

export const dayJsFromMinutes = (minutes: number): Dayjs => {
  if (minutes == undefined) {
    return dayjs('00:00', 'HH:mm');
  }
  const hours = Math.floor(minutes / 60);
  const remainingMins = minutes % 60;
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(
    remainingMins,
  ).padStart(2, '0')}`;
  const dayjsObject = dayjs(formattedTime, 'HH:mm');
  return dayjsObject;
};
