import React, { FC, ReactNode } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import theme from '../../theme';
import { space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';

export type Props = SpaceProps &
  Omit<ButtonProps, 'shape' | 'ghost' | 'danger' | 'type'> & {
    size?: 'small' | 'large';
  };

const StyledButton = styled(Button)<Props>`
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter;
  line-height: 20px;
  border-radius: 4px;
  border: none;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.1);
  background-color: ${theme.red600}!important;
  color: ${theme.white}!important;

  &:focus {
    color: ${theme.white};
    background-color: ${theme.red300};
    border: 2px solid ${theme.red50};
  }

  &:hover {
    background-color: ${theme.red300} !important;
    color: ${theme.white}!important;
    border-color: ${theme.red300}!important;
  }

  &.btn-disabled {
    background-color: ${theme.gray400} !important;
    color: ${theme.white}!important;
  }

  &.btn-disabled:hover,
  &.btn-disabled:focus {
    outline: none;
    box-shadow: none;
    background-color: ${theme.gray400} !important;
  }

  ${space}
`;

export const DangerButton: FC<Props> = ({ size = 'large', ...props }) => {
  let icon: ReactNode | null = null;
  let className = `btn-${size} ${props.className || ''}`;
  if (props.icon) {
    icon = <span className="btn-custom-icon-left">{props.icon}</span>;
  }
  if (props.disabled) {
    className = 'btn-disabled ' + className;
  }
  return (
    <StyledButton
      {...props}
      className={className}
      icon={icon}
      type="primary"
      danger>
      {props.children}
    </StyledButton>
  );
};
