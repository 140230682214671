import React, { FC, ReactNode } from 'react';
import { Alert } from 'antd';
import AlertSuccessSvg from '../../assets/Icons/alert-icons/alert-icon-success.svg';
import AlertWarnSvg from '../../assets/Icons/alert-icons/alert-icon-warn.svg';
import AlertErrorSvg from '../../assets/Icons/alert-icons/alert-icon-error.svg';
import AlertInformationalSvg from '../../assets/Icons/alert-icons/alert-informational.svg';

import { space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';
import theme from '../../theme';
import { AlertProps } from 'antd/lib/alert';

type AlertTypes = 'success' | 'info' | 'warning' | 'error';

export const svgIcons = {
  success: AlertSuccessSvg,
  warning: AlertWarnSvg,
  error: AlertErrorSvg,
  info: AlertInformationalSvg,
};

export const colorTheme = {
  success: {
    background: theme.green50,
  },
  warning: {
    background: theme.warn,
  },
  error: {
    background: theme.red50,
  },
  info: { background: theme.lightBlue50 },
};

type Props = SpaceProps &
  Omit<AlertProps, 'type'> & {
    AlertTypes: AlertTypes;
    action?: ReactNode;
  };
const BannerAlertComp = styled(Alert)<{ type: AlertTypes }>`
  .ant-alert-message {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  padding: 8px 14px 8px 32px;
  border: none;
  border-radius: 0px;
  background-color: ${(props) => colorTheme[props.type].background};
  .ant-alert-close-icon .anticon-close {
    color: ${theme.black};
  }
  @media only screen and (max-width: 768px) {
    padding: 8px 10px;
  }
  ${space};
`;
export const BannerAlert: FC<Props> = ({ AlertTypes, action, ...Props }) => {
  return (
    <BannerAlertComp
      {...Props}
      type={AlertTypes}
      action={action}
      icon={<img src={svgIcons[AlertTypes]} />}
    />
  );
};
