import React, { FC } from 'react';
import { Col, Form, Row } from 'antd';
import styled from '@emotion/styled';
import { space, SpaceProps, layout, LayoutProps } from 'styled-system';
import { LeaveTypesI } from '../../../types';
import getNormalText from '../../../util/getNormalText';
import { checkWarningMsg } from '../util/utils';
import { CustomInputsLeaveBalance } from '../../../components/CustomInputsComp';
import { Validation } from '../../../common/validation';

const FormItem = styled(Form.Item)<SpaceProps & LayoutProps>`
  margin-bottom: 0px;
  ${space}
  ${layout}
`;

interface Props {
  LeaveBalance: LeaveTypesI[];
  workTimeUnitType?: string | null | undefined;
}

const FullTimeLeaveBalance: FC<Props> = ({
  LeaveBalance,
  workTimeUnitType,
}) => {
  return (
    <Row gutter={[20, 0]}>
      {LeaveBalance?.sort((a, b) => a.name.localeCompare(b.name)).map(
        (leave) => {
          return (
            <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
              <FormItem
                name={'FULL_TIME-' + leave.id}
                initialValue={leave.maxPerYear}
                label={`${getNormalText(leave.name)}*`}
                rules={[
                  {
                    pattern: Validation.NoOfDaysPerYearPattern,
                    message: 'Should be between 0 and 365',
                  },
                  () => ({
                    validator(_, value, callback) {
                      try {
                        checkWarningMsg(
                          value,
                          leave.name,
                          leave.maxPerYear,
                          workTimeUnitType,
                        );
                        callback();
                      } catch (error: any) {
                        if (error instanceof Error) {
                          callback(error.message);
                        }
                      }
                    },
                  }),
                ]}>
                <CustomInputsLeaveBalance leaveName={leave.name} />
              </FormItem>
            </Col>
          );
        },
      )}
    </Row>
  );
};

export default FullTimeLeaveBalance;
