import React, { FC, useCallback, useEffect, useState } from 'react';
import { Dropdown, Menu, Tooltip } from 'antd';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router';
import {
  background,
  BackgroundProps,
  layout,
  LayoutProps,
} from 'styled-system';
import styled from '@emotion/styled';
import { RootState } from '../store/reducer';
import theme from '../theme';
import { PeopleI } from '../types/people.types';
import { isManager } from '../util/permissionUtil';
import { B } from './B';
import { Div } from './Div';
import { Link } from 'react-router-dom';
import {
  ChevronLeftBlue,
  ChevronRightBlueSVG,
  DropdownBlack,
  SearchSVG,
} from '../assets';
import { InputComp } from './InputComp';

type NavPeople = Pick<
  PeopleI,
  'id' | 'isArchived' | 'fullName' | 'employeeNumber'
>;

type Props = {
  people?: NavPeople | null;
  allPeople?: NavPeople[];
};

export const NextPreviousEmployeeImage = styled.img`
  cursor: pointer;
`;

export const EmployeeSelectDiv = styled.div`
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px 12px 6px 16px;
  min-width: 300px;
  height: 32px;
`;

export const EmployeeFilterDiv = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
`;

export const NextPreviousEmployeeDiv = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const MenuDiv = styled(Menu)`
  overflow-y: scroll;
  max-height: 400px;
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px;
`;

export const MenuItemSection = styled(Menu.Item)<BackgroundProps>`
  ${background}
`;

export const EmployeeSelectItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ThreeDotDiv = styled.div<LayoutProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${layout}
`;

export const SelectedEmployee = styled.div`
  display: flex;
`;

type RouteParams = {
  employeeKey: string;
};

export const PeopleNavigation: FC<Props> = ({ people, allPeople }) => {
  const navigate = useNavigate();
  const params = useParams<RouteParams>();
  const location = useLocation();
  const currentUrl = location.pathname;
  const [filteredDataSource, setFilteredDataSource] = useState<NavPeople[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<string | null>(null);
  const [sheetIndex, setSheetIndex] = useState<number>(
    allPeople && allPeople.length
      ? allPeople.findIndex((x) => x.id == Number(params.employeeKey))
      : 0,
  );
  const me = useSelector((state: RootState) => state.people.me);

  useEffect(() => {
    if (people) {
      setSelectedEmployee(people.employeeNumber);
    }
  }, [people]);

  useEffect(() => {
    if (allPeople && allPeople.length) {
      const sortedEmployees = allPeople
        .sort((x, y) =>
          Number(x.isArchived) - Number(y.isArchived) ||
          x.employeeNumber?.localeCompare(
            y.employeeNumber ? y.employeeNumber : '',
          )
            ? 1
            : -1,
        )
        .sort((x, y) => Number(x.isArchived) - Number(y.isArchived));
      setFilteredDataSource(sortedEmployees);
    }
  }, [allPeople]);

  const searchDataTableCallback = useCallback(
    debounce((searchText) => {
      if (allPeople && allPeople.length) {
        const text = searchText.trim();
        if (text) {
          const filteredPeople = allPeople.filter((item) => {
            const fullText = item.fullName?.toLowerCase() + item.employeeNumber;
            return fullText.includes(text.toLowerCase());
          });
          setFilteredDataSource(filteredPeople);
        } else {
          setFilteredDataSource(allPeople);
        }
      }
    }, 400),
    [allPeople],
  );

  const getPathName = (userID: number) => {
    const pathName = currentUrl.includes('account-details')
      ? `${currentUrl.replace('account-details', 'people')}/${userID}`
      : isManager() && me?.id == userID && currentUrl.includes('people')
      ? currentUrl
          .replace('people', 'account-details')
          .replace(`/${userID}`, '')
      : currentUrl.replace(params.employeeKey, String(userID));
    return pathName;
  };
  const urlChange = (index: number) => {
    if (allPeople && allPeople.length) {
      const firstIndex = 0;
      const lastIndex = allPeople.length - 1;
      const firstPage = index < 0;
      const lastPage = lastIndex < index;

      const newIndex = firstPage ? lastIndex : lastPage ? firstIndex : index;
      const userID = allPeople[newIndex].id;
      const pathname = getPathName(userID);

      navigate({ pathname });

      setSheetIndex(newIndex);
    }
  };

  const menu = (
    <MenuDiv>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
        <Div p={12}>
          <InputComp
            onChange={(e) => searchDataTableCallback(e.target.value)}
            size="small"
            placeholder="Search Employee"
            suffix={<img src={SearchSVG} />}
          />
        </Div>
      </div>
      {filteredDataSource.map((item, i) => (
        <>
          {!item.isArchived && (
            <MenuItemSection
              key={item.employeeNumber}
              background={
                selectedEmployee == item.employeeNumber
                  ? theme.lightBlue50
                  : null
              }>
              <Link
                to={{
                  pathname: getPathName(item.id),
                }}>
                <EmployeeSelectItem
                  onClick={() => {
                    setSheetIndex(i);
                  }}>
                  <B
                    type="b-default"
                    color={
                      selectedEmployee == item.employeeNumber
                        ? theme.blue500
                        : theme.black
                    }>
                    <Tooltip placement="left" title={item.fullName}>
                      <ThreeDotDiv maxWidth={200}>{item.fullName}</ThreeDotDiv>
                    </Tooltip>
                  </B>
                  <B type="b-small" color={theme.gray600}>
                    {item.employeeNumber}
                  </B>
                </EmployeeSelectItem>
              </Link>
            </MenuItemSection>
          )}
        </>
      ))}
    </MenuDiv>
  );

  return (
    <EmployeeFilterDiv>
      <EmployeeSelectDiv>
        <Dropdown overlay={menu}>
          <SelectedEmployee>
            <B type="b-default">
              <ThreeDotDiv maxWidth={160}>{people?.fullName}</ThreeDotDiv>
            </B>
            &nbsp;
            {people?.employeeNumber && (
              <B type="b-default">({people.employeeNumber})</B>
            )}
            <Div ml="auto" mt="-2px">
              <img src={DropdownBlack} />
            </Div>
          </SelectedEmployee>
        </Dropdown>
      </EmployeeSelectDiv>
      {filteredDataSource.length > 1 && (
        <NextPreviousEmployeeDiv>
          <Div pr="24px">
            <Tooltip placement="topLeft" title="Previous Employee">
              <NextPreviousEmployeeImage
                onClick={() => urlChange(sheetIndex - 1)}
                src={ChevronLeftBlue}
              />
            </Tooltip>
          </Div>
          <Div>
            <Tooltip placement="bottomRight" title="Next Employee">
              <NextPreviousEmployeeImage
                onClick={() => urlChange(sheetIndex + 1)}
                src={ChevronRightBlueSVG}
              />
            </Tooltip>
          </Div>
        </NextPreviousEmployeeDiv>
      )}
    </EmployeeFilterDiv>
  );
};
