import React, { FC } from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import theme from '../../theme';
import styled from '@emotion/styled';

interface Props extends TextAreaProps {
  flexDirection?: string;
}
const TextArea = styled(Input.TextArea)<Props>`
  @media screen and (max-width: 768px) {
    ${(props) => props.flexDirection === 'row' && 'width: 100%;'}
  }
  ${(props) => props.flexDirection === 'row' && 'width: 344px;'}
  background: ${theme.white};
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px 16px;

  &:focus {
    border: 1px solid ${theme.blue500};
    box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1);
  }

  &:hover {
    border: 1px solid ${theme.blue500};
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
    border-radius: 4px;
  }
`;
export const InputAreaV2: FC<Props> = (props) => {
  const { flexDirection = 'column', placeholder, ...rest } = props;
  return (
    <TextArea
      {...rest}
      flexDirection={flexDirection}
      placeholder={placeholder}
    />
  );
};
