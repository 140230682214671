import { Col, Form, Row } from 'antd';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { usePostDate } from '../../../api/attendanceHooks';
import { DatePickerComp, ModalComponent } from '../../../components';
import dayjs, { Dayjs } from 'dayjs';
import { TimePickerComp } from '../../../components/TimePickerComp';
import { getPayrollStartEndDates } from '../../payroll/utils/getPayrollPeriod';
import { OrganizationHolidayI } from '../../../types/organization.types';

interface Props {
  username: string;
  month: number;
  year: number;
  dateList: string[];
  onClose: () => void;
  onSubmit: () => void;
  visible: boolean;
  buttonDisabled?: boolean;
  maxOtHours?: number;
  payrunStartDate?: number;
  roundOff?: number;
  organizationHoliday?: OrganizationHolidayI[];
}
const AddDateModal: FC<Props> = ({
  username,
  month,
  year,
  onClose,
  visible,
  dateList,
  maxOtHours,
  payrunStartDate,
  roundOff,
  organizationHoliday,
}) => {
  const [form] = Form.useForm();
  const { id, employeeId } = useParams();
  const { mutate: postDate, isLoading: postDataLoading } = usePostDate();
  const [isHoliday, setIsHoliday] = React.useState(false);

  const { startDate, endDate } = getPayrollStartEndDates(
    payrunStartDate,
    month,
    year,
  );

  let minStep: 10 | 15 | 30 = null;
  let hideMins = false;
  switch (roundOff) {
    case 30:
      minStep = 30;
      break;
    case 15:
      minStep = 15;
      break;
    case 60:
      hideMins = true;
      break;
  }

  const disableDate = (date: Dayjs) => {
    if (date.isBefore(startDate) || date.isAfter(endDate)) {
      return true;
    }
    return dateList.some((dateItem) => {
      const dayDate = dayjs(dateItem);
      if (
        date.year() === dayDate.year() &&
        date.month() === dayDate.month() &&
        date.date() === dayDate.date()
      ) {
        return true;
      }
    });
  };

  const disabledTime = () => {
    if (maxOtHours !== undefined) {
      return {
        disabledHours: () => {
          const disabledHours = [];
          for (let i = maxOtHours + 1; i < 24; i++) {
            disabledHours.push(i);
          }
          return disabledHours;
        },
        disabledMinutes: (selectedHour: number) => {
          if (selectedHour === maxOtHours) {
            const disabledMinutes = [];
            for (let i = 1; i < 60; i++) {
              disabledMinutes.push(i);
            }
            return disabledMinutes;
          } else {
            return [];
          }
        },
      };
    }
  };

  const handleSubmit = () => {
    form.validateFields();
    const values = form.getFieldsValue();
    if (!values.date) {
      console.error('Date is not selected');
      return;
    }
    postDate(
      {
        id: parseInt(id),
        employeeId,
        fullName: username,
        date: values.date.format('YYYY-MM-DD'),
        noPayMinutes:
          values?.noPayHours?.hour() * 60 + values?.noPayHours?.minute(),
        overTimeMinutes:
          values?.overTimeHours?.hour() * 60 + values?.overTimeHours?.minute(),
      },
      { onSuccess: () => onClose() },
    );
  };

  const defaultMonth = dayjs(`${year}-${month + 1}`);

  return (
    <ModalComponent
      visible={visible}
      onCancel={() => onClose()}
      width={540}
      submitText="Add"
      loading={postDataLoading}
      disabled={postDataLoading}
      onSubmit={handleSubmit}
      title="Add Date">
      <Form size="small" form={form}>
        <Form.Item
          name="date"
          rules={[
            {
              required: true,
              message: 'Please enter select a date',
            },
          ]}>
          <DatePickerComp
            size="middle"
            label="Date"
            defaultPickerValue={defaultMonth}
            disabledDate={(date) => disableDate(date)}
            onChange={(date) => {
              if (
                date &&
                organizationHoliday?.some((holiday) =>
                  dayjs(holiday?.date?.toString()).isSame(dayjs(date), 'day'),
                )
              ) {
                setIsHoliday(true);
              } else {
                setIsHoliday(false);
              }
            }}
          />
        </Form.Item>
        <Row gutter={[32, 24]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {!isHoliday && (
              <Form.Item name="noPayHours">
                <TimePickerComp
                  label="No pay hours"
                  size="small"
                  disabledTime={disabledTime}
                  format={hideMins ? 'HH' : 'HH:mm'}
                  minuteStep={minStep}
                  showNow={false}
                />
              </Form.Item>
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name="overTimeHours">
              <TimePickerComp
                label="OT hours"
                size="small"
                showNow={false}
                format={hideMins ? 'HH' : 'HH:mm'}
                minuteStep={minStep}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModalComponent>
  );
};

export default AddDateModal;
