import { AxiosError } from 'axios';
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { useGetPeople } from '../api/peopleHooks';
import { IndividualPeopleI } from '../types/people.types';

interface State {
  isLoading: boolean;
  people: IndividualPeopleI | null;
  error?: AxiosError<any, any> | null;
}

type Actions =
  | {
      type: 'UPDATE';
      payload: {
        people: IndividualPeopleI | null;
        isLoading: boolean;
        error?: AxiosError<any, any> | null;
      };
    }
  | {
      type: 'CLEAR';
    };

type ContextValue = {
  state: State;
  fetchPeopleById: (employeeKey: string) => void;
};

const Context = createContext<ContextValue | undefined>(undefined);

function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'UPDATE': {
      state.people = action.payload.people;
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      return { ...state };
    }
    case 'CLEAR': {
      return { people: null, isLoading: false };
    }
    default: {
      throw new Error(`Unhandled action`);
    }
  }
}

export const IndividualPeopleStateProvider: React.FC<PropsWithChildren> = ({
  ...props
}) => {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    people: null,
  });

  const currentEmployeeKey = useRef('');

  const { isLoading, error, data = null, mutate: fetchPeople } = useGetPeople();

  const fetchPeopleById = (employeeKey: string) => {
    currentEmployeeKey.current = employeeKey;
    fetchPeople(employeeKey);
  };

  useEffect(() => {
    const people =
      currentEmployeeKey.current == String(state.people?.id)
        ? data || state.people
        : data;

    dispatch({
      type: 'UPDATE',
      payload: {
        people,
        isLoading,
        error,
      },
    });
  }, [isLoading, error, data]);

  return <Context.Provider value={{ state, fetchPeopleById }} {...props} />;
};

export const useIndividualPeopleState = (): ContextValue => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(
      `useIndividualPeopleState must be used within a IndividualPeopleStateProvider`,
    );
  }
  return context;
};
