import React, { FC } from 'react';

import { Avatar, Col, Form, Image, message, Row, Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { useSelector } from 'react-redux';

import { css } from '@emotion/react';

import { B, GhostButton, H } from '../../../../components';
import { BASE_URL } from '../../../../configs';
import { RootState } from '../../../../store/reducer';
import { getCurrentAuthToken } from '../../../../util/storageUtil';
import { normFile } from '../../../../util/utils';
import styled from '@emotion/styled';
import { InfoSVG, UploadSVG } from '../../../../assets';

const props: UploadProps = {
  showUploadList: {
    showRemoveIcon: false,
  },
};

interface ImageProp {
  onChangeImageUpload: (url: string) => void;
  isFieldDataChanged: (isDataChanged: boolean) => void;
}

const MainCard = styled.div`
  padding: 24px 32px 0px;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;
const ProfileImage: FC<ImageProp> = ({
  onChangeImageUpload,
  isFieldDataChanged,
}) => {
  const me = useSelector((state: RootState) => state.people.me);

  return (
    <>
      <MainCard>
        <Row
          css={css`
            margin-bottom: 16px;
            @media (max-width: 768px) {
              padding-top: 24px;
            }
          `}>
          <H type="h6">Profile</H>
        </Row>
        <Row
          css={css`
            margin-bottom: 24px;
          `}>
          <B type="b-default" color="#757575">
            This logo will be visible to to any team member
          </B>
        </Row>
        <Row
          css={css`
            @media (max-width: 768px) {
              flex-wrap: nowrap;
            }
          `}>
          <Col flex="80px">
            {me?.profilePicture != null ? (
              <Avatar src={me?.profilePicture} size={64} shape="square" />
            ) : (
              <Avatar
                css={css`
                  color: #f56a00;
                  background-color: #fde3cf;
                  .ant-avatar-string {
                    font-size: 34px;
                  }
                `}
                size={64}
                shape="square">
                {me?.fullName?.charAt(0)}
              </Avatar>
            )}
          </Col>
          <Col flex="auto">
            <Row
              css={css`
                display: flex;
                flex-direction: row;
              `}>
              <Form.Item
                getValueFromEvent={normFile}
                noStyle
                rules={[
                  {
                    required: false,
                    message: 'image is required',
                  },
                ]}>
                <Upload
                  name="file"
                  accept=".jpg, .png"
                  multiple={false}
                  action={`${BASE_URL}/organization/fileUpload`}
                  headers={{
                    Authorization: `Bearer ${getCurrentAuthToken()}`,
                  }}
                  onChange={(info) => {
                    isFieldDataChanged(true);
                    if (info.file.status === 'done') {
                      message.success(
                        `${info.file.name} file uploaded successfully`,
                      );
                      onChangeImageUpload(info.file.response);
                    } else if (info.file.status === 'error') {
                      message.error('error');
                    }
                  }}
                  {...props}>
                  <GhostButton
                    mb="4px"
                    icon={<img src={UploadSVG} width={14} height={17} />}>
                    Upload Profile Photo
                  </GhostButton>
                </Upload>
              </Form.Item>
            </Row>
            <Row>
              <B type="b-small" color="#757575" pt="6px">
                <Image
                  preview={false}
                  src={InfoSVG}
                  css={css`
                    margin-right: 5px;
                    width: 20px;
                    height: 20px;
                  `}
                />
                You can upload a jpg, GIF or PNG file (File size limit is 5 MB).
              </B>
            </Row>
          </Col>
        </Row>
      </MainCard>
    </>
  );
};
export default ProfileImage;
