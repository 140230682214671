import React, { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import Dropzone from 'react-dropzone';
import { B, Div, InputComp, ModalComponent } from '../../../components';
import theme from '../../../theme';
import { LoanDocumentI } from '../../../types/loan.types';
import { uniqueId } from 'lodash';
import moment from 'moment';
import styled from '@emotion/styled';
import { UploadDocumentSVG } from '../../../assets';

const Card = styled.div`
  background: #fbfbfb;
  border: 1px dashed #006aff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 55px;
  display: flex;
  flex-direction: column;
  text-align: center;
`;
interface Props {
  visible: boolean;
  handleCancel: () => void;
  onSubmit: (document: LoanDocumentI) => void;
  isViewLoan?: boolean;
}

export const DocumentUploadModal: FC<Props> = ({
  visible,
  handleCancel,
  onSubmit,
  isViewLoan,
}) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [isName, setIsName] = useState('');
  const [isFile, setIsFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const dateFormat = 'YYYY-MM-DD';
  const date = moment().format(dateFormat);

  useEffect(() => {
    form.setFieldsValue({ title: isName || null });
  }, [isName]);

  const onFinish = (formValues: { title: string }) => {
    if (selectedFile && !isViewLoan) {
      onSubmit({
        id: uniqueId(),
        name: formValues.title,
        document: selectedFile,
        updatedAt: date,
      });
    }
    if (isViewLoan && selectedFile) {
      onSubmit({
        name: formValues.title,
        document: selectedFile,
      });
    }
  };

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      const fileName = file.name.slice(0, file.name.lastIndexOf('.'));
      setIsName(fileName);
      setIsFile(true);
    }
  };
  return (
    <>
      <ModalComponent
        form={form}
        submitText="Upload"
        disabled={!isFile}
        visible={visible}
        title={
          <div
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => setDisabled(true)}>
            <B type="b-large-semibold">Upload your document or file</B>
          </div>
        }
        onCancel={handleCancel}>
        <Form form={form} onFinish={onFinish} validateTrigger="onBlur">
          <Form.Item>
            <Dropzone
              onDrop={handleDrop}
              accept=".pdf"
              minSize={1024}
              maxSize={3072000}>
              {({
                getRootProps,
                getInputProps,
                isDragAccept,
                isDragReject,
              }) => {
                const additionalClass = isDragAccept
                  ? 'accept'
                  : isDragReject
                  ? 'reject'
                  : '';

                return (
                  <Card
                    {...getRootProps({
                      className: `dropzone ${additionalClass}`,
                    })}>
                    <>
                      <input {...getInputProps()} />
                      <div className="ant-upload-drag-icon">
                        <img src={UploadDocumentSVG} />
                      </div>
                      <Div
                        textAlign="center"
                        display="flex"
                        flexDirection="row"
                        paddingX="84px">
                        <B
                          type="b-large-semibold"
                          color={theme.gray600}
                          pt="8px">
                          Drag and drop or
                        </B>
                        &nbsp;
                        <B
                          type="b-large-semibold"
                          color={theme.blue400}
                          marginBottom="0px"
                          pt="8px">
                          Browse
                        </B>
                      </Div>
                      <B type="b-default" color={theme.gray600} pt="4px">
                        Upload your document
                      </B>
                    </>
                  </Card>
                );
              }}
            </Dropzone>
          </Form.Item>
          <Form.Item
            name="title"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Please enter file name!',
              },
            ]}>
            <InputComp
              size="small"
              onChange={(e) => setIsName(e.target.value)}
              placeholder="Name your document or file"
            />
          </Form.Item>
        </Form>
      </ModalComponent>
    </>
  );
};
