import React, { FC } from 'react';
import { Card, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { B, PrimaryButton } from '../../../components';
import { RootState } from '../../../store/reducer';
import { isAllowed } from '../../../util/permissionUtil';
import { Permission } from '../../../types/permissions.enum';
import theme from '../../../theme';

const CompletionNotification: FC = () => {
  const me = useSelector((state: RootState) => state.people.me);
  const navigate = useNavigate();
  if (
    isAllowed(Permission.CREATE_EDIT_USERS) ||
    me?.profileStatus?.summary.isCompleted
  ) {
    return null;
  }
  return (
    <div>
      <Card
        style={{
          borderRadius: 4,
          minHeight: '124px',
          border: `1px solid ${theme.blue200}`,
          boxSizing: 'border-box',
          background: `${theme.lightBlue100}`,
          marginBottom: '24px',
        }}>
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={16}>
            <B type="b-large-semibold" color={theme.blue500}>
              Complete your profile by filling details required!
            </B>
            <B type="b-default" color={theme.gray850}>
              Plan the best course for your payroll process from the day you
              recruit your first employee! Fill all the required information to
              onboard employees to your company.
            </B>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={1}>
            <PrimaryButton
              mt="12px"
              onClick={() => navigate('/account-details/personal-details')}>
              Complete Your Profile
            </PrimaryButton>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
export default CompletionNotification;
