import { useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';
import { getApiError } from '../util/getApiError';
import { message } from 'antd';
import { HolidaysI } from '../store/actions';

export const useNonDefaultHolidays = () => {
  return useQuery<HolidaysI[], AxiosError>(
    'allHolidays',
    async () => {
      const response = await axios.get('/organization/getNonDefaultHolidays');
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};
