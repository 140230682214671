import { numberThousandSeparator } from '../../../util/utils';
import { SalaryItemsByTypeI } from '../pages/payslip/utils/getSalaryItemsByType';

export const getPayItemSum = (payrollItem: Partial<SalaryItemsByTypeI>) => {
  let payrollItemSum = 0;
  payrollItem.map((item) => {
    payrollItemSum += item?.amount || 0;
  });

  return numberThousandSeparator(payrollItemSum);
};
