import styled from '@emotion/styled';
import { ColumnProps } from 'antd/lib/table';
import { uniqueId } from 'lodash';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { B, TableComp } from '../../../../components';
import theme from '../../../../theme';
import { LoanI, LoanStatus } from '../../../../types/loan.types';
import { useWindowSize } from '../../../../util/screenSize';
import { tableSorter } from '../../../../util/tableSorter';
import { numberThousandSeparator } from '../../../../util/utils';

const Container = styled.div`
  border: 1px solid ${theme.gray300};
  @media (max-width: 450px) {
    border: none;
  }
  border-radius: 4px;
  .view {
    display: none;
    width: 65px;
  }
  .ant-table-row:hover .view {
    display: block;
  }
`;

const StatusColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

interface Props {
  loanList: LoanI[];
  userLoan?: boolean;
}

const LoanListTable: FC<Props> = ({ loanList, userLoan }) => {
  const { isTablet, isDesktop } = useWindowSize();

  const columns: ColumnProps<LoanI>[] = [
    {
      title: 'Loan ID',
      width: 50,
      dataIndex: 'loanId',
      render: (_value: string, data) => (
        <B type="b-small">{data.loanId ? data.loanId : '-'}</B>
      ),
      sorter: (a, b) => tableSorter.defaultSort(a.loanId, b.loanId),
      sortDirections: ['descend', 'ascend'],
      key: 'loanId',
      defaultSortOrder: 'descend',
    },
    {
      title: 'Loan Amount',
      width: 80,
      dataIndex: 'loanAmount',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.loanAmount ? numberThousandSeparator(data.loanAmount) : '-'}
        </B>
      ),
    },
    {
      title: 'Paid Amount',
      width: 80,
      dataIndex: 'paidAmount',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.paidAmount ? numberThousandSeparator(data.paidAmount) : '-'}
        </B>
      ),
    },
    {
      title: 'Balance Amount',
      width: 80,
      dataIndex: 'balanceAmount',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.balanceAmount
            ? numberThousandSeparator(data.balanceAmount)
            : '-'}
        </B>
      ),
    },
    {
      title: 'Status',
      width: 50,
      dataIndex: 'loanStatus',
      render: (_value: string, data) => (
        <StatusColumn>
          <B
            type="b-small"
            color={
              data.loanStatus === LoanStatus.ACTIVE
                ? theme.blue500
                : data.loanStatus === LoanStatus.DRAFT
                ? theme.orange400
                : data.loanStatus === LoanStatus.SETTLED
                ? theme.green500
                : theme.gray600
            }>
            {data.loanStatus
              ? data.loanStatus.charAt(0).toUpperCase() +
                data.loanStatus.substr(1).toLowerCase()
              : '-'}
          </B>
        </StatusColumn>
      ),
    },
    {
      title: '',
      align: 'left',
      dataIndex: 'viewMore',
      key: uniqueId(),
      width: 50,
      render: (_value: string, data) => (
        <Link
          className="view"
          state={{ employee: data }}
          to={{
            pathname: userLoan ? `/user-loan/${data.id}` : `/loan/${data.id}`,
          }}>
          <p
            style={{
              margin: 0,
              fontSize: 12,
              color: theme.blue500,
            }}>
            View More
          </p>
        </Link>
      ),
    },
  ];
  return (
    <Container>
      <TableComp
        pagination={false}
        columns={columns}
        size="middle"
        fullWidth={true}
        dataSource={loanList}
        scroll={{
          x: isTablet ? 1250 : isDesktop ? 900 : 900,
          y: window.innerHeight - 500,
        }}
      />
    </Container>
  );
};

export default LoanListTable;
