import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router';
import { Div, PrimaryButton, SecondaryButton } from '../../../components';
interface Props {
  onClickPostPayRun: () => void;
}
const SalaryInfoFooter: FC<Props> = ({ onClickPostPayRun }) => {
  const navigate = useNavigate();
  return (
    <>
      <Div paddingTop="58px">
        <Row>
          <Col xs={24} sm={12} md={12} lg={10} xl={10}>
            <Row justify="start">
              <SecondaryButton htmlType="button" onClick={() => navigate(-1)}>
                Cancel
              </SecondaryButton>
            </Row>
          </Col>
          <Col xs={24} sm={0} md={2} lg={4} xl={3}></Col>
          <Col xs={24} sm={12} md={10} lg={10} xl={11}>
            <Row justify="end">
              <SecondaryButton
                onClick={() => {
                  navigate(-1);
                }}
                htmlType="button"
                mr={10}>
                Delete Pay Run
              </SecondaryButton>
              <PrimaryButton onClick={() => onClickPostPayRun()}>
                Post Pay Run
              </PrimaryButton>
            </Row>
          </Col>
        </Row>
      </Div>
    </>
  );
};

export default SalaryInfoFooter;
