export enum GratuityFilterTypes {
    ALL_GRATUITY = 'ALL_GRATUITY',
    PENDING = 'PENDING',
    PAID = 'PAID',
}

export enum GratuityTypes {
    ESTIMATION = 'ESTIMATION',
    ACTUAL = 'ACTUAL'
}

export interface GratuityI {
    id: number;
    userId: number;
    employeeNumber: string;
    name: string;
    lastDrawnSalary: number;
    gratuityAmount: number;
    gratuityType: string;
    resignedDate: string;
    dueDate: string;
    status: string;
    isPaid: boolean;
    notes?: GratuityNoteI[];
    documents?: GratuityDocumentI[];
}

export interface UpdateGratuityI {
    id: number;
    data: GratuityI;
}

export interface GratuityNoteI {
    id?: string | number;
    gratuityId?: number;
    note: string;
    updatedAt?: string;
}

export interface GratuityDocumentI {
    id?: string | number;
    gratuityId?: number;
    name: string;
    document: File;
    fileUrl?: string;
    updatedAt?: string;
}
export interface GratuityGeneratedBankFilesI {
    createdAt: string;
    bankName: string;
    dateOfCrediting?: string;
    valueDate?: string;
    total: number;
    fileUrl: string;
}

