import styled from '@emotion/styled';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import FormItem from 'antd/lib/form/FormItem';
import React, { FC, useState } from 'react';
import { B, Div, InputComp, SelectComp } from '../../../components';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import theme from '../../../theme';
import { OTTypesI } from '../../../types/organization.types';
import { EditSVG, GrayCancel } from '../../../assets';

const MainDiv = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.gray300};
  padding: 11px 22px 16px 19px;
  height: 56px;
  justify-content: space-between;
`;

const CustomFormItem = styled(FormItem)`
  width: 187px;
`;

const EditIcon = styled.img`
  width: 13px;
  height: 13px;
  cursor: pointer;
`;

const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const CustomInputComp = styled(InputComp)`
  height: 24px;
  width: 160px;
  margin-left: 10px;
`;

interface Props {
  otList: OTTypesI[];
  onEdit: (id: number | string) => void;
  onRemove: (id: number | string) => void;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onChangeRate: (value: number, id: number | string) => void;
  onChangeCheckBox: (e: CheckboxChangeEvent, id: number | string) => void;
}

const OverTimeTypeList: FC<Props> = ({
  otList,
  onEdit,
  onRemove,
  onChange,
  onChangeRate,
  onChangeCheckBox,
}) => {
  const [editIndex, setEditIndex] = useState(-1);

  const edit = (index: number, otName: string, id: number | string) => {
    if (index === editIndex) {
      return (
        <FormItem
          name={otName + index}
          rules={[
            {
              required: true,
              message: 'Please enter over time',
            },
          ]}>
          <CustomInputComp
            onChange={(e) => onChange(e)}
            onBlur={() => {
              onEdit(id);
              setEditIndex(-1);
            }}
            defaultValue={otName}
          />
        </FormItem>
      );
    } else {
      return (
        <B type="b-default" pl="18px" pt="6px">
          {otName}
        </B>
      );
    }
  };

  return (
    <>
      {otList.map((item, index) => {
        return (
          <MainDiv>
            <Div display="flex">
              <FormItem name={'isEnable' + index}>
                <CheckBoxComp
                  defaultChecked={item.isEnable}
                  onChange={(e) => onChangeCheckBox(e, item.id)}
                />
              </FormItem>
              {edit(index, item.otName, item.id)}
            </Div>
            <Div display="flex">
              <Div pt="4px" pr="36px">
                <EditIcon onClick={() => setEditIndex(index)} src={EditSVG} />
              </Div>
              <CustomFormItem name={'rate' + index}>
                <SelectComp
                  size="small"
                  placeholder="Select Rates"
                  onChange={(v) => {
                    onChangeRate(Number(v), item.id);
                  }}
                  options={[
                    {
                      label: 1,
                      value: 1,
                    },
                    {
                      label: 1.25,
                      value: 1.25,
                    },
                    {
                      label: 1.5,
                      value: 1.5,
                    },
                    {
                      label: 2,
                      value: 2,
                    },
                    {
                      label: 2.5,
                      value: 2.5,
                    },
                  ]}
                  defaultValue={item.rate}
                />
              </CustomFormItem>
              <Div pl="38px" pt="4px">
                <CloseIcon
                  onClick={() => {
                    if (item.id) {
                      onRemove(item.id);
                    }
                  }}
                  src={GrayCancel}
                />
              </Div>
            </Div>
          </MainDiv>
        );
      })}
    </>
  );
};

export default OverTimeTypeList;
