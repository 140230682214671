import React, { FC, useEffect, useState } from 'react';

import { Col, Form, Row, Spin } from 'antd';
import moment, { Moment } from 'moment';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import styled from '@emotion/styled';

import {
  B,
  Div,
  PrimaryButton,
  RouteLeavingGuard,
} from '../../../../components';
import { FooterComponent } from '../../../../components/FooterComponent';
import { useIndividualPeopleState } from '../../../../providers/IndividualPeopleState';
import {
  editPersonalDetails,
  getMinimumUserInfo,
} from '../../../../store/actions';
import theme from '../../../../theme';
import { Permission, ValueOf } from '../../../../types';
import {
  IndividualPeopleI,
  PersonalDetailI,
} from '../../../../types/people.types';
import { isAllowed } from '../../../../util/permissionUtil';
import { scrollToFirstErrorField } from '../../../../util/utils';
import { PeopleLayout } from '../../shared';
import PeopleSelect from '../../shared/PeopleSelect';
import { EmergencyForm } from './EmergencyForm';
import { PersonalDetailsForm } from './PersonalDetailsForm';
import ProfilePicture from './ProfilePicture';
import { isValidMoment } from '../../../../util/isValidMoment';
import { DispatchType } from '../../../../store';
import dayjs from 'dayjs';

type ParamsI = {
  employeeKey: string;
};
const FormComponent = styled(Form)`
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: #00318c;
  }
  margin-bottom: 50px;
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-bottom: 10px;
  }
  @media (max-width: 768px) {
    margin-bottom: 80px;
  }
`;
const PersonalPageHeading = styled(Row)`
  padding: 32px;
  @media (max-width: 1024px) and (min-width: 500px) {
    display: flex;
    flex-direction: row;
  }
  @media (max-width: 768px) {
    padding: 0px;
    display: flex;
    flex-direction: column-reverse;
  }
`;
const Heading = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const ArchivedLabel = styled.div`
  padding: 2px 12px;
  background: ${theme.gray500};
  border-radius: 24px;
  height: 24px;
  margin: auto 8px;
`;

const PersonalDetails: FC = () => {
  const {
    state: { people, isLoading, error },
    fetchPeopleById,
  } = useIndividualPeopleState();
  const [isFieldDataChanged, setIsFieldDataChanged] = useState(false);
  const [form] = Form.useForm();
  const dispatch: DispatchType = useDispatch();
  const params = useParams<ParamsI>();
  const [imageUrl, setImageUrl] = useState<string>();

  const navigate = useNavigate();

  useEffect(() => {
    const newPeople = { ...people };

    if (newPeople) {
      for (const [key, value] of Object.entries(newPeople)) {
        let newValue: ValueOf<IndividualPeopleI> | Moment | unknown = value; //Todo: Remove unknown type
        if (key == 'DOB') {
          newValue = !isValidMoment(people?.DOB, null, 'YYYY-MM-DD')
            ? null
            : people?.DOB
            ? dayjs(people?.DOB)
            : undefined;

          if (!newValue) {
            form.resetFields([key]);
          }
        }

        form.setFieldsValue({ [key]: newValue });
      }
    }
  }, [people, params.employeeKey]);

  const onValuesChange = () => {
    setIsFieldDataChanged(true);
  };

  const defaultDisable = !isAllowed(Permission.CREATE_EDIT_USERS);

  const onFinish = async (data: PersonalDetailI) => {
    setIsFieldDataChanged(false);
    const value = {
      ...data,
      profilePicture: imageUrl,
      DOB: dayjs(data.DOB).format('YYYY-MM-DD'),
    };

    await dispatch(editPersonalDetails(value, params.employeeKey));
    fetchPeopleById(params.employeeKey);
    dispatch(getMinimumUserInfo());

    if (people?.isPending) {
      navigate(`/contact/employment-details/${params.employeeKey}`);
    }
  };

  const shouldBlockNavigation = () => {
    return isFieldDataChanged;
  };

  return (
    <>
      <FormComponent
        onFinish={(e) => onFinish(e as PersonalDetailI)}
        onValuesChange={onValuesChange}
        onFinishFailed={scrollToFirstErrorField}
        form={form}
        layout="vertical"
        wrapperCol={{ span: 50 }}>
        <PeopleLayout>
          <Spin spinning={isLoading}>
            <RouteLeavingGuard
              when={isFieldDataChanged}
              navigate={(path) => navigate(path)}
              shouldBlockNavigation={shouldBlockNavigation}
            />
            <PersonalPageHeading>
              <Heading>
                <B type="b-large-semibold">Personal Details</B>
                {people?.isArchived && (
                  <ArchivedLabel>
                    <B type="b-default" color={theme.white}>
                      Archived
                    </B>
                  </ArchivedLabel>
                )}
              </Heading>
              <Col xs={24} sm={12} md={12} lg={12} xl={0} xxl={0}>
                <Div pb="20px">
                  {isAllowed(Permission.CREATE_EDIT_USERS) && (
                    <PeopleSelect defaultIndex="1" />
                  )}
                </Div>
              </Col>
            </PersonalPageHeading>
            <ProfilePicture
              onChangeImageUpload={(url) => setImageUrl(url)}
              isFieldDataChanged={(isDataChanged) =>
                setIsFieldDataChanged(isDataChanged)
              }
              defaultVisible={!defaultDisable}
            />
            <PersonalDetailsForm defaultDisable={defaultDisable} />
            <EmergencyForm defaultDisable={defaultDisable} />
          </Spin>
        </PeopleLayout>
        {!error && !defaultDisable && (
          <FooterComponent
            rightChildren={
              <PrimaryButton loading={isLoading} htmlType="submit">
                {people?.isPending ? 'Next' : 'Save'}
              </PrimaryButton>
            }
          />
        )}
      </FormComponent>
    </>
  );
};

export default PersonalDetails;
