import { monthOrder } from "../configs/dateConfig";
import { monthToString } from "./timeConvertion";

export const sortMonthYear = (a, b) => {
    const monthA = a.month;
    const yearA = a.year;
    const monthB = b.month;
    const yearB = b.year;
    
    if (yearA !== yearB) {
        return yearA - yearB; 
    } else {
       
        return monthOrder[monthA] - monthOrder[monthB];
    }
}

export const getMonthYearString = (month: number, year: number) => {
    
    return `${monthToString(month)} / ${year}`
}