import React from 'react';
import theme from '../theme';
import styled from '@emotion/styled';

interface DividerProps {
  color?: string;
  height?: number;
  width?: number;
}

const DividerStyled = styled.hr<DividerProps>`
  background-color: ${(props) => props.color || theme.gray300};
  height: ${(props) => props.height || '1px'};
  width: ${(props) => props.width || '100%'};
`;

export const Divider = ({
  color,
  height,
  width,
}: {
  color?: string;
  height?: number;
  width?: number;
}) => {
  return <DividerStyled color={color} height={height} width={width} />;
};
