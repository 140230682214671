import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { addDotsForLongText, isHaveThreeDots } from '../../../util/utils';

interface Props {
  text: string;
  maxLength: number;
  fontWeight?: number;
  fontSize?: number;
}
const ThreeDotPopover: FC<Props> = ({
  text,
  maxLength,
  fontWeight,
  fontSize,
}) => {
  return isHaveThreeDots(text, maxLength) ? (
    <Tooltip title={text} trigger="hover" placement="topLeft">
      <p
        style={{
          margin: 0,
          fontSize: fontSize || 14,
          cursor: 'pointer',
          fontWeight: fontWeight || 'normal',
        }}>
        {addDotsForLongText(text, maxLength)}
      </p>
    </Tooltip>
  ) : (
    <p
      style={{
        margin: 0,
        fontSize: fontSize || 14,
        fontWeight: fontWeight || 'normal',
      }}>
      {text ? text : '-'}
    </p>
  );
};

export default ThreeDotPopover;
