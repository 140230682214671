import React, { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { compact } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { B, TableComp, TreeSelectV2 } from '../../../../components';
import { RootState } from '../../../../store/reducer';
import { ExpenseMetaData, IntegrationStatusEnum } from '../../../../types';
import { useWindowSize } from '../../../../util/screenSize';
import { setTreeViewData } from '../../../expenses/util/getExpenseCategory';

const FormItem = styled(Form.Item)`
  margin: 0;
  width: 95%;
`;

type ParamProps = {
  integration: string;
};
interface EssentialProps {
  [key: string]: number;
}

interface PageProps {
  essential: EssentialProps;
  setEssential: (data: EssentialProps) => void;
  onValuesChange: () => void;
}

const IntegrationPayroll: FC<PageProps> = ({
  essential,
  setEssential,
  onValuesChange,
}) => {
  const { isMobile } = useWindowSize();
  const { integration } = useParams<ParamProps>();
  const integrationSettings = useSelector(
    (state: RootState) => state.integration.integrationSetting,
  );

  // selected essentials
  const [selectedOnce, setSelectedOnce] = useState<string[]>([]);

  useEffect(() => {
    setSelectedOnce(
      getNotSelectedEssential(integrationSettings?.settings.essential),
    );
  }, [integrationSettings?.settings.essential]);

  useEffect(() => {
    setSelectedOnce(
      getNotSelectedEssential({
        ...integrationSettings?.settings.essential,
        ...essential,
      }),
    );
  }, [essential]);

  const columns: ColumnProps<any>[] = [
    {
      title: 'In Humanised',
      dataIndex: 'tile',
      key: 'tile',
      render: (value) => <B type="b-default">{value}</B>,
    },
    {
      title: `In ${integration}`,
      width: isMobile ? 150 : 500,
      dataIndex: 'type',
      fixed: 'right',
      key: 'type',
      render: (type) => {
        if (!integrationSettings) return null;
        const optionList: ExpenseMetaData[] | undefined =
          integrationSettings?.settings.metaData.expenses;

        return (
          <FormItem
            name={type}
            rules={[
              {
                required: true,
                message: `⚠ Select relevant value for ${type}`,
              },
            ]}>
            <TreeSelectV2
              disabled={
                ![
                  IntegrationStatusEnum.ACTIVATED,
                  IntegrationStatusEnum.PENDING,
                ].includes(integrationSettings.status)
              }
              overruleWidthPresent={95}
              treeDefaultExpandAll={true}
              onChange={(selection) => {
                setEssential({
                  ...essential,
                  [type]: String(selection),
                });
                onValuesChange();
              }}
              flexDirection="row"
              placeholder="Select an item"
              treeData={setTreeViewData(
                optionList as ExpenseMetaData[],
                selectedOnce,
              )}
              showSearch
              treeNodeFilterProp="title"
              allowClear
            />
          </FormItem>
        );
      },
    },
  ];

  const getNotSelectedEssential = (essentials: any): string[] => {
    const selected: Array<string> = [];
    for (const key in essentials) {
      if (Object.prototype.hasOwnProperty.call(essentials, key)) {
        if (essentials[key]) {
          selected.push(String(essentials[key]));
        }
      }
    }
    return compact(selected);
  };

  const getDataSource = () => {
    const dataSource = [
      {
        tile: 'Employee Provident Fund - EPF',
        type: 'epf',
      },
      {
        tile: 'Employee Trust Fund - ETF',
        type: 'etf',
      },
      {
        tile: 'Salary',
        type: 'salary',
      },
    ];

    return dataSource;
  };

  return (
    <TableComp
      scroll={{
        x: 800,
      }}
      css={css`
        min-width: 100% !important;
      `}
      loading={false}
      columns={columns}
      dataSource={getDataSource()}
      pagination={false}
      withBorders={true}
    />
  );
};

export default IntegrationPayroll;
