import { MappedDataI } from '../open-column-mapping/shared/CSVMapper';
import {
  EMPLOYEE_NAME_DATA_INDEX,
  generateBulkUploadCSV,
} from '../open-column-mapping/util/openPayrollValidator';
import {
  EmployeePaySheetSummary,
  EmployeeSalary as EmployeeSalary,
} from './EmployeeSalary';

export interface KeyValueI {
  [key: string]: string;
}

export class PaySheet {
  private employeeSalaries: EmployeeSalary[];
  private mapDataColumns: MappedDataI[];

  constructor(mapDataColumns: MappedDataI[]) {
    this.mapDataColumns = mapDataColumns;
    this.employeeSalaries = this.autoMapColumns();
  }

  private getEmployeesCount() {
    if (this.mapDataColumns.length) {
      return this.mapDataColumns[0].rows.length;
    }
    return 0;
  }

  private autoMapColumns() {
    const employeeSalaries: EmployeeSalary[] = [];
    for (let index = 0; index < this.getEmployeesCount(); index++) {
      const employeeSalary = new EmployeeSalary();
      this.mapDataColumns.map((mapDataColum) => {
        const value = mapDataColum.rows[index];

        if (mapDataColum.dataIndex === EMPLOYEE_NAME_DATA_INDEX) {
          employeeSalary.setEmployeeName(value);
        }
        if (mapDataColum.type) {
          const salaryItem = {
            type: mapDataColum.type,
            amount: mapDataColum.type === 'info' ? 0 : Number(value),
            title: mapDataColum.name || '',
            dataIndex: mapDataColum.dataIndex,
            value: value,
          };
          employeeSalary.addItem(salaryItem);
        }
      });
      employeeSalaries.push(employeeSalary);
    }

    return employeeSalaries;
  }

  getMapDataColumns() {
    return this.mapDataColumns;
  }

  getEmployeeSalaries() {
    return this.employeeSalaries;
  }

  getEmployeeSalaryItems() {
    return this.employeeSalaries.map((item) => item.getSalaryItems());
  }

  getPaySheetBreakdownByEmployees() {
    const results: EmployeePaySheetSummary[] = [];
    this.employeeSalaries.forEach((employee, index) => {
      const result = {
        key: index,
        ...employee.getSummary(),
        name: employee.getEmployeeName(),
      };
      results.push(result);
    });
    return results;
  }

  getOverallPaySheetSummary() {
    const paySheets = this.getPaySheetBreakdownByEmployees();
    const result = {
      grossAddition: 0,
      grossDeduction: 0,
      epf: 0,
      employerETF: 0,
      employerEPF: 0,
      netSalary: 0,
      netPay: 0,
    };
    paySheets.forEach((paySheet) => {
      result.grossAddition += paySheet.grossAddition;
      result.grossDeduction += paySheet.grossDeduction;
      result.epf += paySheet.epf;
      result.employerETF += paySheet.employerETF;
      result.employerEPF += paySheet.employerEPF;
      result.netSalary += paySheet.netSalary;
      result.netPay += paySheet.netPay;
    });
    return result;
  }

  // Need to optimize this.. Bad code because of time
  generateJSONForServer() {
    const paySheetSummaries = this.getPaySheetBreakdownByEmployees();

    const paySheets = this.getEmployeeSalaryItems().map((salaryItems, i) => {
      let name = '';
      let designation = '';
      let accountNumber = '';
      let bankBranch = '';

      salaryItems.forEach((item) => {
        if (item.dataIndex === 'name') {
          name = `${item.value}`;
        }

        if (item.dataIndex === 'designation') {
          designation = `${item.value}`;
        }

        if (item.dataIndex === 'accountNumber') {
          accountNumber = `${item.value}`;
        }
        if (item.dataIndex === 'bankBranch') {
          bankBranch = `${item.value}`;
        }
      });

      const newItems = salaryItems
        .filter((item) => item.type !== 'info')
        .sort((a) => (a.type === 'info' ? -1 : 0));
      const summary = paySheetSummaries[i];

      newItems.push({
        type: 'info',
        amount: summary.grossDeduction,
        value: summary.grossDeduction,
        title: 'Total Deduction',
        dataIndex: 'grossDeduction',
      });

      newItems.push({
        type: 'info',
        amount: summary.epf,
        value: summary.epf,
        title: `Provident Fund (Employee's Contribution of 8%)`,
        dataIndex: 'epf',
      });

      newItems.push({
        type: 'info',
        amount: summary.employerEPF,
        value: summary.employerEPF,
        title: `Provident Fund (Employer's Contribution of 12%)`,
        dataIndex: 'employerEPF',
      });

      newItems.push({
        type: 'info',
        amount: summary.employerETF,
        value: summary.employerETF,
        title: `Employees' Trust Fund (Employer's Contribution of 3%)`,
        dataIndex: 'employerETF',
      });

      newItems.push({
        type: 'info',
        amount: summary.netSalary,
        value: summary.netSalary,
        title: `Net Salary (Take Home)`,
        dataIndex: 'netSalary',
      });

      newItems.push({
        type: 'info',
        amount: summary.netPay,
        value: summary.netPay,
        title: `Net Pay (CTC - Cost to the Company)`,
        dataIndex: 'netPay',
      });

      return {
        salaryItems: newItems,
        name: name,
        designation: designation,
        accountNumber: accountNumber,
        bankBranch: bankBranch,
        bank: 'Sampath Bank',
      };
    });
    return {
      paySheets,
      bankDownload: generateBulkUploadCSV(this.mapDataColumns),
    };
  }
}
