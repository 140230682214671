import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { FC } from 'react';
import { Div, AvatarComponent, B, TableComp } from '../../../components';
import theme from '../../../theme';
import { GratuityI, GratuityTypes } from '../../../types/gratuity.types';
import { isValidMoment } from '../../../util/isValidMoment';
import { useWindowSize } from '../../../util/screenSize';
import { tableSorter } from '../../../util/tableSorter';
import {
  isHaveThreeDots,
  addDotsForLongText,
  numberThousandSeparator,
} from '../../../util/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer';

interface Props {
  dataSource: GratuityI[];
  loadingData: boolean;
}

const Container = styled.div`
  border: 1px solid ${theme.gray300};
  @media (max-width: 450px) {
    border: none;
  }
  border-radius: 4px;
`;

const PredictedGratuityTable: FC<Props> = ({ dataSource, loadingData }) => {
  const { isTablet, isDesktop, isDesktopLarge } = useWindowSize();

  const predictedGratuityList = dataSource.filter(
    (i) => i.gratuityType === GratuityTypes.ESTIMATION,
  );

  const columns: ColumnProps<GratuityI>[] = [
    {
      title: 'Name',
      width: 200,
      dataIndex: 'name',
      render: (_value: string, data) => (
        <Div display="flex" flexDirection="row">
          <AvatarComponent
            fontSize={12}
            fontWeight={600}
            shape="circle"
            title={data.name.charAt(0).toUpperCase()}
          />
          <Div display="flex">
            {isHaveThreeDots(data.name, 25) ? (
              <B type="b-small-semibold" pt="6px" pl="12px">
                {data.name ? (
                  <Tooltip placement="topLeft" title={data.name}>
                    {isDesktopLarge && data.name
                      ? addDotsForLongText(data.name, 20)
                      : data.name
                      ? addDotsForLongText(data.name, 15)
                      : '-'}
                  </Tooltip>
                ) : (
                  '-'
                )}
              </B>
            ) : (
              <B type="b-small-semibold" pt="6px" pl="12px" pr="8px">
                {data.name ? data.name : '-'}
              </B>
            )}
          </Div>
        </Div>
      ),
      sorter: (a, b) => tableSorter.defaultSort(a.name, b.name),
      sortDirections: ['descend', 'ascend'],
      key: 'fullName',
    },
    {
      title: 'Emp ID',
      width: 100,
      dataIndex: 'employeeNumber',
      render: (_value: string, data) => (
        <B type="b-small">{data.employeeNumber ? data.employeeNumber : '-'}</B>
      ),
      sorter: (a, b) => tableSorter.defaultSort(a.userId, b.userId),
      sortDirections: ['descend', 'ascend'],
      key: 'employeeNumber',
    },
    {
      title: 'Last Drawn Net Salary',
      width: 150,
      dataIndex: 'lastDrawnSalary',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.lastDrawnSalary
            ? numberThousandSeparator(data.lastDrawnSalary)
            : '-'}
        </B>
      ),
    },
    {
      title: 'Gratuity Amount',
      width: 150,
      dataIndex: 'paidAmount',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.gratuityAmount
            ? numberThousandSeparator(data.gratuityAmount)
            : '-'}
        </B>
      ),
    },
    {
      title: 'Due Date',
      width: 150,
      dataIndex: 'dueDate',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.dueDate
            ? isValidMoment(data.dueDate, '-', 'YYYY-MM-DD').toString()
            : '-'}
        </B>
      ),
    },
  ];
  const scrollTarget = useSelector(
    (state: RootState) => state.dashboard.scrollTarget,
  );
  return (
    <Container>
      <TableComp
        rowKey={(item) => {
          return String(item.id);
        }}
        rowClassName={(row) =>
          row.id === scrollTarget ? 'yellow-highlight' : ''
        }
        pagination={false}
        loading={loadingData}
        columns={columns}
        size="large"
        fullWidth={true}
        dataSource={predictedGratuityList}
        scroll={{
          x: isTablet ? 1250 : isDesktop ? 1075 : 1075,
          y: window.innerHeight - 300,
        }}
      />
    </Container>
  );
};

export default PredictedGratuityTable;
