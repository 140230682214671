//import 'antd/dist/antd.css'; // TODO: do we really need this

import React, { FC, useEffect, useState } from 'react';

import { Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { css } from '@emotion/react';
import { numberThousandSeparator } from '../../../util/utils';
import {
  SalaryItemI,
  EmployeePaySheetSummary,
} from '../modules/EmployeeSalary';
import theme from '../../../theme';
import { Div } from '../../../components';

const { Text } = Typography;

const columns: ColumnProps<PersonalSalaryTableI>[] = [
  {
    title: 'Description',
    dataIndex: 'description',
  },
  {
    title: 'Amount',
    dataIndex: 'value',
    align: 'right',
    className: 'column-money',
  },
];

interface PersonalSalaryTableI {
  key: string;
  description: string;
  value: string;
}
interface Prop {
  selectedPaySheetSummary: EmployeePaySheetSummary;
  selectedSalaryItems: SalaryItemI[];
}

const PersonalSalaryInformationTable: FC<Prop> = ({
  selectedSalaryItems,
  selectedPaySheetSummary,
}) => {
  const [dataSource, setDataSource] = useState<PersonalSalaryTableI[]>([]);

  useEffect(() => {
    const newDataSource = selectedSalaryItems
      .filter((item) => item.type !== 'info')
      .sort((a) => (a.type === 'info' ? -1 : 0));
    setDataSource(
      newDataSource.map((item) => {
        return {
          key: item.dataIndex,
          description: item.title,
          value: numberThousandSeparator(Number(item.amount)),
        };
      }),
    );
  }, [selectedSalaryItems]);

  return (
    <>
      <Div border={`0.5px solid ${theme.gray300}`} borderRadius="4px">
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{
            x: 50,
          }}
          summary={() => {
            const {
              grossAddition,
              grossDeduction,
              epf,
              employerEPF,
              employerETF,
              netSalary,
              netPay,
            } = selectedPaySheetSummary;
            return (
              <>
                <TableRow bold title="Gross Salary" value={grossAddition} />
                <TableRow title="Total Deductions" value={grossDeduction} />
                <TableRow
                  title={`Provident Fund (Employee's Contribution of 8%)`}
                  value={epf}
                />
                <TableRow
                  title={`Provident Fund (Employer's Contribution of 12%)`}
                  value={employerEPF}
                />
                <TableRow
                  title={`Employees' Trust Fund (Employer's Contribution of 3%)`}
                  value={employerETF}
                />
                <TableRow
                  bold
                  title="Net Salary (Take Home)"
                  value={netSalary}
                />
                <TableRow
                  bold
                  title="Net Pay (CTC - Cost to the Company)"
                  value={netPay}
                />
              </>
            );
          }}
          css={css`
            .ant-table-thead > tr > th {
              background: rgba(51, 117, 238, 0.1);
              border-radius: 4px 4px 0px 0px;
              font-family: Inter;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 19px;
              color: #0052ea;
              padding: 16px 32px;
            }
            .ant-table-tbody > tr > td {
              font-family: Inter;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              border-bottom: 1px solid #e0e0e0;
              padding: 16px 32px;
            }

            .ant-typography.ant-typography-danger {
              color: #000000 !important;
              text-align: right;
            }
          `}
        />
      </Div>
    </>
  );
};

export default PersonalSalaryInformationTable;

interface TableRowProp {
  bold?: boolean;
  title: string;
  value: number;
}
const TableRow: FC<TableRowProp> = ({ title, value, bold }) => {
  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell index={1}>
          <Text
            type="danger"
            css={css`
              padding: 17px 12px;
              text-align: right;
              font-family: Inter;
              font-style: normal;
              font-weight: ${bold ? '600' : '400'};
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.011px;
            `}>
            {title}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell align="right" index={1}>
          <Text
            type="danger"
            css={css`
              padding: 17px 12px;
              text-align: right;
              font-family: Inter;
              font-style: normal;
              font-weight: ${bold ? '600' : '400'};
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.011px;
            `}>
            {numberThousandSeparator(value)}
          </Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};
