export const EMPLOYEE_TYPES = [
  {
    label: 'Full Time',
    value: 'FULL TIME',
  },
  {
    label: 'Part Time',
    value: 'PART TIME',
  },
  {
    label: 'Probation',
    value: 'PROBATION',
  },
  {
    label: 'Contract',
    value: 'CONTRACT',
  },
  {
    label: 'Sub Contract',
    value: 'SUB CONTRACt',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const LEAVE_LIST = [
  {
    label: 'Annual Leave',
    value: 'ANNUAL_LEAVE',
  },
  {
    label: 'Casual Leave',
    value: 'CASUAL_LEAVE',
  },
  {
    label: 'Sick Leave',
    value: 'SICK_LEAVE',
  },
  {
    label: 'No Pay',
    value: 'NO_PAY',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const LEAVE_NAMES = [
  {
    label: 'Study leave',
    value: 'STUDY_LEAVE',
  },
  {
    label: 'Training leave',
    value: 'TRAINING_LEAVE',
  },
  {
    label: 'Work on holiday',
    value: 'WORK_ON_HOLIDAY',
  },
  {
    label: 'Maternity leave',
    value: 'MATERNITY_LEAVE',
  },
  {
    label: 'Lieu leave',
    value: 'LIEU_LEAVE',
  },
];

export const EXECUTIVE = 'executive';
