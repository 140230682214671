import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Checkbox, Col, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { B, Div, PrimaryButton } from '../../../../components';
import { CheckBoxComp } from '../../../../components/v2/CheckBoxComp';
import { RootState } from '../../../../store/reducer';
import theme from '../../../../theme';
import { EtfReportDataI, UserPayrollI } from '../../../../types/report.types';
import { ReportLayout } from '../../shared/report-layout/ReportLayout';
import { generateETFExports } from '../../utils/generateETFExport';
import ExportModal from './ExportModal';
import ReportMenuSelector, { DropdownSection } from '../../shared/ReportMenuSelector';

const DropdownMenu = styled(Menu)`
  background: ${theme.white};
  border: 1px solid ${theme.gray300};
  border-radius: 4px;
`;

const FilesAndForms: FC = () => {
  const [visibleEpfModal, setVisibleEpfModal] = useState(false);
  const [visibleT10Modal, setVisibleT10Modal] = useState(false);
  const [visibleAnnualStatementModal, setVisibleAnnualStatementModal] =
    useState(false);
  const [employees, setEmployees] = useState<number[]>([]);
  const allPeoples = useSelector((state: RootState) => state.people.allPeoples);

  const usersWithData: number[] = [];
  let selectedUserIds: number[] = [];

  const etfReportData: EtfReportDataI[] = useSelector(
    (state: RootState) => state.report.payrollETF,
  );

  useEffect(() => {
    onCheckAllEmployees();
  }, [allPeoples]);

  const onCheckAllEmployees = () => {
    const allEmployee: number[] = [];
    allPeoples.map((item) => {
      return allEmployee.push(item.id);
    });
    setEmployees(allEmployee);
  };

  const allEmployeesMenu = (
    <Checkbox.Group
      value={employees}
      onChange={(e) => {
        setEmployees(e as number[]);
      }}>
      <DropdownMenu>
        <Div overflow="scroll" maxHeight="350px">
          {allPeoples
            .sort((a, b) => a.fullName?.localeCompare(b.fullName) )
            .map((item) => {
              return (
                <Div
                  borderBottom={`1px solid ${theme.gray300}`}
                  p="11px 14px"
                  mb="0px"
                  onClick={(e) => e.stopPropagation()}
                  key={item.id}>
                  <CheckBoxComp key={item.id} value={item.id}>
                    {item.fullName || item.preferredName || item.firstName || item.lastName}{' '}
                    {item.employeeNumber ? `(${item.employeeNumber})` : `(-)`}{' '}
                    {item.isArchived && ` (Archived)`}
                  </CheckBoxComp>
                </Div>
              );
            })}
        </Div>
        <Div
          display="flex"
          flexDirection="row"
          p="11px 14px"
          key={1}
          onClick={(e) => e.stopPropagation()}>
          <B
            type="b-default"
            color={theme.blue500}
            onClick={() => onCheckAllEmployees()}>
            Select All
          </B>
          <Div ml="auto">
            <B
              type="b-default"
              color={theme.gray600}
              onClick={() => setEmployees([])}>
              Clear
            </B>
          </Div>
        </Div>
      </DropdownMenu>
    </Checkbox.Group>
  );

  useEffect(() => {
    etfReportData.map((payroll: EtfReportDataI) => {
      payroll.users.map((user: UserPayrollI) => {
        if (allEmployeesMenu.props.value.includes(user.id)) {
          usersWithData.push(user.id);
        }
      });
    });

    selectedUserIds = [...new Set(usersWithData)];
  }, [allEmployeesMenu]);

  return (
    <>
      <ReportLayout>
        <Div p="16px 24px">
          <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
            <B type="b-large-semibold">Choose your reports to download</B>
          </Col>
          <Col xs={24} sm={24} md={12} lg={0} xl={0} xxl={0}>
              <DropdownSection>
                  <ReportMenuSelector defaultIndex="3" />
              </DropdownSection>
            </Col>
          <Div
            m="32px 0px 0px"
            p="20px"
            display="flex"
            justifyContent="space-between"
            border={`1px solid ${theme.gray300}`}
            borderRadius="4px">
            <B type="b-default" cssStyle="margin: auto 0">
              Employee Trust Fund (Half Yearly Report)
            </B>
            <PrimaryButton
              size="small"
              onClick={() => setVisibleEpfModal(true)}>
              Export
            </PrimaryButton>
          </Div>
          <Div
            mt="16px"
            p="20px"
            display="flex"
            justifyContent="space-between"
            border={`1px solid ${theme.gray300}`}
            borderRadius="4px">
            <B type="b-default" cssStyle="margin: auto 0">
              Annual Statement of Employer
            </B>
            <PrimaryButton
              size="small"
              onClick={() => setVisibleAnnualStatementModal(true)}>
              Export
            </PrimaryButton>
          </Div>

          <Div
            mt="16px"
            p="20px"
            display="flex"
            justifyContent="space-between"
            border={`1px solid ${theme.gray300}`}
            borderRadius="4px">
            <B type="b-default" cssStyle="margin: auto 0">
              Certificate of Income Tax Deductions (T10)
            </B>
            <PrimaryButton
              size="small"
              onClick={() => setVisibleT10Modal(true)}>
              Export
            </PrimaryButton>
          </Div>
        </Div>
      </ReportLayout>
      {visibleEpfModal && (
        <ExportModal
          visible={visibleEpfModal}
          onCancel={() => setVisibleEpfModal(false)}
          onSubmit={() => generateETFExports(selectedUserIds, etfReportData)}
          allEmployeesMenu={allEmployeesMenu}
          employees={employees}
          exportType="EPF"
          title={`Employees' Trust Fund Board - ETF`}
          description='Also known as "Form II" or "Half Yearly Report" is a summary of the payrun and total amount remitted by employer for ETF'
        />
      )}
      {visibleAnnualStatementModal && (
        <ExportModal
          visible={visibleAnnualStatementModal}
          onCancel={() => setVisibleAnnualStatementModal(false)}
          allEmployeesMenu={allEmployeesMenu}
          employees={employees}
          exportType="AnnualStatement"
          title="Annual Statement of Employer"
          description="Summary of APIT tax deductions withheld by the employer. Typically due on April 30th of the following financial year"
        />
      )}
      {visibleT10Modal && (
        <ExportModal
          visible={visibleT10Modal}
          onCancel={() => setVisibleT10Modal(false)}
          allEmployeesMenu={allEmployeesMenu}
          employees={employees}
          exportType="T10"
          title="Certificate of Income Tax Deductions (T10)"
          description="Summary of APIT amounts withheld by the company for each individual employee typically submitted by employee before November 30th"
        />
      )}
    </>
  );
};

export default FilesAndForms;
