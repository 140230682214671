import React, { FC } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import theme from '../../theme';
import { space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';
type Props = SpaceProps &
  Omit<ButtonProps, 'shape' | 'ghost' | 'danger' | 'type'> & {
    size?: 'small' | 'large';
  };

const StyledButton = styled(Button)<Props>`
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter;
  line-height: 20px;
  border-radius: 4px;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.1);
  background-color: ${theme.white};
  color: ${theme.blue400};
  border: 1px solid ${theme.blue400};

  &.btn-disabled {
    border: 1px solid ${theme.gray400};
    color: ${theme.gray400};
  }

  &.btn-disabled .ant-btn-loading-icon {
    color: ${theme.gray400};
    padding-left: 0px;
  }

  &.btn-disabled:hover {
    background-color: ${theme.white};
    color: ${theme.gray400};
    border-color: ${theme.gray400};
  }

  &.btn-disabled:focus {
    background-color: ${theme.white};
    color: ${theme.gray400};
    border-color: ${theme.gray400};
  }

  &.btn-small {
    height: 32px;
  }

  .ant-btn-loading-icon {
    color: ${theme.blue500};
    padding-left: 0px;
  }

  &:focus {
    background-color: ${theme.lightBlue50};
    color: ${theme.blue400};
    border-color: ${theme.blue600};
  }

  &:hover {
    background-color: ${theme.lightBlue50};
    color: ${theme.blue400};
    border-color: ${theme.blue600};
  }

  .btn-custom-icon-left {
    margin-right: ${(props) => (props.icon ? '6px' : 0)};
    max-height: 20px;
    color: ${theme.blue500};
  }
  ${space}
`;
export const SecondaryButton: FC<Props> = ({ size = 'large', ...props }) => {
  let icon: React.ReactNode | null = null;
  let className = `btn-${size} ${props.className || ''}`;
  if (props.icon || props.loading) {
    icon = <span className="btn-custom-icon-left">{props.icon}</span>;
  }
  if (props.disabled) {
    className = 'btn-disabled ' + className;
  }

  return (
    <StyledButton {...props} size={undefined} icon={icon} className={className}>
      {props.children}
    </StyledButton>
  );
};
