import React, { FC, useEffect } from 'react';
import { Col, Row } from 'antd';
import { CardBody } from '../../../../components';
import theme from '../../../../theme';
import { PlusOutlined } from '@ant-design/icons';
import { PrimaryButton } from '../../../../components';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType, RootState } from '../../../../store/reducer';
import { getOrganizationsSummary } from '../../../../store/actions';
import { numberThousandSeparator } from '../../../../util/utils';
import styled from '@emotion/styled';

const MainCard = styled.div`
  background: ${theme.white};
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;
const CreateOrganization = styled(Col)`
  display: flex;
  justify-content: flex-end;
  padding: 21px 32px 20px 20px;
`;
const Column = styled(Col)`
  padding: 16px 32px;
`;
const OrganizationsSummary: FC = () => {
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();

  useEffect(() => {
    dispatch(getOrganizationsSummary());
  }, []);

  const organizationsSummary = useSelector(
    (state: RootState) => state.superAdmin.organizationsSummary,
  );

  return (
    <>
      <MainCard>
        <Row>
          <Column xs={24} sm={5} md={5} lg={5} xl={5} xxl={8}>
            <CardBody
              title="Organizations"
              body={
                organizationsSummary?.allOrganizationCount
                  ? numberThousandSeparator(
                      organizationsSummary?.allOrganizationCount,
                    )
                  : 0
              }
            />
          </Column>
          <Column xs={24} sm={5} md={5} lg={5} xl={5} xxl={8}>
            <CardBody
              title="Users"
              body={
                organizationsSummary?.allUsersCount
                  ? numberThousandSeparator(organizationsSummary?.allUsersCount)
                  : 0
              }
            />
          </Column>
          <Column xs={24} sm={5} md={5} lg={5} xl={5} xxl={8}>
            <CardBody
              title="Payrolls"
              body={
                organizationsSummary?.allPayrollsCount
                  ? numberThousandSeparator(
                      organizationsSummary.allPayrollsCount,
                    )
                  : 0
              }
            />
          </Column>
          <CreateOrganization xs={24} sm={9} md={9} lg={9} xl={9} xxl={8}>
            <PrimaryButton
              icon={<PlusOutlined />}
              size="small"
              onClick={() => navigate('/super-admin/create-new-organization')}>
              Create Organization
            </PrimaryButton>
          </CreateOrganization>
        </Row>
      </MainCard>
    </>
  );
};

export default OrganizationsSummary;
