import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import AlertCloseSvg from '../../assets/Icons/alert-icons/alert-close.svg';
import AlertErrorSvg from '../../assets/Icons/alert-icons/alert-icon-error.svg';
import AlertSuccessSvg from '../../assets/Icons/alert-icons/alert-icon-success.svg';
import AlertWarnSvg from '../../assets/Icons/alert-icons/alert-icon-warn.svg';
import AlertInformationalSvg from '../../assets/Icons/alert-icons/alert-informational.svg';
import theme from '../../theme';
import { Div } from '../Div';
import { B } from '../B';

type AlertTypes = 'warn' | 'error' | 'success' | 'info' | 'notification';

export const svgIcons = {
  success: AlertSuccessSvg,
  warn: AlertWarnSvg,
  error: AlertErrorSvg,
  info: AlertInformationalSvg,
  notification: '',
};

export const colorTheme = {
  success: {
    background: `${theme.green50}`,
  },
  warn: {
    background: `${theme.warn}`,
  },
  error: {
    background: `${theme.red50}`,
  },
  info: {
    background: `${theme.lightBlue50}`,
  },
  notification: {
    background: `${theme.gray50}`,
  },
};

interface MultipleAlertMessageProps {
  messages?: string[];
  type: AlertTypes;
  onClose?: () => void;
  actionLabel?: ReactNode;
  hideIcon?: boolean;
  onAction?: () => void;
  width?: number;
}

const ContainerStyle = styled.div<{ type: AlertTypes; width?: number }>`
  background-color: ${(props) => colorTheme[props.type].background};
  box-sizing: border-box;
  border-radius: 4px;
  padding: ${(props) =>
    props.type == 'notification' ? '4px 12px 6px 5px' : '10px 32px'};
  padding-right: 14px;
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
`;

const AlertStyle = styled.div`
  display: flex;
  align-items: flex-start;
`;

const MessagesList = styled.ul<{ messages?: string[] }>`
  list-style: ${({ messages }) =>
    messages && messages?.length > 1 ? 'disc' : 'none'};
  margin: 0;
  li {
    margin-bottom: 8px;
    padding-right: 10px;
  }
  li:last-child {
    margin-bottom: 0;
  }
`;

const RightSection = styled.div`
  display: -webkit-box;
  flex-direction: row;
  margin-left: auto;
`;

const CloseImg = styled.img`
  cursor: pointer;
  width: 12px;
`;

export const MultipleAlertMessage: FC<MultipleAlertMessageProps> = ({
  messages,
  type,
  onClose,
  actionLabel,
  hideIcon,
  onAction,
  width,
}) => {
  if (!messages) {
    return null;
  }
  return (
    <ContainerStyle type={type} width={width}>
      <AlertStyle>
        {!hideIcon && <img src={svgIcons[type]} />}
        <Div display="block" pl={messages.length > 1 ? '32px' : '8px'}>
          <MessagesList messages={messages}>
            {messages.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </MessagesList>
        </Div>
        <RightSection>
          {onAction && (
            <B
              type="b-default-semibold"
              onClick={onAction}
              color={theme.blue500}>
              {actionLabel}
            </B>
          )}
          {onClose && <CloseImg onClick={onClose} src={AlertCloseSvg} />}
        </RightSection>
      </AlertStyle>
    </ContainerStyle>
  );
};
