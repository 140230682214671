import styled from '@emotion/styled';
import { Col, Form, FormInstance, Row, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import theme from '../../theme';
import { Div, B, PrimaryButton, SecondaryButton } from '../../components';
import CustomDropZone from './CustomDropZone';
import { CheckBoxComp } from '../../components/v2/CheckBoxComp';
import {
  AddEmployeeDetailsI,
  EmployeeDocumentFilesI,
  EmployeeEmailOnboardingFormI,
  EmployeeEmailOnboardingSendEmailI,
  EmployeeFileUpload,
} from '../../types/people.types';
import { sendEmailCopyToUser, useSubmitForm } from '../../api/peopleHooks';
import { useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { usePublicUserFileUpload } from '../../api/documentHooks';
import { DocumentDetailsI } from '../../types/document.types';

const MainBody = styled.div`
  width: 942px;
  margin-top: 48px;
  margin-bottom: 24px;
  padding: 48px 80px;
  background-color: ${theme.gray50};
  border: 1px solid ${theme.gray350};
  border-radius: 8px;
  @media screen and (max-width: 768px) {
    width: fit-content;
    padding: 24px;
    margin-top: 24px;
    margin-bottom: 12px;
  }
`;
const FooterBtn = styled(Div)`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

interface Props {
  onChange: () => void;
  goBack: () => void;
  form: FormInstance;
  employeeDetails: AddEmployeeDetailsI;
}
type FormParams = {
  token: string;
};
interface TokenI {
  email: string;
  organizationId: number;
  expirationDate: string;
  id: number;
}

const FileUploadsPage: FC<Props> = ({
  onChange,
  form,
  goBack,
  employeeDetails,
}) => {
  const {
    mutate: submitEmployeeDetails,
    isSuccess: employeeDetailSuccess,
    isLoading: employeeDetailLoading,
    data: submitFormData,
  } = useSubmitForm();
  const { mutate: uploadUserDoc } = usePublicUserFileUpload();
  const {
    mutate: sendEmailCopy,
    data: emailSentData,
    isSuccess: emailSent,
    isLoading: emailSending,
  } = sendEmailCopyToUser();

  const params = useParams<FormParams>();
  const [sendACopy, setSendACopy] = useState<boolean>(false);

  const tokenDecoded: TokenI = jwt_decode(params?.token);

  const handleFinish = async (values: EmployeeFileUpload) => {
    const formData: EmployeeEmailOnboardingFormI = {
      token: params.token,
      ...employeeDetails,
      isSecondary: employeeDetails.isSecondary
        ? employeeDetails.isSecondary
        : false,
      sendACopy: values.sendACopy ? values.sendACopy : false,
      bankBranchId: employeeDetails.bankBranchId.toString(),
    };

    let certCount = 2; // there are two mandatory certificates
    await submitEmployeeDetails(formData);
    const files: EmployeeDocumentFilesI = Object.entries(values).reduce(
      (acc, [key, value]) => {
        if (value instanceof File) {
          acc[key] = value;
          if (key === 'firstOptionalCert' || key === 'secondOptionalCert') {
            certCount++;
          }
        }
        return acc;
      },
      {} as EmployeeDocumentFilesI,
    );

    const uploadPromises = [];
    for (const [key, value] of Object.entries(files)) {
      if (key && value) {
        const data: DocumentDetailsI = {
          file: value,
          title: value?.name,
          type: 'FILE',
          userId: tokenDecoded.id.toString(),
          token: params.token,
        };
        if (data && params.token) {
          uploadPromises.push(uploadUserDoc(data));
        }
      }
    }

    await Promise.all(uploadPromises);

    const fileArray: File[] = [];
    for (const [key, value] of Object.entries(files)) {
      fileArray.push(value);
    }

    const data: EmployeeEmailOnboardingSendEmailI = {
      ...formData,
      certCount: certCount,
      files: fileArray,
    };
    sendEmailCopy(data);
  };

  useEffect(() => {
    if (employeeDetailSuccess && emailSent) {
      onChange();
    }
  }, [submitFormData, emailSentData]);

  return (
    <Div display="flex" justifyContent="center">
      <Form form={form} onFinish={handleFinish} validateTrigger="onBlur">
        <MainBody>
          <B type="b-large-semibold" mb="24px">
            Upload Mandatory Information
          </B>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="nicFrontImg"
                rules={[
                  {
                    required: true,
                    message: 'Please upload the NIC front image.',
                  },
                ]}>
                <CustomDropZone
                  form={form}
                  formItemName="nicFrontImg"
                  label="NIC Front*"
                  message="Files should be jpg, png or pdf"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="nicBackImg"
                rules={[
                  {
                    required: true,
                    message: 'Please upload the NIC back image.',
                  },
                ]}>
                <CustomDropZone
                  form={form}
                  formItemName="nicBackImg"
                  label="NIC Back*"
                  message="Files should be jpg, png or pdf"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="curriculamVitae"
            rules={[
              {
                required: true,
                message: 'Please upload your Curriculam Vitae.',
              },
            ]}>
            <CustomDropZone
              formItemName="curriculamVitae"
              form={form}
              label="Curriculam Vitae*"
              message="Files should be jpg, png or pdf"
            />
          </Form.Item>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="firstCertificate"
                rules={[
                  {
                    required: true,
                    message: 'Please upload your second certificate.',
                  },
                ]}>
                <CustomDropZone
                  formItemName="firstCertificate"
                  form={form}
                  label="Certificate 1*"
                  message="Files should be jpg, png or pdf"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="secondCertificate"
                rules={[
                  {
                    required: true,
                    message: 'Please upload your second certificate.',
                  },
                ]}>
                <CustomDropZone
                  formItemName="secondCertificate"
                  form={form}
                  label="Certificate 2*"
                  message="Files should be jpg, png or pdf"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item name="firstOptionalCert">
                <CustomDropZone
                  formItemName="firstOptionalCert"
                  form={form}
                  label="Certificate"
                  message="Files should be jpg, png or pdf"
                  putOptionalInLabel={true}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item name="secondOptionalCert">
                <CustomDropZone
                  formItemName="secondOptionalCert"
                  form={form}
                  label="Certificate"
                  message="Files should be jpg, png or pdf"
                  putOptionalInLabel={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="sendACopy"
            valuePropName="checked"
            style={{
              borderTop: `1px solid ${theme.gray300}`,
              marginTop: 10,
            }}>
            <Div display="flex" marginTop={32}>
              <CheckBoxComp
                key={123}
                checked={sendACopy ? sendACopy : false}
                onChange={(e) => setSendACopy(Boolean(e.target.checked))}
              />
              <B type="b-large" ml={12}>
                Send me a copy of my responses
              </B>
            </Div>
          </Form.Item>
        </MainBody>
        <FooterBtn>
          <SecondaryButton onClick={goBack}>Back</SecondaryButton>
          <PrimaryButton
            htmlType="submit"
            isLoading={employeeDetailLoading || emailSending}>
            Complete
          </PrimaryButton>
        </FooterBtn>
      </Form>
    </Div>
  );
};

export default FileUploadsPage;
