import React, { FC, useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

import styled from '@emotion/styled';

import { B, Div, Divider, SwitchComponent } from '../../../components';
import theme from '../../../theme';
import { LeavePolicyGroupI, LeaveSettingsI } from '../../../types';
import CalculationSettings from './CalculationSettings';
import {
  PayrollItemTypeEnum,
  noPayStatutoryItemSelection,
} from '../../../types/payroll.types';
import {
  ProRataPayItemTypesI,
  WorkTimeUnitType,
} from '../../../types/organization.types';
import StatutoryCalculationSettings from './StatutoryCalculationSettings ';

const FormItem = styled(Form.Item)<SpaceProps & LayoutProps>`
  margin-bottom: 0px;
  ${space}
  ${layout}
`;

const MainDiv = styled.div`
  padding: 32px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  @media (max-width: 768px) {
    padding: 24px 10px;
    width: 100%;
  }
`;

interface Props {
  leavePolicyGroups: LeavePolicyGroupI[];
  leaveSettings: LeaveSettingsI | null;
  form: FormInstance<any>;
  onChange: (value: boolean) => void;
  payItemTypes?: ProRataPayItemTypesI | null;
  workTimeUnitType?: WorkTimeUnitType | null | string;
}

const NoPay: FC<Props> = ({ leaveSettings, form, onChange, payItemTypes }) => {
  const [isAddNoPayRate, setIsAddNoPayRate] = useState<boolean>(false);

  const [isCheckPayItemValidation, setCheckPayItemValidation] =
    useState<boolean>();

  const [checkPayItem, setCheckPayItem] = useState<{
    BASIC: boolean | undefined;
    FIXED_ALLOWANCE: boolean | undefined;
    VARIABLE_ALLOWANCE: boolean | undefined;
  }>({
    BASIC: false,
    FIXED_ALLOWANCE: false,
    VARIABLE_ALLOWANCE: false,
  });

  useEffect(() => {
    setCheckPayItemValidation(Object.values(checkPayItem).some((item) => item));
  }, [checkPayItem]);

  useEffect(() => {
    if (payItemTypes) {
      setCheckPayItem({
        BASIC: payItemTypes?.BASIC,
        FIXED_ALLOWANCE: payItemTypes?.FIXED_ALLOWANCE,
        VARIABLE_ALLOWANCE: payItemTypes?.VARIABLE_ALLOWANCE,
      });
    }
  }, [payItemTypes]);

  useEffect(() => {
    if (leaveSettings) {
      setIsAddNoPayRate(leaveSettings?.noPay?.status);
      form.setFieldsValue({
        rate: leaveSettings?.noPay?.rate ? leaveSettings.noPay.rate : 0,
        noPay: !!leaveSettings?.noPay?.status,
        NOPAY_APIT: leaveSettings?.noPay?.isApit,
        NOPAY_EPF: leaveSettings?.noPay?.isEpf,
      });
    }
  }, [leaveSettings]);

  return (
    <>
      <MainDiv>
        <B type="b-large-semibold" Pb="16px">
          No Pay
        </B>
        <Row>
          <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
            <Div display="flex">
              <FormItem name="noPay" valuePropName="checked">
                <SwitchComponent
                  onChange={(checked: boolean) => {
                    setIsAddNoPayRate(checked);
                    onChange(checked);
                  }}
                />
              </FormItem>
              <B type="b-default" color={theme.gray600} pl="10px" pt="5px">
                No Pay
              </B>
            </Div>
          </Col>
          <Col xs={24} sm={24} md={19} lg={19} xl={19} xxl={19}>
            {isAddNoPayRate && (
              <B type="b-small" color={theme.gray600}>
                By switching on no pay, the no pay amount will be calculated by
                multiplying the no pay rate; obtained by dividing the Basic
                salary by the no of working days, from the the no.of no pay days
                logged by system Owners, Admins, Guest admins or Managers for
                each employee. Kindly note that you cannot calculate no pay on a
                mid cycle payrun. Please contact us if you follow a different no
                pay policy.
              </B>
            )}
          </Col>
        </Row>
      </MainDiv>
      {isAddNoPayRate && (
        <>
          <CalculationSettings
            title="Please select which items you want to include in no-pay calculations"
            basicSalary={PayrollItemTypeEnum.BASIC}
            fixedAllowance={PayrollItemTypeEnum.FIXED_ALLOWANCE}
            variableAllowance={PayrollItemTypeEnum.VARIABLE_ALLOWANCE}
            isSelectPayItems={isCheckPayItemValidation}
            payItemName="noPayItem"
            onChange={(v) => {
              switch (v.target.id) {
                case PayrollItemTypeEnum.BASIC:
                  setCheckPayItem({ ...checkPayItem, BASIC: v.target.checked });
                  break;
                case PayrollItemTypeEnum.FIXED_ALLOWANCE:
                  setCheckPayItem({
                    ...checkPayItem,
                    FIXED_ALLOWANCE: v.target.checked,
                  });
                  break;
                case PayrollItemTypeEnum.VARIABLE_ALLOWANCE:
                  setCheckPayItem({
                    ...checkPayItem,
                    VARIABLE_ALLOWANCE: v.target.checked,
                  });
                  break;
                default:
                  break;
              }
            }}
          />

          <StatutoryCalculationSettings
            payItemName="noPayStatutoryItem"
            apit={noPayStatutoryItemSelection.APIT}
            epf={noPayStatutoryItemSelection.EPF}
          />
        </>
      )}
      <Divider />
    </>
  );
};

export default NoPay;
