import React, { FC } from 'react';
import { ContractStatusEnum } from '../../../../types/contract.types';
import { Input } from 'antd';
import { DraggableContainer } from './containers/DraggableContainer';
import { PlaceholderBoxPropsI } from './PlaceholderBoxProps';

const TextPlaceHolderBox: FC<PlaceholderBoxPropsI> = ({
  onAction,
  isSelected,
  isReceiver,
  isEditable,
  onChange,
  value,
  contractStatus,
}) => {
  return (
    <>
      {contractStatus === ContractStatusEnum.COMPLETED ? null : (
        <DraggableContainer
          isReceiver={isReceiver}
          selected={isSelected}
          showPoints={isEditable}
          onAction={onAction}>
          <div className={!isEditable ? 'input-container' : 'container'}>
            {!isEditable ? (
              <Input
                onChange={(e) => onChange && onChange(e.target.value)}
                defaultValue={value}
                placeholder="Text"
              />
            ) : (
              <label className="text-input">Text</label>
            )}
          </div>
        </DraggableContainer>
      )}
    </>
  );
};
export default TextPlaceHolderBox;
