import React, { FC, useState } from 'react';
import { Col, Row } from 'antd';
import RadioButton from 'antd/lib/radio/radioButton';
import styled from '@emotion/styled';
import { RadioGroup } from '../../../../components';
import UserPeopleSelect from '../../../people/shared/UserPeopleSelect';
import { AccountDetailLayout } from '../../components/AccountDetailLayout';
import AccountPayslips from './AccountPayslips';
import SalaryInfo from './SalaryInfo';

const MainContainer = styled.div`
  padding: 32px 24px 0px;
  @media (max-width: 768px) {
    padding: 0px 0px 24px;
  }
`;

const AccountPayDetails: FC = () => {
  const [isSalaryDetailsTab, setIsSalaryDetailsTab] = useState(true);
  return (
    <>
      <AccountDetailLayout>
        <MainContainer>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={0} xxl={0}>
              <UserPeopleSelect defaultIndex="3" />
            </Col>
          </Row>
          <RadioGroup defaultValue="payDetails">
            <RadioButton
              value="payDetails"
              onClick={() => setIsSalaryDetailsTab(true)}>
              Pay Details
            </RadioButton>
            <RadioButton
              value="payslips"
              onClick={() => setIsSalaryDetailsTab(false)}>
              Payslips
            </RadioButton>
          </RadioGroup>
        </MainContainer>
        {isSalaryDetailsTab ? <SalaryInfo /> : <AccountPayslips />}
      </AccountDetailLayout>
    </>
  );
};

export default AccountPayDetails;
