import React, { FC } from 'react';
import LoanTable from './LoanTable';

const LoanHome: FC = () => {
  return (
    <div>
      <LoanTable />
    </div>
  );
};

export default LoanHome;
