import React, { FC, useState } from 'react';
import { Avatar, Col, Form, message, Row, Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import styled from '@emotion/styled';
import { B, Div, GhostButton } from '../../../../components';
import { BASE_URL } from '../../../../configs';
import { useIndividualPeopleState } from '../../../../providers/IndividualPeopleState';
import theme from '../../../../theme';
import { getCurrentAuthToken } from '../../../../util/storageUtil';
import { addDotsForLongText, normFile } from '../../../../util/utils';
import { InfoSVG, UploadSVG } from '../../../../assets';

const MainContainer = styled.div`
  padding: 0px 32px;
  .ant-upload-list-item {
    margin-top: 0px;
    margin-bottom: 8px;
  }
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const UploadButtonIcon = styled.img`
  margin-bottom: -2px;
`;

const CharacterAvatar = styled(Avatar)`
  color: ${theme.gray600};
  background-color: ${theme.gray300};
  .ant-avatar-string {
    font-size: 34px;
  }
`;
const ImageUpload = styled(Upload)`
  .ant-upload-list-item-name {
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const props: UploadProps = {
  showUploadList: {
    showRemoveIcon: false,
  },
};

interface ImageProp {
  onChangeImageUpload: (url: string) => void;
  isFieldDataChanged: (isDataChanged: boolean) => void;
  defaultVisible?: boolean;
}
const ProfilePicture: FC<ImageProp> = ({
  onChangeImageUpload,
  isFieldDataChanged,
  defaultVisible = true,
}) => {
  const { state } = useIndividualPeopleState();
  const { people: people } = state;
  const [fileData, setFileData] = useState<any>();

  return (
    <MainContainer>
      <B type="b-default-semibold" pb="12px" color={theme.blue700}>
        Profile Picture
      </B>
      <Row>
        <Col flex="80px">
          {people?.profilePicture != null ? (
            <Avatar src={people?.profilePicture} size={64} shape="square" />
          ) : (
            <CharacterAvatar size={64} shape="square">
              {people?.fullName?.charAt(0)}
            </CharacterAvatar>
          )}
        </Col>
        {defaultVisible && (
          <Col flex="auto">
            <Row>
              <Form.Item
                getValueFromEvent={(e) => normFile(e, fileData)}
                noStyle>
                <ImageUpload
                  name="file"
                  accept=".jpg, .png"
                  multiple={false}
                  fileList={fileData}
                  action={`${BASE_URL}/organization/uploadLogo`}
                  headers={{
                    Authorization: `Bearer ${getCurrentAuthToken()}`,
                  }}
                  onChange={(info) => {
                    isFieldDataChanged(true);
                    if (!!info.fileList.length) {
                      if (info.file.status === 'error') {
                        message.error('There was an error uploading your file');
                      } else if (info.file.status === 'done') {
                        message.success(
                          `${addDotsForLongText(
                            info.file.name,
                            20,
                          )} file uploaded successfully`,
                        );
                        onChangeImageUpload(info.file.response);
                      }
                      setFileData(info.fileList.slice(-1));
                    } else {
                      if (info.file.status === 'error') {
                        message.error('There was an error uploading your file');
                      } else if (info.file.status === 'done') {
                        message.success(
                          `${info.file.name} file uploaded successfully`,
                        );
                        onChangeImageUpload(info.file.response);
                      }
                      setFileData(info.fileList);
                    }
                  }}
                  {...props}>
                  <GhostButton
                    icon={
                      <UploadButtonIcon
                        src={UploadSVG}
                        width={14}
                        height={17}
                      />
                    }>
                    Upload Profile Photo
                  </GhostButton>
                </ImageUpload>
              </Form.Item>
            </Row>
            <Row>
              <Div display="flex" pt="4px" pl="12px">
                <img src={InfoSVG} width={20} height={20} />
                <B type="b-small" pl="6px">
                  You can upload a jpg, GIF or PNG file (File size limit is 5
                  MB)
                </B>
              </Div>
            </Row>
          </Col>
        )}
      </Row>
    </MainContainer>
  );
};
export default ProfilePicture;
