import { Form } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { pdfjs } from 'react-pdf';
import PagesDisplayCard from '../contract-edit/PagesDisplayCard';
import {
  EmptyPage,
  FooterComponent,
  SecondaryButton,
} from '../../../components';
import { useNavigate, useParams } from 'react-router';
import { AssignedPlaceholderDataI } from '../../../types/contract.types';

import ContractViewer from '../shared/ContractViewer';
import {
  ContentEditorContainer,
  ContractContainer,
  ContractLayout,
  DraggableLayout,
} from '../shared/ContractLayout';
import { ZoomProvider } from '../shared/ZoomContext';
import { getPathWithOrgId } from '../../../util/getPathWithOrgId';
import { ContractViewTopBar } from './ ContractViewTopBar';
import { useGetTemplateById } from '../../../api/contractHooks';
import { downloadHandlerTemplate } from '../../../util/downloadPdf.util';

type ParamsI = {
  templatesId: string;
};
export interface PlaceholderBoxProps {
  left: number;
  top: number;
  width: number;
  height: number;
}

const EmptyContract: FC = () => (
  <EmptyPage
    content="Sorry, the contract cannot be found."
    title="something went wrong !"
    buttonLabel=" Back Home"
  />
);

const ViewTemplates: FC = () => {
  const params = useParams<ParamsI>();
  const navigate = useNavigate();

  const [selectedPlaceholder, setSelectedPlaceholder] =
    useState<AssignedPlaceholderDataI | null>(null);

  const {
    data: templateByIdData,
    mutate,
    isLoading: templateLoading,
  } = useGetTemplateById();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; //or pdfjsWorker
  }, []);

  useEffect(() => {
    mutate(params.templatesId);
  }, [params.templatesId]);

  const downloadTemplate = () => {
    if (templateByIdData) {
      downloadHandlerTemplate(
        String(templateByIdData.id),
        templateByIdData.title,
      );
    }
  };

  // if contact not found in private state
  if (!templateByIdData && !templateLoading) {
    return <EmptyContract />;
  }

  return (
    <ZoomProvider>
      <Form>
        {/* @ts-ignore */}
        <DndProvider backend={HTML5Backend}>
          <ContractViewTopBar
            contract={templateByIdData}
            downloadPDF={() => downloadTemplate()}
          />
          <ContractLayout fullScreen={false}>
            <DraggableLayout>
              <ContractContainer>
                <ContractViewer
                  isEditable={false}
                  contract={null}
                  selectedPlaceholder={selectedPlaceholder}
                  placeholders={[]}
                  documentLink={
                    templateByIdData?.documentLink
                      ? templateByIdData?.documentLink
                      : ''
                  }
                  setSelectedPlaceholder={(data) =>
                    setSelectedPlaceholder(data)
                  }
                />
              </ContractContainer>
              <ContentEditorContainer>
                <PagesDisplayCard
                  placeholderDetails={[]}
                  url={
                    templateByIdData?.documentLink
                      ? templateByIdData?.documentLink
                      : ''
                  }
                  assignedUsers={[]}
                />
              </ContentEditorContainer>
            </DraggableLayout>
          </ContractLayout>
        </DndProvider>

        <FooterComponent
          leftChildren={
            <SecondaryButton
              onClick={() => navigate(getPathWithOrgId('/contract'))}>
              Back
            </SecondaryButton>
          }
          fullScreen={false}
        />
      </Form>
    </ZoomProvider>
  );
};
export default ViewTemplates;
