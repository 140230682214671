import React, { FC, useEffect, useState } from 'react';
import { B, GhostButton, ModalComponent, PrimaryButton } from './';

interface PropsI {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
}
export const RouteLeavingGuard: FC<PropsI> = ({
  when,
  navigate,
  shouldBlockNavigation,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      {/* <Prompt when={when} message={handleBlockedNavigation} /> */}
      <ModalComponent
        title={<B type="b-large-semibold">Are you sure you want to leave</B>}
        visible={modalVisible}
        onOk={handleConfirmNavigationClick}
        onCancel={closeModal}
        footer={[
          <GhostButton key="submit" onClick={handleConfirmNavigationClick}>
            Leave this page
          </GhostButton>,
          <PrimaryButton key="back" onClick={closeModal}>
            Stay on this Page
          </PrimaryButton>,
        ]}
        width="597px">
        <B type="b-large">
          There are some unsaved changes. Do you still want to continue?
        </B>
      </ModalComponent>
    </>
  );
};
