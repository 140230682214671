export enum UserRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  USER = 'USER',
  ACCOUNTANT = 'ACCOUNTANT',
  MANAGER = ' MANAGER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  HR_ADMIN = 'HR_ADMIN',
}

export enum UserRoleIdEnum {
  ADMIN = 1,
  OWNER = 2,
  USER = 3,
  GUEST_ADMIN = 4,
  SUPER_ADMIN = 5,
  HR_ADMIN = 6,
}

export type UserRoleResponseType = {
  id: UserRoleIdEnum;
  role: string;
  roleLevel: number;
};
