import React, { FC } from 'react';
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import theme from '../../theme';
import styled from '@emotion/styled';

interface Props extends InputNumberProps {
  isCurrency?: boolean;
  flexDirection?: string;
  addonBefore?: string | null;
  addonAfter?: string;
}
const BeforeSpan = styled.span<{
  addonBefore?: string | null;
  size: 'small' | 'middle' | 'large';
}>`
  display: flex;
  align-items: center;
  height: ${(props) => (props.size === 'small' ? '32px !important' : '40px')};
  background: ${theme.gray100};
  border: 1px solid ${theme.gray300};
  ${(props) => props.addonBefore && 'border-right: 0 !important;'}
  box-sizing: border-box;
  border-radius: 4px 0px 0px 4px;
  padding: 0px 8px;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.gray600};
`;

const AfterSpan = styled.span<{
  addonAfter?: string | null;
  size: 'small' | 'middle' | 'large';
}>`
  display: flex;
  align-items: center;
  height: ${(props) => (props.size === 'small' ? '32px !important' : '40px')};
  background: ${theme.gray100};
  border: 1px solid ${theme.gray300};
  ${(props) => props.addonAfter && 'border-left: 0 !important;'}
  box-sizing: border-box;
  border-radius: 0px 4px 4px 0px;
  padding: 0px 8px;
  font-size: 14px;
  line-height: 20px;
`;

const MainDiv = styled.div<{ flexDirection?: string }>`
  display: flex;
  flex-direction: row;
  width: ${(props) => props.flexDirection === 'row' && '100%'};
`;

const NumberInput = styled(InputNumber)<Props>`
  @media screen and (max-width: 768px) {
    ${(props) =>
      props.flexDirection === 'row' &&
      `width: ${props.addonBefore ? '125px' : '100%'};`}
  }
  ${(props) =>
    props.flexDirection === 'row' &&
    `width: ${props.addonBefore ? 125 : 344}px;`}
  height: ${(props) => (props.size === 'small' ? '32px !important' : '40px')};
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: ${(props) =>
    props.addonBefore
      ? '0px 4px 4px 0px'
      : props.addonAfter
      ? '4px 0px 0px 4px'
      : '4px'};
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  input {
    padding: 0px 16px !important;
    height: ${(props) => (props.size == 'small' ? '30px !important' : '38px')};
  }
  &:focus {
    border: 1px solid ${theme.blue500};
    box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1);
  }

  &:hover {
    border: 1px solid ${theme.blue500};
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
    border-radius: 4px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;
export const InputNumberV2: FC<Props> = (props) => {
  const {
    size = 'large',
    isCurrency,
    flexDirection = 'column',
    addonBefore = null,
    addonAfter = null,
    ...rest
  } = props;

  if (isCurrency) {
    props = {
      ...props,
      formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      parser: (value) => (value ? value.replace(/\$\s?|(,*)/g, '') : ''),
    };
  }
  return (
    <MainDiv>
      {addonBefore && (
        <BeforeSpan addonBefore={addonBefore} size={size}>
          {addonBefore}
        </BeforeSpan>
      )}
      <NumberInput {...rest} size={size} flexDirection={flexDirection} />
      {addonAfter && (
        <AfterSpan addonAfter={addonAfter} size={size}>
          {addonAfter}
        </AfterSpan>
      )}
    </MainDiv>
  );
};
