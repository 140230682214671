import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { B } from '../../../../../components';
import { Div } from '../../../../../components/Div';
import theme from '../../../../../theme';
interface PersonalDetailsI {
  nameWithInitials: string | null;
  fullName: string | null;
  preferredName: string | null;
  nic: string | null;
}
interface Props {
  PersonalDetails?: PersonalDetailsI;
}
const PersonalDetailsConfirmation: FC<Props> = ({ PersonalDetails }) => {
  return (
    <Div borderBottom="1px solid #E0E0E0" Pb="16px">
      <B type="b-default-semibold" pt="32px" pb="px">
        Personal Details
      </B>
      {PersonalDetails ? (
        <>
          <Row gutter={[32, 12]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <B type="b-small" color={theme.gray600}>
                Name with Initials
              </B>{' '}
              <B type="b-default" color={theme.black}>
                {PersonalDetails.nameWithInitials}
              </B>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <B type="b-small" color={theme.gray600}>
                Full Name
              </B>{' '}
              <B type="b-default" color={theme.black}>
                {PersonalDetails.fullName}
              </B>
            </Col>
          </Row>
          <Row gutter={[32, 12]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <B type="b-small" color={theme.gray600}>
                Preferred / Display Name
              </B>{' '}
              <B type="b-default" color={theme.black}>
                {PersonalDetails.preferredName}
              </B>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <B type="b-small" color={theme.gray600}>
                NIC or Passport
              </B>{' '}
              <B type="b-default" color={theme.black}>
                {PersonalDetails.nic}
              </B>
            </Col>
          </Row>
        </>
      ) : null}
    </Div>
  );
};

export default PersonalDetailsConfirmation;
