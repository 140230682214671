import React, { FC, useEffect, useState } from 'react';

import { Col, Form, Popover, Spin, Tooltip, Row, message } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router';
import {
  AlertMessage,
  B,
  ConfirmationModal,
  DeleteModal,
  Div,
  FooterComponent,
  InputComp,
  NameTag,
  PrimaryButton,
  SelectComp,
  SwitchComponent,
} from '../../../../components';
import ErrorPage from '../../../../components/errorPage/ErrorPage';
import { useIndividualPeopleState } from '../../../../providers/IndividualPeopleState';
import { getUserRoles } from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import theme from '../../../../theme';
import { Permission, UserRoleIdEnum } from '../../../../types';
import { UpdateUserSettingsI } from '../../../../types/people.types';
import { getAllowedUserRoles } from '../../../../util/getAllowedUserRoles';
import { isAllowed, isBelowLevelUser } from '../../../../util/permissionUtil';
import { PeopleLayout } from '../../shared';
import PeopleSelect from '../../shared/PeopleSelect';
import {
  useDeleteUser,
  useIsDeletableUser,
  useSendNotification,
  useUpdatePeopleSetting,
} from '../../../../api/peopleHooks';
import { DangerButton } from '../../../../components/buttons/DangerButton';
import { payParams } from './peopleSettingsTypes';
import { InfoSVG } from '../../../../assets';
import { NotificationTypeEnum } from '../../../../types/notification.types';
import dayjs from 'dayjs';

const TagStyle = styled(NameTag)`
  display: inline;
  border-radius: 4px;
  text-align: center;
  font-weight: 400;
  height: 20px;
  padding: 2px 8px;
  margin-left: 8px;
`;

const StyledDiv = styled(Div)`
  padding: 24px 0px 0px;
  display: flex;
  flex-direction: row;
`;

const CustomForm = styled(Form)`
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.black};
    padding-top: 5px;
    width: 165px;
  }
  .ant-form-item-label > label::after {
    content: none;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-bottom: 10px;
  }
`;
const Image = styled.img`
  margin-left: 10px;
  @media (max-width: 450px) {
    margin-bottom: -40px;
  }
`;
const FormItem = styled(Form.Item)`
  margin-left: auto;
  margin-bottom: 0px;
`;
const InputFormItem = styled(Form.Item)`
  width: 490px;
  margin-bottom: 0px;
`;
const CustomDiv = styled.div`
  padding: 32px;
  @media (max-width: 768px) {
    padding: 0px;
    margin-bottom: 24px;
    margin-bottom: 80px;
  }
`;

const PeopleSettingsPage: FC = () => {
  const params = useParams<payParams>();
  const dispatch: DispatchType = useDispatch();
  const [form] = Form.useForm();
  const userRoles = useSelector((state: RootState) => state.people.userRoles);
  const [isBelowUserLevelToEdit, setIsBelowUserLevelToEdit] = useState(true);
  const [isAllowedToEditEmail, setIsAllowedToEditEmail] = useState(false);
  const [allowedUserRoles, setAllowedUserRoles] = useState<
    { value: UserRoleIdEnum; label: string }[]
  >([]);
  const navigate = useNavigate();
  const {
    state: { people, error },
    fetchPeopleById,
  } = useIndividualPeopleState();
  const { mutate: sendNotification } = useSendNotification();

  useEffect(() => {
    dispatch(getUserRoles());
    if (params.employeeKey) {
      getIsUserDeletable(parseInt(params.employeeKey));
      fetchPeopleById(params.employeeKey);
    }
  }, [params.employeeKey]);

  const {
    isLoading: updatePeopleSettingLoading,
    mutate: updatePeopleSetting,
    data: updatePeopleSettingData,
    error: errorPeopleSetting,
  } = useUpdatePeopleSetting();

  const { mutate: deleteUserId, data: deleteUserData } = useDeleteUser();

  const { data: isDeletableUser, mutate: getIsUserDeletable } =
    useIsDeletableUser();

  useEffect(() => {
    if (updatePeopleSettingData && !errorPeopleSetting) {
      fetchPeopleById(params.employeeKey);
      getIsUserDeletable(parseInt(params.employeeKey));
    }
  }, [updatePeopleSettingData]);

  const updatePeople = (isAllowed: boolean) => {
    if (people) {
      updatePeopleSetting({
        isLoginAllowed: people.isLoginAllowed,
        isPayrollAllowed: people.isPayrollAllowed,
        isArchived: isAllowed,
        roleId: people?.role?.id,
        id: params.employeeKey,
        loginEmail: people?.loginEmail ? people?.loginEmail : undefined,
        isPending: people?.isPending,
      });
    }
  };

  useEffect(() => {
    if (deleteUserData) {
      navigate('/contact');
    }
  }, [deleteUserData]);

  useEffect(() => {
    const results = getAllowedUserRoles(userRoles);
    setAllowedUserRoles(results);
    if (people?.role?.roleLevel) {
      const isBelow =
        (isBelowLevelUser(people.role.roleLevel) &&
          people.role.id !== UserRoleIdEnum.GUEST_ADMIN) ||
        (isAllowed(Permission.CHANGE_USER_SETTINGS_OF_SAME_LEVEL) &&
          people.role.id !== UserRoleIdEnum.OWNER &&
          people.role.id !== UserRoleIdEnum.GUEST_ADMIN);
      setIsBelowUserLevelToEdit(isBelow);
      setIsAllowedToEditEmail(
        isAllowed(Permission.CHANGE_USERS_LOGIN_EMAIL) && isBelow,
      );
      form.resetFields();
      form.setFieldsValue({ ...people });
    }
  }, [userRoles, people]);

  if (error && !people) {
    return <ErrorPage />;
  }
  if (!people) {
    return null;
  }
  return (
    <PeopleLayout>
      <Spin spinning={updatePeopleSettingLoading}>
        <CustomForm
          initialValues={{
            ...people,
          }}
          onFinish={async (values) => {
            if (values) {
              updatePeopleSetting({
                ...(values as UpdateUserSettingsI),
                id: params.employeeKey,
                isPending: people?.isPending ? people.isPending : false,
              });

              if (people?.isPending) {
                const reminderDate = dayjs().add(1, 'day');
                sendNotification({
                  type: NotificationTypeEnum.ONBOARDING,
                  userId: Number(params.employeeKey),
                  note: `${people.preferredName}'s onboarding process is complete.`,
                  reminderDate: reminderDate.toString(),
                });
              }
              fetchPeopleById(params.employeeKey);
            }
          }}
          form={form}
          wrapperCol={{ span: 50 }}>
          <CustomDiv>
            <Row>
              <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
                <B type="b-large-semibold">Settings</B>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={0} xxl={0}>
                <PeopleSelect defaultIndex="settings" />
              </Col>
            </Row>
            <Div borderBottom={`0.5px solid ${theme.gray300}`} p="24px 0px">
              <Div display="flex" flexDirection="row">
                <B type="b-large-semibold">
                  Allow System Login
                  <B type="b-small" color={theme.gray600} pb={20}>
                    Users can apply leaves and manage hr by login through system
                  </B>
                </B>
                <FormItem name="isLoginAllowed">
                  <SwitchComponent
                    defaultChecked={people?.isLoginAllowed}
                    disabled={!isBelowUserLevelToEdit}
                  />
                </FormItem>
              </Div>
              <Row>
                <Col xs={24} sm={13} md={14} lg={13} xl={13}>
                  <Form.Item
                    name="roleId"
                    label="Access Role"
                    rules={[
                      { required: false, message: 'Please select role!' },
                    ]}>
                    {isBelowUserLevelToEdit ? (
                      <SelectComp
                        size="middle"
                        disabled={!isBelowUserLevelToEdit}
                        placeholder={people?.role?.role || 'System User'}
                        options={allowedUserRoles}
                      />
                    ) : (
                      <Popover
                        content={`You don't have access to change this user type`}
                        trigger="hover"
                        placement="topLeft">
                        <SelectComp
                          disabled={true}
                          placeholder={people?.role?.role || 'System User'}
                          options={allowedUserRoles}
                        />
                      </Popover>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={14} md={14} lg={14} xl={14}>
                  <Div display="flex" flexDirection="row">
                    <InputFormItem label="System Login Email" name="loginEmail">
                      <InputComp
                        size="small"
                        disabled={!isAllowedToEditEmail}
                        type="email"
                        placeholder="Login Email"
                      />
                    </InputFormItem>
                    <Tooltip title="The employee will receive an email to this address requesting them to reset the password">
                      <Image src={InfoSVG} />
                    </Tooltip>
                  </Div>
                </Col>
              </Row>
            </Div>
            <Div
              borderBottom={` 0.5px solid ${theme.gray300}`}
              padding="24px 0px"
              display="flex"
              flexDirection="row">
              <B type="b-large-semibold">
                Payroll
                <B type="b-small" color={theme.gray600}>
                  Show user when creating a payroll
                </B>
              </B>
              <FormItem name="isPayrollAllowed">
                <SwitchComponent defaultChecked={people?.isPayrollAllowed} />
              </FormItem>
            </Div>
            <StyledDiv>
              <B type="b-large-semibold">
                Account Status
                {people?.isArchived ? (
                  <TagStyle textColor={theme.gray600} color={theme.gray100}>
                    Archived
                  </TagStyle>
                ) : (
                  <TagStyle textColor={theme.green300} color={theme.green50}>
                    Active
                  </TagStyle>
                )}
              </B>

              <FormItem name="isArchived">
                {people?.isArchived ? (
                  <ConfirmationModal
                    title={
                      <B type="b-large-semibold">
                        Are you sure you want to activate this user ?
                      </B>
                    }
                    content={
                      <B type="b-large">
                        By activating this user both their individual login and
                        payroll profile will be enable.
                      </B>
                    }
                    okText="Yes, Activate"
                    cancelText="No"
                    onConfirm={() => updatePeople(false)}
                    trigger={
                      <B color={theme.blue600} type="b-default-semibold">
                        Re-activate
                      </B>
                    }
                  />
                ) : (
                  <DeleteModal
                    title="Are you sure you want to deactivate this user ?"
                    buttonLabel="Yes,Archive"
                    content="By deactivating a user both their individual login and payroll profile will be disabled."
                    onClickCancel={() => updatePeople(true)}
                    openModal={
                      <B
                        color={theme.red300}
                        type="b-default-semibold"
                        cursor="pointer">
                        Archive
                      </B>
                    }></DeleteModal>
                )}
              </FormItem>
            </StyledDiv>
            <B type="b-small" color={theme.gray600} Pb="16px">
              Active or Archive employee profile. Archiving will remove the
              employee from active features like payroll but will still keep the
              profile stored for future reference. You can activate an archived
              profile at anytime
            </B>

            <StyledDiv>
              <B type="b-large-semibold">Delete Employee</B>

              <FormItem name="isDeleted">
                <>
                  <Row gutter={[32, 0]} justify="center" align="middle">
                    <Col>
                      <Tooltip
                        title="This employee is already a part of past or current organisation activities"
                        placement="bottom">
                        <Image src={InfoSVG} />
                      </Tooltip>
                    </Col>
                    <Col>
                      {isDeletableUser ? (
                        <DeleteModal
                          title="Are you sure you want to permanently delete this employee?"
                          buttonLabel="Yes,Delete"
                          content="By delete a user, the user will be completely removed from the system"
                          onClickCancel={() => deleteUserId(people.id)}
                          openModal={
                            <DangerButton>Delete</DangerButton>
                          }></DeleteModal>
                      ) : (
                        <DangerButton disabled={!isDeletableUser}>
                          Delete
                        </DangerButton>
                      )}
                    </Col>
                  </Row>
                </>
              </FormItem>
            </StyledDiv>

            <B
              cssStyle="width:500px"
              type="b-small"
              color={theme.gray600}
              Pb="16px">
              You are only allowed to delete an employee if they aren't involved
              in any of the old or ongoing activities in your organization
              within a period of 7 days since the day of onboarding. Please note
              that only the owner has this permission
            </B>

            {people.isArchived && (
              <AlertMessage
                color={theme.gray600}
                type="notification"
                title={`Archived on ${moment(people.archiveDate).format(
                  'DD MMM YYYY',
                )} by ${
                  people.archivedUser ? people.archivedUser?.fullName : '-'
                }`}
              />
            )}
          </CustomDiv>
          <FooterComponent
            rightChildren={
              <PrimaryButton
                ml={16}
                htmlType="submit"
                disabled={
                  !people.profileStatus.summary.isCompleted && people.isPending
                }>
                {people?.isPending ? 'Complete' : 'Save'}
              </PrimaryButton>
            }
          />
        </CustomForm>
      </Spin>
    </PeopleLayout>
  );
};

export default PeopleSettingsPage;
