import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  AlertMessage,
  DefaultBody,
  FooterComponent,
  SecondaryButton,
} from '../../../../components';
import {
  DataExtractorCSVEnum,
  DataImportStrategyEnum,
  clearDataImportRedirect,
  getAllPeople,
} from '../../../../store/actions';

import { useGetDefaultDataExtractor } from '../../../../util/dataImportUtil';
import { useWindowSize } from '../../../../util/screenSize';
import InitialEmployeeTable from './InitialEmployeeTable';
import DataImportPage from '../../../settings/data-imports/DataImportPage';
import ImportEmployeesCSV from './ImportEmployeesCSV';
import { DispatchType, RootState } from '../../../../store';

const AddEmployeesCSV: FC = () => {
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();
  const { isMobile } = useWindowSize();

  const [csvType, setCSVType] = useState<DataExtractorCSVEnum | null>(null);

  const [fullScreen, setFullScreen] = useState(false);
  const extractorInfo = useGetDefaultDataExtractor();

  const allPeoples = useSelector((state: RootState) => state.people.allPeoples);

  const dataImportPayload = useSelector(
    (state: RootState) => state.dataImport.dataImportPayload,
  );

  useEffect(() => {
    if (extractorInfo?.importType === DataImportStrategyEnum.EmployeeOnly) {
      setCSVType(DataExtractorCSVEnum.EMPLOYEE_CSV);
    }
  }, [extractorInfo]);

  const onComplete = () => {
    dispatch(clearDataImportRedirect());
  };
  const allPeoplesLoading = useSelector(
    (state: RootState) => state.people.allPeoplesLoading,
  );
  useEffect(() => {
    dispatch(getAllPeople());
    dispatch(clearDataImportRedirect());
  }, []);

  if (!extractorInfo) {
    return (
      <AlertMessage
        title="This organization does not support data import feature."
        type="error"
      />
    );
  }

  if (dataImportPayload.length && csvType) {
    return (
      <DataImportPage
        extractorType={extractorInfo?.extractorType}
        onComplete={() => onComplete()}
        csvType={csvType}
      />
    );
  }

  return (
    <DefaultBody>
      <ImportEmployeesCSV extractorInfo={extractorInfo} />
      <InitialEmployeeTable
        loading={allPeoplesLoading}
        dataPayload={allPeoples}
        setFullScreen={setFullScreen}
        fullScreen={fullScreen}
      />
      <FooterComponent
        leftChildren={
          <>
            <SecondaryButton
              block={isMobile ? true : false}
              onClick={() => {
                navigate('/contact');
              }}>
              Back
            </SecondaryButton>
          </>
        }
      />
    </DefaultBody>
  );
};

export default AddEmployeesCSV;
