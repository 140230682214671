import styled from '@emotion/styled';
import {
  color,
  ColorProps,
  SpaceProps,
  space,
  border,
  BorderProps,
  LayoutProps,
  layout,
} from 'styled-system';
import theme from '../theme';

type Props = React.HTMLAttributes<HTMLDivElement> &
  ColorProps &
  SpaceProps &
  BorderProps &
  LayoutProps;

export const DefaultBody = styled.div<Props>`
  padding: 24px 32px;
  background-color: ${theme.white};
  height: 100%;
  @media only screen and (max-width: 1200px) {
    padding: 32px 24px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0px 16px;
  }
  ${color}
  ${space}
  ${border}
 ${layout}
`;
