import React, { FC, useEffect, useState } from 'react';

import { LogTrailTable } from '../../../../components';
import { useGetAllUserLogs } from '../../../../api/peopleHooks';
import { LogTrailItemI } from '../../../../store/actions';
const AllPeopleLogTrail: FC = () => {
  const [payload, setPayload] = useState<LogTrailItemI[]>([]);
  const {
    isLoading: loadingAllUserLogs,
    data: dataAllUserLogs = [],
    mutate: getAllUserLogs,
  } = useGetAllUserLogs();

  useEffect(() => {
    getAllUserLogs();
  }, []);

  useEffect(() => {
    const uniqueLogs = new Map<number, LogTrailItemI>();

    dataAllUserLogs
      .flatMap((item) => item.logs)
      .forEach((item) => {
        if (!uniqueLogs.has(item.id)) {
          uniqueLogs.set(item.id, {
            ...item,
            mapper: JSON.parse(item.mapper),
          });
        }
      });
    setPayload(Array.from(uniqueLogs.values()) as LogTrailItemI[]);
  }, [dataAllUserLogs]);

  return (
    <>
      <LogTrailTable logs={payload} loading={loadingAllUserLogs} />
    </>
  );
};
export default AllPeopleLogTrail;
