import { Moment } from 'moment';

import { LeaveStatus } from '../containers/home/homeUtil';
import { LeaveNameEnum, LeaveTypeEnum } from './';

export interface DataRangeI {
  startDate: Moment | null;
  endDate: Moment | null;
}

export interface PaginationI {
  page: number;
  limit: number;
}

export interface payrollReportFiltersI {
  users?: number[];
  teams?: string[];
  payrollIds?: number[];
  dataRange?: DataRangeI | null;
}

export const NoTeamKeyWord = 'Unallocated';

export interface SelectedInfoI {
  name?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  no?: number | null | string;
  fullName?: string | null;
}

export interface FormTwoRowI {
  memberNumber?: string | null;
  nic?: string | null;
  totalContribution: number | null;
  months: string | null;
}

export interface UserPayrollI {
  calculation: userCalculationI;
  fullName: string | null;
  id: number;
  membersNumber?: string | null;
  nic?: string | null;
  teams: string[];
  month?: string;
}

export interface userCalculationI {
  costToCompany: number;
  employeeEPF: number;
  employerEPF: number;
  employerETF: number;
  epfEtfAmount: number;
  grossDeduction: number;
  grossSalary: number;
  netSalary: number;
  payItems: PayItemType[];
  taxableAmount: number;
  totalAllowance: number;
  totalTax: number;
}

export interface PayItemType {
  id?: string;
  type: string;
  payTitle: string;
  amount: number;
  isTaxable: boolean;
  isEpf: boolean;
}

export interface EtfReportDataI {
  createDate: Moment | null;
  endDate: Moment | null;
  id: number;
  month: string;
  name: string;
  organizationAddress: string;
  organizationName: string;
  organizationTelephoneNumber: string;
  startDate: Moment;
  users: any[];
}

interface monthTest {
  Rs: number;
  Cts: number;
}

export interface SummaryDataI {
  M1: monthTest;
  M2: monthTest;
  M3: monthTest;
  M4: monthTest;
  M5: monthTest;
  M6: monthTest;
}

export interface MembersDataI {
  [key: number]: UserPayrollI[];
}

export interface InitialSummaryDataI {
  [key: number]: SummaryDataI[];
}
export interface LeaveReportI {
  createdAt: string;
  createdById: number;
  date: string;
  from: string;
  hours: number;
  id: number;
  leaveName: LeaveNameEnum;
  leaveType: LeaveNameEnum;
  reason: string;
  reviewBy: { fullName: string } | null;
  reviewById: string | null;
  status: LeaveStatus;
  to: string;
  type: LeaveTypeEnum;
  updatedAt: string;
  user: { fullName: string; employeeNumber: string };
  userId: number;
  rejectionNote: string;
}

export interface GetLeaveReportParams {
  startDate: string;
  endDate: string;
}

export interface GetReportParams {
  startYear: string;
  endYear: string;
  listOfIds: string;
}
