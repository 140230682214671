import React, { FC } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import { space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';
import theme from '../../theme';

type Props = SpaceProps &
  Omit<ButtonProps, 'shape' | 'ghost' | 'danger' | 'type'> & {
    size?: 'small' | 'large';
  };

const StyledButton = styled(Button)<Props>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.green500};
  border-radius: 4px;
  height: 40px;
  a {
    color: ${theme.green500};
  }

  .btn-custom-icon-left {
    margin-right: 6px;
    max-height: 20px;
    color: ${theme.green500};
  }

  &:focus {
    a {
      text-decoration: underline;
    }
    color: ${theme.green600};
  }

  a:active {
    text-decoration: underline;
  }

  &:hover {
    color: ${theme.green600};
    a {
      color: ${theme.green600};
    }
  }
  &.btn-small {
    height: 32px;
    font-size: 14px;
  }
  &.btn-disabled {
    color: ${theme.blue400};
    opacity: 0.5;
    text-decoration: none;
    a {
      color: ${theme.green500};
    }
  }

  &.btn-disabled a {
    text-decoration: none;
  }
  ${space},
`;

export const LinkButtonBbhr: FC<Props> = ({ size = 'large', ...props }) => {
  let icon: React.ReactNode | null = null;
  let className = `btn-${size} ${props.className || ''}`;

  if (!props.loading) {
    icon = <span className="btn-custom-icon-left">{props.icon}</span>;
  }

  if (props.disabled) {
    className = 'btn-disabled ' + className;
  }

  return (
    <StyledButton
      {...props}
      type="link"
      size={undefined}
      icon={icon}
      className={className}>
      <a>{props.children}</a>
    </StyledButton>
  );
};
