import React, { FC } from 'react';
import { Rnd } from 'react-rnd';
import { PlaceholderTypeEnum } from '../../../../types/contract.types';
import { useZoom } from '../../shared/ZoomContext';
import { defaultMinSizes } from '../../utils/placeholder';
import DatePlaceHolderBox from './DatePlaceholder';
import { PlaceholderBoxPropsI } from './PlaceholderBoxProps';
import SignaturePlaceholderBox from './SignaturePlaceholderBox';
import TextPlaceHolderBox from './TextPlaceholder';

const PlaceholderBox: FC<PlaceholderBoxPropsI> = (props) => {
  const { ratio } = useZoom();
  const getPlaceHolder = () => {
    switch (props.type) {
      case PlaceholderTypeEnum.date:
        return <DatePlaceHolderBox {...props} />;
      case PlaceholderTypeEnum.signature:
        return <SignaturePlaceholderBox {...props} />;
      case PlaceholderTypeEnum.textInput:
        return <TextPlaceHolderBox {...props} />;
      default:
        throw 'Placeholder type not implemented';
    }
  };

  return (
    <Rnd
      minHeight={defaultMinSizes[props.type].height * ratio}
      minWidth={defaultMinSizes[props.type].width * ratio}
      onDragStop={(_event, prop) => {
        props.onDragStop(prop);
      }}
      disableDragging={!props.isEditable}
      enableResizing={
        props.isEditable && props.isSelected
          ? {
              bottomLeft: true,
              bottomRight: true,
              topLeft: true,
              topRight: true,
            }
          : false
      }
      size={{
        width: props.width * ratio,
        height: props.height * ratio,
      }}
      position={{
        x: props.left * ratio,
        y: props.top * ratio,
      }}
      bounds={props.bounds}
      onResizeStop={(_e, _direction, ref, _delta, _position) => {
        const widthNumber = ref.style.width.replace(/\D/g, '');
        const heightNumber = ref.style.height.replace(/\D/g, '');

        props.onResizeStop({
          width: Number(widthNumber) / ratio,
          height: Number(heightNumber) / ratio,
        });
      }}>
      {getPlaceHolder()}
    </Rnd>
  );
};

export default PlaceholderBox;
