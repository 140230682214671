import React, { FC } from 'react';
import { Col, Row } from 'antd';
import OrganizationsSummary from './OrganizationsSummary';
import { B, DefaultBody, Div } from '../../../../components';
import OwnerTable from './OwnerTable';

const SuperHomePage: FC = () => {
  return (
    <DefaultBody>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={19}>
          <OrganizationsSummary />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={19}>
          <Div
            display="flex"
            flexDirection="row"
            paddingTop="32px"
            paddingBottom="24px">
            <B type="b-large-semibold">Owners </B>
          </Div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={19}>
          <OwnerTable />
        </Col>
      </Row>
    </DefaultBody>
  );
};

export default SuperHomePage;
