import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducer';
import { B, Div } from '../../components';
import { useSearchParams } from 'react-router-dom';
import { useUpdateContextToken } from '../../api/organizationHooks';
import { Col, Row, Image } from 'antd';
import { TeamConfig } from '../../assets';

const TeamConfiguration: FC = () => {
  const [searchParams] = useSearchParams();

  const me = useSelector((state: RootState) => state.people.me);
  const { mutate: updateContextToken } = useUpdateContextToken();

  useEffect(() => {
    const accessToken = searchParams.get('context');
    updateContextToken(accessToken);
  }, []);

  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Div height="717px" paddingTop="127px">
        <Col span={24} style={{ textAlign: 'center' }}>
          <Image
            width="338px"
            height="338px"
            preview={false}
            src={TeamConfig}
          />
        </Col>
        <Col span={24} style={{ textAlign: 'center', marginTop: '20px' }}>
          <B type="b-extra-large-bold">Great !</B>
        </Col>
        <Col span={24} style={{ textAlign: 'center', marginTop: '10px' }}>
          <B type="b-default">
            You are currently logged in. Feel free to close this page and
            continue your work using the Teams app, or you can{' '}
            <Link to="/">return to the dashboard</Link>
          </B>
        </Col>

        <Col span={24} style={{ textAlign: 'center', marginTop: '10px' }}></Col>
      </Div>
    </Row>
  );
};
export default TeamConfiguration;
