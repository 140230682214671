import React, { FC, useEffect, useState } from 'react';
import { Carousel, notification, Row, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { useGetAccountingIntStatus } from '../../../../api/integrationHooks';
import {
  useGetExistingPayruns,
  useGetValidatedPayrollViewDetails,
  usePutSelectUserPayroll,
  useUpdatePayrun,
} from '../../../../api/payrollHooks';

import {
  AlertMessage,
  B,
  ConfirmationModal,
  DeleteModal,
  EmptyPage,
  H,
  PrimaryButton,
  SecondaryButton,
} from '../../../../components';
import { MultipleAlertMessage } from '../../../../components/alertMessage/MultipleAlertMessage';
import { FooterComponent } from '../../../../components/FooterComponent';
import {
  DataImportUserPayLoad,
  deleteAllPayrollDetails,
  PayrollViewUserPayLoad,
} from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import theme from '../../../../theme';
import {
  ExpenseCreation,
  IntegrationStatusEnum,
  Permission,
} from '../../../../types';
import {
  payrollParam,
  PayrollViewTypeEnum,
  PayrunType,
  TableFilterEnum,
} from '../../../../types/payroll.types';
import { getPathWithOrgId } from '../../../../util/getPathWithOrgId';
import { isAllowed } from '../../../../util/permissionUtil';
import { useWindowSize } from '../../../../util/screenSize';
import {
  formatUserDataImportJSON,
  getLeaveColumns,
} from '../../../settings/data-imports/dataImport.util';
import { ErrorMessageContainer } from '../../../settings/integration-apps/components/IntegrationAlerts';
import PayrollInfoSummary from '../../shared/PayrollInfoSummary';
import PayrollTable from '../../shared/PayrollTable';
import { SalarySummaryCard } from '../../shared/SalarySummaryCard';
import { calculatePayrollSummary } from '../../utils/calculatePayrollSummary';
import PayRunModal from '../payroll-home/shared/PayRunModal';
import PayrollConfirmationPage from './payroll-confirmation/PayrollConfirmationPage';
import PayrollLogTrail from './payroll-draft/PayrollLogTrail';
import { USDSalarySummaryCard } from '../../shared/USDSalarySummaryCard';
import { ChevronLeftBlack, ChevronRightBlackSVG } from '../../../../assets';
import PayrollViewSummary from './PayrollViewSummary';

const TabSection = styled(Row)`
  margin-bottom: 80px;
`;

const CarouselContainer = styled.div`
  background: ${theme.lightBlue50};
  .ant-carousel .slick-next {
    right: 20px;
  }
  .ant-carousel .slick-prev {
    left: 20px;
    z-index: 1;
  }
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next {
    margin-top: -5px;
    height: 12px;
  }
  .ant-carousel .slick-dots-bottom {
    display: none !important;
  }
`;

const PayrollViewPage: FC = () => {
  const { isMobile } = useWindowSize();
  const dispatch: DispatchType = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<payrollParam>();
  const [showAlertMessage, setShowAlertMessage] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [warnMessages] = useState<string[]>([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isDraftPayroll, setIsDraftPayroll] = useState(false);
  const [isEditedPayroll, setIsEditedPayroll] = useState(false);
  const [viewType, setViewType] = useState<PayrollViewTypeEnum>(
    PayrollViewTypeEnum.PAYROLL_VIEW,
  );
  const [filterType, setFilterType] = useState<TableFilterEnum>(
    TableFilterEnum.ALL,
  );
  const [errorRow, setErrorRow] = useState<DataImportUserPayLoad[]>([]);
  const [containsDuplicateEmpIds, setContainsDuplicateEmpIds] = useState(false);

  const isAllowedManageIntegration: boolean = isAllowed(
    Permission.MANAGE_INTEGRATION,
  );
  const [selectedPayrollViewData, setSelectedPayrollViewData] = useState<
    PayrollViewUserPayLoad[]
  >([]);
  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );
  const [dataForTable, setDataForTable] = useState<
    | {
        dataList: DataImportUserPayLoad[];
        payItemColumns: string[];
      }
    | undefined
  >(undefined);

  const {
    isLoading: loadingForgetPasswordRequest,
    data: dataSelectUserPayroll = null,
    mutate: putSelectUserPayroll,
  } = usePutSelectUserPayroll();

  const {
    data: dataUpdatePayRun,
    mutate: updatePayRun,
    error: errorUpdatePayRun,
  } = useUpdatePayrun();

  const getFilteredData = () => {
    if (filterType === TableFilterEnum.UPDATED) {
      const data = dataForTable?.dataList.filter((item) => {
        return (
          (!item.updatedUserValues && item.values.id) ||
          (item.payrollUpdates.length > 0 && item.values.id)
        );
      });
      return data;
    } else {
      return filterType === TableFilterEnum.ERROR
        ? errorRow
        : dataForTable?.dataList;
    }
  };
  const payload = getFilteredData();

  const { isLoading: integrationStatusLoading, data: getIntegrationStatus } =
    useGetAccountingIntStatus();

  const {
    isLoading: payrollViewDataLoading,
    data: payrollViewData,
    isError: payrollViewDataError,
    mutate: getValidatedPayrollViewDetails,
  } = useGetValidatedPayrollViewDetails();

  const { data: getExistingPayruns, mutate: getExistingPayrunsList } =
    useGetExistingPayruns();

  useEffect(() => {
    getExistingPayrunsList({
      month: String(payrollViewData?.month),
      year: Number(payrollViewData?.year),
    });
  }, [payrollViewData?.month, payrollViewData?.year]);

  const draftedMidCyclePayrolls = getExistingPayruns?.filter(
    (i) =>
      i.payrunType === PayrunType.MID_CYCLE_PAYRUN && i.status === 'DRAFTED',
  );

  useEffect(() => {
    switch (viewType) {
      case PayrollViewTypeEnum.ERROR:
        return setFilterType(TableFilterEnum.ERROR);
      case PayrollViewTypeEnum.UPDATE:
        return setFilterType(TableFilterEnum.UPDATED);
      default:
        return setFilterType(TableFilterEnum.ALL);
    }
  }, [viewType]);

  useEffect(() => {
    if (params.payrollKey) {
      getExistingPayrunsList({
        month: String(payrollViewData?.month),
        year: Number(payrollViewData?.year),
      });
      getValidatedPayrollViewDetails(params.payrollKey);
    }
  }, [params.payrollKey]);

  useEffect(() => {
    if (dataUpdatePayRun && !errorUpdatePayRun) {
      getValidatedPayrollViewDetails(params.payrollKey);
    }
  }, [dataUpdatePayRun]);

  useEffect(() => {
    if (isDraftPayroll) {
      setDataForTable(
        payrollViewData?.empPayroll &&
          formatUserDataImportJSON(
            payrollViewData?.empPayroll,
            payrollViewData?.payrunType,
          ),
      );
    } else {
      const selectedPayrollViewData =
        payrollViewData?.empPayroll.filter((item) => item.isSelected) || [];
      setDataForTable(
        formatUserDataImportJSON(
          selectedPayrollViewData,
          payrollViewData?.payrunType,
        ),
      );
    }
  }, [payrollViewData, isDraftPayroll]);

  useEffect(() => {
    if (location.state) {
      setSelectedPayrollViewData(
        payrollViewData?.empPayroll.filter((item) => item.isSelected) || [],
      );
      getValidatedPayrollViewDetails(params.payrollKey);
    }
  }, [location.state]);

  useEffect(() => {
    return () => {
      notification.destroy();
    };
  }, []);

  useEffect(() => {
    if (payrollViewData && dataForTable) {
      let hasAnyError = false;
      if (isDraftPayroll) {
        setSelectedRowKeys(
          dataForTable.dataList
            .filter((item) => {
              if (item.hasAnyError) {
                hasAnyError = true;
              }
              return !item.hasAnyError;
            })
            .filter((item) => item.isSelected || false)
            .map((item) => item.rowKey),
        );
        if (hasAnyError) {
          if (isDraftPayroll && !dataSelectUserPayroll && !location.state) {
            notification.error({
              description:
                'The people with red marked can not pay until they complete their profiles setup',
              message: 'Check Payroll Data',
              duration: 5,
              placement: 'bottomRight',
            });
          }
        }
      } else {
        setSelectedRowKeys(
          dataForTable.dataList
            .filter((item) => item.isSelected || false)
            .map((item) => item.rowKey),
        );
      }
    }

    const containsDuplicates = payload?.some(
      (employee, index, array) =>
        array.findIndex(
          (e) => e.values.employeeNumber === employee.values.employeeNumber,
        ) !== index,
    );

    if (containsDuplicates) {
      setContainsDuplicateEmpIds(containsDuplicates);
    }
  }, [payrollViewData, dataForTable, dataSelectUserPayroll, location.state]);

  useEffect(() => {
    if (payrollViewData) {
      const visible = payrollViewData.status !== 'COMPLETED';
      setIsDraftPayroll(visible);
      const isEdited = payrollViewData.isEdited;
      setIsEditedPayroll(isEdited);
    }
  }, [payrollViewData]);

  useEffect(() => {
    if (dataSelectUserPayroll && payrollViewData) {
      setSelectedPayrollViewData(
        payrollViewData?.empPayroll.filter((item) => item.isSelected) || [],
      );
    }
  }, [dataSelectUserPayroll, payrollViewData]);

  useEffect(() => {
    if (dataSelectUserPayroll) {
      getValidatedPayrollViewDetails(params.payrollKey);
    }
  }, [dataSelectUserPayroll]);

  const deletePayroll = (payrollId: string) => {
    dispatch(deleteAllPayrollDetails(payrollId));
  };

  const selectOnlySelectedUsers = async (values: string[]) => {
    if (payrollViewData) {
      const userIds: number[] = [];
      payrollViewData.empPayroll.map((empPayRoll) => {
        const found = values.find(
          (id) => empPayRoll.values.id === parseInt(id),
        );
        if (found) {
          userIds.push(Number(empPayRoll.values.id));
        }
      });
      if (userIds.length) {
        await putSelectUserPayroll({
          payrollId: params.payrollKey,
          userIds: userIds,
        });
      }
    }
  };
  const leaveColumns: string[] | undefined =
    organizationData?.isUsingSystemLeaves
      ? undefined
      : getLeaveColumns(selectedPayrollViewData);

  const getSelectedPayrolls = () => {
    const payrolls: PayrollViewUserPayLoad[] = [];

    if (payrollViewData) {
      payrollViewData.empPayroll.map((empPayRoll) => {
        const found = selectedRowKeys.find(
          (id) => empPayRoll.values.id === parseInt(id),
        );
        if (found) {
          payrolls.push(empPayRoll);
        }
      });
    }
    return payrolls;
  };

  useEffect(() => {
    if (dataForTable) {
      setErrorRow(
        dataForTable.dataList.filter(
          (item) => item.hasEmployeeErrors || item.payItemErrors.length > 0,
        ),
      );
    }
  }, [dataForTable]);

  useEffect(() => {
    warnMessages.splice(0, warnMessages.length);
    if (dataForTable?.dataList.filter((item) => item.isNewInDraft).length > 0) {
      warnMessages.push(
        `New ${
          dataForTable?.dataList.filter((item) => item.isNewInDraft).length
        } employees have added to the Payrun. Please select employees if you wish to add them to this payrun`,
      );
    }
    if (
      dataForTable?.dataList.filter(
        (item) => !item.isSelected && item.values.resignedDate != null,
      ).length > 0
    ) {
      warnMessages.push(
        `Please note that there are ${
          dataForTable.dataList.filter(
            (item) => !item.isSelected && item.values.resignedDate != null,
          ).length
        } resigned employees in this payrun`,
      );
    }
    if (
      payrollViewData?.payrunType === PayrunType.MONTHLY_PAYRUN &&
      !!draftedMidCyclePayrolls?.length
    ) {
      warnMessages.push(
        'Please complete all draft mid cycle payruns before you complete the monthly payrun for this pay cycle',
      );
    }
  }, [dataForTable, payrollViewData]);

  const totalCalculation = calculatePayrollSummary(getSelectedPayrolls());

  if (selectedPayrollViewData && selectedPayrollViewData.length > 0) {
    return (
      <PayrollConfirmationPage
        selectedPayrollViewData={selectedPayrollViewData}
        payrollViewDataLoading={loadingForgetPasswordRequest}
        payrollViewData={payrollViewData}
        editDraft={(value) => setSelectedPayrollViewData(value)}
        selectedRowKeys={selectedRowKeys.length}
      />
    );
  } else {
    return (
      <>
        <div>
          {isMobile && (
            <H type="h6" padding="5px 10px 5px">
              Salary Information
            </H>
          )}
          {isAllowedManageIntegration &&
            getIntegrationStatus?.type !== ExpenseCreation.SYSTEM &&
            !integrationStatusLoading &&
            [IntegrationStatusEnum.ACTION_REQUIRED].includes(
              getIntegrationStatus?.status as IntegrationStatusEnum,
            ) && (
              <ErrorMessageContainer
                m="14px 32px"
                margin={3 / 5}
                message={
                  <>
                    Opps! Looks like your{' '}
                    <b>{getIntegrationStatus?.type.toLocaleLowerCase()}</b>{' '}
                    integration is not working anymore.
                  </>
                }
                type="error"
                showIcon
              />
            )}
          {payrollViewDataLoading || payrollViewData ? (
            <Spin spinning={payrollViewDataLoading}>
              <PayrollViewSummary
                onChangeView={(type: PayrollViewTypeEnum) => setViewType(type)}
                isDraftPayroll={isDraftPayroll}
                viewType={viewType}
                onClickFullScreen={(v: boolean) => setFullScreen(v)}
                isFullScreen={fullScreen}
                payrollDetails={payrollViewData}
                dataForTable={dataForTable}
                leaveColumnNames={leaveColumns}
                onClickMenu={(v: boolean) => setVisible(v)}
              />
              {warnMessages.length > 0 &&
                showAlertMessage &&
                isDraftPayroll && (
                  <MultipleAlertMessage
                    type="warn"
                    messages={warnMessages}
                    onClose={() => setShowAlertMessage(false)}
                  />
                )}
              {containsDuplicateEmpIds && (
                <AlertMessage
                  type="error"
                  title="There are multiple employees with the same employee number, please edit them."
                />
              )}

              {viewType != PayrollViewTypeEnum.ERROR && (
                <CarouselContainer>
                  {payrollViewData?.currentExchangeRate ? (
                    <Carousel
                      arrows
                      prevArrow={<img src={ChevronLeftBlack} />}
                      nextArrow={<img src={ChevronRightBlackSVG} />}>
                      <SalarySummaryCard
                        summary={totalCalculation}
                        selected={selectedRowKeys.length}
                        allEmpLength={
                          dataForTable ? dataForTable?.dataList.length : 0
                        }
                        currentExchangeRate={
                          payrollViewData?.currentExchangeRate
                        }
                      />
                      <USDSalarySummaryCard
                        summary={totalCalculation}
                        selected={selectedRowKeys.length}
                        allEmpLength={
                          dataForTable ? dataForTable?.dataList.length : 0
                        }
                        currentExchangeRate={
                          payrollViewData.currentExchangeRate
                        }
                        peggedCurrency={payrollViewData.peggedCurrency}
                      />
                    </Carousel>
                  ) : (
                    <SalarySummaryCard
                      summary={totalCalculation}
                      selected={selectedRowKeys.length}
                      allEmpLength={
                        dataForTable ? dataForTable?.dataList.length : 0
                      }
                      currentExchangeRate={payrollViewData?.currentExchangeRate}
                    />
                  )}
                </CarouselContainer>
              )}
              <TabSection>
                {viewType === PayrollViewTypeEnum.LOG_TRAIL_VIEW ? (
                  <PayrollLogTrail />
                ) : (
                  <PayrollTable
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={(values) => {
                      setSelectedRowKeys(values);
                    }}
                    payItemColumns={dataForTable?.payItemColumns || []}
                    dataPayload={
                      payload?.sort(
                        (x, y) =>
                          Number(y.isNewInDraft) - Number(x.isNewInDraft),
                      ) || []
                    }
                    isDraftPayroll={isDraftPayroll}
                    payrollKey={params.payrollKey}
                    fullScreen={fullScreen}
                    extraTableHeight={0}
                    setFullScreen={setFullScreen}
                    isSelectedEmployeeOnly={false}
                    isMidCyclePayrun={
                      payrollViewData?.payrunType ===
                      PayrunType.MID_CYCLE_PAYRUN
                        ? true
                        : false
                    }
                  />
                )}
              </TabSection>
            </Spin>
          ) : (
            <>
              {payrollViewDataError && (
                <div>
                  <EmptyPage
                    content="Sorry, the page you visited does not exist."
                    title="something went wrong !"
                    buttonLabel=" Back Home"
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div>
          <FooterComponent
            justify-content="space-between"
            leftChildren={
              <>
                <SecondaryButton
                  block={isMobile ? true : false}
                  className="ant-col-12"
                  onClick={() => navigate(getPathWithOrgId('/payroll'))}>
                  Back
                </SecondaryButton>
              </>
            }
            rightChildren={
              <>
                {!payrollViewDataLoading && isDraftPayroll ? (
                  <>
                    {!isEditedPayroll && (
                      <SecondaryButton block={isMobile ? true : false}>
                        <DeleteModal
                          title="Delete Payroll"
                          buttonLabel="Yes, Delete"
                          content="Are you sure you want to delete payroll?"
                          onClickCancel={async () => {
                            await deletePayroll(params.payrollKey);
                            navigate(getPathWithOrgId('/payroll'));
                          }}
                          openModal={<>Delete Pay Run </>}></DeleteModal>
                      </SecondaryButton>
                    )}

                    <ConfirmationModal
                      title={<B type="b-large-semibold">Confirmation Alert</B>}
                      content={
                        <>
                          <PayrollInfoSummary
                            payrollViewData={payrollViewData}
                          />

                          <span>
                            You have selected {selectedRowKeys.length} employees
                            in this payroll.
                          </span>
                        </>
                      }
                      okText="Save and Continue"
                      cancelText="No"
                      onConfirm={() => {
                        selectOnlySelectedUsers(selectedRowKeys);
                      }}
                      trigger={
                        <PrimaryButton
                          ml={16}
                          disabled={selectedRowKeys.length == 0}>
                          Save
                        </PrimaryButton>
                      }
                    />
                  </>
                ) : (
                  <>
                    {!payrollViewDataLoading && (
                      <Link to={`/Payroll-publish/${params.payrollKey}`}>
                        <PrimaryButton ml={16}>Summary</PrimaryButton>
                      </Link>
                    )}
                  </>
                )}
              </>
            }
          />
        </div>
        {visible && (
          <PayRunModal
            visible={visible}
            onClose={() => setVisible(false)}
            payRun={payrollViewData}
            onSubmit={async (values) => {
              updatePayRun({ data: values, payrollId: params.payrollKey });
              setVisible(false);
            }}
          />
        )}
      </>
    );
  }
};

export default PayrollViewPage;
