import React, { FC, ReactNode } from 'react';
import { Col, Row } from 'antd';
import theme from '../../../../theme';
import { ReportMenu } from './ReportMenu';
import { DefaultBody } from '../../../../components';
import styled from '@emotion/styled';

interface PeopleLayoutProp {
  children: ReactNode;
}

const RowComp = styled(Row)`
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
`;

const ColComp = styled(Col)`
  border-right: 1px solid ${theme.gray300};
`;
export const ReportLayout: FC<PeopleLayoutProp> = ({ children }) => {
  return (
    <DefaultBody>
      <RowComp>
        <ColComp xs={0} sm={0} md={0} lg={5} xl={5} xxl={4}>
          <ReportMenu />
        </ColComp>
        <Col xs={24} sm={24} md={24} lg={19} xl={19} xxl={20}>
          {children}
        </Col>
      </RowComp>
    </DefaultBody>
  );
};
