import React, { FC } from 'react';
import { Col, Form, Row } from 'antd';
import {
  B,
  DefaultBody,
  FooterComponent,
  InputComp,
  PrimaryButton,
  SecondaryButton,
  SelectComp,
} from '../../../../components';
import theme from '../../../../theme';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import { CreateOwnerI } from '../../../../types/superAdmin.types';
import { useDispatch, useSelector } from 'react-redux';
import { addNewOwner } from '../../../../store/modules/superAdmin';
import { Validation } from '../../../../common/validation';
import { DispatchType, RootState } from '../../../../store';

const FormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;
const FormContainer = styled(Form)`
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue600};
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: none;
  }
  .ant-input[disabled] {
    color: ${theme.black};
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-bottom: 10px;
  }
`;
const Column = styled(Col)`
  margin-bottom: 75px;
`;
const CreateNewOrganization: FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();
  const isPutOwnerLoading = useSelector(
    (state: RootState) => state.superAdmin.putOwnerLoading,
  );

  const onFinish = async (values: CreateOwnerI) => {
    const value = { ...values, isNewOwner: true };
    await dispatch(addNewOwner(value));
    if (!isPutOwnerLoading) {
      navigate(`/super-admin`);
    }
  };

  return (
    <DefaultBody>
      <FormContainer
        form={form}
        layout="vertical"
        onFinish={(v) => onFinish(v as CreateOwnerI)}>
        <B type="b-default-semibold" pb="12px">
          User Information
        </B>{' '}
        <Column xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
          <FormItem
            name="loginEmail"
            label="Personal Email"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Please enter the personal email',
              },
              {
                type: 'email',
                message: 'Please enter valid email',
              },
            ]}>
            <InputComp type="email" placeholder="Enter email" />
          </FormItem>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
              <FormItem
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Please select your title!',
                  },
                ]}>
                <SelectComp
                  size="large"
                  options={[
                    {
                      label: 'Mr.',
                      value: 'mr',
                    },
                    {
                      label: 'Mrs.',
                      value: 'mrs',
                    },
                    {
                      label: 'Ms.',
                      value: 'ms',
                    },
                  ]}
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={19} lg={19} xl={19}>
              <FormItem
                label="Full Name"
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your full name!',
                  },
                ]}>
                <InputComp placeholder="Enter your name" />
              </FormItem>
            </Col>
          </Row>
          <FormItem
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Please enter your last name!',
              },
            ]}>
            <InputComp placeholder="Enter your name" />
          </FormItem>
          <B type="b-default-semibold" pb="12px" pt="12px">
            User InformationOrganization Information
          </B>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem
                label="Organization Name"
                name="organizationName"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your organization name!',
                  },
                ]}>
                <InputComp placeholder="Enter your organization name" />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem
                label="Organization Email"
                name="organizationEmail"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your email!',
                  },
                  {
                    type: 'email',
                    message: 'Please enter valid email',
                  },
                ]}>
                <InputComp placeholder="Enter your email" />
              </FormItem>
            </Col>
          </Row>
          <FormItem
            label="Address"
            name="organizationAddress"
            rules={[
              {
                required: true,
                message: 'Please enter your address!',
              },
            ]}>
            <InputComp placeholder="Enter your address" />
          </FormItem>
          <Row gutter={32}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem
                label="Telephone"
                name="organizationTelephoneNumber"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your telephone!',
                  },
                  {
                    pattern: Validation.MobileNumberPattern,
                    message: 'The input is not valid telephone number!',
                  },
                ]}>
                <InputComp placeholder="Enter your telephone" />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem
                label="Industry"
                name="industry"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your industry!',
                  },
                ]}>
                <InputComp placeholder="Enter your industry" />
              </FormItem>
            </Col>
          </Row>
        </Column>
        <FooterComponent
          leftChildren={
            <SecondaryButton
              onClick={() => {
                navigate('/super-admin');
              }}>
              Cancel
            </SecondaryButton>
          }
          rightChildren={
            <PrimaryButton
              ml={16}
              htmlType="submit"
              loading={isPutOwnerLoading}
              disabled={isPutOwnerLoading}>
              Create Organization
            </PrimaryButton>
          }
        />
      </FormContainer>
    </DefaultBody>
  );
};

export default CreateNewOrganization;
