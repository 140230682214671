import React, { FC, useEffect, useState } from 'react';

import { Form, Row } from 'antd';
import Dropzone from 'react-dropzone';

import { B, InputComp, ModalComponent } from '../../../components';
import theme from '../../../theme';
import { LoanDocumentI } from '../../../types/loan.types';
import styled from '@emotion/styled';
import { UploadDocumentSVG } from '../../../assets';

const CustomRow = styled(Row)`
  background: ${theme.gray150};
  border: 1px dashed ${theme.blue500};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 55px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

interface Props {
  visible: boolean;
  handleCancel: () => void;
  onSubmit: (document: LoanDocumentI) => void;
}

export const GratuityDocumentUploadModal: FC<Props> = ({
  visible,
  handleCancel,
  onSubmit,
}) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [isName, setIsName] = useState('');
  const [isFile, setIsFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    form.setFieldsValue({ title: isName || null });
  }, [isName]);

  const onFinish = (formValues: { title: string }) => {
    if (selectedFile) {
      onSubmit({
        name: formValues.title,
        document: selectedFile,
      });
    }
  };

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      const fileName = file.name.slice(0, file.name.lastIndexOf('.'));
      setIsName(fileName);
      setIsFile(true);
    }
  };
  return (
    <>
      <ModalComponent
        form={form}
        submitText="Upload"
        disabled={!isFile}
        visible={visible}
        title={
          <div
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => setDisabled(true)}>
            <B type="b-large-semibold">Upload your document or file</B>
          </div>
        }
        onCancel={handleCancel}>
        <Form form={form} onFinish={onFinish} validateTrigger="onBlur">
          <Form.Item>
            <Dropzone
              onDrop={handleDrop}
              accept=".pdf"
              minSize={1024}
              maxSize={3072000}>
              {({
                getRootProps,
                getInputProps,
                isDragAccept,
                isDragReject,
              }) => {
                const additionalClass = isDragAccept
                  ? 'accept'
                  : isDragReject
                  ? 'reject'
                  : '';

                return (
                  <CustomRow
                    justify="space-around"
                    align="middle"
                    {...getRootProps({
                      className: `dropzone ${additionalClass}`,
                    })}>
                    <input {...getInputProps()} />
                    <div className="ant-upload-drag-icon">
                      <img src={UploadDocumentSVG} />
                    </div>
                    <B
                      type="b-large-semibold"
                      color={theme.gray600}
                      pt="8px"
                      display="flex">
                      Drag and drop or
                      <B type="b-large-semibold" color={theme.blue400} pl="5px">
                        Browse
                      </B>
                    </B>
                    <B type="b-default" color={theme.gray600} pt="4px">
                      Upload your document
                    </B>
                  </CustomRow>
                );
              }}
            </Dropzone>
          </Form.Item>
          <Form.Item
            name="title"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Please enter file name!',
              },
            ]}>
            <InputComp
              size="small"
              onChange={(e) => setIsName(e.target.value)}
              placeholder="Name your document or file"
            />
          </Form.Item>
        </Form>
      </ModalComponent>
    </>
  );
};
