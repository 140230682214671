import React, { FC, useEffect, useState } from 'react';
import { B, Image } from '../../../components';
import { IntegrationItem } from '../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer';
import { ModalComponent } from '../../../components/ModalComponent';
import { Div } from '../../../components/Div';
import styled from '@emotion/styled';
import { LogoSVG, SwapSVG } from '../../../assets';

const DescriptionSection = styled.div`
  li {
    margin-left: 32px;
    list-style-type: disc;
  }
`;

interface Props {
  item: IntegrationItem;
  onClose: () => void;
  onSubmit: () => void;
}

const IntegrationAuthorizeModal: FC<Props> = ({ item, onClose, onSubmit }) => {
  const [visible, setVisible] = useState(true);
  const loadingIntegrationsOAuth = useSelector(
    (state: RootState) => state.integration.loadingIntegrationsOAuth,
  );
  useEffect(() => {
    if (!visible) {
      onClose();
    }
  }, [visible]);

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <ModalComponent
      onSubmit={() => {
        onSubmit();
      }}
      submitText={`Authorize ${item.title}`}
      loading={loadingIntegrationsOAuth}
      width={540}
      visible={visible}
      onCancel={handleCancel}
      title={<B type="b-large-semibold">Authorize Permission</B>}>
      <Div mb="32px">
        {' '}
        <img src={LogoSVG} alt="logo-img" />
        <Image src={SwapSVG} marginRight="9px" alt="swap-img" />
        <img
          src={item.logo}
          style={{ height: 62, backgroundColor: 'transparent' }}
          alt="item-logo-img"
        />
      </Div>
      <DescriptionSection
        dangerouslySetInnerHTML={{ __html: item.description }}
      />
    </ModalComponent>
  );
};

export default IntegrationAuthorizeModal;
