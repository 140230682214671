export const globalAlertStyles = `
    .ant-message-notice-content{
        padding: 0px !important;
        background: transparent !important;
        border-radius: 5px;
    }
    .ant-message-success{
        background: #E8F5E9;
        border: 0.5px solid #2E7D32;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px 11px;
    }
    .ant-message-error{
        background: #FFEBEE;
        border: 0.5px solid #F44336;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px 11px;
    }
    .ant-message-info{
        background: #EBF1FD;
        border: 0.5px solid #0052EA;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px 11px;
    }
    .ant-message-warning{
        background: #FFF7DA;
        border: 0.5px solid #EB9400;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px 11px;
    }`;
