import { Input } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { DataExtractorCSVEnum } from '../store/actions';
import { message } from 'antd';
import styled from '@emotion/styled';
import theme from '../theme';
import { UploadIconSVG } from '../assets';
import { B } from './B';
import { Div } from './Div';

interface Props {
  onFileChanged: (file: File | null) => void;
  csvType?: DataExtractorCSVEnum;
}
const Image = styled.img`
  padding-right: 3px;
  padding-top: 5px;
`;
const FileUploadInput = styled.div`
  .upload-group {
    display: flex;
    flex-direction: row;
    .file-name {
      height: 40px;
      background: ${theme.white};
      border: 1px solid ${theme.gray400};
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      border-radius: 4px 0px 0px 4px;
      &:hover {
        border: 1px solid ${theme.blue500};
        box-sizing: border-box;
        filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
      }
    }
    .upload-btn span {
      line-height: 40px;
      display: inline-block;
      height: 40px;
      padding: 0 10px;
      background: ${theme.gray50};
      border: 1px solid ${theme.gray400};
      box-sizing: border-box;
      width: 121px;
      border-left: none;
      border-radius: 0px 4px 4px 0px;
      &:hover {
        border-left: 1px solid ${theme.blue600};
        background-color: ${theme.lightBlue50};
        border-color: ${theme.blue600};
      }
    }
  }
  .upload-group input[type='file'] {
    display: none;
    border-radius: 4px;
  }
`;
export const FileUploadComponent: FC<Props> = ({ onFileChanged, csvType }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files && event.target.files.length) {
      const fileSize = event.target.files[0].size / 1000;

      if (fileSize > 2000) {
        message.error('File size is larger than 2 MB');
        setSelectedFile(null);
        onFileChanged(null);
      } else {
        const file = event.target.files[0];
        setSelectedFile(file);
        onFileChanged(file);
      }
    }
  };

  useEffect(() => {
    setSelectedFile(null);
  }, [csvType]);

  return (
    <>
      <FileUploadInput>
        <div className="upload-group">
          <Input
            type="file"
            accept=".csv"
            id="file-upload-input"
            onChange={(e) => {
              handleUpload(e);
            }}
          />
          <Input
            className="file-name"
            type="text"
            accept=".csv"
            value={selectedFile ? selectedFile.name : ''}
            placeholder="No file selected"
          />
          <label className="upload-btn" htmlFor="file-upload-input">
            <span>
              <Div display="flex">
                <Image src={UploadIconSVG} />
                <B type="b-default" mt="8px">
                  Select File
                </B>
              </Div>
            </span>
          </label>
        </div>
      </FileUploadInput>
    </>
  );
};
