import React, { FC } from 'react';
import { Menu, Row, Col, Button, Image, Dropdown } from 'antd';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { B, CustomIcon } from '../../../components';
import theme from '../../../theme';
import { DispatchType, RootState } from '../../../store/reducer';
import { getCurrentAuthPayload } from '../../../util/storageUtil';
import { switchAuthPayload } from '../../../store/actions';
import { getPathWithOrgId } from '../../../util/getPathWithOrgId';
import { addDotsForLongText } from '../../../util/utils';
import { SubscriptionEnum } from '../../../types/subscription.types';
import { UpDownArrow } from '../../../assets';
interface Props {
  currentID?: number;
}
const DropdownButton = styled(Dropdown)`
  margin-right: 16px;
`;

const CommonBox = styled(Button)`
  border: 1px solid ${theme.gray300};
  width: 249px;
  height: 40px;
  background: ${theme.gray50};
  border-radius: 4px;
  text-align: left;
  .ant-image {
    position: absolute;
    margin-right: 11px;
    right: 0;
  }
  &:hover {
    border: 1px solid ${theme.blue500};
    background: ${theme.lightBlue50};
    color: ${theme.black};
  }
  &:focus {
    border: 1px solid ${theme.blue500};
    background: ${theme.lightBlue50};
    color: ${theme.black};
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const MenuCustom = styled(Menu)`
  padding-top: 8px;
  border-radius: 0px;
  height: 500px;
  overflow: auto;
  border: 1px solid ${theme.gray300};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  :where(.css-1drr2mu).ant-dropdown .ant-dropdown-menu,
  :where(.css-1drr2mu).ant-dropdown-menu-submenu .ant-dropdown-menu {
    max-height: 400px;
    overflow: auto;
  }
  .ant-dropdown-menu-item {
    padding-left: 16px;
    margin-top: 8px;
    border-radius: 0px;
    border-top: 1px solid ${theme.gray300};
  }
`;

const CustomCol = styled(Col)`
  margin: 12px 0px 0px 74px;
`;

const OrganizationDropdown: FC<Props> = ({ currentID }) => {
  const currentAuthPayload = getCurrentAuthPayload();
  const authTokenPayloads = useSelector(
    (state: RootState) => state.auth.authTokenPayloads,
  );
  const dispatch: DispatchType = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const me = useSelector((state: RootState) => state.people.me);
  const menu = (
    <MenuCustom>
      <B type="b-small" color={theme.gray600} pl="16px" my="5px">
        Organization
      </B>
      {authTokenPayloads.map((item, index) => {
        return (
          <Menu.Item
            key={item.organizationId}
            onClick={() => {
              navigate(getPathWithOrgId('/', item.organizationId));
              dispatch(switchAuthPayload(index));
              queryClient.clear();
            }}>
            <Row>
              <Col>
                <B
                  type="b-default"
                  cssStyle={`color:${
                    currentID == index ? theme.blue500 : theme.black
                  }`}>
                  {item.profileDisplayName
                    ? item.profileDisplayName
                    : item.organizationName}
                </B>
                <B type="b-small" color={theme.gray600}>
                  {item.role.role}
                </B>
              </Col>
              {currentID == index ? (
                <CustomCol>
                  <CustomIcon icon="blue-correct.svg" />
                </CustomCol>
              ) : null}
            </Row>
          </Menu.Item>
        );
      })}
    </MenuCustom>
  );

  if (!currentAuthPayload) {
    return null;
  }

  return (
    <DropdownButton
      placement="bottomLeft"
      overlay={menu}
      trigger={['click']}
      disabled={me?.subscriptionStatus === SubscriptionEnum.SUSPENDED}>
      <CommonBox>
        {addDotsForLongText(
          currentAuthPayload.profileDisplayName
            ? currentAuthPayload.profileDisplayName
            : currentAuthPayload.organizationName,
          25,
        )}
        <Image preview={false} src={UpDownArrow} />
      </CommonBox>
    </DropdownButton>
  );
};

export default OrganizationDropdown;
