import React, { FC, useEffect, useState } from 'react';

import { Row } from 'antd';
import { cloneDeep, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Div, SecondaryButton } from '../../../../components';
import {
  EmpPayrollI,
  LeaveInformationI,
  putLeaveInformation,
} from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import { LeaveBalance } from '../../../../types';
import { capitalizeUnderscore } from '../../../../util/utils';
import LeaveType from './LeaveType';

interface LeaveInformationProps {
  isUsingSystemLeaves?: boolean;
  leaveBalanceMe: LeaveBalance[] | null;
  isCompletedPayroll: boolean;
}
type payrollParam = {
  payrollKey: string;
  employeeKey: string;
};

const LeaveInformation: FC<LeaveInformationProps> = ({
  isUsingSystemLeaves,
  isCompletedPayroll,
  leaveBalanceMe,
}) => {
  const params = useParams<payrollParam>();
  const dispatch: DispatchType = useDispatch();
  const [leaveInformation, setLeaveInformation] = useState<LeaveInformationI>(
    {} as LeaveInformationI,
  );
  const [systemLeaveInformation, setSystemLeaveInformation] = useState<{
    [key: string]: string;
  }>({});
  const [
    userPayrollDetails,
    setUserPayrollDetails,
  ] = useState<EmpPayrollI | null>(null);
  const payrollDetails = useSelector(
    (state: RootState) => state.payroll.allPayrollDetails,
  );

  useEffect(() => {
    const userPayrollDetail = payrollDetails?.empPayroll.find(
      (item) => item.userId === Number(params.employeeKey),
    );
    if (userPayrollDetail) {
      setUserPayrollDetails(cloneDeep(userPayrollDetail));
    }
  }, [payrollDetails, params.employeeKey]);

  useEffect(() => {
    const systemLeaveInfo: { [key: string]: string } = {};
    leaveBalanceMe?.map((item) => {
      systemLeaveInfo[item.leaveName] = item.balance.toString();
    });
    setSystemLeaveInformation(systemLeaveInfo);
  }, [leaveBalanceMe]);

  const renderLeaveType = (
    data: { [key: string]: string },
    disabled: boolean,
  ) => {
    return Object.entries(data)
      .reverse()
      .map(([key, value]) => (
        <LeaveType
          onChange={(values) => console.log(values)}
          disabled={disabled}
          name={capitalizeUnderscore(key)}
          amount={value}
        />
      ));
  };

  return (
    <>
      <Div p="0px 16px">
        {!isUsingSystemLeaves && userPayrollDetails ? (
          <>
            <LeaveType
              onChange={(values) =>
                setLeaveInformation({
                  ...leaveInformation,
                  openLeaveBalance: parseFloat(values) ? parseFloat(values) : 0,
                })
              }
              disabled={isCompletedPayroll}
              name="Open Leave Balance"
              amount={get(
                userPayrollDetails,
                'leaveInformation.openLeaveBalance',
                '0',
              ).toString()}
            />
            <LeaveType
              onChange={(values) =>
                setLeaveInformation({
                  ...leaveInformation,
                  averageLeaves: parseFloat(values) ? parseFloat(values) : 0,
                })
              }
              disabled={isCompletedPayroll}
              name="Ava.Leaves"
              amount={get(
                userPayrollDetails,
                'leaveInformation.averageLeaves',
                '0',
              ).toString()}
            />
            <LeaveType
              onChange={(values) => {
                setLeaveInformation({
                  ...leaveInformation,
                  otHours: parseFloat(values) ? parseFloat(values) : 0,
                });
              }}
              disabled={isCompletedPayroll}
              name="OT Hours"
              amount={get(
                userPayrollDetails,
                'leaveInformation.otHours',
                '0',
              ).toString()}
            />
            <LeaveType
              onChange={(values) =>
                setLeaveInformation({
                  ...leaveInformation,
                  otRate: parseFloat(values) ? parseFloat(values) : 0,
                })
              }
              addSuffix={false}
              disabled={isCompletedPayroll}
              name="OT Rate"
              amount={get(
                userPayrollDetails,
                'leaveInformation.otRate',
                '0',
              ).toLocaleString('en-GB', {
                minimumFractionDigits: 2,
              })}
            />
            <LeaveType
              onChange={(values) => {
                setLeaveInformation({
                  ...leaveInformation,
                  sundayOtHours: parseFloat(values) ? parseFloat(values) : 0,
                });
              }}
              disabled={isCompletedPayroll}
              name="Sunday OT Hours"
              amount={get(
                userPayrollDetails,
                'leaveInformation.sundayOtHours',
                '0',
              ).toString()}
            />
            <LeaveType
              onChange={(values) =>
                setLeaveInformation({
                  ...leaveInformation,
                  sundayOtRate: parseFloat(values) ? parseFloat(values) : 0,
                })
              }
              addSuffix={false}
              disabled={isCompletedPayroll}
              name="Sunday OT Rate"
              amount={get(
                userPayrollDetails,
                'leaveInformation.sundayOtRate',
                '0',
              ).toLocaleString('en-GB', {
                minimumFractionDigits: 2,
              })}
            />
            <LeaveType
              onChange={(values) =>
                setLeaveInformation({
                  ...leaveInformation,
                  approvedLeaves: parseFloat(values) ? parseFloat(values) : 0,
                })
              }
              disabled={isCompletedPayroll}
              name="App.Leaves"
              amount={get(
                userPayrollDetails,
                'leaveInformation.approvedLeaves',
                '0',
              ).toString()}
            />
            <LeaveType
              onChange={(values) =>
                setLeaveInformation({
                  ...leaveInformation,
                  noPayDays: parseFloat(values) ? parseFloat(values) : 0,
                })
              }
              addSuffix={false}
              disabled={isCompletedPayroll}
              name="Nopay Days"
              amount={get(
                userPayrollDetails,
                'leaveInformation.noPayDays',
                '0',
              ).toString()}
            />
            <LeaveType
              onChange={(values) =>
                setLeaveInformation({
                  ...leaveInformation,
                  noPayRate: parseFloat(values) ? parseFloat(values) : 0,
                })
              }
              addSuffix={false}
              disabled={isCompletedPayroll}
              name="Nopay Rate"
              amount={get(
                userPayrollDetails,
                'leaveInformation.noPayRate',
                '0',
              ).toLocaleString('en-GB', {
                minimumFractionDigits: 2,
              })}
            />
          </>
        ) : (
          renderLeaveType(systemLeaveInformation, true)
        )}
        {!isCompletedPayroll && !isUsingSystemLeaves && (
          <Row justify="end">
            <SecondaryButton
              size="small"
              onClick={() =>
                dispatch(
                  putLeaveInformation({
                    empPayrollId: userPayrollDetails?.id,
                    payrollId: Number(params.payrollKey),
                    ...leaveInformation,
                  }),
                )
              }>
              Update
            </SecondaryButton>
          </Row>
        )}
      </Div>
    </>
  );
};

export default LeaveInformation;
