import React, { FC } from 'react';
import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import theme from '../../theme';
import styled from '@emotion/styled';

type Props = DatePickerProps & {
  flexDirection?: string;
};

const DatePickerStyle = styled(DatePicker)<Props>`
  @media screen and (max-width: 768px) {
          ${(props) => props.flexDirection === 'row' && 'width: 100%;'}
        }
        width: ${(props) =>
          props.flexDirection === 'row' ? '344px;' : '100%;'}
        border: 1px solid ${theme.gray300};
        box-sizing: border-box;
        padding-left: 16px;
        border-radius: 4px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;

        height: 32px;
        input {
          font-size: 14px !important;
          line-height: 20px !important;
        }
        input:placeholder-shown {
          font-size: 14px;
          line-height: 20px;
          color: ${theme.gray600} !important;
        }

        &:focus {
          border: 1px solid ${theme.blue500};
          box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1);
        }

        &:hover {
          border: 1px solid ${theme.blue500};
          box-sizing: border-box;
          filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
          border-radius: 4px;
        }
`;

export const DatePickerV2: FC<Props> = (props) => {
  const { size = 'large', flexDirection = 'column', ...rest } = props;

  return (
    <DatePickerStyle {...rest} size={size} flexDirection={flexDirection} />
  );
};
