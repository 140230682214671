import { useEffect, useState } from 'react';

import {
  isBrowser as isDesktopDevice,
  isMobile as isMobileDevice,
  isTablet as isTabletDevice,
} from 'react-device-detect';
import theme from '../theme';

export function useWindowSize(): {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isDesktopLarge: boolean;
} {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const getValues = () => {
    return {
      isMobile: isMobileDevice || window.innerWidth < theme.MOBILE_MAX_WIDTH,
      isTablet:
        isTabletDevice ||
        (window.innerWidth > theme.MOBILE_MAX_WIDTH &&
          window.innerWidth < theme.TABLET_MAX_WIDTH),
      isDesktop:
        isDesktopDevice ||
        (window.innerWidth > theme.TABLET_MAX_WIDTH &&
          window.innerWidth < theme.LAPTOP_MAX_WIDTH),
      isDesktopLarge:
        window.innerWidth > theme.LAPTOP_MAX_WIDTH &&
        window.innerWidth < theme.DESKTOP_MAX_WIDTH,
    };
  };
  const [windowSize, setWindowSize] = useState(() => getValues());

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      const newValue = getValues();
      setWindowSize((value) => {
        if (newValue === value) {
          return value;
        }
        return newValue;
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
