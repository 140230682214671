import { useEffect, useState } from 'react';
import { AssignableUsersI } from '../../../types/contract.types';

interface ReturnValue {
  firstSignatory: AssignableUsersI | null;
  secondSignatory: AssignableUsersI | null;
}
export const useGetAssignedUsers = (
  assignedUsers: AssignableUsersI[],
): ReturnValue => {
  const [state, setState] = useState<ReturnValue>({
    firstSignatory: null,
    secondSignatory: null,
  });

  useEffect(() => {
    let firstSignatory: AssignableUsersI | null = null;
    let secondSignatory: AssignableUsersI | null = null;

    assignedUsers.forEach((user) => {
      if (user.isFirstSignatory) {
        firstSignatory = user;
      } else if (user.isSecondSignatory) {
        secondSignatory = user;
      }
    });

    setState({ firstSignatory, secondSignatory });
  }, [assignedUsers]);

  return state;
};
