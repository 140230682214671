import React, { FC, useEffect, useState } from 'react';
import { Col, Dropdown, Menu, Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { MoreOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import {
  B,
  DeleteModal,
  Div,
  PrimaryButton,
  SelectComp,
} from '../../../components';
import TableComp from '../../../components/TableComp';
import {
  addNewHoliday,
  deleteHoliday,
  editNewHoliday,
  getHolidays,
} from '../../../store/actions';

import { DispatchType, RootState } from '../../../store/reducer';

import { useWindowSize } from '../../../util/screenSize';
import { AddHolidayModal } from './AddHolidayModal';
import { EditHolidayModal } from './EditHolidayModal';
import { SwitchComponent } from '../../../components/SwitchComponent';
import theme from '../../../theme';
import { tableSorter } from '../../../util/tableSorter';
import { PlusWhiteSVG } from '../../../assets';
import {
  HolidayI,
  OrganizationHolidayI,
} from '../../../types/organization.types';

const SwitchColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

const HolidayFilter = styled(SelectComp)`
  width: 140px;
  text-align: left;
  z-index: 1 !important;
`;
const MenuComp = styled(Menu)`
  width: 149px;
  align-items: left;
`;
const PublicHoliday: FC = () => {
  const { isMobile } = useWindowSize();
  const dispatch: DispatchType = useDispatch();
  const [visibleHolidayModal, setVisibleHolidayModal] = useState(false);
  const [visibleEditHolidayModal, setVisibleEditHolidayModal] = useState(false);
  const [editItem, setEditItem] = useState<OrganizationHolidayI | null>(null);
  const [holidayYear, setHolidayYear] = useState<number>(moment().year());
  const [filteredDataSource, setFilteredDataSource] = useState<
    | {
        key: string;
        id: number;
        date: moment.Moment;
        weekDay: string | null;
        description: string | null;
        note: string | null;
        isDefault: boolean;
        isHoliday: boolean;
        organizationId: number;
        updatedAt: string | null;
        createdAt: string | null;
      }[]
    | undefined
  >([]);

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const holidays = organizationData?.organizationHoliday;

  useEffect(() => {
    dispatch(getHolidays());
  }, []);

  function isHolidayChecker(values: HolidayI) {
    dispatch(editNewHoliday(values));
  }
  const newAllHolidays = holidays?.map((holiday, i) => ({
    key: `${i}`,
    id: holiday.id,
    date: moment(holiday.date),
    weekDay: holiday.weekDay,
    description: holiday.description,
    note: holiday.note,
    isDefault: holiday.isDefault,
    isHoliday: holiday.isHoliday,
    organizationId: holiday.organizationId,
    updatedAt: holiday.updatedAt,
    createdAt: holiday.createdAt,
  }));

  useEffect(() => {
    const list:
      | {
          key: string;
          id: number;
          date: moment.Moment;
          weekDay: string | null;
          description: string | null;
          note: string | null;
          isDefault: boolean;
          isHoliday: boolean;
          organizationId: number;
          updatedAt: string | null;
          createdAt: string | null;
        }[]
      | undefined = [];

    newAllHolidays?.forEach((i) => {
      if (moment(i.date).year() === holidayYear) {
        const loanTable = {
          ...i,
        };
        list.push(loanTable);
      }
    });
    setFilteredDataSource(list);
  }, [holidays, holidayYear]);

  const columns: ColumnProps<OrganizationHolidayI>[] = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (data) => moment(data).format('MMMM Do'),
      width: 150,
      sorter: (a, b) => tableSorter.defaultSort(a.date, b.date),
      sortDirections: ['ascend', 'descend'],
      key: 'date',
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Weekday',
      dataIndex: 'weekDay',
    },
    {
      title: 'Name',
      dataIndex: 'description',
    },
    {
      title: 'Note',
      width: 150,
      dataIndex: 'note',
    },
    {
      title: '',
      dataIndex: '',
      render: (_e, data) => (
        <>
          <SwitchColumn>
            {data.isHoliday ? (
              <SwitchComponent
                size="small"
                defaultChecked={true}
                onChange={(e) => {
                  const values = {
                    id: String(data.id),
                    isHoliday: e,
                  };
                  isHolidayChecker(values);
                }}
              />
            ) : (
              <SwitchComponent
                size="small"
                defaultChecked={false}
                onChange={(e) =>
                  isHolidayChecker({ id: String(data.id), isHoliday: e })
                }
              />
            )}
          </SwitchColumn>
        </>
      ),
    },
    {
      title: '',
      dataIndex: '',
      render: (_value, record) => (
        <>
          {record.isDefault ? null : (
            <Dropdown
              overlay={
                <MenuComp>
                  <Menu.Item
                    onClick={() => {
                      setEditItem(record);
                      setVisibleEditHolidayModal(true);
                    }}>
                    Edit
                  </Menu.Item>

                  <Menu.Item>
                    <DeleteModal
                      title="Delete"
                      buttonLabel="Delete"
                      content="Are you sure you want to delete holiday?"
                      onClickCancel={() =>
                        dispatch(deleteHoliday(String(record.id)))
                      }
                      openModal={<B type="b-default">Delete</B>}></DeleteModal>
                  </Menu.Item>
                </MenuComp>
              }>
              <a>
                <MoreOutlined style={{ fontSize: '24px', color: '#000000' }} />
              </a>
            </Dropdown>
          )}
        </>
      ),
    },
  ];

  return (
    <Div p={isMobile ? '24px 10px' : '32px'}>
      <Div mb="16px">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <B type="b-large-semibold">Public Holidays</B>
            <B type="b-default" color={theme.black}>
              {filteredDataSource ? filteredDataSource.length : 0} Holidays
            </B>
          </Col>
          <Col
            style={{
              textAlign: 'right',
            }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}>
            <Div display="flex" justifyContent="flex-end">
              <HolidayFilter
                size="middle"
                placeholder="Select Year"
                options={[
                  {
                    label: moment().subtract(1, 'year').year(),
                    value: moment().subtract(1, 'year').year(),
                  },
                  {
                    label: moment().year(),
                    value: moment().year(),
                  },
                  {
                    label: moment().add(1, 'year').year(),
                    value: moment().add(1, 'year').year(),
                  },
                ]}
                onChange={(v) => setHolidayYear(Number(v))}
                defaultValue={holidayYear}
              />
              <PrimaryButton
                size="small"
                ml={16}
                onClick={() => setVisibleHolidayModal(true)}
                icon={<img src={PlusWhiteSVG} />}>
                Add Holiday
              </PrimaryButton>
            </Div>
          </Col>
        </Row>
      </Div>
      <TableComp
        size="middle"
        rowKey={(item) => {
          return String(item.id);
        }}
        scroll={{
          x: 800,
        }}
        columns={columns}
        dataSource={filteredDataSource}
        pagination={false}
        withBorders={true}
      />
      {visibleHolidayModal ? (
        <AddHolidayModal
          visible={visibleHolidayModal}
          onCancel={() => {
            setVisibleHolidayModal(false);
          }}
          onSubmit={(values) => {
            dispatch(addNewHoliday(values));
            setVisibleHolidayModal(false);
          }}
        />
      ) : null}
      {visibleEditHolidayModal ? (
        <EditHolidayModal
          visible={visibleEditHolidayModal}
          holidayDetails={editItem}
          onCancel={() => {
            setVisibleEditHolidayModal(false);
            setEditItem(null);
          }}
          onSubmit={(values) => {
            if (editItem !== null) {
              dispatch(editNewHoliday(values));
              setVisibleEditHolidayModal(false);
              setEditItem(null);
            }
          }}
        />
      ) : null}
    </Div>
  );
};
export default PublicHoliday;
