import React, { FC, useEffect, useState } from 'react';
import { Div, B, InputComp, SecondaryButton } from '../../../../components';
import theme from '../../../../theme';
import { useParams } from 'react-router-dom';
import { EmpPayrollI, SalaryInformationI } from '../../../../store/actions';
import { calculateNoOfDaysOFSelectedMonth } from './utils/calculateNoOfDaysOFSelectedMonth';
import { MontlyWorkingDaysSettings } from './enums/montlyWorkingDaysSettings.enum';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import styled from '@emotion/styled';
import { ProrataSettingsI } from '../../../../types/organization.types';

const FormItems = styled(Form.Item)`
  margin-bottom: 0px;
`;

interface ValuesI {
  workedDays: string;
}
interface Props {
  submitWorkedDays: (v: ValuesI) => void;
  monthlyWorkingDaysSetting: string;
  monthlyWorkingDays: number;
  payrollDetails: SalaryInformationI | null;
  variableMonthlyWorkingDays?: number;
  status?: 'DRAFTED' | 'COMPLETED';
  updatedWorkedDays: number | null;
  prorataSettings: ProrataSettingsI;
}

type RouteParams = {
  employeeKey: string;
  payrollKey: string;
};

export const WorkedDaysForm: FC<Props> = ({
  updatedWorkedDays,
  submitWorkedDays,
  monthlyWorkingDaysSetting,
  monthlyWorkingDays,
  payrollDetails,
  variableMonthlyWorkingDays,
  status,
  prorataSettings,
}) => {
  const params = useParams<RouteParams>();
  const [isError, setIsError] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [workedDays, setWorkedDays] = useState<number>(0);

  const [workedDaysForm] = useForm();

  let workingDays: number | undefined;

  let emp: EmpPayrollI | undefined;
  let workedDaysVal = 0;

  useEffect(() => {
    let workedDaysVal = 0;

    if (payrollDetails && payrollDetails.id && payrollDetails.empPayroll) {
      emp = payrollDetails.empPayroll.find(
        (item) => item.userId === Number(params.employeeKey),
      );

      if (emp && emp.workedDays) {
        workedDaysVal = emp.workedDays;
      } else if (!emp?.isProRata) {
        if (
          monthlyWorkingDaysSetting === MontlyWorkingDaysSettings.VARIABLE &&
          variableMonthlyWorkingDays
        ) {
          workedDaysVal = variableMonthlyWorkingDays;
        } else if (
          monthlyWorkingDaysSetting === MontlyWorkingDaysSettings.CALENDAR &&
          payrollDetails &&
          payrollDetails.payrollStartDate
        ) {
          workingDays = calculateNoOfDaysOFSelectedMonth(
            payrollDetails.payrollStartDate,
            payrollDetails?.month,
          );

          workedDaysVal = workingDays;
        } else {
          if (monthlyWorkingDays) {
            workedDaysVal = monthlyWorkingDays;
          }
        }
      }

      workedDaysForm.setFieldsValue({
        workedDays: workedDaysVal,
      });
    }
  }, []);

  useEffect(() => {
    workedDaysVal = 0;
    if (payrollDetails && payrollDetails.id) {
      emp = payrollDetails?.empPayroll.find(
        (item) => item.userId === Number(params.employeeKey),
      );

      if (emp && emp.workedDays) {
        workedDaysVal = emp.workedDays;
      } else if (!emp?.isProRata && !emp?.workedDays) {
        if (
          monthlyWorkingDaysSetting === MontlyWorkingDaysSettings.VARIABLE &&
          variableMonthlyWorkingDays
        ) {
          workedDaysVal = variableMonthlyWorkingDays;
        } else if (
          monthlyWorkingDaysSetting === MontlyWorkingDaysSettings.CALENDAR &&
          payrollDetails &&
          payrollDetails.payrollStartDate
        ) {
          workingDays = calculateNoOfDaysOFSelectedMonth(
            payrollDetails.payrollStartDate,
            payrollDetails?.month,
          );
          workedDaysVal = workingDays;
        } else {
          if (monthlyWorkingDays) {
            workedDaysVal = monthlyWorkingDays;
          }
        }
      }

      workedDaysForm.setFieldsValue({
        workedDays: workedDaysVal,
      });
    }
  }, [params.employeeKey]);

  useEffect(() => {
    if (updatedWorkedDays) {
      workedDaysForm.setFieldsValue({ workedDays: updatedWorkedDays });
    }
  }, [updatedWorkedDays]);

  if (monthlyWorkingDaysSetting === MontlyWorkingDaysSettings.VARIABLE) {
    workingDays = variableMonthlyWorkingDays;
  } else if (
    monthlyWorkingDaysSetting === MontlyWorkingDaysSettings.CALENDAR &&
    payrollDetails &&
    payrollDetails.payrollStartDate
  ) {
    workingDays = calculateNoOfDaysOFSelectedMonth(
      payrollDetails.payrollStartDate,
      payrollDetails?.month,
    );
  } else {
    workingDays = monthlyWorkingDays;
  }

  useEffect(() => {
    setIsError(workingDays && workedDays > workingDays ? true : false);
    const val = workedDaysForm.getFieldValue('workedDays');
    if (updatedWorkedDays) {
      setDisableButton(val === updatedWorkedDays);
    } else {
      setDisableButton(val === workedDaysVal);
    }
    if (val === 0) {
      setDisableButton(true);
    }
  }, [workedDays]);

  return (
    <Div
      border={`1px solid ${theme.gray300}`}
      background={theme.warn}
      p="16px"
      mb={24}
      borderColor={theme.red50}
      borderRadius="4px">
      {monthlyWorkingDaysSetting !== MontlyWorkingDaysSettings.CALENDAR &&
        !updatedWorkedDays &&
        prorataSettings.prorata && (
          <B type="b-small" cssStyle="margin-bottom: 16px; font-weight: 500;">
            Please enter the work days of this employee for prorata calculation
          </B>
        )}

      <B type="b-small" cssStyle="font-weight: 500; margin-bottom: 4px;">
        No of Worked Days
      </B>
      <Form form={workedDaysForm} onFinish={submitWorkedDays}>
        <Div display="flex" justifyContent="space-between">
          <FormItems name="workedDays">
            <InputComp
              size="small"
              style={{ minWidth: '216px', width: '237px' }}
              placeholder="Enter no of worked days"
              onChange={(e) => setWorkedDays(Number(e.target.value))}
              disabled={status === 'DRAFTED' ? false : true}
              isError={isError}
              errorMessage={`No.of working days per this month is ${workingDays} days!`}
            />
          </FormItems>
          <FormItems>
            <SecondaryButton
              size="small"
              htmlType="submit"
              disabled={
                isError || disableButton
                  ? true
                  : status === 'DRAFTED'
                  ? false
                  : true
              }>
              Submit
            </SecondaryButton>
          </FormItems>
        </Div>
      </Form>
    </Div>
  );
};
