import React, { FC, useEffect, useState } from 'react';

import { Col, Form, message, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import {
  border,
  BorderProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';
import styled from '@emotion/styled';
import { B, Div, LinkButton, SwitchComponent } from '../../../components';
import theme from '../../../theme';
import CalculationSettings from './CalculationSettings';
import OverTimeTypeList from './OverTimeTypeList';
import {
  otPayItemType,
  OtSettingsI,
  OTTypesI,
} from '../../../types/organization.types';
import AddOverTime from './AddOverTime';
import {
  otPayItemTypeSelection,
  otStatutoryItemSelection,
  PayrollItemTypeEnum,
} from '../../../types/payroll.types';
import { v4 as uuidv4 } from 'uuid';
import { Divider } from '../../../components/Divider';
import { PlusBlueSVG } from '../../../assets';
import StatutoryCalculationSettings from './StatutoryCalculationSettings ';

const MainDiv = styled.div`
  padding: 32px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  @media (max-width: 768px) {
    padding: 24px 10px;
    width: 100%;
  }
`;
const FormItem = styled(Form.Item)<SpaceProps & LayoutProps>`
  margin-bottom: 0px;
  ${space}
  ${layout}
`;

const OTList = styled.div<SpaceProps & BorderProps>`
  width: 580px;
  border-radius: 4px;
  div:last-child {
    border-bottom: none;
  }
  ${space}
  ${border}
`;
interface Props {
  form: FormInstance<any>;
  otSettings?: OtSettingsI | null;
  otPayItemType?: otPayItemType | null;
  overTimeList?: OTTypesI[];
  otList: OTTypesI[];
  setOtList: (v: OTTypesI[]) => void;
}

const OverTime: FC<Props> = ({
  form,
  otSettings,
  otPayItemType,
  overTimeList,
  setOtList,
  otList,
}) => {
  const [isAddOverTime, setIsOverTime] = useState<boolean>(false);
  const [addOT, setAddOT] = useState<boolean>();
  const [otText, setOTText] = useState<string>();
  const [otRate, setOTRate] = useState<number>(1);
  const [otIsEnable, setOTIsEnable] = useState<boolean>(false);
  const [isCheckPayItemValidation, setCheckPayItemValidation] =
    useState<boolean>();
  const [isCheckOTValidation, setCheckOTValidation] = useState<boolean>();
  const [checkPayItem, setCheckPayItem] = useState<{
    OT_BASIC: boolean | undefined;
    OT_FIXED_ALLOWANCE: boolean | undefined;
    OT_VARIABLE_ALLOWANCE: boolean | undefined;
  }>({
    OT_BASIC: false,
    OT_FIXED_ALLOWANCE: false,
    OT_VARIABLE_ALLOWANCE: false,
  });

  useEffect(() => {
    setCheckPayItemValidation(Object.values(checkPayItem).some((item) => item));
    setCheckOTValidation(Object.values(otList).some((item) => item.isEnable));
  }, [checkPayItem, otList]);

  useEffect(() => {
    if (otPayItemType) {
      setCheckPayItem({
        OT_BASIC: otPayItemType?.BASIC,
        OT_FIXED_ALLOWANCE: otPayItemType?.FIXED_ALLOWANCE,
        OT_VARIABLE_ALLOWANCE: otPayItemType?.VARIABLE_ALLOWANCE,
      });
    }
  }, [otPayItemType]);

  useEffect(() => {
    if (overTimeList) {
      setOtList([...overTimeList]);
    }
  }, [overTimeList]);

  useEffect(() => {
    if (otSettings && otPayItemType) {
      setIsOverTime(otSettings.ot.status);
      form.setFieldsValue({
        ot: !!otSettings.ot.status,
        OT_BASIC: !!otPayItemType.BASIC,
        OT_FIXED_ALLOWANCE: !!otPayItemType.FIXED_ALLOWANCE,
        OT_VARIABLE_ALLOWANCE: !!otPayItemType.VARIABLE_ALLOWANCE,
        OT_APIT: otSettings?.ot?.isApit,
        OT_EPF: otSettings?.ot?.isEpf,
      });
    }
  }, [otSettings]);

  return (
    <>
      <MainDiv>
        <B type="b-large-semibold" Pb="16px">
          Over Time
        </B>
        <Row>
          <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
            <Div display="flex">
              <FormItem name="ot" valuePropName="checked">
                <SwitchComponent
                  onChange={(checked: boolean) => setIsOverTime(checked)}
                />
              </FormItem>
              <B type="b-default" color={theme.gray600} pl="10px" pt="5px">
                Over Time
              </B>
            </Div>
          </Col>
          <Col xs={24} sm={24} md={19} lg={19} xl={19} xxl={19}>
            {isAddOverTime && (
              <>
                <B type="b-small" color={theme.gray600}>
                  Overtime rate calculated with the items you have selected
                  below and the No.of working days specified is multiplied by
                  the Overtime days you enter via CSV or manually. Kindly note
                  that you cannot calculate overtime on a mid cycle payrun.
                  Please contact us if you follow a different overtime policy.
                </B>
                <br />
                <B type="b-small" color={theme.gray600}>
                  Please note that when variable allowances are enabled,
                  overtime values will be included in the calculations for no
                  pay and multiple overtime items.
                </B>
              </>
            )}
          </Col>
        </Row>
      </MainDiv>
      {isAddOverTime && (
        <>
          <CalculationSettings
            title="Please select which items you want to include in overtime calculations"
            basicSalary={otPayItemTypeSelection.BASIC}
            fixedAllowance={otPayItemTypeSelection.FIXED_ALLOWANCE}
            variableAllowance={otPayItemTypeSelection.VARIABLE_ALLOWANCE}
            isSelectPayItems={isCheckPayItemValidation}
            payItemName="otPayItem"
            onChange={(v) => {
              switch (v.target.id) {
                case otPayItemTypeSelection.BASIC:
                  setCheckPayItem({
                    ...checkPayItem,
                    OT_BASIC: v.target.checked,
                  });
                  break;
                case otPayItemTypeSelection.FIXED_ALLOWANCE:
                  setCheckPayItem({
                    ...checkPayItem,
                    OT_FIXED_ALLOWANCE: v.target.checked,
                  });
                  break;
                case otPayItemTypeSelection.VARIABLE_ALLOWANCE:
                  setCheckPayItem({
                    ...checkPayItem,
                    OT_VARIABLE_ALLOWANCE: v.target.checked,
                  });
                  break;
                default:
                  break;
              }
            }}
          />

          <StatutoryCalculationSettings
            payItemName="otStatuaryPayItem"
            apit={otStatutoryItemSelection.APIT}
            epf={otStatutoryItemSelection.EPF}
          />

          <Div p="0px 32px 32px">
            <B type="b-default" pb="16px">
              Please select which pay categories should be included in OT rate
              calculations
            </B>
            <OTList
              border={
                otList.length || addOT ? `1px solid ${theme.gray300}` : `none`
              }
              mb={otList.length || addOT ? '10px' : '0px'}>
              {otList && otList.length ? (
                <OverTimeTypeList
                  otList={otList}
                  onRemove={(id) => {
                    const setId = otList.findIndex((list) => list.id === id);
                    otList.splice(setId, 1);
                    setOtList([...otList]);
                  }}
                  onChange={(e) => {
                    setOTText(e.target.value);
                  }}
                  onEdit={(id) => {
                    if (otText) {
                      const setId = otList.findIndex((list) => list.id === id);
                      otList[setId].otName = otText;
                    }
                  }}
                  onChangeRate={(value, id) => {
                    const setId = otList.findIndex((list) => list.id === id);
                    otList[setId].rate = value;
                  }}
                  onChangeCheckBox={(e, id) => {
                    const setId = otList.findIndex((list) => list.id === id);
                    otList[setId].isEnable = e.target.checked;
                    setOtList([...otList]);
                  }}
                />
              ) : null}

              {addOT && (
                <AddOverTime
                  onChange={(e) => setOTText(e.target.value)}
                  onBlur={() => {
                    setAddOT(false);
                    if (otText) {
                      const value: OTTypesI = {
                        id: uuidv4(),
                        otName: otText,
                        rate: otRate,
                        isEnable: otIsEnable,
                      };
                      if (
                        otList
                          .map((i) => i.otName.toLocaleLowerCase())
                          .includes(otText.toLocaleLowerCase())
                      ) {
                        message.error('This Overtime Type already exists!');
                      } else {
                        otList.push(value);
                        setOtList([...otList]);
                        setOTRate(1);
                        setOTIsEnable(false);
                      }
                    }
                  }}
                  onChangeRate={(v) => setOTRate(v)}
                  onRemove={() => setAddOT(false)}
                  onChangeCheckBox={(e) => setOTIsEnable(e.target.checked)}
                />
              )}
            </OTList>
            <FormItem
              name="otList"
              rules={[
                {
                  required: !isCheckOTValidation && true,
                  message: 'Please enable at least one overtime type',
                },
              ]}>
              <LinkButton
                onClick={() => setAddOT(true)}
                padding={0}
                icon={<img src={PlusBlueSVG} />}
                disabled={otList.length > 4 && true}>
                Add Over Time
              </LinkButton>
            </FormItem>
          </Div>
        </>
      )}
      <Divider />
    </>
  );
};

export default OverTime;
