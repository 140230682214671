import axios, { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { SubscriptionI } from '../types/subscription.types';

export const useGetInvoiceList = () => {
  return useQuery<SubscriptionI[], AxiosError>('bank-list', async () => {
    const response: AxiosResponse<SubscriptionI[]> = await axios.get(
      '/users/get-invoice-data',
    );
    return response.data;
  });
};
