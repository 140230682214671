import { EmpPayrollI } from '../store/actions';
import { OrganizationBodyI } from './organization.types';
import { NotificationItemI, NotificationTypeEnum } from './notification.types';
import { BankBranchI, PayDetailI, PayrollItemI } from './payroll.types';
import { RoleI } from './role.types';
import { SubscriptionEnum } from './subscription.types';
import { UserRole, UserRoleIdEnum } from './userRole.types';

export interface UpdateUserSettingsI {
  id: string;
  isArchived: boolean;
  isPayrollAllowed: boolean;
  isLoginAllowed: boolean;
  isPending?: boolean;
  roleId?: UserRoleIdEnum;
  loginEmail?: string;
}

export interface AddEmployeeDetailsI {
  fullName: string | null;
  nameWithInitials: string | null;
  preferredName: string | null;
  nicOrPassport: string | null;
  employeeNumber: string | null;
  joinedDate: string | null;
  occupationGrade: number | null;
  etfNumber: string | null;
  epfNumber: string | null;
  taxNumber: string | null;
  isSecondary: boolean;
  primarySalary: number | null;
  paySlipMail: string | null;
  bankAccountName: string;
  bankAccountNumber: string | null;
  bankBankCode: string | null;
  bankBranchId: string | null;
  isLoginAllowed: boolean;
  loginEmail: string | null;
  personalMobile: string | null;
  dob: string | null;
  emergencyName: string | null;
  emergencyMobileNumber: string | null;
  emergencyDOB: string | null;
  firstName: string | null;
  lastName: string | null;
  roleId?: UserRoleIdEnum;
}
export interface AddEmployeeI extends AddEmployeeDetailsI {
  employeeHistory: AddUserHistoryI | null;
  payItems: PayrollItemI[];
}

export interface EmployeeEmailOnboardingFormI extends AddEmployeeDetailsI {
  token: string;
  sendACopy: boolean;
}

export interface EmployeeEmailOnboardingSendEmailI
  extends EmployeeEmailOnboardingFormI {
  files: File[];
  certCount: number;
}

export interface EmployeeDocumentFilesI {
  curriculamVitae: File;
  nicFrontImg: File;
  nicBackImg: File;
  firstCertificate: File;
  secondCertificate: File;
  firstOptionalCert?: File;
  secondOptionalCert?: File;
}

export interface EmployeeFileUpload extends EmployeeDocumentFilesI {
  sendACopy: boolean;
}

export interface EmployeeEmailFileUploads {
  sendACopy: boolean;
}

export interface AddUserPayItemsSummary {
  payItems: PayrollItemI[];
  user: UserEpfEtf;
}

export interface UserEpfEtf {
  etfNumber?: string | null;
  epfNumber?: string | null;
}

export interface AddUserHistoryI {
  designation: string;
  startDate: string;
  employmentType: string;
}

export interface UserPayItemSummaryI {
  employeeEPF: number;
  employerEPF: number;
  employerETF: number;
  netSalary: number;
  costToCompany: number;
  apitTable1Amount: number;
  grossSalary: number;
  grossDeduction: number;
  withHoldTax?: number;
}

export interface EmployeeI {
  employeeNumber: string | null;
  joinedDate: string | null;
  occupationGrade: number | null;
  etfNumber: string | null;
  epfNumber: string | null;
  taxNumber: string | null;
  subscriptionStatus: boolean;
  primarySalary: number | null;
  designation: string;
  startDate: string;
  employmentType: string;
}

export interface SummaryDataI extends UserPayItemSummaryI {
  savedUser: savedUserI;
}
export interface savedUserI extends AddEmployeeDetailsI {
  bankBranch: BankBranchI;
  bank: BankI;
  userHistory: AddUserHistoryI[];
  userPayItems: PayrollItemI[];
}
interface BankI {
  bankCode: string | null;
  bankName: string | null;
}

export enum FilterTypes {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  EXTERNAL_USERS = 'EXTERNAL_USERS',
  INTERNAL_USERS = 'INTERNAL_USERS ',
}
export interface AddExternalAccountantI {
  fullName: string;
  email: string;
}
export interface UserHistoryI {
  id?: string;
  positionLevel: string | null;
  employmentType: string | null;
  branchName: string | null;
  manager: PeopleI | null;
  managerId: number | null;
  designation: string | null;
  startDate: string | null;
  endDate: string | null;
  isCurrentJob: boolean;
  userHistoryPayDetails?: UserPayDetailI[] | null;
}

export interface UserPayDetailI extends PayDetailI {
  userId: string;
}

export interface PeopleI {
  id: number;
  companyEmail: string | null;
  companyTelephone: string | null;
  designation: string | null;
  userRole: string | null;
  teams: string[] | null;
  loginEmail: string | null;
  title: string | null;
  fullName: string;
  lastName: string | null;
  firstName: string | null;
  nameWithInitials: string | null;
  preferredName: string | null;
  gender: string | null;
  nationality: string | null;
  address: string | null;
  nicOrPassport: string | null;
  martialStatus: string | null;
  DOB: string | null;
  personalMobile: string | null;
  homeMobile: string | null;
  employeeNumber: string | null;
  managerId: number | null;
  isManager?: boolean;
  secondaryManager: string | null;
  companyBranch: string | null;
  basicSalary: string | null;
  bankAccountName: string | null;
  bankAccountNumber: string | null;
  bankName: string | null;
  branchName: string | null;

  primaryAccountAmount: number | null;
  secondaryBankAccountName: string | null;
  secondaryBankAccountNumber: string | null;
  secondaryBankBankCode: string | null;
  secondaryBankBranchCode: string | null;

  emergencyContactName: string | null;
  emergencyContactAfterHoursNumber: string | null;
  emergencyContactRelationship: string | null;
  emergencyContactMobile: string | null;
  emergencyContactDaytimeNumber: string | null;
  resetToken: string | null;
  active: string | null;
  organizationId: number;
  createdAt: string | null;
  updateAt: string | null;
  role: RoleI | null;
  roleId: number;
  userHistory: UserHistoryI[];
  bankBranchId: string | null;
  isNewUser: boolean;
  isExternal: boolean;
  complete: boolean;
  profilePicture: string | null;
  bank: BankI;
  bankBranch: BankBranchI | null;
  annualLeaves: number;
  bankBankCode: string | null;
  casualLeaves: number;
  isEmployee: boolean;
  sickLeaves: number;
  profileStatus?: {
    summary: UserCompleteSummary;
    completionStatus: UserCompleteStatus;
  };
  userPayItems: UserPayDetailI[];
  isLoginAllowed: boolean;
  isArchived: boolean;
  isPayrollAllowed: boolean;
  leaveBalance: string;
  paySlipMail: string | null;
  joinedDate: string | null;
  resignedDate: string | null;
  archiveDate: string | null;
  isSecondary: boolean;
  primarySalary: number | null;
  epfNumber: string | null;
  etfNumber: string | null;

  isPending?: boolean;
  expiresOn?: string | null;
  onboardingEmail: string | null;
}
export interface PendingUserI {
  id?: number;
  preferredName: string;
  email: string;
  organizationId: number;
  userType: string | null;
}
export interface EmergencyContactI {
  emergencyContactName: string | null;
  emergencyContactAfterHoursNumber: number | null;
  emergencyContactRelationship: string | null;
  emergencyContactMobile: number | null;
  emergencyContactDaytimeNumber: number | null;
}

export interface BankDetailIForm
  extends Omit<BankDetailI, 'secondaryBankBranchCode'> {
  secondaryBankBranchId: string | null; // TODO: find
}
export interface BankDetailI {
  bankAccountName: string | null;
  bankAccountNumber: string | null;
  bankBankCode: string | null;
  bankBranchId: string | null;

  secondaryBankAccountName: string | null;
  secondaryBankAccountNumber: string | null;
  secondaryBankBankCode: string | null;
  secondaryBankBranchCode: string | null;
  primaryAccountAmount: number | string | null;
  paySlipMail?: string | null;
  isEmployeePaidInCash: boolean;
}
interface BankI {
  bankCode: string | null;
  bankName: string | null;
}

export interface PersonalDetailI {
  loginEmail: string | null;
  title: string | null;
  fullName: string | null;
  preferredName: string | null;
  gender: string | null;
  nationality: string | null;
  address: string | null;
  nicOrPassport: string | null;
  martialStatus: string | null;
  DOB: string | null;
  personalMobile: number | null;
  homeMobile: number | null;
  firstName: string | null;
  lastName: string | null;
}
export interface MePersonalDetailI extends EmergencyContactI {
  profilePicture?: string;
  address: string;
  personalMobile: string;
  homeMobile: string;
}
export interface EmploymentDetailI {
  employeeNumber: string | null;
  teams: string | null;
  companyEmail: string | null;
  companyTelephone: number | null;
  joinedDate: string | null;
  resignedDate: string | null;
  epfNumber: number | null;
  etfNumber: number | null;
}

export interface CreateEmployeeI {
  fullName: string | null;
  lastName: string | null;
  loginEmail: string | null;
  preferredName: string | null;
  isLoginAllowed: boolean;
  employeeHistory: UserHistoryI[] | null;
  notifications: NotificationItemI[] | null;
  employeeNumber: string | null;
  epfNumber: number | null;
  etfNumber: number | null;
  taxNumber: string | null;
}

export interface UserCompleteStatus {
  userHistory: boolean;
  userDocument: boolean;
  personnelDetails: boolean;
  bankDetails: boolean;
  employmentDetails: boolean;
  userPayDetails: boolean;
  emergencyDetails: boolean;
  notificationDetails: boolean;
  loanDetails: boolean;
}

export interface IndividualPeopleI extends PeopleI {
  organization?: OrganizationBodyI;
  userRole: UserRole | null;
  calculation: CalculationI;
  archiveDate: string | null;
  archivedBy: number | null;
  archivedUser: UserI | null;
  subscriptionStatus: SubscriptionEnum;
  invoices: InvoicesI | null;
  systemUpdates?: SystemUpdatesI;
  isEpfBorneByEmployer?: boolean;
  isEmployeePaidInCash: boolean;
}

export interface SystemUpdatesI {
  id: number;
  title: string;
  description: string;
  from: string;
  to: string;
  isEpfBorneByEmployer?: boolean;
  isEmployeePaidInCash: boolean;
  systemUpdates?: SystemUpdatesI;
}

export interface SystemUpdatesI {
  id: number;
  title: string;
  description: string;
  from: string;
  to: string;
}
export interface InvoicesI {
  amount: number;
  months: string[];
}

export interface CalculationI {
  apitTable1Amount: number;
  apitTable2Amount: number;
  costToCompany: number;
  employeeEPF: number;
  employerEPF: number;
  employerETF: number;
  epfEtfAmount: number;
  grossDeduction: number;
  grossSalary: number;
  netSalary: number;
  payItems: UserPayDetailI[];
  taxableAmount: number;
  totalAllowance: number;
  totalTax: number;
  withHoldTax?: number;
}
export interface EmployeeWithSalaryI {
  fullName: string;
  employeeNumber: string;
  userPayItems: UserPayDetailI[];
}
export interface AddAccountantI {
  email: string;
}
interface UserCompleteSummary {
  display: string;
  completedValue: number;
  isCompleted: boolean;
  totalValue: number;
}
export interface ExternalAccountantI extends PeopleI {
  createdAt: string;
  roleId: UserRoleIdEnum.GUEST_ADMIN;
}
export interface PeoplePayDetailI {
  data: UserPayDetailI;
  id: string;
}
export interface PayslipI {
  empPayroll: Pick<EmpPayrollI, 'payslipUrl' | 'payroll'>[];
  employeeNumber: string;
  fullName: string;
  id: string;
}
export interface UserI {
  fullName: string | null;
}
export interface PeopleTableI extends PeopleI {
  jobDesignation: string;
  jobStartDateTime: number;
  jobStartDateDisplay: string;
  jobEndDateDisplay: string;
  employeeType: string;
  completedStatus: number;
}

export interface AddPendingEmployeeI {
  preferredName: string;
  email: string;
  roleId: number | null;
  nameWithInitials: string;
  isPending: boolean;
  subject: string;
  description: string;
}

export interface RemovePendingEmployeeI {
  id: number;
  cancelReason: string;
}
export interface AddContactFormI extends AddPendingEmployeeI {
  onBoardViaEmail: boolean;
}

export interface AddContactEmailI {
  id: number;
  email: string;
  subject: string;
  description: string;
}

export interface AddPendingEmployeeResponseI {
  id: number;
  preferredName: string;
  email: string;
  userType: string;
  organizationId: number;
  createdAt: string;
  updatedAt: string;
}

export interface SendNotificationI {
  type: NotificationTypeEnum;
  userId: number;
  note: string;
  reminderDate: string;
}
