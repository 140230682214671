import React, { FC } from 'react';

import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import styled from '@emotion/styled';

import { B } from '../../../../components';
import { useIndividualPeopleState } from '../../../../providers/IndividualPeopleState';
import { updateBankDetails } from '../../../../store/actions';
import { Permission } from '../../../../types';
import { isAllowed } from '../../../../util/permissionUtil';
import { PeopleLayout } from '../../shared';
import PeopleSelect from '../../shared/PeopleSelect';
import { MultiBankDetailForm } from './MultiBankDetailForm';
import { DispatchType } from '../../../../store';

type ParamsI = {
  employeeKey: string;
};

const BankDetailsLayout = styled.div`
  padding: 32px 32px 0px;
  margin-bottom: 100px;
  @media (max-width: 768px) {
    padding: 0px 0px 12px 0px;
    margin-bottom: 0px;
  }
`;

const BankDetailsHeading = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

const DropdownSection = styled.div`
  @media (max-width: 1024px) {
    padding-bottom: 24px;
  }
`;
const BankDetailsPage: FC = () => {
  const params = useParams<ParamsI>();

  const dispatch: DispatchType = useDispatch();
  const {
    state: { people, isLoading },
    fetchPeopleById,
  } = useIndividualPeopleState();
  return (
    <>
      <PeopleLayout>
        {people ? (
          <BankDetailsLayout>
            <BankDetailsHeading>
              <B type="b-large-semibold" Pb="24px">
                Bank Details
              </B>
            </BankDetailsHeading>
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={0} xxl={0}>
                <DropdownSection>
                  {isAllowed(Permission.CREATE_EDIT_USERS) && (
                    <PeopleSelect defaultIndex="4" />
                  )}
                </DropdownSection>
              </Col>
            </Row>
            <MultiBankDetailForm
              loading={isLoading}
              bankDetail={{
                bankAccountName: people.bankAccountName,
                bankAccountNumber: people.bankAccountNumber,
                bankBankCode: people.bankBankCode,
                bankBranchId: people.bankBranchId,

                primaryAccountAmount: people.primaryAccountAmount,
                secondaryBankAccountName: people.secondaryBankAccountName,
                secondaryBankAccountNumber: people.secondaryBankAccountNumber,
                secondaryBankBankCode: people.secondaryBankBankCode,
                secondaryBankBranchCode: people.secondaryBankBranchCode,
                paySlipMail: people.paySlipMail,
                isEmployeePaidInCash: people.isEmployeePaidInCash,
              }}
              onSubmit={async (values) => {
                await dispatch(updateBankDetails(values, String(people.id)));
                fetchPeopleById(params.employeeKey);
              }}
            />
          </BankDetailsLayout>
        ) : null}
      </PeopleLayout>
    </>
  );
};

export default BankDetailsPage;
