import React, { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className: string;
  width: number;
  height: number;
}

const ContentPage: FC<Props> = ({ children, className, width, height }) => {
  return (
    <div
      className={className}
      style={{
        width: width,
        height: height,
        margin: 6,
        position: 'relative',
      }}>
      {children}
    </div>
  );
};
export default ContentPage;
