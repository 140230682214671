import React, { FC } from 'react';

import { Col, Form, Row } from 'antd';

import styled from '@emotion/styled';

import { DatePickerComp, InputComp, SelectComp } from '../../../../components';
import { Validation } from '../../../../common/validation';

interface Props {
  defaultDisable?: boolean;
}
const FormLayout = styled.div`
  padding: 32px 32px 0px;
  @media (max-width: 768px) {
    padding: 0px 0px;
  }
`;

export const UserPersonalDetailsForm: FC<Props> = ({ defaultDisable }) => {
  return (
    <FormLayout>
      <Form.Item
        label="Full Name"
        name="fullName"
        rules={[
          {
            required: true,
            message: 'Please enter full name!',
          },
        ]}>
        <InputComp
          size="small"
          disabled={defaultDisable}
          placeholder="Full Name"
        />
      </Form.Item>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Name with initials"
            name="nameWithInitials"
            rules={[
              {
                required: true,
                message: 'Please enter Name with initials!',
              },
            ]}>
            <InputComp
              size="small"
              disabled={defaultDisable}
              placeholder="Name with initials"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Preferred / Display Name"
            name="preferredName"
            rules={[
              {
                required: true,
                message: 'Please enter preferred name!',
              },
            ]}>
            <InputComp
              size="small"
              disabled={defaultDisable}
              placeholder="Preferred / Display Name"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Gender" name="gender">
            <SelectComp
              size="middle"
              disabled={defaultDisable}
              placeholder="Select a Gender"
              options={[
                {
                  label: 'Male',
                  value: 'male',
                },
                {
                  label: 'Female',
                  value: 'female',
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Nationality"
            name="nationality"
            rules={[
              {
                required: false,
                message: 'Please enter nationality!',
              },
            ]}>
            <InputComp
              size="small"
              disabled={defaultDisable}
              placeholder="Nationality"
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Address" name="address">
        <InputComp size="small" placeholder="Address" />
      </Form.Item>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="NIC"
            name="nicOrPassport"
            rules={[
              {
                required: true,
                message: 'Please enter NIC!',
              },
              {
                pattern: Validation.NicOrPassportPattern,
                message: 'The input is not valid NIC!',
              },
            ]}>
            <InputComp
              size="small"
              disabled={defaultDisable}
              placeholder="NIC"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Marital Status"
            name="martialStatus"
            rules={[
              {
                required: false,
                message: 'Please enter marital status!',
              },
            ]}>
            <SelectComp
              disabled={defaultDisable}
              size="middle"
              options={[
                {
                  label: 'Single',
                  value: 'single',
                },
                {
                  label: 'Married',
                  value: 'married',
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Date of Birth" name="DOB">
            <DatePickerComp size="middle" disabled={defaultDisable} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Personal Mobile"
            name="personalMobile"
            validateFirst={true}
            rules={[
              {
                pattern: Validation.MobileNumberPattern,
                message: 'Invalid phone number',
              },
            ]}>
            <InputComp size="small" placeholder="07xxxxxxxx" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Home Mobile"
            name="homeMobile"
            validateFirst={true}
            rules={[
              {
                required: false,
                message: 'Please enter home mobile!',
              },
              {
                type: 'string',
                pattern: Validation.MobileNumberPattern,
                message: 'Invalid phone number',
              },
            ]}>
            <InputComp size="small" placeholder="01xxxxxxxx" />
          </Form.Item>
        </Col>
      </Row>
    </FormLayout>
  );
};
