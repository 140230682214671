import React, { FC, useState, useEffect } from 'react';
import { Form, Row, Radio, Space } from 'antd';
import { B, Div, RadioButtonComp, SwitchComponent } from '../../../components';
import { useWindowSize } from '../../../util/screenSize';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import theme from '../../../theme';
import styled from '@emotion/styled';
import { Divider } from '../../../components/Divider';
import { css } from '@emotion/react';

interface LeaveInformationSettingProps {
  isSystem: boolean | undefined;
  isLeaveInPayroll: boolean | undefined;
  paySlipConfig?: string;
}
const LeaveInformationDiv = styled.div<{ isDesktopLarge: boolean }>`
  width: ${({ isDesktopLarge }) => (isDesktopLarge ? '1200px' : '800px')};
  padding: 32px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  @media (max-width: 768px) {
    padding: 24px 10px;
    width: 100%;
  }
`;
const LeaveInformationSetting: FC<LeaveInformationSettingProps> = ({
  isSystem,
  isLeaveInPayroll,
  paySlipConfig,
}) => {
  const { isDesktopLarge } = useWindowSize();
  const [isAllowed, setIsAllowed] = useState<boolean>();

  useEffect(() => {
    setIsAllowed(isLeaveInPayroll);
  }, [isLeaveInPayroll]);

  return (
    <>
      <LeaveInformationDiv isDesktopLarge={isDesktopLarge}>
        <B type="b-large-semibold" pb="24px">
          Available Leave Balance
        </B>
        <Row>
          <Div display="flex">
            <Form.Item
              name="isLeaveInPayroll"
              valuePropName="checked"
              css={css`
                margin-bottom: 16px;
              `}>
              <SwitchComponent
                onChange={(checked: boolean) => setIsAllowed(checked)}
              />
            </Form.Item>
            <Div display="flex" flexDirection="column">
              <B type="b-large" pl="18px" pt="4px">
                Include Leave Calculation in Payroll
              </B>
              <Div ml="18px">
                <B type="b-small" mb="16px" color={theme.gray600}>
                  Select if you would like to calculate No Pay Leave or OT for
                  payroll
                </B>
              </Div>
            </Div>
          </Div>
        </Row>
        <B type="b-default-semibold" mt="8px" mb="16px">
          Calculate Leave Based On
        </B>
        <Row>
          <Form.Item name="isUsingSystemLeaves">
            <Radio.Group disabled={!isAllowed} value={isSystem}>
              <Space direction="vertical">
                <RadioButtonComp value={true}>
                  Leave in System
                  <B type="b-small" color={theme.gray600}>
                    You can use leave information stored in the system and
                    cannot edit details individually for each payslip.
                  </B>
                </RadioButtonComp>
                <RadioButtonComp value={false}>
                  Bulk file upload
                  <B type="b-small" color={theme.gray600}>
                    You can upload leave information each month using a bulk
                    file or update for each employee manually on the payroll
                    page.
                  </B>
                </RadioButtonComp>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Row>
        <Row>
          <Div display="flex" mt="0px">
            {paySlipConfig ? (
              <B type="b-small" mt="4px">
                Info: This organization uses a custom payslip design
              </B>
            ) : (
              <>
                <Form.Item
                  name="leaveAllowedForPayslip"
                  valuePropName="checked">
                  <CheckBoxComp disabled={!isAllowed} />
                </Form.Item>
                <B type="b-large" ml="18px" mt="4px">
                  Display Leave Information in Payslip
                </B>
              </>
            )}
          </Div>
        </Row>
      </LeaveInformationDiv>
      <Divider />
    </>
  );
};

export default LeaveInformationSetting;
