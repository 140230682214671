import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import {
  EtfReportDataI,
  InitialSummaryDataI,
  MembersDataI,
  SummaryDataI,
  UserPayrollI,
} from '../../../types/report.types';
import {
  addFullBorders,
  centerTextsByWorksheet,
} from '../../../util/exceljs.util';

export const generateETFExports = (
  employees: number[],
  PayrollsData: EtfReportDataI[],
) => {
  const workbook = new ExcelJS.Workbook();

  const formTwo = workbook.addWorksheet('Form 2');
  const recon_sheet = workbook.addWorksheet('Reconciliation sheet');
  const summary_sheet = workbook.addWorksheet('Summary sheet');

  // form two starts here
  formTwo.columns = [
    { key: 'A', width: 18 },
    { key: 'B', width: 10 },
    { key: 'C', width: 10 },
    { key: 'D', width: 10 },
    { key: 'E', width: 8 },
    { key: 'F', width: 8 },
    { key: 'G', width: 8 },
    { key: 'H', width: 8 },
    { key: 'I', width: 8 },
    { key: 'J', width: 8 },
    { key: 'K', width: 8 },
    { key: 'L', width: 8 },
    { key: 'M', width: 8 },
    { key: 'N', width: 8 },
    { key: 'O', width: 8 },
    { key: 'P', width: 8 },
  ];

  recon_sheet.columns = [
    { key: 'A', width: 10 },
    { key: 'B', width: 10 },
    { key: 'C', width: 10 },
    { key: 'D', width: 10 },
    { key: 'E', width: 10 },
    { key: 'F', width: 10 },
    { key: 'G', width: 10 },
    { key: 'H', width: 10 },
    { key: 'I', width: 10 },
    { key: 'J', width: 10 },
    { key: 'K', width: 20 },
    { key: 'L', width: 10 },
  ];

  summary_sheet.columns = [
    { key: 'A', width: 10 },
    { key: 'B', width: 10 },
    { key: 'C', width: 7 },
    { key: 'D', width: 10 },
    { key: 'E', width: 7 },
    { key: 'F', width: 10 },
    { key: 'G', width: 7 },
    { key: 'H', width: 10 },
    { key: 'I', width: 7 },
    { key: 'J', width: 10 },
    { key: 'K', width: 7 },
    { key: 'L', width: 10 },
    { key: 'M', width: 7 },
    { key: 'N', width: 10 },
    { key: 'O', width: 7 },
  ];

  const memberData: MembersDataI = {};

  let orgName: string | null = '';
  let orgTpNo: string | null = '';
  let orgAddress: string | null = '';

  PayrollsData?.forEach((payroll) => {
    orgTpNo = payroll.organizationTelephoneNumber;
    orgName = payroll.organizationName;
    orgAddress = payroll.organizationAddress;

    payroll.users.forEach((user: UserPayrollI) => {
      if (employees.includes(user.id)) {
        memberData[user.id] = memberData[user.id] || [];

        memberData[user.id].push({
          month: payroll.month,
          ...user,
        });
      }
    });
  });

  const summaryData: InitialSummaryDataI = {};

  const chunkArray = _.chunk(Object.values(memberData), 10);

  if (chunkArray.length == 0)
    formTwo.addRow({ A: 'There is not enough data to generate ETF Report' });

  for (let i = 0; i < chunkArray.length; i++) {
    summaryData[i] = summaryData[i] || [];

    const rowOneF2 = formTwo.addRow({ A: "EMPLOYEES' TRUST FUND BOARD" });
    formTwo.mergeCells(`A${rowOneF2.number}:P${rowOneF2.number}`);

    formTwo.addRow({ A: 'FORM II RETURN' });
    formTwo.mergeCells(`A${rowOneF2.number + 1}:D${rowOneF2.number + 1}`);
    formTwo.addRow({
      A: 'Please read instructions before completing this form',
      G: 'Delete Inapplicable words',
      O: `Page No ${i + 1}`,
    });

    formTwo.addRow({
      A: '1',
      B: '2',
      C: '3',
      D: '4',
      E: '5. TOTAL GROSS WAGES AND CONTRIBUTION',
    });
    formTwo.mergeCells(`E${rowOneF2.number + 3}:P${rowOneF2.number + 3}`);

    formTwo.addRow({
      A: 'NAME OF MEMBER (surname first  followed by initial) ',
      B: "MEMBER'S NUMBER",
      C: 'NATIONAL IDENTITY CARD NO ',
      D: 'TOTAL CONTRIBUTIONS',
      E: 'JAN/JULY',
      G: 'FEB/AUG',
      I: 'MAR/SEP',
      K: 'APR/OCT',
      M: 'MAY/NOV',
      O: 'JUN/DEC',
    });

    formTwo.addRow({
      E: 'TOTAL EARNINGS',
      F: 'CONTRIBUTIONS',
      G: 'TOTAL EARNINGS',
      H: 'CONTRIBUTIONS',
      I: 'TOTAL EARNINGS',
      J: 'CONTRIBUTIONS',
      K: 'TOTAL EARNINGS',
      L: 'CONTRIBUTIONS',
      M: 'TOTAL EARNINGS',
      N: 'CONTRIBUTIONS',
      O: 'TOTAL EARNINGS',
      P: 'CONTRIBUTIONS',
    });

    formTwo.addRow({
      D: 'Rs.',
      E: 'Rs.',
      F: 'Rs.',
      G: 'Rs.',
      H: 'Rs.',
      I: 'Rs.',
      J: 'Rs.',
      K: 'Rs.',
      L: 'Rs.',
      M: 'Rs.',
      N: 'Rs.',
      O: 'Rs.',
      P: 'Rs.',
    });

    formTwo.mergeCells(`A${rowOneF2.number + 4}:A${rowOneF2.number + 6}`);
    formTwo.mergeCells(`B${rowOneF2.number + 4}:B${rowOneF2.number + 6}`);
    formTwo.mergeCells(`C${rowOneF2.number + 4}:C${rowOneF2.number + 6}`);
    formTwo.mergeCells(`D${rowOneF2.number + 4}:D${rowOneF2.number + 5}`);

    formTwo.mergeCells(`E${rowOneF2.number + 4}:F${rowOneF2.number + 4}`);
    formTwo.mergeCells(`G${rowOneF2.number + 4}:H${rowOneF2.number + 4}`);
    formTwo.mergeCells(`I${rowOneF2.number + 4}:J${rowOneF2.number + 4}`);
    formTwo.mergeCells(`K${rowOneF2.number + 4}:L${rowOneF2.number + 4}`);
    formTwo.mergeCells(`M${rowOneF2.number + 4}:N${rowOneF2.number + 4}`);
    formTwo.mergeCells(`O${rowOneF2.number + 4}:P${rowOneF2.number + 4}`);

    let totalEft = 0;

    summaryData[i].push({
      M1: {
        Rs: 0,
        Cts: 0,
      },
      M2: {
        Rs: 0,
        Cts: 0,
      },
      M3: {
        Rs: 0,
        Cts: 0,
      },
      M4: {
        Rs: 0,
        Cts: 0,
      },
      M5: {
        Rs: 0,
        Cts: 0,
      },
      M6: {
        Rs: 0,
        Cts: 0,
      },
    });

    chunkArray[i].forEach((payroll: UserPayrollI[]) => {
      const newRow = formTwo.addRow({
        A: payroll[0].fullName,
        B: payroll[0].membersNumber,
        C: payroll[0].nic,
      });

      const row = formTwo.getRow(newRow.number);

      const rowNum = newRow.number;

      addFullBorders(formTwo, [
        `A${rowNum}`,
        `B${rowNum}`,
        `C${rowNum}`,
        `D${rowNum}`,
        `E${rowNum}`,
        `F${rowNum}`,
        `G${rowNum}`,
        `H${rowNum}`,
        `I${rowNum}`,
        `J${rowNum}`,
        `K${rowNum}`,
        `L${rowNum}`,
        `M${rowNum}`,
        `N${rowNum}`,
        `O${rowNum}`,
        `P${rowNum}`,
      ]);

      let totEtfPerEmployee = 0;
      payroll.forEach((user: UserPayrollI) => {
        totEtfPerEmployee = totEtfPerEmployee + user.calculation.employerETF;

        totalEft = totalEft + user.calculation.employerETF;
        if (user.month === 'Jan' || user.month === 'Jul') {
          row.getCell(`E`).value = user.calculation.grossSalary;
          row.getCell(`F`).value = user.calculation.employerETF;
          summaryData[i][0].M1.Rs += user.calculation.employerETF;
          summaryData[i][0].M1.Cts += Number(
            user.calculation.employerETF.toString().split('.')[1],
          );
        } else if (user.month === 'Feb' || user.month === 'Aug') {
          row.getCell(`G`).value = user.calculation.grossSalary;
          row.getCell(`H`).value = user.calculation.employerETF;
          summaryData[i][0].M2.Rs += user.calculation.employerETF;
          summaryData[i][0].M2.Cts += Number(
            user.calculation.employerETF.toString().split('.')[1],
          );
        } else if (user.month === 'Mar' || user.month === 'Sep') {
          row.getCell(`I`).value = user.calculation.grossSalary;
          row.getCell(`J`).value = user.calculation.employerETF;
          summaryData[i][0].M3.Rs += user.calculation.employerETF;
          summaryData[i][0].M3.Cts += Number(
            user.calculation.employerETF.toString().split('.')[1],
          );
        } else if (user.month === 'Apr' || user.month === 'Oct') {
          row.getCell(`K`).value = user.calculation.grossSalary;
          row.getCell(`L`).value = user.calculation.employerETF;
          summaryData[i][0].M4.Rs += user.calculation.employerETF;
          summaryData[i][0].M4.Cts += Number(
            user.calculation.employerETF.toString().split('.')[1],
          );
        } else if (user.month === 'May' || user.month === 'Nov') {
          row.getCell(`M`).value = user.calculation.grossSalary;
          row.getCell(`N`).value = user.calculation.employerETF;
          summaryData[i][0].M5.Rs += user.calculation.employerETF;
          summaryData[i][0].M5.Cts += Number(
            user.calculation.employerETF.toString().split('.')[1],
          );
        } else if (user.month === 'Jun' || user.month === 'Dec') {
          row.getCell(`O`).value = user.calculation.grossSalary;
          row.getCell(`P`).value = user.calculation.employerETF;
          summaryData[i][0].M6.Rs += user.calculation.employerETF;
          summaryData[i][0].M6.Cts += Number(
            user.calculation.employerETF.toString().split('.')[1],
          );
        }
      });
      row.getCell(`D`).value = totEtfPerEmployee;
    });

    const pageTotalRow = formTwo.addRow({
      A: 'PAGE TOTAL',
    });
    formTwo.mergeCells(`A${pageTotalRow.number}:C${pageTotalRow.number}`);
    pageTotalRow.getCell(`D`).value = totalEft;

    formTwo.addRow({ A: 'PAGE TOTAL TO BE TAKEN TO THE SUMMARY SHEET' });

    formTwo.addRow({});

    formTwo.addRow({
      A: "EMPLOYER'S REGISTRATION NO ",
      D: '……………………………………………',
    });

    formTwo.addRow({});

    formTwo.addRow({
      A: 'NAME & ADDRESS OF EMPLOYER ',
      // D: '………………………………………………………………………………………………………………………………………',
      D: `${orgName}`,
    });
    formTwo.addRow({
      // D: '………………………………………………………………………………………………………………………………………..…………………',
      D: `${orgAddress}`,
    });

    formTwo.addRow({});

    formTwo.addRow({ A: 'TELEPHONE NO', B: `${orgTpNo}` });

    formTwo.addRow({});

    formTwo.addRow({
      A: 'FAX NO',
      D: '……………………………………….',
      H: 'I Certify that all the particulars given above are correct and that no part of',
    });
    formTwo.addRow({
      H: 'the contributions that should be paid by us has been deducted from any',
    });

    formTwo.addRow({
      A: 'Duly completed returns should be Sent to :-',
      H: "employees' earnings.",
    });

    formTwo.addRow({ A: 'Manager - Member Accounts' });
    formTwo.addRow({
      A: "Employees' Trust Fund Board",
      H: '……………………………',
      L: '…………………………………………………………..',
    });
    formTwo.addRow({
      A: 'P.O. Box 807, 1st Floor',
      H: 'Date',
      L: 'Signature of Employer and Rubber Stamp..',
    });
    formTwo.addRow({ A: 'Labour Secretariat, Colombo 05' });
    formTwo.addRow({
      A: '011-2369596',
      H: '* Please notice that this is a specimen of Form II.',
    });

    formTwo.addRow({}); //adding an empty row at the end of the table

    formTwo.getRow(rowOneF2.number).font = {
      name: 'Calibri',
      bold: false,
      size: 18,
    };
    formTwo.getRow(rowOneF2.number + 1).font = {
      name: 'Calibri',
      bold: false,
      size: 15,
    };
    formTwo.getRow(rowOneF2.number + 2).font = {
      name: 'Calibri',
      bold: true,
      size: 13,
    };
    formTwo.getRow(rowOneF2.number + 3).font = {
      name: 'Cambria',
      bold: true,
      size: 11,
    };
    formTwo.getRow(rowOneF2.number + 4).font = {
      name: 'Cambria',
      bold: true,
      size: 8,
    };
    formTwo.getRow(rowOneF2.number + 5).font = {
      name: 'Cambria',
      bold: true,
      size: 8,
    };
    formTwo.getRow(rowOneF2.number + 6).font = {
      name: 'Cambria',
      bold: true,
      size: 8,
    };

    formTwo.getRow(pageTotalRow.number).font = {
      name: 'Cambria',
      bold: true,
      size: 11,
    };
    formTwo.getRow(pageTotalRow.number + 1).font = {
      name: 'Cambria',
      bold: true,
      size: 11,
    };
    formTwo.getRow(pageTotalRow.number + 3).font = {
      name: 'Cambria',
      bold: true,
      size: 11,
    };
    formTwo.getRow(pageTotalRow.number + 5).font = {
      name: 'Cambria',
      bold: true,
      size: 11,
    };
    formTwo.getRow(pageTotalRow.number + 8).font = {
      name: 'Cambria',
      bold: true,
      size: 11,
    };
    formTwo.getRow(pageTotalRow.number + 10).font = {
      name: 'Cambria',
      bold: true,
      size: 11,
    };
    formTwo.getRow(pageTotalRow.number + 11).font = {
      name: 'Cambria',
      bold: true,
      size: 11,
    };
    formTwo.getRow(pageTotalRow.number + 12).font = {
      name: 'Cambria',
      bold: true,
      size: 11,
    };

    formTwo.getCell(`H${pageTotalRow.number + 15}`).font = {
      name: 'Cambria',
      bold: true,
      size: 11,
    };

    formTwo.getCell(`L${pageTotalRow.number + 15}`).font = {
      name: 'Cambria',
      bold: true,
      size: 11,
    };

    formTwo.getRow(rowOneF2.number).height = 20;
    formTwo.getRow(rowOneF2.number + 1).height = 20;
    formTwo.getRow(rowOneF2.number + 5).height = 25;

    centerTextsByWorksheet(formTwo, [
      `A${rowOneF2.number}`,
      `A${rowOneF2.number + 3}`,
      `B${rowOneF2.number + 3}`,
      `C${rowOneF2.number + 3}`,
      `D${rowOneF2.number + 3}`,
      `E${rowOneF2.number + 3}`,
      `A${rowOneF2.number + 4}`,
      `B${rowOneF2.number + 4}`,
      `C${rowOneF2.number + 4}`,
      `D${rowOneF2.number + 4}`,
      `E${rowOneF2.number + 4}`,
      `G${rowOneF2.number + 4}`,
      `I${rowOneF2.number + 4}`,
      `K${rowOneF2.number + 4}`,
      `M${rowOneF2.number + 4}`,
      `O${rowOneF2.number + 4}`,
      `E${rowOneF2.number + 5}`,
      `F${rowOneF2.number + 5}`,
      `G${rowOneF2.number + 5}`,
      `H${rowOneF2.number + 5}`,
      `I${rowOneF2.number + 5}`,
      `J${rowOneF2.number + 5}`,
      `K${rowOneF2.number + 5}`,
      `L${rowOneF2.number + 5}`,
      `M${rowOneF2.number + 5}`,
      `N${rowOneF2.number + 5}`,
      `O${rowOneF2.number + 5}`,
      `P${rowOneF2.number + 5}`,
      `D${rowOneF2.number + 6}`,
      `E${rowOneF2.number + 6}`,
      `F${rowOneF2.number + 6}`,
      `G${rowOneF2.number + 6}`,
      `H${rowOneF2.number + 6}`,
      `I${rowOneF2.number + 6}`,
      `J${rowOneF2.number + 6}`,
      `K${rowOneF2.number + 6}`,
      `L${rowOneF2.number + 6}`,
      `M${rowOneF2.number + 6}`,
      `N${rowOneF2.number + 6}`,
      `O${rowOneF2.number + 6}`,
      `P${rowOneF2.number + 6}`,
      `A${pageTotalRow.number}`,
    ]);

    addFullBorders(formTwo, [
      `A${rowOneF2.number + 3}`,
      `B${rowOneF2.number + 3}`,
      `C${rowOneF2.number + 3}`,
      `D${rowOneF2.number + 3}`,
      `E${rowOneF2.number + 3}`,

      `A${rowOneF2.number + 4}`,
      `B${rowOneF2.number + 4}`,
      `C${rowOneF2.number + 4}`,
      `D${rowOneF2.number + 4}`,
      `E${rowOneF2.number + 4}`,
      `G${rowOneF2.number + 4}`,
      `I${rowOneF2.number + 4}`,
      `K${rowOneF2.number + 4}`,
      `M${rowOneF2.number + 4}`,
      `O${rowOneF2.number + 4}`,

      `E${rowOneF2.number + 5}`,
      `F${rowOneF2.number + 5}`,
      `G${rowOneF2.number + 5}`,
      `H${rowOneF2.number + 5}`,
      `I${rowOneF2.number + 5}`,
      `J${rowOneF2.number + 5}`,
      `K${rowOneF2.number + 5}`,
      `L${rowOneF2.number + 5}`,
      `M${rowOneF2.number + 5}`,
      `N${rowOneF2.number + 5}`,
      `O${rowOneF2.number + 5}`,
      `P${rowOneF2.number + 5}`,

      `D${rowOneF2.number + 6}`,
      `E${rowOneF2.number + 6}`,
      `F${rowOneF2.number + 6}`,
      `G${rowOneF2.number + 6}`,
      `H${rowOneF2.number + 6}`,
      `I${rowOneF2.number + 6}`,
      `J${rowOneF2.number + 6}`,
      `K${rowOneF2.number + 6}`,
      `L${rowOneF2.number + 6}`,
      `M${rowOneF2.number + 6}`,
      `N${rowOneF2.number + 6}`,
      `O${rowOneF2.number + 6}`,
      `P${rowOneF2.number + 6}`,
    ]);

    addFullBorders(formTwo, [
      `A${pageTotalRow.number}`,
      `D${pageTotalRow.number}`,
      `E${pageTotalRow.number}`,
      `F${pageTotalRow.number}`,
      `G${pageTotalRow.number}`,
      `H${pageTotalRow.number}`,
      `I${pageTotalRow.number}`,
      `J${pageTotalRow.number}`,
      `K${pageTotalRow.number}`,
      `L${pageTotalRow.number}`,
      `M${pageTotalRow.number}`,
      `N${pageTotalRow.number}`,
      `O${pageTotalRow.number}`,
      `P${pageTotalRow.number}`,
    ]);
  }
  //form two ends here

  recon_sheet.addRow({ A: "EMPLOYEES' TRUST FUND BOARD" });
  recon_sheet.mergeCells('A1:L1');

  recon_sheet.addRow({
    A: 'RETURN FOR THE HALF - YEAR ENDING 30TH JUNE / 31ST DECEMBER ……………………',
  });
  recon_sheet.mergeCells('A2:L2');

  recon_sheet.addRow({ A: "CONTRIBUTION'S RECONCILIATION STATEMENT" });
  recon_sheet.mergeCells('A3:L3');

  recon_sheet.addRow({});

  recon_sheet.addRow({ A: '01', B: 'DETAILS OF PAYMENTS' });

  recon_sheet.addRow({
    A: 'Month',
    B: 'Total Monthly Contributions payble as per Form II return',
    D: 'Amount remitted monthly',
    F: 'Over/Under',
    H: 'Cheque No',
    I: 'Cheque Amount',
    K: 'Name & branch of Bank where payment was made (If paid by cash)',
    L: 'Date of Payment',
  });

  recon_sheet.addRow({
    B: 'Rs',
    C: 'Cts',
    D: 'Rs',
    E: 'Cts',
    F: 'Rs',
    G: 'Cts',
    I: 'Rs',
    J: 'Cts',
  });

  recon_sheet.mergeCells('A6:A7');
  recon_sheet.mergeCells('B6:C6');
  recon_sheet.mergeCells('D6:E6');
  recon_sheet.mergeCells('F6:G6');
  recon_sheet.mergeCells('I6:J6');

  recon_sheet.addRow({ A: 'Jan/July' });
  recon_sheet.addRow({ A: 'Feb/Aug' });
  recon_sheet.addRow({ A: 'Mar/Sep' });
  recon_sheet.addRow({ A: 'Apr/Oct' });
  recon_sheet.addRow({ A: 'May/Nov' });
  recon_sheet.addRow({ A: 'Jun/Dec' });
  recon_sheet.addRow({ A: 'Total' });

  const reconData: SummaryDataI = {
    M1: {
      Rs: 0,
      Cts: 0,
    },
    M2: {
      Rs: 0,
      Cts: 0,
    },
    M3: {
      Rs: 0,
      Cts: 0,
    },
    M4: {
      Rs: 0,
      Cts: 0,
    },
    M5: {
      Rs: 0,
      Cts: 0,
    },
    M6: {
      Rs: 0,
      Cts: 0,
    },
  };

  Object.values(summaryData).forEach((item: SummaryDataI[]) => {
    reconData.M1.Rs += item[0].M1.Rs;
    reconData.M2.Rs += item[0].M2.Rs;
    reconData.M3.Rs += item[0].M3.Rs;
    reconData.M4.Rs += item[0].M4.Rs;
    reconData.M5.Rs += item[0].M5.Rs;
    reconData.M6.Rs += item[0].M6.Rs;
  });

  recon_sheet.getCell(`B8`).value = Number(
    reconData.M1.Rs.toString().split('.')[0],
  );
  recon_sheet.getCell(`B9`).value = Number(
    reconData.M2.Rs.toString().split('.')[0],
  );
  recon_sheet.getCell(`B10`).value = Number(
    reconData.M3.Rs.toString().split('.')[0],
  );
  recon_sheet.getCell(`B11`).value = Number(
    reconData.M4.Rs.toString().split('.')[0],
  );
  recon_sheet.getCell(`B12`).value = Number(
    reconData.M5.Rs.toString().split('.')[0],
  );
  recon_sheet.getCell(`B13`).value = Number(
    reconData.M6.Rs.toString().split('.')[0],
  );

  recon_sheet.getCell(`C8`).value = Number(
    reconData.M1.Rs.toString().split('.')[1],
  )
    ? Number(reconData.M1.Rs.toString().split('.')[1])
    : '00';
  recon_sheet.getCell(`C9`).value = Number(
    reconData.M2.Rs.toString().split('.')[1],
  )
    ? Number(reconData.M2.Rs.toString().split('.')[1])
    : '00';
  recon_sheet.getCell(`C10`).value = Number(
    reconData.M3.Rs.toString().split('.')[1],
  )
    ? Number(reconData.M3.Rs.toString().split('.')[1])
    : '00';
  recon_sheet.getCell(`C11`).value = Number(
    reconData.M4.Rs.toString().split('.')[1],
  )
    ? Number(reconData.M4.Rs.toString().split('.')[1])
    : '00';
  recon_sheet.getCell(`C12`).value = Number(
    reconData.M5.Rs.toString().split('.')[1],
  )
    ? Number(reconData.M5.Rs.toString().split('.')[1])
    : '00';
  recon_sheet.getCell(`C13`).value = Number(
    reconData.M6.Rs.toString().split('.')[1],
  )
    ? Number(reconData.M6.Rs.toString().split('.')[1])
    : '00';

  recon_sheet.getCell(`D8`).value = Number(
    reconData.M1.Rs.toString().split('.')[0],
  );
  recon_sheet.getCell(`D9`).value = Number(
    reconData.M2.Rs.toString().split('.')[0],
  );
  recon_sheet.getCell(`D10`).value = Number(
    reconData.M3.Rs.toString().split('.')[0],
  );
  recon_sheet.getCell(`D11`).value = Number(
    reconData.M4.Rs.toString().split('.')[0],
  );
  recon_sheet.getCell(`D12`).value = Number(
    reconData.M5.Rs.toString().split('.')[0],
  );
  recon_sheet.getCell(`D13`).value = Number(
    reconData.M6.Rs.toString().split('.')[0],
  );

  recon_sheet.getCell(`E8`).value = Number(
    reconData.M1.Rs.toString().split('.')[1],
  )
    ? Number(reconData.M1.Rs.toString().split('.')[1])
    : '00';
  recon_sheet.getCell(`E9`).value = Number(
    reconData.M2.Rs.toString().split('.')[1],
  )
    ? Number(reconData.M2.Rs.toString().split('.')[1])
    : '00';
  recon_sheet.getCell(`E10`).value = Number(
    reconData.M3.Rs.toString().split('.')[1],
  )
    ? Number(reconData.M3.Rs.toString().split('.')[1])
    : '00';
  recon_sheet.getCell(`E11`).value = Number(
    reconData.M4.Rs.toString().split('.')[1],
  )
    ? Number(reconData.M4.Rs.toString().split('.')[1])
    : '00';
  recon_sheet.getCell(`E12`).value = Number(
    reconData.M5.Rs.toString().split('.')[1],
  )
    ? Number(reconData.M5.Rs.toString().split('.')[1])
    : '00';
  recon_sheet.getCell(`E13`).value = Number(
    reconData.M6.Rs.toString().split('.')[1],
  )
    ? Number(reconData.M6.Rs.toString().split('.')[1])
    : '00';

  const reconSheetTotalContribution =
    reconData.M1.Rs +
    reconData.M2.Rs +
    reconData.M3.Rs +
    reconData.M4.Rs +
    reconData.M5.Rs +
    reconData.M6.Rs;

  recon_sheet.getCell(`B14`).value = reconSheetTotalContribution;

  addFullBorders(recon_sheet, [
    `B8`,
    `B9`,
    `B10`,
    `B11`,
    `B12`,
    `B13`,

    `C8`,
    `C9`,
    `C10`,
    `C11`,
    `C12`,
    `C13`,

    `D8`,
    `D9`,
    `D10`,
    `D11`,
    `D12`,
    `D13`,

    `E8`,
    `E9`,
    `E10`,
    `E11`,
    `E12`,
    `E13`,

    `F8`,
    `F9`,
    `F10`,
    `F11`,
    `F12`,
    `F13`,

    `G8`,
    `G9`,
    `G10`,
    `G11`,
    `G12`,
    `G13`,

    `H8`,
    `H9`,
    `H10`,
    `H11`,
    `H12`,
    `H13`,

    `I8`,
    `I9`,
    `I10`,
    `I11`,
    `I12`,
    `I13`,

    `J8`,
    `J9`,
    `J10`,
    `J11`,
    `J12`,
    `J13`,

    `K8`,
    `K9`,
    `K10`,
    `K11`,
    `K12`,
    `K13`,

    `L8`,
    `L9`,
    `L10`,
    `L11`,
    `L12`,
    `L13`,
  ]);

  centerTextsByWorksheet(recon_sheet, [
    `B8`,
    `B9`,
    `B10`,
    `B11`,
    `B12`,
    `B13`,

    `C8`,
    `C9`,
    `C10`,
    `C11`,
    `C12`,
    `C13`,

    `D8`,
    `D9`,
    `D10`,
    `D11`,
    `D12`,
    `D13`,

    `E8`,
    `E9`,
    `E10`,
    `E11`,
    `E12`,
    `E13`,

    `F8`,
    `F9`,
    `F10`,
    `F11`,
    `F12`,
    `F13`,

    `G8`,
    `G9`,
    `G10`,
    `G11`,
    `G12`,
    `G13`,

    `H8`,
    `H9`,
    `H10`,
    `H11`,
    `H12`,
    `H13`,

    `I8`,
    `I9`,
    `I10`,
    `I11`,
    `I12`,
    `I13`,

    `J8`,
    `J9`,
    `J10`,
    `J11`,
    `J12`,
    `J13`,

    `K8`,
    `K9`,
    `K10`,
    `K11`,
    `K12`,
    `K13`,

    `L8`,
    `L9`,
    `L10`,
    `L11`,
    `L12`,
    `L13`,
  ]);

  recon_sheet.addRow({});

  recon_sheet.addRow({ A: '01', B: 'SUMMARY OF RETURN' });

  recon_sheet.addRow({ A: "Employer's Registration No" });
  recon_sheet.addRow({ A: 'Half Year / Period' });
  recon_sheet.addRow({
    A: 'No of Members',
    D: Object.values(memberData).length,
  });
  recon_sheet.addRow({
    A: 'Total Contribution of six Months',
    D: reconSheetTotalContribution,
  });
  recon_sheet.addRow({
    A: 'No of Pages',
    D: Object.values(summaryData).length,
  });

  recon_sheet.mergeCells('A17:C17');
  recon_sheet.mergeCells('A18:C18');
  recon_sheet.mergeCells('A19:C19');
  recon_sheet.mergeCells('A20:C20');
  recon_sheet.mergeCells('A21:C21');

  recon_sheet.mergeCells('D17:F17');
  recon_sheet.mergeCells('D18:F18');
  recon_sheet.mergeCells('D19:F19');
  recon_sheet.mergeCells('D20:F20');
  recon_sheet.mergeCells('D21:F21');

  recon_sheet.addRow({});

  recon_sheet.addRow({ A: 'EPF/PPF No', C: ':………………………………………….' });
  recon_sheet.addRow({
    A: 'Name & Address of Employer',
    C: `${orgName}`,
    I: 'I certify that all the particulars given above are true & ',
  });
  recon_sheet.addRow({ C: `${orgAddress}`, I: 'correct' });
  recon_sheet.addRow({ C: '…………………………………………………………………………………………' });
  recon_sheet.addRow({
    C: '…………………………………………………………………………………………',
    I: '…………………………………………………………………..',
  });
  recon_sheet.addRow({
    A: 'Te. No',
    C: `${orgTpNo}`,
    I: 'Signature of Employer and Official Seal',
  });
  recon_sheet.addRow({
    A: 'Fax No',
    C: ':………………………………………….',
    I: 'Date : ………………………………',
  });

  centerTextsByWorksheet(recon_sheet, [
    'A1',
    'A2',
    'A3',
    'A6',
    'B6',
    'D6',
    'F6',
    'H6',
    'I6',
    'K6',
    'L6',
    'B7',
    'C7',
    'D7',
    'E7',
    'F7',
    'G7',
    'I7',
    'J7',
  ]);

  addFullBorders(recon_sheet, [
    'A6',
    'B6',
    'D6',
    'F6',
    'H6',
    'I6',
    'K6',
    'L6',
    'B7',
    'C7',
    'D7',
    'E7',
    'F7',
    'G7',
    'H7',
    'I7',
    'J7',
    'K7',
    'L7',

    'A8',
    'A9',
    'A10',
    'A11',
    'A12',
    'A13',
    'A14',
    'A17',
    'A18',
    'A19',
    'A20',
    'A21',
    'B14',

    'D17',
    'D18',
    'D19',
    'D20',
    'D21',
  ]);

  recon_sheet.getRow(1).font = { name: 'Cambria', bold: true, size: 18 };
  recon_sheet.getRow(2).font = { name: 'Cambria', bold: true, size: 13 };
  recon_sheet.getRow(3).font = { name: 'Cambria', bold: true, size: 13 };
  recon_sheet.getRow(5).font = { name: 'Cambria', bold: true, size: 11 };
  recon_sheet.getRow(6).font = { name: 'Cambria', bold: true, size: 11 };
  recon_sheet.getRow(7).font = { name: 'Cambria', bold: true, size: 11 };
  recon_sheet.getRow(14).font = { name: 'Cambria', bold: true, size: 11 };
  recon_sheet.getRow(16).font = { name: 'Cambria', bold: true, size: 11 };

  recon_sheet.getRow(17).font = { name: 'Cambria', bold: true, size: 11 };
  recon_sheet.getRow(18).font = { name: 'Cambria', bold: true, size: 11 };
  recon_sheet.getRow(19).font = { name: 'Cambria', bold: true, size: 11 };
  recon_sheet.getRow(20).font = { name: 'Cambria', bold: true, size: 11 };
  recon_sheet.getRow(21).font = { name: 'Cambria', bold: true, size: 11 };

  recon_sheet.getRow(6).height = 50;

  //summary sheet
  const summaryDataArray: number[] = Object.values(summaryData);
  const summaryChunkArray: any = _.chunk(summaryDataArray, 10);

  let pageNumber = 0;

  for (let i = 0; i < summaryChunkArray.length; i++) {
    const rowOneSummary = summary_sheet.addRow({
      A: "EMPLOYEES' TRUST FUND BOARD",
    });
    summary_sheet.mergeCells(
      `A${rowOneSummary.number}:O${rowOneSummary.number}`,
    );

    summary_sheet.addRow({
      A: 'RETURN FOR THE HALF - YEAR ENDING 30TH JUNE / 31ST DECEMBER ……………………',
    });
    summary_sheet.mergeCells(
      `A${rowOneSummary.number + 1}:O${rowOneSummary.number + 1}`,
    );

    summary_sheet.addRow({ A: "CONTRIBUTION'S RECONCILIATION STATEMENT" });
    summary_sheet.mergeCells(
      `A${rowOneSummary.number + 2}:O${rowOneSummary.number + 2}`,
    );

    summary_sheet.addRow({});

    summary_sheet.addRow({
      A: 'Page NO',
      B: 'Page Total',
      D: 'Jan/July',
      F: 'Feb/Aug',
      H: 'Mar/Sep',
      J: 'Apr/Oct',
      L: 'May/Nov',
      N: 'Jun/Dec',
    });
    summary_sheet.addRow({
      B: 'Rs.',
      C: 'Cts.',
      D: 'Rs.',
      E: 'Cts.',
      F: 'Rs.',
      G: 'Cts.',
      H: 'Rs.',
      I: 'Cts.',
      J: 'Rs.',
      K: 'Cts.',
      L: 'Rs.',
      M: 'Cts.',
      N: 'Rs.',
      O: 'Cts.',
    });

    summary_sheet.mergeCells(
      `A${rowOneSummary.number + 4}:A${rowOneSummary.number + 5}`,
    );
    summary_sheet.mergeCells(
      `B${rowOneSummary.number + 4}:C${rowOneSummary.number + 4}`,
    );
    summary_sheet.mergeCells(
      `D${rowOneSummary.number + 4}:E${rowOneSummary.number + 4}`,
    );
    summary_sheet.mergeCells(
      `F${rowOneSummary.number + 4}:G${rowOneSummary.number + 4}`,
    );
    summary_sheet.mergeCells(
      `H${rowOneSummary.number + 4}:I${rowOneSummary.number + 4}`,
    );
    summary_sheet.mergeCells(
      `J${rowOneSummary.number + 4}:K${rowOneSummary.number + 4}`,
    );
    summary_sheet.mergeCells(
      `L${rowOneSummary.number + 4}:M${rowOneSummary.number + 4}`,
    );
    summary_sheet.mergeCells(
      `N${rowOneSummary.number + 4}:O${rowOneSummary.number + 4}`,
    );

    for (let x = 0; x < summaryChunkArray[i].length; x++) {
      summaryChunkArray[i][x].forEach((item: SummaryDataI) => {
        pageNumber = pageNumber + 1;
        const totRs =
          item.M1.Rs +
          item.M2.Rs +
          item.M3.Rs +
          item.M4.Rs +
          item.M5.Rs +
          item.M6.Rs;

        const newRowSum = summary_sheet.addRow({
          A: `${pageNumber}`,
          B: Number(totRs.toString().split('.')[0]),
          C: Number(totRs.toString().split('.')[1])
            ? Number(totRs.toString().split('.')[1])
            : '00',
          D: Number(item.M1.Rs.toString().split('.')[0]),
          E: Number(item.M1.Rs.toString().split('.')[1])
            ? Number(item.M1.Rs.toString().split('.')[1])
            : '00',
          F: Number(item.M2.Rs.toString().split('.')[0]),
          G: Number(item.M2.Rs.toString().split('.')[1])
            ? Number(item.M2.Rs.toString().split('.')[1])
            : '00',
          H: Number(item.M3.Rs.toString().split('.')[0]),
          I: Number(item.M3.Rs.toString().split('.')[1])
            ? Number(item.M3.Rs.toString().split('.')[1])
            : '00',
          J: Number(item.M4.Rs.toString().split('.')[0]),
          K: Number(item.M4.Rs.toString().split('.')[1])
            ? Number(item.M4.Rs.toString().split('.')[1])
            : '00',
          L: Number(item.M5.Rs.toString().split('.')[0]),
          M: Number(item.M5.Rs.toString().split('.')[1])
            ? Number(item.M5.Rs.toString().split('.')[1])
            : '00',
          N: Number(item.M6.Rs.toString().split('.')[0]),
          O: Number(item.M6.Rs.toString().split('.')[1])
            ? Number(item.M6.Rs.toString().split('.')[1])
            : '00',
        });
        addFullBorders(summary_sheet, [
          `A${newRowSum.number}`,
          `B${newRowSum.number}`,
          `C${newRowSum.number}`,
          `D${newRowSum.number}`,
          `E${newRowSum.number}`,
          `F${newRowSum.number}`,
          `G${newRowSum.number}`,
          `H${newRowSum.number}`,
          `I${newRowSum.number}`,
          `J${newRowSum.number}`,
          `K${newRowSum.number}`,
          `L${newRowSum.number}`,
          `M${newRowSum.number}`,
          `N${newRowSum.number}`,
          `O${newRowSum.number}`,
        ]);

        centerTextsByWorksheet(summary_sheet, [
          `A${newRowSum.number}`,
          `B${newRowSum.number}`,
          `C${newRowSum.number}`,
          `D${newRowSum.number}`,
          `E${newRowSum.number}`,
          `F${newRowSum.number}`,
          `G${newRowSum.number}`,
          `H${newRowSum.number}`,
          `I${newRowSum.number}`,
          `J${newRowSum.number}`,
          `K${newRowSum.number}`,
          `L${newRowSum.number}`,
          `M${newRowSum.number}`,
          `N${newRowSum.number}`,
          `O${newRowSum.number}`,
        ]);
      });
    }
    const grandTotal = summary_sheet.addRow({ A: 'Grand Total' });

    summary_sheet.addRow({});

    summary_sheet.addRow({
      A: "Employer's Registration No",
      D: ':………………………………………….',
    });
    summary_sheet.addRow({
      A: 'Name & Address of Employer',
      D: `${orgName}`,
    });
    summary_sheet.addRow({ D: `${orgAddress}` });
    summary_sheet.addRow({ D: ':………………………………………………………………………………………..' });
    summary_sheet.addRow({
      D: ':………………………………………………………………………………………..',
      K: '…………………………………………………………………..',
    });

    summary_sheet.addRow({
      A: 'Te. No',
      D: `${orgTpNo}`,
      K: 'Signature of Employer and Official Seal ',
    });
    summary_sheet.addRow({
      A: 'Fax No',
      D: ':………………………………………….',
      K: 'Date : ………………………………',
    });

    centerTextsByWorksheet(summary_sheet, [
      `A${rowOneSummary.number}`,
      `A${rowOneSummary.number + 1}`,
      `A${rowOneSummary.number + 2}`,
      `A${rowOneSummary.number + 4}`,
      `B${rowOneSummary.number + 4}`,
      `D${rowOneSummary.number + 4}`,
      `F${rowOneSummary.number + 4}`,
      `F${rowOneSummary.number + 4}`,
      `H${rowOneSummary.number + 4}`,
      `J${rowOneSummary.number + 4}`,
      `L${rowOneSummary.number + 4}`,
      `N${rowOneSummary.number + 4}`,
      `B${rowOneSummary.number + 5}`,
      `C${rowOneSummary.number + 5}`,
      `D${rowOneSummary.number + 5}`,
      `E${rowOneSummary.number + 5}`,
      `F${rowOneSummary.number + 5}`,
      `G${rowOneSummary.number + 5}`,
      `H${rowOneSummary.number + 5}`,
      `I${rowOneSummary.number + 5}`,
      `K${rowOneSummary.number + 5}`,
      `L${rowOneSummary.number + 5}`,
      `M${rowOneSummary.number + 5}`,
      `N${rowOneSummary.number + 5}`,
      `O${rowOneSummary.number + 5}`,
      `A${grandTotal.number}`,
    ]);

    addFullBorders(summary_sheet, [
      `A${rowOneSummary.number + 4}`,
      `B${rowOneSummary.number + 4}`,
      `D${rowOneSummary.number + 4}`,
      `F${rowOneSummary.number + 4}`,
      `F${rowOneSummary.number + 4}`,
      `H${rowOneSummary.number + 4}`,
      `J${rowOneSummary.number + 4}`,
      `L${rowOneSummary.number + 4}`,
      `N${rowOneSummary.number + 4}`,
      `B${rowOneSummary.number + 5}`,
      `C${rowOneSummary.number + 5}`,
      `D${rowOneSummary.number + 5}`,
      `E${rowOneSummary.number + 5}`,
      `F${rowOneSummary.number + 5}`,
      `G${rowOneSummary.number + 5}`,
      `H${rowOneSummary.number + 5}`,
      `I${rowOneSummary.number + 5}`,
      `K${rowOneSummary.number + 5}`,
      `L${rowOneSummary.number + 5}`,
      `M${rowOneSummary.number + 5}`,
      `N${rowOneSummary.number + 5}`,
      `O${rowOneSummary.number + 5}`,
      `J${rowOneSummary.number + 5}`,
      `B${grandTotal.number}`,
      `A${grandTotal.number}`,
    ]);

    summary_sheet.getRow(rowOneSummary.number).font = {
      name: 'Cambria',
      bold: true,
      size: 18,
    };
    summary_sheet.getRow(rowOneSummary.number + 1).font = {
      name: 'Cambria',
      bold: true,
      size: 13,
    };
    summary_sheet.getRow(rowOneSummary.number + 2).font = {
      name: 'Cambria',
      bold: true,
      size: 14,
    };

    summary_sheet.getRow(rowOneSummary.number + 4).font = {
      name: 'Calibri',
      bold: true,
      size: 13,
    };
    summary_sheet.getRow(grandTotal.number).font = {
      name: 'Calibri',
      bold: true,
      size: 13,
    };

    summary_sheet.getRow(grandTotal.number).height = 30;

    summary_sheet.addRow({});
    //end of summary sheet
  }

  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `Formats-of-Form-II-Return-Reconciliation-Summary.xlsx`,
    );
  });
};
