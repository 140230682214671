import React, { FC, Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { css } from '@emotion/react';
import moment from 'moment';
import styled from '@emotion/styled';
import {
  B,
  PrimaryButton,
  SecondaryButton,
  DropdownIcon,
} from '../../../components';
import { DataRangeI } from '../../../types/report.types';
import theme from '../../../theme';
import {
  ExpenseCreation,
  ExpenseMetaData,
  IExpense,
  IntegrationStatusEnum,
  Permission,
} from '../../../types';
import { isAllowed } from '../../../util/permissionUtil';
import {
  OverviewRecord,
  ItemList,
  GenerateBulkModal,
  CustomTreeItemSelector,
} from '../shared';
import {
  useDeleteExpense,
  useGetExpenseCategoriesList,
  useGetExpensesList,
  useGetExpensesOverview,
  useUpdateStatusExpense,
} from '../../../api/expenseHooks';
import { Menu, message, Spin } from 'antd';
import { getApiError } from '../../../util/getApiError';
import { setTreeViewData } from '../util/getExpenseCategory';
import EmployeesSelector from '../../../components/selector/EmployeesSelector';
import RangePickerComponent from '../../../components/selector/RangePickerComponent';
import { ErrorMessageContainer } from '../../settings/integration-apps/components/IntegrationAlerts';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer';

const DEFAULT_MONTH_DEDUCT = 6;

// styled containers
const FilterLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 15px 40px 15px 32px;
  background: ${theme.gray50};
  border-bottom: 1px solid ${theme.gray300};
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    border-bottom: 0px;
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    height: 64px;
    width: inherit;
    padding: 16px;
    ::-webkit-scrollbar {
      height: 3px !important;
    }
  }
`;
const FilterContainer = styled.div`
  display: inline-block;
  margin-right: 15px;
  .ant-picker-range {
    margin-left: 0px !important;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const ListViewLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 40px 0px 32px;
  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 24px 16px 0px 16px;
  }
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    display: none;
  }
  margin-right: 122px;
`;
const MobileHeader = styled.div`
  @media screen and (min-width: 769px) {
    display: none;
  }
  padding: 16px;
  background-color: white;

  border-bottom: 0.5px solid ${theme.gray300};
`;

const ExpensesListPage: FC = () => {
  const me = useSelector((state: RootState) => state.people.me);

  // get expense permissions
  const isAllowedExpenseBasic: boolean = isAllowed(
    Permission.MANAGE_EXPENSE_STATUS_BASIC,
  );
  const isAllowedExpenseSettings: boolean = isAllowed(
    Permission.MANAGE_EXPENSE_SETTINGS,
  );
  const isAllowedManageIntegration: boolean = isAllowed(
    Permission.MANAGE_INTEGRATION,
  );

  const navigate = useNavigate();
  const [expenseList, setExpenseList] = useState<IExpense[]>([]);
  const [approvedCheckList, setApprovedCheckList] = useState<number[]>([]);
  const [dataRange, setDataRange] = useState<DataRangeI>({
    startDate: moment()
      .subtract(DEFAULT_MONTH_DEDUCT, 'month')
      .startOf('month'),
    endDate: moment().endOf('day'),
  });
  const [expenseType, setExpenseType] = useState<number | string>('all');
  const [employee, setEmployee] = useState<number[]>([]);

  const {
    isLoading: categoriesListLoading,
    error: categoriesListError,
    data: getCategoriesList,
  } = useGetExpenseCategoriesList();

  const {
    isLoading: expenseOverviewLoading,
    error: expenseOverviewError,
    data: expenseOverview = [],
    refetch: getExpensesOverview,
  } = useGetExpensesOverview();

  const {
    isLoading: expenseListLoading,
    error: expenseListError,
    data: getExpenseList = [],
    refetch: getExpensesList,
  } = useGetExpensesList(dataRange);

  const {
    isLoading: loadingDeleteExpense,
    error: errorDeleteExpense,
    data: dataDeleteExpense,
    mutate: deleteExpense,
  } = useDeleteExpense();

  const {
    isLoading: loadingUpdateStatusExpense,
    error: errorUpdateStatusExpense,
    data: dataUpdateStatusExpense,
    mutate: updateStatusExpense,
  } = useUpdateStatusExpense();

  const getAllData = () => {
    // navigate to expense home page
    getExpensesList();
    getExpensesOverview();
  };

  const clearSelected = () => {
    setApprovedCheckList([]);
    getAllData();
  };

  useEffect(() => {
    setExpenseList(getExpenseList);
  }, []);

  useEffect(() => {
    filterData(getExpenseList);
  }, [getExpenseList]);

  // success handling
  useEffect(() => {
    if (dataDeleteExpense && !errorDeleteExpense) {
      message.success('Deleted Successfully!');
      // reload data
      getAllData();
    }
  }, [dataDeleteExpense]);

  useEffect(() => {
    if (dataUpdateStatusExpense && !errorUpdateStatusExpense) {
      message.success('Updated Status Successfully!');
      // reload data
      getAllData();
    }
  }, [dataUpdateStatusExpense]);

  // error handling
  useEffect(() => {
    expenseOverviewError && message.error(getApiError(expenseOverviewError));
    expenseListError && message.error(getApiError(expenseListError));
    errorDeleteExpense && message.error(getApiError(errorDeleteExpense));
    errorUpdateStatusExpense &&
      message.error(getApiError(errorUpdateStatusExpense));
    categoriesListError && message.error(getApiError(categoriesListError));
  }, [
    expenseOverviewError,
    expenseListError,
    errorDeleteExpense,
    errorUpdateStatusExpense,
    categoriesListError,
  ]);

  useEffect(() => {
    dataRange && getExpensesList();
  }, [dataRange]);

  useEffect(() => {
    if (expenseType || employee) {
      filterData(getExpenseList);
    }
  }, [expenseType, employee]);

  const filterData = (data: IExpense[]) => {
    if (expenseType || employee) {
      const filter = data.filter((i) => {
        if (expenseType !== 'all' && employee.length > 0) {
          if (i.categoryRef === expenseType && employee.includes(i.userId))
            return i;
        } else if (expenseType !== 'all') {
          if (i.categoryRef === expenseType) return i;
        } else if (employee.length > 0) {
          if (employee.includes(i.userId)) return i;
        }
      });
      setExpenseList(filter);
    }
  };

  const menu = () => (
    <Menu>
      <Menu.Item onClick={() => navigate('/expense/generated-bank-files')}>
        View list of Generated Bank Files
      </Menu.Item>
      <Menu.Item onClick={() => navigate('/expense/categories')}>
        Manage Categories
      </Menu.Item>
      <Menu.Item onClick={() => navigate('/expense/merchants')}>
        Manage Merchants
      </Menu.Item>
    </Menu>
  );

  return (
    <Fragment>
      <MobileHeader>
        <ColumnContainer>
          <B type="b-large-bold">Expenses</B>
          <PrimaryButton
            onClick={() => navigate('/expense/create')}
            size="small">
            Add New Expense
          </PrimaryButton>
        </ColumnContainer>
      </MobileHeader>
      <FilterLayout>
        {(me?.isManager ||
          isAllowedExpenseSettings ||
          isAllowedManageIntegration) && (
          <FilterContainer>
            <EmployeesSelector
              isActive={false}
              onChange={(value) => setEmployee(value)}
              employees={employee}
            />
          </FilterContainer>
        )}
        <FilterContainer>
          <RangePickerComponent
            defaultStartDeductMonths={DEFAULT_MONTH_DEDUCT}
            onChange={(values) => setDataRange(values)}
          />
        </FilterContainer>
        {/* TODO: Check if we can remove this typecasting */}
        {Boolean(
          getCategoriesList?.metaData && getCategoriesList?.metaData.length,
        ) && (
          <FilterContainer
            css={css`
              margin-right: 0px !important;
            `}>
            <CustomTreeItemSelector
              treeDefaultExpandAll={true}
              loading={categoriesListLoading}
              width={300}
              onChange={(values) => setExpenseType(String(values))}
              placeholder="Select expense type"
              treeData={[
                {
                  title: 'All Expenses',
                  value: 'all',
                },
                ...setTreeViewData(
                  getCategoriesList?.metaData as ExpenseMetaData[],
                  [],
                ),
              ]}
              value={expenseType}
              showSearch
              treeNodeFilterProp="title"
              allowClear
              onClear={() => setExpenseType('all')}
            />
          </FilterContainer>
        )}

        <ButtonContainer>
          <PrimaryButton
            onClick={() => navigate('/expense/create')}
            size="small">
            Add New Expense
          </PrimaryButton>
        </ButtonContainer>
      </FilterLayout>
      {isAllowedManageIntegration &&
        getCategoriesList?.type !== ExpenseCreation.SYSTEM &&
        !categoriesListLoading &&
        [IntegrationStatusEnum.ACTION_REQUIRED].includes(
          getCategoriesList?.status as IntegrationStatusEnum,
        ) && (
          <ErrorMessageContainer
            marginLeft="32px"
            marginBottom="0px"
            message={
              <>
                Opps! Looks like your{' '}
                <b>{getCategoriesList?.type.toLocaleLowerCase()}</b> integration
                is not working anymore.
              </>
            }
            type="error"
            showIcon
          />
        )}
      <ListViewLayout>
        <RowContainer>
          {expenseOverviewLoading ? (
            <Spin />
          ) : (
            Boolean(expenseOverview.length) && [
              <ColumnContainer>
                <B type="b-default-semibold">Overview</B>
              </ColumnContainer>,
              expenseOverview.map((report, i) => (
                <OverviewRecord report={report} key={i} index={i} />
              )),
            ]
          )}

          {isAllowedExpenseSettings && (
            <ButtonContainer>
              <GenerateBulkModal
                trigger={
                  <SecondaryButton size="small">
                    Generate Bulk File
                  </SecondaryButton>
                }
                selected={approvedCheckList}
                clearSelected={() => clearSelected()}
                dataSet={getExpenseList}
                disabled={
                  isAllowedExpenseBasic &&
                  Boolean(approvedCheckList && approvedCheckList.length)
                    ? false
                    : true
                }
              />

              <DropdownIcon
                marginLeft={16}
                overlay={menu}
                placement="bottomRight"
                trigger={['click']}
              />
            </ButtonContainer>
          )}
        </RowContainer>
        <ItemList
          creationType={getCategoriesList?.type || ExpenseCreation.SYSTEM}
          integrationStatus={
            getCategoriesList?.status || IntegrationStatusEnum.ACTIVATED
          }
          dataSet={expenseList}
          checkBoxAction={(i) => setApprovedCheckList(i)}
          deleteAction={(id) => deleteExpense(id)}
          updateStatusAction={(data) => updateStatusExpense(data)}
          dataSetLoading={expenseListLoading}
          deleteActionLoading={loadingDeleteExpense}
          updateStatusActionLoading={loadingUpdateStatusExpense}
          checkBoxSelectedList={approvedCheckList}
        />
      </ListViewLayout>
    </Fragment>
  );
};

export default ExpensesListPage;
