import React, { FC, ReactElement, useState } from 'react';
import { Modal } from 'antd';
import styled from '@emotion/styled';
import { GhostButton, PrimaryButton } from './index';
import { ModalProps } from 'antd/lib/modal';
import theme from '../theme';

interface ConfirmationModalProps extends Omit<ModalProps, 'visible'> {
  content: ReactElement;
  onConfirm?: () => void;
  trigger: ReactElement;
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px;
    padding: 0px;
  }
  .ant-modal-body {
    padding: 16px 24px;
  }
  .ant-modal-header {
    padding: 16px 24px;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid ${theme.gray300};
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: reverse;
    padding: 16px 24px;
    border-top: 1px solid ${theme.gray300};
    background: ${theme.gray50};
  }
  @media screen and (max-width: 768px) {
    .ant-modal-body {
      padding: 24px;
    }
  }
`;

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  content,
  onConfirm,
  trigger,
  okText,
  cancelText,
  footer,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    onConfirm && onConfirm();
    handleCancel();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      {trigger &&
        React.cloneElement(trigger, {
          onClick: showModal,
        })}
      <StyledModal
        open={visible}
        onCancel={handleCancel}
        footer={
          footer || [
            <GhostButton onClick={handleCancel} size="small">
              {cancelText}
            </GhostButton>,
            <PrimaryButton size="small" onClick={handleOk}>
              {okText}
            </PrimaryButton>,
          ]
        }
        {...rest}>
        {content}
      </StyledModal>
    </>
  );
};
