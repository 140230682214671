import { Col, Popover, Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';

import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  B,
  CompactTableComp,
  FullScreen,
  fullScreenCss,
  H,
} from '../../../../components';
import { getPayrollSummary } from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';

import theme from '../../../../theme';
import { tableSorter } from '../../../../util/tableSorter';
import { isHaveThreeDots, safeCheckNaN } from '../../../../util/utils';
import { getLastPayrollItem } from '../../../payroll/utils/getLastPayroll';
import { EmployeeExport } from './employee-export/EmployeeExport';
import { Link } from 'react-router-dom';
import { useWindowSize } from '../../../../util/screenSize';
import styled from '@emotion/styled';
import { PeopleI } from '../../../../types/people.types';

interface Props {
  dataPayload: PeopleI[];
  fullScreen: boolean;
  setFullScreen: (fullScreen: boolean) => void;
  loading: boolean;
}

type TableColumnProp = ColumnProps<PeopleI>;
const CusRow = styled(Row)`
  padding-bottom: 16px;
  margin-top: 4px;
  @media (max-width: 760px) {
    padding-bottom: 0px;
  }
`;
const CusDiv = styled.div`
  display: flex;
  margin-top: 10px;
  margin-right: auto;
`;

const SalaryTableCard = styled.div<{ fullScreen: boolean }>`
  width: 100%;
  background: ${theme.white};
  padding: ${(props) => (props.fullScreen ? '32px' : ' 32px 0px 28px')};
  padding-bottom: 100px;
  ${(props) => (props.fullScreen ? fullScreenCss : '')}
`;
const InitialEmployeeTable: FC<Props> = ({
  dataPayload,
  setFullScreen,
  fullScreen,
  loading,
}) => {
  const dispatch: DispatchType = useDispatch();
  const [lastPayrollDate, setLastPayrollDate] = useState('-');
  const [dataSource, setDataSource] = useState<PeopleI[]>([]);
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<number[]>([]);
  const [disableExport, setDisableExport] = useState(true);
  const { isMobile, isTablet } = useWindowSize();
  const payrollSummery = useSelector(
    (state: RootState) => state.payroll.payrollSummery,
  );
  const columns: TableColumnProp[] = [
    {
      title: 'ID',
      width: 100,
      fixed: isMobile || isTablet ? undefined : 'left',
      sorter: (a, b) =>
        tableSorter.defaultSort(
          safeCheckNaN(a.employeeNumber ? a.employeeNumber : '')
            ? a.employeeNumber
            : parseInt(a.employeeNumber as string),
          safeCheckNaN(b.employeeNumber ? b.employeeNumber : '')
            ? b.employeeNumber
            : parseInt(b.employeeNumber as string),
        ),
      sortDirections: ['descend', 'ascend'],
      render: (record: PeopleI) => {
        return <>{record.employeeNumber ? record.employeeNumber : '-'}</>;
      },
    },
    {
      title: 'Full Name',
      width: 180,
      fixed: isMobile || isTablet ? undefined : 'left',
      ellipsis: true,
      sorter: (a, b) => tableSorter.defaultSort(a.fullName, b.fullName),
      sortDirections: ['descend', 'ascend'],
      render: (record: PeopleI) => {
        return (
          <>
            {isHaveThreeDots(String(record.fullName), 20) ? (
              <Popover
                placement="topLeft"
                content={record.fullName}
                trigger="hover">
                {record.fullName ? record.fullName : '-'}
              </Popover>
            ) : record.fullName ? (
              record.fullName
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Name With Initials',
      width: 180,
      ellipsis: true,
      sorter: (a, b) =>
        tableSorter.defaultSort(a.nameWithInitials, b.nameWithInitials),
      sortDirections: ['descend', 'ascend'],
      render: (record: PeopleI) => {
        return (
          <>
            {isHaveThreeDots(String(record.nameWithInitials), 20) ? (
              <Popover
                placement="topLeft"
                content={record.nameWithInitials}
                trigger="hover">
                {record.nameWithInitials ? record.nameWithInitials : '-'}
              </Popover>
            ) : record.nameWithInitials ? (
              record.nameWithInitials
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Joined Date',
      width: 120,
      render: (record: PeopleI) => {
        return <>{record.joinedDate ? record.joinedDate : '-'}</>;
      },
    },
    {
      title: 'NIC',
      width: 160,
      render: (record: PeopleI) => {
        return <>{record.nicOrPassport ? record.nicOrPassport : '-'}</>;
      },
    },
    {
      title: 'Payslip Mail',
      render: (record: PeopleI) => {
        return (
          <>
            {isHaveThreeDots(String(record.paySlipMail), 20) ? (
              <Popover
                placement="topLeft"
                content={record.paySlipMail}
                trigger="hover">
                {record.paySlipMail ? record.paySlipMail : '-'}
              </Popover>
            ) : record.paySlipMail ? (
              record.paySlipMail
            ) : (
              '-'
            )}
          </>
        );
      },
      width: 200,
    },
    {
      title: 'General Information',
      children: [
        {
          title: 'Personal Email',
          ellipsis: true,
          render: (record: PeopleI) => {
            return (
              <>
                {isHaveThreeDots(String(record.loginEmail), 20) ? (
                  <Popover
                    placement="topLeft"
                    content={record.loginEmail}
                    trigger="hover">
                    {record.loginEmail ? record.loginEmail : '-'}
                  </Popover>
                ) : record.loginEmail ? (
                  record.loginEmail
                ) : (
                  '-'
                )}
              </>
            );
          },
          width: 230,
        },
        {
          title: 'Gender',
          render: (record: PeopleI) => {
            return <>{record.gender ? record.gender : '-'}</>;
          },
          width: 100,
        },
        {
          title: 'Personal Mobile',
          render: (record: PeopleI) => {
            return <>{record.personalMobile ? record.personalMobile : '-'}</>;
          },
          width: 180,
        },
        {
          title: 'Designation',
          render: (record: PeopleI) => {
            return <>{record.designation ? record.designation : '-'}</>;
          },
          width: 180,
        },
      ],
    },
    {
      title: 'Bank Information',
      children: [
        {
          title: 'Account Name',
          render: (record: PeopleI) => {
            return <>{record.bankAccountName ? record.bankAccountName : '-'}</>;
          },
          width: 250,
        },
        {
          title: 'Account No.',
          render: (record: PeopleI) => {
            return (
              <>{record.bankAccountNumber ? record.bankAccountNumber : '-'}</>
            );
          },
          width: 170,
        },
        {
          title: 'Bank Code',
          render: (record: PeopleI) => {
            return <>{record.bankBankCode ? record.bankBankCode : '-'}</>;
          },
          width: 130,
        },
        {
          title: 'Branch Code',
          render: (record: PeopleI) => {
            return <>{record.bankBranchId ? record.bankBranchId : '-'}</>;
          },
          width: 130,
        },
      ],
    },
    {
      title: '',
      width: 60,
      fixed: 'right',
      align: 'right',
      render: (_value: string, data: PeopleI) => (
        <Link
          to={{
            pathname: `/contact/personal-details/${data.id}`,
          }}>
          <p
            style={{
              margin: 0,
              fontSize: 14,
              color: theme.blue500,
              cursor: 'pointer',
            }}>
            Edit
          </p>
        </Link>
      ),
    },
  ] as TableColumnProp[];

  useEffect(() => {
    dispatch(getPayrollSummary());
  }, []);

  useEffect(() => {
    setDisableExport(selectedRowsKeys.length ? false : true);
  }, [selectedRowsKeys]);

  useEffect(() => {
    setDataSource(
      dataPayload.filter((item) => item.isPayrollAllowed && !item.isArchived),
    );
    setSelectedRowsKeys(dataPayload.map((item) => item.id));
  }, [dataPayload]);

  useEffect(() => {
    const lastItem = getLastPayrollItem(payrollSummery);
    if (lastItem && lastItem.payrollEndDate) {
      setLastPayrollDate(
        moment(lastItem.payrollEndDate).format('DD MMMM YYYY'),
      );
    }
  }, [payrollSummery]);

  return (
    <>
      <SalaryTableCard id="salaryTable" fullScreen={fullScreen}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
            <Row>
              <H type="h6">Employee Details</H>
            </Row>

            <CusRow>
              <Col>
                <B type="b-default">Employees : {dataSource.length}</B>
              </Col>
              <Col style={{ marginLeft: 40 }}>
                <B type="b-default">Last Updated on : {lastPayrollDate}</B>
              </Col>
            </CusRow>
          </Col>
          <Col xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
            <CusDiv>
              <FullScreen
                fullScreen={fullScreen}
                openFullScreen={() => {
                  setFullScreen(true);
                }}
                exitFullscreen={() => {
                  setFullScreen(false);
                }}
              />
              <EmployeeExport
                disabled={disableExport}
                payload={dataPayload}
                selectedRowKes={selectedRowsKeys}
              />
            </CusDiv>
          </Col>
        </Row>
        <CompactTableComp
          loading={loading}
          columns={[...columns]}
          pagination={false}
          dataSource={dataSource}
          rowKey={(record) => record.id}
          rowSelection={{
            selectedRowKeys: selectedRowsKeys,
            onChange: (
              _selectedRowKeys: React.ReactText[],
              selectedRows: PeopleI[],
            ) => {
              setSelectedRowsKeys(selectedRows.map((item) => item.id));
            },
          }}
          scroll={{
            x: 1000,
            y: fullScreen ? window.innerHeight - 200 : undefined,
          }}
        />
      </SalaryTableCard>
    </>
  );
};

export default InitialEmployeeTable;
