import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Affix, Layout as AntLayout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getMyOrganization, setSideBarCollapsed } from '../../store/actions';
import { useWindowSize } from '../../util/screenSize';
import MobileSideMenu from './sideMenus/MobileSideMenu';
import DesktopSideMenu from './sideMenus/DesktopSideMenu';
import TitleHeader from './headers/TitleHeader';
import TopMenu from './headers/TopMenu';
import { useLocalStorageState } from '../../util/useLocalStorage.util';
import { DispatchType, RootState } from '../../store/reducer';
import { MultipleAlertMessage } from '../../components/alertMessage/MultipleAlertMessage';
import styled from '@emotion/styled';
import { SubscriptionEnum } from '../../types/subscription.types';
import moment from 'moment';

const { Content } = AntLayout;

interface Props {
  children: ReactNode;
  title?: string;
}
const LayoutContent = styled(AntLayout)`
  background-color: white;
`;
const ContentComp = styled(Content)`
  margin-top: 60px;
  background-color: white;
`;

const AlertMessage = styled(MultipleAlertMessage)`
  overflow: hidden !important;
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
`;
const Layout: FC<Props> = ({ children, title }) => {
  const { isMobile, isTablet } = useWindowSize();
  const dispatch: DispatchType = useDispatch();
  const me = useSelector((state: RootState) => state.people.me);
  const [collapsed, setCollapsed] = useLocalStorageState('side-bar', true);
  const [systemUpdatesMessage, setMessageUpdatesMessage] =
    useState<boolean>(true);
  const topMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(getMyOrganization());
  }, []);

  useEffect(() => {
    dispatch(setSideBarCollapsed(collapsed));
  }, [collapsed]);

  return (
    <>
      <Affix offsetTop={0}>
        {(me?.subscriptionStatus === SubscriptionEnum.OVERDUE ||
          me?.subscriptionStatus === SubscriptionEnum.SUSPENDED) && (
          <AlertMessage
            type="error"
            messages={
              me?.subscriptionStatus === SubscriptionEnum.OVERDUE
                ? [
                    'Your account is currently overdue, Please settle all pending payments within 30 days.',
                  ]
                : ['Your account access is restricted pending payment']
            }
          />
        )}
      </Affix>
      <Affix offsetTop={0}>
        {me?.systemUpdates && systemUpdatesMessage && (
          <AlertMessage
            type="warn"
            onClose={() => setMessageUpdatesMessage(false)}
            messages={[
              `Service update: ${me.systemUpdates.description}. ${
                moment(me.systemUpdates.from).isSame(me.systemUpdates.to, 'day')
                  ? `${moment(me.systemUpdates.from).format(
                      'HH:mm',
                    )} to ${moment(me.systemUpdates.to).format(
                      'HH:mm',
                    )} on ${moment(me.systemUpdates.to).format('YYYY-MM-DD')}`
                  : `${me.systemUpdates.from} to ${me.systemUpdates.to}`
              }`,
            ]}
          />
        )}
      </Affix>
      <AntLayout
        style={{
          minHeight: '100vh',
          overflowY: 'auto',
        }}>
        <title>{title || 'Humanised'}</title>

        {isMobile || isTablet ? (
          <MobileSideMenu
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            topMenuRef={topMenuRef}
          />
        ) : (
          <DesktopSideMenu collapsed={collapsed} setCollapsed={setCollapsed} />
        )}
        <LayoutContent>
          <TopMenu
            title={title}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            topMenuRef={topMenuRef}
          />
          <ContentComp>
            {isMobile && <TitleHeader title={title} />}
            {children}
          </ContentComp>
        </LayoutContent>
      </AntLayout>
    </>
  );
};

export default Layout;
