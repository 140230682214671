import styled from '@emotion/styled';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import React, { FC } from 'react';
import { B, TableComp } from '../../../components';
import theme from '../../../theme';
import { LoanInstallmentI } from '../../../types/loan.types';
import { useWindowSize } from '../../../util/screenSize';
import { tableSorter } from '../../../util/tableSorter';
import { numberThousandSeparator } from '../../../util/utils';
import { ExpenseStatusTag } from '../../expenses/shared';

const Container = styled.div`
  border: 1px solid ${theme.gray300};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  @media (max-width: 450px) {
    border: none;
  }
  .ant-table-thead > tr > th {
    padding-right: 32px !important;
  }
`;

const StatusColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

interface Props {
  data: LoanInstallmentI[] | undefined;
  isLoadingdata?: boolean;
}

const InstallmentTable: FC<Props> = ({ data, isLoadingdata }) => {
  const { isTablet, isDesktop } = useWindowSize();

  const columns: ColumnProps<LoanInstallmentI>[] = [
    {
      title: 'Date',
      width: 100,
      dataIndex: 'installmentDate',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.installmentDate ? data.installmentDate : '-'}
        </B>
      ),
      sorter: (a, b) =>
        tableSorter.defaultSort(
          moment(a.installmentDate),
          moment(b.installmentDate),
        ),
      sortDirections: ['ascend', 'descend'],
      key: 'installmentDate',
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Payment Method',
      width: 100,
      dataIndex: 'paymentMethod',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.paymentMethod
            ? data.paymentMethod.charAt(0).toUpperCase() +
              data.paymentMethod.substr(1).toLowerCase()
            : '-'}
        </B>
      ),
    },
    {
      title: 'Status',
      width: 100,
      dataIndex: 'installmentStatus',
      render: (_value: string, data) => (
        <StatusColumn>
          <ExpenseStatusTag status={data.installmentStatus} />
        </StatusColumn>
      ),
    },
    {
      title: 'Amount',
      width: 150,
      dataIndex: 'amount',
      align: 'right',
      render: (_value: string, data) => (
        <B type="b-small" pr="32px">
          LKR {numberThousandSeparator(data.amount)}
        </B>
      ),
    },
  ];
  return (
    <Container>
      <TableComp
        loading={isLoadingdata}
        pagination={false}
        columns={columns}
        size="middle"
        dataSource={data}
        scroll={{
          x: isTablet ? 1250 : isDesktop ? 1075 : 1075,
          y: 90,
        }}
      />
    </Container>
  );
};

export default InstallmentTable;
