import React, { FC } from 'react';

import { Collapse, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import styled from '@emotion/styled';

import { B, Div } from '../../../../components';
import { RootState } from '../../../../store/reducer';
import theme from '../../../../theme';
import { SalaryInformationView } from '../../../../types/payroll.types';
import { addDotsForLongText } from '../../../../util/utils';
import { payrollParam } from './utils/payslipPageTypes';

const CollapseSection = styled(Collapse)`
  border: none;
  background-color: ${theme.white};
  .ant-collapse-header {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: ${theme.black};
    padding: 6px 12px 6px 8px !important;
    background: ${theme.gray150} !important;
    border-radius: 4px !important;
  }
  .ant-collapse-content {
    border-top: none;
    .ant-collapse-content-box {
      padding: 0px 12px 0px 8px !important;
    }
  }
  .ant-collapse-item {
    padding: 0px 16px 16px !important;
    border: none !important;
    margin-bottom: 0px;
  }
  .ant-collapse-item-active {
    padding: 0px 16px 16px !important;
  }
  .ant-collapse-header .ant-collapse-arrow {
    top: 12px !important;
    font-weight: 900 !important;
  }

  .ant-collapse-arrow svg {
    margin-right: 0px;
  }
`;

const CollapseItems = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 1px solid ${theme.gray300};
`;

const EditPersonalDetailLink = styled(Link)`
  display: flex;
  flex-direction: row-reverse;
  padding-top: 12px;
`;

const { Panel } = Collapse;

const BankInformation: FC = () => {
  const params = useParams<payrollParam>();
  const payrollDetails = useSelector(
    (state: RootState) => state.payroll.allPayrollDetails,
  );
  const empPayroll = payrollDetails?.empPayroll.find(
    (item) => item.userId === Number(params.employeeKey),
  );

  const people = empPayroll?.user;
  const isCompletedPayRun = payrollDetails?.status === 'COMPLETED';
  const primaryAccountItems = [
    {
      key: '1',
      description: 'Name',
      details: empPayroll?.bankAccountName ? empPayroll?.bankAccountName : '-',
    },
    {
      key: '2',
      description: 'Account No',
      details: empPayroll?.bankAccountNumber
        ? empPayroll?.bankAccountNumber
        : '-',
    },
    {
      key: '3',
      description: 'Bank Code',

      details: empPayroll?.bankBranch
        ? empPayroll.bankBranch.bankBankCode
        : '-',
      tooltip: empPayroll?.bank ? empPayroll.bank.bankName : null,
    },
    {
      key: '4',
      description: 'Branch  Code',
      details: empPayroll?.bankBranch ? empPayroll.bankBranch.branchCode : '-',
      tooltip: empPayroll?.bankBranch ? empPayroll.bankBranch.branchName : null,
    },

    ...(empPayroll?.primaryAccountAmount
      ? [
          {
            key: '5',
            description: 'Primary Acc - Amount',
            details: String(empPayroll?.primaryAccountAmount),
          },
        ]
      : []),
    {
      key: '6',
      description: 'Payslip Email',
      details: empPayroll?.user.paySlipMail ? empPayroll.user.paySlipMail : '-',
      tooltip: empPayroll?.user.paySlipMail
        ? empPayroll.user.paySlipMail
        : null,
    },
  ];
  const secondaryAccountItems = [
    {
      key: '1',
      description: 'Name',
      details: empPayroll?.secondaryBankAccountName
        ? empPayroll?.secondaryBankAccountName
        : '-',
    },
    {
      key: '2',
      description: 'Account No',
      details: empPayroll?.secondaryBankAccountNumber
        ? empPayroll?.secondaryBankAccountNumber
        : '-',
    },
    {
      key: '3',
      description: 'Bank Code',
      details: empPayroll?.secondaryBankBankCode
        ? empPayroll?.secondaryBankBankCode
        : '-', // TODO: fix this. Get data from API (Show both bank name and code)
    },
    {
      key: '4',
      description: 'Branch Branch',
      details: empPayroll?.secondaryBankBranchCode
        ? empPayroll.secondaryBankBranchCode
        : '-', // TODO: fix this. Get data from API (Show both branch name and code)
    },
  ];
  const dataItems = [
    {
      key: '1',
      description: 'Remark',
      details: empPayroll?.remark ? empPayroll?.remark : '-',
    },
    {
      key: '2',
      description: 'Company ref',
      details: empPayroll?.companyRef ? empPayroll?.companyRef : '-',
    },
    {
      key: '3',
      description: 'Sender Mobile',
      details: people?.organization?.telephoneNumber
        ? people?.organization?.telephoneNumber
        : '-',
    },
    {
      key: '4',
      description: 'Receiver Mobile',
      details: people?.personalMobile ? people?.personalMobile : '-',
    },
    {
      key: '5',
      description: 'NIC',
      details: people?.nicOrPassport ? people?.nicOrPassport : '-',
    },
  ];

  return (
    <>
      <Div pb="8px">
        <CollapseSection
          accordion
          defaultActiveKey={['1']}
          expandIconPosition="right">
          <Panel header="Primary Account" key="1">
            {primaryAccountItems.map((item) => {
              return (
                <CollapseItems key={item.key}>
                  <B type="b-default">{item.description}</B>
                  <B type="b-default">
                    <Tooltip title={item.tooltip}>
                      {item.details
                        ? addDotsForLongText(item.details, 20)
                        : null}
                    </Tooltip>
                  </B>
                </CollapseItems>
              );
            })}
          </Panel>
          {empPayroll?.primaryAccountAmount ? (
            <Panel header="Secondary Account" key="2">
              {secondaryAccountItems.map((item) => {
                return (
                  <CollapseItems key={item.key}>
                    <B type="b-default">{item.description}</B>
                    <B type="b-default">
                      {item.details
                        ? addDotsForLongText(item.details, 20)
                        : '-'}
                    </B>
                  </CollapseItems>
                );
              })}
            </Panel>
          ) : null}
          <Panel header="Other Details" key="3">
            {dataItems.map((item) => {
              return (
                <CollapseItems key={item.key}>
                  <B type="b-default">{item.description}</B>
                  <B type="b-default">
                    {item.details ? addDotsForLongText(item.details, 20) : '-'}
                  </B>
                </CollapseItems>
              );
            })}
            {!isCompletedPayRun &&
              params.salaryInformationViewType ===
                SalaryInformationView.ALL_EMPLOYEE && (
                <EditPersonalDetailLink
                  to={{
                    pathname: `/contact/personal-details/${params.employeeKey}`,
                  }}>
                  <B type="b-default" color={theme.blue500}>
                    Edit Personal Details
                  </B>
                </EditPersonalDetailLink>
              )}
          </Panel>
        </CollapseSection>
      </Div>
    </>
  );
};

export default BankInformation;
