import React, { FC } from 'react';
import { B, Div, H } from '../../../components';
import { Col, Row, Image } from 'antd';
import { HeaderHumanisedNameIcon, TeamConfig } from '../../../assets';
import styled from '@emotion/styled';
import theme from '../../../theme';

const ContractSignedHeader = styled(Row)`
  background: ${theme.blue900};
  display: flex;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  padding: 15px 16px;
  @media (min-width: 768px) and (max-width: 1920px) {
    padding: 15px 32px;
  }
  height: 72px;
`;

const ContractSignedThankYou: FC = () => {
  return (
    <>
      <ContractSignedHeader justify="start">
        <Image preview={false} src={HeaderHumanisedNameIcon} />
      </ContractSignedHeader>
      <Row></Row>
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Div height="717px" paddingTop="127px">
          <Col span={24} style={{ textAlign: 'center' }}>
            <Image
              width="338px"
              height="338px"
              preview={false}
              src={require(`../../../assets/images/contractSuccess.png`)}
            />
          </Col>
          <Col span={24} style={{ textAlign: 'center', marginTop: '20px' }}>
            <B type="b-extra-large-bold">Great !</B>
          </Col>
          <Col span={24} style={{ textAlign: 'center', marginTop: '10px' }}>
            <B type="b-default">
              You have signed the document. You can exit the browser now.
            </B>
          </Col>

          <Col
            span={24}
            style={{ textAlign: 'center', marginTop: '10px' }}></Col>
        </Div>
      </Row>
    </>
  );
};

export default ContractSignedThankYou;
