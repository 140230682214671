import React, { FC } from 'react';
import { Row, Col } from 'antd';
import { B } from '../../../../../components';
import theme from '../../../../../theme';
import styled from '@emotion/styled';
import { PayrollItemI } from '../../../../../types/payroll.types';
import { numberThousandSeparator } from '../../../../../util/utils';
interface Props {
  salaryItems: PayrollItemI[];
}

const CutsCol = styled(Col)`
  text-align: end;
`;
export const SalaryItem: FC<Props> = ({ salaryItems }) => {
  return (
    <>
      {salaryItems.map((item) => {
        return (
          <Row gutter={[32, 12]} key={item.payTitle}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <B type="b-small" color={theme.gray600}>
                {item.payTitle ? item.payTitle : '-'}
              </B>
            </Col>
            <CutsCol xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <B type="b-small" color={theme.black}>
                {numberThousandSeparator(item.amount)}
              </B>
            </CutsCol>
          </Row>
        );
      })}
    </>
  );
};
