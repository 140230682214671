import React, { FC, Fragment, useEffect, useState } from 'react';

import { Divider, Image, Modal, Spin, Tooltip } from 'antd';
import { orderBy } from 'lodash';
import moment from 'moment';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSelector } from 'react-redux';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
  AvatarComponent,
  B,
  ConfirmationModal,
  PrimaryButton,
  SecondaryButton,
} from '../../../components';
import { SwitchComponent } from '../../../components/SwitchComponent';
import { RootState } from '../../../store/reducer';
import theme from '../../../theme';
import {
  IExpense,
  IExpenseStatus,
  Permission,
  UpdateStatusExpenseI,
  UserRoleIdEnum,
} from '../../../types';
import { isAllowed } from '../../../util/permissionUtil';
import { APPROVED_STAGES, ExpenseStatusTag, PriceTag } from './';

const DocSpinner = styled(Spin)`
  display: block;
  margin: 0 auto;
`;

const StyledModal = styled(Modal)`
  top: 50px !important;
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-body {
    padding: 26px 24px 24px 24px;
  }
  .ant-modal-header {
    border-radius: 8px 8px 0px 0px;
  }
  .ant-modal-footer {
    padding: 16px 24px;
  }
  @media screen and (max-width: 768px) {
    .ant-modal-body {
      padding: 24px;
    }
  }
`;
const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ActionLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const ActionRight = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  button {
    margin-left: 16px !important;
  }
`;
const CancelBtn = styled(SecondaryButton)`
  height: 40px;
  border: none;
  box-shadow: none;
  &:hover {
    background-color: transparent;
    color: ${theme.blue600};
  }
`;
const OutlineBtn = styled(SecondaryButton)`
  height: 40px;
`;
const ConfirmBtn = styled(PrimaryButton)`
  height: 40px;
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const UserDetails = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
`;
const DetailsContainer = styled.div<{ extraTopMargin: boolean }>`
  ${(props) => props.extraTopMargin && 'margin-top: 32px;'}
  display: flex;
  flex-direction: column;
`;
const StyledDivider = styled(Divider)`
  margin: 8px 0px;
`;
const ContentArea = styled.div`
  flex: 4;
  @media screen and (max-width: 768px) {
    flex: 2;
  }
`;
const Container = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    margin-top: 16px;
  }
`;
const LogContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: ${theme.gray100};
  border: 1px solid ${theme.gray300};
  border-radius: 4px;
  min-height: 100px;
  max-height: 150px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px !important;
  }
  p {
    margin-bottom: 8px;
  }
`;
const FileContainer = styled.div`
  margin-top: 12px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  ::-webkit-scrollbar {
    height: 5px !important;
  }
  @media screen and (max-width: 768px) {
    ::-webkit-scrollbar {
      height: 3px !important;
    }
  }
`;
const File = styled.div`
  margin-right: 12px;
`;
const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ReferenceNoContainer = styled.div`
  background: ${theme.gray50};
  border-radius: 4px;
  padding: 2px 8px;
`;
const documentStyle = css`
  cursor: pointer;
  width: 97px !important;
  height: 123px !important;
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 97px !important;
    height: 123px !important;
    border: 1px solid ${theme.gray300};
    border-radius: 4px;
    box-sizing: border-box;
  }
  .react-pdf__Page__textContent {
    border: none;
    border-radius: 0px;
    display: none;
  }
`;

const ExpenseDetail: FC<{
  title: string;
  content: React.ReactElement;
}> = ({ title, content }) => {
  return (
    <Fragment>
      <RowContainer>
        <B type="b-default" color={theme.gray600} cssStyle="flex: 1;">
          {title}
        </B>
        <ContentArea>{content}</ContentArea>
      </RowContainer>
      <StyledDivider />
    </Fragment>
  );
};

interface ViewExpenseModalProps {
  deleteAction: (id: number) => void;
  updateStatusAction: (data: UpdateStatusExpenseI) => void;
  deleteActionLoading: boolean;
  updateStatusActionLoading: boolean;
  trigger: React.ReactElement;
  data: IExpense;
}

export const ViewExpenseModal: FC<ViewExpenseModalProps> = ({
  trigger,
  data,
  deleteAction,
  updateStatusAction,
  deleteActionLoading = false,
  updateStatusActionLoading = false,
}) => {
  // get expense permissions
  const isAllowedExpenseBasic: boolean = isAllowed(
    Permission.MANAGE_EXPENSE_STATUS_BASIC,
  );
  const expenseAdvancedPermission: boolean = isAllowed(
    Permission.MANAGE_EXPENSE_STATUS_ADVANCED,
  );

  const me = useSelector((state: RootState) => state.people.me);

  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; //or pdfjsWorker
  }, []);

  const logs = orderBy(data.logs || [], ['id'], ['desc']);

  // Check if logged in user is an owner or expense created not by him/her
  const basicAllowed: boolean =
    me?.roleId === UserRoleIdEnum.OWNER || data.creatorId !== me?.id;

  return (
    <>
      {trigger &&
        React.cloneElement(trigger, {
          onClick: showModal,
        })}
      <StyledModal
        visible={visible}
        width={540}
        title={<B type="b-large-semibold">Expense</B>}
        onCancel={handleCancel}
        footer={
          <ActionContainer>
            <ActionLeft>
              <CancelBtn onClick={handleCancel}>Close</CancelBtn>
            </ActionLeft>
            <ActionRight>
              {!isAllowedExpenseBasic &&
                data.status === IExpenseStatus.PENDING && (
                  <ConfirmationModal
                    title={<B type="b-large-semibold">Confirmation Alert</B>}
                    content={
                      <B type="b-large">
                        Are you sure you want to delete this reimbursement
                        entry, by deleting this you will not see this item blah
                        blah
                      </B>
                    }
                    okText="Yes, Delete"
                    cancelText="No"
                    onConfirm={() => {
                      deleteAction(data.id);
                      handleCancel();
                    }}
                    trigger={
                      <OutlineBtn loading={deleteActionLoading}>
                        Delete
                      </OutlineBtn>
                    }
                  />
                )}
              {isAllowedExpenseBasic &&
                [IExpenseStatus.PENDING, IExpenseStatus.APPROVED].includes(
                  data.status as IExpenseStatus,
                ) &&
                basicAllowed && (
                  <OutlineBtn
                    loading={updateStatusActionLoading}
                    onClick={() => {
                      updateStatusAction({
                        id: data.id,
                        status: IExpenseStatus.REJECTED,
                      });
                      handleCancel();
                    }}>
                    Reject
                  </OutlineBtn>
                )}
              {isAllowedExpenseBasic &&
                !APPROVED_STAGES.includes(data.status) &&
                basicAllowed && (
                  <>
                    {data.status === IExpenseStatus.REJECTED && (
                      <OutlineBtn
                        loading={updateStatusActionLoading}
                        onClick={() => {
                          updateStatusAction({
                            id: data.id,
                            status: IExpenseStatus.APPROVED,
                          });
                          handleCancel();
                        }}>
                        Approve
                      </OutlineBtn>
                    )}
                    {data.status === IExpenseStatus.PENDING && (
                      <ConfirmBtn
                        loading={updateStatusActionLoading}
                        onClick={() => {
                          updateStatusAction({
                            id: data.id,
                            status: IExpenseStatus.APPROVED,
                          });
                          handleCancel();
                        }}>
                        Approve
                      </ConfirmBtn>
                    )}
                  </>
                )}
            </ActionRight>
          </ActionContainer>
        }>
        <ColumnContainer>
          {/* Show user details only on admin view */}
          {isAllowedExpenseBasic && (
            <RowContainer>
              {data.owner.profilePicture != null ? (
                <AvatarComponent
                  size={40}
                  src={data.owner.profilePicture}
                  shape="circle"
                  border="none"
                />
              ) : (
                <AvatarComponent
                  shape="circle"
                  size={40}
                  title={data.owner.fullName.charAt(0).toUpperCase()}
                />
              )}
              <UserDetails>
                <B type="b-large-semibold">{data.owner.fullName}</B>
                <B type="b-small">{data.owner.loginEmail}</B>
              </UserDetails>
            </RowContainer>
          )}
          <DetailsContainer extraTopMargin={Boolean(isAllowedExpenseBasic)}>
            <ColumnContainer>
              <ExpenseDetail
                title="Date"
                content={
                  <DateContainer>
                    <B type="b-small">
                      {moment(data.date).format('DD MMM YYYY')}
                    </B>
                    <ReferenceNoContainer>
                      <B type="b-small" color={theme.gray600}>
                        Ref: {data.reference}
                      </B>
                    </ReferenceNoContainer>
                  </DateContainer>
                }
              />
              <ExpenseDetail
                title="Merchant"
                content={
                  <B type="b-small">{data.merchant ? data.merchant : '-'}</B>
                }
              />
              <ExpenseDetail
                title="Amount"
                content={<PriceTag amount={data.amount} />}
              />
              <ExpenseDetail
                title="Category"
                content={<B type="b-small">{data.category}</B>}
              />
              <ExpenseDetail
                title="Status"
                content={
                  <ActionContainer>
                    <ExpenseStatusTag status={data.status} />
                    {expenseAdvancedPermission &&
                      basicAllowed &&
                      data.status === IExpenseStatus.APPROVED && (
                        <RowContainer>
                          <B
                            type="b-small"
                            marginRight="13px"
                            display="flex"
                            alignItems="center">
                            Category Name
                            <Tooltip
                              placement="top"
                              title="Please generate bank file before making this record mark as paid"
                              color={theme.blue900}
                              key={theme.blue900}>
                              Mark as paid
                            </Tooltip>
                          </B>
                          <SwitchComponent
                            loading={false}
                            defaultChecked={false}
                            onChange={(status) => {
                              if (status) {
                                updateStatusAction({
                                  id: data.id,
                                  status: IExpenseStatus.PAID,
                                });
                              }
                              handleCancel();
                            }}
                          />
                        </RowContainer>
                      )}
                  </ActionContainer>
                }
              />
              <ExpenseDetail
                title="Note"
                content={<B type="b-small">{data.note ? data.note : '-'}</B>}
              />
            </ColumnContainer>
          </DetailsContainer>
          {Boolean(data.files && data.files.length) && (
            <Container>
              <B type="b-default-semibold">Attachment</B>
              <FileContainer>
                {data.files &&
                  data.files.map((file, i) => {
                    if (file.match(/\.(jpg|jpeg|png|gif)$/i)) {
                      return (
                        <File key={i}>
                          <Image
                            width={97}
                            height={123}
                            src={file}
                            placeholder={<Image preview={false} src={file} />}
                            style={{
                              cursor: 'pointer',
                              objectFit: 'contain',
                              border: `1px solid ${theme.gray300}`,
                              borderRadius: 4,
                            }}
                          />
                        </File>
                      );
                    }
                    if (file.match(/\.(pdf)$/i)) {
                      return (
                        <File key={i}>
                          <a href={file} target="_blank" rel="noreferrer">
                            <Document
                              file={file}
                              loading={<DocSpinner />}
                              onLoadError={console.error}
                              css={documentStyle}>
                              <Page pageNumber={1} />
                            </Document>
                          </a>
                        </File>
                      );
                    }
                    return null;
                  })}
              </FileContainer>
            </Container>
          )}
          {Boolean(logs && logs.length) && (
            <Container>
              <B type="b-default-semibold">Audit Log</B>
              <LogContainer>
                {logs &&
                  logs.map((log) => {
                    if (log.mapper.actor) {
                      log.message = log.message.replace(
                        /{@:actor}/gi,
                        String(log.mapper.actor.display),
                      );
                    }
                    return (
                      <B key={log.id} type="b-small" color={theme.gray600}>
                        <Tooltip
                          placement="top"
                          title={moment(log.createdAt).format(
                            'Do MMMM, YYYY hh:mm A',
                          )}
                          color={theme.blue900}
                          key={theme.blue900}>
                          {moment(log.createdAt).format('MMM DD')}
                        </Tooltip>{' '}
                        : {log.message}
                      </B>
                    );
                  })}
              </LogContainer>
            </Container>
          )}
        </ColumnContainer>
      </StyledModal>
    </>
  );
};
