import React, { useState } from 'react';
import { useGetAttendanceURLData } from '../../../api/attendanceHooks';
import {
  MenuStyle,
  MenuItem,
} from '../../payroll/pages/payroll-home/PayRunTable';
import { DeleteModal, B } from '../../../components';
import { useEffect } from 'react';
import { message } from 'antd';
import { monthToStringTrimmed } from '../../../util/timeConvertion';
import {
  useDeleteAttendance,
  useUnarchiveAttendance,
} from '../../../api/attendanceHooks';
import { PayrunType } from '../../../types/payroll.types';
import { useQueryClient } from 'react-query';
import { AttendanceFilterTypes } from '../../../types/attendance.types';
import { useGetAttendanceData } from '../../../api/attendanceHooks';
import PayRollModal from '../../payroll/pages/payroll-home/shared/PayRunModal';
import { useAddNewPayRun } from '../../../api/payrollHooks';
import { useNavigate } from 'react-router-dom';
import { useGetExistingPayruns } from '../../../api/payrollHooks';

const AttendanceDropdownModel = ({ data }) => {
  const queryClient = useQueryClient();
  const attendanceId = data.id;
  const [isPayrollModalVisible, setPayrollModalVisible] = useState(false);

  const { data: payRunData, mutate, error, isLoading } = useAddNewPayRun();
  const navigate = useNavigate();

  const { data: getExistingPayruns, mutate: getExistingPayrunsList } =
    useGetExistingPayruns();

  //archive and unarchive attendance
  const {
    isLoading: loadingDeleteAttendance,
    error: errorDeleteAttendance,
    mutate: deleteAttendance,
    data: deleteAttendanceForData,
  } = useDeleteAttendance();

  const {
    isLoading: loadingDeleteAttendanceUnarchive,
    error: errorDeleteAttendanceUnarchive,
    mutate: unarchiveAttendance,
    data: deleteUnarchiveAttendanceForData,
  } = useUnarchiveAttendance();

  const {
    data: timeAttendanceData,
    isLoading: timeAttendanceIsLoading,
    isSuccess: timeAttendanceIsSuccess,
    refetch: refetchTimeAttendance,
  } = useGetAttendanceData();

  useEffect(() => {
    if (payRunData && !error) {
      setPayrollModalVisible(false);
      navigate('/Payroll-view/' + payRunData.id);
    }
  }, [payRunData]);

  useEffect(() => {
    getExistingPayrunsList({
      month: String(monthToStringTrimmed(data.month)),
      year: Number(data.year),
    });
  }, [monthToStringTrimmed(data.month), data.year]);

  useEffect(() => {
    if (deleteAttendanceForData || deleteUnarchiveAttendanceForData) {
      refetchTimeAttendance();
    }
  }, [
    deleteAttendanceForData,
    deleteUnarchiveAttendanceForData,
    refetchTimeAttendance,
  ]);

  const handleDeleteAttendance = (attendanceId: string) => {
    deleteAttendance(String(attendanceId), {
      onSuccess: () => {
        queryClient.invalidateQueries('attendanceData');
      },
    });
  };

  const handleUnarchiveAttendance = (attendanceId: string) => {
    unarchiveAttendance(String(attendanceId), {
      onSuccess: () => {
        queryClient.invalidateQueries('attendanceData');
      },
    });
  };

  const filterDraftedPayrolls = getExistingPayruns?.filter(
    (i) =>
      i.payrunType === PayrunType.MID_CYCLE_PAYRUN && i.status === 'DRAFTED',
  );
  const filterPayrolls = getExistingPayruns?.filter(
    (i) => i.payrunType === data.payrunType,
  );
  const payrollValidation =
    filterPayrolls &&
    ((data.payrunType === PayrunType.MONTHLY_PAYRUN &&
      !!filterPayrolls?.length) ||
      // || payrunType === PayrunType.MID_CYCLE_PAYRUN && !!completedPayrolls?.length
      // || payrunType === PayrunType.MID_CYCLE_PAYRUN && filterPayrolls?.length
      (data.payrunType === PayrunType.MONTHLY_PAYRUN &&
        !!filterDraftedPayrolls?.length));

  const getAttendanceURLData = useGetAttendanceURLData();

  const handleDownload = async () => {
    try {
      const { url } = await getAttendanceURLData.mutateAsync(attendanceId);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'AttendanceData.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <MenuStyle>
      <MenuItem key="1" onClick={handleDownload}>
        Download
      </MenuItem>{' '}
      {data.status === 'ARCHIVED' ? (
        <MenuItem key="2">
          <DeleteModal
            title="Unarchive"
            buttonLabel="Unarchive"
            content="Are you sure you want to Unarchive?"
            onClickCancel={() => {
              handleUnarchiveAttendance(attendanceId);
            }}
            openModal={
              <B padding="7px 0px 7px 4px" type="b-default">
                Unarchive
              </B>
            }></DeleteModal>
        </MenuItem>
      ) : null}
      {data.status === AttendanceFilterTypes.APPROVED ? (
        <MenuItem key="3">
          <DeleteModal
            title="Archive"
            buttonLabel="Archive"
            content="Are you sure you want to Archive?"
            onClickCancel={() => {
              handleDeleteAttendance(attendanceId);
            }}
            openModal={
              <B padding="7px 0px 7px 4px" type="b-default">
                Archive
              </B>
            }></DeleteModal>
        </MenuItem>
      ) : null}
      {data.status === AttendanceFilterTypes.APPROVED && !payrollValidation ? (
        <>
          {/* <MenuItem key="5" onClick={() => setPayrollModalVisible(true)}>
            Create Payroll
          </MenuItem> */}
          <PayRollModal
            visible={isPayrollModalVisible}
            onClose={() => setPayrollModalVisible(false)}
            onSubmit={async (values) => {
              try {
                mutate(values);
                // navigate('/Payroll-view/' + payRunData.id);
              } catch (error: any) {
                message.error('Something went wrong');
              }
            }}
            attendanceData={data}
          />
        </>
      ) : null}
      {data.status === AttendanceFilterTypes.DRAFT ? (
        <MenuItem key="4">
          <DeleteModal
            title="Delete"
            buttonLabel="Delete"
            content="Are you sure you want to Delete?"
            onClickCancel={() => {
              handleDeleteAttendance(attendanceId);
            }}
            openModal={
              <B padding="7px 0px" type="b-default">
                Delete
              </B>
            }></DeleteModal>
        </MenuItem>
      ) : null}
    </MenuStyle>
  );
};

export default AttendanceDropdownModel;
