import React, { FC, useState } from 'react';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { AvatarComponent, B, SecondaryButton } from '../../../../components';
import { addNewOwner } from '../../../../store/actions';
import {
  CreateOwnerI,
  OrganizationsDataByOwnerI,
} from '../../../../types/superAdmin.types';
import { numberThousandSeparator } from '../../../../util/utils';
import CreateOrganizationModal from '../../shared/CreateOrganizationModal';
import { calculateSummary } from '../../SuperAdminUtil';
import { DispatchType } from '../../../../store';

interface Props {
  organizationDetails: OrganizationsDataByOwnerI[];
}

type Params = { accountId: string };

const OwnerDetails: FC<Props> = ({ organizationDetails }) => {
  const [visible, setVisible] = useState(false);
  const dispatch: DispatchType = useDispatch();

  const param = useParams<Params>();
  if (!organizationDetails || !organizationDetails.length) {
    return null;
  }

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <B type="b-default-semibold" pb="14px">
            Owner
          </B>
          <Row>
            {organizationDetails[0]?.profilePicture ? (
              <AvatarComponent
                src={organizationDetails[0].profilePicture}
                shape="circle"
                border="none"
                size={50}
              />
            ) : (
              <AvatarComponent
                fontWeight={600}
                shape="circle"
                size={50}
                title={organizationDetails[0].fullName.charAt(0).toUpperCase()}
              />
            )}

            <div>
              <B type="b-default-semibold" pt="5px" pl="14px">
                {organizationDetails[0]?.fullName || '-'}
              </B>
              <B type="b-small" pl="14px">
                {organizationDetails[0]?.loginEmail || '-'}
              </B>
            </div>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
          <B type="b-default-semibold" pb="14px">
            Users
          </B>
          <Row>
            <Col xs={24} sm={24} md={12} lg={7} xl={7} xxl={7}>
              <B type="b-default" pt="5px">
                Total Payroll Enabled
              </B>
              <B type="b-default-semibold">
                {numberThousandSeparator(
                  calculateSummary(organizationDetails).payrollEnabledUsers,
                )}
              </B>
            </Col>
            <Col xs={24} sm={24} md={12} lg={7} xl={7} xxl={7}>
              <B type="b-default" pt="5px">
                Total Login Allowed
              </B>
              <B type="b-default-semibold">
                {numberThousandSeparator(
                  calculateSummary(organizationDetails).loginAllowedUsers,
                )}
              </B>
            </Col>
            <Col xs={24} sm={24} md={12} lg={10} xl={10} xxl={10}>
              <SecondaryButton
                mr="16px"
                icon={<PlusOutlined />}
                size="small"
                onClick={() => setVisible(true)}>
                Create Organization
              </SecondaryButton>
            </Col>
          </Row>
        </Col>
      </Row>

      {visible && (
        <CreateOrganizationModal
          visible={visible}
          handleCancel={() => {
            setVisible(false);
          }}
          onSubmit={async (value: CreateOwnerI) => {
            if (organizationDetails[0]?.loginEmail) {
              await dispatch(
                addNewOwner(
                  {
                    ...value,
                    loginEmail: organizationDetails[0].loginEmail,
                  },
                  param.accountId,
                ),
              );
              window.location.reload();
            }

            setVisible(false);
          }}
          ownerName={organizationDetails[0]?.fullName}
        />
      )}
    </>
  );
};

export default OwnerDetails;
