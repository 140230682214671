import React, { FC, PropsWithChildren } from 'react';

interface State {
  zoomValue: number;
  ratio: number;
}

type Props = Partial<State> & PropsWithChildren;

type ZoomContextType = State & {
  setZoomValue: (v: number) => void;
};

const initialValue: State = { zoomValue: 100, ratio: 1 };

const ZoomContext = React.createContext<ZoomContextType>({
  ...initialValue,
  setZoomValue: () => undefined,
});
ZoomContext.displayName = 'ZoomProviders';

const ZoomProvider: FC<Props> = ({ zoomValue, ...props }) => {
  const [state, setState] = React.useState(initialValue);

  const setZoomValue = (zoomValue: number) => {
    setState({
      zoomValue,
      ratio: zoomValue / 100,
    });
  };

  return <ZoomContext.Provider value={{ ...state, setZoomValue }} {...props} />;
};

function useZoom() {
  const context = React.useContext(ZoomContext);
  if (context === undefined) {
    throw new Error(`useZoom must be used within a ZoomProviders`);
  }
  return context;
}

export { ZoomProvider, useZoom };
