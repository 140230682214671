import React, { FC, useState } from 'react';
import { css } from '@emotion/react';
import { B, Div } from '../../../../components';
import {
  Reminders,
  ReminderTypeEnum,
} from '../../../../types/notification.types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Col, Row } from 'antd';
import { ContractReminderEnum } from '../../../../types/contract.types';
import styled from '@emotion/styled';
import ContractSVG from '../../../../assets/Icons/note-icons/contract.svg';
interface Props {
  item: Reminders;
  isCollapsed: boolean;
}

const Contract = styled.div<{ collapse: boolean }>`
  border-radius: 4px;
  width: 100%;
  background: ${(props) => (props.collapse ? '#F5F5F5' : '#ffffff')};
`;
const NotificationDate = styled.div`
  margin-left: auto !important;
  display: flex;
  flex-direction: row;
`;
const CollapseRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 42px;
  width: 100%;
`;
const NotificationContract: FC<Props> = ({ item, isCollapsed }) => {
  const [collapse, setCollapse] = useState<boolean>(false);
  return (
    <>
      {item.type === ReminderTypeEnum.CONTRACT && (
        <Contract
          collapse={collapse}
          onClick={() => {
            if (collapse) {
              setCollapse(false);
            } else {
              setCollapse(true);
            }
          }}>
          <Row
            css={css`
              padding: 12px 10px;
              display: flex;
              flex-direction: row;
              width: 100%;
            `}
            onClick={() => {
              if (collapse) {
                setCollapse(false);
              } else {
                setCollapse(true);
              }
            }}>
            <Div p="4px 10px" display="flex" width="100%">
              <Col span={19}>
                <Div display="flex">
                  <img src={ContractSVG} width={22} height={22} />
                  <B type="b-small" pl="10px">
                    {item.payload.type === ContractReminderEnum.TO_SIGN
                      ? `You have “ ${item.payload.data.contract.name} ” to sign`
                      : `${item.payload.data.user.fullName} Signed “
                  ${item.payload.data.contract.name} ” today.`}
                  </B>
                </Div>
              </Col>
            </Div>
          </Row>

          {!isCollapsed || collapse ? (
            <>
              <Div margin="1px 45px 4px">
                <Link to={`/contract/view/${item.payload.data.contractId}`}>
                  <B type="b-small-semibold" pr="24px" color="#0052EA">
                    View Contract
                  </B>
                </Link>
              </Div>
            </>
          ) : null}
        </Contract>
      )}
    </>
  );
};

export default NotificationContract;
