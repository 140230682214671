import { Button, Checkbox, Dropdown, Menu } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { B, Div } from '..';
import { useDispatch, useSelector } from 'react-redux';
import { getTeams } from '../../store/actions';
import { DispatchType, RootState } from '../../store/reducer';
import { NoTeamKeyWord } from '../../types/report.types';
import { addDotsForLongText } from '../../util/utils';
import styled from '@emotion/styled';
import { CheckBoxComp } from '../v2/CheckBoxComp';
import theme from '../../theme';

export const MenuComp = styled(Menu)`
  background: ${theme.white};
  border: 1px solid ${theme.gray300};
  border-radius: 4px;
  padding: 0px !important;
`;
export const MenuStyle = styled.div`
  overflow-y: scroll;
  max-height: 350px;
`;
export const MenuItem = styled.div`
  border-bottom: 1px solid ${theme.gray300};
  padding: 11px 14px;
  margin-bottom: 0px !important;
`;
export const MenuItemClear = styled.div`
  display: flex;
  flex-direction: row;
  padding: 11px 14px;
`;
export const DropdownComp = styled.span`
  margin-left: 5px;
  background: #eeeeee;
  border-radius: 14px;
  padding: 0px 8px;
`;
const DropdownButton = styled(Button)<{
  teams: string[];
  width: number;
  margin: number;
}>`
  ${(props) =>
    props.margin
      ? `margin-bottom: 16px !important;`
      : `margin-left: 16px !important;`}
  @media only screen and (max-width: 461px) {
    margin-left: 0px !important;
    margin-top: 5px !important;
  }
  width: ${(Props) =>
    Props.width
      ? `${Props.width}px`
      : Props.teams.length >= 1
      ? '192px'
      : '137px'};
  height: 32px;
  padding: 4px 12px 6px 16px !important;
  text-align: start !important;
  :hover,
  :focus {
    color: #000000 !important;
    border-color: #e0e0e0 !important;
  }
`;

const DownOutlinedStyle = styled(DownOutlined)<{
  teams: string[];
  width: number;
}>`
  margin-left: ${(Props) => {
    if (Props.teams.length === 0) {
      return Props.width ? '200px' : '37px';
    }
    return Props.width ? '115px' : '10px';
  }} !important;
`;
const CheckboxGroup = styled(Checkbox.Group)`
  width: 100% !important;
`;
interface Props {
  onChange: (value: string[]) => void;
  width?: number;
  margin?: number;
}

const TeamsSelector: FC<Props> = ({ onChange, width, margin }) => {
  const dispatch: DispatchType = useDispatch();
  const allTeams = useSelector((state: RootState) => state.dashboard.teams);

  const [teams, setTeams] = useState<string[]>([]);

  useEffect(() => {
    dispatch(getTeams());
  }, []);

  useEffect(() => {
    onCheckAllTeam();
  }, [allTeams]);

  useEffect(() => {
    onChange(teams);
  }, [teams]);

  const onCheckAllTeam = () => {
    const allTeam: string[] = [];
    // added no teams to make filter users without teams
    [...allTeams, NoTeamKeyWord].map((item) => {
      return allTeam.push(item);
    });
    setTeams(allTeam);
  };

  const teamMenu = (
    <CheckboxGroup
      value={teams}
      onChange={(e) => {
        setTeams(e as string[]);
      }}>
      <MenuComp>
        <MenuStyle
          onClick={(e) => {
            e.stopPropagation();
          }}>
          {
            // added no teams to make filter users without teams
            [...allTeams, NoTeamKeyWord].map((team, index) => {
              return (
                <MenuItem key={index + 1}>
                  <CheckBoxComp key={team} value={team}>
                    {team}
                  </CheckBoxComp>
                </MenuItem>
              );
            })
          }
        </MenuStyle>

        <MenuItemClear
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <B
            type="b-default"
            color="#0052EA"
            onClick={() => {
              onCheckAllTeam();
            }}>
            Select All
          </B>{' '}
          <Div key={1} marginLeft="auto">
            <B
              type="b-default"
              color="#757575"
              onClick={() => {
                setTeams([]);
              }}>
              Clear
            </B>
          </Div>
        </MenuItemClear>
      </MenuComp>
    </CheckboxGroup>
  );

  return (
    <>
      <Dropdown overlay={teamMenu}>
        <DropdownButton teams={teams} width={width} margin={margin}>
          All Teams
          {teams.length >= 1 ? (
            <DropdownComp>
              <B type="b-small">
                {teams[0] ? addDotsForLongText(teams[0], 6) : '-'}
              </B>
            </DropdownComp>
          ) : null}
          {teams.length > 1 ? (
            <DropdownComp>
              <B type="b-small">+{teams.length - 1}</B>
            </DropdownComp>
          ) : null}
          <DownOutlinedStyle teams={teams} width={width} />
        </DropdownButton>
      </Dropdown>
    </>
  );
};

export default TeamsSelector;
