import moment from 'moment';

export const calculateNoOfDaysOFSelectedMonth = (
  startDate: string,
  month?: string,
): number => {
  let daysInMonth: number;

  if (month) {
    const monthDate = moment(`${month} 1`, 'MMMM D');
    daysInMonth = monthDate.daysInMonth();
  } else {
    const start = moment(startDate);
    daysInMonth = start.daysInMonth();
  }

  return daysInMonth;
};
