import React, { FC, useEffect, useState } from 'react';

import { Collapse, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import styled from '@emotion/styled';

import {
  B,
  GhostButton,
  H,
  NameTag,
  PrimaryButton,
} from '../../../../components';
import {
  CustomPayslipField,
  getAllPayrollDetails,
  updatePayrollBankDetails,
} from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import theme from '../../../../theme';
import { SalaryInformationView } from '../../../../types/payroll.types';
import { payrollParam } from '../../../../types/payslipPageTypes';
import { BankDetailI } from '../../../../types/people.types';
import { MultiBankDetailForm } from '../../../people/pages/bank-page/MultiBankDetailForm';
import BankInformation from './BankInformation';
import { CustomFieldsSection } from './custom-payslip-fields/CustomFieldsSection';
import LeaveInformation from './LeaveInformation';

const { Panel } = Collapse;

const CollapseSection = styled(Collapse)`
  border: none;
  background-color: ${theme.white};
  .ant-collapse-header {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: ${theme.black};
    background: ${theme.white} !important;
    border-radius: 4px !important;
    padding: 18px 16px !important;
  }
  .ant-collapse-content {
    border-top: none;
    .ant-collapse-content-box {
      padding: 0px !important;
    }
  }
  .ant-collapse-item {
    padding: 0px !important;
    border: 1px solid ${theme.gray300};
    border-radius: 4px !important;
    margin-bottom: 24px;
  }
  .ant-collapse-item-active {
    padding: 0px !important;
  }
  .ant-collapse-header .ant-collapse-arrow {
    top: 6px !important;
    font-weight: 900 !important;

    display: contents !important;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    display: inline-block !important;
    margin-right: 12px !important;
    font-size: 12px !important;
  }

  .ant-collapse-arrow svg {
    margin-right: 10px;
  }

  .ant-collapse-extra {
    margin-top: 2px;
  }
`;

const BankDetailModal = styled(Modal)`
  .ant-modal-body {
    padding: 24px 24px 0px;
    border-radius: 4px;
  }
  border-radius: 4px;
`;

const ModalFooter = styled.div`
  padding: 16px 24px;
  border-top: 1px solid ${theme.gray300};
  background: ${theme.gray50};
  display: flex;
  justify-content: flex-end;
  margin: 0 -24px;
`;

interface Props {
  visibleBankDetailModal: boolean;
  onCancel: () => void;
}

export const CollapseItems: FC<Props> = ({
  visibleBankDetailModal,
  onCancel,
}) => {
  const dispatch: DispatchType = useDispatch();
  const params = useParams<payrollParam>();
  const [visible, setVisible] = useState(false);
  const [bankDetail, setBankDetail] = useState<BankDetailI | null>(null);

  useEffect(() => {
    dispatch(getAllPayrollDetails(params.payrollKey));
  }, [params.payrollKey]);

  const payrollDetails = useSelector(
    (state: RootState) => state.payroll.allPayrollDetails,
  );

  const isCompletedPayroll = payrollDetails?.status === 'COMPLETED';
  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const LeavesBalancesUser = useSelector(
    (state: RootState) => state.leave.leavesBalanceUser,
  );
  const payrollLoading = useSelector(
    (state: RootState) => state.payroll.payrollLoading,
  );
  const empPayroll = payrollDetails?.empPayroll.find(
    (item) => item.userId === Number(params.employeeKey),
  );
  const [payslipFields, setPayslipFields] = useState<CustomPayslipField[]>();

  useEffect(() => {
    dispatch(getAllPayrollDetails(params?.payrollKey.toString()));
  }, []);

  useEffect(() => {
    const empPayroll = payrollDetails?.empPayroll.find(
      (item) => item.userId === Number(params.employeeKey),
    );
    setPayslipFields(empPayroll?.payslipFields);
  }, [payrollDetails, params.employeeKey]);

  useEffect(() => {
    if (empPayroll) {
      setBankDetail({
        bankAccountName: empPayroll.bankAccountName,
        bankAccountNumber: empPayroll.bankAccountNumber,
        bankBankCode: empPayroll.bankBankCode,
        bankBranchId: empPayroll.bankBranchId,
        primaryAccountAmount: empPayroll.primaryAccountAmount,
        secondaryBankAccountName: empPayroll.secondaryBankAccountName,
        secondaryBankAccountNumber: empPayroll.secondaryBankAccountNumber,
        secondaryBankBankCode: empPayroll.secondaryBankBankCode,
        secondaryBankBranchCode: empPayroll.secondaryBankBranchCode,
        paySlipMail: empPayroll.user.paySlipMail,
        isEmployeePaidInCash: empPayroll.user.isEmployeePaidInCash,
      });
    }
  }, [empPayroll]);

  useEffect(() => {
    setVisible(visibleBankDetailModal);
  }, [visibleBankDetailModal]);
  const editBankDetails = () => (
    <div>
      {isCompletedPayroll ||
        (params.salaryInformationViewType ===
          SalaryInformationView.ALL_EMPLOYEE && (
          <>
            <B
              onClick={() => {
                setVisible(true);
              }}
              type="b-default"
              color={theme.blue500}>
              Edit Details
            </B>
          </>
        ))}
    </div>
  );

  const csvNameTag = () => (
    <div>
      {!organizationData?.isUsingSystemLeaves && (
        <NameTag textColor={theme.orange300} color={theme.warn}>
          CSV Data
        </NameTag>
      )}
    </div>
  );

  // const validation =
  //   (!isCompletedPayroll &&
  //     params.salaryInformationViewType ===
  //       SalaryInformationView.ALL_EMPLOYEE) ||
  //   (!isCompletedPayroll &&
  //     params.salaryInformationViewType ===
  //       SalaryInformationView.CONFIRM_EMPLOYEE &&
  //     payslipFields &&
  //     !!payslipFields.length) ||
  //   (payslipFields && !!payslipFields.length && isCompletedPayroll);

  return (
    <>
      {!empPayroll?.user.isEmployeePaidInCash && (
        <CollapseSection accordion defaultActiveKey={['1']}>
          {organizationData?.isLeaveInPayroll ? (
            <>
              <Panel header="Leave Information" key="1" extra={csvNameTag()}>
                <LeaveInformation
                  isUsingSystemLeaves={organizationData?.isUsingSystemLeaves}
                  leaveBalanceMe={LeavesBalancesUser}
                  isCompletedPayroll={isCompletedPayroll}
                />
              </Panel>
              <Panel header="Banking Details" key="2" extra={editBankDetails()}>
                <BankInformation />
              </Panel>
            </>
          ) : (
            <Panel header="Banking Details" key="1" extra={editBankDetails()}>
              <BankInformation />
            </Panel>
          )}

          <Panel header="Extra Payslip Items" key="3">
            <CustomFieldsSection />
          </Panel>
        </CollapseSection>
      )}
      <BankDetailModal
        onCancel={() => {
          setVisible(false);
          onCancel();
        }}
        width={800}
        centered
        visible={visible}
        title={<H type="h6">Bank Details</H>}
        footer={null}>
        {empPayroll && bankDetail && (
          <MultiBankDetailForm
            bankDetail={bankDetail}
            onSubmit={async (values) => {
              setVisible(false);
              if (empPayroll) {
                await dispatch(
                  updatePayrollBankDetails(values, String(empPayroll.id)),
                );
                await dispatch(getAllPayrollDetails(params.payrollKey));
              }
            }}
            footer={
              <ModalFooter>
                <GhostButton
                  mr="8px"
                  onClick={() => {
                    setVisible(false);
                  }}>
                  Cancel
                </GhostButton>
                <PrimaryButton
                  loading={payrollLoading}
                  disabled={payrollLoading}
                  htmlType="submit">
                  Update
                </PrimaryButton>
              </ModalFooter>
            }
          />
        )}
      </BankDetailModal>
    </>
  );
};
