import React, { FC } from 'react';

import { B } from '../../../../components';
import { OrganizationLeaveI } from '../../../../types';
import { isValidMoment } from '../../../../util/isValidMoment';

interface Props {
  data: OrganizationLeaveI;
}
const LeavePeriodDisplay: FC<Props> = ({ data }) => {
  return (
    <B type="b-small-semibold">
      {isValidMoment(data.date, '-', 'YYYY-MM-DD').toString()}
    </B>
  );
};
export default LeavePeriodDisplay;
