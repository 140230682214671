//import 'antd/dist/antd.css'; // TODO: do we really need this

import React, { FC } from 'react';

import { Image, Select } from 'antd';
import { layout, LayoutProps } from 'styled-system';

import styled from '@emotion/styled';
import theme from '../../../theme';
import { IUserAttendanceSummary } from '../../../types/attendance.types';
import { DropdownBlack } from '../../../assets';
import { B, Div } from '../../../components';
const MainContainer = styled.div`
  padding: 4px 5px;
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px;
  height: 32px;
`;

const StyledSelector = styled(Select)`
  min-width: 280px;
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
  .ant-select-arrow {
    margin-top: -13px;
  }
  .ant-select-selector {
    background-color: transparent !important;
  }
`;

const ThreeDotDiv = styled.div<LayoutProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${layout}
`;

const { Option } = Select;

interface Props {
  attendanceList: IUserAttendanceSummary[];
  onSelect: (index: string) => void;
  currentValue: string;
}

export const AttendanceNavigation: FC<Props> = ({
  attendanceList,
  onSelect,
  currentValue,
}) => {
  const renderEmployees = () => {
    return (
      <StyledSelector
        value={currentValue}
        onSelect={(v: string) => {
          onSelect(v);
        }}
        suffixIcon={<Image preview={false} src={DropdownBlack} />}>
        {attendanceList?.map((employee) => (
          <Option key={employee.employeeId} value={employee.employeeId}>
            <Div display="flex">
              <B type="b-default">
                <ThreeDotDiv maxWidth={160}>{employee?.fullName}</ThreeDotDiv>
              </B>
              &nbsp;{' '}
              {employee?.employeeId && (
                <B type="b-default">({employee?.employeeId})</B>
              )}
            </Div>
          </Option>
        ))}
      </StyledSelector>
    );
  };

  return <MainContainer>{renderEmployees()}</MainContainer>;
};
