import { message } from 'antd';
import axios from 'axios';
import {
  AllContractsI,
  GetContractI,
  UploadContractI,
  PlaceholderI,
  ChangeStatusI,
  AssignedPlaceholderDataI,
  DocumentPlaceholderI,
  // SaveSignatureI,
  SendContractI,
  MySignatureI,
  AssignableUsersI,
  MyContractI,
  PlaceholderEditI,
  ContractI,
  SaveContractI,
  ContractError,
} from '../../types/contract.types';
import showApiError from '../../util/showApiError';

const LOADING_UPLOAD_CONTRACT = 'LOADING_UPLOAD_CONTRACT';
const All_CONTRACT_LOADING = 'All_CONTRACT_LOADING';
const All_CONTRACT = 'All_CONTRACT';

const ADD_PLACEHOLDER = 'ADD_PLACEHOLDER';
const ADD_PLACEHOLDER_LOADING = 'ADD_PLACEHOLDER_LOADING';

const CONTRACT_LOADING = 'CONTRACT_LOADING';
const CONTRACT = 'CONTRACT';
const UPLOAD_CONTRACT = 'UPLOAD_CONTRACT';
export const UPLOAD_CONTRACT_ERROR = 'UPLOAD_CONTRACT_ERROR';

const SAVE_CONTRACT_LOADING = 'SAVE_CONTRACT_LOADING';
const SAVE_CONTRACT_PDF_LOADING = 'SAVE_CONTRACT_PDF_LOADING';

const NON_EXISTING_CONTRACT_LOADING = 'NON_EXISTING_CONTRACT_LOADING';
const NON_EXISTING_CONTRACT = 'NON_EXISTING_CONTRACT';

const ALL_PLACEHOLDERS = 'ALL_PLACEHOLDERS';
const ALL_PLACEHOLDERS_LOADING = 'ALL_PLACEHOLDERS_LOADING';

const DOCUMENT_PLACEHOLDERS = 'DOCUMENT_PLACEHOLDERS';
const DOCUMENT_PLACEHOLDERS_LOADING = 'DOCUMENT_PLACEHOLDERS_LOADING';

const MY_SIGNATURES_LOADING = 'MY_SIGNATURES_LOADING';
const MY_SIGNATURES = 'MY_SIGNATURES';

const ASSIGNABLE_USERS_LOADING = 'ASSIGNABLE_USERS_LOADING';
const ASSIGNABLE_USERS = 'ASSIGNABLE_USERS';

const ME_CONTRACT_LOADING = 'ME_CONTRACT_LOADING';
const ME_CONTRACT = 'ME_CONTRACT';
interface InitialStateI {
  loading: boolean;
  allContracts: AllContractsI[];
  loadingContracts: boolean;
  loadingContract: boolean;
  addPlaceholder: PlaceholderI[];
  addPlaceholderLoading: boolean;
  contract: GetContractI | null;
  receiverId: string;
  uploadContract: UploadContractI | null;
  uploadContractErrors: ContractError | null;
  allPlaceholders: AssignedPlaceholderDataI[];
  allPlaceholdersLoading: boolean;
  documentPlaceholders: DocumentPlaceholderI | null;
  documentPlaceholdersLoading: boolean;
  mySignatureLoading: boolean;
  mySignature: MySignatureI | null;
  assignableUsersLoading: boolean;
  assignableUsers: AssignableUsersI[];
  meContracts: MyContractI[];
  meContractsLoading: boolean;
  nonExistingContracts: DocumentPlaceholderI | null;
  noneExistingContractsLoading: boolean;
  loadingSaveContract: boolean;
  loadingSavePDFContract: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  allContracts: [],
  loadingContracts: false,
  loadingContract: false,
  addPlaceholder: [],
  addPlaceholderLoading: false,
  contract: null,
  uploadContract: null,
  uploadContractErrors: null,
  allPlaceholders: [],
  allPlaceholdersLoading: false,
  documentPlaceholders: null,
  documentPlaceholdersLoading: false,
  mySignatureLoading: false,
  mySignature: null,
  assignableUsersLoading: false,
  assignableUsers: [],
  meContracts: [],
  meContractsLoading: false,
  nonExistingContracts: null,
  noneExistingContractsLoading: false,
  loadingSaveContract: false,
  loadingSavePDFContract: false,
  receiverId: '',
};

export default function reducer(
  state: InitialStateI = initialState,
  action: any,
): InitialStateI {
  switch (action.type) {
    case LOADING_UPLOAD_CONTRACT: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case All_CONTRACT_LOADING: {
      return {
        ...state,
        loadingContracts: action.payload,
      };
    }
    case All_CONTRACT: {
      return {
        ...state,
        allContracts: action.payload,
      };
    }
    case ADD_PLACEHOLDER: {
      return {
        ...state,
        addPlaceholder: action.payload,
      };
    }
    case ADD_PLACEHOLDER_LOADING: {
      return {
        ...state,
        addPlaceholderLoading: action.payload,
      };
    }
    case CONTRACT_LOADING: {
      return {
        ...state,
        loadingContract: action.payload,
      };
    }
    case CONTRACT: {
      return {
        ...state,
        contract: action.payload,
      };
    }
    case UPLOAD_CONTRACT: {
      return {
        ...state,
        uploadContract: action.payload,
      };
    }
    case UPLOAD_CONTRACT_ERROR: {
      return {
        ...state,
        uploadContractErrors: action.payload,
      };
    }
    case ALL_PLACEHOLDERS: {
      return {
        ...state,
        allPlaceholders: action.payload,
      };
    }
    case ALL_PLACEHOLDERS_LOADING: {
      return {
        ...state,
        allPlaceholdersLoading: action.payload,
      };
    }
    case DOCUMENT_PLACEHOLDERS: {
      return {
        ...state,
        documentPlaceholders: action.payload,
      };
    }
    case DOCUMENT_PLACEHOLDERS_LOADING: {
      return {
        ...state,
        documentPlaceholdersLoading: action.payload,
      };
    }
    case MY_SIGNATURES_LOADING: {
      return {
        ...state,
        mySignatureLoading: action.payload,
      };
    }
    case MY_SIGNATURES: {
      return {
        ...state,
        mySignature: action.payload,
      };
    }
    case ASSIGNABLE_USERS_LOADING: {
      return {
        ...state,
        assignableUsersLoading: action.payload,
      };
    }
    case ASSIGNABLE_USERS: {
      return {
        ...state,
        assignableUsers: action.payload,
      };
    }
    case ME_CONTRACT_LOADING: {
      return {
        ...state,
        meContractsLoading: action.payload,
      };
    }
    case ME_CONTRACT: {
      return {
        ...state,
        meContracts: action.payload,
      };
    }
    case NON_EXISTING_CONTRACT: {
      const {
        assignableUsers,
        contract,
        documentLink,
        placeHolders,
        receiverId,
      } = action.payload;
      return {
        ...state,
        nonExistingContracts: {
          documentLink,
          placeHolders,
        },
        contract,
        assignableUsers,
        receiverId,
      };
    }
    case NON_EXISTING_CONTRACT_LOADING: {
      return {
        ...state,
        noneExistingContractsLoading: action.payload,
      };
    }
    case SAVE_CONTRACT_LOADING: {
      return {
        ...state,
        loadingSaveContract: action.payload,
      };
    }
    case SAVE_CONTRACT_PDF_LOADING: {
      return {
        ...state,
        loadingSavePDFContract: action.payload,
      };
    }
    default:
      return state;
  }
}

export function uploadContract({
  file,
  type,
  title,
  firstSignatories,
  secondSignatories,
  isSaveTemplate,
  templateId,
}: ContractI) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOADING_UPLOAD_CONTRACT, payload: true });
      const formData = new FormData();
      file && formData.append('file', file);
      firstSignatories && formData.append('firstSignatories', firstSignatories);
      secondSignatories &&
        formData.append('secondSignatories', secondSignatories);
      title && formData.append('fileName', title);
      isSaveTemplate &&
        formData.append('isSaveTemplate', isSaveTemplate.toString());
      templateId && formData.append('templateId', templateId);

      const response = await axios.post(`/contracts/uploadContract`, formData);
      dispatch({ type: UPLOAD_CONTRACT, payload: response.data });

      await localStorage.setItem(
        'documentId',
        JSON.stringify(response.data.documentId),
      );
      dispatch(getContractById(response.data.documentId));
      message.success('Successfully upload the contract...');
    } catch (error: any) {
      showApiError(error);
    } finally {
      dispatch({ type: LOADING_UPLOAD_CONTRACT, payload: false });
    }
  };
}

export function getAllContracts() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: All_CONTRACT_LOADING, payload: true });
      const res = await axios.get('/contracts');
      dispatch({ type: 'All_CONTRACT', payload: res.data });
      dispatch({ type: All_CONTRACT_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: All_CONTRACT_LOADING, payload: false });
    }
  };
}

export function deleteContract(contractId: string) {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/contracts/${contractId}`);
      message.success('Successfully deleted the contract...');
      dispatch(getAllContracts());
    } catch (error: any) {
      showApiError(error);
    }
  };
}

export function addPlaceholder(data: PlaceholderI) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: ADD_PLACEHOLDER_LOADING, payload: true });
      await axios.post('/contracts/createPlaceholder', { ...data });
      message.success('Successfully saved data');
      dispatch({ type: ADD_PLACEHOLDER_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: ADD_PLACEHOLDER_LOADING, payload: false });
    }
  };
}
export function getContractById(contractId: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: CONTRACT_LOADING, payload: true });
      const res = await axios.get(`/contracts/${contractId}`);
      dispatch({ type: CONTRACT, payload: res.data });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: CONTRACT_LOADING, payload: false });
    } finally {
      dispatch({ type: CONTRACT_LOADING, payload: false });
    }
  };
}
export function getNoneExistingContract(token: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: NON_EXISTING_CONTRACT_LOADING, payload: true });
      const res = await axios.get(
        `/contracts/nonSystem/documentPlaceholders/${token}`,
      );
      dispatch({ type: NON_EXISTING_CONTRACT, payload: res.data });
      dispatch({ type: NON_EXISTING_CONTRACT_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: NON_EXISTING_CONTRACT_LOADING, payload: false });
    }
  };
}
export function changeStatus(data: ChangeStatusI) {
  return async (dispatch: any) => {
    try {
      await axios.post('/contracts/changeStatus', { ...data });
      message.success('Successfully Changed Status');
      dispatch(getAllContracts());
    } catch (error: any) {
      showApiError(error);
    }
  };
}

export function getPlaceholders(contractId: number) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: ALL_PLACEHOLDERS_LOADING, payload: true });
      const res = await axios.get(`/contracts/getplaceholders/${contractId}`);
      dispatch({ type: ALL_PLACEHOLDERS, payload: [...res.data] });
      dispatch({ type: ALL_PLACEHOLDERS_LOADING, payload: false });
      return true;
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: ALL_PLACEHOLDERS_LOADING, payload: false });
      return false;
    }
  };
}

export function getDocumentPlaceholders(contractId: number) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: DOCUMENT_PLACEHOLDERS_LOADING, payload: true });
      const res = await axios.get(
        `/contracts/documentPlaceholders/${contractId}`,
      );
      dispatch({ type: DOCUMENT_PLACEHOLDERS, payload: res.data });
      dispatch({ type: DOCUMENT_PLACEHOLDERS_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: DOCUMENT_PLACEHOLDERS_LOADING, payload: false });
    }
  };
}

export function saveContract(documentId: string, data: SaveContractI) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: SAVE_CONTRACT_LOADING, payload: true });
      const formData = new FormData();
      if (data.signature) {
        const file: any = data.signature.file;
        formData.append('file', file);
      }
      if (data.placeHolders) {
        const details = JSON.stringify(data.placeHolders);
        formData.append('placeHolders[]', details);
      }
      formData.append('documentId', documentId);
      await axios.post('/contracts/saveSignature', formData);
      dispatch(getDocumentPlaceholders(Number(documentId)));
      dispatch({ type: SAVE_CONTRACT_LOADING, payload: false });
    } catch (error: any) {
      dispatch({ type: SAVE_CONTRACT_LOADING, payload: false });
      showApiError(error);
    }
  };
}

export function addSignaturesToPdf(documentId: string, history: any) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: SAVE_CONTRACT_PDF_LOADING, payload: true });
      await axios.post('/contracts/addSignaturesToPdf', {
        documentId: Number(documentId),
      });
      message.success('Successfully Added Data');
      dispatch(getAllContracts());
      dispatch(getDocumentPlaceholders(Number(documentId)));
      dispatch({ type: SAVE_CONTRACT_PDF_LOADING, payload: false });
      history(`/contract`);
    } catch (error: any) {
      dispatch({ type: SAVE_CONTRACT_PDF_LOADING, payload: false });
      showApiError(error);
    }
  };
}

export function saveNoneExistingContract(token: string, data: SaveContractI) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: SAVE_CONTRACT_LOADING, payload: true });
      const formData = new FormData();
      formData.append('token', token);
      if (data.signature) {
        const file: any = data.signature.file;
        formData.append('file', file);
      }
      if (data.placeHolders) {
        const details = JSON.stringify(data.placeHolders);
        formData.append('placeHolders[]', details);
      }
      await axios.post('/contracts/nonSystem/saveSignature', formData);
      dispatch({ type: SAVE_CONTRACT_LOADING, payload: false });
    } catch (error: any) {
      dispatch({ type: SAVE_CONTRACT_LOADING, payload: false });
      showApiError(error);
    }
  };
}

export function addNoneExistingSignaturesToPdf(token: string, navigate: any) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: SAVE_CONTRACT_PDF_LOADING, payload: true });
      await axios.post('/contracts/nonSystem/addSignaturesToPdf', {
        token: token,
      });
      message.success('Successfully Added');
      dispatch({ type: SAVE_CONTRACT_PDF_LOADING, payload: false });
      navigate(`/contract-signed-non-user`);
    } catch (error: any) {
      dispatch({ type: SAVE_CONTRACT_PDF_LOADING, payload: false });
      showApiError(error);
    }
  };
}

export function sendContract(data: SendContractI) {
  return async (dispatch: any) => {
    try {
      await axios.post('/contracts/sendContract', {
        ...data,
      });
      message.success('Successfully Emailed');
      dispatch(getAllContracts());
    } catch (error: any) {
      showApiError(error);
    }
  };
}
export function getMySign() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: MY_SIGNATURES_LOADING, payload: true });
      const res = await axios.get(`/contracts/getMySign`);
      dispatch({ type: MY_SIGNATURES, payload: res.data });
      dispatch({ type: MY_SIGNATURES_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: MY_SIGNATURES_LOADING, payload: false });
    }
  };
}

export function deletePlaceholder(
  documentId: string,
  placeholderIds: string[],
) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: ALL_PLACEHOLDERS_LOADING, payload: true });
      await axios.delete('/contracts/deletePlaceholder', {
        data: { documentId: documentId, placeholderIds: placeholderIds },
      });
    } catch (error: any) {
      showApiError(error);
    } finally {
      dispatch({ type: ALL_PLACEHOLDERS_LOADING, payload: false });
    }
  };
}
export function getAssignableUsers(contractId: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: ASSIGNABLE_USERS_LOADING, payload: true });
      const res = await axios.get(`/contracts/assignableUsers/${contractId}`);
      dispatch({ type: ASSIGNABLE_USERS, payload: res.data });
      dispatch({ type: ASSIGNABLE_USERS_LOADING, payload: false });
      return true;
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: ASSIGNABLE_USERS_LOADING, payload: false });
      return false;
    }
  };
}
export function getContractsMe() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: ME_CONTRACT_LOADING, payload: true });
      const res = await axios.get('/contracts/me');
      dispatch({ type: 'ME_CONTRACT', payload: res.data });
      dispatch({ type: ME_CONTRACT_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: ME_CONTRACT_LOADING, payload: false });
    }
  };
}
export function changePlaceholder(data: PlaceholderEditI) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: ALL_PLACEHOLDERS_LOADING, payload: true });
      await axios.put('/contracts/editPlaceholders', { ...data });
      message.success('Updated Successfully');
    } catch (error: any) {
      showApiError(error);
    } finally {
      dispatch({ type: ALL_PLACEHOLDERS_LOADING, payload: false });
    }
  };
}
