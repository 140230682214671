import { css } from '@emotion/react';
import { Col, Row, Spin } from 'antd';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { CustomCard, Div } from '../../components';
import { RootState } from '../../store/reducer';
import CustomizationTable from './shared/customizations/CustomizationTable';
import Designations from './shared/customizations/Designations';
import Teams from './shared/customizations/Teams';
import EmploymentType from './shared/customizations/EmploymentType';
import { isProduction } from '../../configs/appConfig';

const Customization: FC = () => {
  const loading = useSelector((state: RootState) => state.organization.loading);

  return (
    <CustomCard>
      <Spin spinning={loading}>
        <Div
          css={css`
            border-bottom: ${isMobile ? '' : '1px solid #e0e0e0'};
          `}>
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Teams />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Designations />
            </Col>
          </Row>
          {!isProduction && (
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <EmploymentType />
              </Col>
            </Row>
          )}
        </Div>
      </Spin>
      <CustomizationTable />
    </CustomCard>
  );
};

export default Customization;
