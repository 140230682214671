import styled from '@emotion/styled';
import { Col, Form, Input, Menu, Row, Tag } from 'antd';
import { FC, useState } from 'react';
import theme from '../../theme';
import { B, Div, GhostButton, PrimaryButton } from '../../components';
import { RemovePendingEmployeeI } from '../../types/people.types';
import { useDeletePendingUser } from '../../api/peopleHooks';
import dayjs from 'dayjs';

const DivStyle = styled(Menu)`
  width: 327px;
  padding: 0px;
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .statusItem {
    display: flex;
    align-items: center;
  }

  .statusDot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: ${theme.orange300};
    border-radius: 50%;
    margin-right: 8px;
  }

  .Dot {
    display: flex;
    align-items: center;
  }
`;

const MenuContent = styled(Div)`
  padding: 15px;
`;
const MenuFooter = styled(Div)`
  padding: 10px;
  border-top: 1px solid ${theme.gray300};
`;
const Item = styled(Row)`
  margin-bottom: 10px;
`;

interface PendingEmployeeProps {
  createdOn: string;
  admin: string;
  expiresOn: string;
  userId: number;

  onClickCancel?: () => void;
  showMenu: (val: boolean) => void;
}

export const PendingEmployeeMenu: FC<PendingEmployeeProps> = ({
  createdOn,
  admin,
  expiresOn,
  userId,
  onClickCancel,
  showMenu,
}) => {
  const [isCancelling, setIsCancelling] = useState(false);

  const handleCancelReason = () => {
    setIsCancelling(true);
  };

  const handleCancel = async () => {
    await showMenu(false);
    showMenu(true);
  };

  const { mutate: deletePendingUser, data: deletePendingUserData } =
    useDeletePendingUser();

  const day = dayjs(createdOn).format('Do');
  const month = dayjs(createdOn).format('MMM');
  const year = dayjs(createdOn).format('YYYY');
  const time = dayjs(createdOn).format('h:mma');

  const expiryDate = new Date(expiresOn);

  const expiryDay = dayjs(expiryDate).format('Do');
  const expiryMonth = dayjs(expiryDate).format('MMM');
  const expiryYear = dayjs(expiryDate).format('YYYY');

  const onFinish = async (value: string) => {
    const dataSet: RemovePendingEmployeeI = {
      id: userId,
      cancelReason: value,
    };

    await deletePendingUser(dataSet, {
      onSuccess: () => {
        handleCancel();
        onClickCancel();
      },
      onError: (error) => {
        console.error('Error deleting pending user:', error);
      },
    });
  };

  return isCancelling ? (
    <DivStyle>
      <Form layout="vertical" onFinish={onFinish}>
        <MenuContent>
          <Form.Item
            name="cancelReason"
            label={<B type="b-default">Reason for cancellation (Optional)</B>}>
            <Input.TextArea rows={3} />
          </Form.Item>
        </MenuContent>
        <MenuFooter>
          <Item justify="end">
            <Col span={10} />
            <Col span={7}>
              <GhostButton size="small" onClick={handleCancel}>
                Cancel
              </GhostButton>
            </Col>
            <Col span={7}>
              <PrimaryButton size="small" htmlType="submit">
                Save
              </PrimaryButton>
            </Col>
          </Item>
        </MenuFooter>
      </Form>
    </DivStyle>
  ) : (
    <DivStyle>
      <MenuContent>
        <Item className="statusItem">
          <Tag className="Dot" bordered={false} color="orange">
            <span className="statusDot"></span>
            <B type="b-default">Pending</B>
          </Tag>
        </Item>
        <Item>
          <Col span={9}>
            <B type="b-default">Created On</B>
          </Col>
          <Col span={15}>
            <B type="b-default">{`${day} ${month} ${year} at ${time}`}</B>
          </Col>
        </Item>
        <Item>
          <Col span={9}>
            <B type="b-default">Created By</B>
          </Col>
          <Col span={15}>
            <B type="b-default">{admin}</B>
          </Col>
        </Item>
        <Item>
          <Col span={9}>
            <B type="b-default">Expires On</B>
          </Col>
          <Col span={15}>
            <B type="b-default">{`${expiryDay} ${expiryMonth} ${expiryYear} at ${time}`}</B>
          </Col>
        </Item>
      </MenuContent>
      <MenuFooter>
        <Item justify="end">
          <GhostButton size="small" onClick={handleCancelReason}>
            Cancel Request
          </GhostButton>
        </Item>
      </MenuFooter>
    </DivStyle>
  );
};

export default PendingEmployeeMenu;
