import React, { FC, useEffect, useState } from 'react';
import { B, Div, PrimaryButton } from '../../../../components';
import SuperSystemReportTable from './SuperSystemReportTable';
import {
  useCreateSystemUpdate,
  useDeleteSystemUpdate,
  useEditSystemUpdate,
  useGetSystemUpdate,
  useGetSystemUpdates,
} from '../../../../api/superAdminHooks';
import { EditSystemUpdateI } from '../../../../types/superAdmin.types';
import { PlusWhiteSVG } from '../../../../assets';
import EmployeesSelector from '../../../../components/selector/EmployeesSelector';
import RangePickerComponent from '../../../../components/selector/RangePickerComponent';
import styled from '@emotion/styled';
import theme from '../../../../theme';
import { DataRangeI } from '../../../../types/report.types';

const SelectorDiv = styled.div`
  width: 100%;
  padding: 8px 24px;
  margin-left: -12px !important;
  padding-left: 0px !important;
  margin-bottom: 50px;
`;

const SuperSystemUpdates: FC = () => {
  const [dateRange, setDateRange] = useState<DataRangeI | null>(null);

  return (
    <Div m="24px 32px">
      <>
        <SelectorDiv>
          <>
            <RangePickerComponent
              onChange={(values) => setDateRange(values)}
              defaultStartDeductMonths={0}
            />
          </>
        </SelectorDiv>
      </>
      <SuperSystemReportTable dateRange={dateRange} />{' '}
    </Div>
  );
};

export default SuperSystemUpdates;
