import React, { FC } from 'react';
import { Input as AntInput, Typography } from 'antd';
import { InputProps } from 'antd/lib/input';
import { space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';
import theme from '../theme';

type Props = SpaceProps &
  Omit<InputProps, 'size'> & {
    label?: string;
    hint?: string;
    flexDirection?: string;
    size?: 'small' | 'large';
  };

const StyledInput = styled(AntInput)<Props>`
  ${(props) => props.flexDirection === 'row' && 'width: 344px;'}
  border: 1px solid ${theme.gray300};
  width: 100%;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 20px;
  padding: ${(props) => props.size === 'small' && '0 12px'};
  height: ${(props) => (props.size == 'small' ? '32px !important' : '40px')};
  &:focus {
    border: 1px solid ${theme.blue500};
    box-shadow: none;
  }

  .ant-input[disabled] {
    background-color: ${theme.gray200};
  }

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${theme.blue500};
  }

  &.ant-input-affix-wrapper:focus,
  &.ant-input-affix-wrapper-focused {
    border-color: ${theme.blue500};
    box-shadow: none;
  }

  &:hover {
    border: 1px solid ${theme.blue500};
  }

  ${space}
`;
const Text = styled(Typography.Text)`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.blue700};
  padding-bottom: 4px;
`;
export const InputComp: FC<Props> = (props) => {
  const { label, hint, flexDirection = 'column', size = 'large' } = props;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {label && <Text>{label}</Text>}
      <StyledInput {...props} size={size} flexDirection={flexDirection} />
      {hint && <Typography.Text type="secondary">{hint}</Typography.Text>}
    </div>
  );
};
