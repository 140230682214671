import { ExpenseMetaData, SuppliersList } from '../../../types';

export const findNestedExpense = (
  expenses: (SuppliersList | ExpenseMetaData)[],
  value: string,
): SuppliersList | ExpenseMetaData | null => {
  for (const expense of expenses) {
    if (expense.value === value) {
      return expense;
    } else {
      if (expense.subs && expense.subs.length) {
        const data = findNestedExpense(expense.subs, value);
        if (data) {
          return data;
        }
      }
    }
  }
  return null;
};
