import * as _ from 'lodash';

import {
  CurrentJob,
  DataImportPayItem,
  DataImportPayItemValuesI,
  DataImportUserPayLoad,
} from '../../../store/actions';
import { PayrollItemTypeEnum, PayrunType } from '../../../types/payroll.types';

const generatePayrollTableData = (
  dataImportPayload: DataImportUserPayLoad,
): { cell: DataImportPayItemValuesI; payItemColumns: string[] } => {
  const payItemColumns: string[] = [];
  const cell: DataImportPayItemValuesI = {};

  (dataImportPayload.values.payItems || []).forEach((payItem) => {
    payItem.payTitle = payItem.payTitle.trim();
    cell[`${payItem.payTitle}`] = payItem;
    payItemColumns.push(`${payItem.payTitle}`);
  });
  (dataImportPayload.payItemErrors || []).forEach((error) => {
    if (error.payTitle) {
      cell[error.payTitle] = {
        ...cell[error.payTitle],
        error: error.message,
      };
    }
  });
  return {
    cell,
    payItemColumns,
  };
};

const hasErrorInRow = (record: DataImportUserPayLoad) => {
  if (record.hasEmployeeErrors) {
    return true;
  } else if (record.payItemErrors.length) {
    return true;
  }
  return false;
};

export const formatUserDataImportJSON = (
  dataImportUserPayloads: Partial<DataImportUserPayLoad>[],
  payrunType?: string | null,
): { dataList: DataImportUserPayLoad[]; payItemColumns: string[] } => {
  let payItemColumns: string[] = [];

  const newDataImportUserPayloads: DataImportUserPayLoad[] =
    dataImportUserPayloads.map((payload) => {
      const newPayload: DataImportUserPayLoad = {
        rowKey: '',
        hasEmployeeErrors: false,
        values: {
          payItems: [],
          isEmployeePaidInCash: false,
        },
        isNewInDraft: false,
        apitForFistTime: false,
        hasAnyError: false,
        isSelected: false,
        employeeErrors: {},
        payItemWarnings: [],
        payItemErrors: [],
        updatedUserValues: {},
        payrollUpdates: [],
        ...payload,
      };
      newPayload.hasAnyError = hasErrorInRow(newPayload);
      const { cell, payItemColumns: keysList } =
        generatePayrollTableData(newPayload);
      payItemColumns = payItemColumns.concat(keysList);
      newPayload.values.payItemValues = cell;
      return newPayload;
    });
  const mappedNewDataImportUserPayloads = newDataImportUserPayloads.map(
    (item) => {
      return { ...item, rowKey: `${item.values.id}` };
    },
  );
  return {
    dataList: mappedNewDataImportUserPayloads,

    payItemColumns: reorderColumns(
      mappedNewDataImportUserPayloads,
      [...new Set(payItemColumns)],
      payrunType,
    ),
    // get only unique list
  };
};

export const formatDataImportJSON = (
  payload: Partial<DataImportUserPayLoad>[],
): { dataList: DataImportUserPayLoad[]; payItemColumns: string[] } => {
  const { dataList: usersList, payItemColumns: columnList } =
    formatUserDataImportJSON(payload);
  const mappedUserList = usersList.map((item, i) => {
    return { ...item, rowKey: 'row-key-' + i };
  });
  return {
    dataList: mappedUserList,
    payItemColumns: reorderColumns(mappedUserList, [...new Set(columnList)]),
  };
};

const mergeTaxColumns = (dataImportUserPayloads: DataImportUserPayLoad[]) => {
  dataImportUserPayloads.forEach((item) => {
    const apitItems = item.values.payItems.filter(
      (payItem) =>
        payItem.type === PayrollItemTypeEnum.APIT_TABLE_1 ||
        payItem.type === PayrollItemTypeEnum.APIT_TABLE_2 ||
        payItem.type === PayrollItemTypeEnum.APIT_TABLE_5 ||
        payItem.type === PayrollItemTypeEnum.APIT_TABLE_7,
    );

    if (!_.isEmpty(apitItems)) {
      const amount = apitItems.reduce(
        (previousValue, currentValue) =>
          previousValue + Number(currentValue.amount),
        0,
      );

      if (item.values.payItemValues) {
        item.values.payItemValues['APIT'] = {
          excelHeader: '',
          payTitle: 'APIT',
          type: PayrollItemTypeEnum.APIT,
          isTaxable: false,
          isEpf: false,
          amount,
        };
      }
      item.values.payItemValues = _.omit(item.values.payItemValues, [
        'APIT_TABLE_1',
        'APIT_TABLE_2',
        'APIT_TABLE_5',
        'APIT_TABLE_7',
      ]);

      item.values.payItems.push({
        excelHeader: '',
        payTitle: 'APIT',
        type: PayrollItemTypeEnum.APIT,
        isTaxable: false,
        isEpf: false,
        amount,
      });
      item.values.payItems = item.values.payItems.filter(
        (payItem) =>
          payItem.type !== PayrollItemTypeEnum.APIT_TABLE_1 &&
          payItem.type !== PayrollItemTypeEnum.APIT_TABLE_2 &&
          payItem.type !== PayrollItemTypeEnum.APIT_TABLE_5 &&
          payItem.type !== PayrollItemTypeEnum.APIT_TABLE_7,
      );
    }
  });
};

const reorderColumns = (
  dataImportUserPayloads: DataImportUserPayLoad[],
  columnList: string[],
  payrunType?: string | null,
) => {
  mergeTaxColumns(dataImportUserPayloads);
  const data = dataImportUserPayloads.map((item) => {
    const { cell } = generatePayrollTableData(item);
    return cell;
  });
  columnList.push('APIT');

  const list: string[] = [];
  const lastList: string[] = [];
  const basicList: string[] = [];
  const stampList: string[] = [];
  const allowanceList: string[] = [];
  for (const payItem of data) {
    for (const column of columnList) {
      if (payItem[column]) {
        if (payrunType === PayrunType.MID_CYCLE_PAYRUN) {
          if (payItem[column].type === PayrollItemTypeEnum.ETF) {
            lastList.push(column);
          } else if (
            payItem[column].type === PayrollItemTypeEnum.EMPLOYEE_EPF
          ) {
            lastList.push(column);
          } else if (
            payItem[column].type === PayrollItemTypeEnum.EMPLOYER_EPF
          ) {
            lastList.push(column);
          } else if (payItem[column].type === PayrollItemTypeEnum.APIT) {
            lastList.push(column);
          } else if (payItem[column].payTitle === 'Stamp Duty') {
            stampList.push(column);
          } else if (
            payItem[column].type === PayrollItemTypeEnum.VARIABLE_ALLOWANCE ||
            payItem[column].type === PayrollItemTypeEnum.LUMP_SUM
          ) {
            allowanceList.push(column);
          }
        } else {
          if (payItem[column].type === PayrollItemTypeEnum.BASIC) {
            basicList.push(column);
          } else if (payItem[column].type === PayrollItemTypeEnum.ETF) {
            lastList.push(column);
          } else if (
            payItem[column].type === PayrollItemTypeEnum.EMPLOYEE_EPF
          ) {
            lastList.push(column);
          } else if (
            payItem[column].type === PayrollItemTypeEnum.EMPLOYER_EPF
          ) {
            lastList.push(column);
          } else if (payItem[column].type === PayrollItemTypeEnum.APIT) {
            lastList.push(column);
          } else if (payItem[column].payTitle === 'Stamp Duty') {
            stampList.push(column);
          } else if (
            payItem[column].type === PayrollItemTypeEnum.FIXED_ALLOWANCE ||
            payItem[column].type === PayrollItemTypeEnum.VARIABLE_ALLOWANCE
          ) {
            allowanceList.push(column);
          } else {
            list.push(column);
          }
        }
      }
    }
  }
  return [
    ...new Set([
      ...basicList,
      ...allowanceList,
      ...list.sort(),
      ...stampList,
      ...lastList,
    ]),
  ];
};

export const getLeaveColumns = (columns: DataImportUserPayLoad[]) => {
  const leaveItem: string[] = [];
  let leaveColumn: string[] = [];
  columns.map((item) => {
    if (item.values.leaveInformation) {
      leaveItem.push(...Object.keys(item.values.leaveInformation));
      leaveColumn = [...new Set(leaveItem)];
    }
  });
  return [...leaveColumn];
};

export const getPayslipFieldColumns = (columns: DataImportUserPayLoad[]) => {
  const PayslipField: string[] = [];
  let payslipFieldColumn: string[] = [];

  columns.map((item) => {
    if (item.values.payslipFields) {
      PayslipField.push(...item.values.payslipFields.map((item) => item.key));
      payslipFieldColumn = [...new Set(PayslipField)];
    }
  });
  return payslipFieldColumn;
};

export const getPayItemColumnsWithType = (
  columns: DataImportUserPayLoad[],
  currentJob?: CurrentJob,
) => {
  const payItemColumns: Omit<DataImportPayItem, 'excelHeader' | 'amount'>[] =
    [];

  const isContractor =
    currentJob?.employmentType.toLocaleLowerCase() === 'contract';

  columns.forEach((item) => {
    item.values.payItems.forEach((payItem) => {
      payItemColumns.push({
        payTitle: payItem.payTitle,
        type: payItem.type,
        isEpf: payItem.isEpf,
        isTaxable: payItem.isTaxable,
        subType: payItem.subType,
      });
    });
  });

  const filtered = [
    ...new Map(payItemColumns.map((v) => [v.payTitle, v])).values(),
  ];

  let contractorFiltered = [];

  if (isContractor) {
    contractorFiltered = [
      ...new Map(
        payItemColumns
          .filter((i) => {
            return !i?.subType; // Only OT and No Pay are having subtypes here
          })
          .map((v) => [v.payTitle, v]),
      ).values(),
    ];
  }

  return isContractor ? contractorFiltered : filtered;
};
