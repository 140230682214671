type OccupationGrade = {
  id: number;
  grade: string;
};

export const OCG_LIST: OccupationGrade[] = [
  {
    id: 11,
    grade: 'Chief executives, senior officials and legislators',
  },
  {
    id: 12,
    grade: 'Administrative and commercial managers',
  },
  {
    id: 13,
    grade: 'Production and specialized services managers',
  },
  {
    id: 14,
    grade: 'Hospitality, shop and related services managers',
  },
  {
    id: 21,
    grade: 'Science and engineering professionals',
  },
  {
    id: 22,
    grade: 'Health professionals',
  },
  {
    id: 23,
    grade: 'Teaching professionals',
  },
  {
    id: 24,
    grade: 'Business and administrative professionals',
  },
  {
    id: 25,
    grade: 'Information and communication technology professionals',
  },
  {
    id: 26,
    grade: 'Legal, social and cultural professionals',
  },
  {
    id: 31,
    grade: 'Science and engineering associate professionals',
  },
  {
    id: 32,
    grade: 'Health associate professionals',
  },
  {
    id: 33,
    grade: 'Business and administrative associate professionals',
  },
  {
    id: 34,
    grade:
      'Policing, legal, social and cultural related associate professionals',
  },
  {
    id: 35,
    grade: 'Information and communication technology technicians',
  },
  {
    id: 41,
    grade: 'General and keyboard clerks',
  },
  {
    id: 42,
    grade: 'Customer services clerks',
  },
  {
    id: 43,
    grade: 'Numerical and material recording clerks',
  },
  {
    id: 44,
    grade: 'Other clerical support workers',
  },
  {
    id: 51,
    grade: 'Personal services workers',
  },
  {
    id: 52,
    grade: 'Sales workers',
  },
  {
    id: 53,
    grade: 'Personal care workers',
  },
  {
    id: 54,
    grade: 'Protective services workers',
  },
  {
    id: 61,
    grade: 'Market – oriented skilled agricultural workers',
  },
  {
    id: 62,
    grade: 'Market – oriented skilled forestry, fishery and hunting workers',
  },
  {
    id: 63,
    grade: 'Agricultural, forestry, fishery, hunters and gatherers',
  },
  {
    id: 71,
    grade: 'Building and related trade workers (excluding electricians)',
  },
  {
    id: 72,
    grade: 'Metal, machinery and related trade workers',
  },
  {
    id: 73,
    grade: 'Andicraft and printing workers',
  },
  {
    id: 74,
    grade: 'Electrical and electronic trade workers',
  },
  {
    id: 75,
    grade:
      'Food processing, wood working, garment and other craft and related trade workers',
  },
  {
    id: 81,
    grade: ' Stationary plant and machine operators',
  },
  {
    id: 82,
    grade: 'Assemblers',
  },
  {
    id: 83,
    grade: 'Drivers and mobile plant operators',
  },
  {
    id: 91,
    grade: 'Cleaners and helpers',
  },
  {
    id: 92,
    grade: 'Agricultural, forestry and fishery labourers',
  },
  {
    id: 93,
    grade: 'Labourers in ming, Construction, manufacturing and transport',
  },
  {
    id: 94,
    grade: 'Food and preparation assistants',
  },
  {
    id: 95,
    grade: 'Street and related sales and services workers',
  },
  {
    id: 96,
    grade: 'Refuse workers and other elementary workers',
  },
  {
    id: 1,
    grade: 'Armed forces occupations',
  },
  {
    id: 2,
    grade: 'Workers not classified by occupations',
  },
];
