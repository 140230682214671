import React, { FC } from 'react';
import { Typography } from 'antd';
import Select, { SelectProps } from 'antd/lib/select';
import { SelectValue } from 'antd/lib/tree-select';
import theme from '../theme';
import styled from '@emotion/styled';

const { Text } = Typography;

interface Props extends SelectProps<SelectValue> {
  label?: string;
  bordered?: boolean;
}
const SelectInput = styled(Select)<{ bordered?: boolean }>`
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  .ant-select-selector {
    border: ${(props) =>
      props.bordered === false
        ? 'none'
        : `1px solid ${theme.gray300}`}!important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    box-shadow: none !important;
  }
  .ant-select-selector:hover {
    border: ${(props) =>
      props.bordered === false
        ? 'none'
        : ` 1px solid ${theme.blue500}`} !important;
    box-sizing: border-box !important;
    filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
    border-radius: 4px !important;
  }
  .ant-select-selector:focus {
    border: 1px solid ${theme.blue500} !important;
    box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1) !important;
  }
  .ant-select-arrow {
    color: ${theme.black};
  }
  .ant-select-selection-item-content {
    color: ${theme.blue600};
  }
  .ant-select-selection-item-remove {
    color: ${theme.blue600};
  }
`;
const Label = styled(Text)`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.blue700};
  padding-bottom: 4px;
`;
export const SelectComp: FC<Props> = (props) => {
  const { label, size = 'large', bordered = true } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {label && <Label>{label}</Label>}

      <SelectInput size={size} bordered={bordered} {...props} />
    </div>
  );
};
