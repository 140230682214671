import React, { FC, useEffect, useState } from 'react';
import { Row } from 'antd';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Div, H } from '../../../../components';
import { RootState } from '../../../../store/reducer';
import { UserHistoryI } from '../../../../types/people.types';
import ExperienceDetail from './ExperienceDetail';

const MainContainer = styled.div`
  padding: 0px 32px;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export const ExperienceCard: FC = () => {
  const [experiences, setExperiences] = useState<UserHistoryI[]>([]);
  const me = useSelector((state: RootState) => state.people.me);
  const userHistory = me?.userHistory || [];
  useEffect(() => {
    setExperiences(userHistory);
  }, [userHistory]);

  return (
    <>
      {experiences.length ? (
        <MainContainer>
          <Div mb="14px">
            <H type="h6">Experience</H>
          </Div>
          <Row>
            {experiences.map((item) => {
              return <ExperienceDetail experience={item} />;
            })}
          </Row>
        </MainContainer>
      ) : null}
    </>
  );
};
