import React, { FC, useEffect, useState } from 'react';

import { Form, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  B,
  DefaultBody,
  FooterComponent,
  InputComp,
  PrimaryButton,
} from '../../components';
import { BANK_EXPORT_TYPES } from '../../configs';
import { DispatchType, RootState } from '../../store/reducer';
import {
  ComBankOptions,
  ComBankTypeEnum,
  Permission,
  SampathBankOptions,
  SampathBankTypeEnum,
} from '../../types';
import { isAllowed } from '../../util/permissionUtil';
import theme from '../../theme';
import styled from '@emotion/styled';
import { useAddBankInformation, useGetBankList } from '../../api/bankHooks';
import { getMyOrganization } from '../../store/actions';
const { Option } = Select;

const COM_BANK_CODE = '7056';
const SAMPATH_BANK_CODE = '7278';

const FormContainer = styled(Form)`
  margin-top: 32px;
  width: 311px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: none;
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-bottom: 10px;
  }
`;

const SelectComp = styled(Select)`
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  .ant-select-selector {
    border: 1px solid ${theme.gray400} !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    box-shadow: none !important;
  }
  .ant-select-selection-item {
    font-size: 14px;
  }
  .ant-select-selector:hover {
    border: 1px solid ${theme.blue500} !important;
    box-sizing: border-box !important;
    filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
    border-radius: 4px !important;
  }
  .ant-select-selector:focus {
    border: 1px solid ${theme.blue500} !important;
    box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1) !important;
  }
  .ant-select-arrow {
    color: black;
  }
`;
const BankInformation: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const [form] = Form.useForm();
  const [branchCode, setBranCode] = useState('');
  const [bankCode, setBankCode] = useState('');

  const {
    data: banks,
    isLoading: bankInfoLoading,
    refetch: getBankDetails,
  } = useGetBankList();

  const { mutate: addBankInformation, data: bankDetailData } =
    useAddBankInformation();

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const bankInformation = organizationData?.bankDetails;

  const bankOptions = banks?.map((item) => {
    return {
      value: item.bankCode,
      label: item.bankName,
    };
  });

  const branchOptions =
    (banks &&
      banks
        .find((item) => item.bankCode == bankCode)
        ?.bankBranches?.map((item) => {
          return {
            value: item.branchCode,
            branch: item.branchCode,
            label: item.branchName,
          };
        })) ||
    [];

  const onFinish = async (values: {
    accountName: string;
    accountNumber: number;
    bankName: string;
    bankCode: number;
    branchCode: string;
    comBankType?: ComBankTypeEnum;
    sampathBankType?: SampathBankTypeEnum;
  }) => {
    values = {
      accountName: values.accountName,
      accountNumber: values.accountNumber,
      bankName: bankname.toString(),
      branchCode: values.branchCode,
      bankCode: Number(values.bankCode),
      comBankType: values.comBankType,
      sampathBankType: values.sampathBankType,
    };
    addBankInformation(values);
  };

  useEffect(() => {
    dispatch(getMyOrganization());
  }, [bankDetailData]);

  useEffect(() => {
    getBankDetails();
  }, []);

  useEffect(() => {
    if (bankInformation) {
      form.setFieldsValue({ bankInformation });
      if (bankInformation.bankCode) {
        setBankCode(bankInformation.bankCode.toString());
      }
    }
  }, [bankInformation]);

  const bankname =
    bankOptions &&
    bankOptions
      .filter((item) => item.value == bankCode)
      .map((item) => {
        return item.label;
      });

  if (!organizationData) {
    return null;
  }

  return (
    <DefaultBody>
      <Spin spinning={bankInfoLoading}>
        <B type="b-large" pt="8px">
          Please enter company bank information details
        </B>
        <FormContainer
          form={form}
          onFinish={onFinish}
          validateTrigger="onBlur"
          initialValues={bankInformation || {}}
          layout="vertical">
          <Form.Item
            name="bankCode"
            label="Bank Name"
            rules={[
              {
                required: true,
                message: 'Please input  Bank Name!',
              },
            ]}>
            <SelectComp
              showSearch
              size="large"
              placeholder="Select a bank name"
              optionFilterProp="children"
              onChange={(e) => {
                setBankCode(String(e));
                setBranCode('');
                form.setFieldsValue({
                  branchCode: undefined,
                  bankBranchId: undefined,
                });
              }}>
              {BANK_EXPORT_TYPES.map((b) => (
                <Option key={b.code} value={b.code}>
                  {b.code} - {b.label}
                </Option>
              ))}
            </SelectComp>
          </Form.Item>
          {bankCode === COM_BANK_CODE ? (
            <Form.Item
              name="comBankType"
              label="Bulk File Type"
              initialValue={ComBankTypeEnum.DIGITAL}
              rules={[
                {
                  required: true,
                  message: 'Please select file type',
                },
              ]}>
              <SelectComp size="large" placeholder="Select the file type">
                {ComBankOptions.map((b, i) => (
                  <Option key={i} value={b.value}>
                    {b.label}
                  </Option>
                ))}
              </SelectComp>
            </Form.Item>
          ) : null}

          {bankCode === SAMPATH_BANK_CODE ? (
            <Form.Item
              name="sampathBankType"
              label="Bulk File Type"
              initialValue={SampathBankTypeEnum.VISHWA}
              rules={[
                {
                  required: true,
                  message: 'Please select file type',
                },
              ]}>
              <SelectComp size="large" placeholder="Select the file type">
                {SampathBankOptions.map((option, index) => (
                  <Option key={index} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </SelectComp>
            </Form.Item>
          ) : null}

          <Form.Item
            name="branchCode"
            label="Branch Name & Code"
            rules={[
              {
                required: true,
                message: 'Please input  Bank Name!',
              },
            ]}>
            <SelectComp
              showSearch
              size="large"
              value={branchCode}
              placeholder="Select a branch name"
              optionFilterProp="children"
              onChange={(e) => {
                setBranCode(String(e));
              }}>
              {branchOptions.map((option) => (
                <Option key={option.value} value={Number(option.value)}>
                  {option.branch} - {option.label}
                </Option>
              ))}
            </SelectComp>
          </Form.Item>
          <Form.Item
            name="accountName"
            label="Account Name"
            rules={[
              {
                required: true,
                message: 'Please input  Account Name!',
              },
            ]}>
            <InputComp placeholder="Enter Your Account Name " />
          </Form.Item>
          <Form.Item
            name="accountNumber"
            label="Account Number"
            rules={[
              {
                type: 'string',
                required: true,
                message: 'Please enter your bank account number!',
              },
              {
                type: 'string',
                pattern: /^\d{1,15}$/,
                message: 'The input is not valid  bank account number!',
              },
            ]}>
            <InputComp placeholder="Enter Your Account Number" />
          </Form.Item>
          <FooterComponent
            rightChildren={
              <PrimaryButton
                disabled={!isAllowed(Permission.MANAGE_ORG_SETTINGS)}
                htmlType="submit">
                Save
              </PrimaryButton>
            }
          />
        </FormContainer>
      </Spin>
    </DefaultBody>
  );
};
export default BankInformation;
