//import 'antd/dist/antd.css'; // TODO: do we really need this
import React, { FC } from 'react';
import { Card, Col, Image, Row, Select } from 'antd';
import { css } from '@emotion/react';
import { B, Div } from '../../../components';
import { useWindowSize } from '../../../util/screenSize';
import { numberThousandSeparator } from '../../../util/utils';
import { EmployeePaySheetSummary } from '../modules/EmployeeSalary';
import { Sort } from '../../../assets';

const { Option } = Select;

interface Props {
  employees: EmployeePaySheetSummary[];
  onSelect: (index: number) => void;
  netPay?: number;
  sheetIndex: number;
}

const IndividualEmployeeSelector: FC<Props> = ({
  employees,
  onSelect,
  netPay,
  sheetIndex,
}) => {
  const { isMobile } = useWindowSize();
  const options = employees.map((item, index) => {
    return {
      value: index,
      label: item.name,
    };
  });
  if (!options.length) {
    return null;
  }
  return (
    <>
      <Div marginBottom="16px">
        <Row>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <Card
              css={css`
                padding: ${isMobile ? ' 20px 10px' : '20px 32px'};
                height: 64px;
                border: 1px solid #e0e0e0;
                border-right: none;
                .ant-card-body {
                  padding: 0px !important;
                }
              `}>
              <B type="b-large" color="#5F6267">
                Employee
              </B>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={13} lg={13} xl={13}>
            <Card
              css={css`
                padding: 10px 32px;
                height: 64px;
                border: 1px solid #e0e0e0;
                border-right: none;
                .ant-card-body {
                  padding: 0px !important;
                }
              `}>
              <Select
                value={sheetIndex}
                onSelect={(v) => onSelect(v)}
                suffixIcon={<Image preview={false} src={Sort} />}
                css={css`
                  font-family: Inter;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: -0.1px;
                  color: #0052ea;
                  width: 220px;
                  .ant-select-selector {
                    border: none !important;
                    box-shadow: none !important;
                  }
                  .ant-select-selection-item {
                    line-height: 39px !important;
                    padding-right: 32px !important;
                    color: #0052ea !important;
                  }
                `}>
                {options.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
            <Card
              css={css`
                padding: ${isMobile ? ' 22px 10px' : '22px 32px'};
                height: 64px;
                border: 1px solid #e0e0e0;
                border-right: none;
                border-left: none;
                .ant-card-body {
                  padding: 0px !important;
                }
              `}>
              <B type="b-default" color="#000000">
                Netpay
              </B>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <Card
              css={css`
                padding: ${isMobile ? ' 20px 0' : '20px 0'};
                height: 64px;
                border: 1px solid #e0e0e0;
                border-left: none;
                .ant-card-body {
                  padding: 0px !important;
                  max-width: 100%;
                  overflow-x: scroll;
                }
              `}>
              <B type="b-large-bold" color="#000000">
                {netPay ? numberThousandSeparator(netPay) : '-'}
              </B>
            </Card>
          </Col>
        </Row>
      </Div>
    </>
  );
};

export default IndividualEmployeeSelector;
