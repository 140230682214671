import {
  IntegrationItem,
  IntegrationLog,
  IntegrationSetting,
  IntegrationEssential,
} from '../../types';
import axios from 'axios';
import showApiError from '../../util/showApiError';
import { find } from 'lodash';
import { message } from 'antd';

const LOAD_INTEGRATIONS = 'LOAD_INTEGRATIONS';
const SET_INTEGRATIONS = 'SET_INTEGRATIONS';
const LOAD_INTEGRATIONS_OAUTH = 'LOAD_INTEGRATIONS_OAUTH';
const LOAD_INTEGRATION_SETTINGS = 'LOAD_INTEGRATION_SETTINGS';
const LOAD_CHANGE_INTEGRATION_SETTINGS = 'LOAD_CHANGE_INTEGRATION_SETTINGS';
const SET_INTEGRATION_SETTINGS = 'SET_INTEGRATION_SETTINGS';
const SET_INTEGRATION_LOGS = 'SET_INTEGRATION_LOGS';

interface InitialStateI {
  loadingIntegrations: boolean;
  integrationList: IntegrationItem[];
  loadingIntegrationsOAuth: boolean;
  integrationSetting: IntegrationSetting | null;
  integrationSettingLoading: boolean;
  integrationSettingUpdateLoading: boolean;
  integrationLogs: IntegrationLog[];
}

const initialState: InitialStateI = {
  loadingIntegrations: false,
  integrationList: [],
  loadingIntegrationsOAuth: false,
  integrationSetting: null,
  integrationSettingLoading: false,
  integrationSettingUpdateLoading: false,
  integrationLogs: [],
};

export default function reducer(
  state: InitialStateI = initialState,
  action: any,
): InitialStateI {
  switch (action.type) {
    case LOAD_INTEGRATIONS: {
      return {
        ...state,
        loadingIntegrations: action.payload,
      };
    }
    case SET_INTEGRATIONS: {
      return {
        ...state,
        integrationList: action.payload,
      };
    }
    case LOAD_INTEGRATIONS_OAUTH: {
      return {
        ...state,
        loadingIntegrationsOAuth: action.payload,
      };
    }
    case LOAD_INTEGRATION_SETTINGS: {
      return {
        ...state,
        integrationSettingLoading: action.payload,
      };
    }
    case LOAD_CHANGE_INTEGRATION_SETTINGS: {
      return {
        ...state,
        integrationSettingUpdateLoading: action.payload,
      };
    }
    case SET_INTEGRATION_SETTINGS: {
      return {
        ...state,
        integrationSetting: action.payload,
      };
    }
    case SET_INTEGRATION_LOGS: {
      return { ...state, integrationLogs: action.payload };
    }
    default:
      return state;
  }
}

export function getIntegrations() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_INTEGRATIONS, payload: true });
      const res = await axios.get('/integration/list');
      dispatch({ type: SET_INTEGRATIONS, payload: res.data });
      dispatch({ type: LOAD_INTEGRATIONS, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: LOAD_INTEGRATIONS, payload: false });
    }
  };
}

export function getOAuth(id: number) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_INTEGRATIONS_OAUTH, payload: true });
      const res = await axios.get(`/integration/oauth/${id}`);
      window.location.assign(res.data.authURI);
      dispatch({ type: LOAD_INTEGRATIONS_OAUTH, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: LOAD_INTEGRATIONS_OAUTH, payload: false });
    }
  };
}

export function getIntegrationSetting(integrationType: string, navigate?: any) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_INTEGRATION_SETTINGS, payload: true });
      const res = await axios.get('/integration/list');
      dispatch({ type: SET_INTEGRATIONS, payload: res.data });
      const getIntegration = find(
        res.data,
        (i) => i.title.toUpperCase() === integrationType.toUpperCase(),
      );
      const resSettings = await axios.get(
        `/integration/settings/${getIntegration.integrationSettings.id}`,
      );
      dispatch({ type: SET_INTEGRATION_SETTINGS, payload: resSettings.data });
      dispatch({ type: LOAD_INTEGRATION_SETTINGS, payload: false });
    } catch (error: any) {
      dispatch({ type: LOAD_INTEGRATION_SETTINGS, payload: false });
      if (navigate) {
        showApiError(error);
        navigate(`/company-settings/integrations`);
      }
    }
  };
}

export function callBackOAuth(
  integrationType: string,
  callBackURI: string,
  navigate: any,
) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_INTEGRATION_SETTINGS, payload: true });
      const res = await axios.get('/integration/list');
      dispatch({ type: SET_INTEGRATIONS, payload: res.data });
      const getIntegration = find(
        res.data,
        (e) => e.title.toUpperCase() === integrationType.toUpperCase(),
      );
      await axios.post(`/integration/callback/${getIntegration.id}`, {
        callBackURI,
      });
      await dispatch(getIntegrationSetting(integrationType, navigate));
      navigate(`/company-settings/integrations/${integrationType}`);
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: LOAD_INTEGRATION_SETTINGS, payload: false });
    }
  };
}

export function refreshMetaData(id: number) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_INTEGRATION_SETTINGS, payload: true });
      const resSettings = await axios.get(
        `/integration/refresh-meta-data/${id}`,
      );
      dispatch({ type: SET_INTEGRATION_SETTINGS, payload: resSettings.data });
      dispatch({ type: LOAD_INTEGRATION_SETTINGS, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: LOAD_INTEGRATION_SETTINGS, payload: false });
    }
  };
}

export function disableIntegration(id: number, navigate: any) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_INTEGRATION_SETTINGS, payload: true });
      await axios.put(`/integration/disable-integration/${id}`);
      dispatch({ type: LOAD_INTEGRATION_SETTINGS, payload: false });
      navigate(`/company-settings/integrations`);
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: LOAD_INTEGRATION_SETTINGS, payload: false });
    }
  };
}

export function updateIntegrationSettings(
  id: number,
  essential: IntegrationEssential,
) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_CHANGE_INTEGRATION_SETTINGS, payload: true });
      await axios.put(`/integration/settings/${id}`, {
        essential,
      });
      const resSettings = await axios.get(`/integration/settings/${id}`);
      dispatch({ type: SET_INTEGRATION_SETTINGS, payload: resSettings.data });
      dispatch({ type: LOAD_CHANGE_INTEGRATION_SETTINGS, payload: false });
      message.success('Successfully Updated!');
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: LOAD_CHANGE_INTEGRATION_SETTINGS, payload: false });
    }
  };
}

export function getIntegrationLogTrails(id: number) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_INTEGRATION_SETTINGS, payload: true });
      const response = await axios.get(`/integration/settings/logs/${id}`);
      dispatch({ type: SET_INTEGRATION_LOGS, payload: response.data });
      dispatch({ type: LOAD_INTEGRATION_SETTINGS, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: LOAD_INTEGRATION_SETTINGS, payload: false });
    }
  };
}
