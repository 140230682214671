import React, { FC } from 'react';
import { Form, Row } from 'antd';
import { B, Div } from '../../../components';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import theme from '../../../theme';
import styled from '@emotion/styled';
import { space, SpaceProps } from 'styled-system';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type FormItemProps = SpaceProps;

const ProRataCalculations = styled.div`
  padding: 0px 32px 32px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
`;
const FormItem = styled(Form.Item)<FormItemProps>`
  ${space}
`;

interface Props {
  title: string;
  basicSalary: string;
  fixedAllowance: string;
  variableAllowance: string;
  onChange?: (e: CheckboxChangeEvent) => void;
  isSelectPayItems?: boolean;
  payItemName: string;
}

const CalculationSettings: FC<Props> = ({
  title,
  basicSalary,
  fixedAllowance,
  variableAllowance,
  onChange,
  isSelectPayItems,
  payItemName,
}) => {
  return (
    <>
      <ProRataCalculations>
        <B type="b-default" pb="12px">
          {title}
        </B>

        <Row>
          <FormItem
            name={payItemName}
            marginBottom="0px"
            rules={[
              {
                required: !isSelectPayItems && true,
                message: 'Please choose at least one item',
              },
            ]}>
            <Div display="flex" height={25}>
              <Div display="flex">
                <FormItem
                  name={basicSalary}
                  valuePropName="checked"
                  marginBottom="16px">
                  <CheckBoxComp onChange={onChange} />
                </FormItem>
                <B type="b-default" pl="18px" pt="6px" pr="51px">
                  Basic Salary
                </B>
              </Div>
              <Div display="flex">
                <FormItem
                  name={fixedAllowance}
                  valuePropName="checked"
                  marginBottom="16px">
                  <CheckBoxComp onChange={onChange} />
                </FormItem>

                <B type="b-default" pl="18px" pt="6px" pr="51Px">
                  Fixed Allowances
                </B>
              </Div>
              <Div display="flex">
                <FormItem
                  name={variableAllowance}
                  valuePropName="checked"
                  marginBottom="0px">
                  <CheckBoxComp onChange={onChange} />
                </FormItem>
                <B type="b-default" pl="18px" pt="6px">
                  Variable Allowances
                </B>
              </Div>
            </Div>
          </FormItem>
        </Row>
      </ProRataCalculations>
    </>
  );
};

export default CalculationSettings;
