import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useGetGratuityLogs } from '../../../api/gratuityHooks';
import {
  FooterComponent,
  LogTrailTable,
  SecondaryButton,
} from '../../../components';
import { getPathWithOrgId } from '../../../util/getPathWithOrgId';

const GratuityLogTrail: FC = () => {
  const navigate = useNavigate();

  const {
    isLoading: loadingGratuityLogs,
    data: dataGratuityLogs = [],
    mutate: getGratuityLogs,
  } = useGetGratuityLogs();

  useEffect(() => {
    getGratuityLogs();
  }, []);

  return (
    <>
      <LogTrailTable logs={dataGratuityLogs} loading={loadingGratuityLogs} />
      <FooterComponent
        leftChildren={
          <SecondaryButton
            onClick={() => navigate(getPathWithOrgId('/gratuity'))}>
            Back
          </SecondaryButton>
        }
      />
    </>
  );
};
export default GratuityLogTrail;
