import { LoanEnabledOrg, isProduction } from '../configs';
import { store } from '../store';
import { Permission, UserRoleIdEnum } from '../types';
import { validateOrganizationIDs } from './validateOrganizationIDs.util';

export function getCurrentUser() {
  return store.getState().people.me;
}

export const isManager = () => {
  const currentUser = getCurrentUser();
  return !!currentUser?.isManager;
};
export function getUserPermissions() {
  const currentUser = getCurrentUser();
  if (currentUser && currentUser.role?.permissions) {
    return currentUser.role.permissions;
  } else {
    return [];
  }
}

export function isAllowed(permission: Permission): boolean {
  const userPermissions = getUserPermissions();
  if (userPermissions) {
    return userPermissions.includes(permission);
  }
  return false;
}

export function isBelowLevelUser(againstUserRoleId?: UserRoleIdEnum): boolean {
  const currentUser = getCurrentUser();

  if (currentUser && currentUser.role?.roleLevel && againstUserRoleId) {
    return currentUser.role.roleLevel < againstUserRoleId;
  } else {
    return false;
  }
}

export function isSameLevelUser(
  againstUserRoleLevel?: UserRoleIdEnum,
): boolean {
  const currentUser = getCurrentUser();

  if (currentUser && currentUser.role?.roleLevel && againstUserRoleLevel) {
    return currentUser.role.roleLevel == againstUserRoleLevel;
  } else {
    return false;
  }
}

export function isAllowedAnyOf(permissions: Permission[]): boolean {
  const userPermissions = getUserPermissions();
  if (userPermissions) {
    return userPermissions.some((prem) => permissions.includes(prem));
  }
  return false;
}

export const enableLoans = () => {
  return (validateOrganizationIDs(LoanEnabledOrg) && isProduction) ||
    !isProduction
    ? true
    : false;
};
