import React, { FC } from 'react';
import { StyledRect } from '../../../shared/ContractLayout';
import { useZoom } from '../../../shared/ZoomContext';

// All points - 't', 'b', 'r', 'l', 'tr', 'tl', 'br', 'bl'
const MAP_POINTS = ['tr', 'tl', 'br', 'bl'];
interface DraggableProps {
  isReceiver: boolean | undefined;
  selected: boolean | undefined;
  showPoints: boolean | undefined;
  children?: string | React.ReactNode;
  onAction?: () => void;
}
export const DraggableContainer: FC<DraggableProps> = ({
  isReceiver = false,
  selected = false,
  showPoints = false,
  children,
  onAction,
}) => {
  const { ratio } = useZoom();
  return (
    <StyledRect
      onClick={onAction}
      ratio={ratio}
      isReceiver={isReceiver}
      selected={selected}>
      {children}
      {showPoints &&
        selected &&
        MAP_POINTS.map((d, key) => <div key={key} className={`${d} round`} />)}
    </StyledRect>
  );
};
