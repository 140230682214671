import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  DefaultBody,
  FooterComponent,
  SecondaryButton,
} from '../../../components';
import { getIntegrations, getIntegrationSetting } from '../../../store/actions';
import IntegrationLogTrail from './components/IntegrationLogTrail';
import { DispatchType } from '../../../store';

type ParamProps = {
  integration: string;
};

const IntegrationLogTrailPage: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const navigate = useNavigate();
  const { integration } = useParams<ParamProps>();

  useEffect(() => {
    dispatch(getIntegrations());
    dispatch(getIntegrationSetting(integration, navigate));
  }, []);

  return (
    <DefaultBody style={{ marginBottom: 32 }}>
      <IntegrationLogTrail />
      <FooterComponent
        leftChildren={
          <SecondaryButton
            onClick={() => {
              navigate(`/company-settings/integrations/${integration}`);
            }}>
            Back
          </SecondaryButton>
        }
        fullScreen={false}
      />
    </DefaultBody>
  );
};

export default IntegrationLogTrailPage;
