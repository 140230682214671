import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { B } from '../../../../../components';
import theme from '../../../../../theme';
import { SummaryDataI } from '../../../../../types/people.types';
import { addDotsForLongText } from '../../../../../util/utils';
interface Props {
  employment?: SummaryDataI;
}
const BankingDetailsConfirmation: FC<Props> = ({ employment }) => {
  return (
    <>
      <B type="b-small-semibold" color={theme.black} pb="12px">
        Banking Details
      </B>
      <Row gutter={[32, 12]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small" color={theme.gray600}>
            Account Name
          </B>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small" color={theme.black}>
            {employment?.savedUser
              ? employment?.savedUser.bankAccountName
              : '-'}
          </B>
        </Col>
      </Row>
      <Row gutter={[32, 12]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small" color={theme.gray600}>
            Account Number
          </B>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small" color={theme.black}>
            {employment?.savedUser
              ? employment?.savedUser.bankAccountNumber
              : '-'}
          </B>
        </Col>
      </Row>
      <Row gutter={[32, 12]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small" color={theme.gray600}>
            Bank
          </B>
        </Col>
        {employment && employment.savedUser && employment.savedUser.bank && (
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <B type="b-small" color={theme.black}>
              {employment?.savedUser
                ? employment?.savedUser.bank.bankName +
                  '-' +
                  employment?.savedUser.bank.bankCode
                : '-'}
            </B>
          </Col>
        )}
      </Row>
      <Row gutter={[32, 12]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small" color={theme.gray600}>
            Branch
          </B>
        </Col>
        {employment?.savedUser &&
          employment.savedUser.bankBranch &&
          employment.savedUser.bankBranch && (
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <B type="b-small" color={theme.black}>
                {employment?.savedUser
                  ? employment?.savedUser.bankBranch.branchName +
                    '-' +
                    employment?.savedUser.bankBranch.branchCode
                  : '-'}
              </B>
            </Col>
          )}
      </Row>
      <Row gutter={[32, 46]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small" color={theme.gray600}>
            Email address for payslip send off
          </B>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <B type="b-small" color={theme.black}>
            {employment?.savedUser.paySlipMail
              ? addDotsForLongText(employment?.savedUser.paySlipMail, 25)
              : '-'}
          </B>
        </Col>
      </Row>
    </>
  );
};

export default BankingDetailsConfirmation;
