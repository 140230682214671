import React, { FC } from 'react';
import { Radio } from 'antd';
import { RadioGroupProps } from 'antd/lib/radio';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';
import theme from '../theme';
import styled from '@emotion/styled';

const RadioGroupComp = styled(Radio.Group)`
  background: #f5f5f5 !important;
  border-radius: 4px;
  padding: 4px;
  .ant-radio-button-wrapper {
    border: none;
    border-right: 1px solid ${theme.gray300};
    height: 23px;
    line-height: 21px;
    background: none;
  }
  .ant-radio-button-wrapper span {
    color: #757575;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
  .ant-radio-button.ant-radio-button-checked {
    background: ${theme.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked span {
    color: #0052ea;
    border-right: none;
  }

  .ant-radio-button-wrapper:last-child {
    border: none;
    border-radius: 0px 4px 4px 0px;
  }
  .ant-radio-button-wrapper:first-child {
    border: none;
    border-right: 1px solid ${theme.gray300}
  .ant-radio-button-wrapper::before {
    display: none;
    border-right: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    border: none;
    color: unset;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: none;
    border-right: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none;
    border-right: none;
  }
  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: none;
  }
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
    background-color: none;
  }
  .ant-radio-button-wrapper:not(:first-child)::before{
     background-color: none;
  }
  
`;

export const RadioGroup: FC<RadioGroupProps> = (props) => {
  return <RadioGroupComp {...props}>{props.children}</RadioGroupComp>;
};

export const RadioButton: FC<RadioButtonProps> = (props) => {
  return <Radio.Button {...props} />;
};
