import React, { FC } from 'react';
import { Tag } from 'antd';
import theme from '../../theme';
import styled from '@emotion/styled';
import { B } from '../B';

interface Props {
  children: string;
  styleCSS?: string;
  textColor?: string;
  withBorders?: boolean;
  color?: string;
}
const TagComp = styled(Tag)<{
  styleCSS?: string;
  textColor?: string;
  withBorders?: boolean;
  color?: string;
}>`
  display: flex;
  text-align: center;
  height: 20px;
  .ant-btn-loading-icon {
    color: ${theme.white};
    padding-left: 10px;
  }
  margin-left: 5px;
  background: ${(props) => (props.color ? props.color : theme.gray50)};
  margin: 0px 8px 0px 8px;

  ${(props) => props.styleCSS}
`;
export const NameTag: FC<Props> = ({
  children,
  styleCSS,
  withBorders,
  textColor,
  color,
}) => (
  <TagComp bordered={withBorders} styleCSS={styleCSS} color={color}>
    <B typ="b-small" color={textColor}>
      {children}
    </B>
  </TagComp>
);
