import 'react-modern-drawer/dist/index.css';
import React, { FC } from 'react';
import { Popover, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { lowerCase, transform, uniqueId } from 'lodash';
import { Link } from 'react-router-dom';

import {
  CurrentJob,
  DataImportUserI,
  DataImportUserPayLoad,
  PayrollViewUserPayLoad,
} from '../../../store/actions';
import theme from '../../../theme';
import { SalaryInformationView } from '../../../types/payroll.types';
import getNormalText from '../../../util/getNormalText';
import { tableSorter } from '../../../util/tableSorter';
import { safeCheckNaN } from '../../../util/utils';
import { isHaveThreeDots, numberThousandSeparator } from '../../../util/utils';
import { getHoursDaysFromHours } from '../../leave/utils/dateAndTime.util';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer';
import { BasedCurrencyEnum } from '../../../types/organization.types';
import { sortEmpIds } from '../utils/sortingUtils';

interface PeopleCellProp {
  record: DataImportUserPayLoad;
  dataKey: keyof DataImportUserI;
}

type CellDisplayType = string | number | null | undefined | boolean;

interface CommonCellProp {
  error: CellDisplayType;
  value: CellDisplayType;
  updated: CellDisplayType;
  warning?: CellDisplayType;
}
const CommonCell: FC<CommonCellProp> = ({ error, value, updated, warning }) => {
  let tooltip: CellDisplayType = error || warning;

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  let color = `black`;
  const DEFAULT_EMPTY = 'empty';
  const DEFAULT_EMPTY_WITH_ISSUE = '***';

  if (error) {
    color = 'red';
  } else if (warning) {
    color = 'black';
  } else if (updated) {
    color = '#BD640D';
    // only updated value
    tooltip = `Master value${
      organizationData?.peggedSettings?.pegged?.status &&
      organizationData?.peggedSettings?.peggedInfo?.basedCurrency ===
        BasedCurrencyEnum.FOREIGN_CURRENCY
        ? `(${organizationData?.peggedSettings?.peggedInfo?.foreignCurrencyType})`
        : ''
    }: ${updated}`;
  }
  if (error && updated) {
    tooltip = `${error}. However, the master value is ${updated}`;
  } else if (warning && updated) {
    tooltip = `${warning}. However, the master value is ${updated}`;
  }

  if (error || warning) {
    if (!value) {
      value = DEFAULT_EMPTY_WITH_ISSUE;
    }
  }

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <span style={{ color, fontWeight: 'bold' }}>
          {value || DEFAULT_EMPTY}
        </span>
      </Tooltip>
    );
  }
  return (
    <>
      {isHaveThreeDots(String(value), 20) ? (
        <Popover placement="topLeft" content={value} trigger="hover">
          {value || DEFAULT_EMPTY}
        </Popover>
      ) : typeof value === 'boolean' && value === true ? (
        value.toString()
      ) : (
        value || DEFAULT_EMPTY
      )}
    </>
  );
};

const PeopleCell: FC<PeopleCellProp> = ({ record, dataKey }) => {
  let value;
  if (dataKey === 'isSecondary') {
    value = record.values[dataKey] ? 'Yes' : 'No';
  } else {
    value = record.values[dataKey] as CellDisplayType;
  }

  if (dataKey === 'teams') {
    if (record.values[dataKey]) {
      let tempStr = '';
      (record?.values[dataKey] as string[]).forEach((element) => {
        tempStr += element + ', ';
      });
      value = tempStr.slice(0, -2);
    }
  }

  const error = record.employeeErrors[dataKey] as CellDisplayType;
  const updated = record.updatedUserValues[dataKey] as CellDisplayType;

  return <CommonCell value={value} error={error} updated={updated} />;
};

interface CurrentJobCellProp {
  record: DataImportUserPayLoad;
  dataKey: keyof CurrentJob;
}

const CurrentJobCell: FC<CurrentJobCellProp> = ({ record, dataKey }) => {
  const value = record.values.currentJob && record.values.currentJob[dataKey];
  const updated =
    record.updatedUserValues.currentJob &&
    record.updatedUserValues.currentJob[dataKey];
  const error = record.employeeErrors[dataKey] as CellDisplayType;
  return <CommonCell value={value} error={error} updated={updated} />;
};

interface PaySummeryCellProp {
  record: PayrollViewUserPayLoad;
  dataKey: keyof PayrollViewUserPayLoad;
  error?: string;
}

const PaySummeryCell: FC<PaySummeryCellProp> = ({ record, dataKey, error }) => {
  const value = record[dataKey];
  const amountNum = Number(value);

  let displayValue = value;
  if (value && !error) {
    displayValue = !Number.isNaN(amountNum)
      ? numberThousandSeparator(amountNum)
      : value || '';
  }
  return (
    <CommonCell
      value={displayValue as CellDisplayType}
      error={error}
      updated={null}
    />
  );
};
interface LeaveSummeryCellProp {
  record: DataImportUserPayLoad;
  dataKey: string;
  error?: string;
}

const LeaveSummeryCell: FC<LeaveSummeryCellProp> = ({
  record,
  dataKey,
  error,
}) => {
  const leaveObject: { [key: string]: number } | undefined =
    record.values.leaveInformation &&
    transform(record.values.leaveInformation, (result, val, key) => {
      if (result) result[lowerCase(key)] = val;
    });
  const value = leaveObject ? leaveObject[lowerCase(dataKey)] : null;
  if (error) {
    return (
      <Tooltip title={error}>
        <span style={{ color: 'red' }}>{value || 'Empty'}</span>
      </Tooltip>
    );
  }
  return <>{value !== null ? getHoursDaysFromHours(value).text : 'Empty'}</>;
};

interface PayslipFieldsCellProp {
  record: DataImportUserPayLoad;
  dataKey: string;
  error?: string;
}
const PayslipFieldsCell: FC<PayslipFieldsCellProp> = ({
  record,
  dataKey,
  error,
}) => {
  const payslipFieldObject = record.values.payslipFields?.find(
    (item) =>
      item.key.toLowerCase().replace(/ +/g, '').trim() ==
      dataKey.toLowerCase().replace(/ +/g, '').trim(),
  );

  const value = payslipFieldObject ? payslipFieldObject.value : null;
  if (error) {
    return (
      <Tooltip title={error}>
        <span style={{ color: 'red' }}>{value || 'Empty'}</span>
      </Tooltip>
    );
  }
  return <>{value ? value : 'Empty'}</>;
};

interface PayItemCellProp {
  record: DataImportUserPayLoad;
  dataKey: string;
  error?: string;
}

const PayItemCell: FC<PayItemCellProp> = ({ record, dataKey }) => {
  const value = record.values.payItemValues
    ? record.values.payItemValues[dataKey]
    : null;

  let updated: string | undefined | number;
  let warning: string | undefined | number;

  const error = value ? value.error : null;
  const found = record.payrollUpdates.find((item) => item.payTitle === dataKey);
  if (found) {
    if (found.amount) {
      updated = !Number.isNaN(Number(found.amount))
        ? numberThousandSeparator(Number(found.amount))
        : found.amount;
    } else {
      updated = found.amount;
    }
  }

  const foundWarning = record.payItemWarnings.find(
    (item) => item.payTitle === dataKey,
  );
  if (foundWarning) {
    warning = foundWarning.message;
  }
  const amountNum = Number(value?.amount);

  let displayValue = value?.amount;
  if (value && !error) {
    displayValue = !Number.isNaN(amountNum)
      ? numberThousandSeparator(amountNum)
      : '';
  }

  return (
    <CommonCell
      value={displayValue as CellDisplayType}
      error={error}
      updated={updated}
      warning={warning}
    />
  );
};

export type TableColumnProp = ColumnProps<DataImportUserPayLoad> & {
  hide?: boolean;
};

export const getColumns = ({
  onErrorFilter,
  payItemErrorColumns,
  payItemColumns,
  payrollKey,
  selectedEmployeeOnly,
  leaveColumn,
  payslipFieldsColumn,
  leaveAllowedForPayslip,
  disableView,
  isPreferredName,
  showDrawer,
  employeeErrorObj,
  isMobile,
  isTablet,
  isDraftPayroll,
  isMidCyclePayrun,
}: {
  drawerDataSet?: (value: DataImportUserPayLoad) => void;
  onErrorFilter?: boolean;
  payItemErrorColumns?: string[];
  employeeErrorObj?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  payItemColumns: string[];
  payrollKey?: string;
  selectedEmployeeOnly?: boolean;
  leaveColumn?: string[];
  payslipFieldsColumn?: string[];
  leaveAllowedForPayslip?: boolean;
  disableView?: boolean;
  isPreferredName: boolean;
  visible: boolean;
  showDrawer?: (value: DataImportUserPayLoad) => void;
  onClose: () => void;
  isMobile: boolean;
  isTablet: boolean;
  isDraftPayroll?: boolean;
  isMidCyclePayrun?: boolean;
}) => {
  const shouldShowFiled = (propertyName: string) => {
    return !!(onErrorFilter && !employeeErrorObj?.hasOwnProperty(propertyName));
  };

  const columns: TableColumnProp[] = [
    {
      title: 'EMP ID',
      width: 120,
      align: 'right',
      fixed: isMobile || isTablet ? undefined : 'left',
      hide: false,
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        sortEmpIds(a.values.employeeNumber, b.values.employeeNumber),
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <>
          <PeopleCell record={record} dataKey="employeeNumber" />
        </>
      ),
    },
    {
      title: 'Full Name',
      width: 189,
      fixed: isMobile || isTablet ? undefined : 'left',
      hide: false,
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.fullName, b.values.fullName),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      render: (record: DataImportUserPayLoad) => {
        return (
          <>
            <PeopleCell record={record} dataKey="fullName" />
          </>
        );
      },
    },
    {
      title: 'Name With Initials',
      width: 189,
      hide: shouldShowFiled('nameWithInitials'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(
          a.values.nameWithInitials,
          b.values.nameWithInitials,
        ),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      render: (record: DataImportUserPayLoad) => {
        return <PeopleCell record={record} dataKey="nameWithInitials" />;
      },
    },
    {
      title: 'First Name',
      width: 169,
      hide: shouldShowFiled('firstName'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.firstName, b.values.firstName),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      render: (record: DataImportUserPayLoad) => {
        return <PeopleCell record={record} dataKey="firstName" />;
      },
    },
    {
      title: 'Last Name',
      width: 189,
      hide: shouldShowFiled('lastName'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.lastName, b.values.lastName),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      render: (record: DataImportUserPayLoad) => {
        return <PeopleCell record={record} dataKey="lastName" />;
      },
    },
    ...(payItemColumns.length
      ? [
          ...payItemColumns
            .filter((item) => {
              return !onErrorFilter
                ? true
                : payItemErrorColumns?.includes(item);
            })
            .map((payTitle) => {
              return {
                title: payTitle,
                align: 'right',
                ellipsis: { showTitle: true },
                hide: false,
                key: uniqueId(),
                sorter: (
                  a: DataImportUserPayLoad,
                  b: DataImportUserPayLoad,
                ) => {
                  const aValue = a.values.payItemValues
                    ? a.values.payItemValues[payTitle]?.amount || 0
                    : 0;
                  const bValue = b.values.payItemValues
                    ? b.values.payItemValues[payTitle]?.amount || 0
                    : 0;
                  return tableSorter.defaultSort(aValue, bValue);
                },
                sortDirections: ['descend', 'ascend'],
                render: (record: DataImportUserPayLoad) => (
                  <PayItemCell record={record} dataKey={payTitle} />
                ),
                width: 160,
              };
            }),
        ]
      : []),
    ...(payrollKey
      ? !isMidCyclePayrun
        ? [
            {
              title: 'Deduction',
              width: 150,
              align: 'right',
              key: uniqueId(),
              sorter: (a: PayrollViewUserPayLoad, b: PayrollViewUserPayLoad) =>
                tableSorter.defaultSort(a.deduction, b.deduction),
              sortDirections: ['descend', 'ascend'],
              render: (record: PayrollViewUserPayLoad) => (
                <PaySummeryCell record={record} dataKey="deduction" />
              ),
            },
            {
              title: 'Net Salary',
              width: 150,
              align: 'right',
              key: uniqueId(),
              sorter: (a: PayrollViewUserPayLoad, b: PayrollViewUserPayLoad) =>
                tableSorter.defaultSort(a.netSalary, b.netSalary),
              sortDirections: ['descend', 'ascend'],
              render: (record: PayrollViewUserPayLoad) => {
                return (
                  <PaySummeryCell
                    record={record}
                    dataKey="netSalary"
                    error={
                      Number(record.netSalary) <= 0
                        ? 'Net Salary should be higher than 0'
                        : undefined
                    }
                  />
                );
              },
            },
            {
              title: 'Cost To Company',
              align: 'right',
              width: 150,
              key: uniqueId(),
              sorter: (a: PayrollViewUserPayLoad, b: PayrollViewUserPayLoad) =>
                tableSorter.defaultSort(a.costToCompany, b.costToCompany),
              sortDirections: ['descend', 'ascend'],
              render: (record: PayrollViewUserPayLoad) => (
                <PaySummeryCell record={record} dataKey="costToCompany" />
              ),
            },
          ]
        : [
            {
              title: 'Net Salary',
              width: 150,
              align: 'right',
              key: uniqueId(),
              sorter: (a: PayrollViewUserPayLoad, b: PayrollViewUserPayLoad) =>
                tableSorter.defaultSort(a.netSalary, b.netSalary),
              sortDirections: ['descend', 'ascend'],
              render: (record: PayrollViewUserPayLoad) => {
                return (
                  <PaySummeryCell
                    record={record}
                    dataKey="netSalary"
                    error={
                      Number(record.netSalary) <= 0
                        ? 'Net Salary should be higher than 0'
                        : undefined
                    }
                  />
                );
              },
            },
            {
              title: 'Cost To Company',
              align: 'right',
              width: 150,
              key: uniqueId(),
              sorter: (a: PayrollViewUserPayLoad, b: PayrollViewUserPayLoad) =>
                tableSorter.defaultSort(a.costToCompany, b.costToCompany),
              sortDirections: ['descend', 'ascend'],
              render: (record: PayrollViewUserPayLoad) => (
                <PaySummeryCell record={record} dataKey="costToCompany" />
              ),
            },
          ]
      : []),

    {
      title: 'Account Name',
      ellipsis: true,
      hide: shouldShowFiled('bankAccountName'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(
          a.values.bankAccountName,
          b.values.bankAccountName,
        ),
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="bankAccountName" />
      ),
      width: 240,
    },
    {
      title: 'Account No.',
      ellipsis: true,
      hide: shouldShowFiled('bankAccountNumber'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(
          a.values.bankAccountNumber,
          b.values.bankAccountNumber,
        ),
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="bankAccountNumber" />
      ),
      width: 170,
    },
    {
      title: 'Bank Code',
      hide: shouldShowFiled('bankBankCode'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.bankBankCode, b.values.bankBankCode),
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="bankBankCode" />
      ),
      width: 130,
    },
    {
      title: 'Branch Code',
      hide: shouldShowFiled('branchCode'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.branchCode, b.values.branchCode),
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="branchCode" />
      ),
      width: 130,
    },
    {
      title: 'Payslip Mail',
      hide: shouldShowFiled('paySlipMail'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.paySlipMail, b.values.paySlipMail),
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="paySlipMail" />
      ),
      width: 230,
    },
    {
      title: 'EPF Number',
      hide: shouldShowFiled('epfNumber'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.epfNumber, b.values.epfNumber),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="epfNumber" />
      ),
      width: 140,
    },
    {
      title: 'ETF Number',
      hide: shouldShowFiled('etfNumber'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.etfNumber, b.values.etfNumber),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="etfNumber" />
      ),
      width: 140,
    },
    {
      title: 'Tax Number',
      hide: shouldShowFiled('taxNumber'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.taxNumber, b.values.taxNumber),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="taxNumber" />
      ),
      width: 140,
    },
    ...(isPreferredName
      ? [
          {
            title: 'Preferred Name',
            width: 189,
            hide: shouldShowFiled('preferredName'),
            key: uniqueId(),
            sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
              tableSorter.defaultSort(a.values.fullName, b.values.fullName),
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
            render: (record: DataImportUserPayLoad) => {
              return (
                <>
                  <PeopleCell record={record} dataKey="preferredName" />
                </>
              );
            },
          },
        ]
      : []),
    {
      title: 'Login Email',
      hide: shouldShowFiled('loginEmail'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.loginEmail, b.values.loginEmail),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="loginEmail" />
      ),
      width: 230,
    },
    {
      title: 'Gender',
      hide: shouldShowFiled('gender'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.gender, b.values.gender),
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="gender" />
      ),
      width: 180,
    },
    {
      title: 'Personal Mobile',
      hide: shouldShowFiled('personalMobile'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(
          a.values.personalMobile,
          b.values.personalMobile,
        ),
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="personalMobile" />
      ),
      width: 180,
    },
    {
      title: 'Phone Number',
      hide: shouldShowFiled('homeMobile'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.homeMobile, b.values.homeMobile),
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="homeMobile" />
      ),
      width: 180,
    },
    {
      title: 'Joined Date',
      hide: shouldShowFiled('joinedDate'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.joinedDate, b.values.joinedDate),
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="joinedDate" />
      ),
      width: 180,
    },
    {
      title: 'Type',
      hide: shouldShowFiled('employmentType'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) => {
        if (a.values.currentJob && b.values.currentJob)
          tableSorter.defaultSort(
            a.values.currentJob.employmentType,
            b.values.currentJob.employmentType,
          );
      },
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <CurrentJobCell record={record} dataKey="employmentType" />
      ),
      width: 150,
    },
    {
      title: 'Branch',
      hide: shouldShowFiled('branchName'),
      key: uniqueId(),
      ellipsis: true,
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) => {
        if (a.values.currentJob && b.values.currentJob)
          tableSorter.defaultSort(
            a.values.currentJob.branchName,
            b.values.currentJob.branchName,
          );
      },
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <CurrentJobCell record={record} dataKey="branchName" />
      ),
      width: 130,
    },
    {
      title: 'Designation',
      hide: shouldShowFiled('designation'),
      key: uniqueId(),
      ellipsis: true,
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) => {
        if (a.values.currentJob && b.values.currentJob)
          tableSorter.defaultSort(
            a.values.currentJob.designation,
            b.values.currentJob.designation,
          );
      },
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <CurrentJobCell record={record} dataKey="designation" />
      ),
      width: 200,
    },
    {
      title: 'Start Date',
      hide: shouldShowFiled('startDate'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) => {
        if (a.values.currentJob && b.values.currentJob)
          tableSorter.defaultSort(
            a.values.currentJob.startDate,
            b.values.currentJob.startDate,
          );
      },
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <CurrentJobCell record={record} dataKey="startDate" />
      ),
      width: 120,
    },
    {
      title: 'End Date',
      hide: shouldShowFiled('endDate'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) => {
        if (a.values.currentJob && b.values.currentJob)
          tableSorter.defaultSort(
            a.values.currentJob.endDate,
            b.values.currentJob.endDate,
          );
      },
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <CurrentJobCell record={record} dataKey="endDate" />
      ),
      width: 120,
    },
    {
      title: 'Resignation Date',
      hide: shouldShowFiled('resignedDate'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.resignedDate, b.values.resignedDate),
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="resignedDate" />
      ),
      width: 180,
    },
    {
      title: 'Occupation G.',
      hide: shouldShowFiled('occupationGrade'),
      key: uniqueId(),
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="occupationGrade" />
      ),
      width: 100,
    },
    {
      title: 'Teams',
      ellipsis: true,
      hide: shouldShowFiled('teams'),
      key: uniqueId(),
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="teams" />
      ),
      width: 140,
    },
    {
      title: 'Address',
      ellipsis: true,
      hide: shouldShowFiled('address'),
      key: uniqueId(),
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="address" />
      ),
      width: 200,
    },
    {
      title: 'Date Of Birth',
      hide: shouldShowFiled('DOB'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.DOB, b.values.DOB),
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="DOB" />
      ),
      width: 120,
    },
    {
      title: 'NIC',
      width: 105,
      hide: shouldShowFiled('nicOrPassport'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.nicOrPassport, b.values.nicOrPassport),
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => (
        <PeopleCell record={record} dataKey="nicOrPassport" />
      ),
    },

    {
      title: 'Secondary Employment',
      width: 165,
      hide: shouldShowFiled('isSecondary'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.isSecondary, b.values.isSecondary),
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => {
        return <PeopleCell record={record} dataKey="isSecondary" />;
      },
    },
    {
      title: 'Primary Salary',
      width: 135,
      hide: shouldShowFiled('primarySalary'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.primarySalary, b.values.primarySalary),
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => {
        return <PeopleCell record={record} dataKey="primarySalary" />;
      },
    },

    {
      title: 'Attendance',
      width: 125,
      hide: shouldShowFiled('attendance'),
      key: uniqueId(),
      sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) =>
        tableSorter.defaultSort(a.values.attendance, b.values.attendance),
      sortDirections: ['descend', 'ascend'],
      render: (record: DataImportUserPayLoad) => {
        return <PeopleCell record={record} dataKey="attendance" />;
      },
    },

    {
      title: 'No of worked days',
      width: 135,
      hide: shouldShowFiled('workedDays'),
      key: uniqueId(),
      render: (record: DataImportUserPayLoad) => {
        return <PeopleCell record={record} dataKey="workedDays" />;
      },
    },

    ...(leaveColumn?.length && leaveAllowedForPayslip
      ? [
          // title: 'Leave Information',
          ...leaveColumn.map((leaveTitle) => {
            return {
              hide: shouldShowFiled(leaveTitle),
              title: getNormalText(leaveTitle),
              key: uniqueId(),
              sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) => {
                const aValue = a.values.leaveInformation
                  ? a.values.leaveInformation
                  : 0;
                const bValue = b.values.leaveInformation
                  ? b.values.leaveInformation
                  : 0;
                return tableSorter.defaultSort(aValue, bValue);
              },
              sortDirections: ['descend', 'ascend'],
              render: (record: DataImportUserPayLoad) => (
                <LeaveSummeryCell record={record} dataKey={leaveTitle} />
              ),

              width: 180,
            };
          }),
        ]
      : []),
    ...(payslipFieldsColumn?.length
      ? [
          ...payslipFieldsColumn.map((title) => {
            return {
              hide: shouldShowFiled(title),
              title: title,
              key: uniqueId(),
              sorter: (a: DataImportUserPayLoad, b: DataImportUserPayLoad) => {
                const aValue = a.values.payslipFields
                  ? a.values.payslipFields
                  : 0;
                const bValue = b.values.payslipFields
                  ? b.values.payslipFields
                  : 0;
                return tableSorter.defaultSort(aValue, bValue);
              },
              sortDirections: ['descend', 'ascend'],
              render: (record: DataImportUserPayLoad) => {
                return <PayslipFieldsCell record={record} dataKey={title} />;
              },

              width: 170,
            };
          }),
        ]
      : []),
    ...((payrollKey && !disableView) || !selectedEmployeeOnly
      ? !selectedEmployeeOnly
        ? [
            {
              title: '',
              width: 60,
              fixed: 'right',
              align: 'right',
              key: uniqueId(),
              render: (_value: string, data: DataImportUserPayLoad) => (
                <>
                  <Link
                    to={{
                      pathname: `/personal-salary-information/${payrollKey}/employee/${
                        data.values.id
                      }/${
                        isDraftPayroll
                          ? SalaryInformationView.ALL_EMPLOYEE
                          : SalaryInformationView.COMPLETED
                      }`,
                    }}>
                    <p
                      style={{
                        margin: 0,
                        fontSize: 14,
                        color: '#0052ea',
                        cursor: 'pointer',
                      }}>
                      {isDraftPayroll ? 'Edit' : 'View'}
                    </p>
                  </Link>
                </>
              ),
            },
          ]
        : [
            {
              title: '',
              width: 100,
              fixed: 'right',
              align: 'right',
              key: uniqueId(),
              render: (_value: string, data: DataImportUserPayLoad) => (
                <Link
                  to={{
                    pathname: `/personal-salary-information/${payrollKey}/employee/${data.values.id}/${SalaryInformationView.CONFIRM_EMPLOYEE}`,
                  }}>
                  <p
                    style={{
                      margin: 0,
                      fontSize: 14,
                      color: '#0052ea',
                      cursor: 'pointer',
                    }}>
                    view
                  </p>
                </Link>
              ),
            },
          ]
      : [
          {
            title: '',
            width: 100,
            fixed: 'right',
            align: 'right',
            key: uniqueId(),
            render: (value: DataImportUserPayLoad) => {
              return (
                <div>
                  <p
                    style={{
                      margin: 0,
                      fontSize: 14,
                      color: theme.blue500,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      showDrawer && showDrawer(value);
                    }}>
                    Edit
                  </p>
                </div>
              );
            },
          },
        ]),
  ] as TableColumnProp[];

  return columns.filter((item) => !item.hide);
};
