import { getCurrentUser } from './permissionUtil';
/**
 * Warn: path should not have question mark (?) // TODO: fix this to support ? url
 */
export const getPathWithOrgId = (
  path: string,
  organizationId?: number,
): string => {
  return `${path}?organizationId=${
    organizationId || getCurrentUser()?.organizationId
  }`;
};
