import styled from '@emotion/styled';
import { Col, Form, Row, Tag } from 'antd';
import { SelectValue } from 'antd/lib/select';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetExistingPayruns } from '../../../../../api/payrollHooks';
import { Validation } from '../../../../../common/validation';
import { monthToStringTrimmed } from '../../../../../util/timeConvertion';
import { IAttendanceData } from '../../../../../types/attendance.types';
import { CheckBoxComp } from '../../../../../components/v2/CheckBoxComp';
import { useGetExistingAttendance } from '../../../../../api/attendanceHooks';
import { stringToMonthNumTrimmed } from '../../../../../util/timeConvertion';
import { SwitchComponent } from '../../../../../components';
import {
  AlertMessage,
  B,
  Div,
  InputComp,
  InputNumberComp,
  SelectComp,
} from '../../../../../components';
import { ModalComponent } from '../../../../../components/ModalComponent';
import {
  getMyOrganization,
  getPayrollSummary,
  PayrunFormI,
  PayrunI,
} from '../../../../../store/actions';
import { AttendanceFilterTypes } from '../../../../../types/attendance.types';
import { Input, Typography } from 'antd';
import { DispatchType, RootState } from '../../../../../store/reducer';
import theme from '../../../../../theme';
import { BasedCurrencyEnum } from '../../../../../types/organization.types';
import { PayrunType } from '../../../../../types/payroll.types';
import { selectMonths } from '../../../../../util/months';
import {
  getPayrollMonthAndYear,
  getPayrollStartEndDates,
} from '../../../utils/getPayrollPeriod';
import { pastYearEnableOrg } from '../../../../../configs/appConfig';
import { validateOrganizationIDs } from '../../../../../util/validateOrganizationIDs.util';
import dayjs from 'dayjs';

const PayRunForm = styled.div`
  .ant-form-item:last-child {
    margin-bottom: 0;
  }
`;

const { Text } = Typography;

const Label = styled(Text)`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.blue700};
  padding-bottom: 4px;
  margin-top: 3px;
  margin-left: 14px;
`;

const TimeAttendanceLabel = styled.div`
  display: flex;
  margin-top: 23px;
  margin-bottom: 13px;
  .ant-form-item {
    margin-bottom: 0 !important;
  }
`;

interface Props {
  onClose: () => void;
  isReRun?: boolean;
  payRun?: PayrunI | null;
  onSubmit: (formValues: PayrunI) => void;
  visible: boolean;
  isCsvPayrun?: boolean;
  buttonDisabled?: boolean;
  attendanceData?: IAttendanceData;
}
const FullHeightInput = styled(Input)`
  height: 31px;
`;
const PayRunModal: FC<Props> = ({
  onClose,
  isReRun,
  onSubmit,
  payRun,
  visible,
  isCsvPayrun,
  buttonDisabled,
  attendanceData,
}) => {
  const filteredMonth = attendanceData
    ? monthToStringTrimmed(attendanceData?.month)
    : null;
  const year = attendanceData ? attendanceData?.year : null;
  const payrunTypes = attendanceData ? attendanceData?.payrunType : null;
  const [form] = Form.useForm();
  const [remarkData, setRemarkData] = useState<string | null>(null);
  const [modalTitle, setModalTitle] = useState<string>('Create New Payrun');
  const [submitText, setSubmitText] = useState<string>('Create Payrun');
  const [payrunMonth, setPayrunMonth] = useState<SelectValue>();
  const [payrunYear, setPayrunYear] = useState<SelectValue>();
  const [payrunType, setPayrunType] = useState<SelectValue>();
  const [isTimeAttendance, setIsTimeAttendance] = useState<boolean>(false);

  const dispatch: DispatchType = useDispatch();

  const payrollLoading = useSelector(
    (state: RootState) => state.payroll.payrollLoading,
  );

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const [previousDraftedPayrolls, setPreviousDraftedPayrolls] = useState([]);
  const [payrunYearOptions, setPayrunYearOptions] = useState<
    {
      label: number;
      value: number;
    }[]
  >([]);

  useEffect(() => {
    if (year) {
      setPayrunYear(year);
    }
    if (filteredMonth) {
      setPayrunMonth(filteredMonth);
    }
  }, [year, filteredMonth]);

  useEffect(() => {
    /**
     *
     * @param offset to know which year its off setting from current year
     * @returns options of years to be displayed in payrun modal
     */
    const getYearOption = (offset: number) => ({
      label: moment().add(offset, 'year').year(),
      value: moment().add(offset, 'year').year(),
    });

    const initialOptions = validateOrganizationIDs(pastYearEnableOrg)
      ? [
          getYearOption(-2),
          getYearOption(-1),
          getYearOption(0),
          getYearOption(1),
        ]
      : [getYearOption(-1), getYearOption(0), getYearOption(1)];

    setPayrunYearOptions(initialOptions);
  }, [pastYearEnableOrg]);

  const { data: getExistingPayruns, mutate: getExistingPayrunsList } =
    useGetExistingPayruns();

  const { data: getExistingAttendances, mutate: getExistingAttendancesList } =
    useGetExistingAttendance();

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (year && filteredMonth) {
    useEffect(() => {
      getExistingPayrunsList({
        month: filteredMonth,
        year: year,
      });
    }, [filteredMonth, year]);
  } else {
    useEffect(() => {
      getExistingPayrunsList({
        month: String(payrunMonth),
        year: Number(payrunYear),
      });
      getExistingAttendancesList({
        month: months.indexOf(String(payrunMonth)),
        year: Number(payrunYear) || 0,
      });
    }, [payrunMonth, payrunYear]);
  }

  const filterPayrolls = getExistingPayruns?.filter(
    (i) => i.payrunType === payrunType,
  );

  const filterAttendance = getExistingAttendances?.find(
    (i) =>
      i.month === months.indexOf(String(payrunMonth)) &&
      i.year === Number(payrunYear),
  );

  const previousPayroll = useSelector(
    (state: RootState) => state.payroll.payrollSummery,
  );

  const filterDraftedPayrolls = getExistingPayruns?.filter(
    (i) =>
      i.payrunType === PayrunType.MID_CYCLE_PAYRUN && i.status === 'DRAFTED',
  );
  const payrollValidation =
    filterPayrolls &&
    ((payrunType === PayrunType.MONTHLY_PAYRUN && !!filterPayrolls?.length) ||
      // || payrunType === PayrunType.MID_CYCLE_PAYRUN && !!completedPayrolls?.length
      // || payrunType === PayrunType.MID_CYCLE_PAYRUN && filterPayrolls?.length
      (payrunType === PayrunType.MONTHLY_PAYRUN &&
        !!filterDraftedPayrolls?.length));

  const attendanceValidation = filterAttendance;
  const attendanceStatusValidation =
    filterAttendance &&
    filterAttendance?.status === AttendanceFilterTypes.DRAFT;

  const ytdDraftValidation =
    organizationData.isYearToDateInPayslip && previousDraftedPayrolls.length;

  const onFinish = async (values: PayrunFormI) => {
    const formValues: PayrunI = {
      name: values.name,
      payrunType: values.payrunType,
      month: values.month,
      attendanceId: isTimeAttendance ? filterAttendance?.id : null,
      year: Number(values.year),
      variableWorkingDays: Number(values.variableWorkingDays),
      payrollStartDate:
        payRun && !isCsvPayrun
          ? payRun.payrollStartDate
          : moment(
              getPayrollStartEndDates(
                organizationData?.payrunStartDate,
                payrunMonth,
                payrunYear,
              ).startDate,
            ).format('YYYY-MM-DD'),
      payrollEndDate:
        payRun && !isCsvPayrun
          ? payRun.payrollEndDate
          : moment(
              getPayrollStartEndDates(
                organizationData?.payrunStartDate,
                payrunMonth,
                payrunYear,
              ).endDate,
            ).format('YYYY-MM-DD'),
      defaultRemark: remarkData,
      currentExchangeRate: Number(values.currentExchangeRate),
      peggedCurrency: payRun?.peggedCurrency
        ? payRun.peggedCurrency
        : organizationData?.peggedSettings?.pegged?.status &&
          organizationData?.peggedSettings?.peggedInfo.basedCurrency ===
            BasedCurrencyEnum.LKR
        ? organizationData.peggedSettings.peggedInfo.currency
        : organizationData?.peggedSettings?.pegged?.status &&
          organizationData?.peggedSettings?.peggedInfo.basedCurrency ===
            BasedCurrencyEnum.FOREIGN_CURRENCY
        ? organizationData.peggedSettings.peggedInfo.foreignCurrencyType
        : null,
      peggedBasedCurrency: organizationData?.peggedSettings?.pegged?.status
        ? organizationData?.peggedSettings?.peggedInfo?.basedCurrency
        : null,
    };
    onSubmit(formValues);
    form.setFieldsValue({ defaultRemark: '' });
  };

  const setDefaultRemark = () => {
    const x = document.getElementById('pname') as HTMLInputElement;
    setRemarkData(x.value);
  };

  useEffect(() => {
    dispatch(getPayrollSummary());
    dispatch(getMyOrganization());
    const prev = previousPayroll?.previousPayroll.filter(
      (payRun) => !payRun.isArchived && payRun.status == 'DRAFTED',
    );
    setPreviousDraftedPayrolls(prev);
  }, []);

  useEffect(() => {
    setRemarkData(payRun?.defaultRemark ? payRun?.defaultRemark : '');
    if (payRun) {
      setModalTitle('Edit Payrun Details');
      setSubmitText('Update ');
      for (const [key, value] of Object.entries(payRun)) {
        form.setFieldsValue({
          payrunType: payRun?.payrunType
            ? payRun.payrunType
            : PayrunType.MONTHLY_PAYRUN,
        });
        form.setFieldsValue({
          month: payRun?.month
            ? payRun.month
            : dayjs(
                getPayrollMonthAndYear(
                  moment(payRun.payrollStartDate),
                  moment(payRun.payrollEndDate),
                  'MMMM',
                ),
              ),
        });
        form.setFieldsValue({
          year: payRun?.year
            ? payRun.year
            : dayjs(
                getPayrollMonthAndYear(
                  moment(payRun.payrollStartDate),
                  moment(payRun.payrollEndDate),
                  'YYYY',
                ),
              ),
        });
        form.setFieldsValue({ [key]: value });
      }
    }
  }, [payRun]);

  useEffect(() => {
    if (isReRun) {
      setModalTitle('Re-run Payroll');
      setSubmitText('Duplicate Payrun');
    }
  }, [isReRun]);

  return (
    <ModalComponent
      form={form}
      submitText={submitText}
      loading={payrollLoading || buttonDisabled}
      width={540}
      visible={visible}
      disabled={
        payrollValidation ||
        buttonDisabled ||
        ytdDraftValidation ||
        (!attendanceValidation && isTimeAttendance) ||
        (attendanceStatusValidation && isTimeAttendance)
          ? true
          : false
      }
      hideCancelButton={!!isCsvPayrun}
      closable={!isCsvPayrun}
      maskClosable={!isCsvPayrun}
      onCancel={() => onClose()}
      title={
        <B type="b-large-semibold" color={theme.black}>
          {modalTitle}
        </B>
      }>
      <PayRunForm>
        <Form
          size="small"
          initialValues={{
            ...payRun,
            remember: true,
            year: year,
            month: filteredMonth,
            payrunType: payrunTypes,
            defaultRemark: remarkData,
          }}
          form={form}
          onFinish={onFinish}>
          {payrollValidation ||
          ytdDraftValidation ||
          !attendanceValidation ||
          attendanceStatusValidation ? (
            <Div mb="16px">
              <AlertMessage
                title={
                  ytdDraftValidation
                    ? 'Please complete draft payruns before creating new payrun'
                    : payrunType === PayrunType.MONTHLY_PAYRUN &&
                      !!filterPayrolls?.length
                    ? 'You have already created a monthly pay run for this salary cycle!'
                    : payrunType === PayrunType.MONTHLY_PAYRUN &&
                      !!filterDraftedPayrolls?.length
                    ? 'Complete the existing mid cycle payrun for this salary cycle'
                    : isTimeAttendance &&
                      filterAttendance &&
                      filterAttendance?.status === AttendanceFilterTypes.DRAFT
                    ? 'Please approve the attendance data before creating payrun'
                    : isTimeAttendance
                    ? 'There is no time attendance data available'
                    : ''
                }
                type="error"
                iconCssStyle={
                  (payrunType === PayrunType.MONTHLY_PAYRUN &&
                    !!filterPayrolls?.length) ||
                  (payrunType === PayrunType.MONTHLY_PAYRUN &&
                    !!filterDraftedPayrolls?.length)
                    ? 'margin-top: 0px'
                    : 'margin-top: -15px'
                }
              />
            </Div>
          ) : null}

          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter a payrun name',
              },
            ]}>
            <InputComp
              size="small"
              onBlur={() => setDefaultRemark()}
              id="pname"
              name="name"
              label="Payrun Name"
              placeholder="Eg: November Payrun"
            />
          </Form.Item>

          <Form.Item
            name="payrunType"
            rules={[
              {
                required: true,
                message: 'Please choose a payrun type',
              },
            ]}>
            {payrunTypes ? (
              <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Label>Payrun Type</Label>
                </div>
                <Input size="middle" defaultValue={payrunTypes} disabled />
              </>
            ) : (
              <SelectComp
                size="middle"
                label="Payrun Type"
                onChange={(v) => setPayrunType(v)}
                options={[
                  {
                    label: 'Mid-Cycle Payrun',
                    value: PayrunType.MID_CYCLE_PAYRUN,
                    disabled: submitText === 'Duplicate Payrun' ? true : false,
                  },
                  {
                    label: 'Monthly Payrun',
                    value: PayrunType.MONTHLY_PAYRUN,
                  },
                ]}
                placeholder="Select payrun type"
                disabled={
                  isTimeAttendance ||
                  (submitText === 'Update ' &&
                    payRun?.payrollStartDate &&
                    payRun.payrollEndDate &&
                    !isCsvPayrun)
                    ? true
                    : false
                }
              />
            )}
          </Form.Item>
          {organizationData &&
            organizationData.monthlyWorkingDaysSettings === 'Variable' && (
              <Form.Item
                name="variableWorkingDays"
                rules={[
                  {
                    required: true,
                    message: 'Please insert the number of working days',
                  },
                ]}>
                <InputComp
                  size="small"
                  onBlur={() => setDefaultRemark()}
                  id="variableWorkingDays"
                  label="No of Working Days"
                  placeholder="Enter no of working days"
                />
              </Form.Item>
            )}
          <Row gutter={[32, 24]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="year"
                rules={[
                  {
                    required: true,
                    message: 'Please choose a payrun year',
                  },
                ]}>
                {year ? (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Label>Year</Label>
                    </div>
                    <FullHeightInput defaultValue={year} disabled />
                  </>
                ) : (
                  <SelectComp
                    size="middle"
                    label="Year"
                    options={payrunYearOptions}
                    onChange={(v) => setPayrunYear(v)}
                    showSearch
                    placeholder="Choose year"
                    disabled={
                      submitText === 'Update ' &&
                      payRun?.payrollStartDate &&
                      payRun.payrollEndDate &&
                      !isCsvPayrun
                        ? true
                        : false
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="month"
                rules={[
                  {
                    required: true,
                    message: 'Please choose a payrun month',
                  },
                ]}>
                {filteredMonth ? (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Label>Month</Label>
                    </div>
                    <Input
                      size="middle"
                      defaultValue={filteredMonth}
                      disabled
                    />
                  </>
                ) : (
                  <SelectComp
                    size="middle"
                    label="Month"
                    options={selectMonths}
                    onChange={(v) => setPayrunMonth(v)}
                    showSearch
                    placeholder="Choose month"
                    disabled={
                      submitText === 'Update ' &&
                      payRun?.payrollStartDate &&
                      payRun.payrollEndDate &&
                      !isCsvPayrun
                        ? true
                        : false
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          {!filteredMonth &&
          organizationData?.timeAttendanceSettings?.isTimeAttendance ? (
            <TimeAttendanceLabel>
              {' '}
              <Form.Item name="timeAttendance" valuePropName="checked">
                <SwitchComponent
                  disabled={
                    payrunType === PayrunType.MID_CYCLE_PAYRUN || !payrunType
                  }
                  onChange={(checked: boolean) => setIsTimeAttendance(checked)}
                />
              </Form.Item>
              <Label>Time Attendance</Label>
            </TimeAttendanceLabel>
          ) : null}

          {payRun &&
          !payRun?.currentExchangeRate &&
          !isCsvPayrun ? null : organizationData?.peggedSettings &&
            organizationData?.peggedSettings?.pegged?.status &&
            organizationData?.peggedSettings?.peggedInfo?.basedCurrency ===
              BasedCurrencyEnum.LKR &&
            !payRun?.currentExchangeRate ? (
            <Form.Item
              name="currentExchangeRate"
              rules={[
                {
                  required: true,
                  message: 'Please enter current exchange rate',
                },
                {
                  pattern: organizationData?.isFourDecimalPoint
                    ? Validation.FourDecimalPointAmountPattern
                    : Validation.AmountPattern,
                  message: 'Please put a valid rate',
                },
              ]}>
              <InputNumberComp
                min={1}
                size="middle"
                name="currentExchangeRate"
                label={`Current Exchange Rate (${organizationData.peggedSettings.peggedInfo.currency} to LKR)`}
                placeholder="Current Exchange Rate"
              />
            </Form.Item>
          ) : organizationData?.peggedSettings &&
            organizationData?.peggedSettings?.pegged?.status &&
            organizationData?.peggedSettings?.peggedInfo?.basedCurrency ===
              BasedCurrencyEnum.FOREIGN_CURRENCY &&
            !payRun?.currentExchangeRate ? (
            <Form.Item
              style={{ marginTop: '16px' }}
              name="currentExchangeRate"
              rules={[
                {
                  required: true,
                  message: 'Please enter current exchange rate',
                },
                {
                  pattern: Validation.AmountPattern,
                  message: 'Please put a valid rate',
                },
              ]}>
              <InputNumberComp
                min={1}
                size="middle"
                name="currentExchangeRate"
                label={`Current Exchange Rate (${organizationData.peggedSettings.peggedInfo.foreignCurrencyType} to LKR)`}
                placeholder="Current Exchange Rate"
              />
            </Form.Item>
          ) : null}
          {!!payRun?.currentExchangeRate && (
            <Form.Item
              name="currentExchangeRate"
              rules={[
                {
                  required: true,
                  message: 'Please enter current exchange rate',
                },
                {
                  pattern: Validation.AmountPattern,
                  message: 'Please put a valid rate',
                },
              ]}>
              <InputNumberComp
                min={1}
                size="middle"
                name="currentExchangeRate"
                label={`Current Exchange Rate (${
                  payRun?.peggedCurrency ? payRun?.peggedCurrency : 'USD'
                } to LKR)`}
                placeholder="Current Exchange Rate"
                disabled={
                  submitText === 'Update ' && payRun?.currentExchangeRate
                    ? true
                    : false
                }
              />
            </Form.Item>
          )}
          <br />

          <Form.Item
            rules={[
              {
                required: true,
                message: 'Please insert a remark!',
              },
              {
                max: 10,
                message: 'Length should be less than 10',
              },
            ]}>
            <InputComp
              onChange={(e) => setRemarkData(e.target.value)}
              size="small"
              value={remarkData ? remarkData : ''}
              name="defaultRemark"
              label="Default Remark"
              placeholder="Eg: Salary November"
              infoText="  This will used as bank beneficiary remark"
            />
          </Form.Item>
        </Form>
      </PayRunForm>
    </ModalComponent>
  );
};

export default PayRunModal;
