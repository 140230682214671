import React, { FC } from 'react';

import { Popover } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { capitalize } from 'lodash';
import moment from 'moment';

import styled from '@emotion/styled';

import { EmptyTextContainer, TableComp } from '../../../../../components';
import { PayrollI } from '../../../../../types/superAdmin.types';
import { isValidMoment } from '../../../../../util/isValidMoment';
import { tableSorter } from '../../../../../util/tableSorter';
import { addDotsForLongText, isHaveThreeDots } from '../../../../../util/utils';

interface Props {
  payroll: PayrollI[];
}
const Title = styled.p`
  margin-bottom: 0px;
`;

const LastColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

const PayrollTable: FC<Props> = ({ payroll }) => {
  const columns: ColumnProps<PayrollI>[] = [
    {
      title: 'Payroll ID',
      dataIndex: 'id',
      width: 120,
    },
    {
      title: 'Payroll Title',
      dataIndex: 'name',
      width: 180,
      render: (value: string) => (
        <>
          {isHaveThreeDots(value, 20) ? (
            <Popover content={value} trigger="hover" placement="topLeft">
              <Title>{value ? addDotsForLongText(value, 20) : '-'}</Title>
            </Popover>
          ) : (
            <Title>{value ? value : '-'}</Title>
          )}
        </>
      ),
    },
    {
      title: 'Pay Period',
      dataIndex: 'payrollStartDate',
      width: 250,
      render: (_value: string, data) => (
        <Title>
          {' '}
          {moment(data.payrollStartDate).isValid()
            ? moment(data.payrollStartDate).format('DD.MM.YYYY')
            : '-'}
          -
          {moment(data.payrollEndDate).isValid()
            ? moment(data.payrollEndDate).format('DD.MM.YYYY')
            : '-'}
        </Title>
      ),
    },
    {
      title: 'Employees',
      dataIndex: 'empPayrollCount',
      width: 120,
    },
    {
      title: 'Payroll status',
      dataIndex: 'status',
      width: 140,
      render: (value: string) => <Title>{capitalize(value)}</Title>,
    },
    {
      title: 'Last Updated Date',
      dataIndex: 'updateAt',
      width: 180,
      render: (value: string) => (
        <LastColumn>
          <Title>{isValidMoment(value, '-', 'DD.MM.YYYY').toString()}</Title>
        </LastColumn>
      ),
      sorter: (a, b) => tableSorter.defaultSort(a.updateAt, b.updateAt),
      defaultSortOrder: 'descend',
    },
  ];

  const locale = {
    emptyText: <EmptyTextContainer description="Not Found" />,
  };

  return (
    <TableComp
      size="middle"
      columns={columns}
      locale={locale}
      dataSource={payroll}
      scroll={{
        x: 800,
        y: window.innerHeight - 400,
      }}
      pagination={false}
      withBorders={true}
    />
  );
};
export default PayrollTable;
