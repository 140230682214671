import React, { FC, useEffect } from 'react';
import { Form } from 'antd';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { RootState } from '../../../../store/reducer';
import theme from '../../../../theme';
import { EmploymentForm } from '../../../people/pages/employment/EmploymentForm';
import { AccountDetailLayout } from '../../components/AccountDetailLayout';
import { ExperienceCard } from './ExperienceCard';
import dayjs from 'dayjs';

const CustomForm = styled(Form)`
  padding: 0px 0px 32px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const EmploymentDetail: FC = () => {
  const [form] = Form.useForm();
  const me = useSelector((state: RootState) => state.people.me);
  useEffect(() => {
    const newPeople = { ...me };
    if (newPeople) {
      for (const [key, value] of Object.entries(newPeople)) {
        if (key === 'joinedDate') {
          form.setFieldsValue({
            joinedDate: dayjs(me?.joinedDate),
          });
        } else if (key == 'resignedDate') {
          form.setFieldsValue({
            resignedDate: dayjs(me?.resignedDate),
          });
        } else if (key === 'teams' && value === null) {
          form.setFieldsValue({ [key]: [] });
        } else {
          form.setFieldsValue({ [key]: value });
        }
      }
    }
  }, [me]);
  return (
    <CustomForm form={form} layout="vertical" wrapperCol={{ span: 50 }}>
      <AccountDetailLayout>
        <EmploymentForm defaultDisable />
        <ExperienceCard />
      </AccountDetailLayout>
    </CustomForm>
  );
};
export default EmploymentDetail;
