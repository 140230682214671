import React, { useState } from 'react';

import { css } from '@emotion/react';

import { Div, H } from '../../components';
import SalaryForm from './shared/SalaryForm';
import SalaryResult from './shared/SalaryResult';
import { calaulateSalarySlip, PayItemType, PaySlipType } from './salary.helper';
import styled from '@emotion/styled';

const Calculator = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 400px;
`;

const SalaryCalculator = () => {
  const [results, setResults] = useState<null | PaySlipType>(null);
  const onFinish = (values: PayItemType[]) => {
    const salaryResult = calaulateSalarySlip(values);
    setResults(salaryResult);
  };

  return (
    <Calculator>
      <H type="h6" pt="30px" pb="20px">
        SL Salary Calculator
      </H>
      <SalaryForm onFinish={onFinish} onChange={() => setResults(null)} />

      <Div textAlign="initial">
        <SalaryResult paySlipResult={results}></SalaryResult>
      </Div>
    </Calculator>
  );
};

export default SalaryCalculator;
