import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { B, Div, LinkButton } from '../../../../components';
import theme from '../../../../theme';
import { Permission } from '../../../../types';
import { NotificationItemI } from '../../../../types/notification.types';
import { isAllowed } from '../../../../util/permissionUtil';
import NoteForm from './NoteForm';
import NoteItem from './NoteItem';
import { PlusBlueSVG } from '../../../../assets';

const StyledDiv = styled.div`
  background: ${theme.offWhite};
  border: 1px solid ${theme.offWhite};
  box-sizing: border-box;
  border-radius: 4px;
`;
interface Props {
  addAndEditOnly: boolean;
  notifications: NotificationItemI[];
  onEditNote: (editIndex: number, data: NotificationItemI) => void;
  onAddNewNote: (data: NotificationItemI) => void;
  onRemove: (editIndex: number) => void;
}

const NoteContainer: FC<Props> = ({
  onEditNote,
  notifications,
  onAddNewNote,
  onRemove,
  addAndEditOnly,
}) => {
  const [showAddNewNote, setShowAddNewNote] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  const onSubmitNote = (value: NotificationItemI) => {
    if (editIndex > -1) {
      onEditNote(editIndex, value);
    } else {
      onAddNewNote(value);
    }
    setShowAddNewNote(false);
    setEditIndex(-1);
  };

  const onCancel = () => {
    setShowAddNewNote(false);
    setEditIndex(-1);
  };

  useEffect(() => {
    onCancel();
  }, [notifications]);

  return (
    <div>
      {isAllowed(Permission.MANAGE_NOTIFICATION) &&
        (!showAddNewNote ? (
          <StyledDiv>
            <LinkButton
              icon={<img src={PlusBlueSVG} />}
              onClick={() => setShowAddNewNote(true)}
              id="add-note-btn">
              Add New Note
            </LinkButton>
          </StyledDiv>
        ) : (
          <NoteForm
            onSubmit={(v) => onSubmitNote(v)}
            onCancel={() => onCancel()}
          />
        ))}
      {!notifications.length && (
        <Div
          height="84px"
          mt="16px"
          background={theme.gray50}
          display="flex"
          borderRadius="4px">
          <B type="b-default" color={theme.gray600} margin="auto">
            You don't have any notes yet.
          </B>
        </Div>
      )}
      {notifications.map((item, index) => {
        if (index === editIndex) {
          return (
            <NoteForm
              editItem={item}
              onSubmit={(v) => onSubmitNote(v)}
              onCancel={() => onCancel()}
            />
          );
        }
        return (
          <NoteItem
            addAndEditOnly={addAndEditOnly}
            item={item}
            onClickEdit={() => {
              setEditIndex(index);
            }}
            onRemove={() => {
              if (item.id) {
                onRemove(item.id);
              } else {
                onRemove(index);
                setEditIndex(-1);
              }
            }}
          />
        );
      })}
      <br />
    </div>
  );
};

export default NoteContainer;
