import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { H } from '../../../../components';
import { meBankDetails } from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import { MultiBankDetailForm } from '../../../people/pages/bank-page/MultiBankDetailForm';
import UserPeopleSelect from '../../../people/shared/UserPeopleSelect';
import { AccountDetailLayout } from '../../components/AccountDetailLayout';

const BankDetailsLayout = styled.div`
  padding: 32px;
  @media (max-width: 768px) {
    padding: 0px;
    margin-bottom: 80px;
  }
`;
const DropdownSection = styled.div`
  @media (max-width: 1024px) {
    padding-bottom: 24px;
  }
`;

const BankDetail: FC = () => {
  const me = useSelector((state: RootState) => state.people.me);
  const dispatch: DispatchType = useDispatch();

  return (
    <AccountDetailLayout>
      <BankDetailsLayout>
        <Row>
          <Col xs={0} sm={0} md={0} lg={0} xl={17} xxl={19}>
            <H type="h6" pb="32px">
              Bank Details
            </H>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12} md={12} lg={12} xl={0} xxl={0}>
            <DropdownSection>
              <UserPeopleSelect defaultIndex="4" />
            </DropdownSection>
          </Col>
        </Row>
        {me && (
          <MultiBankDetailForm
            defaultDisable
            bankDetail={{
              bankAccountName: me.bankAccountName,
              bankAccountNumber: me.bankAccountNumber,
              bankBankCode: me.bankBankCode,
              bankBranchId: me.bankBranchId,

              primaryAccountAmount: me.primaryAccountAmount,
              secondaryBankAccountName: me.secondaryBankAccountName,
              secondaryBankAccountNumber: me.secondaryBankAccountNumber,
              secondaryBankBankCode: me.secondaryBankBankCode,
              secondaryBankBranchCode: me.secondaryBankBranchCode,
              paySlipMail: me.paySlipMail,
              isEmployeePaidInCash: me.isEmployeePaidInCash,
            }}
            onSubmit={(values) => dispatch(meBankDetails(values))}
          />
        )}
      </BankDetailsLayout>
    </AccountDetailLayout>
  );
};

export default BankDetail;
