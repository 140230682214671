import React, { FC, useEffect, useState } from 'react';
import {
  B,
  Div,
  InputComp,
  ModalComponent,
  SelectComp,
} from '../../components';
import { Col, Form, Row } from 'antd';
import theme from '../../theme';
import { CheckBoxComp } from '../../components/v2/CheckBoxComp';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { AddContactFormI, AddPendingEmployeeI } from '../../types/people.types';
import {
  useAddExternalAccountant,
  useCreatePendingUser,
} from '../../api/peopleHooks';
import { UserRoleIdEnum } from '../../types';
import { DispatchType, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getAllowedUserRoles } from '../../util/getAllowedUserRoles';
import { getAllPeople, getUserRoles } from '../../store/actions';
import { SelectValue } from 'antd/es/select';
import { TextAreaComponent } from '../../components/TextArea';

const StyledDescription = styled(Form.Item)`
  label {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: ${theme.blue700} !important;
  }

  margin-bottom: 0px !important;
`;

interface Props {
  visible: boolean;
  showModal: (val: boolean) => void;
  organizationName: string;
}

const AddContactModal: FC<Props> = ({
  visible,
  showModal,
  organizationName,
}) => {
  const [form] = Form.useForm();
  const dispatch: DispatchType = useDispatch();
  const allPeople = useSelector((state: RootState) => state.people.allPeoples);
  const navigate = useNavigate();
  const { mutate: postNewEmployee } = useCreatePendingUser();
  const { mutate: postNewAccountant } = useAddExternalAccountant();

  const userRoles = useSelector((state: RootState) => state.people.userRoles);
  const descriptionTextConst = `We're excited to officially welcome you to the team at ${organizationName}! As you prepare to start your journey with us, we kindly ask you to complete your onboarding process by providing your personal details through the link below.`;

  const [onBoardUsingEmail, setOnBoardUsingEmail] = useState(false);
  const [allowedUserRoles, setAllowedUserRoles] = useState<
    { value: UserRoleIdEnum; label: string }[]
  >([]);
  const [selectedUserRole, setSelectedUserRole] = useState<SelectValue>();
  const [subjectText, setSubjectText] = useState<string>(
    'Complete your onboarding process',
  );
  const [descriptionText, setTextDescription] =
    useState<string>(descriptionTextConst);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  useEffect(() => {
    dispatch(getAllPeople());
  }, []);

  useEffect(() => {
    dispatch(getUserRoles());
    form.setFieldsValue({
      subject: subjectText,
      description: descriptionText,
    });
  }, []);

  useEffect(() => {
    setTextDescription(descriptionTextConst);
    form.setFieldsValue({
      subject: subjectText,
      description: descriptionText,
    });
  }, [organizationName]);

  useEffect(() => {
    const results = getAllowedUserRoles(userRoles);
    const guestAdminRole = { value: 4, label: 'Guest Admin' };
    results.push(guestAdminRole);
    setAllowedUserRoles(results);
  }, [userRoles]);

  const handleFinish = async (values: AddContactFormI) => {
    values.subject = subjectText;
    values.description = descriptionText;
    if (selectedUserRole === UserRoleIdEnum.GUEST_ADMIN) {
      await postNewAccountant({
        fullName: values.preferredName,
        email: values.email,
      });
    } else if (onBoardUsingEmail) {
      const newEmployeeDraft: AddPendingEmployeeI = {
        preferredName: values.preferredName,
        email: values.email,
        roleId: values.roleId,
        nameWithInitials: 'T',
        isPending: true,
        subject: values.subject,
        description: values.description,
      };
      await postNewEmployee(newEmployeeDraft);
    } else {
      navigate(`/contact/add-new-employee/${selectedUserRole}`);
    }
    handleCancel();
  };

  const handleCancel = () => {
    showModal(false);
  };

  const checkUserEmail = (value: string) => {
    const userEmails = allPeople.map(
      (item) => item.companyEmail ?? item.onboardingEmail,
    );
    return !userEmails.some((userEmail) => userEmail && userEmail === value);
  };

  const catchFieldsErrors = () => {
    const hasErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length > 0);
    setIsSubmitDisabled(hasErrors);
  };

  return (
    <>
      <ModalComponent
        form={form}
        centered
        width={585}
        visible={visible}
        onCancel={handleCancel}
        submitText="Create Contact"
        title={
          <B type="b-large-semibold" color={theme.black}>
            Add Contact
          </B>
        }
        disabled={isSubmitDisabled}>
        <div>
          <Form
            form={form}
            layout="vertical"
            wrapperCol={{ span: 50 }}
            onFinish={handleFinish}
            onFieldsChange={catchFieldsErrors}
            name="addContact">
            <Form.Item
              name="preferredName"
              rules={[
                { required: true, message: 'Please enter the Preferred Name' },
              ]}>
              <InputComp
                placeholder="Akshay Segar"
                label="Preferred Name"
                size="small"
              />
            </Form.Item>
            <Row gutter={[32, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the Email Address.',
                    },
                    {
                      type: 'email',
                      message: 'Not a valid Email Address.',
                    },
                    {
                      validator: (_, value) => {
                        if (checkUserEmail(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'Email already exists. Please enter a different email',
                          ),
                        );
                      },
                    },
                  ]}>
                  <InputComp
                    label="Email Address"
                    placeholder="akshay@gmail.com"
                    size="small"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="roleId"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the user type.',
                    },
                  ]}>
                  <SelectComp
                    options={allowedUserRoles}
                    onChange={(e) => setSelectedUserRole(e)}
                    label="User Type"
                    placeholder="Employee"
                    size="middle"
                  />
                </Form.Item>
              </Col>
            </Row>
            {selectedUserRole !== 4 && (
              <>
                <Form.Item name="onboardViaEmail" style={{ marginBottom: 0 }}>
                  <Div display="flex">
                    <CheckBoxComp
                      key={123}
                      value={onBoardUsingEmail}
                      checked={onBoardUsingEmail}
                      onChange={(e) => setOnBoardUsingEmail(e.target.checked)}
                    />
                    <B type="b-default" ml={12}>
                      Onboard Via Email
                    </B>
                  </Div>
                </Form.Item>
                {onBoardUsingEmail && (
                  <div>
                    <Form.Item name="subject" style={{ marginTop: 16 }}>
                      <InputComp
                        placeholder="Complete your onboarding process"
                        label="Subject"
                        size="small"
                        value={subjectText}
                        onChange={(e) => setSubjectText(e.target.value)}
                      />
                    </Form.Item>
                    <StyledDescription name="description" label="Description">
                      <TextAreaComponent
                        style={{ minHeight: 156 }}
                        value={descriptionText}
                        onChange={(e) => setTextDescription(e.target.value)}
                        placeholder="We're excited to officially welcome you to the team at ABC Company! As you prepare to start your ..."
                      />
                    </StyledDescription>
                  </div>
                )}
              </>
            )}
          </Form>
        </div>
      </ModalComponent>
    </>
  );
};

export default AddContactModal;
