import { Dayjs } from 'dayjs';

export interface IAttendanceData {
  id: string;
  organizationId: number;
  title: string;
  payrunType: string;
  month: number;
  year: number;
  status: string;
  isArchived: boolean;
  createdAt: Date;
}

export interface IExistingAttendanceData {
  id: string;
  organizationId: number;
  title: string;
  payrunType: string;
  month: number;
  year: number;
  status: string;
  isArchived: boolean;
  createdAt: Date;
}

export interface AttendancePeriodI {
  month: number;
  year: number;
}

export interface IAttendanceItemsData {
  totalOtMinutes: number;
  totalNoPayMinutes: number;
  totalAttendance: number;
  month: number;
  year: number;
  data: IAttendanceUserSummary[];
}

export interface AttendanceSettings {
  isTimeAttendance: boolean;
  roundOffToNearest: number;
  roundOff: string;
  noPay: boolean;
  minOverTimeAmountPerDay: number;
}

export interface IUserAttendanceItem {
  id: string;
  employeeId: string;
  overtimeMinutes: number;
  noPayMinutes: number;
  isExceeded?: boolean;
  createdAt: string;
  date: string;
}

export interface IUserAttendanceFormData {
  id: string;
  employeeId: string;
  overtimeMinutes: Dayjs;
  noPayMinutes: Dayjs;
  isExceeded?: boolean;
  createdAt: string;
  date: string;
}

export interface IViewData {
  month: number;
  year: number;
  createdAt: Date;
  employeeCount: number;
  data: IUserAttendanceSummary[];
  status: string;
}

export interface IAttendanceURLData {
  url: string;
  filename: string;
}

export interface IUserAttendanceSummary {
  employeeId: string;
  fullName: string;
  totalOverTimeMinutes: number;
  totalNoPayMinutes: number;
  totalWorkingDays: number;
  extraDays: number;
  workingDays: number;
  annualLeaves: number;
  companyLeaves: number;
  finalNoPay: number;
  hasErrors: boolean;
  errors: any;
  id?: number;
}

export interface IUpdateStatus {
  id: string;
  employeeIds: string[];
}

export interface IIDs {
  id: string;
  employeeId: string;
}

export interface IApproveModalData {
  month: string;
  uploadedDate: string;
  monthYear: string;
  noOfEmployees: number;
}

export enum AttendanceFilterTypes {
  ALL = 'ALL',
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED',
  ARCHIVED = 'ARCHIVED',
}

export interface IDateModalData {
  id: number;
  employeeId: string;
  fullName: string;
  date: string;
  noPayMinutes?: number;
  overTimeMinutes?: number;
}

export interface IAttendanceUserSummary {
  id: string;
  timeAttendanceId: number;
  employeeId: string;
  fullName: string;
  startTime: string;
  endTime: string;
  isWorkingDay: boolean;
  overtimeMinutes: number;
  noPayMinutes: number;
  totalMinutes: number;
  status: AttendanceFilterTypes;
  date: string;
  createdAt: string;
  updatedAt: string;
}

export interface IAttendanceUserTable {
  id: string;
  timeAttendanceId: number;
  employeeId: string;
  fullName: string;
  startTime: string;
  endTime: string;
  isWorkingDay: boolean;
  overtimeMinutes: Dayjs;
  noPayMinutes: Dayjs;
  totalMinutes: number;
  status: AttendanceFilterTypes;
  date: string;
  createdAt: string;
  updatedAt: string;
}
