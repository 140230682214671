import React, { FC, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { RootState } from '../../../../store/reducer';
import { Permission } from '../../../../types';
import { isAllowed, isManager } from '../../../../util/permissionUtil';
import { PeopleLayout } from '../../shared';
import AddNotes from './AddNotes';
import { useIndividualPeopleState } from '../../../../providers/IndividualPeopleState';
import { FooterComponent, PrimaryButton } from '../../../../components';

type ParamsI = {
  employeeKey: string;
};
const NotePage: FC = () => {
  const navigate = useNavigate();
  const params = useParams<ParamsI>();
  const me = useSelector((state: RootState) => state.people.me);
  const {
    state: { people, isLoading },
    fetchPeopleById,
  } = useIndividualPeopleState();

  const fetchPeople = () => {
    fetchPeopleById(params.employeeKey);
  };

  useEffect(() => {
    fetchPeople();
  }, []);

  useEffect(() => {
    if (
      (!isManager() && !isAllowed(Permission.MANAGE_NOTIFICATION)) ||
      (isManager() && Number(params.employeeKey) === me?.id)
    ) {
      navigate('/contact');
    }
  }, []);

  return (
    <PeopleLayout>
      <AddNotes />
      {people?.isPending && (
        <FooterComponent
          rightChildren={
            <PrimaryButton
              ml={16}
              htmlType="submit"
              loading={isLoading}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/contact/settings/${params.employeeKey}`);
              }}>
              Next
            </PrimaryButton>
          }
        />
      )}
    </PeopleLayout>
  );
};

export default NotePage;
