import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { LinkButton } from '../../../../../components';
import { DataImportUserPayLoad } from '../../../../../store/actions';
import { generatePayrollExcel } from '../../../utils/payrollExport/generatePayrollExport';
import axios from 'axios';
import { BASE_URL } from '../../../../../configs/appConfig';
import { ExportSVG } from '../../../../../assets';

interface Props {
  dataForTable:
    | {
        dataList: DataImportUserPayLoad[];
        payItemColumns: string[];
      }
    | undefined;
  leaveColumnNames?: string[];
  suffix: string;
  payrollViewData: any;
}

export const PayrollExport: FC<Props> = ({
  dataForTable,
  leaveColumnNames,
  suffix,
  payrollViewData,
}) => {
  return (
    <Tooltip title="Export as Excel Sheet">
      <LinkButton
        size="small"
        onClick={async () => {
          const response = await axios.get(`${BASE_URL}/organization`);
          const organizationData = response.data;
          generatePayrollExcel(
            dataForTable,
            leaveColumnNames,
            suffix,
            organizationData,
            payrollViewData,
          );
        }}
        icon={<img width="20px" height="20px" src={ExportSVG} />}>
        Export
      </LinkButton>
    </Tooltip>
  );
};
