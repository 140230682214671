import { Col, Row } from 'antd';
import React, { FC, ReactNode, useState } from 'react';
import theme from '../../../theme';
import { useWindowSize } from '../../../util/screenSize';
import { AccountDetailMenu } from './AccountDetailMenu';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer';
import { isAllowed, isManager } from '../../../util/permissionUtil';
import { Permission } from '../../../types';
import { Header } from '../../people/shared/Header';
import { DefaultBody } from '../../../components';
import styled from '@emotion/styled';

const AccountDetailContainer = styled.div`
  background: #ffffff;
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px;
  @media (max-width: 768px) {
    border: none;
  }
`;

const CustomColumn = styled(Col)<{ isBorderRight: boolean }>`
  ${({ isBorderRight }) =>
    isBorderRight && `border-right:1px solid ${theme.gray300};`}
  @media (max-width: 1024px) {
    display: none;
  }
`;

interface PeopleLayoutProp {
  children: ReactNode;
}
export const AccountDetailLayout: FC<PeopleLayoutProp> = ({ children }) => {
  const { isMobile, isTablet } = useWindowSize();
  const [titleName, setTitleName] = useState('');
  const me = useSelector((state: RootState) => state.people.me);

  return (
    <>
      {/* <Helmet>
        <title>{titleName}</title>
      </Helmet> */}
      {(isAllowed(Permission.VIEW_USER_INFO) || isManager()) && (
        <Header people={me} />
      )}
      <DefaultBody>
        <AccountDetailContainer>
          <Row>
            <CustomColumn
              xs={0}
              sm={0}
              md={0}
              lg={6}
              xl={6}
              xxl={5}
              isBorderRight={!isMobile && !isTablet}>
              <AccountDetailMenu
                onChange={(value) => {
                  setTitleName(value);
                }}
              />
            </CustomColumn>
            <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={19}>
              {children}
            </Col>
          </Row>
        </AccountDetailContainer>
      </DefaultBody>
    </>
  );
};
