import React, { FC } from 'react';
import { B, DefaultBody } from '../../../components';
import ExportDataTable from './ExportDataTable';
import theme from '../../../theme';

const DataExportPage: FC = ({}) => {
  return (
    <>
      <DefaultBody>
        <B type="b-default" color={theme.gray600} mb="16px">
          Conveniently export a detailed report on payroll, leave, and employees
          onto Excel.
        </B>
        <ExportDataTable />
      </DefaultBody>
    </>
  );
};
export default DataExportPage;
