import React, { FC, useState } from 'react';
import { Avatar, Col, Form, Image, message, Row, Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { B, Div, GhostButton } from '../../../components';
import { BASE_URL } from '../../../configs';
import { RootState } from '../../../store/reducer';
import theme from '../../../theme';
import { useWindowSize } from '../../../util/screenSize';
import { getCurrentAuthToken } from '../../../util/storageUtil';
import { normFile } from '../../../util/utils';
import logoSvg from '../../../assets/logo.svg';
import { EditSVG, InfoSVG, RedInfoSVG, UploadSVG } from '../../../assets';
interface ImageProp {
  onChangeImageUpload: (url: string) => void;
}
const ImageUpload = styled(Upload)`
  .ant-upload-list-item-name {
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
const UploadButtonIcon = styled.img`
  margin-bottom: -3px;
`;
const ErrorIcon = styled.img`
  margin-right: 5px;
  width: 20px;
  height: 20px;
`;
const UploadImage = styled(Image)`
  border: 1px solid ${theme.gray300};
`;

const props: UploadProps = {
  showUploadList: {
    showRemoveIcon: false,
  },
};

const CharacterAvatar = styled(Avatar)`
  color: ${theme.gray600};
  background-color: ${theme.gray300};
  .ant-avatar-string {
    font-size: 34px;
  }
`;

const CompanyLogo: FC<ImageProp> = ({ onChangeImageUpload }) => {
  const { isMobile } = useWindowSize();
  const [fileData, setFileData] = useState<any>();
  const [isUploadError, setIsUploadError] = useState(false);
  const [isTypeError, setIsTypeError] = useState(false);

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const IMAGE_UPLOAD_LIMIT = 1024 * 1024 * 5;

  return (
    <Div
      p={isMobile ? '24px 10px' : '32px 32px 34px'}
      borderBottom={`1px solid ${theme.gray300}`}>
      <B type="b-large-semibold" mb="12px">
        Company Logo
      </B>
      <B type="b-default" color={theme.gray600} mb="24px">
        This logo will be visible to all team members.
      </B>

      <Row>
        <Col flex="80px">
          {!organizationData?.logo ? (
            <CharacterAvatar size={64} shape="square">
              {organizationData?.name?.charAt(0)}
            </CharacterAvatar>
          ) : (
            <UploadImage
              width={64}
              height={64}
              src={organizationData?.logo || logoSvg}
            />
          )}
        </Col>
        <Col flex="auto">
          <Div display="flex" flexDirection="row">
            <Form.Item
              getValueFromEvent={(e) => normFile(e, fileData)}
              name="logo"
              noStyle
              rules={[
                {
                  required: false,
                  message: 'image is required',
                },
              ]}>
              <ImageUpload
                name="file"
                accept=".jpg, .png"
                multiple={true}
                fileList={fileData}
                action={`${BASE_URL}/organization/uploadLogo`}
                headers={{
                  Authorization: `Bearer ${getCurrentAuthToken()}`,
                }}
                beforeUpload={(file) => {
                  if (file.size > IMAGE_UPLOAD_LIMIT) {
                    message.error('Image size more than 5MB');
                    setIsUploadError(true);
                    return false;
                  } else {
                    setIsUploadError(false);
                    return true;
                  }
                }}
                onChange={(info) => {
                  if (info.fileList.length > 1) {
                    if (info.file.status === 'error') {
                      setIsTypeError(true);
                      message.error('error');
                    } else if (info.file.status === 'done') {
                      setIsTypeError(false);
                      onChangeImageUpload(fileData.response);
                    }
                    setFileData(info.fileList.slice(-1));
                  } else {
                    if (info.file.status === 'error') {
                      setIsTypeError(true);
                      message.error('error');
                    } else if (info.file.status === 'done') {
                      onChangeImageUpload(info.file.response);
                      setIsTypeError(false);
                    }
                    setFileData(info.fileList);
                  }
                }}
                {...props}>
                <GhostButton
                  icon={
                    <>
                      {organizationData?.logo ? (
                        <UploadButtonIcon
                          width="18px"
                          height="18px"
                          src={EditSVG}
                        />
                      ) : (
                        <UploadButtonIcon
                          width="14px"
                          height="17px"
                          src={UploadSVG}
                        />
                      )}
                    </>
                  }>
                  {organizationData?.logo
                    ? 'Change Profile Photo'
                    : 'Upload Profile Photo'}
                </GhostButton>
              </ImageUpload>
            </Form.Item>
          </Div>
          <Row>
            <B
              type="b-small"
              color={
                isUploadError || isTypeError ? theme.red600 : theme.gray600
              }
              display="flex"
              pt="6px"
              pl="14px">
              <ErrorIcon
                src={isUploadError || isTypeError ? RedInfoSVG : InfoSVG}
              />
              {isUploadError
                ? 'Your image was too large to upload. Images must have a file size of less than  MB.'
                : isTypeError
                ? 'You have uploaded an unsupported file type for the profile picture.Please try again.'
                : 'You can upload a jpg, GIF or PNG file (File size limit is 5 MB).'}
            </B>
          </Row>
        </Col>
      </Row>
    </Div>
  );
};

export default CompanyLogo;
