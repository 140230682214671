import React, { FC } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import theme from '../../theme';
import styled from '@emotion/styled';
import { color, ColorProps, space, SpaceProps } from 'styled-system';

type GhostBtnProps = ColorProps &
  SpaceProps &
  Omit<ButtonProps, 'size'> & {
    size?: 'small' | 'large';
  };

const GhostBtn = styled(Button)<GhostBtnProps>`
  background-color: transparent;
  color: ${theme.blue500};
  border: none;
  box-shadow: none;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  height: 40px;
  &:hover {
    background-color: ${theme.gray100};
    color: ${theme.blue500};
  }
  &:focus {
    background-color: ${theme.gray100};
    color: ${theme.blue500};
  }
  &.btn-disabled {
    background-color: transparent;
    color: ${theme.blue500};
    opacity: 0.5;
  }
  &.btn-small {
    height: 32px;
    font-size: 14px;
    padding-right: 20px;
  }
  .btn-custom-icon-left {
    margin-right: ${(props) => (props.icon ? '6px' : 0)};
    max-height: 20px;
  }
  .ant-btn-icon {
  }
  ${space}
  ${color}
`;

export const GhostButton: FC<GhostBtnProps> = ({
  size = 'large',
  ...props
}) => {
  let icon: React.ReactNode | null = null;
  let className = `btn-${size} ${props.className || ''}`;
  if (props.icon) {
    icon = <span className="btn-custom-icon-left">{props.icon}</span>;
  }
  if (props.disabled) {
    className = 'btn-disabled ' + className;
  }
  return (
    <>
      <GhostBtn {...props} size={undefined} icon={icon} className={className}>
        {props.children}
      </GhostBtn>
    </>
  );
};
