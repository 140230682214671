import { Form, message } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { pdfjs } from 'react-pdf';
import PagesDisplayCard from '../contract-edit/PagesDisplayCard';
import {
  B,
  ConfirmationModal,
  EmptyPage,
  FooterComponent,
  PrimaryButton,
  SecondaryButton,
} from '../../../components';

import { useDispatch, useSelector } from 'react-redux';
import { DispatchType, RootState } from '../../../store/reducer';
import { useNavigate, useParams } from 'react-router';
import {
  AssignedPlaceholderDataI,
  ContractStatusEnum,
  PlaceholderTypeEnum,
  SaveContractI,
  SaveSignatureI,
  SignatoryEnum,
  SignatoryI,
  UserType,
} from '../../../types/contract.types';
import {
  addSignaturesToPdf,
  getContractById,
  getDocumentPlaceholders,
  getMySign,
  saveContract,
  getAssignableUsers,
} from '../../../store/actions';
import AddMySignatureModal from './AddMySignatureModal';
import ContractViewer from '../shared/ContractViewer';
import {
  ContentEditorContainer,
  ContractContainer,
  ContractLayout,
  DraggableLayout,
} from '../shared/ContractLayout';
import { ContractTopBar } from '../shared/ContractTopBar';
import { ZoomProvider } from '../shared/ZoomContext';
import { downloadHandler } from '../../../util/downloadPdf.util';
import { getPathWithOrgId } from '../../../util/getPathWithOrgId';

type ParamsI = {
  contractId: string;
  token: string;
};
export interface PlaceholderBoxProps {
  left: number;
  top: number;
  width: number;
  height: number;
}

const EmptyContract: FC = () => (
  <EmptyPage
    content="Sorry, the contract cannot be found."
    title="something went wrong !"
    buttonLabel=" Back Home"
  />
);

const ViewContract: FC = () => {
  const params = useParams<ParamsI>();
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();

  const [savedStatus, setSavedStatus] = useState<boolean>(false);
  const [signature, setSignature] = useState<SaveSignatureI | null>(null);
  const [selectedImage, setSelectedImage] = useState<any | null>(null);
  const [selectedPlaceholder, setSelectedPlaceholder] =
    useState<AssignedPlaceholderDataI | null>(null);

  const me = useSelector((state: RootState) => state.people.me);
  const documentPlaceholders = useSelector(
    (state: RootState) => state.contract.documentPlaceholders,
  );
  const assignableUsers = useSelector(
    (state: RootState) => state.contract.assignableUsers,
  );
  const contract = useSelector((state: RootState) => state.contract.contract);

  const loadingContract = useSelector(
    (state: RootState) => state.contract.loadingContract,
  );
  const loadingSaveContract = useSelector(
    (state: RootState) => state.contract.loadingSaveContract,
  );
  const loadingSavePDFContract = useSelector(
    (state: RootState) => state.contract.loadingSavePDFContract,
  );
  const oldMySignature = useSelector(
    (state: RootState) => state.contract.mySignature,
  );

  // check page allowed
  useEffect(() => {
    if (contract && contract.id == params.contractId) {
      if (!contract.isSend) {
        navigate(getPathWithOrgId(`/contract/edit/${contract.id}`));
      }
    }
  }, [contract]);

  // map placeholders
  const placeHolderIds: Array<string> = documentPlaceholders
    ? documentPlaceholders?.placeHolders.map((placeHolder) => {
        return placeHolder.id;
      })
    : [];

  // contract status based on the current user
  const contractStatus = contract?.contractStatus.find(
    (item) => item.userId == me?.id,
  );

  const initContractPage = async () => {
    await Promise.all([
      dispatch(getContractById((params as any).contractId)),
      dispatch(getDocumentPlaceholders(Number(params.contractId))),
      dispatch(getAssignableUsers(String(params.contractId))),
      dispatch(getMySign()),
    ]);
  };

  useEffect(() => {
    initContractPage();
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; //or pdfjsWorker
  }, []);

  const saveContent = async (showMessage = true) => {
    try {
      const formData: SaveContractI = {};
      if (signature) {
        formData.signature = signature;
      }

      if (documentPlaceholders?.placeHolders) {
        const data = documentPlaceholders.placeHolders
          .filter((d) => d.type !== PlaceholderTypeEnum.signature)
          .map((d) => {
            return {
              placeHolderId: [d.id],
              value: d.value,
              type: d.type,
            };
          });
        formData.placeHolders = data.length ? data : [];
        if (formData.signature) {
          formData.placeHolders = [
            ...formData.placeHolders,
            ...formData.signature.placeHolders,
          ];
        }
      }

      if (
        Object.keys(formData).length === 0 &&
        formData.constructor === Object
      ) {
        throw 'Empty content.';
      }

      await dispatch(saveContract((params as any).contractId, formData));

      showMessage && message.success('Successfully Updated');
    } catch (error: any) {
      message.error('Something went wrong...');
      throw error;
    }
  };

  const downloadContract = () => {
    if (contract) {
      downloadHandler(contract.id, contract.name);
    }
  };

  // if contact not found in private state
  if (!contract && !loadingContract) {
    return <EmptyContract />;
  }
  let firstSignatory = null;
  let secondSignatory = null;

  if (
    contract?.firstSignatories?.length > 0 ||
    contract?.secondSignatories?.length > 0
  ) {
    firstSignatory = JSON.parse(
      JSON.stringify(contract?.firstSignatories),
    )?.[0];

    secondSignatory = JSON.parse(
      JSON.stringify(contract?.secondSignatories),
    )?.[0];
  }

  const firstSignatoryStatus = contract?.contractStatus?.find(
    (status) => status.userType == UserType.FIRST_SIGNATORY,
  );

  // We are using this because when first signatory signs he can't come
  //back and sign again
  const isFirstSignatorySigned =
    firstSignatoryStatus?.isSigned &&
    me?.id == parseInt(firstSignatoryStatus?.firstSignatoryId);

  return (
    <ZoomProvider>
      <Form>
        {/* @ts-ignore */}
        <DndProvider backend={HTML5Backend}>
          <ContractTopBar
            contract={contract}
            downloadPDF={() => downloadContract()}
          />
          <ContractLayout fullScreen={false}>
            <DraggableLayout>
              <ContractContainer>
                <ContractViewer
                  image={selectedImage}
                  isEditable={false}
                  contract={contract}
                  selectedPlaceholder={selectedPlaceholder}
                  placeholders={documentPlaceholders?.placeHolders || []}
                  documentLink={
                    documentPlaceholders
                      ? documentPlaceholders?.documentLink
                      : ''
                  }
                  latestSignature={
                    oldMySignature ? oldMySignature?.signatureLink : null
                  }
                  setSelectedPlaceholder={(data) => {
                    setSelectedPlaceholder(data);
                  }}
                />
              </ContractContainer>
              <ContentEditorContainer>
                <PagesDisplayCard
                  assignedUsers={assignableUsers}
                  placeholderDetails={
                    documentPlaceholders
                      ? documentPlaceholders?.placeHolders
                      : []
                  }
                  url={
                    documentPlaceholders
                      ? documentPlaceholders?.documentLink
                      : ''
                  }
                />
              </ContentEditorContainer>
            </DraggableLayout>
          </ContractLayout>
        </DndProvider>

        <FooterComponent
          leftChildren={
            <SecondaryButton
              onClick={() => {
                navigate(getPathWithOrgId('/contract'));
              }}>
              {contract?.status === ContractStatusEnum.PENDING ||
              contract?.status === ContractStatusEnum.COMPLETED ||
              contract?.status === ContractStatusEnum.SENT
                ? 'Back'
                : 'Cancel'}
            </SecondaryButton>
          }
          rightChildren={
            contract?.status === ContractStatusEnum.COMPLETED ||
            (me.id != firstSignatory?.id && me.id != secondSignatory?.id) ||
            isFirstSignatorySigned ? null : (
              <>
                <SecondaryButton
                  mr={16}
                  loading={loadingSaveContract}
                  disabled={loadingSaveContract || loadingSavePDFContract}
                  htmlType="button"
                  onClick={() => {
                    setSavedStatus(true);
                    saveContent();
                  }}>
                  Save
                </SecondaryButton>

                <ConfirmationModal
                  title={<B type="b-large-semibold">Confirmation Alert</B>}
                  content={
                    <B type="b-large">Are you sure you want to continue?</B>
                  }
                  okText="Save and Continue"
                  cancelText="No"
                  onConfirm={async () => {
                    try {
                      !savedStatus && (await saveContent(false));
                      // TODO: not nice that navigate passed to redux function
                      await dispatch(
                        addSignaturesToPdf(params.contractId, navigate),
                      );
                    } catch (error: any) {
                      message.error('Something went wrong...');
                    }
                  }}
                  trigger={
                    <PrimaryButton
                      loading={loadingSavePDFContract}
                      disabled={loadingSavePDFContract}>
                      Confirm
                    </PrimaryButton>
                  }
                />
              </>
            )
          }
          fullScreen={false}
        />

        {selectedPlaceholder?.type == PlaceholderTypeEnum.signature ? (
          <AddMySignatureModal
            nonExistUser={false}
            placeHolderIds={placeHolderIds}
            oldMySignature={
              oldMySignature ? oldMySignature?.signatureLink : null
            }
            visible={true}
            handleCancel={() => {
              setSelectedPlaceholder(null);
            }}
            onSubmit={(fileData, value, image) => {
              const values = {
                documentId: params.contractId,
                file: fileData,
                placeHolders: value,
              };
              setSignature(values);
              setSelectedImage(image);
              setSelectedPlaceholder(null);
            }}
          />
        ) : null}
      </Form>
    </ZoomProvider>
  );
};
export default ViewContract;
