import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { B } from '../../../components/B';
import { getPayrollSummary } from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import { numberThousandSeparator } from '../../../util/utils';
import theme from '../../../theme';
import { SubscriptionEnum } from '../../../types/subscription.types';
import styled from '@emotion/styled';
import { Div } from '../../../components/Div';

const StyledHr = styled.hr`
  border-bottom: 1px solid ${theme.gray300};
  margin: 0;
`;

interface Props {
  isCollapsed: boolean;
}

const OuterComponent = styled.div<{ collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.gray300};
  background-color: ${theme.lightBlue50};
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: ${({ collapsed }) => (collapsed ? '136px' : '86px')};
  margin-bottom: 16px;
  padding: 16px;
  @media (max-width: 768px) {
    padding: 16px 8px;
  }
`;


const PayrollDetails: FC<Props> = ({ isCollapsed }) => {
  const dispatch: DispatchType = useDispatch();
  const me = useSelector((state: RootState) => state.people.me);
  const payrollSummery = useSelector(
    (state: RootState) => state.payroll.payrollSummery,
  );

  const [lastPayrollAmount, setLastPayrollAmount] = useState('0.00');

  useEffect(() => {
    if (me?.subscriptionStatus !== SubscriptionEnum.SUSPENDED) {
      dispatch(getPayrollSummary());
    }
  }, []);

  useEffect(() => {
    if (payrollSummery?.lastPayrollAmount) {
      setLastPayrollAmount(
        numberThousandSeparator(payrollSummery.lastPayrollAmount),
      );
    }
  }, [payrollSummery]);

  return (
    <OuterComponent collapsed={isCollapsed}>
      <Div
        display="flex"
        flexDirection={`${isCollapsed ? 'column' : 'row'}`}
        style={{ marginBottom: '8px' }}>
        <B type="b-small" color={theme.gray600}>
          Next Payroll Date :
        </B>
        <Div marginLeft={`${isCollapsed ? '' : 'auto'}`}>
          <B type="b-small" color={theme.black}>
            {payrollSummery?.nextPayroll?.replace(/\//g, '.')}
          </B>
        </Div>
      </Div>

      <StyledHr />
      <Div
        display="flex"
        flexDirection={`${isCollapsed ? 'column' : 'row'}`}
        style={{ marginTop: '8px' }}>
        <B type="b-small" color={theme.gray600}>
          Last Month Payroll :
        </B>
        <Div marginLeft={`${isCollapsed ? '' : 'auto'}`}>
          <B type="b-small" color={theme.black}>
            LKR {lastPayrollAmount}
          </B>
        </Div>
      </Div>
    </OuterComponent>
  );
};
export default PayrollDetails;
