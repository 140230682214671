import React, { FC } from 'react';

import { Card, Typography, Upload } from 'antd';
import { DraggerProps } from 'antd/lib/upload';
import { useNavigate, useLocation } from 'react-router';

import { css } from '@emotion/react';

import { B } from '../../../components';
import { BASE_URL, SAMPLE_CSV_FOR_PAYROLL } from '../../../configs';
import WhyCSVNotUploadingModal from './WhyCSVNotUploadingModal';
import { ImageUploadSVG } from '../../../assets';

const { Dragger } = Upload;
const { Title } = Typography;

const PayRunCSVUploader: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const props: DraggerProps = {
    name: 'file',
    accept: '.csv,text/plain',
    multiple: false,
    action: `${BASE_URL}/public/uploadCsv`,
    beforeUpload: (file: any) => {
      if (file.type === 'text/csv') {
        return true;
      } else {
        return false;
      }
    },
    onChange(info) {
      if (info.file.response) {
        if (info.file.response.csv) {
          navigate(
            location.pathname === '/open-payroll'
              ? `/open-column-mapping?fileUrl=${info.file.response.csv}`
              : `/map-columns?fileUrl=${info.file.response.csv}`,
          );
        }
      }
    },
  };
  return (
    <>
      <Card>
        <Title level={5}>New Pay Run From File</Title>
        <p>
          Upload a CSV file that includes employee basic details.{' '}
          <a
            href={SAMPLE_CSV_FOR_PAYROLL}
            download
            style={{ color: '#0000ff' }}>
            Download
          </a>{' '}
          a sample csv from here.
        </p>
        <Dragger
          {...props}
          css={css`
            background: #fbfbfb !important;
            height: 321px !important;
            border: 1px dashed #828282 !important;
            box-sizing: border-box;
            border-radius: 8px;
          `}>
          <p className="ant-upload-drag-icon">
            <img src={ImageUploadSVG} />
          </p>
          <B type="b-large-semibol">Drag and drop or Browse</B>
        </Dragger>
        <B type="b-default" pt="20px" textAlign="center">
          Having a trouble uploading the CSV ? <WhyCSVNotUploadingModal />
        </B>
      </Card>
    </>
  );
};

export default PayRunCSVUploader;
