import React, { FC } from 'react';

import { B } from '../../../../components';
import { ErrorI } from '../util/openPayrollValidator';

interface Props {
  errors: ErrorI[];
}

const DataMappingErrors: FC<Props> = ({ errors }) => {
  return (
    <>
      <B color="#D50000" type="b-large-semibold">
        Oops we found issues in the datasets
        <br />
        <br />
        {errors.map((error, i) => (
          <B color="#202125" type="b-default">
            {i + 1}. {error.positionText} {'->'} {error.message}
          </B>
        ))}
      </B>
      <br />
    </>
  );
};

export default DataMappingErrors;
