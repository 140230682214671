import { Carousel, Row, Spin } from 'antd';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  Div,
  FullScreen,
  LinkButton,
  PrimaryButton,
  SecondaryButton,
} from '../../../../../components';
import { FooterComponent } from '../../../../../components/FooterComponent';
import {
  PayrollViewDataI,
  PayrollViewUserPayLoad,
} from '../../../../../store/actions';
import { RootState } from '../../../../../store/reducer';
import {
  formatUserDataImportJSON,
  getLeaveColumns,
} from '../../../../settings/data-imports/dataImport.util';
import { calculatePayrollSummary } from '../../../utils/calculatePayrollSummary';
import PayrollTitle from '../../../shared/PayrollTitle';
import { SalarySummaryCard } from '../../../shared/SalarySummaryCard';
import PayrollTable from '../../../shared/PayrollTable';
import PayrollConfirmationModal from './PayrollConfirmationModal';

import theme from '../../../../../theme';
import { BannerAlert } from '../../../../../components/alertMessage/BannerAlert';
import { PayrollExport } from '../payroll-draft/PayrollExport';
import { USDSalarySummaryCard } from '../../../shared/USDSalarySummaryCard';
import styled from '@emotion/styled';
import { PayrunType } from '../../../../../types/payroll.types';
import { ChevronLeftBlack, EditSVG } from '../../../../../assets';
import { getPathWithOrgId } from '../../../../../util/getPathWithOrgId';

const CarouselContainer = styled.div`
  background: ${theme.lightBlue50};
  .ant-carousel .slick-next {
    right: 20px;
  }
  .ant-carousel .slick-prev {
    left: 20px;
    z-index: 1;
  }
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next {
    margin-top: -5px;
    height: 12px;
  }
  .ant-carousel .slick-dots-bottom {
    display: none !important;
  }
`;

const TabSection = styled(Row)`
  margin-bottom: 80px;
`;

type payrollParam = {
  payrollKey: string;
};
interface Props {
  selectedPayrollViewData: PayrollViewUserPayLoad[];
  payrollViewDataLoading: boolean;
  payrollViewData: PayrollViewDataI | null;
  editDraft: (value: PayrollViewUserPayLoad[]) => void;
  selectedRowKeys?: number;
}

const PayrollConfirmationPage: FC<Props> = ({
  selectedPayrollViewData,
  payrollViewDataLoading,
  payrollViewData,
  editDraft,
  selectedRowKeys,
}) => {
  const navigate = useNavigate();
  const params = useParams<payrollParam>();
  const [visible, setVisible] = useState(false);

  const [fullScreen, setFullScreen] = useState(false);

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const dataForTable = formatUserDataImportJSON(
    selectedPayrollViewData,
    payrollViewData?.payrunType,
  );

  const totalCalculation = calculatePayrollSummary(selectedPayrollViewData);
  const leaveColumns: string[] | undefined =
    organizationData?.isUsingSystemLeaves
      ? undefined
      : getLeaveColumns(selectedPayrollViewData);

  return (
    <>
      <>
        <Spin spinning={payrollViewDataLoading}>
          <BannerAlert
            message="Draft payroll created!"
            closable
            showIcon={true}
            AlertTypes="success"
          />
          <Div p=" 8px 32px" borderBottom={`1px solid ${theme.gray300}`}>
            <Div display="flex" justifyContent="flex-end">
              <PayrollTitle payrollDetails={payrollViewData} />
              <PayrollExport
                dataForTable={dataForTable}
                leaveColumnNames={leaveColumns}
                suffix=""
                payrollViewData={payrollViewData}
              />
              <LinkButton
                size="small"
                onClick={() => editDraft([])}
                icon={<img width="16px" height="16px" src={EditSVG} />}>
                Edit Draft
              </LinkButton>
              <FullScreen
                isDisplayTitle={false}
                fullScreen={fullScreen}
                openFullScreen={() => {
                  setFullScreen(true);
                }}
                exitFullscreen={() => {
                  setFullScreen(false);
                }}
              />
            </Div>
          </Div>

          <CarouselContainer>
            {payrollViewData?.currentExchangeRate ? (
              <Carousel
                arrows
                prevArrow={<img src={ChevronLeftBlack} />}
                nextArrow={<img src={ChevronLeftBlack} />}>
                <SalarySummaryCard
                  summary={totalCalculation}
                  selected={
                    selectedRowKeys
                      ? selectedRowKeys
                      : selectedPayrollViewData.length
                  }
                  currentExchangeRate={payrollViewData?.currentExchangeRate}
                />
                <USDSalarySummaryCard
                  summary={totalCalculation}
                  selected={
                    selectedRowKeys
                      ? selectedRowKeys
                      : selectedPayrollViewData.length
                  }
                  currentExchangeRate={payrollViewData.currentExchangeRate}
                  peggedCurrency={payrollViewData.peggedCurrency}
                />
              </Carousel>
            ) : (
              <SalarySummaryCard
                summary={totalCalculation}
                selected={
                  selectedRowKeys
                    ? selectedRowKeys
                    : selectedPayrollViewData.length
                }
                currentExchangeRate={payrollViewData?.currentExchangeRate}
              />
            )}
          </CarouselContainer>
          <TabSection>
            <PayrollTable
              selectedRowKeys={
                dataForTable?.dataList
                  .filter((item) => !item.hasEmployeeErrors)
                  .map((item) => item.rowKey) || []
              }
              setSelectedRowKeys={() => console.log('log')}
              payItemColumns={dataForTable?.payItemColumns || []}
              dataPayload={dataForTable?.dataList || []}
              isDraftPayroll={false}
              payrollKey={params.payrollKey}
              fullScreen={fullScreen}
              extraTableHeight={0}
              setFullScreen={setFullScreen}
              isSelectedEmployeeOnly={true}
              disableView={false}
              isMidCyclePayrun={
                payrollViewData?.payrunType === PayrunType.MID_CYCLE_PAYRUN
                  ? true
                  : false
              }
            />
          </TabSection>
          <FooterComponent
            leftChildren={
              <SecondaryButton
                onClick={() => navigate(getPathWithOrgId('/payroll'))}>
                Cancel
              </SecondaryButton>
            }
            rightChildren={
              <>
                <PrimaryButton
                  onClick={() => {
                    setVisible(true);
                  }}>
                  Generate Payroll
                </PrimaryButton>
              </>
            }
          />
        </Spin>
      </>
      {visible ? (
        <PayrollConfirmationModal
          payrollViewData={payrollViewData}
          confirmEmployees={
            payrollViewData ? selectedPayrollViewData.length : null
          }
          visible={visible}
          handleCancel={() => {
            setVisible(false);
          }}
          onSubmit={(value, selectedBank) => {
            setVisible(false);
            navigate(
              `/payroll-loading/${params.payrollKey}?bankType=${selectedBank}`,
              {
                state: value,
              },
            );
          }}
        />
      ) : null}
    </>
  );
};

export default PayrollConfirmationPage;
