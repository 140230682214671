import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  AssignedPlaceholderDataI,
  GetContractI,
  PlaceholderTypeEnum,
  ContractStatusEnum,
  PlaceholderLocationI,
} from '../../../types/contract.types';
import ContentPage from '../contract-edit/ContentPage';
import { DropZone } from '../contract-edit/DropZone';
import PlaceholderBox from '../contract-edit/placeholder-items/PlaceholderBox';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer';
import theme from '../../../theme';
import { useGetAssignedUsers } from '../utils/assignedUsers';
import { useZoom } from './ZoomContext';
import { Div } from '../../../components';
import styled from '@emotion/styled';

export interface PlaceholderBoxProps {
  left: number;
  top: number;
  width: number;
  height: number;
}
const DocumentView = styled(Document)`
  overflow-x: auto;
  .react-pdf__Page__textContent {
    box-sizing: border-box;
    border-radius: 5px;
    display: none;
  }
  .react-pdf__Page {
    margin-bottom: 10px;
    border: 1.5px solid #e0e0e0;
  }
  .react-pdf__Page__annotations.annotationLayer {
    display: none;
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
`;
const View = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 28px;
  padding-bottom: 100px;
  .ant-spin-nested-loading {
    color: ${theme.gray100};
  }
`;
interface Props {
  contract: GetContractI | null;
  placeholders: AssignedPlaceholderDataI[];
  selectedPlaceholder: AssignedPlaceholderDataI | null;
  onAddNewPlaceholder?(
    pageIndex: number,
    x: number,
    y: number,
    type: PlaceholderTypeEnum,
  ): void;
  setSelectedPlaceholder(data: AssignedPlaceholderDataI | null): void;
  isEditable: boolean;
  receiver?: number[] | [];
  image?: string;
  latestSignature?: string | null;
  documentLink: string | null;
  setVisiblePage?: (data: number) => void;
  setIsChanged?: (data: boolean) => void;
}

const ContractViewer: FC<Props> = ({
  contract,
  onAddNewPlaceholder: addNewPlaceholder,
  placeholders,
  selectedPlaceholder,
  setSelectedPlaceholder,
  isEditable,
  image,
  latestSignature,
  documentLink,
  setVisiblePage,
  setIsChanged,
}) => {
  const { ratio } = useZoom();
  const [{ pages, pageWidth, pageHeight }, setPageConfig] = useState<{
    pages: number[];
    pageWidth: number;
    pageHeight: number;
  }>({
    pages: [],
    pageWidth: 0,
    pageHeight: 0,
  });

  const assignableUsers = useSelector(
    (state: RootState) => state.contract.assignableUsers,
  );

  const { firstSignatory } = useGetAssignedUsers(assignableUsers);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; //or pdfjsWorker
  }, []);

  useEffect(() => {
    if (pages.length > 0 && setVisiblePage) {
      const pageIds = pages.map((page) => `page_${page + 1}`);

      const handleIntersection = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const visiblePageId = entry.target.id;
            setVisiblePage(pageIds.findIndex((page) => page === visiblePageId));
          }
        });
      };

      pageIds.forEach((pageId) => {
        const targetElement = document.querySelector(`#${pageId}`);
        if (targetElement) {
          const observer = new IntersectionObserver(handleIntersection);
          observer.observe(targetElement);
        }
      });
    }
  }, [pages]);

  const onDocumentLoadSuccess = (results: any) => {
    if (results.numPages > 0) {
      results.getPage(1).then((page: any) => {
        setPageConfig({
          pages: Array.from(Array(results.numPages).keys()),
          pageWidth: page.view[2],
          pageHeight: page.view[3],
        });
      });
    }
  };

  const setDefaultState = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    if (id) {
      const elem = document.getElementById(id);
      if (e.type == 'click' && elem) {
        setSelectedPlaceholder(null);
      }
    }
  };
  return (
    <>
      <View>
        <DocumentView
          file={documentLink}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}>
          {pages.map((_page, pageIndex) => {
            const className = `content-page-${pageIndex}`;
            return (
              <React.Fragment key={pageIndex}>
                <Div id={`page_head_${pageIndex + 1}`} height="10px" />
                <ContentPage
                  width={pageWidth * ratio}
                  height={pageHeight * ratio}
                  key={pageIndex}
                  className={className}>
                  <DropZone
                    onDropped={(x, y, type) => {
                      if (addNewPlaceholder) {
                        // Only Needed for Edit Contract Page. Not for the View Contract
                        addNewPlaceholder(
                          pageIndex,
                          x / ratio,
                          y / ratio,
                          type,
                        );
                      }
                    }}>
                    <div
                      onClick={(e) => {
                        setDefaultState(e, `page_${pageIndex + 1}`);
                      }}
                      id={`page_${pageIndex + 1}`}>
                      <Page
                        id={`page_${pageIndex + 1}`}
                        className={`page_${pageIndex + 1}`}
                        key={`page_${pageIndex + 1}`}
                        pageNumber={pageIndex + 1}
                        width={pageWidth * ratio}
                      />
                    </div>

                    {contract?.status !== ContractStatusEnum.ARCHIVED &&
                      placeholders.map((current) => {
                        const isSigned = contract?.contractStatus.find(
                          (c) =>
                            c.userId === current.assignedUserId ||
                            c.externalUserId === current.assignedUserId,
                        );
                        if (isSigned && isSigned.isSigned) {
                          return null;
                        }
                        if (current.pageNumber !== pageIndex) {
                          return null;
                        }
                        return (
                          <PlaceholderBox
                            contractStatus={contract?.status}
                            image={image}
                            latestSignature={latestSignature}
                            isSaved={current.isSaved}
                            isReceiver={
                              String(current.assignedUserId) ===
                              String(firstSignatory?.id)
                            }
                            type={current.type}
                            value={current.value}
                            onChange={(text) => {
                              if (setIsChanged) {
                                setIsChanged(true);
                              }
                              current.value = text;
                              setSelectedPlaceholder(current);
                            }}
                            isEditable={isEditable}
                            isSelected={selectedPlaceholder?.id === current.id}
                            onAction={() => {
                              setSelectedPlaceholder(current);
                            }}
                            onResizeStop={({ width, height }) => {
                              current.hight = height;
                              current.width = width;
                              setSelectedPlaceholder({ ...current });
                            }}
                            key={current.id}
                            left={current.xAxis}
                            top={current.yAxis}
                            height={current.hight || 48}
                            width={current.width || 98}
                            bounds={`.${className}`}
                            onDragStop={(data) => {
                              current.xAxis = data.x / ratio;
                              current.yAxis = data.y / ratio;
                              setSelectedPlaceholder({ ...current });
                            }}
                          />
                        );
                      })}
                  </DropZone>
                </ContentPage>
              </React.Fragment>
            );
          })}
        </DocumentView>
      </View>
    </>
  );
};
export default ContractViewer;
