import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  AlertMessage,
  DefaultBody,
  FooterComponent,
  SecondaryButton,
} from '../../../../components';
import {
  clearDataImportRedirect,
  DataExtractorCSVEnum,
} from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import { useGetDefaultDataExtractor } from '../../../../util/dataImportUtil';
import { getPathWithOrgId } from '../../../../util/getPathWithOrgId';
import { useWindowSize } from '../../../../util/screenSize';
import DataImportPage from '../../../settings/data-imports/DataImportPage';
import GuidelineUsingCSV from './GuidelineUsingCSV';
import ImportCSV from './ImportCSV';

const PayrunUsingCSV: FC = () => {
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();
  const { isMobile } = useWindowSize();
  const extractorInfo = useGetDefaultDataExtractor();

  const dataImportPayload = useSelector(
    (state: RootState) => state.dataImport.dataImportPayload,
  );

  const onComplete = () => {
    dispatch(clearDataImportRedirect());
  };

  useEffect(() => {
    onComplete();
  }, []);

  if (!extractorInfo) {
    return (
      <AlertMessage
        title="This organization does not support data import feature."
        type="error"
      />
    );
  }

  if (dataImportPayload.length && DataExtractorCSVEnum.SALARY_CSV) {
    return (
      <DataImportPage
        extractorType={extractorInfo?.extractorType}
        onComplete={() => onComplete()}
        csvType={DataExtractorCSVEnum.SALARY_CSV}
      />
    );
  }

  return (
    <DefaultBody>
      <ImportCSV extractorInfo={extractorInfo} />
      <GuidelineUsingCSV />
      <FooterComponent
        leftChildren={
          <>
            <SecondaryButton
              block={isMobile ? true : false}
              onClick={() => navigate(getPathWithOrgId('/payroll'))}>
              Back
            </SecondaryButton>
          </>
        }
      />
    </DefaultBody>
  );
};

export default PayrunUsingCSV;
