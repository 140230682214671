import React, { FC } from 'react';
import { Avatar } from 'antd';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';

import styled from '@emotion/styled';

import theme from '../theme';

export const AvatarComp = styled(Avatar)<
  SpaceProps & TypographyProps & BorderProps & ColorProps
>`
  color: ${theme.black};
  background-color: ${theme.white};
  border: 1px solid ${theme.gray300};
  ${space}
  ${typography}
  ${border}
  ${color}
`;

type Props = SpaceProps &
  TypographyProps &
  BorderProps &
  ColorProps & {
    size?: number;
    shape?: 'circle' | 'square';
    title?: string;
    src?: React.ReactNode;
  };
export const AvatarComponent: FC<Props> = ({
  size,
  shape,
  title,
  src,
  ...otherProps
}) => {
  return (
    <>
      <AvatarComp src={src} size={size} shape={shape} {...otherProps}>
        {title?.charAt(0).toUpperCase()}
      </AvatarComp>
    </>
  );
};
