import React, { FC } from 'react';
import { Col, Row } from 'antd';
import Icon from '@ant-design/icons';
import { css } from '@emotion/react';
import { useWindowSize } from '../../../util/screenSize';

interface Icon {
  leftIcon: string;
  rightIcon: string;
}

export const HeaderImage: FC<Icon> = ({ leftIcon, rightIcon }) => {
  const { isMobile } = useWindowSize();
  return (
    <Row
      style={{
        width: '625px',
        height: '244px',
        background: 'rgb(51,117,238,0.1)',
      }}>
      <Col
        xs={7}
        sm={6}
        md={14}
        lg={12}
        xl={13}
        css={css`
          margin-top: 21px;
          margin-left: ${isMobile ? '' : '27px'};
        `}>
        <Icon
          component={() => (
            <img src={require(`../../../assets/images/${leftIcon}`)} />
          )}
        />
      </Col>
      <Col
        xs={6}
        sm={6}
        md={6}
        lg={8}
        xl={6}
        css={css`
          margin-top: 118px;
        `}>
        <Icon
          component={() => (
            <img src={require(`../../../assets/images/${rightIcon}`)} />
          )}
        />
      </Col>
    </Row>
  );
};
