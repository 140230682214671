export const sortEmpIds = (a: string | number, b: string | number): number => {
  const valueA = isNaN(Number(a)) ? a : parseInt(a as string, 10);
  const valueB = isNaN(Number(b)) ? b : parseInt(b as string, 10);

  if (typeof valueA === 'number' && typeof valueB === 'number') {
    return valueA - valueB;
  }

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    return valueA.localeCompare(valueB);
  }

  return typeof valueA === 'number' ? -1 : 1;
};
