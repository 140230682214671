//import 'antd/dist/antd.css'; // TODO: do we really need this

import React, { FC } from 'react';

import { Image, Select } from 'antd';
import { layout, LayoutProps } from 'styled-system';

import styled from '@emotion/styled';

import { B, Div } from '../../../../components';
import theme from '../../../../theme';
import { EmployeePaySlipSummary } from './utils/payslipPageTypes';
import { getEmployeeSelectorOption } from './utils/getEmployeeSelectorOption';
import { DropdownBlack } from '../../../../assets';

const MainContainer = styled.div`
  padding: 4px 5px;
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px;
  height: 32px;
`;

const StyledSelector = styled(Select)`
  min-width: 280px;
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
  .ant-select-arrow {
    margin-top: -15px;
  }
  .ant-select-selector {
    background-color: transparent !important;
  }
`;

const ThreeDotDiv = styled.div<LayoutProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${layout}
`;

const { Option } = Select;

interface Props {
  employees: EmployeePaySlipSummary[];
  onSelect: (index: number) => void;
  sheetIndex: number;
}

const EmployeeSelector: FC<Props> = ({ employees, onSelect, sheetIndex }) => {
  const options = getEmployeeSelectorOption(employees);
  if (!options.length) {
    return null;
  }

  const renderEmployees = () => {
    return (
      <StyledSelector
        value={sheetIndex}
        onSelect={(v: number) => {
          onSelect(v);
        }}
        suffixIcon={<Image preview={false} src={DropdownBlack} />}>
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            <Div display="flex">
              <B type="b-default">
                <ThreeDotDiv maxWidth={160}>{option?.label}</ThreeDotDiv>
              </B>
              &nbsp;{' '}
              {option?.employeeNumber && (
                <B type="b-default">({option?.employeeNumber})</B>
              )}
            </Div>
          </Option>
        ))}
      </StyledSelector>
    );
  };

  return <MainContainer>{renderEmployees()}</MainContainer>;
};

export default EmployeeSelector;
