import React, { FC, useState } from 'react';
import { Col, Row, Tag } from 'antd';
import moment from 'moment';
import styled from '@emotion/styled';
import calenderSvg from '../../../../assets/Icons/note-icons/calender-gray.svg';
import clockSvg from '../../../../assets/Icons/note-icons/clock-gray.svg';
import leaveSvg from '../../../../assets/Icons/note-icons/leave.svg';
import { B, Div } from '../../../../components';
import { NameTag } from '../../../../components/Tags/NameTag';
import theme from '../../../../theme';
import { ReviewI } from '../../../../types/leave.types';
import {
  Reminders,
  ReminderTypeEnum,
} from '../../../../types/notification.types';
import { capitalizeUnderscore } from '../../../../util/utils';
import LeaveRejectModal from '../../../leave/new-leave/LeaveRejectModal';
import { LeaveStatus } from '../../homeUtil';

const Icon = styled.img`
  margin-bottom: -2px;
  margin-right: 3px;
`;

interface Props {
  item: Reminders;
  isCollapsed: boolean;
  onClickEdit?: () => void;
  onDataChange: (v: ReviewI) => void;
  highlightId?: number;
}
const NotificationLeave: FC<Props> = ({
  item,
  isCollapsed,
  onDataChange,
  highlightId,
}) => {
  const [collapse, setCollapse] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [rejectLeaveID, setRejectLeaveID] = useState<string | null>(null);

  return (
    <>
      {item.type === ReminderTypeEnum.LEAVE && (
        <Div
          background={
            highlightId ? theme.gray100 : collapse ? theme.gray100 : theme.white
          }
          width="100%"
          borderRadius="4px">
          <Row
            onClick={() => {
              if (collapse) {
                setCollapse(false);
              } else {
                setCollapse(true);
              }
            }}>
            <Div display="flex" p="12px 10px" width="100%">
              <Col span={16}>
                <Div display="flex">
                  <img src={leaveSvg} width={22} height={22} />
                  {isCollapsed ? (
                    <B type="b-small" pl="10px">
                      {capitalizeUnderscore(
                        item.payload.leaveName
                          ? item.payload.leaveName
                          : item.payload.leaveType,
                      )}
                    </B>
                  ) : (
                    <B type="b-small" pl="10px">
                      {item.payload.user ? item.payload.user.fullName : '-'}{' '}
                      requested a{' '}
                      {capitalizeUnderscore(
                        item.payload.leaveName
                          ? item.payload.leaveName
                          : item.payload.leaveType,
                      )}
                    </B>
                  )}
                </Div>
              </Col>
              {!isCollapsed && (
                <Col span={8}>
                  <Div display="flex" justifyContent="flex-end">
                    {item.payload.status == LeaveStatus.ACCEPTED ? (
                      <NameTag textColor={theme.green300} color={theme.green50}>
                        Approved
                      </NameTag>
                    ) : item.payload.status == LeaveStatus.PENDING ? (
                      <NameTag textColor={theme.orange300} color={theme.warn}>
                        Pending
                      </NameTag>
                    ) : (
                      <NameTag textColor={theme.red300} color={theme.red50}>
                        Reject
                      </NameTag>
                    )}
                  </Div>
                </Col>
              )}
            </Div>
          </Row>
          {collapse && (
            <Div mb="16px" mt="-6px">
              <Div display="flex" ml="42px">
                <B
                  type="b-small"
                  pr={isCollapsed ? '12px' : '24px'}
                  color={theme.gray600}>
                  <Icon src={calenderSvg} />
                  {moment(item.payload.date).format('YYYY-MM-DD')}
                </B>
                <B type="b-small" color={theme.gray600}>
                  <Icon src={clockSvg} />
                  {item.payload.hours} hrs
                </B>
              </Div>
              {item.payload.status == LeaveStatus.PENDING && (
                <Div display="flex" m="8px 42px 0px">
                  <B
                    type="b-small"
                    pr="24px"
                    color={theme.blue500}
                    onClick={async () => {
                      const values = {
                        status: LeaveStatus.ACCEPTED,
                        leaveId: String(item.payload.id),
                      };
                      onDataChange(values);
                      setCollapse(false);
                    }}>
                    Approve
                  </B>
                  <B
                    type="b-small"
                    color={theme.blue500}
                    onClick={() => {
                      setRejectLeaveID(String(item.payload.id));
                      setVisible(true);
                    }}>
                    Reject
                  </B>
                </Div>
              )}
            </Div>
          )}
        </Div>
      )}
      {visible && (
        <LeaveRejectModal
          visible={visible}
          handleCancel={() => {
            setVisible(false);
            setRejectLeaveID(null);
          }}
          onSubmit={(values) => {
            if (rejectLeaveID && values.reasonForRejection) {
              const value = {
                status: LeaveStatus.REJECTED,
                leaveId: rejectLeaveID,
                reasonForRejection: values.reasonForRejection,
              };
              onDataChange(value);
            }
            setRejectLeaveID(null);
            setVisible(false);
          }}
        />
      )}
    </>
  );
};

export default NotificationLeave;
