import React, { FC, useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import RoundSvg from '../../../../assets/Icons/white-round-correct.svg';
import {
  AlertMessage,
  B,
  Div,
  H,
  LinkButton,
  PrimaryButton,
} from '../../../../components';
import {
  BulkPaySlipDownloadEnum,
  FilePayslipI,
  getPayrollData,
  SingleUserPayroll,
  uploadPayslips,
} from '../../../../store/actions';
import theme from '../../../../theme';
import { ScheduleDateModal } from './ScheduleDateModal';
import { LinkButtonBbhr } from '../../../../components/buttons/LinkButtonBbhr';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { bambooHrOrganisations } from '../../../../configs/appConfig';
import { getCurrentAuthPayload } from '../../../../util/storageUtil';
import { PayrunType } from '../../../../types/payroll.types';
import { DispatchType } from '../../../../store';
import {
  Bamboo,
  DownloadWhiteSVG,
  EmailSVG,
  PublishPaper,
} from '../../../../assets';

const GreeenSection = styled.div`
  background: ${theme.green50};
  text-align: left;
  padding: 32px;
  border-radius: 8px;
`;

const FilesDiv = styled(Col)`
  display: flex;
`;
const Files = styled(Row)`
  margin-top: 16px;
`;
const DownloadDiv = styled.div`
  display: flex;
  margin-top: 24px;
`;

const NotificationDiv = styled.div`
  background: ${theme.gray100};
  border-radius: 4px;
  height: 32px;
  padding: 6px 12px;
`;

const PayslipSendSection = styled(Row)`
  margin: 16px 0px 0px;
  justify-content: space-between;
`;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
interface Props {
  isStillPossibleToUpload: boolean;
  isStillPossibleToSchedule: boolean;
  isStillGeneratingPaySlip: boolean;
  fileTransfer: FilePayslipI | null;
  singlePayrollData: SingleUserPayroll | null;
}

type payrollParam = {
  payrollKey: string;
};
export const FileExportedSuccessfully: FC<Props> = ({
  isStillPossibleToUpload,
  isStillPossibleToSchedule,
  isStillGeneratingPaySlip,
  fileTransfer,
  singlePayrollData,
}) => {
  const PAYSLIP_DATE_FORMAT = 'DD MMMM YYYY';
  const dispatch: DispatchType = useDispatch();
  const [visible, setVisible] = useState(false);
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [isScheduleChecked, setIsScheduleChecked] = useState(false);
  const [displayPayslipScheduleDate, setDisplayPayslipScheduleDate] =
    useState('');
  const [displayPayslipSentDate, setDisplayPayslipSentDate] = useState(
    moment().format(PAYSLIP_DATE_FORMAT),
  );
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [bamboohrEnabled, setBambooHrEnabled] = useState(false);
  const params = useParams<payrollParam>();

  useEffect(() => {
    const orgId = Number(getCurrentAuthPayload()?.organizationId);
    if (
      !isNaN(orgId) &&
      bambooHrOrganisations &&
      bambooHrOrganisations.includes(orgId)
    ) {
      setBambooHrEnabled(true);
    }
    setDisplayPayslipScheduleDate(
      moment(singlePayrollData?.scheduleDate).format(PAYSLIP_DATE_FORMAT),
    );

    setDisplayPayslipSentDate(
      moment(singlePayrollData?.payslipsSentDate).format(PAYSLIP_DATE_FORMAT),
    );

    if (singlePayrollData?.scheduleDate) {
      setIsScheduleChecked(true);
    }
    if (singlePayrollData?.payslipsSentDate) {
      setIsEmailChecked(true);
    }
  }, [singlePayrollData]);

  const onUpload = async () => {
    setUploaded(true);
    setUploading(true);
    dispatch(uploadPayslips(params.payrollKey));
    dispatch(getPayrollData(params.payrollKey));

    setTimeout(() => {
      setUploading(false);
    }, 3000);
  };
  return (
    <Div mb="27px">
      <GreeenSection>
        <Row>
          <Col xs={0} sm={0} md={0} lg={6} xl={4} xxl={4}>
            <img src={PublishPaper} />
          </Col>
          <Col md={19} lg={16} xl={16} xxl={12}>
            <H type="h6" color={theme.black} pt="8px" pb="4px">
              The files have been generated successfully!
            </H>
            <B type="b-small" color={theme.gray600}>
              This zip file contains the bulk bank file, EPF, ETF files as well
            </B>
            <Files>
              <FilesDiv xs={24} sm={24} md={9} lg={5} xl={5} xxl={5}>
                <img src={RoundSvg} />
                <B type="b-default" pl="8px" pt="2px">
                  Bank Bulk File
                </B>
              </FilesDiv>
              {singlePayrollData?.payrunType !==
                PayrunType.MID_CYCLE_PAYRUN && (
                <>
                  <FilesDiv xs={24} sm={24} md={9} lg={5} xl={5} xxl={5}>
                    <img src={RoundSvg} />
                    <B type="b-default" pl="8px" pt="2px">
                      EPF/ETF File
                    </B>
                  </FilesDiv>
                  <FilesDiv xs={24} sm={24} md={6} lg={5} xl={5} xxl={5}>
                    <img src={RoundSvg} />
                    <B type="b-default" pl="8px" pt="2px">
                      Payslips
                    </B>
                  </FilesDiv>
                </>
              )}
            </Files>
            <DownloadDiv>
              <a
                style={{ color: theme.white }}
                href={fileTransfer?.bulkPayslipUrl || ''}
                target="_blank"
                rel="noreferrer"
                download>
                <PrimaryButton
                  size="small"
                  disabled={isStillGeneratingPaySlip}
                  loading={isStillGeneratingPaySlip}
                  icon={<DownloadWhiteSVG />}>
                  Download Files
                </PrimaryButton>
              </a>
              {fileTransfer?.bulkPayslipStatus ===
              BulkPaySlipDownloadEnum.GENERATED ? (
                <B type="b-default" pl="10px" pt="5px" color={theme.green300}>
                  100% Complete! You can now download your files.
                </B>
              ) : (
                <B type="b-default" pl="10px" pt="5px" color={theme.green300}>
                  {fileTransfer?.completion || 0}% Completed!
                </B>
              )}
            </DownloadDiv>
          </Col>
        </Row>

        {fileTransfer?.bulkPayslipStatus === BulkPaySlipDownloadEnum.ERROR ? (
          <div style={{ padding: 10 }}>
            <AlertMessage
              type="error"
              title="Something went wrong while generating bulk payslips."></AlertMessage>{' '}
          </div>
        ) : null}
      </GreeenSection>
      {/* {singlePayrollData?.payrunType !== PayrunType.MID_CYCLE_PAYRUN && ( */}
      <PayslipSendSection>
        <Col lg={24} xl={18} xxl={18}>
          <B display="inline-block" type="b-large">
            <B display="inline-block" type="b-large-semibold">
              Payslips &nbsp;
            </B>
            are ready and have been published! Send payslips to your employees
            on
          </B>
          {isStillGeneratingPaySlip ? (
            <>
              &nbsp; &nbsp;<Spin indicator={antIcon}></Spin>
            </>
          ) : (
            <>
              {!isStillPossibleToSchedule ? (
                <LinkButton size="small" disabled icon={<img src={EmailSVG} />}>
                  Send Payslip
                </LinkButton>
              ) : (
                <>
                  {singlePayrollData?.scheduleDate ? (
                    <LinkButton
                      onClick={() => {
                        setVisible(true);
                      }}
                      icon={<img src={EmailSVG} />}>
                      Change Scheduled Date
                    </LinkButton>
                  ) : (
                    <LinkButton
                      onClick={() => {
                        setVisible(true);
                      }}
                      icon={<img src={EmailSVG} />}>
                      Send Payslip
                    </LinkButton>
                  )}
                </>
              )}
              {!bamboohrEnabled ? (
                <></>
              ) : !isStillPossibleToUpload || uploaded ? (
                <LinkButtonBbhr
                  size="small"
                  disabled
                  loading={uploading}
                  icon={<img src={Bamboo} />}>
                  Upload Now
                </LinkButtonBbhr>
              ) : (
                <LinkButtonBbhr
                  onClick={() => onUpload()}
                  size="small"
                  loading={uploading}
                  icon={<img src={Bamboo} />}>
                  Upload Now
                </LinkButtonBbhr>
              )}
            </>
          )}
        </Col>
        <Col>
          {(isScheduleChecked || isEmailChecked) &&
            (moment(displayPayslipSentDate).isValid() ||
              moment(displayPayslipScheduleDate).isValid()) && (
              <NotificationDiv>
                <B type="b-default" color={theme.black}>
                  {displayPayslipSentDate != 'Invalid date'
                    ? `Mailed on ${displayPayslipSentDate}`
                    : displayPayslipScheduleDate != 'Invalid date'
                    ? `Scheduled on ${displayPayslipScheduleDate}`
                    : null}
                </B>
              </NotificationDiv>
            )}
        </Col>
      </PayslipSendSection>
      {/* )} */}

      {visible && (
        <ScheduleDateModal
          possibleScheduling={isStillPossibleToSchedule}
          payrollData={singlePayrollData ? singlePayrollData : undefined}
          visible={visible}
          handleCancel={() => setVisible(false)}
        />
      )}
    </Div>
  );
};

export default FileExportedSuccessfully;
