import * as ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { cloneDeep, countBy, isNull } from 'lodash';
import moment from 'moment';

import { PeopleI } from '../../../../../types/people.types';
import {
  bankColumns,
  calculationColumns,
  empColumns,
  generalInfoColumns,
  identityColumns,
} from '../../../../../util/excelGenerate.helper';
import { getLastJob } from '../../../../../util/utils';
import { generateEmployeesSheet } from './employeeRows';

export const generateEmployeeMasterExcel = (
  payload: PeopleI[] | undefined,
  selectedRowKes: number[],
) => {
  const selectedPayload =
    payload?.filter((item) => selectedRowKes.includes(item.id)) || [];
  if (!selectedPayload.length) return null;

  const dataPayload = selectedPayload || [];

  let payItemColumnNames: string[] = [];

  (selectedPayload || []).forEach((emp) => {
    emp.userPayItems?.forEach((payItem) => {
      if (payItem.payTitle) {
        payItemColumnNames.push(payItem.payTitle);
      }
    });
  });

  payItemColumnNames = [...new Set(payItemColumnNames)];

  const payItemColumns = cloneDeep(payItemColumnNames || []).map((item) => {
    return {
      key: `${item}`,
      width: 20,
    };
  });

  const workbook = new ExcelJS.Workbook();
  const employees = workbook.addWorksheet('Employees');

  // EPF/ETF Row
  const epfRows = [];
  const epf: { [key: string]: string } = {};
  epf['empId'] = 'EPF/ETF';

  // APIT Row
  const apitRows = [];
  const apit: { [key: string]: string } = {};
  apit['empId'] = 'APIT';

  for (const user of dataPayload) {
    const apitRow: { [key: string]: string } = {};
    const epfRow: { [key: string]: string } = {};
    for (const payItem of payItemColumns) {
      epfRow[payItem.key] = String(
        user.userPayItems?.find((item) => item.payTitle === payItem.key)
          ?.isEpf || '',
      );
      apitRow[payItem.key] = String(
        user.userPayItems?.find((item) => item.payTitle === payItem.key)
          ?.isTaxable || '',
      );
    }
    epfRows.push(epfRow);
    apitRows.push(apitRow);
  }

  for (const p of payItemColumns) {
    const epfResult = countBy(epfRows, (o) => {
      return String(o[p.key]);
    });
    const apitResult = countBy(apitRows, (o) => {
      return String(o[p.key]);
    });

    //Determine whether that pay-item column has EPF/ETF marked as true
    const trueCountEpf = epfResult['true'] ? epfResult['true'] : 0;
    const flaseCountEpf = epfResult['false'] ? epfResult['false'] : 0;
    if (trueCountEpf > flaseCountEpf) {
      epf[p.key] = 'Yes';
    } else if (trueCountEpf === flaseCountEpf) {
      epf[p.key] = '-';
    } else {
      epf[p.key] = 'No';
    }

    //Determine whether that pay-item column has EPF/ETF marked as true
    const trueCountApit = apitResult['true'] ? apitResult['true'] : 0;
    const falseCountApit = apitResult['false'] ? apitResult['false'] : 0;
    if (trueCountApit > falseCountApit) {
      apit[p.key] = 'Yes';
    } else if (trueCountApit === falseCountApit) {
      apit[p.key] = '-';
    } else {
      apit[p.key] = 'No';
    }
  }

  const userRows = [];
  //Forming payroll user rows
  for (const user of dataPayload) {
    const userRow: { [key: string]: string } = {};
    userRow['empId'] = user.employeeNumber || '';
    userRow['fullName'] = user.fullName || '';
    userRow['nameWithInitials'] = user.nameWithInitials || '';
    userRow['preferredName'] = user.preferredName || '';
    userRow['paySlipMail'] = user.paySlipMail || '';
    for (const col of [...bankColumns, ...generalInfoColumns, ...empColumns]) {
      switch (col.key) {
        case 'employmentType':
        case 'branchName':
        case 'designation':
        case 'endDate':
          const lastJob = getLastJob(user.userHistory);
          if (col.key === 'endDate') {
            const startDate = lastJob?.endDate || '';
            userRow[col.key] = moment(startDate).isValid()
              ? moment(startDate).format('YYYY-MM-DD')
              : '';
          } else {
            userRow[col.key] = (lastJob && lastJob[col.key]) || '-';
          }
          break;

        case 'branchCode':
          userRow[col.key] = user.bankBranch?.branchCode || '';
          break;

        case 'DOB':
          const dob = user.DOB || '';
          userRow[col.key] = moment(dob).isValid()
            ? moment(dob).format('YYYY-MM-DD')
            : '';
          break;

        case 'teams':
          const team = user.teams || '';
          userRow[col.key] =
            !isNull(team) && Array.isArray(team) ? team.join() : team;
          break;

        case 'startDate':
          const firstJob = getLastJob(user.userHistory);
          const startDate = firstJob?.startDate || '';
          userRow[col.key] = moment(startDate).isValid()
            ? moment(startDate).format('YYYY-MM-DD')
            : '';
          break;
        case 'joinedDate':
          const joinedDates = user.joinedDate || '';
          userRow[col.key] = moment(joinedDates).isValid()
            ? moment(joinedDates).format('YYYY-MM-DD')
            : '';
          break;
        default:
          userRow[col.key] = String(user[col.key as keyof PeopleI] || '');
      }
    }

    for (const col of calculationColumns) {
      userRow[col.key] = String(user[col.key as keyof PeopleI] || '');
    }

    for (const payItem of payItemColumns) {
      userRow[payItem.key] = String(
        user.userPayItems?.find((item) => item.payTitle === payItem.key)
          ?.amount || '',
      );
    }
    userRows.push(userRow);
  }

  generateEmployeesSheet({
    identityColumns,
    payItemColumns,
    empColumns,
    employees,
    userRows,
    payload,
    epf,
    apit,
  });

  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `Employee_Data_${moment().format('DD-MM-YYYY')}.xlsx`,
    );
  });
};
