import { Col } from 'antd';
import React, { FC } from 'react';
import { B } from '../../../components';
import theme from '../../../theme';

interface CalculationProps {
  title: string;
  amount: string | number;
  prefix: string;
  length?: number;
}

const CalculationCard: FC<CalculationProps> = ({
  title,
  amount,
  prefix,
  length,
}) => {
  return (
    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
      <B type="b-small" color={theme.gray600}>
        {title}
      </B>
      <B type="b-large-semibold">
        <B type="b-small" display="inline-block" color={theme.gray600}>
          {prefix}
        </B>
        {prefix ? ` ${amount}` : length ? amount + '/' + length : amount}
      </B>
    </Col>
  );
};

export default CalculationCard;
