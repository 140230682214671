import React, { FC } from 'react';
import theme from '../theme';
import { LeaveNameEnum } from '../types/leave.types';
import { B } from './B';
import AlertWarnSvg from '../assets/Icons/alert-icons/alert-icon-warn.svg';
import { Input, Typography, Image } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import styled from '@emotion/styled';

interface Props {
  leaveName: string;
  label?: string;
  infoText?: string;
  isError?: boolean;
  errorMessage?: string;
  size?: SizeType;
  value?: string;
}

const { Text } = Typography;

const Label = styled(Text)`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.blue700};
  padding-bottom: 4px;
`;
const CustomInput = styled(Input)<{
  isError?: boolean;
  isWarning: boolean;
  size?: SizeType;
}>`
  border: ${(props) =>
    props.isError
      ? `1px solid ${theme.red500}`
      : props.isWarning
      ? `1px solid ${theme.orange400}`
      : `1px solid ${theme.gray300}`};
  box-sizing: border-box;
  padding-left: 16px;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  height: ${(props) => (props.size == 'small' ? '32px !important' : '40px')};

  &:focus {
    border: ${(props) =>
      props.isError
        ? `1px solid ${theme.red500}`
        : props.isWarning
        ? `1px solid ${theme.orange400}`
        : `1px solid ${theme.gray300}`};
    box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1);
  }

  &:hover {
    border: ${(props) =>
      props.isError
        ? `1px solid ${theme.red500}`
        : props.isWarning
        ? `1px solid ${theme.orange400}`
        : `1px solid ${theme.gray300}`};
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
    border-radius: 4px;
  }
`;
const CustomImage = styled(Image)`
  margin-top: -3px;
  padding-right: 5px;
`;

export const CustomInputsLeaveBalance: FC<Props> = (props) => {
  const {
    label,
    infoText,
    isError = false,
    errorMessage,
    leaveName,
    size,
    value,
  } = props;

  let isWarning = false;
  let warningMessage = '';

  if (leaveName === LeaveNameEnum.ANNUAL_LEAVE && Number(value) < 14) {
    warningMessage = 'Minimum 14 days required!';
    isWarning = true;
  }
  if (leaveName === 'CASUAL_LEAVE' && Number(value) < 7) {
    warningMessage = 'Minimum 7 days required!';
    isWarning = true;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {label ? <Label>{label}</Label> : null}
      <CustomInput
        {...props}
        size={size}
        isError={isError}
        isWarning={isWarning}
      />
      {infoText ? (
        <B type="b-small" color={theme.gray600} margintop="10px">
          {infoText}
        </B>
      ) : null}
      {isError && (
        <B type="b-small" color={theme.red500} marginTop="5px">
          {errorMessage}
        </B>
      )}
      {isWarning && (
        <B type="b-small" color={theme.orange400} marginTop="7px">
          <CustomImage src={AlertWarnSvg} />
          {warningMessage}
        </B>
      )}
    </div>
  );
};
