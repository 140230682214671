import { Permission } from '../types';
import { isAllowed } from './permissionUtil';

interface ReviewAllowedParams {
  reviewerId: number | undefined;
  userId: number | undefined;
}

export function reviewAllowed({
  reviewerId,
  userId,
}: ReviewAllowedParams): boolean {
  return isAllowed(Permission.MANAGE_ASSIGNED_USERS)
    ? true
    : reviewerId !== userId && !isAllowed(Permission.MANAGE_ASSIGNED_USERS);
}
