import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Steps } from 'antd';
import { B, Div } from '../../../../components';
import theme from '../../../../theme';
const { Step } = Steps;

const StepsStyled = styled(Steps)`
  .ant-steps-item-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.black};
    margin-top: -2px;
  }
  .ant-steps-item .ant-steps-item-title {
    line-height: 20px;
  }
  .ant-steps-item-description {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: ${theme.gray600};
  }
  .ant-steps-item .ant-steps-item-content {
    min-height: 72px !important;
  }
  .ant-steps-item .ant-steps-item-description {
    padding-bottom: 0px !important;
    @media (max-width: 768px) {
      padding-bottom: 16px !important;
    }
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: ${theme.white};
    border-color: ${theme.black};
    .ant-steps-icon {
      color: ${theme.black};
    }
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    border-color: ${theme.black};
    .ant-steps-icon {
      color: ${theme.black};
    }
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.black};
    margin-top: 2px;
  }
  .ant-steps-item-subtitle {
    display: block;
    margin-left: 0px;
    color: ${theme.gray600};
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: ${theme.gray600};
  }
`;

const GuidelineUsingCSV: FC = () => {
  return (
    <Div
      py="24px"
      borderBottom={`0.5px solid ${theme.gray300}`}
      mx="-32px"
      px="48px">
      <B type="b-large-semibold" pb={28}>
        How to upload file?
      </B>
      <StepsStyled direction="vertical">
        <Step
          title="Fill-in your payroll details "
          subTitle="Make sure your all employees are updated with the system."
          description="Then fill-in this month’s payroll details in a copy of our upload template"
        />
        <Step
          title="Save as a CSV file and upload  "
          description="You can save any spreadsheet that you use as a CSV "
        />
        <Step
          title="Process your payroll "
          description="Make the necessary edits and complete your payrun "
        />
      </StepsStyled>
    </Div>
  );
};

export default GuidelineUsingCSV;
