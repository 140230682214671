import React, { FC, ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { B } from '../B';
import theme from '../../theme';
import AlertSuccessSvg from '../../assets/Icons/alert-icons/alert-icon-success.svg';
import AlertWarnSvg from '../../assets/Icons/alert-icons/alert-icon-warn.svg';
import AlertErrorSvg from '../../assets/Icons/alert-icons/alert-icon-error.svg';
import AlertInformationalSvg from '../../assets/Icons/alert-icons/alert-informational.svg';
import AlertCloseSvg from '../../assets/Icons/alert-icons/alert-close.svg';
import { Div } from '../Div';
import { Image } from '../Image';

type AlertTypes = 'warn' | 'error' | 'success' | 'info' | 'notification';

export const svgIcons = {
  success: AlertSuccessSvg,
  warn: AlertWarnSvg,
  error: AlertErrorSvg,
  info: AlertInformationalSvg,
  notification: '',
};

export const colorTheme = {
  success: {
    borderColor: `${theme.green500}`,
    background: `${theme.green50}`,
  },
  warn: {
    borderColor: `${theme.orange200}`,
    background: `${theme.orange50}`,
  },
  error: {
    borderColor: `${theme.red200}`,
    background: `${theme.red50}`,
  },
  info: {
    borderColor: `${theme.blue500}`,
    background: `${theme.lightBlue50}`,
  },
  notification: {
    borderColor: `${theme.gray300}`,
    background: `${theme.gray50}`,
  },
};

interface AlertMessageProps {
  title?: string;
  type: AlertTypes;
  description?: string;
  onClose?: () => void;
  actionLabel?: ReactNode;
  hideIcon?: boolean;
  onAction?: () => void;
  width?: number;
  color?: string;
  iconCssStyle?: string;
  closeCssStyle?: string;
}

const ContainerStyle = styled.div<{ type: AlertTypes; width?: number }>`
  border: 0.5px solid ${(props) => colorTheme[props.type].borderColor};
  background-color: ${(props) => colorTheme[props.type].background};
  box-sizing: border-box;
  border-radius: 4px;
  padding: ${(props) =>
    props.type == 'notification' ? '4px 12px 6px 5px' : '10px 11px'};
  padding-right: 14px;
  cursor: pointer;
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
` as any;
const AlertStyle = styled.div`
  display: flex;
  justify-content: space-between;
` as any;

export const AlertMessage: FC<AlertMessageProps> = ({
  title,
  type,
  onClose,
  description,
  actionLabel,
  hideIcon,
  onAction,
  width,
  color,
  iconCssStyle,
}) => {
  if (!title) {
    return null;
  }
  return (
    <ContainerStyle type={type} width={width}>
      <AlertStyle>
        {hideIcon ? null : (
          <Image
            css={css`
              ${iconCssStyle}
            `}
            src={svgIcons[type]}
          />
        )}
        <B type="b-default" color={color} paddingTop="2px" paddingLeft="7px">
          {title}
        </B>
        <Div display="flex" flexDirection="row" marginLeft="auto">
          {description ? null : onAction ? (
            <Div onClick={onAction} mt="4px" mr="8px">
              {actionLabel}
            </Div>
          ) : null}

          {onClose ? (
            <Image
              onClick={onClose}
              cursor="pointer"
              width="12px"
              src={AlertCloseSvg}
            />
          ) : null}
        </Div>
      </AlertStyle>
      {description ? (
        <Div padding="10px 40px 6px 32px">
          <B type="b-small">{description}</B>
        </Div>
      ) : null}
      {description ? (
        actionLabel ? (
          <Div padding="8px 0px 0px 32px">
            <B type="b-default" color="#0052EA" onClick={() => onAction}>
              {actionLabel}
            </B>
          </Div>
        ) : null
      ) : null}
    </ContainerStyle>
  );
};
