import { KeyValueI, MappedDataI } from '../shared/CSVMapper';

export const UN_ASSIGNED = 'Unassigned';
export interface ColumnMappingI {
  name: string;
  dataIndex: string;
  columnPatterns: string[];
  rejectPatterns: string[];
  isRequired: boolean;
  maxLength: number;
  type?: 'addition' | 'deduction' | 'info';
  bankColumn: boolean;
  validations: { message: string; regex: string }[];
}

export interface ErrorI {
  message: string;
  column: string;
  rowIndex: number;
  positionText: string;
  key: string;
}

export interface CSVDataI {
  [key: string]: string;
}
const NOT_ONLY_NUMBER_REGEX = {
  regex: '^(?![0-9]+$)[a-zA-Z0-9 ]{2,}$',
  message: 'only numbers are not allowed',
};
const ONLY_NUMBERS = {
  regex: '^[0-9]*$',
  message: 'only numbers are allowed',
};
const ONLY_3_LENGTH_ALLOWED = {
  regex: '^([0-9]{3,3})$',
  message: 'only numbers are allowed',
};
const ONLY_4_LENGTH_ALLOWED = {
  regex: '^([0-9]{4,4})$',
  message: 'only numbers are allowed',
};
const ONLY_10_LENGTH_ALLOWED = {
  regex: '^([0-9]{10,10})$',
  message: 'only 10 digits are allowed',
};
const NIC_MUST_BE_VALID = {
  regex: '^([0-9]{9}[x|X|v|V]|[0-9]{12})$',
  message: 'NIC Must be valid',
};

export const EMPLOYEE_NAME_DATA_INDEX = 'name';
export const BASIC_SALARY_DATA_INDEX = 'basic';
export const columnMappings: ColumnMappingI[] = [
  {
    name: 'Name',
    dataIndex: EMPLOYEE_NAME_DATA_INDEX,
    columnPatterns: ['name'],
    rejectPatterns: ['account', 'bank', 'branch'],
    isRequired: true,
    maxLength: 24,
    type: 'info',
    bankColumn: true,
    validations: [NOT_ONLY_NUMBER_REGEX],
  },
  {
    name: 'Designation',
    dataIndex: 'designation',
    columnPatterns: ['designation'],
    rejectPatterns: [],
    isRequired: false,
    maxLength: 100,
    type: 'info',
    bankColumn: false,
    validations: [],
  },
  {
    name: 'Basic',
    dataIndex: 'basic',
    columnPatterns: ['Basic'],
    rejectPatterns: [],
    isRequired: false,
    maxLength: 7,
    type: 'addition',
    bankColumn: false,
    validations: [ONLY_NUMBERS],
  },
  {
    name: 'Fixed Allowance',
    dataIndex: 'fixedAllowance',
    columnPatterns: ['Fixed Allowance'],
    rejectPatterns: [],
    isRequired: false,
    maxLength: 7,
    type: 'addition',
    bankColumn: false,
    validations: [ONLY_NUMBERS],
  },
  {
    name: 'Variable Allowance',
    dataIndex: 'variableAllowance',
    columnPatterns: ['Variable Allowance'],
    rejectPatterns: [],
    isRequired: false,
    maxLength: 7,
    type: 'addition',
    bankColumn: false,
    validations: [ONLY_NUMBERS],
  },

  {
    name: 'Salary Advance',
    dataIndex: 'salaryAdvancement',
    columnPatterns: ['Salary Advancement'],
    rejectPatterns: [],
    isRequired: false,
    maxLength: 7,
    type: 'deduction',
    bankColumn: false,
    validations: [ONLY_NUMBERS],
  },
  {
    name: 'Stamp Duty',
    dataIndex: 'stampDuty',
    columnPatterns: ['Stamp Duty'],
    rejectPatterns: [],
    isRequired: false,
    maxLength: 7,
    type: 'deduction',
    bankColumn: false,
    validations: [ONLY_NUMBERS],
  },
  {
    name: 'Fund',
    dataIndex: 'fund',
    columnPatterns: ['fund'],
    rejectPatterns: [],
    isRequired: false,
    maxLength: 7,
    type: 'deduction',
    bankColumn: false,
    validations: [ONLY_NUMBERS],
  },
  {
    name: 'Account No.',
    dataIndex: 'accountNumber',
    columnPatterns: ['Account Number', 'Account No', 'Account No.'],
    rejectPatterns: ['Phone', 'Branch'],
    isRequired: true,
    maxLength: 15,
    type: 'info',
    bankColumn: true,
    validations: [ONLY_NUMBERS],
  },
  {
    name: 'Bank Code',
    dataIndex: 'bankCode',
    columnPatterns: ['Bank Code'],
    rejectPatterns: ['Branch'],
    isRequired: true,
    maxLength: 4,
    type: 'info',
    bankColumn: true,
    validations: [ONLY_NUMBERS, ONLY_4_LENGTH_ALLOWED],
  },
  {
    name: 'Branch Code',
    dataIndex: 'bankBranch',
    columnPatterns: ['Branch Code', 'branch'],
    rejectPatterns: ['Bank'],
    isRequired: true,
    maxLength: 3,
    type: 'info',
    bankColumn: false,
    validations: [ONLY_NUMBERS, ONLY_3_LENGTH_ALLOWED],
  },
  {
    name: 'Remarks',
    dataIndex: 'remarks',
    columnPatterns: ['Remark', 'comment', 'note'],
    rejectPatterns: [],
    isRequired: true,
    maxLength: 10,
    type: 'info',
    bankColumn: true,
    validations: [{ message: '', regex: '' }],
  },
  {
    name: 'Company Ref',
    dataIndex: 'companyRef',
    columnPatterns: ['Company Ref', 'Reference', 'Company'],
    rejectPatterns: [],
    isRequired: false,
    maxLength: 20,
    type: 'info',
    bankColumn: true,
    validations: [{ message: '', regex: '' }],
  },
  {
    name: 'Sender Mobile',
    dataIndex: 'senderMobile',
    columnPatterns: ['Sender Mobile', 'Sender'],
    rejectPatterns: ['Nic'],
    isRequired: false,
    maxLength: 15,
    type: 'info',
    bankColumn: true,
    validations: [ONLY_10_LENGTH_ALLOWED],
  },
  {
    name: 'Receiver Mobile',
    dataIndex: 'receiverMobile',
    columnPatterns: ['Receiver Mobile', 'Receiver'],
    rejectPatterns: ['Nic', 'Email'],
    isRequired: false,
    maxLength: 10,
    type: 'info',
    bankColumn: true,
    validations: [ONLY_10_LENGTH_ALLOWED],
  },
  {
    name: 'Receiver Nic',
    dataIndex: 'receiverNic',
    columnPatterns: ['nic', 'Identity', 'Receiver NIC'],
    rejectPatterns: ['mobile', 'account', 'mobile'],
    isRequired: false,
    maxLength: 10,
    type: 'info',
    bankColumn: true,
    validations: [NIC_MUST_BE_VALID],
  },
  {
    name: 'Receiver Email',
    dataIndex: 'receiverEmail',
    columnPatterns: ['email', 'Receiver Email'],
    rejectPatterns: ['mobile', 'account', 'nic'],
    isRequired: false,
    maxLength: 100,
    type: 'info',
    bankColumn: true,
    validations: [{ message: '', regex: '' }],
  },
];

export function getHeaderMatchedItem(header: string): ColumnMappingI | null {
  for (let i = 0; i < columnMappings.length; i++) {
    const mapping = columnMappings[i];
    for (let j = 0; j < mapping.columnPatterns.length; j++) {
      const columnPattern = mapping.columnPatterns[j];
      if (RegExp(columnPattern, 'ig').test(header)) {
        let didMatchRejectItem = false;
        mapping.rejectPatterns.map((rejectPattern) => {
          if (RegExp(rejectPattern, 'ig').test(header)) {
            didMatchRejectItem = true;
          }
        });
        if (!didMatchRejectItem) {
          return mapping;
        }
      }
    }
  }
  return null;
}

export function getColumnConfig(header: string): ColumnMappingI | null {
  for (let i = 0; i < columnMappings.length; i++) {
    return getHeaderMatchedItem(header);
  }
  return null;
}

export function getColumnConfigByDataIndex(
  dataIndex: string,
): ColumnMappingI | null {
  for (let i = 0; i < columnMappings.length; i++) {
    if (columnMappings[i].dataIndex === dataIndex) {
      return columnMappings[i];
    }
  }
  return null;
}

interface GetErrorI {
  message: string;
  column: string;
  rowIndex: number;
}

function getError({ message, column, rowIndex }: GetErrorI): ErrorI {
  return {
    message: `${message}`,
    column: column,
    rowIndex: rowIndex,
    positionText: `Column "${column}" line ${rowIndex + 1} `,
    key: `${column}_${rowIndex}`,
  };
}

export function getDataMappingErrors(
  data: CSVDataI[],
  mappedHeaders: MappedDataI[],
): ErrorI[] {
  const errors: ErrorI[] = [];
  columnMappings.map((columnMapping) => {
    const { dataIndex, isRequired, maxLength } = columnMapping;
    const columnHeader = mappedHeaders.find((h) => h.dataIndex === dataIndex);
    if (columnHeader === undefined && isRequired) {
      errors.push(
        getError({
          message: `${columnMapping.name} is a required column.`,
          column: 'n/a',
          rowIndex: 0,
        }),
      );
    } else if (columnHeader) {
      data.forEach((item, rowIndex) => {
        const cellData = item[columnHeader.dataIndex];
        if (cellData && cellData.length > maxLength) {
          errors.push(
            getError({
              message: `"${cellData}" has ${cellData.length} letters. It should be be less than ${maxLength}`,
              column: columnHeader.dataIndex,
              rowIndex,
            }),
          );
        } else {
          for (let i = 0; i < columnMapping.validations.length; i++) {
            const validation = columnMapping.validations[i];

            if (!RegExp(validation.regex).test(cellData)) {
              errors.push(
                getError({
                  message: `"${cellData}" ${validation.message}`,
                  column: columnHeader.dataIndex,
                  rowIndex,
                }),
              );
            }
          }
        }
      });
    }
  });
  return errors;
}

function generateStringWithWhiteSpaces(text: string, length: number) {
  let newText = text || '';
  while (newText.length < length) {
    newText += ' ';
  }
  return newText;
}

export function generateBulkUploadCSV(mappedHeaders: MappedDataI[]): string {
  const rows = getRows();
  let content = '';

  rows.map((row: KeyValueI) => {
    for (const [key, value] of Object.entries(row)) {
      const maxLength = mappedHeaders[Number(key)].maxLength;

      if (value) {
        content += generateStringWithWhiteSpaces(value, maxLength);
      } else {
        content += generateStringWithWhiteSpaces(' - ', maxLength);
      }
      content += ',';
    }
    content = content.replace(/(.+),$/, '$1');
    content += '\n';
  });
  return content;

  function getRows() {
    const rows: KeyValueI[] = [];
    mappedHeaders
      .filter((item) => !item.isUnassigned)
      .filter((item) => item.bankColumn)
      .forEach((mappedHeader, mapIndex) => {
        mappedHeader.rows.forEach((columnCell, index) => {
          rows[index] = rows[index] ? rows[index] : {};
          rows[index][mapIndex] = columnCell;
        });
      });
    return rows;
  }
}

export function cleanupInitialCsvData(data: Array<string[]>): string[][] {
  return data.map((row) => row.map((data) => (data || '').split(',').join('')));
}
