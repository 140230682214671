/* eslint-disable no-use-before-define */
import { message } from 'antd';
import axios from 'axios';

import * as FullStory from '@fullstory/browser';

import { isProduction } from '../../configs';
import { UserRoleResponseType } from '../../types';
import {
  AddAccountantI,
  BankDetailIForm,
  CreateEmployeeI,
  EmergencyContactI,
  EmploymentDetailI,
  ExternalAccountantI,
  IndividualPeopleI,
  MePersonalDetailI,
  PeopleI,
  PersonalDetailI,
  UserHistoryI,
} from '../../types/people.types';
import showApiError from '../../util/showApiError';

const All_PEOPLE = 'All_PEOPLE';
const PEOPLE = 'PEOPLE';
const All_PEOPLE_LOADING = 'All_PEOPLE_LOADING';
const PEOPLE_LOADING = 'PEOPLE_LOADING';
const ME = 'ME';
const GET_USER_LOADING = 'GET_USER_LOADING';
const PUT_USER_LOADING = 'PUT_USER_LOADING';
const GET_MANAGER_LOADING = 'GET_MANAGER_LOADING';
const MANAGER = ' MANAGER';
const ERROR_HANDLING = 'ERROR_HANDLING';
const USER_ROLES = 'USER_ROLES';
const GET_USER_ROLES_LOADING = 'GET_USER_ROLES_LOADING';
const All_ACCOUNTANTS = 'All_ACCOUNTANTS';
const All_ACCOUNTANTS_LOADING = 'All_ACCOUNTANTS_LOADING';
const CLEAR_ME = 'CLEAR_ME';

interface InitialStateI {
  allPeoples: PeopleI[];
  people: IndividualPeopleI | null;
  allPeoplesLoading: boolean;
  peopleLoading: boolean;
  me: IndividualPeopleI | null;
  putUserLoading: boolean;
  userLoading: boolean;
  managerLoading: boolean;
  manager: IndividualPeopleI[];
  errorHandling: boolean;
  userRolesLoading: boolean;
  userRoles: UserRoleResponseType[];
  allAccountantsLoading: boolean;
  allAccountants: ExternalAccountantI[];
}

const initialState: InitialStateI = {
  allPeoples: [],
  people: null,
  allPeoplesLoading: false,
  peopleLoading: false,
  me: null,
  putUserLoading: false,
  userLoading: false,
  managerLoading: false,
  manager: [],
  errorHandling: false,
  userRolesLoading: false,
  userRoles: [],
  allAccountantsLoading: false,
  allAccountants: [],
};

export default function reducer(
  state: InitialStateI = initialState,
  action: any,
): InitialStateI {
  switch (action.type) {
    case All_PEOPLE:
      return {
        ...state,
        allPeoples: action.payload,
      };
    case All_PEOPLE_LOADING:
      return {
        ...state,
        allPeoplesLoading: action.payload,
      };
    case PEOPLE_LOADING:
      return {
        ...state,
        peopleLoading: action.payload,
      };
    case PEOPLE:
      return {
        ...state,
        people: action.payload,
      };
    case GET_USER_LOADING:
      return {
        ...state,
        userLoading: action.payload,
      };
    case ME:
      return {
        ...state,
        me: action.payload,
      };
    case PUT_USER_LOADING:
      return {
        ...state,
        putUserLoading: action.payload,
      };
    case GET_MANAGER_LOADING: {
      return {
        ...state,
        managerLoading: action.payload,
      };
    }
    case MANAGER: {
      return {
        ...state,
        manager: action.payload,
      };
    }
    case ERROR_HANDLING:
      return {
        ...state,
        errorHandling: action.payload,
      };
    case GET_USER_ROLES_LOADING: {
      return {
        ...state,
        userLoading: action.payload,
      };
    }
    case USER_ROLES:
      return {
        ...state,
        userRoles: action.payload,
      };
    case All_ACCOUNTANTS_LOADING:
      return {
        ...state,
        allAccountantsLoading: action.payload,
      };
    case All_ACCOUNTANTS:
      return {
        ...state,
        allAccountants: action.payload,
      };
    case CLEAR_ME:
      return {
        ...state,
        me: null,
      };
    default:
      return state;
  }
}

export function addNewEmployee(data: CreateEmployeeI) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: PUT_USER_LOADING, payload: true });
      const res = await axios.post('/users', data);
      message.success('New employment created successfully');
      dispatch({ type: PUT_USER_LOADING, payload: false });
      return res.data.id;
    } catch (error: any) {
      const errorMessage = showApiError(error);
      throw errorMessage;
    } finally {
      dispatch({ type: PUT_USER_LOADING, payload: false });
    }
  };
}

export function createEmploymentDetails(data: EmploymentDetailI, id: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: PUT_USER_LOADING, payload: true });
      await axios.put('/users/employmentDetails', {
        id,
        ...data,
      });
      message.success('Employment Details submitted successfully!');
      dispatch({ type: PUT_USER_LOADING, payload: false });
      return true;
    } catch (error: any) {
      dispatch({ type: PUT_USER_LOADING, payload: false });
      if (error.response && error.response.status === 401) {
        message.error(error.response.data.message);
      } else {
        showApiError(error);
      }
    }
  };
}
//TODO :remove getAllPeople function
export function getAllPeople() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: All_PEOPLE_LOADING, payload: true });
      const res = await axios.get('/users/organization', {
        params: {
          limit: 1000,
          offset: 1,
          orderBy: 'DESC',
        },
      });
      dispatch({ type: 'All_PEOPLE', payload: res.data.items });
      dispatch({ type: All_PEOPLE_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: All_PEOPLE_LOADING, payload: false });
    }
  };
}

export function getMinimumUserInfo(teams?: string[]) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: All_PEOPLE_LOADING, payload: true });
      const res = await axios.get('/users/minimumUserInfo', {
        params: {
          limit: 1000,
          offset: 1,
          orderBy: 'DESC',
          ...(teams && { teams: teams }),
        },
      });
      dispatch({ type: 'All_PEOPLE', payload: res.data.items });
      dispatch({ type: All_PEOPLE_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: All_PEOPLE_LOADING, payload: false });
    }
  };
}

export function createEmergencyContact(data: EmergencyContactI, id: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: PUT_USER_LOADING, payload: true });
      await axios.put('/users/emergencyContact', {
        id,
        ...data,
      });
      message.success('Emergency Details submitted successfully!');
      dispatch({ type: PUT_USER_LOADING, payload: false });
    } catch (error: any) {
      dispatch({ type: PUT_USER_LOADING, payload: false });
      showApiError(error);
    }
  };
}

export function updateBankDetails(data: BankDetailIForm, id: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: PUT_USER_LOADING, payload: true });
      await axios.put('/users/bankDetails', { id, ...data });
      message.success('Bank details submitted successfully');
      dispatch({ type: PUT_USER_LOADING, payload: false });
    } catch (error: any) {
      dispatch({ type: PUT_USER_LOADING, payload: false });
      if (error.response && error.response.status === 401) {
        message.error(error.response.data.message);
      } else {
        showApiError(error);
      }
    }
  };
}

export function getLoggedInUser() {
  return async (dispatch: any) => {
    try {
      const res = await axios.get('/users/me');
      dispatch({ type: ME, payload: res.data });

      if (isProduction) {
        FullStory.identify(res.data.id, {
          displayName: res.data.fullName || null,
          email: res.data.loginEmail || null,
          organisationId: res.data.organizationId || null,
          role: res.data.userRole || null,
        });
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        message.error(error.response.data.message);
      } else {
        showApiError(error);
      }
    } finally {
      dispatch({ type: GET_USER_LOADING, payload: false });
    }
  };
}

export function editPersonalDetails(data: PersonalDetailI, id: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: PUT_USER_LOADING, payload: true });
      await axios.put('/users/PersonnelDetails', { id, ...data });
      dispatch({ type: PUT_USER_LOADING, payload: false });
      message.success('Personal details submitted successfully!');
    } catch (error: any) {
      dispatch({ type: PUT_USER_LOADING, payload: false });
      if (error.response && error.response.status === 401) {
        message.error(error.response.data.message);
      } else {
        showApiError(error);
      }
    }
  };
}

export function employmentHistory(data: UserHistoryI) {
  return async () => {
    try {
      await axios.put('/users/history', {
        ...data,
        id: data.id?.toString(),
      });
      message.success('Employment detail successfully updated ');
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        message.error(error.response.data.message);
      } else {
        showApiError(error);
      }
    }
  };
}
export function createEmploymentHistory(data: UserHistoryI, userId: string) {
  return async () => {
    try {
      await axios.post('users/history', {
        userId,
        ...data,
        isCurrentJob: data.isCurrentJob || false,
      });
      message.success('New Experience added successfully!');
    } catch (error: any) {
      showApiError(error);
      if (error.response && error.response.status === 401) {
        message.error(error.response.data.message);
      } else {
        showApiError(error);
      }
    }
  };
}

export function getManagers() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: GET_MANAGER_LOADING, payload: true });
      const res = await axios.get('users/managers');
      dispatch({ type: MANAGER, payload: res.data });
      dispatch({ type: GET_MANAGER_LOADING, payload: false });
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        message.error(error.response.data.message);
      } else {
        showApiError(error);
      }
      dispatch({ type: GET_USER_LOADING, payload: false });
    }
  };
}
export function deleteExperience(historyId: any, userId: string) {
  return async () => {
    try {
      await axios.delete(`/users/history/${historyId}`);
      message.success('Successfully deleted the Experience..');
    } catch (error: any) {
      showApiError(error);
    }
  };
}

export function mePersonalDetailsEdit(data: MePersonalDetailI) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: PUT_USER_LOADING, payload: true });
      await axios.put('/users/me/PersonnelDetails', { ...data });
      message.success('Personal details submitted successfully!');
      dispatch(getLoggedInUser());
      dispatch({ type: PUT_USER_LOADING, payload: false });
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        message.error(error.response.data.message);
      } else {
        showApiError(error);
      }
      dispatch({ type: PUT_USER_LOADING, payload: false });
    }
  };
}
export function meEmergencyContact(data: EmergencyContactI) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: PUT_USER_LOADING, payload: true });
      await axios.put('/users/me/emergencyContact', {
        ...data,
      });
      message.success('Emergency details submitted successfully!');

      dispatch(getLoggedInUser());
      dispatch({ type: PUT_USER_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: PUT_USER_LOADING, payload: false });
    }
  };
}
export function meBankDetails(data: BankDetailIForm) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: PUT_USER_LOADING, payload: true });
      await axios.put('/users/me/bankDetails', {
        ...data,
      });

      message.success('Bank details submitted successfully');
      dispatch(getLoggedInUser());
      dispatch({ type: PUT_USER_LOADING, payload: false });
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        message.error(error.response.data.message);
        dispatch({ type: PUT_USER_LOADING, payload: false });
      } else {
        showApiError(error);
        dispatch({ type: PUT_USER_LOADING, payload: false });
      }
    }
  };
}

export function getUserRoles() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: GET_USER_ROLES_LOADING, payload: true });
      const res = await axios.get('/users/userRoles');
      dispatch({ type: USER_ROLES, payload: res.data });
      dispatch({ type: GET_USER_ROLES_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
    }
  };
}

//............Accountant.....................

export function getAccountants() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: All_ACCOUNTANTS_LOADING, payload: true });
      const res = await axios.get('/users/accountants');
      dispatch({ type: All_ACCOUNTANTS, payload: res.data });
      dispatch({ type: All_ACCOUNTANTS_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: All_ACCOUNTANTS_LOADING, payload: false });
    }
  };
}

export function addAccountant(data: AddAccountantI) {
  return async (dispatch: any) => {
    try {
      await axios.post('/users/addExternalAccountant', {
        ...data,
      });
      dispatch(getAccountants());
      message.success('New Accountant added successfully!');
    } catch (error: any) {
      showApiError(error);
    }
  };
}

export function removeExternalAccountant(userId: string) {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/users/removeExternalAccountant/${userId}`);
      message.success('Successfully deleted the Accountant..');
      dispatch(getAccountants());
    } catch (error: any) {
      showApiError(error);
    }
  };
}
