import React, { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import moment, { Moment } from 'moment';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import {
  Div,
  FooterComponent,
  PrimaryButton,
  RouteLeavingGuard,
} from '../../../../components';
import { mePersonalDetailsEdit } from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import theme from '../../../../theme';
import { ValueOf } from '../../../../types';
import {
  IndividualPeopleI,
  MePersonalDetailI,
} from '../../../../types/people.types';
import { EmergencyForm } from '../../../people/pages/personal-details/EmergencyForm';
import UserPeopleSelect from '../../../people/shared/UserPeopleSelect';
import { AccountDetailLayout } from '../../components/AccountDetailLayout';
import ProfileImage from './ProfileImage';
import { UserPersonalDetailsForm } from './UserPersonalDetailsForm';
import dayjs from 'dayjs';

const CustomForm = styled(Form)`
  padding: 0px 0px 32px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const Profile: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const [imageUrl, setImageUrl] = useState<string>();
  const [isFieldDataChanged, setIsFieldDataChanged] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const me = useSelector((state: RootState) => state.people.me);

  useEffect(() => {
    if (me) {
      for (const [key, value] of Object.entries(me)) {
        form.setFieldsValue({ [key]: value });
      }
    }
  }, [me]);
  useEffect(() => {
    const newPeople = { ...me };
    if (newPeople) {
      for (const [key, value] of Object.entries(newPeople)) {
        let newValue: ValueOf<IndividualPeopleI> | Moment | unknown = value; //Todo: Remove unknown type
        if (key == 'DOB') {
          newValue = me?.DOB ? dayjs(me?.DOB) : dayjs();
        }
        form.setFieldsValue({ [key]: newValue });
      }
    }
  }, [me]);
  function shouldBlockNavigation() {
    return isFieldDataChanged;
  }
  function onValuesChange() {
    setIsFieldDataChanged(true);
  }
  const scrollToFirstErorField = (errorInfo: ValidateErrorEntity) => {
    const { errorFields } = errorInfo;
    let errorInput;
    if (String(errorFields[0].name) === 'fullName') {
      errorInput = document.querySelectorAll('#fullName')[1];
    } else {
      errorInput = document.getElementById(`${errorFields[0].name}`);
    }
    if (errorInput) {
      errorInput.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  };
  const onFinish = async ({
    address,
    personalMobile,
    homeMobile,
    emergencyContactName,
    emergencyContactRelationship,
    emergencyContactMobile,
    emergencyContactDaytimeNumber,
    emergencyContactAfterHoursNumber,
  }: MePersonalDetailI) => {
    setIsFieldDataChanged(false);
    const value = {
      profilePicture: imageUrl,
      address,
      personalMobile,
      homeMobile,
      emergencyContactName,
      emergencyContactRelationship,
      emergencyContactMobile,
      emergencyContactDaytimeNumber,
      emergencyContactAfterHoursNumber,
    };
    dispatch(mePersonalDetailsEdit(value));
  };

  return (
    <CustomForm
      form={form}
      layout="vertical"
      onValuesChange={onValuesChange}
      onFinish={(v) => onFinish(v as MePersonalDetailI)}
      onFinishFailed={scrollToFirstErorField}
      wrapperCol={{ span: 50 }}>
      <AccountDetailLayout>
        <RouteLeavingGuard
          when={isFieldDataChanged}
          navigate={(path) => navigate(path)}
          shouldBlockNavigation={shouldBlockNavigation}
        />
        <UserPeopleSelect defaultIndex="1" />
        <ProfileImage
          onChangeImageUpload={(url) => setImageUrl(url)}
          isFieldDataChanged={(isDataChanged) =>
            setIsFieldDataChanged(isDataChanged)
          }
        />
        <UserPersonalDetailsForm defaultDisable />
        <EmergencyForm />
      </AccountDetailLayout>
      <Div mt="40px">
        <FooterComponent
          rightChildren={
            <PrimaryButton ml={16} htmlType="submit">
              Save
            </PrimaryButton>
          }
        />
      </Div>
    </CustomForm>
  );
};
export default Profile;
