import React, { FC, ReactNode, useEffect, useState } from 'react';
import {
  Badge,
  BadgeProps,
  Col,
  Collapse,
  List,
  message,
  Row,
  Spin,
  Tooltip,
} from 'antd';
import {
  B,
  Div,
  RadioButton,
  RadioGroup,
  SecondaryButton,
} from '../../../../components';
import NotificationContract from './NotificationContract';
import NotificationLeave from './NotificationLeave';
import NotificationReminder from './NotificationReminder';
import filterAllActiveSvg from '../../../../assets/Icons/note-icons/filter-all-active.svg';
import filterAllSvg from '../../../../assets/Icons/note-icons/filter-all.svg';
import filterLeaveSvg from '../../../../assets/Icons/note-icons/filter-leave.svg';
import filterLeaveActiveSvg from '../../../../assets/Icons/note-icons/filter-leave-active.svg';
import filterReminderActiveSvg from '../../../../assets/Icons/note-icons/filter-reminder-active.svg';
import filterReminderSvg from '../../../../assets/Icons/note-icons/filter-reminder.svg';
import noNotificationYetSvg from '../../../../assets/Icons/note-icons/no-notification-yet.svg';
import {
  Reminders,
  ReminderTypeEnum,
} from '../../../../types/notification.types';
import { EmptyContainer } from '../EmptyContainer';
import {
  useChangeNotificationStatus,
  useGetReminderList,
} from '../../../../api/reminderHooks';
import styled from '@emotion/styled';
import { useEditReview } from '../../../../api/leaveHooks';
import { contractEnabledOrg } from '../../../../configs';
import { validateOrganizationIDs } from '../../../../util/validateOrganizationIDs.util';
import { groupBy } from 'lodash';
import moment from 'moment';
import theme from '../../../../theme';
import { UpcomingTypesEnum } from '../../../../types/organization.types';
import NotificationCelebrations from './NotificationCelebrations';
import { sortBy } from 'lodash';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducer';
import { getApiError } from '../../../../util/getApiError';
import { NavIconRight, NavIconLeft } from '../../../../assets';
import CountBadge from '../../../../components/badges/CountBadge';

const { Panel } = Collapse;
enum FilterTypes {
  Leaves = 'Leaves',
  All = 'All',
  Reminders = 'Reminders',
  Contracts = 'Contracts',
}

interface Props {
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
}

const CollapseDiv = styled.div<{ isCollapsed: boolean }>`
  position: absolute;
  margin: 0 -13px;
  padding: ${({ isCollapsed }) =>
    isCollapsed ? ' 40px 0px 0px 0px' : ' 40px 0px 0px 0px'};

  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1199px) {
    display: none;
  }
`;

const RemindersListComp = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.5px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  .ant-card-body {
    padding: 0;
  }
  .demo-loadmore-list {
    cursor: pointer;
  }
`;

const LoadMore = styled.div`
  text-align: center;
  margin-top: 12px;
  height: 32px;
  line-height: 32px;
  margin-bottom: 24px;
`;
const ReminderRow = styled(Row)`
  .ant-radio-group {
    padding: 6px;
  }
  .ant-radio-button-wrapper {
    padding: 0 4px !important;
    border: none !important;
  }
  .ant-radio-button-wrapper-checked {
    border: none !important;
  }
  .ant-radio-button.ant-radio-button-checked {
    background: none !important;
    box-shadow: none !important;
  }

  .ant-radio-button.ant-radio-button-checked:focus-within {
    background: none !important;
    box-shadow: none !important;
  }
  @media screen and (max-width: 768px) {
    padding: 0px;
    border-bottom: none;
  }
  @media screen and (max-width: 760px) {
    .ant-radio-group {
      width: 100%;
    }
    .ant-radio-button-wrapper {
      width: 33%;
    }
  }
`;

const IconDiv = styled.div<{ filterType: FilterTypes; isActive: boolean }>`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px 2px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: ${(prop) =>
    prop.filterType === FilterTypes.All && prop.isActive
      ? `${theme.blue400}`
      : prop.filterType === FilterTypes.Leaves && prop.isActive
      ? `${theme.green700}`
      : prop.filterType === FilterTypes.Reminders && prop.isActive
      ? `${theme.orange400}`
      : `${theme.white}`};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
`;

const CollapseSection = styled(Collapse)`
  border-radius: 0px !important;
  border: none !important;
  :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse
    > .ant-collapse-item:last-child,
  :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse
    > .ant-collapse-item:last-child
    > .ant-collapse-header {
    border-radius: 0px !important;
  }
  :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header {
    padding: 4px 16px !important;
  }
  :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse
    .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 0px !important;
  }
`;

const NotificationsListDiv = styled.div<{ isCollapsed: boolean }>`
  overflow: auto;
  height: ${({ isCollapsed }) => (isCollapsed ? '585px' : '620px')};
`;

const NoNotificationSection = styled.div`
  text-align: center;
  padding: 30px 0px;
`;

interface DateRowProps {
  date: string;
  countBadge: number;
  children: ReactNode;
  isLastEntry: boolean;
}

interface NotesRowProps {
  data: Reminders[];
}

const ReminderList: FC<Props> = ({ isCollapsed, setIsCollapsed }) => {
  const [filter, setFilter] = useState<FilterTypes>(FilterTypes.All);
  const [filterReminders, setFilterReminders] = useState<Reminders[]>([]);
  const notificationData = useSelector(
    (state: RootState) => state.dashboard.notificationData,
  );

  useEffect(() => {
    if (notificationData) {
      const targetElement = document.getElementById(`${notificationData.id}`);

      targetElement?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [notificationData]);

  const [pageCount, setPageCount] = useState<number>(1);

  const validReminders = filterReminders.filter((i) => i.effectiveDate);
  const {
    isLoading: loadingReminder,
    error: reminderErrors,
    data: reminder = [],
    mutate: getReminders,
  } = useGetReminderList();

  const {
    isLoading: editReviewLoading,
    error: editReviewError,
    data: editReview,
    mutate: putEditReview,
  } = useEditReview();

  const {
    isLoading: changeNotificationStatusLoading,
    error: changeNotificationStatusError,
    data: changeNotificationStatus,
    mutate: putChangeNotificationStatus,
  } = useChangeNotificationStatus();

  const today = new Date().toISOString(); // Get today's date in ISO format

  // Check if today's date is already in the list
  const todayExists = validReminders.some(
    (item) =>
      moment(item.effectiveDate).format('YYYY-MM-DD') ===
      moment().format('YYYY-MM-DD'),
  );

  // If today's date is not in the list, add an object for today
  if (!todayExists) {
    validReminders.push({
      type: UpcomingTypesEnum.No_Any_Notifications,
      payload: {},
      effectiveDate: today,
    });
  }

  const sortedData = sortBy(validReminders, 'effectiveDate').reverse();

  useEffect(() => {
    getReminders({ status: 'PENDING', page: pageCount });
  }, []);

  // refetch............................
  useEffect(() => {
    if (editReview && !editReviewError) {
      message.success('Submitted successfully!');
      getReminders({ status: 'PENDING', page: pageCount });
    }
    if (changeNotificationStatus && !changeNotificationStatusError) {
      message.success('Submitted successfully!');
      getReminders({ status: 'PENDING', page: pageCount });
    }
  }, [editReview, changeNotificationStatus]);
  //error...............................................
  useEffect(() => {
    editReviewError && message.error(getApiError(editReviewError));
    changeNotificationStatusError &&
      message.error(getApiError(changeNotificationStatusError));
  }, [editReviewError, changeNotificationStatusError]);

  useEffect(() => {
    if (reminder.length > 0) {
      const list: Reminders[] = [];

      reminder.forEach((reminder) => {
        const reminderList: Reminders = {
          ...reminder,
        };
        if (filter === FilterTypes.All) {
          list.push(reminderList);
        } else if (
          filter === FilterTypes.Leaves &&
          reminder.type === ReminderTypeEnum.LEAVE
        ) {
          list.push(reminderList);
        } else if (
          filter === FilterTypes.Contracts &&
          reminder.type === ReminderTypeEnum.CONTRACT
        ) {
          list.push(reminderList);
        } else if (
          filter === FilterTypes.Reminders &&
          reminder.type === ReminderTypeEnum.NOTIFICATION
        ) {
          list.push(reminderList);
        }
      });
      setFilterReminders(list);
    }
  }, [reminder, filter]);

  const orderedReminders = groupBy(sortedData, function (b) {
    return moment(b.effectiveDate).format('YYYY-MM-DD');
  });

  const entries = Object.entries(orderedReminders);
  const lastEntry = entries[entries.length - 1];

  const DateRow: FC<DateRowProps> = ({
    date,
    countBadge,
    children,
    isLastEntry,
  }) => {
    return (
      <CollapseSection
        expandIconPosition="right"
        defaultActiveKey={[
          moment().format('YYYY-MM-DD'),
          moment().add(1, 'days').format('YYYY-MM-DD'),
          moment(notificationData.date).format('YYYY-MM-DD'),
        ]}>
        <Panel
          style={{
            borderBottom: isLastEntry ? 'none' : `1px solid ${theme.gray300}`,
          }}
          header={
            <B type="b-small" color={theme.gray600}>
              {moment(date).format('MMM Do dddd')}
            </B>
          }
          key={date}
          extra={
            date == moment().format('YYYY-MM-DD') ? (
              <B type="b-small" color={theme.gray600}>
                {todayExists && (
                  <CountBadge
                    style={{ marginRight: '7px' }}
                    count={countBadge}
                    date={date}></CountBadge>
                )}
                Today
              </B>
            ) : date == moment().add(1, 'days').format('YYYY-MM-DD') ? (
              <B type="b-small" color={theme.gray600}>
                <CountBadge
                  style={{ marginRight: '7px' }}
                  count={countBadge}
                  date={date}></CountBadge>{' '}
                Tomorrow
              </B>
            ) : (
              <CountBadge count={countBadge} date={date}></CountBadge>
            )
          }
          showArrow={
            date == moment().format('YYYY-MM-DD') ||
            date == moment().add(1, 'days').format('YYYY-MM-DD')
              ? false
              : true
          }>
          {children}
        </Panel>
      </CollapseSection>
    );
  };

  const NotesDataRow: FC<NotesRowProps> = ({ data }) => {
    return (
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        // loadMore={loadMore}
        dataSource={data}
        renderItem={(item: Reminders, index: number) => {
          if (item.type === ReminderTypeEnum.CONTRACT) {
            return (
              <Row key={index}>
                <NotificationContract item={item} isCollapsed={isCollapsed} />
              </Row>
            );
          } else if (item.type === ReminderTypeEnum.LEAVE) {
            return (
              <Row key={index} id={`${item.payload.id}`}>
                <NotificationLeave
                  item={item}
                  isCollapsed={isCollapsed}
                  onDataChange={(value) => putEditReview(value)}
                  highlightId={
                    item.payload.id === notificationData.id
                      ? notificationData.id
                      : 0
                  }
                />
              </Row>
            );
          } else if (
            item.type === UpcomingTypesEnum.BirthDay ||
            item.type === UpcomingTypesEnum.Anniversary
          ) {
            return (
              <NotificationCelebrations isCollapsed={isCollapsed} item={item} />
            );
          } else if (item.type === UpcomingTypesEnum.No_Any_Notifications) {
            return (
              <NoNotificationSection>
                <img src={noNotificationYetSvg} />
                <B type="b-small" pt="6px">
                  No Notifications Yet
                </B>
              </NoNotificationSection>
            );
          } else {
            return (
              <Row key={index} id={`${item.payload.id}`}>
                <NotificationReminder
                  item={item}
                  isCollapsed={isCollapsed}
                  onDataChange={(value) => putChangeNotificationStatus(value)}
                  highlightId={
                    item.payload.id === notificationData.id
                      ? notificationData.id
                      : 0
                  }
                />
              </Row>
            );
          }
        }}
      />
    );
  };

  return (
    <>
      {reminderErrors && message.error(getApiError(reminderErrors))}
      <RemindersListComp>
        <CollapseDiv
          isCollapsed={isCollapsed}
          onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? <img src={NavIconLeft} /> : <img src={NavIconRight} />}
        </CollapseDiv>
        <Div
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={16}>
          <Div>
            <B type="b-large-semibold">Notifications</B>
          </Div>

          <Div>
            <ReminderRow gutter={[22, 0]}>
              <>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  style={{ display: isCollapsed ? 'none' : '' }}>
                  <RadioGroup defaultValue="all">
                    <RadioButton
                      value="all"
                      onClick={() => {
                        setFilter(FilterTypes.All);
                      }}>
                      <IconDiv
                        filterType={filter}
                        isActive={filter === FilterTypes.All ? true : false}>
                        <Tooltip placement="top" title="All">
                          {filter === FilterTypes.All ? (
                            <img
                              src={filterAllActiveSvg}
                              width="16px"
                              height="16px"
                            />
                          ) : (
                            <img
                              src={filterAllSvg}
                              width="16px"
                              height="16px"
                            />
                          )}
                        </Tooltip>
                      </IconDiv>
                    </RadioButton>
                    <RadioButton
                      value="allLeave"
                      onClick={() => {
                        setFilter(FilterTypes.Leaves);
                      }}>
                      <IconDiv
                        filterType={filter}
                        isActive={filter === FilterTypes.Leaves ? true : false}>
                        <Tooltip placement="top" title="Leaves">
                          {filter === FilterTypes.Leaves ? (
                            <img
                              src={filterLeaveActiveSvg}
                              width="16px"
                              height="16px"
                            />
                          ) : (
                            <img
                              src={filterLeaveSvg}
                              width="16px"
                              height="16px"
                            />
                          )}
                        </Tooltip>
                      </IconDiv>
                    </RadioButton>
                    <RadioButton
                      value="reminders"
                      onClick={() => {
                        setFilter(FilterTypes.Reminders);
                      }}>
                      <IconDiv
                        filterType={filter}
                        isActive={
                          filter === FilterTypes.Reminders ? true : false
                        }>
                        <Tooltip placement="top" title="Reminders">
                          {filter === FilterTypes.Reminders ? (
                            <img
                              src={filterReminderActiveSvg}
                              width="16px"
                              height="16px"
                            />
                          ) : (
                            <img
                              src={filterReminderSvg}
                              width="16px"
                              height="16px"
                            />
                          )}
                        </Tooltip>
                      </IconDiv>
                    </RadioButton>
                    {validateOrganizationIDs(contractEnabledOrg) ? (
                      <RadioButton
                        value="contracts"
                        onClick={() => {
                          setFilter(FilterTypes.Contracts);
                        }}>
                        Contracts
                      </RadioButton>
                    ) : null}
                  </RadioGroup>
                </Col>
              </>
            </ReminderRow>
          </Div>
        </Div>
        {sortedData.length === 0 ? (
          <EmptyContainer
            description="You have no todo list activities!"
            subDescription="Currently, There’re no hr activities to list here until you’r employees start to use this."
          />
        ) : (
          <NotificationsListDiv isCollapsed={isCollapsed}>
            <Spin
              spinning={
                loadingReminder ||
                editReviewLoading ||
                changeNotificationStatusLoading
              }>
              <Div borderTop={`1px solid ${theme.gray300}`} borderBottom="none">
                {Object.entries(orderedReminders).map(([key, value]) => {
                  const rows = (
                    <div>
                      <NotesDataRow data={value} />
                    </div>
                  );
                  const isLastEntry = lastEntry[0] == key;

                  return (
                    <DateRow
                      date={key}
                      countBadge={value.length}
                      isLastEntry={isLastEntry}>
                      {rows}
                    </DateRow>
                  );
                })}
              </Div>
            </Spin>
          </NotificationsListDiv>
        )}
      </RemindersListComp>
    </>
  );
};
export default ReminderList;
