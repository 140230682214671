import React, { FC } from 'react';
import { Dropdown, Menu } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';
import { useDispatch } from 'react-redux';
import {
  B,
  ConfirmationModal,
  DeleteModal,
  EmptyTextContainer,
  Image,
  TableComp,
} from '../../../components';
import { changeStatus } from '../../../store/actions';
import {
  AllContractsI,
  ContractStatusEnum,
  UserType,
} from '../../../types/contract.types';
import { useWindowSize } from '../../../util/screenSize';
import { downloadHandler } from '../../../util/downloadPdf.util';
import { useNavigate } from 'react-router-dom';
import ThreeDotPopover from '../shared/ThreedotPopover';
import moment from 'moment';
import { tableSorter } from '../../../util/tableSorter';
import styled from '@emotion/styled';
import { DispatchType } from '../../../store';
import { MoreSVG } from '../../../assets';
import theme from '../../../theme';
import { statusTag } from './StatusTag';
import { useContractDelete } from '../../../api/contractHooks';

const MenuItem = styled(Menu.Item)`
  border-bottom: 1px solid ${theme.gray300};
`;
const Card = styled.div`
  border: 1px solid ${theme.gray300};
  border-radius: 4px;
  cursor: pointer;
  .ant-table-cell {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
`;

const DropdownMenu = styled(Menu)`
  width: 163px;
  padding: 0px;
  border: 0.5px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .ant-dropdown-menu-item {
    padding: 12px 0px 12px 16px;
  }
`;
interface Props {
  tableScrollY?: number;
  contracts: AllContractsI[];
  loading: boolean;
  deleteContract: (id: string) => void;
}
const ContractTable: FC<Props> = ({
  tableScrollY,
  contracts,
  loading = false,
  deleteContract,
}) => {
  const { isTablet, isDesktop, isDesktopLarge } = useWindowSize();
  const dispatch: DispatchType = useDispatch();
  const navigate = useNavigate();

  const changeCurrentStatus = (
    documentId: string,
    status: ContractStatusEnum.ARCHIVED,
  ) => {
    const value = {
      documentId: String(documentId),
      status: status,
    };
    dispatch(changeStatus(value));
  };

  const columns: ColumnProps<AllContractsI>[] = [
    {
      title: 'Contract',
      dataIndex: 'contract',
      render: (_value: string, data) => (
        <>
          {isDesktopLarge ? (
            <ThreeDotPopover text={data.name} maxLength={31} fontWeight={600} />
          ) : (
            <ThreeDotPopover text={data.name} maxLength={28} fontWeight={600} />
          )}
        </>
      ),
      sorter: (a, b) => tableSorter.defaultSort(a.name, b.name),
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      sorter: (a, b) =>
        tableSorter.defaultSort(
          a.createdByUser?.fullName,
          b.createdByUser?.fullName,
        ),
      render: (_value: string, data) => (
        <>
          {data.createdByUser ? (
            <B type="b-small" color={theme.gray600}>
              {data.createdByUser?.fullName}
            </B>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => tableSorter.defaultSort(a.status, b.status),
      render: (_value: string, data) => <>{statusTag(data.status, data)}</>,
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedDate',
      sorter: (a, b) =>
        tableSorter.dateSort(a.updatedAt.toString(), b.updatedAt.toString()),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (_value: string, data) => {
        const senderStatus = data.contractStatus.find(
          (item) => item.userType == UserType.SENDER,
        );
        const assigneeStatus = data.contractStatus.find(
          (item) => item.userType == UserType.ASSIGNEE,
        );
        const updatedDate = assigneeStatus
          ? assigneeStatus.updatedAt
          : senderStatus
          ? senderStatus.updatedAt
          : data.updatedAt;
        return (
          <B type="b-small" color={theme.gray600}>
            {moment(updatedDate).format('DD.MM.YYYY')}{' '}
            {moment(updatedDate, ['h:mm A']).format('HH:mm A')}{' '}
          </B>
        );
      },
    },

    {
      title: '',
      dataIndex: '',
      width: 100,
      render: (_value, data) => (
        <>
          <div>
            {data.isSend ||
            data.status === ContractStatusEnum.ARCHIVED ||
            ContractStatusEnum.EXPIRED ? (
              <B
                type="b-default-semibold"
                onClick={() => navigate('/contract/view/' + data.id)}
                color={theme.blue500}>
                View
              </B>
            ) : (
              <B
                type="b-default-semibold"
                onClick={() => navigate('/contract/edit/' + data.id)}
                color={theme.blue500}
                mr="7px">
                Edit
              </B>
            )}
          </div>
        </>
      ),
    },
    {
      title: '',
      dataIndex: '',
      width: 80,
      render: (_value, data) => (
        <>
          <div>
            <Dropdown
              overlay={
                <DropdownMenu>
                  <MenuItem onClick={() => downloadHandler(data.id, data.name)}>
                    <B type="b-default" my="10px">
                      Download a copy
                    </B>
                  </MenuItem>
                  {data.status == ContractStatusEnum.SENT ? (
                    <Menu.Item>
                      <ConfirmationModal
                        title={
                          <B type="b-large-semibold">Confirmation Alert</B>
                        }
                        content={
                          <B type="b-large">
                            Are you sure you want to archive this contract?
                          </B>
                        }
                        okText="Yes, Archive"
                        cancelText="No"
                        onConfirm={async () =>
                          changeCurrentStatus(
                            data.id,
                            ContractStatusEnum.ARCHIVED,
                          )
                        }
                        trigger={
                          <B type="b-default" my="10px">
                            Archive
                          </B>
                        }
                      />
                    </Menu.Item>
                  ) : null}

                  {data.status == ContractStatusEnum.DRAFT && (
                    <Menu.Item>
                      <DeleteModal
                        title="Delete"
                        buttonLabel="Delete"
                        content="Are you sure you want to delete ?"
                        onClickCancel={() => {
                          deleteContract(data.id);
                        }}
                        openModal={
                          <B py="10px" type="b-default">
                            Delete
                          </B>
                        }></DeleteModal>
                    </Menu.Item>
                  )}
                </DropdownMenu>
              }>
              <a>
                <Image cursor="pointer" paddingLeft="12px" src={MoreSVG} />
              </a>
            </Dropdown>
          </div>
        </>
      ),
    },
  ];

  const locale = {
    emptyText: (
      <EmptyTextContainer description="You don't have any contract yet" />
    ),
  };

  return (
    <Card>
      <TableComp
        dataSource={contracts?.length ? contracts : []}
        columns={columns}
        locale={locale}
        pagination={false}
        loading={loading}
        scroll={{
          y: tableScrollY || window.innerHeight - 250,
          x: isTablet ? 1250 : isDesktop ? 800 : 1075,
        }}
      />
    </Card>
  );
};
export default ContractTable;
