export interface ContractI {
  file: File | null;
  type: ContractTypeEnum;
  fileName: string | null;
  title: string;
  isSaveTemplate: boolean;
  templateId: string;
  assignedTo?: string | undefined;
  firstSignatories?: string;
  secondSignatories?: string;
}

export interface ContractHookReturnI {
  documentId: string;
  documentLink: string;
}

export interface ContractHookParamI {
  file: string;
  firstSignatories: string;
  secondSignatories: string;
  fileName: string;
  isSaveTemplate: string;
  templateId: string;
  title: string;
}

export interface TemplateI {
  file: File | null;
  title: string;
  comment: string | null;
}

export enum ContractTypeEnum {
  SYSTEM_USER_CONTRACT = 'SYSTEM_USER_CONTRACT',
  NON_SYSTEM_USER_CONTRACT = 'NON_SYSTEM_USER_CONTRACT',
}

export enum PlaceholderTypeEnum {
  signature = 'SIGNATURE',
  textInput = 'TEXT',
  date = 'DATE',
}

export enum ContractStatusEnum {
  SENT = 'SENT',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  COMPLETED = 'COMPLETED',
  NOT_COMPLETED = 'NOTE_COMPLETED',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  SIGNED = 'SIGNED',
}

export enum ContractErrorEnum {
  DUPLICATE_EMAIL = 'DUPLICATE_EMAIL',
  INCOMPLETE_USER_INFO = 'INCOMPLETE_USER_INFO',
  INCORRECT_USER_INFO = 'INCORRECT_USER_INFO',
}

export enum PlaceholderStatus {
  NOT_COMPLETED = 'NOT_COMPLETED',
  COMPLETED = 'COMPLETED',
}
export enum UserType {
  SENDER = 'SENDER',
  ASSIGNEE = 'ASSIGNEE',
  FIRST_SIGNATORY = 'FIRST_SIGNATORY',
  SECOND_SIGNATORY = 'SECOND_SIGNATORY',
}

export enum ContractReminderEnum {
  TO_SIGN = 'TO_SIGN',
  SIGNED = 'SIGNED',
}
export interface AllContractsI {
  assignee: ContractUserI;
  sender: ContractUserI;
  createdByUser: ContractUserI;
  createdBy: number;
  id: string;
  isSend: boolean;
  name: string;
  status: ContractStatusEnum;
  documentLink: string;
  externalAssigneeName: string | null;
  type: ContractTypeEnum;
  contractStatus: ContractStatusI[];
  updatedAt: Date;
  firstSignatories: SignatoryI[];
  secondSignatories: SignatoryI[];
}

export interface ContractUserI {
  id: string;
  fullName: string;
}

export interface AssignedPlaceholderDataI {
  id: string; // UUID
  assignedUserId: string | number | null | undefined;
  pageNumber: number;
  type: PlaceholderTypeEnum;

  // Positions
  width: number;
  hight: number; // TODO: @dasuni tell API guys to fix this
  xAxis: number;
  yAxis: number;

  // meta
  isSaved: boolean;
  required: boolean;
  status: PlaceholderStatus;

  value?: string;
  signature?: string;
}

export type NewPlaceholderDataI = Omit<AssignedPlaceholderDataI, 'id'>;

export interface PlaceholderLocationI {
  pageIndex: number;
  x: number;
  y: number;
  type: PlaceholderTypeEnum;
}
export interface AssignedEditPlaceholderDataI extends AssignedPlaceholderDataI {
  isNew: boolean;
}

export interface PlaceholderI {
  contractId: number;
  placeHolders: NewPlaceholderDataI[] | [];
}

export interface PlaceholderEditI {
  contractId: number;
  placeHolders: AssignedPlaceholderDataI[] | [];
}

export interface GetContractI {
  assignee: ContractUserI;
  assigneeId: string;
  senderId: number;
  awsKey: string;
  createdAt: string;
  createdBy: string;
  createdById: ContractUserI;
  documentLink: string;
  id: string;
  isSend: boolean;
  type: ContractTypeEnum;
  firstSignatories: SignatoryI[];
  secondSignatories: SignatoryI[];
  name: string;
  organizationId: string;
  originalDocumentLink: string;
  placeHolders: null;
  status: ContractStatusEnum;
  contractStatus: ContractStatusI[];
  updatedAt: string;
}

export interface UploadContractI {
  documentId: string;
  documentLink: string;
}

export interface ContractError {
  statusCode: number;
  message: string;
  error: string;
}
export interface ChangeStatusI {
  documentId: string;
  status: ContractStatusEnum.ARCHIVED;
}

export interface DocumentPlaceholderI {
  documentLink: string;
  placeHolders: AssignedPlaceholderDataI[];
}

export interface ContractViewPlaceholdersI {
  placeHolderId: Array<string>;
  signType?: NewSignatureEnum.EXISTING | NewSignatureEnum.NEW;
  value?: string;
  type: PlaceholderTypeEnum;
}

export interface SaveContractI {
  signature?: SaveSignatureI;
  placeHolders?: ContractViewPlaceholdersI[];
}

export interface SaveSignatureI {
  documentId?: string;
  file: File | Blob | null;
  placeHolders: ContractViewPlaceholdersI[];
}
export interface SaveNoneExistingSignatureI {
  token: string;
  file: File | Blob | null;
  placeHolders: ContractViewPlaceholdersI[];
}
export enum NewSignatureEnum {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
}
export interface SendContractI {
  contractId: string;
  subject: string;
  message: string;
}
export interface MySignatureI {
  signatureLink: string | null;
}

export interface AssignableUsersI {
  id: string;
  name: string;
  fullName: string;
  email?: string;
  isSender?: boolean;
  isAssignee?: boolean;
  isFirstSignatory: boolean;
  isSecondSignatory: boolean;
}
export interface MyContractI {
  id: number;
  status: ContractStatusEnum;
  name: string;
  contractStatus: ContractStatusI[];
}

export interface ContractStatusI {
  id: number;
  userId: number;
  userType: UserType;
  isSend: boolean;
  isSigned: boolean;
  externalUserId: string;
  updatedAt: Date;
  firstSignatoryId: string;
  secondSignatoryId: string;
}

export interface SignatoryI {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  type: ContractType;
  signatoryType?: SignatoryType;
}

export enum ContractType {
  SYSTEM_USER_CONTRACT = 'SYSTEM_USER_CONTRACT',
  NON_SYSTEM_USER_CONTRACT = 'NON_SYSTEM_USER_CONTRACT',
}

export enum SignatoryType {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
}

export interface TemplatesI {
  id: string | number;
  title: string;
  comment: string | null;
  url?: string;
}

export interface UploadTemplateI {
  file: File | null;
  title: string | null;
  comment: string;
}

export interface TemplateByIdI {
  id: string | number;
  title: string;
  comment: string | null;
  documentLink: string;
  organizationId: number;
}

export enum SignatoryEnum {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
}
