import { OrganizationsDataByOwnerI } from '../../types/superAdmin.types';

export const calculateSummary = (
  organizationsData: OrganizationsDataByOwnerI[],
) => {
  if (!organizationsData.length) {
    return {
      payrollEnabledUsers: 0,
      loginAllowedUsers: 0,
    };
  }
  let payrollEnabledUsers = 0;
  let loginAllowedUsers = 0;
  organizationsData.forEach((selectedOrganizations) => {
    payrollEnabledUsers += Number(selectedOrganizations.payrollEnabledUsers);
    loginAllowedUsers += Number(selectedOrganizations.loginAllowedUsers);
  });
  return {
    payrollEnabledUsers: payrollEnabledUsers,
    loginAllowedUsers: loginAllowedUsers,
  };
};
