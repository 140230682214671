import React, { FC, useEffect, useState } from 'react';
import { Form, message } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';

import {
  B,
  DatePickerV2,
  InputV2,
  LabeledInputV2,
  ModalComponent,
  TableComp,
} from '../../../components';

import { LoanGenerateBulkI, LoanI } from '../../../types/loan.types';
import { css } from '@emotion/react';
import theme from '../../../theme';
import styled from '@emotion/styled';
import { PriceTag } from '../../expenses/shared/PriceTag';
import { useGenerateBulkLoan } from '../../../api/loanHooks';
import { useSelector } from 'react-redux';
import {
  BankDetailsI,
  BANK_EXPORT_TYPES,
  BOC_BANK_CONFIG,
  COM_BANK_CONFIG,
  HNB_BANK_CONFIG,
} from '../../../configs';
import { RootState } from '../../../store/reducer';
import { ComBankTypeEnum, ComBankParams } from '../../../types';
import { getApiError } from '../../../util/getApiError';
import { sum } from 'lodash';

interface SelectedLoans {
  id: number;
  userId: number;
  amount: number;
  name: string;
  loans?: number;
}

interface PrepLoan {
  [index: string]: SelectedLoans;
}
interface props {
  visible: boolean;
  handleCancel: () => void;
  selected?: number[];
  clearSelected: () => void;
  dataSet?: LoanI[];
}

const tableStyle = css`
  .ant-table-thead > tr > th {
    padding: 10px 16px !important;
  }
  .ant-table-tbody > tr > td {
    padding-left: 0px !important;
  }
  .ant-table-body {
    background-color: ${theme.white} !important;
  }
  .ant-table-body {
    padding: 0px 16px 0px 16px !important;
  }
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  justify-content: space-between;
`;

const SelectLayout = styled.div`
  margin-top: 24px;
  .ant-select {
    width: 350px;
  }
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const GenerateBankFileModal: FC<props> = ({
  visible,
  handleCancel,
  selected = [],
  clearSelected,
  dataSet = [],
}) => {
  const [bank, setBank] = useState<BankDetailsI | undefined>(undefined);
  const bankDetails = useSelector(
    (state: RootState) => state.organization.organizationData?.bankDetails,
  );
  const [form] = Form.useForm();

  const {
    isSuccess: successGenerateBulkLoan,
    isLoading: loadingGenerateBulkLoan,
    error: errorGenerateBulkLoan,
    data: dataGenerateBulkLoan,
    mutate: generateBulkLoan,
  } = useGenerateBulkLoan();

  useEffect(() => {
    if (successGenerateBulkLoan) {
      clearSelected();
    }
  }, [successGenerateBulkLoan]);

  // selected record list
  const selectedLoans: SelectedLoans[] = dataSet
    .filter((i) => selected.includes(i.id))
    .map((i) => ({
      id: i.id,
      userId: i.userId,
      amount: i.balanceAmount,
      name: i.user.fullName,
    }));

  useEffect(() => {
    if (bankDetails && visible) {
      const getBank = BANK_EXPORT_TYPES.find(
        (i) => i.code === Number(bankDetails.bankCode),
      );
      if (getBank) {
        form.setFieldsValue({
          bankName: getBank.label,
        });
        setBank(getBank);
      }
    }
  }, [bankDetails, visible]);

  const onFinish = (values: LoanGenerateBulkI) => {
    if (bank) {
      const type =
        bankDetails?.bankCode === COM_BANK_CONFIG.code
          ? bankDetails?.comBankType === ComBankTypeEnum.DIGITAL
            ? ComBankParams.CMBD
            : ComBankParams.CMBO
          : bank.value;
      const dataSet: LoanGenerateBulkI = {
        type,
        loanIds: selectedLoans.map((i) => i.id),
        remark: values.remark,
        total: sum(selectedLoans.map((i) => i.amount)),
      };
      if (values.dateOfCrediting) {
        dataSet.dateOfCrediting = values.dateOfCrediting;
      }
      if (values.valueDate) {
        dataSet.valueDate = values.valueDate;
      }
      generateBulkLoan(dataSet);
    } else {
      message.error('Please add your bank details in settings');
    }
  };

  // success handling
  useEffect(() => {
    if (dataGenerateBulkLoan && !errorGenerateBulkLoan) {
      const link = document.createElement('a');
      link.href = dataGenerateBulkLoan;
      link.click();
      message.success('Generated Successfully!');
      // reload data
      clearSelected();
      handleCancel();
    }
  }, [dataGenerateBulkLoan]);

  // error handling
  useEffect(() => {
    errorGenerateBulkLoan && message.error(getApiError(errorGenerateBulkLoan));
  }, [errorGenerateBulkLoan]);

  const sumUserAmount = () => {
    const prepUser: PrepLoan = {};
    selectedLoans.forEach((d) => {
      if (prepUser.hasOwnProperty(d.name)) {
        prepUser[d.name] = {
          ...prepUser[d.name],
          amount: prepUser[d.name].amount + d.amount,
          loans: Number(prepUser[d.name].loans) + 1,
        };
      } else {
        prepUser[d.name] = {
          id: d.id,
          userId: d.userId,
          name: d.name,
          amount: d.amount,
          loans: 1,
        };
      }
    });
    return Object.values(prepUser);
  };

  const columns: ColumnProps<SelectedLoans>[] = [
    {
      key: 1,
      title: 'Employee Name',
      dataIndex: 'name',
      render: (value: string) => <B type="b-default">{value} </B>,
    },
    {
      key: 2,
      title: 'Loan Amount(LKR)',
      dataIndex: 'amount',
      align: 'right',
      render: (value: string) => <B type="b-default">{value} </B>,
    },
  ];
  return (
    <ModalComponent
      form={form}
      onCancel={handleCancel}
      visible={visible}
      loading={loadingGenerateBulkLoan}
      title={<B type="b-large-semibold"> Generate Bulk File</B>}
      submitText="Generate"
      centered
      width={540}>
      <Form onFinish={onFinish} form={form}>
        <B type="b-default" Pb="16px">
          Are you sure you want to generate bulk file for these loans?
        </B>
        <div>
          <TableComp
            withBorders={true}
            dataSource={sumUserAmount() || []}
            columns={columns}
            pagination={false}
            scroll={{
              y: 200,
            }}
            css={tableStyle}
          />
        </div>
        <TotalContainer>
          <B type="b-default" color={theme.gray600}>
            Total
          </B>
          <PriceTag
            amount={sum(selectedLoans.map((i) => i.amount))}
            labelType="b-default"
            prizeType="b-default-semibold"
          />
        </TotalContainer>
        <SelectLayout>
          <LabeledInputV2 label="Bank Name" flexDirection="row">
            <Form.Item name="bankName">
              <InputV2
                flexDirection="row"
                size="small"
                placeholder="No bank selected"
              />
            </Form.Item>
          </LabeledInputV2>
          {bank?.code === HNB_BANK_CONFIG.code && (
            <LabeledInputV2 label="Date of Crediting*" flexDirection="row">
              <Form.Item
                name="dateCredit"
                rules={[
                  {
                    required: true,
                    message: 'Please select date of crediting!',
                  },
                ]}>
                <DatePickerV2
                  flexDirection="row"
                  placeholder="Select Date"
                  inputReadOnly={true}
                />
              </Form.Item>
            </LabeledInputV2>
          )}
          {(bank?.code === HNB_BANK_CONFIG.code ||
            bank?.code === BOC_BANK_CONFIG.code) && (
            <LabeledInputV2 label="Value Date*" flexDirection="row">
              <Form.Item
                name="valueDate"
                rules={[
                  {
                    required: true,
                    message: 'Please select date of crediting!',
                  },
                ]}>
                <DatePickerV2
                  flexDirection="row"
                  placeholder="Select Date"
                  inputReadOnly={true}
                />
              </Form.Item>
            </LabeledInputV2>
          )}
          <LabeledInputV2 label="Remark*" flexDirection="row">
            <FormItem
              name="remark"
              rules={[{ required: true, message: 'Please enter remark!' }]}>
              <InputV2
                flexDirection="row"
                size="small"
                placeholder="Enter Remark"
              />
            </FormItem>
          </LabeledInputV2>
        </SelectLayout>
      </Form>
    </ModalComponent>
  );
};

export default GenerateBankFileModal;
