import React, { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import { B, DeleteModal, Image } from '../../../../components';
import { Col, Dropdown, Menu, message, Row } from 'antd';
import {
  NotificationItemI,
  NotificationStatusEnum,
} from '../../../../types/notification.types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { NameTag } from '../../../../components/Tags/NameTag';
import { useChangeNotificationStatus } from '../../../../api/reminderHooks';
import { getPeopleNotes } from '../../../../store/actions';
import { isAllowed } from '../../../../util/permissionUtil';
import { Permission } from '../../../../types';
import { getApiError } from '../../../../util/getApiError';
import { DispatchType } from '../../../../store';
import { MoreSVG } from '../../../../assets';
import styled from '@emotion/styled';

interface Props {
  item: NotificationItemI;
  onClickEdit: () => void;
  onRemove: () => void;
  addAndEditOnly: boolean;
}

type ParamsI = {
  employeeKey: string;
};

const NoteItemCard = styled(Row)`
  border-bottom: 1px solid #eaeaec;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 18px 0px 8px;
  display: flex;
  flex-direction: row;
`;
const Column = styled(Col)`
  display: flex;
  flex-direction: row;
`;

const NoteItem: FC<Props> = ({
  onClickEdit,
  onRemove,
  item,
  addAndEditOnly,
}) => {
  const {
    error: changeNotificationStatusError,
    data: changeNotificationStatus,
    mutate: putChangeNotificationStatus,
  } = useChangeNotificationStatus();

  const dispatch: DispatchType = useDispatch();
  const params = useParams<ParamsI>();

  useEffect(() => {
    if (changeNotificationStatus && !changeNotificationStatusError) {
      message.success('Submitted successfully!');
      dispatch(getPeopleNotes(params.employeeKey));
    }
  }, [changeNotificationStatus]);

  useEffect(() => {
    changeNotificationStatusError &&
      message.error(getApiError(changeNotificationStatusError));
  }, [changeNotificationStatusError]);

  const menu = (
    <Menu
      css={css`
        width: 163px;
        padding: 0px;
        border: 0.5px solid #e0e0e0;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        .ant-dropdown-menu-item {
          padding: 12px 0px 12px 16px;
          border-bottom: 1px solid #e0e0e0;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
      `}>
      {addAndEditOnly ? null : item.status != 'DONE' ? (
        <Menu.Item
          key="done"
          onClick={() => {
            if (item.id) {
              const value = {
                id: item.id,
                status: NotificationStatusEnum.DONE,
              };
              putChangeNotificationStatus(value);
            }
          }}>
          Mark as done
        </Menu.Item>
      ) : null}
      {item.status === 'DONE' ? (
        <Menu.Item
          key="status"
          onClick={() => {
            if (item.id) {
              const value = {
                id: item.id,
                status: NotificationStatusEnum.PENDING,
              };
              putChangeNotificationStatus(value);
            }
          }}>
          Unmask as done
        </Menu.Item>
      ) : (
        <Menu.Item key="edit" onClick={() => onClickEdit()}>
          Edit note
        </Menu.Item>
      )}
      <Menu.Item key="remove">
        <DeleteModal
          title="Remove note"
          buttonLabel="Yes, Remove!"
          content="Are you sure you want to remove this note?"
          onClickCancel={() => onRemove()}
          openModal={<B type="b-default">Remove note</B>}></DeleteModal>
      </Menu.Item>
    </Menu>
  );
  const getDiffDays = (reminderDate: string | undefined) => {
    if (
      moment(reminderDate).format('YYYY-MM-DD') ===
      moment().format('YYYY-MM-DD')
    ) {
      return 'to day';
    } else if (
      moment(reminderDate).format('YYYY-MM-DD') ===
      moment().add(1, 'days').format('YYYY-MM-DD')
    ) {
      return 'in 1 day';
    }

    return moment(reminderDate).fromNow();
  };

  return (
    <NoteItemCard>
      <Col span={19} xs={12}>
        <B
          type="b-default"
          color={item.status === 'DONE' ? '#757575' : '#000000'}>
          {item.note}
          <B type="b-small" color="#757575">
            {item.createdAt
              ? moment(item.createdAt).format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD')}
          </B>
        </B>
      </Col>
      <Column span={5} xs={12}>
        {item.status === 'DONE' ? (
          <NameTag
            textColor="#205823"
            color="#E8F5E9"
            styleCSS={`
            width:54px ;
            margin-left: auto;
            height: 24px;
            padding: 2px 12px;
            border-radius: 4px;
          `}>
            Done
          </NameTag>
        ) : (
          <>
            {!!item.reminderDate && (
              <>
                <Image
                  width={20}
                  height={20}
                  marginLeft="auto"
                  src={require(`../../../../assets/Icons/note-icons/notification.svg`)}
                />

                <B type="b-small" color="#757575" pl="10px">
                  {getDiffDays(moment(item.reminderDate).format('M/D/YYYY'))}
                </B>
              </>
            )}
          </>
        )}
        {isAllowed(Permission.MANAGE_NOTIFICATION) && (
          <Dropdown overlay={menu} trigger={['click']}>
            <Image
              width={20}
              height={20}
              cursor="pointer"
              marginLeft={item.reminderDate ? '10px' : 'auto'}
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
              src={MoreSVG}
            />
          </Dropdown>
        )}
      </Column>
    </NoteItemCard>
  );
};

export default NoteItem;
