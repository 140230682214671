import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useNavigate, useLocation } from 'react-router';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { B, SecondaryButton } from '../../../components';
import { MappedDataI } from '../open-column-mapping/shared/CSVMapper';
import theme from '../../../theme';
import BankingDetail from './BankingDetail';
import EditEmployeeModal from './EditEmployeeModal';
import IndividualEmployeeSelector from './IndividualEmployeeSelector';
import PersonalSalaryInformationTable from './PersonalSalaryInformationTable';
import { PaySheet } from '../modules/PaySheet';
import {
  EmployeePaySheetSummary,
  SalaryItemI,
} from '../modules/EmployeeSalary';
import styled from '@emotion/styled';

const MainDiv = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 4px;
`;
const Flex = styled.div`
  text-align: right;
  float: right;
  width: 100%;
  padding-bottom: 10px;
`;

const IndividualSalary: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [sheetIndex, setSheetIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [paySheet, setPaySheet] = useState<PaySheet>(new PaySheet([]));

  const [dataSource, setDataSource] = useState<EmployeePaySheetSummary[]>([]);

  const [
    selectedPaySheetSummary,
    setSelectedPaySheetSummary,
  ] = useState<EmployeePaySheetSummary>();

  const [selectedEmployeeSalary, setSelectedEmployeeSalary] = useState<
    SalaryItemI[]
  >();

  useEffect(() => {
    const mapDataColumns = location.state.mapDataColumns || [];
    const newPaySheet = new PaySheet(mapDataColumns);
    setDataSource(newPaySheet.getPaySheetBreakdownByEmployees());
    setPaySheet(newPaySheet);
  }, []);

  useEffect(() => {
    if (dataSource.length) {
      setSelectedPaySheetSummary(dataSource[sheetIndex]);
      setSelectedEmployeeSalary(paySheet.getEmployeeSalaryItems()[sheetIndex]);
      paySheet.generateJSONForServer();
    }
  }, [dataSource, sheetIndex]);
  return (
    <>
      <MainDiv>
        <Row>
          <Flex>
            {sheetIndex !== 0 ? (
              <B
                display="inline-block"
                onClick={() => setSheetIndex(sheetIndex - 1)}
                type="b-default"
                color={theme.blue400}
                pr="10px">
                <LeftOutlined /> Previous
              </B>
            ) : null}
            {sheetIndex !== dataSource.length - 1 ? (
              <B
                display="inline-block"
                onClick={() => setSheetIndex(sheetIndex + 1)}
                type="b-default"
                color={theme.blue400}>
                Next <RightOutlined />
              </B>
            ) : null}
          </Flex>
        </Row>
        <Row gutter={[24, 8]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={16}>
            <IndividualEmployeeSelector
              employees={dataSource}
              sheetIndex={sheetIndex}
              netPay={selectedPaySheetSummary?.netPay}
              onSelect={(index) => {
                setSheetIndex(index);
              }}
            />

            {selectedEmployeeSalary && selectedPaySheetSummary ? (
              <PersonalSalaryInformationTable
                selectedPaySheetSummary={selectedPaySheetSummary}
                selectedSalaryItems={selectedEmployeeSalary}
              />
            ) : null}
            <EditEmployeeModal
              visible={visible}
              onClose={() => {
                setVisible(false);
              }}
            />
            <Row
              justify="end"
              css={css`
                padding-top: 16px;
              `}>
              <Col>
                <SecondaryButton htmlType="button" onClick={() => navigate(-1)}>
                  Cancel
                </SecondaryButton>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            {selectedEmployeeSalary ? (
              <BankingDetail selectedSalaryItems={selectedEmployeeSalary} />
            ) : null}
          </Col>
        </Row>
      </MainDiv>
    </>
  );
};

export default IndividualSalary;
