import React, { FC, useState } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useEffect } from 'react';
import theme from '../../../theme';
import { DataExportFieldMetaType } from '../../../types';
import { addDotsForLongText } from '../../../util/utils';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import styled from '@emotion/styled';

const HederCheckBox = styled.div`
  background: #f5f5f5;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
  padding: 10px 0px 10px 24px !important;
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${theme.blue500};
  }
`;

const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  padding-left: 24px !important;
  .ant-checkbox-group-item {
    margin-top: 12px;
  }
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${theme.blue500};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.lightBlue50};
    border-color: ${theme.blue500};
    box-sizing: border-box;
    border-radius: 4px;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid ${theme.blue500};
    border-top: 0;
    border-left: 0;
  }
  .ant-checkbox-checked::after {
    border-radius: 4px;
  }

  span.ant-checkbox.ant-checkbox-checked:hover {
    border: none;
    border-radius: 4px;
  }
`;

const MainCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: ${theme.MOBILE_MAX_WIDTH}px) {
    margin-top: 30px;
  }
`;

interface Props {
  title: string;
  options: DataExportFieldMetaType[];
  onChangeCheckedList: (value: string[]) => void;
}

const ExportType: FC<Props> = ({ title, options, onChangeCheckedList }) => {
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [checkAll, setCheckAll] = useState<boolean>(false);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? options.map((item) => item.key) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  useEffect(() => {
    onChangeCheckedList(checkedList as string[]);
  }, [checkedList]);

  return (
    <MainCard>
      <HederCheckBox>
        <CheckBoxComp
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}>
          {title}
        </CheckBoxComp>
      </HederCheckBox>

      <CheckboxGroup
        options={options.map((item) => ({
          label: addDotsForLongText(item.value, 25),
          value: item.key,
        }))}
        value={checkedList}
        onChange={onChange}
      />
    </MainCard>
  );
};
export default ExportType;
