import { PayRollSummaryI, PreviousPayrollI } from '../../../store/actions';

function getLastUpdateDate(data: PreviousPayrollI[]) {
  let maxId = 0;
  data.map((item) => {
    maxId = Math.max(Number(item.id), maxId);
  });
  return maxId;
}

export const getLastPayrollItem = (payrollSummery?: PayRollSummaryI | null) => {
  let lastPayrollItem = null;
  if (payrollSummery?.previousPayroll) {
    lastPayrollItem = payrollSummery.previousPayroll
      .filter((item) =>
        Math.max(
          Number(item.id),
          getLastUpdateDate(payrollSummery.previousPayroll || []),
        ),
      )
      .find((item) => item);
  }
  return lastPayrollItem;
};
