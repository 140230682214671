import React, { FC, useEffect, useRef, useState } from 'react';
import { Drawer, notification, Spin } from 'antd';
import ToolContainer from './ToolContainer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { pdfjs } from 'react-pdf';
import PagesDisplayCard from './PagesDisplayCard';
import {
  EmptyPage,
  FooterComponent,
  PrimaryButton,
  RouteLeavingGuard,
  SecondaryButton,
} from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType, RootState } from '../../../store/reducer';
import { useNavigate, useParams } from 'react-router';

import {
  AssignedEditPlaceholderDataI,
  AssignedPlaceholderDataI,
  NewPlaceholderDataI,
  PlaceholderLocationI,
  PlaceholderTypeEnum,
} from '../../../types/contract.types';
import {
  addPlaceholder,
  changePlaceholder,
  deletePlaceholder,
  getAssignableUsers,
  getContractById,
  getMinimumUserInfo,
  getPlaceholders,
  sendContract,
} from '../../../store/actions';
import SendContractModal from '../contract-send/SendContractModal';
import { generateNewPlaceHolder } from '../utils/placeholder';
import ContractViewer from '../shared/ContractViewer';
import { isAllowed } from '../../../util/permissionUtil';
import { Permission } from '../../../types';
import PlaceholderInfoPanel from './PlaceholderInfoPanel';
import {
  ContentEditorContainer,
  ContractContainer,
  ContractLayout,
  DraggableLayout,
  ToolKitContainer,
} from '../shared/ContractLayout';
import theme from '../../../theme';
import { useOneOfKeyPress } from '../../../util/useKeyPress';
import { ContractTopBar } from '../shared/ContractTopBar';
import { ZoomProvider } from '../shared/ZoomContext';
import { useGetAssignedUsers } from '../utils/assignedUsers';
import { getPathWithOrgId } from '../../../util/getPathWithOrgId';
import styled from '@emotion/styled';
import { Cancel } from '../../../assets';

type ParamsI = {
  contractId: string;
};
export interface PlaceholderBoxProps {
  left: number;
  top: number;
  width: number;
  height: number;
}

const SpinComp = styled(Spin)`
  min-height: 100px;
`;
const DrawerComp = styled(Drawer)`
  margin-top: 100px;
  height: calc(100vh - 185px);
  .ant-drawer-body {
    padding: 0;
  }
  .ant-drawer-content {
    background-color: ${theme.gray50};
  }

  .ant-drawer-header-title {
    position: absolute;
    right: 3px;
  }
`;

const EditContract: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const hasChangeRef = useRef<boolean>(false);
  const params = useParams<ParamsI>();
  const navigate = useNavigate();
  const contract = useSelector((state: RootState) => state.contract.contract);

  const lastSelectedUserId = useRef<null | string | number>();

  const isAllowedToSend = isAllowed(Permission.SEND_CONTRACT);
  const isAllowedToGetContract = isAllowed(Permission.GET_CONTRACT_BY_ID);

  const [visibleSendAlert, setVisibleSendAlert] = useState(false);
  const [placeholders, setPlaceholders] = useState<
    AssignedEditPlaceholderDataI[]
  >([]);

  const [visiblePage, setVisiblePage] = useState<number>(0);

  let [
    selectedPlaceholder,
    // eslint-disable-next-line prefer-const
    setSelectedPlaceholder,
  ] = useState<AssignedEditPlaceholderDataI | null>(null);
  const [deletedPlaceholderIds, setDeletedPlaceholderIds] = useState<string[]>(
    [],
  );
  const assignableUsers = useSelector(
    (state: RootState) => state.contract.assignableUsers,
  );

  const addPlaceholderLoading = useSelector(
    (state: RootState) => state.contract.addPlaceholderLoading,
  );
  const allPlaceholdersLoading = useSelector(
    (state: RootState) => state.contract.allPlaceholdersLoading,
  );
  const loadingContract = useSelector(
    (state: RootState) => state.contract.loadingContract,
  );
  const allPlaceholders = useSelector(
    (state: RootState) => state.contract.allPlaceholders,
  );
  const { firstSignatory, secondSignatory } =
    useGetAssignedUsers(assignableUsers);

  const [pageCounts, setPageCounts] = useState({});

  // check page allowed
  useEffect(() => {
    if (contract && contract.id == params.contractId) {
      if (contract.isSend) {
        navigate(getPathWithOrgId(`/contract/view/${contract.id}`));
      }
    }
  }, [contract]);

  const deleteKeyPressed = useOneOfKeyPress(['Backspace', 'Delete']);
  let isReceiver = true;

  if (lastSelectedUserId?.current) {
    isReceiver = Boolean(lastSelectedUserId?.current === firstSignatory?.id);
  }

  useEffect(() => {
    if (deleteKeyPressed && selectedPlaceholder) {
      deleteSelectedPlaceholder();
    }
  }, [deleteKeyPressed, selectedPlaceholder]);

  useEffect(() => {
    const list: AssignedEditPlaceholderDataI[] = allPlaceholders.map(
      (item) => ({ ...item, isNew: false }),
    );
    setPlaceholders([...list]);
  }, [allPlaceholders]);

  useEffect(() => {
    if (isAllowedToGetContract) {
      Promise.all([
        dispatch(getContractById(params.contractId)),
        dispatch(getPlaceholders(Number(params.contractId))),
        dispatch(getAssignableUsers(params.contractId)),
        dispatch(getMinimumUserInfo()),
      ]);
    }
  }, []);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; //or pdfjsWorker
  }, []);

  const addNewPlaceholder = (
    pageIndex: number,
    x: number,
    y: number,
    type: PlaceholderTypeEnum,
  ) => {
    hasChangeRef.current = true;
    const newPlaceholder = {
      ...generateNewPlaceHolder({ pageIndex, type, x, y }),
      assignedUserId: isReceiver ? firstSignatory?.id : secondSignatory?.id,
    };
    placeholders.push(newPlaceholder);
    setPlaceholders([...placeholders]);
    setSelectedPlaceholder(newPlaceholder);
  };

  const validateInputs = (placeholders) => {
    let hasFirstSignatoryPlaceholders = false;
    let hasSecondSignatoryPlaceholders = false;
    let hasFirstSignatorySignature = false;
    let hasSecondSignatorySignature = false;

    placeholders.forEach((item) => {
      if (item.assignedUserId == firstSignatory?.id) {
        hasFirstSignatoryPlaceholders = true;
        if (item.type === 'SIGNATURE') {
          hasFirstSignatorySignature = true;
        }
      }

      if (item.assignedUserId == secondSignatory?.id) {
        hasSecondSignatoryPlaceholders = true;
        if (item.type === 'SIGNATURE') {
          hasSecondSignatorySignature = true;
        }
      }
    });
    if (!hasFirstSignatoryPlaceholders) {
      return 'You did not add placeholders for first signatory';
    }

    if (!hasFirstSignatorySignature) {
      return 'You did not add any signature placeholder for first signatory';
    }

    // Since second signatory is optional, we through error if he has only added
    // second signatory
    if (!hasSecondSignatoryPlaceholders && secondSignatory?.id) {
      return 'You did not add placeholders for second signatory';
    }

    if (!hasSecondSignatorySignature && secondSignatory?.id) {
      return 'You did not add any signature placeholder for second signatory';
    }
    return null;
  };

  const deleteSelectedPlaceholder = () => {
    if (selectedPlaceholder) {
      hasChangeRef.current = true;
      const selectedId = selectedPlaceholder.id;
      const findIndex = placeholders.findIndex((item) => item.id == selectedId);

      if (!selectedPlaceholder.isNew) {
        setDeletedPlaceholderIds([...deletedPlaceholderIds, selectedId]);
      }

      placeholders.splice(findIndex, 1); // Remove item
      setPlaceholders(placeholders);
      setSelectedPlaceholder(null);
    }
  };

  const onSave = async () => {
    const newPlaceHolders: NewPlaceholderDataI[] = [];
    const updatedPlaceholders: AssignedPlaceholderDataI[] = [];
    placeholders.forEach((placeholder) => {
      if (placeholder.isNew) {
        const item = { ...placeholder, id: undefined } as unknown;
        newPlaceHolders.push(item as NewPlaceholderDataI);
      } else {
        updatedPlaceholders.push({
          id: placeholder.id,
          assignedUserId: placeholder.assignedUserId,
          width: placeholder.width,
          hight: placeholder.hight,
          pageNumber: placeholder.pageNumber,
          xAxis: placeholder.xAxis,
          yAxis: placeholder.yAxis,
          required: placeholder.required,
          type: placeholder.type,
        } as AssignedPlaceholderDataI);
      }
    });
    if (deletedPlaceholderIds.length) {
      await dispatch(
        deletePlaceholder(params.contractId, deletedPlaceholderIds),
      );
      setDeletedPlaceholderIds([]);
    }
    if (updatedPlaceholders.length && !newPlaceHolders.length) {
      await dispatch(
        changePlaceholder({
          contractId: Number(params.contractId),
          placeHolders: updatedPlaceholders,
        }),
      );
    }

    if (newPlaceHolders.length) {
      await dispatch(
        addPlaceholder({
          contractId: Number(params.contractId),
          placeHolders: newPlaceHolders as NewPlaceholderDataI[],
        }),
      );
    }
    dispatch(getPlaceholders(Number(params.contractId)));
    hasChangeRef.current = false; // TODO: this is wrong because we don't caught errors
  };

  if (!loadingContract && !contract) {
    return (
      <EmptyPage
        content="Sorry, the contract cannot be found."
        title="something went wrong !"
        buttonLabel=" Back Home"
      />
    );
  }

  return (
    <>
      <ZoomProvider>
        {/** @ts-ignore */}
        <DndProvider backend={HTML5Backend}>
          <ContractLayout>
            <ContractTopBar contract={contract} />
            <DraggableLayout>
              {!loadingContract && (
                <ToolKitContainer>
                  <ToolContainer
                    isReceiver={isReceiver}
                    selectPlaceHolder={(type) => {
                      const currentCount = pageCounts[visiblePage] || 0;
                      addNewPlaceholder(
                        visiblePage,
                        266 + 5 * currentCount,
                        336 + 5 * currentCount,
                        type,
                      );
                      setPageCounts({
                        ...pageCounts,
                        [visiblePage]: currentCount + 1,
                      });
                    }}
                  />
                </ToolKitContainer>
              )}
              <ContractContainer>
                <SpinComp spinning={loadingContract}>
                  {contract && (
                    <ContractViewer
                      documentLink={contract ? contract?.documentLink : ''}
                      isEditable={true}
                      placeholders={placeholders}
                      selectedPlaceholder={selectedPlaceholder}
                      setSelectedPlaceholder={(data) => {
                        hasChangeRef.current = true;
                        setSelectedPlaceholder(
                          data as AssignedEditPlaceholderDataI,
                        );
                      }}
                      onAddNewPlaceholder={addNewPlaceholder}
                      contract={contract}
                      setVisiblePage={setVisiblePage}
                    />
                  )}
                </SpinComp>
              </ContractContainer>
              <ContentEditorContainer id="drawer-container">
                {selectedPlaceholder ? (
                  <DrawerComp
                    width={228}
                    closeIcon={<img src={Cancel} />}
                    mask={false}
                    zIndex={1}
                    onClose={() => setSelectedPlaceholder(null)}
                    placement="right"
                    open={true}>
                    <PlaceholderInfoPanel
                      selectedPlaceholder={selectedPlaceholder}
                      assignableUsers={assignableUsers}
                      onChange={(placeholder) => {
                        hasChangeRef.current = true;
                        selectedPlaceholder = placeholder;
                        lastSelectedUserId.current =
                          placeholder.assignedUserId || null;
                        setPlaceholders([...placeholders]);
                      }}
                      onClickDelete={() => deleteSelectedPlaceholder()}
                    />
                  </DrawerComp>
                ) : null}

                <PagesDisplayCard
                  assignedUsers={assignableUsers}
                  placeholderDetails={placeholders}
                  url={contract ? contract?.documentLink : ''}
                />
              </ContentEditorContainer>
            </DraggableLayout>
          </ContractLayout>
        </DndProvider>
        <FooterComponent
          leftChildren={
            <SecondaryButton
              onClick={() => {
                navigate(getPathWithOrgId('/contract'));
              }}>
              Cancel
            </SecondaryButton>
          }
          rightChildren={
            <>
              <SecondaryButton
                onClick={() => onSave()}
                loading={addPlaceholderLoading || allPlaceholdersLoading}
                htmlType="button"
                marginRight="16px">
                Save Draft
              </SecondaryButton>
              {isAllowedToSend ? (
                <PrimaryButton
                  loading={addPlaceholderLoading || allPlaceholdersLoading}
                  onClick={async () => {
                    await onSave();
                    const validateMessage = await validateInputs(placeholders);
                    if (validateMessage) {
                      notification.info({
                        duration: 4,
                        message: 'Required',
                        description: validateMessage,
                      });
                    } else {
                      setVisibleSendAlert(true);
                    }
                  }}>
                  Save & Send
                </PrimaryButton>
              ) : null}
            </>
          }
        />
        <RouteLeavingGuard
          when={hasChangeRef.current}
          navigate={(path) => navigate(path)}
          shouldBlockNavigation={() => hasChangeRef.current}
        />
        {visibleSendAlert ? (
          <SendContractModal
            documentName={String(contract?.name)}
            receiver={firstSignatory}
            assignableUsers={assignableUsers}
            subject={
              contract?.name
                ? `Complete with Humanised : ${contract?.name}`
                : ''
            }
            visible={visibleSendAlert}
            handleCancel={() => {
              setVisibleSendAlert(false);
            }}
            onSubmit={(value) => {
              dispatch(sendContract(value));
              setVisibleSendAlert(false);
              navigate(getPathWithOrgId('/contract'));
            }}
          />
        ) : null}
      </ZoomProvider>
    </>
  );
};
export default EditContract;
