import React, { FC } from 'react';
import EditContract from './contract-edit/EditContract';
import theme from '../../theme';
import styled from '@emotion/styled';

const Card = styled.div`
  background: ${theme.gray100};
  height: 100%;
`;
const ContractEditHome: FC = () => {
  return (
    <Card>
      <EditContract />
    </Card>
  );
};
export default ContractEditHome;
