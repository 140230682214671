import { message } from 'antd';
import axios from 'axios';
import {
  BirthdayI,
  CelebrationReturnI,
  CustomizationEnum,
  HolidayI,
  NewHolidayI,
  OrganizationBodyI,
  UpcomingNoteReturnI,
} from '../../types/organization.types';
import showApiError from '../../util/showApiError';

const All_HOLIDAYS = 'All_HOLIDAYS';
const GET_ORGANIZATION = 'GET_ORGANIZATION';
const LOAD_ORGANIZATION = 'LOAD_ORGANIZATION';
const NEW_HOLIDAY = 'NEW_HOLIDAY';
const NEW_HOLIDAY_LOADING = 'NEW_HOLIDAY_LOADING';
const UPCOMING_DATA = 'UPCOMING_DATA';
const UPCOMING_DATA_LOADING = 'UPCOMING_DATA_LOADING';
const LOADING_UPLOAD_DOCUMENTS = 'LOADING_UPLOAD_DOCUMENTS';
const UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS';

export enum DataExtractorCSVEnum {
  STMF_IMPORT = 'STMF_IMPORT',
  EMPLOYEE_CSV = 'EMPLOYEE_CSV',
  SALARY_CSV = 'SALARY_CSV',
}
export enum DataExtractorTypesEnum {
  STANDARD_1 = 'STANDARD_1',
}

export enum DataImportStrategyEnum {
  SalaryOnly = 'SalaryOnly',
  EmployeeOnly = 'EmployeeOnly',
}
interface InitialStateI {
  organizationData: null | OrganizationBodyI;
  allHolidays: [];
  loading: boolean;
  newHoliday: NewHolidayI | null;
  newHolidayLoading: boolean;
  upcomingData: (BirthdayI | CelebrationReturnI | UpcomingNoteReturnI)[];
  upcomingDataLoading: boolean;
  documentLoading: boolean;
  document: string | null;
}

const initialState: InitialStateI = {
  allHolidays: [],
  organizationData: null,
  loading: false,
  newHoliday: null,
  newHolidayLoading: false,
  upcomingData: [],
  upcomingDataLoading: false,
  documentLoading: false,
  document: null,
};

export default function reducer(
  state = initialState,
  action: any,
): InitialStateI {
  switch (action.type) {
    case All_HOLIDAYS:
      return {
        ...state,
        allHolidays: action.payload,
      };
    case GET_ORGANIZATION: {
      return {
        ...state,
        organizationData: action.payload,
      };
    }
    case LOAD_ORGANIZATION: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case NEW_HOLIDAY: {
      return {
        ...state,
        newHoliday: action.payload,
      };
    }
    case NEW_HOLIDAY_LOADING: {
      return {
        ...state,
        newHolidayLoading: action.payload,
      };
    }
    case UPCOMING_DATA: {
      return {
        ...state,
        upcomingData: action.payload,
      };
    }
    case UPCOMING_DATA_LOADING: {
      return {
        ...state,
        upcomingDataLoading: action.payload,
      };
    }
    case LOADING_UPLOAD_DOCUMENTS:
      return {
        ...state,
        documentLoading: action.payload,
      };

    case UPLOAD_DOCUMENTS:
      return {
        ...state,
        document: action.payload,
      };
    default:
      return state;
  }
}

export function getMyOrganization() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_ORGANIZATION, payload: true });
      const res = await axios.get<OrganizationBodyI>('/organization');
      dispatch({ type: GET_ORGANIZATION, payload: res.data });
      dispatch({ type: LOAD_ORGANIZATION, payload: false });
      return res.data;
    } catch (error: any) {
      dispatch({ type: LOAD_ORGANIZATION, payload: false });
      showApiError(error);
    }
  };
}

export function getHolidays() {
  return async (dispatch: any) => {
    try {
      const res = await axios.get('/organization/holidays');
      dispatch({ type: All_HOLIDAYS, payload: res.data });
    } catch (error: any) {
      showApiError(error);
    }
  };
}

export const updateCustomization = (
  data: string[],
  customizationType: CustomizationEnum,
) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_ORGANIZATION, payload: true });
      await axios.put('/organization/customization', {
        customizationType: customizationType,
        data: data,
      });
      dispatch({ type: LOAD_ORGANIZATION, payload: false });
      dispatch(getMyOrganization());
    } catch (error: any) {
      dispatch({ type: LOAD_ORGANIZATION, payload: false });
      showApiError(error);
    }
  };
};

export function uploadOrganizationImage(file: File) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_ORGANIZATION, payload: true });
      const formData = new FormData();
      formData.append('file', file);
      await axios.post('/organization/uploadLogo', formData);
      dispatch({ type: LOAD_ORGANIZATION, payload: false });
      dispatch(getMyOrganization());
    } catch (error: any) {
      dispatch({ type: LOAD_ORGANIZATION, payload: false });
      showApiError(error);
    }
  };
}

export function addNewHoliday(data: NewHolidayI) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: NEW_HOLIDAY_LOADING, payload: true });
      const res = await axios.post('/organization/holidays', { ...data });
      dispatch({ type: NEW_HOLIDAY, payload: res.data });
      dispatch({ type: NEW_HOLIDAY_LOADING, payload: false });
      message.success('Successfully Added New Holiday!');
      dispatch(getMyOrganization());
    } catch (error: any) {
      dispatch({ type: NEW_HOLIDAY_LOADING, payload: false });
      showApiError(error);
    }
  };
}

export function editNewHoliday(data: HolidayI) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: NEW_HOLIDAY_LOADING, payload: true });
      await axios.put('/organization/holidays', {
        ...data,
      });
      dispatch({ type: NEW_HOLIDAY_LOADING, payload: false });
      message.success('Holiday successfully edited');
      dispatch(getMyOrganization());
    } catch (error: any) {
      dispatch({ type: NEW_HOLIDAY_LOADING, payload: false });

      showApiError(error);
    }
  };
}

export function deleteHoliday(holidayId: string) {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/organization/holidays/${holidayId}`);
      message.success('Holiday successfully deleted!');
      dispatch(getMyOrganization());
    } catch (error: any) {
      showApiError(error);
    }
  };
}

export function getUpcoming() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: UPCOMING_DATA_LOADING, payload: true });
      const res = await axios.get('/organization/getUpcomingEvents');
      dispatch({ type: UPCOMING_DATA, payload: res.data });
      dispatch({ type: UPCOMING_DATA_LOADING, payload: false });
    } catch (error: any) {
      dispatch({ type: UPCOMING_DATA_LOADING, payload: false });
      showApiError(error);
    }
  };
}
