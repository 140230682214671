export enum Permission {
  //Organization settings
  MANAGE_ORG_SETTINGS = 'MANAGE_ORG_SETTINGS',

  //USER ME ROUTES
  USER_ME = 'USER_ME',

  //USER ROUTES
  CHANGE_USERS_LOGIN_EMAIL = 'CHANGE_USERS_LOGIN_EMAIL',
  CREATE_EDIT_USERS = 'CREATE_EDIT_USERS',
  VIEW_USER_INFO = 'VIEW_USER_INFO',
  VIEW_PAYSLIPS = 'VIEW_PAYSLIPS',
  VIEW_MY_PAYSLIP = 'VIEW_MY_PAYSLIP',

  CHANGE_USER_SETTINGS_OF_BELOW_LEVEL = 'CHANGE_USER_SETTINGS_OF_BELOW_LEVEL',
  CHANGE_USER_SETTINGS_OF_SAME_LEVEL = 'CHANGE_USER_SETTINGS_OF_SAME_LEVEL',
  CHANGE_USERS_SETTINGS = 'CHANGE_USERS_SETTINGS',

  //PAYROLL ROUTES
  MANAGE_PAYROLL = 'MANAGE_PAYROLL',

  //LEAVE ROUTES
  CREATE_EDIT_LEAVES_USER = 'CREATE_EDIT_LEAVES_USER',
  MANAGE_LEAVE_STATUS = 'MANAGE_LEAVE_STATUS',

  MANAGE_ASSIGNED_USERS = 'MANAGE_ASSIGNED_USERS',
  DEFAULT_LEAVE_ACCEPTED = 'DEFAULT_LEAVE_ACCEPTED',
  MANAGE_SAME_LEVEL_USERS = 'MANAGE_SAME_LEVEL_USERS',

  //EXTERNAL ORGANIZATION ACCOUNTAN ROUTES
  MANAGE_ACCOUNTANTS = 'MANAGE_ACCOUNTANTS',

  //NOTES PERMISSIONS
  MANAGE_NOTIFICATION = 'MANAGE_NOTIFICATION',
  VIEW_NOTES = 'VIEW_NOTES',

  // ORGANIZATION REPORT PERMISSIONS
  MANAGE_REPORT = 'MANAGE_REPORT',

  //CONTRACT ROUTES
  GET_ORG_CONTRACTS = 'GET_ORG_CONTRACTS',
  GET_CONTRACT_BY_ID = 'GET_CONTRACT_BY_ID',
  SEND_CONTRACT = 'SEND_CONTRACT',
  DELETE_CONTRACT = 'DELETE_CONTRACT',
  GET_MY_PLACEHOLDERS = 'GET_MY_PLACEHOLDERS',
  DOWNLOAD_CONTRACTS = 'DOWNLOAD_CONTRACTS',
  GET_CONTRACT_PLACEHOLDERS = 'GET_CONTRACT_PLACEHOLDERS',
  CREATE_EDIT_PLACEHOLDERS = 'CREATE__EDIT_PLACEHOLDERS',
  CHANGE_CONTRACT_STATUS = 'CHANGE_CONTRACT_STATUS',
  CREATE_CONTRACT = 'CREATE_CONTRACT',
  SIGN_CONTRACT = 'SIGN_CONTRACT',

  //Expenses
  MANAGE_EXPENSE_SETTINGS = 'MANAGE_EXPENSE_SETTINGS',
  MANAGE_EXPENSE_STATUS_BASIC = 'MANAGE_EXPENSE_STATUS_BASIC',
  MANAGE_EXPENSE_STATUS_ADVANCED = 'MANAGE_EXPENSE_STATUS_ADVANCED',

  //LOAN
  MANAGE_LOAN = 'MANAGE_LOAN',

  //GRATUITY
  MANAGE_GRATUITY = 'MANAGE_GRATUITY',

  //INTEGRATION ROUTES
  MANAGE_INTEGRATION = 'MANAGE_INTEGRATION',

  //Super Admin
  SUPER_ADMIN_FEATURES = 'SUPER_ADMIN_FEATURES',
}
