import axios from 'axios';

import showApiError from '../../util/showApiError';

const TEAM_AVAILABILITY_LOADING = 'TEAM_AVAILABILITY_LOADING';
const TEAM_AVAILABILITY = 'TEAM_AVAILABILITY';
const HOLIDAYS_LOADING = 'HOLIDAYS_LOADING';
const HOLIDAYS = 'HOLIDAYS';
const TEAMS_LOADING = 'TEAMS_LOADING';
const TEAMS = 'TEAMS';
const SET_SCROLL_TARGET = 'SET_SCROLL_TARGET';
const SET_NOTIFICATION_DATA = 'SET_NOTIFICATION_DATA';

export interface HolidaysI {
  id: number;
  date: string;
  weekDay: string;
  description: string;
  note: null;
  createdAt: string;
  updatedAt: string;
}
export interface ConvertedHolidaysI {
  date: string;
  descriptions: string;
}

export interface TeamAvailabilityI {
  key: string;
  name: string;
  [key: string]: any;
}
export interface InitialStateI {
  teamAvailability: TeamAvailabilityI[];
  teamAvailabilityLoading: boolean;
  holidays: HolidaysI[];
  holidaysLoading: boolean;
  teams: string[];
  scrollTarget: string | number | null;
  notificationData: dashBoardNotificationI;
}

interface dashBoardNotificationI {
  id: number;
  date: string;
}
const initialState: InitialStateI = {
  teamAvailability: [],
  teamAvailabilityLoading: false,
  holidays: [],
  holidaysLoading: true,
  teams: [],
  scrollTarget: null,
  notificationData: { id: 0, date: '' },
};

export default function reducer(
  state: InitialStateI = initialState,
  action: any,
): InitialStateI {
  switch (action.type) {
    case TEAM_AVAILABILITY_LOADING:
      return {
        ...state,
        teamAvailabilityLoading: action.payload,
      };
    case TEAM_AVAILABILITY:
      return {
        ...state,
        teamAvailability: action.payload,
      };
    case HOLIDAYS_LOADING:
      return {
        ...state,
        holidaysLoading: action.payload,
      };
    case HOLIDAYS:
      return {
        ...state,
        holidays: action.payload,
      };
    case TEAMS:
      return {
        ...state,
        teams: action.payload,
      };
    case SET_SCROLL_TARGET:
      return {
        ...state,
        scrollTarget: action.payload,
      };
    case SET_NOTIFICATION_DATA:
      return {
        ...state,
        notificationData: action.payload,
      };

    default:
      return state;
  }
}

export function getAllHolidays() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: HOLIDAYS_LOADING, payload: true });
      const res = await axios.get('/organization/holidays');
      dispatch({ type: HOLIDAYS, payload: res.data });
      dispatch({ type: HOLIDAYS_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: HOLIDAYS_LOADING, payload: false });
    }
  };
}
export function getTeamAvailability(month: string, team: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: TEAM_AVAILABILITY_LOADING, payload: true });
      const res = await axios.get('/organization/teamAvailability', {
        params: {
          team: team,
          month: month,
        },
      });
      dispatch({ type: TEAM_AVAILABILITY, payload: res.data });
      dispatch({ type: TEAM_AVAILABILITY_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: TEAM_AVAILABILITY_LOADING, payload: false });
    }
  };
}
export function getTeams() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: TEAMS_LOADING, payload: true });
      const res = await axios.get('/users/teams');
      dispatch({ type: TEAMS, payload: res.data });
      dispatch({ type: TEAMS_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: TEAMS_LOADING, payload: false });
    }
  };
}

export const setScrollTarget = (element: any) => {
  return (dispatch: any) => {
    dispatch({ type: SET_SCROLL_TARGET, payload: element });
  };
};

export const setNotificationData = (element: any) => {
  return (dispatch: any) => {
    dispatch({ type: SET_NOTIFICATION_DATA, payload: element });
  };
};
export function refreshTable(data: boolean) {
  return async (dispatch: any) => {
    try {
      if (data == true) {
        dispatch({ type: HOLIDAYS_LOADING, payload: true });
      } else {
        dispatch({ type: HOLIDAYS_LOADING, payload: false });
      }
    } catch (error: any) {
      showApiError(error);
    }
  };
}
