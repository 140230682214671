import React, { FC, useEffect, useState } from 'react';

import { ColumnProps } from 'antd/lib/table';
import { startCase } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useGetPayslipMailStatus } from '../../../../api/payrollHooks';
import { B, Div } from '../../../../components';
import TableComp from '../../../../components/TableComp';
import { DATE_FORMATS } from '../../../../configs/dateConfig';
import {
  MailDeliveryStatusEnum,
  PayslipDeliveryStatusI,
  PaySlipEmpPayroll,
} from '../../../../store/actions';
import { RootState } from '../../../../store/reducer';
import theme from '../../../../theme';
import { isValidMoment } from '../../../../util/isValidMoment';
import { useInterval } from '../../../../util/useInterval';
import ThreeDotPopover from '../../../contract/shared/ThreedotPopover';
import { ExpenseStatusTag } from '../../../expenses/shared';

const DELIVERY_CALL_INTERVAL = 5 * 1000; // 5 seconds
type payrollParam = {
  payrollKey: string;
};

const PayslipDelivery: FC = () => {
  const params = useParams<payrollParam>();
  const {
    data: dataGetPayslipMailStatus = null,
    mutate: getPayslipMailStatus,
  } = useGetPayslipMailStatus();
  const [isAllDone, setIsAllDone] = useState(false);
  const [payload, setPayload] = useState<PayslipDeliveryStatusI['empPayrolls']>(
    [],
  );
  const singlePayrollData = useSelector(
    (state: RootState) => state.payroll.singlePayrollData,
  );

  const isAllDelivered = (dataGetPayslipMailStatus: PayslipDeliveryStatusI) => {
    return (
      dataGetPayslipMailStatus.isComplete ||
      !singlePayrollData?.payslipsSentDate ||
      moment(singlePayrollData.payslipsSentDate).format(
        DATE_FORMATS.iso_date_format,
      ) !== moment().format(DATE_FORMATS.iso_date_format)
    );
  };

  useEffect(() => {
    if (dataGetPayslipMailStatus) {
      if (isAllDelivered(dataGetPayslipMailStatus)) {
        setIsAllDone(true);
      }
      setPayload(dataGetPayslipMailStatus.empPayrolls);
    }
  }, [dataGetPayslipMailStatus, singlePayrollData]);

  useEffect(() => {
    getPayslipMailStatus(params.payrollKey);
  }, [params]);

  useInterval(
    () => {
      getPayslipMailStatus(params.payrollKey);
    },
    !isAllDone ? DELIVERY_CALL_INTERVAL : null,
  );

  const getDeliveryStatusText = (status: MailDeliveryStatusEnum) => {
    switch (status) {
      case MailDeliveryStatusEnum.DELIVERED:
        return <span>{startCase(status)}</span>;
      case MailDeliveryStatusEnum.FAILED:
        return <span style={{ color: theme.red300 }}>{startCase(status)}</span>;
      default:
        return <span>{startCase(status)}</span>;
    }
  };
  const columns: ColumnProps<PaySlipEmpPayroll>[] = [
    {
      title: 'Employee Name',
      dataIndex: 'fullName',
      render: (_value, record) => (
        <B type="b-small" cursor="pointer">
          {record ? (
            <ThreeDotPopover
              text={record.user.fullName}
              maxLength={25}
              fontSize={12}
            />
          ) : (
            '-'
          )}
        </B>
      ),
    },

    {
      title: 'Employee Email',
      dataIndex: 'paySlipMail',
      render: (_value, record) => (
        <B type="b-small" cursor="pointer">
          {record?.user?.paySlipMail ? record.user.paySlipMail : '-'}
        </B>
      ),
    },
    {
      title: 'Mailed Date',
      dataIndex: 'payslipsSentDate',
      render: (_value, record) => {
        const sendDate = isValidMoment(
          record.payroll?.payslipsSentDate,
          '-',
          'YYYY MMMM Do',
        );
        const scheduleDate = isValidMoment(
          record.payroll?.scheduleDate,
          '-',
          'YYYY MMMM Do',
        );
        return (
          <B type="b-small" cursor="pointer">
            {record?.payroll?.payslipsSentDate
              ? sendDate.toString()
              : scheduleDate.toString()}
          </B>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_value, record) => {
        return (
          <>
            {record?.deliveryStatus ? (
              <B type="b-small" cursor="pointer">
                {getDeliveryStatusText(record.deliveryStatus)}
              </B>
            ) : (
              <ExpenseStatusTag status="PENDING" />
            )}
          </>
        );
      },
    },
  ];

  return (
    <Div mt="24px">
      <TableComp
        size="large"
        headerFontSize={14}
        columns={columns}
        withBorders={true}
        dataSource={payload}
        pagination={false}
        scroll={{
          y: window.innerHeight - 370,
          x: 990,
        }}
      />
    </Div>
  );
};
export default PayslipDelivery;
