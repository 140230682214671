import React, { ReactNode } from 'react';
import { FC } from 'react';
import { getUserPermissions } from '../util/permissionUtil';
import { Permission } from '../types/permissions.enum';

interface Props {
  permission: Permission;
  children: ReactNode;
}

export const Can: FC<Props> = ({ permission, children }) => {
  const permissions = getUserPermissions();
  if (permissions?.includes(permission)) {
    return <>{children}</>;
  } else {
    return null;
  }
};
