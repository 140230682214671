import React, { FC } from 'react';
import { B, FontTypes } from '../../../components';
import theme from '../../../theme';
import { numberThousandSeparator } from '../../../util/utils';
import styled from '@emotion/styled';

const Text = styled(B)`
  display: flex;
  flex-direction: row;
`;

export const PriceTag: FC<{
  amount: number;
  labelType?: FontTypes;
  prizeType?: FontTypes;
  color?: string;
}> = ({
  amount,
  labelType = 'b-small',
  prizeType = 'b-small-semibold',
  color = theme.gray600,
}) => {
  return (
    <Text type={labelType} color={color}>
      LKR{' '}
      <B type={prizeType} marginLeft="2px">
        {numberThousandSeparator(amount ? amount : 0)}
      </B>
    </Text>
  );
};
