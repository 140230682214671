import React, { FC } from 'react';
import { Row } from 'antd';
import { B } from '../../../components';
import styled from '@emotion/styled';
import { EmptyStateTodo } from '../../../assets';

interface EmptyTextProps {
  description: string;
  subDescription: string;
}

const MainCard = styled.div`
  text-align: center;
  vertical-align: middle;
  width: 288px;
  padding-top: 100px;
`;

export const EmptyContainer: FC<EmptyTextProps> = ({
  description,
  subDescription,
}) => {
  return (
    <Row justify="space-around" align="middle">
      <MainCard>
        <img src={EmptyStateTodo} alt="empty-img" />
        <B type="b-default-semibold" color="#757575" pt="22px">
          {description}
        </B>
        <B type="b-small" color="#757575" pt="8px">
          {subDescription}
        </B>
      </MainCard>
    </Row>
  );
};
