import React, { FC, useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import styled from '@emotion/styled';
import { B, Div, InputNumberComp, SelectComp } from '../../../components';
import {
  TIME_LIST,
  WOKRING_DAYS_CONFIG,
  WORK_TIME_UNIT_TYPE,
} from '../../../configs/workingConfig';
import theme from '../../../theme';
import { MonthlyWorkingDayTypeEnum } from '../../../types';
import { FormInstance } from 'antd/lib/form';
import { Divider } from '../../../components/Divider';
import { Validation } from '../../../common/validation';

interface Prop {
  isMandatory: boolean;
  organizationStartTime?: string;
  organizationEndTime?: string;
  dailyWorkingHours?: number;
  monthlyWorkingDays?: number;
  monthlyWorkingDaysSettings?: string;
  formRef: FormInstance<any>;
  workTimeUnitType?: string;
}

const CustomDiv = styled.div`
  padding: 32px;
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  @media (max-width: 760px) {
    padding: 24px 10px;
  }
`;
const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
  .ant-col-10 {
    max-width: 100%;
  }
`;

const WorkingHours: FC<Prop> = ({
  organizationStartTime,
  organizationEndTime,
  dailyWorkingHours,
  monthlyWorkingDays,
  monthlyWorkingDaysSettings,
  formRef,
  workTimeUnitType,
}) => {
  const [startTime, setStartTime] = useState<number>();
  const [startTimeList, setStartTimeList] = useState(TIME_LIST);
  const workTimeUnitList = WORK_TIME_UNIT_TYPE;
  const [workTimeUnit, setWorkTimeUnitType] = useState<string>();

  const [endTime, setEndTime] = useState<number>();
  const [endTimeList, setEndTimeList] = useState(TIME_LIST);
  const workingDaysOptions = WOKRING_DAYS_CONFIG;

  const [workingDayConfig, setWorkingDayConfig] = useState<string | undefined>(
    MonthlyWorkingDayTypeEnum.FIXED,
  );

  const [workingDays, setWorkingDays] = useState<number | undefined>();
  const [workingHours, setWorkingHours] = useState<number>();

  useEffect(() => {
    if (organizationStartTime && organizationEndTime) {
      setStartTime(Number(organizationStartTime?.split(':')[0]));
      setEndTime(Number(organizationEndTime?.split(':')[0]));

      if (monthlyWorkingDaysSettings) {
        setWorkingDays(monthlyWorkingDays);
        setWorkingDayConfig(monthlyWorkingDaysSettings);
      }
      setWorkingHours(dailyWorkingHours);
      setWorkTimeUnitType(workTimeUnitType);
    }
  }, [
    organizationStartTime,
    organizationEndTime,
    monthlyWorkingDays,
    dailyWorkingHours,
    monthlyWorkingDaysSettings,
    workTimeUnitType,
  ]);

  useEffect(() => {
    formRef.setFieldsValue({
      monthlyWorkingDaySettings: workingDayConfig,
      monthlyWorkingDays:
        workingDayConfig === MonthlyWorkingDayTypeEnum.FIXED
          ? workingDays
          : undefined,
    });
  }, [workingDayConfig]);

  useEffect(() => {
    if (startTime) {
      const endTimeList = TIME_LIST.map((item) => {
        if (Number(item.value) <= startTime)
          item = {
            ...item,
            disabled: true,
          };
        return item;
      });
      setEndTimeList(endTimeList);
    }
    if (endTime) {
      const startTimeList = TIME_LIST.map((item) => {
        if (Number(item.value) >= endTime)
          item = {
            ...item,
            disabled: true,
          };
        return item;
      });
      setStartTimeList(startTimeList);
    }
  }, [startTime, endTime]);

  return (
    <>
      <CustomDiv>
        <B type="b-large-semibold" pb="24px">
          Working Hours
        </B>
        <Row gutter={[32, 16]}>
          <Col xs={24} sm={24} md={24} lg={8} xl={6}>
            <B type="b-large" pt="28px">
              Typical working hours
            </B>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <FormItem
              name="startTime"
              rules={[
                {
                  required: true,
                  message: 'required',
                },
              ]}>
              <SelectComp
                size="middle"
                label="From"
                defaultValue={startTime}
                onChange={(value) => setStartTime(Number(value))}
                options={startTimeList}></SelectComp>
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <FormItem
              name="endTime"
              rules={[
                {
                  required: true,
                  message: 'required',
                },
              ]}>
              <SelectComp
                size="middle"
                label="To"
                defaultValue={endTime}
                onChange={(value) => setEndTime(Number(value))}
                options={endTimeList}></SelectComp>
            </FormItem>
          </Col>
        </Row>
        <Div pt="10px">
          <Row gutter={[32, 16]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={6}></Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <FormItem
                name="dailyWorkingHours"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the No. of working hours per day',
                  },
                ]}>
                <InputNumberComp
                  label="No of working hours per day*"
                  size="small"
                  defaultValue={workingHours}></InputNumberComp>
              </FormItem>
            </Col>
            {workingDayConfig === MonthlyWorkingDayTypeEnum.FIXED ? (
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Row>
                  <B type="b-default-semibold" color={theme.blue700} pb="4px">
                    No of working days per Month*
                  </B>
                </Row>
                <Row gutter={12}>
                  <Col xs={24} sm={24} md={12} lg={8} xl={12}>
                    <FormItem name="monthlyWorkingDaysSettings">
                      <SelectComp
                        size="middle"
                        placeholder="Choose working days"
                        value={workingDayConfig}
                        onChange={(value) =>
                          setWorkingDayConfig(value.toString())
                        }
                        options={workingDaysOptions}></SelectComp>
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xl={12}>
                    <FormItem
                      name="monthlyWorkingDays"
                      rules={[
                        {
                          required: true,
                          message:
                            'Please enter the No. of working days per month',
                        },
                        {
                          pattern: Validation.NoOfDaysPerMonthPattern,
                          message: 'Should be between 1 and 28',
                        },
                      ]}>
                      <InputNumberComp
                        value={formRef.getFieldValue('monthlyWorkingDays')}
                        size="small"
                        placeholder="Enter no of days"
                        onChange={(v) =>
                          formRef.setFieldValue('monthlyWorkingDays', v)
                        }
                        defaultValue={workingDays}></InputNumberComp>
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            ) : workingDayConfig === MonthlyWorkingDayTypeEnum.CALENDAR ||
              MonthlyWorkingDayTypeEnum.VARIABLE ? (
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <FormItem
                  name="monthlyWorkingDaysSettings"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the No. of working days per month',
                    },
                  ]}>
                  <SelectComp
                    size="middle"
                    label="No of working days per Month*"
                    placeholder="Choose working days"
                    value={workingDayConfig}
                    onChange={(value) => setWorkingDayConfig(value.toString())}
                    options={workingDaysOptions}></SelectComp>
                </FormItem>
              </Col>
            ) : null}
          </Row>
        </Div>

        <Div pt="10px">
          <Row gutter={[32, 16]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={6}></Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <FormItem
                name="workTimeUnitType"
                rules={[
                  {
                    required: true,
                    message: 'required',
                  },
                ]}>
                <SelectComp
                  size="middle"
                  label="Preferred Input Format"
                  defaultValue={workTimeUnit}
                  onChange={(value) => setWorkTimeUnitType(value.toString())}
                  options={workTimeUnitList}></SelectComp>
              </FormItem>
            </Col>
          </Row>
        </Div>
      </CustomDiv>
      <Divider />
    </>
  );
};
export default WorkingHours;
