import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { B } from '..';
import theme from '../../theme';
import { ContractStatusEnum } from '../../types/contract.types';

interface Props {
  children?: ReactNode;
  status: ContractStatusEnum;
}
interface TagProps {
  children?: ReactNode;
  textColor?: string;
  backgroundColor?: string;
}

const TagStatus = styled.span<TagProps>`
  padding: 4px 8px 5px;
  border-radius: 4px;
  background-color: ${(props) => props.backgroundColor};
`;

export const Tags: FC<TagProps> = ({
  children,
  textColor,
  backgroundColor,
}) => {
  return (
    <TagStatus backgroundColor={backgroundColor}>
      <B
        type="b-small-medium"
        pl="4px"
        py="4px"
        color={textColor}
        display="contents">
        {children}
      </B>
    </TagStatus>
  );
};

export const DocumentStatusTag: FC<Props> = ({ status, children }) => {
  {
    switch (status) {
      case ContractStatusEnum.COMPLETED:
        return (
          <Tags textColor={theme.green500} backgroundColor={theme.green50}>
            {children}
          </Tags>
        );

      case ContractStatusEnum.DRAFT:
        return (
          <Tags textColor={theme.gray700} backgroundColor={theme.gray300}>
            {children}
          </Tags>
        );

      case ContractStatusEnum.SIGNED:
        return (
          <Tags textColor={theme.green500} backgroundColor={theme.green50}>
            {children}
          </Tags>
        );

      case ContractStatusEnum.SENT:
        return (
          <Tags
            textColor={theme.orange300}
            backgroundColor={theme.lightYellow50}>
            {children}
          </Tags>
        );

      case ContractStatusEnum.EXPIRED:
        return (
          <Tags textColor={theme.gray700} backgroundColor={theme.gray200}>
            {children}
          </Tags>
        );
      case ContractStatusEnum.NOT_COMPLETED:
        return (
          <Tags textColor={theme.red300} backgroundColor={theme.red50}>
            {children}
          </Tags>
        );
      case ContractStatusEnum.PENDING:
        return (
          <Tags textColor={theme.gray700} backgroundColor={theme.gray300}>
            {children}
          </Tags>
        );

      case ContractStatusEnum.ARCHIVED:
        return (
          <Tags textColor={theme.gray700} backgroundColor={theme.gray300}>
            {children}
          </Tags>
        );
      default:
        return null;
    }
  }
};
