import * as ExcelJS from 'exceljs';

import { DataImportUserPayLoad } from '../../../../store/actions';
import { camelCaseToNormalText } from '../../../../util/camelCaseToNormal.util';
import { capitalizeUnderscore } from '../../../../util/utils';
import { ColumnItemType } from './generatePayrollExport';

export const generateSalarySheet = ({
  identityColumnsSummary,
  payItemColumns,
  salary,
  userRows,
  dataForTable,
  epf,
  apit,
}: {
  identityColumnsSummary: ColumnItemType[];
  payItemColumns: ColumnItemType[];
  salary: ExcelJS.Worksheet;
  userRows: {
    [key: string]: string;
  }[];
  dataForTable:
    | {
        dataList: DataImportUserPayLoad[];
        payItemColumns: string[];
      }
    | undefined;
  epf: {
    [key: string]: string;
  };
  apit: {
    [key: string]: string;
  };
}) => {
  const salarySheetColumns = [...identityColumnsSummary, ...payItemColumns];

  salary.columns = salarySheetColumns;

  salary.addRow(epf);
  salary.addRow(apit);

  //Salary Sheet Main headings
  const salarySheetTitles: { [key: string]: string } = {};

  salarySheetTitles['empId'] = 'EMP ID*';
  salarySheetTitles['fullName'] = 'Employee Name';
  salarySheetTitles['preferredName'] = 'Preferred Name';

  const dynamicColumns: string[] = [];
  const startCharCode = 'C'.charCodeAt(0); // ASCII code of 'C' is 67
  let currentIndex = 0;

  if (dataForTable) {
    for (const data of dataForTable.dataList) {
      for (const payitemColumn of payItemColumns) {
        data.values.payItems.forEach((item) => {
          if (
            !(payitemColumn?.key in salarySheetTitles) &&
            item.payTitle === payitemColumn.key
          ) {
            let charCode = 0;
            let columnValue: string;
            if (startCharCode + currentIndex <= 90) {
              charCode = startCharCode + currentIndex;
              columnValue = String.fromCharCode(charCode); // Append 'B' to the generated character
            } else {
              charCode = startCharCode + currentIndex - 26;
              columnValue = 'A' + String.fromCharCode(charCode);
            } // Append 'B' to the generated character
            dynamicColumns.push(columnValue);
            currentIndex++;
          }
          const basicWithTypeBasic =
            item.type === 'BASIC' &&
            item.payTitle.toLowerCase().trim().includes('basic');

          const variableAllowance = item.type === 'VARIABLE_ALLOWANCE';

          if (item.payTitle === payitemColumn.key && !basicWithTypeBasic) {
            salarySheetTitles[payitemColumn.key] = `${capitalizeUnderscore(
              item.type,
            )}:${camelCaseToNormalText(payitemColumn.key)}`;
          }
          if (item.payTitle === payitemColumn.key && basicWithTypeBasic) {
            salarySheetTitles[payitemColumn.key] = 'Basic Salary';
          }

          if (item.payTitle === payitemColumn.key && variableAllowance) {
            salarySheetTitles[
              payitemColumn.key
            ] = `Allowance: ${item.payTitle}`;
          }
        });
      }
    }
  }
  salary.addRow(salarySheetTitles);
  salary.getRow(3).font = { bold: true };

  salary.addRows(userRows);
  dynamicColumns.forEach((columnName) => {
    const column = salary.getColumn(columnName);
    column.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (typeof cell.value === 'number') {
        const numericValue = parseFloat(cell.value.toFixed(2));
        if (!isNaN(numericValue)) {
          cell.value = numericValue;
        }
        if (rowNumber > 3) {
          cell.numFmt = '0.00';
        }
      }
    });
  });
};
