import React, { FC } from 'react';
import { Col, Row } from 'antd';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import theme from '../../../theme';
import { HeaderHumanisedNameIcon } from '../../../assets';

interface Props {
  label?: string;
}
const HumanisedHeaderContainer = styled(Row)`
  background: ${theme.blue900};
  display: flex;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  padding: 15px 16px;
  @media (min-width: 768px) and (max-width: 1920px) {
    padding: 15px 32px;
  }
`;
const HumanisedHeader: FC<Props> = () => {
  return (
    <div>
      <HumanisedHeaderContainer justify="start">
        <Col>
          <Link to="/">
            <img src={HeaderHumanisedNameIcon} height={28} />
          </Link>
        </Col>
      </HumanisedHeaderContainer>
    </div>
  );
};

export default HumanisedHeader;
