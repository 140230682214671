/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { Image } from 'antd';
import { css } from '@emotion/react';
import { UN_ASSIGNED } from '../util/openPayrollValidator';
import { Div } from '../../../../components';
import {
  DropdownDark,
  DropdownGreen,
  DropdownRounded,
  StepArrow,
  StepArrowGreen,
  StepArrowWhite,
} from '../../../../assets';

interface Props {
  name: string;
  isCurrentIndex: boolean;
  onClick: () => void;
  isAutoMapped: boolean;
}
const ArrowColumnHeader: FC<Props> = ({ name, onClick, isCurrentIndex }) => {
  const isNotUnAssigned = name !== UN_ASSIGNED;
  return (
    <Div
      onClick={() => onClick()}
      css={css`
        cursor: pointer;
        padding: 10px 0;
      `}>
      <Image
        className="arrow-img"
        css={css`
          display: inline-block;
          width: 232px;
          object-fit: cover;
        `}
        preview={false}
        src={
          isNotUnAssigned
            ? StepArrowGreen
            : isCurrentIndex
            ? StepArrow
            : StepArrowWhite
        }
      />
      <Div
        css={css`
          position: absolute;
          height: 100%;
          text-align: center;
          left: 10px;
          top: 0;
          color: ${isNotUnAssigned
            ? '#2E7D32'
            : isCurrentIndex
            ? '#fff'
            : ' #5F6267'};
          display: flex;
          align-items: center;
        `}>
        <Div
          css={css`
            width: 150px;
          `}>
          {' '}
          {name}
        </Div>
        &nbsp;
        <Image
          css={css`
            cursor: pointer;
            margin-bottom: 2px;
          `}
          preview={false}
          src={require(isNotUnAssigned
            ? DropdownGreen
            : isCurrentIndex
            ? DropdownRounded
            : DropdownDark)}
        />
      </Div>
    </Div>
  );
};

export default ArrowColumnHeader;
