import React from 'react';
import theme from '../../theme';
import styled from '@emotion/styled';
import { DoneCheckImage } from '../../assets';
import { H } from '../../components';

const MainBody = styled.div`
  background-color: ${theme.gray50};
  padding: 120px 188px;
  margin-top: 80px;
  border: 1px solid ${theme.gray350};
  border-radius: 8px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmailOnboardingSuccess = () => {
  return (
    <>
      <MainBody>
        <StyledDiv>
          <img src={DoneCheckImage} height={100} width={100} />
          <H type="h4" color={theme.blue500} margin="24px 0px">
            Congratulations!
          </H>
          <H type="h5" color={theme.gray700} textAlign="center">
            Your form submission is complete, your employer will review your
            details now!
          </H>
        </StyledDiv>
      </MainBody>
    </>
  );
};

export default EmailOnboardingSuccess;
