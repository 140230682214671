import React, { FC, ReactNode, useEffect, useRef } from 'react';
import { Col, Dropdown, Layout as AntLayout, Menu, Row } from 'antd';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import styled from '@emotion/styled';
import {
  B,
  CustomIcon,
  Div,
  H,
  AvatarComponent,
  Image,
} from '../../../components';
import {
  getLoggedInSuperAdmin,
  getLoggedInUser,
  logOutUser,
  switchAuthPayload,
} from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import theme from '../../../theme';
import { Permission } from '../../../types/permissions.enum';
import { getPathWithOrgId } from '../../../util/getPathWithOrgId';
import { isAllowed } from '../../../util/permissionUtil';
import { useWindowSize } from '../../../util/screenSize';
import {
  getCurrentAuthIndex,
  isCurrentAuthSuperAdmin,
} from '../../../util/storageUtil';
import { addDotsForLongText } from '../../../util/utils';
import { getDesignation } from '../../people/PeopleUtil';
import BrandLogo from '../shared/BrandLogo';
import OrganizationDropdown from '../shared/OrganizationDropdown';
import { SubscriptionEnum } from '../../../types/subscription.types';
import { SearchPanel } from '../SearchPopover';
import { DropdownBlack, DropdownWhite, MenuOutSVG } from '../../../assets';

const { Header } = AntLayout;

interface Props {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  title: ReactNode;
  topMenuRef: React.RefObject<HTMLDivElement>;
}

const ContainerDiv = styled.div`
  display: flex;
  margin-top: 4px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    height: inherit;
  }
`;

const ColComp = styled(Col)`
  margin-left: 74px;
  margin-top: 12px;
`;

const MenuItem = styled(Menu.Item)`
  border-top: 1px solid #e0e0e0;
`;
const CustomMenu = styled(Menu)`
  min-width: 200px;
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 0px !important;
  .preferred-menu {
    box-sizing: border-box;
    background: ${theme.gray50}!important;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 12px 16px !important;
    border-bottom: 1px solid rgb(224, 224, 224);
  }
`;

const CustomHeader = styled(Header)<{ collapsed: boolean }>`
  padding: 0px 40px 0px 32px;
  position: fixed;
  right: 0;
  transition: width 0.2s;
  background: ${theme.white};
  height: 60px;
  z-index: 1000;
  left: ${({ collapsed }) => (collapsed ? '88px' : '262px')};
  border-bottom: 1px solid ${theme.lightBlue50};
  @media (max-width: 1024px) {
    padding: 0 16px;
    background: ${theme.blue900};
    left: 0;
  }
`;

const TopMenuBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MobileOnlyDiv = styled.div`
  display: flex;
  margin-top: 12px;
  @media (min-width: 1025px) {
    display: none;
  }
  height: inherit;
`;

const DesktopOnlyDiv = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  display: flex;
`;

const TopMenu: FC<Props> = ({ collapsed, setCollapsed, title, topMenuRef }) => {
  const { isMobile } = useWindowSize();
  const dispatch: DispatchType = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authIndex = getCurrentAuthIndex();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isCurrentAuthSuperAdmin()) {
      dispatch(getLoggedInSuperAdmin());
    } else {
      dispatch(getLoggedInUser());
    }
  }, []);
  const me = useSelector((state: RootState) => state.people.me);
  const authTokenPayloads = useSelector(
    (state: RootState) => state.auth.authTokenPayloads,
  );

  const menu = (
    <CustomMenu>
      {isMobile ||
        (authTokenPayloads.length > 1 && (
          <Menu.Item
            className="preferred-menu"
            disabled={me?.subscriptionStatus === SubscriptionEnum.SUSPENDED}>
            <span>
              <B type="b-default" color={theme.black}>
                {me?.preferredName
                  ? isMobile
                    ? addDotsForLongText(me?.preferredName, 15)
                    : me?.preferredName
                  : me?.lastName}
              </B>
              <B type="b-small" color={theme.gray600}>
                {getDesignation(me?.userHistory || [])}
              </B>
            </span>
          </Menu.Item>
        ))}
      {me?.subscriptionStatus !== SubscriptionEnum.SUSPENDED && (
        <>
          {!isCurrentAuthSuperAdmin() && (
            <Menu.Item className="menu-item">
              <a
                onClick={() => {
                  if (isAllowed(Permission.CREATE_EDIT_USERS)) {
                    navigate(`/contact/personal-details/${me?.id}`);
                  } else {
                    navigate(`/account-details/personal-details`);
                  }
                }}>
                <B type="b-default" color={theme.black}>
                  Profile Details
                </B>
              </a>
            </Menu.Item>
          )}
          <Menu.Item className="menu-item">
            <a onClick={() => navigate(`/security`)}>
              <B type="b-default" color={theme.black}>
                Change password
              </B>
            </a>
          </Menu.Item>
        </>
      )}
      {me?.roleId == 2 && (
        <Menu.Item className="menu-item">
          <a onClick={() => navigate(getPathWithOrgId('/subscription'))}>
            <B type="b-default" color={theme.black}>
              Subscription
            </B>
          </a>
        </Menu.Item>
      )}
      <Menu.Item className="menu-item">
        <a
          onClick={async () => {
            await dispatch(logOutUser());
            navigate('/login');
          }}>
          <B type="b-default" color={theme.black}>
            Sign Out
          </B>
        </a>
      </Menu.Item>
      {isMobile &&
      !!authTokenPayloads.length &&
      me?.subscriptionStatus != SubscriptionEnum.SUSPENDED ? (
        <>
          <Div background={theme.gray50} height="36px">
            <B type="b-large-semibold" mt="5px">
              Organization
            </B>
          </Div>
          <div>
            {authTokenPayloads.map((item, index) => {
              return (
                <MenuItem
                  disabled={
                    me?.subscriptionStatus === SubscriptionEnum.SUSPENDED
                  }
                  key={item.organizationId}
                  onClick={() => {
                    navigate(getPathWithOrgId('/', item.organizationId));
                    dispatch(switchAuthPayload(index));
                    queryClient.clear();
                  }}>
                  <Row>
                    <Col>
                      <B
                        type="b-default"
                        color={
                          authIndex == index ? theme.blue500 : theme.black
                        }>
                        {addDotsForLongText(item.organizationName, 20)}
                      </B>
                      <B type="b-small" color={theme.gray600}>
                        {item.role.role}
                      </B>
                    </Col>
                    {authIndex == index ? (
                      <ColComp>
                        <CustomIcon icon="blue-correct.svg" />
                      </ColComp>
                    ) : null}
                  </Row>
                </MenuItem>
              );
            })}
          </div>
        </>
      ) : null}
    </CustomMenu>
  );

  window.onscroll = function () {
    scrollFunction();
  };
  function scrollFunction() {
    const el: HTMLElement | null = document.getElementById('header');
    if (el !== null) {
      if (
        document.body.scrollTop > 10 ||
        document.documentElement.scrollTop > 10
      ) {
        el.style.boxShadow = '2px 2px 5px #dfe1e3';
      } else {
        el.style.boxShadow = 'none';
      }
    }
  }
  return (
    <>
      <CustomHeader ref={topMenuRef} id="header" collapsed={collapsed}>
        <ContainerDiv>
          <TopMenuBar className="trigger">
            <MobileOnlyDiv>
              <Div mt="4px">
                <BrandLogo collapsed={collapsed} />
              </Div>
              <div onClick={() => setCollapsed(!collapsed)}>
                <Image src={MenuOutSVG} mr="16px" />
              </div>
            </MobileOnlyDiv>
            <DesktopOnlyDiv>
              {location.pathname === '/' ? (
                <H type="h6" mr="16px">
                  {'Welcome ' + me?.preferredName + ' !'}
                </H>
              ) : (
                <H type="h6" mr="16px">
                  {title}
                </H>
              )}
            </DesktopOnlyDiv>
            {title === 'Home' && <SearchPanel />}
          </TopMenuBar>

          <Div display="flex" flexDirection="row" alignItems="center">
            {authTokenPayloads.length > 1 && (
              <OrganizationDropdown currentID={authIndex} />
            )}

            <Dropdown overlay={menu} trigger={['click']}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <>
                  {me?.profilePicture != null ? (
                    <AvatarComponent
                      src={me?.profilePicture}
                      size={32}
                      mr="10px"
                      shape="square"
                      border="none"
                    />
                  ) : (
                    <AvatarComponent
                      bg={theme.lightBlue100}
                      fontSize={24}
                      fontWeight={600}
                      size={32}
                      mr="10px"
                      shape="square"
                      title={
                        me?.preferredName
                          ? me?.preferredName
                          : me?.lastName
                          ? me?.lastName
                          : me?.fullName
                      }
                    />
                  )}
                </>

                {isMobile ? (
                  <Image src={DropdownWhite} paddingLeft="10px" />
                ) : (
                  <>
                    {authTokenPayloads.length <= 1 && (
                      <span>
                        <B type="b-default" color={theme.black}>
                          {me?.fullName
                            ? isMobile
                              ? addDotsForLongText(me?.fullName, 15)
                              : addDotsForLongText(me?.fullName, 25)
                            : me?.lastName}
                        </B>
                        <B type="b-small" color={theme.gray600}>
                          {getDesignation(me?.userHistory || [])}
                        </B>
                      </span>
                    )}
                    <Image src={DropdownBlack} paddingLeft="10px" />
                  </>
                )}
              </div>
            </Dropdown>
          </Div>
        </ContainerDiv>
      </CustomHeader>
    </>
  );
};

export default TopMenu;
