import React, { FC, ReactNode, useEffect, useState } from 'react';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import styled from '@emotion/styled';
import { B, TableComp } from '../../../../components';
import { LogTrailItemI } from '../../../../store/actions';
import { LogTrailPageI } from '../../../../types/logTrail.tyoes';
import { replaceJSX } from '../../../../util/replaceJSX';

const DateColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

const Footer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  border-radius: 4px 4px 0px 0px;
  align-items: center;
  background: #fafafa;
  padding: 10px 16px;
`;

const Table = styled(TableComp)`
  min-width: 100% !important;
`;
interface Props {
  logs: LogTrailItemI[];
  loading: boolean;
  pageDetails: LogTrailPageI | null;
  showMore: (pageCount: number) => void;
}

export const LeaveLogTrailTable: FC<Props> = ({
  logs,
  loading,
  pageDetails,
  showMore,
}) => {
  const [logTrailList, setLogTrailList] = useState<LogTrailItemI[]>([]);
  useEffect(() => {
    if (logTrailList.length > 0) {
      setLogTrailList([...logTrailList, ...logs]);
    } else {
      setLogTrailList(logs);
    }
  }, [logs]);
  const columns: ColumnProps<LogTrailItemI>[] = [
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      width: 200,
      align: 'left',
      render: (value: string) => (
        <DateColumn>{moment(value).format('YYYY-MM-DD hh:mm a')}</DateColumn>
      ),
    },
    {
      title: 'Activity',
      dataIndex: 'message',
      align: 'left',
      render: (_value, row) => renderLog(row),
    },
  ];

  const renderLog = (logItem: LogTrailItemI) => {
    const mapperKeys = Object.keys(logItem.mapper);
    let logMessage: Array<string | ReactNode> = [logItem.message];

    mapperKeys.forEach((mapperKey, i) => {
      const { display, id, type } = logItem.mapper[mapperKey];
      const findKey = `{@:${mapperKey}}`;
      let url: string | null = null;
      if (type === 'USER') {
        url = `/contact/personal-details/${id}`;
      }

      if (url) {
        const replaceComponent = (
          <a key={i} rel="_blank" href={url}>
            {display}
          </a>
        );
        logMessage = replaceJSX(logMessage, findKey, replaceComponent);
      }
    });
    return logMessage;
  };

  return (
    <>
      <Table
        size="middle"
        loading={loading}
        scroll={{
          x: 800,
        }}
        columns={columns}
        dataSource={logTrailList}
        pagination={false}
        withBorders={true}
      />
      {logTrailList.length > 0 && (
        <Footer>
          <B type="b-small" pr="15px">
            Showing {logTrailList.length} out of {pageDetails?.totalItems}
          </B>
          <div>
            {logTrailList.length === pageDetails?.totalItems ? null : (
              <B
                type="b-default"
                onClick={() =>
                  showMore(pageDetails ? pageDetails?.currentPage + 1 : 1)
                }
                color="#3375EE">
                Show More
              </B>
            )}
          </div>
        </Footer>
      )}
    </>
  );
};
