import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Dropdown, Menu } from 'antd';
import { debounce, includes } from 'lodash';
import {
  B,
  Div,
  InputComp,
  DropdownIcon,
  RadioButton,
  RadioGroup,
  RangePickerComp,
} from '../../../components';
import theme from '../../../theme';
import { useNavigate } from 'react-router';
import {
  GratuityFilterTypes,
  GratuityI,
  GratuityTypes,
} from '../../../types/gratuity.types';
import ActualGratuityTable from './ActualGratuityTable';
import PredictedGratuityTable from './PredictedGratuityTable';
import { useGetGratuityList } from '../../../api/gratuityHooks';
import moment, { Moment } from 'moment';
import { DropdownBlack, PayrollCalenderSVG, SearchSVG } from '../../../assets';

const menuItems = [
  {
    key: GratuityFilterTypes.ALL_GRATUITY,
    name: 'All Gratuity',
  },
  {
    key: GratuityFilterTypes.PENDING,
    name: 'Pending',
  },
  {
    key: GratuityFilterTypes.PAID,
    name: 'Paid',
  },
];

const Header = styled.div`
  padding: 24px 40px 24px 32px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: block;
    padding: 30px 22px 24px 16px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    padding-bottom: 30px;
  }
`;

const RightSection = styled.div`
  display: block;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

const AllEmpDropdown = styled(Dropdown)`
  margin: auto;
  color: ${theme.blue500};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  :hover {
    color: ${theme.blue500} !important;
  }
  .ant-dropdown .ant-dropdown-placement-bottomLeft {
    top: 255px !important;
  }
`;

const GratuityHome: FC = () => {
  const navigate = useNavigate();
  const [isActualGratuity, setIsActualGratuity] = useState(true);
  const [filter, setFilter] = useState<GratuityFilterTypes>(
    GratuityFilterTypes.ALL_GRATUITY,
  );
  const [dataSource, setDataSource] = useState<GratuityI[]>([]);
  const [filteredDataSource, setFilteredDataSource] = useState<GratuityI[]>([]);
  const [startDate, setStartDate] = useState<Moment | null>();
  const [endDate, setEndDate] = useState<Moment | null>();

  const actualGratuityList = dataSource.filter(
    (i) => i.gratuityType === GratuityTypes.ACTUAL,
  );
  const predictedGratuityList = dataSource.filter(
    (i) => i.gratuityType === GratuityTypes.ESTIMATION,
  );

  const { isLoading: loadingGratuity, data: gratuity = [] } =
    useGetGratuityList();

  const dateFilterList = filteredDataSource.filter((i) =>
    moment(i.dueDate).isBetween(startDate, endDate),
  );

  useEffect(() => {
    const list: GratuityI[] = [];
    if (!!gratuity.length) {
      gratuity?.forEach((gratuity) => {
        const gratuityTable: GratuityI = {
          ...gratuity,
        };
        if (
          filter === GratuityFilterTypes.PENDING &&
          gratuityTable.status === GratuityFilterTypes.PENDING
        ) {
          list.push(gratuityTable);
        } else if (
          filter === GratuityFilterTypes.PAID &&
          gratuityTable.status === GratuityFilterTypes.PAID
        ) {
          list.push(gratuityTable);
        } else if (filter === GratuityFilterTypes.ALL_GRATUITY) {
          list.push(gratuityTable);
        }
      });
      setDataSource(list);
      setFilteredDataSource(list);
    }
  }, [filter, gratuity]);

  const searchDataTableCallback = useCallback(
    debounce((searchText) => {
      const text = searchText.trim();
      if (text) {
        const filteredData = dataSource.filter((item) => {
          const fullText = item.name?.toLowerCase() + item.employeeNumber;
          return includes(fullText, text.toLowerCase());
        });
        setFilteredDataSource(filteredData);
      } else {
        setFilteredDataSource(dataSource);
      }
    }, 400),
    [dataSource],
  );

  const filterMenu = (
    <Menu>
      {menuItems.map((item) => (
        <Menu.Item onClick={() => setFilter(item.key)} key={item.key}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const threeDotMenu = () => (
    <Menu>
      <Menu.Item onClick={() => navigate('/gratuity/audit-logs')}>
        View LogTrail
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Header>
        <LeftSection>
          <AllEmpDropdown overlay={filterMenu} trigger={['click']}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}>
              <B type="b-large-semibold" display="flex">
                {isActualGratuity ? (
                  <>
                    {menuItems.find((item) => item.key === filter)?.name}(
                    {actualGratuityList.length})
                    <Div pl="10px">
                      <img src={DropdownBlack} />
                    </Div>
                  </>
                ) : (
                  <>All Gratuity({predictedGratuityList.length})</>
                )}
              </B>
            </a>
          </AllEmpDropdown>
        </LeftSection>
        <RightSection>
          <InputComp
            size="small"
            onChange={(e) => searchDataTableCallback(e.target.value)}
            placeholder="Search"
            prefix={<img src={SearchSVG} />}
          />
          <Div ml={16}>
            <RangePickerComp
              onChange={(values) => {
                setStartDate(moment(values?.[0].toDate()));
                setEndDate(moment(values?.[1].toDate()));
              }}
              suffixIcon={<img src={PayrollCalenderSVG} />}
            />
          </Div>
          <DropdownIcon
            marginLeft={16}
            overlay={threeDotMenu}
            placement="bottomRight"
            trigger={['click']}
          />
        </RightSection>
      </Header>
      <Div p="8px 32px 24px">
        <RadioGroup defaultValue="actual">
          <RadioButton value="actual" onClick={() => setIsActualGratuity(true)}>
            Actual
          </RadioButton>
          <RadioButton
            value="predicted"
            onClick={() => setIsActualGratuity(false)}>
            Predicted
          </RadioButton>
        </RadioGroup>
      </Div>
      {isActualGratuity ? (
        <ActualGratuityTable
          dataSource={
            !!startDate && !!endDate ? dateFilterList : filteredDataSource
          }
          loadingData={loadingGratuity}
        />
      ) : (
        <PredictedGratuityTable
          dataSource={
            !!startDate && !!endDate ? dateFilterList : filteredDataSource
          }
          loadingData={loadingGratuity}
        />
      )}
    </>
  );
};

export default GratuityHome;
