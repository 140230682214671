import React from 'react';
import { HeaderHumanisedNameIcon } from '../../assets';
import styled from '@emotion/styled';
import theme from '../../theme';

const NavBar = styled.div`
  background-color: ${theme.blue900};
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;

const StyledHeading = styled.h1`
  color: white;
  position: absolute;
  margin-top: 24px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: 700;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const StyledImg = styled.img`
  margin: 24px 0px 24px 48px;
  @media screen and (max-width: 768px) {
    margin: 24px;
  }
`;

const OnboardingFormNavBar = () => {
  return (
    <NavBar>
      <StyledImg src={HeaderHumanisedNameIcon} />
      <StyledHeading>Onboarding Form</StyledHeading>
    </NavBar>
  );
};

export default OnboardingFormNavBar;
