import { Col, Row } from 'antd';
import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducer';
import styled from '@emotion/styled';

interface Props {
  leftChildren?: ReactNode;
  rightChildren?: ReactNode;
  fullScreen?: boolean;
}
const RowFooter = styled(Row)<{ fullScreen?: boolean; isCollapsed: boolean }>`
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: ${(props) =>
    props.fullScreen ? '0' : `${props.isCollapsed ? 80 : 270}px`};
  height: 56px;
  right: 0;
  box-shadow: 0px -4px 25px rgba(0, 0, 0, 0.05);
  background: white;
  padding-right: 32px;
  padding-bottom: 16px;
  padding-top: 8px;
  padding-left: 32px;

  @media (max-width: 1024px) {
    left: 0px !important;
    padding-right: 17px;
    padding-left: 17px;
  }
`;
export const FooterComponent: FC<Props> = ({
  leftChildren,
  rightChildren,
  fullScreen,
}) => {
  const isCollapsed = useSelector((state: RootState) => state.app.isCollapsed);

  return (
    <RowFooter fullScreen={fullScreen} isCollapsed={isCollapsed}>
      <Col span={12}>{leftChildren}</Col>
      <Col span={12}>
        <Row justify="end">
          <Col>{rightChildren}</Col>
        </Row>
      </Col>
    </RowFooter>
  );
};
