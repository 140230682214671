import moment, { Moment } from 'moment';
import { TIME_FORMAT, HOURS_PER_DAY } from '../../../types';

interface GetLeaveDateRangeI {
  fromDate: string;
  toDate: string;
}

/**
 * Get moment dates between two dates
 */
export const getDatesBetween = ({
  fromDate,
  toDate,
}: GetLeaveDateRangeI): string[] => {
  const ranges: string[] = [];
  const diff = moment(toDate).diff(fromDate, 'days');
  for (let i = 0; i <= diff; i++) {
    const currantDate = moment(fromDate).add(i, 'days');
    ranges.push(currantDate.format('YYYY-MM-DD'));
  }
  return ranges;
};

export const getDurationInHoursMinutes = (
  fromTime: string | undefined,
  toTime: string | undefined,
): string => {
  const start = moment(fromTime, ['h:mm A']);
  const end = moment(toTime, ['h:mm A']);

  if (!fromTime || !toTime || !start.isValid() || !end.isValid())
    return 'invalid date';

  if (start.isBefore(end) || start.isSame(end)) {
    const difference = moment.duration(end.diff(start));
    return difference.hours() > 0 && difference.minutes() > 0
      ? `${difference.hours()}H ${difference.minutes()}M`
      : difference.minutes() > 0
      ? `${difference.minutes()}M`
      : `${difference.hours()}H`;
  }

  const difference = moment.duration(start.diff(end));
  return difference.hours() > 0 && difference.minutes() > 0
    ? `-${difference.hours()}H ${difference.minutes()}M`
    : difference.minutes() > 0
    ? `-${difference.minutes()}M`
    : `-${difference.hours()}H`;
};

export const getDurationInHoursAsFloat = (
  fromTime: string | undefined,
  toTime: string | undefined,
): number => {
  const start = moment(fromTime, [TIME_FORMAT]);
  const end = moment(toTime, [TIME_FORMAT]);
  const difference = moment.duration(end.diff(start));
  return Number(difference.asHours());
};

export const convertHoursToHoursAndMin = (hours: number) => {
  const duration = moment.duration(hours, 'hours');
  if (duration.minutes() > 0) {
    return duration.hours() > 0
      ? `${duration.hours()} hrs ${duration.minutes()} min`
      : `${duration.minutes()} min`;
  }
  return `${duration.hours()} hrs`;
};

/**
 * this will return full names or abbreviations of  time unit
 * props : isShort - determine time unit names should be abbreviations or not
 */
export const getTimeUnitNames = (isShort: boolean) => {
  if (isShort) {
    return {
      days: 'D',
      hour: 'H',
      minute: 'M',
    };
  }
  return {
    days: ' days',
    hour: ' hour',
    minute: ' min',
  };
};

export const getHoursDaysFromHours = (hours: number, isShort = false) => {
  if (hours === 0)
    return {
      hours: 0,
      days: 0,
      text: '-',
    };

  let text = '';
  if (hours < 0) {
    text += '-';
    hours = Math.abs(hours);
  }
  const h = hours % HOURS_PER_DAY;
  const hour = moment.duration(h, 'hours');
  const days = Math.floor(hours / HOURS_PER_DAY);
  const timeUnitNames = getTimeUnitNames(isShort);

  if (days > 0) {
    if (hour.hours() > 0 || hour.minutes() > 0)
      text += `${days}${timeUnitNames.days} `;
    else text += `${days}${timeUnitNames.days}`;
  }
  if (hour.hours() > 0) {
    if (hour.minutes() > 0) text += `${hour.hours()}${timeUnitNames.hour} `;
    else text += `${hour.hours()}${timeUnitNames.hour}`;
  }
  if (hour.minutes() > 0) text += `${hour.minutes()}${timeUnitNames.minute}`;

  return {
    hours: h,
    days,
    text,
  };
};

export const getDisabledTimeSlots = (startTime: string | undefined) => {
  if (startTime) {
    const startTimeNum = parseInt(
      moment(startTime, ['HH:mm A']).format('HH:mm'),
    );
    const timeArr: number[] = [];

    // Explanation: Adding working hours before startTime to array
    for (let i = 0; i < startTimeNum; i++) {
      timeArr.push(i);
    }
    return timeArr;
  } else return [];
};

export const isDisabledEndDate = (endValue: Moment, startValue?: Moment) => {
  if (!endValue || !startValue) {
    return false;
  }
  return moment(endValue).valueOf() < moment(startValue).valueOf();
};

export const isDisabledLeaveEndDate = (
  currantDate: Moment,
  startValue: Moment,
  leaveResetDate: number,
  resignedDate?: string,
) => {
  const disableBeforeStart =
    moment(currantDate).valueOf() < moment(startValue).valueOf();
  const disableNextYears = leaveResetDate < moment(currantDate).valueOf();

  const isAfterResignedDate = resignedDate
    ? currantDate.isAfter(resignedDate)
    : false;

  return disableBeforeStart || disableNextYears || isAfterResignedDate;
};

export const isDisabledLeaveStartDate = (
  currantDate: Moment,
  isResetDateAvailable: boolean,
  leaveResetDate: Moment,
  resignedDate?: string,
) => {
  let disablePastYears: boolean;
  let disableNextYears: boolean;
  let leaveResetStartDate: number = moment().startOf('year').valueOf();
  let leaveResetEndDate: number = moment().endOf('year').valueOf();

  const isAfterResignedDate = resignedDate
    ? currantDate.isAfter(moment(resignedDate))
    : false;

  if (isResetDateAvailable) {
    if (moment().isAfter(moment(leaveResetDate))) {
      leaveResetStartDate = moment(leaveResetDate).valueOf();
      leaveResetEndDate = moment(leaveResetDate).add(1, 'year').valueOf();
      disablePastYears = currantDate.valueOf() < leaveResetStartDate;
      disableNextYears = leaveResetEndDate < currantDate.valueOf();
      return disablePastYears || disableNextYears || isAfterResignedDate;
    } else {
      leaveResetStartDate = moment(leaveResetDate)
        .subtract(1, 'year')
        .valueOf();
      leaveResetEndDate = moment(leaveResetDate).valueOf();
      disablePastYears = currantDate.valueOf() < leaveResetStartDate;
      disableNextYears = leaveResetEndDate < currantDate.valueOf();

      return disablePastYears || disableNextYears || isAfterResignedDate;
    }
  }

  disablePastYears = currantDate.valueOf() < leaveResetStartDate;
  disableNextYears = leaveResetEndDate < currantDate.valueOf();
  return disablePastYears || disableNextYears || isAfterResignedDate;
};
