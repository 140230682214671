import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { ResetPasswordI } from '../store/actions';

export const useForgetPasswordRequest = () => {
  return useMutation<string, AxiosError, string>(async (email: string) => {
    const response: AxiosResponse<string> = await axios.post(
      '/auth/forgotPassword',
      { email: email },
    );
    return response.data;
  });
};

export const useResetPassword = () => {
  return useMutation<string, AxiosError, ResetPasswordI>(
    async (data: ResetPasswordI) => {
      const response: AxiosResponse<string> = await axios.post(
        '/auth/resetPassword',
        data,
      );
      return response.data;
    },
  );
};
