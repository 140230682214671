import React, { FC, useState } from 'react';
import { Row } from 'antd';
import LeavesBalance from './LeavesBalance';
import { PrimaryButton, B, CustomDivider } from '../../../components';
import { LeaveBalance } from '../../../types';
import ApplyLeaveModal from './ApplyLeaveModal';
import { useNavigate } from 'react-router';
import { useGetLeaveStatus } from '../utils/leave.util';
import styled from '@emotion/styled';
import { PeopleI } from '../../../types/people.types';
import theme from '../../../theme';
import { getCurrentAuthPayload } from '../../../util/storageUtil';
import { disableSickleavesOrg } from '../../../configs/appConfig';

interface Props {
  leaveData: LeaveBalance[];
  allPeoples: PeopleI[];
  userId: number | undefined;
}
interface LeaveListProps {
  isShort?: boolean;
}

const LeaveOverviewDiv = styled.div`
  border-bottom: 1px solid ${theme.gray300};
  padding: 16px 0px;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;
const RequestLeaveButton = styled.div`
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;
const OverviewLeave = styled.div`
  padding: 24px 0px 0px 0px;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;
const LeaveOverviewListDiv = styled.div`
  margin: 24px 0px 16px 0px;
  @media only screen and (max-width: 400px) {
    display: none;
  }
`;
const FooterComp = styled.div`
  margin-left: auto;
  padding-right: 12px;
  width: 130px;
`;
const LeaveOverview: FC<Props> = ({ leaveData = [], userId }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const leaveStatus = useGetLeaveStatus(leaveData || []);

  const LeavesBalanceDiv = styled.div`
    padding-right: ${leaveData.length > 4 ? '32px' : '50px'};
  `;

  const LeaveOverviewList: FC<LeaveListProps> = ({ isShort = false }) => (
    <>
      <LeavesBalance
        leaveTitle="Annual Leave Balance"
        leavesBalance={leaveStatus.annualLeaves}
        isMobile
        isShort={isShort}
      />
      <LeavesBalance
        leaveTitle="Sick Leave Balance"
        leavesBalance={leaveStatus.sickLeaves}
        isMobile
        isShort={isShort}
      />
      <LeavesBalance
        leaveTitle="Casual Leave Balance"
        leavesBalance={leaveStatus.casualLeaves}
        isMobile
        isShort={isShort}
      />
      {leaveStatus.noPay ? (
        <LeavesBalance
          leaveTitle="No Pay Leave Balance"
          leavesBalance={leaveStatus.noPay}
          isMobile
          isShort={isShort}
        />
      ) : null}
      {leaveStatus.studyLeave ? (
        <LeavesBalance
          leaveTitle="Study Leave Balance"
          leavesBalance={leaveStatus.studyLeave}
          isMobile
          isShort={isShort}
        />
      ) : null}
      {leaveStatus.trainingLeave ? (
        <LeavesBalance
          leaveTitle="Training Leave Balance"
          leavesBalance={leaveStatus.trainingLeave}
          isMobile
          isShort={isShort}
        />
      ) : null}
      {leaveStatus.maternityLeave ? (
        <LeavesBalance
          leaveTitle="Maternity Leave Balance"
          leavesBalance={leaveStatus.trainingLeave}
          isMobile
          isShort={isShort}
        />
      ) : null}
      {leaveStatus.workOnHoliday ? (
        <LeavesBalance
          leaveTitle="Work On Holiday"
          leavesBalance={leaveStatus.workOnHoliday}
          isMobile
          isShort={isShort}
        />
      ) : null}
      {leaveStatus.lieuLeave ? (
        <LeavesBalance
          leaveTitle="Lieu Leave"
          leavesBalance={leaveStatus.lieuLeave}
          isMobile
          isShort={isShort}
        />
      ) : null}
    </>
  );

  return (
    <>
      <OverviewLeave>
        <Row justify="space-between" align="middle">
          <B type="b-large-semibold"> Leave </B>
          <RequestLeaveButton>
            <PrimaryButton
              htmlType="submit"
              onClick={() => navigate('/leave/apply')}>
              Request Leave
            </PrimaryButton>
          </RequestLeaveButton>
        </Row>
        <LeaveOverviewListDiv>
          <LeaveOverviewList />
        </LeaveOverviewListDiv>
        <LeaveOverviewListDiv>
          <LeaveOverviewList isShort />
        </LeaveOverviewListDiv>
        <CustomDivider m="16px 0px" />
      </OverviewLeave>

      <LeaveOverviewDiv>
        <Row>
          <B
            type="b-default-semibold"
            pr={leaveData.length > 4 ? '38px' : '71px'}>
            Leave Balance
          </B>
          <LeavesBalanceDiv>
            <LeavesBalance
              leaveTitle="Annual Leave "
              leavesBalance={leaveStatus.annualLeaves}
              isShort={true}
            />
          </LeavesBalanceDiv>
          {/* FIXME */}
          {getCurrentAuthPayload()?.organizationId !== disableSickleavesOrg ? (
            <LeavesBalanceDiv>
              <LeavesBalance
                leaveTitle="Sick Leave"
                leavesBalance={leaveStatus.sickLeaves}
                isShort={true}
              />
            </LeavesBalanceDiv>
          ) : null}
          <LeavesBalanceDiv>
            <LeavesBalance
              leaveTitle="Casual Leave"
              leavesBalance={leaveStatus.casualLeaves}
              isShort={true}
            />
          </LeavesBalanceDiv>
          <LeavesBalanceDiv>
            {leaveStatus.noPay ? (
              <LeavesBalance
                leaveTitle="No Pay"
                leavesBalance={leaveStatus.noPay}
                isShort={true}
              />
            ) : null}
          </LeavesBalanceDiv>
          {leaveStatus.studyLeave ? (
            <LeavesBalanceDiv>
              <LeavesBalance
                leaveTitle="study Leave"
                leavesBalance={leaveStatus.studyLeave}
                isShort={true}
              />
            </LeavesBalanceDiv>
          ) : null}
          {leaveStatus.trainingLeave ? (
            <LeavesBalanceDiv>
              <LeavesBalance
                leaveTitle="Training Leave"
                leavesBalance={leaveStatus.trainingLeave}
                isShort={true}
              />
            </LeavesBalanceDiv>
          ) : null}
          {leaveStatus.workOnHoliday ? (
            <LeavesBalanceDiv>
              <LeavesBalance
                leaveTitle="Work On Holiday"
                leavesBalance={leaveStatus.workOnHoliday}
                isShort={true}
              />
            </LeavesBalanceDiv>
          ) : null}
          {leaveStatus.maternityLeave ? (
            <LeavesBalanceDiv>
              <LeavesBalance
                leaveTitle="Maternity Leave"
                leavesBalance={leaveStatus.maternityLeave}
                isShort={true}
              />
            </LeavesBalanceDiv>
          ) : null}
          {leaveStatus.lieuLeave ? (
            <LeavesBalanceDiv>
              <LeavesBalance
                leaveTitle="Lieu Leave"
                leavesBalance={leaveStatus.lieuLeave}
                isShort={true}
              />
            </LeavesBalanceDiv>
          ) : null}
          <FooterComp>
            <PrimaryButton
              size="small"
              htmlType="submit"
              onClick={() => {
                setVisible(true);
              }}>
              Apply Leave
            </PrimaryButton>
          </FooterComp>
        </Row>
        {visible && (
          <ApplyLeaveModal userId={userId} onClose={() => setVisible(false)} />
        )}
      </LeaveOverviewDiv>
    </>
  );
};
export default LeaveOverview;
