import { AxiosError } from 'axios';

import logger from './logger';

export function getApiError(error: AxiosError, defaultError?: string): string {
  const errorMessage = error.response?.data;
  if (error) {
    if (error.response) {
      const { config, status } = error.response;
      logger.info(status, config.url, error.response);
    } else {
      logger.info('getApiError', error);
    }
  }

  if (errorMessage) {
    if (typeof errorMessage.message === 'string') {
      return errorMessage.message;
    }
    if (typeof errorMessage === 'string') {
      return errorMessage;
    }
    if (typeof errorMessage.error === 'string') {
      return errorMessage.error;
    }
  } else {
    logger.error('error', error);
  }

  return defaultError || 'Something went wrong';
}
