import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { B } from '../../../../../components';
import theme from '../../../../../theme';
import { camelCaseToNormalText } from '../../../../../util/camelCaseToNormal.util';
import { DATE_FORMATS } from '../../../../../configs/dateConfig';
import moment from 'moment';

interface CurrentEmploymentI {
  designation: string | null;
  employmentType: string | null;
  startDate: string | null;
}
interface Props {
  currentEmployment?: CurrentEmploymentI;
}
const CurrentEmploymentDetails: FC<Props> = ({ currentEmployment }) => {
  return (
    <>
      <B type="b-default-semibold" py="16px">
        Current Employment Details
      </B>
      <Row gutter={[32, 12]}>
        {currentEmployment ? (
          <>
            {Object.entries(currentEmployment).map(([key, v]) => (
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <B type="b-default" color={theme.gray600}>
                  {camelCaseToNormalText(key)}
                </B>
                <B type="b-default" color={theme.black}>
                  {key == 'startDate'
                    ? moment(v).format(DATE_FORMATS.iso_date_format)
                    : v}
                </B>
              </Col>
            ))}
          </>
        ) : null}{' '}
      </Row>
    </>
  );
};

export default CurrentEmploymentDetails;
