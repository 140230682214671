import { RoundOffEnumValue } from '../containers/time-attendance/time-attendance.enum';
import { AttendanceFilterTypes } from '../types/attendance.types';

export const roundOffOptions = [
  {
    label: '15 Mins',
    value: 15,
  },
  {
    label: '30 Mins',
    value: 30,
  },
  //keep it as comment until clarify the requirement
  // {
  //   label: '1 Hour',
  //   value: 60,
  // },
];

export const roundOffHighLow = [
  {
    label: 'Low',
    value: RoundOffEnumValue.TO_LOW,
  },
  {
    label: 'High',
    value: RoundOffEnumValue.TO_HIGH,
  },
];

export const minOvertimeOptions = [
  {
    label: '30 Mins',
    value: 30,
  },
  {
    label: '1 Hour',
    value: 60,
  },
  {
    label: '2 Hours',
    value: 120,
  },
  {
    label: '4 Hours',
    value: 240,
  },
];

export const attendanceMenuItems = [
  {
    key: AttendanceFilterTypes.ALL,
    name: 'All Time Attendance',
  },
  {
    key: AttendanceFilterTypes.DRAFT,
    name: 'Draft',
  },
  {
    key: AttendanceFilterTypes.APPROVED,
    name: 'Approved',
  },
  {
    key: AttendanceFilterTypes.ARCHIVED,
    name: 'Archived',
  },
];
