import React, { FC } from 'react';
import { Badge, Select, Tooltip } from 'antd';
import { B, SelectComp } from '../../../components';
import {
  AssignableUsersI,
  AssignedEditPlaceholderDataI,
} from '../../../types/contract.types';
import { getPlaceholderName } from '../utils/getPlaceholderName';
import theme from '../../../theme';
import styled from '@emotion/styled';
import { useGetAssignedUsers } from '../utils/assignedUsers';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';

const { Option } = Select;

const TitleContainer = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${theme.gray300};
`;
const RecipientContainer = styled.div`
  padding: 24px 16px;
  border-bottom: 1px solid ${theme.gray300};
`;
const RequiredContainer = styled.div`
  padding: 24px 16px;
  border-bottom: 1px solid ${theme.gray300};
`;
const FooterContainer = styled.div`
  bottom: 70px;
  position: fixed;
  width: -webkit-fill-available;
  padding: 34px 24px;
`;

const SelectComponent = styled(SelectComp)`
  width: 192px;
  border-radius: 4px;
  margin-top: 4px;
`;

interface Props {
  selectedPlaceholder: AssignedEditPlaceholderDataI;
  onClickDelete: () => void;
  assignableUsers: AssignableUsersI[];
  onChange: (selectedPlaceholder: AssignedEditPlaceholderDataI) => void;
}

const PlaceholderInfoPanel: FC<Props> = ({
  selectedPlaceholder,
  onClickDelete,
  assignableUsers,
  onChange,
}) => {
  const { firstSignatory, secondSignatory } =
    useGetAssignedUsers(assignableUsers);
  return (
    <>
      <TitleContainer>
        <B type="b-default-semibold">
          {getPlaceholderName(selectedPlaceholder.type)}
        </B>
      </TitleContainer>
      <RecipientContainer>
        <B type="b-default-semibold" color={theme.blue700}>
          Recipient
        </B>

        <SelectComponent
          showSearch
          onSelect={(e) => {
            selectedPlaceholder.assignedUserId = e as number;
            onChange(selectedPlaceholder);
          }}
          value={selectedPlaceholder.assignedUserId || undefined}
          size="large"
          placeholder="Select Employee"
          optionFilterProp="children"
          defaultValue={secondSignatory?.id}>
          {assignableUsers.map((user) => {
            const extraText = ` (${
              firstSignatory?.id == user.id ? '1st' : '2nd'
            })`;
            const tooltipExtraText = ` (${
              firstSignatory?.id == user.id ? '1st signatory' : '2nd signatory'
            })`;
            return (
              <Option key={user.id} value={user.id}>
                <Tooltip title={`${user.fullName} ${tooltipExtraText}`}>
                  <Badge
                    color={
                      firstSignatory?.id == user.id
                        ? theme.contract.receiver.label
                        : theme.contract.sender.label
                    }
                  />
                  {` ${user.fullName}`}
                  <span dangerouslySetInnerHTML={{ __html: extraText }} />
                </Tooltip>
              </Option>
            );
          })}
        </SelectComponent>
      </RecipientContainer>
      <RequiredContainer>
        <CheckBoxComp
          defaultChecked={selectedPlaceholder.required}
          checked={selectedPlaceholder.required}
          onChange={(e) => {
            selectedPlaceholder.required = e.target.checked;
            onChange(selectedPlaceholder);
          }}>
          <B display="inline-block" type="b-default">
            Required field
          </B>
        </CheckBoxComp>
      </RequiredContainer>
      <FooterContainer>
        <a onClick={() => onClickDelete()}>
          <B type="b-default" color={theme.blue500}>
            Remove {getPlaceholderName(selectedPlaceholder.type)}
          </B>
        </a>
      </FooterContainer>
    </>
  );
};
export default PlaceholderInfoPanel;
