import React from 'react';
import { minutesToNearestHour } from '../../../util/timeConvertion';
import { IUserAttendanceSummary } from '../../../types/attendance.types';
import XLSX from 'xlsx';

interface ExportAttendanceDataProps {
  dataSource: IUserAttendanceSummary[];
}

const AttendanceExport: React.FC<ExportAttendanceDataProps> = ({
  dataSource,
}) => {
  const downloadFile = () => {
    const headers = [
      { v: 'Emp Id', s: { font: { bold: true } } },
      { v: 'Display Name', s: { font: { bold: true } } },
      { v: 'OT Hours', s: { font: { bold: true } } },
      { v: 'No Pay Hours', s: { font: { bold: true } } },
      { v: 'No of Attendance', s: { font: { bold: true } } },
    ];

    const data = dataSource?.map((item) => [
      item.employeeId,
      item.fullName,
      item.totalOverTimeMinutes?.toString()
        ? minutesToNearestHour(item.totalOverTimeMinutes)
        : '-',
      item.totalNoPayMinutes?.toString()
        ? minutesToNearestHour(item.totalNoPayMinutes)
        : '-',
      item.totalWorkingDays,
    ]);

    const ws_data = [headers, ...data];
    const ws = XLSX.utils.aoa_to_sheet(ws_data);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1,
    ).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

    XLSX.writeFile(wb, `Time_Attendance_Data_${formattedDate}.xlsx`);
  };

  return (
    <>
      <div onClick={downloadFile}>Download</div>
    </>
  );
};

export default AttendanceExport;
