import { message } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import {
  DocumentDetailsI,
  DocumentI,
  DocumentListI,
} from '../types/document.types';
import { getApiError } from '../util/getApiError';

export const useOrganizationFileUpload = () => {
  return useMutation<string, AxiosError, File>(
    async (data: File) => {
      const formData = new FormData();
      formData.append('file', data);
      const response: AxiosResponse<string> = await axios.post(
        `/organization/fileUpload`,
        formData,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};
export const useUserFileUpload = () => {
  return useMutation<string, AxiosError, DocumentDetailsI>(
    async (data: DocumentDetailsI) => {
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('title', data.title);
      formData.append('type', data.type);
      formData.append('userId', data.userId);

      const response: AxiosResponse<string> = await axios.post(
        `/organization/employeeFileUpload`,
        formData,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useAddUserDocuments = () => {
  return useMutation<string, AxiosError, DocumentI>(
    async (data: DocumentI) => {
      const response: AxiosResponse<string> = await axios.post(
        `/documents/userFile`,
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully Uploaded');
      },
    },
  );
};

export const useAddMeDocuments = () => {
  return useMutation<string, AxiosError, DocumentI>(
    async (data: DocumentI) => {
      const response: AxiosResponse<string> = await axios.post(
        `/documents/me/userFile`,
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully Uploaded');
      },
    },
  );
};

export const useGetMeDocument = () => {
  return useQuery<DocumentListI[], AxiosError>(
    'documentList',
    async () => {
      const response: AxiosResponse<DocumentListI[]> = await axios.get(
        '/documents/me/documentList',
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useDeleteMeDocument = () => {
  return useMutation<string, AxiosError, string>(
    async (documentId) => {
      const response: AxiosResponse<string> = await axios.delete(
        `/documents/me/userDocument/${documentId}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully deleted the Document.');
      },
    },
  );
};

export const useDeleteUserDocument = () => {
  return useMutation<string, AxiosError, number>(
    async (documentId) => {
      const response: AxiosResponse<string> = await axios.delete(
        `/documents/userdocument/${documentId}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully deleted the Document.');
      },
    },
  );
};

export const useGetUserDocuments = () => {
  return useMutation<DocumentListI[], AxiosError, string>(async (userId) => {
    const response: AxiosResponse<DocumentListI[]> = await axios.get(
      `/documents/documentlist/${userId}`,
    );
    return response.data;
  });
};

export const usePublicUserFileUpload = () => {
  return useMutation<string, AxiosError, DocumentDetailsI>(
    async (data: DocumentDetailsI) => {
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('title', data.title);
      formData.append('type', data.type);
      formData.append('userId', data.userId);
      formData.append('token', data.token);

      const response: AxiosResponse<string> = await axios.post(
        `/organization/publicEmployeeFileUpload`,
        formData,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};
