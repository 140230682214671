import React, { FC } from 'react';
import { Result } from 'antd';
import { Div } from '../../components';

const SuspendPage: FC = () => {
  return (
    <Div mx="25%" mt="20px">
      <Result
        status="403"
        subTitle="“Your account has been temporarily suspended because payment has not been received within 60 days of the invoice date. If you believe there has been an error, please contact your customer success representative for assistance“"
      />
    </Div>
  );
};
export default SuspendPage;
