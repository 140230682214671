import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { message } from 'antd';
import styled from '@emotion/styled';
import { B, Div, PrimaryButton, SecondaryButton } from '../../../components';
import { getOAuth } from '../../../store/actions';
import theme from '../../../theme';
import {
  IntegrationItem,
  IntegrationStatusEnum,
  IntegrationItemSetting,
} from '../../../types';
import IntegrationAuthorizeModal from './IntegrationAuthorizeModal';
import { DispatchType } from '../../../store';

const CardContainer = styled.div`
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  padding: 36px 24px 28px;
  max-width: 344px;
  max-height: 320px;
  border-radius: 4px;
  &:hover {
    background: ${theme.gray50};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  }
`;

const TagContainer = styled.span<{
  integrationSettings: IntegrationItemSetting | null;
}>`
  background: ${(props) =>
    props.integrationSettings?.status === IntegrationStatusEnum.ACTIVATED
      ? theme.green50
      : props.integrationSettings?.status === IntegrationStatusEnum.PENDING
      ? theme.orange50
      : theme.red50};
  padding: 0px 8px 0px 8px;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) =>
    props.integrationSettings?.status === IntegrationStatusEnum.ACTIVATED
      ? theme.green300
      : props.integrationSettings?.status === IntegrationStatusEnum.PENDING
      ? theme.orange300
      : theme.red600};
`;

interface Props {
  item: IntegrationItem;
}

const IntegrationCard: FC<Props> = ({ item }) => {
  const [visible, setVisible] = useState(false);
  const { title, shortDescription, logo, integrationSettings } = item;
  const dispatch: DispatchType = useDispatch();
  const navigate = useNavigate();
  const integrationConfigured =
    integrationSettings &&
    integrationSettings.status !== IntegrationStatusEnum.DEACTIVATED;
  return (
    <>
      <CardContainer>
        <Div display=" flex" justifyContent="space-between">
          <div>
            <img src={logo} />
          </div>
          <div>
            {integrationConfigured && (
              <TagContainer integrationSettings={integrationSettings}>
                {' '}
                {integrationSettings?.status === IntegrationStatusEnum.ACTIVATED
                  ? 'Active'
                  : integrationSettings?.status ===
                    IntegrationStatusEnum.PENDING
                  ? 'Pending'
                  : 'Action Required'}
              </TagContainer>
            )}
          </div>
        </Div>
        <div>
          <B type="b-large-semibold" mt="24px" mb="8px">
            {title}
          </B>
          <div>
            <B type="b-default" color={theme.gray600} marginBottom="48px">
              {shortDescription}
            </B>
          </div>
        </div>
        {integrationConfigured ? (
          <SecondaryButton
            size="small"
            onClick={() => navigate(`/company-settings/integrations/${title}`)}>
            View
          </SecondaryButton>
        ) : (
          <PrimaryButton size="small" onClick={() => setVisible(true)}>
            Configure
          </PrimaryButton>
        )}
      </CardContainer>
      {visible ? (
        <IntegrationAuthorizeModal
          onClose={() => setVisible(false)}
          item={item}
          onSubmit={async () => {
            try {
              dispatch(getOAuth(item.id));
            } catch (error: any) {
              message.error('Something went wrong');
            }
            setVisible(false);
          }}
        />
      ) : null}
    </>
  );
};

export default IntegrationCard;
