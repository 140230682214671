import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Row, message } from 'antd';
import Dropzone from 'react-dropzone';
import styled from '@emotion/styled';
import { B, InputComp, ModalComponent } from '../../../components';
import theme from '../../../theme';
import { TemplateI, UploadTemplateI } from '../../../types/contract.types';
import { UploadDocumentSVG } from '../../../assets';
import pdfPlaceHolderImage from '../../../assets/images/pdf-place-holder.png';

interface Props {
  visible: boolean;
  handleCancel: () => void;
  onSubmit: (values: UploadTemplateI) => void;
}

interface FileWithPreviewI extends File {
  preview: string;
}

const CustomRow = styled(Row)`
  background: ${theme.gray150};
  border: 1px dashed ${theme.blue500};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 55px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;
const FormContainer = styled(Form)`
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: none;
  }
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: #00318c;
  }
  .ant-input[disabled] {
    color: #000000;
  }
  .input-delete-icon {
    color: ${theme.gray600};
  }
  .ant-upload-span {
    margin-top: 16px;
    .ant-input-affix-wrapper {
      border-radius: 4px;
    }
  }
  button {
    margin-bottom: 5px;
  }
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const UploadTemplateModal: FC<Props> = ({
  visible,
  handleCancel,
  onSubmit,
}) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [isFile, setIsFile] = useState(false);

  const [fileInfo, setFileInfo] = useState<{
    file: FileWithPreviewI | null;
    fileName: string | null;
  }>({
    file: null,
    fileName: null,
  });

  useEffect(() => {
    form.setFieldsValue({ fileName: fileInfo.fileName });
  }, [fileInfo.fileName]);

  const uploadValidator = (file) => {
    const isLt10M = file.size / 1024 / 1024 > 10;
    if (isLt10M) {
      message.error('File must smaller than 10MB!');
      return {
        code: 'name-too-large',
        message: `Name is larger than 1 characters`,
      };
    }
    return null;
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      file.preview = URL.createObjectURL(file);
      const fileName = file.name.slice(0, file.name.lastIndexOf('.'));
      setFileInfo({ fileName, file });
      setIsFile(true);
    }
  };
  const onFinish = (formValues: TemplateI) => {
    const values = {
      title: formValues.title,
      file: fileInfo.file,
      comment: formValues.comment,
    };
    onSubmit(values);
  };

  return (
    <ModalComponent
      width={540}
      form={form}
      submitText="Upload"
      disabled={!isFile}
      visible={visible}
      title={
        <div
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => setDisabled(true)}>
          <B type="b-large-semibold">Upload Template</B>
        </div>
      }
      onCancel={handleCancel}>
      <FormContainer
        form={form}
        validateTrigger="onBlur"
        layout="vertical"
        onFinish={(v) => onFinish(v as TemplateI)}>
        <Form.Item
          name="title"
          label="Title"
          validateFirst={true}
          rules={[
            {
              required: true,
              message: 'Please enter file name!',
            },
          ]}>
          <InputComp placeholder="Enter title" />
        </Form.Item>
        <Form.Item
          name="comment"
          label="Comment"
          validateFirst={true}
          rules={[
            {
              required: true,
              message: 'Please enter file name!',
            },
          ]}>
          <Input.TextArea placeholder="Enter your comment" rows={4} />
        </Form.Item>
        <FormItem>
          <Dropzone
            onDrop={handleDrop}
            accept=".pdf"
            minSize={1024}
            maxSize={10240000}
            validator={uploadValidator}>
            {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => {
              const additionalClass = isDragAccept
                ? 'accept'
                : isDragReject
                ? 'reject'
                : '';

              return (
                <CustomRow
                  justify="space-around"
                  align="middle"
                  {...getRootProps({
                    className: `dropzone ${additionalClass}`,
                  })}>
                  {fileInfo.file ? (
                    <div>
                      <input {...getInputProps()} />
                      <img
                        style={{ maxWidth: '370px', maxHeight: '160px' }}
                        src={pdfPlaceHolderImage}
                        onLoad={() => {
                          URL.revokeObjectURL(fileInfo.file.preview);
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <input {...getInputProps()} />
                      <div className="ant-upload-drag-icon">
                        <img src={UploadDocumentSVG} />
                      </div>
                      <B
                        type="b-large-semibold"
                        color={theme.gray600}
                        pt="8px"
                        display="flex">
                        Drag and drop or
                        <B
                          type="b-large-semibold"
                          color={theme.blue400}
                          pl="5px">
                          Browse
                        </B>
                      </B>
                      <B type="b-default" color={theme.gray600} pt="4px">
                        Upload your template document in pdf
                      </B>
                      <B type="b-small" color={theme.gray600} pt="4px">
                        Maximum file size: 10 MB
                      </B>
                    </>
                  )}
                </CustomRow>
              );
            }}
          </Dropzone>
        </FormItem>
      </FormContainer>
    </ModalComponent>
  );
};
export default UploadTemplateModal;
