import { Row, Col } from 'antd';
import { sum } from 'lodash';
import React, { FC } from 'react';
import { B, Div } from '../../../../components';
import theme from '../../../../theme';
import { LoanI, LoanStatus } from '../../../../types/loan.types';
import { numberThousandSeparator } from '../../../../util/utils';

interface Props {
  loanList: LoanI[];
}

const LoanDetailsOverview: FC<Props> = ({ loanList }) => {
  const totalLoanAmount = loanList
    .filter((item) => item.loanStatus === LoanStatus.ACTIVE)
    .map((item) => {
      return item.loanAmount;
    });

  const totalInterestAmount = loanList
    .filter((item) => item.loanStatus === LoanStatus.ACTIVE)
    .map((item) => {
      return item.interestAmount;
    });

  const totalPaidAmount = loanList
    .filter((item) => item.loanStatus === LoanStatus.ACTIVE)
    .map((item) => {
      return item.paidAmount;
    });

  const totalBalanceAmount = loanList
    .filter((item) => item.loanStatus === LoanStatus.ACTIVE)
    .map((item) => {
      return item.balanceAmount;
    });

  return (
    <Row gutter={24}>
      <Col xs={12} sm={12} md={5} lg={5} xl={5}>
        <B type="b-small" color={theme.gray600}>
          Loan Amount
        </B>
        <Div display="flex">
          <B type="b-small" color={theme.gray600} pr="4px" pt="3px">
            LKR
          </B>
          <B type="b-large-semibold">
            {numberThousandSeparator(sum(totalLoanAmount))}
          </B>
        </Div>
      </Col>
      <Col xs={12} sm={12} md={5} lg={5} xl={5}>
        <B type="b-small" color={theme.gray600}>
          Interest Amount
        </B>
        <Div display="flex">
          <B type="b-small" color={theme.gray600} pr="4px" pt="3px">
            LKR
          </B>
          <B type="b-large-semibold">
            {numberThousandSeparator(sum(totalInterestAmount))}
          </B>
        </Div>
      </Col>
      <Col xs={12} sm={12} md={5} lg={5} xl={5}>
        <B type="b-small" color={theme.gray600}>
          Paid Amount
        </B>
        <Div display="flex">
          <B type="b-small" color={theme.gray600} pr="4px" pt="3px">
            LKR
          </B>
          <B type="b-large-semibold">
            {numberThousandSeparator(sum(totalPaidAmount))}
          </B>
        </Div>
      </Col>
      <Col xs={12} sm={12} md={5} lg={5} xl={5}>
        <B type="b-small" color={theme.gray600}>
          Balance Amount
        </B>
        <Div display="flex">
          <B type="b-small" color={theme.gray600} pr="4px" pt="3px">
            LKR
          </B>
          <B type="b-large-semibold">
            {numberThousandSeparator(sum(totalBalanceAmount))}
          </B>
        </Div>
      </Col>
    </Row>
  );
};

export default LoanDetailsOverview;
