import React, { FC, useState } from 'react';

import { Button, Col, Modal, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { css } from '@emotion/react';

import { Div, SecondaryButton } from '../../../components';
import { H } from '../../../components/H';
import { RootState } from '../../../store/reducer';
import { useWindowSize } from '../../../util/screenSize';
import { HeaderImage } from './HeaderImage';

interface Props {
  rightImage: string;
  leftImage: string;
  buttonLabel: string;
  content: string;
  userName: string;
  path: string;
}
export const OnBoard: FC<Props> = ({
  leftImage,
  rightImage,
  buttonLabel,
  content,
  userName,
  path,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const me = useSelector((state: RootState) => state.people.me);
  return (
    <>
      <Modal
        visible={visible}
        width="625px"
        title={
          <div
            style={{
              width: '75%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}>
            <HeaderImage leftIcon={leftImage} rightIcon={rightImage} />
          </div>
        }
        footer={[
          <>
            <Row>
              <Col xs={6} sm={24} md={4} lg={4} xl={4}>
                <SecondaryButton
                  onClick={() => {
                    navigate(path);
                    setVisible(false);
                  }}>
                  Skip
                </SecondaryButton>
              </Col>
              <Col xs={18} sm={24} md={20} lg={20} xl={20}>
                <Button
                  css={css`
                    background: #0052ea;
                    border-color: 0052ea;
                    font-size: 14px;
                    margin-bottom: 34px;
                    border-radius: 4px;
                    margin-right: 40px;
                  `}
                  size="large"
                  key="submit"
                  type="primary"
                  onClick={() => {
                    navigate(`/contact/personal-details/${me?.id}`);
                    setVisible(false);
                  }}>
                  {buttonLabel}
                </Button>
              </Col>
            </Row>
          </>,
        ]}
        css={css`
          .ant-modal-content {
            border-radius: 8px;
          }
          .ant-modal-close-x {
            display: none;
          }
          .ant-modal-header {
            padding: 0px;
            border-radius: 8px 8px 0px 0px;
          }
          .ant-modal-body {
            padding: 0px;
          }
        `}>
        <Div textAlign="center" marginTop="40px">
          <H type="h4">{'Hello ' + userName}</H>
        </Div>
        <p
          style={{
            color: '#5f6267',
            fontSize: '16px',
            width: isMobile ? '' : '438px',
            height: isMobile ? '' : '48px',
            fontWeight: 400,
            textAlign: 'center',
            margin: isMobile ? '0px 30px 0px 30px' : '16px 93px 36px 93px',
            display: isMobile ? 'flex' : '',
          }}>
          {content}
        </p>
      </Modal>
    </>
  );
};
