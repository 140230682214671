import styled from '@emotion/styled';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { B, TableComp, SwitchComponent } from '../../../components';
import theme from '../../../theme';
import { GratuityFilterTypes, GratuityI } from '../../../types/gratuity.types';
import { isValidMoment } from '../../../util/isValidMoment';
import { useWindowSize } from '../../../util/screenSize';
import { numberThousandSeparator } from '../../../util/utils';

interface Props {
  gratuityDetails?: GratuityI;
  onChange?: SwitchChangeEventHandler;
}

const Container = styled.div`
  border: 1px solid ${theme.gray300};
`;

const EmpGratuityTable: FC<Props> = ({ gratuityDetails, onChange }) => {
  const { isTablet, isDesktop } = useWindowSize();
  const [gratuityData, setGratuityData] = useState<GratuityI[]>([]);

  useEffect(() => {
    if (gratuityDetails) {
      setGratuityData([{ ...gratuityDetails }]);
    }
  }, [gratuityDetails]);

  const columns: ColumnProps<GratuityI>[] = [
    {
      title: 'Emp ID',
      width: 100,
      dataIndex: 'employeeNumber',
      render: (_value: string, data) => (
        <B type="b-small">{data.employeeNumber ? data.employeeNumber : '-'}</B>
      ),
    },
    {
      title: 'Last Drawn Net Salary',
      width: 150,
      dataIndex: 'lastDrawnSalary',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.lastDrawnSalary
            ? numberThousandSeparator(data.lastDrawnSalary)
            : '-'}
        </B>
      ),
    },
    {
      title: 'Gratuity Amount',
      width: 150,
      dataIndex: 'paidAmount',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.gratuityAmount
            ? numberThousandSeparator(data.gratuityAmount)
            : '-'}
        </B>
      ),
    },
    {
      title: 'Resigned Date',
      width: 150,
      dataIndex: 'resignedDate',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.resignedDate
            ? isValidMoment(data.resignedDate, '-', 'YYYY-MM-DD').toString()
            : '-'}
        </B>
      ),
    },
    {
      title: 'Due Date',
      width: 150,
      dataIndex: 'dueDate',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.dueDate
            ? isValidMoment(data.dueDate, '-', 'YYYY-MM-DD').toString()
            : '-'}
        </B>
      ),
    },
    {
      title: 'Status',
      width: 100,
      dataIndex: 'status',
      render: (_value: string, data) => (
        <B
          type="b-small"
          color={
            data.status === GratuityFilterTypes.PENDING
              ? theme.orange400
              : theme.green500
          }>
          {data.status
            ? data.status.charAt(0).toUpperCase() +
              data.status.substr(1).toLowerCase()
            : '-'}
        </B>
      ),
    },
    {
      title: 'Mark as Paid',
      width: 100,
      dataIndex: 'isPaid',
      render: (value: boolean) => (
        <SwitchComponent defaultChecked={value} onChange={onChange} />
      ),
    },
  ];
  return (
    <Container>
      <TableComp
        rowKey={(item) => {
          return String(item.id);
        }}
        pagination={false}
        columns={columns}
        size="large"
        fullWidth={true}
        dataSource={gratuityData}
        scroll={{
          x: isTablet ? 1250 : isDesktop ? 1075 : 1075,
        }}
      />
    </Container>
  );
};

export default EmpGratuityTable;
