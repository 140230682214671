import React, { FC } from 'react';

import _ from 'lodash';
import { css } from '@emotion/react';
import { Col, Dropdown, Menu, Row } from 'antd';
import { AddLoanNoteI } from '../../../types/loan.types';
import { DeleteModal, B, Div, Image } from '../../../components';
import theme from '../../../theme';
import styled from '@emotion/styled';
import { isValidMoment } from '../../../util/isValidMoment';
import { MoreSVG } from '../../../assets';

interface Props {
  item: AddLoanNoteI;
  onClickEdit: () => void;
  onRemove: () => void;
  isUserView?: boolean;
}

const DropdownMenu = styled(Menu)`
  width: 163px;
  padding: 0px;
  border: 0.5px solid ${theme.gray300};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .ant-dropdown-menu-item {
    padding: 12px 0px 12px 16px;
    border-bottom: 1px solid ${theme.gray300};
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;

const CustomRow = styled(Row)`
  border-bottom: 1px solid ${theme.gray300};
  box-sizing: border-box;
  padding: 18px 0px 8px;
  display: flex;
  flex-direction: row;
`;

const CustomCol = styled(Col)`
  padding-top: 8px;
`;

const NoteItem: FC<Props> = ({ onClickEdit, onRemove, item, isUserView }) => {
  const menu = (
    <DropdownMenu>
      <Menu.Item key="edit" onClick={() => onClickEdit()}>
        <B type="b-default">Edit note</B>
      </Menu.Item>
      <Menu.Item key="remove">
        <DeleteModal
          title="Remove note"
          buttonLabel="Yes, Remove!"
          content="Are you sure you want to remove this note?"
          onClickCancel={() => onRemove()}
          openModal={<B type="b-default">Remove note</B>}></DeleteModal>
      </Menu.Item>
    </DropdownMenu>
  );

  return (
    <CustomRow>
      <Col span={19}>
        <B type="b-default" color={theme.black}>
          {item.note}
          <B type="b-small" color={theme.gray600}>
            {isValidMoment(item.updatedAt, undefined, 'DD.MM.YYYY').toString()}
          </B>
        </B>
      </Col>
      {!isUserView && (
        <CustomCol span={5}>
          <Div
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            pt="8px">
            <Dropdown overlay={menu} trigger={['click']}>
              <Image
                width={20}
                height={20}
                cursor="pointer"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                src={MoreSVG}
              />
            </Dropdown>
          </Div>
        </CustomCol>
      )}
    </CustomRow>
  );
};

export default NoteItem;
