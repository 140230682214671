import styled from '@emotion/styled';
import { Col, Dropdown, Menu, Row, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { useGetAccountingIntStatus } from '../../../../api/integrationHooks';
import { DropdownBlack } from '../../../../assets';
import { B, DefaultBody, Div } from '../../../../components';
import { MultipleAlertMessage } from '../../../../components/alertMessage/MultipleAlertMessage';
import { PreviousPayrollI } from '../../../../store/actions';
import { RootState } from '../../../../store/reducer';
import theme from '../../../../theme';
import {
  ExpenseCreation,
  IntegrationStatusEnum,
  Permission,
} from '../../../../types';
import { SubscriptionEnum } from '../../../../types/subscription.types';
import { getPathWithOrgId } from '../../../../util/getPathWithOrgId';
import { isAllowed } from '../../../../util/permissionUtil';
import SuspendPage from '../../../layout/SuspendPage';
import { ErrorMessageContainer } from '../../../settings/integration-apps/components/IntegrationAlerts';
import PayRollSummary from './PayRollSummary';
import PayRunTable from './PayRunTable';

enum FilterTypes {
  DRAFTED = 'DRAFTED',
  COMPLETED = 'COMPLETED',
  ARCHIVES = 'ARCHIVES',
  ACTIVE = 'ACTIVE',
}
const ErrorMessage = styled(ErrorMessageContainer)`
  margin-bottom: 0px;
  width: 60%;
`;
const DropdownStyle = styled(Dropdown)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: ${theme.black};
  :hover {
    color: ${theme.black} !important;
  }
  .ant-dropdown .ant-dropdown-placement-bottomLeft {
    top: 255px !important;
  }
`;
const menuItems = [
  {
    key: FilterTypes.ACTIVE,
    name: 'Active Payruns',
  },
  {
    key: FilterTypes.DRAFTED,
    name: 'Draft',
  },
  {
    key: FilterTypes.ARCHIVES,
    name: 'Archived',
  },
  {
    key: FilterTypes.COMPLETED,
    name: 'Completed',
  },
];
const PayrollHomePage: FC = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<FilterTypes>(FilterTypes.ACTIVE);
  const [dataSource, setDataSource] = useState<PreviousPayrollI[]>([]);
  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );
  const organizationDataLoading = useSelector(
    (state: RootState) => state.organization.loading,
  );
  const me = useSelector((state: RootState) => state.people.me);

  const requiredFieldsInCompanySetting =
    organizationData &&
    (!organizationData.name ||
      !organizationData.email ||
      !organizationData.address ||
      !organizationData.industry ||
      !organizationData.telephoneNumber ||
      !organizationData.epfZone ||
      !organizationData.epf ||
      !organizationData.etf ||
      !organizationData.dailyWorkingHours ||
      (organizationData.monthlyWorkingDaysSettings !== 'Variable' &&
        !organizationData.monthlyWorkingDays) ||
      !organizationData.payrunStartDate);

  // get integration permissions
  const isAllowedManageIntegration: boolean = isAllowed(
    Permission.MANAGE_INTEGRATION,
  );

  const { isLoading: integrationStatusLoading, data: getIntegrationStatus } =
    useGetAccountingIntStatus();

  const menu = (
    <Menu>
      {menuItems.map((item) => (
        <Menu.Item key={uuidv4()} onClick={() => setFilter(item.key)}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const previousPayroll = useSelector(
    (state: RootState) => state.payroll.payrollSummery,
  );

  useEffect(() => {
    const list: PreviousPayrollI[] = [];

    previousPayroll?.previousPayroll?.forEach((previousPayroll) => {
      const previousPayrollTable: PreviousPayrollI = {
        ...previousPayroll,
      };
      if (filter === FilterTypes.ACTIVE && !previousPayrollTable.isArchived) {
        list.push(previousPayrollTable);
      } else if (
        filter === FilterTypes.ARCHIVES &&
        previousPayrollTable.isArchived
      ) {
        list.push(previousPayrollTable);
      } else if (
        filter === FilterTypes.COMPLETED &&
        previousPayrollTable.status === 'COMPLETED' &&
        !previousPayrollTable.isArchived
      ) {
        list.push(previousPayrollTable);
      } else if (
        filter === FilterTypes.DRAFTED &&
        previousPayrollTable.status != 'COMPLETED' &&
        !previousPayrollTable.isArchived
      ) {
        list.push(previousPayrollTable);
      }
    });

    setDataSource(list);
  }, [previousPayroll, filter]);

  return (
    <>
      {me?.subscriptionStatus === SubscriptionEnum.SUSPENDED ? (
        <SuspendPage />
      ) : (
        <Spin spinning={organizationDataLoading}>
          <>
            {requiredFieldsInCompanySetting && (
              <MultipleAlertMessage
                type="warn"
                messages={[
                  'Please fill all mandatory details in company setting before processing payruns!',
                ]}
                onAction={() => navigate(getPathWithOrgId('/company-settings'))}
                actionLabel="Manage Company Settings"
              />
            )}
          </>
          <DefaultBody>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={19}>
                <PayRollSummary isDisable={!!requiredFieldsInCompanySetting} />
              </Col>
            </Row>
            {isAllowedManageIntegration &&
              getIntegrationStatus?.type !== ExpenseCreation.SYSTEM &&
              !integrationStatusLoading &&
              [IntegrationStatusEnum.ACTION_REQUIRED].includes(
                getIntegrationStatus?.status as IntegrationStatusEnum,
              ) && (
                <ErrorMessage
                  message={
                    <>
                      Opps! Looks like your{' '}
                      <b>{getIntegrationStatus?.type.toLocaleLowerCase()}</b>{' '}
                      integration is not working anymore.
                    </>
                  }
                  type="error"
                  showIcon
                />
              )}
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={19}>
                <Div pt="32px" pb="24px">
                  <DropdownStyle overlay={menu} trigger={['click']}>
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}>
                      {menuItems.find((item) => item.key === filter)?.name}{' '}
                      <img src={DropdownBlack} />
                    </a>
                  </DropdownStyle>
                  <B type="b-small" color={theme.gray600}>
                    View draft payrun and edit before finalizing, download
                    completed payroll files, or repeat completed payrun for new
                    month.
                  </B>
                </Div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={19}>
                <PayRunTable
                  previousPayroll={dataSource}
                  isCompleteSettings={!!requiredFieldsInCompanySetting}
                />
              </Col>
            </Row>
          </DefaultBody>
        </Spin>
      )}
    </>
  );
};

export default PayrollHomePage;
