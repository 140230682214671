import 'react-modern-drawer/dist/index.css';

import React, { FC, lazy, Suspense, useEffect, useState } from 'react';

import { Form } from 'antd';
import { startCase } from 'lodash';
import { useSelector } from 'react-redux';
import {
  CompactTableComp,
  Div,
  FullScreen,
  fullScreenCss,
} from '../../../components';
import theme from '../../../theme';
import { getLeaveColumns, getPayslipFieldColumns } from './dataImport.util';

import { useWindowSize } from '../../../util/screenSize';

import { RootState } from '../../../store/reducer';
import {
  getColumns,
  TableColumnProp,
} from '../../payroll/shared/getTableColumn';
import { useGetBankList } from '../../../api/bankHooks';
import styled from '@emotion/styled';
import {
  DataExtractorCSVEnum,
  DataImportUserPayLoad,
} from '../../../store/actions';

type WrapperProps = {
  columns: TableColumnProp[];
  fullScreen: boolean;
};

const StyledWrapper = styled.div<WrapperProps>`
  width: ${({ columns }) => (columns.length <= 6 ? '80%' : '100%')};
  background: ${theme.white};
  padding-bottom: 0;
  ${({ fullScreen }) => (fullScreen ? fullScreenCss : '')}
  .meta-i {
    font-size: 7px;
    position: absolute;
    top: -4px;
    right: 10px;
  }
`;

const LazyComponent = lazy(() => import('./EditDrawer'));
interface Props {
  csvType: DataExtractorCSVEnum;
  dataPayload: DataImportUserPayLoad[];
  payItemColumns: string[];
  leaveItemColumns?: string[];
  payrollKey?: string;
  isDraftPayroll: boolean;
  setSelectedRowKeys: (rows: string[]) => void;
  selectedRowKeys: string[];
  fullScreen: boolean;
  setFullScreen: (fullScreen: boolean) => void;
  extraTableHeight: number;
  isSelectedEmployeeOnly: boolean;
  disableView?: boolean;
  onErrorFilter?: boolean;
  payItemErrorColumns?: string[];
  employeeErrorObj?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  onChange?: (v: DataImportUserPayLoad[]) => void;
}

const ImportTable: FC<Props> = ({
  csvType,
  onErrorFilter,
  dataPayload,
  payItemColumns,
  payItemErrorColumns,
  payrollKey,
  isDraftPayroll,
  selectedRowKeys,
  setSelectedRowKeys,
  setFullScreen,
  fullScreen,
  extraTableHeight,
  isSelectedEmployeeOnly,
  disableView,
  employeeErrorObj,
  onChange,
}) => {
  const [form] = Form.useForm();
  const [leaveColumn, setLeaveColumn] = useState<string[]>([]);
  const [payslipFieldsColumn, setPayslipFieldsColumn] = useState<string[]>([]);
  const [isPreferredName, setIsPreferredName] = useState<boolean>(false);
  const [drawerData, setDrawerData] = useState<DataImportUserPayLoad | null>(
    null,
  );
  const [visible, setVisible] = useState(false);
  const [tableHeight, setTableHeight] = useState(0);
  const { data: banks = [] } = useGetBankList();

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  useEffect(() => {
    const height = window.innerHeight + extraTableHeight;
    const newTableHeight = fullScreen
      ? height - 140
      : height > 300
      ? height - 325
      : 0;
    setTableHeight(newTableHeight);
  }, [window.innerHeight, fullScreen, extraTableHeight]);

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setLeaveColumn(getLeaveColumns(dataPayload).map((item) => startCase(item)));

    setPayslipFieldsColumn(
      getPayslipFieldColumns(dataPayload).map((item) => startCase(item)),
    );

    setIsPreferredName(dataPayload.some((item) => item.values.preferredName));
  }, [dataPayload]);

  const handleKeyDown = (evt: KeyboardEvent) => {
    if (evt.code === 'Escape') {
      setFullScreen(false);
    }
  };

  const toggleDrawer = () => {
    setVisible((prevState) => !prevState);
    form.resetFields();
  };

  const showDrawer = (value: DataImportUserPayLoad) => {
    toggleDrawer();
    setDrawerData(value);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const { isMobile, isTablet } = useWindowSize();
  const columns = getColumns({
    onErrorFilter,
    payItemErrorColumns,
    employeeErrorObj,
    payItemColumns,
    payrollKey,
    selectedEmployeeOnly: isSelectedEmployeeOnly,
    leaveColumn,
    payslipFieldsColumn,
    leaveAllowedForPayslip: organizationData?.isLeaveInPayroll,
    disableView,
    isPreferredName,
    visible,
    showDrawer,
    onClose,
    isMobile,
    isTablet,
  });

  return (
    <StyledWrapper id="salaryTable" fullScreen={fullScreen} columns={columns}>
      {fullScreen && (
        <Div display="flex" justifyContent="flex-end">
          <FullScreen
            fullScreen={fullScreen}
            openFullScreen={() => {
              setFullScreen(true);
            }}
            exitFullscreen={() => {
              setFullScreen(false);
            }}
          />
        </Div>
      )}
      <CompactTableComp
        columns={columns}
        pagination={false}
        dataSource={dataPayload}
        scroll={{
          x: 500,
          y: visible ? 'max-height' : tableHeight,
        }}
        rowKey={(record: DataImportUserPayLoad) => record.rowKey}
        rowSelection={
          isDraftPayroll
            ? {
                selectedRowKeys: selectedRowKeys,
                onChange: (
                  _selectedRowKeys: React.Key[],
                  selectedRows: DataImportUserPayLoad[],
                ) => {
                  setSelectedRowKeys(selectedRows.map((item) => item.rowKey));
                },
                getCheckboxProps: (record: DataImportUserPayLoad) => ({
                  disabled: record.hasAnyError,
                }),
                renderCell: (
                  _value: boolean,
                  record,
                  _index: number,
                  originNode: React.ReactNode,
                ) => {
                  return (
                    <>
                      {record.hasAnyError && (
                        <span className="error-row"></span>
                      )}
                      {originNode}
                    </>
                  );
                },
              }
            : undefined
        }
      />
      <Suspense fallback={<div>Loader </div>}>
        {visible && (
          <LazyComponent
            banks={banks}
            dataPayload={dataPayload}
            payItemColumns={payItemColumns}
            drawerData={drawerData}
            isPeopleUpload={csvType === DataExtractorCSVEnum.EMPLOYEE_CSV}
            toggleDrawer={() => {
              toggleDrawer();
            }}
            onSubmit={async (v: DataImportUserPayLoad[]) => {
              if (onChange) {
                onChange(v);
              }
              setVisible((prevState) => !prevState);
            }}
            visible={visible}
          />
        )}
      </Suspense>
    </StyledWrapper>
  );
};

export default ImportTable;
