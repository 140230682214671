/* eslint-disable prefer-const */
import { capitalize } from 'lodash';
import moment, { Moment } from 'moment';
import safe from 'safe-regex';

import { ValidateErrorEntity } from '../../node_modules/rc-field-form/lib/interface';
import { BankI } from '../types/bank.types';
import { ConvertedHolidaysI, HolidaysI } from '../store/modules/dashboard';
import { UserHistoryI } from '../types/people.types';

/**
 * This converts
 *  - 23233 into 23,233.00
 *  - 23233.433 into 23,233.43
 */

export const numberThousandSeparator = (
  num = 0,
  isFourPoint?: boolean | undefined,
) => {
  const decimalPoint = isFourPoint ? 4 : 2;

  return Number(num.toFixed(decimalPoint)).toLocaleString('en-GB', {
    minimumFractionDigits: decimalPoint,
    useGrouping: true,
  });
};

export const removeCommasInNumber = (num: string) => {
  return Number(num.replace(/\,/g, ''));
};

export const checkPasswordStrengthWarningMsg = (password: string) => {
  const regexText = new RegExp(
    '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
  );
  if (!password) {
    throw new Error('Please enter New Password');
  } else if (!safe(password)) {
    throw new Error('Invalid Password');
  } else if (!regexText.test(password) && password.length < 8) {
    throw new Error('Password should have a minimum of 8 characters');
  } else if (regexText.test(password) !== true && password.length >= 8) {
    throw new Error(
      `Password should have at least one letter, number and symbol`,
    );
  }

  return true;
};

export const convertHolidays = (holidays: HolidaysI[]) => {
  const holidayArray: ConvertedHolidaysI[] = [];
  holidays.forEach((element) => {
    const obj = {
      date: moment(element['date']).format('YYYY/MM/DD'),
      descriptions: element['description'],
    };
    holidayArray.push(obj);
  });
  return holidayArray;
};

export const isTodayOrBefore = (data: string): boolean => {
  return moment(data).isBefore(moment(), 'day');
};
export const isSameOrBefore = (data: string): boolean => {
  return moment(data).isSameOrBefore(moment(), 'day');
};

export function addDotsForLongText(title: string, maxLength: number): string {
  let letters: string;
  if (title?.length > maxLength) {
    letters = title.substring(0, maxLength);
    return letters + '...';
  } else {
    return title;
  }
}

export function isHaveThreeDots(title: string, maxLength: number) {
  if (title?.length > maxLength) {
    return true;
  } else {
    return false;
  }
}

export const scrollToFirstErrorField = (errorInfo: ValidateErrorEntity) => {
  const { errorFields } = errorInfo;
  const errorInput: HTMLElement | null = document.getElementById(
    `${errorFields[0].name}`,
  );
  if (errorInput) {
    errorInput.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }
};

/**
 * This will replace _ with space and capitalize the word
 * Example: ANNUAL_LEAVE => Annual Leave
 */
export function capitalizeUnderscore(text: string): string {
  let i,
    frags = text.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = capitalize(frags[i]);
  }
  return frags.join(' ');
}

export const capitalizeFirstLetter = (text: string): string => {
  const word = text
    ?.toLowerCase()
    ?.replace(/\b\w/g, (char) => char.toUpperCase());

  return word;
};

export function removeSemicolon(text: string): string {
  let i,
    frags = text.split(':');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i];
  }
  return frags.join(' ');
}

/**
 * is same day or not
 */
export const isSameDay = (day1: Moment, day2: Moment): boolean => {
  return day1.isSame(day2, 'day');
};

export const getBranches = (bankCode: string, banks: BankI[]) => {
  return (
    banks
      .find((item) => item.bankCode === bankCode)
      ?.bankBranches?.map((item) => ({
        value: item.id,
        code: item.branchCode,
        label: item.branchName,
      })) || []
  );
};

export const getLastJob = (
  userHistories?: UserHistoryI[],
): UserHistoryI | null => {
  if (!userHistories || !userHistories.length) {
    return null;
  }

  let lastJob: UserHistoryI | null = userHistories[0];

  for (const job of userHistories) {
    if (moment(job.startDate).isSameOrAfter(lastJob.startDate)) {
      lastJob = job;
    }
  }
  return lastJob;
};

export const getFirstJob = (
  userHistories?: UserHistoryI[],
): UserHistoryI | null => {
  if (!userHistories || !userHistories.length) {
    return null;
  }

  let firstJob: UserHistoryI | null = userHistories[0];
  for (const job of userHistories) {
    if (moment(job.startDate).isSameOrBefore(firstJob.startDate)) {
      firstJob = job;
    }
  }
  return firstJob;
};

export const normFile = (e: any, fileData: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  if (e.fileList.length > 1) {
    return fileData.shift();
  }
  return e && e.fileList;
};

export const safeCheckNaN = (value?: number | string): boolean => {
  if (value === undefined) {
    return false;
  }
  return isNaN(Number(value));
};
