import React, { FC } from 'react';
import styled from '@emotion/styled';
import { B } from '../../../components';
import theme from '../../../theme';
import { PriceTag } from '.';
import { ExpenseReportI } from '../../../types';

const ColumnContainer = styled.div`
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    margin-bottom: 16px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

export const OverviewRecord: FC<{ report: ExpenseReportI; index: number }> = ({
  report,
  index,
}) => {
  return (
    <ColumnContainer key={index}>
      <B type="b-small" color={theme.gray600}>
        {report.label}
      </B>
      <PriceTag amount={report.amount} />
    </ColumnContainer>
  );
};
