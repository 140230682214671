import styled from '@emotion/styled';
import { Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import FormItem from 'antd/lib/form/FormItem';
import React, { FC } from 'react';
import { Div, InputComp, SelectComp } from '../../../components';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import theme from '../../../theme';
import { GrayCancel } from '../../../assets';

const MainDiv = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.gray300};
  padding: 11px 22px 16px 19px;
  height: 56px;
  justify-content: space-between;
`;

const CustomFormItem = styled(FormItem)`
  width: 187px;
`;

const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const CustomInputComp = styled(InputComp)`
  height: 24px;
  width: 160px;
  margin-left: 10px;
`;

interface Props {
  onRemove: () => void;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  onChangeRate: (value: number) => void;
  onChangeCheckBox: (e: CheckboxChangeEvent) => void;
}

const AddOverTime: FC<Props> = ({
  onRemove,
  onChange,
  onBlur,
  onChangeRate,
  onChangeCheckBox,
}) => {
  const [form] = Form.useForm();

  return (
    <Form form={form}>
      <MainDiv>
        <Div display="flex">
          <FormItem valuePropName="checked">
            <CheckBoxComp onChange={(e) => onChangeCheckBox(e)} />
          </FormItem>
          <FormItem
            name="ot"
            rules={[
              {
                required: true,
                message: 'Please enter over time',
              },
            ]}>
            <CustomInputComp
              placeholder="Enter Name"
              onChange={(e) => {
                onChange(e);
              }}
              onBlur={(e) => {
                onBlur(e);
              }}
            />
          </FormItem>
        </Div>
        <Div display="flex">
          <CustomFormItem>
            <SelectComp
              size="small"
              placeholder="Select Rates"
              onChange={(v) => {
                onChangeRate(Number(v));
              }}
              options={[
                {
                  label: 1,
                  value: 1,
                },
                {
                  label: 1.25,
                  value: 1.25,
                },
                {
                  label: 1.5,
                  value: 1.5,
                },
                {
                  label: 2,
                  value: 2,
                },
                {
                  label: 2.5,
                  value: 2.5,
                },
              ]}
            />
          </CustomFormItem>
          <Div ml="38px" mt="4px">
            <CloseIcon onClick={() => onRemove()} src={GrayCancel} />
          </Div>
        </Div>
      </MainDiv>
    </Form>
  );
};

export default AddOverTime;
