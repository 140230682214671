import styled from '@emotion/styled';
import theme from '../../../theme';

export const ContractLayout = styled.div<{ fullScreen?: boolean }>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${(props) => (props.fullScreen ? '186px' : '145px')});
`;

export const DraggableLayout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  height: inherit;
`;

export const ToolKitContainer = styled.div`
  width: 184px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-sizing: border-box;
  border-right: 1px solid ${theme.gray300};
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ContractContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const ContentEditorContainer = styled.div`
  width: 228px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-sizing: border-box;
  border-left: 1px solid ${theme.gray300};
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 70px;
  background-color: ${theme.gray50};
`;

const labelStyle = `
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: black;
`;

const inputStyle = (ratio: number) => `
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  border: none;

  border-radius: 3px;
  box-shadow: none;
  outline: none;
  margin: 0;
  box-sizing: border-box;
  padding: ${10 * ratio}px ${5 * ratio}px;
  max-width: ${500 * ratio}px;
  font-size: ${12 * ratio}px;
`;

const containerStyle = `
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledRect = styled.div<{
  isReceiver: boolean;
  selected: boolean;
  ratio: number;
}>`
  width: inherit;
  height: inherit;
  position: absolute;
  background-color: ${(props) =>
    props.isReceiver
      ? theme.contract.receiver.hover
      : theme.contract.sender.hover};
  border: 2px solid
    ${(props) =>
      props.isReceiver
        ? theme.contract.receiver.default
        : theme.contract.sender.default};
  border-radius: 4px;
  .round {
    position: absolute;
    width: 7px;
    height: 7px;
    background: white;
    border: 2px solid
      ${(props) =>
        props.isReceiver
          ? theme.contract.receiver.default
          : theme.contract.sender.default};
    border-radius: 5px;
  }
  .t,
  .tl,
  .tr {
    top: -3px;
  }
  .b,
  .bl,
  .br {
    bottom: -3px;
  }
  .r,
  .tr,
  .br {
    right: -3px;
  }
  .tl,
  .l,
  .bl {
    left: -3px;
  }
  .l,
  .r {
    top: 50%;
    margin-top: -3px;
  }
  .t,
  .b {
    left: 50%;
    margin-left: -3px;
  }

  .container {
    ${containerStyle}
    padding: ${(props) => props.ratio * 6}px ${(props) => props.ratio * 8}px;
  }
  .input-container {
    ${containerStyle}
  }
  .sign-container {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    background-color: transparent;
    border-color: transparent;
    border: none;
    font-size: ${(props) => props.ratio * 12}px;
    &:focus {
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  .ant-input {
    ${(props) => inputStyle(props.ratio)}
  }

  .ant-picker-input {
    input {
      ${(props) => inputStyle(props.ratio)}
    }
  }

  .ant-picker-suffix {
    margin-left: ${(props) => props.ratio * 8}px;
  }

  .ant-picker {
    padding: 0;
    width: -webkit-fill-available !important;
    height: -webkit-fill-available !important;
    background-color: transparent;
    border: none;
    &:hover {
      background-color: transparent;
      .ant-picker-clear {
        background: ${(props) =>
          props.isReceiver
            ? theme.contract.receiver.hover
            : theme.contract.sender.hover};
      }
    }
  }

  .ant-picker:hover,
  .ant-picker-focused {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-picker-input {
    width: -webkit-fill-available !important;
    height: -webkit-fill-available !important;
    flex-direction: row-reverse;
    border: none;
  }

  .ant-picker-clear {
    left: 0;
  }

  .text-input {
    ${labelStyle}
    opacity: ${(props) => (props.selected ? '1' : '0.7')};
    font-size: ${(props) => 12 * props.ratio}px;
  }
  .date-input {
    label {
      ${labelStyle}
    }
    opacity: ${(props) => (props.selected ? '1' : '0.7')};
  }
  &:hover {
    cursor: pointer;
    .text-input {
      cursor: pointer;
      opacity: 1;
    }
    .date-input {
      cursor: pointer;
      opacity: 1;
    }
  }
`;

export const SignatureContainer = styled.div<{
  isReceiver: boolean | undefined;
  ratio: number;
}>`
  position: relative;
  width: 200px;
  height: -webkit-fill-available;
  label {
    top: 0;
    left: 0;
    position: absolute;
    margin-top: -7px;
    margin-left: 5px;
    background-color: white;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: ${(props) => 10 * props.ratio}px;
    color: ${(props) =>
      props.isReceiver
        ? theme.contract.receiver.default
        : theme.contract.sender.default};
  }
  .image-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    background: transparent;
    border: 1.5px solid
      ${(props) =>
        props.isReceiver
          ? theme.contract.receiver.hover
          : theme.contract.sender.hover};
    box-sizing: border-box;
    border-radius: 4px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

export const SignatureInput = styled.div<{
  fontStyle: string | undefined;
}>`
  font-family: ${(props) => props.fontStyle};
  font-size: 39px;
  width: max-content;
  height: max-content;
  background: transparent;
`;

export const ContractNonUserHeader = styled.div`
  background: ${theme.blue900};
  height: 56px;
  display: flex;
  align-items: center;
  padding: 20px;
`;
