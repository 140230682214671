import React, { FC, useState, useEffect } from 'react';
import ContractTable from './contract-list/ContractTable';
import { Col, Dropdown, Menu, Row, message } from 'antd';
import { getAllContracts, uploadContract } from '../../store/actions';
import { isAllowed } from '../../util/permissionUtil';
import { Permission } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType, RootState } from '../../store/reducer';
import { camelCaseToNormalText } from '../../util/camelCaseToNormal.util';
import {
  AllContractsI,
  ContractStatusEnum,
  UploadTemplateI,
} from '../../types/contract.types';
import {
  DefaultBody,
  Div,
  PrimaryButton,
  RadioButton,
  RadioGroup,
} from '../../components';
import { DropdownBlack } from '../../assets';
import styled from '@emotion/styled';
import theme from '../../theme';
import TemplatesTable from './templates/TemplatesTable';
import UploadTemplateModal from './templates/UploadTemplateModal';
import CreateNewContractModal from './contract-list/CreateNewContractModal';
import {
  useContractDelete,
  useDeleteTemplate,
  useGetTemplates,
  useTemplateUpload,
  useUploadContract,
} from '../../api/contractHooks';
import { useNavigate } from 'react-router-dom';

const Flex = styled(Div)`
  margin-left: auto;
`;
const DropdownComp = styled(Dropdown)`
  margin-left: auto;
  margin-right: 16px;
  color: ${theme.gray600};
  font-weight: 500;
  border-radius: 4px;
  padding: 5px 14px 8px 12px;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid ${theme.gray300};
  :hover {
    color: ${theme.gray600} !important;
  }
  .ant-dropdown .ant-dropdown-placement-bottomLeft {
    top: 255px !important;
  }
  img {
    padding-left: 14px;
  }
`;

const Column = styled(Col)`
  background: ${theme.white};
  border-radius: 4px 4px 0px 0px;
`;

const CustomRadioGroup = styled(RadioGroup)`
  @media only screen and (max-width: 768px) {
    .ant-radio-button-wrapper {
      margin-bottom: 3px !important;
      width: 100%;
      text-align: center;
    }
  }
`;
const TabLabel = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

//TODO need to remove and revamp the whole status
enum FilterTypes {
  // ActiveContracts = 'ActiveContracts',
  AllContracts = 'AllContracts',
  DraftContracts = 'DraftContracts',
  Completed = 'Completed',
  Sent = 'Sent',
  Archived = 'Archived',
}

enum FilterTypesForUserPermission {
  AllContracts = 'AllContracts',
  Completed = 'Completed',
  Sent = 'Sent',
  Archived = 'Archived',
}
enum UserFilterTypes {
  CreatedByAnyone = 'CreatedByAnyone',
  CreatedByMe = 'CreatedByMe',
  SignedByMe = 'SignedByMe',
}

const ContractPage: FC = () => {
  const [visible, setVisible] = useState(false);
  const [templateModalOpen, setTemplateModalOpen] = useState<boolean>(false);
  const [isAllContractTab, setIsAllContractTab] = useState<boolean>(true);
  const [selectedContracts, setSelectedContracts] = useState<AllContractsI[]>(
    [],
  );
  const [filter, setFilter] = useState<string>(FilterTypes.AllContracts);
  const [userFilter, setUserFilter] = useState<string>(
    UserFilterTypes.CreatedByAnyone,
  );
  const dispatch: DispatchType = useDispatch();
  const navigate = useNavigate();

  const userPermission = isAllowed(Permission.CREATE_CONTRACT);

  const allContracts = useSelector(
    (state: RootState) => state.contract.allContracts,
  );
  const loadingContracts = useSelector(
    (state: RootState) => state.contract.loadingContracts,
  );
  const me = useSelector((state: RootState) => state.people.me);

  const { data: uploadTemplateData, mutate: uploadTemplate } =
    useTemplateUpload();

  const {
    data: uploadContractData = null,
    mutate: uploadContract,
    isLoading: isContractUploadLoading,
    error: uploadError,
  } = useUploadContract();

  const {
    data: dateDeleteTemplate,
    isLoading,
    mutate: deleteTemplate,
    error: deleteTemplateError,
  } = useDeleteTemplate();

  useEffect(() => {
    if (dateDeleteTemplate) {
      getTemplates();
    }
  }, [dateDeleteTemplate]);

  const {
    data: templates,
    refetch: getTemplates,
    isLoading: templatesLoading,
  } = useGetTemplates();

  const { data: contractDeletionData, mutate: deleteContract } =
    useContractDelete();

  useEffect(() => {
    dispatch(getAllContracts());
  }, [contractDeletionData]);

  useEffect(() => {
    if (uploadTemplateData) {
      getTemplates();
    }
  }, [uploadTemplateData]);

  useEffect(() => {
    dispatch(getAllContracts());
    getTemplates();
  }, []);

  useEffect(() => {
    if (uploadContractData) {
      navigate(`/contract/edit/${uploadContractData?.documentId}`);
      setVisible(false);
    }
  }, [uploadContractData]);

  const contractMenu = (
    <Menu>
      {userPermission
        ? Object.keys(FilterTypes).map((key) => (
            <Menu.Item onClick={() => setFilter(key)} key={key}>
              {camelCaseToNormalText(key)}
            </Menu.Item>
          ))
        : Object.keys(FilterTypesForUserPermission).map((key) => (
            <Menu.Item onClick={() => setFilter(key)} key={key}>
              {camelCaseToNormalText(key)}
            </Menu.Item>
          ))}
    </Menu>
  );
  const userMenu = (
    <Menu>
      {Object.keys(UserFilterTypes).map((key) => (
        <Menu.Item onClick={() => setUserFilter(key)} key={key}>
          {camelCaseToNormalText(key)}
        </Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    const firstFilter = allContracts.filter((contract) => {
      if (userPermission) {
        if (userFilter == UserFilterTypes.CreatedByAnyone) {
          return contract;
        } else if (
          userFilter == UserFilterTypes.CreatedByMe &&
          Number(contract?.createdBy) == me?.id
        ) {
          return contract;
        } else if (
          userFilter == UserFilterTypes.SignedByMe &&
          contract.contractStatus.find(
            (status) =>
              (status?.firstSignatoryId == me?.id?.toString() &&
                status.isSigned) ||
              (status?.secondSignatoryId == me?.id?.toString() &&
                status.isSigned),
          )
        ) {
          return contract;
        }
      } else {
        return contract;
      }
    });

    const contractList = firstFilter.filter((contract) => {
      if (
        filter === FilterTypes.AllContracts &&
        contract.status !== ContractStatusEnum.ARCHIVED
      ) {
        return contract;
      } else if (filter == FilterTypes.AllContracts) {
        return contract;
      } else if (
        filter == FilterTypes.DraftContracts &&
        contract.isSend == false
      ) {
        return contract;
      } else if (
        filter == FilterTypes.Sent &&
        contract.status == ContractStatusEnum.SENT
      ) {
        return contract;
      } else if (
        filter == FilterTypes.Completed &&
        contract.status == ContractStatusEnum.COMPLETED
      ) {
        return contract;
      } else if (
        filter == FilterTypes.Archived &&
        contract.status == ContractStatusEnum.ARCHIVED
      ) {
        return contract;
      }
    });

    setSelectedContracts(contractList);
  }, [allContracts, filter, userFilter]);
  return (
    <DefaultBody>
      <Row>
        <Column xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Div padding="0px 0px 16px 0px" display="flex" flexDirection="row">
            {userPermission ? (
              <CustomRadioGroup defaultValue="allLeave">
                <RadioButton
                  value="allLeave"
                  onClick={() => setIsAllContractTab(true)}>
                  <TabLabel>All Contracts</TabLabel>
                </RadioButton>
                <RadioButton
                  value="myLeave"
                  onClick={() => setIsAllContractTab(false)}>
                  <TabLabel>Templates</TabLabel>
                </RadioButton>
              </CustomRadioGroup>
            ) : null}
            {isAllContractTab ? (
              <Flex display="flex" flexDirection="row">
                {userPermission ? (
                  <DropdownComp overlay={userMenu} trigger={['click']}>
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}>
                      {camelCaseToNormalText(userFilter)}{' '}
                      <img src={DropdownBlack} />
                    </a>
                  </DropdownComp>
                ) : null}

                <DropdownComp overlay={contractMenu} trigger={['click']}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}>
                    {camelCaseToNormalText(filter)} <img src={DropdownBlack} />
                  </a>
                </DropdownComp>

                {userPermission && (
                  <PrimaryButton size="small" onClick={() => setVisible(true)}>
                    Create Contract
                  </PrimaryButton>
                )}
              </Flex>
            ) : (
              <Flex display="flex" flexDirection="row">
                <PrimaryButton
                  size="small"
                  onClick={() => setTemplateModalOpen(true)}>
                  Upload Template
                </PrimaryButton>
              </Flex>
            )}
          </Div>
        </Column>
      </Row>

      {userPermission ? (
        isAllContractTab ? (
          <ContractTable
            contracts={selectedContracts}
            loading={loadingContracts}
            deleteContract={(id) => deleteContract(id)}
          />
        ) : (
          <TemplatesTable
            templates={templates}
            loading={templatesLoading || isLoading}
            deleteTemplate={(id: string) => deleteTemplate(id)}
          />
        )
      ) : (
        <ContractTable
          contracts={selectedContracts}
          loading={loadingContracts}
          deleteContract={() => deleteContract}
        />
      )}

      {visible && (
        <CreateNewContractModal
          isLoading={isContractUploadLoading}
          visible={visible}
          handleCancel={() => setVisible(false)}
          uploadError={uploadError}
          onSubmit={async (value) => {
            if (value) {
              uploadContract({ ...value });
            }
          }}
        />
      )}
      {templateModalOpen && (
        <UploadTemplateModal
          visible={templateModalOpen}
          handleCancel={() => setTemplateModalOpen(false)}
          onSubmit={(values: UploadTemplateI) => {
            uploadTemplate(values);
            setTemplateModalOpen(false);
          }}
        />
      )}
    </DefaultBody>
  );
};
export default ContractPage;
