import React, { FC } from 'react';
import styled from '@emotion/styled';
import { B } from '../../../components';
import theme from '../../../theme';
import { PlaceholderTypeEnum } from '../../../types/contract.types';
import { DropperBox } from './DropperBox';
import {
  ReceiverCalenderSVG,
  ReceiverSignatureSVG,
  ReceiverTextInputSVG,
  SenderCalenderSVG,
  SenderSignatureSVG,
  SenderTextInputSVG,
  ToolkitCalenderSVG,
  ToolkitSignatureSVG,
  ToolkitTextInputSVG,
} from '../../../assets';

export interface StandardFieldsI {
  index: number;
  placeholderType: PlaceholderTypeEnum;
  image: string;
  senderImage: string;
  receiverImage: string;
}
interface ToolListI {
  standard_Fields: StandardFieldsI[];
}

const TOOLS_LIST: ToolListI = {
  standard_Fields: [
    {
      index: 1,
      placeholderType: PlaceholderTypeEnum.textInput,
      image: ToolkitTextInputSVG,
      senderImage: SenderTextInputSVG,
      receiverImage: ReceiverTextInputSVG,
    },
    {
      index: 2,
      placeholderType: PlaceholderTypeEnum.date,
      image: ToolkitCalenderSVG,
      senderImage: SenderCalenderSVG,
      receiverImage: ReceiverCalenderSVG,
    },
    {
      index: 3,
      placeholderType: PlaceholderTypeEnum.signature,
      image: ToolkitSignatureSVG,
      senderImage: SenderSignatureSVG,
      receiverImage: ReceiverSignatureSVG,
    },
  ],
};

interface Props {
  isReceiver: boolean;
  selectPlaceHolder: (type: PlaceholderTypeEnum) => void;
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToolContainer: FC<Props> = (props) => {
  return (
    <Column>
      <B
        type="b-default-semibold"
        mt="25px"
        ml="16px"
        mb="14px"
        color={theme.blue700}>
        Standard Fields
      </B>
      {TOOLS_LIST.standard_Fields.map((tool, i) => (
        <DropperBox key={i} index={tool.index} tool={tool} {...props} />
      ))}
    </Column>
  );
};
export default ToolContainer;
