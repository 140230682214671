import axios from 'axios';
import {
  DataExportMetaType,
  DataExportPostBodyI,
  DataExportResponseI,
} from '../../types';
import showApiError from '../../util/showApiError';

const LOAD_EXPORT_META_DATA = 'LOAD_EXPORT_META_DATA';
const SET_EXPORT_META_DATA = 'SET_EXPORT_META_DATA';
const SET_EXPORTED_DATA = 'SET_EXPORTED_DATA';
const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';

interface InitialStateI {
  loadingMetaData: boolean;
  exportMetaDataList: DataExportMetaType[];
  exportedDataResponse: DataExportResponseI[];
  successMessage: string | null;
}

const initialState: InitialStateI = {
  loadingMetaData: false,
  exportMetaDataList: [],
  exportedDataResponse: [],
  successMessage: null,
};

export default function reducer(
  state: InitialStateI = initialState,
  action: any,
): InitialStateI {
  switch (action.type) {
    case LOAD_EXPORT_META_DATA: {
      return {
        ...state,
        loadingMetaData: action.payload,
      };
    }
    case SET_EXPORT_META_DATA: {
      return {
        ...state,
        exportMetaDataList: action.payload,
      };
    }
    case SET_EXPORTED_DATA: {
      return {
        ...state,
        exportedDataResponse: action.payload,
      };
    }
    case SUCCESS_MESSAGE: {
      return {
        ...state,
        successMessage: action.payload,
      };
    }
    default:
      return state;
  }
}

export function getDataExportMetaData() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_EXPORT_META_DATA, payload: true });
      const response = await axios.get('/dataExport/getMeteData');
      dispatch({ type: SET_EXPORT_META_DATA, payload: response.data });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: 'File exported Successfully',
      });
    } catch (error: any) {
      showApiError(error);
    } finally {
      dispatch({ type: LOAD_EXPORT_META_DATA, payload: false });
    }
  };
}

export function postDataExport(data: DataExportPostBodyI) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_EXPORT_META_DATA, payload: true });
      const response = await axios.post('/dataExport', {
        ...data,
      });
      dispatch({ type: SET_EXPORTED_DATA, payload: response.data });
    } catch (error: any) {
      showApiError(error);
    } finally {
      dispatch({ type: LOAD_EXPORT_META_DATA, payload: false });
    }
  };
}

export function clearExportedData() {
  return async (dispatch: any) => {
    dispatch({ type: SET_EXPORTED_DATA, payload: [] });
  };
}
