import React, { CSSProperties, FC, ReactNode, useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import {
  PlaceholderLocationI,
  PlaceholderTypeEnum,
} from '../../../types/contract.types';

const style: CSSProperties = {
  height: '12rem',
  //width: '12rem',
  //marginRight: '1.5rem',
  marginBottom: '1.5rem',
  color: 'white',
  //padding: '1rem',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
};

const activeStyle: CSSProperties = {
  opacity: 0.5,
};

interface Props {
  children: ReactNode;
  onDropped: (x: number, y: number, type: PlaceholderTypeEnum) => void;
}

export const DropZone: FC<Props> = ({ children, onDropped }) => {
  const ref = useRef<HTMLElement | null>(null);

  const [{ canDrop, isOver }, dropTarget] = useDrop(
    () => ({
      accept: Object.values(PlaceholderTypeEnum),
      drop: (item: any, monitor) => {
        const offset = monitor.getSourceClientOffset();
        if (offset && ref.current) {
          const dropTargetXy = ref.current.getBoundingClientRect();

          const x = Math.max(offset.x - dropTargetXy.left, 0);
          const y = Math.max(offset.y - dropTargetXy.top, 0);

          onDropped(x, y, item.placeholderType);
        }
        return { name: 'DropZone' };
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [children],
  );
  const isActive = canDrop && isOver;
  return (
    <div
      ref={(elem) => {
        if (ref) {
          ref.current = elem;
          dropTarget(ref);
        }
      }}
      role="DropZone"
      style={{ ...style }}>
      <div style={isActive ? activeStyle : style}> {children}</div>
    </div>
  );
};
