import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import _ from 'lodash';
import React, { FC } from 'react';
import { B } from '../../../../../components';
import { PayrollI } from '../../../../../types/superAdmin.types';
import { numberThousandSeparator } from '../../../../../util/utils';
const PayrollStatDiv = styled.div`
  background: #fafafa;
  border-radius: 4px;
  padding-bottom: 3px;
`;
interface Props {
  payrollDetails: PayrollI[];
}
const PayrollStats: FC<Props> = ({ payrollDetails }) => {
  const draftedPayruns = payrollDetails.filter(
    (item) => item.status === 'DRAFTED',
  );
  const archivedPayruns = payrollDetails.filter((item) => item.isArchived);
  const activePayruns = payrollDetails.filter((item) => !item.isArchived);
  return (
    <>
      <PayrollStatDiv>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            css={css`
              padding: 16px 24px 16px 24px;
            `}>
            <B type="b-default-semibold">Payroll stats</B>
          </Col>
        </Row>
        <Row
          css={css`
            padding: 0px 24px 16px;
          `}>
          <Col
            xs={24}
            sm={8}
            md={8}
            lg={8}
            xl={5}
            xxl={5}
            css={css`
              display: flex;
              flex-direction: row;
            `}>
            <B type="b-default">Drafted Payruns :</B>
            <B type="b-default-semibold">
              {numberThousandSeparator(draftedPayruns.length)}
            </B>
          </Col>
          <Col
            xs={24}
            sm={8}
            md={8}
            lg={8}
            xl={5}
            xxl={5}
            css={css`
              display: flex;
              flex-direction: row;
            `}>
            <B type="b-default">Active Payruns : </B>
            <B type="b-default-semibold">
              {numberThousandSeparator(activePayruns.length)}
            </B>
          </Col>
          <Col
            xs={24}
            sm={8}
            md={8}
            lg={8}
            xl={5}
            xxl={8}
            css={css`
              display: flex;
              flex-direction: row;
            `}>
            <B type="b-default"> Archived Payruns :</B>{' '}
            <B type="b-default-semibold">
              {numberThousandSeparator(archivedPayruns.length)}
            </B>
          </Col>
        </Row>
      </PayrollStatDiv>
    </>
  );
};

export default PayrollStats;
