import React, { FC } from 'react';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { css } from '@emotion/react';
import { Div, H } from '../../../components';
import { SalaryItemI } from '../modules/EmployeeSalary';
import styled from '@emotion/styled';
const MainFlex = styled.div`
  border: 0.5px solid #e0e0e0;
  border-radius: 4px;
  padding: 32px 24px;
`;
const columns: ColumnProps<BankingDetailI>[] = [
  {
    dataIndex: 'description',
  },
  {
    dataIndex: 'details',
    align: 'right',
  },
];
interface BankingDetailI {
  key: string;
  description: string;
  details: string;
}
interface Prop {
  selectedSalaryItems: SalaryItemI[];
}
const BankingDetail: FC<Prop> = ({ selectedSalaryItems }) => {
  const dataSource = selectedSalaryItems
    .filter((item) => item.type === 'info')
    .map((item, i) => {
      return {
        key: `${i}`,
        description: item.title,
        details: `${item.value}`,
      };
    });
  return (
    <>
      <MainFlex>
        <Div padding="0px 0px 12px 0px">
          <H type="h6">Banking Details</H>
        </Div>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          css={css`
            margin-bottom: 24px;
            .ant-table-thead > tr > th {
              display: none;
            }
            .ant-table-tbody > tr > td {
              font-family: Inter;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              border-bottom: 1px solid #e0e0e0;
              padding: 16px 16px 8px 0px;
            }
          `}
        />
        {/* <B type={'b-default-semibold'} color="#00318c">
          Edit Details
        </B> */}
      </MainFlex>
    </>
  );
};

export default BankingDetail;
