import React, { FC } from 'react';
import { Button, Divider, Form, InputNumber, Radio, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { PayItemType } from '../salary.helper';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import { Div, RadioButtonComp } from '../../../components';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface Props {
  onFinish: (values: PayItemType[]) => void;
  onChange: () => void;
}
const FormList = styled.div`
  position: relative;
`;
const FormDiv = styled.div`
  position: absolute;
  display: flex;
  bottom: -34px;
  right: 30px;
`;
const inputNumberFormatterProps = {
  formatter: (value: number | string | undefined) =>
    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  parser: (displayValue: string | undefined) =>
    `${displayValue}`.replace(/\$\s?|(,*)/g, ''),
};
const SalaryForm: FC<Props> = ({ onFinish, onChange }) => {
  return (
    <Form
      name="dynamic_form_nest_item"
      onFinish={(values) => onFinish(values.salaryItems)}
      onChange={onChange}
      initialValues={{
        salaryItems: [
          {
            type: 'ADDITION',
            amount: 15000,
            isTaxable: true,
            isEpf: true,
          },
        ],
      }}
      autoComplete="off">
      <Form.List name="salaryItems">
        {(fields, { add, remove }) => (
          <FormList>
            {fields.map((field, _index) => (
              <Space
                key={field.key}
                align="baseline"
                css={css`
                  position: relative;
                  margin-bottom: 20px;
                  text-align: initial;
                `}>
                <Form.Item
                  {...field}
                  name={[field.name, 'type']}
                  fieldKey={[field.fieldKey, 'type']}
                  rules={[{ required: true, message: 'type' }]}
                  initialValue="EARN"
                  css={css`
                    position: absolute;
                    display: flex;
                    top: -2px;
                    right: 20px;
                  `}>
                  <Radio.Group size="small">
                    <RadioButtonComp value="EARN">Earn (+)</RadioButtonComp>
                    <RadioButtonComp value="DEDUCTION">
                      Deduct (-)
                    </RadioButtonComp>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  {...field}
                  name={[field.name, 'amount']}
                  fieldKey={[field.fieldKey, 'amount']}
                  rules={[{ required: true, message: 'amount' }]}>
                  <InputNumber
                    min={0}
                    {...inputNumberFormatterProps}
                    placeholder="Amount"
                    css={css`
                      min-width: 250px;
                    `}
                  />
                </Form.Item>

                <FormDiv>
                  <Form.Item
                    {...field}
                    valuePropName="checked"
                    initialValue={false}
                    name={[field.name, 'isEpf']}
                    fieldKey={[field.fieldKey, 'isEpf']}>
                    <CheckBoxComp>EPF/ETF</CheckBoxComp>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    valuePropName="checked"
                    initialValue={false}
                    name={[field.name, 'isTaxable']}
                    fieldKey={[field.fieldKey, 'isTaxable']}>
                    <CheckBoxComp>TAX</CheckBoxComp>
                  </Form.Item>
                </FormDiv>
                <Divider dashed />

                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}>
                Add New Salary Item
              </Button>
            </Form.Item>
          </FormList>
        )}
      </Form.List>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Calculate My Salary
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SalaryForm;
