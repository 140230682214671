import React, { FC, useEffect, useState } from 'react';
import { B, Div, PrimaryButton } from '../../../../components';
import SystemUpdateTable from './SystemUpdateTable';
import AddNewUpdate from './AddNewUpdate';
import {
  useCreateSystemUpdate,
  useDeleteSystemUpdate,
  useEditSystemUpdate,
  useGetSystemUpdate,
  useGetSystemUpdates,
} from '../../../../api/superAdminHooks';
import { EditSystemUpdateI } from '../../../../types/superAdmin.types';
import { PlusWhiteSVG } from '../../../../assets';

const SuperSystemUpdates: FC = () => {
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const {
    isLoading: loadingSystemUpdatesList,
    data: systemUpdatesList = [],
    refetch: getSystemUpdateList,
  } = useGetSystemUpdates();

  const {
    isSuccess: createSystemUpdateDataSuccess,
    mutate: createSystemUpdate,
  } = useCreateSystemUpdate();

  const {
    isSuccess: successEditSystemUpdate,
    mutate: editSystemUpdate,
  } = useEditSystemUpdate();

  const {
    isSuccess: successArchiveLoan,
    mutate: deleteSystemUpdate,
  } = useDeleteSystemUpdate();

  const {
    data: dataGetSystemUpdate,
    mutate: getSystemUpdate,
  } = useGetSystemUpdate();

  useEffect(() => {
    if (
      successArchiveLoan ||
      createSystemUpdateDataSuccess ||
      successEditSystemUpdate
    ) {
      getSystemUpdateList();
    }
  }, [
    successArchiveLoan,
    createSystemUpdateDataSuccess,
    successEditSystemUpdate,
  ]);

  return (
    <Div m="24px 32px">
      <Div
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={24}>
        <B type="b-large-semibold">
          All System Updates ({systemUpdatesList.length})
        </B>
        <PrimaryButton
          size="small"
          onClick={() => {
            setVisible(true);
            setIsEdit(false);
          }}
          icon={<img src={PlusWhiteSVG} />}>
          Add New Update
        </PrimaryButton>
      </Div>
      <SystemUpdateTable
        systemUpdatesList={systemUpdatesList}
        loadingSystemUpdatesList={loadingSystemUpdatesList}
        deleteSystemUpdate={(id: number) => {
          if (id) {
            deleteSystemUpdate(id);
          }
        }}
        getSystemUpdate={(id: number) => {
          if (id) {
            getSystemUpdate(Number(id));
            setIsEdit(true);
          }
        }}
        onClickEdit={() => setVisible(true)}
      />
      {visible && (
        <AddNewUpdate
          createSystemUpdate={createSystemUpdate}
          editSystemUpdate={(value: EditSystemUpdateI) => {
            if (value) {
              editSystemUpdate(value);
            }
          }}
          isEdit={isEdit}
          systemUpdate={dataGetSystemUpdate}
          visible={visible}
          onCancel={() => setVisible(false)}
        />
      )}
    </Div>
  );
};

export default SuperSystemUpdates;
