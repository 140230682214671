import React, { FC } from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import theme from '../../theme';
import styled from '@emotion/styled';

interface Props extends InputProps {
  flexDirection?: string;
}
const InputStyled = styled(Input)<Props>`
  @media screen and (max-width: 768px) {
    ${(props) => props.flexDirection === 'row' && 'width: 100%;'}
  }
  ${(props) => props.flexDirection === 'row' && 'width: 344px;'}
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  padding: 0px 16px !important;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  height: ${(props) => (props.size == 'small' ? '32px' : '40px')}!important;

  &:focus {
    border: 1px solid ${theme.blue500};
    box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1);
  }

  &:hover {
    border: 1px solid ${theme.blue500};
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
    border-radius: 4px;
  }
`;

export const InputV2: FC<Props> = (props) => {
  const { size = 'large', flexDirection = 'column', ...rest } = props;
  return <InputStyled {...rest} size={size} flexDirection={flexDirection} />;
};
