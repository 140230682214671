import React, { FC, useEffect, useState } from 'react';
import {
  Col,
  Form,
  Modal,
  Row,
  Select,
  SelectProps,
  Tabs,
  TabsProps,
} from 'antd';
import html2canvas from 'html2canvas';
import {
  B,
  H,
  InputComp,
  PrimaryButton,
  SecondaryButton,
} from '../../../components';
import theme from '../../../theme';
import {
  ContractViewPlaceholdersI,
  NewSignatureEnum,
  PlaceholderTypeEnum,
} from '../../../types/contract.types';
import { SignatureInput } from '../shared/ContractLayout';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import styled from '@emotion/styled';
import { UploadDocumentSVG } from '../../../assets';
import Dropzone from 'react-dropzone';
import pdfPlaceHolderImage from '../../../assets/images/pdf-place-holder.png';

interface Props {
  visible: boolean;
  handleCancel: () => void;
  onSubmit: (
    file: File | Blob | null,
    value: ContractViewPlaceholdersI[],
    img: any,
  ) => void;
  placeHolderIds?: Array<string>;
  oldMySignature: string | null;
  nonExistUser: boolean;
}
interface FileWithPreviewI extends File {
  preview: string;
}
const Signature = styled.div`
  background: #fafafa;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  min-height: 105px;
  overflow-x: auto;
`;

const ModalComp = styled(Modal)`
  .ant-modal-header {
    border-radius: 8px 8px 0px 0px;
  }
  .ant-modal-body {
    padding: 0px 24px;
  }
  .ant-modal-content {
    padding: 20px 10px !important;
  }
`;

const FormComp = styled(Form)`
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: none;
  }
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: #00318c;
  }
  .ant-input[disabled] {
    color: #000000;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-bottom: 10px;
  }
`;

const SelectInput = styled(Select)<SelectProps>`
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
  .ant-select-selection-item {
    color: #3375ee !important;
  }
  .ant-select-arrow {
    color: #3375ee !important;
  }
`;

const FormItem = styled(Form.Item)``;

const LeftCol = styled(Col)`
  padding-right: 60px;
  text-align: left;
`;
const RightCol = styled(Col)`
  text-align: right;
`;

const CustomRow = styled(Row)`
  background: ${theme.gray150};
  border: 1px dashed ${theme.blue500};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 55px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;
const AddMySignatureModal: FC<Props> = ({
  visible,
  handleCancel,
  onSubmit,
  placeHolderIds,
  oldMySignature,
  nonExistUser,
}) => {
  const [form] = Form.useForm();
  const [name, setName] = useState<string | null>('');
  const [fontStyle, setFontStyle] = useState<string>('Great Vibes');
  const [selectedImage, setSelectedImage] = useState<any | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | Blob | null>(null);
  const [fileInfo, setFileInfo] = useState<{
    file: FileWithPreviewI | null;
    fileName: string | null;
  }>({
    file: null,
    fileName: null,
  });
  const onChange = (e: any) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const onFinish = () => {
    if (placeHolderIds && selectedImage) {
      const placeholderData: ContractViewPlaceholdersI[] = [];
      placeholderData.push({
        placeHolderId: placeHolderIds,
        signType: name ? NewSignatureEnum.NEW : NewSignatureEnum.EXISTING,
        type: PlaceholderTypeEnum.signature,
      });
      onSubmit(selectedFile, placeholderData, selectedImage);
    }
  };

  async function convertCanvasToImage() {
    const signature: any = document.getElementById('sign-canvas');
    const width = signature.getBoundingClientRect().width;
    html2canvas(signature, {
      backgroundColor: null,
      x: -20,
      width: width ? width + 40 : 300,
    }).then((canvas) => {
      if (name) {
        const image = canvas.toDataURL();
        setSelectedImage(image);
        canvas.toBlob((blob: any) => {
          const metadata = {
            type: 'image/png',
          };
          const file = new File([blob], 'image.png', metadata);
          setSelectedFile(file);
        });
      }
    });
  }

  const oldSignatureToImage = async () => {
    if (oldMySignature) {
      setSelectedImage(oldMySignature);
      const response = await fetch(oldMySignature, { mode: 'no-cors' });
      const blob = await response.blob();
      const file = new File([blob], 'image.jpg', { type: blob.type });
      setSelectedFile(file);
    }
  };

  useEffect(() => {
    if (selectedFile && selectedImage) {
      onFinish();
    }
  }, [selectedFile, selectedImage]);

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      file.preview = URL.createObjectURL(file);
      const fileName = file.name.slice(0, file.name.lastIndexOf('.'));
      setName(fileName);
      setFileInfo({ fileName, file });
    }
  };
  const fileUpload = async () => {
    const reader = new FileReader();
    reader.onloadend = async () => {
      await setSelectedImage(reader.result as string);
    };
    await reader.readAsDataURL(fileInfo.file);

    const metadata = {
      type: 'image/png',
    };
    const file = new File([fileInfo.file], 'image.png', metadata);
    await setSelectedFile(file);
  };

  const typeTab = () => {
    return (
      <FormComp validateTrigger="onBlur" form={form}>
        <B mt="23px" mb="6px" type="b-default">
          Confirm your name, initials and signature.
        </B>
        <Form.Item
          name="title"
          rules={[
            {
              required: oldMySignature ? false : true,
              message: 'Please enter file name!',
            },
          ]}>
          <InputComp
            id="name"
            name="name"
            maxLength={32}
            onChange={(e) => onChange(e)}
          />
        </Form.Item>
        <Form.Item>
          <Row>
            <Col xl={18}>
              <B type="b-small-medium" pt="5px" pb="4px">
                Signature
              </B>
            </Col>
            {name ? (
              <RightCol xl={6}>
                <SelectInput
                  defaultValue={fontStyle}
                  variant="borderless"
                  onSelect={(e) => setFontStyle(e)}>
                  <Select.Option value="Great Vibes">Style 1</Select.Option>
                  <Select.Option value="Dancing Script">Style 2</Select.Option>
                  <Select.Option value="Allison">Style 3</Select.Option>
                </SelectInput>
              </RightCol>
            ) : null}
          </Row>
          <Signature>
            {oldMySignature ? (
              name ? (
                <SignatureInput id="sign-canvas" fontStyle={fontStyle}>
                  {name}
                </SignatureInput>
              ) : (
                <img src={oldMySignature} height={70} width={300} />
              )
            ) : (
              <SignatureInput id="sign-canvas" fontStyle={fontStyle}>
                {name}
              </SignatureInput>
            )}
          </Signature>
        </Form.Item>
        {!nonExistUser && (
          <Form.Item>
            <CheckBoxComp>
              <B type="b-default" display="inline-block">
                Remember my signature for next time
              </B>
            </CheckBoxComp>
          </Form.Item>
        )}
        <FormItem>
          <Row>
            <LeftCol xl={12}>
              <SecondaryButton onClick={() => handleCancel()}>
                Cancel
              </SecondaryButton>
            </LeftCol>
            <RightCol xl={12}>
              <PrimaryButton
                htmlType="submit"
                onClick={async () =>
                  !name ? oldSignatureToImage() : convertCanvasToImage()
                }>
                Add Signature
              </PrimaryButton>
            </RightCol>
          </Row>
        </FormItem>
      </FormComp>
    );
  };
  const uploadTab = () => {
    return (
      <FormComp validateTrigger="onBlur" form={form}>
        <B mt="23px" mb="6px" type="b-default">
          Upload an image of your signature
        </B>

        <FormItem>
          <Form.Item>
            <Dropzone
              onDrop={handleDrop}
              accept={['.png']}
              minSize={1}
              maxSize={10240000}>
              {({
                getRootProps,
                getInputProps,
                isDragAccept,
                isDragReject,
              }) => {
                const additionalClass = isDragAccept
                  ? 'accept'
                  : isDragReject
                  ? 'reject'
                  : '';

                return (
                  <CustomRow
                    justify="space-around"
                    align="middle"
                    {...getRootProps({
                      className: `dropzone ${additionalClass}`,
                    })}>
                    {fileInfo.file ? (
                      <div>
                        <input {...getInputProps()} />
                        <img
                          style={{ maxWidth: '370px', maxHeight: '160px' }}
                          src={fileInfo.file.preview}
                          onLoad={() => {
                            URL.revokeObjectURL(fileInfo.file.preview);
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <input {...getInputProps()} />
                        <div className="ant-upload-drag-icon">
                          <img src={UploadDocumentSVG} />
                        </div>
                        <B
                          type="b-large-semibold"
                          color={theme.gray600}
                          pt="8px"
                          display="flex">
                          Drag and drop or
                          <B
                            type="b-large-semibold"
                            color={theme.blue400}
                            pl="5px">
                            Browse
                          </B>
                        </B>
                        <B type="b-default" color={theme.gray600} pt="4px">
                          Upload your Signature
                        </B>
                        <B type="b-small" color={theme.gray600} pt="4px">
                          Should be a png file and Maximum file size less than
                          10 MB
                        </B>
                      </>
                    )}
                  </CustomRow>
                );
              }}
            </Dropzone>
          </Form.Item>
          <Row>
            <LeftCol xl={12}>
              <SecondaryButton onClick={() => handleCancel()}>
                Cancel
              </SecondaryButton>
            </LeftCol>
            <RightCol xl={12}>
              <PrimaryButton
                htmlType="submit"
                onClick={async () => fileUpload()}>
                Add Signature
              </PrimaryButton>
            </RightCol>
          </Row>
        </FormItem>
      </FormComp>
    );
  };

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Type',
      children: typeTab(),
    },
    {
      key: '2',
      label: 'Upload',
      children: uploadTab(),
    },
  ];
  return (
    <ModalComp
      footer={null}
      width={540}
      open={visible}
      onCancel={handleCancel}
      title={
        <B pl="24px" type="default-large">
          Add Your Signature
        </B>
      }>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </ModalComp>
  );
};
export default AddMySignatureModal;
