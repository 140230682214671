import React, { FC, useState } from 'react';
import { Form } from 'antd';
import moment, { Moment } from 'moment';
import { B, DatePickerComp, InputComp } from '../../../components';
import { ModalComponent } from '../../../components/ModalComponent';
import { NewHolidayI } from '../../../types/organization.types';
import { isTodayOrBefore } from '../../../util/utils';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (formValues: NewHolidayI) => void;
}

export const AddHolidayModal: FC<Props> = ({ visible, onCancel, onSubmit }) => {
  const [disabled, setDisabled] = useState(true);
  const [isName, setIsName] = useState('');
  const [isDate, setIsDate] = useState<Moment | null>();

  const [form] = Form.useForm();

  async function onFinish(values: { date: string; description: string }) {
    values = {
      date: moment(values.date).format('YYYY-MM-DD'),
      description: values.description,
    };
    for (const [key] of Object.entries(values)) {
      form.setFieldsValue({ [key]: '' });
    }
    onSubmit(values);
  }

  return (
    <>
      <ModalComponent
        form={form}
        visible={visible}
        submitText="Create Holiday"
        disabled={isName && isDate ? false : true}
        width="532px"
        title={
          <div
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}>
            <B type="b-large-semibold" color="#202125">
              Add Holiday
            </B>
          </div>
        }
        onCancel={() => onCancel()}>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          name="form_in_modal">
          <div>
            <Form.Item
              name="description"
              rules={[
                { required: true, message: 'Please input holiday name' },
              ]}>
              <InputComp
                size="small"
                onChange={(e) => {
                  setIsName(e.target.value);
                }}
                label="Holiday Name"
              />
            </Form.Item>
            <Form.Item name="date">
              <DatePickerComp
                size="middle"
                onChange={(date) => {
                  setIsDate(moment(date.toDate()));
                }}
                label="Date"
                disabledDate={(d) => isTodayOrBefore(d.toString())}
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </div>
        </Form>
      </ModalComponent>
    </>
  );
};
