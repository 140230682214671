import React, { FC } from 'react';
import theme from '../../../../theme';
import { PeopleLayout } from '../../shared';
import { PeopleLogTrail } from './PeopleLogTrail';
import styled from '@emotion/styled';

const MainCard = styled.div`
  background: ${theme.white};
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px;
  @media (max-width: 768px) {
    border: none;
    padding-bottom: 24px;
  }
`;
const EmployeeLogTrailPage: FC = () => {
  return (
    <MainCard>
      <PeopleLayout>
        <PeopleLogTrail />
      </PeopleLayout>
    </MainCard>
  );
};

export default EmployeeLogTrailPage;
