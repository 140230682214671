import React, { FC } from 'react';
import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';
import theme from '../theme';
import styled from '@emotion/styled';

const CardComp = styled(Card)`
  border: 1px solid ${theme.gray300};
  border-radius: 4px;
  width: auto;
  .ant-card-body {
    padding: 0;
  }
`;
export const CustomCard: FC<CardProps> = (props) => (
  <CardComp>{props.children}</CardComp>
);
