import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

import { css } from '@emotion/react';
import { Div } from '../../../../components';

const NoCSVUploaded: FC = () => {
  const location = useLocation();

  return (
    <>
      <br />
      <Div display="flex" justifyContent="center" alignItems="center">
        Please go to &nbsp;
        {location.pathname === '/map-columns' ? (
          <Link to="/payroll">/payroll</Link>
        ) : (
          <Link to="/open-payroll">/open-payroll</Link>
        )}
        &nbsp; to upload csv document
      </Div>
    </>
  );
};

export default NoCSVUploaded;
