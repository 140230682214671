import React, { FC, useEffect, useState } from 'react';
import { Form, Row, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';
import { B, Div, SwitchComponent } from '../../../components';
import { useWindowSize } from '../../../util/screenSize';
import { TaxOnTaxSettingsI } from '../../../types/organization.types';
import { Divider } from '../../../components/Divider';
import { InfoSVG } from '../../../assets';

const MainDiv = styled.div<{ isDesktopLarge: boolean }>`
  width: ${({ isDesktopLarge }) => (isDesktopLarge ? '1200px' : '800px')};
  padding: 32px;
  @media (max-width: 768px) {
    padding: 24px 10px;
    width: 100%;
  }
`;

const InfoLogo = styled.img`
  margin-right: 2px;
  width: 12px;
  height: 12px;
`;

const FormItem = styled(Form.Item)<SpaceProps & LayoutProps>`
  margin-bottom: 0px;
  ${space}
  ${layout}
`;
interface Props {
  form: FormInstance<any>;
  taxOnTaxSettings?: TaxOnTaxSettingsI | null;
}
const TaxOnTax: FC<Props> = ({ form, taxOnTaxSettings }) => {
  const { isDesktopLarge } = useWindowSize();
  const [isAddTaxOnTax, setIsTaxOnTax] = useState<boolean>(false);

  useEffect(() => {
    if (taxOnTaxSettings) {
      setIsTaxOnTax(taxOnTaxSettings.taxOnTax.status);
      form.setFieldsValue({
        taxOnTax: !!taxOnTaxSettings.taxOnTax.status,

        borneBy: taxOnTaxSettings.taxOnTaxInfo.borneBy,
      });
    }
  }, [taxOnTaxSettings]);

  return (
    <>
      <MainDiv isDesktopLarge={isDesktopLarge}>
        <Div display="flex">
          <FormItem name="taxOnTax" valuePropName="checked">
            <SwitchComponent
              onChange={(checked: boolean) => {
                setIsTaxOnTax(checked);
              }}
            />
          </FormItem>
          <Row>
            <B type="b-default" pl="10px" pt="5px" pr="10px">
              Tax on Tax
            </B>

            <Div pt={1}>
              <Tooltip
                placement="right"
                title="Hourly No pay rate is calculated by dividing the sum of all pay types you tick 
                       by the no.of working hours per day and no. of working days per month.">
                <InfoLogo src={InfoSVG} />
              </Tooltip>
            </Div>
          </Row>
        </Div>
      </MainDiv>
      {/* Note: Keep this in case if the tax rules change in the future */}
      {/* {isAddTaxOnTax && (
        <FormContainer>
          <Div p="0px 32px 32px">
            <Row gutter={[20, 0]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <FormItem
                  name="borneBy"
                  label="Borne By"
                  rules={[
                    {
                      required: true,
                      message: 'Please choose whom should it borne',
                    },
                  ]}>
                  <SelectComp
                    size="middle"
                    placeholder="Choose"
                    options={[
                      {
                        label: 'Employee',
                        value: EmploymentType.EMPLOYEE,
                      },
                      {
                        label: 'Employer',
                        value: EmploymentType.EMPLOYER,
                      },
                    ]}
                  />
                </FormItem>
              </Col>
            </Row>
          </Div>
        </FormContainer>
      )} */}
      <Divider />
    </>
  );
};

export default TaxOnTax;
