import React, { FC, useEffect, useState } from 'react';
import { Col, Form, Radio, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';
import {
  B,
  Div,
  InputComp,
  InputNumberV2,
  RadioButtonComp,
  SelectComp,
  SwitchComponent,
} from '../../../components';
import theme from '../../../theme';
import { useWindowSize } from '../../../util/screenSize';
import CalculationSettings from './CalculationSettings';
import { Validation } from '../../../common/validation';
import {
  BasedCurrencyEnum,
  CurrencyType,
  PeggedSettingsI,
  PeggedTypeSelection,
  PegHandledBy,
} from '../../../types/organization.types';
import { SelectValue } from 'antd/lib/select';
import { PeggedTypeSelectionEnum } from '../../../types/payroll.types';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import { useOrganizationData } from '../../../custom-hooks/organizationHooks';
import { Divider } from '../../../components/Divider';

const MainDiv = styled.div<{ isDesktopLarge: boolean }>`
  width: ${({ isDesktopLarge }) => (isDesktopLarge ? '1200px' : '800px')};
  padding: 32px;
  @media (max-width: 768px) {
    padding: 24px 10px;
    width: 100%;
  }
`;

const FormContainer = styled.div`
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700} !important;
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
`;

const FormItem = styled(Form.Item)<SpaceProps & LayoutProps>`
  margin-bottom: 0px;
  ${space}
  ${layout}
`;
interface Props {
  form: FormInstance<any>;
  peggedSettings?: PeggedSettingsI | null;
  PeggedTypeSelection?: PeggedTypeSelection | null;
}
const ForeignCurrencyPeg: FC<Props> = ({
  form,
  peggedSettings,
  PeggedTypeSelection,
}) => {
  const { isDesktopLarge } = useWindowSize();
  const [isPeggedEnable, setIsPeggedEnable] = useState<boolean>(false);
  const [currencyType, setCurrencyType] = useState<BasedCurrencyEnum>();
  const [handledBy, setHandledBy] = useState<SelectValue>();
  const [
    isCheckPayItemValidation,
    setCheckPayItemValidation,
  ] = useState<boolean>();
  const organizationData = useOrganizationData();
  const [checkPayItem, setCheckPayItem] = useState<{
    PEGGED_BASIC: boolean | undefined;
    PEGGED_FIXED_ALLOWANCE: boolean | undefined;
    PEGGED_VARIABLE_ALLOWANCE: boolean | undefined;
  }>({
    PEGGED_BASIC: false,
    PEGGED_FIXED_ALLOWANCE: false,
    PEGGED_VARIABLE_ALLOWANCE: false,
  });

  useEffect(() => {
    setCheckPayItemValidation(Object.values(checkPayItem).some((item) => item));
  }, [checkPayItem]);

  useEffect(() => {
    if (PeggedTypeSelection) {
      setCheckPayItem({
        PEGGED_BASIC: PeggedTypeSelection?.BASIC,
        PEGGED_FIXED_ALLOWANCE: PeggedTypeSelection?.FIXED_ALLOWANCE,
        PEGGED_VARIABLE_ALLOWANCE: PeggedTypeSelection?.VARIABLE_ALLOWANCE,
      });
    }
  }, [PeggedTypeSelection]);

  useEffect(() => {
    if (peggedSettings && PeggedTypeSelection) {
      setIsPeggedEnable(peggedSettings?.pegged?.status);
      setCurrencyType(peggedSettings.peggedInfo.basedCurrency);
      form.setFieldsValue({
        pegged: !!peggedSettings?.pegged?.status,
        PEGGED_BASIC: !!PeggedTypeSelection.BASIC,
        PEGGED_FIXED_ALLOWANCE: !!PeggedTypeSelection.FIXED_ALLOWANCE,
        PEGGED_VARIABLE_ALLOWANCE: !!PeggedTypeSelection.VARIABLE_ALLOWANCE,
        basedCurrency: peggedSettings.peggedInfo.basedCurrency,
        foreignCurrencyType: peggedSettings.peggedInfo.foreignCurrencyType,
        exchangeRate: peggedSettings.peggedInfo.exchangeRate,
        currency: peggedSettings.peggedInfo.currency,
        handledBy: peggedSettings.peggedInfo.handledBy,
        allowanceTitle: peggedSettings.peggedInfo.allowanceTitle,
        isEpfEtf: peggedSettings.peggedInfo.isEpfEtf,
        isApit: peggedSettings.peggedInfo.isApit,
      });
    }
  }, [peggedSettings]);

  return (
    <>
      <MainDiv isDesktopLarge={isDesktopLarge}>
        <B type="b-large-semibold" pb="16px">
          Foreign Currency Pegged Payrolls
        </B>
        <Row>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <Div display="flex">
              <FormItem name="pegged" valuePropName="checked">
                <SwitchComponent
                  onChange={(checked: boolean) => {
                    setIsPeggedEnable(checked);
                  }}
                />
              </FormItem>
              <B type="b-default" pl="10px" pt="5px">
                Enable Pegging
              </B>
            </Div>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
            {isPeggedEnable && (
              <B type="b-small" color={theme.gray600} pt="5px">
                Please update master details according to your preference below
              </B>
            )}
          </Col>
        </Row>
      </MainDiv>
      {isPeggedEnable && (
        <FormContainer>
          <Div p="0px 32px 32px">
            <FormItem
              name="basedCurrency"
              rules={[
                {
                  required: true,
                  message: 'Please choose preferred based currency',
                },
              ]}>
              <Radio.Group
                name="basedCurrency"
                onChange={(e) => setCurrencyType(e.target.value)}
                value={currencyType}>
                <Div>
                  <B type="b-default" pb="18px">
                    Please select preferred based currency
                  </B>
                  <RadioButtonComp value={BasedCurrencyEnum.LKR}>
                    LKR
                  </RadioButtonComp>
                  <RadioButtonComp value={BasedCurrencyEnum.FOREIGN_CURRENCY}>
                    Foreign Currency
                  </RadioButtonComp>
                </Div>
              </Radio.Group>
            </FormItem>
          </Div>
          {currencyType === 'LKR' && (
            <>
              <CalculationSettings
                title="Please select which items you want to be pegged for the chose foreign currency"
                basicSalary={PeggedTypeSelectionEnum.BASIC}
                fixedAllowance={PeggedTypeSelectionEnum.FIXED_ALLOWANCE}
                variableAllowance={PeggedTypeSelectionEnum.VARIABLE_ALLOWANCE}
                isSelectPayItems={isCheckPayItemValidation}
                payItemName="peggedPayItem"
                onChange={(v) => {
                  switch (v.target.id) {
                    case PeggedTypeSelectionEnum.BASIC:
                      setCheckPayItem({
                        ...checkPayItem,
                        PEGGED_BASIC: v.target.checked,
                      });
                      break;
                    case PeggedTypeSelectionEnum.FIXED_ALLOWANCE:
                      setCheckPayItem({
                        ...checkPayItem,
                        PEGGED_FIXED_ALLOWANCE: v.target.checked,
                      });
                      break;
                    case PeggedTypeSelectionEnum.VARIABLE_ALLOWANCE:
                      setCheckPayItem({
                        ...checkPayItem,
                        PEGGED_VARIABLE_ALLOWANCE: v.target.checked,
                      });
                      break;
                    default:
                      break;
                  }
                }}
              />
              <Div p="0px 32px 32px">
                <Row gutter={[20, 0]}>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <FormItem
                      name="exchangeRate"
                      label="Base Exchange Rate*"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter base exchange rate',
                        },
                        {
                          pattern: organizationData?.isFourDecimalPoint
                            ? Validation.FourDecimalPointAmountPattern
                            : Validation.AmountPattern,
                          message: 'Please put a valid number',
                        },
                      ]}>
                      <InputNumberV2
                        min={1}
                        size="small"
                        flexDirection="row"
                        addonAfter="LKR"
                        placeholder="200"
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <FormItem
                      name="currency"
                      label="Currency*"
                      rules={[
                        {
                          required: true,
                          message: 'Please choose currency',
                        },
                      ]}>
                      <SelectComp
                        size="middle"
                        placeholder="Choose Currency"
                        options={[
                          {
                            label: 'USD',
                            value: CurrencyType.USD,
                          },
                          {
                            label: 'AUD',
                            value: CurrencyType.AUD,
                          },
                          {
                            label: 'Euro',
                            value: CurrencyType.EURO,
                          },
                          {
                            label: 'Pounds',
                            value: CurrencyType.POUNDS,
                          },
                        ]}
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Div pt={32}>
                  <Row gutter={[20, 0]}>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <FormItem
                        name="handledBy"
                        label="Handled By*"
                        rules={[
                          {
                            required: true,
                            message: 'Please choose preferred method',
                          },
                        ]}>
                        <SelectComp
                          size="middle"
                          placeholder="Choose"
                          options={[
                            {
                              label: 'Add to Basic Salary',
                              value: PegHandledBy.ADD_TO_BASIC_SALARY,
                            },
                            {
                              label: 'Add as an Allowance',
                              value: PegHandledBy.ADD_AS_AN_ALLOWANCE,
                            },
                          ]}
                          onChange={(e) => setHandledBy(e)}
                        />
                      </FormItem>
                    </Col>
                    {(handledBy === PegHandledBy.ADD_AS_AN_ALLOWANCE ||
                      (handledBy === undefined &&
                        peggedSettings?.peggedInfo.handledBy ===
                          PegHandledBy.ADD_AS_AN_ALLOWANCE)) && (
                      <>
                        <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                          <FormItem
                            name="allowanceTitle"
                            label="Allowance Title"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter allowance title',
                              },
                            ]}>
                            <InputComp
                              size="small"
                              placeholder="Eg: Living Cost Allowance"
                            />
                          </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                          <Div display="flex" height={25} pt="27px" pl="15px">
                            <Div display="flex">
                              <FormItem
                                name="isEpfEtf"
                                valuePropName="checked"
                                marginBottom="16px">
                                <CheckBoxComp />
                              </FormItem>
                              <B type="b-default" pl="18px" pt="6px" pr="51px">
                                EPF/ETF
                              </B>
                            </Div>
                            <Div display="flex">
                              <FormItem
                                name="isApit"
                                valuePropName="checked"
                                marginBottom="16px">
                                <CheckBoxComp />
                              </FormItem>
                              <B type="b-default" pl="18px" pt="6px" pr="51px">
                                APIT
                              </B>
                            </Div>
                          </Div>
                        </Col>
                      </>
                    )}
                  </Row>
                </Div>
              </Div>
            </>
          )}
          {currencyType === 'FOREIGN_CURRENCY' && (
            <Div p="0px 32px 32px">
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <FormItem
                  name="foreignCurrencyType"
                  label="Currency*"
                  rules={[
                    {
                      required: true,
                      message: 'Please choose currency',
                    },
                  ]}>
                  <SelectComp
                    size="middle"
                    placeholder="Choose Currency"
                    options={[
                      {
                        label: 'USD',
                        value: CurrencyType.USD,
                      },
                      {
                        label: 'AUD',
                        value: CurrencyType.AUD,
                      },
                      {
                        label: 'Euro',
                        value: CurrencyType.EURO,
                      },
                      {
                        label: 'Pounds',
                        value: CurrencyType.POUNDS,
                      },
                    ]}
                  />
                </FormItem>
              </Col>
            </Div>
          )}
        </FormContainer>
      )}
      <Divider />
    </>
  );
};

export default ForeignCurrencyPeg;
