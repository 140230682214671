import React, { FC, useEffect, useState } from 'react';

import styled from '@emotion/styled';

import { B, Div, InputComp } from '../../../../components';
import theme from '../../../../theme';

const HrsSection = styled.div`
  background: ${theme.gray100};
  padding: 6px 9px;
  border-left: 1px solid ${theme.gray400};
  box-sizing: border-box;
  border-radius: 0px 4px 4px 0px;
  margin-right: -7px;
  height: 30px;
`;

interface LeaveType {
  name: string;
  amount: string;
  disabled: boolean;
  addSuffix?: boolean;
  onChange: (value: string) => void;
}

const LeaveType: FC<LeaveType> = ({
  name,
  amount,
  disabled,
  addSuffix = true,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState('0');

  useEffect(() => {
    setInputValue(amount);
  }, [amount]);

  return (
    <>
      <Div display="flex" mb="16px">
        <B type="b-default" pt="6px">
          {name}
        </B>
        <Div width="101px" ml="auto">
          <InputComp
            disabled={disabled}
            onChange={(e) => {
              const value = e.target.value;
              onChange(value);
              setInputValue(value);
            }}
            value={inputValue}
            size="small"
            suffix={addSuffix && <HrsSection>Hrs</HrsSection>}
          />
        </Div>
      </Div>
    </>
  );
};

export default LeaveType;
