import { message } from 'antd';
import axios, { AxiosError } from 'axios';
import { getApiError } from './getApiError';

export function showApiError(
  error?: AxiosError | unknown,
  defaultError?: string,
) {
  if (axios.isAxiosError(error)) {
    const errorMessage = getApiError(error, defaultError);
    message.error(errorMessage);
    return errorMessage;
  }
  return null;
}

export default showApiError;
