import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { LogTrailTable } from '../../../../../components/LogTrailTable';
import { getPayrollLogTrails } from '../../../../../store/actions';
import { DispatchType, RootState } from '../../../../../store/reducer';

type ParamsI = {
  payrollKey: string;
};
export const PayrollLogTrail: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const params = useParams<ParamsI>();

  const peopleAuditLogs = useSelector(
    (state: RootState) => state.logTrail.payrollAuditLogs,
  );
  const logLoading = useSelector(
    (state: RootState) => state.logTrail.loadingPayrollAuditLogs,
  );

  useEffect(() => {
    dispatch(getPayrollLogTrails(params.payrollKey));
  }, []);

  return (
    <>
      <LogTrailTable logs={peopleAuditLogs} loading={logLoading} />
    </>
  );
};

export default PayrollLogTrail;
