import React, { FC, useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { B, Div } from '../../../components';
import { SwitchComponent } from '../../../components/SwitchComponent';
import { Divider } from '../../../components/Divider';
import styled from '@emotion/styled';
import theme from '../../../theme';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import { FormInstance } from 'antd/lib/form';
import { isStampDutySettingsI } from '../../../types/organization.types';
// TODO: write styled components
const MainCard = styled.div`
  padding: 32px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: #00318c;
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
`;
const Column = styled(Col)`
  margin-bottom: 24px;
`;
interface Props {
  form: FormInstance<any>;
  isStampDutySettings?: isStampDutySettingsI;
}
const StampDutySetting: FC<Props> = ({ isStampDutySettings, form }) => {
  const [isStampDutyEnabled, setIsStampDutyEnabled] = useState(false);

  useEffect(() => {
    if (isStampDutySettings) {
      setIsStampDutyEnabled(isStampDutySettings.isStampDutyAllowed);
    }
  }, [isStampDutySettings]);

  return (
    <>
      <MainCard>
        <Row>
          <Column>
            <B type="b-large-semibold">Stamp Duty</B>
          </Column>
        </Row>
        <Row>
          <Div display="flex">
            <Form.Item name="isStampDutyAllowed" valuePropName="checked">
              <SwitchComponent
                onChange={(checked: boolean) => {
                  setIsStampDutyEnabled(checked);
                }}
              />
            </Form.Item>
            <Div display="flex" flexDirection="column">
              <B type="b-large" ml="18px" mt="4px">
                Automatically calculate stamp duty for employees
              </B>
              <Div ml="18px">
                <B type="b-small" mb="16px" color={theme.gray600}>
                  The system will automatically add a new salary component
                  called "Stamp Duty" when it meets the conditions. You can
                  manually remove or change it in the payslip page for each
                  payroll employees
                </B>
              </Div>
            </Div>
          </Div>
        </Row>
        {isStampDutyEnabled && (
          <Div display="flex" height={25} pl="15px">
            <Form.Item name="isDeductionsExclude" valuePropName="checked">
              <CheckBoxComp />
            </Form.Item>
            <B ml="18px" mt="6px" type="b-default">
              Exclude Deductions
            </B>
          </Div>
        )}
      </MainCard>
    </>
  );
};

export default StampDutySetting;
