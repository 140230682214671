import styled from '@emotion/styled';
import { Col, message, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUploadForDataExtract } from '../../../../api/DataImportHooks';
import { B, FileUploadComponent, PrimaryButton } from '../../../../components';
import { DataImportConfig } from '../../../../configs';

import {
  DataExtractorCSVEnum,
  uploadExcelSheet,
} from '../../../../store/actions';
import { getApiError } from '../../../../util/getApiError';
import { DispatchType } from '../../../../store';

const DownloadStyle = styled.a`
  color: #0052ea;
  padding-left: 4px;
`;
const ContainerStyle = styled.div`
  background: #fafafa;
  border: 0.5px solid #e0e0e0;
  border-top: none !important;
  box-sizing: border-box;
  padding: 24px 32px 32px;
  margin: -16px -31px 0px !important;
  @media (max-width: 760px) {
    margin: -16px -16px 0px !important;
    padding: 24px 16px 32px;
  }
`;
interface Props {
  extractorInfo: DataImportConfig;
}
const ImportEmployeesCSV: FC<Props> = ({ extractorInfo }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const dispatch: DispatchType = useDispatch();
  const {
    isLoading: loadingUploadForDataExtract,
    error: errorUploadForDataExtract,
    mutate: uploadForDataExtract,
    data: uploadForData,
  } = useUploadForDataExtract();

  useEffect(() => {
    errorUploadForDataExtract &&
      message.error(getApiError(errorUploadForDataExtract));
  }, [errorUploadForDataExtract]);
  useEffect(() => {
    if (!errorUploadForDataExtract && uploadForData) {
      dispatch(uploadExcelSheet(uploadForData));
    }
  }, [uploadForData]);

  return (
    <ContainerStyle>
      <B type="b-large-semibold">Upload your csv file</B>
      <>
        <B type="b-default" py="24px" color="#757575">
          Please ensure your employees' details are up to date. File type should
          be CSV.
          <DownloadStyle
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            download
            href={extractorInfo.sampleFiles[DataExtractorCSVEnum.EMPLOYEE_CSV]}>
            Download{' '}
          </DownloadStyle>
          Sample Employee details sheet.
        </B>
      </>
      {/* changes */}
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <FileUploadComponent
            onFileChanged={(file) => setSelectedFile(file)}
            csvType={DataExtractorCSVEnum.EMPLOYEE_CSV}
          />
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <PrimaryButton
            disabled={!selectedFile}
            loading={loadingUploadForDataExtract}
            onClick={() => {
              if (selectedFile) {
                uploadForDataExtract({
                  type: extractorInfo.extractorType,
                  csvType: DataExtractorCSVEnum.EMPLOYEE_CSV,
                  file: selectedFile,
                });
              }
            }}>
            Import
          </PrimaryButton>
        </Col>
      </Row>
    </ContainerStyle>
  );
};

export default ImportEmployeesCSV;
