import React, { FC } from 'react';
import { Select, TreeSelect } from 'antd';
import {
  SelectProps,
  SelectValue as DefaultSelectValues,
} from 'antd/lib/select';
import {
  SelectValue as TreeSelectValues,
  TreeSelectProps,
} from 'antd/lib/tree-select';
import styled from '@emotion/styled';

interface Props extends SelectProps<DefaultSelectValues> {
  width?: number | null;
}
const SelectComp = styled(Select)<{ width: number }>`
  ${(props) => props.width && `width: ${props.width}px;`}
  min-width: 150px;
  height: 34px;
`;

const TreeSelectComp = styled(TreeSelect)<{ width: number }>`
  ${(props) => props.width && `width: ${props.width}px;`}
  min-width: 150px;
  height: 34px;
`;
export const CustomItemSelector: FC<Props> = (props) => {
  const { width = null, ...rest } = props;
  return <SelectComp width={width} {...rest} />;
};

interface TreeProps extends TreeSelectProps<TreeSelectValues> {
  width?: number | null;
}

export const CustomTreeItemSelector: FC<TreeProps> = (props) => {
  const { width = null, ...rest } = props;
  return <TreeSelectComp width={width} {...rest} />;
};
