import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, message, Radio, Spin } from 'antd';
import {
  AlertMessage,
  B,
  DatePickerComp,
  Div,
  Image,
  ModalComponent,
  RadioButtonComp,
  SelectComp,
} from '../../../../components';
import theme from '../../../../theme';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  getMinimumUserInfo,
  getETFReportData,
} from '../../../../store/actions';
import styled from '@emotion/styled';
import {
  useGetAnnualReport,
  useGetT10Report,
} from '../../../../api/reportHooks';
import dayjs from 'dayjs';
import { DispatchType } from '../../../../store';
import { PayrollCalenderSVG } from '../../../../assets';

interface Props {
  visible: boolean;
  onSubmit?: () => void;
  onCancel: () => void;
  exportType: string;
  title: string;
  description: string;
  allEmployeesMenu: JSX.Element;
  employees: number[];
}

const DropdownButton = styled(Button)`
  width: 100%;
  border-radius: 4px;
  height: 32px;
  padding: 4px 12px 6px 16px !important;
  text-align: start !important;
  :hover,
  :focus {
    color: ${theme.black} !important;
    border-color: ${theme.gray300} !important;
  }
`;

const EmpCount = styled.span`
  margin-left: 5px;
  background: ${theme.gray200};
  border-radius: 4px;
  padding: 0px 8px;
`;

const ExportModal: FC<Props> = ({
  visible,
  onSubmit,
  onCancel,
  exportType,
  title,
  description,
  allEmployeesMenu,
  employees,
}) => {
  const [form] = Form.useForm();
  const dispatch: DispatchType = useDispatch();
  const [radioValue, setRadioValue] = useState(1);
  const [year, setYear] = useState(moment().year());
  const [startYear, setStartYear] = useState<string>(
    moment().subtract(1, 'year').format('YYYY'),
  );
  const [endYear, setEndYear] = useState<string>(moment().format('YYYY'));

  useEffect(() => {
    dispatch(getMinimumUserInfo());
  }, []);

  const params = {
    startYear: startYear,
    endYear: endYear,
    listOfIds: JSON.stringify(employees),
  };

  const {
    isLoading: t10Loading,
    data: t10Data,
    mutate: getT10Reports,
  } = useGetT10Report(params);

  const {
    isLoading: annualReportLoading,
    data: annualReportData,
    mutate: getAnnualReport,
  } = useGetAnnualReport(params);

  useEffect(() => {
    if (startYear && endYear && !!employees.length && exportType === 'T10') {
      getT10Reports();
    }
  }, [startYear, endYear, employees]);

  useEffect(() => {
    if (
      startYear &&
      endYear &&
      !!employees.length &&
      exportType === 'AnnualStatement'
    ) {
      getAnnualReport();
    }
  }, [startYear, endYear, employees]);

  useEffect(() => {
    if (exportType === 'EPF') {
      let StartD, EndD;

      const etfReportYear = year ? year : moment().year();

      if (radioValue === 1) {
        StartD = moment(`${etfReportYear}-01-01`);
        EndD = moment(`${etfReportYear}-06-30`);
      } else if (radioValue === 2) {
        StartD = moment(`${etfReportYear}-07-01`);
        EndD = moment(`${etfReportYear}-12-31`);
      }

      dispatch(
        getETFReportData({
          startDate: moment(StartD).format('YYYY-MM-DD'),
          endDate: moment(EndD).format('YYYY-MM-DD'),
        }),
      );
    }
  }, [year, radioValue]);

  const downloadT10Files = () => {
    if (exportType === 'T10' && employees && employees.length) {
      window.open(t10Data);
    } else if (
      exportType === 'AnnualStatement' &&
      employees &&
      employees.length
    ) {
      window.open(annualReportData);
    } else {
      message.error('Please select atleast one employee before proceeding');
    }
  };

  return (
    <>
      <ModalComponent
        form={form}
        centered
        loading={
          exportType === 'T10'
            ? t10Loading
            : exportType === 'AnnualStatement'
            ? annualReportLoading
            : false
        }
        visible={visible}
        width={400}
        onSubmit={exportType === 'EPF' ? onSubmit : () => downloadT10Files()}
        onCancel={onCancel}
        disabled={
          (exportType === 'T10' && !t10Data) ||
          (exportType === 'AnnualStatement' && !annualReportData)
        }
        submitText="Export"
        title={
          <B type="b-large-semibold" color={theme.black}>
            {title}
          </B>
        }>
        <Spin
          spinning={
            exportType === 'T10'
              ? t10Loading
              : exportType === 'AnnualStatement'
              ? annualReportLoading
              : false
          }>
          <div>
            <Col>
              {exportType === 'T10' && !t10Data && (
                <Div Pb="16px">
                  <AlertMessage
                    title="Tax details unavailable for the selected period and/or employee(s)"
                    type="error"
                    iconCssStyle="margin-top: -15px"
                  />
                </Div>
              )}
              {exportType === 'AnnualStatement' && !annualReportData && (
                <Div Pb="16px">
                  <AlertMessage
                    title="Incomplete tax details for selected financial year or employee(s) in the system"
                    type="error"
                    iconCssStyle="margin-top: -15px"
                  />
                </Div>
              )}
              <B type="b-small" color={theme.gray600}>
                {description}
              </B>
              <Div ml="auto" mt="32px">
                <Dropdown overlay={allEmployeesMenu}>
                  <DropdownButton>
                    All Employees
                    <EmpCount>
                      <B type="b-small">{employees.length}</B>
                    </EmpCount>
                    <DownOutlined />
                  </DropdownButton>
                </Dropdown>
              </Div>
              <>
                <Div m="12px 0px 16px 0px">
                  {exportType === 'EPF' ? (
                    <DatePickerComp
                      size="middle"
                      onChange={(values) => {
                        if (values) {
                          setYear(values.year());
                        }
                      }}
                      picker="year"
                      defaultValue={dayjs()}
                      suffixIcon={
                        <Image src={PayrollCalenderSVG} marginRight="7px" />
                      }
                    />
                  ) : (
                    <>
                      <SelectComp
                        size="middle"
                        placeholder="Select Year"
                        options={[
                          {
                            label:
                              moment().subtract(1, 'year').year() +
                              '/' +
                              moment().format('YY'),
                            value:
                              moment().subtract(1, 'year').year() +
                              '/' +
                              moment().year(),
                          },
                          {
                            label:
                              moment().year() +
                              '/' +
                              moment().add(1, 'year').format('YY'),
                            value:
                              moment().year() +
                              '/' +
                              moment().add(1, 'year').year(),
                          },
                        ]}
                        onChange={(v) => {
                          setStartYear(String(v).slice(0, 4));
                          setEndYear(String(v).slice(-4));
                        }}
                        defaultValue={
                          moment().subtract(1, 'year').year() +
                          '/' +
                          moment().format('YY')
                        }
                      />
                    </>
                  )}
                </Div>
                {exportType === 'EPF' && (
                  <Div m="16px 0px 18px 0px">
                    <Radio.Group
                      onChange={(e) => {
                        setRadioValue(e.target.value);
                      }}
                      value={radioValue}>
                      <Div>
                        <B type="b-default" mb="8px" color={theme.black}>
                          Select Period
                        </B>
                        <RadioButtonComp value={1}>
                          First Half (Jan 1 - Jun 31)
                        </RadioButtonComp>
                        <br /> <br />
                        <RadioButtonComp value={2}>
                          Second Half (Jul 1 - Dec 31)
                        </RadioButtonComp>
                      </Div>
                    </Radio.Group>
                  </Div>
                )}
              </>
            </Col>
          </div>
        </Spin>
      </ModalComponent>
    </>
  );
};

export default ExportModal;
