import React, { FC, useEffect } from 'react';
import { PeopleLayout } from '../../shared';
import { DocumentTable } from './DocumentTable';
import { useIndividualPeopleState } from '../../../../providers/IndividualPeopleState';
import { useNavigate, useParams } from 'react-router-dom';
import { FooterComponent, PrimaryButton } from '../../../../components';

type ParamsI = {
  employeeKey: string;
};

const Document: FC = () => {
  const navigate = useNavigate();
  const params = useParams<ParamsI>();

  const {
    state: { people, isLoading },
    fetchPeopleById,
  } = useIndividualPeopleState();

  function fetchPeople() {
    fetchPeopleById(params.employeeKey);
  }

  useEffect(() => {
    fetchPeople();
  }, []);

  return (
    <PeopleLayout>
      <DocumentTable />
      {people?.isPending && (
        <FooterComponent
          rightChildren={
            <PrimaryButton
              ml={16}
              htmlType="submit"
              loading={isLoading}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/contact/notes/${params.employeeKey}`);
              }}>
              Next
            </PrimaryButton>
          }
        />
      )}
    </PeopleLayout>
  );
};

export default Document;
