import {
  AllContractsI,
  ContractStatusI,
  SignatoryI,
} from '../../../types/contract.types';

export const getContractSignedFractionWords = ({
  contract,
}: {
  contract: AllContractsI;
}): string | null => {
  let count = 0;
  const contractStatus = contract?.contractStatus;
  const totalSignatoryCount = getTotalSignatoriesCount(contract);

  contractStatus.map((status) => {
    if (status.isSigned) {
      count++;
    }
  });

  return `${count} out of ${totalSignatoryCount}`;
};

export const getContractSignedFraction = (contract: AllContractsI) => {
  const contractStatus = contract?.contractStatus;
  const totalSignatoryCount = getTotalSignatoriesCount(contract);

  let count = 0;

  contractStatus?.map((status) => {
    if (status?.isSigned) {
      count++;
    }
  });

  return `${count}/${totalSignatoryCount}`;
};
function getTotalSignatoriesCount(contract: AllContractsI): number {
  const firstSignatoriesCount = contract?.firstSignatories
    ? contract?.firstSignatories?.length
    : 0;

  const secondSignatoriesCount = contract?.secondSignatories
    ? contract?.secondSignatories.length
    : 0;

  const totalCount = firstSignatoriesCount + secondSignatoriesCount;

  return totalCount;
}
