import React, { FC, useEffect } from 'react';

import { Popover, Spin } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AvatarComponent, Div, TableComp } from '../../../../components';
import { getOrganizations } from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import { useGetOrganizationOwnersDetails } from '../../../../api/superAdminHooks';
import { getOrganizationsSummary } from '../../../../store/actions';
import { DataRangeI } from '../../../../types/report.types';
import { OrganizationOwnersDetailsI } from '../../../../types/superAdmin.types';
import moment from 'moment';

const SuperSystemReportTable: FC<{ dateRange: DataRangeI | null }> = ({
  dateRange,
}) => {
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();

  useEffect(() => {
    dispatch(getOrganizations());
  }, []);

  useEffect(() => {
    dispatch(getOrganizationsSummary());
  }, []);

  const {
    isLoading: organizationOwnersDetailsDataLoading,
    data: organizationOwnersDetails,
    mutate: getOrganizationOwnersDetails,
  } = useGetOrganizationOwnersDetails();

  const startDate = moment(dateRange?.startDate).format('YYYY-MM-DD');
  const endDate = moment(dateRange?.endDate).format('YYYY-MM-DD');

  useEffect(() => {
    getOrganizationOwnersDetails({
      startDate: String(startDate),
      endDate: String(endDate),
    });
  }, [startDate, endDate]);

  const columns: ColumnProps<OrganizationOwnersDetailsI>[] = [
    {
      title: 'OrganizationId',
      dataIndex: 'p_organizationId',
    },
    {
      title: 'OrganizationName',
      dataIndex: 'organizationName',
    },
    {
      title: 'OwnerName',
      dataIndex: 'ownerName',
    },
    {
      title: 'Payroll date',
      dataIndex: 'lastPayrollDate',
      render: (date: string) => moment(date).format('YYYY-MM-DD'),
    },
    {
      title: 'No Of Active Users',
      dataIndex: 'numberOfActiveUsers',
    },
    {
      title: 'No Of Payrolls',
      dataIndex: 'numberOfPayrolls',
    },
  ];

  return (
    <>
      <Spin spinning={organizationOwnersDetailsDataLoading}>
        <div>
          <TableComp
            withBorders={true}
            loading={organizationOwnersDetailsDataLoading}
            rowKey={(item) => item.organizationId}
            pagination={false}
            columns={columns}
            dataSource={organizationOwnersDetails}
            size="large"
            scroll={{
              x: 800,
              y: window.innerHeight - 370,
            }}
          />
        </div>
      </Spin>
    </>
  );
};

export default SuperSystemReportTable;
