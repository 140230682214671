import React, { FC, useEffect, useState } from 'react';
import { message } from 'antd';
import { useParams } from 'react-router';
import { B, Div } from '../../../../components';
import { RegulatoryCompliance } from '../../../../components/SalarySlip/RegulatoryCompliance';
import {
  SalaryContainer,
  SalaryItem,
} from '../../../../components/SalarySlip/SalaryItem';
import { SalaryInformationI } from '../../../../store/actions';
import theme from '../../../../theme';
import {
  PayrollItemI,
  PayrollItemTypeEnum,
  PayrunType,
  PayslipSalaryTitleEnum,
  SalaryInformationView,
} from '../../../../types/payroll.types';
import { numberThousandSeparator } from '../../../../util/utils';
import { RemunerationModal } from '../../../people/pages/pay-info/RemunerationModal';
import {
  getSalaryItemsByType,
  getSalaryItemsByTypeForExecutives,
} from './utils/getSalaryItemsByType';
import {
  useCreatePayrollItem,
  useDeletePayrollItem,
  usePutPayrollItem,
} from '../../../../api/payrollHooks';
import { useGetPeople } from '../../../../api/peopleHooks';
import { BasedCurrencyEnum } from '../../../../types/organization.types';
import { EXECUTIVE, orgsWithSeparateEPFExecutives } from '../../../../configs';

type payrollParam = {
  payrollKey: string;
  employeeKey: string;
};

interface Props {
  payrollDetails: SalaryInformationI | null;
  getAllPayrollDetails: Function;
}

const PersonalSalarySlipTable: FC<Props> = ({
  payrollDetails,
  getAllPayrollDetails,
}) => {
  const params = useParams<payrollParam>();

  const userPayrollDetails = payrollDetails?.empPayroll.find(
    (item) => item.userId === Number(params.employeeKey),
  );

  const payrollItems = userPayrollDetails?.payrollItems || [];

  const { data: peopleData, mutate: fetchPeople } = useGetPeople();

  const itemByTypes = getSalaryItemsByType(payrollItems);
  let itemByTypesForExecs;
  if (
    Array.isArray(peopleData?.teams) &&
    peopleData?.teams.some((team) => team.toLowerCase() === EXECUTIVE) &&
    orgsWithSeparateEPFExecutives.includes?.(peopleData.organizationId)
  ) {
    itemByTypesForExecs = getSalaryItemsByTypeForExecutives(payrollItems);
  }
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedPayItem, setSelectedPayItem] = useState<null | PayrollItemI>(
    null,
  );
  const [readOnly, setReadOnly] = useState<PayrollItemTypeEnum>();

  const [isMidDeduction, setIsMidDeduction] = useState(true);

  useEffect(() => {
    if (params.employeeKey) {
      fetchPeople(params.employeeKey);
    }
  }, [params.employeeKey]);

  const onClickEdit = (item: PayrollItemI) => {
    setModalVisible(true);
    setSelectedPayItem(item);
  };

  const onHandleClose = () => {
    setModalVisible(false);
    setSelectedPayItem(null);
  };

  const onClickAdd = (PayrollItemType: PayrollItemTypeEnum) => {
    setModalVisible(true);
    setReadOnly(PayrollItemType);
  };

  const {
    error: errorUpdatePayrollItem,
    data: dataUpdatePayrollItem = null,
    mutate: updatePayrollItem,
  } = usePutPayrollItem();

  const {
    error: errorCreatePayrollItem,
    data: dataCreatePayrollItem = null,
    mutate: createPayrollItem,
  } = useCreatePayrollItem();

  const {
    error: errorDeletePayrollItem,
    data: dataDeletePayrollItem,
    mutate: deletePayrollItem,
  } = useDeletePayrollItem();

  useEffect(() => {
    if (dataCreatePayrollItem && !errorCreatePayrollItem) {
      getAllPayrollDetails(params.payrollKey);
      fetchPeople(params.employeeKey);
    }
  }, [dataCreatePayrollItem]);
  useEffect(() => {
    if (dataUpdatePayrollItem && !errorUpdatePayrollItem) {
      getAllPayrollDetails(params.payrollKey);
      fetchPeople(params.employeeKey);
    }
  }, [dataUpdatePayrollItem]);

  useEffect(() => {
    if (dataDeletePayrollItem && !errorDeletePayrollItem) {
      getAllPayrollDetails(params.payrollKey);
      fetchPeople(params.employeeKey);
    }
  }, [dataDeletePayrollItem]);

  const onDeletePayItem = (itemId: number | undefined) => {
    if (itemId) {
      deletePayrollItem({ itemIds: [itemId], payrollId: params.payrollKey });
    } else {
      message.error('payroll item not found');
    }
  };

  return (
    <>
      <Div border={`1px solid ${theme.gray300}`} p="16px" borderRadius="2px">
        {modalVisible && SalaryInformationView.CONFIRM_EMPLOYEE && (
          <RemunerationModal
            payrollItems={payrollItems}
            visible={modalVisible}
            readOnly={readOnly}
            applyMasterData
            isMidCyclePayrun={
              payrollDetails?.payrunType === PayrunType.MID_CYCLE_PAYRUN
            }
            isForeignCurrencyPayroll={
              payrollDetails?.peggedBasedCurrency ===
              BasedCurrencyEnum.FOREIGN_CURRENCY
                ? true
                : false
            }
            textTip="Changes made will only effect current pay run and not master
            salary details."
            payDetail={
              selectedPayItem
                ? { ...selectedPayItem, userId: params.employeeKey }
                : null
            }
            userData={peopleData}
            handleCancel={() => onHandleClose()}
            onSubmit={async (values) => {
              if (selectedPayItem) {
                const value = {
                  ...values,
                  id: selectedPayItem.id,
                };
                updatePayrollItem(value);
              } else {
                const value = {
                  ...values,
                  payrollId: params.payrollKey,
                  empPayrollId: String(userPayrollDetails?.id),
                };
                createPayrollItem(value);
              }
              onHandleClose();
            }}
          />
        )}
        <B type="b-large-semibold" pb="22px">
          Earnings
        </B>
        {payrollDetails?.payrunType === PayrunType.MONTHLY_PAYRUN && (
          <>
            <SalaryContainer>
              <SalaryItem
                title="Basic Salary"
                onClickAdd={() => {
                  onClickAdd(PayrollItemTypeEnum.BASIC);
                }}
                onClickEdit={(item) => onClickEdit(item)}
                onClickDelete={(item) => onDeletePayItem(item.id)}
                salaryItems={itemByTypes[PayrollItemTypeEnum.BASIC]}
                status={payrollDetails?.status}
              />
            </SalaryContainer>
            <SalaryContainer>
              <SalaryItem
                title="Fixed Allowances"
                onClickAdd={() => {
                  onClickAdd(PayrollItemTypeEnum.FIXED_ALLOWANCE);
                }}
                onClickEdit={(item) => onClickEdit(item)}
                onClickDelete={(item) => onDeletePayItem(item.id)}
                salaryItems={itemByTypes[PayrollItemTypeEnum.FIXED_ALLOWANCE]}
                status={payrollDetails?.status}
              />
            </SalaryContainer>
          </>
        )}

        <SalaryContainer>
          <SalaryItem
            title="Variable Allowances"
            onClickAdd={() => {
              onClickAdd(PayrollItemTypeEnum.VARIABLE_ALLOWANCE);
            }}
            onClickEdit={(item) => onClickEdit(item)}
            onClickDelete={(item) => onDeletePayItem(item.id)}
            salaryItems={itemByTypes[PayrollItemTypeEnum.VARIABLE_ALLOWANCE]}
            status={payrollDetails?.status}
            isMonthlyPayrun={
              payrollDetails?.payrunType === PayrunType.MONTHLY_PAYRUN
                ? true
                : false
            }
          />
        </SalaryContainer>
        {payrollDetails?.payrunType === PayrunType.MONTHLY_PAYRUN && (
          <SalaryContainer>
            <SalaryItem
              title="Non-Cash Benefits"
              onClickAdd={() => {
                onClickAdd(PayrollItemTypeEnum.NON_CASH_BENEFITS);
              }}
              onClickEdit={(item) => onClickEdit(item)}
              onClickDelete={(item) => onDeletePayItem(item.id)}
              salaryItems={itemByTypes[PayrollItemTypeEnum.NON_CASH_BENEFITS]}
              status={payrollDetails?.status}
            />
          </SalaryContainer>
        )}

        <SalaryContainer>
          <SalaryItem
            title="Lump Sum Payments"
            onClickAdd={() => {
              onClickAdd(PayrollItemTypeEnum.LUMP_SUM);
            }}
            onClickEdit={(item) => onClickEdit(item)}
            onClickDelete={(item) => onDeletePayItem(item.id)}
            salaryItems={itemByTypes[PayrollItemTypeEnum.LUMP_SUM]}
            status={payrollDetails?.status}
            isMonthlyPayrun={
              payrollDetails?.payrunType === PayrunType.MONTHLY_PAYRUN
                ? true
                : false
            }
          />
        </SalaryContainer>

        <SalaryContainer>
          {payrollDetails?.payrunType === PayrunType.MONTHLY_PAYRUN ? (
            <SalaryItem
              onClickAdd={() => {
                onClickAdd(PayrollItemTypeEnum.DEDUCTION);
              }}
              onClickEdit={(item) => onClickEdit(item)}
              title={PayslipSalaryTitleEnum.DEDUCTIONS}
              onClickDelete={(item) => onDeletePayItem(item.id)}
              salaryItems={itemByTypes[PayrollItemTypeEnum.DEDUCTION]}
              status={payrollDetails?.status}
            />
          ) : (
            <SalaryItem
              onClickEdit={(item) => onClickEdit(item)}
              title="Deductions"
              onClickDelete={(item) => onDeletePayItem(item.id)}
              salaryItems={itemByTypes[PayrollItemTypeEnum.DEDUCTION].filter(
                (item) => item.payTitle === 'Stamp Duty',
              )}
              status={payrollDetails?.status}
              isMidDeduction={isMidDeduction}
            />
          )}
        </SalaryContainer>

        {userPayrollDetails?.netSalary !== 0 && (
          <>
            <SalaryContainer>
              <Div
                borderBottom={`1px solid ${theme.gray300}`}
                py="7px"
                background={theme.gray100}
                display="flex"
                borderRadius="2px"
                mb="32px">
                <Div display="flex">
                  <B type="b-small" color={theme.gray600} pt="2px" pl="16px">
                    Total Compensation
                  </B>
                  &nbsp;
                  <B type="b-large-semibold" pl="16px">
                    {numberThousandSeparator(
                      userPayrollDetails?.totalCompensation
                        ? userPayrollDetails.totalCompensation
                        : 0,
                    )}
                  </B>
                </Div>
                <Div display="flex" ml="auto" pr="16px">
                  <B type="b-small" color={theme.gray600} pt="2px" pr="16px">
                    Net Pay
                  </B>
                  &nbsp;
                  <B type="b-large-semibold">
                    {numberThousandSeparator(
                      userPayrollDetails ? userPayrollDetails?.netSalary : 0,
                    )}
                  </B>
                </Div>
              </Div>
            </SalaryContainer>

            {peopleData &&
            itemByTypesForExecs &&
            orgsWithSeparateEPFExecutives?.includes(
              peopleData?.organizationId,
            ) &&
            peopleData.teams &&
            peopleData?.teams?.includes(EXECUTIVE) ? (
              <Div>
                {itemByTypesForExecs[PayrollItemTypeEnum.EMPLOYEE_EPF].length >
                  0 && (
                  <>
                    <B type="b-large-semibold" pb="22px">
                      Regulatory Compliance
                    </B>
                    <RegulatoryCompliance
                      salaryItems={
                        itemByTypesForExecs[PayrollItemTypeEnum.EMPLOYEE_EPF]
                      }
                      title="Employee"
                    />
                    <br />
                  </>
                )}
                {itemByTypesForExecs[PayrollItemTypeEnum.EMPLOYER_EPF].length >
                  0 &&
                  itemByTypesForExecs[PayrollItemTypeEnum.ETF].length > 0 && (
                    <>
                      <RegulatoryCompliance
                        salaryItems={[
                          ...itemByTypesForExecs[
                            PayrollItemTypeEnum.EMPLOYER_EPF
                          ],
                          ...itemByTypesForExecs[PayrollItemTypeEnum.ETF],
                        ]}
                        title="Employer"
                      />
                      <br />
                    </>
                  )}
              </Div>
            ) : (
              <Div>
                {itemByTypes[PayrollItemTypeEnum.EMPLOYEE_EPF].length > 0 && (
                  <>
                    <B type="b-large-semibold" pb="22px">
                      Regulatory Compliance
                    </B>
                    {!peopleData?.isEpfBorneByEmployer && (
                      <>
                        <RegulatoryCompliance
                          salaryItems={
                            itemByTypes[PayrollItemTypeEnum.EMPLOYEE_EPF]
                          }
                          title="Employee"
                        />
                        <br />
                      </>
                    )}
                  </>
                )}
                {itemByTypes[PayrollItemTypeEnum.EMPLOYER_EPF].length > 0 &&
                  itemByTypes[PayrollItemTypeEnum.ETF].length > 0 &&
                  (!peopleData?.isEpfBorneByEmployer ? (
                    <>
                      <RegulatoryCompliance
                        salaryItems={[
                          ...itemByTypes[PayrollItemTypeEnum.EMPLOYER_EPF],
                          ...itemByTypes[PayrollItemTypeEnum.ETF],
                        ]}
                        title="Employer"
                      />
                      <br />
                    </>
                  ) : (
                    <>
                      <RegulatoryCompliance
                        salaryItems={[
                          ...itemByTypes[PayrollItemTypeEnum.EMPLOYEE_EPF],
                          ...itemByTypes[PayrollItemTypeEnum.EMPLOYER_EPF],
                          ...itemByTypes[PayrollItemTypeEnum.ETF],
                        ]}
                        title="Employer"
                      />
                      <br />
                    </>
                  ))}
              </Div>
            )}

            {(itemByTypes[PayrollItemTypeEnum.APIT_TABLE_1].length > 0 ||
              itemByTypes[PayrollItemTypeEnum.APIT_TABLE_2].length > 0 ||
              itemByTypes[PayrollItemTypeEnum.APIT_TABLE_2_MID].length > 0 ||
              itemByTypes[PayrollItemTypeEnum.APIT_TABLE_5].length > 0 ||
              itemByTypes[PayrollItemTypeEnum.APIT_TABLE_6].length > 0 ||
              itemByTypes[PayrollItemTypeEnum.APIT_TABLE_7].length > 0) && (
              <RegulatoryCompliance
                salaryItems={[
                  ...itemByTypes[PayrollItemTypeEnum.APIT_TABLE_1],
                  ...itemByTypes[PayrollItemTypeEnum.APIT_TABLE_2],
                  ...itemByTypes[PayrollItemTypeEnum.APIT_TABLE_2_MID],
                  ...itemByTypes[PayrollItemTypeEnum.APIT_TABLE_5],
                  ...itemByTypes[PayrollItemTypeEnum.APIT_TABLE_6],
                  ...itemByTypes[PayrollItemTypeEnum.APIT_TABLE_7],
                ]}
                title="APIT"
              />
            )}
            {itemByTypes[PayrollItemTypeEnum.WITH_HOLD_TAX].length > 0 && (
              <>
                <B type="b-large-semibold" pb="22px">
                  Regulatory Compliance
                </B>
                <RegulatoryCompliance
                  salaryItems={[
                    ...itemByTypes[PayrollItemTypeEnum.WITH_HOLD_TAX],
                  ]}
                  title="WHT"
                />
              </>
            )}

            <Div display="flex" my="16px">
              <B type="b-default-semibold">Cost to company</B>
              <Div ml="auto">
                <B type="b-default-semibold">
                  {userPayrollDetails
                    ? userPayrollDetails.costToCompany === null
                      ? '0.00'
                      : numberThousandSeparator(
                          userPayrollDetails?.costToCompany,
                        )
                    : '0.00'}
                </B>
              </Div>
            </Div>
          </>
        )}
      </Div>
    </>
  );
};

export default PersonalSalarySlipTable;
