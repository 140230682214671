import {
  PlaceholderStatus,
  PlaceholderTypeEnum,
} from '../../../types/contract.types';

import { v4 as uuidv4 } from 'uuid';

// default minimum sizes for draggable holders
export const defaultMinSizes = {
  [PlaceholderTypeEnum.date]: {
    width: 80,
    height: 25,
  },
  [PlaceholderTypeEnum.signature]: {
    width: 57,
    height: 38,
  },
  [PlaceholderTypeEnum.textInput]: {
    width: 60,
    height: 20,
  },
};

// default sizes for draggable holders
export const defaultSizes = {
  [PlaceholderTypeEnum.date]: {
    width: 80,
    height: 30,
  },
  [PlaceholderTypeEnum.signature]: {
    width: 80,
    height: 40,
  },
  [PlaceholderTypeEnum.textInput]: {
    width: 80,
    height: 24,
  },
};

interface Prop {
  type: PlaceholderTypeEnum;
  pageIndex: number;
  x: number;
  y: number;
}
export const generateNewPlaceHolder = ({ type, pageIndex, x, y }: Prop) => {
  const placeHolder = {
    id: uuidv4(),
    isNew: true,
    assignedUserId: null,
    pageNumber: pageIndex,
    type: type,

    // Positions
    width: defaultSizes[type].width, // TODO: Get correct initial width for each placeholder item
    hight: defaultSizes[type].height, // TODO: Get correct initial width for each placeholder item
    xAxis: x,
    yAxis: y,

    // meta
    isSaved: false,
    required: true,
    status: PlaceholderStatus.NOT_COMPLETED,
  };
  return placeHolder;
};
