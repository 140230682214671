import { message } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';

import {
  FilePayslipI,
  PayrollViewDataI,
  PayrunI,
  PayslipDeliveryStatusI,
  PublishSummaryPayrollI,
  SalaryInformationI,
} from '../store/actions';
import {
  AddDeletePayslipFieldI,
  AddNewPayRunI,
  AddPayItemI,
  DeletePayrollItemI,
  EditPayslipFieldI,
  ExistingPayrunsI,
  NextPayrollI,
  PayDetailI,
  PayrollPeriodI,
  SelectUserPayrollI,
  UpdateProrataCalculationI,
} from '../types/payroll.types';
import { getApiError } from '../util/getApiError';

export const useGetNextPayroll = () => {
  return useQuery<NextPayrollI[], AxiosError>('nextPayroll', async () => {
    const response: AxiosResponse<NextPayrollI[]> = await axios.get(
      `/payroll/get-next-payroll`,
    );
    return response.data;
  });
};

export const useCreatePayslipField = () => {
  return useMutation<string, AxiosError, AddDeletePayslipFieldI>(
    async (data) => {
      const response: AxiosResponse<string> = await axios.post(
        `/payroll/custom-payslip-field`,
        data,
      );
      return response.data;
    },
  );
};

export const useEditPayslipField = () => {
  return useMutation<string, AxiosError, EditPayslipFieldI>(async (data) => {
    const response: AxiosResponse<string> = await axios.put(
      `/payroll/custom-payslip-field`,
      data,
    );
    return response.data;
  });
};

export const useEditCompletedPayrun = () => {
  return useMutation<string, AxiosError, number>(
    async (id) => {
      const response: AxiosResponse<string> = await axios.put(
        `payroll/editCompletePayroll`,
        { id },
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully changed completed payrun to draft');
      },
    },
  );
};

export const useDeletePayslipField = () => {
  return useMutation<string, AxiosError, AddDeletePayslipFieldI>(
    async (data) => {
      const response: AxiosResponse<string> = await axios.delete(
        `/payroll/custom-payslip-field`,
        { data },
      );
      return response.data;
    },
  );
};

export const useGetAllPublishPayrollSummary = () => {
  return useMutation<PublishSummaryPayrollI, AxiosError, number>(
    async (payrollId) => {
      const response: AxiosResponse<PublishSummaryPayrollI> = await axios.get(
        `payroll/getPayrollSummary/${payrollId}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useUpdateWorkedDays = () => {
  return useMutation<
    { workedDays: number; userId: number },
    AxiosError,
    UpdateProrataCalculationI
  >(
    async (data) => {
      const response = await axios.put(
        '/payroll/updateProrataCalculation',
        data,
      );

      return response.data;
    },

    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Worked days updated successfully!');
      },
    },
  );
};

export const useGetTransferPayroll = () => {
  return useMutation<FilePayslipI, AxiosError, string>(
    async (payrollId) => {
      const response: AxiosResponse<FilePayslipI> = await axios.get(
        `/payroll/getTransferFIle/${payrollId}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const usePutSelectUserPayroll = () => {
  return useMutation<string, AxiosError, SelectUserPayrollI>(
    async (data: SelectUserPayrollI) => {
      const response: AxiosResponse<string> = await axios.put(
        `/payroll/selectUserPayroll/${data.payrollId}/${data.userIds}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};
export const useGetPayslipMailStatus = () => {
  return useMutation<PayslipDeliveryStatusI, AxiosError, string>(
    async (payrollId) => {
      const response: AxiosResponse<PayslipDeliveryStatusI> = await axios.get(
        `/payroll/payslip-status/${payrollId}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const usePayslipSend = () => {
  return useMutation<string, AxiosError, number>(
    async (empPayrollId: number) => {
      const response: AxiosResponse<string> = await axios.post(
        `/payroll/send-payslip-manually`,
        { empPayrollId },
      );
      return response.data;
    },
    {
      onSuccess: () => {
        message.success('Payslip sent successfully');
      },
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const usePutPayrollItem = () => {
  return useMutation<string, AxiosError, PayDetailI>(
    async (data: PayDetailI) => {
      const response: AxiosResponse<string> = await axios.put(
        `/payroll/payrollItem`,
        {
          ...data,
          id: String(data.id),
          isEpf: data.isEpf || false,
          isTaxable: data.isTaxable || false,
        },
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Payroll item updated successfully!');
      },
    },
  );
};

export const useCreatePayrollItem = () => {
  return useMutation<string, AxiosError, AddPayItemI>(
    async (data: AddPayItemI) => {
      const response: AxiosResponse<string> = await axios.post(
        `/payroll/payrollItem`,
        {
          ...data,
          isEpf: data.isEpf || false,
          isTaxable: data.isTaxable || false,
        },
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('New payroll item added successfully!');
      },
    },
  );
};

export const useDeletePayrollItem = () => {
  return useMutation<string, AxiosError, DeletePayrollItemI>(
    async (data) => {
      const response: AxiosResponse<string> = await axios.delete(
        `/payroll/deleteUserPayrollItem/${data.payrollId}/${data.itemIds}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully deleted the payroll Item..');
      },
    },
  );
};

export const useGetPersonalSalaryInformation = () => {
  return useMutation<any, AxiosError, string>(
    async (empPayrollId) => {
      const response: AxiosResponse<any> = await axios.get(
        `/payroll/emp-payroll/${empPayrollId}`,
      );

      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useGetExistingPayruns = () => {
  return useMutation<ExistingPayrunsI[], AxiosError, PayrollPeriodI>(
    async (data: PayrollPeriodI) => {
      const response: AxiosResponse<ExistingPayrunsI[]> = await axios.get(
        '/payroll/existingPayruns',
        {
          params: {
            month: data.month,
            year: data.year,
          },
        },
      );

      return response.data;
    },
  );
};

export const useAddNewPayRun = () => {
  return useMutation<AddNewPayRunI, AxiosError, PayrunI>(
    async (data: PayrunI) => {
      const response: AxiosResponse<AddNewPayRunI> = await axios.post(
        '/payroll/createPayroll',
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully created');
      },
    },
  );
};

export const useSendWorkedDays = () => {
  return useMutation<string, AxiosError, UpdateProrataCalculationI>(
    async (data: UpdateProrataCalculationI) => {
      const response = await axios.post(
        '/payroll/updateProrataCalculation',
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully updated prorata worked days.');
      },
    },
  );
};

export const useGetAllPayrollDetails = () => {
  return useMutation<SalaryInformationI, AxiosError, string>(
    async (payrollId: string) => {
      const response = await axios.get(`/payroll/getPayroll/${payrollId}`);
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useGetValidatedPayrollViewDetails = () => {
  return useMutation<PayrollViewDataI, AxiosError, string>(
    async (payrollId: string) => {
      const response = await axios.get(`/payroll/validatePayroll/${payrollId}`);
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useUpdatePayrun = () => {
  return useMutation<
    AddNewPayRunI,
    AxiosError,
    { data: PayrunI; payrollId: string }
  >(
    async (data) => {
      const response: AxiosResponse<AddNewPayRunI> = await axios.put(
        '/payroll',
        {
          ...data.data,
          id: data.payrollId,
        },
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Payrun updated successfully!');
      },
    },
  );
};
