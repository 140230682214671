import React, { FC, useEffect, useState } from 'react';

import { Form, message, Tooltip } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { ColumnProps } from 'antd/lib/table';
import { sum } from 'lodash';
import { useSelector } from 'react-redux';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { useGenerateBulkExpense } from '../../../api/expenseHooks';
import {
  Image,
  B,
  DatePickerV2,
  EmptyTextContainer,
  InputV2,
  LabeledInputV2,
  TableComp,
} from '../../../components';
import { ModalComponent } from '../../../components/ModalComponent';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import {
  BANK_EXPORT_TYPES,
  BankDetailsI,
  BOC_BANK_CONFIG,
  COM_BANK_CONFIG,
  HNB_BANK_CONFIG,
  HSBC_BANK_CONFIG,
  NTB_BANK_CONFIG,
} from '../../../configs';
import { RootState } from '../../../store/reducer';
import theme from '../../../theme';
import {
  BanksEnum,
  ComBankParams,
  ComBankTypeEnum,
  ExpenseGenerateBulkI,
  IExpense,
} from '../../../types';
import { getApiError } from '../../../util/getApiError';
import { numberThousandSeparator } from '../../../util/utils';
import { PriceTag } from './';
import { BankErrorSVG } from '../../../assets';

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const TotalContainer = styled(RowContainer)`
  margin-top: 10px;
  justify-content: space-between;
`;
const TableLayout = styled.div`
  margin-top: 16px;
`;
const SelectLayout = styled.div`
  margin-top: 16px;
  .ant-select {
    width: 350px;
  }
`;
const MarkAsPaidLayout = styled.div`
  margin-left: 150px;
  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
`;
const BankErrorLayout = styled.div`
  margin-left: 150px;
  margin-top: -18px;
  margin-bottom: 25px;
  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
`;

const tableStyle = css`
  .ant-table-thead > tr > th {
    padding: 10px 16px !important;
  }
  .ant-table-tbody > tr > td {
    padding-left: 0px !important;
  }
  .ant-table-body {
    background-color: ${theme.white} !important;
  }
  .ant-table-body {
    padding: 0px 16px 0px 16px !important;
  }
`;
interface SelectedExpenses {
  id: number;
  userId: number;
  amount: number;
  name: string;
  expenses?: number;
}
interface PrepExpense {
  [index: string]: SelectedExpenses;
}

interface GenerateBulkModalProps {
  trigger: React.ReactElement;
  disabled: boolean;
  selected: number[];
  clearSelected: () => void;
  dataSet: IExpense[];
}

export const GenerateBulkModal: FC<GenerateBulkModalProps> = ({
  trigger,
  selected = [],
  clearSelected,
  dataSet = [],
  disabled,
}) => {
  const [bank, setBank] = useState<BankDetailsI | undefined>(undefined);
  const [visible, setVisible] = useState(false);
  const bankDetails = useSelector(
    (state: RootState) => state.organization.organizationData?.bankDetails,
  );
  const [form] = Form.useForm();

  const {
    isLoading: loadingGenerateBulkExpense,
    error: errorGenerateBulkExpense,
    data: dataGenerateBulkExpense,
    mutate: generateBulkExpense,
  } = useGenerateBulkExpense();

  // selected record list
  const selectedExpenses: SelectedExpenses[] = dataSet
    .filter((i) => selected.includes(i.id))
    .map((i) => ({
      id: i.id,
      userId: i.userId,
      amount: i.amount,
      name: i.owner.fullName,
    }));

  useEffect(() => {
    if (bankDetails && visible) {
      const getBank = BANK_EXPORT_TYPES.find(
        (i) => i.code === Number(bankDetails.bankCode),
      );

      if (getBank) {
        let bankType = null;
        if (getBank.value === BanksEnum.SAMPATH) {
          bankType = bankDetails.sampathBankType;
        }
        form.setFieldsValue({
          bankName: getBank.label,
          bankType,
        });
        setBank(getBank);
      }
    }
  }, [bankDetails, visible]);

  const onFinish = (values: any) => {
    if (bank) {
      const type =
        bankDetails?.bankCode === COM_BANK_CONFIG.code
          ? bankDetails?.comBankType === ComBankTypeEnum.DIGITAL
            ? ComBankParams.CMBD
            : ComBankParams.CMBO
          : bank.value;
      const dataSet: ExpenseGenerateBulkI = {
        type,
        expenseIds: selectedExpenses.map((i) => i.id),
        markAsPaid: values.markAsPaid,
        remark: values.remark,
      };
      if (values.dateCredit) {
        dataSet.dateOfCrediting = values.dateCredit;
      }
      if (values.valueDate) {
        dataSet.valueDate = values.valueDate;
      }
      generateBulkExpense(dataSet);
    } else {
      message.error('Please add your bank details in settings');
    }
  };

  // success handling
  useEffect(() => {
    if (dataGenerateBulkExpense && !errorGenerateBulkExpense) {
      const link = document.createElement('a');
      link.href = dataGenerateBulkExpense;
      link.click();
      message.success('Generated Successfully!');
      // reload data
      clearSelected();
      handleCancel();
    }
  }, [dataGenerateBulkExpense]);

  // error handling
  useEffect(() => {
    errorGenerateBulkExpense &&
      message.error(getApiError(errorGenerateBulkExpense));
  }, [errorGenerateBulkExpense]);

  const showModal = () => {
    if (disabled) return;
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const sumUserAmount = () => {
    const prepUser: PrepExpense = {};
    selectedExpenses.forEach((d) => {
      if (prepUser.hasOwnProperty(d.name)) {
        prepUser[d.name] = {
          ...prepUser[d.name],
          amount: prepUser[d.name].amount + d.amount,
          expenses: Number(prepUser[d.name].expenses) + 1,
        };
      } else {
        prepUser[d.name] = {
          id: d.id,
          userId: d.userId,
          name: d.name,
          amount: d.amount,
          expenses: 1,
        };
      }
    });
    return Object.values(prepUser);
  };

  const locale = {
    emptyText: (
      <EmptyTextContainer description="You don't have any approved expense selected" />
    ),
  };

  const columns: ColumnProps<any>[] = [
    {
      title: 'User',
      dataIndex: 'name',
      align: 'left',
      render: (name: string) => (
        <B
          type="b-small"
          cssStyle="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
          <Tooltip
            placement="top"
            title={name}
            color={theme.blue900}
            key={theme.blue900}>
            {name}
          </Tooltip>
        </B>
      ),
    },
    {
      title: '',
      align: 'right',
      dataIndex: 'expenses',
      render: (expenses: number) =>
        Number(expenses) > 1 && (
          <B type="b-small" color={theme.gray600}>
            {Number(expenses)} Expenses
          </B>
        ),
    },
    {
      title: 'Price (LKR)',
      align: 'right',
      dataIndex: 'amount',
      render: (amount: number) => (
        <B type="b-small">{numberThousandSeparator(amount)}</B>
      ),
    },
  ];

  return (
    <Form form={form} name="GenerateExpense" onFinish={onFinish}>
      {trigger &&
        (disabled ? (
          <Tooltip
            placement="left"
            title="You have to select approved expenses to proceed"
            color={theme.blue900}
            key={theme.blue900}>
            {React.cloneElement(trigger, {
              onClick: showModal,
              disabled,
            })}
          </Tooltip>
        ) : (
          React.cloneElement(trigger, {
            onClick: showModal,
          })
        ))}
      <ModalComponent
        centered
        form={form}
        submitText="Generate"
        loading={loadingGenerateBulkExpense}
        disabled={!bank}
        visible={visible}
        width={540}
        title={<B type="b-large-semibold">Generate Bulk File</B>}
        onCancel={handleCancel}>
        <ColumnContainer>
          <B type="b-default">
            Are you sure you want to generate bulk file for these
            reimbursements?
          </B>
          <TableLayout>
            <TableComp
              rowKey={(item) => {
                return String(item.id);
              }}
              withBorders={true}
              dataSource={sumUserAmount() || []}
              columns={columns}
              locale={locale}
              pagination={false}
              loading={false}
              scroll={{
                y: 200,
              }}
              css={tableStyle}
            />
          </TableLayout>
          <TotalContainer>
            <B type="b-default" color={theme.gray600}>
              Total
            </B>
            <PriceTag
              amount={sum(selectedExpenses.map((i) => i.amount))}
              labelType="b-default"
              prizeType="b-default-semibold"
            />
          </TotalContainer>
          <SelectLayout>
            <LabeledInputV2 label="Bank Name" flexDirection="row">
              <FormItem name="bankName">
                <InputV2
                  flexDirection="row"
                  disabled
                  size="small"
                  placeholder="No bank selected"
                />
              </FormItem>
            </LabeledInputV2>

            {bankDetails?.sampathBankType && (
              <LabeledInputV2 label="Bank Type" flexDirection="row">
                <FormItem name="bankType">
                  <InputV2
                    flexDirection="row"
                    disabled
                    size="small"
                    placeholder="No bank type selected"
                  />
                </FormItem>
              </LabeledInputV2>
            )}
            {!bank && (
              <BankErrorLayout>
                <B type="b-small" color={theme.red300}>
                  <Image
                    cursor="pointer"
                    width="11px"
                    marginRight="4px"
                    src={BankErrorSVG}
                  />
                  Please add your bank details in settings
                </B>
              </BankErrorLayout>
            )}
            {(bank?.code === HNB_BANK_CONFIG.code ||
              bank?.code === COM_BANK_CONFIG.code ||
              bank?.code === HSBC_BANK_CONFIG.code) && (
              <LabeledInputV2 label="Date of Crediting*" flexDirection="row">
                <FormItem
                  name="dateCredit"
                  rules={[
                    {
                      required: true,
                      message: 'Please select date of crediting!',
                    },
                  ]}>
                  <DatePickerV2
                    flexDirection="row"
                    placeholder="Select Date"
                    inputReadOnly={true}
                    disabled={loadingGenerateBulkExpense}
                  />
                </FormItem>
              </LabeledInputV2>
            )}
            {(bank?.code === HNB_BANK_CONFIG.code ||
            bank?.code === NTB_BANK_CONFIG.code ||
              bank?.code === BOC_BANK_CONFIG.code) && (
              <LabeledInputV2 label="Value Date*" flexDirection="row">
                <FormItem
                  name="valueDate"
                  rules={[
                    { required: true, message: 'Please select value date!' },
                  ]}>
                  <DatePickerV2
                    flexDirection="row"
                    placeholder="Select Date"
                    inputReadOnly={true}
                    disabled={loadingGenerateBulkExpense}
                  />
                </FormItem>
              </LabeledInputV2>
            )}
            <LabeledInputV2 label="Remark*" flexDirection="row">
              <FormItem
                name="remark"
                rules={[{ required: true, message: 'Please enter remark!' }]}>
                <InputV2
                  flexDirection="row"
                  size="small"
                  placeholder="Enter Remark"
                  disabled={loadingGenerateBulkExpense}
                />
              </FormItem>
            </LabeledInputV2>
          </SelectLayout>
          <MarkAsPaidLayout>
            <Form.Item
              initialValue={false}
              name="markAsPaid"
              valuePropName="checked">
              <CheckBoxComp disabled={!bank || loadingGenerateBulkExpense}>
                Mark them as paid
              </CheckBoxComp>
            </Form.Item>
          </MarkAsPaidLayout>
        </ColumnContainer>
      </ModalComponent>
    </Form>
  );
};
