import React, { useEffect, useState } from 'react';
import OnboardingFormNavBar from './OnboardingFormNavBar';
import styled from '@emotion/styled';
import { AddEmployeeDetailsI } from '../../types/people.types';
import { Form } from 'antd';
import FileUploadsPage from './FileUploadsPage';
import EmployeeDetailsFormPage from './EmployeeDetailsFormPage';
import EmailOnboardingSuccess from './EmailOnboardingSuccess';
import { useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import NoAccessPage from './NoAccessPage';
import { Div } from '../../components';
import { useGetUserInfo } from '../../api/peopleHooks';

const Container = styled.div`
  margin-bottom: 48px;
`;
type FormParams = {
  token: string;
};
interface TokenI {
  id: number;
  email: string;
  organizationId: number;
  expirationDate: string;
}

const EmailOnboarding = () => {
  const [form] = Form.useForm();
  const params = useParams<FormParams>();
  const [isLoading, setIsLoading] = useState(false);

  let token = params.token;
  let tokenDecoded: TokenI;

  const { mutate: getUserInfo, data: getUserInfoData } = useGetUserInfo();

  useEffect(() => {
    getUserInfo(token, {
      onSuccess: () => {
        setIsLoading(true);
      },
    });
  }, []);

  try {
    tokenDecoded = jwt_decode(params?.token);
  } catch (e) {
    token = undefined;
  }

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [employeeDetails, setEmployeeDetails] =
    useState<AddEmployeeDetailsI>(null);

  const renderSwitch = () => {
    switch (currentPage) {
      case 1:
        return (
          <EmployeeDetailsFormPage
            form={form}
            onChange={(v) => {
              setEmployeeDetails({
                ...employeeDetails,
                ...(v as AddEmployeeDetailsI),
              });
              setCurrentPage(2);
            }}
          />
        );
      case 2:
        return (
          <FileUploadsPage
            form={form}
            employeeDetails={employeeDetails}
            goBack={() => setCurrentPage(1)}
            onChange={() => setCurrentPage(3)}
          />
        );
    }
  };

  if (
    isLoading &&
    (!token ||
      (tokenDecoded && dayjs().isAfter(tokenDecoded.expirationDate)) ||
      !getUserInfoData)
  ) {
    return <NoAccessPage />;
  }

  return (
    isLoading && (
      <Div display="flex" flexDirection="column" style={{ width: '100%' }}>
        <OnboardingFormNavBar />
        <Container>
          {currentPage !== 3 ? (
            <div>{renderSwitch()}</div>
          ) : (
            <EmailOnboardingSuccess />
          )}
        </Container>
      </Div>
    )
  );
};
export default EmailOnboarding;
