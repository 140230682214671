import React, { FC, useEffect, useState } from 'react';

import { Form, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import styled from '@emotion/styled';

import {
  PrimaryButton,
  RouteLeavingGuard,
  SecondaryButton,
} from '../../../../components';
import { FooterComponent } from '../../../../components/FooterComponent';
import { useIndividualPeopleState } from '../../../../providers/IndividualPeopleState';
import { createEmergencyContact } from '../../../../store/actions';
import theme from '../../../../theme';
import { Permission } from '../../../../types/permissions.enum';
import { isAllowed } from '../../../../util/permissionUtil';
import { PeopleLayout } from '../../shared';
import { EmergencyForm } from '../personal-details/EmergencyForm';
import { EmergencyContactI } from '../../../../types/people.types';
import { DispatchType } from '../../../../store';

type ParamsI = {
  employeeKey: string;
};
const CustomForm = styled(Form)`
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue600};
  }
  margin-bottom: 100px;
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-bottom: 10px;
  }
  @media (max-width: 768px) {
    margin-bottom: 80px;
  }
`;
const EmergencyDetails: FC = () => {
  const [isFieldDataChanged, setIsFieldDataChanged] = useState(false);
  const dispatch: DispatchType = useDispatch();
  const params = useParams<ParamsI>();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {
    state: { people, isLoading, error },
    fetchPeopleById,
  } = useIndividualPeopleState();

  function onValuesChange() {
    setIsFieldDataChanged(true);
  }

  async function onFinish(values: EmergencyContactI) {
    const data = {
      ...values,
      emergencyContactAfterHoursNumber: values.emergencyContactAfterHoursNumber,
      emergencyContactMobile: values.emergencyContactMobile,
      emergencyContactDaytimeNumber: values.emergencyContactDaytimeNumber,
    };
    setIsFieldDataChanged(false);
    await dispatch(createEmergencyContact(data, params.employeeKey));
    fetchPeopleById(params.employeeKey);
  }

  useEffect(() => {
    if (people) {
      for (const [key, value] of Object.entries(people)) {
        form.setFieldsValue({ [key]: value });
      }
    }
  }, [people]);

  const defaultDisable = !isAllowed(Permission.CREATE_EDIT_USERS);

  function shouldBlockNavigation() {
    return isFieldDataChanged;
  }

  return (
    <>
      <CustomForm
        form={form}
        onValuesChange={onValuesChange}
        initialValues={{ ...people }}
        wrapperCol={{ span: 50 }}
        validateTrigger="onBlur"
        onFinish={(v) => onFinish(v as EmergencyContactI)}
        layout="vertical">
        <PeopleLayout>
          <Spin spinning={isLoading}>
            <RouteLeavingGuard
              when={isFieldDataChanged}
              navigate={(path) => navigate(path)}
              shouldBlockNavigation={shouldBlockNavigation}
            />
            <EmergencyForm defaultDisable={defaultDisable} />
          </Spin>
        </PeopleLayout>

        {isAllowed(Permission.CREATE_EDIT_USERS) && !error && (
          <FooterComponent
            leftChildren={[]}
            rightChildren={[
              <SecondaryButton
                onClick={() => {
                  form.resetFields();
                  setIsFieldDataChanged(false);
                }}>
                Cancel
              </SecondaryButton>,
              <PrimaryButton ml={16} loading={isLoading} htmlType="submit">
                Save
              </PrimaryButton>,
            ]}
          />
        )}
      </CustomForm>
    </>
  );
};

export default EmergencyDetails;
