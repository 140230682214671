import moment from 'moment';
import React, { FC } from 'react';
import { Div } from '../../../components/Div';
import { PayrollViewDataI } from '../../../store/actions';
import theme from '../../../theme';

export enum PayrollViewTypeEnum {
  logTrailView = 'logTrailView',
  PayrollView = 'PayrollView',
}
type Props = {
  payrollViewData?: PayrollViewDataI | null;
};

const PayrollInfoSummary: FC<Props> = ({ payrollViewData }) => {
  return (
    <Div background={theme.gray50} mb="16px" p="24px" mt="16px">
      <Div pb="4px">
        <b>Payroll Name:</b> {payrollViewData?.name || '-'}
      </Div>
      <Div pb="4px">
        <b>Remark:</b> {payrollViewData?.defaultRemark || '-'}
      </Div>
      <Div>
        <b>Period:</b>
        {moment(payrollViewData?.payrollStartDate).format('MMM D') +
          ' to ' +
          moment(payrollViewData?.payrollEndDate).format('MMM D')}
      </Div>
    </Div>
  );
};

export default PayrollInfoSummary;
