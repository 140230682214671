import React, { FC } from 'react';
import { ConfigProvider } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';
import { numberThousandSeparator } from '../../../util/utils';
import {
  B,
  Div,
  EmptyPage,
  FooterComponent,
  SecondaryButton,
  TableComp,
} from '../../../components';
import { LoanGeneratedBankFilesI } from '../../../types/loan.types';
import styled from '@emotion/styled';
import theme from '../../../theme';
import { useGetGenerateBankList } from '../../../api/loanHooks';
import { isValidMoment } from '../../../util/isValidMoment';
import { getPathWithOrgId } from '../../../util/getPathWithOrgId';
import { useNavigate } from 'react-router';
import { DownloadBlack } from '../../../assets';

const LastColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

const LoanGeneratedBankFilesTable: FC = () => {
  const navigate = useNavigate();
  const {
    isError: errorGeneratedBankFileList,
    isLoading: loadingGeneratedBankFileList,
    data: generatedBankFileList = [],
  } = useGetGenerateBankList();

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <CalendarOutlined style={{ fontSize: 40 }} />
      <p>No Generated Bank Files</p>
    </div>
  );

  const columns: ColumnProps<LoanGeneratedBankFilesI>[] = [
    {
      title: 'Date',
      width: 120,
      dataIndex: 'createdAt',
      render: (value: string) => (
        <B type="b-small">{moment(value).format('DD MMM YYYY')}</B>
      ),
    },
    {
      title: 'Bank Name',
      width: 120,
      dataIndex: 'bankName',
      render: (value: string) => <B type="b-small">{value ? value : '-'}</B>,
    },
    {
      title: 'Date of Crediting',
      width: 130,
      dataIndex: 'dateOfCrediting',
      render: (value: string) => (
        <B type="b-small">
          {moment(value).isValid() ? moment(value).format('DD.MM.YYYY') : '-'}
        </B>
      ),
    },
    {
      title: 'Value Date',
      width: 120,
      dataIndex: 'valueDate',
      render: (value: string) => (
        <B type="b-small">
          {value
            ? isValidMoment(value, undefined, 'DD.MM.YYYY').toString()
            : '-'}
        </B>
      ),
    },

    {
      title: 'Total',
      width: 120,
      dataIndex: 'total',
      render: (value: number) => (
        <B type="b-small">
          {value ? numberThousandSeparator(Number(value)) : '-'}
        </B>
      ),
    },
    {
      title: '',
      width: 50,
      dataIndex: 'fileUrl',
      render: (value: string) => (
        <LastColumn>
          <a href={value} download>
            <img src={DownloadBlack} />
          </a>
        </LastColumn>
      ),
    },
  ];

  return (
    <>
      {errorGeneratedBankFileList ? (
        <div>
          <EmptyPage
            content="Sorry, the page you visited does not exist."
            title="something went wrong !"
            buttonLabel=" Back Home"
          />
        </div>
      ) : (
        <>
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <Div p="24px 40px 32px">
              <B type="b-large-semibold" color={theme.black} Pb="16px">
                Download Bank Transfer Files
              </B>
              <TableComp
                size="middle"
                columns={columns}
                withBorders={true}
                loading={loadingGeneratedBankFileList}
                dataSource={generatedBankFileList}
                pagination={false}
                scroll={{
                  y: 502,
                }}
              />
            </Div>
          </ConfigProvider>
          <FooterComponent
            leftChildren={
              <SecondaryButton
                marginLeft={23}
                onClick={() => navigate(getPathWithOrgId('/loans'))}>
                Back
              </SecondaryButton>
            }
          />
        </>
      )}
    </>
  );
};

export default LoanGeneratedBankFilesTable;
