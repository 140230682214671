import React, { FC, useState } from 'react';

import { ColumnProps } from 'antd/lib/table';
import { useSelector } from 'react-redux';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import { B, TableComp } from '../../../../components';
import { RootState } from '../../../../store/reducer';
import { IntegrationStatusEnum } from '../../../../types';

const Container = styled.div`
  .ant-table-cell {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
`;

const Collapse = styled.div<{ isSub: boolean }>`
  ${(props) => props.isSub && `margin-left: 25px; margin-top: 10px;`}
`;

interface ExpenseDataSet {
  accountType: string;
  label: string;
  subs: ExpenseDataSet[];
  value: string;
}
interface CollapseDataProps {
  data: ExpenseDataSet;
  isSub?: boolean;
}

export const CollapseData: FC<CollapseDataProps> = ({ data, isSub }) => {
  const [visible, setVisible] = useState(false);
  return (
    <Collapse isSub={Boolean(isSub)}>
      {data.subs && data.subs.length ? (
        <div>
          <B
            type={isSub ? 'b-default' : 'b-default-semibold'}
            onClick={() => setVisible(!visible)}
            cssStyle="cursor: pointer;">
            {visible ? <CaretUpOutlined /> : <CaretDownOutlined />} {data.label}
            &nbsp;&nbsp;&nbsp;
            <small>
              • {data.subs.length} {data.subs.length > 1 ? 'items' : 'item'}
            </small>
          </B>
          {visible &&
            data.subs.map((i) => <CollapseData data={i} isSub={true} />)}
        </div>
      ) : (
        <B
          type={isSub ? 'b-default' : 'b-default-semibold'}
          cssStyle="margin-left: 18px;">
          {data.label}
        </B>
      )}
    </Collapse>
  );
};

const IntegrationExpenses: FC = () => {
  const integrationSettings = useSelector(
    (state: RootState) => state.integration.integrationSetting,
  );

  const columns: ColumnProps<any>[] = [
    {
      title: 'Category Name',
      dataIndex: 'label',
      key: 'label',
      render: (label, data) => {
        return <CollapseData data={data} />;
      },
    },
  ];

  const dataSet = integrationSettings?.settings.metaData.expenses || [];

  return (
    <Container>
      <TableComp
        size="middle"
        loading={false}
        columns={columns}
        dataSource={dataSet}
        pagination={false}
        withBorders={true}
        scroll={{
          y:
            window.innerHeight -
            (integrationSettings &&
            integrationSettings?.status ===
              IntegrationStatusEnum.ACTION_REQUIRED
              ? 500
              : 350),
        }}
      />
    </Container>
  );
};

export default IntegrationExpenses;
