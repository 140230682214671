import React, { FC, useState } from 'react';

import { Col, Form, Row } from 'antd';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import {
  B,
  CustomDivider,
  DefaultBody,
  InputComp,
  LabeledInputV2,
  RouteLeavingGuard,
  SwitchComponent,
} from '../../../../components';
import theme from '../../../../theme';
import { AddEmployeeI } from '../../../../types/people.types';
import AddEmployeeFooter from './shared/AddEmployeeFooter';
import { Validation } from '../../../../common/validation';

const FormItem = styled(Form.Item)`
  margin-bottom: 24px;
  width: 100%;
`;
export const FormBody = styled.div`
  padding: 32px 32px 8px;
  border-bottom: 1px solid #e0e0e0 ;
  backgroundColor= ${theme.white};
  @media only screen and (max-width: 1200px) {
    padding: 32px 24px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0px 16px;
  }
`;
const FormComponent = styled(Form)`
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: #00318c;
  }
  margin-bottom: 50px;
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-bottom: 10px;
  }
  @media (max-width: 768px) {
    margin-bottom: 80px;
  }
  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }
`;
interface Props {
  current: number;
  onClickNext: (num: number) => void;
  onChange: (value: AddEmployeeI) => void;
  personalDetail: AddEmployeeI | null;
}

const PersonalDetails: FC<Props> = ({
  current,
  onClickNext,
  onChange,
  personalDetail,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState<number>(current);
  const [isFieldDataChanged, setIsFieldDataChanged] = useState(false);
  const [issLoginAllowed, setIsLoginAllowed] = useState(
    !!personalDetail?.isLoginAllowed,
  );

  const onFinish = async (value: AddEmployeeI) => {
    setIsFieldDataChanged(false);
    await onChange(value);
    onClickNext(currentStep);
  };
  const shouldBlockNavigation = () => {
    return isFieldDataChanged;
  };
  const onValuesChange = () => {
    setIsFieldDataChanged(true);
  };

  return (
    <DefaultBody
      p={0}
      backgroundColor={theme.white}
      border="0.5px solid #E0E0E0"
      borderRadius={4}>
      <FormComponent
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={{ ...personalDetail }}
        name="ManagePersonalDetails"
        onFinish={(v) => {
          onFinish(v as AddEmployeeI);
        }}
        onValuesChange={onValuesChange}>
        <RouteLeavingGuard
          when={isFieldDataChanged}
          navigate={(path) => navigate(path)}
          shouldBlockNavigation={shouldBlockNavigation}
        />
        <FormBody>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem
                label="Name with Initials*"
                name="nameWithInitials"
                rules={[
                  {
                    required: true,
                    message: 'Enter name with Initials',
                  },
                ]}>
                <InputComp
                  size="small"
                  placeholder="Enter name with Initials"
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem
                label="Full Name*"
                name="fullName"
                rules={[{ required: true, message: 'Enter full name' }]}>
                <InputComp size="small" placeholder="Enter full name" />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="First Name" name="firstName">
                <InputComp size="small" placeholder="Enter name first name" />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="Last Name" name="lastName">
                <InputComp size="small" placeholder="Enter last name" />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem
                label="Preferred / Display Name*"
                name="preferredName"
                rules={[
                  {
                    required: true,
                    message: 'Enter display name',
                  },
                ]}>
                <InputComp size="small" placeholder="Enter display name" />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <B type="b-small" color={theme.gray600} pt="28px">
                Name that a person has chosen to identify themself within the
                Humanised system
              </B>
            </Col>
          </Row>

          <CustomDivider
            mt={2}
            mb={32}
            border="0px solid"
            borderBottom="1px solid #E0E0E0"
          />
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <Row gutter={[32, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormItem
                  label="NIC or Passport*"
                  name="nicOrPassport"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter NIC/Passport number',
                    },
                    {
                      pattern: Validation.NicOrPassportPattern,
                      message: 'The input is not valid NIC/Passport id!',
                    },
                  ]}>
                  <InputComp
                    size="small"
                    placeholder="Enter NIC/Passport number"
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <Row gutter={[32, 0]}>
              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                <FormItem
                  name="isLoginAllowed"
                  valuePropName="checked"
                  label="Login Access">
                  <SwitchComponent
                    onChange={(checked: boolean) => setIsLoginAllowed(checked)}
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <B type="b-small" color={theme.gray600} pt="28pxx">
                  By providing login access to your employees, they can access
                  leaves, expenses and their employee profiles
                </B>
              </Col>
            </Row>
            {issLoginAllowed && (
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <LabeledInputV2 label="Login Email" flexDirection="column">
                    <FormItem
                      name="loginEmail"
                      rules={[
                        {
                          type: 'email',
                          message: 'Enter a valid email',
                        },
                      ]}>
                      <InputComp
                        placeholder="Enter Email address"
                        size="small"
                      />
                    </FormItem>
                  </LabeledInputV2>
                </Col>
              </Row>
            )}
          </Col>
        </FormBody>
      </FormComponent>
      <AddEmployeeFooter
        current={current}
        onClickNext={(v) => {
          setCurrentStep(v);
          form.submit();
        }}
      />
    </DefaultBody>
  );
};

export default PersonalDetails;
