import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { B } from '../../../../../components';
import { PayrollItemI } from '../../../../../types/payroll.types';
import { SummaryDataI } from '../../../../../types/people.types';
import BankingDetailsConfirmation from './BankingDetailsConfirmation';
import PayDetails from './PayDetails';
interface Props {
  summaryData?: SummaryDataI;
  payItem?: PayrollItemI[];
}
const SalaryDetailsConfirmation: FC<Props> = ({ summaryData, payItem }) => {
  return (
    <>
      <B type="b-default-semibold" py="16px">
        Salary Details
      </B>
      {summaryData ? (
        <Row gutter={[32, 12]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <PayDetails
              employment={summaryData}
              payItem={payItem ? payItem : summaryData?.savedUser.userPayItems}
            />
          </Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
            <BankingDetailsConfirmation employment={summaryData} />
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default SalaryDetailsConfirmation;
