import React, { FC, useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { toInteger } from 'lodash';
import styled from '@emotion/styled';
import theme from '../../../../theme';
const { Content, Sider } = Layout;
const REPORT_EMPLOYEE_PATH = '/reports/employee/head-count';
const REPORT_DETAILED = '/reports/payroll/detailed';
const FILES_AND_FORMS = '/reports/payroll/files-and-forms';
const REPORT_LEAVE_BALANCE = '/reports/employee/leave-balance';

const Container = styled(Content)`
  height: 100%;
  min-height: calc(100vh - 200px);
  .ant-layout-sider-children {
    background-color: ${theme.white};
  }
`;

const ReportNav = styled(Menu)`
  .ant-menu-item-group-title {
    padding: 24px 16px 8px;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: ${theme.gray600};
  }
  .ant-menu-item-group-list .ant-menu-item {
    padding: 0px 16px !important;
    height: 40px;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    font-size: 14px;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${theme.lightBlue100} !important;
  }
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: ${theme.blue500};
    font-weight: 600;
    font-size: 14px;
  }
  .ant-menu-item:hover {
    color: ${theme.blue500};
  }
  .ant-menu-item::after {
    border-right: unset !important;
  }
`;

export const ReportMenu: FC = () => {
  const location = useLocation();

  const [activeMenu, setActiveMenu] = useState(getCurrentActiveMenu());

  useEffect(() => {
    setActiveMenu(getCurrentActiveMenu());
  }, []);

  function getCurrentActiveMenu() {
    if (location.pathname.includes(REPORT_DETAILED)) {
      return '2';
    } else if (location.pathname.includes(FILES_AND_FORMS)) {
      return '3';
    } else if (location.pathname.includes(REPORT_EMPLOYEE_PATH)) {
      return '4';
    } else if (location.pathname.includes(REPORT_LEAVE_BALANCE)) {
      return '5';
    }
  }

  return (
    <Container>
      <Layout className="site-layout-background">
        <Sider className="site-layout-background" width="100%">
          <ReportNav
            onClick={(event) => setActiveMenu(toInteger(event.key).toString())}
            selectedKeys={[activeMenu ? activeMenu : '1']}
            mode="inline">
            <Menu.ItemGroup key="g1" title="Payroll">
              <Menu.Item key="1">
                <Link to="/reports/payroll/summary">Summary</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to="/reports/payroll/detailed">Detailed Report</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to="/reports/payroll/files-and-forms">
                  Files and Forms
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="g2" title="Employee">
              <Menu.Item key="4">
                <Link to="/reports/employee/head-count">Head Count</Link>
              </Menu.Item>
              <Menu.Item key="5">
                <Link to="/reports/employee/leave-balance">Leave</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </ReportNav>
        </Sider>
      </Layout>
    </Container>
  );
};
