import React, { FC } from 'react';
import { Input, Typography } from 'antd';
import { InputProps } from 'antd/lib/input';
import styled from '@emotion/styled';
import theme from '../theme';
import { B } from './B';

const TextArea = styled(Input.TextArea)`
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  padding-left: 16px;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  &:focus {
    border: 1px solid ${theme.blue500};
    box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1);
  }

  &:hover {
    border: 1px solid ${theme.blue500};
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
    border-radius: 4px;
  }
`;

interface Props extends InputProps {
  label?: string;
  placeholder?: string;
}

export const TextAreaComp: FC<Props> = (props) => {
  const { label, placeholder, size = 'large' } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {label && (
        <B type="b-default-semibold" color={theme.blue700} pb="4px">
          {label}
        </B>
      )}

      <TextArea size={size} placeholder={placeholder} />
    </div>
  );
};
