import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { List, Popover, Spin, Image, Input } from 'antd';
import styled from '@emotion/styled';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty, debounce, escapeRegExp } from 'lodash';
import { B, Div } from '../../components';
import { ReactComponent as Notification } from '../../assets/Icons/global-search-icons/reminder.svg';
import { useNavigate } from 'react-router';
import { useGlobalSearch } from '../../api/globalSearchHooks';
import theme from '../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationData, setScrollTarget } from '../../store/actions';
import { useOrganizationData } from '../../custom-hooks/organizationHooks';
import { PayrunType } from '../../types/payroll.types';
import moment from 'moment';
import { UserRoleIdEnum } from '../../types';
import { DispatchType, RootState } from '../../store/reducer';
import {
  Expense,
  Gratuity,
  Leave,
  Loan,
  Payrun,
  SearchSVG,
  User,
} from '../../assets';
import { GlobalSearchI } from '../../types/globalSearch.types';

const StyledNotificationsSvg = styled(Notification)`
  width: 20px;
  height: 20px;
`;

const StyledExpenseSvg = styled(Expense)`
  width: 23px;
  height: 23px;
`;

const StyledPeoplesSvg = styled(User)`
  width: 20px;
  height: 20px;
`;

const StyledGratuitySvg = styled(Gratuity)`
  width: 20px;
  height: 20px;
`;

const StyledLoanSvg = styled(Loan)`
  width: 20px;
  height: 20px;
`;

const StyledLeavesSvg = styled(Leave)`
  width: 20px;
  height: 20px;
`;

const StyledPayrunSvg = styled(Payrun)`
  width: 20px;
  height: 20px;
`;

const StyledPanel = styled.div`
  max-height: 600px;
  width: 648px;
  overflow: auto;
  border: 1px solid ${theme.white};
  border-radius: 4px;
`;

const EmptyPanel = styled.div`
  max-height: 600px;
  width: 648px;
  overflow: auto;
  border: 1px solid ${theme.white};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ListDiv = styled.div`
  width: 100%;
  overflow: auto;

  .ant-list-item {
    padding: 12px 12px 12px 2px;
    cursor: pointer;
    :hover {
      background: ${theme.gray200};
      .checked {
        display: block;
      }
    }
  }

  .ant-list-item-meta-title {
    margin-bottom: 0px;
  }

  .ant-list-item-meta {
    align-items: center;
  }

  .ant-list-item-meta-avatar {
    margin-right: 10px;
    display: flex;
    justify-content: center;
  }
`;

const StyledPopover = styled(Popover)`
  .ant-popover-arrow {
    display: none;
  }
`;
// TODO: fix responsive issues
const StyledSearchBar = styled(Input)`
  position: relative;
  border-radius: 4px;
  border: 1px solid var(--color-usage-stroke, ${theme.gray300});
  background: var(--gray-white, ${theme.white});
  width: 671px;
  flex-shrink: 0;
  @media (max-width: 1024px) {
    width: 240px;
  }
`;

const Highlighted = ({ text = '', highlight = '' }) => {
  if (!highlight.trim()) {
    return (
      <B type="b-default" color={theme.black}>
        {text}
      </B>
    );
  }
  const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
  const parts = text.split(regex);
  return (
    <B type="b-default" color={theme.black}>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <b key={i}>{part}</b>
          ) : (
            <span key={i}>{part}</span>
          ),
        )}
    </B>
  );
};

export const SearchPanel: FC = () => {
  const [query, setQuery] = useState('');
  const [data, setData] = useState({} as GlobalSearchI);
  const [searchResultVisible, setSearchResultVisible] = useState(false);
  const navigate = useNavigate();

  const organizationalData = useOrganizationData();
  const me = useSelector((state: RootState) => state.people.me);

  const isAdmin =
    me?.roleId === UserRoleIdEnum.ADMIN ||
    me?.roleId === UserRoleIdEnum.OWNER ||
    me?.roleId === UserRoleIdEnum.GUEST_ADMIN;

  const searchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleOpenPopover = (visible: boolean) => {
    setSearchResultVisible(visible);
  };
  const dispatch: DispatchType = useDispatch();

  const {
    isFetching: isFetchingResults,
    data: searchResults,
    refetch: refetchSearchResults,
  } = useGlobalSearch(query, isAdmin);

  useEffect(() => {
    if (!query) setData({} as GlobalSearchI);
    refetchSearchResults();
  }, [query]);

  useEffect(() => {
    if (!isEmpty(searchResults)) setData(searchResults as GlobalSearchI);
  }, [searchResults]);

  function convertToTitleCase(input: string): string {
    return input
      .toLowerCase() // Convert the input to lowercase
      .replace(/_/g, ' '); // Replace underscores with spaces
  }

  return (
    <StyledPopover
      className="search-panel"
      placement="bottom"
      content={
        query ? (
          <StyledPanel>
            <Spin spinning={isFetchingResults}>
              {isAdmin && (
                <Div pb={2}>
                  <B pb="7px" type="b-default" color={theme.gray600}>
                    Notification
                  </B>
                  <ListDiv>
                    {(data.notifications || data.leaves) && (
                      <>
                        {data?.notifications.length > 0 && (
                          <List
                            itemLayout="horizontal"
                            dataSource={data.notifications}
                            renderItem={(item: any) => {
                              return (
                                <div>
                                  <List.Item
                                    onClick={() => {
                                      let url = `/home/preview/${item.id}`;
                                      if (isAdmin)
                                        url = `/home/admin/preview/${item.id}`;

                                      handleOpenPopover(false);
                                      dispatch(
                                        setNotificationData({
                                          id: item.id,
                                          date: item.reminderDate,
                                        }),
                                      );
                                    }}
                                    key={uuidv4()}>
                                    <List.Item.Meta
                                      avatar={<StyledNotificationsSvg />}
                                      title={
                                        <Highlighted
                                          text={`Reminder "${item?.user?.fullName}'s ${item?.note} `}
                                          highlight={query}
                                        />
                                      }
                                    />
                                  </List.Item>
                                </div>
                              );
                            }}
                          />
                        )}
                        {data?.leaves.filter(
                          (leave) => leave.status === 'PENDING',
                        ).length > 0 && (
                          <List
                            itemLayout="horizontal"
                            dataSource={data.leaves.filter(
                              (leave) => leave.status === 'PENDING',
                            )}
                            renderItem={(item: any) => {
                              return (
                                <div>
                                  <List.Item
                                    onClick={() => {
                                      let url = `/home/preview/${item.id}`;
                                      if (isAdmin)
                                        url = `/home/admin/preview/${item.id}`;

                                      handleOpenPopover(false);
                                      dispatch(
                                        setNotificationData({
                                          id: item.id,
                                          date: item.createdAt,
                                        }),
                                      );
                                    }}
                                    key={uuidv4()}>
                                    <List.Item.Meta
                                      avatar={<StyledLeavesSvg />}
                                      title={
                                        <Highlighted
                                          text={`${
                                            item?.user?.fullName
                                          } requested  ${convertToTitleCase(
                                            item.leaveType,
                                          )}  - ${moment(item.date).format(
                                            'YYYY-MM-DD',
                                          )} `}
                                          highlight={query}
                                        />
                                      }
                                    />
                                  </List.Item>
                                </div>
                              );
                            }}
                          />
                        )}
                        {data?.leaves.filter(
                          (leave) => leave.status === 'PENDING',
                        ).length === 0 &&
                          data?.notifications.length === 0 && (
                            <B type="b-italic" color={theme.black100}>
                              {`No result for ${query}. Please refine your search`}
                            </B>
                          )}
                      </>
                    )}
                  </ListDiv>
                </Div>
              )}

              {isAdmin && (
                <Div pb={2}>
                  <B pb="7px" type="b-default" color={theme.gray600}>
                    Peoples
                  </B>
                  <ListDiv>
                    {data.users && data?.users.length > 0 ? (
                      <List
                        itemLayout="horizontal"
                        dataSource={data.users}
                        renderItem={(item: any) => (
                          <List.Item
                            onClick={() => {
                              navigate(
                                `/contact/personal-details/${item['id']}`,
                              );
                              handleOpenPopover(false);
                            }}
                            key={uuidv4()}>
                            <List.Item.Meta
                              avatar={<StyledPeoplesSvg />}
                              title={
                                <Highlighted
                                  text={`${item.fullName}${
                                    item?.userHistory?.[0]?.designation
                                      ? ', ' +
                                        item?.userHistory?.[0]?.designation
                                      : ''
                                  }`}
                                  highlight={query}
                                />
                              }
                            />
                          </List.Item>
                        )}
                      />
                    ) : (
                      <B type="b-italic" color={theme.black100}>
                        {`No result for ${query}. Please refine your search`}
                      </B>
                    )}
                  </ListDiv>
                </Div>
              )}

              {isAdmin && (
                <Div pb={2}>
                  <B pb="7px" type="b-default" color={theme.gray600}>
                    Payroll
                  </B>
                  <ListDiv>
                    {data.payruns && data?.payruns.length > 0 ? (
                      <List
                        itemLayout="horizontal"
                        dataSource={data.payruns}
                        renderItem={(item: any) => (
                          <List.Item
                            onClick={() => {
                              navigate(`/Payroll-view/${item['id']}`);
                              handleOpenPopover(false);
                            }}
                            key={uuidv4()}>
                            <List.Item.Meta
                              avatar={<StyledPayrunSvg />}
                              title={
                                <Highlighted
                                  text={`${item.name} ${
                                    item.payrunType ===
                                    PayrunType.MONTHLY_PAYRUN
                                      ? 'monthly'
                                      : 'mid cycle'
                                  } - ${item.month}/${item.year}`}
                                  highlight={query}
                                />
                              }
                            />
                          </List.Item>
                        )}
                      />
                    ) : (
                      <B type="b-italic" color={theme.black100}>
                        {`No result for ${query}. Please refine your search`}
                      </B>
                    )}
                  </ListDiv>
                </Div>
              )}

              <Div pb={2}>
                <B pb="7px" type="b-default" color={theme.gray600}>
                  Leave
                </B>
                <ListDiv>
                  {data.leaves && data?.leaves.length > 0 ? (
                    <List
                      itemLayout="horizontal"
                      dataSource={data.leaves}
                      renderItem={(item: any) => (
                        <List.Item
                          onClick={() => {
                            dispatch(setScrollTarget(item.id));
                            navigate(`/leave?${organizationalData?.id}`);
                            handleOpenPopover(false);
                          }}
                          key={uuidv4()}>
                          <List.Item.Meta
                            avatar={<StyledLeavesSvg />}
                            title={
                              <Highlighted
                                text={`${
                                  item?.user?.fullName
                                } requested  ${convertToTitleCase(
                                  item.leaveType,
                                )}  - ${moment(item.date).format(
                                  'YYYY-MM-DD',
                                )} `}
                                highlight={query}
                              />
                            }
                          />
                        </List.Item>
                      )}
                    />
                  ) : (
                    <B type="b-italic" color={theme.black100}>
                      {`No result for ${query}. Please refine your search`}
                    </B>
                  )}
                </ListDiv>
              </Div>

              <Div pb={2}>
                <B pb="7px" type="b-default" color={theme.gray600}>
                  Expenses
                </B>
                <ListDiv>
                  {data.expenses && data?.expenses.length > 0 ? (
                    <List
                      itemLayout="horizontal"
                      dataSource={data.expenses}
                      renderItem={(item: any) => (
                        <List.Item
                          onClick={() => {
                            navigate(
                              `/expenses?organizationId=${organizationalData?.id}`,
                            );
                            dispatch(setScrollTarget(item.id));
                            handleOpenPopover(false);
                          }}
                          key={uuidv4()}>
                          <List.Item.Meta
                            avatar={<StyledExpenseSvg />}
                            title={
                              <Highlighted
                                text={`${item?.owner?.fullName} requested a reimbursment of LKR${item.amount} - ${item.note} `}
                                highlight={query}
                              />
                            }
                          />
                        </List.Item>
                      )}
                    />
                  ) : (
                    <B type="b-italic" color={theme.black100}>
                      {`No result for ${query}. Please refine your search`}
                    </B>
                  )}
                </ListDiv>
              </Div>

              {isAdmin && (
                <Div pb={2}>
                  <B pb="7px" type="b-default" color={theme.gray600}>
                    Gratuities
                  </B>
                  <ListDiv>
                    {data.gratuities && data?.gratuities.length > 0 ? (
                      <List
                        itemLayout="horizontal"
                        dataSource={data.gratuities}
                        renderItem={(item: any) => (
                          <List.Item
                            onClick={() => {
                              navigate(
                                `/gratuity/organizationId=${organizationalData?.id}`,
                              );
                              dispatch(setScrollTarget(item.id));
                              handleOpenPopover(false);
                            }}
                            key={uuidv4()}>
                            <List.Item.Meta
                              avatar={<StyledGratuitySvg />}
                              title={
                                <Highlighted
                                  text={`${
                                    item?.user?.fullName
                                  } is due for LKR ${
                                    item?.gratuityAmount
                                  } in gratuity on ${moment(
                                    item?.dueDate,
                                  ).format('YYYY/MM/DD')}`}
                                  highlight={query}
                                />
                              }
                            />
                          </List.Item>
                        )}
                      />
                    ) : (
                      <B type="b-italic" color={theme.black100}>
                        {`No result for ${query}. Please refine your search`}
                      </B>
                    )}
                  </ListDiv>
                </Div>
              )}

              {isAdmin && (
                <Div pb={2}>
                  <B pb="7px" type="b-default" color={theme.gray600}>
                    Loans
                  </B>
                  <ListDiv>
                    {data.loans && data?.loans.length > 0 ? (
                      <List
                        itemLayout="horizontal"
                        dataSource={data.loans}
                        renderItem={(item: any) => (
                          <List.Item
                            onClick={() => {
                              navigate(`/loans/${organizationalData?.id}`);
                              dispatch(setScrollTarget(item.id));
                              handleOpenPopover(false);
                            }}
                            key={uuidv4()}>
                            <List.Item.Meta
                              avatar={<StyledLoanSvg />}
                              title={
                                <Highlighted
                                  text={`${item?.user?.fullName} has an active loan of ${item.loanAmount}`}
                                  highlight={query}
                                />
                              }
                            />
                          </List.Item>
                        )}
                      />
                    ) : (
                      <B type="b-italic" color={theme.black100}>
                        {`No result for ${query}. Please refine your search`}
                      </B>
                    )}
                  </ListDiv>
                </Div>
              )}
            </Spin>
          </StyledPanel>
        ) : (
          // This is to show no data
          <EmptyPanel>
            <List />
          </EmptyPanel>
        )
      }
      trigger="click"
      onVisibleChange={handleOpenPopover}>
      <StyledSearchBar
        onChange={debounce(searchValue, 500)}
        placeholder="I’m looking for..."
        prefix={<Image src={SearchSVG} />}
        className="global-search-input"
      />
    </StyledPopover>
  );
};
