import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMinimumUserInfo, getTeams } from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import {
  DataRangeI,
  NoTeamKeyWord,
  payrollReportFiltersI,
} from '../../../types/report.types';
import PayrollSelector from './PayrollSelector';
import TeamsSelector from '../../../components/selector/TeamsSelector';
import EmployeesSelector from '../../../components/selector/EmployeesSelector';
import RangePickerComponent from '../../../components/selector/RangePickerComponent';
import styled from '@emotion/styled';
import theme from '../../../theme';

const SelectorDiv = styled.div`
  width: 100%;
  background: ${theme.gray50};
  border-bottom: 1px solid ${theme.gray300};
  border-top: 1px solid ${theme.gray300};
  padding: 8px 24px;
`;
interface Props {
  useDateRange: boolean;
  onChange: (value: payrollReportFiltersI) => void;
}

const DEFAULT_MONTH_DEDUCT = 6;

const ReportSelector: FC<Props> = ({ useDateRange, onChange }) => {
  const dispatch: DispatchType = useDispatch();
  const allPeoples = useSelector((state: RootState) => state.people.allPeoples);
  const allTeams = useSelector((state: RootState) => state.dashboard.teams);
  const payrollReport = useSelector(
    (state: RootState) => state.report.payrollReport,
  );

  const [employees, setEmployees] = useState<number[]>([]);
  const [teams, setTeams] = useState<string[]>([]);
  const [dataRange, setDataRange] = useState<DataRangeI | null>(null);
  const [payrollDate, setPayrollDate] = useState<number[]>([]);

  useEffect(() => {
    dispatch(getMinimumUserInfo());
    dispatch(getTeams());
  }, []);

  useEffect(() => {
    onCheckAllEmployees();
  }, [allPeoples]);

  useEffect(() => {
    onCheckAllTeam();
  }, [allTeams]);

  useEffect(() => {
    let values: payrollReportFiltersI | null = null;
    if (useDateRange) {
      values = {
        users: employees,
        teams: teams,
        dataRange: dataRange,
      };
    } else {
      values = {
        users: employees,
        payrollIds: payrollDate,
      };
    }
    onChange(values);
  }, [employees, teams, dataRange, payrollDate]);

  useEffect(() => {
    const neededEmployees: number[] = [];
    payrollDate.forEach((payroll) => {
      payrollReport.forEach((report) => {
        if (payroll === report.id) {
          report.users.forEach((user) => {
            neededEmployees.push(user.id);
          });
        }
      });
    });
    setEmployees(neededEmployees);
  }, [payrollDate]);

  const onCheckAllEmployees = () => {
    const allEmployee: number[] = [];
    allPeoples.map((item) => {
      return allEmployee.push(item.id);
    });
    setEmployees(allEmployee);
  };

  const onCheckAllTeam = () => {
    const allTeam: string[] = [];
    // added no teams to make filter users without teams
    [...allTeams, NoTeamKeyWord].map((item) => {
      return allTeam.push(item);
    });
    setTeams(allTeam);
  };

  return (
    <>
      <SelectorDiv>
        <EmployeesSelector
          isActive={false}
          employees={employees}
          onChange={(value) => setEmployees(value)}
        />
        {!useDateRange ? (
          <PayrollSelector onChange={(values) => setPayrollDate(values)} />
        ) : (
          <>
            <TeamsSelector onChange={(value) => setTeams(value)} />
            <RangePickerComponent
              onChange={(values) => setDataRange(values)}
              defaultStartDeductMonths={DEFAULT_MONTH_DEDUCT}
            />
          </>
        )}
      </SelectorDiv>
    </>
  );
};

export default ReportSelector;
