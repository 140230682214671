import React, { FC } from 'react';
import { Card, Statistic } from 'antd';
import { css } from '@emotion/react';

interface Props {
  title: string;
  value: number;
}
const SalaryCard: FC<Props> = ({ title, value }) => {
  return (
    <>
      <Card
        css={css`
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          border-radius: 0px;
        `}>
        <Statistic
          title={title}
          value={value}
          precision={2}
          css={css`
            .ant-statistic-title {
              color: #0052ea;
            }
            .ant-statistic-content {
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #202125;
            }
            .ant-statistic-content-value-decimal {
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #202125;
            }
          `}
        />
      </Card>
    </>
  );
};

export default SalaryCard;
