import axios, { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { GlobalSearchI } from '../types/globalSearch.types';

export const useGlobalSearch = (query: string, isAdmin?: boolean) => {
  return useQuery<GlobalSearchI, AxiosError>('global-search', async () => {
    let url;

    if (!query) return null;

    if (isAdmin) {
      url = 'notification/admin-global-search';
    } else {
      url = 'notification/global-search';
    }

    const response: AxiosResponse = await axios.get(url, {
      params: { searchQuery: query },
    });

    return response.data;
  });
};
