import { Card, FormInstance, Typography } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { UploadDocumentSVG } from '../../assets';
import { Div, B } from '../../components';
import theme from '../../theme';
import styled from '@emotion/styled';
import pdfPlaceHolderImage from '../../assets/images/pdf-place-holder.png';

const StyledCard = styled(Card)`
  border: 1px dashed ${theme.blue500};
  height: 215px;
`;

const OptionalText = styled(Typography.Text)`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.gray500};
  padding-bottom: 4px;
  margin-left: 4px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

type DropZoneProps = {
  minSize?: number;
  maxSize?: number;
  message: string;
  label?: string;
  putOptionalInLabel?: boolean;
  formItemName?: string;
  form?: FormInstance;
};

interface FileWithPreviewI extends File {
  preview: string;
}

const CustomDropZone: FC<DropZoneProps> = ({
  minSize,
  maxSize = 3072000,
  message,
  label,
  putOptionalInLabel = false,
  formItemName,
  form,
}) => {
  const [selectedVal, setSelectedVal] = useState<FileWithPreviewI>();

  useEffect(() => {
    const formVal = form.getFieldValue(formItemName);
    if (formVal) {
      formVal.preview = URL.createObjectURL(formVal);
      setSelectedVal(formVal);
      form.setFieldValue(`${formItemName}`, formVal);
    }
  }, []);

  const handleDrop = (acceptedFiles) => {
    if (formItemName) {
      const uploadedFile = acceptedFiles[0];
      const valuesToUpdate = { [formItemName]: uploadedFile };
      if (
        uploadedFile &&
        (uploadedFile.type === 'image/jpeg' ||
          uploadedFile.type === 'image/png')
      ) {
        uploadedFile.preview = URL.createObjectURL(uploadedFile);
      }
      setSelectedVal(uploadedFile);
      form.setFieldsValue(valuesToUpdate);
      form.validateFields([`${formItemName}`]);
    }
  };

  return (
    <div>
      <Dropzone
        minSize={minSize}
        maxSize={maxSize}
        onDrop={handleDrop}
        accept={['image/jpeg', 'image/png', 'application/pdf']}>
        {({ getRootProps, getInputProps }) => {
          return (
            <Container>
              <Div display="flex">
                {label && (
                  <B type="b-default-semibold" color={`${theme.blue700}`}>
                    {label}
                  </B>
                )}
                {putOptionalInLabel && <OptionalText>(Optional)</OptionalText>}
              </Div>
              <StyledCard {...getRootProps()}>
                <input {...getInputProps()} type="file" multiple={false} />
                {selectedVal ? (
                  <Div display="flex" justifyContent="center">
                    <img
                      style={{ maxWidth: '370px', maxHeight: '160px' }}
                      src={
                        selectedVal.type !== 'image/png' &&
                        selectedVal.type !== 'image/jpeg'
                          ? pdfPlaceHolderImage
                          : selectedVal?.preview
                      }
                      onLoad={() => {
                        URL.revokeObjectURL(selectedVal.preview);
                      }}
                    />
                  </Div>
                ) : (
                  <Div marginTop={40} marginBottom={40}>
                    <Div textAlign="center">
                      <img src={UploadDocumentSVG} height={36} width={35} />
                    </Div>
                    <Div display="flex" flexDirection="column">
                      <Div
                        textAlign="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center">
                        <B type="b-large" color={theme.gray600} pt="8px">
                          Drag and drop or
                        </B>
                        &nbsp;
                        <B
                          type="b-large"
                          color={theme.blue400}
                          marginBottom="0px"
                          pt="8px">
                          Browse
                        </B>
                      </Div>
                    </Div>
                    <B
                      textAlign="center"
                      type="b-default"
                      color={theme.gray600}
                      pt="4px">
                      {message}
                    </B>
                  </Div>
                )}
              </StyledCard>
            </Container>
          );
        }}
      </Dropzone>
    </div>
  );
};

export default CustomDropZone;
