import React, { FC } from 'react';
import styled from '@emotion/styled';
import theme from '../../../theme';

interface Props {
  firstSignatory: boolean;
  secondSignatory: boolean;
}
// TODO: move this to the theme
const senderColor = theme.contract.sender.label;
const receiverColor = theme.contract.receiver.label;

const Container = styled.div`
  position: absolute;
  left: -10px;
  z-index: 1;
  margin-top: 16px;
`;
const Indicator = styled.div`
  width: 24px;
  height: 10px;
  background: ${(props) => props.color};
  clip-path: polygon(80% 0, 100% 50%, 80% 99%, 0% 100%, 16% 49%, 0 0);
  margin-bottom: 7px;
`;

const PlaceholderIndicator: FC<Props> = ({
  firstSignatory,
  secondSignatory,
}) => {
  return (
    <Container>
      {firstSignatory ? <Indicator color={receiverColor} /> : null}
      {secondSignatory ? <Indicator color={senderColor} /> : null}
    </Container>
  );
};

export default PlaceholderIndicator;
