import {
  ColumnMappingI,
  getColumnConfigByDataIndex,
} from '../open-column-mapping/util/openPayrollValidator';
import { numberThousandSeparator } from '../../../util/utils';
import { EmployeePaySheetSummary, SalaryItemI } from './EmployeeSalary';
import { KeyValueI } from './PaySheet';

export interface BankAccountI {
  name: string;
  accountNo: number;
  code: number;
  branch: number;
}

export class BankBulkDownload {
  private employeePaySheetSummary: EmployeePaySheetSummary[];
  private salaryItems: Array<SalaryItemI[]>;
  constructor(
    employeePaySheetSummary: EmployeePaySheetSummary[],
    salaryItems: Array<SalaryItemI[]>,
  ) {
    this.salaryItems = salaryItems;
    this.employeePaySheetSummary = employeePaySheetSummary;
  }

  public getColumns() {
    const columns: ColumnMappingI[] = [];
    if (this.salaryItems.length) {
      this.salaryItems[0].map((item) => {
        const columnConfig = getColumnConfigByDataIndex(item.dataIndex);
        if (columnConfig?.type === 'info' && columnConfig.bankColumn) {
          columns.push(columnConfig);
        }
      });
    }
    const result = columns.map((item) => {
      return {
        key: item.dataIndex,
        title: item.name,
        fixed: '',
        dataIndex: item.dataIndex,
        render: (value: string) => value,
        align: 'left',
        width: item.dataIndex === 'name' ? 200 : 'auto',
      };
    });
    result.push({
      key: 'amount',
      title: 'Amount',
      fixed: 'right',
      dataIndex: 'amount',
      align: 'right',
      width: 100,
      render: (value: string) => numberThousandSeparator(Number(value)),
    });
    return result;
  }

  public getDataSource() {
    const dataSource: KeyValueI[] = [];
    this.salaryItems.forEach((rows, i) => {
      const source: KeyValueI = {};
      rows.forEach((rowItem) => {
        source[rowItem.dataIndex] = `${rowItem.value}`;
      });
      source.amount = this.employeePaySheetSummary[i].netSalary.toString();
      dataSource.push(source);
    });
    return dataSource;
  }
}
