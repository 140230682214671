import React, { FC } from 'react';
import { Col, Form, Row, Tooltip } from 'antd';
import styled from '@emotion/styled';
import { B, Div, LabeledInputV2, SelectComp } from '../../../components';
import theme from '../../../theme';
import { Divider } from '../../../components/Divider';
import { InfoSVG } from '../../../assets';

const MainContainer = styled.div`
  width: 1200px;
  padding: 32px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    padding: 24px 10px;
  }
`;

const FormItems = styled(Form.Item)`
  margin-bottom: 0px;
`;

const InfoIcon = styled.img`
  margin: 3px 0 0 7px;
  width: 13px;
`;

const PayCycle: FC = () => {
  const payrunDates: { label: string; value: number }[] = [];
  let counter = 0;
  while (counter < 28) {
    counter = counter + 1;
    payrunDates.push({
      label: counter.toLocaleString('en-US', { minimumIntegerDigits: 2 }),
      value: counter,
    });
  }

  return (
    <>
      <MainContainer>
        <B type="b-large-semibold" Pb="24px">
          Pay Cycle
        </B>
        <Row gutter={[32, 16]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Div display="flex">
              <LabeledInputV2 label="Start Date*" flexDirection="column" />
              <Tooltip
                title="End date is automatically calculated using the start date entered here"
                placement="right">
                <InfoIcon src={InfoSVG} />
              </Tooltip>
            </Div>
            <FormItems
              name="payrunStartDate"
              rules={[
                {
                  required: true,
                  message: 'Please select payrun start date',
                },
              ]}>
              <SelectComp
                size="middle"
                placeholder="Choose Start Date"
                options={payrunDates}
                showSearch
              />
            </FormItems>
          </Col>
        </Row>
      </MainContainer>
      <Divider />
    </>
  );
};

export default PayCycle;
