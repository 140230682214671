import React, { FC } from 'react';
import { Modal } from 'antd';
import { css } from '@emotion/react';
import { B } from '../../../components';
import styled from '@emotion/styled';

const Span = styled.span`
  color: #0052ea;
  cursor: pointer;
`;

const WhyCSVNotUploadingModal: FC = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  return (
    <>
      <Span>Learn More</Span>
      <Modal
        css={css`
          .ant-modal-content {
            border-radius: 8px;
          }
          .ant-modal-header {
            border-radius: 8px;
          }
          .ant-modal-footer {
            background: #fafafa;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
          }
          .ant-modal-close-icon {
            color: #212121;
          }
        `}
        title="Why CSV file is not updating?"
        open={isVisible}
        footer={
          <B
            onClick={() => setIsVisible(false)}
            type="b-default"
            color="#0052EA">
            close
          </B>
        }
        onCancel={() => setIsVisible(false)}
        cancelText="Close"
        width={783}>
        <B type="b-default" color="#5F6267" pb="14px">
          • The very first row of your CSV file will need to be a header.{' '}
        </B>
        <B type="b-default" color="#5F6267" pb="14px">
          • The header is used to tell us what is in each column. See screenshot
          below for an example.{' '}
        </B>
        <B type="b-default" color="#5F6267" pb="14px">
          • Make sure your file is actually a CSV file and not just a
          spreadsheet renamed with a ".csv" extension{' '}
        </B>
        <B type="b-default" color="#5F6267" pb="14px">
          {' '}
          • Files must be smaller than 1 MB in size{' '}
        </B>
        <B type="b-default" color="#5F6267" pb="14px">
          • Your CSV file will need to have a header row that tells Mailshake
          what is in each column.{' '}
        </B>
        <B type="b-default" color="#5F6267" pb="14px">
          • The header row cannot have any punctuation in it. Avoid all
          punctuation in your header row. This includes (. : / ? _ - ){' '}
        </B>
        <B type="b-default" color="#5F6267" pb="14px">
          • No cells in your spreadsheet can contain paragraphs (newlines can't
          be read by our system){' '}
        </B>
        <B type="b-default" color="#5F6267" pb="14px">
          • If your EMAIL column is missing any emails you'll want to delete
          those rows.{' '}
        </B>
        <B type="b-default" color="#5F6267" pb="14px">
          {' '}
          • Delete any rows or columns that are completely empty{' '}
        </B>
        <B type="b-default" color="#5F6267" pb="14px">
          {' '}
          • Make sure each column has a heading
        </B>
      </Modal>
    </>
  );
};

export default WhyCSVNotUploadingModal;
