/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useState } from 'react';
import { Col, Form, Row, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { space, SpaceProps } from 'styled-system';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Validation } from '../../../../common/validation';
import {
  B,
  CustomDivider,
  DatePickerComp,
  Div,
  Image,
  InputComp,
  InputNumberComp,
  LinkButton,
  SelectComp,
} from '../../../../components';
import {
  OCCUPATION_GRADE_URL,
  epfBorneByEmployerOrg,
} from '../../../../configs';
import { getMyOrganization } from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import theme from '../../../../theme';
import { Permission } from '../../../../types';
import { isAllowed } from '../../../../util/permissionUtil';
import { OCG_LIST } from '../../occupationClassificationGrades';
import PeopleSelect from '../../shared/PeopleSelect';
import UserPeopleSelect from '../../shared/UserPeopleSelect';
import { CheckBoxComp } from '../../../../components/v2/CheckBoxComp';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Moment } from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import { InfoSVG, RefreshSVG } from '../../../../assets';

interface Props {
  defaultDisable?: boolean;
  refetch?: () => void;
  isSecondary?: boolean;
  isSecondaryOnChange?: (e: CheckboxChangeEvent) => void;
  isEpfBorneByEmployerOnChange?: (e: CheckboxChangeEvent) => void;
  orgId?: number;
  joinedDate?: string | null;
}

const EmploymentLayout = styled.div`
  padding: 32px 32px 0px;
  @media (max-width: 768px) {
    padding: 0px 0px;
  }
`;

const EmploymentHeading = styled.div`
  @media (max-width: 1024px) and (min-width: 500px) {
    display: flex;
    flex-direction: row;
  }
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const RefreshColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0px !important;
`;
const TeamsContentColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4px !important;
  @media (max-width: 450px) {
    padding: 0px 12px !important;
  }
  margin: 24px 0px;
`;
const OccupationContentColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4px !important;
  @media (max-width: 450px) {
    padding: 0px 12px !important;
  }
`;

const RefreshIcon = styled.img<SpaceProps>`
  width: 18px;
  height: 18px;
  margin-top: 3px;
  ${space}
`;

const CheckBox = styled(CheckBoxComp)`
  margin-top: 35px;
  .ant-checkbox + span {
    color: ${theme.blue700};
    font-family: Inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
`;

const { Option } = Select;
export const EmploymentForm: FC<Props> = ({
  defaultDisable,
  isSecondaryOnChange,
  isSecondary,
  joinedDate,
  isEpfBorneByEmployerOnChange,
  orgId,
}) => {
  const dispatch: DispatchType = useDispatch();
  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const teams = organizationData?.accessGroups || [];

  const refetch = () => {
    dispatch(getMyOrganization());
  };

  const [resignedDate, setResignedDate] = useState<Dayjs | null>(null);
  const joinedDateVal = dayjs(joinedDate);
  const [joinedDateFormValue, setJoinedDateFormValue] = useState<Dayjs | null>(
    joinedDateVal ? joinedDateVal : null,
  );

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return !joinedDateVal || current <= joinedDateVal;
  };

  return (
    <EmploymentLayout>
      <Row>
        <Col xs={0} sm={0} md={0} lg={0} xl={12} xxl={12}>
          <B type="b-large-semibold" pb="24px">
            Employment Details
          </B>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={0} xxl={0}>
          <EmploymentHeading>
            {isAllowed(Permission.CREATE_EDIT_USERS) ? (
              <PeopleSelect defaultIndex="2" />
            ) : (
              <UserPeopleSelect defaultIndex="2" />
            )}
          </EmploymentHeading>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="employeeNumber"
            label="Employee Number"
            tooltip="The system use this employee number as the employee no to generate files (Ex: Bank Bulk Files, EPF)"
            rules={[
              {
                required: true,
                message: 'Please enter your employee number',
              },
              {
                type: 'string',
                message: 'Length should be less than 12',
                max: 12,
              },
            ]}>
            <InputComp
              size="small"
              disabled={defaultDisable}
              placeholder="Employee Number"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="companyEmail"
            label="Company Email"
            validateFirst
            rules={[
              {
                type: 'email',
                message: 'Enter a valid email',
              },
            ]}>
            <InputComp
              size="small"
              disabled={defaultDisable}
              placeholder="Company Email"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item
            name="companyTelephone"
            label="Company Telephone"
            validateFirst
            rules={[
              {
                type: 'string',
                pattern: Validation.NumberRegex,
                message: 'Please put a number without spaces',
              },
              {
                type: 'string',
                required: false,
                pattern: Validation.MobileNumberPattern,
                message: 'Invalid phone number',
              },
            ]}>
            <InputComp
              size="small"
              disabled={defaultDisable}
              placeholder="07xxxxxxxx"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item
            name="joinedDate"
            label="Joined Date"
            tooltip="This refers to the date employee joined the organisation"
            rules={[
              {
                required: true,
                message: 'Please enter joined date',
              },
            ]}>
            <DatePickerComp
              size="middle"
              disabled={defaultDisable}
              format="YYYY-MM-DD"
              value={joinedDateFormValue ? joinedDateFormValue : null}
              onChange={(value) => setJoinedDateFormValue(value)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item
            name="resignedDate"
            label="Resigned Date"
            tooltip="You can archive the employee once the last payrun is complete">
            <DatePickerComp
              size="middle"
              disabled={defaultDisable}
              format="YYYY-MM-DD"
              value={resignedDate ? dayjs(resignedDate) : null}
              onChange={(val) => setResignedDate(dayjs(val))}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item
            name="epfNumber"
            label="EPF"
            rules={[
              {
                type: 'string',
                pattern: Validation.EpfEtfNumberPattern,
                message:
                  'Please put a number without spaces or N/A in capitals',
              },
              {
                type: 'string',
                message: 'Length should be less than 6',
                max: 6,
              },
            ]}>
            <InputComp
              size="small"
              disabled={defaultDisable}
              placeholder="Enter EPF Number"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item
            name="etfNumber"
            label="ETF"
            rules={[
              {
                type: 'string',
                pattern: Validation.EpfEtfNumberPattern,
                message:
                  'Please put a number without spaces or N/A in capitals',
              },
              {
                type: 'string',
                message: 'Length should be less than 6',
                max: 6,
              },
            ]}>
            <InputComp
              size="small"
              disabled={defaultDisable}
              placeholder="Enter ETF Number"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item name="taxNumber" label="TIN">
            <InputComp
              size="small"
              disabled={defaultDisable}
              placeholder="Enter TIN Number"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        {orgId && epfBorneByEmployerOrg?.includes(orgId) && (
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item name="isEpfBorneByEmployer" valuePropName="checked">
              <CheckBox onChange={isEpfBorneByEmployerOnChange}>
                EPF borne by employer
              </CheckBox>
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item name="isSecondary" valuePropName="checked">
            <CheckBox onChange={isSecondaryOnChange}>
              Secondary Employee Income
            </CheckBox>
          </Form.Item>
        </Col>
        {!!isSecondary && (
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item name="primarySalary" label="Primary Employment Income">
              <InputNumberComp
                size="middle"
                disabled={defaultDisable}
                placeholder="Enter Primary Income"
              />
            </Form.Item>
          </Col>
        )}
      </Row>

      {/* 
      TODO : Uncomment once start development time sheets
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          {!isProduction && (
            <Form.Item
              name="employmentType"
              label="Employment Type (For time-sheets)">
              <SelectComp
                disabled={defaultDisable}
                placeholder="Select Employment Type"
                options={employmentTypes.map((type) => ({
                  label: type,
                  value: type,
                }))}
              />
            </Form.Item>
          )}
        </Col>
      </Row> */}
      <CustomDivider mb={24} borderTop={`1px solid ${theme.gray300}`} />
      <Row gutter={24}>
        <Col xs={24} sm={24} md={13} lg={13} xl={13}>
          <Row>
            <Col xs={22} sm={22} md={22} lg={22} xl={22}>
              <Form.Item
                name="teams"
                id="select-team"
                label="Teams"
                css={css`
                  margin-bottom: 6px;
                `}>
                <SelectComp
                  size="middle"
                  disabled={defaultDisable}
                  mode="multiple"
                  placeholder="Teams"
                  options={teams.map((team) => ({
                    label: team,
                    value: team,
                  }))}
                />
              </Form.Item>
              {!teams.length && (
                <Div display="flex">
                  <Image height={10} width={10} marginTop="5px" src={InfoSVG} />
                  <B type="b-small" color={theme.gray600} pl="5px">
                    A team has not been added yet!
                  </B>
                </Div>
              )}
            </Col>
            <RefreshColumn xs={2} sm={2} md={2} lg={2} xl={2}>
              <LinkButton
                ml="16px"
                onClick={() => refetch?.()}
                icon={
                  <RefreshIcon
                    mt={teams.length > 0 ? '13px' : '0px'}
                    src={RefreshSVG}
                  />
                }></LinkButton>
            </RefreshColumn>
          </Row>
        </Col>
        <TeamsContentColumn xs={24} sm={24} md={11} lg={11} xl={11}>
          <div>
            <B type="b-small" color={theme.gray600}>
              Select the relevant team of the employee based on department,
              business unit or other team category.
            </B>
            <a href="/customization" target="_blank">
              <B type="b-default" color={theme.blue500} pt="4px">
                Manage Teams
              </B>
            </a>
          </div>
        </TeamsContentColumn>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={13} lg={13} xl={13}>
          <Row>
            <Col xs={22} sm={22} md={22} lg={22} xl={22}>
              <Form.Item
                name="occupationGrade"
                label="Occupation Grade"
                tooltip="Occupation Grade is required to create EPF files"
                rules={[
                  {
                    required: true,
                    message: 'Please select Occupation Grade!',
                  },
                ]}
                css={css`
                  margin-bottom: 6px;
                `}>
                <SelectComp
                  size="middle"
                  disabled={defaultDisable}
                  placeholder="Select Occupation Group"
                  showSearch
                  optionFilterProp="children">
                  {OCG_LIST.sort((a, b) => (a.grade > b.grade ? 1 : -1)).map(
                    (value) => (
                      <Option key={value.id} value={value.id}>
                        {value.grade} - {value.id}
                      </Option>
                    ),
                  )}
                </SelectComp>
              </Form.Item>
              {!teams.length && (
                <Div display="flex">
                  <Image height={10} width={10} marginTop="5px" src={InfoSVG} />
                  <B type="b-small" color={theme.gray600} pl="5px">
                    Occupation Grade has not been added yet!
                  </B>
                </Div>
              )}
            </Col>
          </Row>
        </Col>
        <OccupationContentColumn xs={24} sm={24} md={11} lg={11} xl={11}>
          <div>
            <B type="b-small" color={theme.gray600}>
              Occupational grade is a field required by Employee Provident Fund
              and Employee Trust Fund. Learn more by clicking on the link below.
            </B>
          </div>
          <a href={OCCUPATION_GRADE_URL} target="_blank">
            <B type="b-default" color={theme.blue500} pt="4px">
              View more
            </B>
          </a>
        </OccupationContentColumn>
        <CustomDivider mb={24} borderTop={`1px solid ${theme.gray300}`} />
      </Row>
    </EmploymentLayout>
  );
};
