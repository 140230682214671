import React, { FC, useEffect, useState } from 'react';
import { Divider, Form, Row } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useForgetPasswordRequest } from '../../api/authHooks';
import { AlertMessage, B, H, InputComp, PrimaryButton } from '../../components';
import { RootState } from '../../store/reducer';
import theme from '../../theme';
import HumanisedFooter from './shared/HumanisedFooter';
import HumanisedHeader from './shared/HumanisedHeader';

const DividerContainer = styled(Divider)`
  margin: 24px 0 18px;
  .ant-divider {
    border-top: 1px solid ${theme.gray300};
  }
`;
const BackgroundContainer = styled.div`
  background: ${theme.gray50};
`;
const ForgotPasswordContainer = styled.div`
  height: 87vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-height: 520px) {
    align-items: normal;
    height: auto;
  }
`;
const FormContainer = styled(Form)`
  max-width: 420px;
  padding: 40px 24px 32px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: none;
  }
  @media (min-width: 768px) and (max-width: 1920px) {
    width: 420px;
  }
  @media (max-width: 450px) {
    padding: 40px 32px 32px;
  }
`;
const ForgotPasswordDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 0px 50px 0px;
  background: ${theme.white};
  border: 1px solid ${theme.gray200};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.0501803);
  border-radius: 4px;
  @media (max-width: 450px) {
    background: transparent;
    border: none;
    box-shadow: none;
  }
  @media screen and (max-height: 520px) {
    margin: 40px 0px 25px 0px;
  }
`;

const BackTOSignContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ForgotPassword: FC = () => {
  const authAllPayloadsLoading = useSelector(
    (state: RootState) => state.auth.authAllPayloadsLoading,
  );
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [form] = Form.useForm();
  const {
    isLoading: loadingForgetPasswordRequest,
    error: errorForgetPasswordRequest,
    data: dataForgetPasswordRequest,
    mutate: updateForgetPasswordRequest,
  } = useForgetPasswordRequest();

  async function handleSubmit(v: { email: string }) {
    updateForgetPasswordRequest(v.email);
    form.resetFields();
  }
  useEffect(() => {
    if (dataForgetPasswordRequest && !errorForgetPasswordRequest) {
      setShowErrorMessage(true);
    }
  }, [dataForgetPasswordRequest]);
  useEffect(() => {
    if (errorForgetPasswordRequest) {
      setShowErrorMessage(true);
    }
  }, [errorForgetPasswordRequest]);
  return (
    <>
      <BackgroundContainer>
        <HumanisedHeader />

        <ForgotPasswordContainer>
          <ForgotPasswordDiv>
            <FormContainer
              form={form}
              name="from"
              validateTrigger="onBlur"
              onFinish={(v) => handleSubmit(v as { email: string })}
              layout="vertical">
              <H type="h6">Forgot Password</H>
              <B type="b-default" color={theme.gray600} pt="4px" pb="24px">
                Enter the email address you used to sign up for Humanised.
              </B>
              {showErrorMessage && (
                <>
                  <AlertMessage
                    title="If an account exists for this email address, We have emailed a link to reset password which will be valid for a day."
                    type="warn"
                    onClose={() => {
                      setShowErrorMessage(false);
                    }}
                  />
                  <br />
                </>
              )}
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid email address',
                  },
                  {
                    required: true,
                    message: 'Please enter your email',
                  },
                ]}>
                <InputComp
                  disabled={authAllPayloadsLoading}
                  type="email"
                  size="large"
                  name="email"
                  placeholder="Enter your email"
                />
              </Form.Item>

              <Row>
                <PrimaryButton
                  htmlType="submit"
                  loading={
                    authAllPayloadsLoading || loadingForgetPasswordRequest
                  }
                  block>
                  Submit
                </PrimaryButton>
              </Row>
              <DividerContainer />
              <BackTOSignContainer>
                <Link to="/login">
                  <B type="b-default" color={theme.blue500}>
                    Back to Sign In
                  </B>
                </Link>
              </BackTOSignContainer>
            </FormContainer>
          </ForgotPasswordDiv>
        </ForgotPasswordContainer>
        <HumanisedFooter />
      </BackgroundContainer>
    </>
  );
};

export default ForgotPassword;
