import { PlaceholderTypeEnum } from '../../../types/contract.types';

export const getPlaceholderName = (type: PlaceholderTypeEnum): string => {
  switch (type) {
    case PlaceholderTypeEnum.date:
      return 'Date';
    case PlaceholderTypeEnum.textInput:
      return 'Text Box';
    case PlaceholderTypeEnum.signature:
      return 'Signature';
    default:
      throw new Error('Not Implemented Given Placeholder Name');
  }
};
