import React, { FC, useEffect, useState } from 'react';
import { Col, Dropdown, notification, Row, Table } from 'antd';
import Axios from 'axios';
import { useNavigate, useLocation } from 'react-router';
import { css } from '@emotion/react';
import { B, Div, H, PrimaryButton, SecondaryButton } from '../../../components';
import { BankBulkDownload } from '../modules/BankBulkDownload';
import {
  KeyValueI,
  MappedDataI,
} from '../open-column-mapping/shared/CSVMapper';
import { PaySheet } from '../modules/PaySheet';
import OnBoardModal from './OnBoardModal';

interface LocationDataI {
  mapDataColumns: MappedDataI[];
  payRunName: string;
}

const BankConfirmation: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [payRunName, setPayRunName] = useState('');
  const [dataSource, setDataSource] = useState<KeyValueI[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [contentJson, setContentJson] = useState<Object>({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const mapDataColumns =
      (location.state as LocationDataI).mapDataColumns || [];
    setPayRunName(location.state.payRunName);
    const newPaySheet = new PaySheet(mapDataColumns);
    const b = new BankBulkDownload(
      newPaySheet.getPaySheetBreakdownByEmployees(),
      newPaySheet.getEmployeeSalaryItems(),
    );
    setColumns(b.getColumns());
    setDataSource(b.getDataSource());
    setContentJson(newPaySheet.generateJSONForServer());
  }, []);

  return (
    <>
      <OnBoardModal
        loading={loading}
        open={visible}
        onComplete={async (result) => {
          try {
            setLoading(true);
            await Axios.post('/public/onboarding', {
              fileName: '10100',
              name: result.name,
              email: result.email,
              payRunName: payRunName,
              employeeSize: `${result.employeeSize}`,
              contentJson,
            });
            setLoading(false);

            navigate('/open-bank-confirmation-thank-you');
          } catch (error: any) {
            setLoading(false);

            notification.error({
              message: 'Error',
              description: 'Hmm... Something went wrong',
            });
          }
        }}
        onCancel={() => setVisible(false)}
      />
      <Div
        css={css`
          background-color: white;
          padding: 48px;
        `}>
        <H Pb="24px" type="h5">
          Confirmation
        </H>
        <Row gutter={[24, 8]}>
          <Col flex={1}>
            <Div
              css={css`
                background: #fdfdfd;
                border: 1px solid #e0e0e0;
                border-radius: 4px;
              `}>
              <H pb="24px" pt="24px" pl="24px" type="h6">
                Banking
              </H>
              <Col flex={1}>
                <Div
                  css={css`
                    display: inline-block;
                    padding: 4px;
                    padding-left: 16px;
                    padding-bottom: 16px;
                  `}>
                  <B type="b-default-semibold" pb="8px" color="#00318C">
                    File Format
                  </B>
                  <Dropdown.Button overlay={<div></div>} disabled>
                    CSV
                  </Dropdown.Button>
                </Div>

                <Div display="inline-block" padding="4px">
                  <B type="b-default-semibold" pb="8px" color="#00318C">
                    Bank
                  </B>
                  <Dropdown.Button overlay={<div></div>} disabled>
                    Sampath Bank
                  </Dropdown.Button>
                </Div>
              </Col>
            </Div>
          </Col>
        </Row>
        <Row gutter={[24, 8]}>
          <Col flex={1}>
            <Div
              css={css`
                background: #fdfdfd;
                border: 1px solid #e0e0e0;
                border-radius: 4px;
                .ant-table-cell:nth-child(1) {
                  padding-left: 30px !important;
                }
              `}>
              <H pb="24px" pt="24px" pl="24px" type="h6">
                Table preview - Bulk Transfer File
              </H>

              <Table
                size="small"
                dataSource={dataSource}
                columns={columns}
                scroll={{ x: 1400 }}
                pagination={false}
              />
              <Row>
                <Col>
                  <Div display="flex" padding="16px">
                    <SecondaryButton onClick={() => navigate(-1)}>
                      Cancel
                    </SecondaryButton>
                  </Div>
                </Col>
                <Col flex="1">
                  <Div display="flex" justifyContent="flex-end" padding="16px">
                    <PrimaryButton onClick={() => setVisible(true)}>
                      Generate
                    </PrimaryButton>
                  </Div>
                </Col>
              </Row>
            </Div>
          </Col>
        </Row>
      </Div>
    </>
  );
};

export default BankConfirmation;
