import React, { FC } from 'react';
import { Col, Row } from 'antd';
import SalaryCard from './SalaryCard';
import { EmployeePaySheetSummary } from '../modules/EmployeeSalary';

interface Prop {
  summary?: EmployeePaySheetSummary;
}

const SalarySummary: FC<Prop> = ({ summary }) => {
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <SalaryCard title="Gross" value={summary?.grossAddition || 0} />
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <SalaryCard
            title="ETF (Employer)"
            value={summary?.employerEPF || 0}
          />
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <SalaryCard
            title="EPF (Employer)"
            value={summary?.employerETF || 0}
          />
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <SalaryCard title="Net pay" value={summary?.netPay || 0} />
        </Col>
      </Row>
    </>
  );
};

export default SalarySummary;
