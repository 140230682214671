import React, { FC } from 'react';
import { Col, Form, Row } from 'antd';
import styled from '@emotion/styled';
import theme from '../../theme';
import { InputComp } from '../InputComp';
import { Tag } from './Tag';

const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const InputComponent = styled(InputComp)`
  margin-top: 2px;
  .ant-select-selection-item {
    background: rgba(51, 117, 238, 0.1);
    border-radius: 4px;
    color: ${theme.blue700};
  }
  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    background: ${theme.blue700};
  }
`;

const TagsContainer = styled(Row)`
  padding-top: 70px;
`;

interface Props {
  tags: string[];
  onChange: (tags: string[]) => void;
  label?: string;
  placeholder?: string;
}
const InputCompTags: FC<Props> = ({ tags, onChange, label, placeholder }) => {
  const tagItems: string[] = tags || [];
  const [tagForm] = Form.useForm();

  async function handleSubmit({ tag }: { tag: string }) {
    const newText: string = tag;
    const newList = [...new Set<string>([...tagItems, newText])];
    tagForm.resetFields();
    onChange(newList);
  }

  async function removeByIndex(index: number) {
    const newList = [...tagItems];
    newList.splice(index, 1);
    onChange(newList);
  }

  return (
    <Form form={tagForm} onFinish={handleSubmit}>
      <Row gutter={[32, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <FormItem
            rules={[
              {
                required: true,
                message: 'Enter tag!',
              },
            ]}
            name="tag">
            <InputComponent
              size="small"
              onPressEnter={(e) => {
                e.preventDefault();
                e.stopPropagation();
                tagForm.submit();
              }}
              name="tag"
              label={label}
              placeholder={placeholder}
            />
          </FormItem>
        </Col>
      </Row>

      <TagsContainer gutter={[32, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {tagItems.map((tag, i) => (
            <Tag
              onClose={() => {
                removeByIndex(i);
              }}>
              {tag}
            </Tag>
          ))}
        </Col>
      </TagsContainer>
    </Form>
  );
};

export default InputCompTags;
