import { ReactNode } from 'react';

/**
 * Here replace the list of string, reactNode with replace component.
 */
export function replaceJSX(
  list: Array<string | ReactNode>,
  find: string,
  replace: ReactNode,
): Array<string | ReactNode> {
  let finalResult: Array<string | ReactNode> = [];
  list.forEach((item) => {
    if (typeof item === 'string') {
      // split string with keyword and replace it with a component

      const parts = item.split(find);

      const results: any[] = [];
      for (let i = 0; i < parts.length; i++) {
        results.push(parts[i]);
        if (i !== parts.length - 1) {
          results.push(replace);
        }
      }
      finalResult = [...finalResult, ...results];
    } else {
      finalResult.push(item); // Nothing to do with already component
    }
  });

  return finalResult;
}
