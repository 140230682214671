import React, { FC, useState } from 'react';
import { message, Spin } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertMessage, B, Div, PrimaryButton } from '../../../components';
import {
  clearExportedData,
  getDataExportMetaData,
  postDataExport,
} from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import theme from '../../../theme';
import { DataExportPostBodyItemI } from '../../../types';
import { generateDataExportExcelSheets } from './exportData.util';
import ExportType from './ExportType';
import styled from '@emotion/styled';

const MainCard = styled.div`
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  max-width: 820px;
  flex-direction: column;
`;
const ExportCard = styled.div`
  display: flex;
  margin-left: auto;
`;
const Card = styled.div`
  padding: 24px 16px 20px;
  display: flex;
  flex-direction: column;
  @media (min-width: ${theme.MOBILE_MAX_WIDTH}px) {
    flex-direction: row;
  }
`;
const ExportDataBody = styled.div`
  padding-bottom: 54px;
  display: flex;
  flex-direction: column;
  @media (min-width: ${theme.MOBILE_MAX_WIDTH}px) {
    flex-direction: row;
  }
`;
const ExportData: FC = ({}) => {
  const dispatch: DispatchType = useDispatch();
  const [exportData, setExportData] = useState<{ [key: string]: string[] }>({});
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const exportMetaDataList = useSelector(
    (state: RootState) => state.dataExport.exportMetaDataList,
  );
  const successMessage = useSelector(
    (state: RootState) => state.dataExport.successMessage,
  );
  const exportedDataResponse = useSelector(
    (state: RootState) => state.dataExport.exportedDataResponse,
  );

  const loadingMetaData = useSelector(
    (state: RootState) => state.dataExport.loadingMetaData,
  );

  useEffect(() => {
    dispatch(getDataExportMetaData());
  }, []);

  useEffect(() => {
    if (exportedDataResponse.length) {
      const data = generateDataExportExcelSheets(exportedDataResponse);
      dispatch(clearExportedData());
      setShowErrorMessage(data ? data : false);
    }
  }, [exportedDataResponse]);

  const getExportDataFromApi = () => {
    if (exportData) {
      const body: DataExportPostBodyItemI[] = [];
      for (const propertyName in exportData) {
        const keys = exportData[propertyName];
        if (keys.length) {
          body.push({
            keys: exportData[propertyName],
            type: propertyName,
          });
        }
      }
      if (body.length) {
        dispatch(postDataExport({ exportData: body }));
      } else {
        message.warning('Please select fields to export data');
      }
    }
  };

  return (
    <>
      {successMessage && showErrorMessage ? (
        <Div marginBottom="34px">
          <AlertMessage
            title={successMessage}
            type="success"
            onClose={() => {
              setShowErrorMessage(false);
            }}
          />
        </Div>
      ) : null}

      <MainCard>
        <Card>
          <B type="b-large-semibold" color="#000000" pt="4px">
            Fields
          </B>
          <ExportCard>
            <PrimaryButton
              disabled={loadingMetaData}
              onClick={() => getExportDataFromApi()}>
              Export
            </PrimaryButton>
          </ExportCard>
        </Card>
        <Spin spinning={loadingMetaData}>
          <ExportDataBody>
            {exportMetaDataList.map((item) => (
              <ExportType
                key={item.type}
                title={item.columnTitle}
                options={item.fields}
                onChangeCheckedList={(value) => {
                  setExportData({ ...exportData, [item.type]: value });
                }}
              />
            ))}
          </ExportDataBody>
        </Spin>
      </MainCard>
    </>
  );
};
export default ExportData;
