import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import theme from '../../../theme';
import { Dropdown, Menu, Divider } from 'antd';
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';
import {
  ContractStatusEnum,
  GetContractI,
} from '../../../types/contract.types';
import { getContractStatusMessage } from '../utils/getContractStatusMessage';
import { useZoom } from './ZoomContext';
import { Download } from '../../../assets';

const Features = styled.div`
  display: flex;
  flex-direction: row;
  .ant-divider-vertical {
    height: inherit;
    border: 1px solid #e0e0e0;
  }
`;
const FeatureContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const ContractText = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  color: ${theme.gray600};
  font-size: 12px;
`;
const Container = styled.div`
  display: flex;
  padding: 0 16px;
  min-height: 40px;
  background-color: white;
  border-bottom: 1px solid ${theme.gray300};
`;
const DownloadContainer = styled.span`
  margin-right: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.gray600};
  cursor: pointer;
`;

const ZoomDropdown = styled(Dropdown)`
  margin: 0 auto;
  color: ${theme.gray600};
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  :hover {
    color: ${theme.gray600} !important;
    font-weight: 600;
    cursor: pointer;
  }
  .ant-dropdown .ant-dropdown-placement-bottomLeft {
    top: 255px !important;
  }
  img {
    padding-left: 14px;
  }
`;
interface Props {
  contract: GetContractI | null;
  downloadPDF?: () => void;
}

const options = [
  { value: 60, label: 'Zoom 60%' },
  { value: 80, label: 'Zoom 80%' },
  { value: 90, label: 'Zoom 90%' },
  { value: 100, label: 'Zoom 100%' },
  { value: 110, label: 'Zoom 110%' },
  { value: 120, label: 'Zoom 120%' },
  { value: 130, label: 'Zoom 130%' },
  { value: 140, label: 'Zoom 140%' },
];

export const ContractTopBar: FC<Props> = ({ contract, downloadPDF }) => {
  const { zoomValue, setZoomValue } = useZoom();
  const [selectedLabel, setSelectedLabel] = useState('');
  useEffect(() => {
    const found = options.find((option) => option.value === zoomValue);
    if (found) {
      setSelectedLabel(found.label);
    }
  }, [zoomValue]);
  const getStatus = getContractStatusMessage(contract);
  return (
    <Container>
      <ContractText>{getStatus.message}</ContractText>
      <Features>
        {downloadPDF ? (
          <>
            <FeatureContainer>
              <DownloadContainer onClick={() => downloadPDF()}>
                <img src={Download} />
                &nbsp;Download
              </DownloadContainer>
            </FeatureContainer>
            <Divider type="vertical" />
          </>
        ) : null}
        <FeatureContainer>
          <ZoomDropdown
            overlay={
              <Menu selectedKeys={[String(zoomValue)]}>
                {options.map((option) => {
                  return (
                    <Menu.Item
                      key={option.value}
                      onClick={() => setZoomValue(option.value)}>
                      {option.label}
                    </Menu.Item>
                  );
                })}
              </Menu>
            }
            trigger={['click']}>
            <span>
              {selectedLabel} <DownOutlined />
            </span>
          </ZoomDropdown>
        </FeatureContainer>
      </Features>
    </Container>
  );
};
