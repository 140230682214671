import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { LeaveReportI } from '../../../types/report.types';
import getNormalText from '../../../util/getNormalText';

export const generateLeaveDetailedExcel = (data: LeaveReportI[] = []) => {
  if (isEmpty(data)) return null;
  const dataPayload = data || [];
  const sheetRow: any[] = [];
  dataPayload.map((item) => {
    sheetRow.push({
      A: item.user.employeeNumber,
      B: item.user.fullName,
      C: getNormalText(item.leaveName),
      D: item.reason,
      E: `${item.hours}hours`,
      F: moment(item.date).format('DD.MM.YYYY'),
      G: item.status,
      H: item.type,
      I: item.reviewBy ? item.reviewBy?.fullName : '-',
      J: item.rejectionNote,
    });
  });
  sheetRow.push({});

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Leave_Detailed');
  worksheet.getRow(1).font = { bold: true };
  // create some dummy data
  worksheet.columns = [
    { key: 'A', width: 10, header: 'EMP ID' },
    { key: 'B', width: 35, header: 'Full Name' },
    { key: 'C', width: 20, header: 'Leave Type' },
    { key: 'D', width: 40, header: 'Reason' },
    { key: 'E', width: 10, header: 'Hours' },
    { key: 'F', width: 15, header: 'Date' },
    { key: 'G', width: 15, header: 'Status' },
    { key: 'H', width: 15, header: 'Type' },
    { key: 'I', width: 30, header: 'Reviewed By' },
    { key: 'J', width: 40, header: 'Rejection Note' },
  ];

  // Adding border to all cells
  worksheet.eachRow(function (row, _rowNumber) {
    worksheet.columns.forEach((_item: Partial<ExcelJS.Column>, i: number) => {
      const cell = row.getCell(i + 1);
      cell.border = {
        top: { style: 'thin', color: { argb: '00000000' } },
        left: { style: 'thin', color: { argb: '00000000' } },
        bottom: { style: 'thin', color: { argb: '00000000' } },
        right: { style: 'thin', color: { argb: '00000000' } },
      };
    });
  });
  worksheet.addRows(sheetRow);
  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `Leave_Detailed_Export_${moment().format('DD-MM-YYYY')}.xlsx`,
    );
  });
};
