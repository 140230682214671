import React, { FC, useEffect } from 'react';

import { Popover, Spin } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { AvatarComponent, Div, TableComp } from '../../../../components';
import { getOrganizations } from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import { OrganizationsI } from '../../../../types/superAdmin.types';
import { tableSorter } from '../../../../util/tableSorter';
import { addDotsForLongText, isHaveThreeDots } from '../../../../util/utils';

const OwnerTable: FC = () => {
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();

  useEffect(() => {
    dispatch(getOrganizations());
  }, []);

  const organizations = useSelector(
    (state: RootState) => state.superAdmin.organizations,
  );
  const organizationsLoading = useSelector(
    (state: RootState) => state.superAdmin.loading,
  );
  const columns: ColumnProps<OrganizationsI>[] = [
    {
      title: 'Owner Name',
      dataIndex: 'fullName',
      render: (_value: string, data) => (
        <Div display="flex" flexDirection="row">
          {data.profilePicture != null ? (
            <AvatarComponent
              src={data.profilePicture}
              shape="circle"
              border="none"
            />
          ) : (
            <AvatarComponent
              fontSize={12}
              fontWeight={600}
              shape="circle"
              title={data.fullName.charAt(0).toUpperCase()}
            />
          )}
          {isHaveThreeDots(data.fullName, 25) ? (
            <Popover
              content={data.fullName}
              trigger="hover"
              placement="topLeft">
              <p
                style={{
                  margin: 0,
                  fontSize: 12,
                  cursor: 'pointer',
                  padding: '5px 0px 0px 15px',
                }}>
                {data.fullName ? addDotsForLongText(data.fullName, 25) : '-'}
              </p>
            </Popover>
          ) : (
            <p
              style={{
                margin: 0,
                fontSize: 14,
                cursor: 'pointer',
                padding: '5px 0px 0px 15px',
              }}>
              {data.fullName ? data.fullName : '-'}
            </p>
          )}
        </Div>
      ),
      sorter: (a, b) =>
        tableSorter.defaultSort(
          a.fullName.toLowerCase(),
          b.fullName.toLowerCase(),
        ),
      sortDirections: ['descend', 'ascend'],
      key: 'fullName',
    },

    {
      title: 'Personal Email',
      dataIndex: 'loginEmail',
    },
    {
      title: 'Organizations',
      dataIndex: 'count',
      width: 150,
    },
    {
      title: '',
      dataIndex: 'userAccountId',
      width: 120,
      render: (value: string) => (
        <Link
          to={{
            pathname: `/super-admin/organization/${value}`,
          }}>
          <p
            style={{
              margin: 0,
              fontSize: 14,
              color: ' #0052ea',
              cursor: 'pointer',
            }}>
            View More
          </p>
        </Link>
      ),
    },
  ];

  return (
    <>
      <Spin spinning={organizationsLoading}>
        <div>
          <TableComp
            withBorders={true}
            onRow={(record: OrganizationsI) => {
              return {
                onClick: () => {
                  navigate(`/super-admin/organization/${record.userAccountId}`);
                },
              };
            }}
            loading={organizationsLoading}
            rowKey={(item) => item.userAccountId}
            pagination={false}
            columns={columns}
            dataSource={organizations}
            size="large"
            scroll={{
              x: 800,
              y: window.innerHeight - 370,
            }}
          />
        </div>
      </Spin>
    </>
  );
};

export default OwnerTable;
