import React, { FC, useEffect, useState } from 'react';
import { Col, message, Row } from 'antd';
import { H, RadioButton, RadioGroup } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType, RootState } from '../../../../store/reducer';
import { LogTrailTable } from '../../../../components/LogTrailTable';
import { getPeopleAuditLog } from '../../../../store/actions';
import { useParams } from 'react-router';
import PeopleSelect from '../../shared/PeopleSelect';
import { useGetLeaveLogs } from '../../../../api/leaveHooks';
import { LeaveLogTrailTable } from './LeaveLogTrailTable';
import { isAllowed } from '../../../../util/permissionUtil';
import { Permission } from '../../../../types';
import { getApiError } from '../../../../util/getApiError';
import styled from '@emotion/styled';

type ParamsI = {
  employeeKey: string;
};

const PeopleLogSelect = styled.div`
  @media (max-width: 1024px) and (min-width: 500px) {
    display: flex;
    flex-direction: column-reverse;
  }
  @media (max-width: 1024px) {
    padding-bottom: 24px;
  }
`;
const Card = styled.div`
  padding: ${false ? '36px 10px 0px 10px !important' : '32px'};
  @media (max-width: 768px) {
    padding: 0px 0px 24px 0px;
  }
`;

const RowStyle = styled(Row)`
  @media (max-width: 1024px) and (min-width: 500px) {
    display: flex;
    flex-direction: row;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;
export const PeopleLogTrail: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const params = useParams<ParamsI>();
  const [pageCount, setPageCount] = useState<number>(1);
  const [isPeopleAuditLogTab, setIsPeopleAuditLogTab] = useState<boolean>(true);

  const peopleAuditLogs = useSelector(
    (state: RootState) => state.logTrail.peopleAuditLogs,
  );

  const logLoading = useSelector((state: RootState) => state.logTrail.loading);

  const {
    isLoading: loadingLeaveLogs,
    error: errorLeaveLogs,
    data: dataLeaveLogs,
    mutate: getLeaveLogs,
  } = useGetLeaveLogs();

  useEffect(() => {
    if (isAllowed(Permission.CREATE_EDIT_USERS)) {
      dispatch(getPeopleAuditLog(params.employeeKey));
    }
  }, [params.employeeKey]);

  useEffect(() => {
    if (isAllowed(Permission.VIEW_USER_INFO) && !isPeopleAuditLogTab) {
      getLeaveLogs({ userId: params.employeeKey, page: String(pageCount) });
    }
  }, [pageCount, params.employeeKey]);

  return (
    <>
      {errorLeaveLogs && message.error(getApiError(errorLeaveLogs))}
      <Card>
        <RowStyle>
          <Col xs={0} sm={0} md={0} lg={0} xl={12} xxl={12}>
            <H type="h6" marginBottom="32px">
              Log Trail Details
            </H>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={0} xxl={0}>
            <PeopleLogSelect>
              <PeopleSelect defaultIndex="log-view" />
            </PeopleLogSelect>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {isAllowed(Permission.VIEW_USER_INFO) ? (
              <Row justify="end">
                <RadioGroup defaultValue="peopleAuditLog">
                  {isAllowed(Permission.CREATE_EDIT_USERS) ? (
                    <RadioButton
                      value="peopleAuditLog"
                      onClick={() => setIsPeopleAuditLogTab(true)}>
                      People Audit Log
                    </RadioButton>
                  ) : null}
                  <RadioButton
                    value="leaveLogTrail"
                    onClick={() => setIsPeopleAuditLogTab(false)}>
                    Leave Log Trail
                  </RadioButton>
                </RadioGroup>
              </Row>
            ) : null}
          </Col>
        </RowStyle>
        <>
          {isPeopleAuditLogTab ? (
            <LogTrailTable logs={peopleAuditLogs} loading={logLoading} />
          ) : (
            <LeaveLogTrailTable
              logs={dataLeaveLogs?.items ? dataLeaveLogs.items : []}
              loading={loadingLeaveLogs}
              pageDetails={dataLeaveLogs?.meta ? dataLeaveLogs?.meta : null}
              showMore={(value) => setPageCount(value)}
            />
          )}
        </>
      </Card>
    </>
  );
};
