import React, { FC, ReactNode } from 'react';
import { Typography } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { PrimaryButton } from '../../../components';
import styled from '@emotion/styled';

const { Title } = Typography;

interface Props {
  title: ReactNode;
}

const TitleComp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 71px;
  @media only screen and (min-width: 601px) {
    padding-bottom: 22px;
  }
  @media only screen and (max-width: 600px) {
    padding: 22px 10px;
  }
`;
const Text = styled(Title)`
  margin-bottom: 0 !important;
  font-size: 20px !important;
  .ant-page-header {
    padding: 20px 0px;
  }
`;

const TitleHeader: FC<Props> = ({ title }) => {
  const location = useLocation();
  const history = useNavigate();
  return (
    <>
      {location.pathname === '/' ? (
        <TitleComp>
          <div>
            <Text level={3}>{title}</Text>
          </div>

          <div>
            <PrimaryButton
              onClick={() => {
                history('/leave');
              }}>
              Apply Leave
            </PrimaryButton>
          </div>
        </TitleComp>
      ) : null}
    </>
  );
};

export default TitleHeader;
