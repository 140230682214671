/* eslint-disable react/jsx-no-target-blank */
import React, { FC } from 'react';
import { B } from '../../../../components';
import ImportCSVUploader from './ImportCSVUploader';
import { DataImportConfig } from '../../../../configs';
import { DataExtractorCSVEnum } from '../../../../store/actions';
import styled from '@emotion/styled';
import theme from '../../../../theme';

const DownloadStyle = styled.a`
  color: ${theme.blue500};
  padding-left: 4px;
`;
const ContainerStyle = styled.div`
  background: ${theme.gray50};
  border: 0.5px solid ${theme.gray300};
  border-top: none !important;
  box-sizing: border-box;
  padding: 24px 48px 32px;
  margin: -16px -31px 0px !important;
  @media (max-width: 760px) {
    margin: -16px -16px 0px !important;
    padding: 24px 16px 32px;
  }
`;
interface Props {
  extractorInfo: DataImportConfig;
}
const ImportCSV: FC<Props> = ({ extractorInfo }) => {
  return (
    <ContainerStyle>
      <B type="b-large-semibold">Import Salary Details</B>
      <div>
        <B type="b-default" py="24px" color={theme.gray600}>
          Please ensure your employees&apos; salary details are up to date. File
          type should be CSV.
          <DownloadStyle
            download
            target="_blank"
            href={extractorInfo.sampleFiles[DataExtractorCSVEnum.SALARY_CSV]}>
            Download &nbsp;
          </DownloadStyle>
          Sample Salary details sheet.
        </B>
        <ImportCSVUploader extractorInfo={extractorInfo} />
      </div>
    </ContainerStyle>
  );
};

export default ImportCSV;
