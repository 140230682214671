import React, { FC, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Form, message, Spin, Divider } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { PlusOutlined } from '@ant-design/icons';
import {
  B,
  DatePickerV2,
  InputAreaV2,
  InputNumberV2,
  SelectV2,
  LabeledInputV2,
  FooterComponent,
  SecondaryButton,
  PrimaryButton,
  FileUploaderV2,
  EmptyPage,
  AlertMessage,
  RouteLeavingGuard,
  TreeSelectV2,
} from '../../../components';
import theme from '../../../theme';
import { isAllowed } from '../../../util/permissionUtil';
import {
  ExpenseCreateI,
  ExpenseCreation,
  ExpenseMetaData,
  IntegrationStatusEnum,
  Permission,
  SuppliersList,
  UpdateExpenseI,
} from '../../../types';
import {
  expenseMetaList,
  getExpenseUsersList,
  setTreeViewData,
} from '../util/getExpenseCategory';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType, RootState } from '../../../store/reducer';
import { getMinimumUserInfo } from '../../../store/actions';
import {
  useCreateExpense,
  useCreateExpenseSupplier,
  useGetExpense,
  useGetExpenseCategoriesList,
  useGetExpenseSuppliersList,
  useUpdateExpense,
} from '../../../api/expenseHooks';
import moment, { Moment } from 'moment';
import { getApiError } from '../../../util/getApiError';
import { findNestedExpense } from '../util/searchNestedCategory';
import {
  ErrorMessageContainer,
  WarnMessageContainer,
} from '../../settings/integration-apps/components/IntegrationAlerts';
import { PeopleI } from '../../../types/people.types';
import dayjs from 'dayjs';

const Layout = styled.div`
  padding: 32px;
  @media screen and (max-width: 768px) {
    padding: 24px 16px;
  }
  margin-bottom: 80px;
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 565px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const FormItem = styled(Form.Item)`
  margin-bottom: 24px;
  width: 344px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const AlertContainer = styled.div`
  width: 814px;
  margin-bottom: 32px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const AddMerchant = styled.div`
  color: ${theme.blue500};
  cursor: pointer;
  padding: 0 8px 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;
const AddMerchantContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

type ParamsI = {
  expenseId: string;
};

const ExpensesManagePage: FC = () => {
  const me = useSelector((state: RootState) => state.people.me);

  const isAllowedExpenseBasic: boolean =
    isAllowed(Permission.MANAGE_EXPENSE_STATUS_BASIC) || !!me?.isManager;
  const isAllowedManageIntegration: boolean = isAllowed(
    Permission.MANAGE_INTEGRATION,
  );

  const dispatch: DispatchType = useDispatch();
  const allPeoples = useSelector((state: RootState) => state.people.allPeoples);

  const params = useParams<ParamsI>();
  const navigate = useNavigate();

  const [alert, setAlert] = useState<boolean>(false);
  const [user, setUser] = useState<PeopleI | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<(File | string)[]>([]);
  const [isFieldDataChanged, setIsFieldDataChanged] = useState(false);
  const [name, setName] = useState('');
  const [form] = Form.useForm();

  const shouldBlockNavigation = () => {
    return isFieldDataChanged;
  };

  const {
    isLoading: categoriesListLoading,
    error: categoriesListError,
    data: getCategoriesList,
  } = useGetExpenseCategoriesList();

  const {
    isLoading: suppliersListLoading,
    error: suppliersListError,
    data: getSuppliersList,
    refetch: getSuppliersListFn,
  } = useGetExpenseSuppliersList();

  const {
    isLoading: loadingCreateSupplier,
    error: errorCreateSupplier,
    mutate: createSupplier,
    data: createSupplierData,
    isSuccess: createSupplierSuccess,
  } = useCreateExpenseSupplier();

  const {
    isLoading: loadingGetExpense,
    error: errorGetExpense,
    data: dataGetExpense,
    mutate: getExpense,
  } = useGetExpense();

  const {
    isLoading: loadingCreateExpense,
    error: errorCreateExpense,
    data: dataCreateExpense,
    mutate: createExpense,
  } = useCreateExpense();

  const {
    isLoading: loadingUpdateExpense,
    error: errorUpdateExpense,
    data: dataUpdateExpense,
    mutate: updateExpense,
  } = useUpdateExpense();

  useEffect(() => {
    if (isAllowedExpenseBasic) {
      dispatch(getMinimumUserInfo());
    }
    if (params?.expenseId) {
      getExpense(Number(params?.expenseId));
    } else {
      if (isAllowedExpenseBasic) {
        setSelectedUser(Number(me?.id));
      }
    }
  }, []);

  useEffect(() => {
    if (user) {
      setAlert(
        !user?.profileStatus?.completionStatus.bankDetails ? true : false,
      );
    }
  }, [user]);

  // success handling
  useEffect(() => {
    if (dataCreateExpense && !errorCreateExpense) {
      message.success('Created Successfully!');
      // navigate to expense home page
      navigate('/expenses');
    }
  }, [dataCreateExpense]);

  useEffect(() => {
    if (dataUpdateExpense && !errorUpdateExpense) {
      message.success('Updated Successfully!');
      // navigate to expense home page
      navigate('/expenses');
    }
  }, [dataUpdateExpense]);

  useEffect(() => {
    if (createSupplierSuccess && !errorCreateSupplier) {
      message.success('Created Merchant Successfully!');
      setName('');
      getSuppliersListFn().then(() => {
        if (createSupplierData) {
          form.setFieldsValue({
            merchantRef: createSupplierData.value,
          });
        }
      });
    }
  }, [createSupplierSuccess]);

  useEffect(() => {
    if (dataGetExpense && !errorGetExpense) {
      if (dataGetExpense) {
        form.setFieldsValue({
          amount: dataGetExpense.amount,
          categoryRef: dataGetExpense.categoryRef,
          date: dayjs(dataGetExpense.date),
          merchantRef: dataGetExpense.merchantRef,
          note: dataGetExpense.note,
          user: dataGetExpense.userId,
        });
        if (dataGetExpense.files && dataGetExpense.files.length) {
          setSelectedFiles(dataGetExpense.files);
        }
      }
    }
  }, [dataGetExpense]);

  // error handling
  useEffect(() => {
    errorCreateExpense && message.error(getApiError(errorCreateExpense));
    errorUpdateExpense && message.error(getApiError(errorUpdateExpense));
    errorGetExpense && message.error(getApiError(errorGetExpense));
    categoriesListError && message.error(getApiError(categoriesListError));
    suppliersListError && message.error(getApiError(suppliersListError));
    errorCreateSupplier && message.error(getApiError(errorCreateSupplier));
  }, [
    errorCreateExpense,
    errorUpdateExpense,
    errorGetExpense,
    categoriesListError,
    suppliersListError,
    errorCreateSupplier,
  ]);

  const setSelectedUser = (id: number) => {
    const user: PeopleI | undefined = allPeoples.find((i) => i.id === id);
    if (user) {
      form.setFieldsValue({
        user: user?.id,
      });
    }
    setUser(user ? user : null);
  };

  const onValuesChange = () => {
    setIsFieldDataChanged(true);
  };

  const onFinish = (values: ExpenseCreateI) => {
    const categories: (SuppliersList | ExpenseMetaData)[] =
      getCategoriesList?.metaData || [];
    const merchants: (SuppliersList | ExpenseMetaData)[] =
      getSuppliersList?.metaData || [];

    setIsFieldDataChanged(false);
    const formData = new FormData();

    formData.append('date', values.date.format('YYYY-MM-DD'));
    formData.append('amount', values.amount);
    formData.append('categoryRef', values.categoryRef);
    formData.append('merchantRef', values.merchantRef);

    if (categories && categories.length) {
      const category: SuppliersList | ExpenseMetaData | null =
        findNestedExpense(categories, values.categoryRef);
      if (!category) {
        message.error('Category not found! Please contact admin...');
        return;
      }
      formData.append('category', category.label);
    }

    if (merchants && merchants.length) {
      const merchant: SuppliersList | ExpenseMetaData | undefined =
        merchants.find((i) => i.value === values.merchantRef);
      if (!merchant) {
        message.error('Merchant not found! Please contact admin...');
        return;
      }
      formData.append('merchant', merchant.label);
    }

    values.note && formData.append('note', values.note);

    const files: string[] = [];
    if (selectedFiles && selectedFiles.length) {
      for (let i = 0; i < selectedFiles.length; i++) {
        if (typeof selectedFiles[i] === 'string') {
          files.push(String(selectedFiles[i]));
        } else {
          formData.append('newUploads', selectedFiles[i]);
        }
      }
    }

    if (params?.expenseId) {
      files && files.length && formData.append('files', JSON.stringify(files));
      const dataSet: UpdateExpenseI = {
        id: Number(params.expenseId),
        data: formData,
      };
      updateExpense(dataSet);
    } else {
      values.user && formData.append('user', values.user);
      createExpense(formData);
    }
  };

  const disabledDate = (current: Moment): boolean => {
    // Can select days before today and today
    return moment() > current ? false : true;
  };

  const CreateMerchant = () => {
    if (name === '') {
      return null;
    } else {
      const existMerchant = getSuppliersList?.metaData.find(
        (i) => i.label.toLocaleLowerCase() === name.toLocaleLowerCase(),
      );
      if (existMerchant) {
        return null;
      } else {
        return (
          <AddMerchantContainer>
            <Divider style={{ margin: '8px 0' }} />
            <AddMerchant
              onClick={() =>
                createSupplier({
                  label: name.trim(),
                })
              }>
              <PlusOutlined /> Create <b>{name && `"${name.trim()}"`}</b>
            </AddMerchant>
          </AddMerchantContainer>
        );
      }
    }
  };

  return (
    <>
      {isAllowedManageIntegration &&
        getCategoriesList?.type !== ExpenseCreation.SYSTEM &&
        !categoriesListLoading &&
        [
          IntegrationStatusEnum.ACTIVATED,
          IntegrationStatusEnum.PENDING,
        ].includes(getCategoriesList?.status as IntegrationStatusEnum) && (
          <WarnMessageContainer
            marginLeft="32px"
            marginBottom="0px"
            message={`Please note the categories & merchants included here are from ${getSuppliersList?.type.toLocaleLowerCase()}, through our
            integration.`}
            type="warning"
            showIcon
            closable
          />
        )}

      {isAllowedManageIntegration &&
        getCategoriesList?.type !== ExpenseCreation.SYSTEM &&
        !categoriesListLoading &&
        [IntegrationStatusEnum.ACTION_REQUIRED].includes(
          getCategoriesList?.status as IntegrationStatusEnum,
        ) && (
          <ErrorMessageContainer
            marginLeft="32px"
            marginBottom="0px"
            message={
              <>
                Opps! Looks like your{' '}
                <b>{getCategoriesList?.type.toLocaleLowerCase()}</b> integration
                is not working anymore.
              </>
            }
            type="error"
            showIcon
          />
        )}
      <Layout>
        {params?.expenseId && (loadingGetExpense || errorGetExpense) ? (
          <div>
            {loadingGetExpense ? (
              <Spin />
            ) : (
              errorGetExpense && (
                <EmptyPage
                  content="Sorry, the page you visited does not exist."
                  title="something went wrong !"
                  buttonLabel=" Back Home"
                />
              )
            )}
          </div>
        ) : (
          <>
            {isAllowedExpenseBasic && alert && user && (
              <AlertContainer>
                <AlertMessage
                  title={`${user.fullName} - Bank details has not added!`}
                  type="warn"
                  actionLabel="Add Now"
                  onAction={() => navigate(`/contact/bank-details/${user.id}`)}
                  onClose={() => setAlert(false)}
                />
              </AlertContainer>
            )}
            <Form
              form={form}
              name="ManageExpense"
              onFinish={onFinish}
              onValuesChange={onValuesChange}>
              <RouteLeavingGuard
                when={isFieldDataChanged}
                navigate={(path) => navigate(path)}
                shouldBlockNavigation={shouldBlockNavigation}
              />
              <ColumnContainer>
                <B type="b-default" color={theme.gray600}>
                  Enter your expense details
                </B>
                <FormContainer>
                  {isAllowedExpenseBasic && (
                    <LabeledInputV2 label="User*" flexDirection="row">
                      <FormItem
                        name="user"
                        rules={[
                          {
                            required: true,
                            message: 'Please select your user!',
                          },
                        ]}>
                        <SelectV2
                          flexDirection="row"
                          placeholder="Select User"
                          options={getExpenseUsersList(allPeoples)}
                          disabled={params?.expenseId ? true : false}
                          onChange={(e) => setSelectedUser(Number(e))}
                        />
                      </FormItem>
                    </LabeledInputV2>
                  )}

                  <LabeledInputV2 label="Date*" flexDirection="row">
                    <FormItem
                      name="date"
                      rules={[
                        { required: true, message: 'Please select date!' },
                      ]}>
                      <DatePickerV2
                        flexDirection="row"
                        placeholder="Select Date"
                        inputReadOnly={true}
                        disabledDate={(current) =>
                          disabledDate(moment(current.toDate()))
                        }
                      />
                    </FormItem>
                  </LabeledInputV2>

                  <LabeledInputV2 label="Merchant*" flexDirection="row">
                    <FormItem
                      name="merchantRef"
                      rules={[
                        { required: true, message: 'Please enter merchant!' },
                      ]}>
                      <SelectV2
                        forceClose={
                          createSupplierSuccess && !errorCreateSupplier
                        }
                        loading={suppliersListLoading}
                        flexDirection="row"
                        placeholder="Select Merchant"
                        options={expenseMetaList(getSuppliersList)}
                        showSearch
                        optionFilterProp="label"
                        allowClear
                        onSearch={(value) => setName(value)}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            {getSuppliersList?.type ===
                              ExpenseCreation.SYSTEM && CreateMerchant()}
                          </>
                        )}
                      />
                    </FormItem>
                  </LabeledInputV2>

                  <LabeledInputV2 label="Amount*" flexDirection="row">
                    <FormItem
                      name="amount"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your amount!',
                        },
                      ]}>
                      <InputNumberV2
                        isCurrency
                        placeholder="Enter Amount"
                        flexDirection="row"
                        size="small"
                        addonBefore="LKR"
                      />
                    </FormItem>
                  </LabeledInputV2>

                  <LabeledInputV2 label="Category*" flexDirection="row">
                    <FormItem
                      name="categoryRef"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your category!',
                        },
                      ]}>
                      <TreeSelectV2
                        treeDefaultExpandAll={true}
                        loading={categoriesListLoading}
                        flexDirection="row"
                        placeholder="Select Category"
                        treeData={setTreeViewData(
                          getCategoriesList?.metaData as ExpenseMetaData[],
                          [],
                        )}
                        showSearch
                        treeNodeFilterProp="title"
                        allowClear
                      />
                    </FormItem>
                  </LabeledInputV2>

                  <LabeledInputV2 label="Note" flexDirection="row">
                    <FormItem name="note">
                      <InputAreaV2
                        flexDirection="row"
                        placeholder="What was it for?"
                      />
                    </FormItem>
                  </LabeledInputV2>

                  <LabeledInputV2 label="Bill/Receipt" flexDirection="row">
                    <FileUploaderV2
                      flexDirection="row"
                      accept=".pdf, .jpg, .png, .jpeg"
                      infoText="Maximum upload file size : 5mb, and supported file extensions pdf, jpg, png or jpeg format"
                      onFileChange={(e) => setSelectedFiles(e)}
                      fileList={selectedFiles}
                      fileLimit={5}
                      maxFileSizeEach={5}
                    />
                  </LabeledInputV2>
                </FormContainer>
              </ColumnContainer>
              <FooterComponent
                leftChildren={
                  <SecondaryButton
                    onClick={() => {
                      navigate('/expenses');
                    }}>
                    Cancel
                  </SecondaryButton>
                }
                rightChildren={
                  <PrimaryButton
                    loading={
                      loadingCreateExpense ||
                      loadingUpdateExpense ||
                      loadingCreateSupplier
                    }
                    htmlType="submit">
                    {params.expenseId ? 'Update' : 'Add'} Expense
                  </PrimaryButton>
                }
                fullScreen={false}
              />
            </Form>
          </>
        )}
      </Layout>
    </>
  );
};

export default ExpensesManagePage;
