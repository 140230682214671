import React, { FC, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import CalculationCard from './CalculationCard';
import { numberThousandSeparator } from '../../../util/utils';

const RowSummary = styled(Row)`
  padding: 12px 32px 12px 64px;
  @media only screen and (max-width: 768px) {
    padding: 8px 10px;
    .ant-col {
      margin-bottom: 16px;
    }
  }
`;
const ColSummary = styled(Col)`
  text-align: right;
`;
interface Props extends PropsWithChildren {
  summary: {
    costToCompany: string;
    netSalary: string;
    employerEpf: string;
    employeeEpf: string;
    totalEpf: string;
    etf: string;
  };
  selected: string | number;
  allEmpLength?: number;
  currentExchangeRate?: number | null;
}

export const SalarySummaryCard: FC<Props> = ({
  summary,
  children,
  selected,
  allEmpLength,
  currentExchangeRate,
}) => {
  return (
    <RowSummary>
      <CalculationCard
        prefix=""
        title="Employee Count"
        amount={selected}
        length={allEmpLength}
      />
      <CalculationCard
        prefix="LKR"
        title="Total EPF"
        amount={summary.totalEpf}
      />
      <CalculationCard prefix="LKR" title="Total ETF " amount={summary.etf} />
      <CalculationCard
        prefix="LKR"
        title="Total Net Salary"
        amount={summary.netSalary}
      />
      <CalculationCard
        prefix="LKR"
        title="Total Cost to Company"
        amount={summary.costToCompany}
      />
      {currentExchangeRate && (
        <CalculationCard
          prefix="LKR"
          title="Exchange Rate"
          amount={numberThousandSeparator(currentExchangeRate)}
        />
      )}
      <ColSummary xs={24} sm={24} md={12} lg={4} xl={4}>
        {children}
      </ColSummary>
    </RowSummary>
  );
};
