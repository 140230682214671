import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { LinkButton } from '../../../../../components';
import { generateEmployeeMasterExcel } from './generateEmployeeExcel.util';
import { useWindowSize } from '../../../../../util/screenSize';
import { PeopleI } from '../../../../../types/people.types';
import { ExportSVG } from '../../../../../assets';

interface Props {
  payload: PeopleI[] | undefined;
  selectedRowKes: number[];
  disabled: boolean;
}

export const EmployeeExport: FC<Props> = ({
  payload,
  selectedRowKes,
  disabled,
}) => {
  const { isMobile, isTablet } = useWindowSize();

  return (
    <Tooltip title="Export as Excel Sheet">
      <LinkButton
        disabled={disabled}
        mb={isMobile || isTablet ? 24 : 0}
        mr={isMobile || isTablet ? 0 : 8}
        onClick={() => {
          generateEmployeeMasterExcel(payload, selectedRowKes);
        }}
        icon={<img width="14px" height="14px" src={ExportSVG} />}>
        Export
      </LinkButton>
    </Tooltip>
  );
};
