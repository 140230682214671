import React from 'react';
import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';

import theme from '../theme';
import styled from '@emotion/styled';

// This "any" is from the AntTable
interface Props<T extends object = any> extends TableProps<T> {
  fullScreen?: boolean;
}
const CompactTable = styled(Table)<{ minHeight: string }>`
  margin-bottom: -25px;
  border: 1px solid ${theme.gray300} !important;
  border-radius: 2px;
  box-shadow: none;

  .ant-table {
    line-height: normal;
    font-size: 13px;
    background-color: #fafafa;
  }
  .ant-table-selection-col {
    width: 60px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${theme.blue500};
    background-color: ${theme.lightBlue50};
  }
  &:hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    border-color: ${theme.blue500} !important;
    background-color: ${theme.lightBlue50} !important;
  }
  .ant-table-body {
    min-height: ${(props) => props.minHeight};
  }

  .ant-table-thead > tr > th {
    background: #fafafa;
    font-size: 14px;
    color: ${theme.gray600};
    height: 32px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    padding: 6px 0px 6px 0px !important;
    text-align: center !important;
    border-right: 1px solid ${theme.gray300};
    &.ant-table-selection-column {
      padding-left: 0 !important;
    }
  }
  .ant-table-thead > tr:not(:last-child) > th[colspan] {
    border-bottom: 1px solid ${theme.gray300};
  }

  .ant-table-tbody > tr > td {
    border-right: 1px solid ${theme.gray300};
    border-bottom: 1px solid ${theme.gray300};
    background: ${theme.white};
    font-size: 12px;
    padding-right: 12px;
    padding-left: 12px !important;
    padding-top: 6px;
    padding-bottom: 6px;
    &.ant-table-selection-column {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }
  .ant-table-tbody .ant-table-row.ant-table-row-selected > .ant-table-cell {
    background: ${theme.white};
  }
  .error-row {
    background-color: red;
    height: 100%;
    width: 12px;
    top: 1px;
    left: 0;
    position: absolute;
  }
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
  }
  .ant-table-column-sorters {
    padding: 0px 5px !important;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    border-color: ${theme.blue500};
    background-color: ${theme.lightBlue50};
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${theme.blue500};
  }
  .ant-table-tbody > .new-row > td {
    background: ${theme.lightBlue100};
  }
  .ant-table .ant-table-expanded-row-fixed {
    height: 535px !important;
  }

  .ant-table-column-sorters {
    display: flex;
    align-items: center;
    justify-content: unset !important;
  }

  .ant-table-column-title {
    flex: none !important;
  }

  .ant-table-column-sorter-full {
    margin-left: 8px;
  }
`;
// const rowSelection = {};
export function CompactTableComp<T extends object = any>(
  props: Props<T>,
): JSX.Element {
  const minHeight = `${(Number(props.scroll?.y) || 100) + 55}px`;

  return <CompactTable {...props} minHeight={minHeight} />;
}

export default CompactTableComp;
