import React, { FC, useEffect } from 'react';

import { Col, Form, Input, Row, Typography } from 'antd';
import { isTablet } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { css } from '@emotion/react';

import { Div, H, InputComp, PrimaryButton } from '../../components';
import { EmptyPage } from '../../components/EmptyComponent';
import { getSignupUser, signupUser } from '../../store/actions';
import { DispatchType, RootState } from '../../store/reducer';
import HumanisedFooter from './shared/HumanisedFooter';
import HumanisedHeader from './shared/HumanisedHeader';

const { Text } = Typography;

type SignupParams = {
  token: string;
};

export interface userSignupDataI {
  fullName: string | null;
  createPassword: string | null;
  token: string;
}

const Signup: FC = () => {
  const params = useParams<SignupParams>();
  const dispatch: DispatchType = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const signupData = useSelector((state: RootState) => state.auth.signupData);
  const errorHandling = useSelector(
    (state: RootState) => state.auth.errorHandling,
  );

  async function onFinish(data: userSignupDataI) {
    const values = {
      fullName: data.fullName,
      password: data.createPassword,
      token: params.token,
    };
    await dispatch(signupUser(values));
    navigate('/');
  }

  useEffect(() => {
    dispatch(getSignupUser(params.token));
  }, []);

  useEffect(() => {
    if (signupData) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(signupData)) {
        form.setFieldsValue({ [key]: value });
      }
    }
  }, [signupData]);

  return (
    <Div
      css={css`
        background: #fafafa;
      `}>
      {errorHandling ? (
        <div>
          <EmptyPage
            content="Sorry, the page you visited does not exist."
            title="something went wrong !"
            buttonLabel=""
          />
        </div>
      ) : (
        <Div
          css={css`
            height: 100vh;
          `}>
          <HumanisedHeader />
          <Div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              margin: ${isTablet ? '130px 0px 130px 0px' : '80px 0px 50px 0px'};
              @media (min-height: 900px) {
                margin: 95px 0px 50px 0px;
              }
              @media (min-height: 1080px) {
                margin: 184px 0px 50px 0px;
              }
            `}>
            <Row
              css={css`
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.0501803);
                height: 560px;
                width: 505px;
                background: #ffff;
              `}>
              <Col style={{ margin: '64px 48px 24px 48px' }}>
                <H type="h4">Sign Up</H>
                <Text
                  css={css`
                    height: 24px;
                    width: 409px;
                    font-weight: 600;
                    font-size: 16px;
                  `}>
                  Beta Launch team invited you to join humanised!
                </Text>

                <Form
                  form={form}
                  validateTrigger="onBlur"
                  layout="vertical"
                  onFinish={onFinish}
                  css={css`
                    margin-top: 44px;
                    .ant-form-item-label > label {
                      font-size: 14px;
                      font-weight: 600;
                      color: #00318c;
                    }
                    .ant-form-item-label
                      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
                      content: none;
                    }
                  `}>
                  <Form.Item
                    name="loginEmail"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your email!',
                      },
                    ]}>
                    <InputComp disabled placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    name="fullName"
                    label="Your Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your Name!',
                      },
                    ]}>
                    <InputComp placeholder="Full name" />
                  </Form.Item>
                  <Form.Item
                    name="createPassword"
                    label="Create Password"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your password!',
                      },
                      {
                        min: 8,
                        message:
                          'your password should be at least 8 characters long',
                      },
                    ]}>
                    <Input.Password
                      type="password"
                      placeholder="Password"
                      size="large"
                      css={css`
                        border: 1px solid #bdbdbd;
                        box-sizing: border-box;
                        padding-left: 16px;
                        border-radius: 4px;
                        font-family: Inter;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;

                        &:focus {
                          border: 1px solid #0052ea;
                          box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1);
                        }

                        &:hover {
                          border: 1px solid #0052ea;
                          box-sizing: border-box;
                          filter: drop-shadow(
                            0px 0px 10px rgba(0, 82, 234, 0.1)
                          );
                          border-radius: 4px;
                        }
                      `}
                    />
                  </Form.Item>
                  <Form.Item
                    css={css`
                      margin-top: 25px;
                      width: 100%;
                      .ant-btn-lg {
                        width: 100%;
                      }
                    `}>
                    <PrimaryButton htmlType="submit">Sign Up</PrimaryButton>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Div>
          <HumanisedFooter />
        </Div>
      )}
    </Div>
  );
};

export default Signup;
