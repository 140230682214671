import React, { FC, useState } from 'react';
import { B, DatePickerComp, Div } from '../../../../components';
import {
  ChangeNotificationStatusI,
  NotificationStatusEnum,
  NotificationTypeEnum,
  Reminders,
  ReminderTypeEnum,
} from '../../../../types/notification.types';
import reminderSvg from '../../../../assets/Icons/note-icons/reminder.svg';
import moment from 'moment';
import { NameTag } from '../../../../components/Tags/NameTag';
import { Col, Dropdown, Menu, Row } from 'antd';
import { addDotsForLongText } from '../../../../util/utils';
import { useWindowSize } from '../../../../util/screenSize';
import theme from '../../../../theme';
import styled from '@emotion/styled';
interface Props {
  item: Reminders;
  isCollapsed: boolean;
  onClickEdit?: () => void;
  onDataChange: (value: ChangeNotificationStatusI) => void;
  highlightId?: number;
}

const SnoozeMenu = styled(Menu)`
  width: 300px;
`;
const NotificationReminder: FC<Props> = ({
  item,
  isCollapsed,
  onDataChange,
  highlightId,
}) => {
  const { isDesktop, isDesktopLarge } = useWindowSize();
  const [collapse, setCollapse] = useState<boolean>(false);
  const snoozeNotification = async (
    id: number,
    snoozedDate: string,
    type: string | null,
  ) => {
    type = type || null;
    const value = {
      id: id,
      status: NotificationStatusEnum.SNOOZED,
      snoozedDate: snoozedDate,
      type: type,
    };
    onDataChange(value);
    setCollapse(false);
  };
  const isToday =
    item.type === ReminderTypeEnum.NOTIFICATION &&
    item.payload.reminderDate &&
    moment(item.payload.reminderDate).format('YYYY-MM-DD') ==
      moment().format('YYYY-MM-DD');
  const menu = (
    <SnoozeMenu
      onClick={(e) => {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
      }}>
      {item.type === ReminderTypeEnum.NOTIFICATION && (
        <>
          <Menu.ItemGroup title="Snooze this until">
            <Menu.Item
              onClick={() => {
                snoozeNotification(
                  item.payload.id,
                  moment().add(1, 'days').format('YYYY-MM-DD'),
                  NotificationTypeEnum.NOTE,
                );
              }}>
              <Div display="flex" justifyContent="space-between">
                <B type="b-small"> Tomorrow </B>
                <B type="b-small-medium" color={theme.gray600}>
                  {moment().add(1, 'days').format('MMMM Do')} - 08:00 AM
                </B>
              </Div>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                snoozeNotification(
                  item.payload.id,
                  moment().day(7).format('YYYY-MM-DD'),
                  NotificationTypeEnum.NOTE,
                );
              }}>
              <Div display="flex" justifyContent="space-between">
                <B type="b-small"> This Weekend</B>
                <B type="b-small-medium" color={theme.gray600}>
                  {moment().day(7).format('MMMM Do')}- 08:00 AM
                </B>
              </Div>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.Divider />
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}>
            <DatePickerComp
              size="small"
              bordered={false}
              placeholder="Pick a Date"
              onChange={(e) => {
                snoozeNotification(
                  item.payload.id,
                  moment(e.toDate()).format('YYYY-MM-DD'),
                  NotificationTypeEnum.NOTE,
                );
              }}
            />
          </div>
        </>
      )}
    </SnoozeMenu>
  );
  return (
    <>
      {item.type === ReminderTypeEnum.NOTIFICATION && (
        <Div
          width="100%"
          background={
            highlightId ? theme.gray100 : collapse ? theme.gray100 : theme.white
          }
          borderRadius="4px">
          <Row
            onClick={() => {
              if (collapse) {
                setCollapse(false);
              } else {
                setCollapse(true);
              }
            }}>
            <Div p="12px 10px" display="flex" width="100%">
              <Col span={19}>
                <Div display="flex">
                  <img src={reminderSvg} width={22} height={22} />
                  {isCollapsed ? (
                    <B type="b-small" pl="10px">
                      Reminder
                    </B>
                  ) : item.payload.type === NotificationTypeEnum.GRATUITY ? (
                    <B type="b-small" pl="10px">
                      {!collapse
                        ? isDesktopLarge
                          ? addDotsForLongText(
                              `Reminder "${item.payload.note}"`,
                              100,
                            )
                          : isDesktop
                          ? addDotsForLongText(
                              `Reminder "${item.payload.note}"`,
                              60,
                            )
                          : `Reminder "${item.payload.note}"`
                        : `Reminder "${item.payload.note}"`}
                    </B>
                  ) : item.payload.type === NotificationTypeEnum.NOTE ? (
                    <B type="b-small" pl="10px">
                      {!collapse
                        ? isDesktopLarge
                          ? addDotsForLongText(
                              `Reminder "${item.payload.user.fullName}'s ${
                                item.payload.note
                              } ”
                    ${isToday ? 'is today' : ''}`,
                              100,
                            )
                          : isDesktop
                          ? addDotsForLongText(
                              `Reminder "${item.payload.user.fullName}'s ${
                                item.payload.note
                              } ”
                  ${isToday ? 'is today' : ''}`,
                              60,
                            )
                          : `Reminder "${item.payload.user.fullName}'s ${
                              item.payload.note
                            } ”
                    ${isToday ? 'is today' : ''}`
                        : `Reminder "${item.payload.user.fullName}'s ${
                            item.payload.note
                          } ”
                    ${isToday ? 'is today' : ''}`}
                    </B>
                  ) : item.payload.type === NotificationTypeEnum.ONBOARDING ? (
                    <B type="b-small" pl="10px">
                      {!collapse
                        ? isDesktopLarge
                          ? addDotsForLongText(`${item.payload.note}`, 100)
                          : isDesktop
                          ? addDotsForLongText(`${item.payload.note}`, 60)
                          : `${item.payload.note}`
                        : `${item.payload.note}`}
                    </B>
                  ) : (
                    <B type="b-small" pl="10px">
                      {!collapse
                        ? isDesktopLarge
                          ? addDotsForLongText(item.payload.note, 100)
                          : isDesktop
                          ? addDotsForLongText(item.payload.note, 60)
                          : item.payload.note
                        : item.payload.note}
                    </B>
                  )}
                </Div>
              </Col>
              <Col span={5}>
                <Div display="flex" justifyContent="flex-end">
                  <>
                    {item.payload.status == NotificationStatusEnum.DONE ? (
                      <NameTag
                        textColor={theme.green300}
                        color={theme.green50}
                        styleCSS={`
                    width:46px ;
                    border-radius: 4px;
                    text-align: center;
                    height: 20px;
                    padding: 0px 8px;
                  `}>
                        Done
                      </NameTag>
                    ) : (
                      item.payload.status == NotificationStatusEnum.SNOOZED && (
                        <NameTag
                          textColor={theme.orange300}
                          color={theme.lightYellow50}
                          styleCSS={`
                    width:62px ;
                    border-radius: 4px;
                    text-align: center;
                    height: 20px;
                    padding: 0px 8px;
                  `}>
                          Snoozed
                        </NameTag>
                      )
                    )}
                  </>
                </Div>
              </Col>
            </Div>
          </Row>
          {collapse && (
            <>
              {item.payload.status != 'DONE' && (
                <Div
                  display="flex"
                  flexDirection="row"
                  m="0px 42px 16px"
                  width="100%">
                  {item.payload.type !== NotificationTypeEnum.GRATUITY && (
                    <B
                      type="b-small"
                      pr="24px"
                      color={theme.blue500}
                      onClick={async () => {
                        const value = {
                          id: item.payload.id,
                          status: NotificationStatusEnum.DONE,
                        };
                        onDataChange(value);
                        setCollapse(false);
                      }}>
                      Done
                    </B>
                  )}

                  {item.payload.type === NotificationTypeEnum.GRATUITY ||
                  item.payload.type === NotificationTypeEnum.T10 ? (
                    <a
                      onClick={() => {
                        snoozeNotification(
                          item.payload.id,
                          moment().utc().format('YYYY-MM-DD'),
                          item.payload.type === NotificationTypeEnum.GRATUITY
                            ? NotificationTypeEnum.GRATUITY
                            : NotificationTypeEnum.T10,
                        );
                      }}>
                      <B type="b-small" color={theme.blue500}>
                        Mark as read
                      </B>
                    </a>
                  ) : (
                    <Dropdown overlay={menu}>
                      <a onClick={(e) => e.preventDefault()}>
                        <B type="b-small" color={theme.blue500}>
                          Snooze
                        </B>
                      </a>
                    </Dropdown>
                  )}
                </Div>
              )}
            </>
          )}
        </Div>
      )}
    </>
  );
};

export default NotificationReminder;
