import styled from '@emotion/styled';
import React, { FC, ReactNode } from 'react';

interface Props {
  JsonConfig: ReactNode;
}
const JsonViewDiv = styled.pre`
  background: #f4f5f7;
  height: 448px;
  padding: 10px 24px;
  overflow-y: scroll;
  max-height: 448px;
`;
const JsonView: FC<Props> = ({ JsonConfig }) => {
  return <JsonViewDiv>{JSON.stringify(JsonConfig, undefined, 3)}</JsonViewDiv>;
};

export default JsonView;
