import { Col, Form, Row, message } from 'antd';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { B, InputComp, ModalComponent, SelectComp } from '../../../components';
import DragAndDrop from '../../../components/dropzone/DragAndDrop';
import { DataImportConfig, pastYearEnableOrg } from '../../../configs';
import { UploadTimeSheetI } from '../../../types';
import { PayrunType } from '../../../types/payroll.types';
import { monthValues } from '../../../util/months';
import { validateOrganizationIDs } from '../../../util/validateOrganizationIDs.util';
import { CustomizationEnum } from '../../../types/organization.types';
import { useUploadTimeSheet } from '../../../api/attendanceHooks';
import { Validation } from '../../../common/validation';

interface Props {
  onClose: () => void;
  refetchTimeAttendance: () => void;
  onSubmit: (v: UploadTimeSheetI) => void;
  visible: boolean;
  buttonDisabled?: boolean;
  extractorInfo?: DataImportConfig;
}
const TimesheetModal: FC<Props> = ({
  onClose,
  visible,
  extractorInfo,
  refetchTimeAttendance,
  onSubmit,
}) => {
  const [isFile, setIsFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [payrunYearOptions, setPayrunYearOptions] = useState<
    {
      label: number;
      value: number;
    }[]
  >([]);

  const {
    isLoading: loadingUploadForDataExtract,
    isSuccess: successUploadTimeSheet,
    mutate: uploadForDataExtract,
  } = useUploadTimeSheet();

  useEffect(() => {
    refetchTimeAttendance();
  }, [successUploadTimeSheet]);

  const handleSubmit = async () => {
    try {
      await form.validateFields(['title', 'payrunType', 'year', 'month']);
      if (!isFile) {
        message.error('Please upload a file');
        return;
      }
      const values = {
        ...form.getFieldsValue(),
        file: selectedFile,
        csvType: CustomizationEnum.TIME_ATTENDANCE_CSV,
        type: extractorInfo?.extractorType,
      };
      handleFinish(values);
    } catch (error) {
      message.error('Please fill all fields');
    }
  };

  const handleFinish = (values: UploadTimeSheetI) => {
    if (selectedFile && extractorInfo) {
      onSubmit(values);
    }
    onClose();
  };

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      const fileName = file.name.slice(0, file.name.lastIndexOf('.'));
      setSelectedFileName(fileName);

      setIsFile(true);
      setIsUploaded(true);
    }
  };

  useEffect(() => {
    const getYearOption = (offset: number) => ({
      label: dayjs().add(offset, 'year').year(),
      value: dayjs().add(offset, 'year').year(),
    });

    const initialOptions = validateOrganizationIDs(pastYearEnableOrg)
      ? [
          getYearOption(-2),
          getYearOption(-1),
          getYearOption(0),
          getYearOption(1),
        ]
      : [getYearOption(-1), getYearOption(0), getYearOption(1)];

    setPayrunYearOptions(initialOptions);
  }, [pastYearEnableOrg]);

  const [form] = Form.useForm();

  return (
    <ModalComponent
      loading={loadingUploadForDataExtract}
      visible={visible}
      onCancel={() => onClose()}
      validateTrigger={['onBlur']}
      width={540}
      onSubmit={handleSubmit}
      submitText="Upload"
      disabled={!isFile}
      title="Upload Timesheet">
      <Form size="small" form={form}>
        <Form.Item
          name="title"
          rules={[
            {
              required: true,
              message: 'Please enter a time sheet title',
            },
            {
              pattern: Validation.AlphanumericPattern,
              message: 'The input is not valid!',
            },
          ]}>
          <InputComp
            size="small"
            id="title"
            name="title"
            label="Title"
            placeholder="Enter Title"
          />
        </Form.Item>
        <Form.Item>
          <B type="b-large-semibold">Link with payrun</B>
        </Form.Item>
        <Form.Item
          name="payrunType"
          rules={[
            {
              required: true,
              message: 'Please choose a payrun type',
            },
          ]}>
          <SelectComp
            size="middle"
            label="Payrun Type"
            options={[
              {
                label: 'Mid-Cycle Payrun',
                value: PayrunType.MID_CYCLE_PAYRUN,
              },
              {
                label: 'Monthly Payrun',
                value: PayrunType.MONTHLY_PAYRUN,
              },
            ]}
            placeholder="Select payrun type"
          />
        </Form.Item>
        <Row gutter={[32, 24]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="year"
              rules={[
                {
                  required: true,
                  message: 'Please choose a payrun year',
                },
              ]}>
              <SelectComp
                size="middle"
                label="Year"
                showSearch
                placeholder="Choose year"
                options={payrunYearOptions}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="month"
              rules={[
                {
                  required: true,
                  message: 'Please choose a payrun month',
                },
              ]}>
              <SelectComp
                size="middle"
                label="Month"
                showSearch
                placeholder="Choose month"
                options={monthValues}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="file"
          rules={[
            {
              required: true,
              message: 'Please select a file',
            },
          ]}>
          <DragAndDrop
            accept=".csv"
            onDrop={handleDrop}
            isUploaded={isUploaded}
          />
        </Form.Item>
      </Form>
    </ModalComponent>
  );
};

export default TimesheetModal;
