import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

import { LeaveBalancesI } from '../../../types';
import { camelCaseToNormalText } from '../../../util/camelCaseToNormal.util';
import { centerTexts } from '../../../util/exceljs.util';
import getNormalText from '../../../util/getNormalText';
import { getHoursDaysFromHours } from './dateAndTime.util';

export const generateLeaveBalanceExcel = (
  dataList: LeaveBalancesI[],
  leaveItemColumns: string[],
) => {
  if (isEmpty(dataList)) return null;

  const dataPayload = dataList || [];

  const workbook = new ExcelJS.Workbook();

  const worksheet = workbook.addWorksheet('Leave_Balance');

  const identityColumns = [
    { key: 'employeeNumber', width: 15 },
    { key: 'fullName', width: 25 },
    { key: 'employeeType', width: 25 },
  ];
  const leaveColumns = [
    { key: 'totalLeave', width: 20 },
    { key: 'taken', width: 20 },
    { key: 'balance', width: 20 },
  ];

  const categoryColumns = [];
  let counter = 0;
  const mainColumns = leaveItemColumns.slice(0, 2).reverse();
  const restColumns = leaveItemColumns.slice(2);
  const mainLeaveColumns = [...mainColumns, ...restColumns];
  for (let i = 0; i < mainLeaveColumns.length * leaveColumns.length; i++) {
    categoryColumns.push({
      key: `${counter}`,
      width: 20,
    });
    counter++;
  }
  const sheetColumns = [...identityColumns, ...categoryColumns];
  worksheet.columns = sheetColumns;

  // column groups starting cells
  const annualLeaveInfoCell = identityColumns.length;
  const casualLeaveInfoCell = identityColumns.length + leaveColumns.length;
  const sickLeaveInfoCell = casualLeaveInfoCell + leaveColumns.length;
  const noPayLeaveInfoCell = sickLeaveInfoCell + leaveColumns.length;
  const studyLeaveInfoCell = noPayLeaveInfoCell + leaveColumns.length;
  const trainingLeaveInfoCell = studyLeaveInfoCell + leaveColumns.length;
  const workOnHolidayInfoCell = trainingLeaveInfoCell + leaveColumns.length;
  const maternityLeaveInfoCell = workOnHolidayInfoCell + leaveColumns.length;
  const values = [];
  //Main headings
  values[0] = 'EMP ID';
  values[1] = 'FullName';
  values[2] = 'Employee Type';

  let columIndex = identityColumns.length;
  for (const leaveItem of mainLeaveColumns) {
    values[columIndex] = getNormalText(leaveItem);
    columIndex += leaveColumns.length;
  }

  const firstRow = worksheet.addRow(values);
  worksheet.mergeCells(1, annualLeaveInfoCell + 1, 1, casualLeaveInfoCell);
  worksheet.mergeCells(1, casualLeaveInfoCell + 1, 1, sickLeaveInfoCell);
  worksheet.mergeCells(1, sickLeaveInfoCell + 1, 1, noPayLeaveInfoCell);
  worksheet.mergeCells(1, noPayLeaveInfoCell + 1, 1, studyLeaveInfoCell);
  worksheet.mergeCells(1, studyLeaveInfoCell + 1, 1, trainingLeaveInfoCell);
  worksheet.mergeCells(1, trainingLeaveInfoCell + 1, 1, workOnHolidayInfoCell);
  worksheet.mergeCells(1, workOnHolidayInfoCell + 1, 1, maternityLeaveInfoCell);
  worksheet.mergeCells(
    1,
    maternityLeaveInfoCell + 1,
    1,
    maternityLeaveInfoCell + leaveColumns.length,
  );

  centerTexts(firstRow, [
    annualLeaveInfoCell + 1,
    casualLeaveInfoCell + 1,
    sickLeaveInfoCell + 1,
    noPayLeaveInfoCell + 1,
    studyLeaveInfoCell + 1,
    trainingLeaveInfoCell + 1,
    workOnHolidayInfoCell + 1,
    maternityLeaveInfoCell + 1,
  ]);

  const subTitles: { [key: string]: string } = {};

  const userRows = [];
  let limit = 0;

  for (const column of categoryColumns) {
    if (limit > leaveColumns.length - 1) {
      limit = 0;
    }
    subTitles[column.key] = camelCaseToNormalText(leaveColumns[limit].key);
    limit++;
  }
  //Adding subtitle row
  worksheet.addRow(subTitles);

  worksheet.getRow(1).font = { bold: true };

  identityColumns.forEach((_cell, i) => {
    worksheet.mergeCells(1, i + 1, 2, i + 1);
  });

  //Forming leave  rows
  for (const leave of dataPayload) {
    const leaveRow: { [key: string]: string } = {};
    leaveRow['employeeNumber'] = get(leave, 'employeeNumber', '_');
    leaveRow['fullName'] = get(leave, 'fullName', '-');
    leaveRow['employeeType'] = get(leave, 'employeeType', '-');
    userRows.push(leaveRow);

    let counter = 0;
    for (const leaveItem of mainLeaveColumns) {
      const found = leave.leaveBalances.find(
        (item) => item.leaveName === leaveItem,
      );
      if (found) {
        leaveRow[counter++] = getHoursDaysFromHours(found.totalLeave).text;
        leaveRow[counter++] = getHoursDaysFromHours(found.taken).text;
        leaveRow[counter++] = getHoursDaysFromHours(found.balance).text;
      } else {
        counter = counter + 4;
      }
    }
  }
  worksheet.addRows(userRows);

  //Adding border to all cells
  worksheet.eachRow(function (row, _rowNumber) {
    sheetColumns.forEach((_item, i) => {
      const cell = row.getCell(i + 1);
      cell.border = {
        top: { style: 'thin', color: { argb: '00000000' } },
        left: { style: 'thin', color: { argb: '00000000' } },
        bottom: { style: 'thin', color: { argb: '00000000' } },
        right: { style: 'thin', color: { argb: '00000000' } },
      };
    });
  });

  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `Leave_Balance_${moment().format('DD-MM-YYYY')}.xlsx`,
    );
  });
};
