import React, { FC } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { ModalProps } from 'antd/lib/modal';
import { space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';
import theme from '../theme';
import { GhostButton } from './buttons/GhostButton';
import { LinkButton } from './buttons/LinkButton';
import { PrimaryButton } from './buttons/PrimaryButton';
import { DeleteModal } from './PopupModals';
import { RemoveBlueSVG } from '../assets';

export const ModalSection = styled(Modal)<SpaceProps>`
  .ant-modal-footer {
    padding: 16px 24px;
    background: ${theme.gray50};
    border-radius: 0px 0px 4px 4px;
  }
  .ant-modal-content {
    border-radius: 4px;
    padding: 0px;
  }
  .ant-modal-header {
    border-bottom: 1px solid ${theme.gray200};
    padding: 16px 24px;
    border-radius: 4px 4px 0px 0px;
    margin-bottom: 0px;
  }
  .ant-modal-body {
    padding: 24px;
    ${space}
  }
`;

const DeleteButton = styled(Button)`
  background-color: ${theme.red600} !important;
  border-color: ${theme.red600} !important;
  font-size: 14px;
  border-radius: 4px;
  height: 40px;
`;

type Props = ModalProps &
  SpaceProps & {
    submitText?: string;
    redColorSubmitButton?: boolean;
    removeText?: string;
    loading?: boolean;
    disabled?: boolean;
    onCancel: () => void;
    onSubmit?: () => void;
    onRemove?: () => void;
    form?: FormInstance;
    hideCancelButton?: boolean;
    hideRemoveButton?: boolean;
    deleteModalTitle?: string;
    deleteModalContent?: string;
    deleteModalButtonText?: string;
  };

export const ModalComponent: FC<Props> = ({
  submitText,
  redColorSubmitButton,
  removeText,
  loading,
  disabled,
  onCancel,
  onSubmit,
  onRemove,
  hideCancelButton,
  hideRemoveButton = true,
  deleteModalTitle,
  deleteModalContent,
  deleteModalButtonText,
  form,
  ...otherProps
}) => {
  const onOk = () => {
    form && form.submit();
  };

  return (
    <ModalSection
      centered
      onSubmit={onSubmit}
      onCancel={onCancel}
      footer={[
        <>
          <Row>
            <Col span={10}>
              <Row justify="start">
                {deleteModalTitle &&
                deleteModalButtonText &&
                deleteModalContent ? (
                  <DeleteModal
                    title={deleteModalTitle}
                    buttonLabel={deleteModalButtonText}
                    content={deleteModalContent}
                    onClickCancel={onRemove}
                    openModal={
                      <LinkButton
                        hidden={hideRemoveButton}
                        icon={
                          <img width="16px" height="20px" src={RemoveBlueSVG} />
                        }>
                        {removeText}
                      </LinkButton>
                    }
                  />
                ) : null}
              </Row>
            </Col>
            <Col span={14}>
              <GhostButton
                onClick={() => onCancel()}
                hidden={hideCancelButton}
                ml="16px">
                Cancel
              </GhostButton>
              {redColorSubmitButton ? (
                <DeleteButton
                  key="submit"
                  type="primary"
                  id="delete-button"
                  onClick={onSubmit || onOk}>
                  {submitText}
                </DeleteButton>
              ) : (
                <PrimaryButton
                  disabled={disabled}
                  onClick={onSubmit || onOk}
                  htmlType="submit"
                  loading={loading}>
                  {submitText}
                </PrimaryButton>
              )}
            </Col>
          </Row>
        </>,
      ]}
      {...otherProps}>
      {otherProps.children}
    </ModalSection>
  );
};
