import { InputNumber, Input, Form } from 'antd';
import { EditableCellProps } from '../types/editableTable.types';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Validation } from '../common/validation';
import { TimePickerComp } from './TimePickerComp';
import dayjs, { Dayjs } from 'dayjs';
import { dayJsFromMinutes } from '../util/timeConvertion';
import { isValidMoment } from '../util/isValidMoment';

const FormItem = styled(Form.Item)`
  margin-bottom: 0px;

  .ant-form-item-control-input {
    width: 85px;
  }
`;

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  defaultVal,
  onChange,
  maxOtHours,
  roundOff,
  ...restProps
}) => {
  const [value, setValue] = useState<Dayjs>(defaultVal);

  let minStep: 10 | 15 | 30 = null;
  let hideMins = false;
  switch (roundOff) {
    case 30:
      minStep = 30;
      break;
    case 15:
      minStep = 15;
      break;
    case 60:
      hideMins = true;
      break;
  }

  const disabledTime = () => {
    if (maxOtHours !== undefined) {
      return {
        disabledHours: () => {
          const disabledHours = [];
          for (let i = maxOtHours + 1; i < 24; i++) {
            disabledHours.push(i);
          }
          return disabledHours;
        },
        disabledMinutes: (selectedHour: number) => {
          if (selectedHour === maxOtHours) {
            const disabledMinutes = [];
            for (let i = 1; i < 60; i++) {
              disabledMinutes.push(i);
            }
            return disabledMinutes;
          } else {
            return [];
          }
        },
      };
    }
  };

  const handleTimeChange = (time) => {
    setValue(time);
  };
  const inputNode =
    inputType === 'number' ? (
      <InputNumber />
    ) : inputType === 'time' ? (
      <TimePickerComp
        value={value}
        size="small"
        onChange={handleTimeChange}
        defaultValue={defaultVal}
        disabledTime={title === 'No Pay Hours' ? disabledTime : null}
        minuteStep={minStep}
        showNow={false}
        format={hideMins ? 'HH' : 'HH:mm'}
      />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <FormItem
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `Please enter number of ${title.toLowerCase()}!`,
            },
          ]}>
          {inputNode}
        </FormItem>
      ) : (
        children
      )}
    </td>
  );
};
