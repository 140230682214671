import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { B } from '../../../components';
import { disableSickleavesOrg } from '../../../configs/appConfig';
import theme from '../../../theme';
import { LeaveBalance } from '../../../types';
import { getCurrentAuthPayload } from '../../../util/storageUtil';
import {
  getHoursDaysFromHours,
  useGetLeaveStatus,
  getLeaveBalance,
} from '../utils';

const LeaveDetailsSection = styled.div`
  background: ${theme.gray50};
  border-radius: 4px;
  padding: 16px;
  border: 1px solid ${theme.gray300};
  margin: 0px 0px 16px !important;
`;
const RowContainer = styled(Row)`
  margin-bottom: 14px;
`;
interface Props {
  leaveSummery: LeaveBalance[] | null;
  type?: string | null;
}

const RowItem = ({ title, value }: { value: number; title: string }) => {
  return (
    <Row>
      <Col span={12}>
        <B type="b-small" color={theme.gray600}>
          {title}
        </B>
      </Col>
      <Col span={12}>
        <B type="b-small" textAlign="right">
          {value !== 0 ? getHoursDaysFromHours(value).text : '-'}
        </B>
      </Col>
    </Row>
  );
};

const LeaveDetails: FC<Props> = ({ leaveSummery = [], type = null }) => {
  const leaveStatus = useGetLeaveStatus(leaveSummery || []);

  return type ? (
    <RowContainer>
      <B type="b-small" color={theme.gray600}>
        {getLeaveBalance(type, leaveStatus)}
      </B>
    </RowContainer>
  ) : (
    <>
      <LeaveDetailsSection>
        <Row>
          <B mb="8px" type="b-small-semibold">
            Leave Balance
          </B>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <RowItem title="Annual Leave" value={leaveStatus.annualLeaves} />
            <RowItem title="Casual Leave" value={leaveStatus.casualLeaves} />
            {/* FIXME */}
            {getCurrentAuthPayload()?.organizationId !==
            disableSickleavesOrg ? (
              <RowItem title="Sick Leave" value={leaveStatus.sickLeaves} />
            ) : null}{' '}
            {leaveStatus.noPay ? (
              <RowItem title="No Pay Leave" value={leaveStatus.noPay} />
            ) : null}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {leaveStatus.studyLeave ? (
              <RowItem title="Study Leave" value={leaveStatus.studyLeave} />
            ) : null}
            {leaveStatus.trainingLeave ? (
              <RowItem
                title="Training Leave"
                value={leaveStatus.trainingLeave}
              />
            ) : null}
            {leaveStatus.workOnHoliday ? (
              <RowItem
                title="Work On Holiday"
                value={leaveStatus.workOnHoliday}
              />
            ) : null}
            {leaveStatus.maternityLeave ? (
              <RowItem
                title="Maternity Leave"
                value={leaveStatus.maternityLeave}
              />
            ) : null}
            {leaveStatus.lieuLeave ? (
              <RowItem title="Lieu Leave" value={leaveStatus.lieuLeave} />
            ) : null}
          </Col>
        </Row>
      </LeaveDetailsSection>
    </>
  );
};

export default LeaveDetails;
