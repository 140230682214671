import { ReactElement } from 'react';
import dayjs from 'dayjs';

export type EmptyTypes = string | null | undefined | ReactElement;

export const isValidMoment = (
  date: string | null | undefined,
  defaultEmptyValue: EmptyTypes,
  format?: string,
) => {
  if (format) {
    return dayjs(date).isValid()
      ? dayjs(date).format(format)
      : defaultEmptyValue;
  } else {
    return dayjs(date).isValid() ? dayjs(date) : defaultEmptyValue;
  }
};
