import React, { FC, useEffect } from 'react';
import { Breadcrumb, Select, SelectProps } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizations } from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import styled from '@emotion/styled';
import { addDotsForLongText } from '../../../util/utils';
import theme from '../../../theme';
import { DropdownBlue, ForwardArrowSVG } from '../../../assets';

const { Option } = Select;
interface Props {
  owner: number;
  onSelect: (index: number) => void;
}
const BreadcrumbComponent = styled(Breadcrumb)`
  font-size: 16px;
  margin-bottom: 17px;
  .ant-breadcrumb-overlay-link > .anticon {
    color: ${theme.blue500} !important;
  }
` as any;
const SelectComponent = styled(Select)<SelectProps>`
  min-width: 180px;
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
  .ant-select-arrow {
    margin-top: -9px;
  }
  .ant-select-selection-item {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.blue500};
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0px !important;
  }
`;

const OrganizationBreadcrumb: FC<Props> = ({ owner, onSelect }) => {
  const dispatch: DispatchType = useDispatch();
  useEffect(() => {
    dispatch(getOrganizations());
  }, []);
  const organizations = useSelector(
    (state: RootState) => state.superAdmin.organizations,
  );
  const options = organizations
    .map((item) => {
      return {
        value: item.userAccountId,
        label: addDotsForLongText(item.fullName || '', 30),
      };
    })
    .sort((a, b) => (a.label > b.label ? 1 : -1));
  if (!options.length) {
    return null;
  }

  return (
    <BreadcrumbComponent separator={<ForwardArrowSVG />}>
      <Breadcrumb.Item>
        <Link to="/super-admin">Dashboard</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <SelectComponent
          showSearch
          optionFilterProp="children"
          value={owner}
          onSelect={(v) => {
            onSelect(Number(v));
          }}
          suffixIcon={<img src={DropdownBlue} />}>
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </SelectComponent>
      </Breadcrumb.Item>
    </BreadcrumbComponent>
  );
};

export default OrganizationBreadcrumb;
