//import 'antd/dist/antd.css'; // TODO: do we really need this
import React, { FC, useState } from 'react';
import { Col, Form, Image, Modal, Row, Typography } from 'antd';
import { css } from '@emotion/react';
import {
  B,
  InputComp,
  PrimaryButton,
  SecondaryButton,
  SelectComp,
  LinkButton,
  InputNumberComp,
} from '../../../components';
import {
  AddCircleSVG,
  MinusSVG,
  PlusSVG,
  RemoveSVG,
  ResetSVG,
} from '../../../assets';

const { Text } = Typography;

const dataSource: PersonalSalaryTableI[] = [
  {
    key: '1',
    description: 'Monthly Basic Pay',
    amount: 40000.0,
    remove: '1',
  },
  {
    key: '3',
    description: 'Travel Allowance',
    amount: 10000.0,
    remove: '3',
  },
  {
    key: '4',
    description: 'Salary Advance',
    amount: -10000.0,
    remove: '4',
  },
  {
    key: '5',
    description: 'Gross',
    amount: 65000.0,
    remove: '5',
  },
  {
    key: '6',
    description: 'EPF',
    amount: 5200.0,
    remove: '6',
  },
];

interface PersonalSalaryTableI {
  key: string;
  description: string;
  amount: number;
  remove: string;
}
interface Props {
  visible: boolean;
  onClose: () => void;
}
const EditEmployeeModal: FC<Props> = ({ visible, onClose }) => {
  const [dataArr, setDataArr] = useState<PersonalSalaryTableI[]>(dataSource);

  return (
    <>
      <Modal
        title="Edit Salary Information"
        centered
        open={visible}
        width={600}
        css={css`
          .ant-modal-header {
            border-radius: 8px;
          }
          .ant-modal-content {
            border-radius: 8px;
          }
          .ant-modal-title {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.017px;
            color: #0052ea;
          }
          .ant-modal-footer {
            display: none;
          }
        `}>
        <Form>
          {dataArr.map((data) => (
            <>
              <Row gutter={[0, 16]}>
                <Col
                  flex="auto"
                  css={css`
                    padding: 5px 0px;
                  `}>
                  <Image
                    width="14px"
                    height="14px"
                    preview={false}
                    src={PlusSVG}
                    css={css`
                      margin: 0px 10px;
                    `}
                  />
                  <B type="b-large" display="inline-block" pl="30px">
                    {data.description}
                  </B>
                </Col>
                <Col
                  flex="180px"
                  css={css`
                    display: flex;
                    .ant-image {
                      margin-left: 20px;
                      margin-right: 10px;
                      margin-top: 6px;
                      cursor: pointer;
                    }
                  `}>
                  <InputNumberComp isCurrency={true} value={data.amount} />
                  <Image
                    width="28px"
                    height="28px"
                    preview={false}
                    src={RemoveSVG}
                    onClick={() => {
                      const newArr = dataArr.filter(
                        (item) => item.key != data.key,
                      );
                      setDataArr(newArr);
                    }}
                  />
                </Col>
              </Row>
            </>
          ))}
          <Form.List name="add">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <>
                    <Row gutter={[0, 16]}>
                      <Col
                        span={14}
                        css={css`
                          display: flex;
                          flex-direction: row;
                        `}>
                        <Image
                          width="12px"
                          height="12px"
                          preview={false}
                          src={PlusSVG}
                          css={css`
                            margin: 5px 10px;
                          `}
                        />
                        <SelectComp
                          placeholder="Select"
                          options={[
                            {
                              label: 'Allowance',
                              value: '1',
                            },
                            {
                              label: 'Conveyance Allowance ',
                              value: '2',
                            },
                            {
                              label: 'Dearness Allowance',
                              value: '3',
                            },
                            {
                              label: 'Deduction',
                              value: '4',
                            },
                            {
                              label: 'House Rent Allowance',
                              value: '5',
                            },
                            {
                              label: 'Information',
                              value: '6',
                            },
                            {
                              label: 'Medical Allowance',
                              value: '7',
                            },
                            {
                              label: 'Ignore',
                              value: '8',
                            },
                          ]}
                          css={css`
                            width: 200px;
                          `}
                        />
                      </Col>
                      <Col
                        span={10}
                        css={css`
                          display: flex;
                          flex-direction: row;
                        `}>
                        <InputComp />
                        <Image
                          width="16px"
                          height="20px"
                          preview={false}
                          src={RemoveSVG}
                          onClick={() => remove(field.name)}
                          css={css`
                            margin: 5px 10px;
                          `}
                        />
                      </Col>
                    </Row>
                  </>
                ))}

                <Form.Item>
                  <B type="b-large" color="#006AFF" onClick={() => add()}>
                    <Image
                      preview={false}
                      src={AddCircleSVG}
                      css={css`
                        width: 18px;
                        height: 18px;
                        margin-left: 10px;
                        margin-right: 10px;
                        margin-top: -2px;
                      `}
                    />
                    Add New Earning or Deduction
                  </B>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Row
            gutter={[0, 16]}
            css={css`
              border-top: 1px solid #e0e0e0;
              padding-top: 16px;
            `}>
            <Col
              span={14}
              css={css`
                padding: 5px 20px;
              `}>
              <Text>Salary Advance</Text>
            </Col>
            <Col
              span={9}
              css={css`
                display: flex;
                flex-direction: row;
              `}>
              <InputComp value="2000" disabled />
            </Col>
          </Row>
          <Row gutter={[0, 32]}>
            <Col
              span={14}
              css={css`
                padding: 5px 0px;
              `}>
              <Image
                width="12px"
                height="12px"
                preview={false}
                src={MinusSVG}
                css={css`
                  margin: 0px 10px;
                `}
              />
              <Text>EPF 8%</Text>
            </Col>
            <Col
              span={10}
              css={css`
                display: flex;
                flex-direction: row;
              `}>
              <InputComp value="2000.00" />
              <Image
                width="16px"
                height="20px"
                preview={false}
                src={RemoveSVG}
                css={css`
                  margin: 5px 10px;
                `}
              />
            </Col>
          </Row>
          <Row
            gutter={[0, 16]}
            css={css`
              background: #fafafa;
            `}>
            <Col
              span={14}
              css={css`
                padding: 5px 20px;
              `}>
              <Text
                css={css`
                  font-family: Inter;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: -0.1px;
                  color: #000000;
                `}>
                Net Pay
              </Text>
            </Col>
            <Col
              span={9}
              css={css`
                display: flex;
                flex-direction: row;
              `}>
              <Text
                css={css`
                  font-family: Inter;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: -0.1px;
                  color: #000000;
                `}>
                54,800.00
              </Text>
            </Col>
          </Row>
        </Form>
        <Row
          css={css`
            border-top: 1px solid #e0e0e0;
            margin: 16px 0px 0px;
            padding: 24px 0px;
          `}>
          <Col span={4}>
            <SecondaryButton onClick={() => onClose()}>Cancel</SecondaryButton>
          </Col>
          <Col span={12}>
            <LinkButton
              icon={<img width="12px" height="12px" src={ResetSVG} />}>
              Reset
            </LinkButton>
          </Col>
          <Col span={8}>
            <PrimaryButton>Save and Continue</PrimaryButton>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default EditEmployeeModal;
