import React, { ReactNode } from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { EmptyPage } from './EmptyComponent';

export enum CustomizedFeatures {
  LOAN = 'isEnableLoan',
  PAYROLL = 'isEnablePayroll',
  LEAVE = 'isEnableLeave',
  EXPENSES = 'isEnableExpenses',
  GRATUITY = 'isEnableGratuity',
  REPORT = 'isEnableReport',
  CONTRACT = 'isEnableContract',
  TIMEATTENDANCE= 'isEnableTimeAttendance',
}


interface Props {
  organizationPermission?: CustomizedFeatures ;
  children: ReactNode;
}

export const CustomFeature: FC<Props> = ({
  organizationPermission,
  children,
}) => {
  const me = useSelector((state: RootState) => state.people.me);
  const permission = me?.organization?.customizedFeatures[organizationPermission];


  if (permission) {
    return <>{children}</>;
  } else {
    return <EmptyPage 
    status='403'
    content="Sorry, you are not authorized to access this page."
        title="Unauthorized access !"
        buttonLabel="Back Home"
    />;
  }
};
