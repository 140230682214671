import { message } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { DataImportUserPayLoad, LogTrailItemI } from '../store/actions';
import { UploadTimeSheetI } from '../types';
import { getApiError } from '../util/getApiError';

import {
  IAttendanceData,
  IAttendanceItemsData,
  IAttendanceURLData,
  IDateModalData,
  IIDs,
  IUpdateStatus,
  IUserAttendanceItem,
  IViewData,
  IExistingAttendanceData,
  AttendancePeriodI,
} from '../types/attendance.types';
import { AttendanceDocumentDetailsI } from '../types/document.types';

export const useUploadTimeSheet = () => {
  return useMutation<DataImportUserPayLoad[], AxiosError, UploadTimeSheetI>(
    async (data: UploadTimeSheetI) => {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const response: AxiosResponse<DataImportUserPayLoad[]> = await axios.post(
        `/time-attendance`,
        formData,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Time sheet uploaded successfully!');
      },
    },
  );
};

export const useGetAttendanceData = () => {
  return useQuery<IAttendanceData[], AxiosError>(
    'attendance-list',
    async () => {
      const response: AxiosResponse<IAttendanceData[]> = await axios.get(
        '/time-attendance',
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};
export const useGetAttendanceItemData = () => {
  return useMutation<IViewData, AxiosError, string>(
    async (attendanceId: string) => {
      const response: AxiosResponse<IViewData> = await axios.get(
        `/time-attendance/${attendanceId}`,
      );
      return response.data;
    },
  );
};
export const useGetUserAttendanceItemData = () => {
  return useMutation<IAttendanceItemsData, AxiosError, IIDs>(
    async (data: IIDs) => {
      const response: AxiosResponse<IAttendanceItemsData> = await axios.get(
        `/time-attendance/list/${data.id}/${data.employeeId}`,
      );
      return response.data;
    },
  );
};

export const useUpdateUserAttendanceData = () => {
  return useMutation<string, AxiosError, IUserAttendanceItem>(
    async (data: IUserAttendanceItem) => {
      data.noPayMinutes = data.noPayMinutes;
      data.overtimeMinutes = data.overtimeMinutes;
      const response: AxiosResponse<string> = await axios.put(
        `/time-attendance/${data.id}`,
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useUpdateStatus = () => {
  return useMutation<string, AxiosError, IUpdateStatus>(
    async (data: IUpdateStatus) => {
      const response: AxiosResponse<string> = await axios.put(
        `/time-attendance/status/${data.id}`,
        { employeeIds: data.employeeIds },
      );
      return response.data;
    },
  );
};

export const usePostDate = () => {
  return useMutation<string, AxiosError, IDateModalData>(
    async (data: IDateModalData) => {
      const response: AxiosResponse<string> = await axios.post(
        `/time-attendance/add-attendance-date`,
        data,
      );
      return response.data;
    },
    {
      onSuccess: () => {
        message.success('Date added successfully');
      },
      onError: () => {
        message.error('Failed to add date');
      },
    },
  );
};

export const useGetAttendanceLogs = () =>
  useMutation<LogTrailItemI[], AxiosError>(
    'attendance-logs',
    async () => {
      const response: AxiosResponse<LogTrailItemI[]> = await axios.get(
        '/time-attendance/time-attendance-logs',
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );

export const useGetAttendanceURLData = () => {
  return useMutation<IAttendanceURLData, AxiosError, string>(
    async (attendanceId: string) => {
      const response: AxiosResponse<IAttendanceURLData> = await axios.get(
        `/time-attendance/url-data/${attendanceId}`,
      );
      return response.data;
    },
  );
};

export const useDeleteAttendance = () => {
  return useMutation<string, AxiosError, string>(
    async (attendanceId) => {
      const response: AxiosResponse<string> = await axios.delete(
        `/time-attendance/deleteAttendance/${attendanceId}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully deleted the Attendance.');
      },
    },
  );
};

export const useUnarchiveAttendance = () => {
  return useMutation<string, AxiosError, string>(
    async (attendanceId) => {
      const response: AxiosResponse<string> = await axios.post(
        `/time-attendance/unarchive/${attendanceId}`,
        attendanceId,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully unarchive the Attendance.');
      },
    },
  );
};

export const useGetExistingAttendance = () => {
  return useMutation<IExistingAttendanceData[], AxiosError, AttendancePeriodI>(
    async (data: AttendancePeriodI) => {
      try {
        const response: AxiosResponse<IExistingAttendanceData[]> =
          await axios.get(
            `/time-attendance/existingAttendances/${data.month}/${data.year}`,
          );
        return response.data;
      } catch (error) {
        console.error('Error while getting attendance:', error);
        throw error;
      }
    },
  );
};
