import React, { FC } from 'react';
import { Col, Form, Row } from 'antd';
import Modal, { ModalProps } from 'antd/lib/modal';
import { css } from '@emotion/react';

import {
  B,
  CustomIcon,
  Div,
  H,
  InputComp,
  PrimaryButton,
  SelectComp,
} from '../../../components';
import theme from '../../../theme';
import { useWindowSize } from '../../../util/screenSize';
import { PayrollCard } from '../../../assets';

interface FormValues {
  name: string;
  email: string;
  employeeSize: string;
}

interface Props extends ModalProps {
  onComplete: (values: FormValues) => void;
  onCancel: () => void;
  loading: boolean;
}

const OnBoardModal: FC<Props> = ({ open, onCancel, onComplete, loading }) => {
  const [form] = Form.useForm();
  const { isMobile } = useWindowSize();
  function onFinish(values: FormValues) {
    onComplete(values);
  }

  return (
    <>
      <Modal
        footer={null}
        width={1030}
        open={open}
        destroyOnClose={true}
        onCancel={onCancel}
        css={css`
          .ant-modal-footer {
            border-top: 0;
          }
          .ant-modal-body {
            padding: 0;
          }
        `}>
        <Row gutter={[0, 128]}>
          <Col
            xs={{ span: 24 }}
            lg={{ span: 8 }}
            css={css`
              background-color: ${theme.blue50};
              display: flex;
              justify-content: center;
              align-items: center;
            `}>
            <img src={PayrollCard} />
          </Col>
          <Col
            xs={{ span: 24 }}
            lg={{ span: 16 }}
            css={css`
              padding: 0 60px;
              height: 100%;
            `}>
            <Div display="flex">
              <CustomIcon icon="check.svg" />
              &nbsp;
              {isMobile ? (
                <>
                  <B type="b-default">
                    Top Secret Payroll Features Just Around the Corner!
                  </B>
                  <br />
                  <br />
                  <br />
                </>
              ) : (
                <H type="h5">
                  Top Secret Payroll Features Just <br /> Around the Corner!
                </H>
              )}
            </Div>

            <B type="b-default" color={theme.gray500}>
              Great! Your company’s about to set off on a no-turning-back HR
              growth voyage! With your CSV file uploaded and payroll data mapped
              successfully, it’s all win-win from here. Now, add your details
              here and we’ll email your customized bulk payment file. You’re
              sitting on HRM gold!
            </B>
            <br />
            <br />
            <Row>
              <Col xs={{ span: 24 }} lg={{ span: 20 }}>
                <Form size="small" form={form} onFinish={onFinish}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter company name',
                      },
                    ]}
                    css={css`
                      margin-bottom: 0px;
                      .ant-col-10 {
                        max-width: 100%;
                      }
                    `}>
                    <InputComp
                      label="Company Name"
                      placeholder="Your company name"
                    />
                  </Form.Item>
                  <br />
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Please enter your email',
                      },
                    ]}
                    css={css`
                      margin-bottom: 0px;
                      .ant-col-10 {
                        max-width: 100%;
                      }
                    `}>
                    <InputComp label="Email" placeholder="yourname@mail.com" />
                  </Form.Item>
                  <br />
                  <Form.Item
                    name="compSize"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your company size',
                      },
                    ]}
                    css={css`
                      margin-bottom: 0px;
                      .ant-col-10 {
                        max-width: 100%;
                      }
                    `}>
                    <SelectComp
                      label="Employee Size"
                      placeholder="Select your employee size"
                      options={[
                        { label: '5', value: '5' },
                        { label: '10', value: '10' },
                        { label: '20', value: '20' },
                        { label: '50', value: '50' },
                        { label: '100+', value: '100+' },
                      ]}
                    />
                  </Form.Item>
                  <br />
                  <Form.Item>
                    <PrimaryButton
                      loading={loading}
                      htmlType="submit"
                      block
                      mt={10}>
                      <CustomIcon icon="download.svg" />
                      &nbsp; Download
                    </PrimaryButton>
                  </Form.Item>
                  <Div
                    display="flex"
                    justifyContent="center"
                    textAlign="center">
                    <B type="b-default" color={theme.gray500}>
                      Need any support ? Call on +94777123123
                    </B>
                  </Div>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default OnBoardModal;
