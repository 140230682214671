import React, { FC } from 'react';
//import 'antd/dist/antd.css'; // TODO: do we really need this
import { Col, Divider, Row } from 'antd';
import styled from '@emotion/styled';
import { SalaryItemsByTypeI } from '../../containers/payroll/pages/payslip/utils/getSalaryItemsByType';
import { getPayItemSum } from '../../containers/payroll/utils/getPayItemSum';
import { numberThousandSeparator } from '../../util/utils';
import { B } from '../B';

interface Props {
  salaryItems: SalaryItemsByTypeI;
  title: string;
}
const DividerComp = styled(Divider)`
  margin: 16px 0px 0px;
`;

export const RegulatoryCompliance: FC<Props> = ({ salaryItems, title }) => {
  return (
    <>
      <Row>
        <Col span={6}>
          <B type="b-small-semibold">{title}</B>
        </Col>
        <Col span={18}>
          <Row justify="space-between" align="bottom">
            <Col span={14}>
              {salaryItems.map((item) => {
                return (
                  <Row>
                    <Col span={12}>
                      <B type="b-small">{item.payTitle}</B>
                    </Col>
                    <Col span={12}>
                      <Row justify="end">
                        <B type="b-small">
                          {title != 'Employee' && title != 'WHT'
                            ? numberThousandSeparator(item.amount)
                            : ''}
                        </B>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
            </Col>
            {title != 'Employee' ? (
              <Col span={10}>
                <Row justify="end">
                  <B type="b-small">{getPayItemSum(salaryItems)}</B>
                </Row>
              </Col>
            ) : (
              salaryItems.map((item) => {
                return (
                  <Col span={10}>
                    <Row justify="end">
                      <B type="b-small">
                        {item.amount
                          ? numberThousandSeparator(item.amount)
                          : '0.00'}
                      </B>
                    </Row>
                  </Col>
                );
              })
            )}
          </Row>
        </Col>
      </Row>
      <DividerComp />
    </>
  );
};
