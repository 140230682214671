import React, { FC } from 'react';
import { Dropdown, Menu, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';
import {
  B,
  DeleteModal,
  EmptyTextContainer,
  Image,
  TableComp,
} from '../../../components';
import { TemplatesI } from '../../../types/contract.types';
import { useWindowSize } from '../../../util/screenSize';
import { useNavigate } from 'react-router-dom';
import ThreeDotPopover from '../shared/ThreedotPopover';
import styled from '@emotion/styled';
import { MoreSVG } from '../../../assets';
import theme from '../../../theme';
import { addDotsForLongText } from '../../../util/utils';
const MenuItem = styled(Menu.Item)`
  border-bottom: none;
`;
const Card = styled.div`
  border: 1px solid ${theme.gray300};
  border-radius: 4px;
  cursor: pointer;
  .ant-table-cell {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
`;

const DropdownMenu = styled(Menu)`
  width: 163px;
  padding: 0px;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .ant-dropdown-menu-item {
    padding: 12px 0px 12px 16px;
  }
`;
interface Props {
  tableScrollY?: number;
  templates: TemplatesI[];
  loading: boolean;
  deleteTemplate: (id: string) => void;
}
const TemplatesTable: FC<Props> = ({
  tableScrollY,
  templates,
  loading = false,
  deleteTemplate,
}) => {
  const { isTablet, isDesktop } = useWindowSize();
  const navigate = useNavigate();

  const columns: ColumnProps<TemplatesI>[] = [
    {
      title: '',
      dataIndex: 'title',
      width: 400,
      render: (_value: string, data) => (
        <ThreeDotPopover text={data.title} maxLength={45} fontWeight={600} />
      ),
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      render: (value: string) => (
        <>
          {value ? (
            <Tooltip title={value} trigger="hover" placement="topLeft">
              <B type="b-small" color={theme.gray600}>
                {addDotsForLongText(value, 90)}
              </B>
            </Tooltip>
          ) : (
            '-'
          )}
        </>
      ),
    },

    {
      title: '',
      dataIndex: 'id',
      width: 100,
      render: (value) => (
        <>
          <div>
            <B
              type="b-default-semibold"
              onClick={() => navigate('/contract/templates/view/' + value)}
              color={theme.blue500}>
              View
            </B>
          </div>
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      width: 80,
      render: (value) => (
        <>
          <div>
            <Dropdown
              overlay={
                <DropdownMenu>
                  <MenuItem>
                    <DeleteModal
                      title="Delete Template"
                      buttonLabel="Yes, Delete!"
                      content="Are you sure you want to delete Template?"
                      onClickCancel={() => deleteTemplate(value as string)}
                      openModal={<B type="b-default">delete</B>}></DeleteModal>
                  </MenuItem>
                </DropdownMenu>
              }>
              <a>
                <Image cursor="pointer" paddingLeft="12px" src={MoreSVG} />
              </a>
            </Dropdown>
          </div>
        </>
      ),
    },
  ];

  const locale = {
    emptyText: (
      <EmptyTextContainer description="You don't have any contract yet" />
    ),
  };

  return (
    <Card>
      <TableComp
        dataSource={templates}
        columns={columns}
        locale={locale}
        pagination={false}
        loading={loading}
        scroll={{
          y: tableScrollY || window.innerHeight - 250,
          x: isTablet ? 1250 : isDesktop ? 800 : 1075,
        }}
      />
    </Card>
  );
};
export default TemplatesTable;
