import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Dropdown, Menu, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { debounce, includes, uniqueId } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useArchiveLoan, useGetLoanList } from '../../../api/loanHooks';
import {
  AvatarComponent,
  B,
  DeleteModal,
  Div,
  DropdownIcon,
  Image,
  InputComp,
  PrimaryButton,
  SecondaryButton,
  TableComp,
} from '../../../components';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import theme from '../../../theme';
import { FilterTypes, LoanI, LoanStatus } from '../../../types/loan.types';
import { useWindowSize } from '../../../util/screenSize';
import { tableSorter } from '../../../util/tableSorter';
import {
  isHaveThreeDots,
  addDotsForLongText,
  numberThousandSeparator,
} from '../../../util/utils';
import GenerateBankFileModal from '../generatedBankFile/GenerateBankFileModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer';
import { DropdownBlack, MoreSVG, SearchSVG } from '../../../assets';
import { sortEmpIds } from '../../payroll/utils/sortingUtils';

const Container = styled.div`
  border: 1px solid ${theme.gray300};
  @media (max-width: 450px) {
    border: none;
  }
  border-radius: 4px;
`;

const MenuStyle = styled(Menu)`
  width: 175px;
  padding: 0px;
  border: 0.5px solid ${theme.gray300};
  border-bottom: none;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .ant-dropdown-menu-item > a {
    padding: 10.5px 16px;
    color: ${theme.black};
  }
  .ant-dropdown-menu-item :hover {
    background: ${theme.gray100};
  }
`;
const MenuItem = styled(Menu.Item)`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.black};
  border-bottom: 1px solid ${theme.gray300};
`;

const Header = styled.div`
  padding: 24px 40px 24px 32px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: block;
    padding: 30px 22px 24px 16px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    padding-bottom: 30px;
  }
`;

const RightSection = styled.div`
  display: block;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

const AllEmpDropdown = styled(Dropdown)`
  margin: auto;
  color: ${theme.blue500};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  :hover {
    color: ${theme.blue500} !important;
  }
  .ant-dropdown .ant-dropdown-placement-bottomLeft {
    top: 255px !important;
  }
`;

const menuItems = [
  {
    key: FilterTypes.ALL,
    name: 'All Loans',
  },
  {
    key: FilterTypes.ACTIVE,
    name: 'Active',
  },
  {
    key: FilterTypes.DRAFT,
    name: 'Draft',
  },
  {
    key: FilterTypes.SETTLED,
    name: 'Settled',
  },
  {
    key: FilterTypes.ARCHIVED,
    name: 'Archived',
  },
];

const LoanTable: FC = () => {
  const navigate = useNavigate();
  const { isTablet, isDesktop, isDesktopLarge } = useWindowSize();
  const [filter, setFilter] = useState<FilterTypes>(FilterTypes.ALL);
  const [dataSource, setDataSource] = useState<LoanI[]>([]);
  const [filteredDataSource, setFilteredDataSource] = useState<LoanI[]>([]);
  const [checkLoan, setCheckLoan] = useState<boolean>();
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedCheckList, setSelectedCheckList] = useState<number[]>([]);

  const {
    isLoading: loadingLoanList,
    data: loanList = [],
    refetch: getloanList,
  } = useGetLoanList();

  const { isSuccess: successArchiveLoan, mutate: archiveLoan } =
    useArchiveLoan();

  useEffect(() => {
    if (successArchiveLoan) {
      getloanList();
    }
  }, [successArchiveLoan]);

  useEffect(() => {
    const list: LoanI[] = [];
    if (!!loanList.length) {
      loanList?.forEach((loan) => {
        const loanTable: LoanI = {
          ...loan,
        };
        if (
          filter === FilterTypes.ACTIVE &&
          loanTable.loanStatus === LoanStatus.ACTIVE
        ) {
          list.push(loanTable);
        } else if (
          filter === FilterTypes.DRAFT &&
          loanTable.loanStatus === LoanStatus.DRAFT
        ) {
          list.push(loanTable);
        } else if (
          filter === FilterTypes.ARCHIVED &&
          loanTable.loanStatus === LoanStatus.ARCHIVED
        ) {
          list.push(loanTable);
        } else if (
          filter === FilterTypes.SETTLED &&
          loanTable.loanStatus === LoanStatus.SETTLED
        ) {
          list.push(loanTable);
        } else if (filter === FilterTypes.ALL) {
          list.push(loanTable);
        }
      });
      setDataSource(list);
      setFilteredDataSource(list);
    }
  }, [filter, loanList]);

  const searchDataTableCallback = useCallback(
    debounce((searchText) => {
      const text = searchText.trim();
      if (text) {
        const filteredData = dataSource.filter((item) => {
          const fullText =
            item.user.fullName?.toLowerCase() +
            item.user.employeeNumber +
            item.loanId;
          return includes(fullText, text.toLowerCase());
        });
        setFilteredDataSource(filteredData);
      } else {
        setFilteredDataSource(dataSource);
      }
    }, 400),
    [dataSource],
  );

  const clearSelected = () => {
    setSelectedCheckList([]);
    getloanList();
  };

  const columns: ColumnProps<LoanI>[] = [
    {
      title: '',
      width: 50,
      dataIndex: 'check',
      render: (_value: string, data) => (
        <CheckBoxComp
          disabled={data.loanStatus === LoanStatus.DRAFT ? false : true}
          onChange={(v) => {
            setCheckLoan(v.target.checked);
            if (v.target.checked) {
              setSelectedCheckList([...selectedCheckList, data.id]);
            } else {
              const checkedList = selectedCheckList;
              const setId = checkedList.findIndex((i) => i === data.id);
              checkedList.splice(setId, 1);
              setSelectedCheckList([...checkedList]);
            }
          }}
        />
      ),
    },
    {
      title: 'Name',
      width: 200,
      dataIndex: 'fullName',
      render: (_value: string, data) => (
        <Div display="flex" flexDirection="row">
          <AvatarComponent
            fontSize={12}
            fontWeight={600}
            shape="circle"
            title={data.user.fullName.charAt(0).toUpperCase()}
          />
          <Div display="flex">
            {isHaveThreeDots(data.user.fullName, 25) ? (
              <B type="b-small-semibold" pt="6px" pl="12px">
                {data.user.fullName ? (
                  <Tooltip placement="topLeft" title={data.user.fullName}>
                    {isDesktopLarge && data.user.fullName
                      ? addDotsForLongText(data.user.fullName, 20)
                      : data.user.fullName
                      ? addDotsForLongText(data.user.fullName, 15)
                      : '-'}
                  </Tooltip>
                ) : (
                  '-'
                )}
              </B>
            ) : (
              <B type="b-small-semibold" pt="6px" pl="12px" pr="8px">
                {data.user.fullName ? data.user.fullName : '-'}
              </B>
            )}
          </Div>
        </Div>
      ),
      sorter: (a, b) =>
        tableSorter.defaultSort(a.user.fullName, b.user.fullName),
      sortDirections: ['descend', 'ascend'],
      key: 'fullName',
    },
    {
      title: 'Emp ID',
      width: 100,
      dataIndex: 'employeeNumber',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.user.employeeNumber ? data.user.employeeNumber : '-'}
        </B>
      ),
      sorter: (a, b) =>
        sortEmpIds(a.user.employeeNumber, b.user.employeeNumber),
      sortDirections: ['descend', 'ascend'],
      key: 'employeeNumber',
    },
    {
      title: 'Loan ID',
      width: 100,
      dataIndex: 'loanId',
      render: (_value: string, data) => (
        <B type="b-small">{data.loanId ? data.loanId : '-'}</B>
      ),
      sorter: (a, b) => tableSorter.defaultSort(a.loanId, b.loanId),
      sortDirections: ['descend', 'ascend'],
      key: 'loanId',
      defaultSortOrder: 'descend',
    },
    {
      title: 'Loan Amount',
      width: 150,
      dataIndex: 'loanAmount',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.loanAmount ? numberThousandSeparator(data.loanAmount) : '-'}
        </B>
      ),
    },
    {
      title: 'Paid Amount',
      width: 150,
      dataIndex: 'paidAmount',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.paidAmount ? numberThousandSeparator(data.paidAmount) : '-'}
        </B>
      ),
    },
    {
      title: 'Balance Amount',
      width: 150,
      dataIndex: 'balanceAmount',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.balanceAmount
            ? numberThousandSeparator(data.balanceAmount)
            : '-'}
        </B>
      ),
    },
    {
      title: 'Status',
      width: 100,
      dataIndex: 'loanStatus',
      render: (_value: string, data) => (
        <B
          type="b-small"
          color={
            data.loanStatus === LoanStatus.ACTIVE
              ? theme.blue500
              : data.loanStatus === LoanStatus.DRAFT
              ? theme.orange400
              : data.loanStatus === LoanStatus.SETTLED
              ? theme.green500
              : theme.gray600
          }>
          {data.loanStatus
            ? data.loanStatus.charAt(0).toUpperCase() +
              data.loanStatus.substr(1).toLowerCase()
            : '-'}
        </B>
      ),
    },
    {
      title: '',
      align: 'left',
      dataIndex: 'viewMore',
      key: uniqueId(),
      width: 100,
      render: (_value: string, data) => (
        <Div display="flex">
          <Link
            to={{
              pathname: `/loan/${data.id}`,
            }}
            state={{ employee: data }}>
            <B type="b-default" color={theme.blue500}>
              View
            </B>
          </Link>
          {data.loanStatus != LoanStatus.ARCHIVED && (
            <Dropdown
              css={css`
                margin-left: 24px;
              `}
              overlay={menu(data)}
              placement="bottomRight"
              trigger={['click']}>
              <Image
                cursor="pointer"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                src={MoreSVG}
              />
            </Dropdown>
          )}
        </Div>
      ),
    },
  ];

  const menu = (data: LoanI) => (
    <MenuStyle>
      <MenuItem>
        <DeleteModal
          title="Archive Loan"
          buttonLabel="Yes, Archive!"
          content="Are you sure you want to archive this loan?"
          onClickCancel={() => {
            archiveLoan({
              id: data.id,
            });
          }}
          openModal={
            <B type="b-default" cssStyle="padding: 7px 0px 7px 4px;">
              Archive Loan
            </B>
          }></DeleteModal>
      </MenuItem>
    </MenuStyle>
  );

  const filterMenu = (
    <Menu>
      {menuItems.map((item) => (
        <Menu.Item onClick={() => setFilter(item.key)} key={item.key}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const threeDotMenu = () => (
    <Menu>
      <Menu.Item onClick={() => navigate('/loan/generated-bank-files')}>
        View List of Generated Bank Files
      </Menu.Item>
      <Menu.Item onClick={() => navigate('/loan/log-trail')}>
        View LogTrail
      </Menu.Item>
    </Menu>
  );

  const scrollTarget = useSelector(
    (state: RootState) => state.dashboard.scrollTarget,
  );

  return (
    <>
      <Header>
        <LeftSection>
          <AllEmpDropdown overlay={filterMenu} trigger={['click']}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}>
              <B type="b-large-semibold" display="flex">
                {menuItems.find((item) => item.key === filter)?.name}(
                {dataSource.length})
                <Div pl="10px">
                  <img src={DropdownBlack} />
                </Div>
              </B>
            </a>
          </AllEmpDropdown>
        </LeftSection>
        <RightSection>
          <InputComp
            size="small"
            onChange={(e) => {
              searchDataTableCallback(e.target.value);
            }}
            placeholder="Search"
            prefix={<img src={SearchSVG} />}
          />
          <SecondaryButton
            size="small"
            ml={16}
            mr={16}
            onClick={() => setVisible(true)}
            disabled={checkLoan ? false : true}>
            Generate Bulk File
          </SecondaryButton>
          <PrimaryButton size="small" onClick={() => navigate('/loan/create')}>
            Add New Loan
          </PrimaryButton>

          <DropdownIcon
            marginLeft={16}
            overlay={threeDotMenu}
            placement="bottomRight"
            trigger={['click']}
          />
        </RightSection>
      </Header>
      <Container>
        <TableComp
          rowKey={(item) => {
            return String(item.id);
          }}
          rowClassName={(row) => {
            return row.id === scrollTarget ? 'yellow-highlight' : '';
          }}
          pagination={false}
          loading={loadingLoanList}
          columns={columns}
          size="large"
          fullWidth={true}
          dataSource={filteredDataSource}
          scroll={{
            x: isTablet ? 1250 : isDesktop ? 1075 : 1075,
            y: window.innerHeight - 300,
          }}
        />
      </Container>
      {visible && (
        <GenerateBankFileModal
          visible={visible}
          handleCancel={() => setVisible(false)}
          clearSelected={() => clearSelected()}
          selected={selectedCheckList}
          dataSet={loanList}
        />
      )}
    </>
  );
};

export default LoanTable;
