import { OrganizationBodyI } from '../../../types/organization.types';
import { BASIC_SALARY_DATA_INDEX } from '../open-column-mapping/util/openPayrollValidator';

export interface SalaryItemI {
  type: 'deduction' | 'addition' | 'info';
  amount: number;
  value: number | string;
  title: string;
  dataIndex: string;
}

export interface EmployeePaySheetSummary {
  name?: string;
  grossAddition: number;
  grossDeduction: number;
  epf: number;
  employerEPF: number;
  employerETF: number;
  netSalary: number;
  netPay: number;
}
export interface EmployeePaySlipSummary {
  payRollId: number;
  userId: number;
  name: string | null;
  netSalary: number;
}

export interface payrollI {
  id: number;
  name: string | null;
  organization: OrganizationBodyI;
  organizationId: number;
  payrollEndDate: string | null;
  payrollStartDate: string | null;
  scheduleDate: string | null;
  status: 'DRAFTED' | 'COMPLETED';
  transferFileUrl: string | null;
}
export class EmployeeSalary {
  private items: SalaryItemI[] = [];
  private name?: string;

  constructor(name?: string) {
    this.name = name;
  }

  getEmployeeName() {
    return this.name;
  }
  setEmployeeName(name: string) {
    this.name = name;
  }

  getSalaryItems() {
    return this.items;
  }

  findByTitle(title: string) {
    this.items.forEach((item) => {
      if (item.title === title) {
        return item;
      }
    });
    return null;
  }

  findByDataIndex(dataIndex: string): SalaryItemI | null {
    let result: SalaryItemI | null = null;
    this.items.forEach((item) => {
      if (item.dataIndex === dataIndex) {
        result = item;
      }
    });
    return result;
  }

  addItem(item: SalaryItemI) {
    this.items.push(item);
    return this.items;
  }

  removeByIndex(index: number) {
    this.items.splice(index, 1);
    return this.items;
  }

  getGrossDeductions() {
    let result = 0;
    this.items.forEach((item) => {
      if (item.type === 'deduction') {
        result += item.amount;
      }
    });
    return result;
  }

  getGrossAdditions() {
    let result = 0;
    this.items.forEach((item) => {
      if (item.type === 'addition') {
        result += item.amount;
      }
    });
    return result;
  }
  getEmployerETF() {
    const basicSalary = this.findByDataIndex(BASIC_SALARY_DATA_INDEX);
    if (!basicSalary) {
      return 0;
    }
    return basicSalary.amount * 0.3;
  }
  getEPF() {
    const basicSalary = this.findByDataIndex(BASIC_SALARY_DATA_INDEX);
    if (!basicSalary) {
      return 0;
    }
    return basicSalary.amount * 0.12;
  }

  getEmployerEPF() {
    const basicSalary = this.findByDataIndex(BASIC_SALARY_DATA_INDEX);
    if (!basicSalary) {
      return 0;
    }
    return basicSalary.amount * 0.8;
  }
  getNetPay() {
    return (
      this.getGrossAdditions() + this.getEmployerEPF() + this.getEmployerETF()
    );
  }
  getSummary(): EmployeePaySheetSummary {
    return {
      name: this.getEmployeeName(),
      grossAddition: this.getGrossAdditions(),
      grossDeduction: this.getGrossDeductions(),
      epf: this.getEPF(),
      employerETF: this.getEmployerETF(),
      employerEPF: this.getEmployerEPF(),
      netSalary:
        this.getGrossAdditions() - this.getGrossDeductions() - this.getEPF(),
      netPay: this.getNetPay(),
    };
  }
}
