import React, { FC } from 'react';
import { B } from '../../../../components';
import { OrganizationLeaveI } from '../../../../types';
import { capitalizeUnderscore } from '../../../../util/utils';

interface Props {
  data: OrganizationLeaveI;
}

const LeaveTypeDisplay: FC<Props> = ({ data }) => {
  const leaveType = capitalizeUnderscore(data.leaveType);
  return <B type="b-small">{leaveType ? leaveType : '-'}</B>;
};
export default LeaveTypeDisplay;
