import { cloneDeep } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SideMenuItems, SUPER_ADMIN_SIDE_MENU } from '../configs';
import { useAttendanceState } from '../context/AttendanceContext';
import { RootState } from '../store/reducer';
import { MenuItemType } from '../types';
import { getPathWithOrgId } from './getPathWithOrgId';
import { isAllowedAnyOf, isManager } from './permissionUtil';
import { isCurrentAuthSuperAdmin } from './storageUtil';

export function useGetMenuItems() {
  const [menuItems, setMenuItems] = React.useState<MenuItemType[]>([]);
  const { isTimeAttendance } = useAttendanceState();
  const currentAuthPayload = useSelector(
    (state: RootState) => state.auth.currentAuthPayload,
  );

  const me = useSelector((state: RootState) => state.people.me);
  const customizedFeatures = me.organization?.customizedFeatures;

  React.useEffect(() => {
    if (isCurrentAuthSuperAdmin()) {
      setMenuItems(SUPER_ADMIN_SIDE_MENU);
    } else {
      const menuItems = SideMenuItems(customizedFeatures);
      const newItems = cloneDeep(menuItems).filter((item) => {
        if (item.isUserVisible) {
          if (item.displayName === 'Time Attendance') {
            return isTimeAttendance;
          }

          if (item.permissions && item.hasException) {
            item.path = getPathWithOrgId(item.path);
            return isManager() || isAllowedAnyOf(item.permissions);
          }
          if (!item.permissions || isAllowedAnyOf(item.permissions)) {
            item.path = getPathWithOrgId(item.path);
            if (!item.subMenus) {
              return true;
            } else {
              const subMenus = [...item.subMenus];
              item.subMenus = subMenus.filter(
                (sub) =>
                  (sub.permissions ? isAllowedAnyOf(sub.permissions) : true) &&
                  sub.isUserVisible,
              );

              if (item.subMenus.length) {
                item.subMenus = item.subMenus.map((subItem) => {
                  subItem.path = getPathWithOrgId(subItem.path);
                  return subItem;
                });
                return true;
              }
            }
          }
        }
        return false;
      });

      setMenuItems(newItems);
    }
  }, [currentAuthPayload, isTimeAttendance]);

  return menuItems;
}
