import React, { FC } from 'react';
import { Layout as AntLayout } from 'antd';
import { Link } from 'react-router-dom';

import styled from '@emotion/styled';
import theme from '../../../theme';
import { useWindowSize } from '../../../util/screenSize';
import { B, Div, PrimaryButton } from '../../../components';
import { AssignableUsersI, GetContractI } from '../../../types/contract.types';

const { Header } = AntLayout;

const StyledContainer = styled(Header)<{ isTablet: boolean }>`
  position: absolute;
  z-index: 100;
  padding: ${(props) => props.isTablet && '0 20px;'}
  top: 100px;
  left: 0;
  width: 93%;
  background: ${theme.white};
  height: 256px;
  a {
    color: ${theme.black};
    position: relative;
    text-decoration: underline;
  }
  a::after {
    content: '';
    margin: 0 auto;
    width: 102%;
    height: 1px;
    background: ${theme.white};
    position: absolute;
    bottom: -2px;
    left: 0%;
  }
`;
type Props = {
  visible: boolean;
  handleClose: () => void;
  contract: GetContractI;
  receiverId: string;
  assignableUsers: AssignableUsersI[];
};

const TermsConditionsHeader: FC<Props> = ({
  visible,

  contract,
  receiverId,
  assignableUsers,
  handleClose,
}) => {
  const fullName = assignableUsers?.find(
    (user) => user.id === receiverId,
  )?.fullName;

  const { isTablet } = useWindowSize();

  return visible ? (
    <StyledContainer isTablet={isTablet}>
      <Div pt="26px" pl="100px" width="90%" height="100%" alignItems="center">
        <Div pr="40px">
          <B type="b-large-semibold">{contract?.name ? contract.name : '-'}</B>
          <B pt={'10px'} type={isTablet ? 'b-default' : 'b-large'}>
            To: {fullName}
          </B>
          <B
            type={isTablet ? 'b-default' : 'b-large'}
            color={theme.black}
            pt="10px">
            By clicking the button below and using Humanised Contract services -
            including access and review of documents - I understand that
            Humanised may collect certain personal information to provide
            services and enhance user experience, as detailed more fully in
            Humanised’s{' '}
            <a href="https://www.gethumanised.com/terms-and-conditions/">
              {' '}
              Privacy Notice
            </a>
            .
          </B>
        </Div>
        <PrimaryButton
          marginLeft={isTablet ? '10px' : ''}
          marginTop={'40px'}
          onClick={handleClose}>
          Agree and continue
        </PrimaryButton>
      </Div>
    </StyledContainer>
  ) : (
    <div></div>
  );
};

export default TermsConditionsHeader;
