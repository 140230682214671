import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { cloneDeep, countBy, get, isArray, isEmpty } from 'lodash';
import moment from 'moment';
import { generatePayrollDataSheet } from './default/payrollData';
import { orgBasedFeatureConfig } from '../../../../configs';

import {
  DataImportUserPayLoad,
  PayrollViewDataI,
} from '../../../../store/actions';
import {
  bankColumns,
  calculationColumns,
  calculationColumnsWithGrossCols,
  empColumns,
  generalInfoColumns,
  identityColumns,
  identityColumnsSummary,
} from '../../../../util/excelGenerate.helper';
import { generateEmployeesSheet } from './employees';
import { generateSalarySheet } from './salary';
import { generateKrestonPayrollDataSheet } from './kreston/krestonPayrollData';
import { OrganizationI } from '../../../../types/organization.types';
import { PayrunType } from '../../../../types/payroll.types';

export interface ColumnItemType {
  key: string;
  width: number;
}

export const generatePayrollExcel = async (
  dataForTable:
    | {
        dataList: DataImportUserPayLoad[];
        payItemColumns: string[];
      }
    | undefined,
  leaveColumnNames: string[] | undefined,
  suffix: string,
  organizationData: OrganizationI,
  payrollViewData: PayrollViewDataI,
) => {
  if (isEmpty(dataForTable?.dataList)) return null;

  const dataPayload = dataForTable?.dataList || [];

  const payItemColumns = cloneDeep(dataForTable?.payItemColumns || []).map(
    (item) => {
      return {
        key: `${item}`,
        width: 20,
      };
    },
  );

  const workbook = new ExcelJS.Workbook();

  const salary = workbook.addWorksheet('Salary');
  const employees = workbook.addWorksheet('Employees');
  const worksheet = workbook.addWorksheet('Generated Salary');

  // EPF/ETF Row
  const epfRows = [];
  const epf: { [key: string]: string } = {};
  epf['empId'] = 'EPF/ETF';

  const enableGrossEpfAndApitCols =
    orgBasedFeatureConfig.exportConfig.bakertillyOrgIds.includes(
      organizationData.id,
    ) && payrollViewData.payrunType === PayrunType.MONTHLY_PAYRUN;
  // If gross APIT and gross EPF/ETF are enabled use calculationColumnsWithGrossCols
  const calculationColumnsWithMoreCols = enableGrossEpfAndApitCols
    ? calculationColumnsWithGrossCols
    : calculationColumns;

  for (const user of dataPayload) {
    const epfRow: { [key: string]: string } = {};
    for (const payItem of payItemColumns) {
      epfRow[payItem.key as string] = get(
        user,
        `values.payItemValues["${payItem.key}"].isEpf`,
        '',
      ) as string;
    }
    epfRows.push(epfRow);
  }
  for (const p of payItemColumns) {
    const result = countBy(epfRows, (o) => {
      return String(o[p.key]);
    });
    const trueCount = result['true'] ? result['true'] : 0;
    const flaseCount = result['false'] ? result['false'] : 0;
    if (trueCount > flaseCount) {
      epf[p.key] = 'Yes';
    } else if (trueCount === flaseCount) {
      epf[p.key] = '-';
    } else {
      epf[p.key] = 'No';
    }
  }

  // APIT Row
  const apitRows = [];
  const apit: { [key: string]: string } = {};
  apit['empId'] = 'APIT';

  for (const user of dataPayload) {
    const apitRow: { [key: string]: string } = {};
    for (const payItem of payItemColumns) {
      apitRow[payItem.key as string] = get(
        user,
        `values.payItemValues["${payItem.key}"].isTaxable`,
        '',
      ) as string;
    }
    apitRows.push(apitRow);
  }
  for (const p of payItemColumns) {
    const result = countBy(apitRows, (o) => {
      return String(o[p.key]);
    });
    const trueCount = result['true'] ? result['true'] : 0;
    const flaseCount = result['false'] ? result['false'] : 0;
    if (trueCount > flaseCount) {
      apit[p.key] = 'Yes';
    } else if (trueCount === flaseCount) {
      apit[p.key] = '-';
    } else {
      apit[p.key] = 'No';
    }
  }

  const userRows = [];
  //Forming payroll user rows
  for (const user of dataPayload) {
    const userRow: { [key: string]: string } = {};
    userRow['empId'] = get(user, 'values.employeeNumber', '') as string;
    userRow['fullName'] = get(user, 'values.fullName', '');
    userRow['nameWithInitials'] = get(user, 'values.nameWithInitials', '');
    userRow['preferredName'] = get(user, 'values.preferredName', '');
    userRow['joinedDate'] = get(user, 'values.joinedDate', '');

    for (const col of [...bankColumns, ...generalInfoColumns, ...empColumns]) {
      if (
        col.key === 'employmentType' ||
        col.key === 'branchName' ||
        col.key === 'designation' ||
        col.key === 'startDate'
      ) {
        userRow[col.key] = get(user, `values.currentJob.${col.key}`, '');
      } else if (col.key === 'paymentMode') {
        let paymentMode = 'bank';
        if (user.values.isEmployeePaidInCash) {
          paymentMode = 'cash';
        }
        userRow[col.key] = paymentMode;
      } else {
        userRow[col.key] = get(user, `values.${col.key}`, '');
      }
      if (col.key === 'teams') {
        const teamsValue = get(user, `values.${col.key}`, '');
        userRow[col.key] = isArray(teamsValue)
          ? teamsValue.join(',')
          : teamsValue;
      }
      if (col.key === 'endDate') {
        userRow[col.key] = get(user, `values.currentJob.${col.key}`, '');
        if (get(user, `values.currentJob.${col.key}`, '') === 'Invalid date') {
          userRow[col.key] = get(user, '', '');
        }
      }
    }

    for (const col of calculationColumnsWithMoreCols) {
      if (col.key === 'grossEarningsForAPIT' && enableGrossEpfAndApitCols) {
        userRow[col.key] = user[`taxableAmount`];
      } else if (
        col.key === 'grossEarningsForEPFAndETF' &&
        enableGrossEpfAndApitCols &&
        userRow['etfNumber'] !== 'N/A' &&
        userRow['epfNumber'] !== 'N/A'
      ) {
        userRow[col.key] = user[`epfEtfAmount`];
      } else {
        userRow[col.key] = get(user, `${col.key}`, '');
      }
    }

    for (const payItem of payItemColumns) {
      userRow[`${payItem.key}`] = get(
        user,
        `values.payItemValues["${payItem.key}"].amount`,
        '',
      ) as string;
    }
    userRows.push(userRow);
  }

  if (
    orgBasedFeatureConfig.exportConfig.krestonExportCompanies.includes(
      organizationData.id,
    )
  ) {
    await generateKrestonPayrollDataSheet({
      payItemColumns,
      calculationColumns,
      bankColumns,
      generalInfoColumns,
      worksheet,
      userRows,
      dataPayload,
      leaveColumnNames,
      organizationData,
      payrollViewData,
    });
  } else {
    await generatePayrollDataSheet({
      payItemColumns,
      calculationColumnsWithMoreCols,
      bankColumns,
      generalInfoColumns,
      worksheet,
      userRows,
      leaveColumnNames,
      identityColumns,
      companyName: organizationData?.name,
      payrollYear: payrollViewData?.year,
      payrollMonth: payrollViewData?.month,
    });
  }
  await generateSalarySheet({
    identityColumnsSummary,
    payItemColumns,
    salary,
    userRows,
    dataForTable,
    epf,
    apit,
  });
  await generateEmployeesSheet({
    identityColumns,
    payItemColumns,
    empColumns,
    employees,
    userRows,
    dataForTable,
    epf,
    apit,
  });

  const payrollDate = `${payrollViewData.year}-${payrollViewData.month}-01`;
  const companyName = organizationData.name;

  const paysheetFileName = `${moment(payrollDate).format('MMM')}_${moment(
    payrollDate,
  ).format('YYYY')} - ${companyName}`;

  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${paysheetFileName}_${moment().format('DD-MM-YYYY')}${suffix}.xlsx`,
    );
  });
};
