import { message } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { DocumentI, DocumentListI } from '../types/document.types';
import { getApiError } from '../util/getApiError';
import {
  ContractHookParamI,
  ContractHookReturnI,
  TemplateByIdI,
  TemplateI,
  TemplatesI,
} from '../types/contract.types';
import { PeopleI } from '../types/people.types';

export const useOrganizationFileUpload = () => {
  return useMutation<string, AxiosError, File>(
    async (data: File) => {
      const formData = new FormData();
      formData.append('file', data);
      const response: AxiosResponse<string> = await axios.post(
        `/organization/fileUpload`,
        formData,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};
export const useTemplateUpload = () => {
  return useMutation<string, AxiosError, TemplateI>(
    async (data: TemplateI) => {
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('title', data.title);
      formData.append('comment', data.comment);

      const response: AxiosResponse<string> = await axios.post(
        `/contracts/uploadTemplate`,
        formData,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },

      onSuccess: () => {
        message.success('Successfully upload');
      },
    },
  );
};

export const useUploadContract = () => {
  return useMutation<ContractHookReturnI, AxiosError, ContractHookParamI>(
    async (data: ContractHookParamI) => {
      const formData = new FormData();
      data.file && formData.append('file', data.file);
      data.firstSignatories &&
        formData.append('firstSignatories', data.firstSignatories);
      data.secondSignatories &&
        formData.append('secondSignatories', data.secondSignatories);
      data.title && formData.append('fileName', data.title);
      data.isSaveTemplate &&
        formData.append('isSaveTemplate', data.isSaveTemplate.toString());
      data.templateId && formData.append('templateId', data.templateId);

      const response = await axios.post(`/contracts/uploadContract`, formData);
      await localStorage.setItem(
        'documentId',
        JSON.stringify(response.data.documentId),
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Uploaded successfully');
      },
    },
  );
};

export const useAddUserDocuments = () => {
  return useMutation<string, AxiosError, DocumentI>(
    async (data: DocumentI) => {
      const response: AxiosResponse<string> = await axios.post(
        `/documents/userFile`,
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully Uploaded');
      },
    },
  );
};

export const useAddMeDocuments = () => {
  return useMutation<string, AxiosError, DocumentI>(
    async (data: DocumentI) => {
      const response: AxiosResponse<string> = await axios.post(
        `/documents/me/userFile`,
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully Uploaded');
      },
    },
  );
};

export const useDeleteMeDocument = () => {
  return useMutation<string, AxiosError, string>(
    async (documentId) => {
      const response: AxiosResponse<string> = await axios.delete(
        `/documents/me/userDocument/${documentId}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully deleted the Document.');
      },
    },
  );
};

export const useDeleteUserDocument = () => {
  return useMutation<string, AxiosError, number>(
    async (documentId) => {
      const response: AxiosResponse<string> = await axios.delete(
        `/documents/userdocument/${documentId}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully deleted the Document.');
      },
    },
  );
};

export const useGetUserDocuments = () => {
  return useMutation<DocumentListI[], AxiosError, string>(async (userId) => {
    const response: AxiosResponse<DocumentListI[]> = await axios.get(
      `/documents/documentlist/${userId}`,
    );
    return response.data;
  });
};

export const useGetTemplates = () => {
  return useQuery<TemplatesI[], AxiosError>(
    'templates',
    async () => {
      const response: AxiosResponse<TemplatesI[]> = await axios.get(
        `/contracts/templates`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useGetTemplateById = () => {
  return useMutation<TemplateByIdI, AxiosError, string>(async (templateId) => {
    const response: AxiosResponse<TemplateByIdI> = await axios.get(
      `/contracts/template/${templateId}`,
    );
    return response.data;
  });
};
export const useDeleteTemplate = () => {
  return useMutation<string, AxiosError, string>(
    async (templateId) => {
      const response: AxiosResponse<string> = await axios.delete(
        `/contracts/template/${templateId}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully deleted the template.');
      },
    },
  );
};

export const useContractDelete = () => {
  return useMutation<string, AxiosError, string>(
    async (contractId) => {
      const response: AxiosResponse<string> = await axios.delete(
        `/contracts/${contractId}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully deleted the contract.');
      },
    },
  );
};

export const useUserSearch = (query: string) => {
  return useQuery<PeopleI[], AxiosError>(
    'userSearch',
    async () => {
      if (query) {
        const response: AxiosResponse<any[]> = await axios.get(
          `contracts/userSearch?searchQuery=${query}`,
        );

        return response.data;
      }
    },
    {
      onError: () => {
        message.success('Successfully searched');
      },
    },
  );
};
