import React, { FC } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { B, TableComp } from '../../../../components';
import { RootState } from '../../../../store/reducer';
import { IntegrationStatusEnum } from '../../../../types';

const Container = styled.div`
  .ant-table-cell {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
`;

const IntegrationSuppliers: FC = () => {
  const integrationSettings = useSelector(
    (state: RootState) => state.integration.integrationSetting,
  );

  const columns: ColumnProps<any>[] = [
    {
      title: 'Supplier Name',
      dataIndex: 'label',
      key: 'label',
      render: (value) => <B type="b-default">{value}</B>,
    },
  ];

  const dataSet = integrationSettings?.settings.metaData.suppliers || [];

  return (
    <Container>
      <TableComp
        loading={false}
        columns={columns}
        dataSource={dataSet}
        pagination={false}
        withBorders={true}
        scroll={{
          y:
            window.innerHeight -
            (integrationSettings &&
            integrationSettings?.status ===
              IntegrationStatusEnum.ACTION_REQUIRED
              ? 500
              : 350),
        }}
      />
    </Container>
  );
};

export default IntegrationSuppliers;
