import * as ExcelJS from 'exceljs';

import { PeopleI } from '../../../../../types/people.types';
import { camelCaseToNormalText } from '../../../../../util/camelCaseToNormal.util';
import { capitalizeUnderscore } from '../../../../../util/utils';
import { ColumnItemType } from '../../../../payroll/utils/payrollExport/generatePayrollExport';

interface Params {
  identityColumns: ColumnItemType[];
  payItemColumns: ColumnItemType[];
  empColumns: ColumnItemType[];
  employees: ExcelJS.Worksheet;
  userRows: {
    [key: string]: string;
  }[];
  payload: PeopleI[] | undefined;
  epf: {
    [key: string]: string;
  };
  apit: {
    [key: string]: string;
  };
}

export const generateEmployeesSheet = ({
  identityColumns,
  payItemColumns,
  empColumns,
  employees,
  userRows,
  payload,
  epf,
  apit,
}: Params) => {
  const empSheetColumns = [
    ...identityColumns,
    ...empColumns,
    ...payItemColumns,
  ];

  employees.columns = empSheetColumns;

  employees.addRow(epf);
  employees.addRow(apit);

  //Employees Sheet Main headings
  const empSheetTitles: { [key: string]: string } = {};

  empSheetTitles['empId'] = 'EMP ID*';
  empSheetTitles['fullName'] = 'Full Name*';
  empSheetTitles['nameWithInitials'] = 'Name with initials*';
  empSheetTitles['joinedDate'] = 'Joined Date*';
  empSheetTitles['preferredName'] = 'Preferred Name';
  empSheetTitles['loginEmail'] = 'Login Email';
  empSheetTitles['paySlipMail'] = 'Payslip Mail';
  empSheetTitles['nicOrPassport'] = 'NIC*';
  empSheetTitles['occupationGrade'] = 'Occupation Grade*';
  empSheetTitles['designation'] = 'Designation';
  empSheetTitles['address'] = 'Address';
  empSheetTitles['DOB'] = 'DOB';
  empSheetTitles['personalMobile'] = 'Mobile';
  empSheetTitles['employmentType'] = 'Employment Type';
  empSheetTitles['branchName'] = 'Office Branch';
  empSheetTitles['teams'] = 'Teams';
  empSheetTitles['bankAccountName'] = 'Bank Account Name*';
  empSheetTitles['bankAccountNumber'] = 'Bank Account Number*';
  empSheetTitles['bankBankCode'] = 'Bank Code*';
  empSheetTitles['branchCode'] = 'Branch Code*';
  empSheetTitles['gender'] = 'Gender';
  empSheetTitles['phoneNumber'] = 'Phone Number';
  empSheetTitles['epfNumber'] = 'EPF Number';
  empSheetTitles['etfNumber'] = 'ETF Number';
  empSheetTitles['taxNumber'] = 'Tax Number';
  empSheetTitles['startDate'] = 'Start Date';
  empSheetTitles['endDate'] = 'Resignation Date';
  if (payload) {
    for (const data of payload) {
      for (const payitemColumn of payItemColumns) {
        data.userPayItems?.forEach((item) => {
          if (
            item.payTitle === payitemColumn.key &&
            !item.payTitle.toLowerCase().trim().includes('basic')
          ) {
            empSheetTitles[payitemColumn.key] = `${capitalizeUnderscore(
              item.type,
            )}:${camelCaseToNormalText(payitemColumn.key)}`;
          }
          if (
            item.payTitle === payitemColumn.key &&
            item.payTitle.toLowerCase().trim().includes('basic')
          ) {
            empSheetTitles[payitemColumn.key] = 'Basic Salary';
          }
        });
      }
    }
  }
  employees.addRow(empSheetTitles);
  employees.getRow(3).font = { bold: true };

  employees.addRows(userRows);
};
