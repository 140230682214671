import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import React, { FC, PropsWithChildren } from 'react';

import { DefaultBody } from '../../../../components';
import theme from '../../../../theme';
import StepsComponent from './StepsComponent';

interface Props extends PropsWithChildren {
  current: number;
  setCurrent: (value: number) => void;
}
const CutsStepsStyled = styled(Row)`
  margin: 16px 0px 36px;
`;

export const AddManuallyLayout: FC<Props> = ({
  children,
  current,
  setCurrent,
}) => {
  return (
    <DefaultBody backgroundColor={theme.gray50} height="100%">
      <CutsStepsStyled justify="center" align="top">
        <Col xs={0} sm={0} md={0} lg={4} xl={4} xxl={4}></Col>
        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
          <StepsComponent current={current} setCurrent={setCurrent} />
        </Col>
        <Col xs={0} sm={0} md={0} lg={4} xl={4} xxl={4}></Col>
      </CutsStepsStyled>
      <CutsStepsStyled justify="center" align="top">
        <Col xs={0} sm={0} md={0} lg={3} xl={3} xxl={3}></Col>
        <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
          {children}
        </Col>
        <Col xs={0} sm={0} md={0} lg={3} xl={3} xxl={3}></Col>
      </CutsStepsStyled>
    </DefaultBody>
  );
};
