import React, { FC, useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown, Menu } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { DownOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import theme from '../../theme';
import { CheckBoxComp } from '../v2/CheckBoxComp';

export const MenuComp = styled(Menu)`
  background: ${theme.white};
  border: 1px solid ${theme.gray300};
  border-radius: 4px;
  padding: 0px !important;
`;
export const MenuStyle = styled.div`
  overflow-y: scroll;
  max-height: 350px;
`;
export const MenuItem = styled.div`
  border-bottom: 1px solid ${theme.gray300};
  padding: 11px 14px;
  margin-bottom: 0px !important;
`;
export const MenuItemClear = styled.div`
  display: flex;
  flex-direction: row;
  padding: 11px 14px;
`;
export const DropdownComp = styled.span`
  margin-left: 5px;
  background: ${theme.gray200};
  border-radius: 14px;
  padding: 0px 8px;
`;
export const DropdownButton = styled(Button)`
  margin-left: 16px !important;
  display: flex;
  justify-content: space-between;
  height: 32px;
  width: 150px;
  padding: 4px 12px 6px 16px !important;
  text-align: start !important;
  border-radius: 4px;
  border: 1px solid ${theme.gray300} !important;
  :hover,
  :focus {
    color: ${theme.black} !important;
    border-color: ${theme.gray300} !important;
  }
`;

export enum SelectorFilterTypes {
  OVERTIME = 'OVERTIME',
  NO_PAY = 'NO_PAY',
}

const menuItems = [
  {
    key: SelectorFilterTypes.OVERTIME,
    name: 'Overtime',
  },
  {
    key: SelectorFilterTypes.NO_PAY,
    name: 'No Pay',
  },
];

interface Props {
  onChange: (value: CheckboxValueType[]) => void;
}

const OvertimeNoPaySelector: FC<Props> = ({ onChange }) => {
  const [filter, setFilter] = useState<CheckboxValueType[]>([]);

  useEffect(() => {
    onChange(filter);
  }, [filter]);

  const allTypeMenu = (
    <Checkbox.Group onChange={(e) => setFilter(e)}>
      <MenuComp>
        <MenuStyle>
          {menuItems.map((item) => {
            return (
              <MenuItem
                onClick={(e) => e.stopPropagation()}
                key={item.key}>
                <CheckBoxComp key={item.key} value={item.name}>
                  {item.name}{' '}
                </CheckBoxComp>
              </MenuItem>
            );
          })}
        </MenuStyle>
      </MenuComp>
    </Checkbox.Group>
  );

  return (
    <>
      <Dropdown overlay={allTypeMenu}>
        <DropdownButton>
          <div>{filter && filter.length === 1 ? filter : 'All Types'}</div>
          <div>
            <DownOutlined />
          </div>
        </DropdownButton>
      </Dropdown>
    </>
  );
};

export default OvertimeNoPaySelector;
