import React, { FC } from 'react';
import { LinkButton } from '../../../../components';
import { PublishSummaryPayrollI } from '../../../../store/actions';
import { generateJournal } from '../../utils/generateSalaryJournal';
import { BlackDownload } from '../../../../assets';

interface Props {
  payload: PublishSummaryPayrollI;
  payrunType?: string | null;
}

export const SalaryJournalExport: FC<Props> = ({ payload, payrunType }) => {
  return (
    <div>
      <LinkButton
        size="small"
        mr={17}
        onClick={() => {
          generateJournal(payload, payrunType);
        }}
        icon={<img src={BlackDownload} />}></LinkButton>
    </div>
  );
};
