import { Alert } from 'antd';
import theme from '../../../../theme';
import styled from '@emotion/styled';
import { space, SpaceProps, layout, LayoutProps } from 'styled-system';

type Props = SpaceProps & LayoutProps;

export const WarnMessageContainer = styled(Alert)<Props>`
  font-size: 12px;
  line-height: 20px;
  background: ${theme.warn};
  border: 0.5px solid ${theme.orange400};
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 24px;
  margin-bottom: 30px;
  width: 80%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  ${space}
  ${layout}
`;

export const InfoMessageContainer = styled(Alert)`
  font-size: 14px;
  line-height: 20px;
  background: ${theme.lightBlue50};
  border: 0.5px solid ${theme.blue500};
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 24px;
  margin-bottom: 30px;
  width: 80%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ErrorMessageContainer = styled(Alert)<Props>`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  background: ${theme.red50};
  border: 0.5px solid ${theme.red200};
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 24px;
  margin-bottom: 30px;
  width: 80%;
  @media screen and (max-width: 768px) {
    width: 95%;
    margin-left: 10px;
  }
  .action-required-container {
    display: flex;
    flex-direction: column;
  }
  .anticon {
    margin-top: 3px !important;
  }
  ${space},
  ${layout}
`;
