import React, { FC } from 'react';
import styled from '@emotion/styled';
import { B } from '..';
import theme from '../../theme';
import { message } from 'antd';
import { Attachment, DeleteBin } from '../../assets';

const FileInput = styled.div<{ direction: string; error: boolean }>`
  @media screen and (max-width: 768px) {
    ${(props) => props.direction === 'row' && 'width: 100%;'}
  }
  ${(props) => props.direction === 'row' && 'width: 344px;'}
  .custom-file-input {
    color: transparent;
    width: 111px;
  }
  .custom-file-input::-webkit-file-upload-button {
    display: none;
  }
  .custom-file-input::before {
    content: 'Choose File';
    background: ${theme.gray50};
    border: 1px solid ${(props) => (props.error ? '#ff4d4f' : theme.gray300)};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 6px 15px;
    color: ${(props) => (props.error ? '#ff4d4f' : theme.blue500)};
    display: inline-block;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .custom-file-input:hover::before {
    border-color: ${theme.blue500};
  }
  .custom-file-input:focus {
    outline: 0 !important;
  }
  .custom-file-input:active {
    outline: 0 !important;
  }
  .custom-file-input:active::before {
    background: ${theme.gray50};
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
`;

const InfoText = styled.small`
  margin-top: 8px;
  font-size: 10px !important;
  line-height: 20px !important;
  color: ${theme.gray600};
`;

const ErrorText = styled.small`
  margin-top: 8px;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #ff4d4f;
`;

const FileLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
`;

const DeleteContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

const DeleteFileImg = styled.img`
  cursor: pointer;
`;

/** How to setup component
 *
 * step 1 - Use a File array state on the parent component
 *    const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
 *
 * step 2 - Use ant form onFinish to capture added files
 *    selectedFiles
 *
 * step 3 - Pass selectedFiles value as `fileList` prop to the component to show selected
 */

/** How to show empty error
 *
 * step 1 - Use a boolean state on the parent component
 *    const [fileEmptyError, setFileEmptyError] = useState<boolean>(false);
 *
 * step 2 - Use ant form onFinishFailed to capture error
 *    const onFinishFailed = () => { setFileEmptyError(selectedFiles && selectedFiles.length === 0 ? true : false);};
 *
 * step 3 - Pass fileEmptyError value as `showEmpty` prop to the component
 */

interface Props {
  showEmpty?: boolean;
  emptyMessage?: string;
  accept?: string;
  flexDirection?: string;
  infoText?: string;
  fileLimit?: 5;
  maxFileSizeEach?: 5; //mb
  onFileChange: (files: (File | string)[]) => void;
  fileList: (File | string)[];
}

export const FileUploaderV2: FC<Props> = (props) => {
  const {
    onFileChange,
    fileList,
    flexDirection = 'column',
    infoText,
    fileLimit = 5,
    maxFileSizeEach = 5,
    showEmpty,
    emptyMessage = 'Please add files',
    ...rest
  } = props;

  const showError = Boolean(showEmpty && fileList.length === 0);

  const getFiles = (files: FileList | null) => {
    const setFiles = [];
    for (const key in files) {
      if (Object.prototype.hasOwnProperty.call(files, key)) {
        const file = files[Number(key)];
        const fileSize = file.size / 1048576; //size in mb
        if (fileSize > maxFileSizeEach) {
          message.error(
            `File size is more than ${maxFileSizeEach}mb - ${file.name}`,
          );
        } else {
          setFiles.push(file);
        }
      }
    }
    const allFiles = [...fileList, ...setFiles];

    if (allFiles.length > fileLimit) {
      message.error(`File upload count is limited to ${fileLimit}`);
      return;
    } else {
      onFileChange(allFiles);
    }
  };

  const removeFile = (fileIndex: number) => {
    fileList.splice(fileIndex, 1);
    onFileChange([...fileList]);
  };

  return (
    <FileInput direction={flexDirection} error={showError}>
      <ColumnContainer>
        <RowContainer>
          <input
            className="custom-file-input"
            type="file"
            {...rest}
            onChange={({ target: { files } }) => getFiles(files)}
            multiple={true}
          />
          <B
            cssStyle="margin-left: 16px;"
            type="b-default"
            color={showError ? '#ff4d4f' : theme.gray600}>
            {fileList && fileList.length
              ? `${fileList.length} ${
                  fileList.length === 1 ? `File` : 'Files'
                } Choosed Successfully!`
              : 'No files Chosen'}
          </B>
        </RowContainer>
        {showError && <ErrorText>{emptyMessage}</ErrorText>}
        {infoText && <InfoText>{infoText}</InfoText>}
        {fileList && fileList.length ? (
          <FileContainer>
            {fileList.map((file, i) => (
              <FileLayout key={i}>
                <img
                  width="8px"
                  height="14px"
                  src={Attachment}
                  draggable={false}
                />
                <B
                  cssStyle="margin: 0px 12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                  type="b-default">
                  {typeof file === 'string'
                    ? file.split('/').pop()
                    : file.name
                    ? file.name
                    : null}
                </B>
                <DeleteContainer>
                  <DeleteFileImg
                    width="11px"
                    height="13px"
                    src={DeleteBin}
                    draggable={false}
                    onClick={() => removeFile(i)}
                  />
                </DeleteContainer>
              </FileLayout>
            ))}
          </FileContainer>
        ) : null}
      </ColumnContainer>
    </FileInput>
  );
};
