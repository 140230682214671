import React, { FC } from 'react';
import { AccountDetailLayout } from '../../components/AccountDetailLayout';
import { DocumentDetailTable } from './DocumentDetailTable';
const DocumentDetail: FC = () => {
  return (
    <>
      <AccountDetailLayout>
        <DocumentDetailTable />
      </AccountDetailLayout>
    </>
  );
};
export default DocumentDetail;
