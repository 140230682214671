import axios from 'axios';

import showApiError from '../../util/showApiError';

const REPORT_LOADING = 'REPORT_LOADING';
const PAYROLL_REPORT = 'PAYROLL_REPORT';
const PAYROLL_REPORT_LOADING = 'PAYROLL_REPORT_LOADING';
const HEADCOUNT_REPORT = 'HEADCOUNT_REPORT';
const HEADCOUNT_REPORT_LOADING = 'HEADCOUNT_REPORT_LOADING';
const PAYROLL_ETF = 'PAYROLL_ETF';
const PAYROLL_ETF_LOADING = 'PAYROLL_ETF_LOADING';

export interface ReportSummaryPayrollCalculationsI {
  employerEPF: number;
  employerETF: number;
  grossDeduction: number;
  grossSalary: number;
  totalAllowance: number;
}

export interface ReportDetailedOtherRecordsI {
  [type: string]: string;
}

export interface ReportDetailedRecordsI {
  [type: string]: number;
}

export interface ReportDetailedPayrollCalculationsI {
  [type: string]: ReportDetailedRecordsI;
  earnings: ReportDetailedRecordsI;
  deductions: ReportDetailedRecordsI;
  total: ReportDetailedRecordsI;
  other: ReportDetailedRecordsI;
}

export interface ReportSummaryPayrollI {
  month: string;
  calculation: ReportSummaryPayrollCalculationsI;
}
interface PayItemsI {
  amount: number;
  createdAt: string;
  empPayrollId: number;
  id: number;
  isEpf: boolean;
  isTaxable: boolean;
  payTitle: string;
  type: string;
  updatedAt: string;
}

export interface ReportUserPayrollI {
  costToCompany: number;
  employeeEPF: number;
  employerEPF: number;
  employerETF: number;
  epfEtfAmount: number;
  grossDeduction: number;
  grossSalary: number;
  netSalary: number;
  payItems: PayItemsI[];
  taxableAmount: number;
  totalAllowance: number;
  totalTax: number;
}

export interface ReportUserI {
  calculation: ReportUserPayrollI;
  fullName: string;
  id: number;
  teams: string[] | string;
}
export interface PayrollReportI {
  createDate: string;
  endDate: string;
  id: number;
  name: string;
  startDate: string;
  month: string;
  users: ReportUserI[];
}

interface PayrollReportFiltersI {
  startDate?: string | null;
  endDate?: string | null;
}

interface PayrollETFFiltersI {
  startDate?: string | null;
  endDate?: string | null;
}

interface HeadCountRecordsI {
  count: number;
  date: string;
  month: string;
}
export interface HeadCountReportI {
  latestCount: number;
  records: HeadCountRecordsI[];
}
interface InitialStateI {
  loading: boolean;
  payrollReport: PayrollReportI[];
  payrollReportLoading: boolean;
  headCountReport: HeadCountReportI;
  headCountReportLoading: boolean;
  payrollETF: any[];
  payrollETFLoading: boolean;
}
const initialState: InitialStateI = {
  loading: false,
  payrollReport: [],
  payrollReportLoading: false,
  headCountReport: {
    latestCount: 0,
    records: [],
  },
  headCountReportLoading: false,
  payrollETF: [],
  payrollETFLoading: false,
};

export default function reducer(
  state: InitialStateI = initialState,
  action: any,
): InitialStateI {
  switch (action.type) {
    case REPORT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case PAYROLL_REPORT:
      return {
        ...state,
        payrollReport: action.payload,
      };
    case PAYROLL_REPORT_LOADING:
      return {
        ...state,
        payrollReportLoading: action.payload,
      };
    case HEADCOUNT_REPORT:
      return {
        ...state,
        headCountReport: action.payload,
      };
    case HEADCOUNT_REPORT_LOADING:
      return {
        ...state,
        headCountReportLoading: action.payload,
      };
    case PAYROLL_ETF:
      return {
        ...state,
        payrollETF: action.payload,
      };
    case PAYROLL_ETF_LOADING:
      return {
        ...state,
        payrollETFLoading: action.payload,
      };

    default:
      return state;
  }
}

export function getPayrollReport(params?: PayrollReportFiltersI) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: PAYROLL_REPORT, payload: [] });
      dispatch({ type: PAYROLL_REPORT_LOADING, payload: true });
      const res = await axios.get('/report/payroll', {
        params,
      });
      dispatch({ type: PAYROLL_REPORT, payload: res.data });
      dispatch({ type: PAYROLL_REPORT_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: PAYROLL_REPORT_LOADING, payload: false });
    }
  };
}

export function getETFReportData(params?: PayrollETFFiltersI) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: PAYROLL_ETF, payload: [] });
      dispatch({ type: PAYROLL_ETF_LOADING, payload: true });
      const res = await axios.get('/report/etf', {
        params,
      });
      dispatch({ type: PAYROLL_ETF, payload: res.data });
      dispatch({ type: PAYROLL_ETF_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: PAYROLL_ETF_LOADING, payload: false });
    }
  };
}

export function getEmployeeHeadCountReport() {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: HEADCOUNT_REPORT,
        payload: {
          latestCount: 0,
          records: [],
        },
      });
      dispatch({ type: HEADCOUNT_REPORT_LOADING, payload: true });
      const res = await axios.get('/report/employee/head-count');
      dispatch({ type: HEADCOUNT_REPORT, payload: res.data });
      dispatch({ type: HEADCOUNT_REPORT_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: HEADCOUNT_REPORT_LOADING, payload: false });
    }
  };
}
