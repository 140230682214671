import styled from '@emotion/styled';
import { Dropdown, Menu } from 'antd';
import { ColumnProps } from 'antd/es/table';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DropdownBlack, MoreSVG, SearchSVG } from '../../../../assets';
import dayjs from 'dayjs';
import { debounce, includes, isEmpty } from 'lodash';
import {
  useGetAttendanceData,
  useUploadTimeSheet,
} from '../../../../api/attendanceHooks';
import {
  B,
  DefaultBody,
  Div,
  DropdownIcon,
  H,
  Image,
  InputComp,
  PrimaryButton,
  TableComp,
} from '../../../../components';
import theme from '../../../../theme';
import { Permission } from '../../../../types';
import {
  AttendanceFilterTypes,
  IAttendanceData,
} from '../../../../types/attendance.types';
import { useGetDefaultDataExtractor } from '../../../../util/dataImportUtil';
import { isAllowed } from '../../../../util/permissionUtil';
import { tableSorter } from '../../../../util/tableSorter';
import { ExpenseStatusTag } from '../../../expenses/shared';
import { StatusColumn } from '../../../leave/new-leave/UserLeavesTable';
import {
  MenuItem,
  MenuStyle,
} from '../../../payroll/pages/payroll-home/PayRunTable';
import TimesheetModal from '../../shared/TimesheetModal';
import { sortMonthYear } from '../../../../util/monthYear';
import { attendanceMenuItems } from '../../../../configs';
import { monthToString } from '../../../../util/timeConvertion';
import AttendanceDropdownModel from '../../attendanceExport/AttendanceDropdownModel';

export const HeaderSection = styled.div`
  padding: 24px 32px 24px 32px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: block;
    padding: 30px 22px 24px 16px;
  }
`;
export const AttendanceDropdown = styled(Dropdown)`
  margin-left: auto;
  margin-top: 15px;
  color: ${theme.black};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  :hover {
    color: ${theme.black} !important;
  }
  .ant-dropdown .ant-dropdown-placement-bottomLeft {
    top: 255px !important;
  }
`;
export const LeftSection = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    padding-bottom: 30px;
  }
`;

export const RightSection = styled.div`
  display: block;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;
export const MobileHideButton = styled.div`
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
`;

export const Header = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
export const LastColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const actionMenu = (data) => <AttendanceDropdownModel data={data} />;

const TimeAttendance = () => {
  const extractorInfo = useGetDefaultDataExtractor();
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);
  const [filter, setFilter] = useState<AttendanceFilterTypes>(
    AttendanceFilterTypes.ALL,
  );
  const [dataSource, setDataSource] = useState<IAttendanceData[]>([]);
  const [filteredData, setFilteredDataSource] =
    useState<IAttendanceData[]>(null);

  const {
    data: timeAttendanceData,
    isLoading: timeAttendanceIsLoading,
    isSuccess: timeAttendanceIsSuccess,
    refetch: refetchTimeAttendance,
  } = useGetAttendanceData();

  const {
    isLoading: loadingUploadForDataExtract,
    data: uploadTimeSheet,
    mutate: uploadForDataExtract,
    error: uploadTimeSheetError,
  } = useUploadTimeSheet();

  useEffect(() => {
    if (!uploadTimeSheetError && uploadTimeSheet) {
      refetchTimeAttendance();
    }
  }, [uploadTimeSheet]);

  useEffect(() => {
    if (timeAttendanceIsSuccess) {
      setDataSource(timeAttendanceData);
    }
  }, [timeAttendanceData, timeAttendanceIsSuccess]);

  const searchDataTableCallback = useCallback(
    debounce((searchText) => {
      const text = searchText.trim();
      if (text) {
        const filteredData = dataSource.filter((item: IAttendanceData) => {
          const fullText =
            item.title.toLowerCase() +
            monthToString(item.month).toLowerCase() +
            item.status.toLowerCase() +
            item.year +
            item.createdAt;
          return includes(fullText, text.toLowerCase());
        });
        setFilteredDataSource(filteredData);
      } else {
        setFilteredDataSource(dataSource);
      }
    }, 400),
    [dataSource],
  );

  const columns: ColumnProps<IAttendanceData>[] = [
    {
      title: 'Title',
      dataIndex: 'title',
      render: (value) => <B type="b-small">{value ? value : '-'}</B>,
      sorter: (a, b) => tableSorter.defaultSort(a.title, b.title),
    },
    {
      title: 'Upload Date',
      dataIndex: 'createdAt',
      render: (value) => (
        <B type="b-small">{value ? dayjs(value).format('DD MMM YYYY') : '-'}</B>
      ),
      sorter: (a, b) => tableSorter.defaultSort(a.createdAt, b.createdAt),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_value, data) => (
        <StatusColumn>
          <ExpenseStatusTag
            noBullet
            status={data.status ? data.status : AttendanceFilterTypes.DRAFT}
          />
        </StatusColumn>
      ),
    },
    {
      title: 'Month/Year',
      dataIndex: 'monthYear',
      render: (_, data) => (
        <B type="b-small">
          {' '}
          {data?.month !== undefined && data?.year
            ? monthToString(data?.month) + data?.year
            : '-'}
        </B>
      ),
      sorter: (a, b) => sortMonthYear(a, b),
    },
    {
      title: '',
      dataIndex: 'options',
      width: 120,
      key: 'options',
      render: (_value: string, data: IAttendanceData) => (
        <>
          <LastColumn>
            <Link to={`/time-attendance/view/${data.id}`}>
              <B type="b-default" color={theme.blue500}>
                View
              </B>
            </Link>
            <Dropdown
              overlay={actionMenu(data)}
              placement="bottomRight"
              trigger={['click']}>
              <Image
                cursor="pointer"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                src={MoreSVG}
              />
            </Dropdown>
          </LastColumn>
        </>
      ),
    },
  ];

  const filterDropDown = (menues) => (
    <Menu>
      {menues.map((item) => (
        <Menu.Item onClick={() => setFilter(item.key)} key={item.key}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const threeDotMenu = () => (
    <MenuStyle>
      <MenuItem onClick={() => navigate('/time-attendance/log-trail')}>
        View LogTrail
      </MenuItem>
    </MenuStyle>
  );

  useEffect(() => {
    if (!isEmpty(timeAttendanceData)) {
      const list = [];

      timeAttendanceData.forEach((data) => {
        const filteredTable = {
          ...data,
        };
        if (
          filter === AttendanceFilterTypes.ALL &&
          filteredTable.status != AttendanceFilterTypes.ARCHIVED
        ) {
          list.push(filteredTable);
        } else if (
          filter === AttendanceFilterTypes.APPROVED &&
          filteredTable.status === AttendanceFilterTypes.APPROVED
        ) {
          list.push(filteredTable);
        } else if (
          filter === AttendanceFilterTypes.DRAFT &&
          filteredTable.status === AttendanceFilterTypes.DRAFT
        ) {
          list.push(filteredTable);
        } else if (
          filter === AttendanceFilterTypes.ARCHIVED &&
          filteredTable.status === AttendanceFilterTypes.ARCHIVED
        ) {
          list.push(filteredTable);
        }
      });

      setDataSource(list);
    }
  }, [filter, timeAttendanceData]);

  return (
    <>
      <HeaderSection>
        <LeftSection>
          <Header>
            <H type="h6">Time Attendance</H>
          </Header>
          <Div m="auto 0">
            {isAllowed(Permission.VIEW_USER_INFO) ? (
              <AttendanceDropdown
                overlay={filterDropDown(attendanceMenuItems)}
                trigger={['click']}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}>
                  <B type="b-large-semibold" display="flex">
                    {
                      attendanceMenuItems.find((item) => item.key === filter)
                        ?.name
                    }
                    ({dataSource?.length})
                    <Div pl="10px">
                      <img src={DropdownBlack} />
                    </Div>
                  </B>
                </a>
              </AttendanceDropdown>
            ) : (
              <B type="b-small" color={theme.gray600}>
                All Time Attendance
              </B>
            )}
          </Div>
        </LeftSection>

        <RightSection>
          <InputComp
            size="small"
            onChange={(e) => {
              searchDataTableCallback(e.target.value);
            }}
            placeholder="Search"
            prefix={<img src={SearchSVG} />}
          />

          <MobileHideButton>
            <PrimaryButton
              onClick={() => setVisible(true)}
              size="small"
              ml={16}>
              Upload Timesheet
            </PrimaryButton>
          </MobileHideButton>

          <MobileHideButton>
            <DropdownIcon
              marginLeft={16}
              overlay={threeDotMenu}
              placement="bottomRight"
              trigger={['click']}
            />
          </MobileHideButton>
        </RightSection>
      </HeaderSection>
      <DefaultBody>
        <TableComp
          dataSource={filteredData ? filteredData : dataSource}
          columns={columns}
          withBorders
          pagination={false}
          loading={timeAttendanceIsLoading || loadingUploadForDataExtract}
          scroll={{
            y: window.innerHeight - 400,
          }}
        />
      </DefaultBody>
      {visible && (
        <TimesheetModal
          onClose={() => setVisible(false)}
          onSubmit={(v) => uploadForDataExtract(v)}
          extractorInfo={extractorInfo}
          visible={visible}
          refetchTimeAttendance={refetchTimeAttendance}
        />
      )}
    </>
  );
};

export default TimeAttendance;
