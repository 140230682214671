import React, { FC, ReactNode } from 'react';
import { Dropdown } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import { space, SpaceProps } from 'styled-system';
import { PrimaryButton } from './buttons/PrimaryButton';
import styled from '@emotion/styled';
import { Image } from './Image';
import { DropdownWhite } from '../assets';

type Props = SpaceProps &
  Omit<ButtonProps, 'shape' | 'ghost' | 'danger' | 'type'> & {
    size?: 'small' | 'large';
    customIcon?: string;
    menu: ReactNode;
  };
const StyledDropdown = styled(Dropdown)`
  ${space}
`;

export const DropdownButton: FC<Props> = ({
  menu,
  customIcon = DropdownWhite,
  size = 'large',
  ...props
}) => {
  let className = `btn-${size} ${props.className || ''}`;

  if (props.disabled) {
    className = 'btn-disabled ' + className;
  }
  return (
    <>
      <StyledDropdown overlay={<>{menu}</>}>
        <PrimaryButton
          size={size}
          {...props}
          style={{ alignItems: 'center' }}
          className={className}>
          {props.children}
          <Image marginLeft="11px" width="10px" src={customIcon} />
        </PrimaryButton>
      </StyledDropdown>
    </>
  );
};
