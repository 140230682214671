import React, { FC } from 'react';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import theme from '../../theme';
import styled from '@emotion/styled';

const { RangePicker } = DatePicker;

type RangePropsI = RangePickerProps & {
  label?: string;
};

const RangeDatePicker = styled(RangePicker)`
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  padding-left: 16px;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  &:focus {
    border: 1px solid ${theme.blue500};
    box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1);
  }

  &:hover {
    border: 1px solid ${theme.blue500};
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
    border-radius: 4px;
  }
  div.ant-picker-dropdown .ant-picker-dropdown-range {
    width: 287px !important;
    overflow: scroll !important;
  }
`;

export const RangePickerV2: FC<RangePropsI> = (props) => {
  return <RangeDatePicker {...props} />;
};
