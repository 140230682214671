import React, { FC, useEffect } from 'react';
import { Col, Image, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Div } from '../../../components/Div';
import { getMinimumUserInfo } from '../../../store/modules/people';
import { DispatchType, RootState } from '../../../store/reducer';
import theme from '../../../theme';
import { Permission } from '../../../types';
import { isAllowed, isManager } from '../../../util/permissionUtil';
import { IndividualPeopleI } from '../../../types/people.types';
import { ArrowBackwardBlue } from '../../../assets';
import { PeopleNavigation } from '../../../components/PeopleNavigation';

const MainContainer = styled.div`
  padding: 8px 32px;
  border-bottom: 1px solid ${theme.gray300};
  @media only screen and (max-width: 768px) {
    padding: 12px 16px;
  }
`;

const BackButtonTextDiv = styled.div`
  padding-left: 6px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const BackButton = styled(Link)`
  font-weight: 500;
  line-height: 20px;
  color: ${theme.blue500};
  display: flex;
`;

interface Props {
  people?: IndividualPeopleI | null;
}

export const Header: FC<Props> = ({ people }) => {
  const dispatch: DispatchType = useDispatch();
  const allPeople = useSelector((state: RootState) => state.people.allPeoples);
  const isAllowedUser = isAllowed(Permission.VIEW_USER_INFO) || isManager();

  useEffect(() => {
    if (isAllowedUser) {
      dispatch(getMinimumUserInfo());
    }
  }, []);
  return (
    <>
      <MainContainer>
        <Row justify="space-between">
          <Col>
            <Div pt="6px">
              <BackButton to="/contact">
                <Image preview={false} src={ArrowBackwardBlue} />
                <BackButtonTextDiv>Back to Employees</BackButtonTextDiv>
              </BackButton>
            </Div>
          </Col>
          <Col>
            <PeopleNavigation people={people} allPeople={allPeople} />
          </Col>
        </Row>
      </MainContainer>
    </>
  );
};
