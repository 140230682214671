import React, { FC } from 'react';
import { B, Div } from '../../../../components';
import { Reminders } from '../../../../types/notification.types';
import { AnniversarySVG, birthday } from '../../../../assets';
import { UpcomingTypesEnum } from '../../../../types/organization.types';
interface Props {
  item: Reminders;
  isCollapsed: boolean;
}
const NotificationCelebrations: FC<Props> = ({ item, isCollapsed }) => {

  return (
    <>
      {(item.type === UpcomingTypesEnum.BirthDay ||
        item.type === UpcomingTypesEnum.Anniversary) && (
        <Div display="flex" p="12px 10px">
          <img
            src={
              item.type === UpcomingTypesEnum.BirthDay
                ? birthday
                : AnniversarySVG
            }
            width={22}
            height={22}
          />
          <B type="b-small" pl="10px">
            {isCollapsed ? item.type : 
              (item.type === UpcomingTypesEnum.BirthDay
              ? item.payload.birthdayText
              : item.payload.event)}
            
          </B>
          
        </Div>
      )}
    </>
  );
};

export default NotificationCelebrations;
