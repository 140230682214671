import React, { FC } from 'react';

import { Row, Spin } from 'antd';
import { useNavigate } from 'react-router';

import styled from '@emotion/styled';

import {
  B,
  DefaultBody,
  H,
  PrimaryButton,
  SecondaryButton,
} from '../../../../components';
import { Div } from '../../../../components/Div';
import theme from '../../../../theme';
import { SummaryDataI } from '../../../../types/people.types';
import { getPathWithOrgId } from '../../../../util/getPathWithOrgId';
import {
  getCurrentEmployment,
  getEmploymentDetail,
  getPersonalDetail,
} from '../../PeopleUtil';
import CurrentEmploymentDetails from './confirmation/CurrentEmploymentDetails';
import EmploymentDetailsConfirmation from './confirmation/EmploymentDetailsConfirmation';
import PersonalDetailsConfirmation from './confirmation/PersonalDetailsConfirmation';
import SalaryDetailsConfirmation from './confirmation/SalaryDetailsConfirmation';

const FooterComp = styled.div`
  padding: 16px 32px;
`;
export const FormBody = styled.div`
  padding: 32px 32px 8px;
  border-bottom: 1px solid ${theme.gray300};
  backgroundColor= ${theme.white};
  @media only screen and (max-width: 1200px) {
    padding: 32px 24px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0px 16px;
  }
`;
const AgainDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
interface Props {
  employmentDetail?: SummaryDataI;
  loadingPostNewEmployee: boolean;
}
const Completed: FC<Props> = ({ employmentDetail, loadingPostNewEmployee }) => {
  const navigate = useNavigate();

  const personalDetail = getPersonalDetail(employmentDetail);
  const employmentDetails = getEmploymentDetail(employmentDetail);
  const currentEmployment = getCurrentEmployment(employmentDetail);
  return (
    <>
      <DefaultBody
        p={0}
        backgroundColor={theme.white}
        border={`0.5px solid ${theme.gray300}`}
        borderRadius={4}>
        <Spin spinning={loadingPostNewEmployee}>
          <FormBody>
            <Div border={`0.5px solid  ${theme.gray300}`} pt="19px" pb="51px">
              <AgainDiv>
                <img
                  src={require('../../../../assets/Icons/alert-icons/alert-icon-success.svg')}
                />
              </AgainDiv>
              <AgainDiv>
                <H type="h6" pb="12px" pt="19px">
                  You have successfully added an Employee to the system!
                </H>
              </AgainDiv>
              <AgainDiv>
                <B type="b-large" color={theme.gray600} pb="16px">
                  You can edit your employee information anytime from the People
                  page.
                </B>
              </AgainDiv>
              <AgainDiv>
                <PrimaryButton
                  onClick={() => navigate(getPathWithOrgId('/payroll'))}>
                  Create Payrun
                </PrimaryButton>
                <SecondaryButton
                  ml={16}
                  onClick={() => {
                    // Pass a state to indicate a reset
                    navigate(getPathWithOrgId('/contact/add-new-employee'), {
                      state: { resetToStep: 1 },
                    });
                  }}>
                  Add Another Employee
                </SecondaryButton>
              </AgainDiv>
            </Div>
            <PersonalDetailsConfirmation PersonalDetails={personalDetail} />
            <Div borderBottom={`1px solid ${theme.gray300}`} pb="10px">
              <EmploymentDetailsConfirmation
                employmentDetails={employmentDetails}
              />
              <CurrentEmploymentDetails currentEmployment={currentEmployment} />
            </Div>
            <SalaryDetailsConfirmation
              payItem={employmentDetail?.savedUser.userPayItems}
              summaryData={employmentDetail}
            />
          </FormBody>
          <FooterComp>
            <Row justify="end">
              <PrimaryButton
                htmlType="submit"
                onClick={() => {
                  navigate('/contact');
                }}>
                Done
              </PrimaryButton>
            </Row>
          </FooterComp>
        </Spin>
      </DefaultBody>
    </>
  );
};

export default Completed;
