import styled from '@emotion/styled';
import { Col, Divider, Row, Tooltip } from 'antd';
import moment from 'moment';
import React, { FC, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { B, DeleteModal } from '../../../components';
import { deleteLeave } from '../../../store/actions';
import { addDotsForLongText, capitalizeUnderscore } from '../../../util/utils';
import { LeaveStatus } from '../../home/homeUtil';
import clockSvg from '../../../assets/Icons/note-icons/clock.svg';
import { convertHoursToHoursAndMin } from '../utils';
import { OrganizationLeaveI } from '../../../types';
import { ExpenseStatusTag } from '../../expenses/shared';
import theme from '../../../theme';
import { DispatchType } from '../../../store';
import { CalenderSVG } from '../../../assets';

const LeaveItem = styled.div`
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 16px 14px;
  margin-bottom: 12px;
`;
const DividerComp = styled(Divider)`
  margin: 8px 0px 14px 0px;
`;
interface Props {
  leavesDetails: OrganizationLeaveI;
}
const UserLeave: FC<Props> = ({ leavesDetails }) => {
  const dispatch: DispatchType = useDispatch();
  return (
    <LeaveItem>
      <Row justify="space-between" align="middle">
        <B type="b-default" color={theme.black}>
          {leavesDetails.reason ? (
            <Tooltip placement="topLeft" title={leavesDetails.reason}>
              {addDotsForLongText(leavesDetails.reason, 30)}
            </Tooltip>
          ) : (
            '-'
          )}
        </B>
        {leavesDetails.reviewBy ? (
          <Tooltip
            placement="topLeft"
            title={
              <Fragment>
                {leavesDetails.reviewBy && (
                  <B color="white" type="b-small">
                    Reviewed By : {leavesDetails.reviewBy.fullName}
                  </B>
                )}
                {leavesDetails.reviewBy &&
                  leavesDetails.status === LeaveStatus.REJECTED && (
                    <B color="white" type="b-small">
                      Reject Note : {leavesDetails.rejectionNote || '-'}
                    </B>
                  )}
              </Fragment>
            }>
            <div>
              <ExpenseStatusTag status={leavesDetails.status} />
            </div>
          </Tooltip>
        ) : (
          <ExpenseStatusTag status={leavesDetails.status} />
        )}
      </Row>
      <Row>
        <Col span={24}>
          <div>
            <img src={CalenderSVG} />
            <B type="b-small" display="inline" color={theme.gray600} px="8px">
              {moment(leavesDetails.date).format('YYYY.MM.DD')}
            </B>
            <B type="b-small" display="inline" color={theme.gray600}>
              &bull;{' '}
              {leavesDetails.leaveType
                ? capitalizeUnderscore(leavesDetails.leaveType)
                : '-'}
            </B>
          </div>
          <div>
            <img src={clockSvg} />
            <B type="b-small" display="inline" color={theme.gray600} pl="8px">
              {convertHoursToHoursAndMin(leavesDetails.hours)}
            </B>
          </div>
        </Col>
      </Row>
      {leavesDetails.status == LeaveStatus.PENDING && (
        <>
          <DividerComp />
          <DeleteModal
            title="Confirm Cancelation"
            buttonLabel="Cancel"
            content="Are you sure you want to cancel this leave?"
            onClickCancel={() => {
              dispatch(deleteLeave(String(leavesDetails.id)));
            }}
            openModal={
              <B type="b-small" color={theme.blue500} cursor="pointer">
                Cancel
              </B>
            }></DeleteModal>
        </>
      )}
    </LeaveItem>
  );
};

export default UserLeave;
