/* eslint-disable react/display-name */
import React, { FC, useEffect, useState } from 'react';
import {
  Badge,
  Card,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Menu,
  Row,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { B, InputComp } from '../../../components';
import { EmptyTextContainer } from '../../../components/EmptyComponent';
import {
  getTeamAvailability,
  getTeams,
  HolidaysI,
  TeamAvailabilityI,
} from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import { useWindowSize } from '../../../util/screenSize';
import { addDotsForLongText } from '../../../util/utils';
import theme from '../../../theme';
import styled from '@emotion/styled';
import { DatePickerProps } from 'antd/lib/date-picker';
import {
  DayI,
  StyledHeaderOuterGrayProps,
  StyledInnerHeaderYellowProps,
} from '../../../types/leave.types';
import { useNonDefaultHolidays } from '../../../api/holidaysHooks';
import { formatLeaveType } from '../../../util/underscoreToSpaces';
import dayjs from 'dayjs';
import { DropdownSVG, SearchSVG } from '../../../assets';

const { Title } = Typography;

interface TeamAvailablilityColumns {
  key: string;
  name: string;
  [key: string]: string;
}
//TODO: bind with antd columns

type FixedType<T extends boolean> = T extends true ? 'left' : false;
interface Columns {
  title: string | JSX.Element;
  dataIndex: string;
  key: string;
  fixed?: FixedType<true | false>;
  width: number | string;
  render?: (value: DayI) => JSX.Element | string;
  filterDropdown?: () => JSX.Element;
  filterIcon?: React.ReactNode | ((filtered: boolean) => React.ReactNode);
}

const StyledBadge = styled(Badge)`
  .ant-badge-status-text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }
`;

const StyledHeaderOuterGray = styled.div<StyledHeaderOuterGrayProps>`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: ${theme.gray200};
  margin-right: ${(props) => (props.dayName === 'Saturday' ? '1px' : '')};
`;

const StyledHeaderInner = styled.div`
  position: absolute;
  left: 32.26%;
  right: 29.03%;
  top: 32%;

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  color: ${theme.black};
`;

const StyledToolTip = styled(Tooltip)`
  .ant-tooltip-inner {
    border: 1px solid ${theme.black} !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
  }
`;

const StyledInnerComponent = styled.div<StyledInnerHeaderYellowProps>`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 41%;
  height: 8px;
  border-radius: ${(props) =>
    props.currentDateNumber === 1 ? '8px 0px 0px 8px' : ''};
`;

const StyledWeekEndOuterComponent = styled.div<StyledHeaderOuterGrayProps>`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: ${theme.white};
  border-left: ${(props) =>
    props.dayName === 'Saturday' ? `1px solid ${theme.gray300}` : ''};
  border-right: ${(props) =>
    props.dayName === 'Sunday' ? `1px solid ${theme.gray300}` : ''};
`;

const StyledOuterComponentCommon = styled.div`
  position: absolute;
  left: 0%;
  right: 0%;
`;

const StyledMenuComponent = styled(Menu)`
  background: ${theme.white};
  border: 0.5px solid ${theme.gray300};
  width: 181px;
  box-sizing: border-box;
  border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
`;

const StyledMenuItemWithoutPadding = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${theme.black};
`;

const StyledCardComponent = styled(Card)`
  border: 0.5px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 8px;
  .ant-card-body {
    padding: 0px;
  }
`;

const StyledRowComponent = styled(Row)`
  padding: 20px 10px 0 10px;
`;

const StyledDatePicker = styled(DatePicker)<DatePickerProps>`
  .ant-picker-input > input {
    color: ${theme.blue400};
    width: 100%;
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 50%;
    }
  }
  margin: 0px 20px 20px;
  width: 100%;
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 50%;
  }
`;

const StyledRowNotMobile = styled(Row)`
  padding: 18px 19px 18px 16px;
  width: 100%;
  justify-content: space-between;
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const StyledColLoading = styled(Col)`
  padding: 4px;
  padding-left: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTable = styled<React.ComponentType<TableProps<TeamAvailabilityI>>>(
  Table,
)`
  .ant-table th {
    font-size: 12px;
    border-top: 1px solid ${theme.gray300};
    border-bottom: 1px solid ${theme.gray300};
    padding: 12px 12px 12px 16px;
    background-color: ${theme.white} !important;
  }

  @media (min-width: 1024px) {
    .ant-table-body {
      height: 650px;
    }
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${theme.gray300};
    padding: 10px 10px 10px 16px;
    font-size: 12px;
  }
  .ant-table-filter-trigger {
    &:hover {
      background-color: ${theme.white};
    }
  }
`;

const StyledDatePickerDesktop = styled(DatePicker)<DatePickerProps>`
  .ant-picker-input > input {
    color: ${theme.contract.receiver.label};
  }
`;

const StyledInputDiv = styled.div`
  padding-inline: 10px;
  padding-block: 12px;
  border-radius: 2px;
  margin-bottom: 60px;
  border: 1px solid ${theme.gray300};
  font-size: 10px !important;
`;

const StyledImg = styled.img`
  width: 16px;
  height: 16px;
  margin-top: 6px;
  background: none !important;
`;
type StyledDivProps = {
  filtered: boolean;
};

const StyledFilterDiv = styled.div<StyledDivProps>`
  height: 100%;
  background: ${(props) => (props.filtered ? 'none' : 'white')};
  padding: 0px;
  border: none;
  display: flex;
`;

const StyledInput = styled(InputComp)`
  margin: 'auto';
  display: 'flex';
  border: 0.5px ${theme.blue300} solid;
  border-radius: 2;
  align-content: 'center';
  text-align: 'center';
`;

const blueComponent = (currentDateNumber: number) => (
  <StyledInnerComponent
    style={{ backgroundColor: theme.blue100 }}
    currentDateNumber={currentDateNumber}
  />
);

const renderTooltip = (data: DayI) => {
  const leaveDay = data.leaveDay;

  return leaveDay.map(function (value, index) {
    return (
      <div key={index}>
        {index > 0 ? <br></br> : ''}
        <B type="b-large" color={theme.white}>
          {formatLeaveType(value.leaveType)}
        </B>
        {value?.leaveHours && <div>{value.leaveHours}</div>}
      </div>
    );
  });
};
const yellowComponent = (data: DayI, currentDateNumber: number) => {
  return (
    <StyledToolTip
      title={renderTooltip(data)}
      color={theme.blue900}
      key="&#$BKFd"
      overlayClassName="custom-tooltip">
      <StyledInnerComponent
        style={{ backgroundColor: theme.red800 }}
        currentDateNumber={currentDateNumber}></StyledInnerComponent>
    </StyledToolTip>
  );
};

const redComponent = (currentDateNumber: number) => (
  <StyledInnerComponent
    style={{ backgroundColor: theme.red700 }}
    currentDateNumber={currentDateNumber}
  />
);

const weekendComponent = (dayName: string) => (
  <StyledWeekEndOuterComponent dayName={dayName}>
    <StyledOuterComponentCommon
      style={{ backgroundColor: theme.gray100, top: '41%', height: '8px' }}
    />
  </StyledWeekEndOuterComponent>
);

const headerTitle = (
  i: number,
  dayName: string,
  isHoliday: boolean,
  holidayName: string,
) => {
  let header;

  if (dayName == 'Saturday' || dayName == 'Sunday') {
    header = (
      <StyledHeaderOuterGray dayName={dayName}>
        <StyledHeaderInner>{i}</StyledHeaderInner>
      </StyledHeaderOuterGray>
    );
  } else if (isHoliday) {
    header = (
      <StyledToolTip
        title={
          <B type="b-large" color={theme.white}>
            {holidayName}
          </B>
        }
        color={theme.blue900}
        key="23FjgK#$#"
        overlayClassName="custom-tooltip">
        <StyledOuterComponentCommon
          style={{ background: theme.red700, bottom: '0%', top: '0%' }}>
          <StyledHeaderInner>{i}</StyledHeaderInner>
        </StyledOuterComponentCommon>
      </StyledToolTip>
    );
  } else {
    header = (
      <StyledOuterComponentCommon
        style={{ background: theme.white, bottom: '0%', top: '0%' }}>
        <StyledHeaderInner>{i}</StyledHeaderInner>
      </StyledOuterComponentCommon>
    );
  }
  return header;
};

const renderCell = (i: DayI, dayName: string, currentDateNumber: number) => {
  let cell;
  if (_.get(i, 'dayType', false)) {
    if (i.dayType == 'available') {
      cell = blueComponent(currentDateNumber);
    } else if (i.dayType == 'onLeave') {
      cell = yellowComponent(i, currentDateNumber);
    } else if (i.dayType == 'holiday') {
      cell = redComponent(currentDateNumber);
    } else if (i.dayType == 'weekend') {
      if (dayName) {
        cell = weekendComponent(dayName);
      } else {
        cell = weekendComponent;
      }
    }
  }
  return cell;
};

let currentMonth = moment().format('YYYY/MM');
let days = moment(moment()).daysInMonth();
let team = 'All';

const TeamAvailability: FC = () => {
  const dispatch: DispatchType = useDispatch();

  const allHolidays: HolidaysI[] | undefined = useNonDefaultHolidays().data;
  const allHolidaysLoading: boolean = useNonDefaultHolidays().isLoading;

  const teamAvailability: TeamAvailabilityI[] = useSelector(
    (state: RootState) => state.dashboard.teamAvailability,
  );
  const teamAvailabilityLoading: boolean = useSelector(
    (state: RootState) => state.dashboard.teamAvailabilityLoading,
  );
  const allTeams: any = useSelector(
    (state: RootState) => state.dashboard.teams,
  );
  const [columns, setColumns] = useState<
    ColumnProps<TeamAvailablilityColumns>[]
  >([]);

  const onChange = async (date: Moment | null) => {
    currentMonth = moment(date).format('YYYY/MM');
    days = moment(currentMonth).daysInMonth();
    await dispatch(getTeamAvailability(currentMonth, team));
  };
  const menu = (data: string[] = []) => (
    <StyledMenuComponent>
      <Menu.Item
        key={0}
        onClick={() => {
          team = 'All';
          dispatch(getTeamAvailability(currentMonth, team));
        }}>
        <StyledMenuItemWithoutPadding>
          All Team Availability
        </StyledMenuItemWithoutPadding>
      </Menu.Item>
      {data.map((value, index) => {
        return (
          <>
            <Menu.Divider />
            <Menu.Item
              key={index + 1}
              onClick={() => {
                team = value;
                dispatch(getTeamAvailability(currentMonth, team));
              }}>
              <StyledMenuItemWithoutPadding>
                {value}
              </StyledMenuItemWithoutPadding>
            </Menu.Item>
          </>
        );
      })}
    </StyledMenuComponent>
  );

  useEffect(() => {
    dispatch(getTeams());
    dispatch(getTeamAvailability(currentMonth, team));
  }, []);
  const locale = {
    emptyText: (
      <EmptyTextContainer description="There’s no data available to display!" />
    ),
  };
  const [filteredAvailability, setFilteredAvailability] =
    useState<TeamAvailabilityI[]>(teamAvailability);
  const handleSearch = (value: string) => {
    if (value.length > 2) {
      const tempFilteredAvailability = teamAvailability.filter((item) => {
        return item.name
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase());
      });
      setFilteredAvailability(tempFilteredAvailability);
    } else {
      setFilteredAvailability(teamAvailability);
    }
  };
  const renderColumns = async (dayCount: number) => {
    const newColumns: Columns[] = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        fixed: isMobile ? false : 'left',
        width: '30px',
        render: (value: DayI) => {
          return addDotsForLongText(String(value), 25);
        },
        filterDropdown: () => (
          <StyledInputDiv>
            <StyledInput
              placeholder="Search Employee"
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              allowClear
              size="small"
              height={40}
              width="100%"
            />
          </StyledInputDiv>
        ),
        filterIcon: (filtered: boolean) => (
          <StyledFilterDiv filtered={filtered}>
            <StyledImg src={SearchSVG} />
          </StyledFilterDiv>
        ),
      },
    ];
    for (let i = 0; i < dayCount; i++) {
      const currentDay =
        currentMonth +
        '/' +
        (i + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 });
      const dayName = moment(currentDay).format('dddd');
      const currentDateNumber = i + 1;
      let isHoliday = false;
      let holidayName = '';

      allHolidays?.forEach((element) => {
        if (moment(element['date']).isSame(new Date(currentDay), 'day')) {
          isHoliday = true;
          holidayName = element['description'];
        }
      });
      newColumns.push({
        title: headerTitle(i + 1, dayName, isHoliday, holidayName),
        dataIndex: 'day' + currentDateNumber,
        key: 'day' + currentDateNumber,
        width: '6px',

        render: (value: DayI) => (
          <>{value ? renderCell(value, dayName, currentDateNumber) : ''}</>
        ),
      });
    }
    setColumns(newColumns);
  };
  useEffect(() => {
    renderColumns(days);
    setFilteredAvailability(teamAvailability);
  }, [teamAvailability]);
  const { isMobile } = useWindowSize();
  return (
    <StyledCardComponent>
      {isMobile ? (
        <>
          <StyledRowComponent gutter={[20, 20]}>
            <Col xs={{ span: '100%' }} lg={{ span: '100%' }}>
              <Dropdown
                arrow={true}
                overlay={menu(allTeams)}
                trigger={['click']}
                placement="bottomCenter">
                {team == 'All' ? (
                  <Title level={4}>
                    All Team Availability <DownOutlined />
                  </Title>
                ) : (
                  <Title level={4}>
                    {team} <DownOutlined />
                  </Title>
                )}
              </Dropdown>
            </Col>
          </StyledRowComponent>
          <Row gutter={[20, 0]}>
            <StyledDatePicker
              onChange={(d) => onChange(moment(d.toDate()))}
              picker="month"
              defaultValue={dayjs()}
              format="MMMM"
              allowClear={false}
              inputReadOnly={true}
            />
          </Row>
          <Row gutter={[16, 20]} justify="center">
            <StyledCol xs={20} sm={20} md={30}>
              <StyledBadge color={theme.blue100} text="Available" />
              <StyledBadge color={theme.orange500} text="Onleave" />
              <StyledBadge color={theme.red700} text="Holiday" />
            </StyledCol>
          </Row>
        </>
      ) : (
        <StyledRowNotMobile>
          <Col xs={{ span: 6 }} lg={{ span: 8 }}>
            <Dropdown
              arrow={false}
              overlay={menu(allTeams)}
              trigger={['click']}
              placement="bottomCenter">
              {team == 'All' ? (
                <Title level={4}>
                  All Team Availability
                  <img src={DropdownSVG} />
                </Title>
              ) : (
                <Title level={4}>
                  {team}
                  <img src={DropdownSVG} />
                </Title>
              )}
            </Dropdown>
          </Col>
          <Col
            style={{ marginTop: '3px', textAlign: 'right' }}
            xs={{ span: 4, offset: 6 }}
            md={{ span: 6, offset: 6 }}
            lg={{ span: 8, offset: 3 }}>
            <StyledBadge color={theme.blue100} text="Available" />
            &nbsp; &nbsp; &nbsp;
            <StyledBadge
              style={{ marginTop: '5px !important' }}
              color={theme.orange500}
              text="Onleave"
            />
            &nbsp; &nbsp; &nbsp;
            <StyledBadge
              style={{ marginTop: '5px !important' }}
              color={theme.red700}
              text="Holiday"
            />
          </Col>
          <Col
            xs={{ span: 4 }}
            lg={{ span: 4, offset: 0 }}
            style={{ marginRight: 3, paddingRight: 0, textAlign: 'right' }}>
            <StyledDatePickerDesktop
              onChange={(d) => onChange(moment(d.toDate()))}
              picker="month"
              defaultValue={dayjs()}
              format="MMMM"
              allowClear={false}
              inputReadOnly={true}
            />
          </Col>
        </StyledRowNotMobile>
      )}
      <Row>
        <Col span={24}>
          {allHolidaysLoading ? (
            <>
              <StyledColLoading>
                <Spin />
              </StyledColLoading>
            </>
          ) : (
            <>
              <StyledTable
                columns={columns}
                dataSource={filteredAvailability}
                scroll={{ x: 1500, y: 650 }}
                sticky
                loading={teamAvailabilityLoading}
                pagination={false}
                locale={locale}
              />
            </>
          )}
        </Col>
      </Row>
    </StyledCardComponent>
  );
};
export default TeamAvailability;
