import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { DataImportUserPayLoad } from '../store/actions';
import { UploadForDataExtractI, UploadTimeSheetI } from '../types';
import { UploadForDataValidateI } from '../types/dataImport.types';
import { message } from 'antd';
import { getApiError } from '../util/getApiError';

export const useUploadForDataExtract = () => {
  return useMutation<
    DataImportUserPayLoad[],
    AxiosError,
    UploadForDataExtractI
  >(async (data: UploadForDataExtractI) => {
    const formData = new FormData();
    formData.append('file', data.file);
    const response: AxiosResponse<DataImportUserPayLoad[]> = await axios.post(
      `/extract-data?type=${data.type}&csvType=${data.csvType}`,
      formData,
    );

    return response.data;
  });
};
export const useUploadForDataValidate = () => {
  return useMutation<
    DataImportUserPayLoad[],
    AxiosError,
    UploadForDataValidateI
  >(
    async (data: UploadForDataValidateI) => {
      const response: AxiosResponse<DataImportUserPayLoad[]> = await axios.post(
        `/extract-data/validate?type=${data.type}&csvType=${data.csvType}`,
        { payload: data.payload },
      );

      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};
