import * as ExcelJS from 'exceljs';

import { camelCaseToNormalText } from '../../../../../util/camelCaseToNormal.util';
import { centerTexts } from '../../../../../util/exceljs.util';
import { ColumnItemType } from '../generatePayrollExport';
import {
  DataImportUserPayLoad,
  PayrollViewDataI,
} from '../../../../../store/actions';
import { krestonBasicPayInfoTitlesI } from '../../../../../types/krestonPayrollData.types';
import { OrganizationI } from '../../../../../types/organization.types';
import { numberToColumnName } from '../../excelUtilFunctions';

export const generateKrestonPayrollDataSheet = ({
  payItemColumns,
  calculationColumns,
  bankColumns,
  generalInfoColumns,
  worksheet,
  userRows,
  dataPayload,
  leaveColumnNames,
  organizationData,
  payrollViewData,
}: {
  payItemColumns: ColumnItemType[];
  calculationColumns: ColumnItemType[];
  bankColumns: ColumnItemType[];
  generalInfoColumns: ColumnItemType[];
  leaveColumnNames: string[] | undefined;
  dataPayload: DataImportUserPayLoad[] | undefined;
  worksheet: ExcelJS.Worksheet;
  userRows: {
    [key: string]: string;
  }[];
  organizationData: OrganizationI;
  payrollViewData: PayrollViewDataI;
}) => {
  const leaveColumns = leaveColumnNames?.map((item) => {
    return {
      key: `${item}`,
      width: 15,
    };
  });

  const krestonGeneralInfoColumns = [
    { key: 'epfNumber', width: 10 },
    { key: 'empId', width: 10 },
    { key: 'designation', width: 20 },
    { key: 'fullName', width: 20 },
    { key: 'nameWithInitials', width: 20 },
  ];

  //
  //       | APIT LIABLE  |
  //
  //

  // kreston basic pay items
  const krestonBasicPayInfo = dataPayload?.flatMap((item) => {
    return item.values.payItems
      .filter((payItem) => payItem.type === 'BASIC')
      .map((payItem) => ({ payItem: payItem }));
  });

  const krestonBasicPayInfoTitles: krestonBasicPayInfoTitlesI[] = [
    ...new Set(krestonBasicPayInfo?.map((item) => item.payItem.payTitle)),
  ]
    .map((title) => {
      const uniquePayItem = krestonBasicPayInfo?.find(
        (item) => item.payItem.payTitle === title,
      );

      if (uniquePayItem) {
        return {
          title: uniquePayItem?.payItem.payTitle,
          isEpf: uniquePayItem?.payItem.isEpf,
          isTaxable: uniquePayItem?.payItem.isTaxable,
          type: uniquePayItem?.payItem.type,
        };
      }
    })
    .filter(
      (titles): titles is krestonBasicPayInfoTitlesI => titles !== undefined,
    );

  const krestonBasicPayInfoColumns = krestonBasicPayInfoTitles.map(
    (titles: krestonBasicPayInfoTitlesI) => ({
      key: titles.title,
      width: 10,
    }),
  );

  // variable allowances
  // EPF enabled
  const krestonVariableAllowanceEPFEnabledInfo = dataPayload?.flatMap(
    (item) => {
      return item.values.payItems
        .filter(
          (payItem) =>
            payItem.type === 'VARIABLE_ALLOWANCE' &&
            payItem.isEpf &&
            payItem.isTaxable,
        )
        .map((payItem) => ({ payItem: payItem }));
    },
  );
  const krestonVariableAllowanceEPFEnabledTitles = [
    ...new Set(
      krestonVariableAllowanceEPFEnabledInfo?.map(
        (item) => item.payItem.payTitle,
      ),
    ),
  ]
    .map((title) => {
      const uniquePayItem = krestonVariableAllowanceEPFEnabledInfo?.find(
        (item) => item.payItem.payTitle === title,
      );
      if (uniquePayItem) {
        return {
          title: uniquePayItem.payItem.payTitle,
          isEpf: uniquePayItem?.payItem.isEpf,
          isTaxable: uniquePayItem?.payItem.isTaxable,
          type: uniquePayItem?.payItem.type,
        };
      }
    })
    .filter(
      (titles): titles is krestonBasicPayInfoTitlesI => titles !== undefined,
    );
  const krestonVariableAllowanceEPFEnabledColumns =
    krestonVariableAllowanceEPFEnabledTitles.map(
      (titles: krestonBasicPayInfoTitlesI) => ({
        key: titles.title,
        width: 10,
      }),
    );
  const krestonVariableAllowanceColumnsEPFEnabledSection = [
    ...krestonVariableAllowanceEPFEnabledColumns,
  ];

  // fixed allowances
  // epf enabled
  const krestonFixedAllowanceEPFEnabledInfo = dataPayload?.flatMap((item) => {
    return item.values.payItems
      .filter(
        (payItem) =>
          payItem.type === 'FIXED_ALLOWANCE' &&
          payItem.isEpf &&
          payItem.isTaxable,
      )
      .map((payItem) => ({ payItem: payItem }));
  });

  const krestonFixedAllowanceEPFEnabledTitles = [
    ...new Set(
      krestonFixedAllowanceEPFEnabledInfo?.map((item) => item.payItem.payTitle),
    ),
  ]
    .map((title) => {
      const uniquePayItem = krestonFixedAllowanceEPFEnabledInfo?.find(
        (item) => item.payItem.payTitle === title,
      );
      if (uniquePayItem) {
        return {
          title: uniquePayItem.payItem.payTitle,
          isEpf: uniquePayItem?.payItem.isEpf,
          isTaxable: uniquePayItem?.payItem.isTaxable,
          type: uniquePayItem?.payItem.type,
        };
      }
    })
    .filter(
      (titles): titles is krestonBasicPayInfoTitlesI => titles !== undefined,
    );
  const krestonFixedAllowanceEPFEnabledColumns =
    krestonFixedAllowanceEPFEnabledTitles.map(
      (titles: krestonBasicPayInfoTitlesI) => ({
        key: titles,
        width: 10,
      }),
    );

  const krestonFixedAllowanceColumnsEPFEnabledSection = [
    ...krestonFixedAllowanceEPFEnabledColumns,
  ];

  // lump sum
  const lumpSumEPF = Array.from(
    dataPayload
      ?.flatMap((item) => {
        return item.values.payItems
          .filter((payItem) => payItem.type === 'LUMP_SUM' && payItem.isEpf)
          .map((payItem) => ({
            title: payItem.payTitle,
            isEpf: payItem.isEpf,
            isTaxable: payItem.isTaxable,
            type: payItem.type,
          }));
      })
      .reduce((map, item) => map.set(item.title, item), new Map())
      .values() || [],
  );

  const lumpSumEPFCol = lumpSumEPF.map((title) => ({
    key: title.title,
    width: 10,
  }));

  // epf liable non cash benefits
  const krestonNonCashEpfTitles = [
    ...new Set(
      dataPayload?.flatMap((item) => {
        return item.values.payItems
          .filter(
            (payItem) =>
              payItem.type === 'NON_CASH_BENEFITS' &&
              payItem.isEpf &&
              payItem.isTaxable,
          )
          .map((payItem) => payItem);
      }),
    ),
  ].map((title) => ({
    title: title.payTitle,
    isEpf: title.isEpf,
    isTaxable: title.isTaxable,
    type: title.type,
  }));
  const krestonNonCashEPFColumns = krestonNonCashEpfTitles.map((titles) => ({
    key: titles.title,
    width: 10,
  }));
  const krestonNonCashEpfSection = [...krestonNonCashEPFColumns];

  const krestonDeductionsEPFEnabledInfo = dataPayload?.flatMap((item) => {
    return item.values.payItems
      .filter(
        (payItem) =>
          payItem.type === 'DEDUCTION' && payItem.isEpf && payItem.isTaxable,
      )
      .map((payItem) => ({ payItem: payItem }));
  });
  const krestonDeductionsEPFEnabledTitles = [
    ...new Set(
      krestonDeductionsEPFEnabledInfo?.map(
        (payItem) => payItem.payItem.payTitle,
      ),
    ),
  ]
    .map((title) => {
      const uniquePayItem = krestonDeductionsEPFEnabledInfo?.find(
        (item) => item.payItem.payTitle === title,
      );
      if (uniquePayItem) {
        return {
          title: uniquePayItem.payItem.payTitle,
          isEpf: uniquePayItem?.payItem.isEpf,
          isTaxable: uniquePayItem?.payItem.isTaxable,
          type: uniquePayItem?.payItem.type,
        };
      }
    })
    .filter(
      (titles): titles is krestonBasicPayInfoTitlesI => titles !== undefined,
    );

  const krestonDeductionsEPFEnabledColumns =
    krestonDeductionsEPFEnabledTitles.map(
      (titles: krestonBasicPayInfoTitlesI) => ({
        key: titles.title,
        width: 10,
      }),
    );
  const krestonDeductionsEPFEnabledColumnsSection = [
    ...krestonDeductionsEPFEnabledColumns,
    { key: 'grossEarningsLiableForEPF', width: 10 },
  ];

  // epf disabled
  // no epf basic pay
  const krestonBasicNoEPFPayInfo = dataPayload?.flatMap((item) => {
    return item.values.payItems
      .filter(
        (payItem) =>
          payItem.type === 'BASIC' && !payItem.isEpf && payItem.isTaxable,
      )
      .map((payItem) => ({ payItem: payItem }));
  });
  const krestonBasicNoEPFPayInfoTitles = [
    ...new Set(
      krestonBasicNoEPFPayInfo?.map((payItem) => payItem.payItem.payTitle),
    ),
  ]
    .map((title) => {
      const uniquePayItem = krestonBasicNoEPFPayInfo?.find(
        (item) => item.payItem.payTitle === title,
      );
      if (uniquePayItem) {
        return {
          title: uniquePayItem.payItem.payTitle,
          isEpf: uniquePayItem?.payItem.isEpf,
          isTaxable: uniquePayItem?.payItem.isTaxable,
          type: uniquePayItem?.payItem.type,
        };
      }
    })
    .filter(
      (titles): titles is krestonBasicPayInfoTitlesI => titles !== undefined,
    );
  const krestonBasicNoEPFPayInfoColumns = krestonBasicNoEPFPayInfoTitles.map(
    (titles: krestonBasicPayInfoTitlesI) => ({
      key: titles.title,
      width: 10,
    }),
  );

  // fixed allowance (no epf)
  const krestonFixedAllowanceEPFDisabledInfo = dataPayload?.flatMap((item) => {
    return item.values.payItems
      .filter(
        (payItem) =>
          payItem.type === 'FIXED_ALLOWANCE' &&
          !payItem.isEpf &&
          payItem.isTaxable,
      )
      .map((payItem) => ({ payItem: payItem }));
  });
  const krestonFixedAllowanceEPFDisabledTitles = [
    ...new Set(
      krestonFixedAllowanceEPFDisabledInfo?.map(
        (payItem) => payItem.payItem.payTitle,
      ),
    ),
  ]
    .map((title) => {
      const uniquePayItem = krestonFixedAllowanceEPFDisabledInfo?.find(
        (item) => item.payItem.payTitle === title,
      );
      if (uniquePayItem) {
        return {
          title: uniquePayItem.payItem.payTitle,
          isEpf: uniquePayItem?.payItem.isEpf,
          isTaxable: uniquePayItem?.payItem.isTaxable,
          type: uniquePayItem?.payItem.type,
        };
      }
    })
    .filter(
      (titles): titles is krestonBasicPayInfoTitlesI => titles !== undefined,
    );
  const krestonFixedAllowanceEPFDisabledColumns =
    krestonFixedAllowanceEPFDisabledTitles.map(
      (titles: krestonBasicPayInfoTitlesI) => ({
        key: titles.title,
        width: 10,
      }),
    );
  const crestonFixedAllowanceColumnsEPFDisabledSection = [
    ...krestonFixedAllowanceEPFDisabledColumns,
  ];

  const krestonVariableAllowanceEPFDisabledInfo = dataPayload?.flatMap(
    (item) => {
      return item.values.payItems
        .filter(
          (payItem) =>
            payItem.type === 'VARIABLE_ALLOWANCE' &&
            !payItem.isEpf &&
            payItem.isTaxable,
        )
        .map((payItem) => ({ payItem: payItem }));
    },
  );
  const krestonVariableAllowanceEPFDisabledTitles = [
    ...new Set(
      krestonVariableAllowanceEPFDisabledInfo?.map(
        (payItem) => payItem.payItem.payTitle,
      ),
    ),
  ]
    .map((title) => {
      const uniquePayItem = krestonVariableAllowanceEPFDisabledInfo?.find(
        (item) => item.payItem.payTitle === title,
      );
      if (uniquePayItem) {
        return {
          title: uniquePayItem.payItem.payTitle,
          isEpf: uniquePayItem?.payItem.isEpf,
          isTaxable: uniquePayItem?.payItem.isTaxable,
          type: uniquePayItem?.payItem.type,
        };
      }
    })
    .filter(
      (titles): titles is krestonBasicPayInfoTitlesI => titles !== undefined,
    );
  const krestonVariableAllowanceEPFDisabledColumns =
    krestonVariableAllowanceEPFDisabledTitles.map(
      (titles: krestonBasicPayInfoTitlesI) => ({
        key: titles.title,
        width: 10,
      }),
    );
  const krestonVariableAllowanceColumnsEPFDisabledSection = [
    ...krestonVariableAllowanceEPFDisabledColumns,
  ];

  // lump sum
  const lumpSumNoEPF = Array.from(
    dataPayload
      ?.flatMap((item) => {
        return item.values.payItems
          .filter((payItem) => payItem.type === 'LUMP_SUM' && !payItem.isEpf)
          .map((payItem) => ({
            title: payItem.payTitle,
            isEpf: payItem.isEpf,
            isTaxable: payItem.isTaxable,
            type: payItem.type,
          }));
      })
      .reduce((map, item) => map.set(item.title, item), new Map())
      .values() || [],
  );
  const lumpSumNoEPFCol = lumpSumNoEPF.map((title) => ({
    key: title.title,
    width: 10,
  }));

  // epf not liable non cash benefits
  const krestonNonCashNoEpfTitles = [
    ...new Set(
      dataPayload?.flatMap((item) => {
        return item.values.payItems
          .filter(
            (payItem) =>
              payItem.type === 'NON_CASH_BENEFITS' &&
              !payItem.isEpf &&
              payItem.isTaxable,
          )
          .map((payItem) => payItem);
      }),
    ),
  ].map((title) => ({
    title: title.payTitle,
    isEpf: title.isEpf,
    isTaxable: title.isTaxable,
    type: title.type,
  }));
  const krestonNonCashNoEPFColumns = krestonNonCashNoEpfTitles.map(
    (titles) => ({
      key: titles.title,
      width: 10,
    }),
  );
  const krestonNonCashNoEpfSection = [...krestonNonCashNoEPFColumns];

  const krestonDeductionEPFDisabledInfo = dataPayload?.flatMap((item) => {
    return item.values.payItems
      .filter(
        (payItem) =>
          payItem.type === 'DEDUCTION' && !payItem.isEpf && payItem.isTaxable,
      )
      .map((payItem) => ({ payItem: payItem }));
  });
  const krestonDeductionEPFDisabledTitles = [
    ...new Set(
      krestonDeductionEPFDisabledInfo?.map(
        (payItem) => payItem.payItem.payTitle,
      ),
    ),
  ]
    .map((title) => {
      const uniquePayItem = krestonDeductionEPFDisabledInfo?.find(
        (item) => item.payItem.payTitle === title,
      );
      if (uniquePayItem) {
        return {
          title: uniquePayItem.payItem.payTitle,
          isEpf: uniquePayItem?.payItem.isEpf,
          isTaxable: uniquePayItem?.payItem.isTaxable,
          type: uniquePayItem?.payItem.type,
        };
      }
    })
    .filter(
      (titles): titles is krestonBasicPayInfoTitlesI => titles !== undefined,
    );
  const krestonDeductionEPFDisabledColumns =
    krestonDeductionEPFDisabledTitles.map(
      (titles: krestonBasicPayInfoTitlesI) => ({
        key: titles.title,
        width: 10,
      }),
    );
  const krestonDeductionEPFDisabledColumnSection = [
    ...krestonDeductionEPFDisabledColumns,
  ];

  //
  //    NOT APIT LIABLE
  //

  // basic pay items
  const krestonNoAPITPayItem = [
    ...new Set(
      dataPayload?.flatMap((item) => {
        return item.values.payItems
          .filter(
            (payItem) =>
              payItem.type === 'BASIC' && !payItem.isEpf && !payItem.isTaxable,
          )
          .map((payItem) => payItem);
      }),
    ),
  ].map((payItem) => ({
    title: payItem.payTitle,
    isEpf: payItem.isEpf,
    isTaxable: payItem.isTaxable,
    type: payItem.type,
  }));
  const krestonNoAPITPayItemColumns = krestonNoAPITPayItem.map((titles) => ({
    key: titles.title,
    width: 10,
  }));

  // fixed allowances
  const krestonNoAPITFixedAllowances = [
    ...new Set(
      dataPayload?.flatMap((item) => {
        return item.values.payItems
          .filter(
            (payItem) =>
              payItem.type === 'FIXED_ALLOWANCE' &&
              !payItem.isEpf &&
              !payItem.isTaxable,
          )
          .map((payItem) => payItem);
      }),
    ),
  ];

  const krestonNoAPITFixedAllowancesTitles =
    krestonNoAPITFixedAllowances.reduce((acc, val) => {
      const exists = acc.find((item) => item.title === val.payTitle);
      if (!exists) {
        acc.push({
          title: val.payTitle,
          isEpf: val.isEpf,
          isTaxable: val.isTaxable,
          type: val.type,
        });
      }
      return acc;
    }, []);

  const uniqueNoAPITFixedTitles = new Set(
    krestonNoAPITFixedAllowancesTitles.map((val) => val.title),
  );

  const krestonNoAPITFixedAllowancesColumns = Array.from(
    uniqueNoAPITFixedTitles,
  ).map((title) => ({
    key: title,
    width: 10,
  }));

  // variable allowances
  const krestonNoAPITVariableAllowances = [
    ...new Set(
      dataPayload?.flatMap((item) => {
        return item.values.payItems
          .filter(
            (payItem) =>
              payItem.type === 'VARIABLE_ALLOWANCE' &&
              !payItem.isEpf &&
              !payItem.isTaxable,
          )
          .map((payItem) => payItem);
      }),
    ),
  ];

  const krestonNoAPITVariableAllowancesTitles =
    krestonNoAPITVariableAllowances.reduce((acc, val) => {
      const exists = acc.find((item) => item.title === val.payTitle);
      if (!exists) {
        acc.push({
          title: val.payTitle,
          isEpf: val.isEpf,
          isTaxable: val.isTaxable,
          type: val.type,
        });
      }
      return acc;
    }, []);

  const uniqueNoAPITVariableTitles = new Set(
    krestonNoAPITVariableAllowancesTitles.map((val) => val.title),
  );

  const krestonNoAPITVariableAllowancesColumns = Array.from(
    uniqueNoAPITVariableTitles,
  ).map((title) => ({
    key: title,
    width: 10,
  }));

  // no cash benefits
  const krestonNoAPITNoCash = [
    ...new Set(
      dataPayload?.flatMap((item) => {
        return item.values.payItems
          .filter(
            (payItem) =>
              payItem.type === 'NON_CASH_BENEFITS' &&
              !payItem.isEpf &&
              !payItem.isTaxable,
          )
          .map((payItem) => payItem);
      }),
    ),
  ];

  const krestonNoAPITNoCashTitles = krestonNoAPITNoCash.reduce((acc, val) => {
    const exists = acc.find((item) => item.title === val.payTitle);
    if (!exists) {
      acc.push({
        title: val.payTitle,
        isEpf: val.isEpf,
        isTaxable: val.isTaxable,
        type: val.type,
      });
    }
    return acc;
  }, []);

  const uniqueNoAPITNoCashTitles = new Set(
    krestonNoAPITNoCashTitles.map((val) => val.title),
  );

  const krestonNoAPITNoCashColumns = Array.from(uniqueNoAPITNoCashTitles).map(
    (title) => ({
      key: title,
      width: 10,
    }),
  );

  // deductions
  const krestonNoAPITDeductions = [
    ...new Set(
      dataPayload?.flatMap((item) => {
        return item.values.payItems
          .filter(
            (payItem) =>
              payItem.type === 'DEDUCTION' &&
              !payItem.isEpf &&
              !payItem.isTaxable &&
              payItem.payTitle !== 'Stamp Duty',
          )
          .map((payItem) => payItem);
      }),
    ),
  ];
  const krestonNoAPITDeductionsTitles = krestonNoAPITDeductions.reduce(
    (acc, val) => {
      const exists = acc.find((item) => item.title === val.payTitle);
      if (!exists) {
        acc.push({
          title: val.payTitle,
          isEpf: val.isEpf,
          isTaxable: val.isTaxable,
          type: val.type,
        });
      }
      return acc;
    },
    [],
  );
  const uniqueKrestonNoAPITDeductionsTitles = new Set(
    krestonNoAPITDeductionsTitles.map((val) => val.title),
  );
  const krestonNoAPITDeductionsColumns = Array.from(
    uniqueKrestonNoAPITDeductionsTitles,
  ).map((title) => ({
    key: title,
    width: 10,
  }));

  const krestonStampTempInfo = dataPayload?.flatMap((item) => {
    return item.values.payItems
      .filter(
        (payItem) =>
          payItem.type === 'DEDUCTION' && payItem.payTitle === 'Stamp Duty',
      )
      .map((payItem) => ({ payItem: payItem }));
  });
  const krestonStampDuty = [
    ...new Set(
      dataPayload?.flatMap((item) => {
        return item.values.payItems
          .filter(
            (payItem) =>
              payItem.type === 'DEDUCTION' && payItem.payTitle === 'Stamp Duty',
          )
          .map((payItem) => payItem.payTitle);
      }),
    ),
  ]
    .map((title) => {
      const uniquePayItem = krestonStampTempInfo?.find(
        (item) => item.payItem.payTitle === title,
      );
      if (uniquePayItem) {
        return {
          title: uniquePayItem.payItem.payTitle,
          isEpf: uniquePayItem?.payItem.isEpf,
          isTaxable: uniquePayItem?.payItem.isTaxable,
          type: uniquePayItem?.payItem.type,
        };
      }
    })
    .filter(
      (titles): titles is krestonBasicPayInfoTitlesI => titles !== undefined,
    );
  const krestonStampDutyColumns = krestonStampDuty.map(
    (titles: krestonBasicPayInfoTitlesI) => ({
      key: titles.title,
      width: 10,
    }),
  );

  // APIT
  const krestonAPITColumn = {
    key: { title: 'APIT', isEpf: false, isTaxable: false, type: '' },
    width: 10,
  };

  // EPF 8%
  const krestonEmpEPFTempInfo = dataPayload?.flatMap((item) => {
    return item.values.payItems
      .filter((payItem) => payItem.type === 'EMPLOYEE_EPF')
      .map((payItem) => ({ payItem: payItem }));
  });
  const krestonEmployeeEPF = [
    ...new Set(
      dataPayload?.flatMap((item) => {
        return item.values.payItems
          .filter((payItem) => payItem.type === 'EMPLOYEE_EPF')
          .map((payItem) => payItem.payTitle);
      }),
    ),
  ]
    .map((title) => {
      const uniquePayItem = krestonEmpEPFTempInfo?.find(
        (item) => item.payItem.payTitle === title,
      );
      if (uniquePayItem) {
        return {
          title: uniquePayItem.payItem.payTitle,
          isEpf: uniquePayItem?.payItem.isEpf,
          isTaxable: uniquePayItem?.payItem.isTaxable,
          type: uniquePayItem?.payItem.type,
        };
      }
    })
    .filter(
      (titles): titles is krestonBasicPayInfoTitlesI => titles !== undefined,
    );
  const krestonEmployeeEPFColumn = krestonEmployeeEPF.map(
    (title: krestonBasicPayInfoTitlesI) => ({
      key: title.title,
      width: 10,
    }),
  );

  // NOTE: The order we usually follow for all payItems
  // Basic
  // Fixed
  // Variable
  // lump sum
  // Non cash
  // deductions
  const expectedColumnOrder: krestonBasicPayInfoTitlesI[] = [
    { title: 'epfNumber', isEpf: false, isTaxable: false, type: '' },
    { title: 'empId', isEpf: false, isTaxable: false, type: '' },
    { title: 'designation', isEpf: false, isTaxable: false, type: '' },
    { title: 'fullName', isEpf: false, isTaxable: false, type: '' },
    { title: 'nameWithInitials', isEpf: false, isTaxable: false, type: '' },
    { title: 'teams', isEpf: false, isTaxable: false, type: '' },
    ...krestonBasicPayInfoTitles,
    ...krestonFixedAllowanceEPFEnabledTitles,
    ...krestonVariableAllowanceEPFEnabledTitles,
    ...lumpSumEPF,
    ...krestonNonCashEpfTitles,
    ...krestonDeductionsEPFEnabledTitles,
    {
      title: 'grossEarningsLiableForEPF',
      isEpf: false,
      isTaxable: false,
      type: '',
    },
    ...krestonBasicNoEPFPayInfoTitles,
    ...krestonFixedAllowanceEPFDisabledTitles,
    ...krestonVariableAllowanceEPFDisabledTitles,
    ...lumpSumNoEPF,
    ...krestonNonCashNoEpfTitles,
    ...krestonDeductionEPFDisabledTitles,
    { title: 'totalGrossEarnings', isEpf: false, isTaxable: false, type: '' },
    ...krestonNoAPITPayItem,
    ...krestonNoAPITFixedAllowancesTitles,
    ...krestonNoAPITVariableAllowancesTitles,
    ...krestonNoAPITNoCashTitles,
    ...krestonNoAPITDeductionsTitles,
    ...krestonStampDuty,
    ...krestonEmployeeEPF,
    krestonAPITColumn.key,
    { title: 'totalDeduction', isEpf: false, isTaxable: false, type: '' },
    { title: 'netSalary', isEpf: false, isTaxable: false, type: '' },
    { title: 'Employer EPF', isEpf: false, isTaxable: false, type: '' },
    { title: 'ETF', isEpf: false, isTaxable: false, type: '' },
    { title: 'totalEPF', isEpf: false, isTaxable: false, type: '' },
    { title: 'totalStatutory', isEpf: false, isTaxable: false, type: '' },
    { title: 'costToCompany', isEpf: false, isTaxable: false, type: '' },
  ];

  const sheetColumns = leaveColumns
    ? [
        ...krestonGeneralInfoColumns,
        ...payItemColumns,
        ...calculationColumns,
        ...bankColumns,
        ...generalInfoColumns,
        ...leaveColumns,
      ]
    : [
        ...krestonGeneralInfoColumns,
        ...payItemColumns,
        ...calculationColumns,
        ...bankColumns,
        ...generalInfoColumns,
      ];
  worksheet.columns = sheetColumns;

  //Document heading and organization information
  const organizationNameStr = organizationData.name;
  const addressStr = organizationData.address;
  const payrollMonth = payrollViewData.month;
  if (payrollViewData.year !== null) {
    const payrollYear = payrollViewData.year.toString();
    worksheet.getCell(4, 1).value = payrollMonth + '-' + payrollYear;
  }
  worksheet.getCell(1, 1).value = 'Pay sheet';
  worksheet.getCell(2, 1).value = organizationNameStr;
  worksheet.getCell(3, 1).value = addressStr;
  worksheet.getRow(3).height = 75;
  worksheet.getColumn(1).width = 10;
  worksheet.getCell(3, 1).alignment = {
    vertical: 'top',
    wrapText: true,
  };
  worksheet.mergeCells(1, 1, 1, 3);
  worksheet.mergeCells(3, 1, 3, 3);
  worksheet.mergeCells(2, 1, 2, 3);
  worksheet.mergeCells(4, 1, 4, 3);

  //Main headings
  // the order of the headings is defined here
  const values = [
    'EPF No',
    'Employee ID',
    'Designation',
    'Full Name',
    'Name with Initials',
    'Team',
    ...krestonBasicPayInfoColumns.map((item) =>
      camelCaseToNormalText(item.key),
    ),
    ...krestonFixedAllowanceColumnsEPFEnabledSection.map((item) =>
      camelCaseToNormalText(item.key?.title),
    ),
    ...krestonVariableAllowanceColumnsEPFEnabledSection.map((item) =>
      camelCaseToNormalText(item.key),
    ),
    ...lumpSumEPFCol.map((item) => camelCaseToNormalText(item.key)),
    ...krestonNonCashEpfSection.map((item) => camelCaseToNormalText(item.key)),
    ...krestonDeductionsEPFEnabledColumnsSection.map((item) =>
      camelCaseToNormalText(item.key),
    ),
    ...krestonBasicNoEPFPayInfoColumns.map((item) =>
      camelCaseToNormalText(item.key),
    ),
    ...crestonFixedAllowanceColumnsEPFDisabledSection.map((item) =>
      camelCaseToNormalText(item.key),
    ),
    ...krestonVariableAllowanceColumnsEPFDisabledSection.map((item) =>
      camelCaseToNormalText(item.key),
    ),
    ...lumpSumNoEPFCol.map((item) => camelCaseToNormalText(item.key)),
    ...krestonNonCashNoEpfSection.map((item) =>
      camelCaseToNormalText(item.key),
    ),
    ...krestonDeductionEPFDisabledColumnSection.map((item) =>
      camelCaseToNormalText(item.key),
    ),
    'Total Gross Earnings',
    ...krestonNoAPITPayItemColumns.map((item) =>
      camelCaseToNormalText(item.key),
    ),
    ...krestonNoAPITFixedAllowancesColumns.map((item) =>
      camelCaseToNormalText(item.key),
    ),
    ...krestonNoAPITVariableAllowancesColumns.map((item) =>
      camelCaseToNormalText(item.key),
    ),
    ...krestonNoAPITNoCashColumns.map((item) =>
      camelCaseToNormalText(item.key),
    ),
    ...krestonNoAPITDeductionsColumns.map((item) =>
      camelCaseToNormalText(item.key),
    ),
    ...krestonStampDutyColumns.map((item) => camelCaseToNormalText(item.key)),
    ...krestonEmployeeEPFColumn.map(() => {
      return 'EPF 8%';
    }),
    camelCaseToNormalText(krestonAPITColumn.key.title),
    'Total Deductions',
    'Net Salary',
    'EPF 12%',
    'ETF 3%',
    'Total EPF 20%',
    'Total Statutory Dues Payable',
    'CTC',
  ];

  // adding two empty rows
  worksheet.addRows([{}, {}]);
  const firstRow = worksheet.addRow(values);

  //Centering first row
  centerTexts(firstRow, []);

  const dynamicColumns: string[] = [];

  const startCharCode = 'F'.charCodeAt(0); // ASCII code of 'A'
  //F is 6
  //Z is 27
  let currentIndex = 0;
  [...payItemColumns, ...calculationColumns].forEach(() => {
    let charCode = 0;
    let columnValue: string;
    if (startCharCode + currentIndex <= 90) {
      charCode = startCharCode + currentIndex;
      columnValue = String.fromCharCode(charCode); // Append 'B' to the generated character
    } else {
      charCode = startCharCode + currentIndex - 26;
      columnValue = 'A' + String.fromCharCode(charCode);
    }
    dynamicColumns.push(columnValue);
    currentIndex++;
  });

  const boldUnderlinedItalicRows = [1, 2, 4];

  boldUnderlinedItalicRows.forEach((rowNumber) => {
    worksheet.getRow(rowNumber).font = {
      bold: true,
      italic: true,
      underline: true,
    };
  });

  // Removed LUMP_SUM from here because we decided to add it to totalGross value
  const typesToNotIncludeInTotalGrossEarnings = [
    'EMPLOYEE_EPF',
    'EMPLOYER_EPF',
    'ETF',
    'APIT',
  ];

  // Entering values to the table
  // total values of columns
  let totalOfgrossEarningsLiableForEPF = 0;
  let totalOftotalGrossEarnings = 0;
  let totalOftotalDeduction = 0;
  let totalOftotalEPF = 0;
  let totalOftotalStatutory = 0;

  userRows.sort((a, b) => {
    if (a.teams[0] === null || b.teams[0] == null) {
      return 0;
    }
    if (a.teams === '' && b.teams !== '') {
      return 1;
    }
    if (a.teams !== '' && b.teams === '') {
      return -1;
    }

    const comparison = a.teams.localeCompare(b.teams);
    if (comparison !== 0) {
      return comparison;
    }

    return a.nameWithInitials.localeCompare(b.nameWithInitials);
  });

  if (dataPayload) {
    dataPayload.sort((a, b) => {
      if (a.values.teams[0] === null || b.values.teams[0] == null) {
        return 0;
      }
      // Sort by teams
      if (
        a.values.teams &&
        b.values.teams &&
        a.values.nameWithInitials &&
        b.values.nameWithInitials
      ) {
        if (a.values.teams.length === 0 && b.values.teams.length > 0) {
          return 1;
        }
        if (a.values.teams.length > 0 && b.values.teams.length === 0) {
          return -1;
        }

        if (a.values.teams?.length > 0 && b.values.teams?.length > 0) {
          const teamComparison = a.values?.teams[0]?.localeCompare(
            b?.values?.teams[0],
          );
          if (teamComparison !== 0) {
            return teamComparison;
          }
          // Sort by nameWithInitials
          const initialsA = a.values.nameWithInitials;
          const initialsB = b.values.nameWithInitials;
          return initialsA.localeCompare(initialsB);
        } else {
          const initialsA = a.values.nameWithInitials;
          const initialsB = b.values.nameWithInitials;
          return initialsA.localeCompare(initialsB);
        }
      } else {
        return 0;
      }
    });
  }

  let index = 0;
  for (const userRow of userRows) {
    const mappedUserData = expectedColumnOrder.map((columnKey) => {
      if (columnKey.title === 'grossEarningsLiableForEPF') {
        // All the epf enabled values are considered here
        let grossEarningLiableForEPF = 0;
        if (dataPayload) {
          dataPayload[index].values.payItems.forEach((value) => {
            if (
              value.isEpf &&
              value.type === 'DEDUCTION' &&
              typeof value.amount === 'number'
            ) {
              grossEarningLiableForEPF -= Math.round(value.amount * 100) / 100;
            } else if (value.isEpf && typeof value.amount === 'number') {
              grossEarningLiableForEPF += Math.round(value.amount * 100) / 100;
            }
          });
        }
        totalOfgrossEarningsLiableForEPF +=
          Math.round(grossEarningLiableForEPF * 100) / 100;
        return grossEarningLiableForEPF;
        // Total Gross Earnings is the APIT liable total
      } else if (columnKey.title === 'totalGrossEarnings') {
        let totalGrossEarnings = 0;
        if (dataPayload) {
          dataPayload[index].values.payItems.forEach((value) => {
            if (
              value.type === 'DEDUCTION' &&
              typeof value.amount === 'number' &&
              value.isTaxable === true
            ) {
              totalGrossEarnings -= Math.round(value.amount * 100) / 100;
            } else if (
              typeof value.amount === 'number' &&
              !typesToNotIncludeInTotalGrossEarnings.includes(value.type) &&
              value.isTaxable === true
            ) {
              totalGrossEarnings += Math.round(value.amount * 100) / 100;
            }
          });
        }
        totalOftotalGrossEarnings += Math.round(totalGrossEarnings * 100) / 100;
        return totalGrossEarnings;
      } else if (columnKey.title === 'totalDeduction') {
        let totalNoAPITDeduction = 0;
        if (dataPayload) {
          dataPayload[index].values.payItems.forEach((value) => {
            if (
              value.type === 'DEDUCTION' ||
              value.type === 'EMPLOYEE_EPF' ||
              (value.type === 'APIT' && !value.isTaxable && !value.isEpf)
            ) {
              if (typeof value.amount === 'number') {
                totalNoAPITDeduction += Math.round(value.amount * 100) / 100;
              }
            }
          });
        }
        totalOftotalDeduction += Math.round(totalNoAPITDeduction * 100) / 100;
        return totalNoAPITDeduction;
      } else if (columnKey.title === 'totalEPF') {
        totalOftotalEPF +=
          Math.round(
            Number(userRow['Employee EPF'] + userRow['Employer EPF']) * 100,
          ) / 100;
        return userRow['Employee EPF'] + userRow['Employer EPF'];
      } else if (columnKey.title === 'totalStatutory') {
        const epf = userRow['Employee EPF'] + userRow['Employer EPF'];
        const etf = userRow['ETF'];
        let apit = Number(userRow['APIT']);
        if (!apit) {
          apit = 0;
        }

        const stampDuty = dataPayload?.[index]?.values.payItems.reduce(
          (acc, value) => {
            if (
              value.payTitle === 'Stamp Duty' &&
              typeof value.amount === 'number'
            ) {
              return value.amount;
            }
            return acc;
          },
          0,
        );
        totalOftotalStatutory +=
          Math.round(Number(epf + etf + apit + stampDuty) * 100) / 100;
        return epf + etf + apit + stampDuty;
      } else if (
        columnKey.title === 'epfNumber' ||
        columnKey.title === 'empId' ||
        columnKey.title === 'designation' ||
        columnKey.title === 'fullName' ||
        columnKey.title === 'nameWithInitials' ||
        columnKey.title === 'Employee EPF' ||
        columnKey.title === 'Employer EPF' ||
        columnKey.title === 'ETF' ||
        columnKey.title === 'costToCompany' ||
        columnKey.title === 'netSalary'
      ) {
        return userRow[columnKey.title];
      } else if (columnKey.title === 'APIT') {
        return userRow[columnKey.title];
      } else if (columnKey.title === 'teams') {
        const teams = userRow[columnKey.title];
        const values: string[] = teams.split(',');
        const outputString: string = values.join(', ');
        return outputString;
      } else {
        if (dataPayload) {
          const payLoadPayItem = dataPayload[index].values.payItems.find(
            (payItem) => payItem.payTitle === columnKey.title,
          );
          const isEpfEqual = payLoadPayItem?.isEpf === columnKey?.isEpf;
          const isAPITEqual =
            payLoadPayItem?.isTaxable === columnKey?.isTaxable;
          const isTypeEqual = payLoadPayItem?.type === columnKey?.type;

          if (isEpfEqual && isAPITEqual && isTypeEqual) {
            return userRow[columnKey.title];
          }
        }
      }
    });
    worksheet.addRow(mappedUserData);
    index++;
  }

  // changing the background color of cells in the seventh row
  const addedFirstRow = worksheet.getRow(7);
  addedFirstRow.height = 50;
  addedFirstRow.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ffd3d3d3' },
    };
    cell.alignment = { vertical: 'top', wrapText: true };
  });

  // changing the width of some columns
  worksheet.getColumn(6).width = 10;

  // Adding autoFilter
  worksheet.autoFilter = {
    from: { row: 7, column: 1 },
    to: { row: 7, column: worksheet.columnCount },
  };

  // freezing first five columns
  worksheet.views = [
    {
      state: 'frozen',
      xSplit: 6,
      ySplit: 7,
    },
  ];
  const lastCol = worksheet.lastColumn;

  //Adding border to all cells
  worksheet.eachRow(function (row, _rowNumber) {
    sheetColumns.forEach((_item, i) => {
      const cell = row.getCell(i + 1);
      if (_rowNumber > 6 && i < lastCol.number) {
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
        };
      }
    });
  });

  const titleCounts: { [key: string]: number } = {};

  expectedColumnOrder.forEach((obj) => {
    const { title } = obj;
    titleCounts[title] = (titleCounts[title] || 0) + 1;
  });
  const duplicateTitleInfo: { [key: string]: number } = {};

  for (const title in titleCounts) {
    if (titleCounts[title] > 1) {
      duplicateTitleInfo[title] = titleCounts[title];
    }
  }

  const totalValues = new Map<string, number>();
  let counter = 0;
  for (const userRow of userRows) {
    expectedColumnOrder.forEach((columnKey) => {
      if (dataPayload) {
        const payLoadPayItem = dataPayload[counter].values.payItems.find(
          (payItem) =>
            payItem.payTitle === columnKey.title &&
            payItem.type === columnKey.type &&
            payItem.isEpf === columnKey.isEpf &&
            payItem.isTaxable === columnKey.isTaxable,
        );

        const isEpfEqual = payLoadPayItem?.isEpf === columnKey?.isEpf;
        const isAPITEqual = payLoadPayItem?.isTaxable === columnKey?.isTaxable;
        const isTypeEqual = payLoadPayItem?.type === columnKey?.type;

        const uniqueColKey =
          columnKey.title +
          columnKey.isEpf +
          columnKey.isTaxable +
          columnKey.type;

        const value = userRow[columnKey.title];
        if (typeof value === 'number') {
          if (
            (isEpfEqual && isAPITEqual && isTypeEqual) ||
            columnKey.title === 'ETF' ||
            columnKey.title === 'APIT' ||
            columnKey.title === 'Employee EPF' ||
            columnKey.title === 'Employer EPF' ||
            columnKey.title === 'costToCompany' ||
            columnKey.title === 'netSalary'
          ) {
            totalValues.set(
              uniqueColKey,
              (totalValues.get(uniqueColKey) || 0) +
                Math.round(value * 100) / 100,
            );
          } else {
            totalValues.set(uniqueColKey, totalValues.get(uniqueColKey) || 0);
          }
        }
      }
    });
    counter++;
  }

  // Add a new row to store total values
  const totalRow = expectedColumnOrder.map((columnKey) => {
    const uniqueColKey =
      columnKey.title + columnKey.isEpf + columnKey.isTaxable + columnKey.type;

    if (columnKey.title === 'fullName') {
      return 'Total';
    } else if (columnKey.title === 'grossEarningsLiableForEPF') {
      return totalOfgrossEarningsLiableForEPF;
    } else if (columnKey.title === 'totalGrossEarnings') {
      return totalOftotalGrossEarnings;
    } else if (columnKey.title === 'totalDeduction') {
      return totalOftotalDeduction;
    } else if (columnKey.title === 'totalEPF') {
      return totalOftotalEPF;
    } else if (columnKey.title === 'totalStatutory') {
      return totalOftotalStatutory;
    }

    if (typeof totalValues.get(uniqueColKey) === 'number') {
      return totalValues.get(uniqueColKey);
    } else {
      return '';
    }
  });
  worksheet.addRow(totalRow);

  // formatting the total row
  const totalRowNumber = worksheet.lastRow?.number;
  if (totalRowNumber) {
    worksheet.getRow(totalRowNumber).font = {
      bold: true,
      color: { argb: 'FFFFFFFF' },
    };
    worksheet
      .getRow(totalRowNumber)
      .eachCell({ includeEmpty: true }, (cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00000000' },
        };
        cell.numFmt = '#,##0.00';
      });
  }

  if (totalRowNumber) {
    const totalRow = worksheet.getRow(totalRowNumber);

    for (let columnIndex = 1; columnIndex < lastCol.number; columnIndex++) {
      const cell = totalRow.getCell(columnIndex);
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00000000' },
      };
      cell.numFmt = '#,##0.00';
    }
  }

  worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    if (worksheet.lastRow?.number) {
      if (rowNumber < worksheet.lastRow?.number && rowNumber >= 8) {
        row.height = 25;
      }
    }
  });

  // This gap row prevents including the total row when sorted using autoFilter
  // Doesn't do anything else
  worksheet.spliceRows(totalRowNumber, 0, []);
  const gapRow = worksheet.getRow(totalRowNumber);
  gapRow.eachCell({ includeEmpty: true }, (cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
    };
  });
  gapRow.height = 1;

  dynamicColumns.forEach((columnName) => {
    const column = worksheet.getColumn(columnName);

    column.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (typeof cell.value === 'number') {
        const numericValue = parseFloat(cell.value.toFixed(2));
        if (!isNaN(numericValue)) {
          cell.value = numericValue;
          cell.numFmt = '#,##0.00';
        }
        if (rowNumber > 2) {
          cell.numFmt = '#,##0.00';
        }
      }
    });
  });

  const epf12ColumnIndex =
    dynamicColumns[dynamicColumns.length - 1]?.charCodeAt(0) - 64 + 1;
  const endingColumnIndex =
    dynamicColumns[dynamicColumns.length - 1]?.charCodeAt(0) - 64 + 1 + 4;

  for (
    let colIndex = epf12ColumnIndex;
    colIndex <= endingColumnIndex;
    colIndex++
  ) {
    const column = worksheet.getColumn(numberToColumnName(colIndex));

    column.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (typeof cell.value === 'number') {
        const numericValue = parseFloat(cell.value.toFixed(2));
        if (!isNaN(numericValue)) {
          cell.value = numericValue;
          cell.numFmt = '#,##0.00';
        }
        if (rowNumber > 2) {
          cell.numFmt = '#,##0.00';
        }
      }
    });
  }

  // If employee IDs are numerical make column values numerical
  const empIdCol = worksheet.getColumn(2);
  empIdCol.eachCell({ includeEmpty: false }, (cell, rowNumber) => {
    if (
      rowNumber > 7 &&
      rowNumber < totalRowNumber &&
      !isNaN(Number(cell.value))
    ) {
      const numericValue = parseInt(cell.value as string);
      cell.value = numericValue;
    }
  });
};
