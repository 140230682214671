import React, { CSSProperties } from 'react';
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';
import styled from '@emotion/styled';

type Props = React.HTMLAttributes<HTMLDivElement> &
  SpaceProps &
  ColorProps &
  LayoutProps &
  TypographyProps & {
    type: FontTypes;
    children?: string | React.ReactNode;
    id?: string;
    display?: CSSProperties['display'];
    onClick?: () => void;
    cursor?: CSSProperties['cursor'];
  };

export type FontTypes =
  | 'b-large'
  | 'b-default'
  | 'b-italic'
  | 'b-small'
  | 'b-large-semibold'
  | 'b-default-semibold'
  | 'b-small-semibold'
  | 'b-small-medium'
  | 'b-large-bold'
  | 'b-extra-large-bold';

export interface IBProps {
  type: FontTypes;
  color?: string;
  children?: string | React.ReactNode;
  display?: CSSProperties['display'];
  textAlign?: CSSProperties['textAlign'];
  onClick?: () => void;
  cssStyle?: string;
  cursor?: CSSProperties['cursor'];
}

const getStyleFontType = (type: FontTypes) => {
  switch (type) {
    case 'b-large':
      return `
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      `;
    case 'b-default':
      return `
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      `;
    case 'b-italic':
      return `
        font-weight: normal;
        font-size: 14px;
        font-style:italic;
        line-height: 20px;
      `;
    case 'b-small':
      return `
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
      `;
    case 'b-large-semibold':
      return `
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.1px;
      `;
    case 'b-default-semibold':
      return `
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
      `;
    case 'b-small-medium':
      return `
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.2px;
      `;
    case 'b-small-semibold':
      return `
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        `;
    case 'b-large-bold':
      return `
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.2px;
      `;
    case 'b-extra-large-bold':
      return `
        font-weight: 700;
        font-size: 100px;
        line-height: 100px;
        letter-spacing: -0.02px;
      `;
  }
};

export const B = styled.p<Props>`
  margin-bottom: 0px;
  ${(props) => props.cursor && `cursor: ${props.cursor}};`};
  ${(props) => getStyleFontType(props.type)}
  ${typography}
  ${space}
  ${color}
  ${layout}
`;
