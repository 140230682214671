import styled from '@emotion/styled';
import { Button, Col, Form, Input, Row, Select, Tooltip } from 'antd';
import { SelectValue } from 'antd/lib/select';
import moment, { Moment } from 'moment';
import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { Validation } from '../../../common/validation';
import dayjs, { Dayjs } from 'dayjs';

import {
  B,
  DatePickerComp,
  Div,
  InputComp,
  InputNumberComp,
  LabeledInputV2,
  SelectV2,
  Image,
} from '../../../components';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import theme from '../../../theme';
import { LoanI, LoanTypeEnum } from '../../../types/loan.types';
import { useOrganizationData } from '../../../custom-hooks/organizationHooks';
import { InfoSVG, PlusBlueSVG } from '../../../assets';

interface Props {
  isDraftLoan?: boolean;
  disableFields?: boolean;
  GuarantorList?: {
    label: string;
    value: number;
  }[];
  isLoanView?: boolean;
  loanDetails?: LoanI;
  userNetSalary?: number;
  onChangeLoanPeriod?: (value: SelectValue) => void;
  onChangeReleaseDate?: (value: Moment | null, dateString: string) => void;
  onChangeLoanAmount?: (value: string | number | undefined) => void;
  onChangeLoanInterest?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputTag = styled.div`
  background: ${theme.gray100};
  padding: 5px 12px;
  border-left: 1px solid ${theme.gray400};
  box-sizing: border-box;
  border-radius: 0px 4px 4px 0px;
  width: 40px;
  margin-right: -7px;
`;

const LoanPeriodSelect = styled.div`
  .ant-select-item-option {
    border-bottom: 1px solid ${theme.gray300} !important;
  }
  .ant-select-item-option-content {
    padding: 7px 4px !important;
  }
`;

const LoanPeriodInput = styled(Input)`
  border-radius: 4px;
`;

const InfoIcon = styled.img`
  width: 15px;
  margin-left: 5px;
`;

const { Option } = Select;

const LoanPeriodData: string[] = ['6', '12', '24'];

const LoanDetails: FC<Props> = ({
  isDraftLoan,
  disableFields,
  GuarantorList,
  isLoanView,
  loanDetails,
  userNetSalary,
  onChangeLoanPeriod,
  onChangeReleaseDate,
  onChangeLoanAmount,
  onChangeLoanInterest,
}) => {
  const [loanPeriod, setLoanPeriod] = useState(LoanPeriodData);
  const [loanType, setLoanType] = useState<SelectValue>();
  const [months, setMonths] = useState('');
  const inputRef = useRef<any>();

  const onMonthsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMonths(event.target.value);
  };
  const organizationData = useOrganizationData();

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const loanPeriodMonths = months;
    e.preventDefault();
    setLoanPeriod([...loanPeriod, loanPeriodMonths]);
    LoanPeriodData.push(loanPeriodMonths);
    setMonths('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const disabledDate = (current: Dayjs) => {
    // Can select days after today and today
    return dayjs() < current ? false : true;
  };

  return (
    <div>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <LabeledInputV2 label="Loan ID">
            <Form.Item
              name="loanId"
              rules={[
                {
                  required: true,
                  message: 'Please select employee name',
                },
              ]}>
              <InputComp size="small" placeholder="Loan ID" disabled />
            </Form.Item>
          </LabeledInputV2>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Div display="flex">
            <LabeledInputV2 label="Loan Amount*" />
            {(loanType === LoanTypeEnum.ADVANCE_PAYMENT ||
              (isDraftLoan &&
                loanDetails?.loanType === LoanTypeEnum.ADVANCE_PAYMENT)) && (
              <Tooltip title="Advance payment cannot be larger than the employees last drawn net salary">
                <InfoIcon src={InfoSVG} />
              </Tooltip>
            )}
          </Div>
          <Form.Item
            name="loanAmount"
            rules={[
              {
                required: true,
                message: 'Please enter a valid loan amount',
                pattern: organizationData?.isFourDecimalPoint
                  ? Validation.FourDecimalPointAmountPattern
                  : Validation.AmountPattern,
              },
            ]}>
            <InputNumberComp
              max={
                loanType === LoanTypeEnum.ADVANCE_PAYMENT ||
                loanDetails?.loanType === LoanTypeEnum.ADVANCE_PAYMENT
                  ? userNetSalary
                  : undefined
              }
              size="middle"
              onChange={onChangeLoanAmount}
              placeholder="Enter Loan Amount"
              disabled={disableFields}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Div display="flex">
            <LabeledInputV2 label="Loan Type*" />
            {loanType === LoanTypeEnum.ADVANCE_PAYMENT && (
              <Tooltip title="Advance payments will be deducted from the payrun which immediately follows the release date">
                <InfoIcon src={InfoSVG} />
              </Tooltip>
            )}
          </Div>
          <Form.Item
            name="loanType"
            rules={[
              {
                required: true,
                message: 'Please select loan type',
              },
            ]}>
            <SelectV2
              placeholder="Select Loan Type"
              disabled={disableFields || isDraftLoan}
              options={[
                {
                  label: 'Default',
                  value: LoanTypeEnum.DEFAULT,
                },
                {
                  label: 'Advance Payment',
                  value: LoanTypeEnum.ADVANCE_PAYMENT,
                },
              ]}
              onChange={(v) => setLoanType(v)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <LabeledInputV2 label="Release Date*">
            <Form.Item
              name="releaseDate"
              rules={[
                {
                  required: true,
                  message: 'Please select start date',
                },
              ]}>
              <DatePickerComp
                onChange={(d, dateString: string) =>
                  onChangeReleaseDate(moment(d.toDate()), dateString)
                }
                size="middle"
                format="DD-MM-YYYY"
                placeholder="Select Start Date"
                disabled={disableFields}
                disabledDate={(current) =>
                  disabledDate(dayjs(current.toDate()))
                }
              />
            </Form.Item>
          </LabeledInputV2>
        </Col>
        {isDraftLoan &&
          loanDetails?.loanType === LoanTypeEnum.ADVANCE_PAYMENT && (
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Div display="flex" pt="31px">
                <Form.Item
                  name="loanReleasedToEmployee"
                  valuePropName="checked">
                  <CheckBoxComp defaultChecked />
                </Form.Item>
                <B type="b-default" pl="11px" pt="6px">
                  Loan Released to Employee
                </B>
              </Div>
            </Col>
          )}
        {(!!isLoanView && loanDetails?.loanType !== LoanTypeEnum.DEFAULT) ||
          (loanType !== LoanTypeEnum.ADVANCE_PAYMENT && (
            <>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <LabeledInputV2 label="Loan Period*">
                  <Form.Item
                    name="loanPeriod"
                    rules={[
                      {
                        required: true,
                        message: 'Please select loan period',
                      },
                    ]}>
                    <SelectV2
                      placeholder="Select Months"
                      onChange={onChangeLoanPeriod}
                      disabled={disableFields}
                      dropdownRender={(menu) => (
                        <LoanPeriodSelect>
                          {menu}
                          <Div p={16} display="flex">
                            <LoanPeriodInput
                              placeholder="Enter Months"
                              type="Number"
                              min={1}
                              ref={inputRef}
                              value={months}
                              onChange={onMonthsChange}
                            />
                            <Button
                              type="text"
                              disabled={months ? false : true}
                              icon={
                                <Image src={PlusBlueSVG} paddingRight="10px" />
                              }
                              onClick={addItem}>
                              Add item
                            </Button>
                          </Div>
                        </LoanPeriodSelect>
                      )}>
                      {loanPeriod.map((item) => (
                        <Option value={item} key={item}>
                          {`${item} Months`}
                        </Option>
                      ))}
                    </SelectV2>
                  </Form.Item>
                </LabeledInputV2>
              </Col>
              <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                <LabeledInputV2 label="Loan Interest">
                  <Form.Item
                    name="loanInterest"
                    rules={[
                      {
                        type: 'string',
                        pattern: Validation.InterestRatePattern,
                        message: 'Invalid loan interest',
                      },
                    ]}>
                    <InputComp
                      onChange={onChangeLoanInterest}
                      size="small"
                      suffix={<InputTag>%</InputTag>}
                      placeholder="Interest"
                      disabled={disableFields}
                    />
                  </Form.Item>
                </LabeledInputV2>
              </Col>
            </>
          ))}
      </Row>
      {(!!isLoanView && loanDetails?.loanType !== LoanTypeEnum.DEFAULT) ||
        (loanType !== LoanTypeEnum.ADVANCE_PAYMENT && (
          <>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <LabeledInputV2 label="Maturity Date">
                  <Form.Item name="maturityDate">
                    <DatePickerComp
                      size="middle"
                      format="DD-MM-YYYY"
                      placeholder="Select Maturity Date"
                      disabled
                    />
                  </Form.Item>
                </LabeledInputV2>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <LabeledInputV2 label="Installment Amount">
                  <Form.Item name="installmentAmount">
                    <InputComp
                      size="small"
                      placeholder="Installment Amount"
                      disabled
                    />
                  </Form.Item>
                </LabeledInputV2>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <LabeledInputV2 label="No of Installments">
                  <Form.Item
                    name="noOfInstallments"
                    rules={[
                      {
                        type: 'number',
                        pattern: Validation.NumberRegex,
                        message: 'Invalid no of installments',
                      },
                    ]}>
                    <InputComp
                      size="small"
                      placeholder="No of Installments"
                      disabled
                    />
                  </Form.Item>
                </LabeledInputV2>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <LabeledInputV2 label="Guarantor">
                  <Form.Item name="guarantorId">
                    <SelectV2
                      placeholder="Choose Guarantor"
                      disabled={disableFields}
                      options={GuarantorList}
                    />
                  </Form.Item>
                </LabeledInputV2>
              </Col>
              {isDraftLoan && (
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Div display="flex" pt="31px">
                    <Form.Item
                      name="loanReleasedToEmployee"
                      valuePropName="checked">
                      <CheckBoxComp defaultChecked />
                    </Form.Item>
                    <B type="b-default" pl="11px" pt="6px">
                      Loan Released to Employee
                    </B>
                  </Div>
                </Col>
              )}
            </Row>
          </>
        ))}
    </div>
  );
};

export default LoanDetails;
