import React, { FC } from 'react';
import { B, DeleteModal, Div, TableComp } from '../../../../components';
import { ColumnProps } from 'antd/lib/table';
import styled from '@emotion/styled';
import { Dropdown, Menu, Tooltip } from 'antd';
import theme from '../../../../theme';
import {
  SystemUpdateFormI,
  SystemUpdateI,
} from '../../../../types/superAdmin.types';
import moment from 'moment';
import { addDotsForLongText } from '../../../../util/utils';
import { useWindowSize } from '../../../../util/screenSize';
import { MoreSVG } from '../../../../assets';

const MenuStyle = styled(Menu)`
  width: 175px;
  padding: 0px;
  border: 0.5px solid ${theme.gray300};
  border-bottom: none;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .ant-dropdown-menu-item > a {
    padding: 10.5px 16px;
    color: ${theme.black};
  }
  .ant-dropdown-menu-item :hover {
    background: ${theme.gray100};
  }
`;

const MenuItem = styled(Menu.Item)`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.black};
  border-bottom: 1px solid ${theme.gray300};
`;

const ThreeDotIcon = styled.img`
  cursor: pointer;
`;
interface Prop {
  systemUpdatesList: SystemUpdateI[];
  loadingSystemUpdatesList: boolean;
  deleteSystemUpdate: (id: number) => void;
  getSystemUpdate: (id: number) => void;
  onClickEdit: () => void;
}
const SystemUpdateTable: FC<Prop> = ({
  systemUpdatesList,
  loadingSystemUpdatesList,
  deleteSystemUpdate,
  getSystemUpdate,
  onClickEdit,
}) => {
  const { isTablet, isDesktop, isDesktopLarge } = useWindowSize();
  const items: SystemUpdateFormI[] = systemUpdatesList.map((i) => {
    const isSameDay =
      moment(i.from).format('DD MMM YYYY') ===
      moment(i.to).format('DD MMM YYYY');
    return {
      id: i.id,
      title: i.title,
      description: i.description,
      duration: isSameDay
        ? moment(i.from).format('DD MMM YYYY HH:mm A') +
          ' - ' +
          moment(i.to).format('HH:mm A')
        : moment(i.from).format('DD MMM YYYY HH:mm A') +
          ' - ' +
          moment(i.to).format('DD MMM YYYY HH:mm A'),
    };
  });

  const menu = (data: SystemUpdateFormI) => (
    <MenuStyle>
      <MenuItem>
        <B
          type="b-default"
          pt="7px"
          pb="7px"
          pl="4px"
          onClick={() => {
            onClickEdit();
            getSystemUpdate(data.id);
          }}>
          Edit
        </B>
      </MenuItem>
      <MenuItem>
        <DeleteModal
          title="Delete System Update"
          buttonLabel="Yes, Delete"
          content="Are you sure you want to delete this system update?"
          onClickCancel={() => deleteSystemUpdate(data.id)}
          openModal={
            <B type="b-default" pt="7px" pb="7px" pl="4px">
              Delete
            </B>
          }
        />
      </MenuItem>
    </MenuStyle>
  );

  const columns: ColumnProps<SystemUpdateFormI>[] = [
    {
      title: 'Title',
      dataIndex: 'title',
      align: 'left',
      render: (_value: string, data) => (
        <B type="b-small-semibold">
          {data.title ? (
            <Tooltip placement="topLeft" title={data.title}>
              {isDesktopLarge && data.title
                ? addDotsForLongText(data.title, 50)
                : isTablet || isDesktop
                ? addDotsForLongText(data.title, 30)
                : addDotsForLongText(data.title, 20)}
            </Tooltip>
          ) : (
            '-'
          )}
        </B>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      align: 'left',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.description ? (
            <Tooltip placement="topLeft" title={data.description}>
              {isDesktopLarge && data.description
                ? addDotsForLongText(data.description, 150)
                : isTablet || isDesktop
                ? addDotsForLongText(data.description, 80)
                : addDotsForLongText(data.description, 40)}
            </Tooltip>
          ) : (
            '-'
          )}
        </B>
      ),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      align: 'left',
      render: (value: string) => <B type="b-small">{value ? value : '-'}</B>,
    },
    {
      title: '',
      fixed: 'right',
      width: 70,
      dataIndex: 'payslipUrl',
      render: (_value: string, data) => (
        <>
          <Div display="flex" alignItems="center" height={32} pr="24px">
            <Dropdown
              overlay={menu(data)}
              placement="bottomRight"
              trigger={['click']}>
              <ThreeDotIcon
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                src={MoreSVG}
              />
            </Dropdown>
          </Div>
        </>
      ),
    },
  ];
  return (
    <Div>
      <TableComp
        loading={loadingSystemUpdatesList}
        withBorders={true}
        columns={columns}
        dataSource={items}
        pagination={false}
      />
    </Div>
  );
};

export default SystemUpdateTable;
