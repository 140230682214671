import React, { FC, useEffect, useState } from 'react';

import { Form, Spin } from 'antd';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import styled from '@emotion/styled';

import { PrimaryButton, RouteLeavingGuard } from '../../../../components';
import { FooterComponent } from '../../../../components/FooterComponent';
import { useIndividualPeopleState } from '../../../../providers/IndividualPeopleState';
import {
  createEmploymentDetails,
  getMinimumUserInfo,
} from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import { Permission, ValueOf } from '../../../../types';
import {
  EmploymentDetailI,
  IndividualPeopleI,
} from '../../../../types/people.types';
import { isValidMoment } from '../../../../util/isValidMoment';
import { isAllowed, isManager } from '../../../../util/permissionUtil';
import { PeopleLayout } from '../../shared';
import ExperienceDetails from '../experience/ExperienceDetails';
import { EmploymentForm } from './EmploymentForm';
import dayjs from 'dayjs';

type ParamsI = {
  employeeKey: string;
};

const CustomForm = styled(Form)`
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: #00318c;
  }
  margin-bottom: 100px;
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-bottom: 10px;
  }
  @media (max-width: 768px) {
    margin-bottom: 80 dpx;
  }
`;

const EmploymentPage: FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const params = useParams<ParamsI>();
  const dispatch: DispatchType = useDispatch();
  const [isFieldDataChanged, setIsFieldDataChanged] = useState(false);
  const [joinedDate, setJoinedDate] = useState<string | null>(null);

  const defaultDisable = !isAllowed(Permission.CREATE_EDIT_USERS);
  const organizationLoading = useSelector(
    (state: RootState) => state.organization.loading,
  );

  const {
    state: { people, isLoading, error },
    fetchPeopleById,
  } = useIndividualPeopleState();
  const [secondaryIncome, setSecondaryIncome] = useState<boolean>();

  const putUserLoading = useSelector(
    (state: RootState) => state.people.putUserLoading,
  );

  useEffect(() => {
    if (people) {
      setSecondaryIncome(people.isSecondary);
      setJoinedDate(people.joinedDate);
    }
  }, [people]);

  useEffect(() => {
    if (!isManager() && !isAllowed(Permission.MANAGE_NOTIFICATION)) {
      navigate('/contact');
    }
    const newPeople = { ...people };
    if (newPeople) {
      for (const [key, value] of Object.entries(newPeople)) {
        if (key == 'joinedDate') {
          let newValues: ValueOf<IndividualPeopleI> | Moment | unknown = value;
          newValues = !isValidMoment(people?.joinedDate, null, 'YYYY-MM-DD')
            ? null
            : people?.joinedDate
            ? dayjs(people?.joinedDate)
            : undefined;

          form.setFieldsValue({ [key]: newValues });
        } else if (key == 'resignedDate') {
          let newValue: ValueOf<IndividualPeopleI> | Moment | unknown = value;
          newValue = !isValidMoment(people?.resignedDate, null, 'YYYY-MM-DD')
            ? null
            : people?.resignedDate
            ? dayjs(people.resignedDate)
            : undefined;

          form.setFieldsValue({ [key]: newValue });
        } else {
          form.setFieldsValue({
            [key]: key == 'teams' && value == null ? [] : value,
          });
        }
      }
    }
  }, [people, params.employeeKey]);

  function fetchPeople() {
    fetchPeopleById(params.employeeKey);
  }

  async function onFinish(values: EmploymentDetailI) {
    const data = {
      ...values,
      joinedDate: dayjs(values.joinedDate).format('YYYY-MM-DD'),
      resignedDate: values?.resignedDate
        ? dayjs(values.resignedDate).format('YYYY-MM-DD')
        : null,
      companyTelephone: values.companyTelephone,
      employeeNumber: values.employeeNumber || null,
      epfNumber: values.epfNumber || null,
      etfNumber: values.etfNumber || null,
    };
    setIsFieldDataChanged(false);
    const success = await dispatch(
      createEmploymentDetails(data, params.employeeKey),
    );
    fetchPeople();
    dispatch(getMinimumUserInfo());
    if (people?.isPending && success) {
      navigate(`/contact/pay-details/${params.employeeKey}`);
    }
  }

  const onValuesChange = () => {
    setIsFieldDataChanged(true);
  };

  const shouldBlockNavigation = () => {
    return isFieldDataChanged;
  };

  return (
    <>
      <CustomForm
        form={form}
        layout="vertical"
        wrapperCol={{ span: 50 }}
        onValuesChange={onValuesChange}
        onFinish={(v) => onFinish(v as EmploymentDetailI)}>
        <PeopleLayout>
          <Spin spinning={isLoading || organizationLoading}>
            <RouteLeavingGuard
              when={isFieldDataChanged}
              navigate={(path) => navigate(path)}
              shouldBlockNavigation={shouldBlockNavigation}
            />
            <EmploymentForm
              refetch={fetchPeople}
              defaultDisable={!isAllowed(Permission.CREATE_EDIT_USERS)}
              isSecondary={secondaryIncome}
              isSecondaryOnChange={(v) => setSecondaryIncome(v.target.checked)}
              joinedDate={joinedDate}
              orgId={people?.organizationId}
            />
            <ExperienceDetails
              people={people}
              fetchPeopleById={fetchPeopleById}
            />
          </Spin>
        </PeopleLayout>

        {!error && !defaultDisable && (
          <FooterComponent
            rightChildren={
              <PrimaryButton
                ml={16}
                loading={isLoading || putUserLoading}
                htmlType="submit">
                {people?.isPending ? 'Next' : 'Save'}
              </PrimaryButton>
            }
          />
        )}
      </CustomForm>
    </>
  );
};
export default EmploymentPage;
