import axios, { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { ExpenseCreation, IntegrationStatusEnum } from '../types';

export interface AccountingIntStatus {
  type: ExpenseCreation;
  status?: IntegrationStatusEnum;
}

// get integration status for accounting based apps
export const useGetAccountingIntStatus = () => {
  return useQuery<AccountingIntStatus, AxiosError>(
    'integration-accounting-status',
    async () => {
      const response: AxiosResponse<AccountingIntStatus> = await axios.get(
        '/integration/accounting/status',
      );
      return response.data;
    },
  );
};
