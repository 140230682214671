import React from 'react';
import { Popover } from 'antd';
import { B, DocumentStatusTag } from '../../../components';
import styled from '@emotion/styled';
import theme from '../../../theme';
import { content } from './ContractStatus';
import {
  AllContractsI,
  ContractStatusEnum,
} from '../../../types/contract.types';
import getNormalText from '../../../util/getNormalText';
import { getContractSignedFraction } from '../utils/contractSignedFraction';

const Status = styled.div`
  cursor: pointer;
`;

const StatusPadding = styled.div`
  padding: 6px 0px;
`;
// TODO: this will have to be properly handled after the issues with the
// contract status is solved
export const statusTag = (tag: ContractStatusEnum, contract: AllContractsI) => {
  switch (tag) {
    case ContractStatusEnum.SIGNED:
      return (
        <Popover placement="topLeft" content={content(contract)}>
          <StatusPadding>
            <DocumentStatusTag status={tag}>Completed</DocumentStatusTag>
          </StatusPadding>
        </Popover>
      );

    case ContractStatusEnum.DRAFT:
      return (
        <StatusPadding>
          <DocumentStatusTag status={tag}>Draft</DocumentStatusTag>
        </StatusPadding>
      );

    case ContractStatusEnum.ARCHIVED:
      return (
        <StatusPadding>
          <DocumentStatusTag status={tag}>Archived</DocumentStatusTag>
        </StatusPadding>
      );

    case ContractStatusEnum.COMPLETED:
      return (
        <Popover placement="topLeft" content={content(contract)}>
          <StatusPadding>
            <DocumentStatusTag status={tag}>Completed</DocumentStatusTag>
          </StatusPadding>
        </Popover>
      );

    case ContractStatusEnum.EXPIRED:
      return (
        <StatusPadding>
          <DocumentStatusTag status={tag}>Expired</DocumentStatusTag>
        </StatusPadding>
      );

    case ContractStatusEnum.SENT:
      return (
        <Status>
          <Popover placement="topLeft" content={content(contract)}>
            <B type="text-default-medium">
              {`${getContractSignedFraction(contract)} Done`}
            </B>
            <B type="text-default" color={theme.orange300}>
              Waiting for others
            </B>
          </Popover>
        </Status>
      );

    default:
      return (
        <StatusPadding>
          <DocumentStatusTag status={tag}>
            {getNormalText(tag)}
          </DocumentStatusTag>
        </StatusPadding>
      );
  }
};
