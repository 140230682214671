import React, { FC, useEffect, useRef, useState } from 'react';
import { Input, Tooltip } from 'antd';
import { CSVDataI, ErrorI } from '../util/openPayrollValidator';
import styled from '@emotion/styled';

interface Props {
  rowIndex: number;
  value: string;
  dataIndex: string;
  record: CSVDataI;
  errors: ErrorI[];
  handleSave: (inputValue: string, dataIndex: string, rowIndex: number) => void;
}
const EditableCol = styled.div<{ error: ErrorI }>`
  padding-right: 24;
  min-width: 150px;
  color: ${(props) => (props.error ? 'red' : 'black')};
  border-bottom: 1px dashed transparent;
  &:hover {
    border-bottom: 1px dashed gray;
  }
`;
const EditableCell: FC<Props> = ({
  rowIndex,
  value,
  dataIndex,
  errors,
  handleSave,
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>();
  const [inputValue, setInputValue] = useState<string>(value);
  useEffect(() => {
    if (editing && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async () => {
    try {
      toggleEdit();
      handleSave(inputValue, dataIndex, rowIndex);
    } catch (errInfo) {}
  };
  const cellKey = `${dataIndex}_${rowIndex}`;
  const error: ErrorI | undefined = errors.find(
    (err: ErrorI) => err.key === cellKey,
  );

  const childNode = editing ? (
    <Input
      ref={inputRef}
      onPressEnter={save}
      onBlur={save}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
    />
  ) : (
    <EditableCol error={error} onClick={toggleEdit}>
      {error ? <Tooltip title={error.message}>{value}</Tooltip> : value}
    </EditableCol>
  );

  return <td>{childNode}</td>;
};

export default EditableCell;
