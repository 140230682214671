import React, { FC } from 'react';
import { Col, Form, Row } from 'antd';
import styled from '@emotion/styled';
import { B, InputComp, SelectComp } from '../../../components';
import InputCompTags from '../../../components/Tags/InputCompTags';
import { INDUSTRIES } from '../../../configs';
import theme from '../../../theme';
import { Validation } from '../../../common/validation';
import { Divider } from '../../../components/Divider';

const MainContainer = styled.div`
  padding: 32px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    padding: 24px 10px;
  }
`;

interface Prop {
  onChangeLocation: (location: string[]) => void;
  locations: string[];
}
const CompanyDetails: FC<Prop> = ({ onChangeLocation, locations }) => {
  return (
    <>
      <MainContainer>
        <B type="b-large-semibold" pb="12px">
          Company Details
        </B>
        <Row gutter={[32, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="name"
              label="Company Name*"
              rules={[
                {
                  required: true,
                  message: 'Please enter your company name!',
                },
              ]}>
              <InputComp size="small" placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="profileDisplayName"
              label="Profile Display Name (Optional)">
              <InputComp size="small" placeholder="Profile Display Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[32, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="email"
              label="Email*"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid e-mail!',
                },
                {
                  required: true,
                  message: 'Please enter your email!',
                },
              ]}>
              <InputComp size="small" placeholder="Company Email" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="address"
              label="Address*"
              rules={[
                {
                  required: true,
                  message: 'Please enter your company address!',
                },
              ]}>
              <InputComp size="small" placeholder="Company Address" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[32, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="industry"
              label="Industry*"
              rules={[
                {
                  required: true,
                  message: 'Please enter your industry',
                },
              ]}>
              <SelectComp size="middle" options={INDUSTRIES}></SelectComp>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="telephoneNumber"
              label="Telephone*"
              rules={[
                {
                  required: true,
                  message: 'Please enter your telephone!',
                },
                {
                  pattern: Validation.MobileNumberPattern,
                  message: 'The input is not valid telephone',
                },
              ]}>
              <InputComp size="small" placeholder="07xxxxxxxx" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[32, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <InputCompTags
              label="Branches"
              placeholder="Branch Locations"
              tags={locations}
              onChange={(locations) => {
                onChangeLocation(locations);
              }}></InputCompTags>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name="registrationNumber" label="Registration Number">
              <InputComp
                size="small"
                placeholder="Company Registration Number"
              />
            </Form.Item>
          </Col>
        </Row>
      </MainContainer>
      <Divider />
    </>
  );
};

export default CompanyDetails;
