import React, { FC, useState } from 'react';

import { Col, Form, Image, message, Row, Upload } from 'antd';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../../configs';
import { RootState } from '../../../store/reducer';
import { getCurrentAuthToken } from '../../../util/storageUtil';
import { B, Div, LinkButton } from '../../../components';
import theme from '../../../theme';
import styled from '@emotion/styled';
import { EditSVG, InfoSVG, RedInfoSVG, UploadSVG } from '../../../assets';

const IMAGE_UPLOAD_LIMIT = 1024 * 1024 * 4; // 4 mb

const EditSvg = styled.img`
  marginTop: '5px'; 
  width="18px";
  height="18px";
  margin-bottom: 9px;
`;

const UploadSvg = styled.img`
  marginTop: '5px'; 
  width="14px";
  height="17px";
  margin-bottom: 9px;
`;

const InfoImage = styled(Image)`
  margin-right: 10px;
  width: 20px;
  height: 20px;
`;
const CusRow = styled(Row)`
  display: flex;
  flex-direction: row;
`;
const CusCol = styled(Col)`
  padding-right: 21px;
`;

interface ImageProp {
  onChangeImageUpload: (url: string) => void;
}

const CompanySeal: FC<ImageProp> = ({ onChangeImageUpload }) => {
  const [image, setImage] = useState('');
  const [fileData, setFileData] = useState<any>();
  const [isUploadError, setIsUploadError] = useState(false);
  const [isTypeError, setIsTypeError] = useState(false);

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList.length > 1) {
      return fileData.shift();
    }
    return e && e.fileList;
  };

  return (
    <>
      <Div pl="18px">
        <CusRow>
          <CusCol>
            <Image
              width={64}
              height={64}
              src={image || organizationData?.seal}
            />
          </CusCol>
          <Col>
            <CusRow>
              <Form.Item
                getValueFromEvent={normFile}
                name="seal"
                noStyle
                rules={[
                  {
                    required: false,
                    message: 'image is required',
                  },
                ]}>
                <Upload
                  name="file"
                  accept=".png"
                  multiple={false}
                  fileList={fileData}
                  action={`${BASE_URL}/organization/upload-seal`}
                  headers={{
                    Authorization: `Bearer ${getCurrentAuthToken()}`,
                  }}
                  beforeUpload={(file) => {
                    if (file.size > IMAGE_UPLOAD_LIMIT) {
                      message.error('Image size more than 4MB');
                      setIsUploadError(true);
                      return false;
                    } else {
                      setIsUploadError(false);
                      return true;
                    }
                  }}
                  onChange={(info) => {
                    if (info.file.status === 'done') {
                      onChangeImageUpload(info.file.response);
                      setImage(info.file.response);
                      setIsTypeError(false);
                    } else if (info.file.status === 'error') {
                      setIsTypeError(true);
                      message.error('error');
                    }
                    setFileData(info.fileList);
                  }}>
                  <LinkButton
                    icon={
                      <>
                        {organizationData?.seal ? (
                          <EditSvg src={EditSVG} />
                        ) : (
                          <UploadSvg src={UploadSVG} />
                        )}
                      </>
                    }>
                    {organizationData?.seal
                      ? 'Change company seal'
                      : 'Upload company seal'}
                  </LinkButton>
                </Upload>
              </Form.Item>
            </CusRow>
            <Row>
              <B
                type="b-small"
                color={
                  isUploadError || isTypeError ? theme.red600 : theme.gray600
                }>
                <InfoImage
                  preview={false}
                  src={isUploadError || isTypeError ? RedInfoSVG : InfoSVG}
                />
                {isUploadError
                  ? 'Your image was too large to upload. Images must have a file size of less than 4 MB.'
                  : isTypeError
                  ? 'You have uploaded an unsupported file type for the profile picture.Please try again.'
                  : 'You can upload a PNG file (File size limit is 4 MB).'}
              </B>
            </Row>
          </Col>
        </CusRow>
      </Div>
    </>
  );
};

export default CompanySeal;
