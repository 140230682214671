import React, { FC, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Spin, Menu, Form } from 'antd';
import {
  B,
  RadioGroup,
  RadioButton,
  DefaultBody,
  SecondaryButton,
  LinkButton,
  DropdownIcon,
  FooterComponent,
  PrimaryButton,
  RouteLeavingGuard,
  DeleteModalV2,
} from '../../../components';
import { DispatchType, RootState } from '../../../store/reducer';
import theme from '../../../theme';
import { IntegrationStatusEnum } from '../../../types';
import IntegrationPayroll from './tabs/IntegrationPayroll';
import {
  callBackOAuth,
  disableIntegration,
  getIntegrationSetting,
  getOAuth,
  refreshMetaData,
  updateIntegrationSettings,
} from '../../../store/actions';
import { useNavigate, useParams, useLocation } from 'react-router';
import IntegrationSuppliers from './tabs/IntegrationSuppliers';
import IntegrationExpenses from './tabs/IntegrationExpenses';
import {
  ErrorMessageContainer,
  InfoMessageContainer,
} from './components/IntegrationAlerts';
import { RefreshSVG } from '../../../assets';

enum TabEnum {
  payroll = 'payroll',
  expenses = 'expenses',
  suppliers = 'suppliers',
}

const TopContainer = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${theme.gray300};
  margin: 0 -32px;
  margin-top: -16px;
  background-color: ${theme.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 76px;
  @media screen and (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
    margin-top: 5px;
  }
`;
const DetailContainer = styled.div`
  margin-left: 80px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;
const HeaderRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 10px;
    align-items: flex-start;
  }
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
    align-items: flex-start;
  }
`;
const SettingsContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const DataContainer = styled.div`
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    margin-bottom: 60px;
  }
`;
const TabContainer = styled.div`
  width: 100%;
`;

type ParamProps = {
  integration: string;
};

const IntegrationSettingPage: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const navigate = useNavigate();
  const { integration } = useParams<ParamProps>();
  // the query string for you.
  const callBackURI: string = useLocation()?.search;

  // form
  const [form] = Form.useForm();

  // remove modal
  const [visible, setVisible] = useState<boolean>(false);

  // manage essentials
  const [essential, setEssential] = useState<{
    [key: string]: number;
  }>({});
  const [isFieldDataChanged, setIsFieldDataChanged] = useState(false);

  const loadingIntegrationSettings = useSelector(
    (state: RootState) => state.integration.integrationSettingLoading,
  );
  const integrationSettings = useSelector(
    (state: RootState) => state.integration.integrationSetting,
  );
  const integrationSettingUpdateLoading = useSelector(
    (state: RootState) => state.integration.integrationSettingUpdateLoading,
  );
  const integrations = useSelector(
    (state: RootState) => state.integration.integrationList,
  );

  const [selectedTab, setSelectedTab] = useState<TabEnum>(TabEnum.payroll);

  useEffect(() => {
    if (callBackURI) {
      if (callBackURI.includes('?error=access_denied')) {
        navigate('/company-settings/integrations');
      } else {
        dispatch(callBackOAuth(integration, callBackURI, navigate));
      }
    } else {
      dispatch(getIntegrationSetting(integration, navigate));
    }
  }, []);

  useEffect(() => {
    if (integrationSettings) {
      form.setFieldsValue({
        ...integrationSettings.settings.essential,
      });
    }
  }, [integrationSettings]);

  const onFinish = (values: any) => {
    dispatch(
      updateIntegrationSettings(Number(integrationSettings?.id), values),
    );
    setIsFieldDataChanged(false);
  };

  const menu = () => (
    <Menu>
      <Menu.Item
        onClick={() =>
          navigate(`/company-settings/integrations/${integration}/log-trail`)
        }>
        View Log Trail
      </Menu.Item>
      <Menu.Item onClick={() => setVisible(true)}>Revoke</Menu.Item>
    </Menu>
  );

  const shouldBlockNavigation = () => {
    return isFieldDataChanged;
  };

  const viewLogo = () => {
    if (integrations && integrationSettings) {
      const getIntegration = integrations.find(
        (i) => i.id === integrationSettings.integrationId,
      );
      return <img src={getIntegration?.logo} style={{ height: 40 }} />;
    }
    return null;
  };

  return (
    <Spin spinning={loadingIntegrationSettings}>
      <DefaultBody style={{ marginBottom: 32 }}>
        <RouteLeavingGuard
          when={isFieldDataChanged}
          navigate={(path) => navigate(path)}
          shouldBlockNavigation={shouldBlockNavigation}
        />
        <TopContainer>
          {viewLogo()}
          <HeaderRowContainer>
            <DetailContainer>
              <B type="b-small" color={theme.gray600}>
                Email
              </B>
              <B type="b-default">
                {integrationSettings?.settings.metaData.companyMail}
              </B>
            </DetailContainer>
            <DetailContainer>
              <B type="b-small" color={theme.gray600}>
                Company name
              </B>
              <B type="b-default">
                {integrationSettings?.settings.metaData.companyName}
              </B>
            </DetailContainer>
            <DetailContainer>
              <B type="b-small" color={theme.gray600}>
                Status
              </B>
              <B
                type="b-default-semibold"
                color={
                  integrationSettings
                    ? integrationSettings?.status ===
                      IntegrationStatusEnum.ACTIVATED
                      ? theme.green500
                      : integrationSettings?.status ===
                        IntegrationStatusEnum.PENDING
                      ? theme.orange400
                      : theme.red300
                    : theme.black
                }>
                {integrationSettings
                  ? integrationSettings?.status ===
                    IntegrationStatusEnum.ACTIVATED
                    ? 'Active'
                    : integrationSettings?.status ===
                      IntegrationStatusEnum.PENDING
                    ? 'Pending'
                    : 'Action Required'
                  : ''}
              </B>
            </DetailContainer>
          </HeaderRowContainer>
        </TopContainer>

        {integrationSettings &&
          integrationSettings?.status === IntegrationStatusEnum.PENDING && (
            <InfoMessageContainer
              message={`Now you integrated with ${integration.toLocaleLowerCase()}, Please map all values to start using humanised with ${integration}!`}
              type="info"
              showIcon
              closable
            />
          )}
        {integrationSettings &&
          integrationSettings?.status ===
            IntegrationStatusEnum.ACTION_REQUIRED && (
            <ErrorMessageContainer
              message={
                <div className="action-required-container">
                  <B type="b-default" color={theme.black}>
                    Action Required Error!
                  </B>
                  <B
                    type="b-small"
                    cssStyle="margin-top: 10px;"
                    color={theme.black}>
                    Connection is not active with the integration anymore. So
                    the data sync will not work
                  </B>
                  <B
                    onClick={() =>
                      dispatch(getOAuth(integrationSettings.integrationId))
                    }
                    type="b-default-semibold"
                    cssStyle="margin-top: 14px; cursor: pointer;"
                    color={theme.blue500}>
                    Re-Configure
                  </B>
                </div>
              }
              type="error"
              showIcon
            />
          )}

        <SettingsContainer>
          <TabContainer>
            <RadioGroup value={selectedTab}>
              <RadioButton
                value={TabEnum.payroll}
                onClick={() => setSelectedTab(TabEnum.payroll)}>
                Payroll
              </RadioButton>
              <RadioButton
                value={TabEnum.expenses}
                onClick={() => setSelectedTab(TabEnum.expenses)}>
                Expenses
              </RadioButton>
              <RadioButton
                value={TabEnum.suppliers}
                onClick={() => setSelectedTab(TabEnum.suppliers)}>
                Suppliers
              </RadioButton>
            </RadioGroup>
          </TabContainer>
          <RowContainer>
            <LinkButton
              mr={4}
              ml={20}
              icon={<img width="18px" height="18px" src={RefreshSVG} />}
              onClick={() =>
                dispatch(refreshMetaData(Number(integrationSettings?.id)))
              }>
              Refresh
            </LinkButton>
            <DropdownIcon
              marginLeft={20}
              overlay={menu}
              placement="bottomRight"
              trigger={['click']}
            />
          </RowContainer>
        </SettingsContainer>
        <DataContainer>
          {selectedTab === TabEnum.payroll && (
            <Form form={form} name="UpdateEssentials" onFinish={onFinish}>
              <IntegrationPayroll
                essential={essential}
                setEssential={(data) => setEssential(data)}
                onValuesChange={() => setIsFieldDataChanged(true)}
              />
            </Form>
          )}
          {selectedTab === TabEnum.expenses && <IntegrationExpenses />}
          {selectedTab === TabEnum.suppliers && <IntegrationSuppliers />}
        </DataContainer>

        <FooterComponent
          leftChildren={
            <SecondaryButton
              onClick={() => {
                navigate('/company-settings/integrations');
              }}>
              Back
            </SecondaryButton>
          }
          rightChildren={
            selectedTab === TabEnum.payroll && (
              <PrimaryButton
                loading={integrationSettingUpdateLoading}
                disabled={false}
                onClick={() => form.submit()}
                htmlType="submit">
                Save
              </PrimaryButton>
            )
          }
          fullScreen={false}
        />
      </DefaultBody>
      <DeleteModalV2
        title="Disable Integration"
        buttonLabel="Disable"
        content="Are you sure you want to disable integration ?"
        submitClick={() =>
          dispatch(
            disableIntegration(Number(integrationSettings?.id), navigate),
          )
        }
        visible={visible}
        setVisible={(view) => setVisible(view)}
      />
    </Spin>
  );
};

export default IntegrationSettingPage;
