import React, { FC } from 'react';
import { DatePicker, Typography } from 'antd';
import { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker';
import theme from '../theme';
import { Div } from './Div';
import styled from '@emotion/styled';

const { Text } = Typography;
const { RangePicker } = DatePicker;
type Props = DatePickerProps & {
  label?: string;
};
const Label = styled(Text)`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.blue700};
  padding-bottom: 4px;
`;
const DatePickerCustom = styled(DatePicker)`
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  padding-left: 16px;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  &:focus {
    border: 1px solid ${theme.blue500};
    box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1);
  }

  &:hover {
    border: 1px solid ${theme.blue500};
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
    border-radius: 4px;
  }
`;

const RangePickerCustom = styled(RangePicker)`
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  padding-left: 16px;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  &:focus {
    border: 1px solid ${theme.blue500};
    box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1);
  }

  &:hover {
    border: 1px solid ${theme.blue500};
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
    border-radius: 4px;
  }
  div.ant-picker-dropdown .ant-picker-dropdown-range {
    width: 287px !important;
    overflow: scroll !important;
  }
`;

export const DatePickerComp: FC<Props> = (props) => {
  const { label, size = 'large', ...rest } = props;

  return (
    <Div display="flex" flexDirection="column">
      {label && <Label>{label}</Label>}

      <DatePickerCustom {...rest} size={size} />
    </Div>
  );
};

type RangePropsI = RangePickerProps & {
  label?: string;
};

export const RangePickerComp: FC<RangePropsI> = (props) => {
  return (
    <Div display="flex" flexDirection="column">
      {props.label && <Label>{props.label}</Label>}
      <RangePickerCustom {...props} />
    </Div>
  );
};
