import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { RadioButton, RadioGroup } from '../../../../components/RadioComp';
import { PayrollViewTypeEnum } from '../../../../types/payroll.types';

type Props = {
  onChangeView: (type: PayrollViewTypeEnum) => void;
  viewType: PayrollViewTypeEnum;
  isDraftPayroll: boolean;
};

const PayrollViewHeader: FC<Props> = ({
  onChangeView,
  viewType,
  isDraftPayroll,
}) => {
  return (
    <Row justify="space-between">
      <Col>
        <RadioGroup defaultValue={viewType} value={viewType}>
          <RadioButton
            value={PayrollViewTypeEnum.PAYROLL_VIEW}
            onClick={() => onChangeView(PayrollViewTypeEnum.PAYROLL_VIEW)}>
            Payroll
          </RadioButton>
          {isDraftPayroll && (
            <>
              <RadioButton
                value={PayrollViewTypeEnum.ERROR}
                onClick={() => onChangeView(PayrollViewTypeEnum.ERROR)}>
                Errors
              </RadioButton>
              <RadioButton
                value={PayrollViewTypeEnum.UPDATE}
                onClick={() => onChangeView(PayrollViewTypeEnum.UPDATE)}>
                Update
              </RadioButton>{' '}
            </>
          )}
          <RadioButton
            value={PayrollViewTypeEnum.LOG_TRAIL_VIEW}
            onClick={() => onChangeView(PayrollViewTypeEnum.LOG_TRAIL_VIEW)}>
            Log Trail
          </RadioButton>
        </RadioGroup>
      </Col>
    </Row>
  );
};

export default PayrollViewHeader;
