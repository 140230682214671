import styled from '@emotion/styled';
import { Col, Form, FormInstance, Row, Select, Tooltip } from 'antd';
import React, { FC, useState } from 'react';
import {
  InputComp,
  DatePickerComp,
  PrimaryButton,
  Div,
  SelectComp,
  InputNumberComp,
  B,
} from '../../components';
import { EmployeeEmailOnboardingFormI } from '../../types/people.types';
import theme from '../../theme';
import { useGetBankList } from '../../api/bankHooks';
import { getBranches } from '../../util/utils';
import { Validation } from '../../common/validation';
import { CheckBoxComp } from '../../components/v2/CheckBoxComp';
import dayjs from 'dayjs';

const { Option } = Select;

interface Props {
  onChange: (value: EmployeeEmailOnboardingFormI) => void;
  form: FormInstance;
}

const MainBody = styled.div`
  width: 942px;
  margin-top: 48px;
  margin-bottom: 24px;
  padding: 48px 80px;
  background-color: ${theme.gray50};
  border: 1px solid ${theme.gray350};
  border-radius: 8px;
  @media screen and (max-width: 768px) {
    width: fit-content;
    padding: 24px;
    margin-top: 24px;
    margin-bottom: 12px;
  }
`;

const FooterBtn = styled(Div)`
  display: flex;
  flex-direction: row-reverse;
  @media screen and (max-width: 768px) {
    padding-right: 24px;
  }
`;

const CheckBox = styled(CheckBoxComp)`
  margin-top: 35px;
  .ant-checkbox + span {
    color: ${theme.blue700};
    font-family: Inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
`;

const FormItem = styled(Form.Item)`
  .ant-typography {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.blue700} !important;
    padding-bottom: 4px;
  }
`;

const EmployeeDetailsFormPage: FC<Props> = ({ onChange, form }) => {
  const { data: banks = [] } = useGetBankList();

  const [primaryBankCode, setPrimaryBankCode] = useState('');
  const [secondaryIncome, setSecondaryIncome] = useState<boolean>(false);

  const bankOptions = banks.map((item) => ({
    value: item.bankCode,
    label: item.bankName,
  }));
  const handleOnFinish = (v) => {
    onChange(v as EmployeeEmailOnboardingFormI);
  };

  return (
    <Div display="flex" justifyContent="center">
      <Form
        validateTrigger="onChange"
        form={form}
        name="onboardUsingEmail"
        wrapperCol={{ span: 50 }}
        layout="vertical"
        onFinish={handleOnFinish}>
        <MainBody>
          <B type="b-large-semibold" mb="24px">
            Personal Details
          </B>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="fullName"
                rules={[
                  { required: true, message: 'Please enter the Full Name' },
                ]}>
                <InputComp
                  placeholder="Enter full name"
                  label="Full Name*"
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Tooltip
                placement="bottomRight"
                title="Please enter EPF submission name exactly.">
                <Form.Item
                  name="nameWithInitials"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the Name with Initials.',
                    },
                  ]}>
                  <InputComp
                    placeholder="Enter name with initials"
                    label="Name with initials*"
                    size="small"
                  />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    pattern: Validation.ValidStringPattern,
                    message: 'The input is not valid!',
                  },
                ]}>
                <InputComp
                  placeholder="Enter first name"
                  label="First name"
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    pattern: Validation.ValidStringPattern,
                    message: 'The input is not valid!',
                  },
                ]}>
                <InputComp
                  placeholder="Enter last name"
                  label="Last name"
                  size="small"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="preferredName"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the preferred name.',
                  },
                ]}>
                <InputComp
                  placeholder="Enter display name"
                  label="Preferred / Display Name*"
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="nicOrPassport"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the NIC or Passport.',
                  },
                  {
                    pattern: Validation.NicOrPassportPattern,
                    message: 'The input is not valid NIC/Passport id!',
                  },
                ]}>
                <InputComp
                  placeholder="Enter NIC/passport number"
                  label="NIC or Passport No*"
                  size="small"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="personalMobile"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the Personal Mobile.',
                  },
                  {
                    pattern: Validation.MobileNumberPattern,
                    message: 'The input is not a valid mobile number!',
                  },
                ]}>
                <InputComp
                  placeholder="Enter mobile number"
                  label="Personal Mobile*"
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="dob"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Date of Birth.',
                  },
                ]}>
                <DatePickerComp
                  size="middle"
                  label="Date of Birth*"
                  disabledDate={(current) => {
                    return current && current.isAfter(dayjs().endOf('day'));
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <B type="b-large-semibold" marginTop={24}>
            Employee Income Details
          </B>
          <Row gutter={[32, 0]} style={{ alignItems: 'baseline' }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item name="isSecondary" valuePropName="checked">
                <CheckBox
                  onChange={(v) => setSecondaryIncome(v.target.checked)}
                  checked={secondaryIncome ? secondaryIncome : false}>
                  Secondary Employee Income
                </CheckBox>
              </Form.Item>
            </Col>
            {!!secondaryIncome && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormItem
                  name="primarySalary"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the name.',
                    },
                  ]}>
                  <InputNumberComp
                    label="Primary Employment Income*"
                    size="small"
                    placeholder="Enter primary income"
                  />
                </FormItem>
              </Col>
            )}
          </Row>

          <B type="b-large-semibold" mb="24px" mt="24px">
            Emergency Details
          </B>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="emergencyName"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the name.',
                  },
                ]}>
                <InputComp
                  placeholder="Enter the name"
                  label="Name*"
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="emergencyMobileNumber"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the Emergency Mobile.',
                  },
                  {
                    pattern: Validation.MobileNumberPattern,
                    message: 'The input is not a valid mobile number!',
                  },
                ]}>
                <InputComp
                  placeholder="Enter the mobile number"
                  label="Mobile Number*"
                  size="small"
                />
              </Form.Item>
            </Col>
          </Row>
          <B type="b-large-semibold" mb="24px" mt="24px">
            Bank Details
          </B>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="bankAccountName"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the Account Name.',
                  },
                ]}>
                <InputComp
                  placeholder="Enter bank account name"
                  label="Bank Account Name*"
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="bankAccountNumber"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the Bank Account Number.',
                  },
                  {
                    pattern: Validation.BankAccountNumberPattern,
                    message:
                      'The input is not a valid Bank Account Number valid!',
                  },
                ]}>
                <InputComp
                  placeholder="Enter bank account number"
                  label="Account Number*"
                  size="small"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="bankBankCode"
                rules={[
                  {
                    required: true,
                    message: 'Please select your bank name!',
                  },
                ]}>
                <SelectComp
                  showSearch
                  size="middle"
                  label="Bank*"
                  placeholder="Select a bank name"
                  optionFilterProp="children"
                  onChange={(e) => {
                    setPrimaryBankCode(String(e));
                    form.setFieldsValue({ bankBranchId: undefined });
                  }}>
                  {bankOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.value} - {option.label}
                    </Option>
                  ))}
                </SelectComp>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="bankBranchId"
                rules={[
                  {
                    required: true,
                    message: 'Please select your branch name!',
                  },
                ]}>
                <SelectComp
                  showSearch
                  size="middle"
                  label="Branch*"
                  placeholder="Select a branch name"
                  optionFilterProp="children">
                  {getBranches(primaryBankCode, banks).map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.code} - {option.label}
                    </Option>
                  ))}
                </SelectComp>
              </Form.Item>
            </Col>
          </Row>
        </MainBody>
        <FooterBtn>
          <PrimaryButton htmlType="submit">Next</PrimaryButton>
        </FooterBtn>
      </Form>
    </Div>
  );
};

export default EmployeeDetailsFormPage;
