import React, { FC, Fragment } from 'react';
import { Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import {
  B,
  DeleteModal,
  EmptyTextContainer,
  TableComp,
} from '../../../components';
import { deleteLeave } from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import { OrganizationLeaveI } from '../../../types';
import { LeaveStatus } from '../../home/homeUtil';
import LeavePeriodDisplay from '../../home/shared/leaves/LeavePeriodDisplay';
import LeaveTypeDisplay from '../../home/shared/leaves/LeaveTypeDisplay';
import { convertHoursToHoursAndMin } from '../utils';
import UserLeave from './UserLeave';
import { ExpenseStatusTag } from '../../expenses/shared';
import { DataRangeI } from '../../../types/report.types';
import moment from 'moment';

interface Props {
  tableScrollY?: number;
  myLeaves: OrganizationLeaveI[];
  dateRange: DataRangeI | null;
}

const UserLeaveItem = styled.div`
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

export const StatusColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;
const TableCard = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
const UserLeavesTable: FC<Props> = ({ tableScrollY, myLeaves, dateRange }) => {
  const dispatch: DispatchType = useDispatch();
  const myLeavesLoading = useSelector(
    (state: RootState) => state.leave.myLeavesLoading,
  );
  const scrollTarget = useSelector(
    (state: RootState) => state.dashboard.scrollTarget,
  );

  const columns: ColumnProps<OrganizationLeaveI>[] = [
    {
      title: 'Date',
      dataIndex: 'date',
      width: 120,
      render: (_hours: string, data) => <LeavePeriodDisplay data={data} />,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      render: (_value, data) =>
        data.reviewBy ? (
          <Tooltip
            placement="topLeft"
            title={
              <Fragment>
                {data.reviewBy && (
                  <B color="white" type="b-small">
                    Reviewed By : {data.reviewBy.fullName}
                  </B>
                )}
                {data.reviewBy && data.status === LeaveStatus.REJECTED && (
                  <B color="white" type="b-small">
                    Reject Note : {data.rejectionNote || '-'}
                  </B>
                )}
              </Fragment>
            }>
            <StatusColumn>
              <ExpenseStatusTag status={data.status} />
            </StatusColumn>
          </Tooltip>
        ) : (
          <StatusColumn>
            <ExpenseStatusTag status={data.status} />
          </StatusColumn>
        ),
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      width: 120,
      render: (_hours: string, data) => (
        <B type="b-default">{convertHoursToHoursAndMin(data.hours)}</B>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 140,
      render: (_value, data) => <LeaveTypeDisplay data={data} />,
    },

    {
      title: 'Comment',
      dataIndex: 'comment',
      width: '100%',
      ellipsis: true,
      render: (_value, data) => (
        <>
          <B type="b-default">
            <Tooltip placement="topLeft" title={data.reason}>
              {data.reason || '-'}
            </Tooltip>
          </B>
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'cancel',
      width: 100,
      render: (_value, data) => (
        <>
          {data.status == LeaveStatus.PENDING && (
            <DeleteModal
              title="Confirm Deletion"
              buttonLabel="Delete"
              content="Are you sure you want to delete this leave?"
              onClickCancel={() => {
                dispatch(deleteLeave(String(data.id)));
              }}
              openModal={
                <B cursor="pointer" type="b-default" color="#0052ea">
                  Delete
                </B>
              }
            />
          )}
        </>
      ),
    },
  ];

  const locale = {
    emptyText: (
      <EmptyTextContainer description="You dont have any leave requested yet" />
    ),
  };

  const startDate = moment(dateRange?.startDate).format('YYYY-MM-DD');
  const endDate = moment(dateRange?.endDate).format('YYYY-MM-DD');
  let filteredDateRange = null;

  if (dateRange?.startDate === null || dateRange?.endDate === null) {
    filteredDateRange = myLeaves;
  } else {
    filteredDateRange = myLeaves.filter(
      (item) => item.date <= endDate && item.date >= startDate,
    );
  }

  return (
    <>
      <TableCard>
        <TableComp
          headerFontSize={14}
          loading={myLeavesLoading}
          columns={columns}
          rowClassName={(row) => {
            return row.id === scrollTarget ? 'yellow-highlight' : '';
          }}
          dataSource={filteredDateRange}
          locale={locale}
          pagination={false}
          withBorders={true}
          scroll={{
            x: 700,
            y: tableScrollY || window.innerHeight - 320,
          }}
        />
      </TableCard>
      <UserLeaveItem>
        {!!myLeaves.length ? (
          <>
            {myLeaves.map((item) => (
              <UserLeave leavesDetails={item} />
            ))}
          </>
        ) : (
          <EmptyTextContainer description="You don't have any leave yet" />
        )}
      </UserLeaveItem>
    </>
  );
};
export default UserLeavesTable;
