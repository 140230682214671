import React, { FC } from 'react';
import { Divider, Row } from 'antd';
import { B, H, PrimaryButton } from '../../../components';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import { FillSVG } from '../../../assets';

const DividerContainer = styled(Divider)`
  margin: 24px 0 18px;
  .ant-divider {
    border-top: 1px solid #e0e0e0;
  }
`;

const FormContainer = styled.div`
  max-width: 420px;
  padding: 40px 24px;
  margin: 0 auto;
    @media (min-width: 768px) and (max-width: 1920px) {
      width: 420px;
    }
    @media (max-width: 450px) {
      padding: 40px 32px;
    }
  }
`;

const ResetLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkExpired: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <FormContainer>
        <img src={FillSVG} />
        <H type="h6" pt="16px">
          Link Expired!
        </H>
        <B type="b-default" color="#757575" pt="4px" pb="24px">
          Either the link had already expired or this link was already used to
          change the password.
        </B>

        <Row>
          <PrimaryButton
            block
            htmlType="submit"
            onClick={() => {
              navigate('/forgetPassword');
            }}>
            Send me a new link
          </PrimaryButton>
        </Row>
        <DividerContainer />
        <ResetLinkContainer>
          <Link to="/login">
            <B type="b-default" color="#0052EA">
              Back to Sign In
            </B>
          </Link>
        </ResetLinkContainer>
      </FormContainer>
    </>
  );
};

export default LinkExpired;
