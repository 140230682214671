import React, { FC, MouseEventHandler } from 'react';
import Icon from '@ant-design/icons';
import { Image } from './Image';

interface Icon {
  icon: string;
  onClick?: () => void;
  onMouseleave?: MouseEventHandler<HTMLSpanElement>;
  onMouseEnter?: MouseEventHandler<HTMLSpanElement>;
  marginBottom?: string;
}

export const CustomIcon: FC<Icon> = ({
  icon,
  onClick,
  marginBottom,
  onMouseEnter,
  onMouseleave,
}) => {
  return (
    <Icon
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseleave}
      component={() => (
        <Image
          src={require(`../assets/Icons/${icon}`)}
          marginDottom={`${marginBottom ? marginBottom : '10px'}`}
        />
      )}
      onClick={onClick}
    />
  );
};
