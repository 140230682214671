/** @jsxImportSource @emotion/react */

import React, { FC, useEffect, useState } from 'react';
import { Col, Dropdown, Menu, message, Row, Spin, Table } from 'antd';
import _ from 'lodash';
import qs from 'qs';
import { useNavigate, useLocation } from 'react-router';

import { CheckOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

import {
  B,
  CustomCard,
  Div,
  H,
  PrimaryButton,
  SecondaryButton,
} from '../../../components';
import { CSVMapper, KeyValueI, MappedDataI } from './shared/CSVMapper';
import theme from '../../../theme';
import { getCSVDataFromUrl } from './util/getCSVDataFromUrl';
import {
  ColumnMappingI,
  columnMappings,
  getDataMappingErrors,
  UN_ASSIGNED,
} from './util/openPayrollValidator';
import ArrowColumnHeader from './shared/ArrowColumnHeader';
import DataMappingErrors from './shared/DataMappingErrors';
import EditableCell from './shared/EditableCell';
import NoCSVUploaded from './shared/NoCSVUploaded';

const { Column, ColumnGroup } = Table;

interface LocationDataI {
  fileUrl: string;
}

const MapColumns: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [wrapper, setWrapper] = useState<CSVMapper>(new CSVMapper([], []));

  const [currentColIndex, setCurrentColIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const [mapDataColumns, setMapDataColumns] = useState<MappedDataI[]>([]);
  const [dataSource, setDataSource] = useState<KeyValueI[]>([]);

  const errors = getDataMappingErrors(dataSource, mapDataColumns);
  useEffect(() => {
    initialize();
  }, []);

  async function initialize() {
    try {
      setLoading(true);
      const queries = qs.parse(location.search.replace('?', ''));
      if (typeof queries.fileUrl === 'string') {
        const { headers, data } = await getCSVDataFromUrl(queries.fileUrl);
        const csvMapper = new CSVMapper(headers, data);
        setMapDataColumns(csvMapper.getMappedData());
        setDataSource(csvMapper.getTableDataSource());
        setWrapper(csvMapper);
      } else {
        message.error('CSV file is missing....');
      }
    } catch (error: any) {
      message.error('Something went wrong please try again...');
    } finally {
      setLoading(false);
    }
  }

  function getRecognizedCount() {
    return mapDataColumns.filter((col) => !col.isUnassigned).length;
  }

  function colorNth() {
    return mapDataColumns.map((item, i) => {
      let styleStr = ``;
      if (i + 1 == 1) {
        styleStr =
          styleStr +
          `:nth-of-type(${i + 1}){
          border-top-left-radius: 4px;
        }`;
      }
      if (i + 1 == getRecognizedCount()) {
        styleStr =
          styleStr +
          `:nth-of-type(${i + 1}){
          border-top-right-radius: 4px;
        }`;
      }

      if (!item.isUnassigned && item.isUnassigned) {
        styleStr =
          styleStr +
          `:nth-of-type(${i + 1}){
                    background-color: #E8F5E9;
                    color: #2E7D32
                  }`;
      } else {
        if (currentColIndex == i)
          styleStr =
            styleStr +
            `:nth-of-type(${i + 1}){
          background-color: #0052EA;
          color: #ffffff
        }`;
      }
      return styleStr;
    });
  }

  function colorNthTd() {
    return mapDataColumns.map((_item, i) => {
      if (currentColIndex == i)
        return `:nth-of-type(${i + 1}){
          background-color: #EEEEEE;
          color: #202125
        }`;
      else {
        return `:nth-of-type(${i + 1}){
          background-color: #fff;
          color: #202125
        }`;
      }
    });
  }

  function updateColumn(
    columnIndex: number,
    columnMapping: ColumnMappingI | null,
  ) {
    wrapper.updateColumn(columnIndex, columnMapping);
    setMapDataColumns(_.cloneDeep(wrapper.getMappedData()));
    setDataSource(_.cloneDeep(wrapper.getTableDataSource()));
  }

  function renderTable() {
    return _.map(mapDataColumns, (item, i) => {
      return (
        <ColumnGroup
          title={
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  {columnMappings.map((columnMapping) => {
                    const isChecked =
                      item.dataIndex === columnMapping.dataIndex;
                    const mappedData = wrapper.getMappedDataByDataIndex(
                      columnMapping.dataIndex,
                    );
                    const isDisabled = !!mappedData;
                    return (
                      <Menu.Item
                        key={columnMapping.dataIndex}
                        disabled={isDisabled}
                        css={css`
                          color: ${isChecked
                            ? '#0052EA'
                            : isDisabled
                            ? '#a1a1a1'
                            : '#202125'};
                          border-bottom: 1px solid #e0e0e0;
                        `}
                        onClick={() => {
                          updateColumn(i, columnMapping);
                        }}>
                        {columnMapping.name}
                        {isChecked ? (
                          <CheckOutlined
                            css={css`
                              padding-left: 4px;
                            `}
                          />
                        ) : null}
                      </Menu.Item>
                    );
                  })}
                  <Menu.Item
                    key={UN_ASSIGNED}
                    onClick={() => updateColumn(i, null)}>
                    {UN_ASSIGNED}
                  </Menu.Item>
                </Menu>
              }>
              <ArrowColumnHeader
                name={item.name || UN_ASSIGNED}
                isCurrentIndex={currentColIndex === i}
                isAutoMapped={item.isAutoMapped}
                onClick={() => {
                  setCurrentColIndex(i);
                }}
              />
            </Dropdown>
          }>
          <ColumnGroup>
            <Column
              title={item.csvHeader}
              dataIndex={item.dataIndex}
              key={item.dataIndex}
              render={(value, record: any, index) => {
                return (
                  <EditableCell
                    record={record}
                    value={value}
                    dataIndex={item.dataIndex}
                    handleSave={handleSave}
                    errors={errors}
                    rowIndex={index}></EditableCell>
                );
              }}
            />
          </ColumnGroup>
        </ColumnGroup>
      );
    });
  }
  const handleSave = (
    inputValue: string,
    dataIndex: string,
    rowIndex: number,
  ) => {
    wrapper.updateValue(dataIndex, rowIndex, inputValue);
    setDataSource(wrapper.getTableDataSource());
  };
  return (
    <>
      {mapDataColumns.length ? (
        <>
          <CustomCard>
            <Row
              justify="space-between"
              css={css`
                padding: 16px 32px;
              `}>
              <Col>
                <H type="h5">CSV Data Mapping Process</H>
              </Col>
              <Col></Col>
            </Row>
            <Row
              css={css`
                padding-left: 32px;
              `}>
              <B type="b-default">
                {getRecognizedCount()} out of {mapDataColumns.length} Columns
                Mapped
              </B>
            </Row>
            <Row
              css={css`
                padding: 0 32px 16px 32px;
              `}>
              {' '}
              <Row
                css={css`
                  padding-bottom: 40px;
                  width: 100%;
                `}
                justify="space-between">
                <B color={theme.gray700} type="b-default">
                  Select the column that contains your mandatory information
                </B>
                <div>
                  <PrimaryButton
                    disabled={currentColIndex === 0}
                    onClick={() => {
                      setCurrentColIndex(
                        currentColIndex <= 0 ? 0 : currentColIndex - 1,
                      );
                    }}>
                    <LeftOutlined />
                  </PrimaryButton>
                  &nbsp;&nbsp;&nbsp;
                  <PrimaryButton
                    disabled={currentColIndex === mapDataColumns.length - 1}
                    onClick={() => {
                      setCurrentColIndex(
                        currentColIndex >= mapDataColumns.length - 1
                          ? mapDataColumns.length - 1
                          : currentColIndex + 1,
                      );
                    }}>
                    <RightOutlined />
                  </PrimaryButton>
                </div>
              </Row>
              <Row
                css={css`
                  width: 100%;
                `}>
                <Table
                  pagination={false}
                  dataSource={dataSource}
                  scroll={{
                    y: dataSource.length * 80,
                    x: mapDataColumns.length * 230,
                  }}
                  css={css`
                    .ant-table-thead > tr > th {
                      background: #fafafa;
                      font-size: 14px;
                      line-height: 20px;
                      color: #5f6267;
                      height: 40px;
                      border-bottom: 0;
                      div {
                        :nth-of-type(1) {
                          .ant-image.arrow-img {
                            .ant-image-img {
                              height: 40px;
                              width: 100%;
                              object-fit: cover;
                              min-width: 230px;
                            }
                          }
                        }
                      }
                    }
                    .ant-table-thead > tr {
                      :nth-of-type(1) {
                        th {
                          padding: 0 !important;
                        }
                      }
                      :nth-of-type(2) {
                        th {
                          background-color: #fff !important;
                          padding: 10px !important;
                        }
                      }
                      :nth-of-type(3) {
                        th {
                          ${colorNth().join('\n')}
                          padding: 10px !important;
                        }
                      }
                    }
                    .ant-table-tbody > tr > td {
                      border-bottom-color: #e0e0e0;
                      padding: 16px 16px !important;
                      ${colorNthTd().join('\n')}
                    }
                  `}>
                  {renderTable()}
                </Table>
              </Row>
            </Row>
            {errors.length ? (
              <Row
                css={css`
                  padding: 32px;
                  border-top: 1px solid #e0e0e0;
                  border-bottom: 1px solid #e0e0e0;
                `}>
                <Div
                  css={css`
                    padding: 32px;
                    background-color: #ffebee;
                    width: 100%;
                  `}>
                  <DataMappingErrors errors={errors} />
                </Div>
              </Row>
            ) : null}

            <Row
              justify="space-between"
              css={css`
                padding: 32px;
              `}>
              <SecondaryButton onClick={() => navigate('/open-payroll')}>
                Upload CSV file Again
              </SecondaryButton>
              <div>
                <SecondaryButton>Cancel</SecondaryButton>
                &nbsp;&nbsp;&nbsp;
                <PrimaryButton
                  onClick={() => {
                    navigate('/open-salary-information', {
                      state: {
                        mapDataColumns: wrapper.getMappedData(),
                      },
                    });
                  }}
                  disabled={errors.length > 0}>
                  Next
                </PrimaryButton>
              </div>
            </Row>
          </CustomCard>
        </>
      ) : loading ? (
        <Div
          css={css`
            text-align: center;
            padding: 50px;
          `}>
          <Spin />
        </Div>
      ) : (
        <NoCSVUploaded />
      )}
    </>
  );
};

export default MapColumns;
