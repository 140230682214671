import moment from 'moment';
import { SummaryDataI, UserHistoryI } from '../../types/people.types';
import { OCG_LIST } from './occupationClassificationGrades';
const DATE_FORMAT = 'DD.MM.YYYY';

export const getEmployeeEndDate = (userHistories: UserHistoryI[]): string => {
  const lastJob = getLatestJob(userHistories);

  if (!lastJob) {
    return '-';
  }

  if (lastJob.isCurrentJob) {
    return 'On Going';
  }
  return moment(lastJob.endDate).format(DATE_FORMAT);
};

export const getPositionLevel = (userHistories: UserHistoryI[]) => {
  return getLatestJob(userHistories)?.positionLevel || '-';
};

export const getDesignation = (userHistories: UserHistoryI[]) => {
  return getLatestJob(userHistories)?.designation || '-';
};

export const getEmpType = (userHistories: UserHistoryI[]) => {
  return getLatestJob(userHistories)?.employmentType || '-';
};

export const getLatestJob = (
  userHistories?: UserHistoryI[],
): UserHistoryI | null => {
  if (!userHistories || !userHistories.length) {
    return null;
  }
  let lastJob = userHistories[0];

  for (const userHistory of userHistories) {
    if (userHistory.isCurrentJob) {
      lastJob = userHistory;
      break;
    }

    // startDate is Before Today and end date is after today
    // also get latest job
    if (
      moment(userHistory.startDate).isBefore(moment()) &&
      moment(userHistory.endDate).isAfter(moment()) &&
      moment(userHistory.startDate).isAfter(lastJob.startDate)
    ) {
      lastJob = userHistory;
    }
  }
  return lastJob;
};

export const getEmployeeStartDate = (userHistories: UserHistoryI[]) => {
  if (!userHistories?.length) {
    return {
      value: 0,
      displayValue: '-',
    };
  }

  let firstStartDate = moment(); // today

  userHistories.forEach((userHistory) => {
    if (firstStartDate.diff(moment(userHistory.startDate)) >= 0) {
      firstStartDate = moment(userHistory.startDate);
    }
  });
  return {
    value: firstStartDate.toDate().getTime(),
    displayValue: firstStartDate.format(DATE_FORMAT),
  };
};

export const getPersonalDetail = (data?: SummaryDataI) => {
  return {
    nameWithInitials: data?.savedUser ? data.savedUser.nameWithInitials : '-',
    fullName: data?.savedUser ? data.savedUser.fullName : '-',
    preferredName: data?.savedUser ? data.savedUser.preferredName : '-',
    nic: data?.savedUser ? data.savedUser.nicOrPassport : '-',
  };
};
export const getEmploymentDetail = (data?: SummaryDataI) => {
  return {
    employeeNumber: data?.savedUser ? data.savedUser.employeeNumber : '-',
    joinedDate: data?.savedUser ? data.savedUser.joinedDate : '-',
    occupationGrade: data?.savedUser
      ? OCG_LIST.filter(
          (item) => item.id === data?.savedUser.occupationGrade,
        ).map((d) => {
          return d.id + `-` + d.grade;
        })[0]
      : '-',
    etfNumber: data?.savedUser ? data.savedUser.etfNumber : '-',
    epfNumber: data?.savedUser ? data.savedUser.epfNumber : '-',
    taxNumber: data?.savedUser ? data.savedUser.taxNumber : '-',
  };
};

export const getCurrentEmployment = (data?: SummaryDataI) => {
  return {
    designation: data?.savedUser.userHistory
      ? data.savedUser.userHistory[0].designation
      : '-',
    employmentType: data?.savedUser.userHistory
      ? data.savedUser.userHistory[0].employmentType
      : '-',
    startDate: data?.savedUser.userHistory
      ? data.savedUser.userHistory[0].startDate
      : '-',
  };
};

export function durationByYearAndMonth(
  startDate: string | undefined,
  endDate: string | undefined,
) {
  const start = moment(startDate, 'DD.MM.YYYY');
  const end = moment(endDate, 'DD.MM.YYYY');

  const isAfterFebruary = start.month() > 1;

  const convertEnd =
    isAfterFebruary && start.isLeapYear()
      ? moment(endDate, 'DD.MM.YYYY').add(2, 'days').format('DD.MM.YYYY')
      : start.isLeapYear() || end.isLeapYear()
      ? moment(endDate, 'DD.MM.YYYY').add(1, 'days').format('DD.MM.YYYY')
      : moment(endDate, 'DD.MM.YYYY').add(2, 'days').format('DD.MM.YYYY');

  const validEnd = moment(convertEnd, 'DD.MM.YYYY');

  const difference = validEnd.diff(start);
  const years = moment.duration(difference).years();
  const months = moment.duration(difference).months();

  if (years && months) {
    return years + ' Years ' + months + ' Months';
  } else if (!years && !months) {
    return '-';
  } else if (!years) {
    return months + ' Months';
  } else if (!months) {
    return years + ' Years';
  }
}
