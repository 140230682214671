import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ColumnProps } from 'antd/lib/table';
import {
  B,
  EmptyTextContainer,
  FooterComponent,
  LinkButton,
  SecondaryButton,
  TableComp,
} from '../../../components';
import { DropdownButton } from '../../../components/DropdownButtonComponent';
import { Menu, Spin, message } from 'antd';
import theme from '../../../theme';
import {
  useCreateExpenseCategory,
  useDeleteExpenseCategory,
  useGetExpenseCategoriesList,
  useUpdateExpenseCategory,
} from '../../../api/expenseHooks';
import {
  ExpenseCreation,
  ExpenseMeta,
  ExpenseMetaData,
  IntegrationStatusEnum,
  Permission,
  SuppliersList,
} from '../../../types';
import { getApiError } from '../../../util/getApiError';
import { useNavigate } from 'react-router';
import { getIntegrationSetting, refreshMetaData } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType, RootState } from '../../../store/reducer';
import { ManageExpenseMetaModal } from '../shared/ManageExpenseMetaModal';
import { CollapsibleList } from '../shared/CollapsibleList';
import {
  ErrorMessageContainer,
  WarnMessageContainer,
} from '../../settings/integration-apps/components/IntegrationAlerts';
import { isAllowed } from '../../../util/permissionUtil';
import { RefreshSVG } from '../../../assets';

const Body = styled.div`
  padding: 28px 32px;
  border-bottom: 1px solid ${theme.gray300};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CustomMenu = styled(Menu)`
  padding: 0 !important;
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: ${theme.blue500};
    background: ${theme.lightBlue100};
  }
`;

const MenuItem = styled(Menu.Item)`
  border-bottom: 1px solid ${theme.gray300};
  margin: 0px !important;
`;

const CustomLinkButton = styled(LinkButton)`
  margin-left: 20px;
  margin-right: 4px;
  height: 32px;
`;

const TableContainer = styled.div`
  margin-bottom: 15px;
  .ant-table-cell {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .ant-table-wrapper {
    border: none !important;
  }
`;
const EmptyContainer = styled.div`
  margin-top: 15px;
`;

const ExpensesCategoriesPage: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const navigate = useNavigate();

  // get integration permissions
  const isAllowedManageIntegration: boolean = isAllowed(
    Permission.MANAGE_INTEGRATION,
  );

  const [visibility, setVisibility] = useState<boolean>(false);
  const [subCategory, setSubCategory] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<
    (SuppliersList | ExpenseMetaData) | null
  >(null);

  const {
    isLoading: categoriesListLoading,
    error: categoriesListError,
    data: getCategoriesList,
    refetch: getCategoriesListFn,
  } = useGetExpenseCategoriesList();

  const {
    isLoading: loadingCreateCategory,
    error: errorCreateCategory,
    mutate: createCategory,
    isSuccess: createCategorySuccess,
  } = useCreateExpenseCategory();

  const {
    isLoading: loadingUpdateCategory,
    error: errorUpdateCategory,
    mutate: updateCategory,
    isSuccess: UpdateCategorySuccess,
  } = useUpdateExpenseCategory();

  const {
    isLoading: loadingDeleteCategory,
    error: errorDeleteCategory,
    mutate: deleteCategory,
    isSuccess: deleteCategorySuccess,
  } = useDeleteExpenseCategory();

  const integrationSettings = useSelector(
    (state: RootState) => state.integration.integrationSetting,
  );
  const loadingIntegrationSettings = useSelector(
    (state: RootState) => state.integration.integrationSettingLoading,
  );

  // error handling
  useEffect(() => {
    categoriesListError && message.error(getApiError(categoriesListError));
    errorCreateCategory && message.error(getApiError(errorCreateCategory));
    errorDeleteCategory && message.error(getApiError(errorDeleteCategory));
    errorUpdateCategory && message.error(getApiError(errorUpdateCategory));
  }, [
    categoriesListError,
    errorCreateCategory,
    errorDeleteCategory,
    errorUpdateCategory,
  ]);

  // success handling
  useEffect(() => {
    if (createCategorySuccess && !errorCreateCategory) {
      message.success('Created Successfully!');
      setVisibility(false);
      getCategoriesListFn();
    }
  }, [createCategorySuccess]);

  useEffect(() => {
    if (UpdateCategorySuccess && !errorUpdateCategory) {
      message.success('Updated Successfully!');
      setVisibility(false);
      getCategoriesListFn();
    }
  }, [UpdateCategorySuccess]);

  useEffect(() => {
    if (deleteCategorySuccess && !errorDeleteCategory) {
      message.success('Deleted Successfully!');
      getCategoriesListFn();
    }
  }, [deleteCategorySuccess]);

  // load list after integration refresh
  useEffect(() => {
    if (!loadingIntegrationSettings) {
      getCategoriesListFn();
    }
  }, [loadingIntegrationSettings]);

  // integration settings
  useEffect(() => {
    if (
      getCategoriesList &&
      getCategoriesList?.type !== ExpenseCreation.SYSTEM
    ) {
      dispatch(getIntegrationSetting(getCategoriesList?.type));
    }
  }, [getCategoriesList]);

  const updateMeta = (data: SuppliersList | ExpenseMetaData) => {
    setUpdateData(data);
    setSubCategory(data.parentRef ? true : false);
    setVisibility(true);
  };

  const columns: ColumnProps<SuppliersList | ExpenseMetaData>[] = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      render: (name, data) => {
        return (
          <CollapsibleList
            isSystem={Boolean(
              getCategoriesList?.type === ExpenseCreation.SYSTEM,
            )}
            type={ExpenseMeta.CATEGORIES}
            data={data}
            deleteAction={(i) => deleteCategory(i)}
            deleteActionLoading={loadingDeleteCategory}
            updateAction={(i) => updateMeta(i)}
          />
        );
      },
    },
  ];

  return (
    <Spin spinning={categoriesListLoading || loadingIntegrationSettings}>
      <Body>
        <Header>
          <B type="b-large-semibold">Expense Categories</B>
          {getCategoriesList?.type !== ExpenseCreation.SYSTEM ? (
            <CustomLinkButton
              icon={<img width="18px" height="18px" src={RefreshSVG} />}
              onClick={() =>
                dispatch(refreshMetaData(Number(integrationSettings?.id)))
              }>
              Refresh
            </CustomLinkButton>
          ) : (
            <DropdownButton
              size="small"
              menu={
                <CustomMenu>
                  <MenuItem
                    onClick={() => [
                      setUpdateData(null),
                      setSubCategory(false),
                      setVisibility(true),
                    ]}>
                    Main Category
                  </MenuItem>
                  <MenuItem
                    onClick={() => [
                      setUpdateData(null),
                      setSubCategory(true),
                      setVisibility(true),
                    ]}>
                    Sub Category
                  </MenuItem>
                </CustomMenu>
              }>
              Create
            </DropdownButton>
          )}
        </Header>
      </Body>
      {isAllowedManageIntegration &&
        getCategoriesList?.type !== ExpenseCreation.SYSTEM &&
        !categoriesListLoading &&
        [
          IntegrationStatusEnum.ACTIVATED,
          IntegrationStatusEnum.PENDING,
        ].includes(getCategoriesList?.status as IntegrationStatusEnum) && (
          <WarnMessageContainer
            MarginLeft="32px"
            message={`The expense categories listed here are from ${getCategoriesList?.type.toLocaleLowerCase()}. If you'd like to change/manage them, you can do so directly from ${getCategoriesList?.type.toLocaleLowerCase()}.`}
            type="warning"
            showIcon
            closable
          />
        )}
      {isAllowedManageIntegration &&
        getCategoriesList?.type !== ExpenseCreation.SYSTEM &&
        !categoriesListLoading &&
        [IntegrationStatusEnum.ACTION_REQUIRED].includes(
          getCategoriesList?.status as IntegrationStatusEnum,
        ) && (
          <ErrorMessageContainer
            marginLeft="32px"
            message={
              <>
                Opps! Looks like your{' '}
                <b>{getCategoriesList?.type.toLocaleLowerCase()}</b> integration
                is not working anymore.
              </>
            }
            type="error"
            showIcon
          />
        )}
      {getCategoriesList?.metaData &&
      Boolean(getCategoriesList?.metaData.length) ? (
        <TableContainer>
          <TableComp
            showHeader={false}
            loading={false}
            columns={columns}
            dataSource={getCategoriesList.metaData || []}
            pagination={false}
            withBorders={true}
            scroll={{
              y:
                window.innerHeight -
                (getCategoriesList?.type !== ExpenseCreation.SYSTEM
                  ? 280
                  : 235),
            }}
          />
        </TableContainer>
      ) : (
        <EmptyContainer>
          <EmptyTextContainer description="You don't have any categories yet" />
        </EmptyContainer>
      )}
      <FooterComponent
        leftChildren={
          <SecondaryButton
            onClick={() => {
              navigate('/expenses');
            }}>
            Back
          </SecondaryButton>
        }
        fullScreen={false}
      />

      <ManageExpenseMetaModal
        type={ExpenseMeta.CATEGORIES}
        visibility={visibility}
        subCategory={subCategory}
        updateData={updateData}
        setVisibility={(data) => setVisibility(data)}
        metaData={getCategoriesList?.metaData || []}
        createAction={(data) => createCategory(data)}
        createLoading={loadingCreateCategory}
        updateAction={(data) => updateCategory(data)}
        updateLoading={loadingUpdateCategory}
      />
    </Spin>
  );
};

export default ExpensesCategoriesPage;
