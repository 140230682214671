import { isBelowLevelUser, isSameLevelUser } from './permissionUtil';
import { capitalizeUnderscore } from './utils';
import { UserRoleIdEnum, UserRoleResponseType } from '../types';

/**
 * Get list of user roles that user can change in the list
 */
export const getAllowedUserRoles = (userRoles: UserRoleResponseType[]) => {
  const userRolesForUser = userRoles.filter((role) => {
    if (
      role.id !== UserRoleIdEnum.OWNER &&
      role.id !== UserRoleIdEnum.GUEST_ADMIN
    )
      return role;
    else return false;
  });

  const userRoleOptions = userRolesForUser
    .filter(
      (item) =>
        isBelowLevelUser(item.roleLevel) || isSameLevelUser(item.roleLevel),
    )
    .sort((a, b) => a.roleLevel - b.roleLevel)
    .map((item) => {
      return { value: item.id, label: item.role };
    });

  return userRoleOptions;
};
