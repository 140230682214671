import React, { FC, useEffect, useState } from 'react';

import { ConfigProvider, Pagination, Spin, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { CalendarOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import { useGetGeneratedBankFileList } from '../../../../api/expenseHooks';
import { B, EmptyPage, TableComp } from '../../../../components';
import { RootState } from '../../../../store/reducer';
import { ExpenseGeneratedBankFilesI } from '../../../../types';
import { useWindowSize } from '../../../../util/screenSize';
import {
  addDotsForLongText,
  numberThousandSeparator,
} from '../../../../util/utils';
import { DownloadBlack } from '../../../../assets';

const LastColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

const PaginationLayout = styled(Pagination)`
  padding-top: 20px;
  text-align: center;
`;

const GeneratedBankFilesTable: FC = () => {
  const { isDesktopLarge } = useWindowSize();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [item, setItem] = useState<ExpenseGeneratedBankFilesI[]>([]);
  const {
    isLoading: expenseListLoading,
    data: getExpenseList,
    refetch: getGeneratedBankFileList,
  } = useGetGeneratedBankFileList({ page: page, limit: pageSize });

  useEffect(() => {
    getGeneratedBankFileList();
  }, [page, pageSize]);

  useEffect(() => {
    if (getExpenseList) {
      setTotal(getExpenseList.meta.totalItems);
      setItem(getExpenseList.items);
    }
  }, [getExpenseList]);

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <CalendarOutlined style={{ fontSize: 40 }} />
      <p>No Generated Bank Files</p>
    </div>
  );

  const columns: ColumnProps<ExpenseGeneratedBankFilesI>[] = [
    {
      title: 'Date',
      width: 120,
      dataIndex: 'items.createdAt',
      render: (value: string) => (
        <B type="b-small">{moment(value).format('DD MMM YYYY')}</B>
      ),
    },
    {
      title: 'Reference No.',
      width: 120,
      dataIndex: 'reference',
      render: (value: string) => <B type="b-small">{value ? value : '-'}</B>,
    },
    {
      title: 'Bank Name',
      width: 120,
      dataIndex: 'bankName',
      render: (value: string) => <B type="b-small">{value ? value : '-'}</B>,
    },
    {
      title: 'Date of Crediting',
      width: 130,
      dataIndex: 'dateOfCrediting',
      render: (value: string) => (
        <B type="b-small">{value ? moment(value).format('DD.MM.YYYY') : '-'}</B>
      ),
    },
    {
      title: 'Value Date',
      width: 120,
      dataIndex: 'valueDate',
      render: (value: string) => (
        <B type="b-small">{value ? moment(value).format('DD.MM.YYYY') : '-'}</B>
      ),
    },
    {
      title: 'Remark',
      width: 150,
      dataIndex: 'transactionReference',
      render: (value: string) => (
        <B type="b-small">
          {value ? (
            <Tooltip placement="topLeft" title={value}>
              {isDesktopLarge
                ? addDotsForLongText(value, 30)
                : addDotsForLongText(value, 20)}
            </Tooltip>
          ) : (
            '-'
          )}
        </B>
      ),
    },
    {
      title: 'Total',
      width: 120,
      dataIndex: 'total',
      render: (_value: string, value) => (
        <B type="b-small">
          {value.total ? numberThousandSeparator(value.total) : '-'}
        </B>
      ),
    },
    {
      title: '',
      fixed: 'right',
      width: 70,
      dataIndex: 'fileUrl',
      render: (value: string) => (
        <>
          <LastColumn>
            <a href={value} download>
              <img src={DownloadBlack} />
            </a>
          </LastColumn>
        </>
      ),
    },
  ];

  const errorHandling = useSelector(
    (state: RootState) => state.payroll.errorHandling,
  );

  return (
    <>
      {errorHandling ? (
        <div>
          <EmptyPage
            content="Sorry, the page you visited does not exist."
            title="something went wrong !"
            buttonLabel=" Back Home"
          />
        </div>
      ) : (
        <>
          <Spin spinning={expenseListLoading}>
            <ConfigProvider renderEmpty={customizeRenderEmpty}>
              <div>
                <TableComp
                  size="middle"
                  columns={columns}
                  withBorders={true}
                  dataSource={item}
                  pagination={false}
                  loading={expenseListLoading}
                  scroll={{
                    y: 502,
                  }}
                />
                {total === 0 ? null : (
                  <PaginationLayout
                    current={page}
                    total={total}
                    pageSize={pageSize}
                    onChange={(page, pageSize) => {
                      setPage(page);
                      setPageSize(pageSize ? pageSize : 10);
                    }}
                  />
                )}
              </div>
            </ConfigProvider>
          </Spin>
        </>
      )}
    </>
  );
};

export default GeneratedBankFilesTable;
