import { message } from 'antd';
import axios from 'axios';
import { cloneDeep } from 'lodash';

import { formatDataImportJSON } from '../../containers/settings/data-imports/dataImport.util';
import { PayrollItemTypeEnum } from '../../types/payroll.types';
import showApiError from '../../util/showApiError';
import {
  DataExtractorCSVEnum,
  DataExtractorTypesEnum,
  getAllPeople,
} from '../actions';
import { CustomPayslipField, PayrunI } from './payroll';

const SET_DATA_PAYLOAD = 'SET_DATA_PAYLOAD';
const LOADING_DATA_IMPORTS = 'LOADING_DATA_IMPORTS';
const SET_REDIRECT_TO = 'SET_REDIRECT_TO';
const SET_DATA_IMPORT_MESSAGE = 'SET_DATA_IMPORT_MESSAGE';

export interface DataImportUserI {
  id: number | string;
  address: string | null;
  fullName: string | null;
  lastName: string | null;
  firstName: string | null;
  nameWithInitials: string;
  joinedDate: string | null;
  resignedDate: string | null;
  preferredName?: string | null;
  employeeNumber: string | number;
  DOB?: string | number;
  epfNumber?: string | null;
  etfNumber?: string | null;
  taxNumber?: string | null;
  loginEmail: string | null;
  nicOrPassport: string | number;
  gender: string | number;
  personalMobile: string | number;
  bankAccountName: string | null;
  bankAccountNumber: string | number;
  bankBankCode: string | number;
  branchCode: string | number;
  occupationGrade: string | number;
  deduction?: string | number;
  netSalary?: string | number;
  costToCompany?: string | number;
  homeMobile: string | number;
  teams?: string | string[];
  leaveInformation?: { [key: string]: number };
  payslipFields?: CustomPayslipField[];
  currentJob?: CurrentJob;
  paySlipMail?: string | null;
  isSecondary: boolean;
  primarySalary: string | number;
  attendance: string | number;
  workedDays: number;
}

export interface CurrentJob {
  branchName: string;
  designation: string;
  employmentType: string;
  endDate?: string;
  isCurrentJob: boolean;
  startDate: string;
  positionLevel: string;
}
export interface DataImportPayItem {
  excelHeader: string;
  payTitle: string;
  type: PayrollItemTypeEnum | string;
  isTaxable: boolean;
  isEpf: boolean;
  amount: number | string;
  subType?: string;
}

export interface DataImportPayItemError {
  payTitle: string;
  message: string;
}

export interface DataImportPayItemValuesI {
  [key: string]: DataImportPayItem & {
    error?: string;
  };
}

export interface CsvDataI {
  extractedUsers: SubmitDataImportPayload[];
  payroll?: PayrunI;
}

export type SubmitDataImportPayload = Partial<DataImportUserI> & {
  payItems: DataImportPayItem[];
  payItemValues?: DataImportPayItemValuesI;
};
// export interface DataImportLeaveInformationI {
//   openLeaveBalance: string;
//   averageLeaves: string;
//   otHours: string;
//   otRate: string;
//   approvedLeaves: string;
//   noPayDays: string;
// }

export interface DataImportUserPayLoad {
  rowKey: string;
  isSelected: boolean;
  defaultSelected?: boolean;
  hasEmployeeErrors: boolean;
  empPayrollId?: number | string;
  hasAnyError: boolean;
  isNewInDraft: boolean;
  apitForFistTime: boolean;
  values: Partial<DataImportUserI> & {
    payItems: DataImportPayItem[];
    payItemValues?: DataImportPayItemValuesI;
    isEmployeePaidInCash: boolean;
  };
  employeeErrors: Partial<DataImportUserI & CurrentJob>;
  payItemWarnings: Partial<DataImportPayItemError>[];
  payItemErrors: Partial<DataImportPayItemError>[];
  updatedUserValues: Partial<DataImportUserI>;
  payrollUpdates: Partial<DataImportPayItem>[];
}

interface dataImportI {
  pathname: string;
  state: number;
}
interface InitialStateI {
  loading: boolean;
  dataImportPayload: DataImportUserPayLoad[];
  payItemColumns: string[];
  originalPayload: Partial<DataImportUserPayLoad>[];
  redirectTo: string | null;
  dataImportingErrorMessage: string | null;
  dataImport: dataImportI | null;
}

const initialState: InitialStateI = {
  loading: false,
  dataImportPayload: [],
  payItemColumns: [],
  originalPayload: [],
  redirectTo: null,
  dataImportingErrorMessage: null,
  dataImport: null,
};

export default function reducer(
  state: InitialStateI = initialState,
  action: any,
): InitialStateI {
  switch (action.type) {
    case SET_DATA_IMPORT_MESSAGE: {
      return { ...state, dataImportingErrorMessage: action.payload };
    }
    case LOADING_DATA_IMPORTS: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case SET_DATA_PAYLOAD: {
      return {
        ...state,
        dataImportPayload: action.payload.dataList,
        payItemColumns: action.payload.payItemColumns,
        originalPayload: action.payload.originalPayload,
      };
    }
    case SET_REDIRECT_TO: {
      return {
        ...state,
        redirectTo: action.payload,
      };
    }
    default:
      return state;
  }
}

export function uploadExcelSheet(payload: DataImportUserPayLoad[]) {
  return async (dispatch: any) => {
    try {
      if (payload) {
        const formattedPayload = formatDataImportJSON(cloneDeep(payload));
        dispatch({
          type: SET_DATA_PAYLOAD,
          payload: {
            dataList: formattedPayload.dataList,
            payItemColumns: formattedPayload.payItemColumns,
            originalPayload: payload,
          },
        });
      } else {
        dispatch({
          type: SET_DATA_PAYLOAD,
          payload: {
            dataList: [],
            payItemColumns: [],
            originalPayload: null,
          },
        });
      }
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: LOADING_DATA_IMPORTS, payload: false });
    }
  };
}
export const submitForProcess = (
  payload: CsvDataI,
  type: DataExtractorTypesEnum,
  csvType: DataExtractorCSVEnum,
) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOADING_DATA_IMPORTS, payload: true });
      if (!payload.extractedUsers.length) {
        message.error('No data to proceed');
      } else {
        const response = await axios.post(
          `/extract-data/resubmit?type=${type}&csvType=${csvType}`,
          payload,
        );

        if (response.data && response.data.id) {
          dispatch({
            type: SET_REDIRECT_TO,
            payload: `/Payroll-view/${response.data.id}?edit=yes`,
          });
        }
        if (csvType === 'EMPLOYEE_CSV') {
          dispatch({
            type: SET_REDIRECT_TO,
            payload: {
              pathname: `/contact`,
              state: payload.extractedUsers.length,
            },
          });
        }
        await dispatch(getAllPeople());
      }
    } catch (error: any) {
      showApiError(error);
    } finally {
      dispatch({ type: LOADING_DATA_IMPORTS, payload: false });
    }
  };
};

export const clearDataImportRedirect = () => {
  return (dispatch: any) => {
    dispatch(uploadExcelSheet([]));
    dispatch({ type: SET_DATA_IMPORT_MESSAGE, payload: null });
    dispatch({ type: SET_REDIRECT_TO, payload: null });
  };
};
