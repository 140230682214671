import { Col, Row, Spin } from 'antd';
import  { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { B } from '../../../../components';
import { DispatchType, RootState } from '../../../../store/reducer';
import { ReportLayout } from '../../shared/report-layout/ReportLayout';
import ReportSelector from '../../shared/ReportSelector';
import {
  getPayrollReport,
  ReportSummaryPayrollCalculationsI,
  ReportSummaryPayrollI,
} from '../../../../store/actions';
import {
  getSummaryDataFiltered,
  getMonthsBetweenDates,
} from '../../utils/payrollReport';
import { payrollReportFiltersI } from '../../../../types/report.types';
import styled from '@emotion/styled';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import ReportMenuSelector, { DropdownSection } from '../../shared/ReportMenuSelector';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const StyledRow = styled(Row)`
  padding: 16px 24px;
  width: 100%;
`;

const ContainerDiv = styled.div`
padding: 1px 24px;
width: 100%;
margin-top: 49px;
margin-bottom: 10px;
.ant-spin-container {
  position: relative;
  height: 60vh;
}
.ant-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;`;



// chart options
const options: _DeepPartialObject<any> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      display: true,
      title: {
        display: true,
        text: 'Amount',
        color: 'black',
        font: {
          family: 'Inter',
          size: 14,
          weight: 'bold',
          lineHeight: 1,
        },
        padding: { top: 0, bottom: 20 },
      },
    },
    x: {
      display: true,
      title: {
        display: true,
        text: 'Month',
        color: 'black',
        font: {
          family: 'Inter',
          size: 14,
          weight: 'bold',
          lineHeight: 1,
        },
        padding: { top: 20, bottom: 0 },
      },
    },
  },
  elements: {
    line: {
      tension: 0.2,
    },
  },
  interaction: {
    intersect: false,
    mode: 'index' as const,
  },
};

const SummaryReportPage: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const [filterSummaryReportData, setFilterSummaryReportData] =
    useState<payrollReportFiltersI>();

  // used for set filtered chart data
  const [filteredData, _setFilteredData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      fill: boolean;
      backgroundColor: string;
      borderColor: string;
      customKey: string;
    }[];
  } | null>(null);

  // result of api call loading
  const payrollReportLoading = useSelector(
    (state: RootState) => state.report.payrollReportLoading,
  );
  // result of api call data
  const payrollReport = useSelector(
    (state: RootState) => state.report.payrollReport,
  );

  // fires when new report api is called
  useEffect(() => {
    getChartData();
  }, [payrollReport, filterSummaryReportData]);

  // used to fire api call when date changes
  useEffect(() => {
    if (
      filterSummaryReportData?.dataRange?.startDate &&
      filterSummaryReportData.dataRange.endDate
    ) {
      const { startDate, endDate } = filterSummaryReportData.dataRange;
      dispatch(
        getPayrollReport({
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
        }),
      );
    }
  }, [filterSummaryReportData?.dataRange]);

  // used to manipulate data for the chart.js object
  const getChartData = () => {
    const selectedFilters: payrollReportFiltersI = {};

    if (filterSummaryReportData?.users?.length) {
      selectedFilters.users = filterSummaryReportData?.users;
    }

    if (filterSummaryReportData?.teams?.length) {
      selectedFilters.teams = filterSummaryReportData?.teams;
    }

    const chartData: ReportSummaryPayrollI[] = getSummaryDataFiltered(
      payrollReport,
      selectedFilters,
    );

    let dates: string[] = [];
    if (
      filterSummaryReportData?.dataRange?.startDate &&
      filterSummaryReportData.dataRange.endDate
    ) {
      const { startDate, endDate } = filterSummaryReportData.dataRange;
      dates = getMonthsBetweenDates(
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD'),
      );
    }

    // create default data array based on ^ dates ^ (xAxis labels)
    const initData: number[] = new Array(dates.length).fill(0);

    // init chart.js line chart data
    const data = {
      labels: dates,
      datasets: [
        {
          label: 'Total Salary',
          data: [...initData],
          fill: false,
          backgroundColor: ' rgba(0, 82, 234, 0.5)',
          borderColor: '#0052EA',
          customKey: 'grossSalary',
        },
        {
          label: 'Allowances',
          data: [...initData],
          fill: false,
          backgroundColor: ' rgba(22, 157, 0, 0.5)',
          borderColor: ' #169D00',
          customKey: 'totalAllowance',
        },
        {
          label: 'Deduction',
          data: [...initData],
          fill: false,
          backgroundColor: 'rgba(198, 40, 40, 0.5)',
          borderColor: '#C62828',
          customKey: 'grossDeduction',
        },
        {
          label: 'EPF (Employer)',
          data: [...initData],
          fill: false,
          backgroundColor: 'rgba(255, 154, 3, 0.5)',
          borderColor: ' #FF9A03',
          customKey: 'employerEPF',
        },
        {
          label: 'ETF (Employer)',
          data: [...initData],
          fill: false,
          backgroundColor: 'rgba(141, 0, 191, 0.5)',
          borderColor: '#8D00BF',
          customKey: 'employerETF',
        },
      ],
    };

    // return empty if no chart data provided
    if (chartData && chartData.length === 0) {
      data.datasets = [];
      _setFilteredData(data);
      return;
    }

    // set line chart based on payroll summary result
    data.datasets.map((line) => {
      chartData.forEach((payroll) => {
        // get index of the date selected in chart x axis
        const dateIndex: number = dates.indexOf(payroll.month);

        // set values to y axis based on each type that are going to view in chart
        for (const key in payroll.calculation) {
          if (Object.prototype.hasOwnProperty.call(payroll.calculation, key)) {
            if (key === line.customKey) {
              line.data[dateIndex] +=
                payroll.calculation[
                  line.customKey as keyof ReportSummaryPayrollCalculationsI
                ];
            }
          }
        }
      });
      return line;
    });

    // set manipulated chart.js line data to react state
    _setFilteredData(data);
  };

  return (
    <>
      <ReportLayout>
        <div>
          {' '}
          <StyledRow>
            <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
              <B type="b-large-semibold">Payroll Summary</B>
            </Col>

            <Col xs={24} sm={24} md={12} lg={0} xl={0} xxl={0}>
              <DropdownSection>
                  <ReportMenuSelector defaultIndex="1" />
              </DropdownSection>
            </Col>
            
          </StyledRow>
          <ReportSelector
            useDateRange={true}
            onChange={(values) => setFilterSummaryReportData(values)}
          />
        </div>
        <ContainerDiv>
          <Spin spinning={payrollReportLoading}>
            {filteredData && <Line data={filteredData} options={options} />}
          </Spin>
        </ContainerDiv>
      </ReportLayout>
    </>
  );
};

export default SummaryReportPage;
