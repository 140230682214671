import React, { FC, ReactNode } from 'react';
import { B, Div } from '../../../../components';
import { numberThousandSeparator } from '../../../../util/utils';
import theme from '../../../../theme';
import styled from '@emotion/styled';
interface SummaryHeaderProps {
  title: string;
  amountTitle?: string;
}

interface SummaryItemProps {
  typeName: string;
  payAmount: number;
}
interface SummaryContainerProps {
  children: ReactNode;
  headingTitle: string;
  amountTitle?: string;
}
interface SalaryRowProps {
  typeTitle: string;
  amount: number;
}

const Card = styled.div`
  padding: 10px 24px;
  background: ${theme.gray50};
  display: flex;
  border: 1px solid ${theme.gray300};
  border-top-width: 0px;
  border-bottom-width: 1px;
`;
const Summary = styled.div`
  padding: 10px 24px;
  display: flex;
  border: 1px solid ${theme.gray300};
  border-top-width: 0;
  :last-child {
    border-bottom-width: 0;
  }
`;
const Amount = styled.div`
  margin-left: auto;
  display: flex;
`;
const Container = styled.div`
  .salary-header:first-child {
    border-radius: 4px 4px 0px 0px;
    border-top-width: 1px;
  }
  .salary-row:last-child {
    border-radius: 0 0 4px 4px;
    margin-bottom: 24px;
    border-bottom-width: 1px;
  }
`;

const SummaryHeader: FC<SummaryHeaderProps> = ({ title, amountTitle }) => {
  return (
    <Card className="salary-header">
      <B type="b-default" color={theme.gray600}>
        {title}
      </B>

      <Div marginLeft="auto" display="flex">
        <B type="b-default" color={theme.gray600}>
          {amountTitle}
        </B>
      </Div>
    </Card>
  );
};

export const SummaryRow: FC<SalaryRowProps> = ({ typeTitle, amount }) => {
  return (
    <>
      <Summary className="salary-row">
        <Div display="flex" flexDirection="row">
          <B marginRight="10px" type="b-default" color={theme.black}>
            {typeTitle}
          </B>
        </Div>
        <Amount>
          <B type="b-default" color={theme.black}>
            {numberThousandSeparator(amount)}
          </B>
        </Amount>
      </Summary>
    </>
  );
};

export const SummaryItem: FC<SummaryItemProps> = ({ typeName, payAmount }) => {
  return <SummaryRow typeTitle={typeName} amount={payAmount} />;
};

export const SummaryContainer: FC<SummaryContainerProps> = ({
  children,
  headingTitle,
  amountTitle,
}) => {
  return (
    <Container>
      <SummaryHeader title={headingTitle} amountTitle={amountTitle} />
      {children}
    </Container>
  );
};
