import React, { FC, useEffect, useState } from 'react';
import { Col, DatePicker, message, Row } from 'antd';
import { B, PrimaryButton } from '../../../../components';
import { ReportLayout } from '../../shared/report-layout/ReportLayout';
import { useGetLeaveReport } from '../../../../api/reportHooks';
import moment, { Moment } from 'moment';
import { LeaveReportI } from '../../../../types/report.types';
import { generateLeaveDetailedExcel } from '../../utils/generateLeaveDetailedExcel';
import EmployeesSelector from '../../../../components/selector/EmployeesSelector';
import { getApiError } from '../../../../util/getApiError';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import theme from '../../../../theme';
import ReportMenuSelector, {
  DropdownSection,
} from '../../shared/ReportMenuSelector';
import TeamsSelector from '../../../../components/selector/TeamsSelector';

const { RangePicker } = DatePicker;

const LeaveReport = styled.div`
  padding: 16px 24px;
  width: 100%;
`;

const DateRangePicker = styled(RangePicker)`
  width: 318px;
`;
const LeaveBalanceReport: FC = () => {
  const [startDate, setStartDate] = useState<Moment | null>(
    moment().subtract(2, 'month').startOf('month'),
  );
  const [endDate, setEndDate] = useState<Moment | null>(moment().endOf('day'));
  const [employees, setEmployees] = useState<number[]>([]);
  const [teams, setTeams] = useState<string[]>([]);
  const [leaveDetail, setLeaveDetail] = useState<LeaveReportI[] | []>([]);
  const [isTeamsValid, setIsTeamsValid] = useState(true);

  const {
    isLoading: loadingLeaves,
    error: leavesErrors,
    data: leaveData = [],
    refetch: getLeave,
  } = useGetLeaveReport({
    startDate: startDate
      ? startDate.format('YYYY.MM.DD')
      : moment().subtract(2, 'month').startOf('month').format('YYYY.MM.DD'),
    endDate: endDate
      ? endDate.format('YYYY.MM.DD')
      : moment().endOf('day').format('YYYY.MM.DD'),
  });
  useEffect(() => {
    if (startDate && endDate) {
      getLeave();
    }
  }, [startDate, endDate]);
  useEffect(() => {
    if (leaveData.length > 0) {
      const employee = employees.map((item) => {
        return { id: item };
      });
      const data = leaveData.filter(({ userId: i }) =>
        employee.some(({ id: y }) => y === i),
      );
      setLeaveDetail(data);
    }
  }, [leaveData, employees]);

  const handleTeamsChange = (value: string[]) => {
    setTeams(value);
    setIsTeamsValid(value.length > 0);
  };

  return (
    <>
      {leavesErrors && message.error(getApiError(leavesErrors))}
      <ReportLayout>
        <LeaveReport>
          <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
            <B type="b-large-semibold" Pb="8px">
              Leave Report
            </B>
          </Col>

          <Col xs={12} sm={12} md={12} lg={0} xl={0} xxl={0}>
            <DropdownSection>
              <ReportMenuSelector defaultIndex="5" />
            </DropdownSection>
          </Col>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={14} lg={9} xl={9}>
              <B type="b-small" color={theme.gray600} mb="32px">
                Summary of the leaves taken, displays the leave balance, gross
                pay, as well as the total government remittances from the both
                the employee and employer.
              </B>
            </Col>
          </Row>

          <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={14} lg={9} xl={9}>
              <TeamsSelector
                onChange={handleTeamsChange}
                width={318}
                margin={16}
              />
              {!isTeamsValid && (
                <div style={{ color: theme.red500}}>
                  Please add at least one team
                </div>
              )}
            </Col>
          </Row>
          {teams.length > 0 && (
            <Row gutter={[12, 12]}>
              <Col xs={24} sm={24} md={14} lg={9} xl={9}>
                <EmployeesSelector
                  isActive={false}
                  onChange={(value) => setEmployees(value)}
                  width={318}
                  employees={employees}
                  teams={teams}
                />
              </Col>
            </Row>
          )}

          <br />

          <Row gutter={[22, 22]}>
            <Col xs={24} sm={24} md={14} lg={9} xl={9}>
              <DateRangePicker
                value={[dayjs(startDate?.toDate()), dayjs(endDate?.toDate())]}
                onChange={(e) => {
                  setStartDate(e?.[0] ? moment(e?.[0].toDate()) : null);
                  setEndDate(e?.[1] ? moment(e?.[1].toDate()) : null);
                }}
              />
            </Col>
          </Row>

          <PrimaryButton
            mt="22px"
            size="small"
            htmlType="submit"
            disabled={loadingLeaves}
            loading={loadingLeaves}
            onClick={() =>
              teams.length === 0
                ? message.info('Please add at least one team')
                : leaveData.length === 0 || employees.length === 0
                ? message.info('There are no leave details between these dates')
                : generateLeaveDetailedExcel(leaveDetail)
            }>
            Export
          </PrimaryButton>
        </LeaveReport>
      </ReportLayout>
    </>
  );
};

export default LeaveBalanceReport;
