import { WorkTimeUnitType } from '../types/organization.types';

export const Validation = {
  NicOrPassportPattern:
    /^([0-9]{9}[x|X|v|V]|[0-9]{12})$|[A-Z]{2}[0-9]{7}|[A-Z]{1}[0-9]{7}$/,
  MobileNumberPattern: /^\d{9,10}$/,
  AccountNumberPattern: /^\d{1,15}$/,
  NumberRegex: /^[0-9]+$/,
  AlphanumericPattern: /^[A-Za-z0-9-]+$/,
  ValidStringPattern: /^[a-zA-Z\s\-']+$/,
  SafeStringPattern: /^[a-zA-Z0-9\s\-_.]+$/,
  DayAndMonthPattern: /^(0[1-9]|1\d|2\d|3[01])\-(0[1-9]|1[0-2])$/,
  EmailPattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/,
  BankAccountNumberPattern: /^\d{1,15}$/,
  AmountPattern: /^\d+(\d{3})*(?:\.\d{1,2})?$/,
  FourDecimalPointAmountPattern: /^\d+(\d{3})*(?:\.\d{1,4})?$/,
  HalfDayPattern: /^(?:\d+(?:\.5)?|0(?:\.5)?)$/,
  DecimalNumberPattern: /^(?:\d+(?:\.\d+)?|\.\d+)$/,
  EpfEtfNumberPattern: /^(?:[0-9]+|N\/A)$/,
  InterestRatePattern: /^(?:\d{1,2}(\.\d{1,2})?|100(\.00)?)$/,
  NoOfDaysPerMonthPattern: /^([1-9]|0[1-9]|[1][0-9]|[2][0-8])$/, // 1 to 28
  NoOfDaysPerYearPattern:
    /^0*(?:[0-9]|[1-9][0-9]|[1-2][0-9]{2}|3[0-5][0-9]|36[0-5])$/, // 0 to 365
};

export const OtNoPayValidation = (
  dailyWorkingHours?: number,
  monthlyWorkingDays?: number,
  workTimeUnitType?: string,
) => {
  if (dailyWorkingHours && monthlyWorkingDays) {
    const validDailyWorkingHours = Number(dailyWorkingHours) - 1;
    const validMonthlyWorkingDays = Number(monthlyWorkingDays);
    const [firstDigit, secondDigit] =
      validMonthlyWorkingDays < 10
        ? ('0' + validMonthlyWorkingDays).slice(-2).toString().split('')
        : validMonthlyWorkingDays.toString().split('');

    let secondValidDigit;
    if (validMonthlyWorkingDays >= 10) {
      secondValidDigit = 9;
    } else {
      secondValidDigit = secondDigit;
    }

    let pattern;
    if (workTimeUnitType === WorkTimeUnitType.DAYS_HALF_DAYS) {
      pattern = new RegExp(
        `(^[0-${
          Number(firstDigit) - 1
        }][0-9]\\.([05])$)|(^[0-${secondValidDigit}]\\.([05])$)|(^[0-${
          Number(firstDigit) - 1
        }][0-9]$)|(^0\\.([05])$)|(^[0-${secondValidDigit}]$)|(^[0-${firstDigit}][0-${secondDigit}]$)|(${firstDigit}(?!${secondDigit}\\b)[0-${secondDigit}]\\.([05]))$`,
        'g',
      );
    } else if (workTimeUnitType === WorkTimeUnitType.DECIMAL) {
      pattern = new RegExp(
        `^(0\\.${secondValidDigit}(\\d{1,2})?$)|^([1-${
          Number(firstDigit) - 1
        }][0-9](\\.\\d{1,2})?)$|(${firstDigit}(?!${secondDigit}\\b)[0-${secondDigit}](\\.\\d{1,2})?)$|(^[0-${secondValidDigit}]$)|(^[${firstDigit}][${secondDigit}])$|(^[0-${secondValidDigit}](\\.\\d{1,2})?)$`,
        'g',
      );
    } else {
      pattern = new RegExp(
        `(^[0-${
          Number(firstDigit) - 1
        }][0-9]\\.[0-${validDailyWorkingHours}]$)|(^[0-${secondValidDigit}]\\.[0-${validDailyWorkingHours}]$)|(^[0-${
          Number(firstDigit) - 1
        }][0-9]$)|(^0\\.[0-${validDailyWorkingHours}]$)|(^[0-${secondValidDigit}]$)|(^[0-${firstDigit}][0-${secondDigit}]$)|(${firstDigit}(?!${secondDigit}\\b)[0-${secondDigit}](\\.[0-${validDailyWorkingHours}])$)`,
        'g',
      );
    }
    return pattern;
  }
};
