import React, { FC, useEffect, useState } from 'react';
import { Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  AlertMessage,
  B,
  RadioButtonComp,
  DatePickerComp,
} from '../../../../components';
import {
  getPayrollData,
  payrollSchedule,
  PublishPayrollI,
  sendPaySlips,
  SingleUserPayroll,
} from '../../../../store/actions';
import theme from '../../../../theme';
import { isSameOrBefore } from '../../../../util/utils';
import { useParams } from 'react-router';
import moment from 'moment';
import { ModalComponent } from '../../../../components/ModalComponent';
import { DispatchType, RootState } from '../../../../store/reducer';
import { BlackCalender } from '../../../../assets';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

type payrollParam = {
  payrollKey: string;
};

interface Props {
  visible: boolean;
  handleCancel: () => void;
  payrollData?: SingleUserPayroll;
  possibleScheduling: boolean;
}
const RowComp = styled(Row)`
  margin-top: 18px;
`;
const RadioButton = styled(RadioButtonComp)`
  margin: 5px 19px 16px 0px;
`;
export const ScheduleDateModal: FC<Props> = ({
  visible,
  handleCancel,
  payrollData,
  possibleScheduling,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const dispatch: DispatchType = useDispatch();
  const params = useParams<payrollParam>();
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [isScheduleChecked, setIsScheduleChecked] = useState(false);
  const [alreadySchedule, setAlreadySchedule] = useState(
    payrollData?.scheduleDate != null,
  );
  const [emailSendLoading, setEmailSendLoading] = useState(false);
  useEffect(() => {
    dispatch(getPayrollData(params.payrollKey));
  }, []);

  useEffect(() => {
    if (payrollData?.scheduleDate) {
      setIsScheduleChecked(true);
    }
  }, [payrollData]);

  const onFinish = async (values: PublishPayrollI) => {
    if (isScheduleChecked) {
      await dispatch(payrollSchedule(values, params.payrollKey));
      dispatch(getPayrollData(params.payrollKey));
      handleCancel();
    } else if (isEmailChecked) {
      setEmailSendLoading(true);
      dispatch(sendPaySlips(params.payrollKey));
      dispatch(getPayrollData(params.payrollKey));

      setTimeout(() => {
        setEmailSendLoading(false);
        handleCancel();
      }, 3000);
    } else {
      handleCancel();
    }
  };

  const payrollScheduleLoad = useSelector(
    (state: RootState) => state.payroll.payrollScheduleLoad,
  );

  return (
    <>
      <ModalComponent
        form={form}
        submitText={
          possibleScheduling && payrollData?.scheduleDate && !isEmailChecked
            ? 'Update'
            : 'Send'
        }
        loading={emailSendLoading || payrollScheduleLoad}
        visible={visible}
        onCancel={handleCancel}
        width={541}
        title={
          <div
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}>
            <B type="b-large-semibold" color={theme.black}>
              Send pay slips
            </B>
          </div>
        }>
        <Form
          initialValues={{
            ...payrollData,
            date: payrollData?.scheduleDate
              ? dayjs(payrollData?.scheduleDate)
              : '',
          }}
          form={form}
          onFinish={onFinish}
          validateTrigger="onBlur">
          <B type="b-default" marginBottom="26px">
            Payslips are ready and have been published. Email payslips to your
            all employees
          </B>
          <div>
            <RadioButtonComp
              checked={isEmailChecked}
              onChange={(e) => {
                {
                  setIsEmailChecked(e.target.checked);
                  setIsScheduleChecked(false);
                  setAlreadySchedule(false);
                }
              }}>
              Send now
            </RadioButtonComp>
            <RowComp>
              <RadioButton
                checked={isScheduleChecked}
                onChange={(e) => {
                  {
                    setIsScheduleChecked(e.target.checked);
                    setIsEmailChecked(false);
                    setAlreadySchedule(true);
                  }
                }}>
                Schedule
              </RadioButton>
              <Form.Item
                name="date"
                validateFirst={!isEmailChecked}
                rules={[
                  {
                    required: !isEmailChecked,
                    message: 'Please Enter Schedule Date',
                  },
                ]}>
                <DatePickerComp
                  size="small"
                  disabled={!isScheduleChecked || !alreadySchedule}
                  disabledDate={(d) => isSameOrBefore(d.toString())}
                  style={{ width: '100%', height: '32px' }}
                  suffixIcon={<img src={BlackCalender} />}
                />
              </Form.Item>{' '}
              {isScheduleChecked ? (
                <AlertMessage
                  title="The system will send the emails at 6.30 am"
                  type="notification"
                />
              ) : null}
            </RowComp>
          </div>
        </Form>
      </ModalComponent>
    </>
  );
};
