import React, { FC } from 'react';
import { PaySlipType } from '../salary.helper';
import { numberThousandSeparator } from '../../../util/utils';
import styled from '@emotion/styled';

const Td = styled.td`
  padding-left: 10px;
`;
const TableRow: FC<{ name: string; value: string | number }> = ({
  name,
  value,
}) => {
  return (
    <tr>
      <td>{name}</td>
      <Td align="right">
        {value ? numberThousandSeparator(Number(value)) : name ? '-' : ''}
      </Td>
    </tr>
  );
};

interface Props {
  paySlipResult: PaySlipType | null;
}
const SalaryResult: FC<Props> = ({ paySlipResult }) => {
  if (!paySlipResult) {
    return null;
  }
  return (
    <table>
      <thead>
        <tr>
          <th>Title</th>
          <th>Amount</th>
        </tr>
      </thead>

      <tbody>
        <TableRow name="Gross Salary" value={paySlipResult.grossSalary} />
        <TableRow name="Gross Deduction" value={paySlipResult.grossDeduction} />
        <TableRow name="" value="" />

        <TableRow
          name="Employee Total Tax (-)"
          value={paySlipResult.totalTax}
        />
        <TableRow name="Employee EPF (-)" value={paySlipResult.employeeEPF} />
        <TableRow name="" value="" />

        <TableRow
          name="Net Salary (Take Home)"
          value={paySlipResult.netSalary}
        />

        <TableRow name="" value="" />
        <TableRow name="" value="" />

        <TableRow name="Employeer EPF" value={paySlipResult.employeerEPF} />
        <TableRow name="Employeer ETF" value={paySlipResult.employeerETF} />
        <TableRow name="CTC (Cost to Company)" value={paySlipResult.netPay} />
      </tbody>
    </table>
  );
};

export default SalaryResult;
