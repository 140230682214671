import React, { FC } from 'react';

import { ColumnProps } from 'antd/lib/table';

import styled from '@emotion/styled';

import { EmptyTextContainer, TableComp } from '../../../../../components';
import { EmployeeI } from '../../../../../types/superAdmin.types';
import { isValidMoment } from '../../../../../util/isValidMoment';

interface Props {
  employees: EmployeeI[];
}
const Title = styled.p`
  margin-bottom: 0px;
`;

const LastColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

const EmployeesTable: FC<Props> = ({ employees }) => {
  const columns: ColumnProps<EmployeeI>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: 'Employee ID',
      dataIndex: 'employeeNumber',
      width: 150,
      render: (value) => <Title>{value ? value : '-'}</Title>,
    },
    {
      title: 'Email available',
      dataIndex: 'loginEmail',
      width: 150,
      render: (value: boolean) => <Title>{value ? 'Yes' : 'No'}</Title>,
    },
    {
      title: 'Login Allowed',
      dataIndex: 'isLoginAllowed',
      width: 150,
      render: (value: boolean) => <Title>{value ? 'Yes' : 'No'}</Title>,
    },
    {
      title: 'User Role',
      dataIndex: 'role',
      width: 180,
      render: (_value: string, data) => <Title>{data.role.role}</Title>,
    },
    {
      title: 'Is Archive',
      dataIndex: 'isArchived',
      width: 120,
      render: (value: boolean) => <Title>{value ? 'Yes' : 'No'}</Title>,
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      width: 150,
      render: (value: string) => (
        <LastColumn>
          <Title>{isValidMoment(value, '-', 'DD.MM.YYYY').toString()}</Title>
        </LastColumn>
      ),
    },
  ];

  const locale = {
    emptyText: (
      <EmptyTextContainer description="You dont have any leave requested yet" />
    ),
  };

  return (
    <TableComp
      size="middle"
      columns={columns}
      locale={locale}
      dataSource={employees}
      scroll={{
        x: 800,
        y: window.innerHeight - 400,
      }}
      pagination={false}
      withBorders={true}
    />
  );
};
export default EmployeesTable;
