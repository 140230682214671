import moment from 'moment';
import {
  PayrollReportI,
  ReportDetailedPayrollCalculationsI,
  ReportSummaryPayrollCalculationsI,
  ReportSummaryPayrollI,
  ReportUserI,
} from '../../../store/actions';
import { PayrollItemTypeEnum } from '../../../types/payroll.types';
import { PeopleI } from '../../../types/people.types';
import {
  NoTeamKeyWord,
  payrollReportFiltersI,
} from '../../../types/report.types';

/**
 * Get payroll report data filtered
 * @param data
 * @param filters
 * @returns
 * Use: getDataFiltered(data, { users?: number[], teams?: string[], payrollIds?: number[] });
 */
const getDataFiltered = (
  data: PayrollReportI[],
  filters?: payrollReportFiltersI,
): PayrollReportI[] => {
  if (!filters || Object.keys(filters).length === 0) {
    return data;
  }
  let dataSet: PayrollReportI[] = [];
  data.forEach((payroll) => {
    let users: ReportUserI[] = [];
    if (filters?.users || filters?.teams) {
      payroll.users.forEach((user) => {
        let getUser = false;
        // filter from selected users
        if (filters?.users && filters?.users?.length) {
          if (filters?.users?.includes(user.id)) getUser = true;
        }
        if (getUser) {
          users.push(user);
        }
      });

      // filter from selected teams
      users = users.filter((user) => {
        if (filters?.teams && filters?.teams?.length) {
          // set default to `No Teams` if user wasn't on a team
          const userTeams = user.teams ? user.teams : [NoTeamKeyWord];
          if (
            userTeams &&
            !filters?.teams?.some((i: string) => userTeams.includes(i))
          )
            return;
        }
        return user;
      });

      // remove payroll if there are not users involved
      if (users && users.length === 0) return null;
    }
    dataSet.push({ ...payroll, users });
  });

  // filter from selected payrolls
  if (filters?.payrollIds && filters?.payrollIds?.length) {
    dataSet = dataSet.filter((payroll) =>
      filters?.payrollIds?.includes(payroll.id),
    );
  }
  return dataSet;
};

const getSummaryDataFiltered = (
  data: PayrollReportI[],
  filters?: payrollReportFiltersI,
): ReportSummaryPayrollI[] => {
  // get filtered data prepare
  const dataSet: PayrollReportI[] = getDataFiltered(data, filters);
  // init new filtered array to set only necessary data to include
  const filtered: ReportSummaryPayrollI[] = [];
  if (dataSet && dataSet.length) {
    dataSet.forEach((payroll) => {
      // init summary calculation for a payroll summary
      const calculation: ReportSummaryPayrollCalculationsI = {
        employerEPF: 0,
        employerETF: 0,
        grossDeduction: 0,
        grossSalary: 0,
        totalAllowance: 0,
      };
      // sum values from all the payrolls selected by the filter
      if (payroll.users && payroll.users.length) {
        payroll.users.map((user) => {
          calculation.employerEPF += user.calculation.employerEPF;
          calculation.employerETF += user.calculation.employerETF;
          calculation.grossDeduction += user.calculation.grossDeduction;
          calculation.grossSalary += user.calculation.grossSalary;
          calculation.totalAllowance += user.calculation.totalAllowance;
        });
      }
      // push data in to new filtered array
      filtered.push({
        month: payroll.month,
        calculation,
      });
    });
  }

  return filtered;
};

const getDetailedDataFiltered = (
  data: PayrollReportI[],
  filters?: payrollReportFiltersI,
): ReportDetailedPayrollCalculationsI => {
  // get filtered data prepare
  const dataSet: PayrollReportI[] = getDataFiltered(data, filters);
  // init summary calculation for a payroll summary
  const calculation: ReportDetailedPayrollCalculationsI = {
    earnings: {},
    deductions: {},
    other: {
      employeeEPF: 0,
      employerEPF: 0,
      employerETF: 0,
      totalTax: 0,
    },
    total: {
      costToCompany: 0,
      netSalary: 0,
    },
  };
  if (dataSet && dataSet.length) {
    dataSet.forEach((payroll) => {
      // sum values from all the payrolls selected by the filter
      if (payroll.users && payroll.users.length) {
        payroll.users.map((user) => {
          if (
            user?.calculation?.payItems &&
            user?.calculation?.payItems?.length
          ) {
            user.calculation.payItems.forEach((item) => {
              if (
                item.type === PayrollItemTypeEnum.BASIC ||
                item.type === PayrollItemTypeEnum.FIXED_ALLOWANCE ||
                item.type === PayrollItemTypeEnum.VARIABLE_ALLOWANCE
              ) {
                if (!calculation.earnings[item.payTitle]) {
                  calculation.earnings[item.payTitle] = 0;
                }
                calculation.earnings[item.payTitle] += item.amount;
              }
              if (item.type === PayrollItemTypeEnum.DEDUCTION) {
                if (!calculation.deductions[item.payTitle]) {
                  calculation.deductions[item.payTitle] = 0;
                }
                calculation.deductions[item.payTitle] += item.amount;
              }
            });
          }
          calculation.other.employeeEPF += user.calculation.employeeEPF;
          calculation.other.employerEPF += user.calculation.employerEPF;
          calculation.other.employerETF += user.calculation.employerETF;
          calculation.other.totalTax += user.calculation.totalTax;
          calculation.total.costToCompany += user.calculation.costToCompany;
          calculation.total.netSalary += user.calculation.netSalary;
        });
      }
    });
  }
  // cleanup empty calculations
  for (const key in calculation) {
    if (Object.prototype.hasOwnProperty.call(calculation, key)) {
      const section = calculation[key];
      for (const index in section) {
        if (Object.prototype.hasOwnProperty.call(section, index)) {
          if (!section[index]) delete section[index];
        }
      }
    }
  }

  return calculation;
};

const getMonthsBetweenDates = (
  startDate: string,
  endDate: string,
): string[] => {
  const dateStart = moment(startDate);
  const dateEnd = moment(endDate);
  const timeValues = [];

  while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
    timeValues.push(dateStart.format('MMM (YYYY)'));
    dateStart.add(1, 'month');
  }

  return timeValues;
};
const getPayrollDetailedDataFiltered = (
  data?: PayrollReportI,
): ReportDetailedPayrollCalculationsI => {
  const calculation: ReportDetailedPayrollCalculationsI = {
    earnings: {},
    deductions: {},
    other: {
      employeeEPF: 0,
      employerEPF: 0,
      employerETF: 0,
      totalTax: 0,
    },
    total: {
      costToCompany: 0,
      netSalary: 0,
    },
  };
  if (data) {
    if (data.users && data.users.length) {
      data.users.map((user) => {
        if (
          user?.calculation?.payItems &&
          user?.calculation?.payItems?.length
        ) {
          user.calculation.payItems.forEach((item) => {
            if (
              item.type === PayrollItemTypeEnum.BASIC ||
              item.type === PayrollItemTypeEnum.FIXED_ALLOWANCE ||
              item.type === PayrollItemTypeEnum.VARIABLE_ALLOWANCE
            ) {
              if (!calculation.earnings[item.payTitle]) {
                calculation.earnings[item.payTitle] = 0;
              }
              calculation.earnings[item.payTitle] += item.amount;
            }
            if (item.type === PayrollItemTypeEnum.DEDUCTION) {
              if (!calculation.deductions[item.payTitle]) {
                calculation.deductions[item.payTitle] = 0;
              }
              calculation.deductions[item.payTitle] += item.amount;
            }
          });
        }
        calculation.other.employeeEPF += user.calculation.employeeEPF;
        calculation.other.employerEPF += user.calculation.employerEPF;
        calculation.other.employerETF += user.calculation.employerETF;
        calculation.other.totalTax += user.calculation.totalTax;
        calculation.total.costToCompany += user.calculation.costToCompany;
        calculation.total.netSalary += user.calculation.netSalary;
      });
    }
  }
  for (const key in calculation) {
    if (Object.prototype.hasOwnProperty.call(calculation, key)) {
      const section = calculation[key];
      for (const index in section) {
        if (Object.prototype.hasOwnProperty.call(section, index)) {
          if (!section[index]) delete section[index];
        }
      }
    }
  }

  return calculation;
};

const getSelectedEmployee = (
  data: PeopleI[],
  filteredEmployee: number[],
): PeopleI[] => {
  return data.filter(({ id: i }) => filteredEmployee.some((x) => x === i));
};

export {
  getDataFiltered,
  getSummaryDataFiltered,
  getDetailedDataFiltered,
  getMonthsBetweenDates,
  getPayrollDetailedDataFiltered,
  getSelectedEmployee,
};
