import { Form, Select } from 'antd';
import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { B, InputComp, SelectComp } from '../../../components';
import FormItem from 'antd/lib/form/FormItem';
import { ExpenseMeta, ExpenseMetaData, SuppliersList } from '../../../types';
import { ModalComponent } from '../../../components/ModalComponent';
import theme from '../../../theme';

const CustomSelectComp = styled(SelectComp)`
  .ant-select-selector {
    height: 32px !important;
    display: flex !important;
    align-items: center !important;
    input {
      height: -webkit-fill-available !important;
    }
  }
`;
const FormContainer = styled(Form)`
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: none;
  }
`;

export interface ExpenseMetaCreate {
  label: string;
  value?: string;
  parentId?: string;
}

export interface ExpenseMetaUpdate {
  id: string;
  label: string;
  subId?: string;
}

interface ModalProps {
  type: ExpenseMeta;
  visibility: boolean;
  subCategory?: boolean;
  updateData: SuppliersList | ExpenseMetaData | null;
  setVisibility: (data: boolean) => void;
  metaData: (SuppliersList | ExpenseMetaData)[];
  createAction: (data: ExpenseMetaCreate) => void;
  createLoading: boolean;
  updateAction: (data: ExpenseMetaUpdate) => void;
  updateLoading: boolean;
}

export const ManageExpenseMetaModal: FC<ModalProps> = ({
  type,
  visibility,
  subCategory = false,
  updateData,
  createLoading = false,
  setVisibility,
  metaData = [],
  createAction,
  updateAction,
  updateLoading = false,
}) => {
  const categorySet = [...metaData];
  const [form] = Form.useForm();

  // set domain type
  let domain = 'Category';
  if (type === ExpenseMeta.CATEGORIES) {
    domain = 'Category';
  }
  if (type === ExpenseMeta.MERCHANTS) {
    domain = 'Merchant';
  }

  useEffect(() => {
    visibility && form.resetFields();
  }, [visibility]);

  const handleCancel = () => {
    setVisibility(false);
  };

  const onFinish = (values: ExpenseMetaCreate) => {
    if (updateData) {
      updateAction(
        subCategory
          ? {
              id: String(updateData.parentRef),
              label: String(values.label).trim(),
              subId: String(updateData.value),
            }
          : {
              id: String(updateData.value),
              label: String(values.label).trim(),
            },
      );
    } else {
      createAction(values);
    }
  };

  return (
    <ModalComponent
      form={form}
      submitText={updateData ? 'Update' : 'Create'}
      loading={createLoading || updateLoading}
      disabled={createLoading || updateLoading}
      visible={visibility}
      width={540}
      title={
        <B type="b-large-semibold">
          {subCategory
            ? `${updateData ? 'Update' : 'Create New'} Sub-${domain}`
            : `${updateData ? 'Update' : 'Create New'} ${domain}`}
        </B>
      }
      onCancel={handleCancel}>
      <FormContainer
        layout="vertical"
        form={form}
        name={`ManageExpense${domain}`}
        onFinish={onFinish}
        initialValues={
          updateData
            ? subCategory
              ? {
                  label: updateData.label,
                  parentId: updateData.parentRef,
                }
              : {
                  label: updateData.label,
                }
            : {}
        }>
        <FormItem
          name="label"
          label={subCategory ? `Sub ${domain} Name` : `${domain} Name`}
          rules={[
            {
              required: true,
              message: `⚠ ${domain} Name Cannot be empty`,
            },
            {
              validator(_, value) {
                if (subCategory) {
                  const parentId = form.getFieldValue('parentId');
                  const main = categorySet.find((i) => i.value === parentId);
                  if (main && main.subs) {
                    const subs = main.subs || [];
                    const exist = subs.find(
                      (i: any) => i.label === value.trim(),
                    );
                    if (exist) {
                      return Promise.reject(
                        `⚠ Sorry, that ${domain.toLocaleLowerCase()} name already exists!`,
                      );
                    }
                  }
                } else {
                  const main = categorySet.find(
                    (i) => i.label === value.trim(),
                  );
                  if (main) {
                    return Promise.reject(
                      `⚠ Sorry, that ${domain.toLocaleLowerCase()} name already exists!`,
                    );
                  }
                }
                return Promise.resolve();
              },
            },
          ]}>
          <InputComp
            name="label"
            size="small"
            placeholder={`Enter a ${domain} Name`}
            infoText={
              type === ExpenseMeta.CATEGORIES
                ? subCategory
                  ? `Here, you are creating the sub expense category under a main expense category.`
                  : `Here, you are creating the main expense category. Later, multiple sub-categories can be created under this.`
                : type === ExpenseMeta.MERCHANTS
                ? 'Here, the merchant you create will appear on the reimbursement list.'
                : ''
            }
          />
        </FormItem>
        {!updateData && subCategory && (
          <FormItem
            name="parentId"
            label="Main Category"
            rules={[
              {
                required: true,
                message:
                  '⚠ Please select main category to add you sub category',
              },
            ]}>
            <CustomSelectComp placeholder="Select Main Category Name">
              {categorySet.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </CustomSelectComp>
          </FormItem>
        )}
      </FormContainer>
    </ModalComponent>
  );
};
