import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import {
  GetLeaveLogsParams,
  LeaveBalancesI,
  OrganizationLeaveI,
  RequestLeaveI,
  ReviewI,
} from '../types';
import { LogTrailI } from '../types/logTrail.tyoes';
import { message } from 'antd';
import { getApiError } from '../util/getApiError';

export const useRequestMyLeave = () => {
  return useMutation<string, AxiosError, RequestLeaveI>(
    async (data: RequestLeaveI) => {
      const response: AxiosResponse<string> = await axios.post(
        '/Leaves/me',
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Leave added successfully');
      },
    },
  );
};

export const useRequestLeaveAsAdmin = () => {
  return useMutation<string, AxiosError, RequestLeaveI>(
    async (data: RequestLeaveI) => {
      const response: AxiosResponse<string> = await axios.post(
        '/Leaves/Admin',
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Leave added successfully');
      },
    },
  );
};

export const useGetMyLeave = () => {
  return useQuery<OrganizationLeaveI, AxiosError>(
    'my-leaves',
    async () => {
      const response: AxiosResponse<OrganizationLeaveI> = await axios.get(
        '/Leaves/overview/me',
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useGetAllLeaveBalances = () => {
  return useQuery<LeaveBalancesI[], AxiosError>(
    'all-leaves-balance',
    async () => {
      const response: AxiosResponse<LeaveBalancesI[]> = await axios.get(
        '/Leaves/leave-balances',
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useEditReview = () => {
  return useMutation<string, AxiosError, ReviewI>(
    async (data: ReviewI) => {
      const response: AxiosResponse<string> = await axios.put(
        '/Leaves/review',
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useGetLeaveLogs = () => {
  return useMutation<LogTrailI, AxiosError, GetLeaveLogsParams>(
    async (params) => {
      const response: AxiosResponse<LogTrailI> = await axios.get(
        `Leaves/leave-logs`,
        {
          params,
        },
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};
