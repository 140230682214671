import React, { FC, useEffect, useState } from 'react';
import { Form, message } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { pdfjs } from 'react-pdf';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import PagesDisplayCard from '../contract-edit/PagesDisplayCard';
import {
  B,
  ConfirmationModal,
  EmptyPage,
  FooterComponent,
  PrimaryButton,
  SecondaryButton,
} from '../../../components';
import HumanisedLogo from '../../../assets/logo-large.svg';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType, RootState } from '../../../store/reducer';
import { useParams } from 'react-router';
import {
  AssignedPlaceholderDataI,
  ContractStatusEnum,
  PlaceholderTypeEnum,
  SaveContractI,
  SaveNoneExistingSignatureI,
  UserType,
} from '../../../types/contract.types';
import {
  addNoneExistingSignaturesToPdf,
  getNoneExistingContract,
  // saveNoneExistingSignature,
  saveNoneExistingContract,
} from '../../../store/actions';
import AddMySignatureModal from './AddMySignatureModal';
import ContractViewer from '../shared/ContractViewer';
import {
  ContentEditorContainer,
  ContractContainer,
  ContractLayout,
  ContractNonUserHeader,
  DraggableLayout,
} from '../shared/ContractLayout';
import { ContractTopBar } from '../shared/ContractTopBar';
import { ZoomProvider } from '../shared/ZoomContext';
import { downloadNonUserHandler } from '../../../util/downloadPdf.util';
import { useNavigate } from 'react-router-dom';
import TermsConditionsHeader from './TermModal';
import styled from '@emotion/styled';

type ParamsI = {
  contractId: string;
  token: string;
};

interface TokenI {
  contractId: number;
  organizationId: number;
  expirationDate: string;
  sub?: string;
}
export interface PlaceholderBoxProps {
  left: number;
  top: number;
  width: number;
  height: number;
}

const EmptyContract: FC = () => (
  <EmptyPage
    content="Sorry, the contract cannot be found."
    title="something went wrong !"
  />
);

const ExpiredContract: FC = () => (
  <EmptyPage content="Sorry, please contact sender." title="Link expired !" />
);

const ViewNonUserContract: FC = () => {
  const params = useParams<ParamsI>();
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();
  const [alreadySigned, setAlreadySigned] = useState(false);

  // get params
  const tokenExist = Boolean(params?.token);

  // decode token
  const tokenDecoded: TokenI = jwt_decode(params?.token);

  const [savedStatus, setSavedStatus] = useState<boolean>(false);
  //initially check whether there is state stored in local storage and updates state accordingly
  const [termsAndConditionsVisible, setTermsAndConditionsVisible] = useState(
    () => {
      const savedState = sessionStorage.getItem('termsAndConditionsVisible');
      return savedState !== null ? JSON.parse(savedState) : true;
    },
  );

  useEffect(() => {
    sessionStorage.setItem(
      'termsAndConditionsVisible',
      JSON.stringify(termsAndConditionsVisible),
    );

    if (termsAndConditionsVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [termsAndConditionsVisible]);

  const handleClose = () => {
    setTermsAndConditionsVisible(false);
  };
  const [noneExistingSignature, setNoneExistingSignature] =
    useState<SaveNoneExistingSignatureI | null>(null);
  const [selectedImage, setSelectedImage] = useState<any | null>(null);
  const [selectedPlaceholder, setSelectedPlaceholder] =
    useState<AssignedPlaceholderDataI | null>(null);

  const assignableUsers = useSelector(
    (state: RootState) => state.contract.assignableUsers,
  );
  const contract = useSelector((state: RootState) => state.contract.contract);
  const receiverId = useSelector(
    (state: RootState) => state.contract.receiverId,
  );
  const nonExistingContracts = useSelector(
    (state: RootState) => state.contract.nonExistingContracts,
  );
  const noneExistingContractsLoading = useSelector(
    (state: RootState) => state.contract.noneExistingContractsLoading,
  );
  const loadingSaveContract = useSelector(
    (state: RootState) => state.contract.loadingSaveContract,
  );
  const loadingSavePDFContract = useSelector(
    (state: RootState) => state.contract.loadingSavePDFContract,
  );

  // map placeholders

  const placeHolderIds: Array<string> = nonExistingContracts
    ? nonExistingContracts?.placeHolders.map((placeHolder) => {
        return placeHolder.id;
      })
    : [];

  // contract status based on the current user
  const contractStatus = contract?.contractStatus.find(
    (item) => item.userType === UserType.ASSIGNEE,
  );
  useEffect(() => {
    const firstSignatory = contract?.contractStatus.find(
      (el) =>
        el.firstSignatoryId === tokenDecoded?.sub ||
        el.secondSignatoryId === tokenDecoded?.sub,
    );
    setAlreadySigned(!!firstSignatory?.isSigned);
  }, [contract]);

  const initContractPage = async () => {
    await Promise.all([dispatch(getNoneExistingContract(params.token))]);
  };

  useEffect(() => {
    initContractPage();
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; //or pdfjsWorker
  }, []);

  const saveContent = async (showMessage = true) => {
    try {
      const formData: SaveContractI = {};
      if (noneExistingSignature) {
        formData.signature = noneExistingSignature;
      }

      if (nonExistingContracts?.placeHolders) {
        const data = nonExistingContracts.placeHolders
          .filter((d) => d.type !== PlaceholderTypeEnum.signature)
          .map((d) => {
            return {
              placeHolderId: [d.id],
              value: d.value,
              type: d.type,
            };
          });
        formData.placeHolders = data.length ? data : [];
        if (formData.signature) {
          formData.placeHolders = [
            ...formData.placeHolders,
            ...formData.signature.placeHolders,
          ];
        }

        let hasError = false;
        nonExistingContracts?.placeHolders.forEach((placeholder) => {
          if (placeholder.type == PlaceholderTypeEnum.signature) {
            if (!noneExistingSignature) {
              hasError = true;
            }
          }
          if (
            !placeholder.value &&
            placeholder.type !== PlaceholderTypeEnum.signature
          ) {
            hasError = true;
          }
        });

        if (hasError) {
          message.error(
            'Please Fill all the required information to save the contract',
          );
          return;
        }
      }

      if (
        Object.keys(formData).length === 0 &&
        formData.constructor === Object
      ) {
        throw 'Empty content.';
      }

      await dispatch(saveNoneExistingContract(params.token, formData));

      showMessage && message.success('Successfully Updated');
    } catch (error: any) {
      console.error(error);
      message.error('Something went wrong...');
      throw error;
    }
  };

  const downloadContract = () => {
    if (params.token) {
      downloadNonUserHandler(params.token);
    }
  };

  // check if link expired
  if (tokenExist && moment().isAfter(tokenDecoded.expirationDate)) {
    return <ExpiredContract />;
  }

  // if contact not found in public state
  if (tokenExist && !nonExistingContracts && !noneExistingContractsLoading) {
    return <EmptyContract />;
  }

  const StyledFlex = styled('div')`
    height: calc(100vh - 200px);
    width: 100%;
    position: absolute !important;
    top: 352px;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 200;
  `;

  return (
    <div>
      <ZoomProvider>
        <ContractNonUserHeader>
          <img src={HumanisedLogo} />
        </ContractNonUserHeader>

        <TermsConditionsHeader
          contract={contract}
          visible={termsAndConditionsVisible}
          handleClose={handleClose}
          receiverId={receiverId}
          assignableUsers={assignableUsers}
        />
        <Form>
          {/* @ts-ignore */}
          <DndProvider backend={HTML5Backend}>
            <ContractTopBar
              contract={contract}
              downloadPDF={() => downloadContract()}
            />
            <ContractLayout fullScreen={true}>
              <DraggableLayout>
                <ContractContainer>
                  <ContractViewer
                    image={selectedImage}
                    isEditable={false}
                    contract={contract}
                    selectedPlaceholder={selectedPlaceholder}
                    placeholders={
                      alreadySigned
                        ? []
                        : nonExistingContracts?.placeHolders || []
                    }
                    documentLink={
                      nonExistingContracts
                        ? nonExistingContracts.documentLink
                        : ''
                    }
                    latestSignature={null}
                    setSelectedPlaceholder={(data) => {
                      setSelectedPlaceholder(data);
                    }}
                  />
                </ContractContainer>
                <ContentEditorContainer>
                  <PagesDisplayCard
                    assignedUsers={assignableUsers}
                    placeholderDetails={
                      nonExistingContracts
                        ? nonExistingContracts.placeHolders
                        : []
                    }
                    url={
                      nonExistingContracts
                        ? nonExistingContracts.documentLink
                        : ''
                    }
                  />
                </ContentEditorContainer>
              </DraggableLayout>
            </ContractLayout>
          </DndProvider>

          <FooterComponent
            leftChildren={null}
            rightChildren={
              contract?.status === ContractStatusEnum.COMPLETED ||
              contractStatus?.isSigned ||
              alreadySigned ? null : (
                <>
                  <SecondaryButton
                    loading={loadingSaveContract}
                    disabled={loadingSaveContract || loadingSavePDFContract}
                    htmlType="button"
                    marginRight="16px"
                    onClick={() => {
                      setSavedStatus(true);
                      saveContent();
                    }}>
                    Save
                  </SecondaryButton>

                  <ConfirmationModal
                    title={<B type="b-large-semibold">Confirmation Alert</B>}
                    content={
                      <B type="b-large">Are you sure you want to continue?</B>
                    }
                    okText="Save and Continue"
                    cancelText="No"
                    onConfirm={async () => {
                      try {
                        !savedStatus && (await saveContent(false));
                        // TODO: not nice that history passed to redux function
                        await dispatch(
                          addNoneExistingSignaturesToPdf(
                            params.token,
                            navigate,
                          ),
                        );
                      } catch (error: any) {
                        console.error(error);
                        message.error('Something went wrong...');
                      }
                    }}
                    trigger={
                      <PrimaryButton
                        loading={loadingSavePDFContract}
                        disabled={loadingSavePDFContract}>
                        Confirm
                      </PrimaryButton>
                    }
                  />
                </>
              )
            }
            fullScreen={true}
          />

          {selectedPlaceholder?.type == PlaceholderTypeEnum.signature ? (
            <AddMySignatureModal
              nonExistUser={true}
              placeHolderIds={placeHolderIds}
              oldMySignature={null}
              visible={true}
              handleCancel={() => {
                setSelectedPlaceholder(null);
              }}
              onSubmit={(fileData, value, image) => {
                const values = {
                  token: params.token,
                  file: fileData,
                  placeHolders: value,
                };

                setNoneExistingSignature(values);
                setSelectedImage(image);
                setSelectedPlaceholder(null);
              }}
            />
          ) : null}
        </Form>
      </ZoomProvider>
      {termsAndConditionsVisible && <StyledFlex />}
    </div>
  );
};
export default ViewNonUserContract;
