import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import {
  ExpenseCategoryDelete,
  ExpenseSupplierDelete,
} from '../containers/expenses/shared/CollapsibleList';
import {
  ExpenseMetaCreate,
  ExpenseMetaUpdate,
} from '../containers/expenses/shared/ManageExpenseMetaModal';
import {
  ExpenseGenerateBulkI,
  ExpenseReportI,
  IBulkFilePayload,
  IExpense,
  IntegrationMetaList,
  UpdateExpenseI,
  UpdateStatusExpenseI,
} from '../types';
import { DataRangeI, PaginationI } from '../types/report.types';

export const useGetExpensesOverview = () => {
  return useQuery<ExpenseReportI[], AxiosError>(
    'expense-overview',
    async () => {
      const response: AxiosResponse<ExpenseReportI[]> = await axios.get(
        '/expense/overview',
      );
      return response.data;
    },
  );
};

export const useGetExpensesList = (dateRange: DataRangeI | null) => {
  return useQuery<IExpense[], AxiosError>('expense-list', async () => {
    const response: AxiosResponse<IExpense[]> = await axios.get(
      '/expense/list',
      {
        params: {
          startDate: dateRange?.startDate?.format('YYYY-MM-DD'),
          endDate: dateRange?.endDate?.format('YYYY-MM-DD'),
        },
      },
    );
    return response.data;
  });
};

export const useGetGeneratedBankFileList = (pagination: PaginationI) => {
  return useQuery<IBulkFilePayload, AxiosError>(
    'bulk-file-details',
    async () => {
      const response: AxiosResponse<IBulkFilePayload> = await axios.get(
        '/expense/bulk-file-details',
        {
          params: {
            page: pagination.page.toString(),
            limit: pagination.limit.toString(),
          },
        },
      );
      return response.data;
    },
  );
};

export const useGetExpense = () => {
  return useMutation<IExpense, AxiosError, number>(async (id) => {
    const response: AxiosResponse<IExpense> = await axios.get(`/expense/${id}`);
    return response.data;
  });
};

export const useCreateExpense = () => {
  return useMutation<string, AxiosError, FormData>(async (data: FormData) => {
    const response: AxiosResponse<string> = await axios.post('/expense', data);
    return response.data;
  });
};

export const useUpdateExpense = () => {
  return useMutation<string, AxiosError, UpdateExpenseI>(
    async ({ id, data }) => {
      const response: AxiosResponse<string> = await axios.put(
        `/expense/${id}`,
        data,
      );
      return response.data;
    },
  );
};

export const useUpdateStatusExpense = () => {
  return useMutation<string, AxiosError, UpdateStatusExpenseI>(
    async ({ id, status }) => {
      const response: AxiosResponse<string> = await axios.put(
        `/expense/${id}/update-status`,
        {
          status,
        },
      );
      return response.data;
    },
  );
};

export const useDeleteExpense = () => {
  return useMutation<string, AxiosError, number>(async (id) => {
    const response: AxiosResponse<string> = await axios.delete(
      `/expense/${id}`,
    );
    return response.data;
  });
};

export const useGenerateBulkExpense = () => {
  return useMutation<string, AxiosError, ExpenseGenerateBulkI>(async (data) => {
    const response: AxiosResponse<string> = await axios.post(
      `/expense/generate-bulk-file`,
      data,
    );
    return response.data;
  });
};

/**
 * Expense Categories
 */
export const useGetExpenseCategoriesList = () => {
  return useQuery<IntegrationMetaList, AxiosError>(
    'expense-categories-list',
    async () => {
      const response: AxiosResponse<IntegrationMetaList> = await axios.get(
        '/expense/categories/list',
      );
      return response.data;
    },
  );
};

export const useCreateExpenseCategory = () => {
  return useMutation<string, AxiosError, ExpenseMetaCreate>(
    async (data: ExpenseMetaCreate) => {
      const response: AxiosResponse<string> = await axios.post(
        '/expense/categories',
        data,
      );
      return response.data;
    },
  );
};

interface CategoryManage {
  subId?: string;
  label?: string;
}

export const useUpdateExpenseCategory = () => {
  return useMutation<string, AxiosError, ExpenseMetaUpdate>(
    async (category: ExpenseMetaUpdate) => {
      const data: CategoryManage = {
        label: category.label,
      };
      if (category.subId) {
        data.subId = category.subId;
      }
      const response: AxiosResponse<string> = await axios.put(
        `/expense/categories/${category.id}`,
        data,
      );
      return response.data;
    },
  );
};

export const useDeleteExpenseCategory = () => {
  return useMutation<string, AxiosError, ExpenseCategoryDelete>(
    async (category: ExpenseCategoryDelete) => {
      const data: CategoryManage = {};
      if (category.subId) {
        data.subId = category.subId;
      }
      const response: AxiosResponse<string> = await axios.delete(
        `/expense/categories/${category.id}`,
        { data },
      );
      return response.data;
    },
  );
};

/**
 * Expense Suppliers
 */
export const useGetExpenseSuppliersList = () => {
  return useQuery<IntegrationMetaList, AxiosError>(
    'expense-suppliers-list',
    async () => {
      const response: AxiosResponse<IntegrationMetaList> = await axios.get(
        '/expense/suppliers/list',
      );
      return response.data;
    },
  );
};

export const useCreateExpenseSupplier = () => {
  return useMutation<ExpenseMetaCreate, AxiosError, ExpenseMetaCreate>(
    async (data: ExpenseMetaCreate) => {
      const response: AxiosResponse<ExpenseMetaCreate> = await axios.post(
        '/expense/suppliers',
        data,
      );
      return response.data;
    },
  );
};

export const useUpdateExpenseSupplier = () => {
  return useMutation<string, AxiosError, ExpenseMetaUpdate>(
    async (supplier: ExpenseMetaUpdate) => {
      const data: CategoryManage = {
        label: supplier.label,
      };
      const response: AxiosResponse<string> = await axios.put(
        `/expense/suppliers/${supplier.id}`,
        data,
      );
      return response.data;
    },
  );
};

export const useDeleteExpenseSupplier = () => {
  return useMutation<string, AxiosError, ExpenseSupplierDelete>(
    async (supplier: ExpenseSupplierDelete) => {
      const response: AxiosResponse<string> = await axios.delete(
        `/expense/suppliers/${supplier.id}`,
      );
      return response.data;
    },
  );
};
