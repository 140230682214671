import getCamelCase from '../../../../util/getCamelCase';
import { ColumnMappingI, getColumnConfig } from '../util/openPayrollValidator';
import _ from 'lodash';
export interface KeyValueI {
  [key: string]: string;
}

export interface MappedDataI {
  name?: string;
  csvHeader: string;
  dataIndex: string;
  width: number;
  isEditable: boolean;
  isUnassigned: boolean;
  type?: 'info' | 'deduction' | 'addition';
  rows: string[];
  isAutoMapped: boolean;
  maxLength: number;
  bankColumn: boolean;
}

export class CSVMapper {
  private csvRows: Array<string[]>;
  private csvHeaders: string[];
  private mapDataColumns: MappedDataI[];

  constructor(csvHeaders: string[], csvRows: Array<string[]>) {
    this.csvRows = csvRows;
    this.csvHeaders = csvHeaders;
    this.mapDataColumns = this.autoMapColumns();
  }

  private getCSVColumnDataByIndex(index: number) {
    const results: string[] = [];
    this.csvRows.forEach((csvRow) => {
      results.push(csvRow[index]);
    });

    return results;
  }

  private autoMapColumns() {
    const results: MappedDataI[] = [];
    this.csvHeaders.map((csvHeader, i) => {
      const columnConfig = getColumnConfig(csvHeader);
      const result: MappedDataI = {
        name: columnConfig?.name,
        csvHeader,
        dataIndex: columnConfig?.dataIndex || `__${getCamelCase(csvHeader)}__`,
        type: columnConfig?.type,
        width: 300,
        isEditable: true,
        isUnassigned: columnConfig ? false : true,
        rows: this.getCSVColumnDataByIndex(i),
        isAutoMapped: true,
        maxLength: columnConfig?.maxLength || 1,
        bankColumn: columnConfig?.bankColumn || false,
      };
      results.push(result);
    });
    return results;
  }

  private getCSVIndex(dataIndex: string) {
    for (let i = 0; i < this.mapDataColumns.length; i++) {
      if (this.mapDataColumns[i].dataIndex === dataIndex) {
        return i;
      }
    }
    return -1;
  }

  getCsvHeaders() {
    return this.csvHeaders;
  }

  getCsvRows() {
    return this.csvRows;
  }

  getMappedData(): MappedDataI[] {
    return this.mapDataColumns;
  }

  getMappedDataByDataIndex(dataIndex: string): MappedDataI | null {
    for (let i = 0; i < this.mapDataColumns.length; i++) {
      const mapDataColumn = this.mapDataColumns[i];
      if (mapDataColumn.dataIndex === dataIndex) {
        return mapDataColumn;
      }
    }

    return null;
  }

  getTableDataSource() {
    const results: KeyValueI[] = [];
    this.csvRows.map((row) => {
      const result: KeyValueI = {};
      row.map((value, index) => {
        const mappedColumn = this.mapDataColumns[index];
        if (mappedColumn) {
          result[mappedColumn.dataIndex] = value;
        }
      });
      results.push(result);
    });
    return results;
  }

  updateValue(dataIndex: string, rowIndex: number, inputValue: string) {
    const rowCellIndex = this.getCSVIndex(dataIndex);
    if (rowCellIndex > -1) {
      this.csvRows[rowIndex][rowCellIndex] = inputValue;
      this.mapDataColumns[rowCellIndex].rows = this.getCSVColumnDataByIndex(
        rowCellIndex,
      );
    }
  }

  updateColumn(columnIndex: number, newConfig: ColumnMappingI | null) {
    let isUnassigned = false;
    let name = undefined;
    let type: 'info' | 'deduction' | 'addition' | undefined;
    let dataIndex = `__${getCamelCase(
      this.mapDataColumns[columnIndex].csvHeader,
    )}__`;
    if (newConfig) {
      isUnassigned = false;
      name = newConfig.name;
      dataIndex = newConfig.dataIndex;
      type = newConfig.type;
    }

    this.mapDataColumns[columnIndex] = {
      ...this.mapDataColumns[columnIndex],
      isUnassigned: isUnassigned,
      dataIndex,
      type,
      name: name,
      isAutoMapped: false,
    };
  }
}
