import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import { DeleteModal, NameTag } from '../';
import { payrollParam } from '../../types/payslipPageTypes';
import { SalaryItemsByTypeI } from '../../containers/payroll/pages/payslip/utils/getSalaryItemsByType';
import theme from '../../theme';
import {
  PayrollItemI,
  PayrunType,
  SalaryInformationView,
} from '../../types/payroll.types';
import { numberThousandSeparator } from '../../util/utils';
import { B } from '../B';
import { Div } from '../Div';
import { useOrganizationData } from '../../custom-hooks/organizationHooks';
import { Dropdown, Menu } from 'antd';
import { MoreSVG, PlusBlueSVG } from '../../assets';

interface SalaryRowProps {
  payTitle: string;
  amount: number;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  showMenu: boolean;
  status?: 'DRAFTED' | 'COMPLETED';
  isEPF: boolean;
  isTax: boolean;
  isSystemCalculated?: boolean;
  isFromTimeAttendance?: boolean;
  nonEditable?: boolean;
  payrunType: string | null;
}
const SalaryRowComp = styled.div`
  padding: 8px 0px;
  display: flex;
  border-bottom: 1px solid ${theme.gray300};
  border-top-width: 0;
  :last-child {
    border-bottom: none;
  }
`;

const PayTitle = styled.div`
  display: flex;
  flex-direction: row;
`;

const AmountCol = styled.div`
  margin-left: auto;
  display: flex;
`;
const Image = styled.img`
  cursor: pointer;
  margin-left: 10px;
  margin-right: -3px;
`;
const PlusImage = styled.img`
  cursor: pointer;
  .ant-image-img {
    padding-right: 3px;
  }
`;
const SalaryHeaderComp = styled.div`
  padding: 4px 0px;
  display: flex;
  border-bottom: 1px solid ${theme.gray300};
  border-top-width: 0px;
`;
const SalaryContainerComp = styled.div`
  .salaryHeader:last-child {
    border-radius: 4px;
  }
  .salary-row:last-child {
    margin-bottom: 20px;
  }
`;

const AmountText = styled.div<{ showMenu: boolean }>`
  padding-right: ${(prop) => (prop.showMenu ? '6px' : '32px')};
`;
export const SalaryRow: FC<SalaryRowProps> = ({
  payTitle,
  amount,
  onClickEdit,
  onClickDelete,
  showMenu,
  status,
  isEPF,
  isTax,
  isFromTimeAttendance,
  isSystemCalculated,
  nonEditable,
  payrunType,
}) => {
  const params = useParams<payrollParam>();

  const organizationData = useOrganizationData();

  return (
    <>
      <SalaryRowComp className="salary-row">
        <PayTitle>
          <B pr="20px" type="b-small" color={theme.black}>
            {payTitle}
            {payrunType === PayrunType.MID_CYCLE_PAYRUN && ' (Paid)'}
          </B>
          {isEPF ? (
            <NameTag
              textColor={theme.orange400}
              withBorders={false}
              color={theme.warn}>
              EPF/ETF
            </NameTag>
          ) : null}
          {isTax ? (
            <NameTag
              textColor={theme.orange400}
              withBorders={false}
              color={theme.warn}>
              APIT
            </NameTag>
          ) : null}
          {isFromTimeAttendance ? (
            <NameTag
              textColor={theme.orange400}
              withBorders={false}
              color={theme.warn}>
              SYSTEM GENERATED
            </NameTag>
          ) : null}
        </PayTitle>
        <AmountCol>
          <AmountText showMenu={showMenu}>
            <B
              type="b-small"
              color={theme.black}
              pr={
                (isSystemCalculated &&
                  status === 'DRAFTED' &&
                  params.salaryInformationViewType ===
                    SalaryInformationView.ALL_EMPLOYEE) ||
                (status === 'DRAFTED' &&
                  params.salaryInformationViewType ===
                    SalaryInformationView.ALL_EMPLOYEE &&
                  payrunType === PayrunType.MID_CYCLE_PAYRUN)
                  ? '27px'
                  : '0px'
              }>
              {numberThousandSeparator(
                amount,
                organizationData?.isFourDecimalPoint,
              )}
            </B>
          </AmountText>
          {status !== 'COMPLETED' &&
          params.salaryInformationViewType !==
            SalaryInformationView.CONFIRM_EMPLOYEE &&
          !isFromTimeAttendance ? (
            <>
              {showMenu &&
                (onClickEdit || onClickDelete) &&
                !isSystemCalculated &&
                !(payrunType === PayrunType.MID_CYCLE_PAYRUN) && (
                  <Dropdown
                    overlay={
                      <Menu>
                        {!nonEditable && onClickEdit && (
                          <Menu.Item key="edit" onClick={() => onClickEdit()}>
                            Edit
                          </Menu.Item>
                        )}
                        {onClickDelete && payTitle !== 'Basic Salary' && (
                          <Menu.Item key="delete">
                            <DeleteModal
                              title="Delete Payroll"
                              buttonLabel="Yes, Delete!"
                              content="Are you sure you want to delete this pay item?"
                              onClickCancel={() => {
                                onClickDelete();
                              }}
                              openModal={
                                <B type="b-default">Delete</B>
                              }></DeleteModal>
                          </Menu.Item>
                        )}
                      </Menu>
                    }
                    trigger={['click']}>
                    <Image
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                      src={MoreSVG}
                    />
                  </Dropdown>
                )}
            </>
          ) : (
            <Div pl="28px"></Div>
          )}
        </AmountCol>
      </SalaryRowComp>
    </>
  );
};
export const SalaryHeader: FC<{
  title: string;
  onClickAdd?: () => void;
  status?: 'DRAFTED' | 'COMPLETED';
}> = ({ title, onClickAdd, status }) => {
  const params = useParams<payrollParam>();
  return (
    <SalaryHeaderComp className="salary-header">
      <div>
        {title === 'Deductions' ? (
          <B type="b-large-semibold">Deduction</B>
        ) : (
          <B type="b-small-semibold" color={theme.gray600}>
            {title}
          </B>
        )}
      </div>
      {status !== 'COMPLETED' &&
        params.salaryInformationViewType !==
          SalaryInformationView.CONFIRM_EMPLOYEE && (
          <Div ml="auto">
            {onClickAdd && (
              <PlusImage
                width={11}
                height={11}
                onClick={() => onClickAdd()}
                src={PlusBlueSVG}
              />
            )}
          </Div>
        )}
    </SalaryHeaderComp>
  );
};
interface Props {
  salaryItems: SalaryItemsByTypeI;
  title: string;
  onClickAdd?: () => void;
  onClickEdit?: (item: PayrollItemI) => void;
  onClickDelete?: (item: PayrollItemI) => void;
  status?: 'DRAFTED' | 'COMPLETED';
  nonEditable?: boolean;
  isMonthlyPayrun?: boolean;
  isMidDeduction?: boolean;
}
export const SalaryItem: FC<Props> = ({
  salaryItems,
  title,
  onClickAdd,
  onClickEdit,
  onClickDelete,
  status,
  nonEditable,
  isMonthlyPayrun,
  isMidDeduction,
}) => {
  const params = useParams<payrollParam>();
  return (
    !isMidDeduction && (
    <>
      <SalaryHeader title={title} onClickAdd={onClickAdd} status={status} />
      {salaryItems.length > 0 ? (
        salaryItems.map((item) => {
          return (
            <SalaryRow
              status={status}
              key={item.id}
              payTitle={item.payTitle || ''}
              amount={item.amount}
              isEPF={item.isEpf}
              isTax={item.isTaxable}
              isFromTimeAttendance={item?.isFromTimeAttendance}
              isSystemCalculated={item.isSystemCalculated}
              showMenu={!!(onClickEdit || onClickDelete)}
              onClickEdit={() => (onClickEdit ? onClickEdit(item) : undefined)}
              onClickDelete={() =>
                onClickDelete ? onClickDelete(item) : undefined
              }
              nonEditable={nonEditable}
              payrunType={isMonthlyPayrun ? item.payrunType : null}
            />
          );
        })
      ) : (
        <Div display="flex" mb="26px" mt="8px">
          <B type="b-small" color={theme.gray600}>
            You haven't added a {title.toLocaleLowerCase()} pay item!
          </B>
          &nbsp;
          {status !== 'COMPLETED' &&
            params.salaryInformationViewType !==
              SalaryInformationView.CONFIRM_EMPLOYEE &&
             (
              <B type="b-small" color={theme.blue500} onClick={onClickAdd}>
                Add {title}
              </B>
            )}
        </Div>
      )}
    </>)
  );
};
export const SalaryContainer: FC<{ children: ReactNode }> = ({ children }) => {
  return <SalaryContainerComp>{children}</SalaryContainerComp>;
};
