import styled from '@emotion/styled';
import React, { FC, ReactNode } from 'react';
import { SpaceProps, space } from 'styled-system';
import { B, Div } from '.';
import theme from '../theme';

export enum Status {
  INFO = 'info',
  WARNING = 'warn',
  SUCCESS = 'success',
  PENDING = 'pending',
  VOID = 'void',
  EXPIRED = 'expired',
  DRAFT = 'draft',
  SENT = 'sent',
}

interface Props {
  children?: ReactNode;
  status: string;
}

interface TagProps {
  children: ReactNode;
  textColor: string;
  tagColor: string;
  noBullet?: boolean;
  borderColor?: string;
}

const Span = styled.span<
  { tagColor: string; borderColor?: string } & SpaceProps
>`
  height: 8px;
  width: 8px;
  background-color: ${(props) => props.tagColor};
  border-radius: 50%;
  display: inline-block;
  border: ${(props) => props.borderColor || ''};
  ${space}
`;
export const Tag: FC<TagProps> = ({
  children,
  textColor,
  tagColor,
  noBullet,
  borderColor,
}) => {
  return (
    <Div display="flex">
      {!noBullet && (
        <Span tagColor={tagColor} borderColor={borderColor} mt="5px" />
      )}
      <B type="b-small" pl="4px" color={textColor}>
        {children}
      </B>
    </Div>
  );
};

export const StatusTag: FC<Props> = ({ status, children }) => {
  {
    switch (status) {
      case Status.WARNING:
        return (
          <Tag textColor="#58975B" tagColor="#9e9e9e">
            {children}
          </Tag>
        );

      case Status.SUCCESS:
        return (
          <Tag textColor="#58975B" tagColor="#2e7d32">
            {children}
          </Tag>
        );

      case Status.INFO:
        return (
          <Tag textColor={theme.blue100} tagColor={theme.blue100}>
            {children}
          </Tag>
        );

      case Status.PENDING:
        return (
          <Tag
            textColor="#EB9400"
            tagColor="white"
            borderColor="1.5px solid #eb9400">
            {children}
          </Tag>
        );

      case Status.SENT:
        return (
          <Tag
            textColor="#E57373"
            tagColor="white"
            borderColor="1.5px solid #e57373">
            {children}
          </Tag>
        );

      case Status.DRAFT:
        return (
          <Tag
            textColor="#9E9E9E"
            tagColor="white"
            borderColor="1.5px solid #9e9e9e">
            {children}
          </Tag>
        );

      default:
        return null;
    }
  }
};
