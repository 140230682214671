import { Radio } from 'antd';
import styled from '@emotion/styled';
import theme from '../theme';

export const RadioButtonComp = styled(Radio)`
  :where(.css-dev-only-do-not-override-1b0bdye).ant-radio-wrapper
    .ant-radio-inner {
    width: 20px;
    height: 20px;
  }
  :where(.css-dev-only-do-not-override-1b0bdye).ant-radio-wrapper
    .ant-radio-inner::after {
    background-color: ${theme.blue500};
    width: 22px;
    height: 22px;
    margin-block-start: -11px;
    margin-inline-start: -11px;
  }
  :where(.css-dev-only-do-not-override-1b0bdye).ant-radio-wrapper
    .ant-radio-checked
    .ant-radio-inner {
    background-color: ${theme.white};
    border: 1px solid ${theme.blue500};
  }
`;
