import styled from '@emotion/styled';
import { Col, Row, Tooltip, message } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  B,
  Can,
  CardBody,
  Div,
  PrimaryButton,
  SecondaryButton,
} from '../../../../components';
import { getPayrollSummary } from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import theme from '../../../../theme';
import { numberThousandSeparator } from '../../../../util/utils';
import PayRollModal from './shared/PayRunModal';
import { Permission } from '../../../../types';
import { SubscriptionEnum } from '../../../../types/subscription.types';
import { useAddNewPayRun } from '../../../../api/payrollHooks';
import { InfoOutedSVG } from '../../../../assets';
interface Prop {
  isDisable: boolean;
}

const Card = styled(Col)`
  padding: 16px 0px;
  @media (max-width: 768px) {
    border-bottom: 1px solid ${theme.gray300};
  }
`;
const Image = styled.img`
  width: 24px;
  height: 24px;
  margin-top: 5px;
`;
const CutsCol = styled(Col)`
  padding: 18px 0px;
  display: flex;
  @media (min-width: 1024px) {
    justify-content: flex-end;
  }
`;

const PayRollSummary: FC<Prop> = ({ isDisable }) => {
  const [visible, setVisible] = useState(false);
  const dispatch: DispatchType = useDispatch();
  const navigate = useNavigate();

  const [lastPayrollAmount, setLastPayrollAmount] = useState('0.00');

  const payrollSummery = useSelector(
    (state: RootState) => state.payroll.payrollSummery,
  );
  const { data, mutate, error, isLoading } = useAddNewPayRun();
  const me = useSelector((state: RootState) => state.people.me);

  useEffect(() => {
    if (me?.subscriptionStatus != SubscriptionEnum.SUSPENDED) {
      dispatch(getPayrollSummary());
    }
  }, []);

  useEffect(() => {
    if (payrollSummery?.lastPayrollAmount) {
      setLastPayrollAmount(
        numberThousandSeparator(payrollSummery.lastPayrollAmount),
      );
    }
  }, [payrollSummery]);

  useEffect(() => {
    if (data && !error) {
      setVisible(false);
      navigate('/Payroll-view/' + data.id);
    }
  }, [data]);

  const content = (
    <div>
      <B type="b-small" color={theme.white}>
        {isDisable
          ? `Please fill all mandatory details in company settings before you create new payruns`
          : `Process new month's payroll by creating a payrun and selecting employees or uploading salary CSV file.`}
      </B>
    </div>
  );

  return (
    <>
      <Div borderBottom={`1px solid ${theme.gray300}`}>
        <Row>
          <Card xs={24} sm={24} md={24} lg={6} xl={6} xxl={8}>
            <CardBody
              title="Total Payroll Last Month"
              prefix="LKR"
              body={lastPayrollAmount}
            />
          </Card>
          <Can permission={Permission.CREATE_CONTRACT}>
            <Card xs={24} sm={24} md={10} lg={6} xl={6} xxl={8}>
              <CardBody
                title="Next Payroll Date"
                body={
                  payrollSummery?.nextPayroll
                    ? payrollSummery?.nextPayroll
                    : '-'
                }
              />
            </Card>
          </Can>
          <CutsCol xs={24} sm={24} md={14} lg={10} xl={12} xxl={8}>
            <PrimaryButton
              size="small"
              disabled={isDisable}
              onClick={() => {
                setVisible(true);
              }}>
              Start Payroll
            </PrimaryButton>
            <SecondaryButton
              size="small"
              mx="16px"
              disabled={isDisable}
              onClick={() => navigate('/create-payroll-using-file')}>
              Start Payroll Using CSV
            </SecondaryButton>

            <Tooltip placement="topRight" title={content}>
              <Image src={InfoOutedSVG} />
            </Tooltip>
          </CutsCol>
        </Row>
        {visible && (
          <PayRollModal
            buttonDisabled={isLoading}
            visible={visible}
            onClose={() => setVisible(false)}
            onSubmit={async (values) => {
              try {
                mutate(values);
              } catch (error: any) {
                message.error('Something went wrong');
              }
            }}
          />
        )}
      </Div>
    </>
  );
};

export default PayRollSummary;
