import { Dropdown } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';
import React, { FC } from 'react';
import theme from '../theme';
import { Image } from './Image';
import { MoreHorizontaSVG } from '../assets';
import styled from '@emotion/styled';

interface DropdownIconProps extends DropDownProps {
  customIcon?: any;
  marginLeft?: number;
  marginRight?: number;
}
const DropdownComp = styled(Dropdown)<DropdownIconProps>`
  margin-left: ${(props) => props.marginLeft}px;
  margin-right: ${(props) => props.marginRight}px;
  padding: 14px 10px;
  background: ${theme.gray100};
  border-radius: 4px;
  height: 32px;
  width: 36px;
  &:hover {
    background: ${theme.gray300};
  }
`;

export const DropdownIcon: FC<DropdownIconProps> = ({
  customIcon = MoreHorizontaSVG,
  marginLeft = 0,
  marginRight = 0,
  ...rest
}) => {
  return (
    <DropdownComp marginLeft={marginLeft} marginRight={marginRight} {...rest}>
      <Image
        cursor="pointer"
        onClick={(e) => e.preventDefault()}
        src={customIcon}
        draggable={false}
      />
    </DropdownComp>
  );
};
