import { AuthPayload, UserRoleIdEnum } from '../types/';

enum StorageKeys {
  authPayloads = 'authPayloads',
  currentAuthIndex = 'currentAuthIndex',
}

export const clearStorage = () => {
  return localStorage.clear();
};

// ------------- auth payloads ------------- ------------- ------------- -------------

export const getCurrentAuthToken = (): string | null => {
  const payload = getCurrentAuthPayload();
  return payload ? payload.jwtPayload : null;
};

export const getCurrentAuthIndex = (): number => {
  const payload = localStorage.getItem(StorageKeys.currentAuthIndex);
  return Number(payload) || 0;
};

export const setCurrentAuthIndex = (index: number): void => {
  localStorage.setItem(StorageKeys.currentAuthIndex, index.toString());
};

export const setAuthPayloads = (payLoads: AuthPayload[]): void => {
  localStorage.setItem(StorageKeys.authPayloads, JSON.stringify(payLoads));
};

export const getAuthPayloads = (): AuthPayload[] => {
  try {
    const payloads = localStorage.getItem(StorageKeys.authPayloads);
    return payloads ? JSON.parse(payloads) : [];
  } catch (error: any) {
    return [];
  }
};

export const getCurrentAuthPayload = (): AuthPayload | null => {
  try {
    const authPayloads = getAuthPayloads();
    return authPayloads[getCurrentAuthIndex()] || null;
  } catch (error: any) {
    return null;
  }
};

export const isCurrentAuthSuperAdmin = (): boolean => {
  try {
    const authPayload = getCurrentAuthPayload();
    return authPayload?.role.id === UserRoleIdEnum.SUPER_ADMIN;
  } catch (error: any) {
    return false;
  }
};
