import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { PayrollReportI } from '../../../store/actions';
import { PeopleI } from '../../../types/people.types';
import { SelectedInfoI } from '../../../types/report.types';
import { removeSemicolon, numberThousandSeparator } from '../../../util/utils';
import { getPayrollDetailedDataFiltered } from './payrollReport';

export const generateDetailedExcel = (
  data: any,
  numberOfPayrolls?: number,
  employees?: PeopleI[],
  PayrollsData?: PayrollReportI[],
) => {
  if (isEmpty(data)) return null;
  const dataPayload = data || {};
  const sheetRow: any[] = [];
  sheetRow.push({});
  sheetRow.push({ A: 'Generated On', B: moment().format('DD/MM/YYYY') });
  sheetRow.push({ A: 'Number of Payrolls', B: numberOfPayrolls });
  sheetRow.push({ A: 'Number of Employees', B: employees?.length });
  sheetRow.push({});
  for (const key in dataPayload) {
    if (Object.prototype.hasOwnProperty.call(dataPayload, key)) {
      const section = dataPayload[key];
      if (key === 'earnings') {
        let itemAmount = 0;
        sheetRow.push({ A: `${key.toUpperCase()}` });
        for (const type in section) {
          if (Object.prototype.hasOwnProperty.call(section, type)) {
            const value = section[type];
            const index = type;
            itemAmount += value;
            sheetRow.push({ A: index, B: numberThousandSeparator(value) });
          }
        }
        sheetRow.push({ A: '', B: '', c: numberThousandSeparator(itemAmount) });
      } else if (key === 'deductions') {
        sheetRow.push({ A: `${key.toUpperCase()}` });
        let itemAmount = 0;
        for (const type in section) {
          if (Object.prototype.hasOwnProperty.call(section, type)) {
            const value = section[type];
            const index = type;
            itemAmount += value;
            sheetRow.push({ A: index, B: numberThousandSeparator(value) });
          }
        }
        sheetRow.push({ A: '', B: '', c: numberThousandSeparator(itemAmount) });
      } else if (key === 'other') {
        sheetRow.push({
          A: 'CALCULATIONS',
        });
        for (const type in section) {
          if (Object.prototype.hasOwnProperty.call(section, type)) {
            const value = section[type];
            const index = type;
            sheetRow.push({
              A: getTypeNames(index),
              B: numberThousandSeparator(value),
            });
          }
        }
        sheetRow.push({});
      } else {
        for (const type in section) {
          if (Object.prototype.hasOwnProperty.call(section, type)) {
            const value = section[type];
            const index = type;
            sheetRow.push({
              A: getTypeNames(index),
              B: numberThousandSeparator(value),
            });
          }
        }
      }
    }
  }
  const selectedInfoRow: SelectedInfoI[] = [];
  selectedInfoRow.push({
    name: 'Name',
    startDate: 'Start Date',
    endDate: 'End Date',
    no: 'No',
    fullName: 'Employee Name (Full Name)',
  });

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Payroll_Detailed');
  const selectedInfoSheet = workbook.addWorksheet('Selected Info');
  let payrollSheet: ExcelJS.Worksheet;

  PayrollsData?.map((item, index) => {
    const payroll = getPayrollDetailedDataFiltered(item);
    const payrollSheetRow: any[] = [];
    payrollSheetRow.push({});
    payrollSheetRow.push({
      A: 'Generated On',
      B: moment(item.createDate).format('DD/MM/YYYY'),
    });
    payrollSheetRow.push({ A: 'Payroll Name', B: item.name });
    payrollSheetRow.push({
      A: 'Start Date',
      B: moment(item.startDate).isValid()
        ? moment(item.startDate).format('DD/MM/YYYY')
        : '-',
    });
    payrollSheetRow.push({
      A: 'End Date',
      B: moment(item.endDate).isValid()
        ? moment(item.endDate).format('DD/MM/YYYY')
        : '-',
    });
    payrollSheetRow.push({ A: 'Number of Employees', B: item.users.length });
    payrollSheetRow.push({});

    for (const key in payroll) {
      if (Object.prototype.hasOwnProperty.call(payroll, key)) {
        const section = payroll[key];
        if (key === 'earnings') {
          let itemAmount = 0;
          payrollSheetRow.push({ A: `${key.toUpperCase()}` });
          for (const type in section) {
            if (Object.prototype.hasOwnProperty.call(section, type)) {
              const value = section[type];
              const index = type;
              itemAmount += value;
              payrollSheetRow.push({
                A: index,
                B: numberThousandSeparator(value),
              });
            }
          }
          payrollSheetRow.push({
            A: '',
            B: '',
            c: numberThousandSeparator(itemAmount),
          });
        } else if (key === 'deductions') {
          payrollSheetRow.push({ A: `${key.toUpperCase()}` });
          let itemAmount = 0;
          for (const type in section) {
            if (Object.prototype.hasOwnProperty.call(section, type)) {
              const value = section[type];
              const index = type;
              itemAmount += value;
              payrollSheetRow.push({
                A: index,
                B: numberThousandSeparator(value),
              });
            }
          }
          payrollSheetRow.push({
            A: '',
            B: '',
            c: numberThousandSeparator(itemAmount),
          });
        } else if (key === 'other') {
          payrollSheetRow.push({
            A: 'CALCULATIONS',
          });
          for (const type in section) {
            if (Object.prototype.hasOwnProperty.call(section, type)) {
              const value = section[type];
              const index = type;
              payrollSheetRow.push({
                A: getTypeNames(index),
                B: value ? numberThousandSeparator(value) : '0.00',
              });
            }
          }
          payrollSheetRow.push({});
        } else {
          for (const type in section) {
            if (Object.prototype.hasOwnProperty.call(section, type)) {
              const value = section[type];
              const index = type;
              payrollSheetRow.push({
                A: getTypeNames(index),
                B: numberThousandSeparator(value),
              });
            }
          }
        }
      }
    }
    const date = moment(item.startDate);

    payrollSheet = workbook.addWorksheet(
      `${index + 1}. ${removeSemicolon(item.name)} ${
        date.isValid() ? date.format('DD-MM-YYYY') : ''
      }`,
    );

    payrollSheet.columns = [
      { key: 'A', width: 30 },
      { key: 'B', width: 20 },
      { key: 'c', width: 15 },
    ];
    payrollSheet.addRows(payrollSheetRow);
    selectedInfoRow.push({
      name: item.name,
      startDate: moment(item.startDate).format('DD/MM/YYYY'),
      endDate: moment(item.endDate).format('DD/MM/YYYY'),
    });
  });

  if (employees?.length) {
    employees.map((user, i) => {
      i++;
      if (selectedInfoRow.length > i) {
        selectedInfoRow[i]['no'] = user.employeeNumber;
        selectedInfoRow[i]['fullName'] = user.isArchived
          ? `${user.fullName} - Archived`
          : user.fullName;
      } else {
        selectedInfoRow.push({
          name: '',
          startDate: '',
          endDate: '',
          no: user.employeeNumber,
          fullName: user.isArchived
            ? `${user.fullName} - Archived`
            : user.fullName,
        });
      }
    });
  }
  // create some dummy data
  worksheet.columns = [
    { key: 'A', width: 30 },
    { key: 'B', width: 20 },
    { key: 'c', width: 15 },
  ];
  selectedInfoSheet.columns = [
    {
      header: 'Selected Payrolls',
      key: 'name',
      width: 30,
    },
    { key: 'startDate', width: 20 },
    { key: 'endDate', width: 20 },
    { key: 'D' },
    {
      header: 'Selected Employees',
      key: 'no',
    },
    { key: 'fullName', width: 40 },
  ];

  // Adding border to all cells
  worksheet.eachRow(function (row, _rowNumber) {
    worksheet.columns.forEach((_item: any, i: number) => {
      const cell = row.getCell(i + 1);
      cell.border = {
        top: { style: 'thin', color: { argb: '00000000' } },
        left: { style: 'thin', color: { argb: '00000000' } },
        bottom: { style: 'thin', color: { argb: '00000000' } },
        right: { style: 'thin', color: { argb: '00000000' } },
      };
    });
  });
  worksheet.addRows(sheetRow);
  selectedInfoSheet.addRows(selectedInfoRow);
  selectedInfoSheet.getRow(1).font = { bold: true };
  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `Payroll_Detailed_Export_${moment().format('DD-MM-YYYY')}.xlsx`,
    );
  });
};
const getTypeNames = (key: string): string => {
  const types: any = {
    basic: 'Basics',
    costToCompany: 'Total Cost to company',
    netSalary: 'Net Salary Amount',
    employeeEPF: 'EPF Employee contribution 8%',
    employerEPF: 'EPF Employer contribution 12%',
    employerETF: 'ETF contribution 3%',
    totalTax: 'APIT',
  };
  if (types[key]) return types[key];
  return key;
};
