import React, { FC, ReactNode } from 'react';
import theme from '../../theme';
import { CloseOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

interface Props {
  children: ReactNode;
  onClose: () => void;
}

const MainContainer = styled.div`
  background: ${theme.lightBlue100};
  color: ${theme.blue600};
  padding: 3px 8px 4px 16px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 8px;
  height: 36px;
`;
const CloseOut = styled(CloseOutlined)`
  padding: 8px;
  cursor: pointer;
`;

export const Tag: FC<Props> = ({ children, onClose }) => (
  <MainContainer>
    {children}
    <CloseOut onClick={() => onClose()} />
  </MainContainer>
);
