import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Div, H } from '../../../components';
import { RootState } from '../../../store/reducer';
import theme from '../../../theme';
import { addDotsForLongText, isHaveThreeDots } from '../../../util/utils';
import styled from '@emotion/styled';
import { Spin } from 'antd';
import { HumanisedLog, Logo } from '../../../assets';
interface Props {
  title?: string;
  collapsed: boolean;
}
const Image = styled.img`
  max-width: 45px;
  max-height: 45px;
  object-fit: cover;
  @media (max-width: 450px) {
    max-width: 40px;
    max-height: 40px;
  }
`;

const CollapsedSpin = styled(Div)`
  padding: 24px 30px 0px;
  @media (max-width: 1024px) {
    padding: 0px 19px 0px 0px;
  }
`;
const CollapsedIcon = styled(Div)`
  padding: 24px 20px 0px;
  @media (max-width: 1024px) {
    padding: 0px 19px 0px 0px;
  }
`;
const OpenedSpin = styled(Div)`
  alignitems: center;
  padding: 50px 30px 0px;
  text-align: center;
  @media (max-width: 1024px) {
    padding: 0px 19px 0px 0px;
  }
`;
const OpenedSection = styled(Div)`
  padding-left: 20px;
  padding-top: 24px;
  padding-right: 0px;
  display: flex;
  flex-direction: row;
  @media (max-width: 1024px) {
    padding: 0px 19px 0px 0px;
  }
`;
const OpenedIcon = styled(Div)`
  display: inline-block;
  @media (max-width: 768px) {
    margin: 0px;
    padding: unset;
  }
`;

const BrandLogo: FC<Props> = ({ title, collapsed }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (organizationData) {
      setIsLoading(false);
    }
  }, [organizationData]);

  if (collapsed) {
    return isLoading ? (
      <CollapsedSpin>
        <Spin />
      </CollapsedSpin>
    ) : (
      <CollapsedIcon>
        <a href="/home">
          {organizationData?.logo ? (
            <Image alt="logo" src={organizationData?.logo} />
          ) : (
            <HumanisedLog />
          )}
        </a>
      </CollapsedIcon>
    );
  }
  return isLoading ? (
    <OpenedSpin>
      <Spin />
    </OpenedSpin>
  ) : (
    <OpenedSection>
      <OpenedIcon>
        <a href="/home">
          {organizationData?.logo ? (
            <Image alt="log" src={organizationData?.logo} />
          ) : (
            <HumanisedLog />
          )}
        </a>
      </OpenedIcon>
      <div>
        {title ? (
          isHaveThreeDots(title, 14) ? (
            <H
              type="h6"
              color={theme.white}
              display="inline-block"
              pl="16px"
              pr="16px">
              {show ? (title ? addDotsForLongText(title, 14) : '') : ''}
            </H>
          ) : (
            <H
              type="h6"
              color={theme.white}
              display="inline-block"
              pl="16px"
              pr="16px"
              pt="10px">
              {show ? title : ''}
            </H>
          )
        ) : (
          ''
        )}
      </div>
    </OpenedSection>
  );
};

export default BrandLogo;
