import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import FormItem from 'antd/lib/form/FormItem';
import { B, Div, InputComp } from '../../../components';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import { GrayCancel } from '../../../assets';

const MainDiv = styled.div`
  display: flex;
  padding: 11px 22px 16px 19px;
  height: 56px;
  justify-content: space-between;
`;

const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const CustomInputComp = styled(InputComp)`
  height: 24px;
  width: 160px;
  margin-left: 10px;
`;

interface Props {
  onRemove: () => void;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  onChangeCheckBox: (e: CheckboxChangeEvent) => void;
  onChangeEpf: (e: CheckboxChangeEvent) => void;
  onChangeApit: (e: CheckboxChangeEvent) => void;
  onChangeAmount: React.ChangeEventHandler<HTMLInputElement>;
}

const AddAttendanceAllowance: FC<Props> = ({
  onRemove,
  onChange,
  onBlur,
  onChangeCheckBox,
  onChangeAmount,
}) => {
  const [form] = Form.useForm();

  return (
    <Form form={form}>
      <MainDiv>
        <Div display="flex" mr={3}>
          <FormItem valuePropName="checked">
            <CheckBoxComp onChange={(e) => onChangeCheckBox(e)} />
          </FormItem>
          <FormItem
            name="allowanceName"
            rules={[
              {
                required: true,
                message: 'Please enter allowance name',
              },
            ]}>
            <CustomInputComp
              placeholder="Enter Name"
              onChange={(e) => onChange(e)}
              onBlur={(e) => onBlur(e)}
            />
          </FormItem>
        </Div>
        <Div display="flex" ml="4px">
          <FormItem
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please enter allowance amount',
              },
            ]}>
            <CustomInputComp
              placeholder="Enter Amount"
              type="number"
              onChange={(e) => onChangeAmount(e)}
            />
          </FormItem>
        </Div>

        <Div display="flex" ml="15px">
          <FormItem valuePropName="checked">
            <CheckBoxComp onChange={(e) => onChangeCheckBox(e)} />
          </FormItem>
          <B type="b-default" ml="8px" mt="7px">
            EPF/ETF
          </B>
        </Div>

        <Div display="flex" ml="15px">
          <FormItem valuePropName="checked">
            <CheckBoxComp onChange={(e) => onChangeCheckBox(e)} />
          </FormItem>
          <B type="b-default" ml="8px" mt="7px">
            APIT
          </B>
        </Div>

        <Div ml="38px" mt="4px">
          <CloseIcon onClick={() => onRemove()} src={GrayCancel} />
        </Div>
      </MainDiv>
    </Form>
  );
};

export default AddAttendanceAllowance;
