import 'react-modern-drawer/dist/index.css';
import React, { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import { startCase } from 'lodash';
import { useSelector } from 'react-redux';
import {
  CompactTableComp,
  Div,
  FullScreen,
  fullScreenCss,
} from '../../../components';
import { DataImportUserPayLoad } from '../../../store/actions';
import { RootState } from '../../../store/reducer';
import theme from '../../../theme';
import { useWindowSize } from '../../../util/screenSize';
import {
  getLeaveColumns,
  getPayslipFieldColumns,
} from '../../settings/data-imports/dataImport.util';
import { getColumns } from './getTableColumn';
import styled from '@emotion/styled';

interface Props {
  dataPayload: DataImportUserPayLoad[];
  payItemColumns: string[];
  leaveItemColumns?: string[];
  payrollKey?: string;
  isDraftPayroll: boolean;
  setSelectedRowKeys: (rows: string[]) => void;
  selectedRowKeys: string[];
  fullScreen: boolean;
  setFullScreen: (fullScreen: boolean) => void;
  extraTableHeight: number;
  isSelectedEmployeeOnly: boolean;
  disableView?: boolean;
  onErrorFilter?: boolean;
  payItemErrorColumns?: string[];
  employeeErrorObj?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  onChange?: (v: DataImportUserPayLoad[]) => void;
  isMidCyclePayrun?: boolean;
}
const FullScreenCard = styled.div<{
  columnsLength: number;
  fullScreen: boolean;
}>`
  width: ${(props) => (props.columnsLength <= 6 ? '80%' : '100%')};
  width: '100%';
  background: ${theme.white};
  padding-bottom: 0;
  ${(props) => (props.fullScreen ? fullScreenCss : '')}
  .meta-i {
    font-size: 7px;
    position: absolute;
    top: -4px;
    right: 10px;
  }
`;
const PayrollTable: FC<Props> = ({
  onErrorFilter,
  dataPayload,
  payItemColumns,
  payItemErrorColumns,
  payrollKey,
  isDraftPayroll,
  selectedRowKeys,
  setSelectedRowKeys,
  setFullScreen,
  fullScreen,
  extraTableHeight,
  isSelectedEmployeeOnly,
  disableView,
  employeeErrorObj,
  isMidCyclePayrun,
}) => {
  const [form] = Form.useForm();
  const [leaveColumn, setLeaveColumn] = useState<string[]>([]);
  const [payslipFieldsColumn, setPayslipFieldsColumn] = useState<string[]>([]);
  const [isPreferredName, setIsPreferredName] = useState<boolean>(false);

  const [visible, setVisible] = useState(false);
  const [tableHeight, setTableHeight] = useState(0);

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  useEffect(() => {
    const height = window.innerHeight + extraTableHeight;
    const newTableHeight = fullScreen
      ? height - 140
      : height > 300
      ? height - 325
      : 0;
    setTableHeight(newTableHeight);
  }, [window.innerHeight, fullScreen, extraTableHeight]);

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setLeaveColumn(getLeaveColumns(dataPayload).map((item) => startCase(item)));

    setPayslipFieldsColumn(
      getPayslipFieldColumns(dataPayload).map((item) => startCase(item)),
    );

    setIsPreferredName(dataPayload.some((item) => item.values.preferredName));
  }, [dataPayload]);

  const handleKeyDown = (evt: KeyboardEvent) => {
    if (evt.code === 'Escape') {
      setFullScreen(false);
    }
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const { isMobile, isTablet } = useWindowSize();
  const columns = getColumns({
    onErrorFilter,
    payItemErrorColumns,
    employeeErrorObj,
    payItemColumns,
    payrollKey,
    selectedEmployeeOnly: isSelectedEmployeeOnly,
    leaveColumn,
    payslipFieldsColumn,
    leaveAllowedForPayslip: organizationData?.isLeaveInPayroll,
    disableView,
    isPreferredName,
    visible,
    onClose,
    isMobile,
    isTablet,
    isDraftPayroll,
    isMidCyclePayrun,
  });

  const bankDetailsUpdatedDataPayload = dataPayload.map((item) => {
    if (item.values.isEmployeePaidInCash) {
      item.values.bankAccountName = 'empty';
      item.values.bankAccountNumber = 'empty';
      item.values.bankBankCode = 'empty';
      item.values.branchCode = 'empty';
    }
    return item;
  });

  return (
    <FullScreenCard
      id="salaryTable"
      columnsLength={columns.length}
      fullScreen={fullScreen}>
      {fullScreen && (
        <Div display="flex" justifyContent="flex-end">
          <FullScreen
            fullScreen={fullScreen}
            openFullScreen={() => {
              setFullScreen(true);
            }}
            exitFullscreen={() => {
              setFullScreen(false);
            }}
          />
        </Div>
      )}

      <CompactTableComp
        columns={columns}
        pagination={false}
        dataSource={bankDetailsUpdatedDataPayload}
        scroll={
          bankDetailsUpdatedDataPayload.length > 0
            ? {
                x: 500,
                y: tableHeight,
              }
            : undefined
        }
        rowKey={(record: DataImportUserPayLoad) => record.rowKey}
        rowClassName={(record) => {
          return isDraftPayroll && record.isNewInDraft ? 'new-row' : '';
        }}
        rowSelection={
          isDraftPayroll
            ? {
                selectedRowKeys: selectedRowKeys,
                onChange: (
                  _selectedRowKeys: React.Key[],
                  selectedRows: DataImportUserPayLoad[],
                ) => {
                  setSelectedRowKeys(selectedRows.map((item) => item.rowKey));
                },
                getCheckboxProps: (record: DataImportUserPayLoad) => ({
                  disabled: record.hasAnyError,
                }),
                renderCell: (
                  _value: boolean,
                  record,
                  _index: number,
                  originNode: React.ReactNode,
                ) => {
                  return (
                    <>
                      {record.hasAnyError ? (
                        <span className="error-row"></span>
                      ) : null}
                      {originNode}
                    </>
                  );
                },
              }
            : undefined
        }
      />
    </FullScreenCard>
  );
};

export default PayrollTable;
