import React, { FC, Fragment, useState } from 'react';
import { Dropdown, Menu, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  B,
  DeleteModal,
  EmptyTextContainer,
  Image,
  TableComp,
} from '../../../components';
import { deleteLeaveUser } from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import theme from '../../../theme';
import { OrganizationLeaveI, Permission, ReviewI } from '../../../types';
import { isAllowed, isManager } from '../../../util/permissionUtil';
import { reviewAllowed } from '../../../util/reviewAllowed';
import { useWindowSize } from '../../../util/screenSize';
import { addDotsForLongText } from '../../../util/utils';
import { LeaveStatus } from '../../home/homeUtil';
import LeavePeriodDisplay from '../../home/shared/leaves/LeavePeriodDisplay';
import LeaveTypeDisplay from '../../home/shared/leaves/LeaveTypeDisplay';
import { convertHoursToHoursAndMin } from '../utils';
import AdminLeave from './AdminLeave';
import LeaveRejectModal from './LeaveRejectModal';
import { ExpenseStatusTag } from '../../expenses/shared';
import { tableSorter } from '../../../util/tableSorter';
import { DataRangeI } from '../../../types/report.types';
import moment from 'moment';
import { MoreSVG } from '../../../assets';

const AdminLeaveItem = styled.div`
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

const StatusColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

const DropdownMenu = styled(Menu)`
  width: 163px;
  padding: 0px;
  border: 0.5px solid ${theme.gray300};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .ant-dropdown-menu-item > a {
    padding: 12px 0px 12px 16px;
  }
  li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
    border-bottom: 1px solid ${theme.gray300};
  }
`;
interface Props {
  tableScrollY?: number;
  organizationLeaves: OrganizationLeaveI[];
  onDataChange: (v: ReviewI) => void;
  dateRange: DataRangeI | null;
}
const AllLeaveTable: FC<Props> = ({
  tableScrollY,
  organizationLeaves,
  onDataChange,
  dateRange,
}) => {
  const { isDesktopLarge } = useWindowSize();
  const dispatch: DispatchType = useDispatch();
  const [visible, setVisible] = useState(false);
  const [rejectLeaveID, setRejectLeaveID] = useState<string | null>(null);

  const organizationLeavesLoading = useSelector(
    (state: RootState) => state.leave.organizationLeavesLoading,
  );
  const scrollTarget = useSelector(
    (state: RootState) => state.dashboard.scrollTarget,
  );

  const me = useSelector((state: RootState) => state.people.me);
  const columns: ColumnProps<OrganizationLeaveI>[] = [
    {
      title: 'Employee Name',
      dataIndex: 'name',
      ellipsis: true,
      width: 200,
      render: (_hours: string, data) => (
        <B type="b-default">
          <Tooltip title={data.user?.fullName || '-'}>
            {data.user?.fullName || '-'}
          </Tooltip>
        </B>
      ),
      sorter: (a, b) =>
        tableSorter.defaultSort(a.user.fullName, b.user.fullName),
      sortDirections: ['descend', 'ascend'],
      key: 'createdBy.fullname',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 120,
      render: (_value, data) =>
        data.reviewBy ? (
          <Tooltip
            placement="topLeft"
            title={
              <Fragment>
                {data.reviewBy && (
                  <B color="white" type="b-small">
                    Reviewed By : {data.reviewBy.fullName}
                  </B>
                )}
                {data.reviewBy && data.status === LeaveStatus.REJECTED && (
                  <B color="white" type="b-small">
                    Reject Note : {data.rejectionNote || '-'}
                  </B>
                )}
              </Fragment>
            }>
            <StatusColumn>
              <ExpenseStatusTag status={data.status} />
            </StatusColumn>
          </Tooltip>
        ) : (
          <StatusColumn>
            <ExpenseStatusTag status={data.status} />
          </StatusColumn>
        ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: isDesktopLarge ? 150 : 130,
      render: (_hours: string, data) => <LeavePeriodDisplay data={data} />,
      sorter: (a, b) => tableSorter.defaultSort(a.date, b.date),
      sortDirections: ['descend', 'ascend'],
      key: 'date',
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      width: 120,
      render: (_hours: string, data) => (
        <B type="b-default">{convertHoursToHoursAndMin(data.hours)}</B>
      ),
      sorter: (a, b) => tableSorter.defaultSort(a.hours, b.hours),
      sortDirections: ['descend', 'ascend'],
      key: 'hours',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: isDesktopLarge ? 150 : 120,
      render: (_value, data) => <LeaveTypeDisplay data={data} />,
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      render: (_value, data) => (
        <>
          <B type="b-default">
            {data.reason ? (
              <Tooltip placement="topLeft" title={data.reason}>
                {addDotsForLongText(data.reason, 40)}
              </Tooltip>
            ) : (
              '-'
            )}
          </B>
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 120,
      render: (_value, data) => (
        <>
          {data.status !== LeaveStatus.ACCEPTED &&
            reviewAllowed({
              reviewerId: me?.id,
              userId: Number(data.userId),
            }) && (
              <B
                type="b-small"
                color={theme.blue500}
                cursor="pointer"
                padding="0px"
                onClick={() => {
                  const values = {
                    status: LeaveStatus.ACCEPTED,
                    leaveId: String(data.id),
                  };
                  onDataChange(values);
                }}>
                Approve
              </B>
            )}
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'more',
      width: 60,
      render: (_value, data) => {
        return (
          <>
            {(reviewAllowed({
              reviewerId: me?.id,
              userId: Number(data.userId),
            }) ||
              isAllowed(Permission.CREATE_EDIT_LEAVES_USER) ||
              (isManager() && data.status === LeaveStatus.PENDING)) && (
              <Dropdown
                overlay={
                  <DropdownMenu>
                    {data.status !== LeaveStatus.REJECTED &&
                      reviewAllowed({
                        reviewerId: me?.id,
                        userId: Number(data.userId),
                      }) && (
                        <Menu.Item>
                          <B
                            padding="10px 0px 10px 6px"
                            type="b-default"
                            onClick={() => {
                              setRejectLeaveID(String(data.id));
                              setVisible(true);
                            }}>
                            Reject
                          </B>
                        </Menu.Item>
                      )}

                    <Menu.Item>
                      <DeleteModal
                        title="Confirm Cancellation"
                        buttonLabel="Cancel"
                        content="Are you sure you want to cancel this leave?"
                        onClickCancel={() => {
                          dispatch(
                            deleteLeaveUser(
                              String(data.userId),
                              String(data.id),
                            ),
                          );
                        }}
                        openModal={
                          <B
                            padding="10px 0px 10px 6px"
                            cursor="pointer"
                            type="b-default">
                            Cancel
                          </B>
                        }></DeleteModal>
                    </Menu.Item>
                  </DropdownMenu>
                }>
                <a>
                  <Image cursor="pointer" src={MoreSVG} />
                </a>
              </Dropdown>
            )}
          </>
        );
      },
    },
  ];

  const locale = {
    emptyText: (
      <EmptyTextContainer description="You don't have any leave requested yet" />
    ),
  };

  const startDate = moment(dateRange?.startDate).format('YYYY-MM-DD');
  const endDate = moment(dateRange?.endDate).format('YYYY-MM-DD');

  let filteredDateRange = null;

  if (dateRange?.startDate === null || dateRange?.endDate === null) {
    filteredDateRange = organizationLeaves;
  } else {
    filteredDateRange = organizationLeaves.filter(
      (item) => item.date <= endDate && item.date >= startDate,
    );
  }

  return (
    <>
      <TableComp
        rowKey={(item) => {
          return String(item.id);
        }}
        headerFontSize={14}
        rowClassName={(row) =>
          row.id === scrollTarget ? 'yellow-highlight' : ''
        }
        withBorders={true}
        dataSource={filteredDateRange}
        columns={columns}
        locale={locale}
        pagination={false}
        loading={organizationLeavesLoading}
        scroll={{
          y: tableScrollY || window.innerHeight - 340,
          x: 1200,
        }}
        css={css`
          @media only screen and (max-width: 768px) {
            display: none;
          }
        `}
      />

      <AdminLeaveItem>
        {!!organizationLeaves.length ? (
          <>
            {organizationLeaves.map((item) => (
              <AdminLeave
                leavesDetails={item}
                onDataChange={(value) => {
                  onDataChange(value);
                }}
              />
            ))}
          </>
        ) : (
          <EmptyTextContainer description="You don't have any leave yet" />
        )}
      </AdminLeaveItem>
      {visible && (
        <LeaveRejectModal
          visible={visible}
          handleCancel={() => {
            setVisible(false);
            setRejectLeaveID(null);
          }}
          onSubmit={(values) => {
            if (rejectLeaveID && values.reasonForRejection) {
              const value = {
                status: LeaveStatus.REJECTED,
                leaveId: rejectLeaveID,
                reasonForRejection: values.reasonForRejection,
              };
              onDataChange(value);
            }
            setRejectLeaveID(null);
            setVisible(false);
          }}
        />
      )}
    </>
  );
};
export default AllLeaveTable;
