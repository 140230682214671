import React, { FC, useEffect, useState } from 'react';
import { Col, Row, Spin, Divider } from 'antd';
import { OrganizationsList } from './OrganizationsList';
import OrganizationDetails from './OrganizationDetails';
import OwnerDetails from './OwnerDetails';
import { useNavigate, useParams } from 'react-router';
import OrganizationBreadcrumb from '../../shared/OrganizationBreadcrumb';
import { DefaultBody, EmptyPage } from '../../../../components';
import styled from '@emotion/styled';
import theme from '../../../../theme';
import { useGetOrganizationData } from '../../../../api/superAdminHooks';

type params = {
  accountId: string;
};

const OrganizationsListComp = styled(Col)`
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px;
`;
const AccountHomePage: FC = () => {
  const params = useParams<params>();
  const navigate = useNavigate();
  const [selectOrganization, setSelectOrganization] = useState<number | null>(
    null,
  );

  const {
    isLoading: organizationDataLoading,
    data: organizationData = [],
    mutate: getOrganizationData,
  } = useGetOrganizationData();

  useEffect(() => {
    getOrganizationData(Number(params.accountId));
  }, [params.accountId]);

  useEffect(() => {
    if (organizationData.length) {
      setSelectOrganization(organizationData[0].organizationId);
    }
  }, [organizationData]);

  const selectOrganizationData = organizationData.find(
    (item) => item.organizationId === selectOrganization,
  );
  const organizations = organizationData.map((item) => {
    return {
      organizationId: item.organizationId,
      organizationName: item.organizationName,
    };
  });

  const urlChange = (index: number) => {
    navigate(`/super-admin/organization/${index}`);
  };

  if (!organizationDataLoading && !organizations.length) {
    return (
      <EmptyPage content="Not found" title="Not found" buttonLabel="Home" />
    );
  }
  return (
    <DefaultBody>
      <Spin spinning={organizationDataLoading}>
        <OrganizationBreadcrumb
          owner={Number(params.accountId)}
          onSelect={(index) => urlChange(index)}
        />
        <OwnerDetails organizationDetails={organizationData} />

        <Divider />
        <Row>
          <OrganizationsListComp xs={4} sm={4} md={5} lg={5} xl={5} xxl={4}>
            <OrganizationsList
              organizationList={organizations}
              onSelect={(index) => setSelectOrganization(index)}
            />
          </OrganizationsListComp>
          <Col xs={20} sm={20} md={19} lg={19} xl={19} xxl={20}>
            <OrganizationDetails
              organizationDetails={
                selectOrganizationData ? selectOrganizationData : null
              }
            />
          </Col>
        </Row>
      </Spin>
    </DefaultBody>
  );
};

export default AccountHomePage;
