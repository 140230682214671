import React, { FC, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { B, Div } from '../../../components';
import CompanySeal from './CompanySeal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import theme from '../../../theme';
import { Divider } from '../../../components/Divider';
import styled from '@emotion/styled';
import { orgBasedFeatureConfig } from '../../../configs';

interface Props {
  setSealUrl: (url: string) => void;
}
const MainCard = styled.div`
  padding: 32px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
`;
const ColComp = styled(Col)`
  margin-bottom: 24px;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;
const PayslipSetting: FC<Props> = ({ setSealUrl }) => {
  const [sealDisabled, setSealDisabled] = useState(true);
  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  return (
    <>
      <MainCard>
        <Row>
          <ColComp>
            <B type="b-large-semibold">Pay-Slip Customization</B>
            <B type="b-default">
              Please select what you want to include on payslip
            </B>
          </ColComp>
        </Row>
        <Row>
          <div>
            <Div display="flex">
              <FormItem name="logoInPayslip" valuePropName="checked">
                <CheckBoxComp
                  disabled={
                    organizationData?.logo ? false : true
                  }></CheckBoxComp>
              </FormItem>
              <div>
                <B type="b-large" pl="18px" pt="4px">
                  Company Logo to the pay-slip
                </B>
                <Div marginLeft="18px">
                  <B type="b-small" pb="16px" color={theme.gray600}>
                    Select if you want display company logo (upload the logo in
                    company details first)
                  </B>
                </Div>
              </div>
            </Div>
            <Div display="flex">
              <FormItem name="nicInPayslip" valuePropName="checked">
                <CheckBoxComp />
              </FormItem>
              <div>
                <B type="b-large" pl="18px" pt="4px">
                  NIC number to the pay-slip
                </B>
                <Div marginLeft="18px">
                  <B type="b-small" pb="16px" color={theme.gray600}>
                    Select if you want to include employee NIC number in the
                    payslip page
                  </B>
                </Div>
              </div>
            </Div>
            <Div display="flex">
              <FormItem name="bankDetailsInPayslip" valuePropName="checked">
                <CheckBoxComp />
              </FormItem>
              <div>
                <B type="b-large" pl="18px" pt="4px">
                  Bank details to the pay-slip
                </B>
                <Div ml="18px">
                  <B type="b-small" pb="16px" color={theme.gray600}>
                    Select if you want to include employee bank details in the
                    payslip page
                  </B>
                </Div>
              </div>
            </Div>
            <Div display="flex">
              <FormItem name="printedDetailsInPayslip" valuePropName="checked">
                <CheckBoxComp />
              </FormItem>
              <div>
                <B type="b-large" pl="18px" pt="4px">
                  Printed date and time to the pay-slip
                </B>
                <Div ml="18px">
                  <B type="b-small" pb="16px" color={theme.gray600}>
                    Select if you want to add printed date and time at the
                    bottom of the pay-slip page
                  </B>
                </Div>
              </div>
            </Div>
            <Div display="flex">
              <FormItem name="companySealInPayslip" valuePropName="checked">
                <CheckBoxComp
                  disabled={organizationData?.seal ? false : sealDisabled}
                />
              </FormItem>
              <Div marginBottom="20px">
                <B type="b-large" pl="18px" pt="4px">
                  Company Seal
                </B>
                <Div marginLeft="18px">
                  <B type="b-small" pb="16px" color={theme.gray600}>
                    Select if you want to add printed seal on the pay-slip page
                  </B>
                </Div>
                <CompanySeal
                  onChangeImageUpload={(url) => {
                    setSealUrl(url);
                    setSealDisabled(false);
                  }}
                />
              </Div>
            </Div>
            <Div display="flex">
              <FormItem
                name="companyRegistrationNumberInPayslip"
                valuePropName="checked">
                <CheckBoxComp
                  disabled={!organizationData?.registrationNumber}
                />
              </FormItem>
              <div>
                <B type="b-large" pl="18px" pt="4px">
                  Company Registration number on pay slip
                </B>
                <Div marginLeft="18px">
                  <B type="b-small" pb="16px" color={theme.gray600}>
                    Select if you want to add company registration on the
                    pay-slip page
                  </B>
                </Div>
              </div>
            </Div>
            {orgBasedFeatureConfig.exportConfig.ytdEnabledCompanies.includes(
              organizationData?.id,
            ) && (
              <Div display="flex">
                <FormItem name="isYearToDateInPayslip" valuePropName="checked">
                  <CheckBoxComp />
                </FormItem>
                <div>
                  <B type="b-large" pl="18px" pt="4px">
                    Add cumulative salary for year to date
                  </B>
                  <Div marginLeft="18px">
                    <B type="b-small" pb="16px" color={theme.gray600}>
                      Select if you want to add cumulative salary on the
                      pay-slip page
                    </B>
                  </Div>
                </div>
              </Div>
            )}
          </div>
        </Row>
      </MainCard>
      <Divider />
    </>
  );
};

export default PayslipSetting;
