import React from 'react';
import { FC } from 'react';
import { css } from '@emotion/react';
import { LinkButton } from '.';
import { Tooltip } from 'antd';
import styled from '@emotion/styled';
import { ExpandSVG, ShrinkSVG } from '../assets';
interface Props {
  fullScreen: boolean;
  openFullScreen: () => void;
  exitFullscreen: () => void;
  isDisplayTitle?: boolean;
  size?: 'small' | 'large';
}
export const fullScreenCss = css`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 312102;
  background: white;
  position: fixed;
`;

const StyledImg = styled.img`
  margin-top: 3px;
`;
export const FullScreen: FC<Props> = ({
  fullScreen,
  openFullScreen,
  exitFullscreen,
  isDisplayTitle = true,
  size = 'large',
}) => {
  if (fullScreen) {
    return (
      <>
        <LinkButton
          onClick={() => exitFullscreen()}
          icon={<img src={ShrinkSVG} />}>
          Exit Full Screen
        </LinkButton>
      </>
    );
  }
  return (
    <Tooltip title="View as Full Screen Mode">
      <LinkButton
        size={size}
        onClick={() => openFullScreen()}
        icon={<StyledImg src={ExpandSVG} />}>
        {isDisplayTitle && 'Full Screen'}
      </LinkButton>
    </Tooltip>
  );
};
