import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { AutoComplete, Col, Form, Input, Row, message } from 'antd';
import Dropzone from 'react-dropzone';
import styled from '@emotion/styled';
import { v4 as uuidv4 } from 'uuid';
import {
  AlertMessage,
  B,
  Div,
  InputComp,
  ModalComponent,
  PrimaryButton,
  SelectComp,
  SwitchComponent,
} from '../../../components';
import theme from '../../../theme';
import {
  ContractErrorEnum,
  ContractTypeEnum,
  SignatoryEnum,
} from '../../../types/contract.types';
import { UploadDocumentSVG } from '../../../assets';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import { debounce, isEmpty } from 'lodash';
import {
  useGetTemplateById,
  useGetTemplates,
  useUserSearch,
} from '../../../api/contractHooks';
import pdfPlaceHolderImage from '../../../assets/images/pdf-place-holder.png';
import { AxiosError } from 'axios';

interface Props {
  visible: boolean;
  handleCancel: () => void;
  //TODO have to put type here
  onSubmit: (values: any) => void;
  uploadError: AxiosError<any, any>;
  isLoading: boolean;
}

interface FileWithPreviewI extends File {
  preview: string;
}

const CustomRow = styled(Row)`
  background: ${theme.gray150};
  border: 1px dashed ${theme.blue500};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 55px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;
const FormContainer = styled(Form)`
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: none;
  }
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-input[disabled] {
    color: ${theme.black};
  }
  .input-delete-icon {
    color: ${theme.gray600};
  }
  .ant-upload-span {
    margin-top: 16px;
    .ant-input-affix-wrapper {
      border-radius: 4px;
    }
  }
  button {
    margin-bottom: 5px;
  }
`;
const CheckBox = styled(CheckBoxComp)`
  .ant-checkbox + span {
    font-family: Inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;
const SignatoryCard = styled.div`
  background: ${theme.gray50};
  padding: 16px 16px 0px;
`;

const AutoCompleteInput = styled(AutoComplete)`
  height: 40px;
  border: 1px solid ${theme.gray300};
  border-radius: 4px;
  background-color: ${theme.white};

  &:focus {
    border: 1px solid ${theme.blue500};
    box-shadow: none;
  }
  &:hover {
    border: 1px solid ${theme.blue500};
  }
`;

const RedAsterisk = styled.span`
  color: red;
`;
const requiredLabel = (labelName: string) => {
  return (
    <span>
      <RedAsterisk>*</RedAsterisk> {labelName}
    </span>
  );
};

const CreateNewContractModal: FC<Props> = ({
  visible,
  handleCancel,
  onSubmit,
  uploadError,
  isLoading,
}) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [isFile, setIsFile] = useState(false);
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const [isSaveTemplate, setSaveTemplate] = useState<boolean>(false);
  const [templateUrl, setTemplateUrl] = useState<string>('');
  const [templateId, setTemplateId] = useState<string>();
  const [fileInfo, setFileInfo] = useState<{
    file: FileWithPreviewI | null;
    fileName: string | null;
  }>({
    file: null,
    fileName: null,
  });

  interface ContractError {
    error: string;
    path?: string;
    type: ContractErrorEnum;
    signatoryType?: string;
  }

  const [contractError, setContractError] = useState<ContractError>();

  useEffect(() => {
    if (uploadError?.response?.data) {
      const errorData = uploadError.response.data;

      if (errorData.type === ContractErrorEnum.DUPLICATE_EMAIL) {
        setContractError({ error: errorData.error, type: errorData.type });
      } else if (
        errorData.type === ContractErrorEnum.INCOMPLETE_USER_INFO ||
        errorData.type === ContractErrorEnum.INCORRECT_USER_INFO
      ) {
        setContractError({
          error: errorData.error,
          type: errorData.type,
          path: errorData.path,
          signatoryType: errorData.signatoryType,
        });
      }
    }
  }, [uploadError]);

  const onFinish = async (formValues: any) => {
    try {
      await form.validateFields();
      const firstSignatories = [
        {
          id: firstSignatoryId ? firstSignatoryId : uuidv4(),
          firstName: formValues.firstSignatoryFirstName,
          lastName: formValues.firstSignatoryLastName,
          email: formValues.firstSignatoryEmail,
          type: firstSignatoryId
            ? ContractTypeEnum.SYSTEM_USER_CONTRACT
            : ContractTypeEnum.NON_SYSTEM_USER_CONTRACT,
        },
      ];

      let secondSignatories = [];
      if (
        formValues.secondSignatoryFirstName &&
        formValues.secondSignatoryLastName &&
        formValues.secondSignatoryEmail
      ) {
        secondSignatories = [
          {
            id: secondSignatoryId ? secondSignatoryId : uuidv4(),
            firstName: formValues.secondSignatoryFirstName,
            lastName: formValues.secondSignatoryLastName,
            email: formValues.secondSignatoryEmail,
            type: secondSignatoryId
              ? ContractTypeEnum.SYSTEM_USER_CONTRACT
              : ContractTypeEnum.NON_SYSTEM_USER_CONTRACT,
          },
        ];
      }

      const values = {
        fileName: fileInfo.fileName,
        file: fileInfo.file,
        firstSignatories: JSON.stringify(firstSignatories),
        secondSignatories:
          secondSignatories.length > 0 ? JSON.stringify(secondSignatories) : [],
        isSaveTemplate,
        templateId: isUpload ? null : templateId, //
        title: formValues.title,
      };
      setContractError(null);
      onSubmit(values);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ fileName: fileInfo.fileName });
  }, [fileInfo.fileName]);

  const uploadValidator = (file) => {
    const isLt10M = file.size / 1024 / 1024 > 10;
    if (isLt10M) {
      message.error('File must smaller than 10MB!');
      return {
        code: 'name-too-large',
        message: `Name is larger than 1 characters`,
      };
    }
    return null;
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      file.preview = URL.createObjectURL(file);
      const fileName = file.name.slice(0, file.name.lastIndexOf('.'));
      setFileInfo({ fileName, file });
      setIsFile(true);
    }
  };

  const [query, setQuery] = useState<string>('');
  const [firstSignatoryId, setFirstSignatoryId] = useState<number>();
  const [secondSignatoryId, setSecondSignatoryId] = useState<number>();

  const [options, setOptions] = useState([]);

  const {
    data: templates,
    refetch: getTemplates,
    isLoading: templatesLoading,
    error: errorTemplate,
  } = useGetTemplates();
  const allTemplates = templates?.map((item) => {
    return {
      value: item?.id,
      label: item?.title,
    };
  });

  const {
    isFetching: isFetchingResults,
    data: searchResults,
    refetch: refetchSearchResults,
  } = useUserSearch(query);

  useEffect(() => {
    getTemplates();
    setContractError(null);
  }, []);

  const searchValueFirsSignatory = (text: string) => {
    setQuery(text);
  };

  const searchValueSecondSignatory = (text: string) => {
    setQuery(text);
  };

  useEffect(() => {
    if (!query) setOptions([]);
    refetchSearchResults();
  }, [query]);

  useEffect(() => {
    if (!isEmpty(searchResults)) {
      const option = searchResults.map((option) => ({
        value: option.firstName,
        label: (
          <div>
            <B type="b-default">{option.firstName}</B>
            <B type="b-small" color={theme.gray600} marginTop="10px">
              {option.loginEmail}
            </B>
          </div>
        ),
      }));

      setOptions(option);
    }
  }, [searchResults]);

  const onSelect = (data, signatory: SignatoryEnum) => {
    const user = searchResults?.find((result) => result.firstName === data);
    if (signatory === SignatoryEnum.FIRST) {
      if (user?.id) {
        setFirstSignatoryId(user?.id);
      }
      form.setFieldsValue({
        firstSignatoryLastName: user?.lastName,
        firstSignatoryEmail: user?.loginEmail,
        firstSignatoryId: user?.id ? user?.id : null,
      });
    } else {
      if (user?.id) {
        setSecondSignatoryId(user?.id);
      }
      form.setFieldsValue({
        secondSignatoryLastName: user?.lastName,
        secondSignatoryEmail: user?.loginEmail,
        secondSignatoryId: user?.id ? user?.id : null,
      });
    }
  };
  const CustomAlertMessage = styled(AlertMessage)`
    margin-top: 10px;
    padding-top: 100px;
  `;

  const {
    data: templateByIdData,
    mutate: getTemplateById,
    isLoading: templateLoading,
  } = useGetTemplateById();

  const onSelectTemplate = (id: string) => {
    if (id) getTemplateById(id);
  };

  const handleAutoCompleteClick = () => {
    setOptions([]);
  };

  useEffect(() => {
    const templateId = templateByIdData?.id;
    if (templateId) {
      setTemplateId(templateId?.toString());
    }
  }, [templateByIdData]);

  return (
    <ModalComponent
      footer={
        <PrimaryButton
          disabled={isLoading}
          onClick={() => onFinish(form.getFieldsValue())}
          htmlType="submit"
          block>
          {isLoading ? 'Creating...' : 'Create'}
        </PrimaryButton>
      }
      width={540}
      form={form}
      hideCancelButton={true}
      visible={visible}
      title={
        <div
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => setDisabled(true)}>
          <B type="b-large-semibold">Create New Contract</B>
        </div>
      }
      onCancel={handleCancel}>
      <FormContainer form={form} validateTrigger="onChange" layout="vertical">
        <B type="b-default-semibold" color={theme.blue800} mb="8px">
          First Signatory
        </B>
        <SignatoryCard>
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="firstSignatoryFirstName"
                label={requiredLabel('First Name')}
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: 'Please enter first name!',
                  },
                ]}>
                <AutoCompleteInput
                  aria-required={true}
                  variant="borderless"
                  options={options}
                  onSelect={(data) => onSelect(data, SignatoryEnum.FIRST)}
                  onSearch={debounce(searchValueFirsSignatory, 300)}
                  onClick={handleAutoCompleteClick}
                  placeholder="Enter first name"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="firstSignatoryLastName"
                label={requiredLabel('Last Name')}
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: 'Please enter last name!',
                  },
                ]}>
                <InputComp
                  size="large"
                  style={{ borderRadius: '4px' }}
                  placeholder="Enter last name"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="firstSignatoryEmail"
                label={requiredLabel('Email')}
                validateFirst={true}
                help={
                  contractError &&
                  (contractError.type ===
                    ContractErrorEnum.INCOMPLETE_USER_INFO ||
                  contractError.type ===
                    ContractErrorEnum.INCORRECT_USER_INFO ? (
                    <>
                      {contractError.signatoryType === SignatoryEnum.FIRST ? (
                        <span>
                          {contractError.error}
                          {contractError.path ? (
                            <a href={contractError.path}>here </a>
                          ) : (
                            ''
                          )}
                        </span>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    contractError.error
                  ))
                }
                validateStatus={
                  contractError &&
                  (contractError.type ===
                    ContractErrorEnum.INCOMPLETE_USER_INFO ||
                  contractError.type === ContractErrorEnum.INCORRECT_USER_INFO
                    ? contractError.signatoryType === SignatoryEnum.FIRST
                      ? 'error'
                      : ''
                    : 'error')
                }
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid email address',
                  },
                  {
                    required: true,
                    message: 'Please enter your email!',
                  },
                ]}>
                <InputComp
                  size="large"
                  style={{ borderRadius: '4px' }}
                  placeholder="Enter email Id"
                  rows={4}
                />
              </Form.Item>
            </Col>
          </Row>
        </SignatoryCard>
        {form.getFieldValue('firstSignatoryEmail') &&
          !form.getFieldValue('secondSignatoryEmail') &&
          !form.getFieldValue('secondSignatoryFirstName') &&
          !form.getFieldValue('secondSignatoryLastName') && (
            <Div mt="12px">
              <AlertMessage
                type="info"
                title="Please note that this contract has only one signatory"
              />
            </Div>
          )}

        <Div display="flex" justifyContent="space-between" mt="24px">
          <Div m="auto 0">
            <B type="b-default-semibold" color={theme.blue800} mb="8px">
              Second Signatory (Optional)
            </B>
          </Div>
        </Div>
        <SignatoryCard>
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="secondSignatoryFirstName"
                label="First Name"
                validateFirst={true}>
                <AutoCompleteInput
                  variant="borderless"
                  options={options}
                  onSelect={(data) => onSelect(data, SignatoryEnum.SECOND)}
                  onSearch={debounce(searchValueSecondSignatory, 300)}
                  onClick={handleAutoCompleteClick}
                  placeholder="Enter first name"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="secondSignatoryLastName"
                label="Last Name"
                validateFirst={true}>
                <InputComp
                  size="large"
                  style={{ borderRadius: '4px' }}
                  placeholder="Enter last name"
                  rows={4}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                name="secondSignatoryEmail"
                label="Email"
                validateFirst={true}
                help={
                  contractError &&
                  (contractError.type ===
                    ContractErrorEnum.INCOMPLETE_USER_INFO ||
                  contractError.type ===
                    ContractErrorEnum.INCORRECT_USER_INFO ? (
                    <>
                      {contractError.signatoryType === SignatoryEnum.SECOND ? (
                        <span>
                          {contractError.error}
                          {contractError.path ? (
                            <a href={contractError.path}>here </a>
                          ) : (
                            ''
                          )}
                        </span>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    contractError.error
                  ))
                }
                validateStatus={
                  contractError &&
                  (contractError.type ===
                    ContractErrorEnum.INCOMPLETE_USER_INFO ||
                  contractError.type === ContractErrorEnum.INCORRECT_USER_INFO
                    ? contractError.signatoryType === SignatoryEnum.SECOND
                      ? 'error'
                      : ''
                    : 'error')
                }>
                <InputComp
                  size="large"
                  style={{ borderRadius: '4px' }}
                  placeholder="Enter email Id"
                  rows={4}
                />
              </Form.Item>
            </Col>
          </Row>
        </SignatoryCard>
        <Div display="flex" mt="24px">
          <Form.Item name="isLeaveInPayroll" valuePropName="checked">
            <SwitchComponent
              onChange={(checked: boolean) => setIsUpload(checked)}
            />
          </Form.Item>
          <Div display="flex" flexDirection="column">
            <B
              pl="18px"
              pt="4px"
              type="b-default-semibold"
              color={theme.blue800}
              mb="8px">
              Upload New Contract
            </B>
          </Div>
        </Div>
        {isUpload ? (
          <>
            <Form.Item
              name="title"
              label={requiredLabel('Title of Contract')}
              rules={[
                {
                  required: true,
                  message: 'Please enter last name!',
                },
              ]}>
              <InputComp
                size="large"
                style={{ borderRadius: '4px' }}
                placeholder="Enter title"
              />
            </Form.Item>
            <Form.Item>
              <Dropzone
                onDrop={handleDrop}
                accept=".pdf"
                minSize={1024}
                maxSize={10240000}
                validator={uploadValidator}>
                {({
                  getRootProps,
                  getInputProps,
                  isDragAccept,
                  isDragReject,
                }) => {
                  const additionalClass = isDragAccept
                    ? 'accept'
                    : isDragReject
                    ? 'reject'
                    : '';

                  return (
                    <CustomRow
                      justify="space-around"
                      align="middle"
                      {...getRootProps({
                        className: `dropzone ${additionalClass}`,
                      })}>
                      {fileInfo.file ? (
                        <div>
                          <input {...getInputProps()} />
                          <img
                            style={{ maxWidth: '370px', maxHeight: '160px' }}
                            src={pdfPlaceHolderImage}
                            onLoad={() => {
                              URL.revokeObjectURL(fileInfo.file.preview);
                            }}
                          />
                        </div>
                      ) : (
                        <>
                          <input {...getInputProps()} />
                          <div className="ant-upload-drag-icon">
                            <img src={UploadDocumentSVG} />
                          </div>
                          <B
                            type="b-large-semibold"
                            color={theme.gray600}
                            pt="8px"
                            display="flex">
                            Drag and drop or
                            <B
                              type="b-large-semibold"
                              color={theme.blue400}
                              pl="5px">
                              Browse
                            </B>
                          </B>
                          <B type="b-default" color={theme.gray600} pt="4px">
                            Upload your contract document in pdf
                          </B>
                          <B type="b-small" color={theme.gray600} pt="4px">
                            Maximum file size: 10 MB
                          </B>
                        </>
                      )}
                    </CustomRow>
                  );
                }}
              </Dropzone>
            </Form.Item>
            <FormItem>
              <CheckBox onChange={(v) => setSaveTemplate(v.target.checked)}>
                Save as Template
              </CheckBox>
            </FormItem>
          </>
        ) : (
          <Div borderTop={`1px solid  ${theme.gray300}`} pt="32px" mb="0px">
            <Row gutter={[12, 12]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <FormItem
                  name="templates"
                  label={requiredLabel('Select Contract Templates')}
                  validateFirst={true}
                  rules={[
                    {
                      required: true,
                      message: 'Please select contract templates!',
                    },
                  ]}>
                  <SelectComp
                    size="middle"
                    onSelect={onSelectTemplate}
                    showSearch
                    placeholder="Select contract templates"
                    optionFilterProp="label"
                    options={allTemplates}
                  />
                </FormItem>
              </Col>
            </Row>
          </Div>
        )}
      </FormContainer>
    </ModalComponent>
  );
};
export default CreateNewContractModal;
