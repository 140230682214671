import React, { FC, useEffect, useState } from 'react';

import { Divider, Form, message, Result, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import styled from '@emotion/styled';

import { useResetPassword } from '../../api/authHooks';
import { B, H, InputPasswordComp, PrimaryButton } from '../../components';
import { resetLinkExpire } from '../../store/actions';
import { DispatchType, RootState } from '../../store/reducer';
import theme from '../../theme';
import { checkPasswordStrengthWarningMsg } from '../../util/utils';
import HumanisedFooter from './shared/HumanisedFooter';
import HumanisedHeader from './shared/HumanisedHeader';
import LinkExpired from './shared/LinkExpired';
import { Link } from 'react-router-dom';

type forgotParams = {
  token: string;
};

const DividerContainer = styled(Divider)`
  margin: 24px 0 18px;
  .ant-divider {
    border-top: 1px solid ${theme.gray300};
  }
`;
const FormContainer = styled(Form)`
  max-width: 420px;
  max-height: 700px;
  padding: 40px 24px 33px;
  margin: 0 auto;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  @media (min-width: 768px) and (max-width: 1920px) {
    width: 420px;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
    @media (min-width: 768px) and (max-width: 1920px) {
      width: 420px;
    }
  }
`;
const RestPasswordContainer = styled.div`
  height: 87vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-height: 620px) {
    align-items: normal;
    height: auto;
  }
`;

const RestPasswordFormDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 0px 50px 0px;
  background: ${theme.white};
  border: 1px solid ${theme.gray200};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.0501803);
  border-radius: 4px;
  @media (max-width: 450px) {
    background: transparent;
    border: none;
    box-shadow: none;
  }
  @media screen and (max-height: 620px) {
    margin: 40px 0px 25px 0px;
  }
`;
const BackgroundContainer = styled.div`
  background: ${theme.gray50};
`;

const BackToSignContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RestPassword: FC = () => {
  const params = useParams<forgotParams>();
  const [inputValue, setInputValue] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const dispatch: DispatchType = useDispatch();
  const navigate = useNavigate();

  const isLinkExpire = useSelector(
    (state: RootState) => state.auth.resetLinkData,
  );
  const {
    isLoading: loadingResetPassword,
    error: errorResetPassword,
    data: dataResetPassword,
    mutate: resetPassword,
  } = useResetPassword();

  useEffect(() => {
    if (params.token) {
      dispatch(resetLinkExpire(params.token));
    }
  }, [params.token]);

  async function handleSubmit() {
    const resetData = {
      password: password,
      resetToken: params.token,
    };

    if (isLinkExpire) {
      message.error('Password reset link has expired');
    } else {
      await resetPassword(resetData);
    }
  }
  useEffect(() => {
    if (dataResetPassword && !errorResetPassword) {
      navigate('/login', {
        state: { message: 'Password Change Successfully!' },
      });
    }
  }, [dataResetPassword]);
  useEffect(() => {
    if (errorResetPassword) {
      message.error(errorResetPassword.response?.data.message);
    }
  }, [errorResetPassword]);
  return (
    <>
      <BackgroundContainer>
        <HumanisedHeader />
        <RestPasswordContainer>
          <RestPasswordFormDiv>
            {params.token ? (
              <>
                {!isLinkExpire ? (
                  <FormContainer
                    name="from"
                    onFinish={handleSubmit}
                    layout="vertical">
                    <H type="h6">Create Password</H>
                    <B
                      type="b-default"
                      color={theme.gray600}
                      pt="4px"
                      pb="24px">
                      Please enter a new password for your account.
                    </B>
                    <Form.Item
                      name="newPassword"
                      label="New Password"
                      rules={[
                        () => ({
                          validator(_, value, callback) {
                            try {
                              checkPasswordStrengthWarningMsg(value);
                              callback();
                            } catch (error: any) {
                              if (error instanceof Error) {
                                callback(error.message);
                              }
                            }
                          },
                        }),
                      ]}>
                      <InputPasswordComp
                        type="password"
                        size="large"
                        name="password"
                        value={password || ''}
                        placeholder="Enter New Password"
                        onChange={(
                          ev: React.ChangeEvent<HTMLInputElement>,
                        ): void => setPassword(ev.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      name="reEnterPassword"
                      dependencies={['newPassword']}
                      label="Confirm New Password"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Confirm Password',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value, callback) {
                            if (
                              !value ||
                              getFieldValue('newPassword') === value
                            ) {
                              return callback();
                            }
                            return callback(
                              'The confirm password that you entered do not match',
                            );
                          },
                        }),
                      ]}>
                      <InputPasswordComp
                        type="password"
                        size="large"
                        name="reEnterPassword"
                        placeholder="Enter Confirm Password"
                        value={inputValue}
                        onChange={(
                          ev: React.ChangeEvent<HTMLInputElement>,
                        ): void => setInputValue(ev.target.value)}
                      />
                    </Form.Item>
                    <Row>
                      <PrimaryButton
                        block
                        htmlType="submit"
                        loading={loadingResetPassword}>
                        Update
                      </PrimaryButton>
                      <DividerContainer />
                    </Row>
                    <BackToSignContainer>
                      <Link to="/login">
                        <B type="b-default" color={theme.blue500}>
                          Back to Sign In
                        </B>
                      </Link>
                    </BackToSignContainer>
                  </FormContainer>
                ) : (
                  <LinkExpired />
                )}
              </>
            ) : (
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
              />
            )}
          </RestPasswordFormDiv>
        </RestPasswordContainer>
        <HumanisedFooter />
      </BackgroundContainer>
    </>
  );
};

export default RestPassword;
