import React, { FC, useEffect, useState } from 'react';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import styled from '@emotion/styled';
import { useGetMyPayslips } from '../../../../api/peopleHooks';
import { B, TableComp } from '../../../../components';
import { DownloadBlack } from '../../../../assets';

const MainContainer = styled.div`
  padding: 24px 32px 8px;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const LastColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

export interface MyPayslipI {
  payrollName: string;
  payrollEndDate: string;
  payrollStartDate: string;
  payslipUrl: string;
}

const AccountPayslips: FC = () => {
  const [item, setItem] = useState<MyPayslipI[]>([]);
  const {
    isLoading: payslipListLoading,
    data: getPayslipList,
    mutate: getMyPayslips,
  } = useGetMyPayslips();

  useEffect(() => {
    getMyPayslips();
  }, []);

  useEffect(() => {
    if (getPayslipList) {
      setItem(getPayslipList);
    }
  }, [getPayslipList]);

  const columns: ColumnProps<MyPayslipI>[] = [
    {
      title: 'Month',
      dataIndex: 'payrollName',
      width: 150,
      render: (value) => <B type="b-small">{value ? value : '-'}</B>,
    },
    {
      title: 'Date Range',
      dataIndex: 'dateRange',
      render: (_value, data) => {
        const startDate = moment(data.payrollStartDate).isValid()
          ? moment(data.payrollStartDate).format('DD.MM.YYYY')
          : '-';
        const endDate = moment(data.payrollEndDate).isValid()
          ? moment(data.payrollEndDate).format('DD.MM.YYYY')
          : '-';
        return (
          <B type="b-small">
            {startDate} - {endDate}
          </B>
        );
      },
    },
    {
      title: '',
      fixed: 'right',
      width: 70,
      dataIndex: 'payslipUrl',
      render: (value: string) => (
        <>
          <LastColumn>
            <a href={value} download>
              <img src={DownloadBlack} />
            </a>
          </LastColumn>
        </>
      ),
    },
  ];

  return (
    <MainContainer>
      <B type="b-default-semibold" Pb="16px">
        Download Payslips
      </B>
      <TableComp
        size="middle"
        withBorders={true}
        columns={columns}
        dataSource={item}
        loading={payslipListLoading}
        pagination={false}
      />
    </MainContainer>
  );
};

export default AccountPayslips;
