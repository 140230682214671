import React, { FC, useEffect, useState } from 'react';
import { ConfigProvider, Dropdown, Menu, message, Spin, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CalendarOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import {
  B,
  DeleteModal,
  Div,
  EditModal,
  EmptyPage,
  Image,
  TableComp,
} from '../../../../components';
import { NameTag } from '../../../../components/Tags/NameTag';
import {
  BulkPaySlipDownloadEnum,
  deleteAllPayrollDetails,
  getAllPayrollDetails,
  getPayrollSummary,
  PayrunI,
  PreviousPayrollI,
  unarchivePayroll,
} from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import theme from '../../../../theme';
import { getCapitalize } from '../../../../util/getCapitalize.util';
import { getPathWithOrgId } from '../../../../util/getPathWithOrgId';
import { tableSorter } from '../../../../util/tableSorter';
import {
  addDotsForLongText,
  numberThousandSeparator,
} from '../../../../util/utils';
import PayRunModal from './shared/PayRunModal';
import { PayrunType } from '../../../../types/payroll.types';
import { getPayrollMonthAndYear } from '../../utils/getPayrollPeriod';
import {
  useAddNewPayRun,
  useEditCompletedPayrun,
  useUpdatePayrun,
} from '../../../../api/payrollHooks';
import { MoreSVG } from '../../../../assets';

const LastColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

export const MenuStyle = styled(Menu)`
  width: 175px;
  padding: 0px;
  border: 0.5px solid ${theme.gray300};
  border-bottom: none;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .ant-dropdown-menu-item > a {
    padding: 10.5px 16px;
    color: ${theme.black};
  }
  .ant-dropdown-menu-item :hover {
    background: ${theme.gray100};
  }
`;
export const MenuItem = styled(Menu.Item)`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.black};
  border-bottom: 1px solid ${theme.gray300};
`;
const DropdownComp = styled(Dropdown)`
  margin-left: 24px;
`;
interface Props {
  previousPayroll: PreviousPayrollI[];
  isCompleteSettings: boolean | null;
}
const PayRunTable: FC<Props> = ({ previousPayroll, isCompleteSettings }) => {
  const [payrollIdForReRun, setPayrollIdForReRun] = useState<null | string>(
    null,
  );
  const [payroll, setPayroll] = useState<PayrunI | null>(null);
  const [payrollId, setPayrollId] = useState<string | null>(null);
  const [visible, setVisible] = useState(false);
  const dispatch: DispatchType = useDispatch();
  const navigate = useNavigate();
  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );
  const {
    isLoading: loadingUpdatePayRun,
    data: dataUpdatePayRun,
    mutate: updatePayRun,
    error: errorUpdatePayRun,
  } = useUpdatePayrun();

  useEffect(() => {
    if (dataUpdatePayRun && !errorUpdatePayRun) {
      dispatch(getPayrollSummary());
    }
  }, [dataUpdatePayRun]);

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <CalendarOutlined style={{ fontSize: 40 }} />
      <p>No Payruns yet</p>
    </div>
  );
  const { data, mutate, error, isLoading } = useAddNewPayRun();

  useEffect(() => {
    if (data && !error) {
      setVisible(false);
      navigate('/Payroll-view/' + data.id);
    }
  }, [data]);

  const deletePayroll = (payrollId: string, status: string) => {
    if (status === 'COMPLETED') {
      const msg = 'Successfully archived the completed payrun';
      dispatch(deleteAllPayrollDetails(payrollId, msg));
    } else {
      dispatch(deleteAllPayrollDetails(payrollId));
    }
  };

  const {
    mutate: editCompletePayrun,
    data: dataEditCompletePayrun = null,
    error: errorEditCompletePayrun,
    isSuccess: isEditPayrollSuccess,
  } = useEditCompletedPayrun();

  const editPayroll = (payrollId: number) => {
    setPayrollId(payrollId?.toString());
    editCompletePayrun(payrollId);
  };

  useEffect(() => {
    if (isEditPayrollSuccess && payrollId) {
      dispatch(getAllPayrollDetails(payrollId));
      navigate('/Payroll-view/' + payrollId);
    }
  }, [isEditPayrollSuccess]);

  const columns: ColumnProps<PreviousPayrollI>[] = [
    {
      title: 'Payroll Name',
      dataIndex: 'name',
      width: 170,
      key: 'name',
      render: (_value: string, data) => (
        <B type="b-small-semibold">
          {data.name ? (
            <Tooltip placement="topLeft" title={data.name}>
              {data.name ? addDotsForLongText(data.name, 40) : '-'}
            </Tooltip>
          ) : (
            '-'
          )}
        </B>
      ),
    },
    {
      title: 'Month/Year',
      dataIndex: 'monthYear',
      width: 170,
      key: 'monthYear',
      render: (_value: string, data) => (
        <B type="b-small">
          {data?.month && data?.year
            ? data?.month + ' ' + data?.year
            : data.payrollStartDate && data.payrollEndDate
            ? getPayrollMonthAndYear(
                moment(data.payrollStartDate),
                moment(data.payrollEndDate),
              )
            : '-'}
        </B>
      ),
      sorter: (a, b) =>
        tableSorter.defaultSort(a.payrollEndDate, b.payrollEndDate),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Payrun Type',
      dataIndex: 'payrunType',
      width: 170,
      key: 'payrunType',
      render: (_value: string, data) => (
        <B type="b-small">
          {data?.payrunType
            ? data?.payrunType === PayrunType.MONTHLY_PAYRUN
              ? 'Monthly'
              : data?.payrunType === PayrunType.MID_CYCLE_PAYRUN
              ? 'Mid-Cycle'
              : '-'
            : '-'}
        </B>
      ),
    },
    {
      title: 'Cost to Company',
      dataIndex: 'payrollStartDate',
      width: 170,
      key: 'payrollStartDate',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.costToCompany
            ? numberThousandSeparator(data.costToCompany)
            : '-'}
        </B>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      width: 120,
      key: 'createdAt',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.createdAt ? moment(data.createdAt).format('DD/MM/YYYY') : '-'}
        </B>
      ),
    },
    {
      title: 'Status',
      width: 166,
      dataIndex: 'status',
      key: 'status',
      render: (_value: string, data) => (
        <Div display="flex" flexDirection="row">
          <B type="b-small">{data.status ? getCapitalize(data.status) : '-'}</B>
          {data.isArchived ? (
            <NameTag
              textColor="#205823"
              color="#E8F5E9"
              styleCSS={`
            width:65px ;
            border-radius: 4px;
            margin: 0px 0px 0px 4px;
          `}>
              Archived
            </NameTag>
          ) : null}
        </Div>
      ),
    },
    {
      title: '',
      dataIndex: 'options',
      width: 120,
      key: 'options',
      render: (_value: string, data: PreviousPayrollI) => (
        <>
          <LastColumn>
            <Link to={'/Payroll-view/' + data.id}>
              <B type="b-default" color={theme.blue500}>
                View
              </B>
            </Link>{' '}
            <DropdownComp
              overlay={menu(data)}
              placement="bottomRight"
              trigger={['click']}>
              <Image
                cursor="pointer"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                src={MoreSVG}
              />
            </DropdownComp>
          </LastColumn>
        </>
      ),
    },
  ];

  const menu = (data: PreviousPayrollI) => (
    <MenuStyle>
      {data.status == 'COMPLETED' && !data.isArchived ? (
        <>
          {data.bulkPayslipStatus ? (
            <MenuItem key="1" disabled={!data?.bulkPayslipUrl}>
              <Link to={`/Payroll-publish/${data.id}`}>
                <B padding="7px 0px 7px 4px" type="b-default">
                  View Summary
                </B>
              </Link>
            </MenuItem>
          ) : null}

          {data.bulkPayslipStatus ? (
            <MenuItem key="2" disabled={!data?.bulkPayslipUrl}>
              {data.bulkPayslipStatus === BulkPaySlipDownloadEnum.GENERATED ? (
                <Link to={data?.bulkPayslipUrl} download>
                  <B padding="7px 0px 7px 4px" type="b-default">
                    Download Zip File
                  </B>
                </Link>
              ) : data.bulkPayslipStatus === BulkPaySlipDownloadEnum.ERROR ? (
                <Tooltip title="Something went wrong while generating payslips">
                  Download Bulk File
                </Tooltip>
              ) : (
                <Tooltip title="Payslips are still generating.">
                  <B padding="7px 0px 7px 4px" type="b-default">
                    Download Bulk File
                  </B>
                </Tooltip>
              )}
            </MenuItem>
          ) : null}
          <MenuItem key="5">
            <DeleteModal
              title="Archive"
              buttonLabel="Archive"
              content="Are you sure you want to Archive?"
              onClickCancel={() => {
                deletePayroll(String(data.id), data.status);
              }}
              openModal={
                <B padding="7px 0px 7px 4px" type="b-default">
                  Archive Pay Run
                </B>
              }></DeleteModal>
          </MenuItem>

          <MenuItem key="6">
            <EditModal
              title="Edit Completed Payrun"
              buttonLabel="Edit Payrun"
              content="Are you sure you want to edit a completed payroll?"
              onClickCancel={() => {
                editPayroll(Number(data.id));
              }}
              openModal={
                <B padding="7px 0px 7px 4px" type="b-default">
                  Edit Pay Run
                </B>
              }></EditModal>
          </MenuItem>
        </>
      ) : (
        <>
          {!data.isEdited && (
            <MenuItem key="6">
              <DeleteModal
                title="Delete Payroll"
                buttonLabel="Yes, Delete"
                content="Are you sure you want to delete payroll?"
                onClickCancel={async () => {
                  await deletePayroll(String(data.id), data.status);
                  navigate(getPathWithOrgId('/payroll'));
                }}
                openModal={
                  <B padding="7px 0px 7px 4px" type="b-default">
                    Delete
                  </B>
                }></DeleteModal>
            </MenuItem>
          )}

          <MenuItem
            key="7"
            onClick={() => {
              setVisible(true);
              setPayroll({
                name: data.name,
                payrunType: data.payrunType,
                month: data.month,
                year: data.year,
                payrollStartDate: data.payrollStartDate,
                payrollEndDate: data.payrollEndDate,
                defaultRemark: data.name,
                currentExchangeRate: data.currentExchangeRate,
                peggedCurrency: data.peggedCurrency
                  ? data.peggedCurrency
                  : null,
              });
              setPayrollId(data.id);
            }}>
            <B padding="7px 0px 7px 4px" type="b-default">
              Edit
            </B>
          </MenuItem>
        </>
      )}
      {data.isArchived ? (
        <MenuItem
          key="3"
          onClick={() => dispatch(unarchivePayroll(String(data.id)))}>
          <B padding="7px 0px 7px 4px" type="b-default">
            Unarchive Pay Run
          </B>
        </MenuItem>
      ) : data?.currentExchangeRate ||
        organizationData?.peggedSettings?.pegged?.status ||
        data.payrunType === PayrunType.MID_CYCLE_PAYRUN ||
        organizationData?.prorataSettings?.prorata?.status ? (
        <></>
      ) : (
        <>
          <MenuItem
            key="4"
            onClick={() => {
              setVisible(true);
              setPayrollIdForReRun(data.id);
            }}
            disabled={!!isCompleteSettings}>
            <B padding="7px 0px 7px 4px" type="b-default">
              <Tooltip
                title={
                  !!isCompleteSettings
                    ? 'Please fill all mandatory details in company setting before processing payruns!'
                    : null
                }>
                Re-Run Payrun
              </Tooltip>
            </B>
          </MenuItem>
        </>
      )}
    </MenuStyle>
  );

  const payrollSummeryLoading = useSelector(
    (state: RootState) => state.payroll.payrollSummeryLoading,
  );

  const errorHandling = useSelector(
    (state: RootState) => state.payroll.errorHandling,
  );

  return (
    <>
      {errorHandling ? (
        <div>
          <EmptyPage
            content="Sorry, the page you visited does not exist."
            title="something went wrong !"
            buttonLabel=" Back Home"
          />
        </div>
      ) : (
        <>
          <Spin spinning={!previousPayroll}>
            <ConfigProvider renderEmpty={customizeRenderEmpty}>
              <div>
                <TableComp
                  size="large"
                  headerFontSize={14}
                  rowKey={(item) => {
                    return String(item.id);
                  }}
                  columns={columns}
                  onRow={() => {
                    return {
                      onClick: () => {
                        // navigate(`/Payroll-view/${record.id}`); // TODO: Because of this User cannot click 3 dot menu
                      },
                    };
                  }}
                  withBorders={true}
                  dataSource={previousPayroll ? previousPayroll : []}
                  pagination={false}
                  loading={payrollSummeryLoading || loadingUpdatePayRun}
                  scroll={{
                    y: window.innerHeight - 370,
                    x: 990,
                  }}
                />
              </div>
            </ConfigProvider>
          </Spin>
        </>
      )}

      {payrollIdForReRun !== null || visible ? (
        <PayRunModal
          buttonDisabled={isLoading}
          visible={visible}
          onClose={() => {
            setPayrollIdForReRun(null);
            setVisible(false);
          }}
          isReRun={payrollIdForReRun ? true : false}
          payRun={!payrollIdForReRun ? payroll : null}
          onSubmit={async (values) => {
            try {
              if (payrollIdForReRun !== null) {
                mutate({
                  ...values,
                  payrollId: payrollIdForReRun.toString(),
                });
              } else {
                if (payrollId) {
                  updatePayRun({ data: values, payrollId: String(payrollId) });
                  setVisible(false);
                }
              }
            } catch (error) {
              message.error('Something went wrong');
            }
            setPayrollIdForReRun(null);
          }}
        />
      ) : null}
    </>
  );
};

export default PayRunTable;
