import { Col, Form, Row } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React, { FC } from 'react';
import { InputComp, LabeledInputV2, SelectComp } from '../../../components';
import { PeopleI } from '../../../types/people.types';

interface Props {
  disableFields?: boolean;
  allPeoples?: PeopleI[];
  onChange?: ((value: SelectValue) => void) | undefined;
}

const SelectEmployee: FC<Props> = ({ disableFields, allPeoples, onChange }) => {
  const getUsersList = () => {
    if (allPeoples && allPeoples.length) {
      return allPeoples
        .sort((a, b) => a.fullName?.localeCompare(b.fullName))
        .filter((active) => !active.isArchived)
        .map((item) => ({
          label: `${item.fullName} ${
            item.employeeNumber ? `(${item.employeeNumber})` : ''
          }`,
          value: item.id,
        }));
    } else {
      return [];
    }
  };

  return (
    <div>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <LabeledInputV2 label="Employee Name*">
            <Form.Item
              name="userId"
              rules={[
                {
                  required: true,
                  message: 'Please select employee name',
                },
              ]}>
              <SelectComp
                size="middle"
                placeholder="Select Employee"
                disabled={disableFields}
                onChange={onChange}
                options={getUsersList()}
              />
            </Form.Item>
          </LabeledInputV2>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <LabeledInputV2 label="Employee Number">
            <Form.Item name="empNumber">
              <InputComp size="small" placeholder="Employee Number" disabled />
            </Form.Item>
          </LabeledInputV2>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <LabeledInputV2 label="Designation">
            <Form.Item name="designation">
              <InputComp size="small" placeholder="Designation" disabled />
            </Form.Item>
          </LabeledInputV2>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <LabeledInputV2 label="Net Salary">
            <Form.Item name="netSalary">
              <InputComp size="small" placeholder="Net Salary" disabled />
            </Form.Item>
          </LabeledInputV2>
        </Col>
      </Row>
    </div>
  );
};

export default SelectEmployee;
