import React, { FC } from 'react';
import { Divider, Dropdown, Menu, Spin, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  B,
  ConfirmationModal,
  EmptyTextContainer,
  Image,
  TableComp,
} from '../../../components';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import { RootState } from '../../../store/reducer';
import theme from '../../../theme';
import {
  ExpenseCreation,
  IExpense,
  IExpenseStatus,
  IExpenseUser,
  IntegrationStatusEnum,
  Permission,
  UpdateStatusExpenseI,
  UserRoleIdEnum,
} from '../../../types';
import { isAllowed } from '../../../util/permissionUtil';
import { numberThousandSeparator } from '../../../util/utils';
import { PriceTag } from './';
import { ExpenseStatusTag } from './ItemStatus';
import { ViewExpenseModal } from './ViewExpenseModal';
import { reviewAllowed } from '../../../util/reviewAllowed';
import { space, SpaceProps, layout, LayoutProps } from 'styled-system';
import {
  Attachment,
  BankWarningSVG,
  MoreSVG,
  PayrollCalenderSVG,
} from '../../../assets';
import { css } from '@emotion/react';

type Props = SpaceProps & LayoutProps;

export const APPROVED_STAGES = [
  IExpenseStatus.APPROVED,
  IExpenseStatus.PAID,
  IExpenseStatus.ARCHIVED,
];
const FINISHED_STAGES = [IExpenseStatus.PAID, IExpenseStatus.ARCHIVED];

// table styled containers
const TableLayout = styled.div`
  margin-top: 24px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const tableStyle = css`
  .ant-table-thead > tr > th {
    padding-left: 0px !important;
  }
  .ant-table-tbody > tr > td {
    padding-left: 0px !important;
  }
`;

const MenuItem = styled(Menu.Item)`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.black};
  border-bottom: 1px solid ${theme.gray300};
  p {
    margin: -12px -16px;
    padding: 12px 16px;
  }
`;
const StyledMenu = styled(Menu)`
  width: 176px;
  padding: 0px;
  border: 0.5px solid ${theme.gray300};
  border-bottom: none;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  .ant-dropdown-menu-item {
    padding: 12px 16px;
    color: ${theme.black};
  }
  .ant-dropdown-menu-item :hover {
    background: ${theme.gray100};
  }
`;
const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 12px;
`;
const ActionItem = styled.div<{ marginRight: number }>`
  margin-right: ${(props) => props.marginRight}px;
`;

// card styled containers
const CardLayout = styled.div`
  @media screen and (min-width: 769px) {
    display: none;
  }
  background-color: ${theme.gray50};
  display: flex;
  flex-direction: column;
  padding: 0px 16px 16px 16px;
  border-top: 1px solid ${theme.gray300};
`;
const Card = styled.div`
  background: ${theme.white};
  border: 1px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 16px;
  margin-top: 16px;
  box-shadow: 0 2px 10px -2px rgb(0 0 0 / 20%), 0 2px 15px rgb(0 0 0 / 0%);
`;
const EmptyContainer = styled.div`
  margin-top: 64px;
`;
const RowContainer = styled.div<Props>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  ${space}
  ${layout}
`;
const ActionRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const DateRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const StatusContainer = styled.div<{ admin: boolean }>`
  ${(props) => props.admin && 'margin-left: 25px;'}
`;
const StyledDivider = styled(Divider)`
  margin: 8px 0px 14px 0px;
`;

const Text = styled(B)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Label = styled.label`
  margin-left: 24px;
`;

interface TablePropsI {
  creationType: ExpenseCreation;
  integrationStatus: IntegrationStatusEnum;
  checkBoxAction: (selected: number[]) => void;
  deleteAction: (id: number) => void;
  updateStatusAction: (data: UpdateStatusExpenseI) => void;
  dataSetLoading: boolean;
  deleteActionLoading: boolean;
  updateStatusActionLoading: boolean;
  checkBoxSelectedList: number[];
  dataSet: IExpense[];
}

export const ItemList: FC<TablePropsI> = (props) => {
  const {
    creationType,
    integrationStatus,
    checkBoxAction,
    deleteAction,
    updateStatusAction,
    checkBoxSelectedList,
    dataSet = [],
    dataSetLoading = false,
    deleteActionLoading = false,
    updateStatusActionLoading = false,
  } = props;
  const history = useNavigate();
  const me = useSelector((state: RootState) => state.people.me);

  const expenseAdvancedPermission: boolean = isAllowed(
    Permission.MANAGE_EXPENSE_STATUS_ADVANCED,
  );
  const isAllowedExpenseSettings: boolean = isAllowed(
    Permission.MANAGE_EXPENSE_SETTINGS,
  );

  const isAllowedExpenseBasic =
    !!me?.isManager || expenseAdvancedPermission || isAllowedExpenseSettings;

  const allLoading =
    dataSetLoading || deleteActionLoading || updateStatusActionLoading;

  const usersWithoutBank = dataSet.filter((i) => !i.owner.bank);
  const approvedUsersWithBank = dataSet.filter(
    (i) => i.status === IExpenseStatus.APPROVED && i.owner.bank,
  );

  const locale = {
    emptyText: (
      <EmptyTextContainer description="You don't have any expense requested yet" />
    ),
  };

  const menu = (item: IExpense) => {
    const basicAllowed: boolean =
      me?.roleId === UserRoleIdEnum.OWNER || item.creatorId !== me?.id;
    const isReviewAllowed = reviewAllowed({
      reviewerId: me?.id,
      userId: item.userId,
    });
    return (
      <StyledMenu key={item.id}>
        {isAllowedExpenseBasic &&
          isReviewAllowed &&
          basicAllowed && [
            [IExpenseStatus.PENDING, IExpenseStatus.APPROVED].includes(
              item.status as IExpenseStatus,
            ) && (
              <MenuItem
                onClick={() => {
                  updateStatusAction({
                    id: item.id,
                    status: IExpenseStatus.REJECTED,
                  });
                }}>
                Reject
              </MenuItem>
            ),
            item.status === IExpenseStatus.APPROVED &&
              expenseAdvancedPermission &&
              basicAllowed && (
                <MenuItem
                  onClick={() => {
                    updateStatusAction({
                      id: item.id,
                      status: IExpenseStatus.PAID,
                    });
                  }}>
                  Mark As Paid
                </MenuItem>
              ),
            item.status === IExpenseStatus.REJECTED &&
              expenseAdvancedPermission &&
              basicAllowed && (
                <MenuItem>
                  <ConfirmationModal
                    title={<B type="b-large-semibold">Confirmation Alert</B>}
                    content={
                      <B type="b-large">
                        Are you sure you want to archive this reimbursement
                        entry?
                      </B>
                    }
                    okText="Yes, Archive"
                    cancelText="No"
                    onConfirm={() => {
                      updateStatusAction({
                        id: item.id,
                        status: IExpenseStatus.ARCHIVED,
                      });
                    }}
                    trigger={<B type="b-large">Archive</B>}
                  />
                </MenuItem>
              ),
          ]}
        {creationType === item.creationType &&
          item.status === IExpenseStatus.PENDING &&
          (item.creatorId === me?.id || item.userId === me?.id) && (
            <MenuItem onClick={() => history(`/expense/update/${item.id}`)}>
              Edit Expense
            </MenuItem>
          )}
        {item.status === IExpenseStatus.PENDING &&
          me?.id === item.creatorId && (
            <MenuItem>
              <ConfirmationModal
                title={<B type="b-large-semibold">Confirmation Alert</B>}
                content={
                  <B type="b-large">
                    Are you sure you want to delete this reimbursement entry?
                  </B>
                }
                okText="Yes, Delete"
                cancelText="No"
                onConfirm={() => {
                  deleteAction(item.id);
                }}
                trigger={<B type="b-large">Delete</B>}
              />
            </MenuItem>
          )}
      </StyledMenu>
    );
  };

  const columns: ColumnProps<any>[] = [
    isAllowedExpenseSettings
      ? {
          title: Boolean(
            approvedUsersWithBank && approvedUsersWithBank.length,
          ) && (
            <CheckBoxComp
              checked={Boolean(
                checkBoxSelectedList && checkBoxSelectedList.length,
              )}
              onChange={(e) => {
                if (e.target.checked) {
                  const selectAllApproved = dataSet
                    .filter(
                      (i) =>
                        i.status === IExpenseStatus.APPROVED && i.owner.bank,
                    )
                    .map((i) => i.id);
                  checkBoxAction(selectAllApproved);
                } else {
                  checkBoxAction([]);
                }
              }}
            />
          ),
          dataIndex: 'status',
          align: 'center',
          width: 50,
          render: (status: IExpenseStatus, data: IExpense) => {
            let tooltip = false,
              tooltipMessage: string | null = null;
            if (!data.owner.bank) {
              tooltip = true;
              tooltipMessage = 'Bank details are not added';
            }
            if (status !== IExpenseStatus.APPROVED) {
              tooltip = true;
              tooltipMessage = 'Only approved expenses can be checked';
            }

            const checkBox = () => {
              return (
                <CheckBoxComp
                  checked={Boolean(
                    checkBoxSelectedList.find((x) => {
                      return x === data.id;
                    }),
                  )}
                  disabled={
                    !(IExpenseStatus.APPROVED === status && data.owner.bank)
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      checkBoxAction([...checkBoxSelectedList, data.id]);
                    } else {
                      const result = checkBoxSelectedList.filter((x) => {
                        return x !== data.id;
                      });
                      checkBoxAction(result);
                    }
                  }}
                />
              );
            };

            return tooltip ? (
              <Tooltip
                placement="right"
                title={tooltipMessage}
                color={theme.blue900}
                key={theme.blue900}>
                {checkBox()}
              </Tooltip>
            ) : (
              checkBox()
            );
          },
        }
      : {},
    {
      title: isAllowedExpenseSettings ? 'Date' : <Label>Date</Label>,
      dataIndex: 'date',
      width: 80,
      render: (date: string) => (
        <B
          type="b-small"
          marginLeft={isAllowedExpenseSettings ? '0px' : '24px'}>
          {dayjs(date).format('DD MMM YYYY')}
        </B>
      ),
    },
    isAllowedExpenseBasic
      ? {
          title: 'EMP ID',
          dataIndex: 'owner',
          width: 50,
          render: (owner: IExpenseUser) => (
            <Text type="b-small">
              <Tooltip
                placement="topLeft"
                title={owner.employeeNumber}
                color={theme.blue900}
                key={theme.blue900}>
                {owner.employeeNumber}
              </Tooltip>
            </Text>
          ),
        }
      : {
          title: 'Ref. No',
          dataIndex: 'reference',
          width: 60,
          render: (reference: string) => (
            <Text type="b-small">
              <Tooltip
                placement="topLeft"
                title={reference}
                color={theme.blue900}
                key={theme.blue900}>
                {reference}
              </Tooltip>
            </Text>
          ),
        },
    isAllowedExpenseBasic &&
    Boolean(usersWithoutBank && usersWithoutBank.length)
      ? {
          title: '',
          dataIndex: 'owner',
          width: 15,
          align: 'left',
          render: (owner: IExpenseUser) =>
            !owner.bank && (
              <Tooltip
                placement="top"
                title="Bank details are not added"
                color={theme.blue900}
                key={theme.blue900}>
                <Image
                  cursor="pointer"
                  marginBottom="3px"
                  width="13px"
                  height="13px"
                  src={BankWarningSVG}
                  draggable={false}
                />
              </Tooltip>
            ),
        }
      : {},
    isAllowedExpenseBasic
      ? {
          title: 'User',
          dataIndex: 'owner',
          width: 100,
          render: (owner: IExpenseUser) => (
            <Text type="b-small-medium">
              <Tooltip
                placement="topLeft"
                title={owner.fullName}
                color={theme.blue900}
                key={theme.blue900}>
                {owner.fullName}
              </Tooltip>
            </Text>
          ),
        }
      : {},
    isAllowedExpenseBasic
      ? {}
      : {
          title: 'Merchant',
          dataIndex: 'merchant',
          width: 100,
          render: (merchant: string) => (
            <Text type="b-small">
              <Tooltip
                placement="top"
                title={merchant}
                color={theme.blue900}
                key={theme.blue900}>
                {merchant ? merchant : '-'}
              </Tooltip>
            </Text>
          ),
        },

    {
      title: 'Price (LKR)',
      dataIndex: 'amount',
      width: 80,
      render: (amount: number) => (
        <B type="b-small">{numberThousandSeparator(amount)}</B>
      ),
    },
    {
      title: 'Note',
      dataIndex: 'note',
      width: isAllowedExpenseBasic ? 260 : 300,
      render: (note: string) => (
        <Text type="b-small">
          <Tooltip
            placement="topLeft"
            title={note}
            color={theme.blue900}
            key={theme.blue900}>
            {note ? note : '-'}
          </Tooltip>
        </Text>
      ),
    },
    {
      title: (
        <StatusContainer admin={isAllowedExpenseBasic}>Status</StatusContainer>
      ),
      dataIndex: 'status',
      width: 80,
      align: 'left',
      fixed: 'right',
      filters: [
        {
          text: 'Pending',
          value: IExpenseStatus.PENDING,
        },
        {
          text: 'Approved',
          value: IExpenseStatus.APPROVED,
        },
        {
          text: 'Rejected',
          value: IExpenseStatus.REJECTED,
        },
        {
          text: 'Paid',
          value: IExpenseStatus.PAID,
        },
        {
          text: 'Archived',
          value: IExpenseStatus.ARCHIVED,
        },
      ],
      onFilter: (value, record) => record.status.includes(value),
      render: (status: IExpenseStatus) => (
        <StatusContainer admin={isAllowedExpenseBasic}>
          <ExpenseStatusTag status={status} />
        </StatusContainer>
      ),
    },
    {
      title: '',
      dataIndex: '',
      width: isAllowedExpenseBasic ? 160 : 85,
      fixed: 'right',
      render: (_value: string, data: any) => {
        // Check if logged in user is an owner or expense created not by him/her
        const basicAllowed: boolean =
          me?.roleId === UserRoleIdEnum.OWNER || data.creatorId !== me?.id;
        return (
          <ActionsContainer>
            {isAllowedExpenseBasic &&
              reviewAllowed({ reviewerId: me?.id, userId: data.userId }) &&
              !APPROVED_STAGES.includes(data.status) &&
              basicAllowed && (
                <ActionItem marginRight={32}>
                  <B
                    type="b-small"
                    color={theme.blue500}
                    onClick={() => {
                      updateStatusAction({
                        id: data.id,
                        status: IExpenseStatus.APPROVED,
                      });
                    }}>
                    Approve
                  </B>
                </ActionItem>
              )}
            <ActionItem
              marginRight={
                FINISHED_STAGES.includes(data.status)
                  ? data.files && data.files.length
                    ? 38
                    : 92
                  : data.files && data.files.length
                  ? 37
                  : !isAllowedExpenseBasic &&
                    data.status !== IExpenseStatus.PENDING
                  ? 92
                  : 72
              }>
              <ViewExpenseModal
                trigger={
                  <B type="b-small" color={theme.blue500}>
                    View
                  </B>
                }
                data={data}
                deleteAction={(id) => deleteAction(id)}
                updateStatusAction={(data) => updateStatusAction(data)}
                deleteActionLoading={deleteActionLoading}
                updateStatusActionLoading={updateStatusActionLoading}
              />
            </ActionItem>
            {Boolean(data.files && data.files.length) && (
              <ActionItem
                marginRight={
                  !isAllowedExpenseBasic &&
                  data.status !== IExpenseStatus.PENDING
                    ? 46
                    : !FINISHED_STAGES.includes(data.status)
                    ? 26
                    : 46
                }>
                <ViewExpenseModal
                  trigger={
                    <Tooltip
                      placement="top"
                      title={`${
                        data.files.length > 1
                          ? `${data.files.length} Attachments`
                          : 'Attachment'
                      } available`}
                      color={theme.blue900}
                      key={theme.blue900}>
                      <Image
                        cursor="pointer"
                        width="8px"
                        height="14px"
                        src={Attachment}
                        draggable={false}
                      />
                    </Tooltip>
                  }
                  data={data}
                  deleteAction={(id) => deleteAction(id)}
                  updateStatusAction={(data) => updateStatusAction(data)}
                  deleteActionLoading={deleteActionLoading}
                  updateStatusActionLoading={updateStatusActionLoading}
                />
              </ActionItem>
            )}
            {!isAllowedExpenseBasic &&
            data.status !==
              IExpenseStatus.PENDING ? null : !FINISHED_STAGES.includes(
                data.status,
              ) ? (
              <Dropdown
                overlay={menu(data)}
                placement="bottomLeft"
                trigger={['click']}>
                <Image
                  cursor="pointer"
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                  src={MoreSVG}
                  draggable={false}
                />
              </Dropdown>
            ) : null}
          </ActionsContainer>
        );
      },
    },
  ];

  const scrollTarget = useSelector(
    (state: RootState) => state.dashboard.scrollTarget,
  );

  return (
    <>
      <TableLayout>
        <TableComp
          rowKey={(item) => {
            return String(item.id);
          }}
          withBorders={true}
          dataSource={dataSet}
          rowClassName={(row) =>
            row.id === scrollTarget ? 'yellow-highlight' : ''
          }
          columns={columns.filter((c) => c.render)}
          locale={locale}
          pagination={false}
          loading={allLoading}
          scroll={{
            x: 1200,
            y:
              window.innerHeight -
              (integrationStatus === IntegrationStatusEnum.ACTION_REQUIRED
                ? 380
                : 300),
          }}
          css={isAllowedExpenseBasic ? tableStyle : null}
        />
      </TableLayout>
      <CardLayout>
        <Spin tip="Loading..." spinning={allLoading}>
          {dataSet && dataSet.length
            ? dataSet.map((item) => {
                // Check if logged in user is an owner or expense created not by him/her
                const basicAllowed: boolean =
                  me?.roleId === UserRoleIdEnum.OWNER ||
                  item.creatorId !== me?.id;
                return (
                  <Card key={item.id}>
                    <ColumnContainer>
                      <RowContainer>
                        <Text
                          type={
                            isAllowedExpenseBasic
                              ? 'b-small-semibold'
                              : 'b-small'
                          }
                          color={
                            isAllowedExpenseBasic ? theme.black : theme.gray600
                          }>
                          {isAllowedExpenseBasic
                            ? item.owner.fullName
                            : item.category}
                        </Text>
                        <PriceTag amount={item.amount} />
                      </RowContainer>
                      <Text type="b-small" marginTop="4px">
                        {item.note}
                      </Text>
                      <RowContainer marginTop="4px">
                        <DateRowContainer>
                          <Image
                            src={PayrollCalenderSVG}
                            marginRight="6px"
                            width="14px"
                            height="14px"
                          />
                          <B color={theme.gray600} type="b-small">
                            {dayjs(item.date).format('DD.MM.YYYY')}
                          </B>
                        </DateRowContainer>
                        <ExpenseStatusTag status={item.status} />
                      </RowContainer>
                      <StyledDivider />
                      <RowContainer>
                        <ActionRowContainer>
                          <ViewExpenseModal
                            trigger={
                              <B type="b-small" color={theme.blue500}>
                                View
                              </B>
                            }
                            data={item}
                            deleteAction={(id) => deleteAction(id)}
                            updateStatusAction={(data) =>
                              updateStatusAction(data)
                            }
                            deleteActionLoading={deleteActionLoading}
                            updateStatusActionLoading={
                              updateStatusActionLoading
                            }
                          />
                          {!isAllowedExpenseBasic &&
                            item.status === IExpenseStatus.PENDING &&
                            (item.creatorId === me?.id ||
                              item.userId === me?.id) && (
                              <B
                                type="b-small"
                                marginLeft="16px"
                                fontWeight="500"
                                color={theme.blue500}
                                onClick={() =>
                                  history(`/expense/update/${item.id}`)
                                }>
                                Edit
                              </B>
                            )}
                          {isAllowedExpenseBasic &&
                            !APPROVED_STAGES.includes(item.status) &&
                            basicAllowed && (
                              <B
                                type="b-small"
                                color={theme.blue500}
                                marginLeft="16px"
                                fontWeight="500"
                                onClick={() => {
                                  updateStatusAction({
                                    id: item.id,
                                    status: IExpenseStatus.APPROVED,
                                  });
                                }}>
                                Approve
                              </B>
                            )}
                          {isAllowedExpenseBasic &&
                            [
                              IExpenseStatus.PENDING,
                              IExpenseStatus.APPROVED,
                            ].includes(item.status as IExpenseStatus) &&
                            basicAllowed && (
                              <B
                                type="b-small"
                                color={theme.blue500}
                                marginLeft="16px"
                                fontWeight="500"
                                onClick={() => {
                                  updateStatusAction({
                                    id: item.id,
                                    status: IExpenseStatus.REJECTED,
                                  });
                                }}>
                                Reject
                              </B>
                            )}
                        </ActionRowContainer>
                        {item.status === IExpenseStatus.PENDING &&
                          me?.id === item.creatorId && (
                            <ConfirmationModal
                              title={
                                <B type="b-large-semibold">
                                  Confirmation Alert
                                </B>
                              }
                              content={
                                <B type="b-large">
                                  Are you sure you want to delete this
                                  reimbursement entry, by deleting this you will
                                  not see this item blah blah
                                </B>
                              }
                              okText="Yes, Delete"
                              cancelText="No"
                              onConfirm={() => {
                                deleteAction(item.id);
                              }}
                              trigger={
                                <B type="b-small" color={theme.blue500}>
                                  Delete
                                </B>
                              }
                            />
                          )}
                        {expenseAdvancedPermission &&
                          item.status === IExpenseStatus.REJECTED &&
                          basicAllowed && (
                            <ConfirmationModal
                              title={
                                <B type="b-large-semibold">
                                  Confirmation Alert
                                </B>
                              }
                              content={
                                <B type="b-large">
                                  Are you sure you want to archive this
                                  reimbursement entry, by archive this you will
                                  not see this item blah blah
                                </B>
                              }
                              okText="Yes, Archive"
                              cancelText="No"
                              onConfirm={() => {
                                updateStatusAction({
                                  id: item.id,
                                  status: IExpenseStatus.ARCHIVED,
                                });
                              }}
                              trigger={
                                <B type="b-small" color={theme.blue500}>
                                  Archive
                                </B>
                              }
                            />
                          )}
                      </RowContainer>
                    </ColumnContainer>
                  </Card>
                );
              })
            : !allLoading && (
                <EmptyContainer>
                  <EmptyTextContainer description="You don't have any expense requested yet" />
                </EmptyContainer>
              )}
        </Spin>
      </CardLayout>
    </>
  );
};
