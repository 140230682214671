// This converts a numeric index to a spreadsheet column name
// ex: 1 -> A | 27 -> AA
export const numberToColumnName = (num: number) => {
  let columnName = '';
  while (num > 0) {
    const remainder = (num - 1) % 26;
    columnName = String.fromCharCode(65 + remainder) + columnName;
    num = Math.floor((num - 1) / 26);
  }
  return columnName;
};
