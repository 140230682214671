import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useDrag, DragPreviewImage, useDrop } from 'react-dnd';
import { B, Image } from '../../../components';
import { getPlaceholderName } from '../utils/getPlaceholderName';
import { StandardFieldsI } from './ToolContainer';
import theme from '../../../theme';
import { PlaceholderTypeEnum } from '../../../types/contract.types';
export interface BoxProps {
  tool: StandardFieldsI;
  index: number;
  isReceiver: boolean;
  selectPlaceHolder: (type: PlaceholderTypeEnum) => void;
}

export const DropperContainer = styled.div`
  &:hover {
    background: ${theme.gray100};
  }
  margin-top: 4px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const Toolkit = styled.div<{ opacity: number }>`
  .toolkit-item {
    opacity: ${(prpos) => prpos.opacity};
  }
  padding: 4px 16px;
`;

const RowAlign = styled(Row)`
  align-items: center;
`;
export const DropperBox: FC<BoxProps> = function Box({
  tool,
  index,
  isReceiver,
  selectPlaceHolder,
}) {
  const [{ opacity }, drag, preview] = useDrag(() => ({
    type: tool.placeholderType,
    item: { placeholderType: tool.placeholderType },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  }));

  return (
    <DropperContainer>
      <DragPreviewImage
        connect={preview}
        src={isReceiver ? tool.receiverImage : tool.senderImage}
      />
      <Toolkit
        opacity={opacity}
        key={index}
        ref={drag}
        role="Box"
        onClick={() => {
          selectPlaceHolder(tool.placeholderType);
        }}>
        <RowAlign className="toolkit-item">
          <Image src={tool.image} marginRight="8px" />
          <B mt="2px" type="b-default" color="black">
            {getPlaceholderName(tool.placeholderType)}
          </B>
        </RowAlign>
      </Toolkit>
    </DropperContainer>
  );
};
