import { message } from 'antd';
import XLSX from 'xlsx';
import { DataExportResponseI } from '../../../types';

export const generateDataExportExcelSheets = (
  response: DataExportResponseI[],
) => {
  const wb = XLSX.utils.book_new();
  let isValidToCreate = false;
  response.forEach((item) => {
    if (item.headers) {
      isValidToCreate = true;
      const ws = XLSX.utils.json_to_sheet(item.data, { header: [] });
      XLSX.utils.book_append_sheet(wb, ws, item.sheetName);
    }
  });
  if (isValidToCreate) {
    XLSX.writeFile(wb, 'data-export-sheet.xlsx');
    return true;
  } else {
    message.error('There is no enough data to create excel sheet');
  }
};
