import { Col, Row, Spin } from 'antd';
import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { B, DefaultBody } from '../../../components';
import { getIntegrations } from '../../../store/modules/integration';
import { DispatchType, RootState } from '../../../store/reducer';
import IntegrationCard from './IntegrationCard';

const Heading = styled.div`
  margin-top: 8px;
  @media screen and (max-width: 768px) {\
    margin-top: 20px;
  }
`;

const DomainContainer = styled.div`
  margin-top: 40px;
`;
const DomainInfoContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 24px;
`;

const IntegrationListPage: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const loadingIntegrations = useSelector(
    (state: RootState) => state.integration.loadingIntegrations,
  );
  const integrations = useSelector(
    (state: RootState) => state.integration.integrationList,
  );

  useEffect(() => {
    dispatch(getIntegrations());
  }, []);

  const accountingIntegrations = integrations.filter(
    (i) => i.integrationDomainId === 1, //Accounting domain id = 1
  );

  return (
    <DefaultBody>
      <Spin spinning={loadingIntegrations}>
        <Heading>
          <B type="b-default" color="#000000">
            You can conveniently integrate Humanised Payroll with multiple
            applications.
          </B>
        </Heading>
        <DomainContainer>
          <DomainInfoContainer>
            <B type="b-large-semibold" color="#000000" Pb="8px">
              Accounting Software
            </B>
            <B type="b-default" color="#000000">
              You can only intergrated one accounting software / even if
              there’re multiple softwares
            </B>
          </DomainInfoContainer>
          <Row gutter={[0, 32]}>
            {accountingIntegrations.map((item, i) => (
              <Col key={i} xs={24} sm={24} md={12} lg={7} xl={7} xxl={6}>
                <IntegrationCard item={item} />
              </Col>
            ))}
          </Row>
        </DomainContainer>
      </Spin>
    </DefaultBody>
  );
};

export default IntegrationListPage;
