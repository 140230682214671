import React, { FC } from 'react';
import { TimePicker, Typography } from 'antd';
import styled from '@emotion/styled';
import theme from '../theme';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
  label?: string;
  hint?: string;
  flexDirection?: string;
  size?: 'small' | 'middle' | 'large';
  format?: string;
  defaultValue?: Dayjs;
  value?: Dayjs;
  onChange?: (value: any) => void;
  minuteStep?: 15 | 10 | 30;
  disabledTime?: (now: Dayjs) => any;
  showNow?: boolean;
}
const Text = styled(Typography.Text)`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.blue700};
  padding-bottom: 4px;
`;
const StyledTimePicker = styled(TimePicker)<Props>`
  ${(props) => props.flexDirection === 'row' && 'width: 344px;'}
  border: 1px solid ${theme.gray300};
  width: 100%;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 20px;
  padding: ${(props) => props.size === 'small' && '0 12px'};
  height: ${(props) => (props.size == 'small' ? '32px !important' : '40px')};
  &:focus {
    border: 1px solid ${theme.blue500};
    box-shadow: none;
  }

  .ant-input[disabled] {
    background-color: ${theme.gray200};
  }

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${theme.blue500};
  }

  &.ant-input-affix-wrapper:focus,
  &.ant-input-affix-wrapper-focused {
    border-color: ${theme.blue500};
    box-shadow: none;
  }

  &:hover {
    border: 1px solid ${theme.blue500};
  }
`;

export const TimePickerComp: FC<Props> = (props) => {
  const {
    label,
    hint,
    size = 'large',
    format = 'HH:mm',
    defaultValue,
    value,
    onChange,
    minuteStep,
    disabledTime,
    showNow,
  } = props;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {label && <Text>{label}</Text>}
      <StyledTimePicker
        defaultValue={defaultValue}
        format={format}
        value={value}
        size={size}
        onChange={onChange}
        minuteStep={minuteStep}
        disabledTime={disabledTime}
        showNow={showNow}
      />
      {hint && <Typography.Text type="secondary">{hint}</Typography.Text>}
    </div>
  );
};
