const SET_SIDE_BAR_COLLAPSED = 'SET_SIDE_BAR_COLLAPSED';

interface InitialStateI {
  isCollapsed: boolean;
}

const initialState: InitialStateI = {
  isCollapsed: true,
};

export default function reducer(
  state: InitialStateI = initialState,
  action: any,
): InitialStateI {
  switch (action.type) {
    case SET_SIDE_BAR_COLLAPSED:
      return {
        ...state,
        isCollapsed: action.payload,
      };
    default:
      return state;
  }
}

export const setSideBarCollapsed = (payload: boolean) => {
  return (dispatch: any) => {
    dispatch({ type: SET_SIDE_BAR_COLLAPSED, payload });
  };
};
