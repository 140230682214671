import React, { FC, ReactNode } from 'react';
import theme from '../../../theme';
import { LeaveStatus } from '../../home/homeUtil';
import { SubscriptionEnum } from '../../../types/subscription.types';
import { NameTag } from '../../../components';

interface Props {
  children?: ReactNode;
  status: string;
}

export const StatusTag: FC<Props> = ({ status }) => {
  {
    switch (status) {
      case LeaveStatus.PENDING:
        return (
          <NameTag textColor={theme.orange400} color={theme.warn}>
            Pending
          </NameTag>
        );
      case SubscriptionEnum.DUE:
        return (
          <NameTag
            textColor={theme.orange400}
            color={theme.warn}
            styleCSS={`
            width:39px;`}>
            Due
          </NameTag>
        );
          
      case LeaveStatus.ACCEPTED:
        return (
          <NameTag textColor={theme.green500} color={theme.green50}>
            Approved
          </NameTag>
        );

      case LeaveStatus.REJECTED:
        return (
          <NameTag textColor={theme.red300} color={theme.red50}>
            Rejected
          </NameTag>
        );
      case SubscriptionEnum.OVERDUE:
      case SubscriptionEnum.SUSPENDED:
        return (
          <NameTag
            textColor={theme.red300}
            color={theme.red50}
            styleCSS={`
            width:64px;`}>
            Overdue
          </NameTag>
        );

      case SubscriptionEnum.PAID:
        return (
          <NameTag
            textColor={theme.green500}
            color={theme.green50}
            styleCSS={`
            width:39px;`}>
            Paid
          </NameTag>
        );

      case SubscriptionEnum.WRITTEN_OFF:
        return (
          <NameTag
            textColor={theme.gray600}
            color={theme.gray100}
            styleCSS={`
            width:78px;`}>
            Written Off
          </NameTag>
        );
      default:
        return null;
    }
  }
};
