import React, { FC } from 'react';
import { B } from '../../../components/B';
import styled from '@emotion/styled';
const SignInFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: #5f6267;
  }
`;

const Content = styled.div`
  @media (max-width: 450px) {
    display: none;
  }
`;

const MobileContent = styled.div`
  @media (min-width: 450px) {
    display: none;
  }
`;

const HumanisedFooter: FC = () => {
  return (
    <SignInFooter>
      <Content>
        <B type="b-default" Pb="24px" color="#5F6267">
          Copyright © 2022 &nbsp;
          <a href="https://www.gethumanised.com">gethumanised.com</a> All Rights
          Reserved.
        </B>
      </Content>
      <MobileContent>
        <B type="b-small" Pb="24px" color="#5F6267">
          Copyright © 2022 &nbsp;
          <a href="https://www.gethumanised.com">gethumanised.com</a> All Rights
          Reserved.
        </B>
      </MobileContent>
    </SignInFooter>
  );
};

export default HumanisedFooter;
