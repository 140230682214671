import styled from '@emotion/styled';
import { Col, Form, Row, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  useAddGratuityDocument,
  useAddGratuityNote,
  useEditGratuityNote,
  useGetGratuityByID,
  useRemoveGratuityDocument,
  useRemoveGratuityNote,
  useUpdateGratuity,
} from '../../../api/gratuityHooks';
import {
  B,
  CustomDivider,
  Div,
  FooterComponent,
  PrimaryButton,
  SecondaryButton,
} from '../../../components';
import theme from '../../../theme';
import { GratuityI, UpdateGratuityI } from '../../../types/gratuity.types';

import { getPathWithOrgId } from '../../../util/getPathWithOrgId';
import EmpGratuityTable from './EmpGratuityTable';
import GratuityNotesAndDocuments from './GratuityNotesAndDocuments';
import GratuityOverview from './GratuityOverview';

const GratuityForm = styled(Form)`
  .ant-input[disabled],
  .ant-picker-input > input[disabled],
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: ${theme.black};
  }
`;

type ParamsI = {
  id: string;
};

const ViewGratuityPage: FC = () => {
  const navigate = useNavigate();
  const params = useParams<ParamsI>();
  const [form] = Form.useForm();
  const [isPaid, setIsPaid] = useState<boolean>(false);

  const {
    isLoading: loadingGratuity,
    data: dataGratuity,
    mutate: getGratuity,
  } = useGetGratuityByID();

  const {
    isLoading: loadingUpdateGratuity,
    isSuccess: successUpdateGratuity,
    mutate: updateGratuity,
  } = useUpdateGratuity();

  const { isSuccess: successAddNote, mutate: addNote } = useAddGratuityNote();
  const {
    isSuccess: successEditNote,
    mutate: editNote,
  } = useEditGratuityNote();
  const {
    isSuccess: successRemoveNote,
    mutate: removeNote,
  } = useRemoveGratuityNote();
  const {
    isSuccess: successAddDocument,
    mutate: addDocument,
  } = useAddGratuityDocument();
  const {
    isSuccess: successRemoveDocument,
    mutate: removeDocument,
  } = useRemoveGratuityDocument();

  useEffect(() => {
    if (params.id) {
      getGratuity(Number(params.id));
    }
  }, []);

  useEffect(() => {
    if (
      successUpdateGratuity ||
      successAddNote ||
      successEditNote ||
      successRemoveNote ||
      successAddDocument ||
      successRemoveDocument
    ) {
      getGratuity(Number(params.id));
    }
  }, [
    successUpdateGratuity,
    successAddNote,
    successEditNote,
    successRemoveNote,
    successAddDocument,
    successRemoveDocument,
  ]);

  const onFinish = async (values: GratuityI) => {
    const data = {
      ...values,
      isPaid: isPaid,
    };

    if (params.id) {
      const dataSet: UpdateGratuityI = {
        id: Number(params.id),
        data: data,
      };
      await updateGratuity(dataSet);
    }
  };

  return (
    <div>
      <Spin spinning={loadingGratuity || loadingUpdateGratuity}>
        <Div p="32px 40px 0px 48px">
          <GratuityOverview gratuityDetails={dataGratuity} />
          <CustomDivider
            mt={24}
            mb={0}
            borderTop={`1px solid ${theme.gray300}`}
          />
        </Div>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={19}>
            <GratuityForm
              form={form}
              onFinish={(v) => onFinish(v as GratuityI)}>
              <Div p="32px 40px 32px 48px">
                <B type="b-large-semibold" pb="32px">
                  Employee Gratuity Details
                </B>
                <EmpGratuityTable
                  gratuityDetails={dataGratuity}
                  onChange={(checked: boolean) => setIsPaid(checked)}
                />
                <Div pt="32px">
                  <GratuityNotesAndDocuments
                    note={dataGratuity?.notes}
                    documents={dataGratuity?.documents}
                    onAddNewNote={(values) => {
                      const v = {
                        gratuityId: dataGratuity?.id,
                        ...values,
                      };
                      addNote(v);
                    }}
                    onEditNote={(id, value) => {
                      const selectedNote =
                        dataGratuity?.notes &&
                        dataGratuity.notes.find((note) => note.id === id);
                      if (selectedNote) {
                        editNote({
                          id: Number(selectedNote.id),
                          note: value.note,
                        });
                      }
                    }}
                    onRemoveNote={(id) => {
                      const selectedNote =
                        dataGratuity?.notes &&
                        dataGratuity.notes.find((note) => note.id === id);
                      if (selectedNote) {
                        removeNote(Number(selectedNote.id));
                      }
                    }}
                    onSubmitDocument={(values) => {
                      const v = {
                        gratuityId: dataGratuity?.id,
                        ...values,
                      };
                      addDocument(v);
                    }}
                    onRemoveDocument={(id) => {
                      const selectedDocument =
                        dataGratuity?.documents &&
                        dataGratuity.documents.find(
                          (document) => document.id === id,
                        );
                      if (selectedDocument) {
                        removeDocument(Number(selectedDocument.id));
                      }
                    }}
                  />
                </Div>
              </Div>
              <FooterComponent
                leftChildren={
                  <SecondaryButton
                    marginLeft={23}
                    onClick={() => navigate(getPathWithOrgId('/gratuity'))}>
                    Back
                  </SecondaryButton>
                }
                rightChildren={
                  <PrimaryButton
                    ml={16}
                    loading={loadingGratuity || loadingUpdateGratuity}
                    htmlType="submit">
                    Save
                  </PrimaryButton>
                }
              />
            </GratuityForm>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default ViewGratuityPage;
