import React, { FC } from 'react';
import { PlaceholderBoxPropsI } from './PlaceholderBoxProps';
import { ContractStatusEnum } from '../../../../types/contract.types';
import { DraggableContainer } from './containers/DraggableContainer';
import { SignatureContainer } from '../../shared/ContractLayout';
import { useZoom } from '../../shared/ZoomContext';

const SignaturePlaceholderBox: FC<PlaceholderBoxPropsI> = ({
  onAction,
  image,
  isSelected,
  isReceiver,
  contractStatus,
  isEditable,
  isSaved,
  latestSignature,
}) => {
  const { ratio } = useZoom();
  return (
    <>
      {contractStatus === ContractStatusEnum.COMPLETED ? null : (
        <>
          {image ? (
            <SignatureContainer ratio={ratio} isReceiver={isReceiver}>
              <label>Signature</label>
              <div className="image-container">
                <img onClick={onAction} src={image} alt="signature-img" />
              </div>
            </SignatureContainer>
          ) : latestSignature && isSaved ? (
            <SignatureContainer ratio={ratio} isReceiver={isReceiver}>
              <label>Signature</label>
              <div className="image-container">
                <img
                  onClick={onAction}
                  src={latestSignature}
                  alt="latest-signature-img"
                />
              </div>
            </SignatureContainer>
          ) : (
            <DraggableContainer
              isReceiver={isReceiver}
              selected={isSelected}
              showPoints={isEditable}
              onAction={onAction}>
              <div className="sign-container">
                <label className="text-input">Signature</label>
              </div>
            </DraggableContainer>
          )}
        </>
      )}
    </>
  );
};
export default SignaturePlaceholderBox;
