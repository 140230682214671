import React, { FC } from 'react';
import { B, Div } from '../../components';
import theme from '../../theme';
import { Divider } from 'antd';
import styled from '@emotion/styled';
import { useGetMessages } from '../../api/organizationHooks';
import moment from 'moment';

const MainContainer = styled.div`
  width: 1200px;
  @media (max-width: 1366px) {
    width: 100%;
  }
`;

const DividerComp = styled(Divider)`
  margin: 16px 0px;
`;

const SystemUpdates: FC = () => {
  const { data: messages = [] } = useGetMessages();
  const systemMessages = messages;
  const systemMessagesCount = String(systemMessages.length).padStart(
    systemMessages.length > 0 ? 2 : 1,
    '0',
  );

  systemMessages.forEach((message) => {
    const fromMoment = moment(message.from);
    const toMoment = moment(message.to);

    if (fromMoment.isSame(toMoment, 'day')) {
      message.from = fromMoment.format('DD, MMM YYYY');
      message.to = `${fromMoment.format('HH:mm A')} to ${toMoment.format(
        'HH:mm A',
      )}`;
    } else {
      message.from = fromMoment.format('DD, MMM YYYY HH:mm A');
      message.to = toMoment.format('DD, MMM YYYY HH:mm A');
    }
  });

  return (
    <Div p={32}>
      <B type="b-large-semibold" Pb="24px">
        New Updates ({systemMessagesCount})
      </B>
      <MainContainer>
        {systemMessages.length > 0
          ? systemMessages?.map((systemMessage, index) => (
              <Div
                key={index}
                p={24}
                mb="32px"
                background={theme.lightBlue50}
                borderRadius={4}>
                <B type="b-large-semibold">{systemMessage.title}</B>
                <DividerComp />
                <B type="b-default-semibold" color={theme.gray750} pb={20}>
                  {systemMessage.description}
                </B>
                <B type="b-default-semibold" color={theme.gray750}>
                  {systemMessage.from} - {systemMessage.to}
                </B>
              </Div>
            ))
          : 'Yay! We are all up to date. Please get in touch with us if you are experiencing any issue regardless.'}
      </MainContainer>
    </Div>
  );
};
export default SystemUpdates;
