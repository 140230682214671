import { message } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { GetLeaveReportParams, GetReportParams, LeaveReportI } from '../types/report.types';
import { getApiError } from '../util/getApiError';

export const useGetLeaveReport = (params: GetLeaveReportParams) => {
  return useQuery<LeaveReportI[], AxiosError>(
    ['leave-report', params],
    async () => {
      const response: AxiosResponse<
        LeaveReportI[]
      > = await axios.get(`/report/leave-log`, { params });
      return response.data;
    },
  );
};

export const useGetT10Report = (params: GetReportParams) => {
  return useMutation<string, AxiosError>(
    ['t10-report', params],
    async () => {
      const response: AxiosResponse<string> = await axios.get(
        `/report/t-10`,
        { params }
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      }
    }
  );
};

export const useGetAnnualReport = (params: GetReportParams) => {
  return useMutation<string, AxiosError>(
    ['annual-report', params],
    async () => {
      const response: AxiosResponse<string> = await axios.get(
        `/report/annual-report`,
        { params }
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      }
    }
  );
};
