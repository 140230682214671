import axios from 'axios';
import showApiError from '../../util/showApiError';

const GET_PEOPLE_LOGS = 'GET_PEOPLE_LOGS';
const GET_PAYROLL_LOGS = 'GET_PAYROLL_LOGS';
const LOAD_PAYROLL_LOGS = 'LOAD_PAYROLL_LOGS';
const LOAD_PEOPLE_LOGS = 'LOAD_PEOPLE_LOGS';

export interface LogTrailMapper {
  display: string;
  id: string;
  type: 'USER';
  link?: string;
}

export interface LogTrailItemI {
  id: number;
  actorId: number;
  message: string;
  type: string;
  createdAt: string;
  payrollId?: number;
  userId?: number;
  mapper: {
    [key: string]: LogTrailMapper;
  };
  category?: string;
  organizationId?: number;
  referenceId?: number;
  updatedAt?: string;
}

// TODO: The api should provide the JSON for mapper instead of string
export type LogTrailApiResponse = Omit<LogTrailItemI, 'mapper'> & {
  mapper: string;
};
interface InitialStateI {
  loading: boolean;
  peopleAuditLogs: LogTrailItemI[];
  payrollAuditLogs: LogTrailItemI[];
  loadingPayrollAuditLogs: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  peopleAuditLogs: [],
  payrollAuditLogs: [],
  loadingPayrollAuditLogs: false,
};

export default function reducer(
  state: InitialStateI = initialState,
  action: any,
): InitialStateI {
  switch (action.type) {
    case GET_PEOPLE_LOGS: {
      return {
        ...state,
        peopleAuditLogs: action.payload,
        loading: false,
      };
    }
    case LOAD_PEOPLE_LOGS: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case LOAD_PAYROLL_LOGS: {
      return {
        ...state,
        loadingPayrollAuditLogs: action.payload,
      };
    }

    case GET_PAYROLL_LOGS: {
      return {
        ...state,
        payrollAuditLogs: action.payload,
        loadingPayrollAuditLogs: false,
      };
    }
    default:
      return state;
  }
}

export function getPeopleAuditLog(userId: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_PEOPLE_LOGS, payload: true });
      const response = await axios.get(`/users/userLogs/${userId}`);

      // TODO: The api should provide the JSON for mapper instead of string
      const payload = response.data.map((item: LogTrailApiResponse) => {
        return {
          ...item,
          mapper: JSON.parse(item.mapper),
        };
      });
      dispatch({
        type: GET_PEOPLE_LOGS,
        payload: payload as LogTrailItemI[],
      });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: LOAD_PEOPLE_LOGS, payload: false });
    }
  };
}

export function getPayrollLogTrails(payrollId: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_PAYROLL_LOGS, payload: true });
      const response = await axios.get(`/payroll/payrollLogs/${payrollId}`);

      // TODO: The api should provide the JSON for mapper instead of string
      const payload = response.data.map((item: LogTrailApiResponse) => {
        return {
          ...item,
          mapper: JSON.parse(item.mapper),
        };
      });
      dispatch({
        type: GET_PAYROLL_LOGS,
        payload: payload as LogTrailItemI[],
      });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: LOAD_PAYROLL_LOGS, payload: false });
    }
  };
}
