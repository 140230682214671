import styled from '@emotion/styled';
import { Row, Col } from 'antd';
import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { useGetUserLoanList } from '../../../../api/loanHooks';
import {
  B,
  CustomDivider,
  FooterComponent,
  PrimaryButton,
} from '../../../../components';
import theme from '../../../../theme';
import { PeopleLayout } from '../../shared';
import PeopleSelect from '../../shared/PeopleSelect';
import LoanDetailsOverview from './LoanDetailsOverview';
import LoanListTable from './LoanListTable';
import { useIndividualPeopleState } from '../../../../providers/IndividualPeopleState';
import { useNavigate } from 'react-router-dom';

const MainContainer = styled.div`
  padding: 32px;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

type ParamsI = {
  employeeKey: string;
};

const LoanPage: FC = () => {
  const params = useParams<ParamsI>();

  const { data: loanList = [], mutate: getloanList } = useGetUserLoanList();
  const {
    state: { people, isLoading },
    fetchPeopleById,
  } = useIndividualPeopleState();
  const navigate = useNavigate();

  const fetchPeople = () => {
    fetchPeopleById(params.employeeKey);
  };

  useEffect(() => {
    if (params.employeeKey) {
      getloanList(Number(params.employeeKey));
      fetchPeople();
    }
  }, [params.employeeKey]);

  return (
    <PeopleLayout>
      <MainContainer>
        <Row>
          <Col xs={0} sm={0} md={0} lg={16} xl={16} xxl={19}>
            <B type="b-large-semibold" Pb="24px">
              Loan Details
            </B>
          </Col>
          <Col xs={12} sm={14} md={12} lg={0} xl={0} xxl={0}>
            <PeopleSelect defaultIndex="5" />
          </Col>
        </Row>
        <LoanDetailsOverview loanList={loanList} />
        <CustomDivider
          mt={16}
          mb={32}
          borderTop={`0.5px solid ${theme.gray300}`}
        />
        <LoanListTable loanList={loanList} />
      </MainContainer>
      {people?.isPending && (
        <FooterComponent
          rightChildren={
            <PrimaryButton
              ml={16}
              htmlType="submit"
              loading={isLoading}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/contact/document/${params.employeeKey}`);
              }}>
              Next
            </PrimaryButton>
          }
        />
      )}
    </PeopleLayout>
  );
};

export default LoanPage;
