import { BadgeProps, Badge } from 'antd';
import moment from 'moment';
import { FC } from 'react';
import theme from '../../theme';

interface CountBadgeProps {
  count: number;
  date: string;
}

const CountBadge: FC<CountBadgeProps & BadgeProps> = ({
  style,
  count,
  date,
}) => {
  const notifyCount = count.toString().padStart(2, '0');
  return (
    <Badge
      style={style}
      color={
        date <= moment().format('YYYY-MM-DD') ? theme.gray400 : theme.blue500
      }
      count={notifyCount}
    />
  );
};

export default CountBadge;
