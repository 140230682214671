import React, { FC, useEffect, useState } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { useGetTransferPayroll } from '../../../../api/payrollHooks';
import { DefaultBody, SecondaryButton } from '../../../../components';
import { EmptyPage } from '../../../../components/EmptyComponent';
import { FooterComponent } from '../../../../components/FooterComponent';
import {
  BulkPaySlipDownloadEnum,
  getPayrollData,
} from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import { getPathWithOrgId } from '../../../../util/getPathWithOrgId';
import { useInterval } from '../../../../util/useInterval';
import FileExportedSuccessfully from './FileExportedSuccessfully';
import PublishPayrollSummary from './PublishPayrollSummary';

type payrollParam = {
  payrollKey: string;
};

export const PublishPayrun: FC = () => {
  const params = useParams<payrollParam>();
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();
  const {
    data: dataGetTransferPayroll = null,
    mutate: getTransferPayroll,
  } = useGetTransferPayroll();

  const [isStillPossibleToSchedule, setIsStillPossibleToSchedule] = useState(
    false,
  );
  const [isStillPossibleToUpload, setIsStillPossibleToUpload] = useState(false);
  const singlePayrollData = useSelector(
    (state: RootState) => state.payroll.singlePayrollData,
  );

  const errorHandling = useSelector(
    (state: RootState) => state.payroll.errorHandling,
  );

  const isStillGeneratingPaySlip =
    !dataGetTransferPayroll ||
    dataGetTransferPayroll?.bulkPayslipStatus ===
      BulkPaySlipDownloadEnum.IN_PROGRESS ||
    dataGetTransferPayroll?.bulkPayslipStatus ===
      BulkPaySlipDownloadEnum.PENDING;

  const getIsPossibleToSchedule = () => {
    if (!singlePayrollData) {
      return false;
    }
    const { scheduleDate, payslipsSentDate } = singlePayrollData;
    if (payslipsSentDate) {
      return false;
    }
    if (scheduleDate && moment(scheduleDate).startOf('day').diff('s') > 0) {
      return false;
    }
    return true;
  };
  const getIsPossibleToUpload = () => {
    if (!singlePayrollData) {
      return false;
    }
    const { isPayslipsUploaded } = singlePayrollData;
    if (isPayslipsUploaded) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    getTransferPayroll(params.payrollKey);
    dispatch(getPayrollData(params.payrollKey));
  }, [params]);

  useEffect(() => {
    setIsStillPossibleToUpload(getIsPossibleToUpload());
    setIsStillPossibleToSchedule(getIsPossibleToSchedule());
  }, [singlePayrollData]);

  useInterval(
    () => {
      getTransferPayroll(params.payrollKey);
    },
    isStillGeneratingPaySlip ? 8 * 1000 : null,
  );

  if (errorHandling) {
    return (
      <EmptyPage
        content="Sorry, the page you visited does not exist."
        title="something went wrong !"
        buttonLabel=" Back Home"
      />
    );
  } else {
    return (
      <>
        <DefaultBody style={{ paddingBottom: 0 }}>
          <FileExportedSuccessfully
            isStillPossibleToUpload={isStillPossibleToUpload}
            isStillPossibleToSchedule={isStillPossibleToSchedule}
            isStillGeneratingPaySlip={isStillGeneratingPaySlip}
            fileTransfer={dataGetTransferPayroll}
            singlePayrollData={singlePayrollData}
          />
          <PublishPayrollSummary />
          <FooterComponent
            leftChildren={
              <SecondaryButton
                onClick={() => navigate(getPathWithOrgId('/payroll'))}>
                Back to Home
              </SecondaryButton>
            }
          />
        </DefaultBody>
      </>
    );
  }
};

export default PublishPayrun;
