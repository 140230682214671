import React, { FC, useEffect, useState } from 'react';

import { Divider, message, Row } from 'antd';
import { useNavigate, useLocation } from 'react-router';

import styled from '@emotion/styled';

import { useForgetPasswordRequest } from '../../api/authHooks';
import { B, H, PrimaryButton } from '../../components';
import theme from '../../theme';
import HumanisedFooter from './shared/HumanisedFooter';
import HumanisedHeader from './shared/HumanisedHeader';

const BackgroundContainer = styled.div`
  background: ${theme.gray50};
`;
const DividerContainer = styled(Divider)`
  margin: 24px 0 18px;
  .ant-divider {
    border-top: 1px solid ${theme.gray300};
  }
`;
const ResetLinkSentContainer = styled.div`
  height: 87vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-height: 520px) {
    align-items: normal;
    height: auto;
  }
`;
const FormContainer = styled.div`
  max-width: 420px;
  padding: 40px 24px 32px;
  margin: 0 auto;
  @media (min-width: 768px) and (max-width: 1920px) {
    width: 420px;
  }
  @media (max-width: 450px) {
    padding: 40px 32px 32px;
  }
`;
const ResetLinkSentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 0px 50px 0px;
  background: ${theme.white};
  border: 1px solid ${theme.gray200};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.0501803);
  border-radius: 4px;
  @media (max-width: 450px) {
    background: transparent;
    border: none;
    box-shadow: none;
  }
  @media screen and (max-height: 520px) {
    margin: 40px 0px 25px 0px;
  }
`;

const ResendLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PasswordResetLinkSent: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState<string | null>(null);
  useEffect(() => {
    setEmail(location.state.email);
  }, [location]);
  const {
    error: errorForgetPasswordRequest,
    data: dataForgetPasswordRequest,
    mutate: updateForgetPasswordRequest,
  } = useForgetPasswordRequest();
  function handleSubmit() {
    if (email) {
      updateForgetPasswordRequest(email);
    }
  }
  useEffect(() => {
    if (errorForgetPasswordRequest) {
      navigate({
        pathname: '/forgetPassword',
      });
    }
  }, [errorForgetPasswordRequest]);
  return (
    <>
      {dataForgetPasswordRequest && message.success('Reset Link sent')}
      <BackgroundContainer>
        <HumanisedHeader />
        <ResetLinkSentContainer>
          <ResetLinkSentDiv>
            <FormContainer>
              <H type="h6">Password reset link sent</H>
              <B type="b-large" pt="24px" pb="32px">
                Check your inbox, we just sent you an email at{' '}
                <strong>{email ? email : '-'}</strong>
              </B>
              <Row>
                <PrimaryButton
                  htmlType="submit"
                  block
                  onClick={() => navigate('/')}>
                  Back to Sign In
                </PrimaryButton>
              </Row>
              <DividerContainer />
              <ResendLinkContainer>
                <B
                  type="b-default"
                  color={theme.blue500}
                  onClick={() => {
                    handleSubmit();
                  }}>
                  Resend Link
                </B>
              </ResendLinkContainer>
            </FormContainer>
          </ResetLinkSentDiv>
        </ResetLinkSentContainer>
        <HumanisedFooter />
      </BackgroundContainer>
    </>
  );
};

export default PasswordResetLinkSent;
