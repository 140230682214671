import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducer';
import { OnBoard } from '../auth/shared/OnBoardingModal';
import CompletionNotification from './shared/CompletionNotification';
import TeamAvailability from './shared/TeamAvailability';
import ReminderList from './shared/note/ReminderList';
import { Permission } from '../../types';
import { isAllowed } from '../../util/permissionUtil';
import { isCurrentAuthSuperAdmin } from '../../util/storageUtil';
import { Navigate } from 'react-router';
import { Div } from '../../components';
import { MultipleAlertMessage } from '../../components/alertMessage/MultipleAlertMessage';
import { SubscriptionEnum } from '../../types/subscription.types';
import PayrollDetails from './shared/PayrollDetails';
import UpcomingEvents from './shared/UpcomingEvents';
import { useLocalStorageState } from '../../util/useLocalStorage.util';




const Home: FC = () => {
  const me = useSelector((state: RootState) => state.people.me);
  const [isCollapsed, setIsCollapsed] = useLocalStorageState('notification-bar', false);

  if (isCurrentAuthSuperAdmin()) {
    return <Navigate to="/super-admin" />;
  }
  return (
    <Div p={32}>
      {me?.isNewUser && (
        <OnBoard
          path="/"
          userName={me?.fullName ? me?.fullName : '-'}
          leftImage="onboard-leave.svg"
          rightImage="humanisedName-icon.svg"
          buttonLabel="Continue"
          content="Now its easier than ever to request leave and sign contracts with humanised ..."
        />
      )}
      {me?.subscriptionStatus === SubscriptionEnum.SUSPENDED && (
        <MultipleAlertMessage
          type="error"
          messages={[
            'Account Suspended, Please make sure you have settled outstanding subscription fees',
          ]}
        />
      )}
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <CompletionNotification />
        </Col>
      </Row>
      
      <Row gutter={[24, 24]}> 
        <Col xs={24} sm={24} md={24} lg={24} xl={isCollapsed? 19: 16} xxl={isCollapsed ? 20: 16}>
          <TeamAvailability></TeamAvailability>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={isCollapsed ? 5:8} xxl={isCollapsed ? 4:8}>
          <Row>
            {isAllowed(Permission.MANAGE_PAYROLL) && <PayrollDetails isCollapsed={isCollapsed} />}
          </Row>
          <Row>
            {isAllowed(Permission.MANAGE_NOTIFICATION) ? (
              <ReminderList isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed}/>
            ) : (
              <UpcomingEvents />
            )}
          </Row>
        </Col>
      </Row>
    </Div>
  );
};
export default Home;
