import React, { FC } from 'react';

import { Col, Row } from 'antd';

import { css } from '@emotion/react';

import PayRunCSVUploader from './PayRunCSVUploader';

const UploadNewPayRunCSV: FC = () => {
  return (
    <>
      <Row
        css={css`
          padding: 32px;

          border: 0.5px solid #e0e0e0;
          border-radius: 4px;
        `}>
        <Col span={24} xl={12}>
          <PayRunCSVUploader />
        </Col>
      </Row>
    </>
  );
};

export default UploadNewPayRunCSV;
