import React, { FC, useEffect, useState } from 'react';

import { Col, Divider, Form, Row } from 'antd';
import qs from 'qs';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { AlertMessage, B, H, InputComp, PrimaryButton } from '../../components';
import { InputPasswordComp } from '../../components/InputPasswordComp';
import { CheckBoxComp } from '../../components/v2/CheckBoxComp';
import { loginByToken, loginUser, UserLoginI } from '../../store/actions';
import { DispatchType, RootState } from '../../store/reducer';
import theme from '../../theme';
import HumanisedFooter from './shared/HumanisedFooter';
import HumanisedHeader from './shared/HumanisedHeader';

const FormContainer = styled(Form)`
  max-width: 500px;
  padding: 40px 24px;
  margin: 0 auto;
  @media (min-width: 768px) and (max-width: 1920px) {
    width: 420px;
  }
`;
const SignInContainer = styled.div<{ showMessage: boolean }>`
  display: flex;
  height: ${(Props) => (Props.showMessage ? '77vh' : '87vh')};
  justify-content: center;
  align-items: center;
  @media screen and (max-height: 620px) {
    align-items: normal;
    height: auto;
  }
`;
const SignInMessage = styled.div<{ showMessage: boolean }>`
  display: flex;
  height: ${(Props) => (Props.showMessage ? '10vh' : '')};
  justify-content: center;
  align-items: center;
`;
const SignInForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 0px 50px 0px;
  border: 1px solid ${theme.gray200};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.0501803);
  border-radius: 4px;
  background: ${theme.white};
  @media screen and (max-width: 768px) {
    border: none;
    box-shadow: none;
    border-radius: 0px;
    background: transparent;
  }
  @media screen and (max-height: 620px) {
    margin: 40px 0px 25px 0px;
  }
`;

const SignInFooterForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DividerContainer = styled(Divider)`
  .ant-divider {
    border-top: 1px solid ${theme.gray300};
  }
`;
const BackgroundContainer = styled.div`
  background: ${theme.gray50};
`;

type LocationState = Partial<{
  token: string;
  redirectTo: string;
  message: string;
}>;

const Login: FC = () => {
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();
  const queryClient = useQueryClient();
  const [showMessage, setShowMessage] = useState(false);
  const me = useSelector((state: RootState) => state.people.me);
  const loginLoading = useSelector(
    (state: RootState) => state.auth.loginLoading,
  );

  const location = useLocation();

  async function handleSubmit(data: UserLoginI) {
    const organizationIds = location.search.match(/organizationId=[0-9]*/gi);
    if (organizationIds && organizationIds.length) {
      await dispatch(
        loginUser(
          data,
          Number(organizationIds[0].replace('organizationId=', '')),
        ),
      );
    } else {
      await dispatch(loginUser(data));
    }
  }

  useEffect(() => {
    if (location.state) {
      setShowMessage(true);
    }
  }, [location.state]);

  const handleAutoLogin = () => {
    const queries = qs.parse(location.search.replace('?', ''));
    const token = queries['token'];
    if (token && typeof token === 'string') {
      dispatch(loginByToken(token));
    }
  };

  useEffect(() => {
    handleAutoLogin();
  }, [location.search]);

  useEffect(() => {
    // clear react query cache when user comes to the login screen
    queryClient.clear();
  }, []);

  useEffect(() => {
    if (me) {
      const queries = qs.parse(location.search.replace('?', ''));
      const redirectTo = queries['redirectTo'];
      if (redirectTo && typeof redirectTo === 'string') {
        navigate(redirectTo);
      } else {
        navigate('/');
      }
    }
  }, [me]);

  return (
    <BackgroundContainer>
      <HumanisedHeader />
      {showMessage && (
        <SignInMessage showMessage={showMessage}>
          <AlertMessage
            width={420}
            title={(location.state as LocationState).message}
            type="success"
            onClose={() => setShowMessage(false)}
          />
          <br />
        </SignInMessage>
      )}
      <SignInContainer showMessage={showMessage}>
        <SignInForm>
          <FormContainer
            name="from"
            initialValues={{ keepLoggedIn: false }}
            validateTrigger="onBlur"
            onFinish={(v) => handleSubmit(v as UserLoginI)}>
            <H type="h6">Sign In</H>
            <B type="b-default" color={theme.gray600} pb="24px">
              Enter sign-in details and access your account.
            </B>
            <B type="b-default-semibold" pb="4px" color={theme.blue700}>
              Email
            </B>
            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'Please enter a valid email address',
                },
                {
                  required: true,
                  message: 'Please enter your email',
                },
              ]}>
              <InputComp
                name="email"
                type="email"
                placeholder="Enter your email"
              />
            </Form.Item>
            <Row>
              <Col span={12}>
                <B type="b-default-semibold" pb="4px" color={theme.blue700}>
                  Password
                </B>
              </Col>
              <Col span={12}>
                <Row justify="end">
                  <Link to="/forgetPassword">
                    <B type="b-default" pb="4px" color={theme.blue500}>
                      Forget Password?
                    </B>
                  </Link>
                </Row>
              </Col>
            </Row>
            <Form.Item
              css={css`
                margin-bottom: 19px;
              `}
              name="password"
              rules={[
                { required: true, message: 'Please enter your password' },
              ]}>
              <InputPasswordComp
                type="password"
                size="large"
                name="password"
                placeholder="Enter your password"
              />
            </Form.Item>
            <Form.Item
              name="keepLoggedIn"
              valuePropName="checked"
              css={css`
                margin-bottom: 18px;
              `}>
              <CheckBoxComp>Remember me next time</CheckBoxComp>
            </Form.Item>
            <Row>
              <PrimaryButton block loading={loginLoading} htmlType="submit">
                Sign In
              </PrimaryButton>
            </Row>
            <DividerContainer />
            <SignInFooterForm>
              <B type="b-default-semibold">New to Humanised?</B>
              <a href="https://www.gethumanised.com/small-business-payroll-team-info/">
                <B type="b-default" color={theme.blue500} pl="8px">
                  Request free demo
                </B>
              </a>
            </SignInFooterForm>
          </FormContainer>
        </SignInForm>
      </SignInContainer>
      <HumanisedFooter />
    </BackgroundContainer>
  );
};

export default Login;
