import React, { FC } from 'react';
import { Div } from './Div';

interface Props {
  children?: string | React.ReactNode;
  border: string;
  backgroundColor: string;
  display: string;
  justifyContent: string;
  verticalAlignment: string;
}

const Noticecard: FC<Props> = ({
  border,
  backgroundColor,
  children,
  display,
  justifyContent,
  verticalAlignment,
}) => {
  return (
    <>
      <Div
        display={display}
        justifyContent={justifyContent}
        alignItems={verticalAlignment}
        border={border}
        background={backgroundColor}
        p="12px 17px"
        mb={24}
        borderRadius="4px">
        {children}
      </Div>
    </>
  );
};

export default Noticecard;
