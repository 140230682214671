import { Col, Row, Spin } from 'antd';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { B, Div, RadioButton, RadioGroup } from '../../../../components';
import theme from '../../../../theme';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';
import { SalaryJournalExport } from './SalaryJournalExport';
import { v4 as uuidv4 } from 'uuid';
import SalaryJournal from './SalaryJournal';
import { useGetAllPublishPayrollSummary } from '../../../../api/payrollHooks';
import PayslipDelivery from './PayslipDelivery';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducer';
import styled from '@emotion/styled';
import { BlackPrinter } from '../../../../assets';

type payrollParam = {
  payrollKey: string;
};

export const PublishPayrollSummary: FC = () => {
  const params = useParams<payrollParam>();
  const printRef = useRef<HTMLDivElement | null>(null);
  const [tab, setTab] = useState<string>('salaryJournal');
  const {
    isLoading: loadingGetAllPublishPayrollSummary,
    data: dataGetAllPublishPayrollSummary,
    mutate: getAllPublishPayrollSummary,
  } = useGetAllPublishPayrollSummary();

  const singlePayrollData = useSelector(
    (state: RootState) => state.payroll.singlePayrollData,
  );

  useEffect(() => {
    getAllPublishPayrollSummary(Number(params.payrollKey));
  }, [params]);

  const haveDeduction =
    dataGetAllPublishPayrollSummary?.deductionsGroup.length == 0;

  const [isSalaryJournal, setIsSalaryJournal] = useState(true);

  const GreyDiv = styled(Div)`
    border: 1px solid ${theme.gray300};
    background: ${theme.gray50};
    p: 24px 32px;
    m: 5px -31px;
    @media (min-width: 768px) {
      height: 70vh;
    }
  `;

  const InnerWhiteDiv = styled.div`
    border: 1px solid ${theme.gray300};
    background: ${theme.white};
    margin: 5px 5px 70px;
    @media (min-width: 768px) {
      margin: 10px 10px 70px;
    }

    @media print {
      break-after: page;
      .testing {
        -webkit-print-color-adjust: exact;
      }
    }
  `;

  const StyledCol = styled(Col)`
    text-align: right;
    padding-right: 3px;
    display: flex;
    justify-content: flex-end;
    @media print {
      display: none;
    }
  `;

  return (
    <GreyDiv>
      <InnerWhiteDiv id="summary" ref={printRef}>
        <Spin spinning={loadingGetAllPublishPayrollSummary}>
          <Div key={uuidv4()} padding="32px 40px 12px 40px">
            <Row>
              <Col md={12} lg={12} xl={12} xxl={12}>
                <RadioGroup value={tab}>
                  <RadioButton
                    value="salaryJournal"
                    onClick={() => {
                      setIsSalaryJournal(true);
                      setTab('salaryJournal');
                    }}>
                    Salary Journal
                  </RadioButton>
                  <RadioButton
                    disabled={
                      !Boolean(
                        singlePayrollData?.payslipsSentDate ||
                          singlePayrollData?.scheduleDate,
                      )
                    }
                    value="payslipOutbox"
                    onClick={() => {
                      setIsSalaryJournal(false);
                      setTab('payslipOutbox');
                    }}>
                    Payslip Outbox
                  </RadioButton>
                </RadioGroup>
              </Col>
              <StyledCol md={12} lg={12} xl={12} xxl={12}>
                {isSalaryJournal && (
                  <>
                    {dataGetAllPublishPayrollSummary && (
                      <SalaryJournalExport
                        payload={dataGetAllPublishPayrollSummary}
                        payrunType={singlePayrollData?.payrunType}
                      />
                    )}
                    {printRef.current ? (
                      <ReactToPrint
                        trigger={() => (
                          <img
                            src={BlackPrinter}
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                        content={() => printRef.current}></ReactToPrint>
                    ) : null}
                  </>
                )}
              </StyledCol>
            </Row>
            <Div mt="16px">
              <B color={theme.gray600} type="b-default">
                Period :{' '}
                {moment(
                  dataGetAllPublishPayrollSummary?.payrollStartDate,
                ).format('MMMM DD')}
                &nbsp;-&nbsp;
                {moment(dataGetAllPublishPayrollSummary?.payrollEndDate).format(
                  'MMMM DD',
                )}{' '}
                | Employees : {dataGetAllPublishPayrollSummary?.noOfEmployees}
              </B>
            </Div>
            {isSalaryJournal ? (
              <SalaryJournal
                haveDeduction={haveDeduction}
                allPublishPayrollSummary={
                  dataGetAllPublishPayrollSummary
                    ? dataGetAllPublishPayrollSummary
                    : null
                }
                payrunType={singlePayrollData?.payrunType}
              />
            ) : (
              <PayslipDelivery />
            )}
          </Div>
        </Spin>
      </InnerWhiteDiv>
    </GreyDiv>
  );
};

export default PublishPayrollSummary;
