import React, { FC } from 'react';

import { Col, Form, Row } from 'antd';
import styled from '@emotion/styled';
import { Validation } from '../../../common/validation';
import { Divider, B, InputComp } from '../../../components';
import theme from '../../../theme';

const MainContainer = styled.div`
  padding: 32px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    padding: 24px 10px;
  }
`;

const FormItems = styled(Form.Item)`
  margin-bottom: 0px;
`;

const ETFInformation: FC = () => {
  return (
    <>
      <MainContainer>
        <B type="b-large-semibold" pb="12px">
          EPF/ETF Information
        </B>
        <Row gutter={[32, 16]}>
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
            <FormItems
              name="epfZone"
              label="Zone*"
              normalize={(value) => value.toUpperCase()}
              rules={[
                {
                  required: true,
                  message: 'Please enter your EPF zone letter',
                },
                {
                  type: 'string',
                  max: 2,
                  message: 'Please enter only two letters',
                },
              ]}>
              <InputComp size="small" placeholder="EPF Zone" />
            </FormItems>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <FormItems
              name="epf"
              label="EPF*"
              rules={[
                {
                  required: true,
                  message: 'Please enter your EPF registration number',
                },
                {
                  type: 'string',
                  pattern: Validation.NumberRegex,
                  message: 'Invalid EPF registration number',
                  max: 6,
                },
              ]}>
              <InputComp size="small" placeholder="Company EPF" />
            </FormItems>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <FormItems
              name="etf"
              label="ETF*"
              rules={[
                {
                  required: true,
                  message: 'Please Enter your ETF registration number',
                },
                {
                  type: 'string',
                  pattern: Validation.NumberRegex,
                  message: 'Invalid ETF registration number',
                  max: 6,
                },
              ]}>
              <InputComp size="small" placeholder="Company ETF" />
            </FormItems>
          </Col>
        </Row>
      </MainContainer>
      <Divider />
    </>
  );
};

export default ETFInformation;
