import React, { FC } from 'react';
import styled from '@emotion/styled';
import { B, H } from '..';
import { Link } from 'react-router-dom';
import { LogoSVG } from '../../assets';

const Content = styled.div`
  text-align: center;
  width: 542px;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 77vh;
`;
const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 100px;
  line-height: 100px;
  margin: 0px 0px 16px;
`;

const ErrorPage: FC = () => {
  return (
    <Container>
      <Content>
        <img src={LogoSVG} />
        <Title>Oops!</Title>
        <H type="h4" pb="10px">
          We can't seem to find the page you're looking for
        </H>

        <Text>
          <Link to="/People">
            <B type="b-large" color="#0052EA">
              Go back
            </B>
          </Link>
          &nbsp;
          <B type="b-large" color="#757575">
            and try again.
          </B>
        </Text>
      </Content>
    </Container>
  );
};
export default ErrorPage;
