import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import qs from 'qs';

import App from './App';
import { BASE_URL, isProduction, SENTRY_URL } from './configs';
import * as serviceWorker from './serviceWorker';

import { logOutUser } from './store/actions';
import * as FullStory from '@fullstory/browser';
import { getCurrentAuthPayload, getCurrentAuthToken } from './util/storageUtil';
import { setupAxiosToken } from './util/api';

import RootProvider from './providers/RootProvider';
import { store } from './store';

// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';

// if (SENTRY_URL) {
//   Sentry.init({
//     dsn: SENTRY_URL,
//     integrations: [new BrowserTracing()],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 0.1,
//   });
// }

FullStory.init({
  orgId: '10Z4FR',
  devMode: !isProduction,
});

axios.defaults.baseURL = BASE_URL;
axios.defaults.timeout = 180000;
setupAxiosToken(getCurrentAuthToken());

axios.interceptors.request.use(async (request) => {
  if (request.data && request.headers) {
    if (
      request.headers['Content-Type'] === 'application/x-www-form-urlencoded'
    ) {
      request.data = qs.stringify(request.data);
    }
  }
  const currentAuthPayload = getCurrentAuthPayload();
  const currentEpochTime = Math.round(Date.now() / 1000);
  if (currentAuthPayload && currentAuthPayload.jwtExpire < currentEpochTime) {
    // Logoutting if it is expired
    await store.dispatch(logOutUser());
  }
  return request;
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <RootProvider>
    <App />
  </RootProvider>,
);

serviceWorker.unregister();
