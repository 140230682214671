import React, { FC } from 'react';
import { Form, Input } from 'antd';
import styled from '@emotion/styled';
import { B } from '../../../components';
import { ModalComponent } from '../../../components/ModalComponent';
import theme from '../../../theme';
import { ReviewI } from '../../../types';

const FormItem = styled(Form.Item)`
  margin-bottom: 8px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
`;

const TextArea = styled(Input.TextArea)`
  border-radius: 4px;
`;

interface Props {
  visible: boolean;
  handleCancel: () => void;
  onSubmit: (values: ReviewI) => void;
}

const LeaveRejectModal: FC<Props> = ({ visible, handleCancel, onSubmit }) => {
  const [form] = Form.useForm();

  const onFinish = async (values: ReviewI) => {
    onSubmit(values);
  };

  return (
    <ModalComponent
      form={form}
      width={585}
      visible={visible}
      onCancel={handleCancel}
      submitText="Reject"
      redColorSubmitButton={true}
      title="Are you sure you want to reject leave ?">
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        wrapperCol={{ span: 50 }}
        name="requestLeave">
        <FormItem
          name="reasonForRejection"
          label="Rejection Note"
          rules={[
            {
              required: true,
              message: 'Please enter Rejection Note',
            },
          ]}>
          <TextArea placeholder="Please leave a note regarding why you want to reject this leave" />
        </FormItem>
        <B type="b-small">This note will be display for employee as well</B>
      </Form>
    </ModalComponent>
  );
};

export default LeaveRejectModal;
