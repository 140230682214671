import React, { FC, useState, useEffect } from 'react';
import { B } from '../../../components';
import { Document, Page } from 'react-pdf';
import {
  AssignableUsersI,
  AssignedPlaceholderDataI,
} from '../../../types/contract.types';
import PlaceholderIndicator from '../shared/PlaceholderIndicator';
import { Spin } from 'antd';
import { useGetAssignedUsers } from '../utils/assignedUsers';
import theme from '../../../theme';
import styled from '@emotion/styled';
import { NumberBooleanObject } from '../../../types';
interface FlagState {
  firstSignatoryFlags: NumberBooleanObject;
  secondSignatoryFlags: NumberBooleanObject;
}

interface Props {
  url: string;
  placeholderDetails: AssignedPlaceholderDataI[];
  assignedUsers: AssignableUsersI[];
}
const DocSpinner = styled(Spin)`
  display: block;
  margin: 0 auto;
`;

const PageStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 42px;
`;
const PDFPageContainer = styled.div`
  position: relative;
  max-width: 130px;
  margin: 0 auto;
`;

const DocumentPage = styled(Document)`
  cursor: pointer;
  .react-pdf__Page__textContent {
    border: none;
    border-radius: 0px;
    display: none;
  }
  .react-pdf__Page__annotations.annotationLayer {
    display: none;
  }
  .react-pdf__Page {
    margin-bottom: 24px;
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
    max-width: 130px !important;
    max-height: 186px !important;
    border: 1.5px solid ${theme.gray300};
    box-sizing: border-box;
  }
  .active {
    .react-pdf__Page__canvas {
      border: 2px solid ${theme.blue500};
    }
  }
`;

const PagesDisplayCard: FC<Props> = ({
  url,
  placeholderDetails,
  assignedUsers,
}) => {
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [flags, setFlags] = useState<FlagState>({
    firstSignatoryFlags: {},
    secondSignatoryFlags: {},
  });
  const { firstSignatory, secondSignatory } =
    useGetAssignedUsers(assignedUsers);

  function onDocumentLoadSuccess(numPages: any) {
    setNumPages(numPages.numPages);
  }

  const scroll = (id: number) => {
    setCurrentPage(id);
    const el = document.getElementById(`page_head_${id + 1}`);
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const firstSignatoryFlags: NumberBooleanObject = {};
    const secondSignatoryFlags: NumberBooleanObject = {};

    placeholderDetails.forEach((item) => {
      const assignedId = String(item.assignedUserId);
      if (assignedId == String(firstSignatory?.id)) {
        firstSignatoryFlags[item.pageNumber] = true;
      } else if (assignedId == String(secondSignatory?.id)) {
        secondSignatoryFlags[item.pageNumber] = true;
      }
    });

    setFlags({ firstSignatoryFlags, secondSignatoryFlags });
  }, [placeholderDetails, firstSignatory, secondSignatory]);

  return (
    <>
      <PageStatusContainer>
        <B type="b-small-semibold"> Pages </B>
        <B type="b-small-semibold" color={theme.gray600}>
          {currentPage + 1} of {numPages}{' '}
        </B>
      </PageStatusContainer>

      <DocumentPage
        file={url}
        loading={<DocSpinner />}
        onLoadSuccess={onDocumentLoadSuccess}
        noData={!url ? null : <>No Data</>}
        onLoadError={console.error}>
        {Array.from(new Array(numPages), (_el, index) => (
          <PDFPageContainer key={index} onClick={() => scroll(index)}>
            <PlaceholderIndicator
              firstSignatory={flags.firstSignatoryFlags[index]}
              secondSignatory={flags.secondSignatoryFlags[index]}
            />
            <Page
              className={`page_${index} ${
                Boolean(index === currentPage) && 'active'
              }`}
              id={`page_${index + 1}`}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          </PDFPageContainer>
        ))}
      </DocumentPage>
    </>
  );
};
export default PagesDisplayCard;
