import React, { FC, PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { IndividualPeopleStateProvider } from './IndividualPeopleState';

import { BrowserRouter } from 'react-router-dom';
import { store } from '../store';

const RootProvider: FC<PropsWithChildren> = ({ children }) => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <IndividualPeopleStateProvider>
            {children}
          </IndividualPeopleStateProvider>
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default RootProvider;
