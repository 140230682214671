import { Moment } from "moment";
import { ComBankParams, BanksEnum } from "./expense.types";
import { UserHistoryI } from "./people.types";

export enum FilterTypes {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  SETTLED = 'SETTLED ',
  ARCHIVED = 'ARCHIVED',
}

export enum LoanStatus {
  SETTLED = 'SETTLED',
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
}

export enum PaymentMethod {
  PAYROLL = 'PAYROLL',
  EXTERNAL_FUNDS = 'EXTERNAL FUNDS'
}

export enum LoanTypeEnum {
  DEFAULT = 'DEFAULT',
  ADVANCE_PAYMENT = 'ADVANCE_PAYMENT'
}
export interface LoanUserI {
  employeeNumber: string;
  fullName: string;
  userHistory: UserHistoryI[];
}

export interface LoanI {
  id: number;
  loanId: string;
  userId: number;
  user: LoanUserI;
  loanAmount: number;
  interestAmount: number;
  paidAmount: number;
  balanceAmount: number;
  loanType: string;
  releaseDate: string;
  loanPeriod: number;
  loanInterest: number;
  maturityDate: string;
  installmentAmount: number;
  noOfInstallments: number;
  guarantor: LoanUserI;
  guarantorId: number;
  loanReleasedToEmployee: boolean;
  notes: AddLoanNoteI[];
  document: LoanDocumentI[];
  loanStatus: LoanStatus;
  installment: LoanInstallmentI[];
}

export interface LoanInstallmentI {
  installmentDate: string;
  paymentMethod: string;
  installmentStatus: string;
  amount: number;
}

export interface EmployeeBankDetailsI {
  employeeName: string;
  loanAmount: number;
}

export interface LoanCreateI {
  loanId: string;
  loanAmount: string;
  loanType: string;
  releaseDate: Moment;
  loanPeriod: string;
  loanInterest?: string;
  maturityDate: Moment;
  installmentAmount: string;
  noOfInstallments: string;
  guarantorId?: string;
  loanReleasedToEmployee: string;
  note?: string[];
  documentFiles?: File[];
  userId?: string;
}

export interface AddLoanNoteI {
  id?: string | number;
  loanId?: number;
  note: string;
  updatedAt?: string;
}

export interface LoanDocumentI {
  id?: string | number;
  loanId?: number;
  name: string;
  document: File;
  fileUrl?: string;
  updatedAt?: string;
}

export interface UpdateLoanI {
  id: number;
  data: FormData;
}

export interface ArchiveLoanStatusI {
  id: number;
}

export interface SettleLoanDataI {
  id: number;
  data: SettleLoanI;
}

export interface SettleLoanI {
  paymentMethod: string;
  amount: number;
  markAsPaid: boolean;
}

export interface LoanGenerateBulkI {
  type: ComBankParams | BanksEnum;
  remark: string;
  loanIds: number[];
  dateOfCrediting?: string;
  valueDate?: string;
  total: number;
}

export interface LoanGeneratedBankFilesI {
  createdAt: string;
  bankName: string;
  dateOfCrediting?: string;
  valueDate?: string;
  transactionReference: string;
  total: number;
  fileUrl: string;
}