import React, { FC } from 'react';
import { B } from './B';
import { Div } from './Div';

interface GroupCardBodyProps {
  title: string;
  body: string | number;
  prefix?: string | number;
}
export const CardBody: FC<GroupCardBodyProps> = ({ title, prefix, body }) => {
  return (
    <>
      <B type="b-default" color="#757575">
        {title}
      </B>
      <Div display="flex" alignItems="flex-end">
        {prefix && (
          <B type="b-small" color="#757575" pr="4px">
            {prefix}
          </B>
        )}
        <B type="b-large-semibold" color="#000000">
          {body}
        </B>
      </Div>
    </>
  );
};
