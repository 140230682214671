import React, { FC, useEffect, useState } from 'react';

import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useCreatePayslipField } from '../../../../../api/payrollHooks';
import { B, Div } from '../../../../../components';
import { getAllPayrollDetails } from '../../../../../store/actions';
import { CustomPayslipField } from '../../../../../store/modules/payroll';
import { DispatchType, RootState } from '../../../../../store/reducer';
import theme from '../../../../../theme';
import { getApiError } from '../../../../../util/getApiError';
import AddEditPayslipField from './AddEditPayslipField';
import CustomPaySlipField from './CustomPaySlipField';
import { SalaryInformationView } from '../../../../../types/payroll.types';
import { payrollParam } from '../../../../../types/payslipPageTypes';

export const CustomFieldsSection: FC = () => {
  const params = useParams<payrollParam>();
  const dispatch: DispatchType = useDispatch();

  const [visible, setVisible] = useState(false);

  const [payslipFields, setPayslipFields] = useState<CustomPayslipField[]>();

  const payrollDetails = useSelector(
    (state: RootState) => state.payroll.allPayrollDetails,
  );
  const empPayroll = payrollDetails?.empPayroll.find(
    (item) => item.userId === Number(params.employeeKey),
  );

  const isCompletedPayRun = payrollDetails?.status === 'COMPLETED';

  const {
    error: errorCreatePayslipField,
    data: dataCreatePayslipField,
    mutate: createPayslipField,
  } = useCreatePayslipField();

  // success handling
  useEffect(() => {
    if (dataCreatePayslipField && !errorCreatePayslipField) {
      message.success('Created Successfully!');
      payrollDetails &&
        dispatch(getAllPayrollDetails(payrollDetails?.id.toString()));
    }
  }, [dataCreatePayslipField]);

  // Error handling
  useEffect(() => {
    if (errorCreatePayslipField) {
      message.error(getApiError(errorCreatePayslipField));
    }
  }, [errorCreatePayslipField]);

  useEffect(() => {
    const empPayroll = payrollDetails?.empPayroll.find(
      (item) => item.userId === Number(params.employeeKey),
    );
    setPayslipFields(empPayroll?.payslipFields);
  }, [payrollDetails, params.employeeKey]);

  const onClickAdd = ([key, value]: [string, string]) => {
    if (empPayroll) {
      createPayslipField({ empPayrollId: empPayroll?.id, key, value });
    }
  };

  if (isCompletedPayRun && !payslipFields) {
    return null;
  }

  return (
    <>
      <Div p="0px 0px 18px">
        {empPayroll &&
          payslipFields &&
          payslipFields.map((item) => {
            if (empPayroll) {
              return (
                <CustomPaySlipField
                  payrollId={empPayroll.payrollId}
                  empPayrollId={empPayroll.id}
                  keyName={item.key}
                  value={item.value}
                  isCompletedPayRun={isCompletedPayRun}
                />
              );
            }
            return null;
          })}
        {!isCompletedPayRun &&
          params.salaryInformationViewType ===
            SalaryInformationView.ALL_EMPLOYEE && (
            <div>
              <B
                pl="24px"
                onClick={() => setVisible(true)}
                type="b-default"
                color={theme.blue500}>
                Create New
              </B>
            </div>
          )}
      </Div>
      {visible && (
        <AddEditPayslipField
          onClickAdd={([key, value]) => onClickAdd([key, value])}
          setVisible={(visible) => {
            setVisible(visible);
          }}
        />
      )}
    </>
  );
};
