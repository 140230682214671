import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Div, LogTrailTable } from '../../../../components';
import { getIntegrationLogTrails } from '../../../../store/modules/integration';
import { DispatchType, RootState } from '../../../../store/reducer';

const IntegrationLogTrail: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const loadingIntegrations = useSelector(
    (state: RootState) => state.integration.loadingIntegrations,
  );
  const integrationSettings = useSelector(
    (state: RootState) => state.integration.integrationSetting,
  );
  const integrationLogs = useSelector(
    (state: RootState) => state.integration.integrationLogs,
  );
  useEffect(() => {
    if (integrationSettings) {
      dispatch(getIntegrationLogTrails(integrationSettings.id));
    }
  }, [integrationSettings]);

  return (
    <Div marginTop="20px" marginBottom="30px">
      <LogTrailTable
        loading={loadingIntegrations}
        logs={integrationLogs.map((item) => {
          return { ...item, mapper: item.mapper || {} };
        })}
      />
    </Div>
  );
};

export default IntegrationLogTrail;
