import React, { FC, useEffect, useState } from 'react';
import { message } from 'antd';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import {
  useAddPayDetails,
  useDeletePeoplePayDetails,
  useUpdateUserPayDetails,
} from '../../../../api/peopleHooks';
import { B, Div, FooterComponent, PrimaryButton } from '../../../../components';
import {
  SalaryContainer,
  SalaryItem,
} from '../../../../components/SalarySlip/SalaryItem';
import { useIndividualPeopleState } from '../../../../providers/IndividualPeopleState';
import theme from '../../../../theme';
import {
  PayrollItemI,
  PayrollItemTypeEnum,
  PayTitleEnum,
} from '../../../../types/payroll.types';
import { numberThousandSeparator } from '../../../../util/utils';
import { getSalaryItemsByType } from '../../../payroll/pages/payslip/utils/getSalaryItemsByType';
import { RegulatoryComplianceDataItem } from '../addEmployeeManually/shared/RegulatoryComplianceDataItem';
import { RemunerationModal } from './RemunerationModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducer';
import { BasedCurrencyEnum } from '../../../../types/organization.types';
import { useNavigate } from 'react-router-dom';

const FormBody = styled.div`
  padding: 24px 32px 8px;
  backgroundColor= ${theme.white};
  @media only screen and (max-width: 1200px) {
    padding: 32px 24px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0px;
  }
`;

const PayAmount = styled.div`
  margin-left: auto;
  display: flex;
  padding-right: 30px;
`;
const NetPay = styled.div`
  margin-left: auto;
  display: flex;
  padding-right: 32px;
`;
type UseParamI = {
  employeeKey: string;
};

const SalaryInfo: FC = () => {
  const params = useParams<UseParamI>();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState<PayrollItemTypeEnum>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedPayItem, setSelectedPayItem] = useState<PayrollItemI | null>(
    null,
  );
  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const {
    state: { people },
    fetchPeopleById,
  } = useIndividualPeopleState();

  const {
    mutate: deletePeoplePayDetails,
    isSuccess: deletePeoplePayDetailSuccess,
  } = useDeletePeoplePayDetails();

  const { mutate: updateUserPayDetail, isSuccess: UpdateUserPayDetailSuccess } =
    useUpdateUserPayDetails();

  const { mutate: addUserPayDetail, isSuccess: addPayDetailSuccess } =
    useAddPayDetails();

  const itemByTypes = getSalaryItemsByType(
    people?.userPayItems ? people.userPayItems : [],
  );

  const onClickEdit = (item: PayrollItemI) => {
    setModalVisible(true);
    setSelectedPayItem(item);
  };

  const onHandleClose = () => {
    setModalVisible(false);
    setSelectedPayItem(null);
  };

  const onClickAdd = (PayrollItemType: PayrollItemTypeEnum) => {
    setModalVisible(true);
    setReadOnly(PayrollItemType);
  };
  const onDeletePayItem = async (itemId: number | undefined) => {
    if (itemId) {
      await deletePeoplePayDetails(itemId);
      fetchPeopleById(params.employeeKey);
    } else {
      message.error('payroll item not found');
    }
  };
  const RegulatoryComplianceData = people?.calculation
    ? Object.entries(people?.calculation).map(([key, v]) => ({
        payTitle: key,
        amount: v,
        isEpf: false,
        isTaxable: false,
      }))
    : [];

  useEffect(() => {
    if (
      deletePeoplePayDetailSuccess ||
      addPayDetailSuccess ||
      UpdateUserPayDetailSuccess
    ) {
      fetchPeopleById(params.employeeKey);
    }
  }, [
    deletePeoplePayDetailSuccess,
    addPayDetailSuccess,
    UpdateUserPayDetailSuccess,
  ]);

  const itemByBasic = itemByTypes[PayrollItemTypeEnum.BASIC];
  const itemByFixedAllowances =
    itemByTypes[PayrollItemTypeEnum.FIXED_ALLOWANCE];
  const itemByVariableAllowances =
    itemByTypes[PayrollItemTypeEnum.VARIABLE_ALLOWANCE];
  const itemByNonCashBenefits =
    itemByTypes[PayrollItemTypeEnum.NON_CASH_BENEFITS];
  const itemByLumpSum = itemByTypes[PayrollItemTypeEnum.LUMP_SUM];
  const itemByDeductions = itemByTypes[PayrollItemTypeEnum.DEDUCTION];
  return (
    <>
      <FormBody>
        <B type="b-large-semibold" pb="22px">
          Earnings
        </B>
        <SalaryContainer>
          <SalaryItem
            title="Basic Salary"
            onClickAdd={() => {
              onClickAdd(PayrollItemTypeEnum.BASIC);
            }}
            onClickEdit={(item) => onClickEdit(item)}
            onClickDelete={(item) => onDeletePayItem(item.id)}
            salaryItems={itemByBasic && itemByBasic.length ? itemByBasic : []}
          />
        </SalaryContainer>
        <SalaryContainer>
          <SalaryItem
            title="Fixed Allowances"
            onClickAdd={() => {
              onClickAdd(PayrollItemTypeEnum.FIXED_ALLOWANCE);
            }}
            onClickEdit={(item) => onClickEdit(item)}
            onClickDelete={(item) => onDeletePayItem(item.id)}
            salaryItems={
              itemByFixedAllowances && itemByFixedAllowances.length
                ? itemByFixedAllowances
                : []
            }
          />
        </SalaryContainer>
        <SalaryContainer>
          <SalaryItem
            title="Variable Allowances"
            onClickAdd={() => {
              onClickAdd(PayrollItemTypeEnum.VARIABLE_ALLOWANCE);
            }}
            onClickEdit={(item) => onClickEdit(item)}
            onClickDelete={(item) => onDeletePayItem(item.id)}
            salaryItems={
              itemByVariableAllowances && itemByVariableAllowances.length
                ? itemByVariableAllowances
                : []
            }
          />
        </SalaryContainer>
        <SalaryContainer>
          <SalaryItem
            title="Non-Cash Benefits"
            onClickAdd={() => {
              onClickAdd(PayrollItemTypeEnum.NON_CASH_BENEFITS);
            }}
            onClickEdit={(item) => onClickEdit(item)}
            onClickDelete={(item) => onDeletePayItem(item.id)}
            salaryItems={
              itemByNonCashBenefits && itemByNonCashBenefits.length
                ? itemByNonCashBenefits
                : []
            }
            status="DRAFTED"
          />
        </SalaryContainer>
        {itemByLumpSum.length > 0 && (
          <SalaryContainer>
            <SalaryItem
              title="Lump Sum Payments"
              nonEditable={true}
              onClickDelete={(item) => onDeletePayItem(item.id)}
              salaryItems={
                itemByLumpSum && itemByLumpSum.length ? itemByLumpSum : []
              }
            />
          </SalaryContainer>
        )}
        <SalaryContainer>
          <SalaryItem
            onClickAdd={() => {
              onClickAdd(PayrollItemTypeEnum.DEDUCTION);
            }}
            onClickEdit={(item) => onClickEdit(item)}
            title="Deductions"
            onClickDelete={(item) => onDeletePayItem(item.id)}
            salaryItems={
              itemByDeductions && itemByDeductions.length
                ? itemByDeductions
                : []
            }
          />
        </SalaryContainer>
        {people?.userPayItems && people?.userPayItems.length > 0 && (
          <>
            {organizationData?.peggedSettings?.pegged?.status &&
            organizationData?.peggedSettings?.peggedInfo?.basedCurrency ===
              BasedCurrencyEnum.FOREIGN_CURRENCY ? (
              <SalaryContainer>
                <Div
                  borderBottom={`1px solid ${theme.gray300}`}
                  py="7px"
                  background={theme.gray100}
                  display="flex"
                  borderRadius="2px"
                  mb="22px">
                  <NetPay>
                    <B type="b-small" color={theme.gray600} pt="2px">
                      Total (
                      {
                        organizationData?.peggedSettings?.peggedInfo
                          ?.foreignCurrencyType
                      }
                      )
                    </B>
                    &nbsp;
                    <B type="b-large-semibold" color={theme.black}>
                      {numberThousandSeparator(
                        Number(people?.calculation?.grossSalary) -
                          Number(people?.calculation?.grossDeduction) || 0,
                      )}
                    </B>
                  </NetPay>
                </Div>
              </SalaryContainer>
            ) : (
              <SalaryContainer>
                <Div
                  borderBottom={`1px solid ${theme.gray300}`}
                  py="7px"
                  background={theme.gray100}
                  display="flex"
                  borderRadius="2px"
                  mb="22px">
                  <NetPay>
                    <B type="b-small" color={theme.gray600} pt="2px">
                      Net Pay
                    </B>
                    &nbsp;
                    <B type="b-large-semibold" color={theme.black}>
                      {numberThousandSeparator(
                        people?.calculation?.netSalary || 0,
                      )}
                    </B>
                  </NetPay>
                </Div>
              </SalaryContainer>
            )}
            <br />
            {organizationData?.peggedSettings?.pegged?.status &&
            organizationData?.peggedSettings?.peggedInfo?.basedCurrency ===
              BasedCurrencyEnum.FOREIGN_CURRENCY ? null : (
              <>
                {people?.calculation?.employeeEPF != 0 ||
                people?.calculation?.employerETF != 0 ||
                people?.calculation?.apitTable1Amount != 0 ||
                people?.calculation?.withHoldTax != 0 ? (
                  <Div>
                    <B type="b-large-semibold" pb="22px">
                      Regulatory Compliance
                    </B>
                    {people?.calculation?.employeeEPF > 0 &&
                      !people.isEpfBorneByEmployer && (
                        <>
                          <RegulatoryComplianceDataItem
                            salaryItems={RegulatoryComplianceData.filter(
                              (item) =>
                                item.payTitle === PayTitleEnum.EMPLOYEE_EPF,
                            )}
                            title="Employee"
                          />
                          <br />
                        </>
                      )}
                    {people?.calculation?.employerETF > 0 && (
                      <>
                        {people.isEpfBorneByEmployer ? (
                          <RegulatoryComplianceDataItem
                            salaryItems={[
                              ...RegulatoryComplianceData.filter(
                                (item) =>
                                  item.payTitle === PayTitleEnum.EMPLOYEE_EPF ||
                                  item.payTitle === PayTitleEnum.EMPLOYER_EPF ||
                                  item.payTitle === PayTitleEnum.EMPLOYER_ETF,
                              ),
                            ]}
                            title="Employer"
                          />
                        ) : (
                          <RegulatoryComplianceDataItem
                            salaryItems={[
                              ...RegulatoryComplianceData.filter(
                                (item) =>
                                  item.payTitle === PayTitleEnum.EMPLOYEE_EPF ||
                                  item.payTitle === PayTitleEnum.EMPLOYER_EPF,
                              ),
                            ]}
                            title="Employer"
                          />
                        )}
                        <br />
                      </>
                    )}

                    {people?.calculation?.apitTable1Amount != 0 && (
                      <RegulatoryComplianceDataItem
                        salaryItems={[
                          ...RegulatoryComplianceData.filter(
                            (item) => item.payTitle === 'apitTable1Amount',
                          ),
                        ]}
                        title="APIT"
                      />
                    )}

                    {people?.calculation?.withHoldTax > 0 && (
                      <RegulatoryComplianceDataItem
                        salaryItems={[
                          ...RegulatoryComplianceData.filter(
                            (item) =>
                              item.payTitle === PayTitleEnum.WITH_HOLD_TAX,
                          ),
                        ]}
                        title="WHT"
                      />
                    )}
                  </Div>
                ) : null}

                <br />
                <Div py="10px" display="flex">
                  <B type="b-large-semibold" color={theme.black}>
                    Cost to company
                  </B>

                  <PayAmount>
                    <B type="b-large-semibold" color={theme.black}>
                      {numberThousandSeparator(
                        people?.calculation?.costToCompany || 0,
                      )}
                    </B>
                  </PayAmount>
                </Div>
              </>
            )}
          </>
        )}
        {people?.isPending && (
          <FooterComponent
            rightChildren={
              <PrimaryButton
                ml={16}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/contact/bank-details/${params.employeeKey}`);
                }}
                htmlType="submit">
                Next
              </PrimaryButton>
            }
          />
        )}
      </FormBody>
      {modalVisible && (
        <RemunerationModal
          visible={modalVisible}
          readOnly={readOnly}
          textTip="Changes made will only effect current pay run and not master
            salary details."
          payDetail={
            selectedPayItem ? { ...selectedPayItem, userId: '1' } : null
          }
          handleCancel={() => onHandleClose()}
          onSubmit={async (values) => {
            const value = {
              ...values,
              userId: params.employeeKey,
            };

            if (selectedPayItem && selectedPayItem.id) {
              updateUserPayDetail({
                id: String(selectedPayItem.id),
                data: value,
              });
            } else {
              addUserPayDetail(value);
            }
            onHandleClose();
          }}
          isEmployeeAddDetailPage={true}
          userData={people}
        />
      )}
    </>
  );
};

export default SalaryInfo;
