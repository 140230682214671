import { SelectValue } from 'antd/lib/select';
import moment from 'moment';

export const getPayrollStartEndDates = (
  payrunStartDate?: number,
  payrunMonth?: SelectValue,
  payrunYear?: SelectValue,
) => {
  const validDate = moment(payrunStartDate, 'DD')
    .month(String(payrunMonth))
    .year(Number(payrunYear));

  const endOfMonth = moment(validDate).endOf('month');
  const startOfMonth = moment(validDate).startOf('month');

  const diffBetweenStartOfMonth = validDate.diff(startOfMonth, 'day');
  const diffBetweenEndOfMonth = endOfMonth.diff(validDate, 'day');

  let startDate;
  let endDate;
  if (diffBetweenStartOfMonth < diffBetweenEndOfMonth) {
    startDate = moment(payrunStartDate, 'DD')
      .month(String(payrunMonth))
      .year(Number(payrunYear))
      .format();
    endDate = moment(payrunStartDate, 'DD')
      .month(String(payrunMonth))
      .year(Number(payrunYear))
      .add(1, 'months')
      .subtract(1, 'days')
      .format();
  } else {
    startDate = moment(payrunStartDate, 'DD')
      .month(String(payrunMonth))
      .year(Number(payrunYear))
      .subtract(1, 'months')
      .format();
    endDate = moment(payrunStartDate, 'DD')
      .month(String(payrunMonth))
      .year(Number(payrunYear))
      .subtract(1, 'days')
      .format();
  }

  return { startDate, endDate };
};

export const getPayrollMonthAndYear = (
  payrollStartDate: moment.Moment,
  payrollEndDate: moment.Moment,
  format = 'MMMM YYYY',
): string => {
  const endOfMonth = moment(payrollStartDate).endOf('month');
  const startOfMonth = moment(payrollEndDate).startOf('month');

  const firstMonthDays = endOfMonth.diff(payrollStartDate, 'day');
  const secondMonthDays = payrollEndDate.diff(startOfMonth, 'day');

  if (firstMonthDays > secondMonthDays) {
    return moment(payrollStartDate).format(format);
  } else {
    return moment(payrollEndDate).format(format);
  }
};
