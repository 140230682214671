import { LoadingOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Col, Result, Row, Spin } from 'antd';
import qs from 'qs';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router';
import { DefaultBody, H } from '../../../../components';
import {
  clearPayrollStatus,
  createPayslips,
  getAllPayrollDetails,
  PayslipI,
} from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import styled from '@emotion/styled';

type payrollParam = {
  payrollKey: string;
};
const Payslip = styled.div`
  background: #fdfdfd;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;
const SpinPayslip = styled.div`
  padding: 4px;
  padding-left: 16px;
  padding-bottom: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PayslipLoading: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const navigate = useNavigate();
  const params = useParams<payrollParam>();
  const location = useLocation();
  const payslipLoading = useSelector(
    (state: RootState) => state.payroll.payslipStatusLoading,
  );
  const payslipStatus = useSelector(
    (state: RootState) => state.payroll.payslipStatus,
  );
  const dataBody: PayslipI = location.state;

  useEffect(() => {
    const queryParams = qs.parse(location.search.replace('?', ''));
    dispatch(getAllPayrollDetails((params as payrollParam).payrollKey));
    if (typeof queryParams.bankType === 'string') {
      dispatch(createPayslips(dataBody, queryParams.bankType));
    }
  }, []);
  if (payslipStatus == 'Payslips Generated') {
    dispatch(clearPayrollStatus());
    navigate('/Payroll-publish/' + params.payrollKey);
  }
  const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;
  return (
    <>
      <DefaultBody>
        <Row gutter={[24, 8]}>
          <Col flex={1}>
            <Payslip>
              <H pb="24px" pt="24px" pl="24px" type="h6">
                Generating
              </H>

              {payslipLoading == true ? (
                <SpinPayslip>
                  <Spin indicator={antIcon} />
                </SpinPayslip>
              ) : payslipStatus != 'Payslips Generated' ? (
                <Result
                  title="Something went Wrong!"
                  subTitle="Hmm, that's strange. Payroll not found."
                />
              ) : (
                ''
              )}
            </Payslip>
          </Col>
        </Row>
      </DefaultBody>
    </>
  );
};
export default PayslipLoading;
