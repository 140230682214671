import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DataImportConfig,
  DATA_IMPORT_CONFIG,
  DEFAULT_IMPORT_CONFIG,
} from '../configs';
import { RootState } from '../store/reducer';

/**
 * The organization can have many configs or none. Every organization should have one, if it does not have any
 * it should send default one. We assumed that first time of the dataExtractorTypes is the default one :)
 * @returns DataImportConfig
 */
export const useGetDefaultDataExtractor = (): DataImportConfig => {
  const [config, setConfig] = useState(DEFAULT_IMPORT_CONFIG);
  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  useEffect(() => {
    if (organizationData && organizationData.dataExtractorTypes) {
      const types = organizationData.dataExtractorTypes.split(',');

      if (types.length) {
        // We assume that first item is always default one;
        const found = DATA_IMPORT_CONFIG.find(
          (item) => item.extractorType === types[0],
        );
        if (found) {
          setConfig(found);
        }
      }
    }
  }, [organizationData]);

  return config;
};
