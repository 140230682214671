import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { B, Div } from '../../../components';
import theme from '../../../theme';
import { DocumentStatusTag } from '../../../components/Tags/DocumentStatusTag';
import {
  AllContractsI,
  ContractStatusEnum,
  UserType,
} from '../../../types/contract.types';
import { Col, Row } from 'antd';

import moment from 'moment';
import { getContractSignedFractionWords } from '../utils/contractSignedFraction';

export const content = (contract: AllContractsI) => {
  const firstSignatoryStatus = contract?.contractStatus?.find(
    (status) =>
      status.firstSignatoryId && status.userType === UserType.FIRST_SIGNATORY,
  );

  const secondSignatoryStatus = contract?.contractStatus?.find(
    (status) =>
      status.secondSignatoryId && status.userType === UserType.SECOND_SIGNATORY,
  );
  return (
    <Div width="327px">
      <B type="b-small-semibold" mb="16px">
        {`${getContractSignedFractionWords({
          contract,
        })} have signed`}
      </B>

      {(firstSignatoryStatus?.isSigned && secondSignatoryStatus?.isSigned) ||
      (!firstSignatoryStatus?.isSigned && !secondSignatoryStatus?.isSigned) ? (
        <>
          <DocumentStatusTag
            status={
              firstSignatoryStatus?.isSigned && secondSignatoryStatus?.isSigned
                ? ContractStatusEnum.SIGNED
                : ContractStatusEnum.NOT_COMPLETED
            }>
            {firstSignatoryStatus?.isSigned ? 'Signed' : 'Not Signed'}
          </DocumentStatusTag>
          <Div m="10px 0px 0px">
            <Row gutter={[4, 4]}>
              <Col span={9}>
                <B type="b-small">
                  {contract?.firstSignatories?.[0]?.firstName
                    ? `${contract?.firstSignatories?.[0]?.firstName} ${contract?.firstSignatories?.[0]?.lastName}`
                    : '-'}
                </B>
              </Col>
              <Col span={15}>
                <Row justify="end">
                  <B
                    type="b-small"
                    color={theme.gray700}
                    fontSize="10px"
                    mt="2px">
                    {firstSignatoryStatus?.isSigned
                      ? moment(firstSignatoryStatus?.updatedAt).format(
                          'Do MMM YYYY [at] h:mma',
                        )
                      : ''}
                  </B>
                </Row>
              </Col>
            </Row>
          </Div>
          <Div m="0px 0px 17px">
            <Row gutter={[4, 4]}>
              <Col span={9}>
                <B type="b-small">
                  {contract?.secondSignatories?.[0]?.firstName
                    ? `${contract?.secondSignatories?.[0]?.firstName} ${contract?.secondSignatories?.[0]?.lastName}`
                    : ''}
                </B>
              </Col>
              <Col span={15}>
                <Row justify="end">
                  <B type="b-small" color={theme.gray700} fontSize="10px">
                    {secondSignatoryStatus?.isSigned
                      ? moment(secondSignatoryStatus?.updatedAt).format(
                          'Do MMM YYYY [at] h:mma',
                        )
                      : ''}
                  </B>
                </Row>
              </Col>
            </Row>
          </Div>
        </>
      ) : (
        <>
          {' '}
          <div>
            <DocumentStatusTag
              status={
                firstSignatoryStatus?.isSigned
                  ? ContractStatusEnum.SIGNED
                  : ContractStatusEnum.NOT_COMPLETED
              }>
              {firstSignatoryStatus?.isSigned ? 'Signed' : 'Not Signed'}
            </DocumentStatusTag>
            <Div m="10px 0px 17px">
              <Row gutter={[4, 4]}>
                <Col span={9}>
                  <B type="b-small">
                    {contract?.firstSignatories?.[0]?.firstName
                      ? `${contract?.firstSignatories?.[0]?.firstName} ${contract?.firstSignatories?.[0]?.lastName}`
                      : '-'}
                  </B>
                </Col>
                <Col span={15}>
                  <Row justify="end">
                    <B
                      type="b-small"
                      color={theme.gray700}
                      fontSize="10px"
                      mt="2px">
                      {firstSignatoryStatus?.isSigned
                        ? moment(firstSignatoryStatus?.updatedAt).format(
                            'Do MMM YYYY [at] h:mma',
                          )
                        : ''}
                    </B>
                  </Row>
                </Col>
              </Row>
            </Div>
          </div>
          {contract?.secondSignatories?.length > 0 && (
            <div>
              <DocumentStatusTag
                status={
                  secondSignatoryStatus?.isSigned
                    ? ContractStatusEnum.SIGNED
                    : ContractStatusEnum.NOT_COMPLETED
                }>
                {secondSignatoryStatus?.isSigned ? 'Signed' : 'Not Signed'}
              </DocumentStatusTag>
              <Div m="10px 0px 16px">
                <Row gutter={[4, 4]}>
                  <Col span={9} key={uuidv4()}>
                    <B type="b-small">
                      {' '}
                      {contract?.firstSignatories?.[0]?.firstName
                        ? `${contract?.secondSignatories?.[0]?.firstName} ${contract?.secondSignatories?.[0]?.lastName}`
                        : null}
                    </B>
                  </Col>
                  <Col span={15}>
                    <Row justify="end">
                      <B
                        type="b-small"
                        color={theme.gray700}
                        fontSize="10px"
                        mt="2px">
                        {secondSignatoryStatus?.isSigned
                          ? moment(secondSignatoryStatus.updatedAt).format(
                              'Do MMM YYYY [at] h:mma',
                            )
                          : ' '}
                      </B>
                    </Row>
                  </Col>
                </Row>
              </Div>
            </div>
          )}
        </>
      )}
    </Div>
  );
};
