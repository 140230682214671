import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { B, ConfirmationModal, Image } from '../../../components';
import { Menu, Dropdown } from 'antd';
import theme from '../../../theme';
import { ExpenseMeta, ExpenseMetaData, SuppliersList } from '../../../types';
import { MoreSVG } from '../../../assets';

const StyledMenu = styled(Menu)`
  width: 176px;
  padding: 0px;
  border: 0.5px solid ${theme.gray300};
  border-bottom: none;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  .ant-dropdown-menu-item {
    padding: 12px 16px;
    color: ${theme.black};
  }
  .ant-dropdown-menu-item :hover {
    background: ${theme.gray100};
  }
`;
const MenuItem = styled(Menu.Item)`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.black};
  border-bottom: 1px solid ${theme.gray300};
  p {
    margin: -12px -16px;
    padding: 12px 16px;
  }
`;
const CollapseList = styled.div<{ isSub: boolean }>`
  ${(props) =>
    props.isSub
      ? `margin-left: 25px; margin-top: 10px; border-top: 1px solid ${theme.gray300}; padding-top: 8px;`
      : `margin-right: 20px;`}
`;
const FullWidthContainer = styled.div`
  width: 100%;
`;
const SpaceBetweenContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export interface ExpenseCategoryDelete {
  id: string;
  subId?: string;
}

export interface ExpenseSupplierDelete {
  id: string;
}

interface CollapseDataProps {
  isSystem: boolean;
  type: ExpenseMeta;
  data: SuppliersList | ExpenseMetaData;
  isSub?: boolean;
  deleteActionLoading: boolean;
  deleteAction: (data: ExpenseCategoryDelete) => void;
  updateAction: (data: SuppliersList | ExpenseMetaData) => void;
}
export const CollapsibleList: FC<CollapseDataProps> = ({
  isSystem,
  type,
  data,
  isSub,
  deleteAction,
  deleteActionLoading = false,
  updateAction,
}) => {
  const [visible, setVisible] = useState(false);

  // set domain type
  let domain = 'Category';
  if (type === ExpenseMeta.CATEGORIES) {
    domain = 'Category';
  }
  if (type === ExpenseMeta.MERCHANTS) {
    domain = 'Merchant';
  }

  const moreOptions = () => (
    <Dropdown overlay={menu()} placement="bottomLeft" trigger={['click']}>
      <Image
        cursor="pointer"
        onClick={(e) => e.preventDefault()}
        src={MoreSVG}
        draggable={false}
        alt="more-options"
      />
    </Dropdown>
  );

  const menu = () => {
    return (
      <StyledMenu key={data.value}>
        <MenuItem onClick={() => updateAction(data)}>Edit {domain}</MenuItem>
        <MenuItem>
          <ConfirmationModal
            title={<B type="b-large-semibold">Confirmation Alert</B>}
            content={
              <B type="b-large">
                Are you sure you want to delete this expense{' '}
                {domain.toLocaleLowerCase()} entry?
              </B>
            }
            okText="Yes, Delete"
            cancelText="No"
            confirmLoading={deleteActionLoading}
            onConfirm={() => {
              deleteAction(
                isSub
                  ? {
                      id: String(data.parentRef),
                      subId: String(data.value),
                    }
                  : {
                      id: String(data.value),
                    },
              );
            }}
            trigger={<B type="b-large">Delete</B>}
          />
        </MenuItem>
      </StyledMenu>
    );
  };

  const subs: (SuppliersList | ExpenseMetaData)[] | undefined = data.subs;

  return (
    <CollapseList isSub={isSub || false}>
      {subs && subs.length ? (
        <FullWidthContainer>
          <SpaceBetweenContainer>
            <B
              type={isSub ? 'b-default' : 'b-default-semibold'}
              onClick={() => setVisible(!visible)}
              cssStyle="cursor: pointer;">
              {visible ? <CaretUpOutlined /> : <CaretDownOutlined />}{' '}
              {data.label}
              &nbsp;&nbsp;&nbsp;
              <small>
                • {subs.length} {subs.length > 1 ? 'items' : 'item'}
              </small>
            </B>
            {isSystem && moreOptions()}
          </SpaceBetweenContainer>
          {visible &&
            subs.map((i) => (
              <CollapsibleList
                isSystem={isSystem}
                type={type}
                data={i}
                isSub={true}
                deleteAction={deleteAction}
                deleteActionLoading={deleteActionLoading}
                updateAction={updateAction}
              />
            ))}
        </FullWidthContainer>
      ) : (
        <SpaceBetweenContainer>
          <B
            type={
              type === ExpenseMeta.MERCHANTS
                ? 'b-default'
                : isSub
                ? 'b-default'
                : 'b-default-semibold'
            }
            marginLeft="18px">
            {data.label}
          </B>
          {isSystem && moreOptions()}
        </SpaceBetweenContainer>
      )}
    </CollapseList>
  );
};
