import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ColumnProps } from 'antd/lib/table';
import {
  EmptyTextContainer,
  FooterComponent,
  InputComp,
  LinkButton,
  PrimaryButton,
  SecondaryButton,
  TableComp,
} from '../../../components';
import { Spin, message } from 'antd';
import theme from '../../../theme';
import {
  useCreateExpenseSupplier,
  useDeleteExpenseSupplier,
  useGetExpenseSuppliersList,
  useUpdateExpenseSupplier,
} from '../../../api/expenseHooks';
import {
  ExpenseCreation,
  ExpenseMeta,
  ExpenseMetaData,
  IntegrationStatusEnum,
  Permission,
  SuppliersList,
} from '../../../types';
import { getApiError } from '../../../util/getApiError';
import { useNavigate } from 'react-router';
import { getIntegrationSetting, refreshMetaData } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType, RootState } from '../../../store/reducer';
import { ManageExpenseMetaModal } from '../shared/ManageExpenseMetaModal';
import { CollapsibleList } from '../shared/CollapsibleList';
import { SearchOutlined } from '@ant-design/icons';
import {
  ErrorMessageContainer,
  WarnMessageContainer,
} from '../../settings/integration-apps/components/IntegrationAlerts';
import { isAllowed } from '../../../util/permissionUtil';
import { RefreshSVG } from '../../../assets';

const Body = styled.div`
  padding: 28px 32px;
  border-bottom: 1px solid ${theme.gray300};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const CustomLinkButton = styled(LinkButton)`
  margin-left: 20px;
  margin-right: 4px;
  height: 32px;
  @media screen and (max-width: 768px) {
    margin-top: 5px;
    margin-left: 0px;
  }
`;
const SearchInput = styled(InputComp)`
  border-radius: 4px;
  height: 32px;
  width: 265px;
  border: 1px solid ${theme.gray300};
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const TableContainer = styled.div`
  margin-bottom: 15px;
  .ant-table-cell {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .ant-table-wrapper {
    border: none !important;
  }
`;
const EmptyContainer = styled.div`
  margin-top: 15px;
`;

const ExpensesMerchantsPage: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const navigate = useNavigate();

  const [visibility, setVisibility] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<
    SuppliersList | ExpenseMetaData | null
  >(null);
  const [search, setSearch] = useState<string>('');

  // get integration permissions
  const isAllowedManageIntegration: boolean = isAllowed(
    Permission.MANAGE_INTEGRATION,
  );

  const {
    isLoading: suppliersListLoading,
    error: suppliersListError,
    data: getSuppliersList,
    refetch: getSuppliersListFn,
  } = useGetExpenseSuppliersList();

  const {
    isLoading: loadingCreateSupplier,
    error: errorCreateSupplier,
    mutate: createSupplier,
    isSuccess: createSupplierSuccess,
  } = useCreateExpenseSupplier();

  const {
    isLoading: loadingUpdateSupplier,
    error: errorUpdateSupplier,
    mutate: updateSupplier,
    isSuccess: UpdateSupplierSuccess,
  } = useUpdateExpenseSupplier();

  const {
    isLoading: loadingDeleteSupplier,
    error: errorDeleteSupplier,
    mutate: deleteSupplier,
    isSuccess: deleteSupplierSuccess,
  } = useDeleteExpenseSupplier();

  const integrationSettings = useSelector(
    (state: RootState) => state.integration.integrationSetting,
  );
  const loadingIntegrationSettings = useSelector(
    (state: RootState) => state.integration.integrationSettingLoading,
  );

  // error handling
  useEffect(() => {
    suppliersListError && message.error(getApiError(suppliersListError));
    errorCreateSupplier && message.error(getApiError(errorCreateSupplier));
    errorUpdateSupplier && message.error(getApiError(errorUpdateSupplier));
    errorDeleteSupplier && message.error(getApiError(errorDeleteSupplier));
  }, [
    suppliersListError,
    errorCreateSupplier,
    errorUpdateSupplier,
    errorDeleteSupplier,
  ]);

  // success handling
  useEffect(() => {
    if (createSupplierSuccess && !errorCreateSupplier) {
      message.success('Created Successfully!');
      setVisibility(false);
      getSuppliersListFn();
    }
  }, [createSupplierSuccess]);

  useEffect(() => {
    if (UpdateSupplierSuccess && !errorUpdateSupplier) {
      message.success('Updated Successfully!');
      setVisibility(false);
      getSuppliersListFn();
    }
  }, [UpdateSupplierSuccess]);

  useEffect(() => {
    if (deleteSupplierSuccess && !errorDeleteSupplier) {
      message.success('Deleted Successfully!');
      getSuppliersListFn();
    }
  }, [deleteSupplierSuccess]);

  // load list after integration refresh
  useEffect(() => {
    if (!loadingIntegrationSettings) {
      getSuppliersListFn();
    }
  }, [loadingIntegrationSettings]);

  // integration settings
  useEffect(() => {
    if (getSuppliersList && getSuppliersList?.type !== ExpenseCreation.SYSTEM) {
      dispatch(getIntegrationSetting(getSuppliersList?.type));
    }
  }, [getSuppliersList]);

  const updateMeta = (data: SuppliersList | ExpenseMetaData) => {
    setUpdateData(data);
    setVisibility(true);
  };

  const columns: ColumnProps<SuppliersList | ExpenseMetaData>[] = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      render: (name, data) => {
        return (
          <CollapsibleList
            isSystem={Boolean(
              getSuppliersList?.type === ExpenseCreation.SYSTEM,
            )}
            type={ExpenseMeta.MERCHANTS}
            data={data}
            deleteAction={(i) => deleteSupplier(i)}
            deleteActionLoading={loadingDeleteSupplier}
            updateAction={(i) => updateMeta(i)}
          />
        );
      },
    },
  ];

  const getMetaData = (): (SuppliersList | ExpenseMetaData)[] => {
    const dataSet: (SuppliersList | ExpenseMetaData)[] =
      getSuppliersList?.metaData || [];
    if (search !== '') {
      const filtered: (SuppliersList | ExpenseMetaData)[] = dataSet.filter(
        (i: SuppliersList | ExpenseMetaData) => {
          const label = i.label.toLocaleLowerCase();
          return label.includes(search.toLocaleLowerCase());
        },
      );
      return filtered;
    } else {
      return dataSet;
    }
  };

  return (
    <Spin spinning={suppliersListLoading || loadingIntegrationSettings}>
      <Body>
        <Header>
          <SearchInput
            size="small"
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Merchants"
            suffix={<SearchOutlined style={{ color: '#9E9E9E' }} />}
          />
          {getSuppliersList?.type !== ExpenseCreation.SYSTEM ? (
            <CustomLinkButton
              icon={<img width="18px" height="18px" src={RefreshSVG} />}
              onClick={() =>
                dispatch(refreshMetaData(Number(integrationSettings?.id)))
              }>
              Refresh
            </CustomLinkButton>
          ) : (
            <PrimaryButton
              size="small"
              onClick={() => [setUpdateData(null), setVisibility(true)]}>
              Create
            </PrimaryButton>
          )}
        </Header>
      </Body>
      {getSuppliersList?.type !== ExpenseCreation.SYSTEM &&
        !suppliersListLoading &&
        [
          IntegrationStatusEnum.ACTIVATED,
          IntegrationStatusEnum.PENDING,
        ].includes(integrationSettings?.status as IntegrationStatusEnum) && (
          <WarnMessageContainer
            marginLeft="32px"
            message={`The merchants listed here are from ${getSuppliersList?.type.toLocaleLowerCase()}. If you'd like to change/manage them, you can do so directly from ${getSuppliersList?.type.toLocaleLowerCase()}.`}
            type="warning"
            showIcon
            closable
          />
        )}

      {isAllowedManageIntegration &&
        getSuppliersList?.type !== ExpenseCreation.SYSTEM &&
        !suppliersListLoading &&
        [IntegrationStatusEnum.ACTION_REQUIRED].includes(
          integrationSettings?.status as IntegrationStatusEnum,
        ) && (
          <ErrorMessageContainer
            marginLeft="32px"
            message={
              <>
                Opps! Looks like your{' '}
                <b>{getSuppliersList?.type.toLocaleLowerCase()}</b> integration
                is not working anymore.
              </>
            }
            type="error"
            showIcon
          />
        )}
      {getSuppliersList?.metaData &&
      Boolean(getSuppliersList?.metaData.length) ? (
        <TableContainer>
          <TableComp
            showHeader={false}
            loading={false}
            columns={columns}
            dataSource={getMetaData() || []}
            pagination={false}
            withBorders={true}
            scroll={{
              y:
                window.innerHeight -
                (getSuppliersList?.type !== ExpenseCreation.SYSTEM ? 280 : 235),
            }}
          />
        </TableContainer>
      ) : (
        <EmptyContainer>
          <EmptyTextContainer description="You don't have any merchants yet" />
        </EmptyContainer>
      )}
      <FooterComponent
        leftChildren={
          <SecondaryButton onClick={() => navigate('/expenses')}>
            Back
          </SecondaryButton>
        }
        fullScreen={false}
      />

      <ManageExpenseMetaModal
        type={ExpenseMeta.MERCHANTS}
        visibility={visibility}
        updateData={updateData}
        setVisibility={(data) => setVisibility(data)}
        metaData={getSuppliersList?.metaData || []}
        createAction={(data) => createSupplier(data)}
        createLoading={loadingCreateSupplier}
        updateAction={(data) => updateSupplier(data)}
        updateLoading={loadingUpdateSupplier}
      />
    </Spin>
  );
};

export default ExpensesMerchantsPage;
