import { Col, message, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { FileUploadComponent, PrimaryButton } from '../../../../components';
import {
  DataExtractorCSVEnum,
  uploadExcelSheet,
} from '../../../../store/actions';
import { DataImportConfig } from '../../../../configs';
import { useUploadForDataExtract } from '../../../../api/DataImportHooks';
import { getApiError } from '../../../../util/getApiError';
import { useDispatch } from 'react-redux';
import { DispatchType } from '../../../../store';

interface Props {
  extractorInfo: DataImportConfig;
}
const ImportCSVUploader: FC<Props> = ({ extractorInfo }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const dispatch: DispatchType = useDispatch();

  const {
    isLoading: loadingUploadForDataExtract,
    error: errorUploadForDataExtract,
    mutate: uploadForDataExtract,
    data: uploadForData,
  } = useUploadForDataExtract();

  useEffect(() => {
    errorUploadForDataExtract &&
      message.error(getApiError(errorUploadForDataExtract));
  }, [errorUploadForDataExtract]);

  useEffect(() => {
    if (!errorUploadForDataExtract && uploadForData) {
      dispatch(uploadExcelSheet(uploadForData));
    }
  }, [uploadForData]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={14} lg={8} xl={8} xxl={8}>
          <FileUploadComponent
            onFileChanged={(file) => setSelectedFile(file)}
            csvType={DataExtractorCSVEnum.SALARY_CSV}
          />
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
          <PrimaryButton
            disabled={!selectedFile}
            loading={loadingUploadForDataExtract}
            onClick={() => {
              if (selectedFile) {
                uploadForDataExtract({
                  type: extractorInfo.extractorType,
                  csvType: DataExtractorCSVEnum.SALARY_CSV,
                  file: selectedFile,
                });
              }
            }}>
            Import
          </PrimaryButton>
        </Col>
      </Row>
    </>
  );
};

export default ImportCSVUploader;
