import { message } from 'antd';
import axios from 'axios';
import { NotificationItemI, Reminders } from '../../types/notification.types';

import showApiError from '../../util/showApiError';
const NOTES_LOADING = 'NOTES_LOADING';
const NOTES = 'NOTES';
const ADD_NOTE_LOADING = 'ADD_NOTE_LOADING';
const REMINDER_LOADING = 'REMINDER_LOADING';
const REMINDER = 'REMINDER';

interface InitialStateI {
  notes: NotificationItemI[];
  notesLoading: boolean;
  reminder: Reminders[];
  reminderLoading: boolean;
}

const initialState: InitialStateI = {
  notes: [],
  notesLoading: false,
  reminder: [],
  reminderLoading: false,
};

export default function reducer(
  state: InitialStateI = initialState,
  action: any,
): InitialStateI {
  switch (action.type) {
    case NOTES_LOADING:
      return {
        ...state,
        notesLoading: action.payload,
      };
    case NOTES:
      return {
        ...state,
        notes: action.payload,
      };
    case REMINDER_LOADING:
      return {
        ...state,
        reminderLoading: action.payload,
      };
    case REMINDER:
      return {
        ...state,
        reminder: action.payload,
      };
    default:
      return state;
  }
}
export function getPeopleNotes(userId: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: NOTES_LOADING, payload: true });
      const res = await axios.get(`/notification/${userId}/notes`);
      dispatch({ type: NOTES, payload: res.data });
      dispatch({ type: NOTES_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: NOTES_LOADING, payload: false });
    }
  };
}

export function addNewNote(data: NotificationItemI) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: ADD_NOTE_LOADING, payload: true });
      await axios.post('/notification', data);
      message.success('New note created successfully');
      dispatch({ type: ADD_NOTE_LOADING, payload: false });
      dispatch(getPeopleNotes(String(data.userId)));
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: ADD_NOTE_LOADING, payload: false });
    }
  };
}
export function updateNote(data: NotificationItemI, userId: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: ADD_NOTE_LOADING, payload: true });
      await axios.put('/notification', data);
      message.success('Note update successfully');
      dispatch({ type: ADD_NOTE_LOADING, payload: false });
      dispatch(getPeopleNotes(userId));
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: ADD_NOTE_LOADING, payload: false });
    }
  };
}
export function deleteNote(noteId: string, userId: string) {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/notification/notes/${noteId}`);
      message.success('Successfully deleted the note.');
      dispatch(getPeopleNotes(userId));
    } catch (error: any) {
      showApiError(error);
    }
  };
}
