import React, { FC, useEffect, useState } from 'react';

import { Row } from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import styled from '@emotion/styled';

import { B, Div, LinkButton } from '../../../../components';
import {
  createEmploymentHistory,
  deleteExperience,
  employmentHistory,
} from '../../../../store/actions';
import { Permission } from '../../../../types';
import {
  IndividualPeopleI,
  UserHistoryI,
} from '../../../../types/people.types';
import { isAllowed } from '../../../../util/permissionUtil';
import { ExperienceModal } from '../../shared/ExperienceModal';
import { Experience } from './Experience';
import { DispatchType } from '../../../../store';
import { PlusBlueSVG } from '../../../../assets';

const MainContainer = styled.div`
  padding: 14px 32px;
  @media only screen and (max-width: 768px) {
    padding: 0px;
  }
`;

const HeaderSection = styled.div`
  padding-bottom: 14px;
  display: flex;
  justify-content: space-between;
`;

type ParamsI = {
  employeeKey: string;
};
interface Props {
  people: IndividualPeopleI | null;
  fetchPeopleById: (employeeKey: string) => void;
}
const ExperienceDetails: FC<Props> = ({ people, fetchPeopleById }) => {
  const params = useParams<ParamsI>();
  const dispatch: DispatchType = useDispatch();
  const [visibleExperienceModal, setVisibleExperienceModal] = useState(false);
  const [experiences, setExperiences] = useState<UserHistoryI[]>([]);
  const [
    selectedExperienceIndex,
    setSelectedExperienceIndex,
  ] = useState<number>(-1);

  useEffect(() => {
    if (people != null) {
      const userHistory = people?.userHistory || [];
      setExperiences(userHistory);
    }
  }, [people]);
  return (
    <MainContainer>
      <HeaderSection>
        <Div m="auto 0">
          <B type="b-large-semibold">Experience</B>
        </Div>
        {isAllowed(Permission.CREATE_EDIT_USERS) && (
          <LinkButton
            icon={<img src={PlusBlueSVG} />}
            id="add-experience-btn"
            onClick={() => {
              setVisibleExperienceModal(true);
              setSelectedExperienceIndex(-1);
            }}>
            Add experience
          </LinkButton>
        )}
      </HeaderSection>
      <Row>
        {experiences.map((item, i) => (
          <Experience
            key={i}
            id={`experience-${i}`}
            experience={item}
            onClickEdit={() => {
              setVisibleExperienceModal(true);
              setSelectedExperienceIndex(i);
            }}
          />
        ))}
      </Row>

      {visibleExperienceModal && (
        <ExperienceModal
          joinedDate={people ? people.joinedDate : null}
          peopleId={people ? people.id : null}
          onRemove={async () => {
            await dispatch(
              deleteExperience(
                experiences[selectedExperienceIndex]?.id,
                params.employeeKey,
              ),
            );
            setVisibleExperienceModal(false);
            setSelectedExperienceIndex(-1);
            fetchPeopleById(params.employeeKey);
          }}
          onSubmit={async (v) => {
            if (selectedExperienceIndex > -1) {
              experiences[selectedExperienceIndex] = v;
              await dispatch(employmentHistory(v));
            } else {
              experiences.push(v);
              await dispatch(createEmploymentHistory(v, params.employeeKey));
            }

            setExperiences(experiences);
            setVisibleExperienceModal(false);
            fetchPeopleById(params.employeeKey);
          }}
          experience={
            selectedExperienceIndex > -1
              ? experiences[selectedExperienceIndex]
              : undefined
          }
          visible={visibleExperienceModal}
          onCancel={() => {
            setVisibleExperienceModal(false);
            setSelectedExperienceIndex(-1);
          }}
        />
      )}
    </MainContainer>
  );
};
export default ExperienceDetails;
