import { Row, Col } from 'antd';
import React, { FC } from 'react';
import { B, Div } from '../../../components';
import theme from '../../../theme';
import { GratuityI } from '../../../types/gratuity.types';
import { isValidMoment } from '../../../util/isValidMoment';
import { numberThousandSeparator } from '../../../util/utils';

interface Props {
  gratuityDetails?: GratuityI;
}

const GratuityOverview: FC<Props> = ({ gratuityDetails }) => {
  return (
    <Row gutter={24}>
      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        <B type="b-small" color={theme.gray600}>
          Employee Name
        </B>
        <B type="b-large-semibold">{gratuityDetails && gratuityDetails.name}</B>
      </Col>
      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        <B type="b-small" color={theme.gray600}>
          Gratuity Amount
        </B>
        <Div display="flex">
          <B type="b-small" color={theme.gray600} pr="4px" pt="3px">
            LKR
          </B>
          <B type="b-large-semibold">
            {gratuityDetails &&
              numberThousandSeparator(gratuityDetails?.gratuityAmount)}
          </B>
        </Div>
      </Col>
      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        <B type="b-small" color={theme.gray600}>
          Due Date
        </B>

        <B type="b-large-semibold">
          {gratuityDetails &&
            isValidMoment(
              gratuityDetails.dueDate,
              '-',
              'YYYY-MM-DD',
            ).toString()}
        </B>
      </Col>
    </Row>
  );
};

export default GratuityOverview;
